import {Action, Actions, BaseFlagProps, Flags, Parameters} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {buildArray, Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

export interface CreateInvoiceHeaderProps extends TestIdProps {
  flags?: BaseFlagProps[];
  actions?: Action[];
  createdAt?: string | Nullish;
  author?: string;
}

export function InvoiceDetailSubHeader(props: CreateInvoiceHeaderProps) {
  const formatDateTime = useDateTimeFormatter();
  const createdAt: Date = props.createdAt ? parseDate(props.createdAt) : new Date();

  const invoiceParameters = buildArray<string>()
    .when(
      isNotNil(props.createdAt),
      `${i18n.t('entity.order.createdAt')}: ${formatDateTime('dateShort', createdAt)}`
    )
    .when(isNotNil(props.author), `${i18n.t('general.labels.author')}: ${props.author}`);

  return (
    <Box padding={4}>
      <HStack justify="space-between" align="center">
        <HStack spacing={4} align="center">
          <HStack spacing={1}>
            <Flags flags={props.flags} data-testid={suffixTestId('flags', props)} />
          </HStack>
          <Parameters
            size="small"
            parameters={invoiceParameters}
            data-testid={suffixTestId('parameters', props)}
          />
        </HStack>

        <HStack spacing={4} align="center">
          <Actions actions={props.actions} data-testid={props['data-testid']} />
        </HStack>
      </HStack>
    </Box>
  );
}
