import {
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Hide, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {
  BusinessCaseSeriesDefinitionRequestBody,
  BusinessCaseSeriesDefinitionResponseBody,
  useCreateBusinessCaseSeriesDefinitionMutation,
  useLazyGetBusinessCaseSeriesDefinitionQuery,
  usePutBusinessCaseSeriesDefinitionMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {selectSeries, selectUserBranches, Series, SeriesTypeEnum} from '@dms/teas';

import {suffixTestId, TestIdProps, yupString} from 'shared';

interface BusinessCaseSeriesDefinitionFormProps extends TestIdProps {
  definitionId?: BusinessCaseSeriesDefinitionResponseBody['id'];
}

export function BusinessCaseSeriesDefinitionForm(props: BusinessCaseSeriesDefinitionFormProps) {
  const branchList = useSelector(selectUserBranches) || [];
  const series = useSelector(selectSeries);

  const [
    getSeriesDefinition,
    {data: definition, isFetching: isFetchingDefinition, isError: isErrorDefinition},
  ] = useLazyGetBusinessCaseSeriesDefinitionQuery();

  const [saveSeriesDefinition, {isLoading: isLoadingSave}] =
    usePutBusinessCaseSeriesDefinitionMutation();

  const [createSeriesDefinition, {isLoading: isLoadingCreate}] =
    useCreateBusinessCaseSeriesDefinitionMutation();

  useEffect(() => {
    if (!props.definitionId) {
      return;
    }

    getSeriesDefinition({seriesDefinitionId: props.definitionId});
  }, [props.definitionId]);

  const businessCaseSeries = filterAndPrepareSeriesForChoice(
    series,
    SeriesTypeEnum.NEW_AND_USED_CARS_BUSINESS_CASE
  );

  const invoiceSeries = filterAndPrepareSeriesForChoice(series, SeriesTypeEnum.ACCOUNTING_INVOICE);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        branchId:
          definition?.systemDefault && props.definitionId
            ? Yup.string().nullable().default(null)
            : Yup.string().required(),
        businessCaseSeriesId: Yup.string().required(),
        proformaInvoiceSeriesId: Yup.string().required(),
        invoiceSeriesId: Yup.string().required(),
        taxDocumentSeriesId: Yup.string().required(),
        correctiveTaxDocumentSeriesId: Yup.string().required(),
        brokerageFeesInvoiceSeriesId: yupString.required(),
        brokerageFeesTaxDocumentSeriesId: yupString.required(),
        brokerageFeesCorrectiveTaxDocumentSeriesId: yupString.required(),
        saleBrokerageInvoiceSeriesId: yupString.required(),
        saleBrokerageProformaInvoiceSeriesId: yupString.required(),
        saleBrokerageTaxDocumentSeriesId: yupString.required(),
        saleBrokerageCorrectiveTaxDocumentSeriesId: yupString.required(),
      }),
    [definition?.systemDefault, props.definitionId]
  );

  const branchListOptions = branchList.map((branch) => ({
    value: branch.id,
    label: branch.name,
  }));

  const handleSubmit: FormSubmitHandler<BusinessCaseSeriesDefinitionRequestBody> = async (
    values
  ) => {
    if (props.definitionId && definition?.id) {
      await saveSeriesDefinition({
        seriesDefinitionId: definition?.id,
        putBusinessCaseSeriesDefinitionRequestBody: values,
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('page.businessCaseSeriesDefinition.notification.success')
          );
          closeCurrentDialog();
        })
        .catch(handleApiError);

      return;
    }

    createSeriesDefinition({
      businessCaseSeriesDefinitionRequestBody: values,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('page.businessCaseSeriesDefinition.notification.success'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isFetchingDefinition} isError={isErrorDefinition} spacing={5}>
      <Form<BusinessCaseSeriesDefinitionRequestBody>
        onSubmit={handleSubmit}
        schema={schema}
        defaultValues={definition ?? {}}
      >
        {(control) => (
          <VStack spacing={4}>
            <Card title={i18n.t('entity.settings.labels.general')} variant="inlineGrey">
              <Grid columns={2}>
                <FormField
                  control={control}
                  name="name"
                  type="text"
                  label={i18n.t('entity.seriesDefinition.labels.definitionName')}
                  data-testid={suffixTestId('definitionName', props)}
                  isRequired
                />
                <GridItem>
                  <Hide when={definition?.systemDefault}>
                    <FormField
                      control={control}
                      type="choice"
                      name="branchId"
                      label={i18n.t('entity.branch.labels.branch')}
                      data-testid={suffixTestId('branchId', props)}
                      options={branchListOptions}
                      menuInPortal
                      isRequired
                    />
                  </Hide>
                </GridItem>
                <FormField
                  control={control}
                  type="choice"
                  name="businessCaseSeriesId"
                  label={i18n.t('entity.settings.labels.businessCaseSeriesId')}
                  data-testid={suffixTestId('businessCaseSeriesId', props)}
                  options={businessCaseSeries}
                  menuInPortal
                  isRequired
                />
              </Grid>
            </Card>

            <Card title={i18n.t('entity.settings.labels.sale')} variant="inlineGrey">
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="invoiceSeriesId"
                  label={i18n.t('entity.settings.labels.invoiceSeriesId')}
                  data-testid={suffixTestId('invoiceSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="proformaInvoiceSeriesId"
                  label={i18n.t('entity.settings.labels.proformaInvoiceSeriesId')}
                  data-testid={suffixTestId('proformaInvoiceSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="taxDocumentSeriesId"
                  label={i18n.t('entity.settings.labels.taxDocumentSeriesId')}
                  data-testid={suffixTestId('taxDocumentSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="correctiveTaxDocumentSeriesId"
                  label={i18n.t('entity.settings.labels.correctiveTaxDocumentSeriesId')}
                  data-testid={suffixTestId('correctiveTaxDocumentSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
              </Grid>
            </Card>

            <Card title={i18n.t('entity.settings.labels.saleBrokerage')} variant="inlineGrey">
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="saleBrokerageInvoiceSeriesId"
                  label={i18n.t('entity.settings.labels.saleBrokerageInvoiceSeriesId')}
                  data-testid={suffixTestId('saleBrokerageInvoiceSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="saleBrokerageProformaInvoiceSeriesId"
                  label={i18n.t('entity.settings.labels.saleBrokerageProformaInvoiceSeriesId')}
                  data-testid={suffixTestId('saleBrokerageProformaInvoiceSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="saleBrokerageTaxDocumentSeriesId"
                  label={i18n.t('entity.settings.labels.saleBrokerageTaxDocumentSeriesId')}
                  data-testid={suffixTestId('saleBrokerageTaxDocumentSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="saleBrokerageCorrectiveTaxDocumentSeriesId"
                  label={i18n.t(
                    'entity.settings.labels.saleBrokerageCorrectiveTaxDocumentSeriesId'
                  )}
                  data-testid={suffixTestId('saleBrokerageCorrectiveTaxDocumentSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
              </Grid>
            </Card>

            <Card title={i18n.t('entity.settings.labels.brokerageFees')} variant="inlineGrey">
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="brokerageFeesInvoiceSeriesId"
                  label={i18n.t('entity.settings.labels.brokerageFeesInvoiceSeriesId')}
                  data-testid={suffixTestId('brokerageFeesInvoiceSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <GridItem />
                <FormField
                  control={control}
                  type="choice"
                  name="brokerageFeesTaxDocumentSeriesId"
                  label={i18n.t('entity.settings.labels.brokerageFeesTaxDocumentSeriesId')}
                  data-testid={suffixTestId('brokerageFeesTaxDocumentSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  name="brokerageFeesCorrectiveTaxDocumentSeriesId"
                  label={i18n.t(
                    'entity.settings.labels.brokerageFeesCorrectiveTaxDocumentSeriesId'
                  )}
                  data-testid={suffixTestId('brokerageFeesCorrectiveTaxDocumentSeriesId', props)}
                  options={invoiceSeries}
                  menuInPortal
                  isRequired
                />
              </Grid>
            </Card>

            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.cancel')}
                variant="secondary"
                data-testid={suffixTestId('cancel', props)}
                onClick={closeCurrentDialog}
              />
              <FormButton
                control={control}
                isLoading={isLoadingSave || isLoadingCreate}
                type="submit"
                data-testid={suffixTestId('submit', props)}
                title={i18n.t(
                  props.definitionId ? 'general.actions.save' : 'general.actions.create'
                )}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

function filterAndPrepareSeriesForChoice(series: Series[], seriesType: SeriesTypeEnum) {
  return series
    .filter((item) => item.type === seriesType)
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));
}
