import {
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {object} from 'yup';

import {useGetJbrConfigQuery, usePatchJbrConfigMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

interface JbrSettingsFormType {
  url: string;
  username: string;
  password: string;
}

const schema = object({
  url: yupString.url().required(),
  username: yupString.required(),
  password: yupString.required(),
});

export function JbrSettingsCredentialsForm() {
  const {
    data: jbrQueryData,
    isLoading: isJbrQueryLoading,
    isError: isJbrQueryError,
  } = useGetJbrConfigQuery();
  const [patchJbrConfigMutation, {isLoading: isPatchJbrMutationLoading}] =
    usePatchJbrConfigMutation();

  const defaultValues = {
    url: jbrQueryData?.credentials?.url ?? '',
    username: jbrQueryData?.credentials?.username ?? '',
    password: jbrQueryData?.credentials?.password ?? '',
  };

  const onSubmit: FormSubmitHandler<JbrSettingsFormType> = async (values) => {
    await patchJbrConfigMutation({
      jbrConfigRequestBody: {
        credentials: {
          ...values,
        },
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isJbrQueryLoading} isError={isJbrQueryError}>
      <Form<JbrSettingsFormType> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
        {(control) => (
          <Grid columns={12}>
            <GridItem span={12}>
              <FormField
                control={control}
                name="url"
                label={i18n.t('page.integrations.labels.jbrUrl')}
                type="text"
                data-testid={testIds.settings.jbrSettings('url')}
                isDisabled={isPatchJbrMutationLoading}
              />
            </GridItem>
            <GridItem span={12}>
              <FormField
                control={control}
                name="username"
                label={i18n.t('page.integrations.labels.jbrUsername')}
                type="text"
                data-testid={testIds.settings.jbrSettings('username')}
                isDisabled={isPatchJbrMutationLoading}
              />
            </GridItem>
            <GridItem span={12}>
              <FormField
                control={control}
                name="password"
                label={i18n.t('page.integrations.labels.jbrPassword')}
                type="text"
                data-testid={testIds.settings.jbrSettings('password')}
                isDisabled={isPatchJbrMutationLoading}
              />
            </GridItem>
            <GridItem span={12}>
              <ButtonGroup align="right">
                <FormButton
                  title={i18n.t('general.actions.save')}
                  control={control}
                  type="submit"
                  data-testid={testIds.settings.jbrSettings('submit')}
                  isLoading={isPatchJbrMutationLoading}
                />
              </ButtonGroup>
            </GridItem>
          </Grid>
        )}
      </Form>
    </DataStatus>
  );
}
