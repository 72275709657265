import {
  Box,
  Center,
  Heading,
  HStack,
  Icon,
  Text,
  ThemeColorTextPath,
  VStack,
} from 'platform/foundation';
import {css} from 'styled-components';

import i18n from '@dms/i18n';

import adBgImage from '../assets/photos/ad_bgremoval.jpg';

interface PromoBgRemovalProps {
  headingColor?: ThemeColorTextPath;
  subheadingColor?: ThemeColorTextPath;
  bgImageSrc?: string;
}

export function PromoBgRemoval(props: PromoBgRemovalProps) {
  const {headingColor = 'primary', subheadingColor = 'secondary', bgImageSrc = adBgImage} = props;
  return (
    <div
      css={css`
        background-image: url(${bgImageSrc});
        background-size: cover;
        border-radius: ${({theme}) => theme.getSize(2)};
        min-width: ${({theme}) => theme.getSize(80)};
        min-height: ${({theme}) => theme.getSize(55)};
        padding: ${({theme}) => theme.getSize(5)};
      `}
    >
      <HStack>
        <VStack spacing={2} maxWidth={58}>
          <Heading size={4} color={headingColor}>
            {i18n.t('page.homepage.ad.PhotoBackgroundReplacement')}
          </Heading>
          <Text size="xSmall" color={subheadingColor}>
            {i18n.t('page.homepage.ad.PerfectPhotosWithAI')}
          </Text>
        </VStack>
        <Box width={10} height={10} borderRadius="circular" backgroundColor="general.white">
          <Center height="100%">
            <Icon value="navigation/arrow_forward_ios" size={4} />
          </Center>
        </Box>
      </HStack>
    </div>
  );
}
