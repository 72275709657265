import {isFeatureEnabled} from 'feature-flags';
import {showNotification} from 'platform/components';
import {ParsedUrlQuery} from 'querystring';

import {useCallback, useRef, useState} from 'react';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {
  useRouter,
  BillingInformationService,
  CreateBillingInformationRequestBody,
  useCallApi,
} from '@dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BillingInformationDetail as BillingInformationDetailV2} from '../../pages/BillingInformationDetail/BillingInformationDetail';
import {BillingInformationDetail} from './components/BillingInformationDetail';
import {ValidationCheckerProvider} from './components/ValidationChecker';
import {getBackBreadcrumb} from './utils';

export function BillingInfoNew() {
  const [isCreating, setIsCreating] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isInitial] = useState(false);
  const dataRef = useRef<CreateBillingInformationRequestBody>();
  const {params, push, back} = useRouter();

  const breadcrumb = getBackBreadcrumb(params as ParsedUrlQuery);

  const createBillingInformation = useCallApi(BillingInformationService.createBillingInformation);

  const redirectBack = useCallback(() => {
    if (isInitial) {
      push(settingsRoutes.billingInformation);
    } else {
      back();
    }
  }, [isInitial]);

  const createBillingInfo = async (): Promise<void> => {
    setIsCreating(true);
    const billing = dataRef.current;

    if (
      billing?.contactInformation &&
      // eslint-disable-next-line no-prototype-builtins
      !billing?.contactInformation?.hasOwnProperty('selfEmployedInformation')
    ) {
      billing.contactInformation.selfEmployedInformation = null;
    }
    if (
      billing?.contactInformation &&
      // eslint-disable-next-line no-prototype-builtins
      !billing.contactInformation.hasOwnProperty('fileNumber')
    ) {
      billing.contactInformation.fileNumber = null;
    }
    if (
      billing?.contactInformation?.address &&
      // eslint-disable-next-line no-prototype-builtins
      !billing.contactInformation.address.hasOwnProperty('state')
    ) {
      billing.contactInformation.address.state = null;
    }

    await createBillingInformation({
      requestBody: billing,
    })
      .then(() => {
        showNotification.success(i18n.t('entity.customer.labels.billingInformationCreated'));

        redirectBack();

        setIsCreating(false);
      })
      .catch((error) => {
        showNotification.error(error.response?.data?.error?.message || error.toString());
        setIsCreating(false);
      });
  };

  if (isFeatureEnabled(featureFlags.TENANT_V2)) {
    return <BillingInformationDetailV2 />;
  }

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.customer.actions.addNewBillingInformation'),
        breadcrumbs: [breadcrumb],
        actions: [
          {
            type: 'button',
            title: i18n.t('general.actions.back'),
            onClick: redirectBack,
            isDisabled: isCreating,
            variant: 'secondary',
          },
          {
            type: 'button',
            title: i18n.t('entity.customer.actions.createBillingInformation'),
            onClick: createBillingInfo,
            isDisabled: isCreating || !isValid,
            variant: 'primary',
            leftIcon: 'content/add',
          },
        ],
      }}
      data-testid="billing-information-detail-settings-modal"
    >
      <ValidationCheckerProvider onChange={setIsValid}>
        <BillingInformationDetail
          setData={(data) => {
            dataRef.current = data;
          }}
        />
      </ValidationCheckerProvider>
    </SettingsTemplate>
  );
}
