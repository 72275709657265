import {isFeatureEnabled} from 'feature-flags';
import {Action, Card} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space} from 'platform/foundation';

import {FC, useCallback, useEffect, useState} from 'react';

import {isNil} from 'ramda';

import {
  EntityResourceIds,
  NewOfferPurchaseVehicleResponseBody,
  PatchBuyingOfferPurchaseVehicleRequestBody,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  usePatchBuyingOfferPurchaseVehicleMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CommentsWidget, handleApiError, usePermissions} from '@dms/shared';
import {WithValidationErrors} from '@dms/teas';

import {buildArray, useRequiredParams} from 'shared';

import {BusinessCasePurchaseVehicleBuyingDetail} from './BusinessCasePurchaseVehicleBuyingDetail';
import {BusinessCasePurchaseVehicleBuyingForm} from './BusinessCasePurchaseVehicleBuyingForm';

type BuyingTabProps = {
  purchaseVehicle: NewOfferPurchaseVehicleResponseBody;
};

export const BusinessCasePurchaseVehicleBuying: FC<BuyingTabProps> = ({purchaseVehicle}) => {
  const isBusinessCaseSimplifiedPricingEnabled = isFeatureEnabled(
    featureFlags.BUSINESS_CASE_PRICING_SIMPLIFIED
  );

  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const [patchBuyingVehicle] = usePatchBuyingOfferPurchaseVehicleMutation();

  const editable = businessCase?.actions?.purchaseEditVehicle;
  const offer = businessCase?.offers?.[0];

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: purchaseVehicle?.id ?? '',
    },
    {skip: isNil(purchaseVehicle?.id)}
  );

  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
  ] = usePermissions({
    permissionKeys: ['vehicleCommentRead', 'vehicleCommentAdd', 'vehicleCommentDelete'],
    scopes: {
      vehicleCommentAdd: {participation: vehicleParticipation},
      vehicleCommentRead: {participation: vehicleParticipation},
    },
  });

  const maxBuyingPrice = purchaseVehicle?.pricing?.vatDeductible
    ? purchaseVehicle?.pricing?.maxBuyingPrice?.priceWithVat
    : purchaseVehicle?.pricing?.maxBuyingPrice?.priceNotDeductible;

  const [isDetail, setDetail] = useState(
    (purchaseVehicle?.buying.confirmed && editable) || !editable
  );

  const [showDiscard, setShowDiscard] = useState(!!purchaseVehicle?.buying?.confirmed);
  const toggleDetail = useCallback(() => setDetail(!isDetail), [isDetail]);

  const title =
    isBusinessCaseSimplifiedPricingEnabled && !isDetail
      ? undefined
      : i18n.t('entity.businessCase.labels.buying');

  const handleSubmit = useCallback(
    async (
      values: PatchBuyingOfferPurchaseVehicleRequestBody
    ): Promise<WithValidationErrors<void>> => {
      if (isNil(offer?.id)) {
        return;
      }

      await patchBuyingVehicle({
        offerId: offer.id,
        patchBuyingOfferPurchaseVehicleRequestBody: values,
        businessCaseId,
        offerPurchaseVehicleId: purchaseVehicle?.id as string,
      })
        .unwrap()
        .catch(handleApiError);

      toggleDetail();
      setShowDiscard(true);
    },
    [offer?.id, patchBuyingVehicle, businessCaseId, purchaseVehicle?.id, toggleDetail]
  );

  useEffect(() => {
    setDetail((purchaseVehicle?.buying.confirmed && editable) || !editable);
  }, [purchaseVehicle?.buying.confirmed, editable]);

  return (
    <Grid columns={1}>
      <GridItem>
        <Grid columns={2}>
          <GridItem>
            <Card
              title={title}
              actions={buildArray<Action>().when(isDetail && editable, {
                type: 'button',
                variant: 'link',
                leftIcon: 'image/edit',
                onClick: toggleDetail,
                title: i18n.t('general.actions.edit'),
                'data-testid': testIds.businessCase.buying('buying-edit'),
              })}
              data-testid={testIds.businessCase.buying('buying')}
              variant="inlineWhite"
            >
              {purchaseVehicle && (
                <>
                  {isDetail ? (
                    <BusinessCasePurchaseVehicleBuyingDetail
                      values={purchaseVehicle.buying}
                      maxBuyingPrice={maxBuyingPrice ?? undefined}
                    />
                  ) : (
                    <>
                      <Show when={isBusinessCaseSimplifiedPricingEnabled}>
                        <Space vertical={4} />
                      </Show>
                      <BusinessCasePurchaseVehicleBuyingForm
                        maxBuyingPrice={purchaseVehicle.pricing?.maxBuyingPrice ?? undefined}
                        onSubmit={handleSubmit}
                        onDiscard={toggleDetail}
                        showDiscard={showDiscard}
                        initialValues={purchaseVehicle.buying}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </GridItem>
          <Hide when={isNil(purchaseVehicle?.vehicleId) || !hasVehicleCommentReadPermission}>
            <CommentsWidget
              title={i18n.t('entity.vehicle.labels.vehicleComments')}
              resourceId={EntityResourceIds.vehicle}
              recordId={purchaseVehicle.vehicleId}
              isAddDisabled={!hasVehicleCommentAddPermission}
              isDeleteDisabled={!hasVehicleCommentDeletePermission}
            />
          </Hide>
        </Grid>
      </GridItem>
    </Grid>
  );
};
