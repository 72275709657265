import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {
  useCancelCorrectiveTaxDocumentMutation,
  useCancelInvoiceMutation,
  useCancelInvoiceProformaMutation,
} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {Nullish} from 'shared';

import {InvoiceTypes} from '../pages/InvoiceOverview/types';

export const useCancelDocument = () => {
  const [
    cancelProformaDocument,
    {isLoading: loadingProformaDocument, isError: isProformaDocumentError},
  ] = useCancelInvoiceProformaMutation();
  const [
    cancelInvoiceDocument,
    {isLoading: loadingInvoiceDocument, isError: isInvoiceDocumentError},
  ] = useCancelInvoiceMutation();
  const [
    cancelCorrectiveTaxDocument,
    {isLoading: loadingCorrectiveTaxDocument, isError: isCorrectiveTaxDocumentError},
  ] = useCancelCorrectiveTaxDocumentMutation();

  const isLoading =
    loadingProformaDocument || loadingInvoiceDocument || loadingCorrectiveTaxDocument;

  const isError = isProformaDocumentError || isInvoiceDocumentError || isCorrectiveTaxDocumentError;

  const cancelDocument = async (invoiceType: InvoiceTypes | Nullish, documentId: string) => {
    if (isNil(invoiceType)) {
      showNotification.error();
      return;
    }

    const downloadAction = match(invoiceType)
      .with(
        'PROFORMA',
        always(
          cancelProformaDocument({
            invoiceProformaId: documentId,
          })
        )
      )
      .with(
        'CORRECTIVE_TAX_DOCUMENT',
        always(
          cancelCorrectiveTaxDocument({
            correctiveTaxDocumentId: documentId,
          })
        )
      )
      .otherwise(always(cancelInvoiceDocument({invoiceId: documentId})));

    await downloadAction
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  return [cancelDocument, {isLoading, isError}] as const;
};
