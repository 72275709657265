import {isFeatureEnabled} from 'feature-flags';
import {Button, DataStatus, FormControl, FormField, IconButton} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn, useFieldArray} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {defaultTo, isNotNil} from 'ramda';
import {isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  selectActiveBranchId,
  useGetBranchQuery,
  useGetEmployeeCostCentersListQuery,
  useGetEmployeeMechanicsQuery,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {EditLabourItemForm} from '../../../../types/EditLabourItemForm';
import {getOptionsFromActiveCostCenters} from '../../../../utils/getOptionsFromActiveCostCenters';
import {getOptionsFromMechanics} from '../../../../utils/getOptionsFromMechanics';

export interface EditLabourItemMechanicsProps extends RequiredTestIdProps {
  control: FormControl<EditLabourItemForm>;
  formApi: UseFormReturn<EditLabourItemForm>;
  isEditingDisabled?: boolean;
  authorizationProfileId?: string;
}

export function RequestTabLabourItemMechanics(props: EditLabourItemMechanicsProps) {
  const branchId = useSelector(selectActiveBranchId);
  const {data: branchDetail} = useGetBranchQuery({branchId});

  const {
    data: mechanics,
    isLoading: areMechanicsLoading,
    isError: hasMechanicsError,
  } = useGetEmployeeMechanicsQuery(
    {
      authorizationProfileId: props.authorizationProfileId as string,
    },
    {
      skip:
        isNilOrEmpty(props.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const {
    data: costCenters,
    isLoading: areCostCentersLoading,
    isError: hasCostCentersError,
  } = useGetEmployeeCostCentersListQuery(
    {
      company: branchDetail?.billingInformation?.id as string,
      branch: branchDetail?.id as string,
    },
    {
      skip:
        isNilOrEmpty(branchDetail?.billingInformation?.id) ||
        isNilOrEmpty(branchDetail?.id) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const {fields, append, remove} = useFieldArray({control: props.control, name: 'assignMechanics'});

  useEffect(() => {
    if (fields.length === 1) {
      const totalQuantity = props.formApi.getValues('quantity');

      props.formApi.setValue('assignMechanics.0.ratio', TOTAL_RATIO);
      props.formApi.setValue('assignMechanics.0.amount', totalQuantity);
    }
  }, [fields.length, props.formApi]);

  const handleMechanicChange = (index: number) => (value: string | number | string[] | null) => {
    if (!isString(value)) {
      return;
    }

    const selectedMechanic = mechanics?.employees?.find((mechanic) => mechanic?.id === value);

    props.formApi.setValue(
      `assignMechanics.${index}.costCenterId`,
      defaultTo(null, selectedMechanic?.costCenterId)
    );
  };

  const handleQuantityChange = (index: number) => (value: number | null) => {
    const totalQuantity = props.formApi.getValues('quantity');

    props.formApi.setValue(
      `assignMechanics.${index}.ratio`,
      isNotNil(value) ? Math.round((value / totalQuantity) * TOTAL_RATIO) : 0
    );
  };

  const handleRatioChange = (index: number) => (value: number | null) => {
    const totalQuantity = props.formApi.getValues('quantity');

    props.formApi.setValue(
      `assignMechanics.${index}.amount`,
      isNotNil(value) ? Math.round(value * totalQuantity) / TOTAL_RATIO : 0
    );
  };

  return (
    <DataStatus isError={hasMechanicsError || hasCostCentersError}>
      <VStack spacing={4} align="flex-start" data-testid={props['data-testid']}>
        {fields.map(({id}, index) => (
          <VStack key={id} spacing={4} width="100%">
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  name={`assignMechanics.${index}.id`}
                  type="choice"
                  control={props.control}
                  label={
                    isFirstItem(index) ? i18n.t('entity.warehouse.labels.mechanic') : undefined
                  }
                  options={getOptionsFromMechanics(mechanics)}
                  onChange={handleMechanicChange(index)}
                  isDisabled={props.isEditingDisabled}
                  isLoading={areMechanicsLoading}
                  menuInPortal
                  data-testid={suffixTestId(`inputs.mechanic-[${index}]`, props)}
                />
              </Box>

              <Box flex={1}>
                <HStack spacing={4}>
                  <Box flex={2}>
                    <FormField
                      name={`assignMechanics.${index}.costCenterId`}
                      type="choice"
                      control={props.control}
                      label={
                        isFirstItem(index)
                          ? i18n.t('entity.warehouse.labels.costCenter')
                          : undefined
                      }
                      options={getOptionsFromActiveCostCenters(costCenters)}
                      isDisabled={props.isEditingDisabled}
                      isLoading={areCostCentersLoading}
                      menuInPortal
                      data-testid={suffixTestId(`inputs.costCenter-[${index}]`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      name={`assignMechanics.${index}.amount`}
                      type="number"
                      control={props.control}
                      label={
                        isFirstItem(index) ? i18n.t('entity.warehouse.labels.quantity') : undefined
                      }
                      onChange={handleQuantityChange(index)}
                      isDisabled={fields.length === 1 || props.isEditingDisabled}
                      suffix="h"
                      decimalPlaces={2}
                      data-testid={suffixTestId(`inputs.quantity-[${index}]`, props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      name={`assignMechanics.${index}.ratio`}
                      type="integer"
                      control={props.control}
                      label={
                        isFirstItem(index) ? i18n.t('entity.warehouse.labels.ratio') : undefined
                      }
                      onChange={handleRatioChange(index)}
                      isDisabled={fields.length === 1 || props.isEditingDisabled}
                      suffix="%"
                      data-testid={suffixTestId(`inputs.ratio-[${index}]`, props)}
                    />
                  </Box>
                  <Show when={fields.length > 1}>
                    <VStack>
                      <Space vertical={isFirstItem(index) ? 5 : 0} />
                      <IconButton
                        icon="content/clear"
                        priority="tertiary"
                        severity="danger"
                        onClick={() => remove(index)}
                        isDisabled={props.isEditingDisabled}
                        data-testid={suffixTestId(`remove-[${index}]`, props)}
                      />
                    </VStack>
                  </Show>
                </HStack>
              </Box>
            </HStack>
          </VStack>
        ))}

        <Button
          variant="link"
          title={i18n.t('entity.warehouse.actions.addMechanic')}
          leftIcon="content/add_circle"
          onClick={() => append(EMPTY_MECHANIC)}
          isDisabled={props.isEditingDisabled}
          data-testid={suffixTestId('add', props)}
        />
      </VStack>
    </DataStatus>
  );
}

const isFirstItem = (index: number) => index === 0;

const TOTAL_RATIO = 100;
const EMPTY_MECHANIC = {id: null, costCenterId: null, amount: 0, ratio: 0};
