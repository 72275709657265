import {Grid, Heading, Space} from 'platform/foundation';

import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {MainCategoryProps} from '../../../types/ConditionTypes';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {getTranslation} from '../utils/getTranslation';
import {AuditFormField} from './AuditFormField';
import {CategoryGeneralComment} from './CategoryGeneralComment';
import {SecondWheelsSet} from './SecondWheelsSet';
import {WheelsSet} from './WheelsSet';

const firstWheelsSetCategoryKeys: string[] = [
  AuditCategoryUniqueKey.LF_WHEEL,
  AuditCategoryUniqueKey.LR_WHEEL,
  AuditCategoryUniqueKey.RR_WHEEL,
  AuditCategoryUniqueKey.RF_WHEEL,
];

const firstSetCategoryKey = 'firstSet';

export const WheelsAndTires: FC<MainCategoryProps & TestIdProps> = ({
  data: categoryStructure,
  ...props
}) => {
  const locale = useSelector(selectUserSelectedLanguage);

  const {firstSet, secondSet, firstSetAdditional, rest} = useMemo(() => {
    const categories: {
      firstSet: AuditCategoryOfStructure[];
      firstSetAdditional: AuditCategoryOfStructure[];
      secondSet?: AuditCategoryOfStructure;
      rest: AuditCategoryOfStructure[];
    } = {
      firstSet: [],
      firstSetAdditional: [],
      rest: [],
    };

    if (!categoryStructure.childCategories) {
      return categories;
    }

    for (const category of categoryStructure.childCategories) {
      if (firstWheelsSetCategoryKeys.includes(category.uniqueKey)) {
        categories.firstSet.push(category);
        continue;
      }

      if (category.uniqueKey === AuditCategoryUniqueKey.THE_SECOND_SET) {
        categories.secondSet = category;
        continue;
      }

      if (
        category.uniqueKey ===
        AuditCategoryUniqueKey.THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY
      ) {
        categories.firstSetAdditional?.push(category);
        continue;
      }

      categories.rest?.push(category);
    }

    return categories;
  }, [categoryStructure]);

  return (
    <>
      {firstSet && (
        <>
          <Heading size={3} data-testid={props['data-testid']}>
            {i18n.t('entity.condition.labels.1stSet')}
          </Heading>
          <Space vertical={4} />
          {firstSetAdditional?.map((category) =>
            category.paramDefinitions?.relatedActions?.map((paramDefinition) => (
              <Grid columns={1} verticalSpacing={0} key={paramDefinition.id}>
                <AuditFormField
                  paramDefinition={paramDefinition}
                  categoryId={category.id}
                  label={getTranslation(locale, category.name)}
                  uniqueKey={category.uniqueKey}
                  data-testid={suffixTestId(`firstSetAdditional-${paramDefinition.id}`, props)}
                />
              </Grid>
            ))
          )}
          <Space vertical={6} />
          <WheelsSet
            data-testid={props['data-testid']}
            categories={firstSet}
            categoryKey={firstSetCategoryKey}
          />
        </>
      )}

      {Boolean(rest.length) && (
        <>
          <Space vertical={6} />
          {rest.map((category, index) =>
            category.paramDefinitions?.relatedActions?.map((paramDefinition) => (
              <Grid columns={1} verticalSpacing={0} key={paramDefinition.id}>
                <AuditFormField
                  paramDefinition={paramDefinition}
                  categoryId={category.id}
                  label={getTranslation(locale, category.name)}
                  uniqueKey={category.uniqueKey}
                  data-testid={suffixTestId(`rest-${paramDefinition.id}`, props)}
                />
              </Grid>
            ))
          )}
        </>
      )}

      <SecondWheelsSet category={secondSet} />

      <CategoryGeneralComment category={categoryStructure} />
    </>
  );
};
