import {defaultTo} from 'ramda';

import {CustomerRequestBody, PhoneNumberData, CustomerResponseBodyV2, PhoneNumber} from '@dms/api';

import {
  emptyPersonData,
  emptyEmail,
  emptyIdentityCard,
  emptyBusinessInfoData,
} from '../constants/emptyValues';

export const getCustomerDefaultValues = (
  customer?: CustomerResponseBodyV2,
  tenantPhoneInfo?: PhoneNumber
): CustomerRequestBody | null => {
  if (!customer) {
    return null;
  }

  const phoneNumbers = customer.foundingPerson?.phoneNumbers;

  const identityCards = customer.foundingPerson?.identityCards.map((i) => ({
    id: i.id,
    cardData: i,
  }));

  const emails = customer?.foundingPerson?.emails;

  return {
    customerGroups: customer?.customerGroups,
    customerData: {
      customerDiscriminator: customer?.discriminator,
      customerSource: customer?.source,
    },
    foundingPerson: {
      personData: customer.foundingPerson ?? emptyPersonData,
      phoneNumbers: phoneNumbers?.length
        ? phoneNumbers.map((n) => ({
            type: n.type,
            phoneNumber: {countryCode: n.countryCode, prefix: n.prefix, number: n.number},
          }))
        : ([
            {
              type: null,
              phoneNumber: {
                prefix: defaultTo('+420', tenantPhoneInfo?.prefix),
                number: '',
                countryCode: defaultTo('CZE', tenantPhoneInfo?.countryCode),
              },
            },
          ] as PhoneNumberData[]),
      emails: emails?.length ? emails : [emptyEmail],
      identityCards: identityCards?.length ? identityCards : [emptyIdentityCard],
      permanentAddressId: customer.foundingPerson?.permanentAddress?.id ?? null,
    },
    businessInfo: {
      businessInfoData: customer.businessInfo?.businessInfo ?? emptyBusinessInfoData,
      businessAddressId: customer.businessInfo?.address?.id ?? null,
    },
    selfEmployed: customer.selfEmployed ?? false,
    selfEmployedBusinessInfoData: customer.selfEmployedBusinessInfoData ?? null,
    selfEmployedBusinessAddressId: customer.selfEmployedBusinessAddress?.id,
    institutions: customer.institutions,
  };
};
