import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {GetInvoiceV4ApiResponse} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getPaymentStateFlagProps = (
  paymentState: GetInvoiceV4ApiResponse['paymentState'] | Nullish
): BaseFlagProps | null => {
  const flagProps = match<typeof paymentState, BaseFlagProps | null>(paymentState)
    .with(
      'draft',
      always({colorScheme: 'neutral', label: i18n.t('entity.accounting.labels.draft')})
    )
    .with(
      'confirmed',
      always({colorScheme: 'neutral', label: i18n.t('entity.accounting.labels.confirmed')})
    )
    .with(
      'canceled',
      always({colorScheme: 'red', label: i18n.t('entity.accounting.labels.canceled')})
    )
    .with(
      'partially_paid',
      always({colorScheme: 'orange', label: i18n.t('entity.accounting.labels.partiallyPaid')})
    )
    .with(
      'overdue',
      always({colorScheme: 'orange', label: i18n.t('entity.accounting.labels.overdue')})
    )
    .with(
      'overpayment',
      always({colorScheme: 'orange', label: i18n.t('entity.accounting.labels.overpayment')})
    )
    .with(
      'completed',
      always({colorScheme: 'green', label: i18n.t('entity.accounting.labels.completed')})
    )
    .with(
      'reopened',
      always({colorScheme: 'purple', label: i18n.t('entity.accounting.labels.reopened')})
    )
    .otherwise(always(null));

  if (isNil(flagProps)) {
    return null;
  }

  return {...flagProps, isSubtle: true};
};
