import {
  Action,
  Attributes,
  AttributesRow,
  Card,
  closeCurrentDialog,
  DataStatus,
  Flag,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Box, Link, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {defaultTo, equals, isNil, isNotNil, mergeAll, not, path} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  GetReceiveNoteResponse,
  useGetAuthorizationProfilesQuery,
  useGetCurrenciesQuery,
  useGetDeliveryNoteQuery,
  useGetPaymentTypeEnumQuery,
  useGetReceiveNoteItemsTotalPriceQuery,
  useGetSuppliersQuery,
  useGetTenantQuery,
  useGetWarehouseAccountQuery,
  useGetWarehouseQuery,
  useGetWarehousesQuery,
  usePostReceiveNoteItemsDeleteMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  catchUnhandledDataGridActions,
  DEFAULT_CURRENCY,
  handleApiError,
  Section,
} from '@dms/shared';

import {
  buildArray,
  composePath,
  EMPTY_PLACEHOLDER,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  useNavigate,
} from 'shared';

import {
  ActionCallback,
  ActionCallbackParams,
  DataGrid,
  QueryFilterObject,
  useRefreshDataGrid,
} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../components/OverviewTotalPrice';
import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../constants/missingEditingDetailsMessage';
import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {getSupplierById} from '../../../../utils/getSupplierById';
import {getWarehouseById} from '../../../../utils/getWarehouseById';
import {AddItemForm} from './components/AddItemForm';
import {BasicInformationForm} from './components/BasicInformationForm';
import {BasicInformationReadOnly} from './components/BasicInformationReadOnly';
import {ADD_RECEIVE_NOTE_ITEM_DIALOG} from './constants/addReceiveNoteItemDialog';
import {ReceiveNoteItemData} from './types/ReceiveNoteItemData';

interface OverviewProps extends RequiredTestIdProps {
  receiveNote?: GetReceiveNoteResponse;
  isReceiveNoteLoading: boolean;
  hasReceiveNoteError: boolean;
}

export function Overview(props: OverviewProps) {
  const navigate = useNavigate();

  const {receiveNoteId} = useWarehouseParams();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const dateTimeFormatter = useDateTimeFormatter();

  const formatCurrency = useFormatCurrency();

  const [isReadOnly, setIsReadOnly] = useState(isNotNilOrEmpty(receiveNoteId));

  const isReceiveNoteCompleted = props.receiveNote?.state === 'COMPLETED';

  const {data: totalPrice} = useGetReceiveNoteItemsTotalPriceQuery(
    {receiveNoteId},
    {skip: isNilOrEmpty(receiveNoteId)}
  );

  const {data: warehouse} = useGetWarehouseQuery(
    {warehouseId: props.receiveNote?.warehouseId as string},
    {skip: isNilOrEmpty(props.receiveNote)}
  );

  const {
    data: warehouseAccount,
    isLoading: isWarehouseAccountLoading,
    isError: isWarehouseAccountError,
  } = useGetWarehouseAccountQuery(
    {warehouseAccountId: warehouse?.defaultAccountId as string},
    {skip: isNilOrEmpty(warehouse)}
  );

  const {
    data: deliveryNote,
    isLoading: isDeliveryNoteLoading,
    isError: isDeliveryNoteError,
  } = useGetDeliveryNoteQuery(
    {deliveryNoteId: props.receiveNote?.deliveryNoteId as string},
    {skip: isNilOrEmpty(props.receiveNote)}
  );

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: paymentTypes,
    isLoading: isPaymentTypesLoading,
    isError: isPaymentTypesError,
  } = useGetPaymentTypeEnumQuery();

  const {
    data: currencies,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetCurrenciesQuery();

  const {data: tenant, isLoading: isTenantLoading, isError: isTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: areAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const [postReceiveNoteItemsDelete] = usePostReceiveNoteItemsDeleteMutation();

  const isDraftReceiveNote = isNilOrEmpty(receiveNoteId);

  const isNewReceiveNoteDetailDataLoaded = !warehouses || !paymentTypes || !currencies || !tenant;

  const isEditReceiveNoteDetailDataLoaded =
    isNewReceiveNoteDetailDataLoaded || !props.receiveNote || !warehouseAccount;

  const isNewReceiveNoteDetailDataLoading =
    isWarehousesLoading ||
    areSuppliersLoading ||
    isPaymentTypesLoading ||
    isCurrenciesLoading ||
    isTenantLoading ||
    props.isReceiveNoteLoading ||
    isDeliveryNoteLoading;

  const isEditReceiveNoteDetailDataLoading =
    isNewReceiveNoteDetailDataLoading ||
    props.isReceiveNoteLoading ||
    isWarehouseAccountLoading ||
    areAuthorizationProfilesLoading;

  const isNewReceiveNoteDetailDataError =
    isWarehousesError ||
    hasSuppliersError ||
    isPaymentTypesError ||
    isCurrenciesError ||
    isTenantError ||
    props.hasReceiveNoteError ||
    isDeliveryNoteError;

  const isEditReceiveNoteDetailDataError =
    isNewReceiveNoteDetailDataError ||
    props.hasReceiveNoteError ||
    isWarehouseAccountError ||
    hasAuthorizationProfilesError;

  const isFromReceiveNote = defaultTo(false, deliveryNote?.isFromReceiveNote);

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {receiveNoteId}]),
    [receiveNoteId]
  );

  if (receiveNoteId ? isEditReceiveNoteDetailDataLoaded : isNewReceiveNoteDetailDataLoaded) {
    return (
      <DataStatus
        isLoading={
          receiveNoteId ? isEditReceiveNoteDetailDataLoading : isNewReceiveNoteDetailDataLoading
        }
        isError={receiveNoteId ? isEditReceiveNoteDetailDataError : isNewReceiveNoteDetailDataError}
        minHeight="100vh"
        data-testid={suffixTestId('status', props)}
      />
    );
  }

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  const isReceiveNotePending = props.receiveNote?.state === 'PENDING';

  const supplierByReceiveNote = getSupplierById(suppliers ?? [], props.receiveNote?.supplierId);

  const warehouseByReceiveNote = getWarehouseById(warehouses, props.receiveNote?.warehouseId);

  const paymentTypeByReceiveNote = paymentTypes?.find((paymentType) =>
    equals(paymentType.key, props.receiveNote?.paymentType)
  );

  const currencyByWarehouse = currencies?.find((currency) =>
    equals(currency.code, warehouse?.currency)
  );

  const authorizationProfileByReceiveNote = authorizationProfiles?.find((authorizationProfile) =>
    equals(authorizationProfile.id, props.receiveNote?.authorizationProfileId)
  );

  const basicInformationCardActions = buildArray<Action>().when(
    isNotNilOrEmpty(receiveNoteId) && isReadOnly,
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'image/edit',
      title: i18n.t('general.labels.edit'),
      isDisabled: not(isReceiveNotePending),
      onClick: () => setIsReadOnly(false),
    }
  );

  const deliveryNoteAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: isNotNil(deliveryNote?.deliveryNoteNumber) ? (
        <Link
          title={deliveryNote?.deliveryNoteNumber}
          rightIcon="action/open_in_new"
          size="small"
          onClick={() =>
            window.open(
              composePath(warehouseRoutes.deliveryNoteDetailOverview, {
                params: {id: deliveryNote?.deliveryNoteId},
              }),
              '_blank'
            )
          }
          data-testid={suffixTestId('deliveryNoteLink', props)}
        />
      ) : (
        EMPTY_PLACEHOLDER
      ),
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: warehouse?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.supplier'),
      value: supplierByReceiveNote?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.issuedDate'),
      value: formatToShortDate(deliveryNote?.issuedAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.createdDate'),
      value: formatToShortDate(deliveryNote?.created),
    },
    {
      label: i18n.t('entity.warehouse.labels.createdFromReceiveNote'),
      content: (
        <Flag
          isSubtle
          colorScheme="blue"
          label={isFromReceiveNote ? i18n.t('general.labels.yes') : i18n.t('general.labels.no')}
          size="small"
          data-testid={suffixTestId('isFromReceiveNoteFlag', props)}
        />
      ),
    },
  ];

  const listOfItemsCardActions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/add_circle',
      title: i18n.t('general.labels.add'),
      onClick: () => handleAddItem(null, refreshDataGrid),
      isDisabled: not(isReceiveNotePending) || not(isFromReceiveNote),
      'data-testid': suffixTestId('actions.add', props),
    },
  ];

  const totalPriceWithoutVat = formatCurrency(
    totalPrice?.totalPriceWithoutVat ?? 0,
    warehouse?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const totalPriceWithVat = formatCurrency(
    totalPrice?.totalPriceWithVat ?? 0,
    warehouse?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const handleAddItem = (
    receiveNoteItemData: ReceiveNoteItemData | Nullish,
    refreshDatagrid: () => void,
    isEditing?: boolean
  ) => {
    openDialog(
      <AddItemForm
        receiveNoteId={receiveNoteId}
        warehouseId={props.receiveNote?.warehouseId}
        supplierId={props.receiveNote?.supplierId}
        receiveNoteItemData={receiveNoteItemData}
        warehouse={warehouseByReceiveNote}
        warehouseAccount={warehouseAccount}
        warehouseDetail={warehouse}
        tenant={tenant}
        refreshDatagrid={refreshDatagrid}
        isEditing={isEditing}
        state={props.receiveNote?.state}
        data-testid={suffixTestId('forms.addItem', props)}
      />,
      {
        id: ADD_RECEIVE_NOTE_ITEM_DIALOG,
        title: isEditing
          ? i18n.t('general.labels.edit')
          : i18n.t('entity.warehouse.labels.addReceiveNoteItem'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': suffixTestId('dialogs.addItem', props),
      }
    );
  };

  const handleDeleteReceiveNoteItem = (
    rowDataIds: string[],
    name: string,
    refreshData: () => void
  ) => {
    openDeleteDialog({
      name,
      onConfirm: async () => {
        await postReceiveNoteItemsDelete({
          receiveNoteId,
          body: {
            receiveNoteItemId: rowDataIds,
          },
        })
          .unwrap()
          .then(() => {
            refreshData();
            showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
            closeCurrentDialog();
          })
          .catch(handleApiError);
      },
    });
  };

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData, rowId}) => {
    const isBulk = isArray(rowData);
    const getName = (rowData: ActionCallbackParams['rowData']) =>
      path(['name', 'value'], rowData) as string;
    const receiveNoteItemId = path(['id'], rowData) as string;
    const articleId = path(['articleId', 'value'], rowData) as string;
    const name = isBulk
      ? (rowData.map((data: ActionCallbackParams['rowData']) => getName(data)).join() as string)
      : (path(['name', 'value'], rowData) as string);
    const amount = path(['quantity', 'value'], rowData) as number;

    if (!isBulk && (isNil(receiveNoteItemId) || isNil(articleId) || isNil(name) || isNil(amount))) {
      throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
    }

    const receiveNoteItemData: ReceiveNoteItemData = {
      receiveNoteItemId,
      articleId,
      amount,
    };

    match(actionKey)
      .with('redirectDetail', () => {
        navigate(
          composePath(warehouseRoutes.articleDetailOverview, {
            params: {
              warehouseId: props.receiveNote?.warehouseId,
              id: articleId,
            },
          })
        );
      })
      .with('edit', () => {
        handleAddItem(receiveNoteItemData, refreshData, true);
      })
      .with('delete', () => {
        handleDeleteReceiveNoteItem(rowId as string[], name, refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <Section data-testid={testIds.warehouse.receiveNoteCreate('wrapper')}>
      <Box>
        <VStack spacing={4}>
          <Card
            title={i18n.t('general.labels.basicInformation')}
            actions={basicInformationCardActions}
            data-testid={suffixTestId('cards.basicInformation', props)}
          >
            {isReadOnly ? (
              <BasicInformationReadOnly
                readOnlyFields={{
                  supplier: supplierByReceiveNote?.name,
                  warehouse: warehouseByReceiveNote?.name,
                  authorizationProfile: authorizationProfileByReceiveNote?.name,
                  number: props.receiveNote?.receiveNoteNumber,
                  date: isReceiveNoteCompleted ? props.receiveNote?.updated : null,
                  invoiceNumber: props.receiveNote?.invoiceNumber,
                  invoiceIssueDate: props.receiveNote?.invoiceIssueDate,
                  paymentType: paymentTypeByReceiveNote?.value,
                  currency: currencyByWarehouse?.code,
                  exchangeRate: props.receiveNote?.exchangeRate,
                  ncConversion: props.receiveNote?.ncConversion,
                  note: props.receiveNote?.note,
                }}
              />
            ) : (
              <BasicInformationForm
                receiveNote={props.receiveNote}
                suppliers={suppliers}
                warehouses={warehouses}
                authorizationProfiles={authorizationProfiles}
                paymentTypes={paymentTypes}
                currencies={currencies}
                currencyCodeByWarehouse={currencyByWarehouse?.code}
                onClose={() => setIsReadOnly(true)}
                data-testid={suffixTestId('forms.basicInformation', props)}
              />
            )}
          </Card>

          <Card
            title={i18n.t('entity.warehouse.labels.deliveryNote')}
            data-testid={suffixTestId('cards.deliveryNote', props)}
          >
            <DataStatus isEmpty={isDraftReceiveNote} minHeight={60}>
              <Box height={60}>
                <Card variant="inlineGrey">
                  <Attributes
                    rows={deliveryNoteAttributes}
                    size="quarter"
                    data-testid={suffixTestId('deliveryNoteAttributes', props)}
                  />
                </Card>
              </Box>
            </DataStatus>
          </Card>

          <Card
            title={i18n.t('general.labels.listOfItems')}
            actions={listOfItemsCardActions}
            data-testid={suffixTestId('cards.items', props)}
          >
            <DataStatus isEmpty={isDraftReceiveNote} minHeight={60}>
              <DataGrid
                ref={dataGridRef}
                gridCode="receive-note-item"
                actionCallback={actionCallback}
                queryModifier={queryModifier}
                autoHeight
                data-testid={suffixTestId('datagrid.receiveNoteItems', props)}
              />
              <OverviewTotalprice
                totalPriceWithoutVat={totalPriceWithoutVat}
                totalPriceWithVat={totalPriceWithVat}
                data-testid={suffixTestId('totalPrice', props)}
              />
            </DataStatus>
          </Card>
        </VStack>
      </Box>
    </Section>
  );
}
