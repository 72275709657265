import {useContext} from 'react';

import {VehicleWarehouseContext} from './context/VehicleWarehouseContext';

export const useVehicleWarehouseContext = () => {
  const context = useContext(VehicleWarehouseContext);

  if (!context) {
    throw new Error('useVehicleWarehouseContext must be used within a VehicleWarehouseProvider');
  }

  return context;
};
