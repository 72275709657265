import {Form, FormField, FormSubmitHandler, Separator} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {array, number, object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil, isPositive} from 'ramda-adjunct';

import {
  useGetAuthorizationProfilesQuery,
  useGetDictionaryQuery,
  useGetLabourRateQuery,
  useGetTenantQuery,
  useGetVehicleGroupsQuery,
  usePatchLabourRateMutation,
  usePostLabourRateMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useWorkTypeOptions} from '@dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {LabourRateType} from './types/LabourRateType';

export function LabourRatesDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data: tenant} = useGetTenantQuery();
  const {
    data: labourRate,
    isLoading: isLabourRateLoading,
    isError: isLabourRateError,
  } = useGetLabourRateQuery({labourRateId: id ?? ''}, {skip: isNil(id)});
  const {data: rateTypes, isLoading: isRateTypesLoading} = useGetDictionaryQuery({
    category: 'SVCRATETYPE',
  });
  const {data: vehicleGroups, isLoading: isVehicleGroupsLoading} = useGetVehicleGroupsQuery({});
  const {data: authorizationProfiles, isLoading: isAuthorizationProfilesLoading} =
    useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});
  const [postLabourRate] = usePostLabourRateMutation();
  const [patchLabourRate] = usePatchLabourRateMutation();

  const {getOptionsWithSelectedValues, isLoading: isWorkTypesLoading} = useWorkTypeOptions();

  const handleSubmit: FormSubmitHandler<LabourRateType> = async (data) => {
    const body = {
      ...data,
      name: data.name ?? '',
      rateTypes: data.rateTypes?.filter(isNotNil) ?? [],
      rateValueWithoutVat: data.rateValueWithoutVat ?? 0,
      authorizationProfiles: data.authorizationProfiles?.filter(isNotNil) ?? [],
      currency: tenant?.currency ?? '',
      workTypes: data.workTypes?.filter(isNotNil) ?? [],
    };

    if (isNil(id)) {
      return await postLabourRate({body})
        .unwrap()
        .then(() => navigate(settingsRoutes.labourRates))
        .catch(handleApiError);
    }

    await patchLabourRate({labourRateId: id, body})
      .unwrap()
      .then(() => navigate(settingsRoutes.labourRates))
      .catch(handleApiError);
  };

  const authorizationProfileOptions = authorizationProfiles?.map((profile) => ({
    label: profile?.name,
    value: profile?.id,
  }));

  const workTypeOptions = getOptionsWithSelectedValues(labourRate?.vehicleGroups);

  const vehicleGroupOptions = vehicleGroups
    ?.filter(
      (group) => group?.isActive || labourRate?.vehicleGroups?.includes(group?.vehicleGroupId ?? '')
    )
    .map((group) => ({
      label: group?.name,
      value: group?.vehicleGroupId,
    }));

  return (
    <SettingsTemplate
      header={{
        title: labourRate?.name ?? i18n.t('entity.workshop.actions.newLabourRate'),
        breadcrumbs: [
          {
            label: i18n.t('entity.workshop.labels.labourRates'),
            href: settingsRoutes.labourRates,
          },
        ],
      }}
      isCreating={isNil(id)}
      isLoading={isLabourRateLoading}
      isError={isLabourRateError}
      data-testid={testIds.settings.labourRatesDetail('template')}
    >
      <SettingsSection>
        <Form<LabourRateType>
          schema={FormSchema}
          defaultValues={labourRate}
          onSubmit={handleSubmit}
        >
          {(control) => (
            <>
              <VStack spacing={4}>
                <FormField
                  control={control}
                  name="name"
                  type="text"
                  label={i18n.t('general.labels.name')}
                  isRequired
                  data-testid={testIds.settings.labourRatesDetail('name')}
                />
                <FormField
                  control={control}
                  name="authorizationProfiles"
                  type="multiChoice"
                  label={i18n.t('entity.settings.labels.authorizationProfile')}
                  isRequired
                  isLoading={isAuthorizationProfilesLoading}
                  options={authorizationProfileOptions}
                  data-testid={testIds.settings.labourRatesDetail('authorizationProfiles')}
                  isNotClearable
                />
                <Separator spacing={0} />
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="rateTypes"
                      type="multiChoice"
                      options={rateTypes}
                      isLoading={isRateTypesLoading}
                      label={i18n.t('entity.order.labels.workRateType')}
                      isRequired
                      data-testid={testIds.settings.labourRatesDetail('rateTypes')}
                      isNotClearable
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="rateValueWithoutVat"
                      type="number"
                      label={i18n.t('entity.order.labels.rateValueWithoutVat')}
                      isRequired
                      data-testid={testIds.settings.labourRatesDetail('rateValueWithoutVat')}
                    />
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="workTypes"
                      type="multiChoice"
                      options={workTypeOptions}
                      isLoading={isWorkTypesLoading}
                      label={i18n.t('entity.order.labels.workType')}
                      data-testid={testIds.settings.labourRatesDetail('workTypes')}
                      isNotClearable
                    />
                  </Box>
                  <Space fillAvailable />
                </HStack>
                <Separator spacing={0} />
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="vehicleGroups"
                      type="multiChoice"
                      label={i18n.t('entity.workshop.labels.vehicleGroup')}
                      isLoading={isVehicleGroupsLoading}
                      options={vehicleGroupOptions}
                      data-testid={testIds.settings.labourRatesDetail('vehicleGroups')}
                      isNotClearable
                    />
                  </Box>
                  <Space fillAvailable />
                </HStack>
              </VStack>
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    title: i18n.t('general.actions.discardChanges'),
                    variant: 'secondary',
                    onClick: () => navigate(settingsRoutes.labourRates),
                  },
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveChanges'),
                  },
                ]}
                data-testid={testIds.settings.labourRatesDetail('footer')}
              />
            </>
          )}
        </Form>
      </SettingsSection>
    </SettingsTemplate>
  );
}

const FormSchema = object({
  name: yupString.required(),
  authorizationProfiles: array()
    .of(yupString)
    .test({
      message: i18n.t('general.validations.fieldIsRequired'),
      test: (arr) => isPositive(arr?.length),
    })
    .required(),
  workTypes: array().of(yupString).nullable(),
  rateTypes: array()
    .of(yupString)
    .test({
      message: i18n.t('general.validations.fieldIsRequired'),
      test: (arr) => isPositive(arr?.length),
    })
    .required(),
  rateValueWithoutVat: number().required(),
  vehicleGroups: array().of(yupString).nullable(),
});
