import {FlagProps} from 'platform/components';
import {Pattern, match} from 'ts-pattern';

import {useMemo} from 'react';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

type StateType = 'SCHEDULED' | 'COMPLETED';

const getStateFlag = (
  type: 'CHECKIN' | 'HANDOVER',
  state: StateType | Nullish
): FlagProps | undefined =>
  match([type, state])
    .with(['CHECKIN', 'COMPLETED'], () => ({
      label: i18n.t('entity.serviceOrder.states.checkedIn'),
      colorScheme: 'blue',
    }))
    .with(['HANDOVER', 'COMPLETED'], () => ({
      label: i18n.t('entity.serviceOrder.states.handedOver'),
      colorScheme: 'green',
    }))
    .with([Pattern.any, 'SCHEDULED'], () => ({label: i18n.t('entity.workshop.labels.scheduled')}))
    .otherwise(([_, label]) => (label ? {label} : undefined));

export function useStateCheckinHandoverFlag(
  type: 'CHECKIN' | 'HANDOVER',
  state: StateType | Nullish
) {
  return useMemo(() => getStateFlag(type, state), [type, state]);
}
