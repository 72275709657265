import {
  Card,
  Separator,
  ButtonGroup,
  Button,
  Tabs,
  DataStatus,
  TabProps,
  openDialog,
} from 'platform/components';
import {HStack, Box, VStack, Show} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetMetadaServiceCaseQuery,
  useGetServiceCaseIntegrationsQuery,
  useGetServiceCaseJobsQuery,
  useGetTireVehicleCountQuery,
  usePostServiceCaseAddServicePackageMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {Tires, handleApiError} from '@dms/shared';

import {buildArray} from 'shared';

import {ServicePackages} from '../../../../components/ServicePackages/ServicePackages';
import {XENTRY_INTEGRATION_CODE} from '../../../../constants/xentryIntegrationCode';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {CopyPreviousRequests} from './components/CopyPreviousRequests';
import {Jobs} from './components/Jobs';
import {XentryIntegration} from './components/XentryIntegration';

interface ServiceJobsSelectionProps {
  onContinueClick: () => void;
  onBackClick: () => void;
}

export function ServiceJobsSelection(props: ServiceJobsSelectionProps) {
  const {serviceCaseId} = useWorkshopUrl();
  const [postServiceCaseAddServicePackage] = usePostServiceCaseAddServicePackageMutation();
  const {data: jobs} = useGetServiceCaseJobsQuery({serviceCaseId});
  const {data: serviceCase} = useGetMetadaServiceCaseQuery(
    {serviceCaseId},
    {skip: isNilOrEmpty(serviceCaseId)}
  );
  const {data: tireVehicleCount} = useGetTireVehicleCountQuery(
    {vehicleId: serviceCase?.vehicleId ?? ''},
    {skip: isNilOrEmpty(serviceCase?.vehicleId)}
  );
  const {data: integrations} = useGetServiceCaseIntegrationsQuery(
    {serviceCaseId},
    {skip: isNilOrEmpty(serviceCaseId)}
  );

  const onHistoricalRequestsClick = () => {
    openDialog(
      <CopyPreviousRequests
        serviceCase={serviceCase}
        data-testid={testIds.workshop.createServiceCase('historicalRequests')}
      />,
      {
        title: i18n.t('entity.serviceCase.labels.historyOfRepairs'),
        size: 'large',
      }
    );
  };

  const handleAddServicePackage = async (servicePackageId: string) => {
    await postServiceCaseAddServicePackage({
      serviceCaseId,
      body: {servicePackage: {servicePackageId}},
    })
      .unwrap()
      .catch(handleApiError);
  };

  return (
    <VStack spacing={4} height="100%">
      <Show
        when={integrations?.caseIntegration?.some(
          (integration) => integration?.integration?.code === XENTRY_INTEGRATION_CODE
        )}
      >
        <XentryIntegration
          serviceCaseId={serviceCaseId}
          data-testid={testIds.workshop.createServiceCase('xentry')}
        />
      </Show>
      <Card isFullHeight>
        <DataStatus isLoading={isNilOrEmpty(serviceCaseId)} minHeight="100%">
          <HStack grow={1} height="100%">
            <Box minWidth={80}>
              <ServicePackages
                serviceCaseId={serviceCaseId}
                onServicePackageAdd={handleAddServicePackage}
                data-testid={testIds.workshop.createServiceCase('packages')}
              />
            </Box>
            <Separator orientation="vertical" />
            <Box flex={1}>
              <Tabs
                tabs={buildArray<TabProps>([
                  {
                    id: 'jobs',
                    content: <Jobs serviceCaseId={serviceCaseId} />,
                    title: i18n.t('page.workshop.labels.requests'),
                    actions: [
                      {
                        type: 'button',
                        title: i18n.t('entity.serviceCase.labels.historyOfRepairs'),
                        onClick: onHistoricalRequestsClick,
                        leftIcon: 'action/history',
                        variant: 'link',
                      },
                    ],
                  },
                ]).whenNot(isNilOrEmpty(serviceCase?.vehicleId), () => ({
                  id: 'tires',
                  content: (
                    <Tires
                      vehicleId={serviceCase?.vehicleId ?? ''}
                      gridCode="tire-order-wheel-service-case-creating"
                    />
                  ),
                  title: i18n.t('page.tiresInventory.labels.tires'),
                  isDisabled: (tireVehicleCount?.count ?? 0) === 0,
                }))}
                variant="condensed"
                data-testid={testIds.workshop.createServiceCase('tabs')}
              />
            </Box>
          </HStack>
        </DataStatus>
      </Card>
      <ButtonGroup align="right">
        <Button
          onClick={props.onBackClick}
          variant="outlined"
          data-testid={testIds.workshop.createServiceCase('back')}
          title={i18n.t('general.actions.back')}
        />
        <Button
          onClick={props.onContinueClick}
          variant="outlined"
          isDisabled={isNilOrEmpty(jobs)}
          data-testid={testIds.workshop.createServiceCase('continue')}
          title={i18n.t('general.actions.continue')}
        />
      </ButtonGroup>
    </VStack>
  );
}
