import {FC, useCallback, KeyboardEvent, FocusEvent} from 'react';

import i18n from '@dms/i18n';
import {TextField} from '@dms/teas';

import {SearchContainer} from './styles';

export type SearchRowProps = {
  onSearch: (search: string) => void;
};

export const SearchRow: FC<SearchRowProps> = ({onSearch}) => {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const searchString = e.currentTarget.value?.trim();

    onSearch(searchString);
  };

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  }, []);

  return (
    <SearchContainer>
      <TextField
        placeholder={i18n.t('general.labels.searchPlaceholder')}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </SearchContainer>
  );
};
