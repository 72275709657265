import {FlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getTimeTrackingStateFlag = (state: string | Nullish) =>
  match<string | Nullish, FlagProps | undefined>(state)
    .with(
      'OPEN',
      always({
        label: i18n.t('entity.serviceOrder.states.unstarted'),
        colorScheme: 'blue',
        isSubtle: true,
      })
    )
    .with(
      'IN_PROGRESS',
      always({
        label: i18n.t('general.labels.inProgress'),
        colorScheme: 'blue',
        isSubtle: true,
      })
    )
    .with(
      'WORK_DONE',
      always({
        label: i18n.t('entity.serviceOrder.states.workDone'),
        colorScheme: 'green',
        isSubtle: true,
      })
    )
    .with(
      'ON_HOLD_CUSTOMER',
      always({
        label: i18n.t('entity.customer.labels.customer'),
        colorScheme: 'orange',
        isSubtle: true,
      })
    )
    .with(
      'ON_HOLD_SPARE_PART',
      always({
        label: i18n.t('entity.serviceOrder.labels.sparePart'),
        colorScheme: 'orange',
        isSubtle: true,
      })
    )
    .with(
      'ON_HOLD_ASSISTANCE',
      always({
        label: i18n.t('entity.insurance.labels.risks.assistance'),
        colorScheme: 'orange',
        isSubtle: true,
      })
    )
    .otherwise(always(undefined));
