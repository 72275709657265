import {
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {RefObject, useCallback, useState} from 'react';

import {isNil, isNotNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useDeleteLabourCatalogItemsMutation,
  useGetLabourCatalogGridFilterCatalogCategoryQuery,
  usePostBasketLabourItemLabourCatalogApiMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {
  ActionCallback,
  DataGrid,
  DataGridRef,
  QueryFilterObject,
  useRefreshDataGrid,
} from 'features/datagrid';

import {getTreeFolderQueryModifier} from '../../utils/getTreeFolderQueryModifier';
import {handleApiError} from '../../utils/handleApiError';
import {FolderTree} from '../FolderTree/FolderTree';
import {LabourCatalogItemFrom} from '../LabourCatalogItemFrom/LabourCatalogItemFrom';
import {CatalogueFolder} from '../LabourCatalogItemFrom/types/catalogueFolder';
import {MoveToFolder} from '../MoveToFolder/MoveToFolder';

interface WorkItemsListCardProps extends TestIdProps {
  packageId?: string;
  createOverride?: {
    datagridRef: RefObject<DataGridRef>;
    refreshDataGrid: () => void;
    activeFolder: CatalogueFolder | undefined;
    setActiveFolder: (activeFolder: CatalogueFolder | undefined) => void;
  };
}

type CastedRowData = {itemName?: {value?: string}} | undefined;

export function WorkItemsListCard(props: WorkItemsListCardProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [activeFolder, setActiveFolder] = useState<CatalogueFolder | undefined>();
  const [shouldIncludeSubfolders, setShouldIncludeSubfolders] = useState(true);

  const {
    data: catalogues,
    isLoading: isCataloguesLoading,
    isError: isCataloguesError,
  } = useGetLabourCatalogGridFilterCatalogCategoryQuery();

  const [deleteLabourCatalogItems] = useDeleteLabourCatalogItemsMutation();
  const [postBasketLabourItemLabourCatalog] = usePostBasketLabourItemLabourCatalogApiMutation();

  const onCreateNewClick = () => {
    if (isNil(props.createOverride?.activeFolder ?? activeFolder)) {
      return;
    }

    openDialog(
      <LabourCatalogItemFrom
        onClose={() => closeDialog('createNewItemDialog')}
        onSubmitted={props.createOverride?.refreshDataGrid ?? refreshDataGrid}
        catalogueFolder={props.createOverride?.activeFolder ?? activeFolder}
        data-testid={suffixTestId('createNewItem', props)}
      />,
      {
        id: 'createNewItemDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
      }
    );
  };

  const handleEditItem = (itemId: string, itemName: string, refresh: () => void) => {
    openDialog(
      <LabourCatalogItemFrom
        onClose={() => closeDialog('editCatalogItemDialog')}
        onSubmitted={refresh}
        editedId={itemId}
        data-testid={suffixTestId('createNewItem', props)}
      />,
      {
        id: 'editCatalogItemDialog',
        title: itemName,
        scrollBehavior: 'outside',
      }
    );
  };

  const handleDeleteItem = (labourCatalogItemIds: string[], refresh: () => void) => {
    openDeleteDialog({
      onConfirm: () =>
        deleteLabourCatalogItems({body: {labourCatalogItemIds}})
          .unwrap()
          .then(refresh)
          .catch(handleApiError),
    });
  };

  const handleMoveToFolder = (itemIds: string | string[]) => {
    openDialog(
      <MoveToFolder
        catalogueIds={catalogueIds}
        contextTarget="labour-catalog"
        relationTarget="labour-catalog-item"
        itemIds={isArray(itemIds) ? itemIds : [itemIds]}
        onClose={closeCurrentDialog}
        onSubmitted={props.createOverride?.refreshDataGrid ?? refreshDataGrid}
        data-testid={suffixTestId('moveDialog', props)}
      />,
      {
        size: 'small',
        title: i18n.t('entity.addWork.labels.moveToFolder'),
      }
    );
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const itemId = isArray(rowId) ? rowId[0] : rowId;
    const items = isArray(rowId) ? rowId : [rowId];
    const itemName = (rowData as CastedRowData)?.itemName?.value;

    match([itemName, actionKey])
      .with([Pattern.any, 'delete'], () => handleDeleteItem(items, refreshData))
      .with([Pattern.string, 'edit'], [Pattern.string, 'redirectDetail'], ([itemName]) =>
        handleEditItem(itemId, itemName, refreshData)
      )
      .with([Pattern.string, 'addToBasket'], () =>
        postBasketLabourItemLabourCatalog({
          packageId: props.packageId ?? '',
          body: {labourCatalogId: itemId},
        })
          .unwrap()
          .catch(handleApiError)
      )
      .with([Pattern.any, 'moveToFolder'], () => handleMoveToFolder(rowId))
      .otherwise(() => showNotification.error());
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        getTreeFolderQueryModifier(
          props.createOverride?.activeFolder?.id ?? activeFolder?.id,
          shouldIncludeSubfolders
        ),
      ]),
    [activeFolder?.id, shouldIncludeSubfolders, props.createOverride?.activeFolder?.id]
  );

  const catalogueIds = catalogues?.map((catalogue) => catalogue.value) ?? [];

  return (
    <Card
      title={
        isNil(props.createOverride)
          ? i18n.t('entity.workshopServicePackages.labels.workItems')
          : undefined
      }
      isFullHeight
      actions={
        isNil(props.createOverride)
          ? [
              {
                type: 'button',
                variant: 'link',
                leftIcon: 'content/add_circle',
                title: i18n.t('entity.workshopServicePackages.actions.createCatalogueItem'),
                isDisabled: isNil(activeFolder),
                onClick: onCreateNewClick,
              },
            ]
          : undefined
      }
    >
      <DataStatus isLoading={isCataloguesLoading} isError={isCataloguesError} minHeight="100%">
        <HStack height="100%" spacing={4}>
          <Box
            flex={1}
            borderRight="1px solid"
            borderColor="palettes.neutral.40.100"
            paddingRight={4}
            padding={1}
            height="100%"
            overflowX="auto"
          >
            <FolderTree
              contextIds={catalogueIds}
              contextTarget="labour-catalog"
              activeFolderId={props.createOverride?.activeFolder?.id ?? activeFolder?.id}
              shouldIncludeSubfolders={shouldIncludeSubfolders}
              onFolderChange={(id, contextId) =>
                (props.createOverride?.setActiveFolder ?? setActiveFolder)(
                  isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined
                )
              }
              onIncludeSubfoldersChange={setShouldIncludeSubfolders}
              data-testid={suffixTestId('catalogueTree', props)}
            />
          </Box>
          <Box flex={3}>
            <VStack spacing={4} height="100%">
              <Box flex={1}>
                <DataGrid
                  // DG must be re-rendered on folder change to update the query modifier
                  // eslint-disable-next-line no-restricted-syntax
                  key={`${
                    props.createOverride?.activeFolder?.id ?? activeFolder?.id
                  }-${shouldIncludeSubfolders}`}
                  queryModifier={queryModifier}
                  ref={props.createOverride?.datagridRef ?? dataGridRef}
                  gridCode={
                    isNil(props.packageId) ? 'labour-catalog-items' : 'basket-labour-catalog'
                  }
                  actionCallback={actionCallback}
                  data-testid={suffixTestId('labour-catalog', props)}
                />
              </Box>
            </VStack>
          </Box>
        </HStack>
      </DataStatus>
    </Card>
  );
}
