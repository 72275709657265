const DEFAULT_QUANTITY = 0;

/**
 * Returns a formatted string combining the quantity and the handling unit.
 * If the quantity is null or undefined, it defaults to `DEFAULT_QUANTITY`.
 *
 * @param {number} quantity - The quantity to be displayed. Defaults to `DEFAULT_QUANTITY` if null or undefined.
 * @param {string} handlingUnit - The unit in which the quantity is measured (e.g., "kg", "lbs", "units").
 * @returns {string} A formatted string combining the quantity and the handling unit.
 *
 * @example
 * getQuantityWithHandlingUnit(5, 'kg'); // "5 kg"
 * getQuantityWithHandlingUnit(null, 'lbs'); // "DEFAULT_QUANTITY lbs"
 */
export const getQuantityWithHandlingUnit = (quantity: number | string, handlingUnit: string) => {
  return `${quantity ?? DEFAULT_QUANTITY} ${handlingUnit}`;
};
