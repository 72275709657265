export const VehicleCustomFields = {
  hsn: {
    definitionId: 'manufacturerKeyNumber',
    valueType: 'string',
  },
  tsn: {
    definitionId: 'typeCodeNumber',
    valueType: 'string',
  },
  umweltplakette: {
    definitionId: 'environmentalBadge',
    valueType: 'string',
  },
} as const;

export enum UmweltplaketteEnum {
  none = 'VEHICLEFIELD_CUSTOM_ENV_BADGE_NONE',
  red = 'VEHICLEFIELD_CUSTOM_ENV_BADGE_RED',
  yellow = 'VEHICLEFIELD_CUSTOM_ENV_BADGE_YELLOW',
  green = 'VEHICLEFIELD_CUSTOM_ENV_BADGE_GREEN',
}
