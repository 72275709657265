import {getApiDateString} from 'shared';

import {
  AccDeleteConfigurationApiArg,
  AccDeleteConfigurationApiResponse,
  AccDeleteConfigurationPaymentImportModeApiArg,
  AccDeleteConfigurationPaymentImportModeApiResponse,
  AccGetConfigurationExportCarisApiArg,
  AccGetConfigurationExportCarisApiResponse,
  AccGetConfigurationInvoiceCostItemApiArg,
  AccGetConfigurationInvoiceCostItemApiResponse,
  AccGetConfigurationPaymentImportModeApiArg,
  AccGetConfigurationPaymentImportModeApiResponse,
  AccM2StrediskoGetCentresApiArg,
  AccM2StrediskoGetCentresApiResponse,
  AccPatchConfigurationExportCarisApiArg,
  AccPatchConfigurationExportCarisApiResponse,
  AccPatchConfigurationInvoiceCostItemApiArg,
  AccPatchConfigurationInvoiceCostItemApiResponse,
  AccPatchConfigurationPaymentImportModeApiArg,
  AccPatchConfigurationPaymentImportModeApiResponse,
  AccPostConfigurationInvoiceCostItemApiArg,
  AccPostConfigurationInvoiceCostItemApiResponse,
  AccPostConfigurationPaymentImportModeApiArg,
  AccPostConfigurationPaymentImportModeApiResponse,
  AccPostMatrixConfigInitializeApiArg,
  AccPostMatrixConfigInitializeApiResponse,
  GetCategoryApiArg,
  GetCategoryApiRes,
  GetOperationalItemsRequest,
  GetOperationalItemsResponse,
  GetStockMovementsRequest,
  GetStockMovementsResponse,
  MatricesConfigCleanupApiArg,
  MatricesConfigCleanupApiRes,
  MoveAllMatricesFromCarisApiArg,
  MoveAllMatricesFromCarisApiRes,
  MoveMatricesApiArg,
  MoveMatricesApiRes,
  AccPatchDocumentExportDateSetNullApiArg,
  AccPatchDocumentExportDateSetNullApiResponse,
} from '../types/accounting';
import {metadaApi} from './baseApi/metadaApi';

export const metadaAccountingApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getStockMovementTypes: build.query<GetStockMovementsResponse[], GetStockMovementsRequest>({
      query: (queryArg) => ({
        url: `accounting/v1/stock-movement-types?applicationId=Servis&movementDirection=1`,
        method: 'GET',
        invalidatesTags: ['stockMovements'],
      }),
    }),
    getOperationItems: build.query<GetOperationalItemsResponse[], GetOperationalItemsRequest>({
      query: (queryArg) => {
        // Always send current date
        const TODAY = getApiDateString(new Date());
        const APPLICATION_ID = 'Servis';
        const ITEM_GROUP = 'Material';

        return {
          url: `accounting/v1/operation-items?accountingDate=${TODAY}&applicationId=${APPLICATION_ID}&itemGroup=${ITEM_GROUP}`,
          method: 'GET',
          invalidatesTags: ['listOfItems'],
        };
      },
    }),
    getAccountingDictionary: build.query<GetCategoryApiRes, GetCategoryApiArg>({
      query: ({category}) => ({
        url: `accounting/v1/dictionary/${category}`,
        method: 'GET',
      }),
      providesTags: ['accountingDictionary'],
    }),
    moveMatrices: build.mutation<MoveMatricesApiRes, MoveMatricesApiArg>({
      query: ({category, ...body}) => ({
        url: `accounting/v1/${category}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['accountingDictionary'],
    }),
    matricesConfigCleanup: build.mutation<MatricesConfigCleanupApiRes, MatricesConfigCleanupApiArg>(
      {
        query: (body) => ({
          url: `accounting/v1/matrices-config-db-clean-up`,
          method: 'POST',
          body,
        }),
      }
    ),
    moveAllMatricesFromCaris: build.mutation<
      MoveAllMatricesFromCarisApiRes,
      MoveAllMatricesFromCarisApiArg
    >({
      query: ({googleDocId, sheetName, ...params}) => ({
        url: `accounting/v1/convert-matrices-from-caris/${googleDocId}/${sheetName}`,
        method: 'POST',
        params,
      }),
      invalidatesTags: ['accountingDictionary'],
    }),
    postConfigurationPaymentImportMode: build.mutation<
      AccPostConfigurationPaymentImportModeApiResponse,
      AccPostConfigurationPaymentImportModeApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/payment-import-mode`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchConfigurationPaymentImportMode: build.mutation<
      AccPatchConfigurationPaymentImportModeApiResponse,
      AccPatchConfigurationPaymentImportModeApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/payment-import-mode`,
        method: 'PATCH',
        body: queryArg.body,
        params: {branchId: queryArg.branchId},
      }),
    }),
    getConfigurationPaymentImportMode: build.query<
      AccGetConfigurationPaymentImportModeApiResponse,
      AccGetConfigurationPaymentImportModeApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/payment-import-mode/${queryArg.branchId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    deleteConfigurationPaymentImportMode: build.mutation<
      AccDeleteConfigurationPaymentImportModeApiResponse,
      AccDeleteConfigurationPaymentImportModeApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/payment-import-mode/${queryArg.branchId}`,
        method: 'DELETE',
      }),
    }),
    PostConfigurationInvoiceCostItem: build.mutation<
      AccPostConfigurationInvoiceCostItemApiResponse,
      AccPostConfigurationInvoiceCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/invoice-cost-item/used-cars`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    GetConfigurationInvoiceCostItem: build.query<
      AccGetConfigurationInvoiceCostItemApiResponse,
      AccGetConfigurationInvoiceCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/invoice-cost-item/${queryArg.id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    PatchConfigurationInvoiceCostItem: build.mutation<
      AccPatchConfigurationInvoiceCostItemApiResponse,
      AccPatchConfigurationInvoiceCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/invoice-cost-item/${queryArg.id}`,
        method: 'PATCH',
        params: queryArg,
      }),
    }),
    DeleteConfiguration: build.mutation<
      AccDeleteConfigurationApiResponse,
      AccDeleteConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/configuration/record/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    accPostMatrixConfigInitialize: build.mutation<
      AccPostMatrixConfigInitializeApiResponse,
      AccPostMatrixConfigInitializeApiArg
    >({
      query: () => ({
        url: `accounting/v1/matrix-config-initialization`,
        method: 'POST',
      }),
    }),
    accGetConfigurationExportCaris: build.query<
      AccGetConfigurationExportCarisApiResponse,
      AccGetConfigurationExportCarisApiArg
    >({
      query: () => ({
        url: `accounting/v1/configuration/caris-export`,
      }),
      providesTags: ['carisExport'],
    }),
    accPatchConfigurationExportCaris: build.mutation<
      AccPatchConfigurationExportCarisApiResponse,
      AccPatchConfigurationExportCarisApiArg
    >({
      query: (body) => ({
        url: `accounting/v1/configuration/caris-export`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['carisExport'],
    }),
    accM2StrediskoGetCentres: build.query<
      AccM2StrediskoGetCentresApiResponse,
      AccM2StrediskoGetCentresApiArg | void
    >({
      query: (queryArg) => ({
        url: `accounting/v1/m2-centres`,
        params: {accountingDate: queryArg?.accountingDate, code: queryArg?.code},
      }),
    }),
    accPatchDocumentExportDateSetNull: build.mutation<
      AccPatchDocumentExportDateSetNullApiResponse,
      AccPatchDocumentExportDateSetNullApiArg
    >({
      query: (queryArg) => ({
        url: `accounting/v1/document-export-flag/${queryArg.type}/${queryArg.id}`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useAccPatchDocumentExportDateSetNullMutation,
  useAccGetConfigurationExportCarisQuery,
  useAccPatchConfigurationExportCarisMutation,
  useMatricesConfigCleanupMutation,
  useAccPostMatrixConfigInitializeMutation,
  usePatchConfigurationInvoiceCostItemMutation,
  usePostConfigurationInvoiceCostItemMutation,
  useGetConfigurationInvoiceCostItemQuery,
  useDeleteConfigurationMutation,
  useDeleteConfigurationPaymentImportModeMutation,
  usePatchConfigurationPaymentImportModeMutation,
  usePostConfigurationPaymentImportModeMutation,
  useGetConfigurationPaymentImportModeQuery,
  useGetStockMovementTypesQuery,
  useGetOperationItemsQuery,
  useGetAccountingDictionaryQuery,
  useMoveMatricesMutation,
  useMoveAllMatricesFromCarisMutation,
  useAccM2StrediskoGetCentresQuery,
} = metadaAccountingApi;
