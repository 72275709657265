import {
  PhoneNumberData,
  EmailData,
  IdentityCardRequestBody,
  PersonData,
  BusinessInfoData,
  AddressRequestBodyV2,
} from '@dms/api';

/* eslint-disable eag/match-named-export */

export const emptyPhoneNumber: PhoneNumberData = {
  type: null,
  phoneNumber: {
    prefix: '+420',
    number: '',
    countryCode: 'CZE',
  },
};

export const emptyEmail: EmailData = {
  email: '',
  type: null,
};

export const emptyIdentityCard: IdentityCardRequestBody = {
  id: null,
  cardData: {
    type: null,
    cardNumber: '',
    issuedOn: null,
    validUntil: null,
    issuer: null,
    issuedInCountryCode: null,
    note: null,
  },
};

export const emptyPersonData: PersonData = {
  firstName: null,
  lastName: null,
  middleName: null,
  titleBefore: null,
  titleAfter: null,
  genderKey: null,
  roles: null,
  citizenshipCode: null,
  birthdate: null,
  personalIdentifier: null,
};

export const emptyBusinessInfoData: BusinessInfoData = {
  countryOfRegistrationCode: null,
  registrationNumber: null,
  vatNumber: null,
  tradeName: null,
  fileNumber: null,
};

export const emptyAddressSvk = {
  address: {
    prefix: '',
    street: '',
    descriptiveNumber: null,
    orientationNumber: null,
    city: '',
    zip: '',
    country: '',
    addressComplement: '',
    coordinates: null,
    postal: null,
  },
  type: '',
};

export const emptyAddressCze = {
  address: {
    prefix: '',
    street: '',
    descriptiveNumber: null,
    orientationNumber: null,
    city: '',
    zip: '',
    country: '',
    addressComplement: '',
    coordinates: null,
    postal: null,
  },
  type: '',
};

export const emptyAddressPol: AddressRequestBodyV2 = {
  address: {
    prefix: '',
    street: '',
    descriptiveNumber: null,
    orientationNumber: null,
    city: '',
    zip: '',
    country: '',
    addressComplement: '',
    coordinates: null,
    postal: null,
  },
  type: '',
  invalid: null,
};
