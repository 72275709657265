import {useCallback} from 'react';

import {mergeAll} from 'ramda';
import {isNotString, isString, notEqual} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {customerRoutes, testIds} from '@dms/routes';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function Suppliers() {
  const navigate = useNavigate();

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.suppliers'),
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {institutions: ['SUPPLIER']}]),
    []
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    if (isNotString(rowId) || notEqual('detail', actionKey)) {
      return;
    }
    if ('id' in rowData && isString(rowData.id)) {
      navigate(composePath(customerRoutes.detail, {params: {id: rowData.id}}));
    }
  };

  return (
    <SettingsTemplate data-testid={testIds.settings.suppliers('template')} header={header}>
      <DataGrid gridCode="customer" actionCallback={actionCallback} queryModifier={queryModifier} />
    </SettingsTemplate>
  );
}
