import {Attributes} from 'platform/components';
import {HStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {FC} from 'react';

import {UserResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {parseDate, TestIdProps} from 'shared';

export type CloseDialogCardType = TestIdProps & {
  reasonClose: string | null | undefined;
  closedBy: UserResponseBody | null | undefined;
  reasonCloseNote: string | null | undefined;
  closedAt: string | null | undefined;
};

export const CloseDialogCard: FC<CloseDialogCardType> = (props) => {
  const {closedAt, closedBy, reasonClose, reasonCloseNote} = props;
  const formatDateTime = useDateTimeFormatter();

  return (
    <HStack spacing={4}>
      <Attributes
        size="quarter"
        rows={[
          {label: i18n.t('general.labels.reason'), value: reasonClose || null},
          {
            label: i18n.t('general.label.closedBy'),
            value: getNaturalPersonFullName(closedBy),
          },
        ]}
      />
      <Attributes
        size="quarter"
        rows={[
          {label: i18n.t('general.labels.note'), value: reasonCloseNote || null},
          {
            label: i18n.t('general.label.closedOn'),
            value: closedAt && formatDateTime('dateTimeLong', parseDate(closedAt)),
          },
        ]}
      />
    </HStack>
  );
};
