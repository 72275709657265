import {createApi} from '@reduxjs/toolkit/query/react';

import {teasBaseQuery} from '../baseQuery/teasBaseQuery';

export const omneticApi = createApi({
  baseQuery: teasBaseQuery,
  reducerPath: 'rtk_omneticApi',
  tagTypes: [
    'Interest',
    'interestPriceReport',
    'AuditData',
    'PurchaseFunnel',
    'SaleFunnel',
    'VehicleStockOverview',
    'LatestVehiclesList',
    'BillingInformation',
    'BillingInformationList',
    'customFieldList',
    'LatestInterestsList',
    'LatestBusinessCasesList',
    'ServiceCase',
    'ServiceAlbumPhotoList',
    'Roles',
    'deviceList',
    'windshield',
    'entityCustomFields',
    'watermark',
    'balanceInvoice',
    'notificationSettings',
    'transaction',
    'PriceReport',
    'dueDatesDetail',
    'dueDates',
    'EsignoService',
    'preAccountingProcessing',
    'correctiveDocumentSetting',
    'TaxDocumentSettingList',
    'Vehicle',
    'EmployeePhoto',
    'signDocumentTransaction',
    'vehiclePriceReport',
    'vehicleInsurance',
    'invoiceProformaPaymentList',
    'cashRegisters',
    'cashRegisterLimitDefinition',
    'DocumentSeriesDefinition',
    'GeneralSeries',
    'UserMenuPins',
    'DashboardSettings',
    'CurrentUser',
    'OtherUserSettings',
    'offer',
    'paymentPrescription',
    'shortcomings',
    'VehiclePhotos',
    'VehicleAlbumPhotos',
    'highlights',
    'invoiceProforma',
    'User',
    'Users',
    'Tenant',
    'Branch',
    'BranchList',
    'cancellationDefinition',
    'marginDefinitions',
    'proformaGeneration',
    'CebiaAutotracerAndReportConfig',
    'CebiaFeatureDecoderConfig',
    'CebiaRokvyConfig',
    'CebiaVinDecoderConfig',
    'OmneticVinDecoderConfig',
    'roundingDefinitions',
    'invoicePayment',
    'InvalidDocumentRegister',
    'EuVies',
    'DistraintRegister',
    'invoice',
    'InsolvencyRegister',
    'UnreliableVatPayerRegister',
    'VehicleFinanceSummary',
    'SaleVehicle',
    'SaleVehicleFlags',
    'SaleVehicleCost',
    'SaleVehicleEarning',
    'correctiveTaxDocument',
    'SaleVehicleCostPreset',
    'SaleVehicleEarningPreset',
    'photoSettings',
    'promotionalPhoto',
    'miniSale',
    'miniPurchase',
    'supportedPlatforms',
    'promotionalPhotoVehicle',
    'participation',
    'GdprConsentType',
    'GdprConsent',
    'documentContext',
    'dgv4-preset',
    'dgv4-smart-search',
    'customerNote',
    'customerComments',
    'Customer',
    'Customers',
    'Checkout',
    'CustomerNote',
    'AutoFillTemplates',
    'CentralizedPricing',
    'Inspection',
    'upsellProductItem',
    'businessCaseUpsell',
    'Complaint',
    'VehicleComplaints',
    'SaleVehicleComplaints',
    'commissionRule',
    'permissions',
    'BCList',
    'VehicleLastActiveBC',
    'EntityTagList',
    'tenantTagList',
    'CustomerVerificationRegisters',
    'InspectionTemplates',
    'Codelist',
    'CustomerRequestedPrice',
    'CashRegisterDocument',
    'SourcingFilterPresetList',
    'SourcingFilterPreset',
    'vehicleWarehouse',
    'vehicleWarehouseForMovement',
    'vehicleWarehouseSettings',
    'vehicleWarehouseInformation',
    'vehicleWarehousesByUser',
    'vehicleWarehousesByBranch',
    'vehicleWarehouseSummary',
    'AdGeneralSettings',
    'AdPlatformSettings',
    'AdWindshieldSettings',
    'PredefinedNotes',
    'BusinessCaseDetail',
    'BusinessCaseActions',
    'BusinessCaseCountByVehicle',
    'BusinessCaseSalePrices',
    'BusinessCasePurchasePrices',
    'VatCheckTotalsDefinition',
    'note',
    'VehicleNote',
    'VehicleFinancialInstitution',
    'documentsCount',
    'comments',
    'comment',
    'InspectionList',
    'CebiaSmartCodeUrlConfig',
    'Notification',
    'MessageTemplate',
    'MessageTemplates',
    'backgroundRemovalSettings',
    'LocaleConfig',
    'PublishingPlatformSauto',
    'PublishingPlatformTipcars',
    'PublishingPlatformMobileDe',
    'PublishingPlatformMyWeb',
    'PublishingPlatformFacebook',
    'PublishingPlatformAutocaris',
    'PublishingPlatformSkodaPlus',
    'PublishingPlatformDasWeltAuto',
    'PublishingPlatformMercedesNaSklade',
    'PublishingPlatformHyundaiPromise',
    'PublishingPlatformOtomoto',
    'PublishingPlatformFordUzywane',
    'PublishingPlatformAutoplacPl',
    'PublishingPlatformVehis',
    'PublishingPlatformMamGo',
    'PublishingPlatformFordGeronimo',
    'SourcingAllListsWithSameResponseStructure',
    'SourcingFavouriteList',
    'SourcingHiddenList',
    'SourcingToBuyList',
    'SourcingComparisonList',
    'SourcingVehicleList',
    'SourcingVehicleDetail',
    'SourcingPriceMapVehicles',
    'SourcingComments',
    'storageSector',
    'storageOccupiedSpace',
    'storageOccupiedSpaces',
    'storageSectors',
    'BusinessCaseSettings',
    'SaleVehicleActions',
    'SaleVehicleAlbumPhotos',
    'SaleVehicleAlbums',
    'SaleVehiclePromoPhotos',
    'SaleVehicleCoverPhoto',
    'TipCarsDefaultSettings',
    'miniPurchase',
    'BulkEvent',
    'GeneralSettingsTenant',
    'OfferedCars',
    'VehicleReservationHistory',
    'VehicleActiveReservation',
    'TopMakes',
    'ServiceVehicleHomeService',
    'WatermarkDetial',
    'WatermarkList',
    'PlatformList',
    'Task',
    'TaskCounter',
    'TaskList',
  ],
  endpoints: () => ({}),
});
