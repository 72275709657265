import {ButtonGroup, DataStatus, openDialog} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CreateUserForm} from './CreateUserForm';
import {PairUserForm} from './PairUserForm';

interface ConnectUserToEmployeeProps extends RequiredTestIdProps {
  employeeId: string;
}
export function ConnectUserToEmployee(props: ConnectUserToEmployeeProps) {
  const handleCreateAndPairUser = () => {
    openDialog(
      <CreateUserForm
        employeeId={props.employeeId}
        data-testid={suffixTestId('CreateUserForm', props)}
      />,
      {
        title: i18n.t('page.employeeDetail.labels.loginDetail'),
      }
    );
  };

  const handlePairUser = () => {
    openDialog(
      <PairUserForm
        employeeId={props.employeeId}
        data-testid={suffixTestId('PairUserForm', props)}
      />,
      {
        title: i18n.t('page.employeeDetail.labels.pairExistingUser'),
        size: 'small',
      }
    );
  };

  return (
    <Box padding={5}>
      <VStack align="center" spacing={4}>
        <DataStatus
          isEmpty
          emptyMessage={i18n.t('page.employeeDetail.labels.linkedUserEmptyMessage')}
          emptySubheadline={i18n.t('page.employeeDetail.labels.linkedUserEmptySubMessage')}
        />
        <ButtonGroup
          data-testid={suffixTestId('connectUser', props)}
          buttons={[
            {
              title: i18n.t('page.employeeDetail.labels.pairExistingUser'),
              onClick: handlePairUser,
              variant: 'secondary',
            },
            {
              title: i18n.t('page.employeeDetail.labels.createNewUser'),
              onClick: handleCreateAndPairUser,
              variant: 'primary',
            },
          ]}
        />
      </VStack>
    </Box>
  );
}
