import {Chips} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {BillingRecipient} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useBillingInformation} from '../hooks/useBillingInformation';

interface AllowedCustomerSelectorProps extends RequiredTestIdProps {
  checkoutId: string;
  selectedRecipient: BillingRecipient;
}

export function AllowedCustomerSelector(props: AllowedCustomerSelectorProps) {
  const {allowedCustomerOptions, isSettingBillingInformationLoading, handleChangeBillingRecipient} =
    useBillingInformation(props.checkoutId);

  return (
    <VStack spacing={4}>
      <Text size="small" color="tertiary">
        {i18n.t('general.labels.selectBillingInformation')}
      </Text>
      <Chips
        options={allowedCustomerOptions}
        value={[props.selectedRecipient]}
        onChange={handleChangeBillingRecipient}
        isDisabled={isSettingBillingInformationLoading}
        data-testid={suffixTestId('billingRecipients', props)}
      />
    </VStack>
  );
}
