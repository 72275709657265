import {Action, Card, useDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';
import {Section, UploadDocumentsDialog, useGetDocumentDatagridActions} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

interface DocumentsProps extends TestIdProps {
  directSaleId: string;
}

export function Documents(props: DocumentsProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();
  const [rowActions] = useGetDocumentDatagridActions(refreshDataGrid);

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {directSaleId: props.directSaleId}]),
    [props.directSaleId]
  );

  const documentsActions: Action[] = [
    {
      type: 'button',
      variant: 'ghostLink',
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.warehouse.actions.addDocument'),
      onClick: openUploadDialog,
      'data-testid': suffixTestId('action.addDocument', props),
    },
  ];

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <Card title={i18n.t('page.warehouse.labels.documents')} actions={documentsActions}>
        <Box height="65vh">
          <DataGrid
            gridCode="document-context-list-direct-sale"
            ref={dataGridRef}
            emptyState={{headline: i18n.t('general.labels.noData')}}
            actionCallback={rowActions}
            queryModifier={queryModifier}
            data-testid={suffixTestId('document-context-list-direct-sale', props)}
          />
        </Box>
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={refreshDataGrid}
        contextTarget="direct-sale"
        contextId={props.directSaleId}
        bulkContext={[{target: 'direct-sale', targetId: props.directSaleId}]}
        data-testid={suffixTestId('upload-document', props)}
      />
    </Section>
  );
}
