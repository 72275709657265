import {Label} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {DEFAULT_CURRENCY} from '@dms/shared';

import {RequiredTestIdProps} from 'shared';

interface SupplierArticlesAddNewCatalogInfoPriceProps extends RequiredTestIdProps {
  label: string;
  price: number;
  currency: string;
}

export function SupplierArticlesAddNewCatalogInfoPrice(
  props: SupplierArticlesAddNewCatalogInfoPriceProps
) {
  const formatCurrency = useFormatCurrency();

  return (
    <VStack data-testid={props['data-testid']}>
      <Label>{props.label}</Label>
      <Heading size={4}>
        {formatCurrency(props.price, props.currency ?? DEFAULT_CURRENCY, 2)}
      </Heading>
    </VStack>
  );
}
