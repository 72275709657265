import {BranchListItemResponseBody, useGetBranchListQuery} from '@dms/api';

export const useBranches = () => {
  const {data: branchList, ...args} = useGetBranchListQuery();

  return {
    ...args,
    data: branchList,
    branchOptions: branchList?.branchListItems?.map(branchToOption) ?? [],
  };
};

const branchToOption = (branch: BranchListItemResponseBody) => ({
  value: branch.id,
  label: branch.marketingName,
});
