// We want to filter inactive manufacturer, but if our props.manufacturerId is inactive, we want to display it anyway
import {GetManufacturersResponse} from '@dms/api';

import {Nullish} from 'shared';

export const getMatchingManufacturers = (
  manufacturers: GetManufacturersResponse | Nullish,
  manufacturerId: string
) => {
  if (!manufacturers) {
    return [];
  }

  return manufacturers.filter(
    (manufacturer) => manufacturer.isActive || manufacturer.manufacturerId === manufacturerId
  );
};
