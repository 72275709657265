import {BreadcrumbType, Form, FormSubmitHandler} from 'platform/components';
import {useLocale} from 'platform/locale';
import {object} from 'yup';

import {prop} from 'ramda';

import {useCreateTemplateMutation} from '@dms/api';
import {i18n} from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsTemplate} from '../../../components/SettingsTemplate/SettingsTemplate';
import {contextOptions} from '../constants/contextOptions';
import {MessageTemplateFormValues} from '../types/MessageTemplateFormValues';
import {MessageTemplateFields} from './MessageTemplateFields';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.messageTemplates.labels.list'),
    href: settingsRoutes.messageTemplates,
  },
];

type Props = {
  templateType: 'sms' | 'email';
  messageTemplateId?: string;
};

export const CreateMessageTemplateForm = (props: Props) => {
  const {language} = useLocale();
  const navigate = useNavigate();

  const [createTemplateMutation, {isLoading: isMutationInProgress}] = useCreateTemplateMutation();

  const redirectToMessageTemplatesOverview = () => navigate(settingsRoutes.messageTemplates);

  const onSubmit: FormSubmitHandler<MessageTemplateFormValues> = async (
    data: MessageTemplateFormValues
  ) => {
    await createTemplateMutation({
      templateType: props.templateType,
      createTemplateRequestBody: {
        ...data,
        locale: language,
      },
    })
      .unwrap()
      .catch(handleApiError)
      .then(redirectToMessageTemplatesOverview);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs,
        title: i18n.t('page.messageTemplates.labels.new', {
          templateType: i18n.t(`page.messageTemplates.labels.${props.templateType}`),
        }),
      }}
      description={i18n.t('page.messageTemplates.labels.newDescription')}
      data-testid={testIds.settings.messageTemplates(`${props.templateType}new`)}
    >
      <Form<MessageTemplateFormValues>
        schema={props.templateType === 'sms' ? schemaSMS : schemaEmail}
        onSubmit={onSubmit}
      >
        {(control, formApi) => (
          <MessageTemplateFields
            formApi={formApi}
            control={control}
            templateType={props.templateType}
            isMutationInProgress={isMutationInProgress}
          />
        )}
      </Form>
    </SettingsTemplate>
  );
};

const schemaSMS = object({
  name: yupString.required(),
  context: yupString.required().oneOf(contextOptions.map(prop('value'))),
  body: yupString.required(),
});

const schemaEmail = schemaSMS.shape({
  subject: yupString.required(),
});
