import {Blocker, BlockerFunction, useBlocker, useInRouterContext} from 'react-router-dom';

const UNBLOCKED_STATE: Blocker = {
  state: 'unblocked',
  reset: undefined,
  proceed: undefined,
  location: undefined,
};

export function useBlockNavigating(blockerFunction: BlockerFunction) {
  const isWithinRouterContext = useInRouterContext();

  const getUnblockedState = () => UNBLOCKED_STATE;
  const useAppropriateBlocker = isWithinRouterContext ? useBlocker : getUnblockedState;
  const blocker = useAppropriateBlocker(blockerFunction);

  return {blocker};
}
