import {Upload, UploadState, useTranslationContext} from 'platform/components';
import {Box} from 'platform/foundation';
import {RcFile} from 'rc-upload/lib/interface';

import {CSSProperties, useState} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {useConditionContext} from '../hooks/useConditionContext';

interface UploadVideoProps extends TestIdProps {
  ratio: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
  onStartVideoUpload: (file: RcFile) => Promise<void>;
}

export function UploadVideo(props: UploadVideoProps) {
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.Idle);
  const {isDisabledForUser} = useConditionContext();
  const t = useTranslationContext();

  const handleStartUpload = async (file: RcFile) => {
    setUploadState(UploadState.Uploading);
    await props.onStartVideoUpload(file);
    setUploadState(UploadState.Success);
  };

  return (
    <Box width="100%" height="100%" ratio={props.ratio} position="relative" overflow="hidden">
      <Upload
        size="default"
        accept="video/*"
        type="card"
        uploadIcon="image/video_call"
        uploadText={`${props.isMandatory ? '*' : ''} ${t('entity.video.labels.add')}`}
        errorIcon="navigation/cancel"
        customRequest={({onSuccess}) => {
          onSuccess?.('', new XMLHttpRequest());
        }}
        onStart={handleStartUpload}
        uploadState={uploadState}
        isMultiple
        isDisabled={isDisabledForUser}
        data-testid={suffixTestId('uploadControl', props)}
      />
    </Box>
  );
}
