import {NumberInput} from '../../NumberInput/NumberInput';
import {useFieldProps} from '../hooks/useFieldProps';
import {NumberRendererResponseBody} from '../types/modelSchema';

const DEFAULT_DECIMAL_PLACES = 0;

export function NumberRenderer(props: NumberRendererResponseBody) {
  const {name, decimalPlaces, tooltip} = props;
  const {onChange, onBlur, onFocus, value, isRequired, error} = useFieldProps(props);

  return (
    <NumberInput
      label={props.label}
      data-testid={name}
      onChange={onChange}
      isRequired={isRequired}
      errorMessage={error?.message}
      tooltip={tooltip}
      onFocus={onFocus}
      onBlur={onBlur}
      decimalPlaces={decimalPlaces ?? DEFAULT_DECIMAL_PLACES}
      value={value || ''}
      isDisabled={props.isReadOnly}
    />
  );
}
