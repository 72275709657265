import {DataStatus} from 'platform/components';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {any, head, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Main} from '@dms/shared';
import {
  loadVehicleDetailsData,
  useThunkDispatch,
  getCondition,
  InspectionType,
  VehicleInspection,
  selectCarAudit,
} from '@dms/teas';

import {useFetchAuditStructure} from '../../hooks/useFetchAuditStructure';

export const Conditions: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId} = useParams();

  const {fetchAuditStructure} = useFetchAuditStructure();

  const {loading} = useSelector(selectCarAudit);

  const isAuditLoading = any(isTrue, [
    loading.getCondition,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(() => {
        dispatch(
          getCondition.action({
            requestBody: {vehicleIds: [vehicleId]},
            inspectionType: InspectionType.vehicleCondition,
          })
        )
          .unwrap()
          .then((response) => {
            if (isNotNil(response)) {
              fetchAuditStructure(head(response));
            }
          });
      });
    }
  }, [vehicleId]);

  return (
    <Main>
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <VehicleInspection shouldUseAuditIdForUpdateTemplate />
      </DataStatus>
    </Main>
  );
};
