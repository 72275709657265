import {isFeatureEnabled} from 'feature-flags';
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  selectActiveBranchId,
  useGetBranchQuery,
  useGetEmployeeCostCentersListQuery,
  useGetEmployeeMechanicsQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantQuery,
  usePostServiceItemsLabourSettingsMutation,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {
  getActiveOptions,
  getDecimalFromPercentage,
  handleApiError,
  useGetVatRatesOptions,
  useTenant,
  useWorkTypeOptions,
} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {WorkBasketItemType} from '../../types/WorkBasketItemType';
import {BulkEditItemFormType} from './types/BulkEditItemFormType';

interface EditItemProps extends TestIdProps {
  items: WorkBasketItemType[];
  serviceCaseId: string;
  serviceOrderId: string;
  serviceJobId: string;
  onClose: () => void;
}

export function BulkEditItem(props: EditItemProps) {
  const {tenantCurrency} = useTenant();
  const [vatOptions] = useGetVatRatesOptions();
  const [postServiceItemsLabourSettings, {isLoading: isItemsSettingsLoading}] =
    usePostServiceItemsLabourSettingsMutation();
  const {workTypeOptions} = useWorkTypeOptions();
  const branchId = useSelector(selectActiveBranchId);
  const {data: branchDetail} = useGetBranchQuery({branchId});
  const {data: order} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {mechanicOptions} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {
      selectFromResult: ({data}) => ({
        mechanics: data?.employees ?? [],
        mechanicOptions: data?.employees?.map((mechanic) => ({
          label: mechanic?.name,
          value: mechanic?.id,
        })),
      }),
      skip:
        isNil(orderVariant?.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );
  const {costCenterOptions} = useGetEmployeeCostCentersListQuery(
    {company: branchDetail?.billingInformation?.id ?? '', branch: branchDetail?.id ?? ''},
    {
      selectFromResult: ({data}) => ({
        costCenterOptions: getActiveOptions(data?.costCenters),
      }),
      skip:
        isNilOrEmpty(branchDetail?.billingInformation?.id) ||
        isNilOrEmpty(branchDetail?.id) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const handleSubmit: FormSubmitHandler<BulkEditItemFormType> = async (data) =>
    await postServiceItemsLabourSettings({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        items: props.items.map((item) => ({
          serviceJobId: props.serviceJobId,
          serviceItemId: item.id,
        })),
        itemSettings: {
          isMechanic: data.isMechanic,
          mechanicId: data.isMechanic ? data.mechanicId : null,
          isCostCenter: data.isCostCenter,
          costCenterId: data.isCostCenter ? data.costCenterId : null,
          isDiscount: data.isDiscount,
          discountRate: data.isDiscount ? getDecimalFromPercentage(data.discountRate) : null,
          isVatType: data.isVatType,
          vatType: data.isVatType ? data.vatType : null,
          isWorkType: data.isWorkType,
          workType: data.isWorkType ? data.workType : null,
          isPurchasePricePerUnit: data.isPurchasePricePerUnit,
          purchasePricePerUnit:
            data.isPurchasePricePerUnit && isNotNil(data.purchasePricePerUnit)
              ? {
                  isPriceWithVat: false,
                  amount: data.purchasePricePerUnit,
                  currency: tenantCurrency,
                }
              : undefined,
          isSellingPricePerUnit: data.isSellingPricePerUnit,
          sellingPricePerUnit:
            data.isSellingPricePerUnit && isNotNil(data.sellingPricePerUnit)
              ? {
                  isPriceWithVat: false,
                  amount: data.sellingPricePerUnit,
                  currency: tenantCurrency,
                }
              : undefined,
        },
      },
    })
      .unwrap()
      .then(() => {
        props.onClose();
      })
      .catch(handleApiError);

  const defaultValues = {
    isMechanic: false,
    isCostCenter: false,
    isDiscount: false,
    isVatType: false,
    isWorkType: false,
    isPurchasePricePerUnit: false,
    isSellingPricePerUnit: false,
  };

  return (
    <Form<BulkEditItemFormType> onSubmit={handleSubmit} defaultValues={defaultValues}>
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isMechanic"
                    data-testid={suffixTestId('isMechanic', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    type="choice"
                    control={control}
                    name="mechanicId"
                    label={i18n.t('entity.order.labels.mechanic')}
                    filterOption={(option) => formApi.watch('mechanicId') !== option.value}
                    options={mechanicOptions}
                    isDisabled={!formApi.watch('isMechanic')}
                    menuInPortal
                    data-testid={suffixTestId(`mechanicId`, props)}
                  />
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isCostCenter"
                    data-testid={suffixTestId('isCostCenter', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    type="choice"
                    control={control}
                    name="costCenterId"
                    label={i18n.t('entity.order.labels.costCenter')}
                    options={costCenterOptions}
                    isDisabled={!formApi.watch('isCostCenter')}
                    menuInPortal
                    data-testid={suffixTestId(`costCenter`, props)}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isDiscount"
                    data-testid={suffixTestId('isDiscount', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isDisabled={!formApi.watch('isDiscount')}
                    control={control}
                    type="number"
                    name="discountRate"
                    label={i18n.t('entity.orderItem.labels.manualDiscount')}
                    decimalPlaces={2}
                    suffix="%"
                    maxStepperValue={100}
                    minStepperValue={0}
                    data-testid={suffixTestId('discount', props)}
                  />
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isVatType"
                    data-testid={suffixTestId('isVatType', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isDisabled={!formApi.watch('isVatType')}
                    control={control}
                    type="choice"
                    name="vatType"
                    options={vatOptions}
                    label={i18n.t('entity.addWork.lables.vat')}
                    placeholder={i18n.t('general.labels.select')}
                    menuInPortal
                    data-testid={suffixTestId('vatType', props)}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <Alert
            type="inline"
            variant="info"
            title={i18n.t('entity.addWork.lables.timeNormsInfo')}
            data-testid={suffixTestId('timeNormsInfo', props)}
          />
          <HStack spacing={4}>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isWorkType"
                    data-testid={suffixTestId('isWorkType', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isDisabled={!formApi.watch('isWorkType')}
                    name="workType"
                    control={control}
                    type="choice"
                    options={workTypeOptions}
                    label={i18n.t('entity.addWork.lables.workCategory')}
                    placeholder={i18n.t('general.labels.select')}
                    menuInPortal
                    data-testid={suffixTestId('workType', props)}
                  />
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isPurchasePricePerUnit"
                    data-testid={suffixTestId('isPurchasePricePerUnit', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isDisabled={!formApi.watch('isPurchasePricePerUnit')}
                    currency={tenantCurrency}
                    name="purchasePricePerUnit"
                    type="currency"
                    control={control}
                    label={i18n.t('general.labels.purchasePrice')}
                    data-testid={suffixTestId('purchasePricePerUnit', props)}
                  />
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack align="center" spacing={5}>
                <Box paddingTop={5}>
                  <FormField
                    control={control}
                    type="switch"
                    name="isSellingPricePerUnit"
                    data-testid={suffixTestId('isSellingPricePerUnit', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isDisabled={!formApi.watch('isSellingPricePerUnit')}
                    currency={tenantCurrency}
                    name="sellingPricePerUnit"
                    type="currency"
                    control={control}
                    label={i18n.t('general.labels.sellingPrice')}
                    data-testid={suffixTestId('sellingPricePerUnit', props)}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <ButtonGroup align="right">
            <Button
              onClick={props.onClose}
              variant="secondary"
              title={i18n.t('general.actions.discard')}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              isDisabled={
                !(
                  formApi.watch('isMechanic') ||
                  formApi.watch('isCostCenter') ||
                  formApi.watch('isDiscount') ||
                  formApi.watch('isVatType') ||
                  formApi.watch('isWorkType') ||
                  formApi.watch('isPurchasePricePerUnit') ||
                  formApi.watch('isSellingPricePerUnit')
                )
              }
              control={control}
              type="submit"
              title={i18n.t('general.actions.saveChanges')}
              isLoading={isItemsSettingsLoading}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
