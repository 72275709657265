import {Card, DataStatus, Form, FormButton, FormField, showNotification} from 'platform/components';
import {Grid, Right, Space, Text} from 'platform/foundation';
import {object} from 'yup';

import {useGetTipcarsPlatformQuery, useTipcarsAuthenticateMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {AdvertisingPlatformCodeEnum} from '@dms/teas';

import {yupString} from 'shared';

import {useBranchId} from '../../../../hooks/useBranchId';
import {advertisingPlatformNames} from '../../consts/advertisingPlatformNames';

type PlatformAuthenticationFormTipCarsData = {
  companyCode: string;
  password: string;
};

export function PlatformAuthenticationFormTipCars() {
  const branchId = useBranchId();
  const params = {branchId};
  const {data, isLoading, isError} = useGetTipcarsPlatformQuery({params});
  const [saveAuthentication] = useTipcarsAuthenticateMutation();

  const onSubmitCredentials = (values: PlatformAuthenticationFormTipCarsData) =>
    saveAuthentication({
      body: values,
      params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Card
      title={`${i18n.t('general.labels.authentication')} – ${advertisingPlatformNames[AdvertisingPlatformCodeEnum.TIPCARS]}`}
    >
      <Text size="small" color="secondary">
        {i18n.t('page.settings.labels.authenticationDescription')}
      </Text>
      <Space vertical={4} />

      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<PlatformAuthenticationFormTipCarsData>
          defaultValues={data?.credentials}
          schema={schema}
          onSubmit={onSubmitCredentials}
        >
          {(control) => (
            <>
              <Grid columns={2}>
                <FormField
                  control={control}
                  name="companyCode"
                  type="text"
                  label={i18n.t('page.advertisement.labels.companyCode')}
                />
                <FormField
                  control={control}
                  name="password"
                  type="password"
                  label={i18n.t('general.labels.password')}
                />
              </Grid>

              <Space vertical={4} />
              <Right>
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                />
              </Right>
            </>
          )}
        </Form>
      </DataStatus>
    </Card>
  );
}

const schema = object({
  companyCode: yupString.required(),
  password: yupString.required(),
});
