import {format, parseISO} from 'date-fns';
import {Action, Card, DataStatus, Form, showNotification} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {indexBy, isNil, reject} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  InvoiceDocumentTypes,
  PaymentInfoResponseBody,
  useGetCorrectiveTaxDocumentPaymentListQuery,
  useGetCorrectiveTaxDocumentV4Query,
  useSendCorrectiveTaxDocumentMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, useCurrencies, useGetAccountingDocuments} from '@dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {CustomerAndSupplierDetail} from '../../components/CustomerAndSupplierDetail';
import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoiceDetailSubHeader} from '../../components/InvoiceDetailSubHeader';
import {ListOfItemsDetail} from '../../components/ListOfItemsDetail';
import {PaymentDatesDetail} from '../../components/PaymentDatesDetail';
import {PaymentDetailsDetail} from '../../components/PaymentDetailsDetail';
import {PaymentsListDetail} from '../../components/PaymentsListDetail';
import {PaymentsNotesDetail} from '../../components/PaymentsNotesDetail';
import {SectionsWithCenteredContent} from '../../components/SectionsWithCenteredContent';
import {invoicePaymentOptions} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {useDocumentActions} from '../../hooks/useDocumentActions';
import {AccountingDocumentFormValues} from '../../types';
import {getInvoiceCategoryFlagProps} from '../../utils/getInvoiceCategoryFlagProps';
import {getInvoiceStateFlagProps} from '../../utils/getInvoiceStateFlagProps';
import {getPaymentStateFlagProps} from '../../utils/getPaymentStateFlagProps';
import {CorrectiveTaxDocumentDetails} from './CorrectiveTaxDocumentDetails';

export function CorrectiveTaxDocumentDetail() {
  const {id: correctiveTaxDocumentId} = useRequiredParams();

  const {getCurrencyName} = useCurrencies();
  const [subheaderActions] = useDocumentActions('CORRECTIVE_TAX_DOCUMENT', correctiveTaxDocumentId);

  const [sendInvoice, {isLoading: isSendingDocument}] = useSendCorrectiveTaxDocumentMutation();

  const {
    data: correctiveTaxDocument,
    isLoading: isLoadingCorrectiveTaxDocument,
    isError: isCorrectiveTaxDocumentError,
  } = useGetCorrectiveTaxDocumentV4Query({correctiveTaxDocumentId});
  const {data: correctedDocument} = useGetAccountingDocuments(
    correctiveTaxDocument?.invoicingDocumentId,
    correctiveTaxDocument?.invoicingDocumentType?.replace('_', '-') as InvoiceDocumentTypes
  );

  const {
    data: paymentList,
    isLoading: isLoadingPaymentList,
    isError: isPaymentListError,
  } = useGetCorrectiveTaxDocumentPaymentListQuery({correctiveTaxDocumentId});

  const isLoading = isLoadingCorrectiveTaxDocument || isLoadingPaymentList;
  const isError = isCorrectiveTaxDocumentError || isPaymentListError;

  const bankAccountValue = isNotNilOrEmpty(correctiveTaxDocument?.paymentInfo?.bankAccount)
    ? reject(isNil, [
        correctiveTaxDocument?.paymentInfo?.bankAccount,
        correctiveTaxDocument?.paymentInfo?.bankName,
        correctiveTaxDocument?.currency,
      ]).join(', ')
    : null;

  const shouldDisplaySendButton =
    isNil(correctiveTaxDocument?.sentAt) &&
    isNil(correctiveTaxDocument?.cancelledAt) &&
    correctiveTaxDocument?.paymentState !== 'canceled';
  const paymentTypesByType = indexBy((item) => item.value, invoicePaymentOptions);
  const paymentMethodLabel = correctiveTaxDocument
    ? (paymentTypesByType[correctiveTaxDocument.paymentInfo.paymentMethod]
        .label as PaymentInfoResponseBody['paymentMethod'])
    : 'BANK_TRANSFER';

  const initialValues = correctiveTaxDocument
    ? {
        ...correctiveTaxDocument,
        paymentInfo: {
          ...correctiveTaxDocument.paymentInfo,
          bankAccount: bankAccountValue,
          paymentMethod: paymentMethodLabel,
        },
        footerNote: correctiveTaxDocument.behindItemsNote,
        exchangeRateRatio: {
          ...correctiveTaxDocument.exchangeRateRatio,
          amount: correctiveTaxDocument.exchangeRateRatio?.amount?.toString() ?? null,
          currency: getCurrencyName(correctiveTaxDocument.exchangeRateRatio?.currency),
          type: i18n.t('general.actions.recalculate'),
        },
      }
    : undefined;

  const handleSendInvoice = () => {
    if (isNil(correctiveTaxDocument)) {
      showNotification.error();
      return;
    }

    sendInvoice({correctiveTaxDocumentId: correctiveTaxDocument.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const invoiceHeaderActions = buildArray<Action>().when(shouldDisplaySendButton, {
    type: 'button',
    title: i18n.t('general.actions.send'),
    onClick: handleSendInvoice,
    isLoading: isSendingDocument,
    buttonType: 'submit',
  });

  const pageTitle = i18n.t('entity.correctiveTaxDocument.labels.correctiveTaxDocumentNumber', {
    documentNumber: correctiveTaxDocument?.number,
  });
  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
      <Show when={isNotNil(correctiveTaxDocument)}>
        <Helmet title={pageTitle} />

        <Form<AccountingDocumentFormValues> defaultValues={initialValues}>
          {(control, formApi) => (
            <InvoiceVatRateProvider
              control={control}
              listItemType="invoicing-documents"
              date={
                correctedDocument?.dateOfTaxableSupply
                  ? format(parseISO(correctedDocument?.dateOfTaxableSupply), 'yyyy-MM-dd')
                  : undefined
              }
            >
              <VStack>
                <Box backgroundColor="general.white">
                  <InvoiceDetailHeader title={pageTitle} actions={invoiceHeaderActions} />

                  <InvoiceDetailSubHeader
                    author={correctiveTaxDocument!.author}
                    actions={subheaderActions}
                    createdAt={correctiveTaxDocument!.createdAt}
                    data-testid={testIds.accounting.correctiveTaxDocumentDetail('subHeader')}
                    flags={reject(isNil, [
                      getInvoiceStateFlagProps(correctiveTaxDocument),
                      getPaymentStateFlagProps(correctiveTaxDocument!.paymentState),
                      getInvoiceCategoryFlagProps(correctiveTaxDocument?.invoiceCategory),
                    ])}
                  />
                </Box>

                <SectionsWithCenteredContent
                  data-testid="accounting-sections"
                  sections={[
                    {
                      content: (
                        <VStack spacing={4}>
                          <Card>
                            <VStack spacing={4}>
                              <CustomerAndSupplierDetail
                                customer={correctiveTaxDocument!.customer}
                                supplier={correctiveTaxDocument!.supplier}
                                additionalCustomer={correctiveTaxDocument?.additionalCustomer}
                                additionalFields={correctiveTaxDocument?.customFields}
                                data-testid={testIds.accounting.correctiveTaxDocumentDetail(
                                  'customer-and-supplier'
                                )}
                              />
                              <PaymentDatesDetail control={control} />
                            </VStack>
                          </Card>

                          <CorrectiveTaxDocumentDetails
                            control={control}
                            formApi={formApi}
                            correctiveTaxDocument={correctiveTaxDocument}
                            data-testid={testIds.accounting.correctiveTaxDocumentDetail(
                              'CorrectiveTaxDocumentDetails'
                            )}
                          />

                          <PaymentDetailsDetail control={control} />

                          <ListOfItemsDetail
                            items={correctiveTaxDocument!.items}
                            summary={correctiveTaxDocument!.documentSummary}
                            exchangeRateRatioCalculation={
                              correctiveTaxDocument!.exchangeRateRatioDocumentSummary
                            }
                            control={control}
                          />

                          <PaymentsNotesDetail control={control} />
                        </VStack>
                      ),
                      id: 'detail',
                      label: i18n.t('general.labels.details'),
                    },
                    {
                      content: (
                        <PaymentsListDetail
                          paymentItems={paymentList}
                          invoiceType="invoice"
                          data-testid={testIds.accounting.invoiceDetail('paymentList')}
                        />
                      ),
                      id: 'payments',
                      label: i18n.t('page.accounting.invoiceDetailPayments.title'),
                    },
                  ]}
                />
              </VStack>
            </InvoiceVatRateProvider>
          )}
        </Form>
      </Show>
    </DataStatus>
  );
}
