import {AttributesRow} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {useGetVehicleQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, buildArray} from 'shared';

import {useVehicleCatalogue} from '../../../hooks/useVehicleCatalogue';

export function useEngineVehicleParameters(vehicleId: string | Nullish) {
  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );

  const [vehicleUtils] = useVehicleCatalogue(vehicle?.type);

  const emmisionClass = vehicleUtils.getEmissionClass(vehicle?.engine?.emissionClass);

  const isFullHybrid = vehicle?.vehicle?.hybridType === 'HYBRIDTYPE_FULL';
  const isPhevHybrid = vehicle?.vehicle?.hybridType === 'HYBRIDTYPE_PHEV';
  const isElectricVehicle = vehicle?.fuelType === 'FUELTYPE_ELECTRIC';
  const isHydrogenVehicle = vehicle?.fuelType === 'FUELTYPE_HYDROGEN';
  const hasPetrolOdDieselFuel =
    vehicle?.fuelType === 'FUELTYPE_DIESEL' ||
    vehicle?.fuelType === 'FUELTYPE_PETROL' ||
    vehicle?.secondaryFuelType === 'FUELTYPE_DIESEL' ||
    vehicle?.secondaryFuelType === 'FUELTYPE_PETROL';

  return buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.engineName'),
      value: vehicle?.engine?.engineName,
      testId: 'engineName',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.engineNumber'),
      value: vehicle?.engine?.engineNumber,
      testId: 'engineNumber',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.engineCode'),
      value: vehicle?.engine?.engineCode,
      testId: 'engineCode',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.power'),
      value: vehicle?.power,
      testId: 'power',
    })
    .when(isFullHybrid || isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.powerCombustionEngine')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.engine?.powerCombustionEngine,
      testId: 'powerCombustionEngine',
    })
    .when(isFullHybrid || isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.powerElectricEngine')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.engine?.powerElectricEngine,
      testId: 'powerElectricEngine',
    })
    .when(!isHydrogenVehicle && !isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.engineCapacity')} (${i18n.t('general.metric.ccm')})`,
      value: vehicle?.engine?.engineVolume,
      testId: 'engineCapacity',
    })
    .when(vehicle?.fuelType, {
      label: i18n.t('entity.vehicle.labels.maxTorque'),
      value: vehicle?.vehicle?.maxTorque,
      testId: 'maxTorque',
    })
    .when(!isHydrogenVehicle && !isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.numberOfCylinders'),
      value: vehicle?.engine?.cylinderCount,
      testId: 'numberOfCylinders',
    })
    .when(vehicle?.fuelType, {
      label: i18n.t('entity.vehicle.labels.numberOfGears'),
      value: vehicle?.engine?.gearCount,
      testId: 'numberOfGears',
    })
    .when(vehicle?.fuelType, {
      label: `${i18n.t('entity.vehicle.labels.fuelConsumptionCombined')} (${i18n.t(
        'general.metric.l100km'
      )})`,
      value: vehicle?.engine?.fuelConsumptionCombined,
      testId: 'fuelConsumptionCombined',
    })
    .when(vehicle?.fuelType, {
      label: `${i18n.t('entity.vehicle.labels.fuelConsumptionUrban')} (${i18n.t(
        'general.metric.l100km'
      )})`,
      value: vehicle?.engine?.fuelConsumptionUrban,
      testId: 'fuelConsumptionUrban',
    })
    .when(vehicle?.fuelType, {
      label: `${i18n.t('entity.vehicle.labels.fuelConsumptionExtraUrban')} (${i18n.t(
        'general.metric.l100km'
      )})`,
      value: vehicle?.engine?.fuelConsumptionExtraUrban,
      testId: 'fuelConsumptionExtraUrban',
    })
    .when(isFullHybrid, {
      label: `${i18n.t('entity.vehicle.labels.powerConsumptionCombined')} (${i18n.t(
        'general.metric.kwh100km'
      )})`,
      value: vehicle?.engine?.powerConsumptionCombined,
      testId: 'powerConsumptionCombined',
    })
    .when(!isHydrogenVehicle && !isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.emissionClass'),
      value: emmisionClass,
      testId: 'emissionClass',
    })
    .when(!isHydrogenVehicle && !isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.emissionsCo2WO')} (${i18n.t('general.metric.gkm')})`,
      value: vehicle?.engine?.carbonDioxideEmission,
      testId: 'emissionsCo2WO',
    })
    .when(hasPetrolOdDieselFuel, {
      label: i18n.t('entity.vehicle.labels.dieselParticulateFilter'),
      value:
        (vehicle?.engine?.hasDpf && i18n.t('general.labels.yes')) ||
        (!isNil(vehicle?.engine?.hasDpf) && i18n.t('general.labels.no')),
      testId: 'dieselParticulateFilter',
    });
}
