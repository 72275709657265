import {Parameter} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetUserQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER, getUserName} from '@dms/shared';

import {buildArray, parseDate} from 'shared';

import {useInterestData} from './useInterestData';

export function useInterestParameters(interestId: string) {
  const {interest, readCode} = useInterestData(interestId);

  const formatDateTime = useDateTimeFormatter();

  const {data: updatedByUserData} = useGetUserQuery(
    {id: interest?.updatedBy!},
    {skip: isNilOrEmpty(interest?.updatedBy)}
  );

  return buildArray<Parameter>()
    .when(isNotNil(interest?.publicId), interest?.publicId)
    .add(
      `${i18n.t('general.labels.created')}: ${
        isNotNilOrEmpty(interest?.createdAt)
          ? formatDateTime('dateTimeShort', parseDate(interest!.createdAt!))
          : EMPTY_PLACEHOLDER
      }`
    )
    .when(
      isNotNilOrEmpty(interest?.updatedAt),
      `${i18n.t('general.labels.changed')}: ${interest?.updatedAt ? formatDateTime('dateTimeShort', parseDate(interest.updatedAt)) : null}`
    )
    .when(
      isNotNilOrEmpty(updatedByUserData) && isNotNilOrEmpty(interest?.updatedBy),
      `${i18n.t('general.labels.changedBy')}: ${getUserName(updatedByUserData)}`
    )
    .add(
      `${i18n.t('entity.interest.labels.source')}: ${readCode?.name ? readCode?.name : EMPTY_PLACEHOLDER}`
    );
}
