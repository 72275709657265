import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';

import {
  CustomerResponseBodyV2,
  useGetVehicleCustomerQuery,
  useLazyGetCustomerV2Query,
} from '@dms/api';

import {getNaturalPersonFullName} from '../../../utils/getNaturalPersonFullName';
import {isCustomerNaturalPerson} from '../../../utils/isCustomerNaturalPerson';
import {getOwnershipTitle} from '../utils/getOwnershipTitle';

interface VehicleCustomer extends CustomerResponseBodyV2 {
  fullName: string;
  ownershipTypeTranslated: string;
}

interface UseGetCustomersDetailsProps {
  vehicleId: string;
}

export function useGetCustomersDetails(props: UseGetCustomersDetailsProps) {
  const {data: vehicleCustomers} = useGetVehicleCustomerQuery({vehicleId: props.vehicleId});
  const [getCustomer] = useLazyGetCustomerV2Query();

  const [gdprCustomers, setGdprCustomers] = useState<VehicleCustomer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (!vehicleCustomers) {
        return;
      }

      setIsLoading(true);
      setIsError(false);

      try {
        const customers = await Promise.all(
          vehicleCustomers.map((customer) =>
            getCustomer({customerId: customer?.customerId ?? ''}, true).then(({data}) => data)
          )
        );

        const filteredCustomers = customers
          .filter(isNotNil)
          .filter(isCustomerNaturalPerson)
          .map((customer) => {
            const ownershipType = vehicleCustomers.find(
              (item) => item?.customerId === customer.id
            )?.customerType;

            return {
              ...customer,
              fullName: getNaturalPersonFullName(customer?.foundingPerson),
              ownershipTypeTranslated: ownershipType ? getOwnershipTitle(ownershipType) : '',
            } as VehicleCustomer;
          });

        setGdprCustomers(filteredCustomers);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [vehicleCustomers, getCustomer]);

  return {
    gdprCustomers,
    isLoading,
    isError,
  };
}
