import {Button, ButtonGroup, closeCurrentDialog} from 'platform/components';
import {Space, Text} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {JobType} from '../types/JobType';

interface NeededAtConfirmationDialogProps extends RequiredTestIdProps {
  data: JobType;
  reset: () => void;
  onSubmit: (job: JobType, reset: () => void) => Promise<void>;
}

export function NeededAtConfirmationDialog(props: NeededAtConfirmationDialogProps) {
  const [subbmitting, setSubmitting] = useState<'update' | 'dontUpdate' | null>(null);

  return (
    <>
      <Text alternative>{i18n.t('page.warehouse.labels.updateNeededAtDateOnExistingItems')}</Text>
      <Space vertical={2} />
      <ButtonGroup align="right" data-testid={suffixTestId('archiveLicense-confirmDialog', props)}>
        <Button
          data-testid={suffixTestId('confirmDialog-cancel', props)}
          title={i18n.t('general.actions.cancel')}
          variant="secondary"
          isDisabled={isNotNil(subbmitting)}
          onClick={() => {
            closeCurrentDialog();
          }}
        />
        <Button
          data-testid={suffixTestId('confirmDialog-dontUpdate', props)}
          title={i18n.t('entity.orderRequest.actions.dontUpdate')}
          variant="secondary"
          isDisabled={isNotNil(subbmitting)}
          isLoading={subbmitting === 'dontUpdate'}
          onClick={() => {
            setSubmitting('dontUpdate');

            props.onSubmit({...props.data, withItemNeededAtUpdate: false}, props.reset).then(() => {
              setSubmitting(null);
              closeCurrentDialog();
            });
          }}
        />
        <Button
          data-testid={suffixTestId('confirmDialog-update', props)}
          title={i18n.t('general.actions.update')}
          variant="primary"
          isDisabled={isNotNil(subbmitting)}
          isLoading={subbmitting === 'update'}
          onClick={() => {
            setSubmitting('update');

            props.onSubmit({...props.data, withItemNeededAtUpdate: true}, props.reset).then(() => {
              setSubmitting(null);
              closeCurrentDialog();
            });
          }}
        />
      </ButtonGroup>
    </>
  );
}
