import {DropdownItem, DropdownItemProps} from 'platform/components';
import {Icon} from 'platform/foundation';

import {ReactElement} from 'react';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

export type ActionEventType = 'docx' | 'pdf' | 'delete' | 'setPrimary' | 'edit' | 'hide' | 'unhide';

export type MenuActionProps = {
  isPrimary: boolean;
  isSystem: boolean;
  isHideable: boolean;
  isHidden: boolean;
  templateCode: string;
  downloadUrl: string;
  customTemplateId: string;
  fileName?: string;
};

export type TMenuActions = {
  isPrimary: boolean;
  isSystem: boolean;
  isHideable: boolean;
  isHidden: boolean;
  fileName?: string;
  handleActionEvent: (type: ActionEventType) => void;
};

export const menuActions = ({
  isPrimary,
  isSystem,
  isHideable,
  isHidden,
  handleActionEvent,
}: TMenuActions): Array<ReactElement<DropdownItemProps, typeof DropdownItem>> => {
  const actions = [
    <DropdownItem
      key="download"
      prefix={<Icon value="file/download" />}
      label={`${i18n.t('entity.document.labels.downloadAs')} DOCX`}
      data-testid={testIds.settings.documentTemplates('submenu-DOCX')}
      onClick={() => handleActionEvent('docx')}
    />,
    <DropdownItem
      key="primary"
      prefix={<Icon value="action/check_circle" />}
      label={i18n.t('entity.document.actions.setPrimary')}
      data-testid={testIds.settings.documentTemplates('submenu-setPrimary')}
      onClick={() => handleActionEvent('setPrimary')}
      isDisabled={isPrimary}
    />,
    <DropdownItem
      key="edit"
      prefix={<Icon value="image/edit" />}
      label={i18n.t('general.actions.edit')}
      data-testid={testIds.settings.documentTemplates('submenu-edit')}
      onClick={() => handleActionEvent('edit')}
      isDisabled={isSystem}
    />,
    <DropdownItem
      key="delete"
      prefix={<Icon value="action/delete" />}
      label={i18n.t('general.actions.delete')}
      data-testid={testIds.settings.documentTemplates('submenu-delete')}
      onClick={() => handleActionEvent('delete')}
      isDisabled={isSystem}
    />,
  ];

  if (!isPrimary && isHideable) {
    actions.splice(
      1,
      0,
      isHidden ? (
        <DropdownItem
          prefix={<Icon value="action/visibility" />}
          label={i18n.t('general.actions.unhide')}
          data-testid={testIds.settings.documentTemplates('submenu-unhide')}
          onClick={() => handleActionEvent('unhide')}
        />
      ) : (
        <DropdownItem
          prefix={<Icon value="action/visibility_off" />}
          label={i18n.t('general.actions.hide')}
          data-testid={testIds.settings.documentTemplates('submenu-hide')}
          onClick={() => handleActionEvent('hide')}
        />
      )
    );
  }

  return actions;
};
