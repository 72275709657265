import {format, parseISO} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {indexBy, isNil} from 'ramda';

import {
  CreateCashReceiptV4RequestBody,
  useCreateCashReceiptV4Mutation,
  useGetCashRegisterListQuery,
  useGetTenantQuery,
  useGetVatRatesQuery,
  useRenderExpenseCashReceiptDocumentMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {accountingRoutes, testIds} from '@dms/routes';
import {downloadFile, handleApiError, useGetCurrentBranch} from '@dms/shared';

import {getIsoStringWithoutTimezone} from 'shared';

import {BasicInformation} from '../../components/BasicInformation';
import {CashRegisterAlerts} from '../../components/CashRegisterAlerts';
import {CustomerAndSupplier} from '../../components/CustomerAndSupplier';
import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsForm} from '../../components/listOfItems/ListOfItemsForm';
import {DATE_FORMAT, getEmptyItem} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {AccountingDocumentFormValues} from '../../types';
import {$CreateExpenseCashReceiptSchema} from './$CreateExpenseCashReceiptSchema';

export function CreateExpenseCashReceipt() {
  const navigate = useNavigate();

  const {
    data: cashRegisters,
    isLoading: isLoadingCashRegisters,
    isError: isCashRegisterError,
  } = useGetCashRegisterListQuery({
    inactive: false,
    withValidIncomeSeries: true,
  });
  const cashRegistersById = indexBy((item) => item.id, cashRegisters ?? []);
  const {activeBranchId, data: currentBranch} = useGetCurrentBranch();

  const [createCashReceipt] = useCreateCashReceiptV4Mutation();
  const [renderCashReceipt] = useRenderExpenseCashReceiptDocumentMutation();

  const {data: tenant, isLoading: isLoadingTenant, isError: isGetTenantError} = useGetTenantQuery();
  const {isLoading: isLoadingVatRates, isError: isGetVatRatesError} = useGetVatRatesQuery();

  const isLoading = isLoadingCashRegisters || isLoadingTenant || isLoadingVatRates;
  const isError = isCashRegisterError || isGetTenantError || isGetVatRatesError;

  const handleSubmit: FormSubmitHandler<AccountingDocumentFormValues> = async (data) => {
    const cashRegisterCurrency = cashRegistersById[data.cashRegisterId ?? '']?.currency;
    const tenantCurrency = tenant?.currency;

    if (isNil(cashRegisterCurrency) || isNil(currentBranch) || isNil(tenantCurrency)) {
      showNotification.error();
      return;
    }

    const shouldSubmitExchangeRatio =
      isFeatureEnabled(featureFlags.SALES_FOREIGN_CURRENCY) &&
      cashRegisterCurrency !== tenantCurrency;

    const createCashRegisterDocumentRequestBody: CreateCashReceiptV4RequestBody = {
      cashRegisterId: data.cashRegisterId ?? '',
      issuedAt: getIsoStringWithoutTimezone(parseISO(data.issueDate)),
      items: data.invoiceItems.map((item) => ({
        description: item.description,
        unitPrice: {
          amount: item.pricePerUnit?.toString() ?? '0',
          currency: cashRegisterCurrency,
        },
        quantity: item.quantity.toString(),
        unit: item.unit ?? 'pcs',
        vatType: item.vatType,
        withVat: item.isUnitPriceWithVat,
      })),
      kindOfPayment: data.paymentType ?? 'expense/current_payment',
      paidByCard: data.paymentInfo.paymentMethod === 'CARD',
      type: 'expense',
      purpose: data.reason,
      customerId: data.customer?.id,
      note: data.footerNote,
      variableSymbol: data.paymentInfo.variableSymbol,
      relatedInvoicingDocumentId: null,
      relatedInvoicingDocumentType: null,
      branchId: activeBranchId,
      billingInformationId: currentBranch.billingInformation?.id ?? 's',
      exchangeRateRatio:
        shouldSubmitExchangeRatio &&
        data.exchangeRateRatio?.amount &&
        data?.exchangeRateRatio?.ratio
          ? {
              amount: parseFloat(data.exchangeRateRatio?.amount),
              ratio: data?.exchangeRateRatio?.ratio?.toString(),
              currency: cashRegisterCurrency,
            }
          : null,
      documentSource: {
        documentSource: 'internal',
        moduleSource: 'accounting',
        moduleSourceId: null,
        serviceSource: 'omnetic',
      },
    };

    await createCashReceipt({createCashRegisterDocumentRequestBody})
      .unwrap()
      .then(async (cashReceipt) => {
        if (data.downloadAfterCreation && cashReceipt) {
          await renderCashReceipt({
            renderExpenseCashReceiptDocumentRequestBody: {cashRegisterDocumentId: cashReceipt?.id},
          })
            .unwrap()
            .then((render) => render.pdfUrl)
            .then(downloadFile)
            .catch(handleApiError);
        }
      })
      .then(() => showNotification.success())
      .then(() => navigate(accountingRoutes.cashReceiptsOverview))
      .catch(handleApiError);
  };

  const defaultValues: Partial<AccountingDocumentFormValues> = {
    cashRegisterId: cashRegisters?.[0]?.id,
    paymentInfo: {
      paymentMethod: 'CASH',
    },
    paymentType: 'expense/current_payment',
    invoiceItems: [getEmptyItem({unit: 'pcs'})],
    dateOfTaxableSupply: format(new Date(), DATE_FORMAT),
    issueDate: format(new Date(), DATE_FORMAT),
  };

  const pageTitle = i18n.t('page.accounting.cashReceipt.newExpenseDocument');

  return (
    <>
      <Helmet title={pageTitle} />
      <DataStatus minHeight="80vh" isLoading={isLoading} isError={isError}>
        <Form<AccountingDocumentFormValues>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          schema={$CreateExpenseCashReceiptSchema}
        >
          {(control, formApi) => (
            <InvoiceVatRateProvider control={control} listItemType="cash-receipts">
              <VStack>
                <InvoiceDetailHeader
                  title={pageTitle}
                  actions={[
                    {
                      type: 'form-button',
                      'data-testid':
                        testIds.accounting.createCashReceiptExpense('createAndDownload'),
                      buttonType: 'submit',
                      title: i18n.t('page.accounting.cashRegister.createAndDownload'),
                      variant: 'secondary',
                      onClick: () => formApi.setValue('downloadAfterCreation', true),
                      control,
                    },
                    {
                      type: 'form-button',
                      buttonType: 'submit',
                      control,
                      title: i18n.t('general.actions.create'),
                      'data-testid': testIds.accounting.createCashReceiptExpense('submit'),
                    },
                  ]}
                />
                <CashRegisterAlerts type="expense" control={control} />

                <InvoicePage>
                  <Card>
                    <CustomerAndSupplier
                      control={control}
                      formApi={formApi}
                      data-testid={testIds.accounting.createCashReceiptExpense(
                        'customer-and-supplier'
                      )}
                    />
                  </Card>

                  <BasicInformation
                    control={control}
                    formApi={formApi}
                    documentType="expense"
                    data-testid={testIds.accounting.createCashReceiptExpense('basic-information')}
                  />

                  <ListOfItemsForm
                    control={control}
                    formApi={formApi}
                    listItemType="cash-receipts"
                    data-testid={testIds.accounting.createCashReceiptExpense('list-of-items')}
                  />

                  <Card>
                    <FormField
                      control={control}
                      name="footerNote"
                      type="textarea"
                      label={i18n.t('general.labels.note')}
                      data-testid={testIds.accounting.createCashReceiptExpense('note')}
                    />
                  </Card>
                </InvoicePage>
              </VStack>
            </InvoiceVatRateProvider>
          )}
        </Form>
      </DataStatus>
    </>
  );
}
