import {SourcingVehicleDetailResponseBody} from '@dms/api';
import {FeaturesType} from '@dms/teas';

/**
 * Return new array of vehicles with alphabetically sorted features for every vehicle in the array.
 * Every feature is enriched with `isAvailable` key to see if the feature can be displayed in the comparison table.
 */
export const sortAndUpdateVehicleFeatures = (
  vehicles: SourcingVehicleDetailResponseBody[]
): SourcingVehicleDetailResponseBody[] => {
  const vehiclesFeatures: {[key: string]: FeaturesType} = {};

  vehicles?.forEach(({features}) => {
    if (features) {
      Object.entries(features).forEach(([key, value]) => {
        vehiclesFeatures[key] = value;
      });
    }
  });

  const sortedVehiclesFeatures = Object.values(vehiclesFeatures).sort((a, b) =>
    (a.translation || '').localeCompare(b.translation || '')
  );

  return vehicles?.map((vehicle) => {
    const features: {[key: string]: FeaturesType} = {};

    sortedVehiclesFeatures.forEach((feature) => {
      if (vehicle?.features?.[feature.key]) {
        features[feature.key] = {
          ...vehicle.features[feature.key],
          isAvailable: true,
        };
      } else {
        features[feature.key] = {
          ...feature,
          isAvailable: false,
        };
      }
    });

    return {...vehicle, features};
  });
};
