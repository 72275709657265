import {GridItem, Box, Grid, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {path} from 'ramda';

import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {FullScreenModal} from '@dms/shared';

import {composePath} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

const EXTERNAL_FILTER_ID = 'warehouseMovementsFilter';

export function WarehouseMovements() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    if (actionKey !== 'vehicleDetail') {
      return;
    }

    const vehicleId = path<string>(['vehiclePublicId', 'value', 'id'], rowData);

    navigate(
      composePath(vehiclesRoutes.vehicleWarehouseInfo, {
        params: {
          id: vehicleId,
        },
      })
    );
  };

  const close = () => {
    navigate(vehiclesRoutes.vehicleWarehouses);
  };

  return (
    <>
      <Helmet title={i18n.t('page.vehicles.warehouseMovements')} />
      <FullScreenModal
        headline={i18n.t('page.vehicles.warehouseMovements')}
        actions={[
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: close,
            'data-testid': testIds.vehicles.create('header-close-action'),
          },
        ]}
      >
        <Box padding={4} height="100%">
          <VStack spacing={4} height="100%">
            <Grid columns={3}>
              <GridItem span={2}>
                <div id={EXTERNAL_FILTER_ID} />
              </GridItem>
            </Grid>
            <Box height="100%">
              <DataGrid
                gridCode="vehicle-warehouse-movement"
                externalFilterId={EXTERNAL_FILTER_ID}
                actionCallback={actionCallback}
              />
            </Box>
          </VStack>
        </Box>
      </FullScreenModal>
    </>
  );
}
