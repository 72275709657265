import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {
  BaseAuthorizationProfile,
  BranchListResponseBody,
  GetManufacturersResponse,
  TenantResponseBody,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  getOptionsFromAuthorizationProfiles,
  getOptionsFromBranches,
  getOptionsFromManufacturers,
} from '@dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {getOptionsFromBillingInformations} from '../utils/getOptionsFromBillingInformations';

interface GeneralFormProps extends TestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  branches: BranchListResponseBody | Nullish;
  tenant: TenantResponseBody | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  manufacturers: GetManufacturersResponse | Nullish;
  onAuthorizationProfileChange: (authorizationProfileId: string | number | null) => void;
  isAuthorizationProfileFetching: boolean;
}

export function GeneralForm(props: GeneralFormProps) {
  return (
    <VStack spacing={6}>
      <Heading size={3}>{i18n.t('general.labels.general')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="name"
            type="text"
            label={i18n.t('general.labels.name')}
            isRequired
            data-testid={suffixTestId('inputs.name', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="warehouseNumber"
            type="text"
            label={i18n.t('entity.warehouse.labels.code')}
            data-testid={suffixTestId('inputs.warehouseNumber', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="authorizationProfileId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.authorizationProfile')}
            options={getOptionsFromAuthorizationProfiles(props.authorizationProfiles)}
            onChange={props.onAuthorizationProfileChange}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.authorizationProfile', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="companyBillingInfoId"
            type="choice"
            label={i18n.t('general.labels.company')}
            options={getOptionsFromBillingInformations(props.tenant?.billingInformation)}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.companyBillingInfoId', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="branchId"
            type="choice"
            label={i18n.t('entity.branch.labels.branch')}
            options={getOptionsFromBranches(props.branches)}
            isLoading={props.isAuthorizationProfileFetching}
            isRequired
            isNotClearable
            isDisabled
            data-testid={suffixTestId('inputs.branchId', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="supportedMake"
            type="choice"
            label={i18n.t('entity.warehouse.labels.make')}
            data-testid={suffixTestId('inputs.supportedMake', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            name="defaultManufacturerId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.defaultManufacturer')} // PHRASE
            options={getOptionsFromManufacturers(props.manufacturers)}
            data-testid={suffixTestId('inputs.defaultManufacturer', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
