import {DropdownItem, openDialog} from 'platform/components';
import {Icon, Text} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {selectAutoFillTemplates} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, useRequiredParams} from 'shared';

import {InspectionIdForAuditId} from '../../../types/InspectionIdForAuditId';
import {CreateAutoFillTemplateForm} from './CreateAutoFillTemplateForm';
import {DeleteAutoFillTemplateForm} from './DeleteAutoFillTemplateForm';

interface AutoFillTemplatesActionsProps extends InspectionIdForAuditId {
  vehicleId: string | Nullish;
  inspectionId: string | Nullish;
}

export function AutoFillTemplatesActions(props: AutoFillTemplatesActionsProps) {
  const params = useRequiredParams();
  const vehicleId = props.vehicleId ?? params.id;
  const {data: autoFillTemplates = []} = useSelector(selectAutoFillTemplates);

  return (
    <>
      <DropdownItem
        key="newTemplate"
        prefix={<Icon value="content/save" />}
        label={i18n.t('general.actions.autofillTemplates.saveTemplate')}
        onClick={() =>
          openDialog(
            <CreateAutoFillTemplateForm
              vehicleId={vehicleId}
              auditId={props.auditId}
              inspectionId={props.inspectionId}
              shouldUseAuditIdForUpdateTemplate={props.shouldUseAuditIdForUpdateTemplate}
            />,
            {
              title: i18n.t('entity.autofillTemplates.newTemplateDialog.title'),
              size: 'small',
            }
          )
        }
        data-testid="templatesMenuItem-newTemplate"
      />
      {autoFillTemplates && autoFillTemplates?.length > 0 && (
        <DropdownItem
          key="deleteTemplate"
          prefix={<Icon value="action/delete" color="severity.danger" />}
          label={
            <Text size="small" color="danger">
              {i18n.t('general.actions.autofillTemplates.deleteTemplate')}
            </Text>
          }
          onClick={() =>
            openDialog(<DeleteAutoFillTemplateForm />, {
              title: i18n.t('entity.autofillTemplates.deleteDialog.title'),
              size: 'small',
              scrollBehavior: 'outside',
            })
          }
          data-testid="templatesMenuItem-deleteTemplate"
        />
      )}
    </>
  );
}
