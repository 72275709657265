import {sub} from 'date-fns';
import {Button, Card, FormControl, FormField, IconButton, Tooltip} from 'platform/components';
import {Box, HStack, Icon, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {FocusEventHandler} from 'react';
import {useFieldArray, UseFormReturn} from 'react-hook-form';

import {isNotNil} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {Nullish, parseDate} from 'shared';

import {DocumentSeriesFormValues} from '../types/DocumentSeriesFormValues';
import {getEmptyItem} from '../utils/getEmptyItem';
import {getFormattedStartingNumber} from '../utils/getFormattedStartingNumber';
import {getRawStartingNumber} from '../utils/getRawStartingNumber';
import {ButtonWrapper, TooltipWrapper} from './styles';

interface PatternsListProps {
  control: FormControl<DocumentSeriesFormValues>;
  formApi: UseFormReturn<DocumentSeriesFormValues>;
}

export function PatternsList(props: PatternsListProps) {
  const formatDateTime = useDateTimeFormatter();

  const {fields, append, remove} = useFieldArray<DocumentSeriesFormValues>({
    control: props.control,
    name: 'patterns',
  });

  const getDateRange = (from: Date | Nullish, nextFrom: Date | Nullish) => {
    if (!from || !nextFrom) {
      return null;
    }

    const to = sub(nextFrom, {days: 1});
    return `${formatDateTime('dateShort', from)} - ${formatDateTime('dateShort', to)}`;
  };

  return (
    <Card variant="inlineGrey">
      <VStack spacing={2}>
        {fields?.map((field, index) => {
          const currentItem = props.formApi.watch(`patterns.${index}`);
          const nextItemDateFrom = props.formApi.watch(`patterns.${index + 1}.from`);

          const onFocus: FocusEventHandler<HTMLInputElement> = () => {
            props.formApi.setValue(
              `patterns.${index}.startingNumber`,
              getRawStartingNumber(currentItem)
            );
          };

          const onBlur = () => {
            props.formApi.setValue(
              `patterns.${index}.startingNumber`,
              getFormattedStartingNumber(currentItem, true)
            );
          };

          const onCalculateNewStartingNumber = () => {
            props.formApi.setValue(
              `patterns.${index}.startingNumber`,
              getFormattedStartingNumber(currentItem)
            );
          };

          const onStartingNumberChange = (value: string | null) => {
            props.formApi.setValue(
              `patterns.${index}.startingNumber`,
              String(parseInt(value || '') || '')
            );
          };

          return (
            <HStack spacing={4} key={field.id}>
              <Box flex={1}>
                <FormField
                  control={props.control}
                  name={`patterns.${index}.prefix`}
                  onChange={onCalculateNewStartingNumber}
                  type="text"
                  isDisabled={field.state !== 'not_used'}
                  label={i18n.t('entity.documentSeries.item.prefix')}
                  data-testid={testIds.settings.seriesDefinitionEdit(`patterns.${index}.prefix`)}
                  isRequired
                />
              </Box>

              <Box flex={2}>
                <FormField
                  control={props.control}
                  name={`patterns.${index}.startingNumber`}
                  type="text"
                  isDisabled={field.state !== 'not_used'}
                  label={i18n.t('entity.documentSeries.item.startingNumber')}
                  data-testid={testIds.settings.seriesDefinitionEdit(
                    `patterns.${index}.startingNumber`
                  )}
                  onChange={onStartingNumberChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  isRequired
                />
              </Box>

              <Box flex={1}>
                <VStack>
                  <FormField
                    name={`patterns.${index}.length`}
                    control={props.control}
                    type="number"
                    isDisabled={field.state !== 'not_used'}
                    data-testid={testIds.settings.seriesDefinitionEdit(`patterns.${index}.length`)}
                    label={i18n.t('entity.documentSeries.item.length')}
                    isStepperVisible
                    isRequired
                    onChange={onCalculateNewStartingNumber}
                  />
                </VStack>
              </Box>

              <Box flex={2}>
                <HStack spacing={1} align="flex-end">
                  <FormField
                    control={props.control}
                    name={`patterns.${index}.from`}
                    type="date"
                    isDisabled={field.state !== 'not_used'}
                    data-testid={testIds.settings.seriesDefinitionEdit(`patterns.${index}.from`)}
                    label={i18n.t('entity.documentSeries.item.from')}
                    isRequired
                  />

                  <IconButton
                    priority="tertiary"
                    icon="action/delete"
                    onClick={() => remove(index)}
                    data-testid={testIds.settings.seriesDefinitionEdit(
                      `patterns.${index}.deleteButton`
                    )}
                    isDisabled={field.state !== 'not_used'}
                  />
                  <Box width={6}>
                    <Show when={isNotNil(nextItemDateFrom)}>
                      <Tooltip
                        label={getDateRange(parseDate(field.from), parseDate(nextItemDateFrom))}
                      >
                        <Box paddingVertical={1}>
                          <TooltipWrapper>
                            <Icon value="action/info_outline" />
                          </TooltipWrapper>
                        </Box>
                      </Tooltip>
                    </Show>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          );
        })}

        <ButtonWrapper>
          <Button
            data-testid={testIds.settings.seriesDefinitionEdit('addButton')}
            variant="link"
            leftIcon="content/add_circle"
            onClick={() => append(getEmptyItem())}
            title={i18n.t('entity.documentSeries.items.addSeries')}
          />
        </ButtonWrapper>
      </VStack>
    </Card>
  );
}
