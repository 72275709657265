import {Menu, MenuItem} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {ReactNode} from 'react';

import {useSectionNavigation} from '@dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {INVOICE_FORM_MAX_WIDTH} from '../constants';

const maxSafeInteger = Number.MAX_SAFE_INTEGER ?? 9007199254740991;

export type SectionItem = {
  content: ReactNode;
} & MenuItem;

export interface SectionsProps extends RequiredTestIdProps {
  sections: SectionItem[];
}

const SIDE_MENU_WIDTH = 54;

export function SectionsWithCenteredContent(props: SectionsProps) {
  const {onSectionChange, activeSectionId} = useSectionNavigation(props.sections[0].id);

  const getSectionById = props.sections.find(({id}) => id === activeSectionId);

  return (
    <HStack justify="space-between">
      <Show when={props.sections}>
        <Box width={SIDE_MENU_WIDTH} flexGrow={1}>
          {props.sections && (
            <Box paddingVertical={4} paddingLeft={4}>
              <Menu
                items={props.sections}
                activeItemId={activeSectionId}
                onItemChange={onSectionChange}
                data-testid={suffixTestId('page-navigation', props)}
              />
            </Box>
          )}
        </Box>
      </Show>
      <Box padding={4} width={INVOICE_FORM_MAX_WIDTH}>
        {getSectionById?.content}
      </Box>
      <Box width={SIDE_MENU_WIDTH} flexShrink={maxSafeInteger} flexGrow={1} />
    </HStack>
  );
}
