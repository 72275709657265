import {isFeatureEnabled} from 'feature-flags';
import {ParsedUrlQuery} from 'querystring';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {useRouter} from '@dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BillingInformationDetail as BillingInformationDetailV2} from '../../pages/BillingInformationDetail/BillingInformationDetail';
import {BillingInformationDetail} from './components/BillingInformationDetail';
import {getBackBreadcrumb} from './utils';

export function BillingInfoEdit() {
  const router = useRouter();
  const id = router.params?.id as string;
  const breadcrumb = getBackBreadcrumb(router.params as ParsedUrlQuery);

  if (isFeatureEnabled(featureFlags.TENANT_V2)) {
    return <BillingInformationDetailV2 />;
  }

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.customer.actions.editBillingInformation'),
        breadcrumbs: [breadcrumb],
      }}
      data-testid="billing-information-detail-settings-modal"
    >
      <BillingInformationDetail billingInformationId={id} />
    </SettingsTemplate>
  );
}
