import {TextInput} from 'platform/components';
import {useCurrencySymbolFormatter} from 'platform/locale';

import {FC} from 'react';
import {Field as FinalFormField, FieldMetaState} from 'react-final-form';

import {isNil} from 'ramda';

import {Money} from '@dms/api';

import {Nullish, TestIdProps, CurrencyCodeType} from 'shared';

import {FormFieldWrapper} from '../FinalForm/components/FormFieldWrapper';

type MoneyFieldProps = {
  name: string;
  label?: string | Nullish;
  getIsValid: (meta: FieldMetaState<unknown>) => boolean;
  getErrorMessage: (meta: FieldMetaState<unknown>) => string | undefined;
  onChange?: (value: Money) => void;
  commonFieldProps?: {
    isRequired?: boolean;
    isDisabled?: boolean;
  } & TestIdProps;
  helperText?: string;
} & TestIdProps;
/**
 * @deprecated - use platform instead
 */
export const MoneyField: FC<MoneyFieldProps> = ({
  name,
  label,
  commonFieldProps = {},
  getIsValid,
  getErrorMessage,
  onChange,
  helperText,
  ...props
}) => {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const format = (value?: Money): Money => ({
    currency: value?.currency || '',
    amount: value?.amount !== '' && !isNil(value?.amount) ? Number(value?.amount).toFixed(2) : '',
  });

  return (
    <FinalFormField<Money> name={name} format={format} formatOnBlur>
      {({input, meta}) => (
        <FormFieldWrapper data-testid={props['data-testid']}>
          <TextInput
            {...input}
            data-testid={props['data-testid']}
            {...commonFieldProps}
            label={label}
            isInvalid={!getIsValid(meta)}
            isDisabled={commonFieldProps.isDisabled}
            isRequired={commonFieldProps.isRequired}
            value={input.value?.amount || ''}
            helperText={helperText}
            onChange={(value) => {
              let v = '';

              const filteredCharacters = (isNil(value) ? '' : (`${value}` as string))
                .replace(',', '.')
                .replace(/[^0-9|.|-]/g, '');

              if (filteredCharacters !== '' && !isNaN(Number(filteredCharacters))) {
                v = filteredCharacters.toString() as string;
              }

              onChange?.({
                amount: v,
                currency: input.value?.currency,
              });
              input.onChange({
                amount: v,
                currency: input.value?.currency,
              });
            }}
            suffix={
              input.value?.currency
                ? formatCurrencySymbol(input.value.currency as CurrencyCodeType)
                : undefined
            }
            errorMessage={getErrorMessage(meta)}
          />
        </FormFieldWrapper>
      )}
    </FinalFormField>
  );
};
