import {
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Grid, GridItem, HStack, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useEffect, useState} from 'react';

import {defaultTo, isNil, isNotNil} from 'ramda';

import {
  GetManufacturersResponse,
  GetWarehouseHandlingUnitsResponse,
  PostArticleRequest,
  useGetArticleCategoryListQuery,
  useGetTreeFolderQuery,
  useGetWarehouseAccountsQuery,
  usePostArticleMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  getOptionsFromWarehouseAccounts,
  getOptionsFromManufacturers,
  handleApiError,
} from '@dms/shared';

import {Nullish, TestIdProps, suffixTestId, yupString} from 'shared';

import {TreeFolderPath} from '../../../../../components/TreeFolderPath';
import {getOptionsFromHandlingUnits} from '../../../../../utils/getOptionsFromHandlingUnits';

interface ArticleFormProps extends TestIdProps {
  warehouseId: string | Nullish;
  searchedCatalogueNumber: string | null;
  searchedManufacturerId: string | null;
  onCreateArticle: (articleId: string, wasCreated: boolean) => Promise<void>;
  manufacturers: GetManufacturersResponse;
  handlingUnit: string | Nullish;
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish;
  areHandlingUnitsLoading: boolean;
}

type ArticleFormType = {
  manufacturerNumber: string;
  manufacturerId: string;
  name: string;
  warehouseAccount: string;
  storageLocation: string;
  handlingUnit: string;
  leafId: string;
};

export function ArticleForm(props: ArticleFormProps) {
  const [leafId, setLeafId] = useState<string | Nullish>(null);
  const [leafIdErrorMessage, setLeafIdErrorMessage] = useState<string | undefined>(undefined);

  const {data: warehouseAccounts, isLoading: areWarehouseAccountsLoading} =
    useGetWarehouseAccountsQuery();

  const {data: categories, isLoading: areCategoriesLoading} = useGetArticleCategoryListQuery();

  const defaultCategoryId = categories?.categories?.find((category) => category.isDefault)?.id;

  const {data: defaultTreeFolder, isLoading: isDefaultTreeFolderLoading} = useGetTreeFolderQuery(
    {contextTarget: 'spare-parts-catalog', contextId: defaultCategoryId ?? ''},
    {skip: isNil(defaultCategoryId)}
  );

  const [createArticle] = usePostArticleMutation();

  const isLoading =
    props.areHandlingUnitsLoading || areCategoriesLoading || isDefaultTreeFolderLoading;

  useEffect(() => {
    if (isNotNil(leafId) || isNil(defaultTreeFolder)) {
      return;
    }

    setLeafId(defaultTreeFolder?.treeFolder?.id);
  }, [defaultTreeFolder, leafId]);

  const handleSubmit: FormSubmitHandler<ArticleFormType> = async (formValues) => {
    if (isNil(leafId)) {
      return;
    }

    const requestBody: PostArticleRequest['body'] = {
      manufacturerNumber: formValues.manufacturerNumber,
      manufacturerId: formValues.manufacturerId,
      name: formValues.name,
      warehouseAccount: formValues.warehouseAccount,
      storageLocation: formValues.storageLocation,
      handlingUnit: formValues.handlingUnit,
      treeFolder: {leafId},
    };

    await createArticle({warehouseId: props.warehouseId as string, body: requestBody})
      .unwrap()
      .then((postResult) => {
        props.onCreateArticle(postResult.articleId, true);
      })
      .catch(handleApiError);
  };

  const handleChangeCategory = (activeFolderId: string | undefined) => {
    setLeafIdErrorMessage(undefined);
    setLeafId(activeFolderId);
  };

  const handleValidateCategory = () => {
    if (leafId) {
      return;
    }
    setLeafIdErrorMessage(i18n.t('general.validations.fieldIsRequired'));
  };

  const defaultValues: Partial<ArticleFormType> = {
    manufacturerNumber: defaultTo('', props.searchedCatalogueNumber),
    manufacturerId: defaultTo('', props.searchedManufacturerId),
    handlingUnit: defaultTo('', props.handlingUnit),
  };

  return (
    <DataStatus isLoading={isLoading}>
      <Form<ArticleFormType>
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <VStack spacing={4} data-testid={props['data-testid']}>
            <Card variant="inlineGrey" title={i18n.t('entity.warehouse.labels.articleDetail')}>
              <Grid columns={4}>
                <GridItem span={2}>
                  <FormField
                    type="text"
                    name="manufacturerNumber"
                    label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                    control={control}
                    isRequired
                    data-testid={suffixTestId('inputs.manufacturerNumber', props)}
                  />
                </GridItem>
                <GridItem span={2}>
                  <FormField
                    type="choice"
                    name="manufacturerId"
                    label={i18n.t('entity.warehouse.labels.manufacturer')}
                    options={getOptionsFromManufacturers(props.manufacturers)}
                    control={control}
                    isRequired
                    isNotClearable
                    menuInPortal
                    data-testid={suffixTestId('inputs.manufacturer', props)}
                  />
                </GridItem>
                <GridItem span={2}>
                  <FormField
                    type="text"
                    name="name"
                    label={i18n.t('entity.warehouse.labels.name')}
                    control={control}
                    isRequired
                    data-testid={suffixTestId('inputs.name', props)}
                  />
                </GridItem>
                <GridItem span={2}>
                  <FormField
                    type="choice"
                    name="warehouseAccount"
                    label={i18n.t('entity.warehouse.labels.warehouseAccount')}
                    options={getOptionsFromWarehouseAccounts(warehouseAccounts)}
                    control={control}
                    isLoading={areWarehouseAccountsLoading}
                    menuInPortal
                    data-testid={suffixTestId('inputs.warehouseAccount', props)}
                  />
                </GridItem>
                <GridItem span={1}>
                  <FormField
                    type="text"
                    name="storageLocation"
                    label={i18n.t('entity.warehouse.labels.storageLocation')}
                    control={control}
                    data-testid={suffixTestId('inputs.storageLocation', props)}
                  />
                </GridItem>
                <GridItem span={1}>
                  <FormField
                    type="choice"
                    name="handlingUnit"
                    label={i18n.t('entity.warehouse.labels.unit')}
                    options={getOptionsFromHandlingUnits(props.handlingUnits)}
                    control={control}
                    menuInPortal
                    data-testid={suffixTestId('inputs.unit', props)}
                  />
                </GridItem>
                <GridItem span={4}>
                  <TreeFolderPath
                    leafId={leafId}
                    onChange={(activeFolder) => handleChangeCategory(activeFolder?.id)}
                    invalidMessage={leafIdErrorMessage}
                    isRequired
                    data-testid={suffixTestId('inputs.category', props)}
                  />
                </GridItem>
              </Grid>
            </Card>
            <HStack>
              <FormButton
                type="submit"
                variant="link"
                size="small"
                title={i18n.t('entity.warehouse.actions.createNewArticle')}
                leftIcon="content/add_circle"
                control={control}
                onClick={handleValidateCategory}
                data-testid={suffixTestId('actions.createNewArticle', props)}
              />
            </HStack>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  manufacturerNumber: yupString.required(),
  manufacturerId: yupString.required(),
  name: yupString.required(),
});
