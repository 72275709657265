import {
  Button,
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, HStack, Link, Space, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';

import {
  buildUrl,
  getWorkspaceFromUri,
  LoginApiArg,
  RefreshTokenApiArg,
  useLoginMutation,
  useRefreshTokenMutation,
} from '@dms/api';
import {appWorkspaceKey, browserStorageKey, document360, redirectLink} from '@dms/config';
import i18n from '@dms/i18n';
import {loginRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {AuthContainer} from '../../components/AuthContainer/AuthContainer';
import {useDoc360Login} from './useDoc360Login';

const {workspace} = getWorkspaceFromUri();

type LoginForm = {
  email: string;
  password: string;
};

export function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const navigateToLastKnownPage = useCallback(() => {
    const lastRoute = sessionStorage.getItem(redirectLink);

    if (isNotNil(lastRoute)) {
      navigate(lastRoute);
      sessionStorage.removeItem(redirectLink);
    } else {
      navigate('/');
    }
  }, [navigate]);

  const [login, {isLoading}] = useLoginMutation();

  const isDoc360Login = searchParams.has(document360);
  const loginToDoc360 = useDoc360Login();
  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    const getUsersLastknownRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);

    if (isNil(getUsersLastknownRefreshToken) || isNil(workspace)) {
      return;
    }

    if (isDoc360Login) {
      loginToDoc360();
      return;
    }

    const refreshTokenArgs: RefreshTokenApiArg = {
      workspace: workspace!,
      refreshTokenRequestBody: {
        refreshToken: getUsersLastknownRefreshToken,
      },
    };

    refreshToken(refreshTokenArgs)
      .unwrap()
      .then((response) => {
        if (response && response.refreshToken && response.token) {
          sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, response.token);
          localStorage.setItem(browserStorageKey.REFRESH_TOKEN, response.refreshToken);

          navigateToLastKnownPage();
        }
      })
      .catch((ex) => {
        if (ex.status === 401) {
          return;
        }
        handleApiError(ex);
      });
  }, [isDoc360Login, loginToDoc360, navigate, navigateToLastKnownPage, refreshToken, searchParams]);

  const onSubmit: FormSubmitHandler<LoginForm> = async ({email, password}, setErrors) => {
    const arg: LoginApiArg = {
      workspace: workspace!,
      loginRequestBody: {
        email,
        password,
      },
    };
    await login(arg)
      .unwrap()
      .then((response) => {
        if (response.token && response.refreshToken) {
          sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, response.token);
          localStorage.setItem(browserStorageKey.REFRESH_TOKEN, response.refreshToken);

          if (isDoc360Login) {
            loginToDoc360();
          } else {
            navigateToLastKnownPage();
          }
        }
      })
      .catch((ex) => {
        if (ex.status === 401 || ex.status === 400) {
          const msg =
            ex.data?.error?.message || i18n.t('page.login.notifications.wrongCredentials');
          setErrors([
            {
              name: 'password',
              message: msg,
            },
          ]);
          showNotification.error(msg);
          return;
        }
        showNotification.error(
          ex.data?.error?.message || i18n.t('page.login.notifications.loginFailure')
        );
      });
  };

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.login.actions.logIn')}</title>
      </Helmet>

      <AuthContainer>
        <Form onSubmit={onSubmit} schema={schema}>
          {(control) => (
            <VStack spacing={4}>
              <Card variant="inlineGrey">
                <Text size="xSmall" color="secondary">
                  {i18n.t('page.login.labels.workspace')}
                </Text>
                <Space vertical={1} />
                <HStack justify="space-between">
                  <Text alternative size="small">
                    {workspace}
                  </Text>
                  <Link
                    href={buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey)}
                    size="small"
                    data-testid={testIds.login.login('link')}
                    title={i18n.t('general.actions.change')}
                  />
                </HStack>
              </Card>
              <FormField
                name="email"
                type="email"
                isRequired
                autoComplete="on"
                control={control}
                label={i18n.t('general.labels.email')}
                data-testid={testIds.login.login('email')}
              />
              <FormField
                name="password"
                type="password"
                isRequired
                control={control}
                label={i18n.t('general.labels.password')}
                data-testid={testIds.login.login('password')}
              />
              <Grid columns={2} data-testid={testIds.login.login('actions')}>
                <Button
                  data-testid={testIds.login.login('redirect-forgotPasswordButton')}
                  variant="ghost"
                  isDisabled={isLoading}
                  onClick={() => navigate(loginRoutes.loginForgotPassword)}
                  title={i18n.t('page.login.labels.forgotPassword')}
                />
                <FormButton
                  control={control}
                  data-testid={testIds.login.login('submitButton')}
                  type="submit"
                  isLoading={isLoading}
                  title={i18n.t('page.login.actions.logIn')}
                />
              </Grid>
            </VStack>
          )}
        </Form>
      </AuthContainer>
    </>
  );
}

const schema = Yup.object().shape({
  email: Yup.string()
    .max(64, i18n.t('general.notifications.errorEmailMax'))
    .required(i18n.t('general.notifications.errorEmailRequired'))
    .nullable(),
  password: Yup.string()
    .required(i18n.t('general.notifications.errorPasswordsRequired'))
    .nullable(),
});
