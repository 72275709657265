import styled from 'styled-components';

import {TypographyCard} from '@dms/teas';

export const CardFullWidth = styled(TypographyCard)`
  width: 100%;
`;

export const ActionButtonsColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${({theme}) => theme.getSize(2)};
`;
