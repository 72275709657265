import {Button, openConfirmDialog, Separator, Tooltip} from 'platform/components';
import {Heading, Hide, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {
  useDisableVehicleWarehouseManagementMutation,
  useEnableVehicleWarehouseManagementMutation,
  useGetVehicleWarehouseManagementSettingsQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, usePermissions} from '@dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {VehicleWarehouseGrid} from './components/VehicleWarehouseGrid';

export function VehicleWarehouseManagement() {
  const navigate = useNavigate();

  const {data: settings} = useGetVehicleWarehouseManagementSettingsQuery();
  const [enableVehicleWarehouses] = useEnableVehicleWarehouseManagementMutation();
  const [disableVehicleWarehouses] = useDisableVehicleWarehouseManagementMutation();

  const isSettingsEnabled = Boolean(settings?.isEnabled);

  const header: SettingsTemplateHeader = {
    title: i18n.t('page.salesSettings.labels.vehicleWarehouseManagement'),
  };

  const [canEnableVehicleWarehouse, canDisableVehicleWarehouse, canCreateVehicleWarehouse] =
    usePermissions({
      permissionKeys: [
        'enableWarehouseManagement',
        'disableWarehouseManagement',
        'vehicleWarehouseCreate',
      ],
    });

  const onEnable = () => {
    openConfirmDialog({
      text: i18n.t('page.vehicleWarehouseManagement.enableConfirmText'),
      onConfirm: () => enableVehicleWarehouses().unwrap().catch(handleApiError),
    });
  };

  const onDisable = () => {
    openConfirmDialog({
      text: i18n.t('page.vehicleWarehouseManagement.disableConfirmText'),
      onConfirm: () => disableVehicleWarehouses().unwrap().catch(handleApiError),
    });
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.vehicleWarehouseManagement('template')}
      header={header}
    >
      <VStack spacing={6} align="flex-start">
        <Heading size={4}>
          {i18n.t('page.vehicleWarehouseManagement.labels.warehouseManagement')}
        </Heading>
        <Text size="xSmall" color="tertiary">
          {i18n.t('page.vehicleWarehouseManagement.description')}
        </Text>
        <Hide when={isSettingsEnabled}>
          <Tooltip
            label={i18n.t('general.notifications.noPermission')}
            isDisabled={canEnableVehicleWarehouse}
          >
            <Button
              title={i18n.t('page.vehicleWarehouseManagement.actions.enableWarehouseManagement')}
              onClick={onEnable}
              isDisabled={!canEnableVehicleWarehouse}
            />
          </Tooltip>
        </Hide>
        <Show when={isSettingsEnabled}>
          <Tooltip
            label={i18n.t('general.notifications.noPermission')}
            isDisabled={canDisableVehicleWarehouse}
          >
            <Button
              title={i18n.t('page.vehicleWarehouseManagement.actions.disableWarehouseManagement')}
              onClick={onDisable}
              variant="secondary"
              isDisabled={!canDisableVehicleWarehouse}
            />
          </Tooltip>
        </Show>
      </VStack>
      <Show when={isSettingsEnabled}>
        <Separator spacing={6} />
        <VStack spacing={2}>
          <HStack>
            <Heading size={4}>
              {i18n.t('page.vehicleWarehouseManagement.labels.vehicleWarehouses')}
            </Heading>
            <Space fillAvailable />
            <Show when={canCreateVehicleWarehouse}>
              <Button
                title={i18n.t('page.vehicleWarehouseManagement.actions.newWarehouse')}
                variant="ghostLink"
                leftIcon="content/add"
                onClick={() => {
                  navigate(settingsRoutes.vehicleWarehouseCreate);
                }}
              />
            </Show>
          </HStack>
          <VehicleWarehouseGrid />
        </VStack>
      </Show>
    </SettingsTemplate>
  );
}
