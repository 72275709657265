import {isNil} from 'ramda';

import {BigDecimal, Currency} from '@dms/api';

import {Nullish} from 'shared';

export type Money = {
  amount: BigDecimal | number | Nullish;
  currency: Currency | Nullish;
};

export const moneyToString = (money: Partial<Money> | Nullish) => {
  if (isNil(money?.amount) || isNil(money?.currency)) {
    return null;
  }
  return {
    amount: `${money.amount ?? 0}`,
    currency: money.currency,
  };
};
