import {Card} from 'platform/components';
import {Grid, Box} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {selectFuelTypes, selectHybridTypes} from '../../../store/vehicleCatalogue/selectors';
import {FuelTypeEnum} from '../../../types/FuelTypeEnum';
import {HybridTypeEnum} from '../../../types/HybridTypeEnum';
import {VehicleType} from '../../../types/VehicleType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';
import {BatterySection} from './BatterySection';
import {HybridFull} from './HybridFull';
import {HybridPlugin} from './HybridPlugin';

interface FuelInnerProps {
  vehicleType: VehicleType;
}

const FuelInner: FC<FuelInnerProps> = ({vehicleType}) => {
  const {Field, Subscribe} = useFormRenderer<VehicleCreateFormState>();
  const fuelOptions = useSelector(selectFuelTypes(vehicleType));
  const hybridOptions = useSelector(selectHybridTypes(vehicleType));

  if (!fuelOptions.length) {
    return null;
  }
  if (!hybridOptions.length) {
    return null;
  }

  return (
    <>
      <Field
        as="chips"
        name="fuelType"
        label={i18n.t('entity.vehicle.labels.fuel')}
        options={fuelOptions}
        enabledDeselect
      />
      <Subscribe<FuelTypeEnum>
        name="fuelType"
        component={({input: {value}}) => {
          const fuelType = fuelOptions.find((option) => option.value === value);
          const secondaryFuelTypes = fuelOptions.filter((option) =>
            fuelType?.properties
              ?.find((property) => property.name === 'secondary_fuel_type')
              ?.value?.includes(option.value)
          );

          return (
            <>
              {!!secondaryFuelTypes.length && (
                <Field
                  as="chips"
                  name="secondaryFuelType"
                  label={i18n.t('general.labels.secondaryType')}
                  options={secondaryFuelTypes}
                  enabledDeselect
                />
              )}
              {(value === FuelTypeEnum.FUELTYPE_LPG || value === FuelTypeEnum.FUELTYPE_CNG) && (
                <Box paddingBottom={4}>
                  <Card variant="inlineGrey">
                    <div
                      css={css`
                        margin-bottom: -16px;
                      `}
                    >
                      <Grid columns={2}>
                        <Field
                          as="date"
                          name="state.gasRevisionValidUntil"
                          label={i18n.t('entity.vehicle.labels.gasRevisionValidUntil')}
                        />
                        <Field
                          as="date"
                          name="state.pressureVesselValidUntil"
                          label={i18n.t('entity.vehicle.labels.pressureVesselExpires')}
                        />
                      </Grid>
                    </div>
                  </Card>
                </Box>
              )}
              {value === FuelTypeEnum.FUELTYPE_HYBRID && (
                <Box paddingBottom={4}>
                  <Card variant="inlineGrey">
                    <Field
                      as="chips"
                      name="hybridType"
                      label={i18n.t('general.labels.hybridType')}
                      options={hybridOptions}
                      enabledDeselect
                    />
                  </Card>
                </Box>
              )}
              {value === FuelTypeEnum.FUELTYPE_ELECTRIC && (
                <Box paddingBottom={4}>
                  <BatterySection vehicleType={vehicleType} />
                </Box>
              )}
              {value === FuelTypeEnum.FUELTYPE_HYBRID && (
                <Subscribe<HybridTypeEnum>
                  name="hybridType"
                  component={({input: {value}}) => {
                    const hybridType = hybridOptions.find((option) => option.value === value);
                    return (
                      <>
                        {value === HybridTypeEnum.HYBRIDTYPE_FULL && (
                          <Box paddingBottom={4}>
                            <HybridFull vehicleType={vehicleType} />
                          </Box>
                        )}
                        {value === HybridTypeEnum.HYBRIDTYPE_PHEV && (
                          <Box paddingBottom={4}>
                            <HybridPlugin vehicleType={vehicleType} />
                          </Box>
                        )}
                      </>
                    );
                  }}
                />
              )}
              {value === FuelTypeEnum.FUELTYPE_HYDROGEN && (
                <Box paddingBottom={4}>
                  <Card variant="inlineGrey">
                    <div
                      css={css`
                        margin-bottom: -16px;
                      `}
                    >
                      <Grid columns={2}>
                        <Field
                          as="date"
                          name="state.pressureVesselValidUntil"
                          label={i18n.t('entity.vehicle.labels.pressureVesselExpires')}
                        />
                      </Grid>
                    </div>
                  </Card>
                </Box>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export const Fuel: FC<PropsWithChildren<any>> = () => {
  const {Subscribe} = useFormRenderer<VehicleCreateFormState>();

  return (
    <Subscribe
      name="type"
      component={({input: {value: vehicleType}}) => <FuelInner vehicleType={vehicleType} />}
    />
  );
};
