import {Button, ButtonGroup, Separator, showNotification} from 'platform/components';

import {FC, useCallback} from 'react';
import {FormSpy} from 'react-final-form';
import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {createVehicleSeriesDefinition, useApiDispatch} from '@dms/teas';

import {VehicleSeriesDefinitionFormType} from '../types';
import {VehicleSeriesDefinitionFormDefaultLayout} from './VehicleSeriesDefinitionFormDefaultLayout';
import {VehicleSeriesDefinitionFormWithLists} from './VehicleSeriesDefinitionFormWithLists';

export const VehicleSeriesDefinitionNew: FC = () => {
  const navigate = useNavigate();
  const apiDispatch = useApiDispatch();

  const handleSubmit = useCallback(
    (values: VehicleSeriesDefinitionFormType) => {
      apiDispatch(createVehicleSeriesDefinition.action, {
        requestBody: values,
      })
        .then(() => {
          navigate(settingsRoutes.vehicleSeriesDefinition);
          showNotification.success(
            i18n.t('page.vehicleSeriesDefinition.notifications.definitionCreated')
          );
        })
        .catch((error) => handleApiError(error?.response));
    },
    [navigate, apiDispatch]
  );

  return (
    <VehicleSeriesDefinitionFormDefaultLayout>
      <VehicleSeriesDefinitionFormWithLists onSubmit={handleSubmit}>
        <Separator />
        <FormSpy
          subscription={{submitting: true}}
          render={({form, submitting}) => (
            <ButtonGroup align="right">
              <Button
                onClick={() => navigate(settingsRoutes.vehicleSeriesDefinition)}
                data-testid={testIds.settings.vehicleNewSeriesDefinition('cancel')}
                variant="secondary"
                title={i18n.t('general.actions.cancel')}
              />
              <Button
                isLoading={submitting}
                onClick={() => form.submit()}
                data-testid={testIds.settings.vehicleNewSeriesDefinition('create')}
                title={i18n.t('general.actions.create')}
              />
            </ButtonGroup>
          )}
        />
      </VehicleSeriesDefinitionFormWithLists>
    </VehicleSeriesDefinitionFormDefaultLayout>
  );
};
