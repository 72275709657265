import {Button} from 'platform/components';

import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';

import {CI_MODE, CI_MODE_OFF, CI_MODE_ON} from 'shared';

import {getFixedTranslation} from '../utils/getFixedTranslation';

export function TranslationDebug() {
  const lastKnownLanguage = localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE);
  const areTranslationsShown = i18n.language === CI_MODE;

  const toggleTranslationVisibility = () => {
    if (areTranslationsShown) {
      localStorage.setItem(browserStorageKey.CI_MODE, CI_MODE_OFF);
      i18n.changeLanguage(lastKnownLanguage ?? 'en');
    } else {
      localStorage.setItem(browserStorageKey.CI_MODE, CI_MODE_ON);
      i18n.changeLanguage(CI_MODE);
    }

    window.location.reload();
  };

  return (
    <Button
      title={
        areTranslationsShown
          ? getFixedTranslation('page.settings.labels.hideTranslations')
          : getFixedTranslation('page.settings.labels.showTranslations')
      }
      onClick={toggleTranslationVisibility}
      variant={areTranslationsShown ? 'danger' : 'secondary'}
      data-testid="layout-header-devSettings-showTranslation"
      leftIcon={areTranslationsShown ? 'action/visibility_off' : 'action/visibility'}
    />
  );
}
