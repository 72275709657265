import {Box} from 'platform/foundation';

import {RefObject} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {Section, SectionTab} from '@dms/shared';

import {DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

import {tireInventorySearchParams} from '../../../../utils/tireInventorySearchParams';
import {useTiresInventoryUrl} from '../../hooks/useTiresInventoryUrl';
import {useTireOrderSetActions} from './hooks/useTireOrderSetActions';

interface SetOfTiresProps {
  setDGRef: RefObject<DataGridRef>;
  movementDGRef: RefObject<DataGridRef>;
}

export function SetOfTires(props: SetOfTiresProps) {
  const {orderId} = useTiresInventoryUrl();

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {orderId}]);

  const orderSetActionCallback = useTireOrderSetActions(orderId);

  const tabs: SectionTab[] = [
    {
      content: (
        //  Datagrid inside tabs need to be wrapped in Box with unique key, to force Datagrid rerender between tab switching
        <Box key="tire-order-set" height={100}>
          <DataGrid
            gridCode="tire-order-set"
            actionCallback={orderSetActionCallback}
            queryModifier={queryModifier}
            ref={props.setDGRef}
            data-testid={testIds.tiresInventory.tireOrderDetail('setOfTires')}
          />
        </Box>
      ),
      queryId: tireInventorySearchParams.tireOrderDetial.setOfTireTabs.tires,
      title: i18n.t('page.tiresInventory.labels.tires'),
    },
    {
      content: (
        //  Datagrid inside tabs need to be wrapped in Box with unique key, to force Datagrid rerender between tab switching
        <Box key="tire-order-movement" height={100}>
          <DataGrid
            gridCode="tire-order-movement"
            actionCallback={orderSetActionCallback}
            queryModifier={queryModifier}
            ref={props.movementDGRef}
            data-testid={testIds.tiresInventory.tireOrderDetail('tiresMovement')}
          />
        </Box>
      ),
      queryId: tireInventorySearchParams.tireOrderDetial.setOfTireTabs.tiresMovement,
      title: i18n.t('page.tiresInventory.labels.tiresMovement'),
    },
    {
      content: (
        <Box key="tire-order-set-archived" height={100}>
          <DataGrid
            gridCode="tire-order-set-archived"
            actionCallback={orderSetActionCallback}
            queryModifier={queryModifier}
            ref={props.movementDGRef}
            data-testid={testIds.tiresInventory.tireOrderDetail('tiresArchived')}
          />
        </Box>
      ),
      queryId: tireInventorySearchParams.tireOrderDetial.setOfTireTabs.archived,
      title: i18n.t('page.tiresInventory.labels.archived'),
    },
  ];

  return <Section data-testid={testIds.tiresInventory.tireOrderDetail('overview')} tabs={tabs} />;
}
