import {
  CreateVehicleWarehouseApiArg,
  CreateVehicleWarehouseApiResponse,
  GetVehicleWarehouseApiArg,
  GetVehicleWarehouseApiResponse,
  DeleteVehicleWarehouseApiArg,
  DeleteVehicleWarehouseApiResponse,
  GetVehicleWarehouseManagementSettingsApiResponse,
  EnableVehicleWarehouseManagementApiResponse,
  DisableVehicleWarehouseManagementApiResponse,
  PatchVehicleWarehouseApiResponse,
  PatchVehicleWarehouseApiArg,
  GetUserVehicleWarehousesApiResponse,
  IssueVehicleWarehouseMovementApiResponse,
  IssueVehicleWarehouseMovementApiArg,
  ReceiveVehicleWarehouseMovementApiResponse,
  ReceiveVehicleWarehouseMovementApiArg,
  GetVehicleWarehouseMovementApiResponse,
  GetVehicleWarehouseMovementApiArg,
  GetSaleVehicleWarehouseInformationApiResponse,
  GetSaleVehicleWarehouseInformationApiArg,
  GetSaleVehicleAvailableWarehouseActionsApiResponse,
  GetSaleVehicleAvailableWarehouseActionsApiArg,
  CancelIssueVehicleWarehouseMovementApiResponse,
  CancelIssueVehicleWarehouseMovementApiArg,
  CancelReceiptVehicleWarehouseMovementApiResponse,
  CancelReceiptVehicleWarehouseMovementApiArg,
  TransferVehicleWarehouseMovementApiResponse,
  TransferVehicleWarehouseMovementApiArg,
  GetVehicleInWarehouseHistorySummaryApiResponse,
  GetVehicleInWarehouseHistorySummaryApiArg,
  GetUserVehicleWarehousesForVehicleApiResponse,
  GetUserVehicleWarehousesForVehicleApiArg,
  ListVehicleWarehousesApiResponse,
  ListVehicleWarehousesApiArg,
  ListAvailableVehicleWarehousesForVehicleMovementApiResponse,
  ListAvailableVehicleWarehousesForVehicleMovementApiArg,
  PatchSaleVehicleStockInDateApiResponse,
  PatchSaleVehicleStockInDateApiArg,
  ReReceiveVehicleToVehicleWarehouseApiResponse,
  ReReceiveVehicleToVehicleWarehouseApiArg,
} from '../types/vehicleWarehouse';
import {omneticApi} from './baseApi/omneticApi';

export const vehicleWarehouseApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getVehicleWarehouseManagementSettings: build.query<
      GetVehicleWarehouseManagementSettingsApiResponse,
      void
    >({
      query: () => ({
        url: `/dms/v1/settings/vehicle-warehouse`,
      }),
      providesTags: ['vehicleWarehouseSettings'],
    }),
    enableVehicleWarehouseManagement: build.mutation<
      EnableVehicleWarehouseManagementApiResponse,
      void
    >({
      query: () => ({
        url: `/dms/v1/settings/vehicle-warehouse/enable`,
        method: 'POST',
      }),
      invalidatesTags: ['vehicleWarehouseSettings'],
    }),
    disableVehicleWarehouseManagement: build.mutation<
      DisableVehicleWarehouseManagementApiResponse,
      void
    >({
      query: () => ({
        url: `/dms/v1/settings/vehicle-warehouse/disable`,
        method: 'POST',
      }),
      invalidatesTags: ['vehicleWarehouseSettings'],
    }),
    listVehicleWarehouses: build.query<
      ListVehicleWarehousesApiResponse,
      ListVehicleWarehousesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: (response, error, queryArg) => [
        {type: 'vehicleWarehousesByBranch', id: queryArg.branchId},
      ],
    }),
    createVehicleWarehouse: build.mutation<
      CreateVehicleWarehouseApiResponse,
      CreateVehicleWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['vehicleWarehouse'],
    }),
    getVehicleWarehouse: build.query<GetVehicleWarehouseApiResponse, GetVehicleWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/${queryArg.vehicleWarehouseId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'vehicleWarehouse', id: queryArg.vehicleWarehouseId},
        {type: 'vehicleWarehousesByUser'},
      ],
    }),
    patchVehicleWarehouse: build.mutation<
      PatchVehicleWarehouseApiResponse,
      PatchVehicleWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/${queryArg.vehicleWarehouseId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'vehicleWarehouse', id: queryArg.vehicleWarehouseId},
        {type: 'vehicleWarehousesByUser'},
      ],
    }),
    deleteVehicleWarehouse: build.mutation<
      DeleteVehicleWarehouseApiResponse,
      DeleteVehicleWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/${queryArg.vehicleWarehouseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['vehicleWarehouse', 'vehicleWarehousesByUser'],
    }),
    getUserVehicleWarehouses: build.query<GetUserVehicleWarehousesApiResponse, void>({
      query: () => ({
        url: `/dms/v1/vehicle-warehouse/by-user`,
      }),
      providesTags: ['vehicleWarehouse'],
    }),
    receiveVehicleWarehouseMovement: build.mutation<
      ReceiveVehicleWarehouseMovementApiResponse,
      ReceiveVehicleWarehouseMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/${queryArg.vehicleWarehouseId}/receive-vehicle`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.body.vehicleId},
        {type: 'vehicleWarehouseInformation', id: queryArg.body.vehicleId},
        {type: 'documentsCount', id: queryArg.body.vehicleId},
        {type: 'vehicleWarehousesByUser', id: queryArg.body.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.body.vehicleId},
      ],
    }),
    issueVehicleWarehouseMovement: build.mutation<
      IssueVehicleWarehouseMovementApiResponse,
      IssueVehicleWarehouseMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/${queryArg.vehicleWarehouseId}/issue-vehicle`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.body.vehicleId},
        {type: 'vehicleWarehouseInformation', id: queryArg.body.vehicleId},
        {type: 'documentsCount', id: queryArg.body.vehicleId},
        {type: 'vehicleWarehousesByUser', id: queryArg.body.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.body.vehicleId},
      ],
    }),
    getVehicleWarehouseMovement: build.query<
      GetVehicleWarehouseMovementApiResponse,
      GetVehicleWarehouseMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/0/movement/${queryArg.vehicleWarehouseMovementId}`,
      }),
    }),
    getSaleVehicleWarehouseInformation: build.query<
      GetSaleVehicleWarehouseInformationApiResponse,
      GetSaleVehicleWarehouseInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/warehouse-information`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'vehicleWarehouseInformation', id: queryArg.vehicleId},
      ],
    }),
    getSaleVehicleAvailableWarehouseActions: build.query<
      GetSaleVehicleAvailableWarehouseActionsApiResponse,
      GetSaleVehicleAvailableWarehouseActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.vehicleId}/available-warehouse-actions`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'vehicleWarehouseInformation', id: queryArg.vehicleId},
      ],
    }),
    cancelIssueVehicleWarehouseMovement: build.mutation<
      CancelIssueVehicleWarehouseMovementApiResponse,
      CancelIssueVehicleWarehouseMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/cancel-issue`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'vehicleWarehouseInformation', id: queryArg.vehicleId},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'vehicleWarehousesByUser', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    cancelReceiptVehicleWarehouseMovement: build.mutation<
      CancelReceiptVehicleWarehouseMovementApiResponse,
      CancelReceiptVehicleWarehouseMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/cancel-receipt`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'vehicleWarehouseInformation', id: queryArg.vehicleId},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'vehicleWarehousesByUser', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    transferVehicleWarehouseMovement: build.mutation<
      TransferVehicleWarehouseMovementApiResponse,
      TransferVehicleWarehouseMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sale-vehicle/${queryArg.saleVehicleId}/transfer`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'vehicleWarehouseInformation', id: queryArg.vehicleId},
        {type: 'documentsCount', id: queryArg.vehicleId},
        {type: 'vehicleWarehousesByUser', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    getVehicleInWarehouseHistorySummary: build.query<
      GetVehicleInWarehouseHistorySummaryApiResponse,
      GetVehicleInWarehouseHistorySummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/vehicle-in-warehouse-history-summary`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getUserVehicleWarehousesForVehicle: build.query<
      GetUserVehicleWarehousesForVehicleApiResponse,
      GetUserVehicleWarehousesForVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/by-user/vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'vehicleWarehousesByUser', id: queryArg.vehicleId},
      ],
    }),
    patchSaleVehicleStockInDate: build.mutation<
      PatchSaleVehicleStockInDateApiResponse,
      PatchSaleVehicleStockInDateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sales/sale-vehicle/${queryArg.vehicleId}/stock-in-date`,
        method: 'PATCH',
        body: queryArg.patchSaleVehicleStockInDateRequestBody,
      }),
    }),
    listAvailableVehicleWarehousesForVehicleMovement: build.query<
      ListAvailableVehicleWarehousesForVehicleMovementApiResponse,
      ListAvailableVehicleWarehousesForVehicleMovementApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle-warehouse/vehicle-movement`,
        params: {vehicleId: queryArg.vehicleId},
      }),
      providesTags: () => ['vehicleWarehouseForMovement'],
    }),
    reReceiveVehicleToVehicleWarehouse: build.mutation<
      ReReceiveVehicleToVehicleWarehouseApiResponse,
      ReReceiveVehicleToVehicleWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/re-receive`,
        method: 'PUT',
        body: queryArg.reReceiveVehicleToVehicleWarehouseRequestBody,
      }),
    }),
  }),
});

export const {
  useCreateVehicleWarehouseMutation,
  useGetVehicleWarehouseQuery,
  useGetVehicleWarehouseManagementSettingsQuery,
  useEnableVehicleWarehouseManagementMutation,
  useDisableVehicleWarehouseManagementMutation,
  usePatchVehicleWarehouseMutation,
  useDeleteVehicleWarehouseMutation,
  useGetUserVehicleWarehousesQuery,
  useIssueVehicleWarehouseMovementMutation,
  useReceiveVehicleWarehouseMovementMutation,
  useGetVehicleWarehouseMovementQuery,
  useLazyGetVehicleWarehouseMovementQuery,
  useGetSaleVehicleWarehouseInformationQuery,
  useLazyGetSaleVehicleWarehouseInformationQuery,
  useGetSaleVehicleAvailableWarehouseActionsQuery,
  useCancelIssueVehicleWarehouseMovementMutation,
  useCancelReceiptVehicleWarehouseMovementMutation,
  useTransferVehicleWarehouseMovementMutation,
  useLazyGetVehicleInWarehouseHistorySummaryQuery,
  usePatchSaleVehicleStockInDateMutation,
  useGetUserVehicleWarehousesForVehicleQuery,
  useLazyGetUserVehicleWarehousesForVehicleQuery,
  useListVehicleWarehousesQuery,
  useLazyListVehicleWarehousesQuery,
  useListAvailableVehicleWarehousesForVehicleMovementQuery,
  useLazyListAvailableVehicleWarehousesForVehicleMovementQuery,
  useReReceiveVehicleToVehicleWarehouseMutation,
} = vehicleWarehouseApi;
