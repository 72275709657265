import {useRef} from 'react';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, isNumber} from 'ramda-adjunct';

import {useThrottledCallback} from 'shared';

import {ActionCallback} from '../types/ActionCallback';
import {ActionDefinitionObject} from '../types/ActionDefinition';
import {GridApi, RowClickedEvent} from '../types/AgGridTypes';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {refreshDatagrid} from '../utils/refreshDatagrid';
import {getSearchParamsByGridCode} from '../utils/url/getSearchParamsByGridCode';
import {setSourceSearchParamsByGridCode} from '../utils/url/setSourceSearchParamsByGridCode';
import {useDeselectAll} from './useDeselectAll';
import {useHandleLink} from './useHandleLink';
import {useRedrawRows} from './useRedrawRows';
import {useRefreshCells} from './useRefreshCells';

/**
 * Returns handler for DG row clicking.
 *
 * Do NOT trigger callback when:
 * - ✓ clicked element have its own callback
 * - use event.stopPropagation in cells
 * - ✓ any cell is beeing edited (click is used to close edit mode)
 * - ✗ any popup menu is open (click is used to close the menu)
 * - ✓ user performed double click
 * Get correct callback by row definition and call it
 * 		- 1. row action
 * 		- 2. expander
 * 		- 3. selector
 */
export const useRowClickHandler = (
  gridApi: GridApi | undefined,
  settings: FeGridSettings,
  actionCallback: ActionCallback | undefined,
  actionsDefinition: ActionDefinitionObject | undefined,
  gridCode: string,
  definition: TransformedDefinitionApiResponse
) => {
  const handleLink = useHandleLink();
  const searchParams = getSearchParamsByGridCode(gridCode);
  const performedClicks = useRef(0);

  const refreshCells = useRefreshCells(gridApi);
  const redrawRows = useRedrawRows(gridApi);
  const deselectAll = useDeselectAll(gridApi);

  const throttledCallback = useThrottledCallback((eventParams: RowClickedEvent): void => {
    if (eventParams.event?.defaultPrevented || isNilOrEmpty(eventParams.data)) {
      return;
    }
    performedClicks.current = performedClicks.current + 1;
    const editingCellsLength = eventParams.api.getEditingCells()?.length ?? 0;
    if (editingCellsLength > 0) {
      return;
    }
    const selectedCells = eventParams.api.getCellRanges()?.[0]?.columns?.length;
    if (isNumber(selectedCells) && selectedCells > 1) {
      return;
    }

    const rowClick = eventParams.data?.actions?.rowClick;

    const sourceSearchParams = setSourceSearchParamsByGridCode(
      gridCode,
      String(eventParams.data.rowNumber)
    );

    const refreshData = () => refreshDatagrid(gridCode);

    if (isNotNil(rowClick) && actionCallback && actionsDefinition && actionsDefinition[rowClick]) {
      const actionLink = actionCallback({
        actionKey: rowClick,
        rowId: eventParams.data.id,
        rowData: eventParams.data,
        refreshData,
        refreshCells,
        redrawRows,
        deselectAll,
        sourceSearchParams,
        queryId: searchParams?.queryId ?? '',
      });
      if (typeof actionLink === 'string' && eventParams.event) {
        handleLink(actionLink, eventParams.event as MouseEvent);
      }
    }
  }, 1000);

  return throttledCallback;
};
