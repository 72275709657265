import {DataStatus, Form, FormField, FormSubmitHandler} from 'platform/components';
import {GridItem, Grid} from 'platform/foundation';
import {array, object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useCreateVehicleWarehouseMutation,
  useGetBillingInformationListQuery,
  useGetBranchListQuery,
  useGetSeriesListQuery,
  useGetVehicleWarehouseQuery,
  usePatchVehicleWarehouseMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';

interface VehicleWarehouseFormFields {
  name: string;
  customId: string;
  costCentre: string | null;
  companyId: string;
  branchIds: string[];
  receivingSeriesId: string;
  issuingSeriesId: string;
  serialNumberSeriesId: string;
}

export function VehicleWarehouseForm() {
  const {id} = useParams();
  const navigate = useNavigate();

  const vehicleWarehouseId = id ?? '';
  const isEdit = isNotNilOrEmpty(vehicleWarehouseId);

  const {
    data: vehicleWarehouse,
    isLoading: isVehicleWarehouseLoading,
    isError: isVehicleWarehouseError,
  } = useGetVehicleWarehouseQuery({vehicleWarehouseId}, {skip: !isEdit});

  const [createVehicleWarehouse] = useCreateVehicleWarehouseMutation();
  const [patchVehicleWarehouse] = usePatchVehicleWarehouseMutation();

  const {
    data: seriesOptions,
    isLoading: isSeriesLoading,
    isError: isSeriesError,
  } = useGetSeriesListQuery(
    {type: ['sales/vehicle-warehouse']},
    {
      selectFromResult: (response) => ({
        ...response,
        data: response.data?.map((series) => ({
          label: series.name,
          value: series.id,
        })),
      }),
    }
  );

  const {
    data: companyOptions,
    isLoading: isBillingInformationLoading,
    isError: isBillingInformationError,
  } = useGetBillingInformationListQuery(undefined, {
    selectFromResult: (response) => ({
      ...response,
      data: response.data?.billingInformationListItems?.map((company) => ({
        label: company.billingName,
        value: company.id,
      })),
    }),
  });

  const {
    data: branchOptions,
    isLoading: isBranchListLoading,
    isError: isBranchListError,
  } = useGetBranchListQuery(undefined, {
    selectFromResult: (response) => ({
      ...response,
      data: response.data?.branchListItems?.map((branch) => ({
        label: branch.marketingName,
        value: branch.id,
      })),
    }),
  });

  const onSubmit: FormSubmitHandler<VehicleWarehouseFormFields> = (values) =>
    isEdit
      ? patchVehicleWarehouse({
          vehicleWarehouseId,
          body: values,
        })
          .unwrap()
          .then(() => {
            navigate(settingsRoutes.vehicleWarehouseManagement);
          })
          .catch(handleApiError)
      : createVehicleWarehouse({body: values})
          .unwrap()
          .then(() => {
            navigate(settingsRoutes.vehicleWarehouseManagement);
          })
          .catch(handleApiError);

  const isLoading =
    isVehicleWarehouseLoading ||
    isBillingInformationLoading ||
    isBranchListLoading ||
    isSeriesLoading;
  const isError =
    isVehicleWarehouseError || isBillingInformationError || isBranchListError || isSeriesError;

  return (
    <SettingsSection>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<VehicleWarehouseFormFields>
          defaultValues={vehicleWarehouse}
          onSubmit={onSubmit}
          schema={schema}
        >
          {(control) => (
            <Grid spacing={4} columns={2}>
              <GridItem span={2}>
                <FormField
                  name="name"
                  type="text"
                  label={i18n.t('entity.vehicleWarehouse.labels.warehouseName')}
                  control={control}
                  data-testid={testIds.settings.vehicleWarehouseManagement('name')}
                  isRequired
                />
              </GridItem>
              <FormField
                name="customId"
                type="text"
                label={i18n.t('entity.vehicleWarehouse.labels.warehouseId')}
                control={control}
                data-testid={testIds.settings.vehicleWarehouseManagement('warehouseId')}
                isRequired
              />
              <FormField
                name="costCentre"
                type="text"
                label={i18n.t('entity.vehicleWarehouse.labels.costCenter')}
                data-testid={testIds.settings.vehicleWarehouseManagement('costCenter')}
                control={control}
              />
              <FormField
                name="companyId"
                type="choice"
                label={i18n.t('entity.vehicleWarehouse.labels.company')}
                data-testid={testIds.settings.vehicleWarehouseManagement('companyId')}
                options={companyOptions}
                control={control}
                isDisabled={isEdit}
                isNotClearable
                isRequired
              />
              <FormField
                name="branchIds"
                type="multiChoice"
                label={i18n.t('entity.vehicleWarehouse.labels.branch')}
                data-testid={testIds.settings.vehicleWarehouseManagement('branch')}
                options={branchOptions}
                control={control}
                isNotClearable
                isRequired
              />
              <FormField
                name="receivingSeriesId"
                type="choice"
                label={i18n.t('entity.vehicleWarehouse.labels.receivingSeries')}
                data-testid={testIds.settings.vehicleWarehouseManagement('receivingSeries')}
                options={seriesOptions}
                control={control}
                isDisabled={isEdit}
                isNotClearable
                isRequired
              />
              <FormField
                name="issuingSeriesId"
                type="choice"
                label={i18n.t('entity.vehicleWarehouse.labels.issuingSeries')}
                data-testid={testIds.settings.vehicleWarehouseManagement('issuingSeries')}
                options={seriesOptions}
                control={control}
                isDisabled={isEdit}
                isNotClearable
                isRequired
              />
              <FormField
                name="serialNumberSeriesId"
                type="choice"
                label={i18n.t('entity.vehicleWarehouse.labels.serialNumber')}
                data-testid={testIds.settings.vehicleWarehouseManagement('serialNumber')}
                options={seriesOptions}
                control={control}
                isDisabled={isEdit}
                isNotClearable
                isRequired
              />
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: isEdit
                      ? i18n.t('general.actions.discardChanges')
                      : i18n.t('general.actions.discard'),
                    onClick: () => {
                      navigate(settingsRoutes.vehicleWarehouseManagement);
                    },
                    'data-testid': testIds.settings.vehicleWarehouseManagement('discard'),
                  },
                  {
                    type: 'form-button',
                    buttonType: 'submit',
                    title: isEdit
                      ? i18n.t('general.actions.saveChanges')
                      : i18n.t('general.actions.save'),
                    control,
                    'data-testid': testIds.settings.vehicleWarehouseManagement('save'),
                  },
                ]}
              />
            </Grid>
          )}
        </Form>
      </DataStatus>
    </SettingsSection>
  );
}

const schema = object({
  name: yupString.required(),
  customId: yupString.required(),
  costCentre: yupString.default(null).nullable(),
  companyId: yupString.required(),
  branchIds: array().of(yupString.required()).required(),
  receivingSeriesId: yupString.required(),
  issuingSeriesId: yupString.required(),
  serialNumberSeriesId: yupString.required(),
});
