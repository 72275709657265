import {isFeatureEnabled} from 'feature-flags';
import {Choice, DataStatus, Switch} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {isNil, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  selectActiveBranchId,
  useGetBranchListQuery,
  useIsPublishingMultiBranchEnabledQuery,
  useSetPublishingMultiBranchEnabledMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {i18n} from '@dms/i18n';
import {testIds} from '@dms/routes';
import {queryParams} from '@dms/shared';

import {useQueryState} from 'shared';

type BranchOption = {
  label: string | null;
  value: string;
};

export function MultiBranchControl() {
  const [branchId, setBranchId, clearBranchId] = useQueryState(queryParams.BRANCH_ID);
  const activeBranchId = useSelector(selectActiveBranchId);

  const [setMultiBranch] = useSetPublishingMultiBranchEnabledMutation();
  const {data: branchList, isLoading: isBranchListLoading} = useGetBranchListQuery();
  const {data: isMultiBranch, isLoading: isMultiBranchLoading} =
    useIsPublishingMultiBranchEnabledQuery();

  const branchOptions: BranchOption[] =
    branchList?.branchListItems?.map((branch) => ({
      label: branch.marketingName,
      value: branch.id,
    })) ?? [];

  useEffect(() => {
    const shouldUpdateBranchId = !isBranchListLoading && isMultiBranch === isNil(branchId);

    if (shouldUpdateBranchId) {
      if (
        isFeatureEnabled(featureFlags.SETTINGS_ADVERTISEMENT_MULTIBRANCH) &&
        isMultiBranch &&
        isNotNil(branchList) &&
        isNotEmpty(branchList.branchListItems)
      ) {
        const isActiveBranchPresent = branchList.branchListItems
          .map((item) => item.id)
          .includes(activeBranchId);

        setBranchId(isActiveBranchPresent ? activeBranchId : branchList.branchListItems[0].id);
      } else {
        clearBranchId();
      }
    }
  }, [
    isMultiBranch,
    branchList,
    isBranchListLoading,
    branchId,
    activeBranchId,
    setBranchId,
    clearBranchId,
  ]);

  return (
    <HStack align="center" spacing={4}>
      <DataStatus isLoading={isMultiBranchLoading}>
        <Switch
          label={i18n.t('entity.advertisingPlatforms.labels.allowMultiBranch')}
          helperText={i18n.t('entity.advertisingPlatforms.labels.allowMultiBranchDescription')}
          value={!!isMultiBranch}
          onChange={setMultiBranch}
          data-testid={testIds.settings.advertisingPlatforms('allowMultiBranch')}
        />
        <Show when={isMultiBranch}>
          <Box width={70}>
            <DataStatus isLoading={isBranchListLoading}>
              <Choice
                value={branchId}
                onChange={(branchId) => setBranchId(branchId as string)}
                options={branchOptions}
                isNotClearable
                data-testid={testIds.settings.advertisingPlatforms('branch')}
              />
            </DataStatus>
          </Box>
        </Show>
      </DataStatus>
    </HStack>
  );
}
