import {isFeatureEnabled} from 'feature-flags';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {usePermissions} from '@dms/shared';

import {buildArray} from 'shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../components/SettingsStackItems/SettingsStackItems';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function Advertising() {
  const [hasConfigureBackgroundRemovalPermission, hasRemoveVehiclePhotoBackgroundPermission] =
    usePermissions({
      permissionKeys: ['configureBackgroundRemoval', 'removeVehiclePhotoBackground'],
      scopes: {
        // No need for participation scope. This ACL is used for settings
        removeVehiclePhotoBackground: {
          participation: undefined,
        },
      },
    });

  const header: SettingsTemplateHeader = {title: i18n.t('page.salesSettings.labels.advertising')};

  const items = buildArray<SettingsStackItemProps>([
    {
      cyId: testIds.settings.advertising('platforms-link'),
      title: i18n.t('page.salesSettings.labels.platforms'),
      url: settingsRoutes.advertisingPlatforms,
    },
    {
      cyId: testIds.settings.advertising('promoPhotos-link'),
      title: i18n.t('page.salesSettings.labels.promotionalPhotos'),
      url: settingsRoutes.advertisingPromoPhotos,
    },
    {
      cyId: testIds.settings.advertising('watermark-link'),
      title: i18n.t('page.salesSettings.labels.watermark'),
      url: isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
        ? settingsRoutes.advertisingWatermarking
        : settingsRoutes.advertisingWatermarkingOld,
    },
    {
      cyId: testIds.settings.advertising('qrCodeDefinition-link'),
      title: i18n.t('page.salesSettings.labels.qrCodeDefinitionTitle'),
      url: settingsRoutes.advertisingQrCodeDefinition,
    },
  ])
    .when(
      isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL) &&
        hasConfigureBackgroundRemovalPermission &&
        hasRemoveVehiclePhotoBackgroundPermission,
      {
        cyId: testIds.settings.advertising('backgroundRemoval-link'),
        title: i18n.t('page.salesSettings.labels.backgroundRemoval'),
        url: isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL)
          ? settingsRoutes.backgroundRemoval
          : settingsRoutes.backgroundRemovalOld,
      }
    )
    .whenFeatureEnabled(featureFlags.SALES_SALE_PROGRAMS, {
      cyId: testIds.settings.advertising('sale-preset-link'),
      title: i18n.t('page.salesSettings.labels.salePreset'),
      url: settingsRoutes.salePreset,
    });

  return (
    <SettingsTemplate header={header} data-testid="settings-advertising">
      <SettingsStackItems items={items} />
    </SettingsTemplate>
  );
}
