import {useDateTimeFormatter} from 'platform/locale';

import {useMemo} from 'react';

import {useGetVehicleAuditApiCatalogsQuery} from '@dms/api';

import {getPopoverHeaderData} from '../utils/getPopoverHeaderData';

export const useHeaderPopoverData = (inspectionId?: string) => {
  const formatDateTime = useDateTimeFormatter();
  const {data, refetch} = useGetVehicleAuditApiCatalogsQuery(
    {
      auditId: inspectionId ?? '',
    },
    {
      skip: !inspectionId,
    }
  );

  const popoverHeaderData = useMemo(() => getPopoverHeaderData(formatDateTime, data), [data]);
  if (!data) {
    return null;
  }
  return {popoverHeaderData, refetchData: refetch};
};
