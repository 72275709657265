import {Option} from 'platform/components';

import {map} from 'ramda';

import {
  PostTireOrderApiResponse,
  PostTireOrderApiArg,
  GetTireOrderApiResponse,
  GetTireOrderApiArg,
  GetTireOptionsApiResponse,
  GetTireOptionsApiArg,
  GetTireManufacturerApiResponse,
  GetEmployeeMechanicsByBranchApiResponse,
  GetEmployeeMechanicsByBranchApiArg,
  PostTireSetApiResponse,
  PostTireSetApiArg,
  PostTiresArchiveApiResponse,
  PostTiresArchiveApiArg,
  PostTireSetDeliveryNoteApiResponse,
  PostTireSetDeliveryNoteApiArg,
  PostTireSetLabelApiResponse,
  PostTireSetLabelApiArg,
  PostTiresTransferToOrderApiResponse,
  PostTiresTransferToOrderApiArg,
  GetTireSetApiResponse,
  GetTireSetApiArg,
  PatchTireSetApiResponse,
  PatchTireSetApiArg,
  PostTireSetAddWheelsApiResponse,
  PostTireSetAddWheelsApiArg,
  PostTiresReceiptApiResponse,
  PostTiresReceiptApiArg,
  PostTiresIssueApiResponse,
  PostTiresIssueApiArg,
  PostTiresRestoreApiResponse,
  PostTiresRestoreApiArg,
  GetCodelistTireLocationApiResponse,
  GetTiresGeneralSettingsApiArg,
  GetTiresGeneralSettingsApiResponse,
  PutTiresGeneralSettingsApiArg,
  PutTiresGeneralSettingsApiResponse,
  PostTireManufacturerApiResponse,
  PostTireManufacturerApiArg,
  PatchTireManufacturerApiResponse,
  PatchTireManufacturerApiArg,
  PatchTireManufacturerSetActiveApiResponse,
  PatchTireManufacturerSetActiveApiArg,
  PatchTireManufacturerSetDefaultApiResponse,
  PatchTireManufacturerSetDefaultApiArg,
  PatchTireManufacturerSetInactiveApiResponse,
  PatchTireManufacturerSetInactiveApiArg,
  PostTireLocationApiResponse,
  PostTireLocationApiArg,
  PatchTireLocationApiResponse,
  PatchTireLocationApiArg,
  PatchTireLocationPositionDownApiResponse,
  PatchTireLocationPositionDownApiArg,
  PatchTireLocationPositionUpApiResponse,
  PatchTireLocationPositionUpApiArg,
  PatchTireLocationSetActiveApiResponse,
  PatchTireLocationSetActiveApiArg,
  PatchTireLocationSetInactiveApiResponse,
  PatchTireLocationSetInactiveApiArg,
  GetTireVehicleCountApiResponse,
  GetTireVehicleCountApiArg,
  GetOrderSetDefaultContactPersonApiArg,
  GetOrderSetDefaultContactPersonApiResponse,
  GetGeneralSettingsStorageLocationApiResponse,
  GetTireIdsOrdersApiApiResponse,
  GetTireIdsOrdersApiApiArg,
  GetTireWarehousesApiArg,
  GetTireWarehousesApiResponse,
  PostWarehouseEnablingSetAsDisallowApiResponse,
  PostWarehouseEnablingSetAsAllowApiArg,
  GetTireWarehouseApiArg,
  GetTireWarehouseApiResponse,
  PostWarehouseEnablingSetAsDisallowApiArg,
  PostTireWarehouseApiArg,
  PostTireWarehouseApiResponse,
  DeleteTireWarehouseApiArg,
  DeleteTireWarehouseApiResponse,
  PatchTireWarehouseApiArg,
  PatchTireWarehouseApiResponse,
  GetTireExternalWarehouseApiArg,
  GetTireExternalWarehouseApiResponse,
  PostWarehouseEnablingSetAsAllowApiResponse,
  GetTireWarehouseSettingsApiResponse,
  GetTireWarehouseSettingsApiArg,
  GetDefaultStoreUntilByOrderIdApiResponse,
  GetDefaultStoreUntilByOrderIdApiArg,
  DeleteGeneralSettingApiArg,
  DeleteGeneralSettingApiResponse,
  GetGeneralSettingApiArg,
  GetGeneralSettingApiResponse,
  PatchGeneralSettingApiArg,
  PatchGeneralSettingApiResponse,
  PostGeneralSettingApiArg,
  PostGeneralSettingApiResponse,
  GetTireSetLocationResponse,
  GetTireSetLocationArg,
  UpdateTireSetLocationResponse,
  UpdateTireSetLocationArg,
} from '../types/metadaTires';
import {metadaApi} from './baseApi/metadaApi';

export const metadaTiresApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postTireOrder: build.mutation<PostTireOrderApiResponse, PostTireOrderApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireIds', id: `${queryArg.body.customerId}-${queryArg.body.vehicleId}`},
      ],
    }),
    getTireIds: build.query<GetTireIdsOrdersApiApiResponse, GetTireIdsOrdersApiApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order`,
        params: {
          customerId: queryArg.customerId,
          vehicleId: queryArg.vehicleId,
        },
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireIds', id: `${queryArg.customerId}-${queryArg.vehicleId}`},
      ],
    }),
    getTireOrder: build.query<GetTireOrderApiResponse, GetTireOrderApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireOrder', id: queryArg.orderId}],
    }),
    getTireOptions: build.query<Option[], GetTireOptionsApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/codelist`,
        params: {category: queryArg.category},
      }),
      transformResponse: (response: GetTireOptionsApiResponse) =>
        map((item) => ({label: item?.name ?? '', value: item?.code ?? ''}), response ?? []),
    }),
    getTireLocations: build.query<GetCodelistTireLocationApiResponse, void>({
      query: () => ({
        url: `/core/v1/codelist/tire-location`,
      }),
      providesTags: ['tireLocations'],
    }),
    getTireManufacturer: build.query<GetTireManufacturerApiResponse, unknown>({
      query: () => ({
        url: `/core/v1/codelist/tire-manufacturer`,
      }),
      providesTags: ['tireManufacturers'],
    }),
    getEmployeeMechanicsByBranch: build.query<Option[], GetEmployeeMechanicsByBranchApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/mechanics-by-branch`,
        params: {branchId: queryArg.branchId},
      }),
      transformResponse: (response: GetEmployeeMechanicsByBranchApiResponse) =>
        map(
          (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
          response?.employees ?? []
        ),
    }),
    postTireSet: build.mutation<PostTireSetApiResponse, PostTireSetApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireOrder', id: queryArg.orderId}],
    }),
    postTiresArchive: build.mutation<PostTiresArchiveApiResponse, PostTiresArchiveApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/archive`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
      ],
    }),
    postTireSetDeliveryNote: build.mutation<
      PostTireSetDeliveryNoteApiResponse,
      PostTireSetDeliveryNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/delivery-note`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTireSetLabel: build.mutation<PostTireSetLabelApiResponse, PostTireSetLabelApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/label`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTiresTransferToOrder: build.mutation<
      PostTiresTransferToOrderApiResponse,
      PostTiresTransferToOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/transfer-to-order`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
        {type: 'tireOrder', id: queryArg.body?.targetOrderId ?? undefined},
      ],
    }),
    getTireSet: build.query<GetTireSetApiResponse, GetTireSetApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    patchTireSet: build.mutation<PatchTireSetApiResponse, PatchTireSetApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
      ],
    }),
    postTireSetAddWheels: build.mutation<
      PostTireSetAddWheelsApiResponse,
      PostTireSetAddWheelsApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/add-wheels`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
      ],
    }),
    postTiresReceipt: build.mutation<PostTiresReceiptApiResponse, PostTiresReceiptApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/receipt`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTiresIssue: build.mutation<PostTiresIssueApiResponse, PostTiresIssueApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/issue`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTiresRestore: build.mutation<PostTiresRestoreApiResponse, PostTiresRestoreApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/restore`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    getTireSetLocation: build.query<GetTireSetLocationResponse, GetTireSetLocationArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/location`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireSetLocation', id: queryArg.setId}],
    }),
    updateTireSetLocation: build.mutation<UpdateTireSetLocationResponse, UpdateTireSetLocationArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/location`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSetLocation', id: queryArg.setId}],
    }),
    getTiresGeneralSettings: build.query<
      GetTiresGeneralSettingsApiResponse,
      GetTiresGeneralSettingsApiArg
    >({
      query: () => ({
        url: `/tires/v1/general-settings`,
      }),
      providesTags: ['tiresInventoryGeneralSettings'],
    }),
    putTiresGeneralSettings: build.mutation<
      PutTiresGeneralSettingsApiResponse,
      PutTiresGeneralSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/general-settings`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: ['tiresInventoryGeneralSettings', 'tiresInventoryDefaultStoreUntil'],
    }),
    postTireManufacturer: build.mutation<
      PostTireManufacturerApiResponse,
      PostTireManufacturerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturer: build.mutation<
      PatchTireManufacturerApiResponse,
      PatchTireManufacturerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturerSetActive: build.mutation<
      PatchTireManufacturerSetActiveApiResponse,
      PatchTireManufacturerSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}/set-active`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturerSetDefault: build.mutation<
      PatchTireManufacturerSetDefaultApiResponse,
      PatchTireManufacturerSetDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}/set-default`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturerSetInactive: build.mutation<
      PatchTireManufacturerSetInactiveApiResponse,
      PatchTireManufacturerSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}/set-inactive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    postTireLocation: build.mutation<PostTireLocationApiResponse, PostTireLocationApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocation: build.mutation<PatchTireLocationApiResponse, PatchTireLocationApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationPositionDown: build.mutation<
      PatchTireLocationPositionDownApiResponse,
      PatchTireLocationPositionDownApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/position-down`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationPositionUp: build.mutation<
      PatchTireLocationPositionUpApiResponse,
      PatchTireLocationPositionUpApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/position-up`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationSetActive: build.mutation<
      PatchTireLocationSetActiveApiResponse,
      PatchTireLocationSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/set-active`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationSetInactive: build.mutation<
      PatchTireLocationSetInactiveApiResponse,
      PatchTireLocationSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/set-inactive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    getTireVehicleCount: build.query<GetTireVehicleCountApiResponse, GetTireVehicleCountApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-wheel-count`,
        params: {vehicleId: queryArg.vehicleId, customerId: queryArg.customerId},
      }),
    }),
    getDefaultStoreUntilByOrderId: build.query<
      GetDefaultStoreUntilByOrderIdApiResponse,
      GetDefaultStoreUntilByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/default-store-until`,
      }),
      providesTags: ['tiresInventoryDefaultStoreUntil'],
    }),
    getOrderSetDefaultContactPerson: build.query<
      GetOrderSetDefaultContactPersonApiResponse,
      GetOrderSetDefaultContactPersonApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/default-contact-person`,
      }),
    }),
    getGeneralSettingsStorageLocation: build.query<
      GetGeneralSettingsStorageLocationApiResponse,
      void
    >({
      query: () => ({
        url: `/tires/v1/storage-location-setting`,
      }),
      providesTags: ['generalSettingsStorageLocation'],
    }),
    postStorageLocationSettingsSetAsAllow: build.mutation<undefined, void>({
      query: () => ({
        url: `/tires/v1/storage-location-setting/allow-storage-location`,
        method: 'POST',
      }),
      invalidatesTags: ['generalSettingsStorageLocation'],
    }),
    postStorageLocationSettingsSetAsDisallow: build.mutation<undefined, void>({
      query: () => ({
        url: `/tires/v1/storage-location-setting/disallow-storage-location`,
        method: 'POST',
      }),
      invalidatesTags: ['generalSettingsStorageLocation'],
    }),
    getAllTireWarehouses: build.query<GetTireWarehousesApiResponse, GetTireWarehousesApiArg>({
      query: () => ({
        url: `/tires/v1/warehouse`,
      }),
      providesTags: ['tireWarehousesAll'],
    }),
    postTireWarehouse: build.mutation<PostTireWarehouseApiResponse, PostTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireWarehousesAll'],
    }),
    getTireWarehouseSettings: build.query<
      GetTireWarehouseSettingsApiResponse,
      GetTireWarehouseSettingsApiArg
    >({
      query: () => ({
        url: `/tires/v1/warehouse-setting`,
      }),
      providesTags: ['tireWarehouseSettings'],
    }),
    postWarehouseEnablingSetAsAllow: build.mutation<
      PostWarehouseEnablingSetAsAllowApiResponse,
      PostWarehouseEnablingSetAsAllowApiArg
    >({
      query: () => ({
        url: `/tires/v1/warehouse-setting/allow-storage-location`,
        method: 'POST',
      }),
      invalidatesTags: ['tireWarehouseSettings'],
    }),
    postWarehouseEnablingSetAsDisallow: build.mutation<
      PostWarehouseEnablingSetAsDisallowApiResponse,
      PostWarehouseEnablingSetAsDisallowApiArg
    >({
      query: () => ({
        url: `/tires/v1/warehouse-setting/disallow-storage-location`,
        method: 'POST',
      }),
      invalidatesTags: ['tireWarehouseSettings'],
    }),
    getTireWarehouse: build.query<GetTireWarehouseApiResponse, GetTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireWarehouse', id: queryArg.warehouseId},
      ],
    }),
    deleteTireWarehouse: build.mutation<DeleteTireWarehouseApiResponse, DeleteTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}`,
        method: 'DELETE',
      }),
    }),
    patchTireWarehouse: build.mutation<PatchTireWarehouseApiResponse, PatchTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireWarehouse', id: queryArg.warehouseId},
      ],
    }),
    getTireExternalWarehouse: build.query<
      GetTireExternalWarehouseApiResponse,
      GetTireExternalWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}/external-warehouse`,
      }),
    }),
    postGeneralSetting: build.mutation<PostGeneralSettingApiResponse, PostGeneralSettingApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getGeneralSetting: build.query<GetGeneralSettingApiResponse, GetGeneralSettingApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting/${queryArg.generalSettingId}`,
      }),
      providesTags: ['generalSettings'],
    }),
    deleteGeneralSetting: build.mutation<
      DeleteGeneralSettingApiResponse,
      DeleteGeneralSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting/${queryArg.generalSettingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'generalSettings', id: queryArg.generalSettingId},
      ],
    }),
    patchGeneralSetting: build.mutation<PatchGeneralSettingApiResponse, PatchGeneralSettingApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting/${queryArg.generalSettingId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'generalSettings', id: queryArg.generalSettingId},
      ],
    }),
  }),
});

export const {
  usePostTireOrderMutation,
  useGetTireOrderQuery,
  useGetTireOptionsQuery,
  useGetTireLocationsQuery,
  useGetTireManufacturerQuery,
  useGetEmployeeMechanicsByBranchQuery,
  usePostTireSetMutation,
  usePostTiresArchiveMutation,
  usePostTireSetDeliveryNoteMutation,
  usePostTireSetLabelMutation,
  usePostTiresTransferToOrderMutation,
  useGetTireSetQuery,
  useLazyGetTireSetQuery,
  usePatchTireSetMutation,
  usePostTireSetAddWheelsMutation,
  usePostTiresReceiptMutation,
  usePostTiresIssueMutation,
  usePostTiresRestoreMutation,
  useGetTiresGeneralSettingsQuery,
  usePutTiresGeneralSettingsMutation,
  usePostTireManufacturerMutation,
  usePatchTireManufacturerMutation,
  usePatchTireManufacturerSetActiveMutation,
  usePatchTireManufacturerSetDefaultMutation,
  usePatchTireManufacturerSetInactiveMutation,
  usePostTireLocationMutation,
  usePatchTireLocationMutation,
  usePatchTireLocationPositionDownMutation,
  usePatchTireLocationPositionUpMutation,
  usePatchTireLocationSetActiveMutation,
  usePatchTireLocationSetInactiveMutation,
  useGetTireVehicleCountQuery,
  useGetOrderSetDefaultContactPersonQuery,
  useGetGeneralSettingsStorageLocationQuery,
  usePostStorageLocationSettingsSetAsAllowMutation,
  usePostStorageLocationSettingsSetAsDisallowMutation,
  useGetTireIdsQuery,
  useGetAllTireWarehousesQuery,
  usePostTireWarehouseMutation,
  usePostWarehouseEnablingSetAsAllowMutation,
  usePostWarehouseEnablingSetAsDisallowMutation,
  useGetTireWarehouseQuery,
  useDeleteTireWarehouseMutation,
  usePatchTireWarehouseMutation,
  useGetTireExternalWarehouseQuery,
  useGetTireWarehouseSettingsQuery,
  useGetDefaultStoreUntilByOrderIdQuery,
  usePostGeneralSettingMutation,
  useGetGeneralSettingQuery,
  useDeleteGeneralSettingMutation,
  usePatchGeneralSettingMutation,
  useGetTireSetLocationQuery,
  useUpdateTireSetLocationMutation,
} = metadaTiresApi;
