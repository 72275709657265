import {
  Card,
  FormControl,
  FormField,
  IconButton,
  OptionType,
  Radio,
  Separator,
  Switch,
} from 'platform/components';
import {
  Box,
  Clickable,
  Grid,
  Heading,
  HStack,
  Link,
  Show,
  Space,
  VStack,
} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';
import {useWorkTypeOptions} from '@dms/shared';

import {CurrencyCodeType, suffixTestId, TestIdProps} from 'shared';

import {DiscountType} from '../../../../../types/discountType';
import {WorkDiscountOption} from '../types/workDiscountOption';

const WORK_OPTIONS = [
  {
    label: i18n.t('entity.order.labels.percentageDiscount'),
    value: WorkDiscountOption.PercentageDiscount,
  },
  {
    label: i18n.t('entity.order.labels.discountedWorkRate'),
    value: WorkDiscountOption.DiscountedWorkRate,
  },
];

interface DiscountOnWorkCardProps extends TestIdProps {
  serviceCaseId: string;
  control: FormControl<DiscountType>;
  formApi: UseFormReturn<DiscountType>;
  selectedOption: WorkDiscountOption | null;
  onOptionChange: (value: WorkDiscountOption | null) => void;
  isExpanded: boolean;
  onExpandChange: () => void;
  currency?: CurrencyCodeType;
  isReadOnly?: boolean;
}

export function DiscountOnWorkCard(props: DiscountOnWorkCardProps) {
  const {workTypeOptions, isLoading: isOptionsLoading} = useWorkTypeOptions();
  const {fields, append, remove} = useFieldArray({
    control: props.control,
    name: 'work.discountedWorkRates.discountedWorkRate',
  });

  const appendEmptyFields = () =>
    append({workRatePriceWithoutVat: undefined, workRateType: undefined});

  const shouldOptionBeDisplayed = (option: OptionType) =>
    !props.formApi
      .watch('work.discountedWorkRates.discountedWorkRate')
      ?.map((field) => field.workRateType)
      .includes(option.value);

  return (
    <Card variant="inlineGrey">
      <Clickable
        onClick={props.onExpandChange}
        isDisabled={props.isReadOnly}
        data-testid={suffixTestId('toggle', props)}
      >
        <HStack align="center" spacing={3}>
          <Switch
            value={props.isExpanded}
            onChange={props.onExpandChange}
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('expand', props)}
          />
          <Heading size={4}>{i18n.t('entity.order.labels.discountOnWork')}</Heading>
        </HStack>
      </Clickable>

      <Show when={props.isExpanded}>
        <Separator />
        <VStack spacing={4}>
          <Radio
            options={WORK_OPTIONS}
            value={props.selectedOption}
            onChange={(value) => props.onOptionChange(value as WorkDiscountOption)}
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('type', props)}
          />
          <Show when={props.selectedOption === WorkDiscountOption.PercentageDiscount}>
            <Grid columns={4} spacing={4}>
              <FormField
                control={props.control}
                name="work.percentDiscount.discountRate"
                type="number"
                label={i18n.t('entity.order.labels.percentage')}
                isDisabled={props.isReadOnly}
                suffix="%"
                decimalPlaces={2}
                data-testid={suffixTestId('discountRate', props)}
              />
            </Grid>
          </Show>
          <Show when={props.selectedOption === WorkDiscountOption.DiscountedWorkRate}>
            {fields.map((field, i) => {
              const isFirst = i === 0;
              const removeFields = () => remove(i);
              return (
                <Grid key={field.id} columns={4} spacing={4}>
                  <FormField
                    isLoading={isOptionsLoading}
                    control={props.control}
                    name={`work.discountedWorkRates.discountedWorkRate.${i}.workRateType`}
                    type="choice"
                    options={workTypeOptions}
                    filterOption={shouldOptionBeDisplayed}
                    label={isFirst ? i18n.t('entity.order.labels.workType') : null}
                    menuInPortal
                    isDisabled={props.isReadOnly}
                    data-testid={suffixTestId(`workType-[${i}]`, props)}
                  />
                  <FormField
                    control={props.control}
                    name={`work.discountedWorkRates.discountedWorkRate.${i}.workRatePriceWithoutVat`}
                    type="currency"
                    currency={props.currency}
                    label={isFirst ? i18n.t('entity.order.labels.workRateWithoutVAT') : null}
                    isDisabled={props.isReadOnly}
                    data-testid={suffixTestId(`workRate-[${i}]`, props)}
                  />
                  <Box>
                    <Show when={fields.length > 1}>
                      <Show when={isFirst}>
                        <Space vertical={5} />
                      </Show>
                      <IconButton
                        icon="navigation/close"
                        severity="danger"
                        priority="tertiary"
                        isDisabled={props.isReadOnly}
                        onClick={removeFields}
                        data-testid={suffixTestId(`removeField-[${i}]`, props)}
                      />
                    </Show>
                  </Box>
                </Grid>
              );
            })}
            <Link
              onClick={appendEmptyFields}
              size="small"
              title={i18n.t('entity.order.actions.addWorkType')}
              isDisabled={props.isReadOnly}
              data-testid={suffixTestId('addWorkType', props)}
            />
          </Show>
        </VStack>
      </Show>
    </Card>
  );
}
