import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  Separator,
} from 'platform/components';
import {HStack, Show, Text, VStack} from 'platform/foundation';
import {Pattern, match} from 'ts-pattern';
import {object} from 'yup';

import {always, includes} from 'ramda';

import {
  getOptionsFromServiceCaseOrderVariants,
  useGetServiceCaseOrderVariantsQuery,
  useGetServiceOrderComplaintQuery,
  usePostServiceOrderActionForApproveClaimMutation,
  usePostServiceOrderActionForRejectClaimMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {PredefinedNotes, handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

const APPROVE_CLAIM_TYPE = 'APPROVE_CLAIM_TYPE';
const REJECT_CLAIM_TYPE = 'REJECT_CLAIM_TYPE';

interface DecisionOnClaimModalProps extends RequiredTestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  onClose: () => void;
}

export function DecisionOnClaimModal(props: DecisionOnClaimModalProps) {
  const {
    data: serviceOrderComplaint,
    isLoading: isServiceOrderComplaintLoading,
    isFetching: isServiceOrderComplaintFetching,
    isError: isServiceOrderComplaintError,
  } = useGetServiceOrderComplaintQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {data: orderVariants} = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const [postServiceOrderActionForApproveClaim] =
    usePostServiceOrderActionForApproveClaimMutation();
  const [postServiceOrderActionForRejectClaim] = usePostServiceOrderActionForRejectClaimMutation();

  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    if (data.type.includes(APPROVE_CLAIM_TYPE)) {
      return await postServiceOrderActionForApproveClaim({
        serviceCaseId: props.serviceCaseId,
        serviceOrderId: props.serviceOrderId,
      })
        .unwrap()
        .then(props.onClose)
        .catch(handleApiError);
    }

    return await postServiceOrderActionForRejectClaim({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: data,
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isLoading={isServiceOrderComplaintLoading || isServiceOrderComplaintFetching}
      isError={isServiceOrderComplaintError}
    >
      <Form<FormType>
        defaultValues={{
          type: [APPROVE_CLAIM_TYPE],
          isDuplicateToOrder: false,
          reason: serviceOrderComplaint?.claimReason ?? undefined,
        }}
        schema={formSchema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Text color="secondary" size="small">
              {i18n.t('entity.order.labels.decisionOnClaimDescription')}
            </Text>
            <FormField
              name="type"
              control={control}
              type="chips"
              options={[
                {label: i18n.t('entity.order.labels.approveClaim'), value: APPROVE_CLAIM_TYPE},
                {label: i18n.t('entity.order.labels.rejectClaim'), value: REJECT_CLAIM_TYPE},
              ]}
              data-testid={suffixTestId('type', props)}
            />
            <Show when={formApi.watch('type').includes(REJECT_CLAIM_TYPE)}>
              <VStack spacing={1}>
                <HStack justify="space-between" align="flex-end">
                  <Label isRequired>{i18n.t('entity.order.labels.claimReason')}</Label>
                  <PredefinedNotes
                    note={formApi.watch('reason') ?? null}
                    onPrefill={(note) => formApi.setValue('reason', note)}
                    resource="SERVICE_CASE"
                    context="service_request_solution"
                    isLinkVariant
                    data-testid={suffixTestId('reasonPredefinedNotes', props)}
                  />
                </HStack>
                <FormField
                  name="reason"
                  control={control}
                  type="text"
                  isRequired
                  data-testid={suffixTestId('reason', props)}
                />
              </VStack>
              <FormField
                name="isDuplicateToOrder"
                control={control}
                type="switch"
                label={i18n.t('entity.order.labels.duplicateToAnotherServiceOrder')}
                data-testid={suffixTestId('isDuplicateToOrder', props)}
              />
              <Show when={formApi.watch('isDuplicateToOrder')}>
                <FormField
                  name="serviceOrderVariantId"
                  control={control}
                  type="choice"
                  options={getOptionsFromServiceCaseOrderVariants(orderVariants)}
                  label={i18n.t('entity.order.labels.orderVariant')}
                  menuInPortal
                  data-testid={suffixTestId('serviceOrderVariantId', props)}
                />
              </Show>
              <Separator spacing={0} />
            </Show>
            <ButtonGroup align="right" data-testid={suffixTestId('projectDetailDialog', props)}>
              <Button
                data-testid={suffixTestId('discard', props)}
                variant="secondary"
                onClick={props.onClose}
                title={i18n.t('general.actions.discard')}
              />
              <FormButton
                data-testid={suffixTestId('submit', props)}
                control={control}
                type="submit"
                title={match([formApi.watch('type')[0], formApi.watch('isDuplicateToOrder')])
                  .with(
                    [APPROVE_CLAIM_TYPE, Pattern.any],
                    always(i18n.t('entity.order.actions.approve'))
                  )
                  .with([REJECT_CLAIM_TYPE, false], always(i18n.t('entity.order.actions.reject')))
                  .with(
                    [REJECT_CLAIM_TYPE, true],
                    always(i18n.t('entity.order.actions.rejectAndDuplicate'))
                  )
                  .otherwise(always(i18n.t('general.actions.save')))}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

type FormType = {
  type: string[];
  reason: string;
  isDuplicateToOrder: boolean;
  serviceOrderVariantId: string;
};

const formSchema = object({
  reason: yupString.when('type', {
    is: includes(REJECT_CLAIM_TYPE),
    then: yupString.required(),
  }),
});
