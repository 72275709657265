import {
  BaseFlagProps,
  FlagProps,
  openConfirmDialog,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {HStack, Space, Text} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {Fragment, ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';

import {always, defaultTo, equals, isNil, not} from 'ramda';
import {concatAll, isFalsy, isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  GetDirectSaleResponse,
  GetWarehousesResponse,
  useGetAuthorizationProfilesQuery,
  useGetBranchListQuery,
  useGetDirectSaleQuery,
  useGetDirectSaleTypesQuery,
  useGetDirectSaleVariantQuery,
  useGetMaterialPriceTypesQuery,
  useGetTenantQuery,
  useGetUserQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  useGetWarehousesQuery,
  useGetWorkRateTypesQuery,
  usePatchDirectSaleMutation,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
  queryParams,
} from '@dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  generateHashFromObjects,
  getRandomId,
  Nullish,
  parseDate,
  useBoolean,
  useNavigate,
  useQueryState,
} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {createHeaderFlags} from '../../utils/createHeaderFlags';
import {Customer} from './(sections)/Customer/Customer';
import {Overview} from './(sections)/DirectSale/Overview';
import {TABS_IDS} from './(sections)/DirectSale/utils/tabIds';
import {Documents} from './(sections)/Documents/Documents';
import {Vehicle} from './(sections)/Vehicle/Vehicle';

const DIRECT_SALE_SECTION_IDS = {
  directSale: 'directSale',
  documents: 'documents',
  vehicle: 'vehicle',
};

const PERCENTAGE_BASE = 100;

export function DirectSalesDetail() {
  const {directSaleId} = useWarehouseParams();

  const navigate = useNavigate();

  const currencyFormatter = useFormatCurrency();

  const formatDate = useDateTimeFormatter();

  const [activeSectionId, setActiveSectionId] = useQueryState(
    queryParams.COMPONENT_SECTIONS_SECTION
  );

  const [, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const [isMaterialReturnModalVisible, openMaterialReturnModal, closeMaterialReturnModal] =
    useBoolean();

  const {
    data: directSale,
    isLoading: isDirectSaleLoading,
    isError: hasDirectSaleError,
  } = useGetDirectSaleQuery(
    {directSaleId: directSaleId as string},
    {skip: isNilOrEmpty(directSaleId)}
  );

  const {
    data: availableWarehouses,
    isLoading: isAvailableWarehousesLoading,
    isError: isAvailableWarehousesError,
  } = useGetWarehousesQuery(
    {directSaleVariantId: defaultTo('', directSale?.directSaleVariantId)},
    {skip: isNil(directSale)}
  );

  const {
    data: directSaleVariant,
    isLoading: isDirectSaleVariantLoading,
    isError: hasDirectSaleVariantError,
  } = useGetDirectSaleVariantQuery(
    {directSaleVariantId: defaultTo('', directSale?.directSaleVariantId)},
    {skip: isNil(directSale)}
  );

  const {data: user, isLoading: isUserLoading} = useGetUserQuery({id: directSale?.createdBy ?? ''});

  const {
    data: directSaleActions,
    isLoading: isDirectSaleActionsLoading,
    isError: hasDirectSaleActionsError,
  } = useGetWarehouseHeaderDynamicActionsQuery({id: directSaleId, resource: 'direct-sale'});

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: isAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const {
    data: branches,
    isLoading: areBranchesLoading,
    isError: hasBranchesError,
  } = useGetBranchListQuery();

  const {
    data: directSaleTypes,
    isLoading: isDirectSaleTypesLoading,
    isError: hasDirectSaleTypesError,
  } = useGetDirectSaleTypesQuery();

  const {
    data: materialPriceTypes,
    isLoading: isMaterialPriceTypesLoading,
    isError: hasMaterialPriceTypesError,
  } = useGetMaterialPriceTypesQuery();

  const {
    data: labourRateTypes,
    isLoading: isLabourRateTypesLoading,
    isError: hasLabourRateTypesError,
  } = useGetWorkRateTypesQuery();

  const [saveDirectSale] = usePatchDirectSaleMutation();

  const [putDirectSaleAction, {isLoading: isPutDirectSaleActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const isLoading =
    isDirectSaleLoading ||
    isDirectSaleVariantLoading ||
    isUserLoading ||
    isAvailableWarehousesLoading ||
    isDirectSaleActionsLoading ||
    isTenantLoading ||
    isAuthorizationProfilesLoading ||
    areBranchesLoading ||
    isDirectSaleTypesLoading ||
    isMaterialPriceTypesLoading ||
    isLabourRateTypesLoading;

  const isError =
    hasDirectSaleError ||
    hasDirectSaleVariantError ||
    hasDirectSaleActionsError ||
    isAvailableWarehousesError ||
    hasTenantError ||
    hasAuthorizationProfilesError ||
    hasBranchesError ||
    hasDirectSaleTypesError ||
    hasMaterialPriceTypesError ||
    hasLabourRateTypesError;

  const directSaleTitle = `${i18n.t('page.warehouse.labels.directSale')} ${
    directSale?.number ?? ''
  }`;

  const priceWithVat = currencyFormatter(
    directSale?.totalPrice.withVat.amount ?? 0,
    directSale?.totalPrice.withVat.currency ?? '',
    2
  );

  const priceWithoutVat = currencyFormatter(
    directSale?.totalPrice.withoutVat.amount ?? 0,
    directSale?.totalPrice.withoutVat.currency ?? '',
    2
  );

  const hasPendingReturn = !!directSale?.hasPendingReturn;

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDate('dateShort', parseDate(date));
  };

  const createdAt = `${i18n.t('general.labels.dateCreated')}: ${formatToShortDate(directSale?.createdAt)}`;

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(user) ?? EMPTY_PLACEHOLDER}`;

  const isLockedOrArchivedOrClosed =
    directSale?.state === 'LOCKED' ||
    directSale?.state === 'ARCHIVED' ||
    directSale?.state === 'CLOSED';

  const handleChangeDirectSaleVariant = () => {
    navigate(
      composePath(warehouseRoutes.directSalesVariantChange, {
        params: {id: directSaleId, directSaleVariantId: directSale?.directSaleVariantId},
      })
    );
  };

  const handleActionClick = (actionKey: string) => {
    match(actionKey)
      .with('return', () => openMaterialReturnModal())
      .with('discount', () => {
        setActiveTabId(TABS_IDS.discountTab);
      })
      .with('variantChange', () => handleChangeDirectSaleVariant())
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            putDirectSaleAction({id: directSaleId, resource: 'direct-sale', body: {actionKey}})
              .unwrap()
              .then(() => navigate(composePath(warehouseRoutes.directSalesList)))
              .catch(handleApiError),
          'data-testid': testIds.warehouse.directSalesDetailOverview('delete'),
        })
      )
      .otherwise(() => {
        const confirmText = match(actionKey)
          .with(
            'lock',
            always(`${i18n.t('page.warehouse.labels.lockDirectSale')} ${directSale?.number}?`)
          )
          .with(
            'archive',
            always(`${i18n.t('page.warehouse.labels.archiveDirectSale')} ${directSale?.number}?`)
          )
          .otherwise(always(null));

        if (isNil(confirmText)) {
          return putDirectSaleAction({
            id: directSaleId,
            resource: 'direct-sale',
            body: {actionKey},
          })
            .unwrap()
            .then(() => {
              showNotification.success();
            })
            .catch(handleApiError);
        }

        openConfirmDialog({
          text: confirmText,
          onConfirm: () =>
            putDirectSaleAction({id: directSaleId, resource: 'direct-sale', body: {actionKey}})
              .unwrap()
              .then(() => {
                showNotification.success();
              })
              .catch(handleApiError),
          'data-testid': testIds.warehouse.directSalesDetailOverview('dialogs'),
        });
      });
  };

  const handleVehicleChange = (vehicleId: string | Nullish) =>
    saveDirectSale({directSaleId, body: {vehicleId}})
      .unwrap()
      .then(() =>
        showNotification.success(
          isNil(vehicleId)
            ? i18n.t('entity.warehouse.notifications.vehicleRemoved')
            : i18n.t('entity.warehouse.notifications.vehicleAssigned')
        )
      )
      .catch(handleApiError);

  const handleCustomerChange = (customerId: string | Nullish) =>
    saveDirectSale({directSaleId, body: {customerId}})
      .unwrap()
      .then(() =>
        showNotification.success(
          isNil(customerId)
            ? i18n.t('entity.warehouse.notifications.customerRemoved')
            : i18n.t('entity.warehouse.notifications.customerAssigned')
        )
      )
      .catch(handleApiError);

  const handleCheckout = () => {
    setActiveSectionId(DIRECT_SALE_SECTION_IDS.directSale);
    setActiveTabId(TABS_IDS.checkoutTab);
  };

  const getAuthorizationProfileFromVariant = (authorizationProfileId: string | Nullish) => {
    const result = authorizationProfiles?.find((authorizationProfile) =>
      equals(authorizationProfile.id, authorizationProfileId)
    );

    return defaultTo(EMPTY_PLACEHOLDER, result?.name);
  };

  const getBranchFromDirectSale = (branchId: string | Nullish) => {
    const result = branches?.branchListItems?.find((branch) => equals(branch.id, branchId));

    return defaultTo(EMPTY_PLACEHOLDER, result?.marketingName);
  };

  const getDirectSaleTypeFromVariant = (directSaleTypeId: string | Nullish) => {
    const result = directSaleTypes?.find((directSaleType) =>
      equals(directSaleType?.directSaleTypeId, directSaleTypeId)
    );

    return defaultTo(EMPTY_PLACEHOLDER, result?.name);
  };

  const getPriceTypeFromVariant = (code: string | Nullish, type: 'material' | 'labour') =>
    match(type)
      .with('material', () => {
        const result = materialPriceTypes?.find((materialPriceType) =>
          equals(materialPriceType?.code, code)
        );
        return defaultTo(EMPTY_PLACEHOLDER, result?.name);
      })
      .with('labour', () => {
        const result = labourRateTypes?.find((labourRateType) =>
          equals(labourRateType?.code, code)
        );
        return defaultTo(EMPTY_PLACEHOLDER, result?.name);
      })
      .exhaustive();

  const getMarginFromVariant = (margin: number | Nullish) =>
    margin ? `${margin * PERCENTAGE_BASE} %` : EMPTY_PLACEHOLDER;

  const getRatioFromVariant = (ratio: number | Nullish) =>
    ratio ? ratio * PERCENTAGE_BASE : EMPTY_PLACEHOLDER;

  const dynamicFlags = createHeaderFlags(directSale?.markers);

  const staticFlags = match(directSale?.state)
    .returnType<Omit<BaseFlagProps, 'size'>[]>()
    .with(
      'LOCKED',
      always([
        {
          colorScheme: 'green',
          label: i18n.t('entity.warehouse.labels.lockedState'),
        },
      ])
    )
    .with(
      'ARCHIVED',
      always([
        {
          colorScheme: 'neutral',
          label: i18n.t('entity.warehouse.labels.archivedState'),
        },
      ])
    )
    .with(
      'CLOSED',
      always([
        {
          colorScheme: 'green',
          label: i18n.t('entity.warehouse.labels.closedState'),
        },
      ])
    )
    .otherwise(
      always([
        {
          colorScheme: 'blue',
          label: i18n.t('entity.warehouse.labels.openState'),
        },
      ])
    );

  const headerFlags = concatAll<FlagProps[]>([staticFlags, dynamicFlags]);

  const headerActions = createHeaderActions({
    actions: directSaleActions?.actions,
    callback: handleActionClick,
    isLoading: isPutDirectSaleActionLoading,
  });

  const variantTooltipData = [
    {
      label: i18n.t('entity.warehouse.labels.authorizationProfile'),
      value: getAuthorizationProfileFromVariant(directSaleVariant?.general?.authorizationProfileId),
    },
    {
      label: i18n.t('entity.warehouse.labels.branch'),
      value: getBranchFromDirectSale(directSale?.branchId),
    },
    {
      label: i18n.t('entity.warehouse.labels.directSaleType'),
      value: getDirectSaleTypeFromVariant(directSaleVariant?.general?.directSaleTypeId),
    },
    {
      label: i18n.t('entity.warehouse.labels.unitPriceWithVAT'),
      value: isFalsy(directSaleVariant?.general?.isUnitPriceWithVat)
        ? i18n.t('general.labels.no')
        : i18n.t('general.labels.yes'),
    },
    {
      label: i18n.t('entity.warehouse.labels.materialPriceType'),
      value: getPriceTypeFromVariant(directSaleVariant?.general?.priceType, 'material'),
    },
    {
      label: i18n.t('entity.warehouse.labels.minimumMaterialMargin'),
      value: getMarginFromVariant(directSaleVariant?.general?.minimalMaterialMargin),
    },
    {
      label: i18n.t('entity.warehouse.labels.materialPriceTypeRatio'),
      value: getRatioFromVariant(directSaleVariant?.general?.priceTypeRatio),
    },
    {
      label: i18n.t('entity.warehouse.labels.labourRateType'),
      value: getPriceTypeFromVariant(directSaleVariant?.general?.rateType, 'labour'),
    },
    {
      label: i18n.t('entity.warehouse.labels.minimumLabourMargin'),
      value: getMarginFromVariant(directSaleVariant?.general?.minimalWorkMargin),
    },
    {
      label: i18n.t('entity.warehouse.labels.labourRatio'),
      value: getRatioFromVariant(directSaleVariant?.general?.rateTypeRatio),
    },
    {
      label: i18n.t('entity.warehouse.labels.note'),
      value: defaultTo(EMPTY_PLACEHOLDER, directSaleVariant?.general?.note),
    },
  ];

  const variantTooltipContent: ReactNode = (
    <>
      {variantTooltipData.map((tooltipItem, index) => {
        const isFirstItem = index === 0;

        return (
          <Fragment key={`tooltipItem-${getRandomId()}`}>
            {not(isFirstItem) && <Space vertical={1} />}

            <HStack spacing={1}>
              <Text size="xSmall" color="white">
                {`• ${tooltipItem.label}:`}
              </Text>
              <Text size="xSmall" color="white" alternative>
                {tooltipItem.value}
              </Text>
            </HStack>
          </Fragment>
        );
      })}
    </>
  );

  const header: DetailTemplateHeader = {
    title: directSaleTitle,
    icon: 'action/shopping_cart',
    parameters: [
      directSale?.number,
      {
        title: directSale?.variantName,
        tooltip: variantTooltipContent,
        shouldShowTooltipIcon: true,
        hasAutoWidth: true,
      },
      createdAt,
      createdBy,
    ],
    recordId: directSale?.id,
    resourceId: EntityResourceIds.directSales,
    controls: ['ASSIGNEE'],
    flags: headerFlags,
    actions: headerActions,
    primaryParameter: defaultTo(undefined, priceWithVat),
    secondaryParameter: `${priceWithoutVat} ${i18n.t('general.labels.w/oVat')}`,
    alert: hasPendingReturn
      ? {
          title: i18n.t('entity.warehouse.notifications.youHavePendingItemsForReturn'),
          hyperlinks: [
            {
              title: i18n.t('entity.warehouse.actions.showPending'),
              onClick: openMaterialReturnModal,
            },
          ],
        }
      : undefined,
  };

  const navigation: NavigationItem[] = [
    {
      id: DIRECT_SALE_SECTION_IDS.directSale,
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.directSalesDetailOverview, {
        params: {id: directSaleId},
      }),
      content: (
        <Overview
          directSale={directSale as GetDirectSaleResponse}
          isDirectSaleLoading={isDirectSaleLoading}
          isEditingDisabled={isLockedOrArchivedOrClosed}
          directSaleActions={directSaleActions?.actions}
          availableWarehouses={availableWarehouses as GetWarehousesResponse}
          isMaterialReturnModalVisible={isMaterialReturnModalVisible}
          onCloseMaterialReturnModal={closeMaterialReturnModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('section.directSale')}
        />
      ),
      'data-testid': testIds.warehouse.directSalesDetailOverview('navigation.directSale'),
    },
    {
      id: DIRECT_SALE_SECTION_IDS.documents,
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.directSalesDetailDocuments, {
        params: {id: directSaleId},
      }),
      content: (
        <Documents
          directSaleId={directSaleId}
          data-testid={testIds.warehouse.directSalesDetailDocuments('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.directSalesDetailDocuments('navigation.documents'),
    },
    {
      id: DIRECT_SALE_SECTION_IDS.vehicle,
      label: i18n.t('page.warehouse.labels.vehicle'),
      href: composePath(warehouseRoutes.directSalesDetailVehicle, {
        params: {id: directSaleId},
      }),
      content: (
        <Vehicle
          vehicleId={defaultTo('', directSale?.vehicleId)}
          isDirectSaleLoading={isDirectSaleLoading}
          isEditingDisabled={isLockedOrArchivedOrClosed}
          onVehicleChange={handleVehicleChange}
          data-testid={testIds.warehouse.directSalesDetailVehicle('section.vehicle')}
        />
      ),
      'data-testid': testIds.warehouse.directSalesDetailVehicle('navigation.vehicle'),
    },
    {
      id: 'customer',
      label: i18n.t('page.warehouse.labels.customer'),
      href: composePath(warehouseRoutes.directSalesDetailCustomer, {
        params: {id: directSaleId},
      }),
      content: (
        <Customer
          customerId={defaultTo('', directSale?.customerId)}
          isDirectSaleLoading={isDirectSaleLoading}
          isEditingDisabled={isLockedOrArchivedOrClosed}
          onCustomerChange={handleCustomerChange}
          data-testid={testIds.warehouse.directSalesDetailCustomer('section.customer')}
        />
      ),
      'data-testid': testIds.warehouse.directSalesDetailCustomer('navigation.customer'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.directSaleDetail')} />
      <DetailTemplate
        key={generateHashFromObjects(directSale)}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.directSalesDetailOverview('page')}
        activeSectionId={activeSectionId}
        onSectionChange={setActiveSectionId}
      />
    </>
  );
}
