import {Button} from 'platform/components';
import {Hide} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';

import i18n from '@dms/i18n';
import {useBatchDownload} from '@dms/shared';
import {DownloadDialog} from '@dms/teas';

import {suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {useBatchButtonLocation} from '../hooks/useBatchButtonLocation';

interface BatchDownloadButtonProps extends TestIdProps {
  saleVehicleId?: string;
}

export function BatchDownloadButton(props: BatchDownloadButtonProps) {
  const {id: vehicleId} = useRequiredParams();
  const {inspectionId, tab} = useParams();

  const [vehiclePhotoLocation, isDownloadUnavailable] = useBatchButtonLocation();

  const {handleDownload, handleSaleVehicleDownload, eventData, isLoading, isStatePending} =
    useBatchDownload();

  return (
    <Hide when={tab === 'service-case'}>
      <Button
        variant="outlined"
        leftIcon="file/download"
        onClick={() =>
          isNotNil(props.saleVehicleId)
            ? handleSaleVehicleDownload({saleVehicleId: props.saleVehicleId})
            : handleDownload({
                vehiclePhotoLocation,
                vehicleId,
                auditId: inspectionId,
              })
        }
        isDisabled={(isDownloadUnavailable && isNil(props.saleVehicleId)) || isLoading}
        data-testid={suffixTestId(`BatchDownloadButton`, props)}
        title={i18n.t('general.actions.downloadAll')}
      />
      <DownloadDialog
        isOpen={isLoading || isStatePending}
        fileName={eventData?.data?.bulkName}
        fileCount={eventData?.data?.objects.length}
        withWrapper
      />
    </Hide>
  );
}
