import {Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {Helmet} from 'react-helmet-async';

import {buildUrl, useLazyWorkspaceInfoQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {loginRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

import {AuthContainer} from '../../components/AuthContainer/AuthContainer';

type WorkspaceForm = {
  workspace: string;
};

export function Workspace() {
  const [getWorkspaceInfo, {isLoading}] = useLazyWorkspaceInfoQuery();

  const onSubmit: FormSubmitHandler<WorkspaceForm> = async ({workspace}, setErrors) => {
    const workspaceNotFound = () => {
      setErrors([
        {name: 'workspace', message: i18n.t('page.login.notifications.workspaceNotFound')},
      ]);
    };
    await getWorkspaceInfo({workspace})
      .unwrap()
      .then((response) => {
        if (response.workspace) {
          window.location.href = buildUrl(loginRoutes.login, response.workspace);
          return;
        }
        workspaceNotFound();
      })
      .catch((ex) => {
        if (ex.status === 404) {
          workspaceNotFound();
          return;
        }
        handleApiError(ex, {setErrors});
      });
  };

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.login.actions.signUp')}</title>
      </Helmet>

      <AuthContainer>
        <Form onSubmit={onSubmit} schema={schema}>
          {(control) => (
            <VStack spacing={4}>
              <FormField
                name="workspace"
                type="text"
                isRequired
                control={control}
                label={i18n.t('page.login.labels.workspace')}
                data-testid={testIds.login.loginWorkspace('workspaceName')}
                maxLength={100}
              />
              <FormButton
                isLoading={isLoading}
                control={control}
                type="submit"
                data-testid={testIds.login.loginWorkspace('submitButton')}
                title={i18n.t('general.actions.continue')}
              />
            </VStack>
          )}
        </Form>
      </AuthContainer>
    </>
  );
}

const schema = object({
  workspace: yupString.required(i18n.t('general.notifications.errorWorkspaceNameRequired')),
});
