import {Action, Attributes, Card, DataStatus, openDialog} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotEmpty} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {EntityResourceIds} from '@dms/api';
import i18n from '@dms/i18n';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {EditCustomFieldsValuesForm} from './components/EditCustomFieldsValuesForm';
import {useCustomFieldsParameterRow} from './hooks/useCustomFieldsParameterRow';
import {isEntityFeatureFlagEnabled} from './utils/isEntityFeatureFlagEnabled';

interface EntityCustomFieldsProps extends RequiredTestIdProps {
  resourceId: EntityResourceIds;
  recordId: string;
  isEditable: boolean;
}

export function EntityCustomFields(props: EntityCustomFieldsProps) {
  const [rows] = useCustomFieldsParameterRow(props);

  const handleEdit = () =>
    openDialog(
      <EditCustomFieldsValuesForm
        recordId={props.recordId}
        resourceId={props.resourceId}
        data-testid={suffixTestId('edit-dialog', props)}
      />,
      {title: i18n.t('page.generalSettings.labels.customFields')}
    );

  const hasAtLeastOneRow = isNotEmpty(rows);
  const cardActions = buildArray<Action>().when(isTrue(props.isEditable) && hasAtLeastOneRow, {
    variant: 'link',
    type: 'button',
    leftIcon: 'image/edit',
    onClick: handleEdit,
    title: i18n.t('general.actions.edit'),
  });

  return (
    <Show when={isNotEmpty(rows) && isEntityFeatureFlagEnabled(props.resourceId)}>
      <Card
        title={i18n.t('page.generalSettings.labels.customFields')}
        actions={cardActions}
        data-testid={suffixTestId('card', props)}
      >
        <DataStatus
          isEmpty={!hasAtLeastOneRow}
          spacing={5}
          emptyMessage={i18n.t('page.generalSettings.labels.emptyCustomFieldsMessage')}
          action={{
            variant: 'link',
            title: i18n.t('page.generalSettings.labels.emptyCustomFieldsLink'),
          }}
        >
          <Attributes data-testid={suffixTestId('fields', props)} rows={rows} />
        </DataStatus>
      </Card>
    </Show>
  );
}
