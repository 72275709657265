import {Card, Action, DataStatus} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useListLatestInterestsQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {interestsRoutes} from '@dms/routes';
import {useDataQuery} from '@dms/shared';

import {TestIdProps, composePath, useNavigate} from 'shared';

import {LatestInterestItem} from './LatestInterestItem';

interface LatestInterestsProps extends TestIdProps {
  branchId: string | undefined;
}

export function LatestInterests(props: LatestInterestsProps) {
  const navigate = useNavigate();

  const {
    data: latestInterests,
    isLoading: isLatestInterestsLoading,
    isError: isLatestInterestsError,
  } = useListLatestInterestsQuery(
    {
      branchId: props.branchId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const {queryParams} = useDataQuery('interest', {
    // Latest interests sorted by createdAt in descending order
    order: [{columnKey: 'createdAt', order: 'DESC'}],
  });

  const onClickOpenLatestInterests = () => {
    navigate(composePath(interestsRoutes.home, {queryParams}));
  };

  const displayLatestInterestsAction: Action = {
    title: i18n.t('page.homepage.labels.view'),
    onClick: onClickOpenLatestInterests,
    variant: 'link',
    type: 'button',
    rightIcon: 'navigation/chevron_right',
  };

  return (
    <Card
      title={i18n.t('page.homepage.labels.latestInterests')}
      hasSeparator={false}
      actions={[displayLatestInterestsAction]}
    >
      <Box minHeight={45}>
        <DataStatus
          minHeight="100%"
          isLoading={isLatestInterestsLoading}
          isError={isLatestInterestsError}
          isEmpty={isNilOrEmpty(latestInterests)}
        >
          <VStack spacing={4}>
            {latestInterests?.map((interest) => (
              <LatestInterestItem key={interest.id} interest={interest} />
            ))}
          </VStack>
        </DataStatus>
      </Box>
    </Card>
  );
}
