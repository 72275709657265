import {DataStatus} from 'platform/components';

import {FC} from 'react';

import {isNil} from 'ramda';

import {
  NewOfferPurchaseBrokerageVehicleResponseBody,
  NewOfferPurchaseVehicleResponseBody,
  OfferSaleVehicleResponseBody,
  useGetBusinessCaseQuery,
  useGetVehicleV2Query,
} from '@dms/api';
import {getBusinessCaseTradeTypeFlag, usePricePermissions} from '@dms/shared';
import {VehicleWidget} from '@dms/teas';

import {TestIdProps, useRequiredParams} from 'shared';

import {isBrokerageVehicle} from '../../utils/isBrokerageVehicle';

type BusinessCaseOverviewVehicleProps = {
  vehicle?:
    | OfferSaleVehicleResponseBody
    | NewOfferPurchaseBrokerageVehicleResponseBody
    | NewOfferPurchaseVehicleResponseBody;
  isSimple?: boolean;
} & TestIdProps;

export const BusinessCaseOverviewVehicle: FC<BusinessCaseOverviewVehicleProps> = ({
  vehicle,
  ...props
}) => {
  const {id: businessCaseId} = useRequiredParams();
  const {
    data: businessCase,
    isLoading: isLoadingBusinessCase,
    isError: isBusinessCaseError,
  } = useGetBusinessCaseQuery({businessCaseId});
  const {
    data: vehicleDetail,
    isLoading: isLoadingVehicle,
    isError: isVehicleError,
  } = useGetVehicleV2Query(
    {vehicleId: vehicle?.vehicleId ?? ''},
    {skip: isNil(vehicle?.vehicleId)}
  );

  const {canReadBusinessCaseSaleVehicleSalePrice} = usePricePermissions({
    businessCaseRecordId: businessCaseId,
    vehicleRecordId: null,
  });

  const isLoading = isLoadingBusinessCase || isLoadingVehicle;
  const isError = isBusinessCaseError || isVehicleError;
  const isBrokerage = isBrokerageVehicle(vehicle) || businessCase?.brokerageBusinessCaseId;

  const flag = getBusinessCaseTradeTypeFlag(isBrokerage ? 'BROKERAGE' : 'IN_STOCK');
  const purchaseTypeBusinessCase =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE';

  const saleTypeBusinessCase = businessCase?.businessCaseType === 'SELLING';

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VehicleWidget
        data-testid={props['data-testid']}
        vehicle={vehicleDetail}
        showMileagePurchase={purchaseTypeBusinessCase}
        showMileageSale={saleTypeBusinessCase}
        saleVehicleId={businessCase?.saleVehicleId ?? ''}
        selling={
          businessCase?.offers[0]?.saleVehicles?.[0]
            ? businessCase.offers[0].saleVehicles[0]
            : undefined
        }
        purchaseVehicle={
          businessCase?.offers[0]?.purchaseVehicles?.[0]
            ? businessCase.offers[0].purchaseVehicles[0]
            : undefined
        }
        purchaseBrokeraggeVehicle={
          businessCase?.offers[0]?.purchaseBrokerageVehicles?.[0]
            ? businessCase.offers[0].purchaseBrokerageVehicles[0]
            : undefined
        }
        shouldShowPrice={!props.isSimple && canReadBusinessCaseSaleVehicleSalePrice}
        shouldShowDetailButton={!props.isSimple}
        flags={
          flag && flag.label && flag.colorScheme
            ? [
                {
                  name: flag.label,
                  color: flag.colorScheme,
                },
              ]
            : undefined
        }
      />
    </DataStatus>
  );
};
