/* eslint-disable eag/match-named-export */
import {z} from 'zod';

import {FileOperationResponseBody} from './api';

export type AuditDataVideoAssetsFilesBody = {
  id: string;
  videoId: string;
  url: string;
  originUrl: string | null;
  previewUrl: string | null;
  coverImageUrl: string | null;
  variants: {type: string; url: string}[] | null;
};

export type AuditDataVideoAssetsBody = {
  paramDefinitionId: string;
  videos: AuditDataVideoAssetsFilesBody[];
};

export enum InspectionType {
  VALIDATION_INSPECTION = 'validationInspection',
  VEHICLE_CONDITION = 'vehicleCondition',
  NON_VALIDATION_INSPECTION = 'nonValidationInspection',
  AUTOFILL_TEMPLATE = 'autofillTemplate',
  CHECKIN = 'checkin',
  HANDOVER = 'handover',
  COMPLAINT = 'complaint',
}

export enum InspectionState {
  FOR_CHECK = 'for_check',
  IN_PROGRESS = 'in_progress',
  FINISHED_AUDIT = 'finished_audit',
  TO_REVISION = 'to_revision',
  IN_REVISION = 'in_revision',
  TO_FINISHING = 'to_finishing',
}

export type InspectionTemplatesApiResponse = /** status 200 */ InspectionTemplateBody[];

export type ToggleInspectionTemplateActiveStateApiResponse =
  /** status 200 */ InspectionTemplateBody;
export type ToggleInspectionTemplateActiveStateApiArg = {
  templateId: string;
};

export type DeleteInspectionTemplateApiResponse = /** status 204 */ undefined;
export type DeleteInspectionTemplateApiArg = {
  templateId: string;
};

export type GetPublicizeInspectionApiResponse =
  /** status 200 */ GetPublicizeInspectionResponseBody;
export type GetPublicizeInspectionApiArg = {
  inspectionId: string;
};

export type ToFinishingInspectionApiResponse = /** status 200 */ VehicleFinishedResponseBody;
export type ToFinishingInspectionApiArg = {
  inspectionId: string;
  note: string;
};

export type ToRevisionInspectionApiResponse = /** status 200 */ VehicleFinishedResponseBody;
export type ToRevisionInspectionApiArg = {
  inspectionId: string;
};

export type ToInRevisionInspectionApiResponse = /** status 200  */ VehicleFinishedResponseBody;
export type ToInRevisionInspectionApiArg = {
  inspectionId: string;
  toInRevisionInspectionRequestBody: ToInRevisionInspectionRequestBody;
};
export type InspectionToInProgressApiArg = {
  inspectionId: string;
};
export type ToInRevisionInspectionRequestBody = {
  updatedAt?: string | null;
};
export type CreateAutoFillTemplateApiResponse = /** status 200  */ CreatedInspectionResponseBody;
export type CreateAutoFillTemplateApiArg = {
  vehicleId: string;
  auditStructureId: string;
  inspectionName: string;
};

export type GetAutoFillTemplatesApiResponse = /** status 200  */ InspectionsResponseItemBody[];
export type GetAutoFillTemplatesApiArg = void;
export type GetAutoFillTemplateApiArg = {
  inspectionId: string;
  autoFillTemplateId: string;
};

export type DeleteAutoFillTemplateApiResponse = unknown;
export type DeleteAutoFillTemplateApiArg = {
  inspectionId: string;
};

export type GetAuditStructureApiResponse = AuditStructure;
export type GetAuditStructureApiArg = {
  auditStructureId: string;
};

export type GetNewestStructureByTypeApiResponse = /** status 200  */ AuditStructure[];
export type GetNewestStructureByTypeApiArg = {
  inspectionType: InspectionType;
};

export type UpdateAuditDataApiResponse = /** status 200  */ UpdateAuditDataResponseItemBody;
export type UpdateAuditDataApiArg = {
  inspectionId: string;
  autofillTemplateId: string;
  requestBody: Array<UpdateAuditDataRequestBody>;
};

export type GetAuditDataApiResponse = /** status 200  */ LoadAuditDataResponseItemBody[];
export type GetAuditDataApiArg = {
  auditId: string;
};

export type InspectionTemplateBody = {
  id: string;
  name: string;
  key: string;
  inspectionType: InspectionType;
  vehicleType: string;
  createdAt: string;
  isActive: boolean;
  isDeletable: boolean;
};

export type VehicleFinishedResponseBody = {
  message: string;
};

export type GetPublicizeInspectionResponseBody = {
  publicInspectionId: string;
  inspectionId: string;
};

export type UpdateAuditDataRequestBody = {
  categoryId: string;
  paramDefinitionId: string;
  value: string;
  updatedAt: string;
};

export type AuditStructure = {
  id: string;
  validation?: 'validate' | 'nonValidate' | null;
  name: Record<string, string>;
  categories: Array<AuditCategoryOfStructure>;
  ratings: Array<AuditRating>;
  inspectionType?: InspectionType | null;
};

export type AuditCategoryOfStructure = {
  name: Record<string, string>;
  asTitle: boolean;
  key: string | null;
  id: string;
  visible: boolean;
  mandatory: boolean;
  childCategories: Array<AuditCategoryOfStructure> | null;
  paramDefinitions: AuditParamDefinitions | null;
  icon: string | null;
  featureKey: string | null;
  uniqueKey: string;
};

export type AuditRating = {
  categoryId: string;
  paramDefinitionId: string;
  name?: string;
};

export type AuditParamDefinition = {
  id: string;
  key: string | null;
  type: string;
  unit: string | null;
  values: Array<AuditParamValue>;
  label: Record<string, string> | null;
  relatedParamDefinitionIds: Array<AuditRelatedParamDefinitions> | null;
  mandatory: boolean;
  imageCategoryId: string | null;
  badge: Record<string, string> | null;
  comparisonKey?: string | null;
};

export type AuditParamDefinitions = {
  relatedActions: Array<AuditParamDefinition> | null;
  additionalInformation: Array<AuditParamDefinition> | null;
};

export type AuditRelatedParamDefinitions = {
  categoryId: string;
  paramDefinitionId: string;
};

export type AuditParamValue = {
  value: string;
  label: Record<string, string> | null;
  openCategoryWithKey: string | null;
  content: AuditParamDefinitions | null;
  featureKey: string | null;
};

export type CreatedInspectionResponseBody = {
  id: string;
  vehicleId: string;
  vehicleAuditState: InspectionState;
};

export type UpdateAuditDataCategories = {
  id: string;
  fields: Array<ResponseUpdateAuditDataFieldsBody>;
};

export type ResponseUpdateAuditDataFieldsBody = {
  paramDefinitionId: string;
  value: string;
  updatedAt: string | null;
};

export type LoadAuditDataCategories = {
  id: string;
  fields: Array<ResponseAuditDataFieldsBody>;
  assets: Array<AuditDataAssetsBody>;
  videoAssets: Array<AuditDataVideoAssetsBody>;
};

export type ResponseAuditDataFieldsBody = {
  paramDefinitionId: string;
  value: string;
};

export type AuditDataAssetsBody = {
  paramDefinitionId: string;
  files: AuditDataAssetsFilesBody[];
};

export type AuditDataAssetsFilesBody = {
  uploadedAssetsId: string;
  imageId: string | null;
  url: string;
  resizeUrl: string;
  meta: string;
  directory: string | null;
};

export type UpdateAuditDataResponseItemBody = {
  id: string;
  state: InspectionState;
  user: string | null;
  finishedAt: string | null;
  controlStructureId: string;
  vehicleId: string;
  categories: Array<UpdateAuditDataCategories>;
  inspectionType: InspectionType | null;
};

export type LoadAuditDataResponseItemBody = {
  id: string;
  state: InspectionState;
  user: string | null;
  finishedAt: string | null;
  controlStructureId: string;
  vehicleId: string;
  updateAt: string | null;
  categories: Array<LoadAuditDataCategories>;
  inspectionType: InspectionType | null;
  inspectionNote: string | null;
};

export type InspectionsResponseItemBody = {
  id: string;
  structureId: string;
  inspectionState: InspectionState;
  finishedAt: string | null;
  finishedByUser: string | null;
  inspectionName: string | null;
  structureName: string | null;
};

export type AuditParamValueResponseBody = {
  value: string;
  label: {
    [key: string]: string;
  } | null;
  openCategoryWithKey: string | null;
  content: AuditParamDefinitionsResponseBody | null;
  featureKey: string | null;
};
export type AuditRelatedParamDefinitionsResponseBody = {
  categoryId: string;
  paramDefinitionId: string;
};

export type AuditParamDefinitionResponseBody = {
  id: string;
  key: string | null;
  type: string;
  unit: string | null;
  values: AuditParamValueResponseBody[];
  label: {
    [key: string]: string;
  } | null;
  relatedParamDefinitionIds: AuditRelatedParamDefinitionsResponseBody[] | null;
  mandatory: boolean;
  imageCategoryId: string | null;
  badge: {
    [key: string]: string;
  } | null;
};

export type AuditParamDefinitionsResponseBody = {
  relatedActions: AuditParamDefinitionResponseBody[] | null;
  additionalInformation: AuditParamDefinitionResponseBody[] | null;
};

export type BasicCategoryOfStructureResponseBody = {
  name: {
    [key: string]: string;
  };
  asTitle: boolean;
  key: string | null;
  id: string;
  childCategories: BasicCategoryOfStructureResponseBody[] | null;
  paramDefinitions: AuditParamDefinitionsResponseBody | null;
  icon: string | null;
  featureKey: string | null;
  uniqueKey: string;
};
export type BasicStructureResponseBody = {
  version: number;
  categories: BasicCategoryOfStructureResponseBody[];
};

export type GetBasicStructureForSettingsApiResponse =
  /** status 200  */ BasicStructureResponseBody[];
export type GetBasicStructureForSettingsApiArg = {
  templateId?: string;
  inspectionType?:
    | 'vehicleCondition'
    | 'validationInspection'
    | 'nonValidationInspection'
    | 'autofillTemplate'
    | 'checkin'
    | 'handover';
};

export type CreateTypeOfControlSettingsApiResponse =
  /** status 200  */ StructureSettingsResponseBody;
export type CreateTypeOfControlSettingsApiArg = {
  createTypeOfControlSettingsRequestBody: CreateTypeOfControlSettingsRequestBody;
};
export type StructureItemBody = {
  categoryId?: string | null;
  paramDefinitionId?: string | null;
  visible: boolean;
  mandatory: boolean;
};
export type StructureSettingsResponseBody = {
  name: string;
  key?: string;
  validation: 'VALIDATE' | 'NON_VALIDATE';
  items: StructureItemBody[];
};
export type CreateTypeOfControlSettingsRequestBody = {
  name: string;
  key: string | null;
  inspectionType:
    | 'vehicleCondition'
    | 'validationInspection'
    | 'nonValidationInspection'
    | 'autofillTemplate'
    | 'checkin'
    | 'handover'
    | 'complaint';
  validation: 'VALIDATE' | 'NON_VALIDATE';
  items: StructureItemBody[];
  version?: number | null;
};

export type GetStructureSettingsApiArg = {
  typeOfControlId?: string;
};
export type GetStructureSettingsApiResponse = /** status 200  */ StructureSettingsResponseBody;

export type DeleteInspectionApiResponse = /** status 204  */ undefined;
export type DeleteInspectionApiArg = {
  inspectionId: string;
  force: boolean;
};

export type InspectionListApiResponse = /** status 200  */ InspectionListResponseBody[];
const inspectionListRequestBodySchema = z.discriminatedUnion('inspectionType', [
  z.object({
    inspectionType: z.literal('autofillTemplate'),
    vehicleId: z.string().nullable(),
  }),
  z.object({
    inspectionType: z.enum([
      'vehicleCondition',
      'validationInspection',
      'nonValidationInspection',
      'checkin',
      'handover',
      'complaint',
    ]),
    vehicleId: z.string(),
  }),
]);
export const inspectionListApiArgSchema = z.object({
  inspectionListRequestBody: inspectionListRequestBodySchema,
});
export type InspectionListApiArg = z.infer<typeof inspectionListApiArgSchema>;
export type InspectionListResponseBody = {
  id: string;
  structureId: string;
  inspectionState: 'for_check' | 'in_progress' | 'finished_audit';
  finishedAt?: string | null;
  finishedByUser?: string | null;
};
export type InspectionListRequestBody = {
  inspectionType:
    | 'vehicleCondition'
    | 'validationInspection'
    | 'nonValidationInspection'
    | 'autofillTemplate'
    | 'checkin'
    | 'handover';
  vehicleId: string | null;
};

export type CreateInspectionApiResponse = /** status 201  */ VehicleAuditCreatedResponseBody;
export type CreateInspectionApiArg = {
  vehicleId: string;
  createAuditRequestBody: CreateAuditRequestBody;
};
export type VehicleAuditCreatedResponseBody = {
  id: string;
  vehicleId: string;
  vehicleAuditState: 'for_check' | 'in_progress' | 'finished_audit';
};
export type CreateAuditRequestBody = {
  inspectionType:
    | 'validationInspection'
    | 'vehicleCondition'
    | 'nonValidationInspection'
    | 'autofillTemplate'
    | 'checkin'
    | 'handover'
    | 'complaint';
  auditStructureId?: string | null;
  auditId?: string | null;
  alreadySync?: boolean;
  inspectionName?: string | null;
};

export type LoadAuditDataByVehicleIdApiResponse =
  /** status 200  */ LoadAuditDataResponseItemBody[];
export type LoadAuditDataByVehicleIdApiArg = {
  inspectionType: InspectionType;
  body: VehiclesRequestBody;
};
export type LoadAuditDataCategoriesResponseBody = {
  id: string;
  fields: ResponseAuditDataFieldsBody[];
  assets: AuditDataAssetsBody[];
};
export type VehiclesRequestBody = {
  vehicleIds: string[];
};
export type CreateAuditApiResponse = {
  id: string;
  vehicleAuditState: string;
  vehicleId: string;
};
export type CreateAuditApiArg = {
  vehicleId: string;
  body: CreateAuditRequestBody;
};
export type GetSpecificallyAuditDataApiResponse =
  /** status 200  */ SpecificallyAuditDataItemResponseBody[];
export type GetSpecificallyAuditDataApiArg = {
  inspectionType:
    | 'vehicleCondition'
    | 'validationInspection'
    | 'nonValidationInspection'
    | 'autofillTemplate'
    | 'checkin'
    | 'handover'
    | 'complaint';
  authorization?: string;
  specificallyAuditDataRequestBody: SpecificallyAuditDataRequestBody;
};
export type SpecificallyAuditDataItemValuesResponseBody = {
  key: string;
  value: string;
};
export type SpecificallyAuditDataItemResponseBody = {
  vehicleId: string;
  lastUpdate: string;
  values: SpecificallyAuditDataItemValuesResponseBody[];
};
export type SpecificallyAuditDataRequestBody = {
  vehicleIds: string[];
  keys: string[];
};
export type RotateImageAssetRequestBody = {
  images: string[];
  angle: 90 | 180 | 270;
};
export type RotateImageAssetApiResponse = /** status 201  */ RotateImageAssetResponseBody;
export type RotateImageAssetApiArg = {
  rotateImageAssetRequestBody: RotateImageAssetRequestBody;
};
export type RotateImageAssetResponseBody = {
  batchId: string;
};
export type FileOperationListApiResponse = /** status 200  */ FileOperationResponseBody[];
export type FileOperationListApiArg = {
  sourceFileId?: string[];
  sourceRemoteFileId?: string[];
  state?: string;
  batchId?: string;
  authorization?: string;
};
export type FileOperationTypeHttpBody = 'REMOVE_BACKGROUND' | 'ROTATE' | 'COPY';
export type VehicleAuditStatusLogs = {
  status: string;
  author: {
    id: string;
    label: string;
  };
  updatedAt: string;
}[];
export type GetVehicleAuditApiArg = {
  auditId?: string;
  workspace?: string;
};
export type GetVideoAssetResponseBody = {
  id: string;
  videoId: string;
  originUrl: string;
  previewUrl: string;
  coverImageUrl: string;
  variants: {
    type: string;
    url: string;
  }[];
};
export type GetVideoAssetApiResponse = /** status 200  */ GetVideoAssetResponseBody;
export type GetVideoAssetApiArg = {auditId: string; videoAssetId: string};
export type UploadVideoAssetResponseBody = {
  id: string;
};
export type UploadVideoAssetApiResponse = /** status 200  */ UploadVideoAssetResponseBody;
export type UploadVideoAssetApiArg = {
  auditId: string;
  body: {
    categoryId: string;
    paramDefinitionId: string;
    videoId: string;
    updatedAt: string;
    vehicleAuditVideoAssetId: string;
  };
};
export type DeleteVideoAssetApiArg = {auditId: string; videoAssetId: string};
