import {environment} from '@dms/environment';

import {getWorkspaceFromUri} from './getWorkspaceFromUri';

export type UrlBuilderConfig = {
  protocol: string;
  workspace?: string;
  domain: string;
  route?: string;
  searchParam?: string;
};

const REVIEW_INITIAL_URL = `${window.location.origin}`;

export function buildUrl(route: string, workspace?: string, isSearchParamsAdded = true) {
  const {workspace: workspaceFromUri, domain, isReviewApp} = getWorkspaceFromUri();

  const _workspace = workspace ?? workspaceFromUri;

  const builderConfig: UrlBuilderConfig = {
    protocol: window.location.protocol,
    workspace: environment.workspace ? undefined : _workspace,
    domain,
    route,
    searchParam: isSearchParamsAdded ? window.location.search : undefined,
  };

  return isReviewApp ? new URL(route, REVIEW_INITIAL_URL).href : urlBuilder(builderConfig);
}

export function urlBuilder(config: UrlBuilderConfig) {
  const {domain, protocol, searchParam, workspace, route} = config;

  return `${protocol}//${workspace ? workspace + '.' : ''}${domain}${route ?? ''}${
    searchParam ?? ''
  }`;
}
