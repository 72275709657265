import {Button, closeDialog, openDialog, Tooltip} from 'platform/components';

import {MouseEventHandler} from 'react';

import {head, isEmpty, reject} from 'ramda';
import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {AssignMechanicType} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useWorkshopUrl} from '../../../hooks/useWorkshopUrl';
import {AssignMechanicForm} from './AssignMechanicForm';

interface AssignMechanicProps extends TestIdProps {
  assignMechanics: (AssignMechanicType | null)[] | Nullish;
  jobId: string;
}

export function AssignMechanic(props: AssignMechanicProps) {
  const {orderId, serviceCaseId} = useWorkshopUrl();

  const onClick: MouseEventHandler = (event) => {
    event.stopPropagation();

    openDialog(
      <AssignMechanicForm
        orderId={orderId}
        serviceCaseId={serviceCaseId}
        serviceJobId={props.jobId}
        onClose={() => closeDialog('assignMechanic')}
        selectedMechanics={props.assignMechanics?.map((mechanic) => mechanic?.id ?? '') ?? []}
        data-testid={suffixTestId('dialog', props)}
      />,
      {
        id: 'assignMechanic',
        title: i18n.t('entity.orderRequest.labels.assignMechanic'),
        size: 'small',
      }
    );
  };

  const anotherCount = (props.assignMechanics?.length ?? 0) - 1;
  const allNames = props.assignMechanics?.map((mechanic) => mechanic?.name).join(', ');
  const buttonTitle = isEmpty(reject(isNilOrEmpty, props.assignMechanics ?? []))
    ? i18n.t('entity.orderRequest.labels.assignMechanic')
    : `${head(props.assignMechanics ?? [])?.name}${
        isPositive(anotherCount) ? ` +${anotherCount}` : ''
      }`;

  return (
    <Tooltip label={isEmpty(allNames) ? undefined : allNames}>
      <Button
        title={buttonTitle}
        leftIcon="social/person_add"
        variant="link"
        size="small"
        onClick={onClick}
        data-testid={props['data-testid']}
      />
    </Tooltip>
  );
}
