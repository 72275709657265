import {isNil} from 'ramda';

import {Nullish} from 'shared';

export function convertMileageToAlphaMileageRange(mileage?: number | Nullish): string | null {
  if (isNil(mileage)) {
    return null;
  }
  if (mileage < 5000) {
    return '<5k';
  }
  if (mileage < 15000) {
    return '5k-15k';
  }
  if (mileage < 30000) {
    return '15k-30k';
  }
  if (mileage < 50000) {
    return '30k-50k';
  }
  if (mileage < 75000) {
    return '50k-75k';
  }
  if (mileage < 100000) {
    return '75k-100k';
  }
  if (mileage < 150000) {
    return '100k-150k';
  }
  if (mileage < 200000) {
    return '150k-200k';
  }
  if (mileage < 250000) {
    return '200k-250k';
  }
  if (mileage < 300000) {
    return '250k-300k';
  }
  return '>300k';
}
