import {
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import {SchemaOf, mixed, object, string} from 'yup';

import {VFC, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {isNil, isNotNil, not} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  OfferSaleVehiclePatchRequestBody,
  OfferSaleVehicleResponseBody,
  omneticApi,
  selectTenant,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  usePatchSaleVehicleMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  DEFAULT_CURRENCY,
  NoPermissionTooltip,
  handleApiError,
  usePermissions,
  useSaleVehicleActions,
} from '@dms/shared';
import {$MoneyAmount} from '@dms/teas';

import {useRequiredParams} from 'shared';

export const BusinessCaseSaleVehicleDiscountForm: VFC = () => {
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const [lastTouched, setLastTouched] = useState<
    'discountPercentage' | 'discountPrice' | undefined
  >(undefined);
  const [formApiInternal, setFormApiInternal] =
    useState<UseFormReturn<OfferSaleVehiclePatchRequestBody> | null>(null);

  const {id: businessCaseId} = useRequiredParams();

  const [patchSaleVehicle, {isLoading: isPatchingSaleVehicle}] = usePatchSaleVehicleMutation();

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [canEditDiscount] = usePermissions({
    permissionKeys: ['businessCaseEditSaleDiscount'],
    scopes: {
      businessCaseEditSaleDiscount: {participation: businessCaseParticipation},
    },
  });

  const {data: {currency: tenantCurrency} = {}} = useSelector(selectTenant);

  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const offer = businessCase?.offers[0];
  const saleVehicles = businessCase?.offers?.[0]?.saleVehicles;

  const saleVehicle = saleVehicles?.[0] as OfferSaleVehicleResponseBody;
  const defaultValues: OfferSaleVehiclePatchRequestBody = {
    discountPrice:
      saleVehicle?.discountPrice?.withVat?.amount ??
      saleVehicle?.discountPrice?.withoutVat?.amount ??
      null,
    discountPercentage: saleVehicle?.discountPercentage ?? null,
    discountReason: saleVehicle?.discountReason ?? null,
  };

  const {isSaleActionEnabled} = useSaleVehicleActions(saleVehicle?.vehicleId ?? '');

  const defaultCurrency = isCurrency(tenantCurrency) ? tenantCurrency : DEFAULT_CURRENCY;
  const currency = isCurrency(saleVehicle?.discountPrice?.withVat?.currency)
    ? saleVehicle?.discountPrice?.withVat?.currency
    : defaultCurrency;

  const dispatch = useDispatch();

  const handleSubmit: FormSubmitHandler<OfferSaleVehiclePatchRequestBody> = async (values) => {
    if (isNilOrEmpty(saleVehicle)) {
      throw new Error('Sale vehicle not found');
    }
    if (
      isNilOrEmpty(offer?.id) ||
      (isNilOrEmpty(values.discountPercentage) && isNilOrEmpty(values.discountPrice))
    ) {
      return;
    }

    const discountReason = values.discountReason || null;

    await patchSaleVehicle({
      offerId: offer!.id,
      offerSaleVehiclePatchRequestBody:
        lastTouched === 'discountPercentage'
          ? {
              discountPercentage: values.discountPercentage?.toString() || null,
              discountReason,
              discountPrice: null,
            }
          : {
              discountPrice: values.discountPrice?.toString() || null,
              discountReason,
              discountPercentage: null,
            },
      businessCaseId,
      offerSaleVehicleId: saleVehicle?.id,
    })
      .unwrap()
      .then((data) => {
        if (isNotNil(formApiInternal)) {
          if (
            lastTouched === 'discountPrice' &&
            isNotNilOrEmpty(data.saleVehicles?.[0]?.discountPercentage)
          ) {
            formApiInternal!.setValue(
              'discountPercentage',
              data.saleVehicles![0]!.discountPercentage
            );
          }
          if (
            lastTouched === 'discountPercentage' &&
            (isNotNilOrEmpty(data.saleVehicles?.[0]?.discountPrice?.withVat?.amount) ||
              isNotNilOrEmpty(data.saleVehicles?.[0]?.discountPrice?.withoutVat?.amount))
          ) {
            formApiInternal!.setValue(
              'discountPrice',
              data.saleVehicles![0]!.discountPrice?.withVat?.amount ??
                data.saleVehicles![0]!.discountPrice?.withoutVat?.amount ??
                null
            );
          }
        }
        dispatch(omneticApi.util.invalidateTags([{type: 'offer', id: offer!.id}]));
      })
      .catch(handleApiError);
  };

  const isFieldDisabled = !isSaleActionEnabled('SALE_VEHICLE_UPDATE') || not(canEditDiscount);
  if (isNilOrEmpty(saleVehicle)) {
    return null;
  }

  return (
    <Form<OfferSaleVehiclePatchRequestBody>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={schema(saleVehicle?.sellingPrice?.withVat?.amount)}
    >
      {(control, formApi) => {
        if (isNil(formApiInternal)) {
          setFormApiInternal(formApi);
        }
        return (
          <Card
            variant="inlineGrey"
            title={i18n.t('entity.businessCase.labels.customerDiscount')}
            data-testid={testIds.businessCase.selling('discount')}
          >
            <HStack spacing={4}>
              <Box flexGrow={1}>
                <NoPermissionTooltip shouldShowTooltip={!canEditDiscount}>
                  <FormField
                    type="number"
                    name="discountPercentage"
                    control={control}
                    label={i18n.t('page.businessCase.labels.percentage')}
                    suffix="%"
                    isDisabled={isFieldDisabled}
                    onFocus={() => setLastTouched('discountPercentage')}
                    data-testid={testIds.businessCase.selling('discountPercentage')}
                  />
                </NoPermissionTooltip>
              </Box>
              <Box flexGrow={1}>
                <NoPermissionTooltip shouldShowTooltip={!canEditDiscount}>
                  <FormField
                    type="number"
                    name="discountPrice"
                    control={control}
                    label={i18n.t('general.labels.amount')}
                    suffix={formatCurrencySymbol(currency)}
                    isDisabled={isFieldDisabled}
                    onFocus={() => setLastTouched('discountPrice')}
                    data-testid={testIds.businessCase.selling('discountPrice')}
                  />
                </NoPermissionTooltip>
              </Box>
              <Box flexGrow={1}>
                <NoPermissionTooltip shouldShowTooltip={!canEditDiscount}>
                  <FormField
                    type="text"
                    name="discountReason"
                    control={control}
                    label={i18n.t('general.labels.reason')}
                    isDisabled={isFieldDisabled}
                    data-testid={testIds.businessCase.selling('discountReason')}
                  />
                </NoPermissionTooltip>
              </Box>
              <NoPermissionTooltip shouldShowTooltip={!canEditDiscount}>
                <HStack justify="flex-end" align="flex-end">
                  <FormButton
                    type="submit"
                    control={control}
                    title={i18n.t('general.actions.save')}
                    isDisabled={
                      isFieldDisabled ||
                      (isNil(formApi.getValues('discountPrice')) &&
                        isNil(formApi.getValues('discountPercentage')))
                    }
                    isLoading={isPatchingSaleVehicle}
                    data-testid={testIds.businessCase.selling('discountSubmitButton')}
                  />
                </HStack>
              </NoPermissionTooltip>
            </HStack>
          </Card>
        );
      }}
    </Form>
  );
};

const schema = (sellingPrice?: string): SchemaOf<OfferSaleVehiclePatchRequestBody> =>
  object().shape({
    discountPrice: $MoneyAmount({max: {amount: sellingPrice}})
      .defined()
      .nullable(),
    discountPercentage: mixed()
      .defined()
      .test(
        'max',
        i18n.t('general.notifications.numberMaxValidationMessage'),
        (value) => !value || value <= 100
      )
      .test(
        'typeError',
        i18n.t('general.notifications.numberValidationMessage'),
        (value) => !value || !isNaN(value)
      )
      .test(
        'positive',
        i18n.t('general.notifications.numberPositive'),
        (value) => !value || value >= 0
      )
      .nullable(),
    discountReason: string().defined().nullable(),
  });
