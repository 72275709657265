import {
  Actions,
  Button,
  DataStatus,
  DialogsContext,
  Parameters,
  useAnimatedPopper,
  openDialog,
  Action,
} from 'platform/components';
import {Box, HStack, Heading, Hide, Show, Space, ThemeIconKey, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';
import {Nullish} from 'utility-types';

import {useContext, useState} from 'react';

import {always, isNil, map, pipe, reject, sortBy} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetServiceCaseIntegrationsQuery,
  useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery,
  ServiceCaseIntegrationCode,
  ServiceCaseIntegrationActionKey,
  ServiceCaseIntegrationAction,
} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, noop, parseDate, suffixTestId} from 'shared';

import {CLARA_VEHICLE_INFO_VIEW} from '../../pages/ServiceCaseDetail/constants/claraVehicleInfoView';
import {DAIMLER_CLARA_VEHINFO} from '../../pages/ServiceCaseDetail/constants/daimlerClaraVehinfo';
import {MERCEDES_XENTRY_LINK} from '../../pages/ServiceCaseDetail/constants/mercedesXentryLink';
import {MERCEDES_XENTRY_SYNC} from '../../pages/ServiceCaseDetail/constants/mercedesXentrySync';
import {MERCEDES_XENTRY_SYNC_ACTION} from '../../pages/ServiceCaseDetail/constants/mercedesXentrySyncAction';
import {SyncXentry} from '../SyncXentry/SyncXentry';
import {ClaraVehicleInfoView} from './components/ClaraVehicleInfoView';

interface IntegrationsDropdownProps extends TestIdProps {
  serviceCaseId: string;
}

export function IntegrationsDropdown(props: IntegrationsDropdownProps) {
  const formatDateTime = useDateTimeFormatter();

  const [loadingAction, setLoadingAction] = useState<ServiceCaseIntegrationActionKey | null>(null);

  const {data, isError, isLoading, refetch} = useGetServiceCaseIntegrationsQuery(
    {serviceCaseId: props.serviceCaseId},
    {skip: isNilOrEmpty(props.serviceCaseId)}
  );

  const [getXentrySync] = useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery();

  const integrations = sortBy(
    (integration) => integration?.position ?? 0,
    data?.caseIntegration ?? []
  );

  const {isAnyDialogOpen} = useContext(DialogsContext);

  const {popperProps, Popper, togglePopper, referenceRef} = useAnimatedPopper({
    placement: 'bottom-end',
    gutter: 4,
    isOutsideClickIgnored: isAnyDialogOpen,
  });

  const onActionClick = (actionKey?: ServiceCaseIntegrationActionKey | Nullish) => {
    if (isNil(actionKey)) {
      return;
    }

    setLoadingAction(actionKey);

    match(actionKey)
      .with('clara-vehicle-info-view', () => {
        setLoadingAction(null);
        openDialog(
          <ClaraVehicleInfoView
            serviceCaseId={props.serviceCaseId}
            data-testid={suffixTestId('claraVehicleInfoView', props)}
          />,
          {title: i18n.t('entity.integration.labels.claraVehicleInfo'), size: 'large'}
        );
      })
      .with('xentry-job-sync', async () => {
        const {data} = await getXentrySync({
          serviceCaseId: props.serviceCaseId,
        });

        if (!data) {
          refetch();
          return;
        }

        setLoadingAction(null);

        openDialog(
          <SyncXentry
            serviceCaseId={props.serviceCaseId}
            data-testid={suffixTestId('syncXentry', props)}
          />,
          {
            title: i18n.t('entity.integration.labels.xentrySync'),
            id: 'selectXentryDialog',
            size: 'large',
          }
        );
      })
      .otherwise(always(noop));
  };

  const isAnyActionAllowed = integrations.some(
    (integration) => integration?.action?.some((action) => action?.style !== 'disabled') ?? false
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Box ref={referenceRef}>
        <Button
          title={i18n.t('entity.orderIntegration.labels.integration')}
          variant="secondary"
          data-testid={props['data-testid']}
          onClick={(event) => {
            event.stopPropagation();
            togglePopper();
          }}
          isDisabled={!isAnyActionAllowed}
        />
      </Box>
      <Popper {...popperProps}>
        <Box
          borderRadius="medium"
          boxShadow="elevation_2"
          border="1px solid"
          borderColor="palettes.neutral.40.100"
          backgroundColor="general.white"
          padding={3}
        >
          <Box padding={2}>
            <VStack spacing={4}>
              {integrations.map((integration) => (
                <Hide
                  key={integration?.integration?.featureCode}
                  when={integration?.integration?.featureCode === MERCEDES_XENTRY_LINK}
                >
                  <Box
                    padding={4}
                    border="1px solid"
                    borderColor="general.separator"
                    borderRadius="small"
                    backgroundColor={
                      !integration?.integration?.createdAt ? 'palettes.neutral.10.100' : undefined
                    }
                  >
                    <HStack spacing={4} align="center" justify="space-between" minWidth={100}>
                      <Heading size={4}>
                        {getIntegrationTitle(integration?.integration?.featureCode)}
                      </Heading>
                      <Actions
                        size="small"
                        actions={pipe(
                          reject<ServiceCaseIntegrationAction>(
                            (action) => action?.style === 'hidden'
                          ),
                          map<ServiceCaseIntegrationAction, Action>((action) => ({
                            type: 'button',
                            title: getActionTitle(action?.key),
                            leftIcon: getActionIcon(action?.key),
                            variant: 'outlined',
                            isLoading: loadingAction === action?.key,
                            onClick: (e) => {
                              e.stopPropagation();
                              onActionClick(action?.key);
                            },
                            isDisabled: action?.style === 'disabled',
                          }))
                        )(integration?.action ?? [])}
                        data-testid={suffixTestId('actions', props)}
                      />
                    </HStack>
                    <Show when={integration?.integration?.featureCode === MERCEDES_XENTRY_SYNC}>
                      <Space vertical={3} />
                      <HStack align="center">
                        <Parameters
                          color="secondary"
                          size="xSmall"
                          parameters={[
                            i18n.t('entity.orderIntegration.labels.lastSync', {
                              sync: isNotNilOrEmpty(integration?.integration?.updatedAt)
                                ? formatDateTime(
                                    'dateTimeShort',
                                    parseDate(integration?.integration?.updatedAt)
                                  )
                                : i18n.t('entity.orderIntegration.labels.noSync'),
                            }),
                          ]}
                          data-testid={suffixTestId('parameters', props)}
                        />
                      </HStack>
                    </Show>
                  </Box>
                </Hide>
              ))}
            </VStack>
          </Box>
        </Box>
      </Popper>
    </DataStatus>
  );
}

const getActionIcon = (key?: ServiceCaseIntegrationActionKey | Nullish) =>
  match<ServiceCaseIntegrationActionKey | Nullish, ThemeIconKey | undefined>(key)
    .with(CLARA_VEHICLE_INFO_VIEW, always('action/visibility'))
    .with(MERCEDES_XENTRY_SYNC_ACTION, always('action/autorenew'))
    .otherwise(always(undefined));

const getActionTitle = (key?: ServiceCaseIntegrationActionKey | Nullish) =>
  match<ServiceCaseIntegrationActionKey | Nullish, string>(key)
    .with(CLARA_VEHICLE_INFO_VIEW, always(i18n.t('general.actions.view')))
    .with(MERCEDES_XENTRY_SYNC_ACTION, always(i18n.t('entity.orderIntegration.actions.sync')))
    .otherwise(always(''));

const getIntegrationTitle = (key?: ServiceCaseIntegrationCode | Nullish) =>
  match<ServiceCaseIntegrationCode | Nullish, string>(key)
    .with(undefined, null, MERCEDES_XENTRY_LINK, always(''))
    .with(DAIMLER_CLARA_VEHINFO, always(i18n.t('entity.integration.labels.claraVehicleInfo')))
    .with(MERCEDES_XENTRY_SYNC, always(i18n.t('entity.orderIntegration.labels.xentryOrder')))
    .exhaustive();
