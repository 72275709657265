import {useCallback} from 'react';

import {GridApi} from '../types/AgGridTypes';

export const REFETCH_DATAGRID_EVENT = 'refetch_data_grid_event';

export const useRefreshServerSideStore = (gridCode: string, gridApi: GridApi | undefined) => {
  const refetchManually = useCallback(() => {
    if (gridApi) {
      gridApi.refreshServerSide({purge: true});
      gridApi.dispatchEvent({type: 'storeRefreshed'});
    }
  }, [gridApi]);

  window.addEventListener(REFETCH_DATAGRID_EVENT, (event) => {
    const eventGridCode = (event as CustomEvent<string>).detail;

    if (eventGridCode === gridCode) {
      refetchManually();
    }
  });

  return refetchManually;
};
