import {Attributes, AttributesRow, Button, Card, DataStatus} from 'platform/components';
import {HStack, Heading, Show, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {defaultTo, isNil, isNotNil, mergeAll} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {GetIssueNoteResponse, useGetCustomerV2Query, useGetVehicleQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {warehouseRoutes, workshopRoutes} from '@dms/routes';
import {DEFAULT_CURRENCY, Section, getCustomerName} from '@dms/shared';

import {EMPTY_PLACEHOLDER, RequiredTestIdProps, composePath, parseDate, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface OverviewProps extends RequiredTestIdProps {
  issueNote: GetIssueNoteResponse | undefined;
  isIssueNoteLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const {issueNoteId} = useWarehouseParams();
  const currencyFormatter = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const directSaleCustomerId = defaultTo(
    '',
    props.issueNote?.originDocTypeInformation.directSale?.customerId
  );
  const serviceOrderCustomerId = defaultTo(
    '',
    props.issueNote?.originDocTypeInformation.serviceOrder?.customerId
  );
  const serviceOrderIssueCustomerId = defaultTo(
    '',
    props.issueNote?.originDocTypeInformation.serviceOrderIssue?.customerId
  );

  const directSaleVehicleId = defaultTo(
    '',
    props.issueNote?.originDocTypeInformation.directSale?.vehicleId
  );
  const serviceOrderVehicleId = defaultTo(
    '',
    props.issueNote?.originDocTypeInformation.serviceOrder?.vehicleId
  );
  const serviceOrderIssueVehicleId = defaultTo(
    '',
    props.issueNote?.originDocTypeInformation.serviceOrderIssue?.vehicleId
  );

  const {data: directSaleCustomer, isLoading: isDirectSaleCustomerLoading} = useGetCustomerV2Query(
    {customerId: directSaleCustomerId},
    {skip: isNilOrEmpty(directSaleCustomerId)}
  );

  const {data: serviceOrderCustomer, isLoading: isServiceOrderCustomerLoading} =
    useGetCustomerV2Query(
      {customerId: serviceOrderCustomerId},
      {skip: isNilOrEmpty(serviceOrderCustomerId)}
    );

  const {data: serviceOrderIssueCustomer, isLoading: isServiceOrderIssueCustomerLoading} =
    useGetCustomerV2Query(
      {
        customerId: serviceOrderIssueCustomerId,
      },
      {skip: isNilOrEmpty(serviceOrderIssueCustomerId)}
    );

  const {data: directSaleVehicle, isLoading: isDirectSaleVehicleLoading} = useGetVehicleQuery(
    {vehicleId: directSaleVehicleId},
    {skip: isNilOrEmpty(directSaleVehicleId)}
  );

  const {data: serviceOrderVehicle, isLoading: isServiceOrderVehicleLoading} = useGetVehicleQuery(
    {vehicleId: serviceOrderVehicleId},
    {skip: isNilOrEmpty(serviceOrderVehicleId)}
  );

  const {data: serviceOrderIssueVehicle, isLoading: isServiceOrderIssueVehicleLoading} =
    useGetVehicleQuery(
      {vehicleId: serviceOrderIssueVehicleId},
      {skip: isNilOrEmpty(serviceOrderIssueVehicleId)}
    );

  const isLoading =
    props.isIssueNoteLoading ||
    isDirectSaleCustomerLoading ||
    isServiceOrderCustomerLoading ||
    isServiceOrderIssueCustomerLoading ||
    isDirectSaleVehicleLoading ||
    isServiceOrderVehicleLoading ||
    isServiceOrderIssueVehicleLoading;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {issueNoteId}]),
    [issueNoteId]
  );

  const getCurrentDateAndTime = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const openInNewTab = (
    entity: 'directSales' | 'serviceOrder' | 'serviceOrderIssue',
    id?: string
  ) => {
    if (isNil(id)) {
      return;
    }

    match(entity)
      .with('directSales', () =>
        window.open(
          composePath(warehouseRoutes.directSalesDetailOverview, {params: {id}}),
          '_blank'
        )
      )
      .with('serviceOrder', () =>
        window.open(composePath(workshopRoutes.serviceCaseDetail, {params: {id}}), '_blank')
      )
      .with('serviceOrderIssue', () =>
        window.open(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            params: {id},
          }),
          '_blank'
        )
      )
      .exhaustive();
  };

  const directSaleCustomerName = getCustomerName(directSaleCustomer);
  const serviceOrderCustomerName = getCustomerName(serviceOrderCustomer);
  const serviceOrderIssueCustomerName = getCustomerName(serviceOrderIssueCustomer);

  const issueNoteAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.issuedBy'),
      value: props.issueNote?.issueNoteInformation.issuedBy,
    },
    {
      label: i18n.t('general.labels.receivedBy'),
      value: props.issueNote?.issueNoteInformation.receivedBy,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: getCurrentDateAndTime(props.issueNote?.issueNoteInformation.createdAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfIssue'),
      value: getCurrentDateAndTime(props.issueNote?.issueNoteInformation.issuedAt),
    },
  ];

  const directSalesAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNote?.originDocTypeInformation.directSale?.number}
          rightIcon="action/launch"
          onClick={() =>
            openInNewTab('directSales', props.issueNote?.originDocTypeInformation.directSale?.id)
          }
          data-testid={suffixTestId('linkToDirectSale', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.type'),
      value: props.issueNote?.originDocTypeInformation.directSale?.type,
    },
    {
      label: i18n.t('general.labels.variant'),
      value: props.issueNote?.originDocTypeInformation.directSale?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: directSaleCustomerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: directSaleVehicle?.title,
    },
  ];

  const serviceOrderAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNote?.originDocTypeInformation.serviceOrder?.number}
          rightIcon="action/launch"
          onClick={() =>
            openInNewTab('serviceOrder', props.issueNote?.originDocTypeInformation.serviceOrder?.id)
          }
          data-testid={suffixTestId('serviceOrder', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.type'),
      value: props.issueNote?.originDocTypeInformation.serviceOrder?.type,
    },
    {
      label: i18n.t('general.labels.variant'),
      value: props.issueNote?.originDocTypeInformation.serviceOrder?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: serviceOrderCustomerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: serviceOrderVehicle?.title,
    },
  ];

  const serviceOrderIssueAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.issueNote?.originDocTypeInformation.serviceOrderIssue?.number}
          rightIcon="action/launch"
          onClick={() =>
            openInNewTab(
              'serviceOrderIssue',
              props.issueNote?.originDocTypeInformation.serviceOrderIssue?.id
            )
          }
          data-testid={suffixTestId('serviceOrderIssue', props)}
        />
      ),
    },
    {
      label: i18n.t('general.labels.type'),
      value: props.issueNote?.originDocTypeInformation.serviceOrderIssue?.type,
    },
    {
      label: i18n.t('general.labels.variant'),
      value: props.issueNote?.originDocTypeInformation.serviceOrderIssue?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: serviceOrderIssueCustomerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: serviceOrderIssueVehicle?.title,
    },
  ];

  const totalValue = currencyFormatter(
    defaultTo(0, props.issueNote?.summaryInformation.totalValue.amount),
    defaultTo(DEFAULT_CURRENCY, props.issueNote?.summaryInformation.totalValue.currency),
    2
  );

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={isLoading} minHeight={60}>
        <Card title={i18n.t('entity.issueNotes.labels.issueNoteInformation')}>
          <Attributes
            rows={issueNoteAttributes}
            size="quarter"
            data-testid={suffixTestId('issueNoteAttributes', props)}
          />

          <Space vertical={4} />

          <VStack spacing={4}>
            <Show when={isNotNil(props.issueNote?.originDocTypeInformation.directSale)}>
              <Card title={i18n.t('entity.issueNote.labels.directSales')} variant="inlineGrey">
                <Attributes
                  rows={directSalesAttributes}
                  size="quarter"
                  data-testid={suffixTestId('directSalesAttributes', props)}
                />
              </Card>
            </Show>
            <Show when={isNotNil(props.issueNote?.originDocTypeInformation.serviceOrder)}>
              <Card title={i18n.t('entity.issueNote.labels.serviceOrder')} variant="inlineGrey">
                <Attributes
                  rows={serviceOrderAttributes}
                  size="quarter"
                  data-testid={suffixTestId('serviceOrderAttributes', props)}
                />
              </Card>
            </Show>
            <Show when={isNotNil(props.issueNote?.originDocTypeInformation.serviceOrderIssue)}>
              <Card
                title={i18n.t('entity.issueNote.labels.serviceOrderIssue')}
                variant="inlineGrey"
              >
                <Attributes
                  rows={serviceOrderIssueAttributes}
                  size="quarter"
                  data-testid={suffixTestId('serviceOrderIssueAttributes', props)}
                />
              </Card>
            </Show>
          </VStack>
        </Card>

        <Space vertical={4} />

        <Card title={i18n.t('entity.issueNote.labels.issuedItems')}>
          <DataGrid
            gridCode="warehouse-issue-note-items"
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('issuedItems', props)}
          />

          <HStack minHeight={15} align="center" justify="space-between">
            <Heading size={5}>{i18n.t('general.labels.totalValue')}</Heading>
            <Heading size={5}>{totalValue}</Heading>
          </HStack>
        </Card>
      </DataStatus>
    </Section>
  );
}
