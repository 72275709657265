import {Action} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {ReactNode} from 'react';
import {matchPath, useLocation, useNavigate} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

import {PageHeading, PageTab} from './components/PageHeading';

export interface MainProps extends TestIdProps {
  tabs?: PageTab[];
  heading?: string;
  actions?: Action[];
  children?: ReactNode;
  isFullHeight?: boolean;
}

export function Main(props: MainProps) {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const height = props.isFullHeight ? '100%' : undefined;
  const foundTabIndex =
    props.tabs?.findIndex(({href}) => matchPath(pathname, href.split('?')[0])) ?? 0;
  const activeTabIndex = foundTabIndex >= 0 ? foundTabIndex : 0;

  const onTabChange = (index: number) => {
    if (activeTabIndex === index) {
      return;
    }

    props.tabs && navigate(props.tabs[index].href);
  };

  return (
    <Box
      flex={1}
      padding={4}
      height={height}
      minWidth={0}
      role="main"
      data-testid={suffixTestId('main-box', props)}
    >
      <VStack align="stretch" justify="stretch" height={height}>
        <VStack align="stretch" justify="stretch" height={height}>
          <PageHeading
            tabs={props.tabs}
            title={props.heading}
            actions={props.actions}
            onTabChange={onTabChange}
            activeTabIndex={activeTabIndex}
            data-testid={suffixTestId('main-heading', props)}
          />
          <Box flex={1} data-testid={suffixTestId('main-content', props)}>
            {props.tabs?.[activeTabIndex]?.content ?? props.children}
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
}
