import {closeDialog, openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {testIds, tiresInventoryRoutes} from '@dms/routes';
import {EditTireSet} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {ReceiveJobItemRowData} from './types/ReceiveJobItemRowData';
import {getDialogTitle} from './utils/getDialogTitle';

export function TierListOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData}) => {
    const castedRowData = rowData as ReceiveJobItemRowData;

    match(actionKey)
      .with('redirectDetail', () => {
        openDialog(
          <EditTireSet
            tireOrderId={castedRowData.orderId.value}
            setId={castedRowData.orderSetId.value}
            onClose={() => closeDialog('editTireSet')}
            onEdit={refreshData}
            data-testid={testIds.tiresInventory.tireList('tire-set-dialog')}
          />,
          {
            id: 'editTireSet',
            title: getDialogTitle(castedRowData),
            size: 'large',
          }
        );
      })
      .with('redirectTireOrder', () =>
        navigate(
          composePath(tiresInventoryRoutes.tireOrderDetail, {
            params: {id: castedRowData?.orderId?.value},
            queryParams: {source: 'tire-list'},
          })
        )
      )
      .otherwise(() => showNotification.error(`Action ${actionKey} is not implemented`));
  };

  return (
    <DataGrid
      gridCode="tire-list"
      actionCallback={actionCallback}
      data-testid={testIds.tiresInventory.tireList('grid')}
    />
  );
}
