import {Button} from 'platform/components';
import {Hide, HStack, Text, VStack} from 'platform/foundation';
import {css} from 'styled-components';

import {useLayoutEffect, useRef, useState} from 'react';

import {findLastIndex, not, range} from 'ramda';

import {SectorResponseBody, useGetOccupiedSpacesQuery, VehicleSpaceResponseBody} from '@dms/api';

import {suffixTestId, TestIdProps} from 'shared';

interface SectorProps extends TestIdProps {
  sector: SectorResponseBody;
  isShowingFree: boolean;
  selectedNumber: number | undefined;
  occupiedSpace: VehicleSpaceResponseBody | undefined;
  onSpaceClick: (spaceNumber: number) => void;
}

export function Sector(props: SectorProps) {
  const lastElementRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number | undefined>();

  const {data: occupiedSpaces} = useGetOccupiedSpacesQuery({sectorId: props.sector.sectorId});

  useLayoutEffect(() => setButtonWidth(lastElementRef.current?.clientWidth), [props.isShowingFree]);

  const spaces = range(1, props.sector.numberOfParkingSpaces + 1).map((space) => {
    const isSelected = props.selectedNumber === space;
    const isOccupied = occupiedSpaces?.some((occupiedSpace) => occupiedSpace.number === space);
    const isOriginalSelected =
      props.sector.sectorId === props.occupiedSpace?.sectorId &&
      space === props.occupiedSpace.number;

    return {
      title: `${props.sector.prefix}${space}`,
      value: space,
      isSelected,
      isOccupied,
      isOriginalSelected,
      isHidden: not(isSelected) && not(isOriginalSelected) && props.isShowingFree && isOccupied,
    };
  });

  const lastShowedIndex = findLastIndex((x) => not(x.isHidden), spaces);

  return (
    <VStack spacing={4}>
      <Text data-testid={suffixTestId('name', props)}>{props.sector.name}</Text>
      <HStack spacing={4} wrap>
        {spaces.map((space, index) => (
          <Hide key={space.value} when={space.isHidden}>
            <div
              css={css`
                max-width: 100%;
                width: ${buttonWidth}px;
              `}
              ref={index === lastShowedIndex ? lastElementRef : undefined}
            >
              <Button
                variant={space.isSelected ? 'primary' : 'secondary'}
                title={space.title}
                isDisabled={
                  not(space.isSelected) && not(space.isOriginalSelected) && space.isOccupied
                }
                onClick={() => props.onSpaceClick(space.value)}
                data-testid={suffixTestId(`space-${index}`, props)}
                isFullWidth
              />
            </div>
          </Hide>
        ))}
      </HStack>
    </VStack>
  );
}
