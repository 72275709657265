import {
  Alert,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {isNil, isNotNil} from 'ramda';

import {
  GetWarehouseHandlingUnitResponse,
  usePatchHandlingUnitMutation,
  usePostHandlingUnitMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {MeasurementUnitForm} from '../types/MeasurementUnitForm';

export interface MeasurementUnitsDetailFormProps extends RequiredTestIdProps {
  measurementUnit: GetWarehouseHandlingUnitResponse | Nullish;
}

export function MeasurementUnitsDetailForm(props: MeasurementUnitsDetailFormProps) {
  const navigate = useNavigate();

  const {duplicateError: isDuplicated, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [postMeasurementUnit] = usePostHandlingUnitMutation();
  const [patchMeasurementUnit] = usePatchHandlingUnitMutation();

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesMeasurementUnits);
  };

  const handleSubmit: FormSubmitHandler<MeasurementUnitForm> = async (data) => {
    if (isNotNil(props.measurementUnit)) {
      return await patchMeasurementUnit({
        id: props.measurementUnit.id,
        body: {
          name: data.name,
          description: data.description,
          isDefault: data.isDefault,
        },
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
        )
        .then(handleNavigateBack)
        .catch(handleApiError);
    }
    await postMeasurementUnit(data)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.measurementUnitCreated'))
      )
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  return (
    <Form<MeasurementUnitForm>
      schema={formSchema}
      defaultValues={props.measurementUnit}
      onSubmit={handleSubmit}
    >
      {(control) => (
        <VStack spacing={4} data-testid={props['data-testid']}>
          <Show when={isDuplicated}>
            <Alert
              variant="error"
              title={i18n.t('entity.warehouse.notifications.measurementUnitAlreadyExists')}
              data-testid={suffixTestId('measurementUnitAlreadyExists', props)}
            />
          </Show>
          <Grid columns={1}>
            <FormField
              isDisabled={props.measurementUnit?.isDefault}
              type="switch"
              name="isDefault"
              label={i18n.t('general.actions.setAsDefault')}
              control={control}
              data-testid={suffixTestId('inputs.setAsDefault', props)}
            />
          </Grid>
          <Separator />
          <Grid columns={2}>
            <FormField
              type="text"
              name="name"
              label={i18n.t('entity.warehouse.labels.name')}
              control={control}
              isRequired
              data-testid={suffixTestId('inputs.name', props)}
            />
            <FormField
              type="text"
              name="description"
              label={i18n.t('entity.warehouse.labels.description')}
              control={control}
              data-testid={suffixTestId('inputs.description', props)}
            />
          </Grid>
          <Space fillAvailable />
          <SettingsFooter
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: isNil(props.measurementUnit)
                  ? i18n.t('general.actions.discard')
                  : i18n.t('general.actions.discardChanges'),
                onClick: handleNavigateBack,
                'data-testid': suffixTestId('actions.discard', props),
              },
              {
                type: 'form-button',
                control,
                buttonType: 'submit',
                variant: 'primary',
                title: isNil(props.measurementUnit)
                  ? i18n.t('general.actions.save')
                  : i18n.t('general.actions.saveChanges'),
                'data-testid': suffixTestId('actions.submit', props),
              },
            ]}
          />
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  name: yupString.required(),
  isDefault: boolean().default(false),
});
