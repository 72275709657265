import {Tabs} from 'platform/components';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {MatrixList} from './(sections)/MatrixList/MatrixList';

export function MatrixManagement() {
  return (
    <SettingsTemplate
      data-testid={testIds.settings.accountingErrorLog('header')}
      header={{
        title: i18n.t('page.accounting.labels.accountingMatrixManagement'),
      }}
    >
      <Tabs
        data-testid={testIds.settings.accountingMatrixManagement('tabs')}
        tabs={[
          {
            id: 'metadaMatrices',
            title: i18n.t('page.accountingSettings.labels.metada'),
            content: <MatrixList category="matrices-config-from-metada-to-google" />,
          },
          {
            id: 'googleSheetsMatrices',
            title: i18n.t('page.accountingSettings.labels.googleSheets'),
            content: <MatrixList category="matrices-config-from-google-to-metada" />,
          },
        ]}
      />
    </SettingsTemplate>
  );
}
