import {FormField} from 'platform/components';
import {Grid} from 'platform/foundation';
import styled from 'styled-components';

import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  LinkButton,
  selectCashRegisterLimitsDocumentTypes,
  selectCashRegisterLimitsTypes,
} from '@dms/teas';

import {DefinitionFormType} from './types';

interface RestrictionSectionItemProps {
  disableRemove: boolean;
  readonly partlyChangeable?: boolean;
  defaultValue: Partial<DefinitionFormType['restrictions'][0]>;
  index: number;
  onRemove?: () => void;
}

export function RestrictionSectionItem(props: RestrictionSectionItemProps) {
  const form = useFormContext<DefinitionFormType>();
  const types = useSelector(selectCashRegisterLimitsTypes);
  const documentTypes = useSelector(selectCashRegisterLimitsDocumentTypes);

  const {control} = form;

  const currency = form.watch(`currency`);
  const documentTypeOptions = documentTypes.map((documentType) => ({
    value: documentType.id,
    label: documentType.label,
  }));
  const typeOptions = types.map((type) => ({
    value: type.id,
    label: type.label,
  }));

  return (
    <Grid columns={4}>
      <FormField
        type="text"
        /**
         * Will be resolved by https://carvago.atlassian.net/browse/T20-24077
         * Details: https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/2425#note_259960
         */
        // @ts-ignore
        control={control}
        name={`restrictions.${props.index}.amount.amount`}
        label={i18n.t('entity.cashRegisterLimits.labels.amount')}
        suffix={currency}
        data-testid={testIds.settings.cashLimits('amount')}
      />
      <FormField
        type="multiChoice"
        /**
         * Will be resolved by https://carvago.atlassian.net/browse/T20-24077
         * Details: https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/2425#note_259960
         */
        // @ts-ignore
        control={control}
        name={`restrictions.${props.index}.documentTypes`}
        label={i18n.t('entity.cashRegisterLimits.labels.documentType')}
        data-testid={testIds.settings.cashLimits('documentType')}
        options={documentTypeOptions}
        isNotClearable
      />
      <FormField
        type="date"
        /**
         * Will be resolved by https://carvago.atlassian.net/browse/T20-24077
         * Details: https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/2425#note_259960
         */
        // @ts-ignore
        control={control}
        name={`restrictions.${props.index}.validFrom`}
        label={i18n.t('entity.accounting.labels.validFrom')}
        data-testid={testIds.settings.cashLimits('validFrom')}
        isDisabled={props.partlyChangeable}
      />
      <FormField
        type="choice"
        /**
         * Will be resolved by https://carvago.atlassian.net/browse/T20-24077
         * Details: https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/2425#note_259960
         */
        // @ts-ignore
        control={control}
        name={`restrictions.${props.index}.type`}
        label={i18n.t('entity.cashRegisterLimits.labels.restriction')}
        data-testid={testIds.settings.cashLimits('restriction')}
        options={typeOptions}
        tooltip={i18n.t('entity.cashRegisterLimits.labels.restrictionTooltip')}
        isNotClearable
      />
      {!props.disableRemove && (
        <DangerLinkButton
          data-testid={testIds.settings.cashLimits(`remove-${props.index}`)}
          onClick={props.onRemove}
        >
          {i18n.t('entity.accounting.actions.removeValidity')}
        </DangerLinkButton>
      )}
    </Grid>
  );
}

const DangerLinkButton = styled(LinkButton)`
  padding: 0;
  color: ${({theme}) => theme.colors.palettes.red[70][100]};
`;
