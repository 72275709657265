import styled from 'styled-components';
import styledMap from 'styled-map';

import {forwardRef, ForwardRefRenderFunction, ReactEventHandler, ReactNode} from 'react';

import {or} from 'ramda';

import {FormLabel} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

const cursor = styledMap`
	default: pointer;
	disabled: default;
`;

const Label = styled(FormLabel)`
  cursor: ${cursor};
  position: relative;
  display: inline-flex;
  margin-right: 7px;
  margin-bottom: 11px;
  border-radius: ${({theme}) => theme.radii.small};

  &:hover {
    border-radius: ${({theme}) => theme.radii.small};
    box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;

  &:checked {
    + ${Label} {
      > div {
        border: 1px solid ${({theme}) => theme.colors.palettes.blue[60][100]};
        background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;

  &:nth-child(4n) label {
    margin-right: 0px;
  }
`;

type CheckboxProps = {
  name?: string;
  label?: string | ReactNode;
  disabled?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  className?: string;
  onChange?: ReactEventHandler;
} & TestIdProps;

const MakeCheckboxComponent: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  props,
  ref
) => {
  const {label, disabled, className, name, ...rest} = props;

  return (
    <Wrapper data-testid={suffixTestId(`wrapper`, props)}>
      <Input
        ref={ref}
        id={name}
        type="checkbox"
        disabled={disabled}
        data-testid={suffixTestId(`input`, props)}
        {...rest}
      />
      <Label
        htmlFor={name}
        className={className}
        disabled={disabled}
        data-testid={suffixTestId(`label`, props)}
      >
        {or(label, ' ')}
      </Label>
    </Wrapper>
  );
};

export const MakeCheckbox = forwardRef(MakeCheckboxComponent);
