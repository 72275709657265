import {Card, FormControl, FormField} from 'platform/components';
import {Grid} from 'platform/foundation';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {AccountingDocumentFormValues} from '../types';

interface PaymentsNotesProps extends TestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
}

export function PaymentsNotes(props: PaymentsNotesProps) {
  return (
    <Card>
      <Grid columns={2}>
        <FormField
          name="footerNote"
          control={props.control}
          minRows={3}
          type="textarea"
          label={i18n.t('entity.invoice.labels.textBehindItems')}
          data-testid={suffixTestId('textBehindItems', props)}
        />
        <FormField
          control={props.control}
          minRows={3}
          name="internalNote"
          type="textarea"
          label={i18n.t('entity.invoice.labels.internalNote')}
          data-testid={suffixTestId('internalNote', props)}
        />
      </Grid>
    </Card>
  );
}
