import {match, Pattern} from 'ts-pattern';

import {useDispatch} from 'react-redux';

import {always} from 'ramda';

import {
  CreateMinisaleApiArg,
  interestApi,
  MinisaleResponseBody,
  UpdateMinisaleApiArg,
  useCreateMinisaleMutation,
  useGetCurrentUserInfoQuery,
  useUpdateMinisaleMutation,
} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {useInterestData} from '../../../hooks/useInterestData';
import {BuyingFormType} from '../types/BuyingFormType';
import {transformBuyingFormToMinisale} from '../utils/transformBuyingFormToMinisale';

export function useMinisaleUpdate(interestId: string) {
  const dispatch = useDispatch();

  const {miniSale, isLoading} = useInterestData(interestId);

  const {data: currentUser} = useGetCurrentUserInfoQuery();

  const [createMinisale, {isLoading: isCreateMinisaleLoading}] = useCreateMinisaleMutation();
  const [updateMiniSale, {isLoading: isUpdateMinisaleLoading}] = useUpdateMinisaleMutation();

  const onBuyingUpdate = (values: BuyingFormType) => {
    const valuesWithCurrentUser: BuyingFormType = {...values, pricedBy: currentUser?.id};

    const minisaleRequestBody = transformBuyingFormToMinisale(valuesWithCurrentUser);

    if (miniSale && miniSale.id) {
      const arg: UpdateMinisaleApiArg = {
        updateMinisaleRequestBody: {
          interestId,
          ...minisaleRequestBody,
          minisaleId: miniSale.id,
        },
      };
      updateMiniSale(arg)
        .unwrap()
        .then(() => {
          if (shouldFetchMinisale(valuesWithCurrentUser, miniSale)) {
            dispatch(interestApi.util.invalidateTags([{type: 'miniSale', id: miniSale.id}]));
          }
        })
        .catch(handleApiError);
    } else {
      const arg: CreateMinisaleApiArg = {
        createMinisaleRequestBody: {
          interestId,
          ...minisaleRequestBody,
        },
      };
      createMinisale(arg).unwrap().catch(handleApiError);
    }
  };

  return {
    onBuyingUpdate,
    isMinisaleUpdateLoading: isLoading || isCreateMinisaleLoading || isUpdateMinisaleLoading,
  };
}

export const shouldFetchMinisale = (
  values: BuyingFormType,
  miniSale: MinisaleResponseBody | null
) =>
  match([
    values.vehicleData?.make,
    values.vehicleData?.modelFamily,
    miniSale?.vehicleData?.make,
    miniSale?.vehicleData?.modelFamily,
    values.pricedBy !== miniSale?.pricedBy,
  ])
    .with([Pattern.string, Pattern.string, Pattern.string, Pattern.string, true], always(true)) // pricer changed
    .with(
      [Pattern.string, Pattern.string, Pattern.nullish, Pattern.nullish, Pattern.boolean],
      always(true)
    )
    .with(
      [Pattern.string, Pattern.string, Pattern.any, Pattern.nullish, Pattern.boolean],
      always(true)
    )
    .with(
      [Pattern.string, Pattern.string, Pattern.nullish, Pattern.any, Pattern.boolean],
      always(true)
    )
    .with(
      [Pattern.any, Pattern.nullish, Pattern.string, Pattern.string, Pattern.boolean],
      always(true)
    )
    .with(
      [Pattern.nullish, Pattern.any, Pattern.string, Pattern.string, Pattern.boolean],
      always(true)
    )
    .with(
      [Pattern.nullish, Pattern.nullish, Pattern.string, Pattern.string, Pattern.boolean],
      always(true)
    )
    .otherwise(always(false));
