import {DataStatus} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {testIds, workshopRoutes} from '@dms/routes';
import {
  catchUnhandledDataGridActions,
  ProfitabilityHistory,
  queryParams,
  Section,
  WorkItems,
  workshopSearchParams,
} from '@dms/shared';

import {Nullish, composePath} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

type JobRow = {
  id: string;
  serviceCaseId: {value: string};
  serviceOrderId: {value: string};
  jobName: {value: string};
};

type ReceiveJobRowData = JobRow | JobRow[] | Nullish;

type ReceiveOrderRowData =
  | {
      serviceCaseId?: {value?: string};
    }
  | Nullish;

type ReceiveCaseRowData =
  | {
      caseState?: {value?: {key?: string}};
    }
  | Nullish;

interface HistoryOfRepairsProps {
  vehicleId: string | Nullish;
}

export function HistoryOfRepairs(props: HistoryOfRepairsProps) {
  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {vehicleId: props.vehicleId ?? null}]);

  const actionOrderCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as ReceiveOrderRowData;

    match(actionKey)
      .with('redirectDetail', 'redirectDetail_newTab', () => {
        const orderId = isArray(rowId) ? rowId[0] : rowId;

        window.open(
          composePath(workshopRoutes.serviceCaseDetail, {
            params: {
              id: castedRowData?.serviceCaseId?.value ?? '',
            },
            queryParams: {
              [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
              [queryParams.SERVICE_CASE_ORDER_ID]: orderId,
            },
          })
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const actionServiceRequestsCallback: ActionCallback = ({actionKey, rowData}) => {
    const castedRowData = rowData as ReceiveJobRowData;

    if (!isArray(castedRowData)) {
      const path = composePath(workshopRoutes.serviceCaseDetail, {
        params: {
          id: castedRowData?.serviceCaseId?.value,
        },
        queryParams: {
          [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
          [queryParams.SERVICE_CASE_ORDER_ID]: castedRowData?.serviceOrderId?.value ?? '',
          [queryParams.SERVICE_CASE_OPEN_JOB_ID]: castedRowData?.id,
        },
      });

      match(actionKey)
        .with('open', () => window.open(path))
        .otherwise(() => catchUnhandledDataGridActions(actionKey));
    }
  };

  const actionCaseCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as ReceiveCaseRowData;

    match(actionKey)
      .with('redirectDetail', 'redirectDetail_newTab', () => {
        const serviceCaseState = castedRowData?.caseState?.value?.key;
        const serviceCaseId = isArray(rowId) ? rowId[0] : rowId;

        let path;

        if (serviceCaseState === 'DRAFT') {
          path = composePath(workshopRoutes.draftServiceCase, {
            params: {id: serviceCaseId},
            queryParams: {
              [queryParams.COMPONENT_SECTIONS_TAB]: workshopSearchParams.create.serviceJobs,
            },
          });
        } else {
          path = composePath(workshopRoutes.serviceCaseDetail, {
            params: {
              id: serviceCaseId,
            },
            queryParams: {
              [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
            },
          });
        }

        window.open(path);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <Section
      data-testid={testIds.vehicles.historyOfRepairs('historyOfRepairs')}
      tabs={[
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="vehicle-history-service-case"
              minHeight={175}
            >
              <Box height={175}>
                <DataGrid
                  gridCode="service-history-service-case"
                  actionCallback={actionCaseCallback}
                  queryModifier={queryModifier}
                  data-testid={testIds.vehicles.historyOfRepairs('historyOfRepairs-serviceCases')}
                />
              </Box>
            </DataStatus>
          ),
          queryId: 'serviceCases',
          title: i18n.t('page.workshop.labels.serviceCases'),
        },
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="vehicle-history-service-order"
              minHeight={175}
            >
              <Box height={175}>
                <DataGrid
                  gridCode="service-history-service-order"
                  actionCallback={actionOrderCallback}
                  queryModifier={queryModifier}
                  data-testid={testIds.vehicles.historyOfRepairs('historyOfRepairs-serviceOrders')}
                />
              </Box>
            </DataStatus>
          ),
          queryId: 'serviceOrders',
          title: i18n.t('page.workshop.labels.serviceOrders'),
        },
        {
          content: (
            <DataStatus
              isLoading={isNil(props.vehicleId)}
              key="historyOfRepairs-service-requests"
              minHeight={175}
            >
              <Box height={175}>
                <DataGrid
                  gridCode="vehicle-history-order-requests"
                  actionCallback={actionServiceRequestsCallback}
                  queryModifier={queryModifier}
                  data-testid={testIds.vehicles.historyOfRepairs(
                    'historyOfRepairs-serviceRequests'
                  )}
                />
              </Box>
            </DataStatus>
          ),
          queryId: 'serviceRequests',
          title: i18n.t('page.workshop.labels.serviceRequests'),
        },
        {
          content: (
            <WorkItems
              localHistoryDatagridCode="vehicle-history-order-items"
              externalHistoryDatagridCode="vehicle-history-service-items-external-history"
              vehicleId={props.vehicleId}
              data-testid={testIds.vehicles.historyOfRepairs('historyOfRepairs-workItems')}
            />
          ),
          queryId: 'workItems',
          title: i18n.t('entity.orderRequest.labels.workItems'),
        },
        {
          content: (
            <ProfitabilityHistory
              vehicleId={props.vehicleId}
              data-testid={testIds.vehicles.historyOfRepairs('historyOfRepairs-profitability')}
            />
          ),
          queryId: 'profitability',
          title: i18n.t('entity.order.labels.profitability'),
        },
      ]}
    />
  );
}
