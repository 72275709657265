import {Card, CardVariantType} from 'platform/components';
import {VStack, Box} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {FC, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {
  Subscribe,
  useFormRenderer,
  selectUserSelectedLanguage,
  BasicCategoryOfStructure,
  AuditCategoryUniqueKey,
  TRANSLATED_LANGUAGES,
} from '@dms/teas';

import {SECOND_SET_KEY_PARAM_VALUE_MAP} from '../constants';
import {useConfirmationDialog} from '../hooks/useConfirmationDialog';
import {AuditSettingsOption, AuditStructureSettingsForm} from '../types';
import {getOptionsFromParamDefinition} from '../utils/legacy_utils';
import {getEquipmentIdsFromCategories} from '../utils/utils';
import {AuditSettingsFeatures} from './AuditSettingsFeatures';
import {AuditSettingsOptions} from './AuditSettingsOptions';

interface AuditCategorySettingsProps {
  category: BasicCategoryOfStructure;
  cardVariant?: CardVariantType;
  isExpanded: boolean;
  onTurnOffCategory?: () => void;
}

interface AuditSettingsCategoriesProps {
  categories?: BasicCategoryOfStructure[] | null;
  parentCategory?: BasicCategoryOfStructure;
  cardVariant?: CardVariantType;
  onTurnOffCategory?: () => void;
}

export const AuditSettingsCategories: FC<AuditSettingsCategoriesProps> = ({
  categories,
  ...props
}) => (
  <VStack spacing={4}>
    {categories
      ?.filter(
        ({uniqueKey}) =>
          uniqueKey !== AuditCategoryUniqueKey.LR_WHEEL &&
          uniqueKey !== AuditCategoryUniqueKey.RR_WHEEL &&
          uniqueKey !== AuditCategoryUniqueKey.RF_WHEEL
      )
      ?.map((category) => (
        <AuditSettingsCategoryCard key={category.id} category={category} {...props} />
      ))}
  </VStack>
);

const AuditSettingsCategory: FC<AuditCategorySettingsProps> = ({
  category,
  cardVariant,
  isExpanded,
  onTurnOffCategory,
}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const {
    form: {
      mutators: {setFieldValue},
      getState,
    },
  } = useFormRenderer<AuditStructureSettingsForm>();
  const theme = useTheme();

  const isSecondSetCategory = useMemo(
    () => category.key && Object.keys(SECOND_SET_KEY_PARAM_VALUE_MAP).includes(category.key),
    [category]
  );

  const getPhotoDocumentationOptions = useCallback((): AuditSettingsOption[] => {
    const options: AuditSettingsOption[] = [];

    category.childCategories?.forEach((category) => {
      category.paramDefinitions?.relatedActions?.forEach((paramDefinition) => {
        if (!paramDefinition.label) {
          return;
        }

        options.push({
          label: getTranslation(locale, paramDefinition.label),
          paramDefinitionId: paramDefinition.id,
          categoryId: category.id,
          children: [],
        });
      });
    });

    return options;
  }, [category.childCategories]);

  const getEquipmentOptions = useCallback((): AuditSettingsOption[] => {
    const firstEquipment = category.childCategories?.[0].childCategories?.[0];

    return getOptionsFromParamDefinition(firstEquipment?.paramDefinitions, category.name, locale);
  }, [category.childCategories]);

  const options = useMemo((): AuditSettingsOption[] => {
    if (category.uniqueKey === AuditCategoryUniqueKey.PHOTODOCUMENTATION) {
      return getPhotoDocumentationOptions();
    }

    if (category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT) {
      return getEquipmentOptions();
    }

    const paramDefinitions = isSecondSetCategory
      ? (category.childCategories?.[0]?.paramDefinitions ?? null)
      : category.paramDefinitions;

    return getOptionsFromParamDefinition(paramDefinitions, category.name, locale);
  }, [category]);

  const hasShowOptions = useMemo(
    () =>
      !!options.length &&
      !(category.uniqueKey === AuditCategoryUniqueKey.THE_SECOND_SET && !isSecondSetCategory),
    [options, category, isSecondSetCategory]
  );

  const hasShowChildren = useMemo(
    () =>
      Boolean(category.childCategories?.length) &&
      !isSecondSetCategory &&
      category.uniqueKey !== AuditCategoryUniqueKey.PHOTODOCUMENTATION &&
      category.uniqueKey !== AuditCategoryUniqueKey.EQUIPMENT,
    [category, isSecondSetCategory]
  );

  const isAllOptionsAndChildCategoriesUnchecked = useCallback(
    (isAllOptionsUnchecked?: boolean) => {
      const formValues = getState()?.values;

      for (const childCategory of category?.childCategories ?? []) {
        if (formValues.categories?.[childCategory.id] !== false) {
          return false;
        }
      }

      if (isAllOptionsUnchecked) {
        return true;
      }

      for (const {paramDefinitionId} of options) {
        if (formValues?.paramDefinitions?.[paramDefinitionId]?.visible !== false) {
          return false;
        }
      }

      return true;
    },
    [category, options]
  );

  const turnOffCategory = useCallback(
    (isAllOptionsUnchecked?: boolean) => {
      if (hasShowChildren && !isAllOptionsAndChildCategoriesUnchecked(isAllOptionsUnchecked)) {
        return;
      }

      setFieldValue(`categories.${category.id}`, false);
      onTurnOffCategory?.();
    },
    [isAllOptionsAndChildCategoriesUnchecked, hasShowChildren]
  );

  return (
    <>
      {hasShowOptions && (
        <Box paddingBottom={4}>
          <AuditSettingsOptions
            options={options}
            isExpanded={isExpanded}
            onAllOptionsUnchecked={() => {
              turnOffCategory(true);
            }}
          />
        </Box>
      )}
      {category.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT && (
        <Box
          borderRadius={theme.components.Card.borderRadius}
          borderColor="palettes.neutral.40.100"
          border="1px solid"
          padding={4}
          top={4}
        >
          <AuditSettingsFeatures category={category} />
        </Box>
      )}
      {hasShowChildren && (
        <AuditSettingsCategories
          categories={category.childCategories ?? []}
          parentCategory={category}
          cardVariant={cardVariant}
          onTurnOffCategory={() => {
            turnOffCategory?.();
          }}
        />
      )}
    </>
  );
};

interface AuditSettingsCategoryCardProps {
  category: BasicCategoryOfStructure;
  parentCategory?: BasicCategoryOfStructure;
  cardVariant?: CardVariantType;
  onTurnOffCategory?: () => void;
}

const AuditSettingsCategoryCard: FC<AuditSettingsCategoryCardProps> = ({
  category,
  parentCategory,
  cardVariant,
  onTurnOffCategory,
}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const {Field} = useFormRenderer<AuditStructureSettingsForm>();
  const {getConfirmation} = useConfirmationDialog();
  const [expanded, setExpanded] = useState<boolean>(false);

  const {id, uniqueKey} = category;
  const isExpandable =
    uniqueKey !== AuditCategoryUniqueKey.TESTDRIVE &&
    uniqueKey !== AuditCategoryUniqueKey.EXTERIOR_DAMAGE &&
    uniqueKey !== AuditCategoryUniqueKey.INTERIOR_DAMAGE;

  const getSecondSetCategoryName = (key: string, parentCategory?: BasicCategoryOfStructure) => {
    const toggleParam = parentCategory?.paramDefinitions?.relatedActions?.[0];
    const radioButtonsParam = toggleParam?.values?.[0]?.content?.relatedActions?.[0];
    const label = radioButtonsParam?.values?.find(
      ({value}) => value === SECOND_SET_KEY_PARAM_VALUE_MAP?.[key]
    )?.label;

    return label ? getTranslation(locale, label) : key;
  };

  const getCategoryName = (
    {name, key, uniqueKey}: BasicCategoryOfStructure,
    parentCategory?: BasicCategoryOfStructure
  ) => {
    if (key && SECOND_SET_KEY_PARAM_VALUE_MAP?.[key]) {
      return getSecondSetCategoryName(key, parentCategory);
    }

    if (uniqueKey === AuditCategoryUniqueKey.LF_WHEEL) {
      return i18n.t`entity.condition.labels.theFirstSet`;
    }

    if (uniqueKey === AuditCategoryUniqueKey.EQUIPMENT) {
      return i18n.t('entity.vehicle.labels.features');
    }

    return getTranslation(locale, name);
  };

  return (
    // @ts-ignore Type instantiation is excessively deep and possibly infinite.
    <Subscribe
      name="equipmentValues"
      component={({input: equipmentValuesInput}) => (
        <Field
          name={`categories.${id}`}
          component={({input}) => {
            const value = Boolean(input.value);

            const setEquipmentValues = equipmentValuesInput.onChange;

            const isChecked = (() => {
              if (parentCategory && (input.value === undefined || input.value === '')) {
                return true;
              }

              return value;
            })();

            const isExpanded = isExpandable ? (parentCategory ? isChecked : expanded) : false;

            const handleChange = (value: boolean) => {
              const filteredOutValuesFromCategory = (equipmentValuesInput.value || []).filter(
                (id: string) =>
                  !category.childCategories
                    ?.map((cat) => cat.id)
                    .concat(category.id)
                    .includes(id)
              );

              if (!value) {
                onTurnOffCategory?.();
                setEquipmentValues(filteredOutValuesFromCategory);
              }

              if (value || !isExpandable) {
                input.onChange(value);
                setEquipmentValues(
                  equipmentValuesInput.value.concat(getEquipmentIdsFromCategories([category]))
                );
              }

              if (!value && isExpandable) {
                getConfirmation()
                  .then(() => {
                    input.onChange(value);
                    setEquipmentValues(filteredOutValuesFromCategory);
                  })
                  .catch(() => {});
              }

              setExpanded(value);
            };

            if (!parentCategory && isExpanded && !value) {
              setExpanded(value);
            }

            return (
              <Card
                variant={cardVariant}
                isExpanded={isExpanded}
                isExpandable={isExpandable && !parentCategory && value}
                title={getCategoryName(category, parentCategory)}
                control={{
                  type: 'switch',
                  value: isChecked,
                  onChange: handleChange,
                }}
                onExpandButtonClick={() => setExpanded(!isExpanded)}
              >
                {isExpanded ? (
                  <AuditSettingsCategory
                    category={category}
                    isExpanded={isExpanded}
                    cardVariant={cardVariant === 'inlineWhite' ? 'inlineGrey' : 'inlineWhite'}
                    onTurnOffCategory={() => {
                      if (parentCategory) {
                        onTurnOffCategory?.();
                      }
                    }}
                  />
                ) : null}
              </Card>
            );
          }}
        />
      )}
    />
  );
};

const getTranslation = (locale: TRANSLATED_LANGUAGES, translatable?: Record<string, string>) => {
  if (!translatable) {
    return '';
  }
  switch (locale) {
    case TRANSLATED_LANGUAGES.czech:
      return translatable.cs;
    case TRANSLATED_LANGUAGES.slovak:
      return translatable.sk;
    case TRANSLATED_LANGUAGES.germany:
      return translatable.de;
    case TRANSLATED_LANGUAGES.france:
      return translatable.fr;
    case TRANSLATED_LANGUAGES.spain:
      return translatable.es;
    case TRANSLATED_LANGUAGES.poland:
      return translatable.pl;
    case TRANSLATED_LANGUAGES.italy:
      return translatable.it;
    default:
      return translatable.en;
  }
};
