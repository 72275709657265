import {closeCurrentDialog, openDialog} from 'platform/components';
import {useFormatCurrency} from 'platform/locale';

import {UserRole} from '@dms/api';
import i18n from '@dms/i18n';

import {InsuranceData} from '../../../hooks/useInsuranceData';
import {BecomeSellerForm} from './BecomeSellerForm';
import {InsuranceActionCard} from './InsuranceActionCard';

interface BecomeSellerCardProps {
  insuranceData: InsuranceData;
}

export function BecomeSellerCard(props: BecomeSellerCardProps) {
  const formatCurrency = useFormatCurrency();

  const isInsurer = props.insuranceData.role !== UserRole.NONE;

  function handleBecomeSeller() {
    openDialog(
      <BecomeSellerForm
        insuranceData={props.insuranceData}
        onDone={closeCurrentDialog}
        onCancel={closeCurrentDialog}
      />,
      {
        title: i18n.t('entity.insurance.labels.insuranceSelling'),
      }
    );
  }

  return (
    <InsuranceActionCard
      button={{
        variant: isInsurer ? 'outlined' : 'primary',
        rightIcon: 'hardware/keyboard_arrow_right',
        title: i18n.t('entity.insurance.actions.becomeSeller'),
        onClick: handleBecomeSeller,
      }}
      image="assets/images/insurance_handshake.png"
      title={i18n.t('entity.insurance.labels.insuranceSelling')}
      amount={`${formatCurrency(props.insuranceData.insuranceOffer?.commissionFull ?? 0, 'CZK')}`}
      description={i18n.t('entity.insurance.labels.averageFeeForThisCar')}
      footer={isInsurer ? null : i18n.t('entity.insurance.labels.sellersUseThisOption')}
    />
  );
}
