import {
  ListSentToastNotificationsApiResponse,
  ListSentToastNotificationsApiArg,
  PatchUnreadSentToastNotificationsApiArg,
  PatchSentToastNotificationApiResponse,
  PatchSentToastNotificationApiArg,
  GetSentToastNotificationApiResponse,
  GetSentToastNotificationApiArg,
  RenderCustomerEmailTemplateApiArg,
  RenderCustomerEmailTemplateApiResponse,
  RenderCustomerEmailTemplateExampleApiArg,
  RenderCustomerEmailTemplateExampleApiResponse,
  RenderCustomerSmsTemplateApiArg,
  RenderCustomerSmsTemplateApiResponse,
  RenderCustomerSmsTemplateExampleApiArg,
  RenderCustomerSmsTemplateExampleApiResponse,
  ListContextParametersApiArg,
  ListContextParametersApiResponse,
  CreateMessageTemplateApiArg,
  CreateMessageTemplateApiResponse,
  GetMessageTemplateApiArg,
  GetMessageTemplateApiResponse,
  PatchMessageTemplateApiArg,
  PatchMessageTemplateApiResponse,
  DeleteMessageTemplateApiArg,
  DeleteMessageTemplateApiResponse,
  GetContextTemplateListApiArg,
  GetContextTemplateListApiResponse,
  ListNotificationDefinitionsApiArg,
  ListNotificationDefinitionsApiResponse,
  GetToastNotificationExampleApiArg,
  GetToastNotificationExampleApiResponse,
  PreviewEmailNotificationApiResponse,
  PreviewEmailNotificationApiArg,
  RenderServiceOrderEmailTemplateApiArg,
  RenderServiceOrderEmailTemplateApiResponse,
  RenderServiceOrderEmailTemplateExampleApiArg,
  RenderServiceOrderEmailTemplateExampleApiResponse,
  RenderServiceOrderSmsTemplateApiArg,
  RenderServiceOrderSmsTemplateApiResponse,
  RenderServiceOrderSmsTemplateExampleApiArg,
  RenderServiceOrderSmsTemplateExampleApiResponse,
  PatchTenantNotificationSettingsApiResponse,
  PatchTenantNotificationSettingsApiArg,
  PatchUserNotificationSettingsApiArg,
  PatchUserNotificationSettingsApiResponse,
  GetNotificationSettingsApiArg,
  GetNotificationSettingsApiResponse,
  GetNotificationDefinitionsApiResponse,
  GetNotificationDefinitionsApiArg,
} from '../types/messaging';
import {omneticApi} from './baseApi/omneticApi';

export const messagingApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    listSentToastNotifications: build.query<
      ListSentToastNotificationsApiResponse,
      ListSentToastNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification`,
        params: {limit: queryArg.limit, after: queryArg.after},
      }),
      providesTags: (result) =>
        result?.notifications
          ? [
              ...result.notifications.map(({id}) => ({type: 'Notification' as const, id})),
              {type: 'Notification', id: 'LIST'},
            ]
          : [{type: 'Notification', id: 'LIST'}],
    }),
    listContextParameters: build.query<
      ListContextParametersApiResponse,
      ListContextParametersApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/${queryArg.context}/parameter`,
      }),
    }),
    getSentToastNotification: build.query<
      GetSentToastNotificationApiResponse,
      GetSentToastNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification/${queryArg.id}`,
      }),
    }),

    getSentBackgroundNotification: build.query<
      GetSentToastNotificationApiResponse,
      GetSentToastNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/background-notification/${queryArg.id}`,
      }),
    }),

    patchUnreadSentToastNotifications: build.mutation<
      unknown,
      PatchUnreadSentToastNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification/unread`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'Notification', id: 'LIST'}],
    }),

    patchSentToastNotification: build.mutation<
      PatchSentToastNotificationApiResponse,
      PatchSentToastNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'Notification', id: 'LIST'}],
    }),
    createTemplate: build.mutation<CreateMessageTemplateApiResponse, CreateMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/template/${queryArg.templateType}`,
        method: 'POST',
        body: queryArg.createTemplateRequestBody,
      }),
      invalidatesTags: ['MessageTemplates'],
    }),
    getTemplate: build.query<GetMessageTemplateApiResponse, GetMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/template/${queryArg.templateType}/${queryArg.templateId}`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {
          type: 'MessageTemplate',
          id: queryArg.templateId,
        },
      ],
    }),
    patchTemplate: build.mutation<PatchMessageTemplateApiResponse, PatchMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/template/${queryArg.templateType}/${queryArg.templateId}`,
        method: 'PATCH',
        body: queryArg.patchTemplateRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {
          type: 'MessageTemplate',
          id: queryArg.templateId,
        },
        'MessageTemplates',
      ],
    }),
    deleteTemplate: build.mutation<DeleteMessageTemplateApiResponse, DeleteMessageTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/template/${queryArg.templateType}/${queryArg.templateId}`,
        method: 'DELETE',
      }),
    }),
    getContextTemplateList: build.query<
      GetContextTemplateListApiResponse,
      GetContextTemplateListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/template`,
        params: {type: queryArg.type, context: queryArg.context},
      }),
      providesTags: ['MessageTemplates'],
    }),
    renderCustomerEmailTemplate: build.mutation<
      RenderCustomerEmailTemplateApiResponse,
      RenderCustomerEmailTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/customer/render-email`,
        method: 'POST',
        body: queryArg.renderCustomerEmailTemplateRequestBody,
      }),
    }),
    renderCustomerEmailTemplateExample: build.mutation<
      RenderCustomerEmailTemplateExampleApiResponse,
      RenderCustomerEmailTemplateExampleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/customer/render-email-example`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    renderCustomerSmsTemplate: build.mutation<
      RenderCustomerSmsTemplateApiResponse,
      RenderCustomerSmsTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/customer/render-sms`,
        method: 'POST',
        body: queryArg.renderCustomerSmsTemplateRequestBody,
      }),
    }),
    renderCustomerSmsTemplateExample: build.mutation<
      RenderCustomerSmsTemplateExampleApiResponse,
      RenderCustomerSmsTemplateExampleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/customer/render-sms-example`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    renderServiceOrderEmailTemplate: build.mutation<
      RenderServiceOrderEmailTemplateApiResponse,
      RenderServiceOrderEmailTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/service-order/render-email`,
        method: 'POST',
        body: queryArg.renderServiceOrderEmailTemplateRequestBody,
      }),
    }),
    renderServiceOrderEmailTemplateExample: build.mutation<
      RenderServiceOrderEmailTemplateExampleApiResponse,
      RenderServiceOrderEmailTemplateExampleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/service-order/render-email-example`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    renderServiceOrderSmsTemplate: build.mutation<
      RenderServiceOrderSmsTemplateApiResponse,
      RenderServiceOrderSmsTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/service-order/render-sms`,
        method: 'POST',
        body: queryArg.renderServiceOrderSmsTemplateRequestBody,
      }),
    }),
    renderServiceOrderSmsTemplateExample: build.mutation<
      RenderServiceOrderSmsTemplateExampleApiResponse,
      RenderServiceOrderSmsTemplateExampleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/context/service-order/render-sms-example`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listNotificationDefinitions: build.query<
      ListNotificationDefinitionsApiResponse,
      ListNotificationDefinitionsApiArg
    >({
      query: () => ({
        url: `/dms/v1/messaging/notification/definition`,
      }),
    }),
    getNotificationDefinitions: build.query<
      GetNotificationDefinitionsApiResponse,
      GetNotificationDefinitionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/definition/${queryArg.id}`,
      }),
    }),
    getToastNotificationExample: build.query<
      GetToastNotificationExampleApiResponse,
      GetToastNotificationExampleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/definition/${queryArg.id}/example-toast-notification`,
      }),
    }),
    previewEmailNotification: build.query<
      PreviewEmailNotificationApiResponse,
      PreviewEmailNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/definition/${queryArg.id}/example-email-notification/${queryArg.locale}`,
        responseHandler: async (response) => {
          const text = await response.text();
          return text;
        },
      }),
    }),
    patchTenantNotificationSettings: build.mutation<
      PatchTenantNotificationSettingsApiResponse,
      PatchTenantNotificationSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/definition/${queryArg.id}/tenant-settings`,
        method: 'PATCH',
        body: queryArg.patchTenantNotificationSettingsRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'notificationSettings', id: queryArg.id},
      ],
    }),
    patchUserNotificationSettings: build.mutation<
      PatchUserNotificationSettingsApiResponse,
      PatchUserNotificationSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/definition/${queryArg.id}/user-settings`,
        method: 'PATCH',
        body: queryArg.patchUserNotificationSettingsRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'notificationSettings', id: queryArg.id},
      ],
    }),
    getNotificationSettings: build.query<
      GetNotificationSettingsApiResponse,
      GetNotificationSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/definition/${queryArg.id}/settings`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'notificationSettings', id: queryArg.id}],
    }),
  }),
});

export const {
  useGetNotificationDefinitionsQuery,
  useGetNotificationSettingsQuery,
  usePatchUserNotificationSettingsMutation,
  usePatchTenantNotificationSettingsMutation,
  usePreviewEmailNotificationQuery,
  useGetToastNotificationExampleQuery,
  useListNotificationDefinitionsQuery,
  useListSentToastNotificationsQuery,
  useGetSentToastNotificationQuery,
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetSentToastNotificationQuery,
  usePatchUnreadSentToastNotificationsMutation,
  usePatchSentToastNotificationMutation,
  useListContextParametersQuery,
  useCreateTemplateMutation,
  useGetTemplateQuery,
  usePatchTemplateMutation,
  useDeleteTemplateMutation,
  useRenderCustomerEmailTemplateExampleMutation,
  useRenderCustomerEmailTemplateMutation,
  useRenderCustomerSmsTemplateExampleMutation,
  useRenderCustomerSmsTemplateMutation,
  useRenderServiceOrderEmailTemplateExampleMutation,
  useRenderServiceOrderEmailTemplateMutation,
  useRenderServiceOrderSmsTemplateExampleMutation,
  useRenderServiceOrderSmsTemplateMutation,
  useGetContextTemplateListQuery,
} = messagingApi;
