import {CountryCode} from 'libphonenumber-js';
import {formatPhoneNumber} from 'platform/locale';

import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {selectCurrentUserInfo} from '@dms/api';

import {Nullish} from 'shared';

export function useUserPhoneNumber(): string | null {
  const {data} = useSelector(selectCurrentUserInfo);

  const phoneNumber = useMemo(
    () =>
      formatPhoneNumber(
        `${data?.phoneNumber?.prefix}${data?.phoneNumber?.number}`,
        data?.phoneNumber?.countryCode as CountryCode | Nullish
      ),
    [data]
  );

  return phoneNumber;
}
