import {DataStatus} from 'platform/components';
import {Image} from 'platform/foundation';
import styled from 'styled-components';

import {ChangeEvent} from 'react';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useMakeModelFilter} from '../../../hooks/useMakeModelFilter';
import {TOP_MAKES} from '../constants/topMakes';
import {getBrandImageUrl} from '../utils/getBrandImageUrl';
import {MakeCheckbox} from './MakeCheckbox';

export function TopMakes(props: TestIdProps) {
  const {makes, allSelectedMakes, selectMake, unselectMake, isLoading} = useMakeModelFilter();
  const topMakes = Object.values(makes ?? {}).filter((make) => TOP_MAKES.includes(make.name));

  const handleChangeMake = (make: string, isSelected: boolean) => {
    isSelected ? selectMake(make) : unselectMake(make);
  };

  return (
    <Wrapper>
      <DataStatus
        isLoading={isLoading}
        isEmpty={topMakes?.length === 0}
        emptyMessage={i18n.t('page.filters.labels.noMakes')}
        grow={1}
      >
        {topMakes?.map(({name, label}) => {
          const isSelected = allSelectedMakes.includes(name);

          return (
            <MakeCheckbox
              key={name}
              name={`make.${name}`}
              label={
                <Box title={label}>
                  <Image
                    src={getBrandImageUrl(name)}
                    alt={label}
                    data-testid={suffixTestId(`topMake-${name}`, props)}
                  />
                </Box>
              }
              checked={isSelected}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeMake(name, e.target.checked)
              }
              data-testid={suffixTestId(`topMake-${name}`, props)}
            />
          );
        })}
      </DataStatus>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 189px;
  margin-bottom: 9px;
  min-height: 50px;
`;

const Box = styled.div`
  width: 42px;
  height: 42px;
  background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  border-radius: ${({theme}) => theme.radii.small};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[20][100]};
  font-size: 10px;
  padding: 3px;
`;
