import {FormControl, FormField} from 'platform/components';
import {Box, HStack, Heading, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {FormType} from '../../types/ServiceOrderVariant';
import {InternalPayment} from './components/InternalPayment';
import {PaymentType} from './components/PaymentType';
import {getOrderPaymentTypeOptions} from './utils/getOrderPaymentTypeOptions';

interface PaymentTabProps {
  control: FormControl<FormType>;
  formApi: UseFormReturn<FormType>;
}

export function PaymentTab(props: PaymentTabProps) {
  const navigate = useNavigate();

  const paymentType = props.formApi.watch('paymentType');
  const orderPaymentTypeOptions = getOrderPaymentTypeOptions(paymentType);

  return (
    <>
      <VStack spacing={4}>
        <Heading size={4}>{i18n.t('entity.bank.labels.paymentType')}</Heading>
        <PaymentType
          formApi={props.formApi}
          control={props.control}
          title={i18n.t('entity.invoice.paymentMethod.cash')}
          formSection="paymentType.cashPayment"
          data-testid={testIds.settings.serviceOrderVariantsDetail('cashPayment')}
        />
        <PaymentType
          formApi={props.formApi}
          control={props.control}
          title={i18n.t('entity.invoice.paymentMethod.card')}
          formSection="paymentType.cardPayment"
          data-testid={testIds.settings.serviceOrderVariantsDetail('cardPayment')}
        />
        <PaymentType
          formApi={props.formApi}
          control={props.control}
          title={i18n.t('entity.invoice.paymentMethod.bankTransfer')}
          formSection="paymentType.bankPayment"
          data-testid={testIds.settings.serviceOrderVariantsDetail('bankPayment')}
        />
        <InternalPayment
          formApi={props.formApi}
          control={props.control}
          title={i18n.t('general.labels.internal')}
          data-testid={testIds.settings.serviceOrderVariantsDetail('internalPayment')}
        />
        <HStack spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              name="paymentType.defaultPaymentType"
              type="choice"
              label={i18n.t('entity.order.labels.defaultPaymentMethod')}
              options={orderPaymentTypeOptions}
              data-testid={testIds.settings.serviceOrderVariantsDetail('defaultPayment')}
              isDisabled={!orderPaymentTypeOptions.length}
            />
          </Box>
          <Space fillAvailable />
        </HStack>
      </VStack>
      <Space vertical={18} />
      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.actions.discardChanges'),
            onClick: () => navigate(settingsRoutes.serviceOrderVariants),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: i18n.t('general.actions.saveChanges'),
          },
        ]}
        data-testid={testIds.settings.serviceOrderVariantsDetail('footer')}
      />
    </>
  );
}
