import {Card, DataStatus} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {OrderAdditionalCustomerResponseBody, useGetCustomerV2Query} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps} from 'shared';

import {CheckoutContractInfo} from './CheckoutContractInfo';

interface CheckoutAdditionalCustomerDetailProps extends TestIdProps {
  additionalCustomer: OrderAdditionalCustomerResponseBody;
  index: number;
  counterStartAt: number;
}

export function CheckoutAdditionalCustomerDetail(props: CheckoutAdditionalCustomerDetailProps) {
  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetCustomerV2Query(
    {
      customerId: props.additionalCustomer.customerId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !props.additionalCustomer.customerId,
    }
  );

  const contractInformation = customerData?.contractInformation.find(
    (contractInformation) =>
      contractInformation.id === props.additionalCustomer.contractInformationId
  );

  return (
    <Card variant="inlineWhite">
      <VStack spacing={4}>
        <Heading size={4}>
          {i18n.t('entity.accounting.labels.nthCustomerOnInvoice', {
            n: props.index + props.counterStartAt,
          })}
        </Heading>
        <DataStatus isEmpty={isNilOrEmpty(customerData)} isLoading={isLoading} isError={isError}>
          <CheckoutContractInfo
            person={contractInformation?.person ?? null}
            businessInfo={contractInformation?.businessInfo ?? null}
            isAdditionalCustomer
          />
        </DataStatus>
      </VStack>
    </Card>
  );
}
