import {Card, closeCurrentDialog, DataStatus, EmptyStatus, openDialog} from 'platform/components';
import {Box, Center, Grid, Hide, Show, VStack} from 'platform/foundation';

import {CustomerResponseBodyV2, useUpdateInterestMutation} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CustomerMatchOrCreate, CustomerWidgetCard, handleApiError, Section} from '@dms/shared';

import {useInterestData} from '../../hooks/useInterestData';
import {UTMCard} from './components/UTMCard';

interface CustomerProps {
  interestId: string;
}

export function Customer(props: CustomerProps) {
  const {interest, utm, customer, isLoading, isError} = useInterestData(props.interestId);

  const [updateInterest] = useUpdateInterestMutation();

  const onCustomer = (customer: CustomerResponseBodyV2) =>
    updateInterest({
      id: props.interestId,
      updateInterestRequestBody: {
        customerId: customer.id,
        sourceCodeId: interest!.sourceCodeId,
        type: interest!.type,
      },
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);

  const handleCustomerChange = () =>
    openDialog(
      <CustomerMatchOrCreate
        data-testid={testIds.interest.detail('customer')}
        onCustomer={onCustomer}
        secondStepComponentType="BUSINESS_CASE"
      />,
      {title: i18n.t('entity.customer.labels.customer')}
    );

  return (
    <Section>
      <Grid columns={2}>
        <VStack spacing={4}>
          <Show when={customer}>
            <CustomerWidgetCard
              isContactPersonHidden
              customer={customer}
              onCustomer={onCustomer}
              onChange={handleCustomerChange}
              secondStepComponentType="BUSINESS_CASE"
              data-testid={testIds.interest.detail('customer')}
              isDetailLinkVisible
            />
          </Show>
          <Hide when={customer}>
            <Card title={i18n.t('entity.customer.labels.customer')}>
              <DataStatus isLoading={isLoading} isError={isError} minHeight={CARD_MIN_HEIGHT}>
                <Box minHeight={CARD_MIN_HEIGHT}>
                  <Center height={CARD_MIN_HEIGHT}>
                    <EmptyStatus data-testid={testIds.interest.detail('empty-customer')} />
                  </Center>
                </Box>
              </DataStatus>
            </Card>
          </Hide>
          <Show whenFeatureEnabled={featureFlags.INTERESTS_UTM}>
            <UTMCard
              interestId={props.interestId}
              parameters={utm}
              data-testid={testIds.interest.detail('customer-utm')}
            />
          </Show>
        </VStack>
      </Grid>
    </Section>
  );
}

const CARD_MIN_HEIGHT = 100;
