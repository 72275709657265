import {startOfToday} from 'date-fns';
import {Form, FormField} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {defaultTo} from 'ramda';

import {BaseDirectSale} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabFormData} from './types/RequestTabFormData';

export interface RequestTabFormProps extends RequiredTestIdProps {
  directSale: BaseDirectSale | Nullish;
  onChange: (formData: RequestTabFormData) => void;
  isEditingDisabled: boolean;
}

export function RequestTabForm(props: RequestTabFormProps) {
  const today = startOfToday();

  const defaultValues: RequestTabFormData = {
    name: props.directSale?.name,
    description: props.directSale?.description,
    neededAt: props.directSale?.neededAt,
  };

  const isEditingDisabled = defaultTo(false, props.isEditingDisabled);

  return (
    <Form<RequestTabFormData> defaultValues={defaultValues} onChange={props.onChange}>
      {(control) => (
        <Grid spacing={4} columns={2} data-testid={`${props['data-testid']}-wrapper`}>
          <GridItem span={1}>
            <FormField
              type="text"
              name="name"
              label={i18n.t('entity.warehouse.labels.name')}
              control={control}
              isDisabled={isEditingDisabled}
              data-testid={suffixTestId('inputs.name', props)}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              type="date"
              name="neededAt"
              label={i18n.t('entity.warehouse.labels.neededAt')}
              minDate={today}
              control={control}
              isDisabled={isEditingDisabled}
              data-testid={suffixTestId('inputs.neededAt', props)}
            />
          </GridItem>
          <GridItem span={2}>
            <FormField
              type="text"
              name="description"
              label={i18n.t('entity.warehouse.labels.description')}
              control={control}
              isDisabled={isEditingDisabled}
              data-testid={suffixTestId('inputs.description', props)}
            />
          </GridItem>
        </Grid>
      )}
    </Form>
  );
}
