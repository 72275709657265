import {Breadcrumbs, BreadcrumbType, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, Space, VStack, Text} from 'platform/foundation';

import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {TestIdProps} from 'shared';

export interface MyProfileHeaderProps extends TestIdProps {
  title: string;
  subtitle?: string;
  breadcrumbs: BreadcrumbType[];
}

export function MyProfileHeader(props: MyProfileHeaderProps) {
  return (
    <VStack>
      <Box paddingTop={6} paddingHorizontal={6} paddingBottom={6}>
        <Show when={isNotNilOrEmpty(props.breadcrumbs)}>
          <Breadcrumbs breadcrumbs={props.breadcrumbs} />
          <Space vertical={8} />
        </Show>
        <HStack align="center" spacing={3} justify="space-between">
          <Heading size={1}>{props.title}</Heading>
        </HStack>
        <Show when={isNotNil(props.subtitle)}>
          <Space vertical={4} />
          <Text size="small" color="secondary">
            {props.subtitle}
          </Text>
        </Show>
      </Box>
      <Separator spacing={0} />
    </VStack>
  );
}
