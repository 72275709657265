import {anyPass, is, isEmpty, isNil, map, pipe, reject} from 'ramda';

/**
 * Recursively removes `null`, `undefined`, or empty properties from an object.
 * This is done by first rejecting any entries that are either `null`, `undefined`,
 * or `isEmpty`. Then, it maps over the values, and if a value is an object, it
 * recursively applies the `sanitizeObject` function to it. This ensures that even
 * nested objects have their nil or empty values removed.
 *
 * @template T extends object
 * @param {T} object - The object to be cleared of nil or empty properties.
 * @returns {T} - A new object with the same structure as the input, but with all
 *                nil or empty properties removed.
 * @example
 * // Returns { a: { b: 'c' } }
 * sanitizeObject({ a: { b: 'c', d: null }, e: '' });
 */
export const sanitizeObject = <T extends object, TResult extends object = object>(
  object: T
): TResult =>
  pipe<any, any, any>(
    reject(anyPass([isNil, isEmpty])),
    map((val) => (is(Object, val) ? sanitizeObject(val) : val))
  )(object);
