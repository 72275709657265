import {DataStatus} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetSignableDocumentTransactionQuery, useGetTransactionQuery} from '@dms/api';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DeviceSelectStep} from './components/DeviceSelectStep';
import {ErrorStep} from './components/ErrorStep';
import {ModeSelectStep} from './components/ModeSelectStep';
import {SendToDeviceStep} from './components/SendToDeviceStep';
import {SendToSignatoriesStep} from './components/SendToSignatoriesStep';
import {SignatoriesSelectStep} from './components/SignatoriesSelectStep';
import {SignByQrCodeStep} from './components/SignByQrCodeStep';
import {SignDocumentStep} from './types/SignDocumentStep';
import {StepContentProps} from './types/StepContentProps';

interface SignDocumentStepperProps extends RequiredTestIdProps {
  customerId: string | Nullish;
  fileId: string;
}

const DEFAULT_SIGNING_STEP: SignDocumentStep = 'modeSelect';

export function SignDocumentStepper(props: SignDocumentStepperProps) {
  const {data: signableDocument, isLoading: isLoadingDocument} =
    useGetSignableDocumentTransactionQuery({fileId: props.fileId});
  const {
    data: transaction,
    isLoading: isLoadingTransaction,
    isError: isTransactionError,
  } = useGetTransactionQuery(
    {transactionId: signableDocument?.transactionId ?? ''},
    {skip: isNilOrEmpty(signableDocument?.transactionId), refetchOnMountOrArgChange: true}
  );

  const isLoading = isLoadingDocument || isLoadingTransaction;

  const [signingStep, setSigningStep] = useState<SignDocumentStep>(DEFAULT_SIGNING_STEP);

  useEffect(() => {
    if (signingStep !== DEFAULT_SIGNING_STEP || isNil(transaction) || isTransactionError) {
      return;
    }

    const currentStep =
      transaction.transactionType === 'local' ? 'OnSite_qrCode' : 'Remote_sentToSignatories';

    setSigningStep(currentStep);
    // rules want [signingStep], we use it for checking initial state, and using it would cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const contentProps: StepContentProps = {
    customerId: props.customerId,
    fileId: props.fileId,
    transaction,
    signingStep,
    setSigningStep,
    'data-testid': suffixTestId(signingStep, props),
  };

  const content = match(signingStep)
    .with('modeSelect', always(<ModeSelectStep {...contentProps} />))
    // on-site signing
    .with('OnSite_deviceSelect', always(<DeviceSelectStep {...contentProps} />))
    .with('OnSite_qrCode', always(<SignByQrCodeStep {...contentProps} />))
    .with('OnSite_sentToDevice', always(<SendToDeviceStep {...contentProps} />))
    .with('OnSite_error', always(<ErrorStep {...contentProps} />))
    // remote signing
    .with('Remote_signatoriesSelect', always(<SignatoriesSelectStep {...contentProps} />))
    .with('Remote_sentToSignatories', always(<SendToSignatoriesStep {...contentProps} />))
    .with('Remote_error', always(<ErrorStep {...contentProps} />))

    .otherwise(always(null));

  return (
    <DataStatus isLoading={isLoading}>
      <Box padding={4}>{content}</Box>
    </DataStatus>
  );
}
