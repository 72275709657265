import {
  Action,
  Attributes,
  AttributesRow,
  Card,
  DataStatus,
  Dialog,
  useDialog,
} from 'platform/components';
import {HStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda-adjunct';

import {useGetSaleVehicleWarehouseInformationQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {buildArray, parseDate} from 'shared';

import {VehicleWarehouseOperationsCard} from './VehicleWarehouseOperationsCard';

interface VehicleWarehouseInfoCardProps {
  vehicleId: string;
  saleVehicleId: string;
  areWarehouseOperationsHidden?: boolean;
}

export function VehicleWarehouseInfoCard(props: VehicleWarehouseInfoCardProps) {
  const formatDateTime = useDateTimeFormatter();
  const [isOpen, onOpen, onClose] = useDialog(false);
  const {
    data: warehouseInfo,
    isLoading,
    isError,
  } = useGetSaleVehicleWarehouseInformationQuery({
    vehicleId: props.vehicleId,
    saleVehicleId: props.saleVehicleId,
  });

  const handleOpenWarehouseMovement = () => {
    onOpen();
  };

  const attributesCol1: AttributesRow[] = [
    {
      label: i18n.t('entity.vehicleWarehouse.labels.warehouse'),
      value: warehouseInfo?.warehouse ?? EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleWarehouse.labels.stockInDate'),
      value: isNotNil(warehouseInfo?.receivedAt)
        ? formatDateTime('dateShort', parseDate(warehouseInfo!.receivedAt))
        : EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleWarehouse.labels.stockInBy'),
      value: warehouseInfo?.receivedBy ?? EMPTY_PLACEHOLDER,
    },
  ];

  const attributesCol2: AttributesRow[] = [
    {
      label: i18n.t('entity.vehicleWarehouse.labels.sku'),
      value: warehouseInfo?.stockKeepingUnit ?? EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleWarehouse.labels.stockOutDate'),
      value: isNotNil(warehouseInfo?.issuedAt)
        ? formatDateTime('dateShort', parseDate(warehouseInfo!.issuedAt))
        : EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleWarehouse.labels.stockOutBy'),
      value: warehouseInfo?.issuedBy ?? EMPTY_PLACEHOLDER,
    },
  ];

  const actions = buildArray<Action>().whenNot(props.areWarehouseOperationsHidden, {
    type: 'button',
    title: i18n.t('entity.vehicle.labels.vehicleWarehouseOperations'),
    onClick: handleOpenWarehouseMovement,
    variant: 'ghostLink',
  });

  return (
    <Card title={i18n.t('entity.vehicle.labels.vehicleWarehouseInfo')} actions={actions}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <HStack spacing={4}>
          <Attributes rows={attributesCol1} />
          <Attributes rows={attributesCol2} />
        </HStack>
      </DataStatus>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title={i18n.t('entity.vehicle.labels.vehicleWarehouseOperations')}
        size="large"
      >
        <VehicleWarehouseOperationsCard
          vehicleId={props.vehicleId}
          saleVehicleId={props.saleVehicleId}
        />
      </Dialog>
    </Card>
  );
}
