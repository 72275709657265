import {FormControl, FormField} from 'platform/components';
import {Show} from 'platform/foundation';

import {useWatch} from 'react-hook-form';

import {useGetDirectSaleVariantsQuery, useGetSettingsServiceOrderVariantsQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import {getOptionsFromDirectSaleVariants} from '../../WarehouseDetail/utils/getOptionsFromDirectSaleVariants';
import {DiscountFormType} from '../types/DiscountFormType';

interface DiscountAllowedVariantProps extends TestIdProps {
  control: FormControl<DiscountFormType>;
}

export function DiscountAllowedVariant(props: DiscountAllowedVariantProps) {
  const {data: orderVariants, isLoading: isOrderVariantsLoading} =
    useGetSettingsServiceOrderVariantsQuery();
  const {data: directSaleVariants, isLoading: isDirectSaleVariantsLoading} =
    useGetDirectSaleVariantsQuery();

  const allowServiceOrderVariant = useWatch({
    control: props.control,
    name: 'isServiceOrderVariant',
  });
  const allowDirectSaleVariant = useWatch({control: props.control, name: 'isDirectSaleVariant'});

  return (
    <>
      <FormField
        control={props.control}
        name="isServiceOrderVariant"
        type="switch"
        label={i18n.t('entity.order.labels.serviceOrderVariant')}
        data-testid={suffixTestId('orderVariantSwitch', props)}
      />
      <Show when={allowServiceOrderVariant}>
        <FormField
          control={props.control}
          name="serviceOrderVariantIds"
          type="multiChoice"
          label={i18n.t('entity.order.labels.serviceOrderVariant')}
          options={orderVariants}
          isLoading={isOrderVariantsLoading}
          data-testid={suffixTestId('orderVariants', props)}
          isNotClearable
        />
      </Show>

      <FormField
        control={props.control}
        name="isDirectSaleVariant"
        type="switch"
        label={i18n.t('entity.order.labels.directSaleVariants')}
        data-testid={suffixTestId('directSaleVariantSwitch', props)}
      />
      <Show when={allowDirectSaleVariant}>
        <FormField
          control={props.control}
          name="directSaleVariantIds"
          type="multiChoice"
          label={i18n.t('entity.order.labels.directSaleVariants')}
          options={getOptionsFromDirectSaleVariants(directSaleVariants ?? [])}
          isLoading={isDirectSaleVariantsLoading}
          data-testid={suffixTestId('directSaleVariants', props)}
          isNotClearable
        />
      </Show>
    </>
  );
}
