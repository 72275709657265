import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {AutostacjaSettingsSubscriptionKeyForm} from './components/AutostacjaSettingsSubscriptionKeyForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.integrations.labels.autostacja'),
    href: settingsRoutes.autostacjaSettings,
  },
];

export function AutostacjaSettingsSubscriptionKey() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.autostacjaSubscriptionKey'), breadcrumbs}}
      data-testid="autostacja-settings-subscription-key"
    >
      <AutostacjaSettingsSubscriptionKeyForm />
    </SettingsTemplate>
  );
}
