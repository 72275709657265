import {DataStatus} from 'platform/components';

import {useGetLabourCatalogGridFilterCatalogCategoryQuery} from '@dms/api';

import {TestIdProps} from 'shared';

import {ActiveFolder} from '../../MoveToFolder/types/activeFolder';
import {SelectFolder} from '../../SelectFolder/SelectFolder';

interface SelectWorkFolderProps extends TestIdProps {
  serviceCaseId?: string;
  onSelect: (folder: ActiveFolder) => void;
  onClose: VoidFunction;
}

export function SelectWorkFolder(props: SelectWorkFolderProps) {
  const {data, isLoading, isError} = useGetLabourCatalogGridFilterCatalogCategoryQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const catalogueIds = data?.map((catalogue) => catalogue.value) ?? [];

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <SelectFolder
        catalogueIds={catalogueIds}
        contextTarget="labour-catalog"
        onClose={props.onClose}
        onSelect={(folder) => {
          props.onSelect(folder);
          props.onClose();
        }}
        data-testid={props['data-testid']}
      />
    </DataStatus>
  );
}
