import {LightboxVideo} from 'platform/lightbox';
import {RcFile} from 'rc-upload/lib/interface';

import {createContext} from 'shared';

interface UseVideoUpload {
  uploadingVideos: {id: string; progress?: number}[];
  isUploadInProgress: boolean;
  handleStartVideoUpload: (
    file: RcFile,
    categoryId: string,
    paramDefinitionId: string
  ) => Promise<void>;
  handleDeleteVideo: (
    videoAsset: LightboxVideo,
    categoryId: string,
    paramDefinitionId: string
  ) => void;
  setBlockNavigationWhenUploading: (shouldBlock: boolean) => void;
}

export const [VideoUploadContextProvider, useVideoUploadContext] = createContext<UseVideoUpload>();
