import {closeDialog, openConfirmDialog, openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head, isNil} from 'ramda';
import {isArray, isNotNilOrEmpty, noop} from 'ramda-adjunct';

import {
  usePostTiresArchiveMutation,
  usePostTireSetDeliveryNoteMutation,
  usePostTireSetLabelMutation,
  usePostTiresTransferToOrderMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {EditTireSet, handleApiError, printFile} from '@dms/shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

type ReceiveOrderSetRowData = {
  orderSetId: {value: string};
  setNumber?: {value?: string};
  tireManufacturer?: {value?: {label?: string}};
  tireSeason?: {value?: {label?: string}};
};

export function useTireOrderSetActions(orderId: string) {
  const [postTiresArchive] = usePostTiresArchiveMutation();
  const [postTireSetDeliveryNote] = usePostTireSetDeliveryNoteMutation();
  const [postTireSetLabel] = usePostTireSetLabelMutation();
  const [postTiresTransferToOrder] = usePostTiresTransferToOrderMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const rowIds = isArray(rowId) ? rowId : [rowId];

    const castedRowData = rowData as ReceiveOrderSetRowData;
    const setId = castedRowData.orderSetId.value;

    const transferActionCallback: ActionCallback = ({rowId}) => {
      const targetOrderId = isArray(rowId) ? head(rowId) : rowId;

      if (isNil(targetOrderId)) {
        return;
      }

      postTiresTransferToOrder({
        orderId,
        setId,
        body: {targetOrderId},
      })
        .unwrap()
        .then(() => {
          closeDialog('transferToOrder');
          refreshData();
        })
        .catch(handleApiError);
    };

    match(actionKey)
      .with('edit', 'redirectDetail', () => {
        const titleData = [
          castedRowData?.tireManufacturer?.value?.label,
          castedRowData?.tireSeason?.value?.label,
        ].filter(isNotNilOrEmpty);

        openDialog(
          <EditTireSet
            tireOrderId={orderId}
            setId={setId}
            onClose={() => closeDialog('editTireSet')}
            onEdit={refreshData}
            data-testid={testIds.tiresInventory.tireOrderDetail('tire-set-dialog')}
          />,
          {
            id: 'editTireSet',
            title: `${castedRowData?.setNumber?.value} - ${titleData.join(', ')}`,
            size: 'large',
          }
        );
      })
      .with('printLabel', () =>
        postTireSetLabel({orderId, setId, body: {wheels: rowIds.map((id) => ({id}))}})
          .unwrap()
          .then((data) => {
            const url = data?.printout?.pdfUrl;
            if (url) {
              printFile(url);
            } else {
              showNotification.error();
            }
          })
          .catch(handleApiError)
      )
      .with('printDeliveryNote', () =>
        postTireSetDeliveryNote({orderId, setId, body: {wheels: rowIds.map((id) => ({id}))}})
          .unwrap()
          .then((data) => {
            const url = data?.printout?.pdfUrl;
            if (url) {
              printFile(url);
            } else {
              showNotification.error();
            }
          })
          .catch(handleApiError)
      )
      .with('archive', () =>
        openConfirmDialog({
          text: i18n.t('page.tiresInventory.labels.archiveTire'),
          onConfirm: () =>
            postTiresArchive({
              orderId,
              setId,
              body: {wheels: rowIds.map((id) => ({id}))},
            })
              .unwrap()
              .then(() => {
                refreshData();
                showNotification.success();
              })
              .catch(handleApiError),
        })
      )
      .with('transferToOrder', () => {
        openDialog(
          <DataGrid
            gridCode="tire-set-transfer"
            autoHeight
            actionCallback={transferActionCallback}
            data-testid={testIds.tiresInventory.tireOrderDetail('transferToOrder')}
          />,
          {
            id: 'transferToOrder',
            title: i18n.t('page.tiresInventory.labels.transferToOrder'),
            size: 'large',
          }
        );
      })
      .otherwise(noop);
  };

  return actionCallback;
}
