import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentSeriesForm} from './components/DocumentSeriesForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.document.labels.seriesTitle'),
    href: settingsRoutes.seriesDefinition,
  },
];

export function SeriesDefinitionNew() {
  return (
    <SettingsTemplate
      header={{breadcrumbs, title: i18n.t('entity.documentSeries.actions.newSeries')}}
      data-testid="general-document-series-modal"
    >
      <DocumentSeriesForm />
    </SettingsTemplate>
  );
}
