import {BreadcrumbType} from 'platform/components';
import {match} from 'ts-pattern';

import {useGetCorrectiveTaxDocumentSettingQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {useBranches} from '@dms/shared';

import {useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CorrectiveTaxDocumentForm} from './components/CorrectiveTaxDocumentForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.correctiveTaxDocumentSetting.labels.list'),
    href: settingsRoutes.correctiveTaxDocuments,
  },
];

export const CorrectiveTaxDocumentEdit = () => {
  const {id} = useRequiredParams();
  const {data, isLoading, isError} = useGetCorrectiveTaxDocumentSettingQuery({
    id,
  });
  const {data: {branchListItems: branchList} = {}} = useBranches();

  const title = match(data?.isSystem)
    .with(true, () => i18n.t('entity.correctiveTaxDocumentSetting.labels.allBranches'))
    .otherwise(
      () => branchList?.find((branch) => branch.id === data?.branchId)?.marketingName ?? ''
    );

  return (
    <SettingsTemplate
      header={{title, breadcrumbs}}
      data-testid="settings-corrective-tax-document-edit-page"
      isLoading={isLoading}
      isError={isError}
    >
      <CorrectiveTaxDocumentForm correctiveTaxDocumentSetting={data} />
    </SettingsTemplate>
  );
};
