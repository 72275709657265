import {
  DataStatus,
  DialogFooter,
  Form,
  ButtonGroup,
  Button,
  FormButton,
  closeCurrentDialog,
  Separator,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {DeepPartial} from 'utility-types';
import {object} from 'yup';

import {defaultTo, isNil, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  PatchSupplierArticleRequest,
  useGetArticleQuery,
  useGetHandlingUnitQuery,
  useGetHandlingUnitsQuery,
  useGetSupplierArticleQuery,
  useGetSuppliersQuery,
  useGetTreeFolderPathQuery,
  usePatchSupplierArticleMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {
  generateHashFromObjects,
  RequiredTestIdProps,
  suffixTestId,
  yupNumber,
  yupString,
} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';
import {SupplierArticlesAddNewForm} from '../types/SupplierArticlesAddNewForm';
import {SupplierArticlesAddNewArticleDetail} from './SupplierArticlesAddNewArticleDetail';
import {SupplierArticlesAddNewCatalogInfo} from './SupplierArticlesAddNewCatalogInfo';
import {SupplierArticlesAddNewQuantityAndUnitsMappingForm} from './SupplierArticlesAddNewQuantityAndUnitsMappingForm';
import {SupplierArticlesAddNewSupplierInfoForm} from './SupplierArticlesAddNewSupplierInfoForm';

interface SupplierArticlesAddNewProps extends RequiredTestIdProps {
  warehouseId: string;
  articleId: string;
  onAfterSubmit: VoidFunction;
  supplierArticleId?: string;
  isEditing?: boolean;
}

export function SupplierArticlesAddNew(props: SupplierArticlesAddNewProps) {
  const isEditing = defaultTo(false, props.isEditing);

  const {
    data: supplierArticle,
    isLoading: isSupplierArticleLoading,
    isError: hasSupplierArticleError,
  } = useGetSupplierArticleQuery(
    {supplierArticleId: props.supplierArticleId as string},
    {skip: isNilOrEmpty(props.supplierArticleId) || not(isEditing)}
  );

  const {
    data: article,
    isLoading: isArticleLoading,
    isFetching: isArticleFetching,
    isError: hasArticleError,
    refetch: refreshArticle,
  } = useGetArticleQuery(
    {warehouseId: props.warehouseId, articleId: props.articleId},
    {skip: isNilOrEmpty(props.supplierArticleId) || not(isEditing)}
  );

  const {
    data: treeFolderPath,
    isLoading: isTreeFolderPathLoading,
    isFetching: isTreeFolderPathFetching,
    isError: hasTreeFolderPathError,
  } = useGetTreeFolderPathQuery(
    {leafId: article?.treeFolder?.leafId as string},
    {skip: isNilOrEmpty(article?.treeFolder?.leafId)}
  );

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: handlingUnits,
    isLoading: areHandlingUnitsLoading,
    isError: hasHandlingUnitsError,
  } = useGetHandlingUnitsQuery();

  const {
    data: supplierUnit,
    isLoading: isSupplierUnitLoading,
    isError: hasSupplierUnitError,
  } = useGetHandlingUnitQuery(
    {id: supplierArticle?.supplierUnitId as string},
    {skip: isNilOrEmpty(supplierArticle?.supplierUnitId)}
  );

  const {
    data: warehouseUnit,
    isLoading: isWarehouseUnitLoading,
    isError: hasWarehouseUnitError,
  } = useGetHandlingUnitQuery(
    {id: supplierArticle?.warehouseUnitId as string},
    {skip: isNilOrEmpty(supplierArticle?.warehouseUnitId)}
  );

  const [patchSupplierArticle] = usePatchSupplierArticleMutation();

  const isLoading =
    isSupplierArticleLoading ||
    isArticleLoading ||
    isTreeFolderPathLoading ||
    areSuppliersLoading ||
    areHandlingUnitsLoading ||
    isSupplierUnitLoading ||
    isWarehouseUnitLoading;

  const hasError =
    hasSupplierArticleError ||
    hasArticleError ||
    hasTreeFolderPathError ||
    hasSuppliersError ||
    hasHandlingUnitsError ||
    hasSupplierUnitError ||
    hasWarehouseUnitError;

  const defaultValues: DeepPartial<SupplierArticlesAddNewForm> = {
    supplierInfo: {
      supplierId: supplierArticle?.supplierId,
      supplierOrderingNumber: supplierArticle?.supplierOrderingNumber,
      supplierBulkPackageQuantity: supplierArticle?.supplierBulkPackageQuantity,
    },
    quantityAndUnitsMapping: {
      supplierQuantity: supplierArticle?.supplierQuantity,
      supplierUnitId: supplierArticle?.supplierUnitId,
      warehouseQuantity: supplierArticle?.warehouseQuantity,
      warehouseUnitId: supplierArticle?.warehouseUnitId,
    },
  };

  const handleSubmit: FormSubmitHandler<SupplierArticlesAddNewForm> = async (formValues) => {
    if (isNil(supplierArticle)) {
      // TODO We don't have POST yet
      return;
    }

    const patchRequestBody: PatchSupplierArticleRequest['body'] = {
      articleId: supplierArticle?.articleId as string,
      manufacturerNumber: supplierArticle?.manufacturerNumber as string,
      supplierArticle: {
        supplierId: formValues.supplierInfo.supplierId,
        supplierOrderingNumber: formValues.supplierInfo.supplierOrderingNumber,
        supplierBulkPackageQuantity: formValues.supplierInfo.supplierBulkPackageQuantity,
        supplierQuantity: formValues.quantityAndUnitsMapping.supplierQuantity,
        supplierUnitId: formValues.quantityAndUnitsMapping.supplierUnitId,
        warehouseQuantity: formValues.quantityAndUnitsMapping.warehouseQuantity,
      },
    };

    await patchSupplierArticle({
      supplierArticleId: props.supplierArticleId as string,
      body: patchRequestBody,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(closeCurrentDialog)
      .then(props.onAfterSubmit)
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={hasError} minHeight={155}>
      <Form<SupplierArticlesAddNewForm>
        key={generateHashFromObjects(supplierArticle)}
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <>
            <VStack spacing={4}>
              <SupplierArticlesAddNewSupplierInfoForm
                control={control}
                suppliers={suppliers}
                data-testid={suffixTestId('sections.supplierInfo', props)}
              />
              <Separator spacing={0} />
              <SupplierArticlesAddNewQuantityAndUnitsMappingForm
                control={control}
                handlingUnits={handlingUnits}
                supplierUnit={supplierUnit?.name ?? FALLBACK_HANDLING_UNIT}
                warehouseUnit={warehouseUnit?.name ?? FALLBACK_HANDLING_UNIT}
                data-testid={suffixTestId('sections.quantityAndUnitsMapping', props)}
              />
              <SupplierArticlesAddNewCatalogInfo
                articleName={supplierArticle?.articleName}
                supplierName={supplierArticle?.supplierName}
                manufactuterName={supplierArticle?.manufacturerName}
                supplierOrderingNumber={supplierArticle?.supplierOrderingNumber}
                manufacturerNumber={supplierArticle?.manufacturerNumber}
                supplierBulkPackageQuantity={supplierArticle?.supplierBulkPackageQuantity}
                recommendedPriceWithoutVat={supplierArticle?.recommendedPriceWithoutVat}
                purchasePriceWithoutVat={supplierArticle?.purchasePriceWithoutVat}
                currency={supplierArticle?.currency}
                data-testid={suffixTestId('sections.catalogInfo', props)}
              />
              <Show when={props.isEditing}>
                <SupplierArticlesAddNewArticleDetail
                  isArticleDetailFetching={isArticleFetching || isTreeFolderPathFetching}
                  articleDetail={{
                    name: article?.name,
                    number: article?.manufacturerNumber,
                    manufacturerName: article?.manufacturerName,
                    storageLocation: article?.storageLocation,
                    categoryName: treeFolderPath?.treeFolder?.pathName,
                  }}
                  onRefreshArticleDetail={refreshArticle}
                  data-testid={suffixTestId('sections.articleDetail', props)}
                />
              </Show>
            </VStack>
            <DialogFooter>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                  data-testid={suffixTestId('actions.discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  title={
                    isEditing
                      ? i18n.t('general.actions.saveChanges')
                      : i18n.t('general.actions.add')
                  }
                  data-testid={suffixTestId('actions.submit', props)}
                />
              </ButtonGroup>
            </DialogFooter>
          </>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  supplierInfo: object({
    supplierId: yupString.required(),
    supplierOrderingNumber: yupString,
    supplierBulkPackageQuantity: yupNumber,
  }),
  quantityAndUnitsMapping: object({
    supplierQuantity: yupNumber.required().positive(),
    supplierUnitId: yupString.required(),
    warehouseQuantity: yupNumber.required().positive(),
    warehouseUnitId: yupString.required(),
  }),
});
