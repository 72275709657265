import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormSubmitHandler,
  FormField,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {head, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  usePutServiceOrderItemsAssignMechanicMutation,
  useGetServiceOrderJobQuery,
  useGetEmployeeMechanicsQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantQuery,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, yupString} from 'shared';

type BulkAssignMechanicFormType = {
  assignMechanicId?: string | Nullish;
};

interface BulkAssignMechanicFormProps extends RequiredTestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  items: {serviceJobId: string; serviceItemId: string}[];
  onSubmit: VoidFunction;
  onClose: VoidFunction;
}

export function BulkAssignMechanicForm(props: BulkAssignMechanicFormProps) {
  const {data, isLoading, isError} = useGetServiceOrderJobQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
    serviceJobId: head(props.items)?.serviceJobId ?? '',
  });
  const {data: order} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {data: mechanics, isLoading: isMechanicOptionsLoading} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {
      skip:
        isNil(orderVariant?.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const mechanicOptions = mechanics?.employees?.map((mechanic) => ({
    label: mechanic?.name,
    value: mechanic?.id,
  }));

  const [putServiceOrderItemsAssignMechanic] = usePutServiceOrderItemsAssignMechanicMutation();

  const handleSubmit: FormSubmitHandler<BulkAssignMechanicFormType> = async (data) => {
    await putServiceOrderItemsAssignMechanic({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        serviceItems: props.items.map((item) => ({
          serviceItemId: item.serviceItemId,
          serviceJobId: item.serviceJobId,
        })),
        assignMechanicId: data.assignMechanicId ?? '',
      },
    })
      .unwrap()
      .then(() => {
        props.onSubmit();
        props.onClose();
      })
      .catch(handleApiError);
  };

  const defaultValues: BulkAssignMechanicFormType = {
    assignMechanicId: head(data?.assignMechanics?.filter(isNotNil) ?? [])?.id,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={34}>
      <Form<BulkAssignMechanicFormType>
        schema={FromSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              label={i18n.t('entity.order.labels.mechanic')}
              name="assignMechanicId"
              type="choice"
              options={mechanicOptions}
              isLoading={isMechanicOptionsLoading}
              data-testid={suffixTestId('mechanic', props)}
              menuInPortal
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discardButton', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('saveButton', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const FromSchema = object({
  assignMechanicId: yupString.required(),
});
