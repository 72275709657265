import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Heading, Space, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  AddrDetailApiResponse,
  PostBillingInformationV2ApiArg,
  useGetBillingInformationV2Query,
  useGetTenantV2Query,
  usePostBillingInformationV2Mutation,
  usePutBillingInformationV2Mutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {AddressSearch, handleApiError, useTenant} from '@dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BankAccountList} from './components/BankAccountList';
import {DeputyPersonList} from './components/DeputyPersonList';
import {getIsBankAccountError} from './utils/getIsBankAccountError';

const breadcrumbs = [
  {
    label: i18n.t('entity.customer.labels.billingInformation'),
    href: settingsRoutes.billingInformation,
  },
];

type FormValues = PostBillingInformationV2ApiArg;

export function BillingInformationDetail() {
  const {id} = useParams();
  const navigate = useNavigate();

  const isCreating = isNil(id);

  const [createBillingInfo] = usePostBillingInformationV2Mutation();
  const [updateBillingInfo] = usePutBillingInformationV2Mutation();
  const {data: tenant} = useGetTenantV2Query();
  const {
    data: billingInformation,
    isLoading,
    isError,
  } = useGetBillingInformationV2Query({id: id ?? ''}, {skip: isCreating});

  const {tenantCountry} = useTenant();
  const setAddressFormSearch =
    (formApi: UseFormReturn<FormValues>) => (address: AddrDetailApiResponse) => {
      if (isNil(tenant)) {
        showNotification.error();
        return;
      }

      formApi.setValue(
        'contactInformation.address',
        {
          city: address?.city ?? '',
          street: address?.street ?? '',
          zip: address?.zipCode ?? '',
          state: address?.state ?? '',
          prefix: address?.prefix ?? '',
          descriptiveNumber: address?.descriptiveNumber ?? null,
          orientationNumber: address?.orientationNumber ?? null,
          addressComplement: null,
          district: null,
          country: tenant.contactInformation.address.country,
          coordinates: null,
          postal: null,
        },
        {shouldValidate: true}
      );
    };

  const handleSubmit: FormSubmitHandler<FormValues> = async (values, setError) => {
    const submitAction = isCreating ? createBillingInfo : updateBillingInfo;

    await submitAction({id: id ?? '', ...values})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.billingInformation))
      .catch((err) => {
        const isBankAccountError = getIsBankAccountError(err, values, setError);
        handleApiError(err, {silent: isBankAccountError});
      });
  };

  const title = isCreating
    ? i18n.t('entity.customer.actions.newBillingInformation')
    : (billingInformation?.billingName ?? i18n.t('entity.customer.actions.editBillingInformation'));

  const redirectToOverview = () => navigate(settingsRoutes.billingInformation);
  const defaultValues = {
    ...billingInformation,
    contactInformation: {
      ...billingInformation?.contactInformation,
      address: {
        street: billingInformation?.contactInformation?.address?.street ?? '',
        city: billingInformation?.contactInformation?.address?.city ?? '',
        zip: billingInformation?.contactInformation?.address?.zip ?? '',
        state: billingInformation?.contactInformation?.address?.state ?? '',
        prefix: billingInformation?.contactInformation?.address?.prefix ?? '',
        descriptiveNumber: billingInformation?.contactInformation?.address?.descriptiveNumber ?? '',
        orientationNumber:
          billingInformation?.contactInformation?.address?.orientationNumber ?? null,
        addressComplement:
          billingInformation?.contactInformation?.address?.addressComplement ?? null,
        district: billingInformation?.contactInformation?.address?.district ?? null,
        country: tenantCountry ?? '',
      },
    },
  };

  return (
    <SettingsTemplate
      header={{
        title,
        breadcrumbs,
      }}
      data-testid={testIds.settings.billingInformation('detail')}
      isError={isError}
      isCreating={isCreating}
      isLoading={isLoading}
    >
      <Form<FormValues> defaultValues={defaultValues} onSubmit={handleSubmit} schema={schema}>
        {(control, formApi) => (
          <VStack>
            <SettingsSection>
              <Grid columns={2}>
                <GridItem span={2}>
                  <FormField
                    type="text"
                    control={control}
                    name="billingName"
                    label={i18n.t('entity.customer.labels.billingName')}
                    isRequired
                  />
                </GridItem>

                <FormField
                  type="text"
                  control={control}
                  name="contactInformation.registrationNumber"
                  label={i18n.t('entity.customer.labels.registrationNumber')}
                  isRequired
                />

                <FormField
                  type="text"
                  control={control}
                  name="contactInformation.vatNumber"
                  label={i18n.t('entity.customer.labels.vatNumber')}
                  isRequired
                />

                <FormField
                  type="text"
                  control={control}
                  name="contactInformation.companyName"
                  label={i18n.t('entity.customer.labels.companyName')}
                  isRequired
                />

                <FormField
                  type="text"
                  control={control}
                  name="contactInformation.fileNumber"
                  label={i18n.t('contractInformation.labels.fileNumber')}
                  isRequired
                />
              </Grid>
            </SettingsSection>

            <Separator spacing={5} />

            <SettingsSection>
              <VStack spacing={5}>
                <Heading size={4}>{i18n.t('entity.address.labels.address')}</Heading>
                <AddressSearch onSelect={setAddressFormSearch(formApi)} />
                <Grid columns={4}>
                  <GridItem span={2}>
                    <FormField
                      name="contactInformation.address.street"
                      label={i18n.t('entity.address.labels.street')}
                      control={control}
                      type="text"
                      isRequired
                    />
                  </GridItem>
                  <FormField
                    name="contactInformation.address.descriptiveNumber"
                    label={i18n.t('entity.address.labels.descriptiveNumber')}
                    control={control}
                    type="text"
                  />
                  <FormField
                    name="contactInformation.address.orientationNumber"
                    label={i18n.t('entity.address.labels.orientationNumber')}
                    control={control}
                    type="text"
                  />
                  <GridItem span={2}>
                    <FormField
                      name="contactInformation.address.city"
                      label={i18n.t('entity.address.labels.city')}
                      control={control}
                      type="text"
                      isRequired
                    />
                  </GridItem>

                  <FormField
                    name="contactInformation.address.district"
                    label={i18n.t('entity.address.labels.district')}
                    control={control}
                    type="text"
                  />
                  <FormField
                    name="contactInformation.address.zip"
                    label={i18n.t('entity.address.labels.zipCode')}
                    control={control}
                    type="text"
                    isRequired
                  />
                </Grid>
              </VStack>
            </SettingsSection>

            <Separator spacing={5} />

            <DeputyPersonList control={control} formApi={formApi} />

            <Separator spacing={5} />

            <BankAccountList control={control} formApi={formApi} />

            <Space vertical={5} />

            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.close')}
                onClick={redirectToOverview}
                variant="secondary"
              />
              <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  billingName: yupString.required(),
  contactInformation: object({
    registrationNumber: yupString.required(),
    vatNumber: yupString.required(),
    companyName: yupString.required(),
    fileNumber: yupString.required(),
    address: object({
      street: yupString.required(),
      descriptiveNumber: yupString,
      orientationNumber: yupString,
      city: yupString.required(),
      district: yupString,
      zip: yupString.required(),
    }),
  }),
  deputyPersons: array(
    object({
      firstName: yupString.required(),
      lastName: yupString.required(),
    })
  ),
  bankAccounts: array(
    object({
      accountName: yupString.required(),
      accountNumber: yupString.required(),
    })
  ).min(1),
});
