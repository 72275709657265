import {Attributes} from 'platform/components';

import {CustomerResponseBodyV2} from '@dms/api';

import {TestIdProps} from 'shared';

import {useCustomerInformationAttributes} from '../../hooks/useCustomerInformationAttributes';

interface CustomerContractAttributesProps extends TestIdProps {
  customer: CustomerResponseBodyV2;
  displayAftersalesVersion?: boolean;
}

export function CustomerInformationAttributes(props: CustomerContractAttributesProps) {
  const attributes = useCustomerInformationAttributes(
    props.customer,
    props.displayAftersalesVersion
  );
  return <Attributes rows={attributes} size="quarter" data-testid={props['data-testid']} />;
}
