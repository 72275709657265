import {Card, showNotification, Textarea} from 'platform/components';
import {Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';

import {useSourcingGetSalesClaimQuery, useSourcingSetSalesClaimMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {parseDate} from 'shared';

interface SalesClaimNoteProps {
  adId: string;
}

export function SalesClaimNote({adId}: SalesClaimNoteProps) {
  const [note, setNote] = useState<string | null>('');
  const {data: salesClaim} = useSourcingGetSalesClaimQuery({adId});
  const [setVehicleSalesClaim] = useSourcingSetSalesClaimMutation();
  const formatDateTime = useDateTimeFormatter();

  const author = isNotNil(salesClaim)
    ? `${salesClaim.author.firstName} ${salesClaim.author.lastName}`.trim()
    : undefined;
  const date = isNotNil(salesClaim)
    ? formatDateTime('dateTimeMedium', parseDate(salesClaim?.createdAt))
    : undefined;

  const handleUpdateNote = () => {
    setVehicleSalesClaim({adId, note})
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);
  };

  useEffect(() => {
    setNote(salesClaim?.note ?? '');
  }, [salesClaim?.note]);

  return (
    <Card title={i18n.t('general.labels.notes')}>
      <VStack spacing={2}>
        <Textarea
          value={note}
          onChange={setNote}
          onBlur={handleUpdateNote}
          placeholder={i18n.t('general.actions.typeANotePlaceholder')}
          minRows={3}
          data-testid={testIds.sourcing.vehicleDetail('salesClaimNote')}
        />
        <Show when={isNotNil(salesClaim?.author) && isNotNil(salesClaim?.createdAt)}>
          <Text size="small" color="secondary">
            {i18n.t('general.labels.createdBy')} {author} • {date}
          </Text>
        </Show>
      </VStack>
    </Card>
  );
}
