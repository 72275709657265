import {Card, Flag} from 'platform/components';
import {Clickable, HStack} from 'platform/foundation';

import {ReactElement} from 'react';

import {isNotNil} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {VehicleCatalogueFeature} from '@dms/teas';

interface ComponentProps {
  featureItems: VehicleCatalogueFeature[];
  highlightedFeatures: string[];
  onHighlightOn: (keys: string[]) => void;
  onHighlightOff: (key: string) => void;
}

export function AllFeaturesCard({
  featureItems = [],
  highlightedFeatures,
  onHighlightOn,
  onHighlightOff,
}: ComponentProps) {
  const toggleFeatureHighlight = (feature: VehicleCatalogueFeature) => {
    const isHighlighted = isNotNil(feature.key) ? highlightedFeatures.includes(feature.key) : false;
    if (isHighlighted) {
      onHighlightOff(feature.key!);
    } else {
      onHighlightOn(feature.key ? [feature.key] : []);
    }
  };

  const renderFeature = (
    item: VehicleCatalogueFeature & {isPremium?: boolean},
    index: number
  ): ReactElement => {
    const isHighlighted = item.key ? highlightedFeatures.includes(item.key) : false;
    return (
      <HStack key={`${item.key}[${index}]`} minWidth={0}>
        <Clickable onClick={() => toggleFeatureHighlight(item)}>
          <Flag
            label={item.isPremium ? `★ ${item.name}` : (item.name ?? '')}
            colorScheme={isHighlighted ? 'blue' : undefined}
            data-testid={testIds.sourcing.classifieds(`allFeaturesCardFeature[${index}]`)}
          />
        </Clickable>
      </HStack>
    );
  };

  return (
    <Card
      title={i18n.t('general.labels.allFeatures')}
      tooltip={i18n.t('entity.vehicle.labels.allFeaturesTooltip')}
    >
      <HStack spacing={1} wrap>
        {featureItems.map(renderFeature)}
      </HStack>
    </Card>
  );
}
