import {Icon} from 'platform/foundation';

import {FC, PropsWithChildren, useMemo} from 'react';

import {isNil} from 'ramda';

import {useGetBranchListQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {CommonSkeleton} from '@dms/shared';
import {LinkButton, SimpleTable, useRouter} from '@dms/teas';

import {composePath} from 'shared';

import {ListCardStyled} from '../../BillingInfo/components/BillingInformationList';

const columns = [
  {
    Header: i18n.t('page.settings.labels.marketingName'),
    id: 'marketingName',
  },
  {
    Header: i18n.t('entity.address.labels.city'),
    id: 'city',
  },
  {
    Header: i18n.t('entity.address.labels.street'),
    id: 'street',
  },
  {
    Header: i18n.t('entity.customer.labels.companyName'),
    id: 'companyName',
  },
  {
    Header: i18n.t('entity.user.labels.users'),
    id: 'usersCount',
  },
  {
    Header: null,
    id: 'action',
  },
];

export const BranchesList: FC<PropsWithChildren<any>> = () => {
  const router = useRouter();
  const {data: branches, isLoading} = useGetBranchListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const rows = useMemo(
    () =>
      branches?.branchListItems?.map((item) => ({
        id: item.id,
        marketingName: item.marketingName || '-',
        city: item.city || '-',
        street: item.street || '-',
        companyName: item.companyName || '-',
        usersCount: !isNil(item.usersCount) ? item.usersCount : '-',
      })),
    [branches?.branchListItems]
  );

  return (
    <ListCardStyled
      title={i18n.t('entity.branch.labels.branches')}
      headerContent={
        <LinkButton
          data-testid={testIds.settings.branches('createNew')}
          href={settingsRoutes.branchesNew}
        >
          <Icon value="content/add" /> {i18n.t('entity.branch.actions.addBranch')}
        </LinkButton>
      }
    >
      {isLoading ? (
        <CommonSkeleton />
      ) : (
        <SimpleTable
          noZebra
          showRowDivider
          highlightRowOnHover
          tdPadding="default"
          columns={columns}
          rows={[{data: rows || []}]}
          onRowClick={(row) =>
            router.push(composePath(settingsRoutes.branchesEdit, {params: {id: row.id as string}}))
          }
        />
      )}
    </ListCardStyled>
  );
};
