import {Dropdown, DropdownItem, FormField, IconButton, TextInput} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {suffixTestId} from 'shared';

import {useGetInvoiceCurrency} from './hooks/useGetInvoiceCurrency';
import {InvoiceItemRowProps} from './types/InvoiceItemRowProps';

export function MarginItemRow(props: InvoiceItemRowProps) {
  const field = props.fields[props.index];
  const [currency] = useGetInvoiceCurrency(props.control, 'invoices');

  const hasDiscount = props.fields.some(
    (item) => item.relatedItemId === field.itemId && item.type === 'discount'
  );

  const handleRecalculateItems = () => props.callbacks.onRecalculate(field.itemId);

  return (
    <Grid columns={35} align="flex-end">
      <GridItem span={8}>
        <TextInput isDisabled value={i18n.t('general.labels.margin')} />
      </GridItem>
      <GridItem span={3}>
        <TextInput isDisabled value="1" />
      </GridItem>
      <GridItem span={3}>
        <TextInput isDisabled value={i18n.t('general.metric.pcs')} />
      </GridItem>
      <GridItem span={4}>
        <FormField
          name={`invoiceItems.${props.index}.pricePerUnit`}
          control={props.control}
          onChange={handleRecalculateItems}
          type="currency"
          decimalPlaces={2}
          isRequired
          currency={currency}
          data-testid={suffixTestId(`pricePerUnit-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={2}>
        <VStack>
          <FormField
            name={`invoiceItems.${props.index}.isUnitPriceWithVat`}
            control={props.control}
            onChange={handleRecalculateItems}
            type="checkbox"
            data-testid={suffixTestId(`isUnitPriceWithVat-${props.index}`, props)}
          />
          <Space vertical={2} />
        </VStack>
      </GridItem>
      <GridItem span={5}>
        <FormField
          name={`invoiceItems.${props.index}.priceWithoutVat`}
          control={props.control}
          type="currency"
          currency={currency}
          isDisabled
          data-testid={suffixTestId(`totalPriceWithoutVat-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={3}>
        <FormField
          name={`invoiceItems.${props.index}.vatType`}
          control={props.control}
          type="choice"
          options={props.vatRatesOptions}
          isNotClearable
          onChange={() => {
            props.formApi.clearErrors(`invoiceItems.${props.index}.vatType`);
            handleRecalculateItems();
          }}
          data-testid={suffixTestId(`vatType-${props.index}`, props)}
        />
      </GridItem>
      <GridItem span={5}>
        <FormField
          name={`invoiceItems.${props.index}.priceWithVat`}
          control={props.control}
          currency={currency}
          isDisabled
          type="currency"
          data-testid={suffixTestId(`totalPriceWithVat-${props.index}`, props)}
        />
      </GridItem>

      <GridItem span={2}>
        <Show when={props.isCorrectiveTaxDocument}>
          <IconButton
            data-testid={suffixTestId(`deleteItem-${props.index}`, props)}
            icon="action/delete"
            severity="danger"
            onClick={() => props.callbacks.onDeleteRow(props.index)}
          />
        </Show>
        <Hide when={props.isCorrectiveTaxDocument}>
          <Dropdown
            dropdownControl={
              <IconButton
                data-testid={suffixTestId(`deleteItem-${props.index}`, props)}
                icon="navigation/more_vert"
              />
            }
          >
            <Hide when={hasDiscount}>
              <DropdownItem
                label={i18n.t('page.accounting.labels.addDiscount')}
                leftIcon="content/add_circle"
                onClick={() => props.callbacks.onCreateDiscount(props.index)}
              />
            </Hide>
            <DropdownItem
              label={i18n.t('page.accounting.labels.removeItem')}
              leftIcon="action/delete"
              severity="danger"
              onClick={() => props.callbacks.onDeleteRow(props.index)}
            />
          </Dropdown>
        </Hide>
      </GridItem>
    </Grid>
  );
}
