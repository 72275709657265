import {DataStatus, Label, MultiChoice} from 'platform/components';
import {Box, Grid} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {selectTenant, useSourcingGetSourcingServersQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {filterLimits, Slider} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../../FiltersContext/FiltersContext';
import {allowedCountryCodes} from '../../constants/AllowedCountryCodes';
import {useApiEnums} from '../../hooks/useApiEnums';

const allowedSellerTypeKeys = ['SELLERTYPE_DEALERSHIP', 'SELLERTYPE_PRIVATE_SELLER'];

export function FilterSeller(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();
  const {getEnum, isLoading} = useApiEnums();
  const {data: {country: countryCode} = {}} = useSelector(selectTenant);
  const {data: sellerServers} = useSourcingGetSourcingServersQuery(
    {countryCode: countryCode!},
    {skip: isNil(countryCode)}
  );
  const sellerServerOptions =
    sellerServers?.map((sellerServer) => ({
      value: sellerServer.code,
      label: sellerServer.name,
    })) ?? [];

  const countries = getEnum('country')
    .filter((country) => allowedCountryCodes.includes(country.key))
    .sort((a, b) => a.label.localeCompare(b.label));

  const sellerTypes = getEnum('seller_type').filter((sellerType) =>
    allowedSellerTypeKeys.includes(sellerType.key)
  );

  return (
    <DataStatus isLoading={isLoading}>
      <Grid columns={4} spacing={8} data-testid="block-filters-seller">
        <MultiChoice
          label={i18n.t('page.filters.labels.sellerCountry')}
          placeholder={i18n.t('general.labels.chooseOptions')}
          value={filters.sellerCountry ?? null}
          options={countries}
          onChange={(value) => onUpdateFilters(['sellerCountry'], value)}
          data-testid={suffixTestId('sellerCountry', props)}
        />

        <MultiChoice
          label={i18n.t('page.filters.labels.sellerServer')}
          placeholder={i18n.t('general.labels.chooseOption')}
          value={filters.sellerServer ?? null}
          options={sellerServerOptions}
          onChange={(value) => onUpdateFilters(['sellerServer'], value)}
          data-testid={suffixTestId('sellerServer', props)}
        />

        <MultiChoice
          label={i18n.t('page.filters.labels.sellerType')}
          placeholder={i18n.t('general.labels.chooseOption')}
          options={sellerTypes}
          value={filters.sellerType ?? null}
          onChange={(value) => onUpdateFilters(['sellerType'], value)}
          data-testid={suffixTestId('sellerType', props)}
        />

        <Box>
          <Label>{i18n.t('entity.warehouse.labels.averageCarsInStock')}</Label>
          <Slider
            step={null}
            min={filterLimits.AVG_NUMBER_ADS_MIN}
            max={filterLimits.AVG_NUMBER_ADS_MAX}
            marks={filterLimits.AVG_NUMBER_ADS_MARKS}
            showMaxPlus
            defaultValue={
              filters.avgNumberAds || [
                filterLimits.AVG_NUMBER_ADS_MIN,
                filterLimits.AVG_NUMBER_ADS_MAX,
              ]
            }
            onChange={(_, newValue) => onUpdateFilters(['avgNumberAds'], newValue)}
            data-testid="slider-avgNumberAds"
          />
        </Box>
      </Grid>
    </DataStatus>
  );
}
