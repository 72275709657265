/* eslint-disable eag/match-named-export */

/* eslint-disable eag/no-index */
import {FormState} from 'final-form';
import {object, SchemaOf, string} from 'yup';

import i18n from '@dms/i18n';
import {
  AuditParamDefinitions,
  TRANSLATED_LANGUAGES,
  StructureItemBody,
  VehicleCatalogueEnumOption,
  AuditParamType,
} from '@dms/teas';

import {VISIBLE_TYPE} from '../constants';
import {
  AuditSettingsOption,
  AuditSettingsOptionChildren,
  AuditSettingsOptionType,
  AuditStructureSettingsForm,
  SelectAllState,
} from '../types';

export const getSelectAllStateByType =
  (
    getState: () => FormState<AuditStructureSettingsForm, Partial<AuditStructureSettingsForm>>,
    options: AuditSettingsOption[]
  ) =>
  (type: AuditSettingsOptionType): SelectAllState => {
    const formValues = getState()?.values;
    let allChecked = true;
    let leastOneChecked = false;

    const walk = (options: AuditSettingsOption[]) => {
      for (const {paramDefinitionId, children} of options) {
        const value = formValues?.paramDefinitions?.[paramDefinitionId]?.[type];
        const isChecked = (() => {
          if (type === VISIBLE_TYPE && value !== false) {
            return true;
          }

          return Boolean(value);
        })();

        if (isChecked) {
          leastOneChecked = true;
        } else {
          allChecked = false;
        }

        if (children) {
          for (const child of children) {
            if (children) {
              walk(child.options);
            }
          }
        }
      }
    };

    walk(options);

    return {
      isChecked: allChecked,
      isIndeterminate: !allChecked && leastOneChecked,
    };
  };

const getOptionLabel = (
  type: string,
  label: Record<string, string> | null,
  categoryName: Record<string, string>,
  locale: TRANSLATED_LANGUAGES,
  isRelated?: boolean
) => {
  if (label) {
    return getTranslation(locale, label);
  }

  if (
    type === AuditParamType.RADIO_BUTTONS ||
    type === AuditParamType.TOGGLE ||
    type === AuditParamType.PILL_RADIO ||
    type === AuditParamType.PILL_CHECKBOX ||
    type === AuditParamType.CHECKBOX_BUTTON
  ) {
    if (isRelated && type !== AuditParamType.TOGGLE) {
      return i18n.t`page.vehicleSettings.labels.condition`;
    }

    return i18n.t`page.vehicleSettings.labels.state`;
  }

  if (type === AuditParamType.PHOTO) {
    return i18n.t`entity.photo.labels.photo`;
  }

  if (type === AuditParamType.ADDITIONAL_PHOTOS) {
    return i18n.t`entity.photo.labels.photos`;
  }

  if (type === AuditParamType.ADDITIONAL_COMMENT) {
    return i18n.t`general.labels.note`;
  }

  if (type === AuditParamType.GENERAL_COMMENT) {
    return i18n.t`general.labels.note`;
  }

  return `${getTranslation(locale, categoryName)}`;
};

export const getOptionsFromParamDefinition = (
  paramDefinitions: AuditParamDefinitions | null | undefined,
  categoryName: Record<string, string>,
  locale: TRANSLATED_LANGUAGES,
  isRelated?: boolean
): AuditSettingsOption[] => {
  const options: AuditSettingsOption[] = [];

  [
    ...(paramDefinitions?.relatedActions ?? []),
    ...(paramDefinitions?.additionalInformation ?? []),
  ].forEach(({id, label, type, values}) => {
    if (type === AuditParamType.CATEGORY_STATE) {
      return;
    }

    const childOptions: AuditSettingsOptionChildren[] = [];

    values?.forEach((paramValue) => {
      if (!paramValue.content) {
        return;
      }

      childOptions.push({
        label:
          type !== AuditParamType.TOGGLE && paramValue.label
            ? getTranslation(locale, paramValue.label)
            : null,
        options: getOptionsFromParamDefinition(paramValue.content, categoryName, locale, true),
      });
    });

    options.push({
      label: getOptionLabel(type, label, categoryName, locale, isRelated),
      children: childOptions,
      paramDefinitionId: id,
    });
  });

  return options;
};

export const getFormSchema = (): SchemaOf<AuditStructureSettingsForm> =>
  // eslint-disable-next-line no-restricted-syntax
  object({
    name: string().required(i18n.t('general.validations.fieldIsRequired')),
  }) as unknown as SchemaOf<AuditStructureSettingsForm>;

const getEquipmentIdToCatalogueFeature = (
  features: VehicleCatalogueEnumOption[],
  uniqueKeyToIdDictionary: Record<string, string>
) =>
  Object.fromEntries(features.map((feature) => [uniqueKeyToIdDictionary[feature.value], feature]));

export const getRequestBodyItemsFormValues = (
  values: AuditStructureSettingsForm,
  uniqueKeyToIdDictionary: Record<string, string>,
  features: VehicleCatalogueEnumOption[],
  equipmentIds: string[]
): StructureItemBody[] => {
  const items: StructureItemBody[] = [];
  const uniqueKeyToMainFeature = getEquipmentIdToCatalogueFeature(
    features,
    uniqueKeyToIdDictionary
  );
  // in the form only the main equipment is visible,
  // this function ensure, that if main equipment is checked, all the children are visible as well
  const pushChildrenParamDefinitionId = (children: VehicleCatalogueEnumOption[] | undefined) => {
    children?.forEach((child) => {
      uniqueKeyToIdDictionary[child.value] &&
        items.push({
          categoryId: null,
          paramDefinitionId: uniqueKeyToIdDictionary[child.value],
          visible: true,
          mandatory: false,
        });
      if (child.children) {
        pushChildrenParamDefinitionId(child.children);
      }
    });
  };

  values.equipmentValues?.forEach((value) => {
    items.push({
      categoryId: null,
      paramDefinitionId: value,
      visible: true,
      mandatory: false,
    });
    pushChildrenParamDefinitionId(uniqueKeyToMainFeature[value]?.children);
  });

  // if equipment is not checked, we need to set it as not visible at BE
  equipmentIds.forEach((equipmentId) => {
    if (!items.some((item) => item.paramDefinitionId === equipmentId)) {
      items.push({
        categoryId: null,
        paramDefinitionId: equipmentId,
        visible: false,
        mandatory: false,
      });
    }
  });

  Object.entries(values.categories).forEach(([categoryId, visible]) => {
    items.push({
      categoryId,
      paramDefinitionId: null,
      visible,
      mandatory: false,
    });
  });

  Object.entries(values.paramDefinitions)
    .filter(([id]) => !values.equipmentValues?.includes(id))
    .forEach(([paramDefinitionId, {visible, mandatory}]) => {
      items.push({
        categoryId: null,
        paramDefinitionId,
        visible,
        mandatory: mandatory ?? false,
      });
    });

  return items;
};

const getTranslation = (locale: TRANSLATED_LANGUAGES, translatable?: Record<string, string>) => {
  if (!translatable) {
    return '';
  }
  switch (locale) {
    case TRANSLATED_LANGUAGES.czech:
      return translatable.cs;
    case TRANSLATED_LANGUAGES.slovak:
      return translatable.sk;
    case TRANSLATED_LANGUAGES.germany:
      return translatable.de;
    case TRANSLATED_LANGUAGES.france:
      return translatable.fr;
    case TRANSLATED_LANGUAGES.spain:
      return translatable.es;
    case TRANSLATED_LANGUAGES.poland:
      return translatable.pl;
    case TRANSLATED_LANGUAGES.italy:
      return translatable.it;
    default:
      return translatable.en;
  }
};
