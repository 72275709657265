import {motion, useSpring, useTransform} from 'framer-motion';
import {Display} from 'platform/foundation';

import {useEffect} from 'react';

interface AnimatedFunnelValueProps {
  value: number;
}

export function AnimatedFunnelValue({value}: AnimatedFunnelValueProps) {
  const springValue = useSpring(value, {mass: 0.2, stiffness: 75, damping: 10, duration: 0.1});
  const displayValue = useTransform(springValue, (current) => Math.round(current).toString());

  useEffect(() => {
    springValue.set(value);
  }, [springValue, value]);

  return (
    <Display size={1}>
      <motion.span>{displayValue}</motion.span>
    </Display>
  );
}
