import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery, useGetVehicleV2Query} from '@dms/api';
import {businessCaseRoutes} from '@dms/routes';

import {composePath} from 'shared';

export function useTaskRelationBusinessCase(businessCaseId: string) {
  const {
    data: businessCase,
    isLoading: isBCLoading,
    isError: hasBCError,
  } = useGetBusinessCaseQuery(
    {
      businessCaseId,
    },
    {
      skip: isNilOrEmpty(businessCaseId),
    }
  );

  const sellingVehicleId = businessCase?.offers?.[0]?.saleVehicles?.[0]?.vehicleId ?? null;
  const purchaseVehicleId =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? (businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0]?.vehicleId ?? null)
      : (businessCase?.offers?.[0]?.purchaseVehicles?.[0]?.vehicleId ?? null);

  const vehicleId = sellingVehicleId ?? purchaseVehicleId;

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleV2Query(
    {
      vehicleId: vehicleId!,
    },
    {
      skip: isNilOrEmpty(vehicleId),
    }
  );

  return {
    vehicle,
    customerId: businessCase?.customerId,
    isLoading: isBCLoading || isVehicleLoading,
    isError: hasBCError || hasVehicleError,
    pathname: composePath(businessCaseRoutes.overview, {
      params: {id: businessCaseId},
    }),
  };
}
