import {toPairs} from 'ramda';

import {
  TaxDocumentCalculationResponseBody,
  TaxDocumentCalculationSummaryItemResponseBody,
  TaxDocumentCalculationV2ResponseBody,
  VatTypeSummaryV2ResponseBody,
} from '@dms/api';

import {Nullish} from 'shared';

export type SummaryItemType = TaxDocumentCalculationSummaryItemResponseBody & {
  summaryType: 'calculation' | 'exchangeRateRatioCalculation';
};

export const typedToPairs = (val: Record<string, VatTypeSummaryV2ResponseBody>) => toPairs(val);

export const summaryItemToItemWithType = (
  items: TaxDocumentCalculationSummaryItemResponseBody[] | Nullish,
  type: 'calculation' | 'exchangeRateRatioCalculation'
): SummaryItemType[] => (items ?? []).map((item) => ({...item, summaryType: type}));

export const isV2 = (
  val: TaxDocumentCalculationResponseBody | TaxDocumentCalculationV2ResponseBody | Nullish
): val is TaxDocumentCalculationV2ResponseBody => 'calculation' in (val ?? {});
