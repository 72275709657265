import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray, isString} from 'ramda-adjunct';

import {useBulkDeleteDirectSaleVariantMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehouseDirectSaleVariants() {
  const navigate = useNavigate();

  const [deleteDirectSaleVariants] = useBulkDeleteDirectSaleVariantMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        if ('id' in rowData && isString(rowData.id)) {
          navigate(
            composePath(settingsRoutes.warehousesDirectSaleVariantsDetail, {
              params: {id: rowData.id},
            })
          );
        }
      })
      .with('delete', () => {
        const rowIds = isArray(rowId) ? rowId : [rowId];
        openDeleteDialog({
          text: i18n.t('entity.warehouse.labels.deleteSelectedItemsPrompt'),
          onConfirm: () => {
            deleteDirectSaleVariants({directSaleVariantId: rowIds})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError);
          },
        });
      })
      .otherwise(noop);
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.directSaleVariants'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newDirectSaleVariant'),
        onClick: () => {
          navigate(settingsRoutes.warehousesDirectSaleVariantsCreate);
        },
        'data-testid': testIds.settings.warehousesDirectSaleVariants('newDirectSaleVariant'),
      },
    ],
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesDirectSaleVariants('template')}
      header={header}
    >
      <DataGrid
        gridCode="direct-sale-variant"
        actionCallback={actionCallback}
        data-testid={testIds.settings.warehousesDirectSaleVariants('direct-sale-variant')}
      />
    </SettingsTemplate>
  );
}
