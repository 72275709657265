import {Form, FormSubmitHandler, closeDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';
import {array, boolean, object} from 'yup';

import {useCallback} from 'react';

import {GetServiceOrderApiResponse, serviceInspectionNotificationApi, tenantApi} from '@dms/api';
import i18n from '@dms/i18n';
import {getDigitalCertificateUrl, handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

import {FormContactPerson} from '../../../hooks/useContactPersons';
import {
  EmailSmsNotificationForm,
  NotificationCategory,
  ServiceCaseState,
} from '../types/EmailSmsNotificationForm';
import {EmailSmsNotificationFormBody} from './EmailSmsNotificationFormBody';

interface EmailSmsNotificationProps extends RequiredTestIdProps {
  orderId?: string;
  serviceCaseId: string;
  dialogId: string;
  serviceOrder?: GetServiceOrderApiResponse;
  serviceCaseState?: ServiceCaseState;
  isCheckinFinished: boolean;
  isHandoverFinished: boolean;
  checkinInspectionId: string;
  handoverInspectionId: string;
  selectedLang: string;
  contactPersons: FormContactPerson[];
  defaultCategory?: NotificationCategory;
}

export function EmailSmsNotification(props: EmailSmsNotificationProps) {
  const [sendSms] = tenantApi.usePostKonzultaSmsMutation();
  const [sendEmail] = tenantApi.useSendEmailMutation();

  const [sendCheckinEmail] =
    serviceInspectionNotificationApi.usePostSendCheckinEmailNotificationMutation();

  const [sendHandoverEmail] =
    serviceInspectionNotificationApi.usePostSendHandoverEmailNotificationMutation();

  const defaultValues: EmailSmsNotificationForm = {
    category: props.defaultCategory ? [props.defaultCategory] : [NotificationCategory.CUSTOM],
    email: {
      isEnabled: true,
      data: {
        person: props.serviceOrder?.contactInformation?.id || '',
        email: props.serviceOrder?.contactInformation?.email || '',
        subject: '',
        message: '',
        documents: [],
      },
    },
    sms: {
      isEnabled: true,
      data: {
        person: props.serviceOrder?.contactInformation?.id || '',
        phone: props.serviceOrder?.contactInformation?.phoneNumber?.number || '',
        message: '',
      },
    },
  };

  const handleCloseDialog = useCallback(() => closeDialog(props.dialogId), [props.dialogId]);

  const handleSubmit: FormSubmitHandler<EmailSmsNotificationForm> = async (
    formValue: EmailSmsNotificationForm
  ) => {
    const {email, sms} = formValue;

    const requests: (() => Promise<unknown>)[] = [];

    if (sms.isEnabled) {
      requests.push(() =>
        sendSms({
          konzultaSmsSenderRequestBody: {
            phone: sms.data.phone,
            text: sms.data.message,
          },
        })
          .unwrap()
          .then(() => showNotification.success(i18n.t('general.notifications.smsSendSuccessfully')))
          .catch((reason) => {
            handleApiError(reason);
            return Promise.reject(reason);
          })
      );
    }

    if (email.isEnabled) {
      match(formValue.category[0])
        .with(NotificationCategory.CUSTOM, () => {
          requests.push(() =>
            sendEmail({
              to: [email.data.email],
              subject: email.data.subject,
              body: email.data.message.replaceAll('\n', '<br>'),
              attachmentFiles: email.data.documents.map((item) => ({fileId: item.file.value.id})),
            })
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.emailSendSuccessfully'))
              )
              .catch((reason) => {
                handleApiError(reason);
                return Promise.reject(reason);
              })
          );
        })
        .with(NotificationCategory.CHECKIN, () => {
          requests.push(() =>
            sendCheckinEmail({
              serviceCaseId: props.serviceCaseId,
              body: {
                recipients: [{email: email.data.email}],
                digitalCertificateUrl: getDigitalCertificateUrl(
                  props.checkinInspectionId,
                  props.selectedLang
                ),
                attachments: email.data.documents.map((item) => ({
                  name: item.file.value.title,
                  url: item.file.value.remoteUrl,
                })),
              },
            })
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.emailSendSuccessfully'))
              )
              .catch((reason) => {
                handleApiError(reason);
                return Promise.reject(reason);
              })
          );
        })
        .with(NotificationCategory.HANDOVER, () =>
          requests.push(() =>
            sendHandoverEmail({
              serviceCaseId: props.serviceCaseId,
              body: {
                recipients: [{email: email.data.email}],
                digitalCertificateUrl: getDigitalCertificateUrl(
                  props.handoverInspectionId,
                  props.selectedLang
                ),
                attachments: email.data.documents.map((item) => ({
                  name: item.file.value.title,
                  url: item.file.value.remoteUrl,
                })),
              },
            })
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.emailSendSuccessfully'))
              )
              .catch((reason) => {
                handleApiError(reason);
                return Promise.reject(reason);
              })
          )
        )
        .exhaustive();
    }

    return await Promise.all(requests.map((item) => item()))
      .then(handleCloseDialog)
      .catch(() => Promise.resolve());
  };

  return (
    <Form<EmailSmsNotificationForm>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={validation}
    >
      {(control, formApi) => (
        <EmailSmsNotificationFormBody
          control={control}
          formApi={formApi}
          orderId={props.orderId}
          serviceCaseId={props.serviceCaseId}
          serviceCaseState={props.serviceCaseState}
          contactPersons={props.contactPersons}
          isCheckinFinished={props.isCheckinFinished}
          isHandoverFinished={props.isHandoverFinished}
          onCloseDialog={handleCloseDialog}
          data-testid={suffixTestId('sms-email-dialog-form-body', props)}
        />
      )}
    </Form>
  );
}

const validation = object({
  category: array().of(yupString),
  email: object().when('category', {
    is: (category: NotificationCategory[]) => category.includes(NotificationCategory.CUSTOM),
    then: object({
      isEnabled: boolean(),
      data: object().when('isEnabled', {
        is: true,
        then: object({
          person: yupString.required(),
          email: yupString.email().required(),
          subject: yupString.required(),
          message: yupString.required(),
        }),
        otherwise: object().strip(),
      }),
    }),
    otherwise: object({
      isEnabled: boolean(),
      data: object().when('isEnabled', {
        is: true,
        then: object({
          person: yupString.required(),
          email: yupString.email().required(),
        }),
        otherwise: object().strip(),
      }),
    }),
  }),
  sms: object({
    isEnabled: boolean(),
    data: object().when('isEnabled', {
      is: true,
      then: object({
        person: yupString.required(),
        phone: yupString.required(),
        message: yupString.required(),
      }),
      otherwise: object().strip(),
    }),
  }),
});
