import {Choice, DataStatus, Switch} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isNil, isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {selectActiveBranchId, useIsPublishingMultiBranchEnabledQuery} from '@dms/api';
import {i18n} from '@dms/i18n';
import {testIds} from '@dms/routes';
import {queryParams, useBranches} from '@dms/shared';

import {useQueryState} from 'shared';

export function MultiBranchControl() {
  const [branchId, setBranchId, clearBranchId] = useQueryState(queryParams.BRANCH_ID);
  const activeBranchId = useSelector(selectActiveBranchId);
  const {branchOptions, isLoading: isLoadingBranches} = useBranches();

  const {data: isMultiBranch, isLoading: isMultiBranchLoading} =
    useIsPublishingMultiBranchEnabledQuery();

  const isActiveBranchPresent = branchOptions.map((item) => item.value).includes(activeBranchId);

  // Update branchId query parameter if value not set or current branch is not available for user
  if (isMultiBranch && isNotEmpty(branchOptions) && (!isActiveBranchPresent || isNil(branchId))) {
    setBranchId(isActiveBranchPresent ? activeBranchId : branchOptions[0].value);
  }
  // Clear branchId query parameter if value is presented
  if (!isMultiBranch && isNotNil(branchId)) {
    clearBranchId();
  }

  return (
    <HStack align="center" spacing={4}>
      <DataStatus isLoading={isMultiBranchLoading}>
        <Switch
          label={i18n.t('entity.advertisingPlatforms.labels.allowMultiBranch')}
          helperText={i18n.t('entity.advertisingPlatforms.labels.allowMultiBranchDescription')}
          value={!!isMultiBranch}
          isDisabled
          data-testid={testIds.settings.advertisingPlatforms('allowMultiBranch')}
        />
        <Show when={isMultiBranch}>
          <Box width={70}>
            <DataStatus isLoading={isLoadingBranches}>
              <Choice
                value={branchId}
                onChange={(branchId) => setBranchId(branchId as string)}
                options={branchOptions}
                isNotClearable
                data-testid={testIds.settings.advertisingPlatforms('branch')}
              />
            </DataStatus>
          </Box>
        </Show>
      </DataStatus>
    </HStack>
  );
}
