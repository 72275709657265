import {Card} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {assocPath, includes, not, pipe} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {BasicCategoryOfStructureResponseBody, useGetCurrentUserInfoQuery} from '@dms/api';

import {CategoryUniqueKey, SECOND_SET_VALUES} from '../constants';
import {StructureSettingsForm} from '../types/StructureSettingsForm';
import {getCategoryName} from '../utils/getCategoryName';
import {getParams} from '../utils/getParams';
import {ParameterList} from './ParameterList';

interface CategoryCardProps {
  categoryData: BasicCategoryOfStructureResponseBody;
  parentCategoryData?: BasicCategoryOfStructureResponseBody;
  isActive: boolean;
  formApi: UseFormReturn<StructureSettingsForm>;
}

export function CategoryCard(props: CategoryCardProps) {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const {data: userInfo} = useGetCurrentUserInfoQuery();

  const handleChange = (value: boolean) => {
    pipe(
      assocPath(['categories', props.categoryData.id], value),
      props.formApi.reset
    )(props.formApi.getValues());

    setExpanded(value);
  };

  const filteredCategories = props.categoryData.childCategories?.filter((category) =>
    not(
      includes(category.uniqueKey, [
        CategoryUniqueKey.LR_WHEEL,
        CategoryUniqueKey.RR_WHEEL,
        CategoryUniqueKey.RF_WHEEL,
      ])
    )
  );

  const hasParams =
    not(
      includes(props.categoryData.uniqueKey, [
        CategoryUniqueKey.THE_SECOND_SET,
        CategoryUniqueKey.TESTDRIVE,
        CategoryUniqueKey.EXTERIOR_DAMAGE,
        CategoryUniqueKey.DAMAGE_TO_THE_INTERIOR,
      ])
    ) || !!SECOND_SET_VALUES[props.categoryData.key || ''];

  const hasChildCategories =
    not(
      includes(props.categoryData.uniqueKey, [
        CategoryUniqueKey.PHOTODOCUMENTATION,
        CategoryUniqueKey.EXTERIOR_DAMAGE,
        CategoryUniqueKey.DAMAGE_TO_THE_INTERIOR,
        CategoryUniqueKey.EQUIPMENT,
      ])
    ) &&
    isNotNilOrEmpty(filteredCategories) &&
    !SECOND_SET_VALUES[props.categoryData.key || ''];

  const hasChildCategoriesOrParams = hasChildCategories || hasParams;

  return (
    <Card
      variant="inlineWhite"
      title={getCategoryName(
        props.categoryData,
        props.parentCategoryData,
        userInfo?.settings.language
      )}
      control={{
        type: 'switch',
        value: props.isActive,
        onChange: handleChange,
      }}
      isExpandable={props.isActive && !props.parentCategoryData && hasChildCategoriesOrParams}
      isExpanded={isExpanded || (hasChildCategoriesOrParams && isNotNil(props.parentCategoryData))}
      onExpandButtonClick={() => setExpanded(!isExpanded)}
    >
      {props.isActive && hasChildCategoriesOrParams ? (
        <VStack spacing={4}>
          <Show when={hasParams}>
            <ParameterList
              paramDefinitions={getParams(props.categoryData)}
              formApi={props.formApi}
              onNoneSelected={() => handleChange(false)}
            />
          </Show>
          <Show when={hasChildCategories}>
            {filteredCategories?.map((category) => (
              <CategoryCard
                key={category.id}
                categoryData={category}
                parentCategoryData={props.categoryData}
                isActive={props.formApi.watch('categories')[category.id] ?? true}
                formApi={props.formApi}
              />
            ))}
          </Show>
        </VStack>
      ) : null}
    </Card>
  );
}
