import {Card, Action, Label, Separator} from 'platform/components';
import {HStack, Box, Text, VStack, Show, Heading} from 'platform/foundation';
import {useDateTimeFormatter, LocaleDateTimeFormat} from 'platform/locale';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {GetServiceCaseScheduleRepairApiResponse} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {getRandomId, parseDate, Nullish, suffixTestId, TestIdProps} from 'shared';

interface RepairScheduledCardProps extends TestIdProps {
  data: GetServiceCaseScheduleRepairApiResponse | Nullish;
  cardActions?: Action[];
}

export function RepairScheduledCard(props: RepairScheduledCardProps) {
  const formatDateTime = useDateTimeFormatter();

  const getFormatedDateString = (format: LocaleDateTimeFormat, value: string | Nullish) => {
    if (isNil(value)) {
      return EMPTY_PLACEHOLDER;
    }

    return formatDateTime(format, parseDate(value));
  };

  const checkinDate = getFormatedDateString('dateShort', props.data?.repairSchedule?.checkin?.from);
  const checkinTime = getFormatedDateString('timeShort', props.data?.repairSchedule?.checkin?.from);
  const handoverDate = getFormatedDateString(
    'dateShort',
    props.data?.repairSchedule?.handover?.releaseDate
  );
  const handoverTime = getFormatedDateString(
    'timeShort',
    props.data?.repairSchedule?.handover?.releaseDate
  );

  const checkinAdvisor = props.data?.repairSchedule?.checkin?.employee?.name ?? EMPTY_PLACEHOLDER;
  const handoverAdvisor = props.data?.repairSchedule?.checkin?.employee?.name ?? EMPTY_PLACEHOLDER;

  const serviceWorks = props.data?.serviceWork?.map((work) => ({
    start: getFormatedDateString('timeShort', work?.from),
    end: getFormatedDateString('timeShort', work?.till),
    mechanic: work?.employee?.name ?? EMPTY_PLACEHOLDER,
  }));

  return (
    <Card
      title={i18n.t('entity.serviceScheduler.lables.repairSchedule')}
      actions={props.cardActions}
    >
      <HStack spacing={4}>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.checkinDate')}</Label>
          <Text data-testid={suffixTestId('checkinDate', props)}>{checkinDate}</Text>
        </Box>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.checkinTime')}</Label>
          <Text data-testid={suffixTestId('checkinTime', props)}>{checkinTime}</Text>
        </Box>
        <Box flex={2}>
          <Label>{i18n.t('entity.serviceScheduler.lables.serviceAdvisor')}</Label>
          <Text data-testid={suffixTestId('checkinAdvisor', props)}>{checkinAdvisor}</Text>
        </Box>
      </HStack>
      <Separator />
      <HStack spacing={4}>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.handoverDate')}</Label>
          <Text data-testid={suffixTestId('handoverDate', props)}>{handoverDate}</Text>
        </Box>
        <Box flex={1}>
          <Label>{i18n.t('entity.serviceScheduler.lables.handoverTime')}</Label>
          <Text data-testid={suffixTestId('handoverTime', props)}>{handoverTime}</Text>
        </Box>
        <Box flex={2}>
          <Label>{i18n.t('entity.serviceScheduler.lables.serviceAdvisor')}</Label>
          <Text data-testid={suffixTestId('handoverAdvisor', props)}>{handoverAdvisor}</Text>
        </Box>
      </HStack>
      <Show when={isNotNilOrEmpty(serviceWorks)}>
        <Separator />
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('entity.serviceScheduler.lables.serviceWork')}</Heading>
          {serviceWorks?.map((work, index) => (
            <HStack spacing={4} key={getRandomId()}>
              <Box flex={1}>
                <Label>{i18n.t('entity.serviceScheduler.lables.startWork')}</Label>
                <Text data-testid={suffixTestId(`work-[${index}]-start`, props)}>{work.start}</Text>
              </Box>
              <Box flex={1}>
                <Label>{i18n.t('entity.serviceScheduler.lables.endOfWork')}</Label>
                <Text data-testid={suffixTestId(`work-[${index}]-end`, props)}>{work.end}</Text>
              </Box>
              <Box flex={2}>
                <Label>{i18n.t('entity.serviceScheduler.lables.mechanic')}</Label>
                <Text data-testid={suffixTestId(`work-[${index}]-mechanic`, props)}>
                  {work.mechanic}
                </Text>
              </Box>
            </HStack>
          ))}
        </VStack>
      </Show>
    </Card>
  );
}
