import {DataStatus, Form, FormField} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {useGetManufacturersQuery} from '@dms/api';
import {i18n} from '@dms/i18n';
import {getOptionsFromManufacturers, getSearchTypeOptions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

type MaterialPriceListTabExternalFiltersForm = Partial<{
  catalogNumber: string;
  searchType: string;
  manufacturerId: string;
  supplierId: string;
}>;

type MaterialPriceListTabExternalFiltersProps = FormToDataGridConnectorProps & TestIdProps;

export function MaterialPriceListTabExternalFilters(
  props: MaterialPriceListTabExternalFiltersProps
) {
  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const isLoading = isManufacturersLoading;
  const isError = isManufacturersError;

  const searchTypeOptions = getSearchTypeOptions();
  const defaultSearchType = searchTypeOptions[0].value;

  const values = {
    ...props.values,
    searchType: props.values.searchType ?? defaultSearchType,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialPriceListTabExternalFiltersForm> values={values} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={3} align="flex-end">
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="catalogNumber"
                  type="text"
                  label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                  data-testid={suffixTestId('catalogueNumber', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  name="searchType"
                  type="choice"
                  options={searchTypeOptions}
                  isNotClearable
                  data-testid={suffixTestId('filteringMethod', props)}
                />
              </GridItem>
            </Grid>
            <FormField
              control={control}
              name="manufacturerId"
              type="choice"
              label={i18n.t('entity.warehouse.labels.manufacturer')}
              options={getOptionsFromManufacturers(manufacturers)}
              data-testid={suffixTestId('manufacturer', props)}
            />
            <FormField
              control={control}
              name="supplierId"
              type="choice"
              label={i18n.t('entity.warehouse.labels.supplier')}
              options={[]}
              data-testid={suffixTestId('supplier', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
