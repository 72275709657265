import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';

import {BankAccountData} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, yupString, TestIdProps, Nullish} from 'shared';

import {COUNTRY_CZE} from '../../constants/country';
import {useBank} from '../../hooks/useBank';
import {useCurrencies} from '../../hooks/useCurrencies';
import {useTenant} from '../../hooks/useTenant';
import {combineAccountNumberAndBankCode} from '../../utils/combineAccountNumberAndBankCode';
import {CountrySelect} from '../CountrySelect/CountrySelect';

const BANK_CODE_REGEX = /\/\d{4}$/;

export interface BankAccountFormProps extends TestIdProps {
  initialValues?: Partial<BankAccountData>;
  onSubmit?: FormSubmitHandler<BankAccountData>;
}

export function BankAccountForm(props: BankAccountFormProps) {
  const {bankOptions} = useBank();
  const {currencyOptions} = useCurrencies();
  const {isTenantCountrySupported} = useTenant();

  const onChangeBankCode =
    (formApi: UseFormReturn<BankAccountData>) => (bankCode: string | number | Nullish) => {
      if (isNil(bankCode)) {
        return;
      }
      if (!isTenantCountrySupported(COUNTRY_CZE)) {
        return;
      }

      const accountNumber = formApi.getValues('number');
      if (isNil(accountNumber)) {
        return;
      }

      const composedBankAccount = combineAccountNumberAndBankCode(
        accountNumber,
        bankCode.toString()
      );

      formApi.setValue('number', composedBankAccount);
    };

  const onChangeAccountNumber =
    (formApi: UseFormReturn<BankAccountData>) => (accountNumber: string | Nullish) => {
      if (isNil(accountNumber)) {
        return;
      }
      if (!isTenantCountrySupported(COUNTRY_CZE)) {
        return;
      }
      if (!BANK_CODE_REGEX.test(accountNumber)) {
        // If account number does not contain valid bank code, clear bank code
        formApi.setValue('bankCode', null);
        return;
      }

      // Extract bank code from account number
      const bankCode = accountNumber.slice(-4);
      const bankCodeOption = bankOptions?.find((option) => option.value === bankCode);

      if (bankCodeOption) {
        formApi.setValue('bankCode', bankCodeOption.value);
      }
    };

  return (
    <Form<BankAccountData>
      onSubmit={props.onSubmit}
      defaultValues={props.initialValues}
      schema={schema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="ownerName"
                label={i18n.t('entity.bankAccount.labels.ownerName')}
                data-testid={suffixTestId(`ownerName`, props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="name"
                label={i18n.t('entity.bankAccount.labels.name')}
                data-testid={suffixTestId(`name`, props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="number"
                label={i18n.t('entity.bank.labels.accountNumber')}
                data-testid={suffixTestId(`number`, props)}
                tooltip={i18n.t('entity.bank.labels.accountNumberTooltip')}
                onChange={onChangeAccountNumber(formApi)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="bankCode"
                options={bankOptions}
                label={i18n.t('entity.bank.labels.name')}
                menuInPortal
                data-testid={suffixTestId(`bankCode`, props)}
                onChange={onChangeBankCode(formApi)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="currency"
                options={currencyOptions}
                label={i18n.t('entity.bankAccount.labels.currency')}
                menuInPortal
                data-testid={suffixTestId(`currency`, props)}
              />
            </Box>
            <Box flex={1}>
              <CountrySelect<BankAccountData>
                control={control}
                name="countryCode"
                isNotClearable
                label={i18n.t('entity.bankAccount.labels.countryCode')}
                menuInPortal
                data-testid={suffixTestId(`countryCode`, props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="swiftBic"
                label={i18n.t('entity.bankAccount.labels.swiftBic')}
                data-testid={suffixTestId(`swiftBic`, props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="iban"
                label={i18n.t('entity.bankAccount.labels.iban')}
                data-testid={suffixTestId(`iban`, props)}
              />
            </Box>
          </HStack>

          <Space vertical={2} />
          <ButtonGroup align="right" data-testid={suffixTestId(`submitButtons`, props)}>
            <Button
              data-testid={suffixTestId(`discard`, props)}
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('general.actions.discard')}
            />
            <FormButton
              data-testid={suffixTestId(`save`, props)}
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  name: yupString.default(null),
  countryCode: yupString.default(null),
  ownerName: yupString.default(null),
  iban: yupString.default(null),
  swiftBic: yupString.default(null),
  currency: yupString.default(null),
  number: yupString.default(null),
  bankCode: yupString.default(null),
});
