import {
  BulkPostReceiveNoteCorrectionItemsRequest,
  BulkPostReceiveNoteCorrectionItemsRequestSchema,
  BulkPostReceiveNoteCorrectionItemsResponse,
  BulkPostReceiveNoteCorrectionItemsResponseSchema,
  GetReceiveNoteCorrectionItemRequest,
  GetReceiveNoteCorrectionItemRequestSchema,
  GetReceiveNoteCorrectionItemResponse,
  GetReceiveNoteCorrectionItemResponseSchema,
  PatchReceiveNoteCorrectionItemRequest,
  PatchReceiveNoteCorrectionItemRequestSchema,
  PatchReceiveNoteCorrectionItemResponse,
  PatchReceiveNoteCorrectionItemResponseSchema,
  PatchReceiveNoteCorrectionItemsDeleteRequest,
  PatchReceiveNoteCorrectionItemsDeleteRequestSchema,
  PatchReceiveNoteCorrectionItemsDeleteResponse,
  PatchReceiveNoteCorrectionItemsDeleteResponseSchema,
  PutReceiveNoteCorrectionItemCalculationRequest,
  PutReceiveNoteCorrectionItemCalculationRequestSchema,
  PutReceiveNoteCorrectionItemCalculationResponse,
  PutReceiveNoteCorrectionItemCalculationResponseSchema,
} from '../types/metadaWarehouseReceiveNoteCorrectionItem';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseReceiveNoteCorrectionItemApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    bulkPostReceiveNoteCorrectionItems: build.mutation<
      BulkPostReceiveNoteCorrectionItemsResponse,
      BulkPostReceiveNoteCorrectionItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/bulk-add-items`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionBasket', id: queryArg.creditNoteId},
      ],
      extraOptions: {
        requestSchema: BulkPostReceiveNoteCorrectionItemsRequestSchema,
        responseSchema: BulkPostReceiveNoteCorrectionItemsResponseSchema,
      },
    }),
    deleteReceiveNoteCorrectionItems: build.mutation<
      PatchReceiveNoteCorrectionItemsDeleteResponse,
      PatchReceiveNoteCorrectionItemsDeleteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/items/delete`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionBasket', id: queryArg.creditNoteId},
        {type: 'receiveNoteCorrections', id: queryArg.creditNoteId},
        'headerDynamicActions',
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionItemsDeleteRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionItemsDeleteResponseSchema,
      },
    }),
    getReceiveNoteCorrectionItem: build.query<
      GetReceiveNoteCorrectionItemResponse,
      GetReceiveNoteCorrectionItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note/${queryArg.creditNoteId}/item/${queryArg.creditNoteItemId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionItems', id: queryArg.creditNoteItemId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteCorrectionItemRequestSchema,
        responseSchema: GetReceiveNoteCorrectionItemResponseSchema,
      },
    }),
    patchReceiveNoteCorrectionItem: build.mutation<
      PatchReceiveNoteCorrectionItemResponse,
      PatchReceiveNoteCorrectionItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/credit-note-item/${queryArg.creditNoteItemId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteCorrectionItems', id: queryArg.creditNoteItemId},
        'receiveNoteCorrectionBasket',
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteCorrectionItemRequestSchema,
        responseSchema: PatchReceiveNoteCorrectionItemResponseSchema,
      },
    }),
    putReceiveNoteCorrectionItemCalculation: build.mutation<
      PutReceiveNoteCorrectionItemCalculationResponse,
      PutReceiveNoteCorrectionItemCalculationRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/credit-note-item/calculation',
        method: 'PUT',
        body: queryArg.body,
        signal: queryArg.signal,
        queryArg,
      }),
      extraOptions: {
        requestSchema: PutReceiveNoteCorrectionItemCalculationRequestSchema,
        responseSchema: PutReceiveNoteCorrectionItemCalculationResponseSchema,
      },
    }),
  }),
});

export const {
  useBulkPostReceiveNoteCorrectionItemsMutation,
  useDeleteReceiveNoteCorrectionItemsMutation,
  useGetReceiveNoteCorrectionItemQuery,
  usePatchReceiveNoteCorrectionItemMutation,
  usePutReceiveNoteCorrectionItemCalculationMutation,
} = metadaWarehouseReceiveNoteCorrectionItemApi;
