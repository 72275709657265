import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Align, Box, Heading, Hide, HStack, Show, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {eqProps, find, lensPath, map, not, pipe, set} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  CompGetInfoV3ApiResponse,
  ContractInformationResponseBodyV2,
  CreateCustomerAddressV2ApiResponse,
  CreateCustomerContactApiArg,
  LegalForm,
  PatchCustomerContactApiArg,
  PatchCustomerContractInformationApiArg,
  PersonRequestBody,
  useCreateCustomerContactMutation,
  useCreateCustomerContractInformationMutation,
  useGetCustomerV2Query,
  useGetTenantQuery,
  usePatchCustomerContactMutation,
  usePatchCustomerContractInformationMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {Nullish, suffixTestId, TestIdProps, yupString} from 'shared';

import {useCustomerAddress} from '../../hooks/useCustomerAddress';
import {ContractInformationFormType} from '../../types/ContractInformationFormType';
import {getCustomFieldPayload} from '../../utils/additionalFields';
import {getContractDefaultValues} from '../../utils/getContractDefaultValues';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';
import {InternationalAddressForm} from '../AddressForm/InternationalAddressForm';
import {BankList} from '../BankList/BankList';
import {yupBankAccount} from '../BankList/yupBankAccount';
import {ContactPersonForm} from '../ContactPersonForm/ContactPersonForm';
import {CountrySelect} from '../CountrySelect/CountrySelect';
import {DecodeBusinessIdButton} from '../DecodeBusinessIdButton/DecodeBusinessIdButton';

interface ContractInformationFormProps extends TestIdProps {
  customerId: string;
  isLoading?: boolean;
  isEditContactInformation?: boolean;
  isBankListDisabled?: boolean;
  contract?: ContractInformationResponseBodyV2;
  isOutsideDialog?: boolean;
  onClose?: () => void;
  onSave?: (id: string) => void;
}

export function ContractInformationForm(props: ContractInformationFormProps) {
  const {data: tenant} = useGetTenantQuery();
  const {
    addressOptions,
    openCreateAddressDialog,
    openEditAddressDialog,
    createCustomerAddress,
    isCreateCustomerAddressLoading,
  } = useCustomerAddress(props.customerId ?? '');

  /**
   * placed on this level, because the component in openDialog is not re-rendered
   */
  const {data: customerData} = useGetCustomerV2Query({customerId: props.customerId});

  const [createContract, {isLoading: isCreateContractLoading}] =
    useCreateCustomerContractInformationMutation();
  const [patchContract, {isLoading: isPatchContractLoading}] =
    usePatchCustomerContractInformationMutation();
  const [createContact, {isLoading: isCreateContactLoading}] = useCreateCustomerContactMutation();
  const [editContact, {isLoading: isEditContactLoading}] = usePatchCustomerContactMutation();

  const handleSubmit: FormSubmitHandler<ContractInformationFormType> = async (values) => {
    const {additionalFields, ...submitValues} = values;
    const bankAccountsData = submitValues.bankAccounts?.[0]?.bankAccountData;
    const args: PatchCustomerContractInformationApiArg = {
      customerId: props.customerId,
      contractInformationId: props.contract?.id ?? '',
      contractInformationRequestBody: {
        ...submitValues,
        customFieldsPayload: getCustomFieldPayload(additionalFields),
        bankAccounts:
          !bankAccountsData?.number &&
          !bankAccountsData?.iban &&
          !bankAccountsData?.bankCode &&
          !bankAccountsData?.currency &&
          !bankAccountsData?.name &&
          !bankAccountsData?.ownerName &&
          !bankAccountsData?.swiftBic
            ? []
            : submitValues.bankAccounts,
      },
    };

    const patchOrCreateContract = isNotNilOrEmpty(props.contract?.id)
      ? patchContract
      : createContract;

    await patchOrCreateContract(args)
      .unwrap()
      .then(({id}) => props.onSave?.(id))
      .then(() => showNotification.success(i18n.t('general.notifications.success')))
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const onCreateContactSubmit =
    (formApi: UseFormReturn<ContractInformationFormType>): FormSubmitHandler<PersonRequestBody> =>
    async (values) => {
      if (isNilOrEmpty(values?.phoneNumbers?.[0]?.phoneNumber?.number)) {
        values.phoneNumbers = [];
      }

      if (isNilOrEmpty(values?.emails?.[0]?.email)) {
        values.emails = [];
      }

      if (isNilOrEmpty(values?.identityCards?.[0]?.cardData?.cardNumber)) {
        values.identityCards = [];
      }

      const args: CreateCustomerContactApiArg = {
        customerId: props.customerId,
        personRequestBody: values,
      };
      await createContact(args)
        .unwrap()
        .then((response) => {
          showNotification.success(i18n.t('general.notifications.success'));
          formApi.setValue('personId', response.id);
        })
        .then(closeCurrentDialog)
        .catch(handleApiError);
    };

  const handleEditPersonSubmit =
    (contactId: string): FormSubmitHandler<PersonRequestBody> =>
    async (values) => {
      if (isNilOrEmpty(values?.phoneNumbers?.[0]?.phoneNumber?.number)) {
        values.phoneNumbers = [];
      }

      if (isNilOrEmpty(values?.emails?.[0]?.email)) {
        values.emails = [];
      }

      if (isNilOrEmpty(values?.identityCards?.[0]?.cardData?.cardNumber)) {
        values.identityCards = [];
      }

      const args: PatchCustomerContactApiArg = {
        customerId: props.customerId,
        contactId,
        personRequestBody: values,
      };
      await editContact(args)
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.success'));
          closeCurrentDialog();
        })
        .catch(handleApiError);
    };

  const handleDecodeBusinessIdForm =
    (formApi: UseFormReturn<ContractInformationFormType>) =>
    (company: CompGetInfoV3ApiResponse) => {
      const prepareAddressesStructure = (a: AddressResponseBodyV2) => ({
        id: a.id,
        address: `${a.address.street} ${a.address.descriptiveNumber}${
          a.address.orientationNumber ? '/' : ''
        }${a.address.orientationNumber}`,
      });

      const compareWhetherAddressAlreadyExists = (address: {id: string; address: string | null}) =>
        eqProps('address', address, company?.[0]);

      const findCustomerAddress = pipe(
        map(prepareAddressesStructure),
        find(compareWhetherAddressAlreadyExists)
      )(customerData?.addresses ?? []);

      const isAddressAlreadyExist = isNotNilOrEmpty(findCustomerAddress);

      /**
       * if the decoded address does not exist in the customer entity,
       * offer the user to add the address to their existing
       */
      if (not(isAddressAlreadyExist)) {
        const addressData: AddressRequestBodyV2 = {
          address: {
            street: company?.[0]?.street ?? '',
            descriptiveNumber: company?.[0]?.descriptiveNumber ?? null,
            orientationNumber: company?.[0]?.orientationNumber ?? null,
            city: company?.[0]?.city ?? '',
            zip: company?.[0]?.zipCode ?? '',
            state: company?.[0]?.state,
            country: company?.[0]?.country ?? '',
            addressComplement: null,
            prefix: company?.[0]?.prefix ?? null,
            district: null,
          },
          type: i18n.t('entity.person.permanentAddressId'),
          invalid: null,
        };

        openDialog(
          <InternationalAddressForm
            data-testid={testIds.customer.detail('decode-dialog')}
            isEdit={props.isEditContactInformation}
            isLoading={isCreateCustomerAddressLoading}
            address={addressData}
            onSubmit={createCustomerAddress((address) =>
              formApi?.setValue('businessInfo.businessAddressId', address.id)
            )}
          />,
          {
            title: i18n.t('entity.customer.labels.wouldYouLikeToAddNewAddress'),
            scrollBehavior: 'outside',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('decodeDialog', props),
          }
        );
      }
      /**
       * if the address exists in the customer entity, set its default value in the form
       */
      if (isAddressAlreadyExist && findCustomerAddress?.id) {
        formApi.setValue('businessInfo.businessAddressId', findCustomerAddress.id);
      }

      pipe(
        set(lensPath(['businessInfo', 'businessInfoData']), {
          countryOfRegistrationCode: company?.[0]?.country ?? null,
          registrationNumber: company?.[0]?.businessId,
          vatNumber: company?.[0]?.taxId ?? null,
          fileNumber: company?.[0]?.fileNumber ?? null,
          tradeName: company?.[0]?.businessName ?? null,
        }),
        formApi.reset
      )(formApi.getValues());
    };

  const onEditPerson = (personId: string) => {
    const person = customerData?.persons?.find((p) => p.id === personId);

    openDialog(
      <ContactPersonForm
        data-testid={testIds.customer.detail('editContactPerson')}
        customerId={props.customerId}
        contact={person}
        addresses={customerData?.addresses}
        isLoading={isEditContactLoading}
        onSubmit={handleEditPersonSubmit(personId)}
      />,
      {
        withAdditionalFooter: true,
        title: i18n.t('entity.customer.labels.editContactPerson'),
        'data-testid': suffixTestId('editContactPerson', props),
      }
    );
  };

  const onCreateAddress =
    (formApi: UseFormReturn<ContractInformationFormType>) =>
    (address: CreateCustomerAddressV2ApiResponse) =>
      formApi.setValue('businessInfo.businessAddressId', address.id);

  const contactsOptions = customerData?.persons?.map((person) => ({
    label: getNaturalPersonFullName(person),
    value: person.id,
  }));

  const legalFormOptions = [
    {
      label: i18n.t('entity.person.labels.physicalPerson'),
      value: 'NATURAL_PERSON',
    },
    {
      label: i18n.t('entity.customer.labels.isSelfEmployed'),
      value: 'SELF_EMPLOYED',
    },
    {
      label: i18n.t('general.labels.company'),
      value: 'LEGAL_ENTITY',
    },
  ];

  return (
    <Form<ContractInformationFormType>
      onSubmit={handleSubmit}
      schema={props.isBankListDisabled ? schemaWithoutBankList : schema}
      defaultValues={getContractDefaultValues(
        props.isBankListDisabled,
        tenant?.country,
        props.contract
      )}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            type="chips"
            name="legalForm"
            isDisabled={props.isEditContactInformation}
            options={legalFormOptions}
            data-testid={suffixTestId('legalForm', props)}
            onChange={(value) => formApi.setValue('legalForm', value?.[0] as LegalForm)}
          />
          <Show when={formApi.watch('legalForm') === 'NATURAL_PERSON'}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="personId"
                  options={contactsOptions}
                  isDisabled={!contactsOptions?.length}
                  isRequired
                  menuInPortal
                  label={i18n.t('entity.party.labels.personId')}
                  data-testid={suffixTestId('personId', props)}
                />
              </Box>
              <HStack height={13} align="flex-end">
                <IconButton
                  icon="image/edit"
                  severity="informational"
                  isDisabled={isNilOrEmpty(formApi.watch('personId'))}
                  onClick={() => onEditPerson(formApi.watch('personId')!)}
                  data-testid={suffixTestId('editPersonId', props)}
                />
              </HStack>
            </HStack>
            <Align left>
              <Button
                leftIcon="content/add_circle"
                title={i18n.t('entity.customer.actions.newContactPerson')}
                size="small"
                variant="link"
                onClick={() =>
                  openDialog(
                    <ContactPersonForm
                      data-testid={testIds.customer.detail('newContactPerson')}
                      customerId={props.customerId}
                      addresses={customerData?.addresses}
                      isLoading={isCreateContactLoading}
                      onSubmit={onCreateContactSubmit(formApi)}
                    />,
                    {
                      withAdditionalFooter: true,
                      title: i18n.t('entity.customer.actions.newContactPerson'),
                      'data-testid': suffixTestId('newContactPerson', props),
                    }
                  )
                }
                data-testid={suffixTestId('newContactPerson', props)}
              />
            </Align>
          </Show>
          <Show when={formApi.watch('legalForm') !== 'NATURAL_PERSON'}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="businessInfo.businessInfoData.registrationNumber"
                  label={i18n.t('entity.businessInfo.labels.registrationNumber')}
                  data-testid={suffixTestId('registrationNumber', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="businessInfo.businessInfoData.vatNumber"
                  label={i18n.t('entity.businessInfo.labels.vatNumber')}
                  data-testid={suffixTestId('vatNumber', props)}
                />
              </Box>
              <HStack height={13} align="flex-end">
                <DecodeBusinessIdButton
                  businessId={formApi.watch('businessInfo.businessInfoData.registrationNumber')}
                  vatId={formApi.watch('businessInfo.businessInfoData.vatNumber')}
                  countryCode={formApi.watch(
                    'businessInfo.businessInfoData.countryOfRegistrationCode'
                  )}
                  onSuccess={handleDecodeBusinessIdForm(formApi)}
                  data-testid={suffixTestId('decode', props)}
                />
              </HStack>
            </HStack>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="businessInfo.businessInfoData.tradeName"
                label={i18n.t('entity.businessInfo.labels.tradeName')}
                isRequired
                data-testid={suffixTestId('tradeName', props)}
              />
            </Box>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="businessInfo.businessInfoData.fileNumber"
                  label={i18n.t('entity.businessInfo.labels.fileNumber')}
                  data-testid={suffixTestId('fileNumber', props)}
                />
              </Box>
              <Box flex={1}>
                <CountrySelect<ContractInformationFormType>
                  control={control}
                  name="businessInfo.businessInfoData.countryOfRegistrationCode"
                  label={i18n.t('entity.businessInfo.labels.countryOfRegistrationCode')}
                  isRequired
                  menuInPortal
                  data-testid={suffixTestId('countryOfRegistrationCode', props)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="businessInfo.businessAddressId"
                  options={addressOptions}
                  isRequired
                  menuInPortal
                  label={i18n.t('contractInformation.labels.businessAddressId')}
                  data-testid={suffixTestId('permanentAddressId', props)}
                />
              </Box>
              <HStack height={13} align="flex-end">
                <IconButton
                  icon="image/edit"
                  severity="informational"
                  isDisabled={isNilOrEmpty(formApi.watch('businessInfo.businessAddressId'))}
                  onClick={() =>
                    openEditAddressDialog(formApi.watch('businessInfo.businessAddressId')!)
                  }
                  data-testid={suffixTestId('editPermanentAddressId', props)}
                />
              </HStack>
            </HStack>
            <Align left>
              <Button
                leftIcon="content/add_circle"
                title={i18n.t('entity.customer.actions.newAddress')}
                size="small"
                variant="link"
                onClick={() => openCreateAddressDialog({onSuccess: onCreateAddress(formApi)})}
                data-testid={suffixTestId('newAddress', props)}
              />
            </Align>
          </Show>

          <Show when={tenant?.country === 'POL'}>
            <Separator spacing={0} />
            <FormField
              control={control}
              type="checkbox"
              name="additionalFields.affiliatedEntity"
              label={i18n.t('general.labels.affiliatedEntity')}
              data-testid={suffixTestId('legalForm', props)}
            />
          </Show>

          <Hide when={props.isBankListDisabled}>
            <Separator spacing={0} />
            <Heading size={4}>{i18n.t('entity.customer.labels.bankAccount')}</Heading>
            <BankList
              control={control}
              formApi={formApi}
              data-testid={suffixTestId('bankList', props)}
            />
          </Hide>
          {props.isOutsideDialog ? (
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose ?? closeCurrentDialog}
                isDisabled={isCreateContractLoading || isPatchContractLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={isCreateContractLoading || isPatchContractLoading}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          ) : (
            <DialogFooter data-testid={props['data-testid']}>
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={props.onClose ?? closeCurrentDialog}
                  isDisabled={isCreateContractLoading || isPatchContractLoading}
                  title={i18n.t('general.actions.discard')}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  variant="primary"
                  type="submit"
                  control={control}
                  isLoading={isCreateContractLoading || isPatchContractLoading}
                  title={i18n.t('general.actions.save')}
                  data-testid={suffixTestId('save', props)}
                />
              </ButtonGroup>
            </DialogFooter>
          )}
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  legalForm: yupString.optional(),
  personId: yupString.when('legalForm', {
    is: (form: string) => form === 'NATURAL_PERSON',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  businessInfo: Yup.object().when('legalForm', {
    is: (form: string) => form !== 'NATURAL_PERSON',
    then: (schema) =>
      schema.shape({
        businessAddressId: yupString.required(),
        businessInfoData: Yup.object()
          .shape({
            countryOfRegistrationCode: yupString.required(),
            registrationNumber: yupString,
            vatNumber: yupString,
            tradeName: yupString.required(),
            fileNumber: yupString.optional(),
          })
          .test({
            test(businessInfoData) {
              const {registrationNumber, vatNumber} = businessInfoData;

              if (isNilOrEmpty(registrationNumber) && isNilOrEmpty(vatNumber)) {
                throw this.createError({
                  path: 'businessInfo.businessInfoData.registrationNumber',
                  message: i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired'),
                });
              }

              return true;
            },
          }),
      }),
  }),
  bankAccounts: Yup.array().of(yupBankAccount).min(1),
});

const schemaWithoutBankList = Yup.object().shape({
  legalForm: yupString.optional(),
  personId: yupString.when('legalForm', {
    is: (form: string) => form === 'NATURAL_PERSON',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  businessInfo: Yup.object().when('legalForm', {
    is: (form: string) => form !== 'NATURAL_PERSON',
    then: (schema) =>
      schema.shape({
        businessAddressId: yupString.required(),
        businessInfoData: Yup.object().shape(
          {
            countryOfRegistrationCode: yupString.required(),
            registrationNumber: yupString.when('vatNumber', {
              is: (value: string | Nullish) => isNilOrEmpty(value),
              then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
            }),
            vatNumber: yupString.when('registrationNumber', {
              is: (value: string | Nullish) => isNilOrEmpty(value),
              then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
            }),
            tradeName: yupString.required(),
            fileNumber: yupString.optional(),
          },
          [['registrationNumber', 'vatNumber']]
        ),
      }),
  }),
});
