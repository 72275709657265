import {
  Button,
  ButtonGroup,
  closeDialog,
  Form,
  FormField,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {usePostStorageLocationNoteApiMutation} from '@dms/api';
import {i18n} from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {refreshDatagrid} from 'features/datagrid';

interface NotesDialogProps {
  rowIds: string[];
  defaultNote?: string;
}

export function NotesDialog(props: NotesDialogProps) {
  const [addStorageLocationNote, noteQuery] = usePostStorageLocationNoteApiMutation();

  const handleSubmit = async (formData: {note: string}) => {
    await addStorageLocationNote({
      body: {
        storageLocationIds: props.rowIds,
        note: formData.note,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('storage-location-tire-warehouse');
        closeDialog('storage-locations-notes-dialog');
      })
      .catch(handleApiError);
  };

  return (
    <Form defaultValues={{note: props.defaultNote || ''}} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <FormField type="textarea" control={control} name="note" isResizable />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.cancel')}
              variant="secondary"
              onClick={() => closeDialog('storage-locations-notes-dialog')}
            />
            <Button
              title={i18n.t('general.actions.save')}
              type="submit"
              isLoading={noteQuery.isLoading}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
