import {showNotification} from 'platform/components';

import {useNavigate, useParams} from 'react-router-dom';

import {useGetTireWarehouseQuery, usePatchTireWarehouseMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SettingsTemplate} from '../../../../components/SettingsTemplate/SettingsTemplate';
import {GeneralTab} from '../../components/GeneralTab';
import {StorageLocations} from '../../components/StorageLocations';
import {TireWarehouseForm} from '../../types/TireWarehouseForm';
import {externalWarehouseFactory} from '../../utils/externalWarehouseFactory';

export function Detail() {
  const {id: warehouseId} = useParams();
  const navigate = useNavigate();

  const tireWarehouseDetailQuery = useGetTireWarehouseQuery(
    {warehouseId: warehouseId || ''},
    {skip: !warehouseId}
  );

  const [updateTireWarehouse, updateTireWarehouseStatus] = usePatchTireWarehouseMutation();

  const defaultValues: TireWarehouseForm = {
    name: tireWarehouseDetailQuery.data?.name || '',
    branchId: tireWarehouseDetailQuery.data?.branchId || '',
    storageLocationSeparator: tireWarehouseDetailQuery.data?.storageLocationSeparator || '',
    transportDays: tireWarehouseDetailQuery.data?.transportDays || 0,
    hasExternalWarehouse: tireWarehouseDetailQuery.data?.hasExternalWarehouse || false,
    externalWarehouses: tireWarehouseDetailQuery.data?.externalWarehouses || [
      externalWarehouseFactory(true),
    ],
  };

  const handleSubmit = (data: TireWarehouseForm) => {
    if (!warehouseId) {
      return;
    }

    updateTireWarehouse({body: data, warehouseId})
      .unwrap()
      .then(() => {
        showNotification.success();
        navigate(settingsRoutes.tireWarehouses);
      })
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs: [
          {
            label: i18n.t('entity.tireWarehouses.labels.title'),
            href: settingsRoutes.tireWarehouses,
          },
        ],
        title: i18n.t('entity.tireWarehousesDetail.labels.title', {
          name: tireWarehouseDetailQuery.data?.name,
        }),
      }}
      tabs={[
        {
          queryId: 'general',
          title: i18n.t('general.labels.general'),
          content: (
            <GeneralTab
              onSubmit={handleSubmit}
              defaultValues={defaultValues}
              isLoading={updateTireWarehouseStatus.isLoading}
              isEditing
            />
          ),
        },
        {
          queryId: 'location',
          title: i18n.t('entity.tireWarehouses.labels.storageLocations'),
          content: <StorageLocations tireWarehouseId={warehouseId || ''} />,
        },
      ]}
      isLoading={tireWarehouseDetailQuery.isLoading}
      isError={tireWarehouseDetailQuery.isError}
      data-testid={testIds.settings.tireWarehousesDetail('template')}
    />
  );
}
