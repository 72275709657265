import {Key, FeatureKey} from '@dms/api';

import {Nullish} from 'shared';

export function buildTree(items: Key[] | Nullish, search: string | Nullish) {
  if (!items) {
    return {
      features: [],
      treeOwnerMap: new Map<string, string>(),
    };
  }

  const itemsMap = new Map();
  const treeOwnerMap = new Map<string, string>();

  items.forEach((item) => {
    itemsMap.set(item.const_key, {...item, children: [], level: 0});
  });

  const rootItems: FeatureKey[] = [];

  items.forEach((item) => {
    const currentItem = itemsMap.get(item.const_key);

    if (item.parent) {
      const parent = itemsMap.get(item.parent);

      if (parent) {
        parent.children.push(currentItem);
        currentItem.level = parent.level + 1;

        const topMostParent = treeOwnerMap.get(item.parent) || item.parent;
        treeOwnerMap.set(item.const_key, topMostParent);
      }

      return;
    }

    rootItems.push(currentItem);
    treeOwnerMap.set(item.const_key, item.const_key);
  });

  const filterTree = (treeNode: FeatureKey) => {
    if (
      treeNode.labels.some((item) =>
        item.label.toLowerCase().includes((search || '').toLowerCase())
      )
    ) {
      return true;
    }

    treeNode.children = treeNode.children.filter(filterTree);

    return treeNode.children.length > 0;
  };

  return {
    features: search ? rootItems.filter(filterTree) : rootItems,
    treeOwnerMap,
  };
}
