import {Card} from 'platform/components';
import {Show} from 'platform/foundation';

import {OfferResponseBody, useGetBusinessCaseQuery} from '@dms/api';
import {testIds} from '@dms/routes';

import {useRequiredParams, useToggle} from 'shared';

import {isBrokerageVehicle} from '../../../../../utils/isBrokerageVehicle';
import {BrokeragePricingForm} from './BrokeragePricingForm';
import {PricingDetail} from './PricingDetail';
import {PricingForm} from './PricingForm';
import {getHeaderActions} from './utils/formGetters';

interface PricingWidgetProps {
  isBrokerage?: boolean;
  offer: OfferResponseBody;
  isReadonly?: boolean;
}

export function PricingWidget(props: PricingWidgetProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({
    businessCaseId,
  });

  const isEditable = (!props?.isReadonly && businessCase?.actions.purchaseEditVehicle) ?? false;

  const isBrokerage = Boolean(businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE');

  const purchaseVehicle = isBrokerage
    ? businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0]
    : businessCase?.offers?.[0]?.purchaseVehicles?.[0];

  const isStock = purchaseVehicle && !isBrokerageVehicle(purchaseVehicle);

  const [isDetail, toggleDetail] = useToggle(
    (purchaseVehicle?.pricing.confirmed && isEditable) || !isEditable
  );

  return (
    <Card
      data-testid={testIds.businessCase.buying('pricing')}
      actions={getHeaderActions(isDetail, isEditable, toggleDetail)}
      variant="inlineWhite"
    >
      <Show when={isDetail}>
        <PricingDetail
          isBrokerage={isBrokerage}
          vehicle={purchaseVehicle}
          isStock={isStock}
          data-testid="pricing-widget"
          businessCaseId={businessCaseId}
        />
      </Show>
      <Show when={!isDetail}>
        {props.isBrokerage ? (
          <BrokeragePricingForm offer={props.offer} toggleDetail={toggleDetail} />
        ) : (
          <PricingForm
            toggleDetail={toggleDetail}
            businessCaseId={businessCase?.id}
            vehicleId={purchaseVehicle?.vehicleId}
          />
        )}
      </Show>
    </Card>
  );
}
