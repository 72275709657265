import {Dropdown, DropdownItem, DropdownSubmenu} from 'platform/components';
import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {fetchUnAssignRequest} from '../../../store/assignment/reducer';
import {TAssignmentUserProps} from '../../../store/assignment/types';
import {Assignment} from '../../../types/Assignment';

type Option = {
  label: string;
  submenu: {label: string; callBack: () => void}[];
};

export function AssignmentOption({assignments, ...rest}: TAssignmentUserProps & TestIdProps) {
  const dispatch = useDispatch();
  const userRef = useRef<HTMLButtonElement>(null);
  const [menuList, setMenuList] = useState<Option[] | undefined>();

  const setMenuListFromBE = useCallback(() => {
    setMenuList(
      assignments?.map((assignment: Assignment) => ({
        label:
          getNaturalPersonFullName({
            firstName: assignment?.user.firstName ?? undefined,
            lastName: assignment?.user.lastName ?? undefined,
          }) ||
          assignment?.user?.email ||
          '',
        submenu: [
          {
            label: i18n.t('general.labels.unassign'),
            callBack: () => {
              dispatch(fetchUnAssignRequest(assignment));
            },
          },
        ],
      }))
    );
  }, [assignments, dispatch]);

  useEffect(() => {
    setMenuListFromBE();
  }, [assignments, setMenuListFromBE]);

  return (
    <Dropdown
      dropdownControl={
        <AssignButtonIcon
          aria-haspopup="true"
          ref={userRef}
          data-testid={suffixTestId('asigned-usersList-button', rest)}
        >
          <Icon value="navigation/more_vert" />
        </AssignButtonIcon>
      }
      onOpen={setMenuListFromBE}
    >
      {menuList?.map(({label, submenu}) => (
        <DropdownSubmenu
          label={label}
          key={`submenuItem-${label}`}
          suffix={<Icon value="navigation/arrow_right" />}
        >
          {submenu.map(({label: subLabel, callBack}) => (
            <DropdownItem
              label={subLabel}
              key={`submenuItem-label-${label}-${subLabel}`}
              onClick={callBack}
            />
          ))}
        </DropdownSubmenu>
      ))}
    </Dropdown>
  );
}

const AssignButtonIcon = styled.span`
  cursor: pointer;
  background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  justify-content: center;
  height: 24px;
  border-radius: 5px;
  align-items: center;
  display: inline-flex;
  padding: 0 8px;
  color: ${({theme}) => theme.colors.palettes.neutral[700][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  width: 24px;
  margin-right: 8px;
`;
