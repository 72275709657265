import validatePhone from 'phone';
import {PhoneNumber} from 'platform/components';
import {mixed, object, string} from 'yup';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const yupPersonalInformationSchema = object({
  firstName: string().required().nullable(),
  lastName: string().required().nullable(),
  phoneNumber: mixed()
    .test(
      'isPhone',
      i18n.t('general.validations.invalidPhoneNumber'),
      (phone: PhoneNumber | Nullish) => {
        if (isNilOrEmpty(phone?.number)) {
          return true;
        }

        return validatePhone(phone?.number || '', {
          country: phone?.countryCode,
          validateMobilePrefix: false,
        }).isValid;
      }
    )
    .nullable(),
});
