import {Alert} from 'platform/components';

import i18n from '@dms/i18n';
import {customerRoutes} from '@dms/routes';

import {composePath} from 'shared';

interface NoCustomerAlertProps {
  customerId: string;
  visible: boolean;
}

export function NoCustomerAlert(props: NoCustomerAlertProps) {
  if (!props.visible) {
    return null;
  }

  return (
    <Alert
      type="inline"
      variant="error"
      title={i18n.t('entity.gdpr.validations.noContractInformation')}
      hyperlinks={[
        {
          title: i18n.t('page.customer.viewCustomer'),
          rightIcon: 'action/launch',
          target: '_blank',
          href: composePath(customerRoutes.detail, {params: {id: props.customerId}}),
        },
      ]}
    />
  );
}
