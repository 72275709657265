import {Button, ButtonGroup, Card, Dialog, Separator, showNotification} from 'platform/components';
import {Grid, GridItem, Hide, Show, Space} from 'platform/foundation';

import {FC, useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  IdentityCardResponseBody,
  IdentityCardResponseBodyV2,
  PersonRequestBody,
  PersonResponseBody,
  PersonResponseBodyV2,
  usePatchCustomerContactMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {AutoSave, Form, IdentityCardData} from '@dms/teas';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {IdentityCardForm} from '../../IdentityCard/IdentityCardForm';
import {IdentityCardSelect} from '../../IdentityCard/IdentityCardSelect';
import {PersonForm} from '../../Person/PersonForm';
import {PersonSelect} from '../../Person/PersonSelect';

export type CheckoutPersonContractInformationFormState = {
  personId: string | null;
  person: PersonRequestBody | null;
  selectedIdentityCardIds?: string[];
};

interface CheckoutPersonContractInformationFormProps {
  customerId: string;
  contractIndex?: number;
  handleSubmit: (values: CheckoutPersonContractInformationFormState) => Promise<void> | void;
  showPersonData?: boolean;
  showIdentityCardSelect?: boolean;
  person: PersonResponseBodyV2[] | undefined;
  selectedPerson?: PersonResponseBody | null;
  selectedIdentityCards?: IdentityCardResponseBody[];
  address?: AddressResponseBodyV2[];
  initialValues?: CheckoutPersonContractInformationFormState; //Searching for
  permanentPersonId?: string;
  handlePersonSubmit: (
    personData: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  handleIdentityCardSubmit: (
    person: PersonResponseBodyV2
  ) => (
    cardData: IdentityCardData,
    id: string | null
  ) => Promise<IdentityCardResponseBodyV2 | null>;
  saveOnChange?: boolean;
  onDiscard?: () => void;
  isAdditionalCustomer?: boolean;
}

export const CheckoutPersonContractInformationForm: FC<
  CheckoutPersonContractInformationFormProps & TestIdProps
> = ({
  handlePersonSubmit,
  handleAddressSubmit,
  handleSubmit: onSubmit,
  showPersonData = false,
  selectedPerson,
  permanentPersonId,
  selectedIdentityCards,
  address,
  person,
  initialValues,
  saveOnChange,
  onDiscard,
  ...props
}) => {
  const [patchCustomerContact, {isLoading}] = usePatchCustomerContactMutation();
  const [isCreateIdentityCardOpen, openCreateIdentityCardDialog, closeCreateIdentityCardDialog] =
    useBoolean();
  const [selectedPersonFromInput, setSelectedPersonFromInput] = useState<
    PersonResponseBodyV2 | undefined
  >(undefined);
  const handleSubmitIdentityCard = (identityCardData: IdentityCardResponseBodyV2) => {
    if (isNilOrEmpty(selectedPersonFromInput) && isNilOrEmpty(selectedPerson)) {
      showNotification.error();
      return;
    }

    const person = isNotNil(selectedPerson) ? getPersonV2(selectedPerson) : undefined;

    const requestBody = selectedPersonFromInput
      ? getPersonRequestBody(identityCardData.id ?? null, identityCardData, selectedPersonFromInput)
      : getPersonRequestBody(identityCardData.id ?? null, identityCardData, person!);

    if (isNil(identityCardData.id)) {
      requestBody?.identityCards.push({id: null, cardData: identityCardData});
    }

    if (isNotNil(requestBody)) {
      patchCustomerContact({
        customerId: props.customerId,
        contactId: selectedPersonFromInput?.id ?? person?.id ?? '',
        personRequestBody: requestBody,
      })
        .unwrap()
        .then(() => {
          showNotification.success();
          closeCreateIdentityCardDialog();
        })
        .catch(handleApiError);
    }
  };

  return (
    <Grid columns={1}>
      <GridItem>
        <Card variant="inlineGrey" data-testid={props['data-testid']}>
          <Form<CheckoutPersonContractInformationFormState>
            formId={props['data-testid']}
            onSubmit={onSubmit}
            initialValues={initialValues}
            defaultValues={{
              selectedIdentityCardIds: [],
            }}
            keepDirtyOnReinitialize={false}
            render={({
              FieldArray,
              Field,
              Subscribe,
              form: {
                mutators: {push},
              },
              handleSubmit,
            }) => (
              <>
                {saveOnChange && <AutoSave save={onSubmit} saveOnChange />}
                <Grid columns={1}>
                  <GridItem>
                    {!showPersonData && (
                      <Grid columns={2}>
                        <PersonSelect<undefined>
                          data-testid={suffixTestId('person', props)}
                          name="personId"
                          label={i18n.t('entity.person.selectPerson')}
                          handlePersonSubmit={handlePersonSubmit}
                          person={person?.filter((p) => p.id !== permanentPersonId)}
                          initialValue={selectedPerson ? getPersonV2(selectedPerson) : undefined}
                        />
                      </Grid>
                    )}
                    <Subscribe
                      name="personId"
                      component={({input}) => {
                        const selectedPerson = person?.find((person) => person.id === input.value);
                        if (isNil(selectedPerson)) {
                          return null;
                        }
                        setSelectedPersonFromInput(selectedPerson);
                        return (
                          <>
                            <Field
                              name="person"
                              component={({input}) => (
                                <PersonForm
                                  data-testid={props['data-testid']}
                                  compactSelect
                                  showPersonData={showPersonData}
                                  showAddress
                                  showIdentityCards={false}
                                  address={address}
                                  initialValues={selectedPerson}
                                  onChange={input.onChange}
                                  handleAddressSubmit={handleAddressSubmit}
                                  saveOnChange
                                />
                              )}
                            />
                            <Hide when={props.isAdditionalCustomer}>
                              <Separator />
                              <FieldArray<undefined> name="selectedIdentityCardIds">
                                {({fields}) =>
                                  fields.map((name, i) => (
                                    <Subscribe<Array<string>>
                                      key={name}
                                      name="selectedIdentityCardIds"
                                      component={({input: {value}}) => {
                                        const identityCardOptions =
                                          selectedPerson?.identityCards.filter(
                                            (p) => !value?.includes(p.id) || value[i] === p.id
                                          ) || [];

                                        const deletedCard =
                                          value[i] &&
                                          !selectedPerson?.identityCards.find(
                                            (card) => card.id === value[i]
                                          )
                                            ? selectedIdentityCards?.find(
                                                (card) => card.id === value[i]
                                              )
                                            : undefined;

                                        return (
                                          <IdentityCardSelect<undefined>
                                            data-testid={suffixTestId(`[${i}]`, props)}
                                            name={name}
                                            initialValue={
                                              deletedCard
                                                ? {
                                                    id: deletedCard.id,
                                                    type: deletedCard.identityCardData.type,
                                                    cardNumber:
                                                      deletedCard.identityCardData.cardNumber,
                                                    issuedOn: deletedCard.identityCardData.issuedOn,
                                                    validUntil:
                                                      deletedCard.identityCardData.validUntil,
                                                    issuer: deletedCard.identityCardData.issuer,
                                                    issuedInCountryCode:
                                                      deletedCard.identityCardData
                                                        .issuedInCountryCode,
                                                    note: deletedCard.identityCardData.note,
                                                  }
                                                : null
                                            }
                                            label={i18n.t('general.labels.selectIdentityCard')}
                                            showRemove={
                                              fields.length !== undefined && fields.length > 1
                                            }
                                            handleRemoveIdentityCard={() => fields.remove(i)}
                                            identityCards={identityCardOptions}
                                            person={selectedPerson}
                                            customerId={props.customerId}
                                          />
                                        );
                                      }}
                                    />
                                  ))
                                }
                              </FieldArray>
                              <ButtonGroup>
                                <Button
                                  data-testid={suffixTestId(`createIdentityCard`, props)}
                                  variant="link"
                                  leftIcon="content/add_circle"
                                  onClick={openCreateIdentityCardDialog}
                                  title={i18n.t('general.actions.createNewIdentity')}
                                />
                                <Button
                                  data-testid={suffixTestId(`addIdentityCard`, props)}
                                  variant="link"
                                  leftIcon="content/add_circle"
                                  onClick={() => push('selectedIdentityCardIds', '')}
                                  title={i18n.t('general.customer.addIdentityCard')}
                                />
                              </ButtonGroup>
                              <Dialog
                                data-testid={suffixTestId(`createIdentityCardForm`, props)}
                                isOpen={isCreateIdentityCardOpen}
                                onClose={closeCreateIdentityCardDialog}
                                scrollBehavior="outside"
                                title={i18n.t('general.actions.createNewIdentity')}
                              >
                                <IdentityCardForm
                                  data-testid={suffixTestId(`createIdentityCardForm`, props)}
                                  onSubmit={handleSubmitIdentityCard}
                                  WrapperComponent={({children, handleSubmit}) => (
                                    <>
                                      {children}
                                      <Space vertical={4} />
                                      <ButtonGroup
                                        align="right"
                                        data-testid={suffixTestId(`createIdentityCardForm`, props)}
                                      >
                                        <Button
                                          data-testid={suffixTestId(
                                            `createIdentityCardForm-discard`,
                                            props
                                          )}
                                          variant="secondary"
                                          onClick={closeCreateIdentityCardDialog}
                                          title={i18n.t('general.actions.discard')}
                                        />
                                        <Button
                                          data-testid={suffixTestId(
                                            `createIdentityCardForm-submit`,
                                            props
                                          )}
                                          variant="primary"
                                          isLoading={isLoading}
                                          isDisabled={isLoading}
                                          onClick={handleSubmit}
                                          title={i18n.t('general.actions.create')}
                                        />
                                      </ButtonGroup>
                                    </>
                                  )}
                                />
                              </Dialog>
                            </Hide>
                          </>
                        );
                      }}
                    />
                  </GridItem>
                </Grid>
                <Show when={!saveOnChange}>
                  <Separator />
                  <ButtonGroup align="right">
                    <Button
                      data-testid={suffixTestId(`discard`, props)}
                      variant="secondary"
                      onClick={onDiscard}
                      title={i18n.t('general.actions.discard')}
                    />
                    <Button
                      data-testid={suffixTestId(`save`, props)}
                      variant="primary"
                      onClick={handleSubmit}
                      title={i18n.t('general.actions.save')}
                    />
                  </ButtonGroup>
                </Show>
              </>
            )}
          />
        </Card>
      </GridItem>
    </Grid>
  );
};

const getPersonV2 = (person: PersonResponseBody) => ({
  id: person.id,
  firstName: person.personData.firstName,
  lastName: person.personData.lastName,
  middleName: person.personData.middleName,
  titleBefore: person.personData.titleBefore,
  titleAfter: person.personData.titleAfter,
  genderKey: person.personData.genderKey,
  roles: person.personData.roles,
  citizenshipCode: person.personData.citizenshipCode,
  birthdate: person.personData.birthdate,
  personalIdentifier: person.personData.personalIdentifier,
  phoneNumbers: person.phoneNumbers?.map((p) => ({
    type: p.type,
    countryCode: p.phoneNumber.countryCode,
    prefix: p.phoneNumber.prefix,
    number: p.phoneNumber.number,
  })),
  emails: person.emails,
  identityCards: person.identityCards?.map((i) => ({
    id: i.id,
    type: i.identityCardData.type,
    cardNumber: i.identityCardData.cardNumber,
    issuedOn: i.identityCardData.issuedOn,
    validUntil: i.identityCardData.validUntil,
    issuer: i.identityCardData.issuer,
    issuedInCountryCode: i.identityCardData.issuedInCountryCode,
    note: i.identityCardData.note,
  })),
  permanentAddress: person.permanentAddress
    ? {
        id: person.permanentAddress.id,
        type: person.permanentAddress.addressData.type,
        invalid: person.permanentAddress.addressData.invalid,
        address: {
          street: '',
          descriptiveNumber: null,
          orientationNumber: null,
          city: person.permanentAddress.addressData.city ?? '',
          zip: person.permanentAddress.addressData.postalCode ?? '',
          country: person.permanentAddress.addressData.countryCode ?? '',
          addressComplement: null,
          prefix: null,
          district: null,
          state: null,
        },
      }
    : null,
});

const getPersonRequestBody = (
  identityCardId: string | null,
  cardData: IdentityCardData,
  person: PersonResponseBodyV2
): PersonRequestBody => ({
  personData: {
    firstName: person.firstName,
    lastName: person.lastName,
    middleName: person.middleName,
    titleBefore: person.titleBefore,
    titleAfter: person.titleAfter,
    genderKey: person.genderKey,
    roles: person.roles,
    citizenshipCode: person.citizenshipCode,
    birthdate: person.birthdate,
    personalIdentifier: person.personalIdentifier,
  },
  phoneNumbers: person.phoneNumbers.map((item) => ({
    type: item.type,
    phoneNumber: {
      countryCode: item.countryCode,
      prefix: item.prefix,
      number: item.number,
    },
  })),
  emails: person.emails,
  permanentAddressId: person.permanentAddress?.id ?? null,
  identityCards: person.identityCards.map((identityCard) =>
    identityCard.id === identityCardId
      ? {
          id: identityCardId,
          cardData: {
            type: cardData.type,
            cardNumber: cardData.cardNumber,
            issuedOn: cardData.issuedOn,
            validUntil: cardData.validUntil,
            issuer: cardData.issuer,
            issuedInCountryCode: cardData.issuedInCountryCode,
            note: cardData.note,
          },
        }
      : {
          id: identityCard.id,
          cardData: {
            type: identityCard.type,
            cardNumber: identityCard.cardNumber,
            issuedOn: identityCard.issuedOn,
            validUntil: identityCard.validUntil,
            issuer: identityCard.issuer,
            issuedInCountryCode: identityCard.issuedInCountryCode,
            note: identityCard.note,
          },
        }
  ),
});
