import {Button, openDialog, Separator} from 'platform/components';
import {Box, Heading, Hide, HStack, Icon, Show, Text, VStack} from 'platform/foundation';

import {isEmpty} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {useGetEsignoServiceQuery, useGetPairedDevicesListQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {sortDevicesByState} from '@dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DeviceAttributes} from './components/DeviceAttributes';
import {PairDeviceStepper} from './components/pairDeviceStepper/PairDeviceStepper';

export function PairedDevicesOverview() {
  const {data: pairedDevices, isLoading, isError} = useGetPairedDevicesListQuery();
  const {data: esignoService} = useGetEsignoServiceQuery();
  const isEsignoEnabled = isTrue(esignoService?.enabled);

  const openPairDeviceDialog = () =>
    openDialog(
      <PairDeviceStepper state="setup" data-testid={testIds.settings.signatures('pairDevice')} />,
      {
        title: i18n.t('entity.settings.labels.addDevice'),
      }
    );

  const sortedPairedDevices = sortDevicesByState(pairedDevices);

  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.settings.labels.signatures')}}
      data-testid={testIds.settings.signatures('settingsTemplate')}
      isLoading={isLoading}
      isError={isError}
    >
      <VStack spacing={6}>
        <Separator spacing={0} />
        <HStack justify="space-between">
          <Heading size={4}>{i18n.t('entity.settings.labels.listOfDevices')}</Heading>
          <Show when={isEsignoEnabled}>
            <Button
              variant="link"
              leftIcon="content/add_circle"
              title={i18n.t('entity.settings.labels.addDevice')}
              onClick={openPairDeviceDialog}
              data-testid={testIds.settings.signatures('pairDevice')}
            />
          </Show>
        </HStack>

        <Show when={isEmpty(pairedDevices)}>
          <Box padding={4}>
            <VStack spacing={4} align="center" justify="center">
              <Icon value="content/computer_home_sync" size={20} color="text.white" />
              <Box width={140}>
                <Text size="small" align="center" color="secondary">
                  {i18n.t('entity.settings.labels.noDevicesPaired')}
                </Text>
              </Box>
              <Show when={isEsignoEnabled}>
                <Button
                  title={i18n.t('entity.settings.labels.addDevice')}
                  data-testid={testIds.settings.signatures('empty-status')}
                  onClick={openPairDeviceDialog}
                />
              </Show>
            </VStack>
          </Box>
        </Show>

        <Hide when={isEmpty(pairedDevices)}>
          <VStack spacing={6}>
            <Text size="small" color="secondary">
              {i18n.t('entity.settings.labels.signaturesDescription')}
            </Text>

            {sortedPairedDevices?.map((unpairedDevices) => (
              <DeviceAttributes
                device={unpairedDevices}
                data-testid={testIds.settings.signatures(unpairedDevices.name)}
                key={unpairedDevices.pairedDeviceId}
              />
            ))}
          </VStack>
        </Hide>
      </VStack>
    </SettingsTemplate>
  );
}
