import {Separator} from 'platform/components';
import {Heading, Hide, Show, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {CustomerResponseBodyV2} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {CustomerInformationAttributes} from '../../CustomerInformationAttributes/CustomerInformationAttributes';
import {CustomerVerification} from '../../CustomerVerification/CustomerVerification';
import {SecondStepComponentType} from '../types/SecondStepComponentType';
import {CustomerContactPerson} from './CustomerContactPerson';

interface CustomerInformationCardProps extends TestIdProps {
  customer: CustomerResponseBodyV2;
  secondStepComponentType: SecondStepComponentType;
  isContactPersonHidden?: boolean;
  resourceId?: string;
  recordId?: string;
  hasVerification?: boolean;
}

export function CustomerInformation(props: CustomerInformationCardProps) {
  const contractInformationId = props.customer.contractInformation[0]?.id;

  return (
    <VStack spacing={2}>
      <Heading size={5}>{i18n.t('entity.customer.labels.customerInformation')}</Heading>
      <CustomerInformationAttributes
        displayAftersalesVersion={props.secondStepComponentType === 'SERVICE_CASE'}
        customer={props.customer}
        data-testid={props['data-testid']}
      />
      <Show when={props.hasVerification && isNotNil(contractInformationId)}>
        <CustomerVerification
          contractInformationId={contractInformationId}
          customerId={props.customer.id}
          recordId={props.recordId}
          resourceId={props.resourceId}
          data-testid={suffixTestId('verification', props)}
        />
      </Show>
      <Hide when={props.isContactPersonHidden}>
        <Separator spacing={2} />
        <CustomerContactPerson
          secondStepComponentType={props.secondStepComponentType}
          customer={props.customer}
          data-testid={props['data-testid']}
        />
      </Hide>
    </VStack>
  );
}
