import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {
  loadDocumentSeriesList,
  getInterestSeriesDefinitionList,
  SeriesTypeEnum,
  useThunkDispatch,
} from '@dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {InterestSeriesDefinitionList} from './components/InterestSeriesDefinitionList';

export function InterestDefinition() {
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const dispatch = useThunkDispatch();

  useEffect(() => {
    startLoading();
    Promise.all([
      dispatch(getInterestSeriesDefinitionList.action({})),
      dispatch(
        loadDocumentSeriesList({
          type: [SeriesTypeEnum.INTEREST_INTEREST, SeriesTypeEnum.GENERAL_VEHICLE],
          config: {sendBranch: false},
        })
      ),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, startLoading, stopLoading]);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('entity.interest.labels.interest'),
      href: settingsRoutes.interest,
    },
  ];

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('entity.seriesDefinition.labels.interestCodeDefinition'), breadcrumbs}}
      description={i18n.t('entity.seriesDefinition.labels.interestDefinitionDescription')}
      data-testid="integrations-interest-definitions-settings-modal"
    >
      <InterestSeriesDefinitionList />
    </SettingsTemplate>
  );
}
