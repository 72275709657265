import {Helmet} from 'react-helmet-async';

import i18n from '@dms/i18n';
import {Main} from '@dms/shared';

import {InspectionOverview} from './components/InspectionOverview';

export function Inspection() {
  return (
    <>
      <Helmet title={i18n.t('page.Inspection.labels.title')} />
      <Main isFullHeight heading={i18n.t('page.Inspection.labels.title')}>
        <InspectionOverview />
      </Main>
    </>
  );
}
