import {Button, Flag, Tooltip} from 'platform/components';
import {HStack, Image, Link, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import {SimilarVehicleResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {composePath, DOT_CHARACTER, Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {thumbnailUrl} from '../../../constants/thumbnailUrl';
import {getVehicleCoverPhoto} from '../../../utils/getVehicleCoverPhoto';
import {TextHighlighter} from './TextHighlighter';

interface MatchingVehicleProps extends TestIdProps {
  searchedVin: string | Nullish;
  searchedRegistrationPlate: string | Nullish;
  vehicle: SimilarVehicleResponseBody;
  onSelect?: (vehicleId: string) => void;
}

export function MatchingVehicle(props: MatchingVehicleProps) {
  const formatDateTime = useDateTimeFormatter();

  const coverPhoto = getVehicleCoverPhoto(props.vehicle.photos);

  const onSelect = () => props.onSelect?.(props.vehicle.id);

  return (
    <HStack spacing={4} align="center" data-testid={props['data-testid']}>
      <Image
        height={10}
        width="auto"
        ratio="4 / 3"
        fit="cover"
        src={coverPhoto ? `${coverPhoto.url}&height=72` : thumbnailUrl}
        data-testid={suffixTestId('photo', props)}
      />
      <VStack spacing={1} grow={1}>
        <HStack align="center" spacing={1}>
          <TextHighlighter
            size="small"
            searchWords={props.searchedRegistrationPlate}
            textToHighlight={props.vehicle.registrationPlate}
            data-testid={suffixTestId('registrationPlate', props)}
          />
          <Text size="small" color="secondary" data-testid={suffixTestId('title', props)}>
            {`(${props.vehicle.title})`}
          </Text>
          <Show when={props.vehicle.isArchived}>
            <Flag
              size="small"
              colorScheme="neutral"
              label={i18n.t('entity.vehicle.labels.archived')}
              data-testid={suffixTestId('archived', props)}
            />
          </Show>
        </HStack>
        <HStack spacing={1} data-testid={props['data-testid']} wrap align="center">
          <Link
            size="small"
            target="_blank"
            leftIcon="action/launch"
            title={i18n.t('entity.vehicle.labels.detail')}
            href={composePath(vehiclesRoutes.detail, {params: {id: props.vehicle.id}})}
            data-testid={suffixTestId('detail', props)}
          />
          <Text color="tertiary" size="xSmall">
            {DOT_CHARACTER}
          </Text>
          <Tooltip
            label={i18n.t('entity.vehicle.labels.vin')}
            data-testid={suffixTestId('vin', props)}
          >
            <TextHighlighter
              size="xSmall"
              color="secondary"
              searchWords={props.searchedVin}
              textToHighlight={props.vehicle.vin}
              data-testid={suffixTestId('vin', props)}
            />
          </Tooltip>
          <Text color="tertiary" size="xSmall">
            {DOT_CHARACTER}
          </Text>
          <Tooltip
            label={i18n.t('entity.vehicle.labels.firstRegistration')}
            data-testid={suffixTestId('firstRegistration', props)}
          >
            <Text
              color="secondary"
              size="xSmall"
              noWrap
              data-testid={suffixTestId('firstRegistration', props)}
            >
              {isNil(props.vehicle.firstRegistration)
                ? EMPTY_PLACEHOLDER
                : formatDateTime('dateShort', parseDate(props.vehicle.firstRegistration))}
            </Text>
          </Tooltip>
        </HStack>
      </VStack>
      <Show when={props.onSelect}>
        <Button
          size="default"
          onClick={onSelect}
          variant="secondary"
          title={i18n.t('general.labels.select')}
          data-testid={suffixTestId('select', props)}
        />
      </Show>
    </HStack>
  );
}
