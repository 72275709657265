import {Card, FormField, Table, TableRow, FormControl} from 'platform/components';
import {Box, Clickable, Heading, HStack, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match, Pattern} from 'ts-pattern';
import {Nullish} from 'utility-types';

import {UseFormReturn} from 'react-hook-form';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {ServiceCaseIntegrationsXentryOrderSynchronizationGroup} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, RequiredTestIdProps, parseDate, EMPTY_PLACEHOLDER} from 'shared';

import {SOURCES} from '../constants/Sources';
import {FormSchema} from '../types/FormSchema';

interface DataCardProps extends RequiredTestIdProps {
  isOmnetic?: boolean;
  hasSelectedSource: boolean;
  index: number;
  formApi: UseFormReturn<FormSchema>;
  control: FormControl<FormSchema>;
  serviceCaseId: string;
  group: ServiceCaseIntegrationsXentryOrderSynchronizationGroup;
}

export const DataCard = (props: DataCardProps) => {
  const formatDateTime = useDateTimeFormatter();

  const getValue = (valueType: string | Nullish, value: string | Nullish) =>
    match([valueType, value])
      .with([Pattern.any, Pattern.nullish], always(EMPTY_PLACEHOLDER))
      .with(['dateTime', Pattern.any], () =>
        isNotNilOrEmpty(value)
          ? formatDateTime('dateTimeMedium', parseDate(value))
          : EMPTY_PLACEHOLDER
      )
      .otherwise(always(value));

  const option = match(props.isOmnetic)
    .with(
      true,
      always({
        label: '',
        value: SOURCES.OMNETIC,
      })
    )
    .otherwise(
      always({
        label: '',
        value: SOURCES.XENTRY,
      })
    );

  return (
    <Clickable
      onClick={() =>
        props.formApi.setValue(
          `groups.${props.index}.selectedSource`,
          props.isOmnetic ? SOURCES.OMNETIC : SOURCES.XENTRY
        )
      }
      isDisabled={props.hasSelectedSource}
      data-testid={suffixTestId('clickable', props)}
    >
      <Card variant="inlineGrey">
        <HStack justify="space-between" align="center">
          <VStack spacing={1}>
            <HStack spacing={2}>
              <FormField
                type="radio"
                control={props.control}
                name={`groups.${props.index}.selectedSource`}
                options={[option]}
                isDisabled={
                  props.formApi.watch(`groups.${props.index}.selectedSource`) ===
                  (props.isOmnetic ? SOURCES.OMNETIC : SOURCES.XENTRY)
                    ? false
                    : props.hasSelectedSource
                }
                data-testid={suffixTestId('radio', props)}
              />
              <Heading size={4} data-testid={suffixTestId('heading', props)}>
                {i18n.t(`entity.integration.labels.${props.isOmnetic ? 'omnetic' : 'xentry'}`)}
              </Heading>
            </HStack>
            <Table
              tableLayout="fixed"
              variant="bordered"
              columns={[
                {
                  width: '50%',
                },
                {
                  width: '50%',
                },
              ]}
            >
              {props.group?.data?.map((item) => (
                <TableRow key={item?.labelName}>
                  <Box padding={1}>
                    <Text size="small">{item?.labelName}</Text>
                  </Box>
                  <Box padding={1}>
                    <Text size="small">{`${getValue(item?.valueType, props.isOmnetic ? item?.valueOmnetic : item?.valueXentry)} ${item?.unit || ''}`}</Text>
                  </Box>
                </TableRow>
              ))}
            </Table>
          </VStack>
        </HStack>
      </Card>
    </Clickable>
  );
};
