import {ReactElement} from 'react';

import {useFormRenderer, PossibleObject} from '@dms/teas';

export const PriceField = (props: PossibleObject & {name: string}): ReactElement => {
  const {Field} = useFormRenderer();
  // price mask accepted by task https://carvago.atlassian.net/browse/T20-6531
  return (
    <Field
      {...props}
      as="text"
      format={(value) => value && Number(value).toFixed(2)}
      formatOnBlur
    />
  );
};
