import {FC, useEffect, useState, useCallback, ChangeEvent} from 'react';

import {equals} from 'ramda';

import {TextField} from '../TextField/TextField';
import {TeasRangeSlider, InputsWrapper, TextFieldFormatWithRightAlign} from './styles';

interface Mark {
  value: number;
  label?: React.ReactNode;
}

type SliderProps = {
  min?: number;
  max?: number;
  showMaxPlus?: boolean;
  unit?: string;
  marks?: Mark[];
  onChange?: (event: React.ChangeEvent<{}>, value: number[]) => void;
  step?: number | null;
  defaultValue?: number[];
};

/**
 * @deprecated - use platform instead
 */
export const Slider: FC<SliderProps> = ({
  onChange,
  defaultValue,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  showMaxPlus,
  unit = '',
  ...props
}) => {
  const [sliderValue, setSliderValue] = useState<undefined | number[]>(defaultValue);

  useEffect(() => {
    if (!equals(sliderValue, defaultValue)) {
      setSliderValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = useCallback(
    (_: any, newValue: number[]) => {
      setSliderValue(newValue);
      onChange?.(_, newValue);
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {name, value: _value} = event.target;
      const numberValue = Number(_value);

      const comparer: {[key: string]: boolean} = {
        min: numberValue < min || numberValue > max,
        max: numberValue > max || numberValue < min,
      };

      if (comparer[name]) {
        setSliderValue([
          // eslint-disable-next-line no-nested-ternary
          name === 'min' ? (_value === '' ? 0 : numberValue) : (sliderValue as number[])[0],
          // eslint-disable-next-line no-nested-ternary
          name === 'max' ? (_value === '' ? 0 : numberValue) : (sliderValue as number[])[1],
        ]);

        return;
      }

      handleChange(undefined, [
        // eslint-disable-next-line no-nested-ternary
        name === 'min' ? (_value === '' ? 0 : numberValue) : (sliderValue as number[])[0],
        // eslint-disable-next-line no-nested-ternary
        name === 'max' ? (_value === '' ? 0 : numberValue) : (sliderValue as number[])[1],
      ]);
    },
    [sliderValue, handleChange, min, max]
  );

  const handleInputBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {name} = event.target;
      const index = name === 'min' ? 0 : 1;
      const values = [...(sliderValue as number[])];

      if (values[index] === undefined) {
        values[index] = 0;
      }

      values[0] = Math.max(Number(min), Math.min(Number(max), Number(values[0])));
      values[1] = Math.max(Number(min), Math.min(Number(max), Number(values[1])));

      const comparer: {[key: string]: boolean} = {
        min: values[0] > values[1],
        max: values[1] < values[0],
      };

      if (comparer[name]) {
        const [first, second] = values;

        [values[0], values[1]] = [second, first];
      }

      handleChange(undefined, values);
    },
    [sliderValue, handleChange, min, max]
  );

  return (
    <>
      <TeasRangeSlider
        {...props}
        min={min}
        max={max}
        data-testid="slider"
        value={sliderValue?.map((v) => (v === undefined ? 0 : v))}
        onChange={handleChange}
      />
      <InputsWrapper>
        <TextField
          variant="inline"
          name="min"
          type="number"
          min={min}
          value={!sliderValue || sliderValue[0] === undefined ? '' : String(sliderValue[0])}
          data-testid="slider-input-min"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
        />
        <TextFieldFormatWithRightAlign
          variant="inline"
          name="max"
          type={sliderValue?.[1] === max ? 'text' : 'number'}
          max={max}
          value={!sliderValue || sliderValue[1] === undefined ? '' : String(sliderValue[1] ?? '')}
          format={(val) => (showMaxPlus && sliderValue?.[1] === max ? `${val}+` : val)}
          data-testid="slider-input-max"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          inputEndAdornment={{render: unit}}
        />
      </InputsWrapper>
    </>
  );
};
