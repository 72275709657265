import {Form, FormField, Option} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {FormToDataGridConnectorProps} from 'features/datagrid';

import {StorageLocationFiltersValues} from '../types/StorageLocationFiltersValues';

interface StorageFiltersDGValues {
  tireWarehouse: string[];
  emptyLocation: ['TRUE' | 'FALSE'];
}

type StorageLocationFiltersProps = FormToDataGridConnectorProps & {
  warehouseOptions: Option[];
};

export function StorageLocationFilters(props: StorageLocationFiltersProps) {
  const typedValues = props.values as StorageFiltersDGValues;

  return (
    <Form<StorageLocationFiltersValues>
      values={{
        tireWarehouse: typedValues.tireWarehouse?.[0] || props.warehouseOptions[0]?.value,
        emptyLocation: typedValues.emptyLocation?.includes('TRUE') ? true : false,
      }}
      onChange={(newValue) => {
        props.onChange({
          tireWarehouse: [newValue.tireWarehouse],
          emptyLocation: newValue.emptyLocation ? ['TRUE'] : undefined,
        });
      }}
    >
      {(control) => (
        <HStack align="center" spacing={4}>
          <Box minWidth={70}>
            <FormField
              type="choice"
              control={control}
              name="tireWarehouse"
              options={props.warehouseOptions}
              label={i18n.t('entity.warehouse.labels.warehouse')}
              isNotClearable
            />
          </Box>
          <Box paddingTop={5}>
            <FormField
              type="switch"
              control={control}
              name="emptyLocation"
              label={i18n.t('general.labels.availableOnly')}
            />
          </Box>
        </HStack>
      )}
    </Form>
  );
}
