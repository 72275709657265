import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  getVehicleSeriesDefinition,
  vehicleSeriesDefinitionActions,
  useThunkDispatch,
} from '@dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {vehicleSeriesDefinitionCommonActions} from '../VehicleSeriesDefinition/utils';
import {VehicleSeriesDefinitionEdit} from './components/VehicleSeriesDefinitionEdit';

export function VehicleEditSeriesDefinition() {
  const {id} = useParams();

  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    startLoading();
    Promise.all([
      ...vehicleSeriesDefinitionCommonActions.map((action) => dispatch(action)),
      dispatch(vehicleSeriesDefinitionActions.clearDetail()),
      dispatch(getVehicleSeriesDefinition.action({id})),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, id, startLoading, stopLoading]);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.salesSettings.labels.vehicleSeriesDefinitionTitle'),
      href: settingsRoutes.vehicleSeriesDefinition,
    },
  ];

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('page.salesSettings.labels.vehicleDefinitionTitle'), breadcrumbs}}
      data-testid={testIds.settings.vehicleEditSeriesDefinition('modal')}
    >
      {isLoading ? null : <VehicleSeriesDefinitionEdit />}
    </SettingsTemplate>
  );
}
