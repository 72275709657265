import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head, isNil, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useDeleteServiceOrderIssueNoteMutation,
  usePatchServiceOrderIssueNotePartsReadyMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';

export function ServiceOrderIssues() {
  const navigate = useNavigate();

  const [partsReadyServiceOrderIssueNote] = usePatchServiceOrderIssueNotePartsReadyMutation();

  const [deleteServiceOrderIssueNote] = useDeleteServiceOrderIssueNoteMutation();

  const actionCallback: ActionCallback = ({
    actionKey,
    rowData,
    rowId,
    sourceSearchParams,
    refreshData,
  }) => {
    const serviceOrderIssueNoteId = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(serviceOrderIssueNoteId)) {
      throw new Error('Service order issue note id is not defined');
    }

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(
          composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
            queryParams: sourceSearchParams,
            params: {
              id: serviceOrderIssueNoteId,
            },
            isSearchParamsPreserved: false,
          })
        )
      )
      .with('ready', 'remove-ready', () => {
        const arePartsReady = path(['partsReady', 'value'], rowData) as boolean;

        if (isNil(arePartsReady)) {
          throw new Error('Parts ready is not defined');
        }

        partsReadyServiceOrderIssueNote({
          serviceOrderIssueNoteId,
          body: {partsReady: actionKey === 'ready'},
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderIssueNote({serviceOrderIssueNoteId})
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'))
              )
              .then(refreshData)
              .catch(handleApiError),
          'data-testid': testIds.warehouse.serviceOrderIssues('deleteDialog'),
        })
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode="warehouse-service-order-issue-notes"
        actionCallback={actionCallback}
        data-testid={testIds.warehouse.serviceOrderIssues('list')}
      />
    </FullscreenDataGridWrapper>
  );
}
