import {ButtonProps, closeCurrentDialog, openDialog} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {isArray} from 'util';

import {equals, not} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useDeleteBasketMaterialItemsApiMutation,
  useGetBasketMaterialItemsApiQuery,
  usePatchBasketItemQuantityApiMutation,
  usePostBasketItemBulkDeleteMutation,
  usePostBasketMaterialItemCheckoutApiMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  AfterSalesMaterialBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  MaterialBasketItemEditingDetails,
  useInvalidBasketItemsIds,
} from '@dms/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {ExtendedMateriallBasketItemType} from '../types/ExtendedMateriallBasketItemType';
import {EditMaterialItem} from './EditMaterialItem';
import {MaterialItemsListCard} from './MaterialItemsListCard';

interface AddMaterialItemsDialogProps extends TestIdProps {
  packageId: string;
  onClose: () => void;
  onAfterSubmit: () => void;
}

export function AddMaterialItemsDialog(props: AddMaterialItemsDialogProps) {
  const [postBasketMaterialItemCheckout, {isLoading: isPostBasketMaterialItemCheckoutLoading}] =
    usePostBasketMaterialItemCheckoutApiMutation();
  const [deleteBasketMaterialItems, {isLoading: isDeleteBasketMaterialItemsLoading}] =
    useDeleteBasketMaterialItemsApiMutation();
  const [deleteBasketItems, {isLoading: isDeleteBasketItemsLoading}] =
    usePostBasketItemBulkDeleteMutation();
  const [patchBasketItemQuantity] = usePatchBasketItemQuantityApiMutation();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: isBasketError,
  } = useGetBasketMaterialItemsApiQuery({
    packageId: props.packageId,
  });

  const basketItems =
    basket?.materialBasketItem?.map((item) => ({
      ...item,
      itemEditingAllowed: true,
      quantityEditingAllowed: true,
    })) ?? [];

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchBasketItemQuantity({
      basketItemId: itemId,
      body: {
        quantity: quantity.newQuantity || 0,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = (item: MaterialBasketItemEditingDetails) =>
    openDialog(
      <EditMaterialItem itemId={item.itemId ?? ''} context="basket" onClose={closeCurrentDialog} />,
      {
        title: `${item.itemName} (${item.itemNumber})`,
      }
    );

  const handleAddMaterial = () =>
    postBasketMaterialItemCheckout({packageId: props.packageId})
      .unwrap()
      .then(props.onAfterSubmit)
      .then(props.onClose)
      .catch(handleApiError);

  const handleCancel = () =>
    deleteBasketMaterialItems({packageId: props.packageId})
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);

  const actions = buildArray<ButtonProps>()
    .add({
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleCancel,
      isLoading: isDeleteBasketMaterialItemsLoading || isDeleteBasketItemsLoading,
      'data-testid': suffixTestId('cancel', props),
    })
    .add({
      title: i18n.t('general.actions.add'),
      onClick: handleAddMaterial,
      isLoading: isPostBasketMaterialItemCheckoutLoading,
      'data-testid': suffixTestId('add', props),
    });

  const handleDelete = (itemsIds: string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {basketItemIds: ids};

    return deleteBasketItems({body: requestBody}).unwrap().catch(handleApiError);
  };

  return (
    <FullScreenModal
      headline={i18n.t('entity.workshopServicePackages.labels.addMaterial')}
      actions={actions}
    >
      <Box padding={4} height="100%">
        <VStack spacing={4} height="100%">
          <Box maxHeight="100%" flex={1}>
            <HStack spacing={4} height="100%">
              <Box flex={5}>
                <MaterialItemsListCard packageId={props.packageId} />
              </Box>
              <Box flex={1} minWidth={86}>
                <AfterSalesMaterialBasket<ExtendedMateriallBasketItemType>
                  basket={{
                    items: basketItems,
                    totalPrice: null,
                    isLoading: isBasketLoading,
                    hasError: isBasketError,
                    hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                    isDeletingItems: isDeleteBasketItemsLoading || isDeleteBasketItemsLoading,
                    isPriceHidden: true,
                  }}
                  mechanic={{isMechanicHidden: true}}
                  onQuantityChange={handleQuantityChange}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  data-testid={suffixTestId('basket', props)}
                />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </FullScreenModal>
  );
}
