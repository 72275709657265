import {HStack, Heading, Show, StorageImage} from 'platform/foundation';

import {FC, useMemo} from 'react';

import i18n from '@dms/i18n';

import {
  ContentWrapper,
  EmptyWrapper,
  ImagesWrapper,
  ViewWrapper,
  ImageViewGrid,
  CoverPhotoLabelWrapper,
} from './styles';
import {Images} from './types';

export const ImagesCardView: FC<Images> = ({images, onImageClick}) => {
  const imageRenderer = useMemo(
    () =>
      images.map((image) => (
        <ImageViewGrid
          data-testid={`images-card-content-items-${image.id}`}
          key={image.id}
          onClick={() => onImageClick?.(image.id)}
        >
          <Show when={image.isCoverPhoto}>
            <CoverPhotoLabelWrapper blue>
              {i18n.t('entity.photo.labels.coverPhoto')}
            </CoverPhotoLabelWrapper>
          </Show>
          <Show when={image.isPromoPhoto}>
            <CoverPhotoLabelWrapper grayDark>
              {i18n.t('entity.photo.labels.promoPhoto')}
            </CoverPhotoLabelWrapper>
          </Show>
          <StorageImage url={image.url} width={25} height={19} fit="cover" />{' '}
          {/* set width * base * retina */}
        </ImageViewGrid>
      )),
    [images, onImageClick]
  );

  return (
    <ViewWrapper data-testid="view-images-card-wrapper">
      <ContentWrapper data-testid="images-card-content">
        {images.length ? (
          <ImagesWrapper active={false}>
            <HStack data-testid="images-card-content-items" wrap spacing={4}>
              {imageRenderer}
            </HStack>
          </ImagesWrapper>
        ) : (
          <EmptyWrapper active={true} data-testid="images-card-content-empty">
            <Heading size={5}>{i18n.t('entity.photo.notifications.noPhoto')}</Heading>
          </EmptyWrapper>
        )}
      </ContentWrapper>
    </ViewWrapper>
  );
};
