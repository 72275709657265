import {
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useState} from 'react';

import {equals, isNotNil} from 'ramda';

import {CompGetInfoV3ApiResponse, CountryAlpha3CodeEnum} from '@dms/api';
import i18n from '@dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {useAddress} from '../../../hooks/useAddress';
import {useCountry} from '../../../hooks/useCountry';
import {isPolishTenant} from '../../../utils/isPolishTenant';

interface SelectLegalPersonProps extends TestIdProps {
  data: CompGetInfoV3ApiResponse;
  onSubmit: (legalPerson: CompGetInfoV3ApiResponse) => void;
}

export function SelectLegalPerson(props: SelectLegalPersonProps) {
  const {composeAddress, transformToCommonAddress} = useAddress();
  const [selectedCompanyIndex, setCompanyIndex] = useState<number>(0);

  const {getCountryName} = useCountry();

  const onSubmit = () => {
    closeCurrentDialog();
    if (props.data) {
      props.onSubmit([props.data[selectedCompanyIndex]]);
    }
  };

  return (
    <VStack spacing={4}>
      {props.data?.map((company, index) => (
        <Card
          key={company?.businessId}
          variant="inlineWhite"
          control={{
            type: 'radio',
            onChange: () => setCompanyIndex(index),
            value: equals(index, selectedCompanyIndex),
          }}
          data-testid={suffixTestId('selectLegalPerson', props)}
        >
          <Attributes
            size="third"
            rows={buildArray<AttributesRow>()
              .when(
                isPolishTenant(company?.country as CountryAlpha3CodeEnum) &&
                  isNotNil(company?.personSelfEmployee?.firstName),
                {
                  label: i18n.t('entity.person.labels.firstName'),
                  value: company?.personSelfEmployee?.firstName,
                }
              )
              .when(
                isPolishTenant(company?.country as CountryAlpha3CodeEnum) &&
                  isNotNil(company?.personSelfEmployee?.lastName),
                {
                  label: i18n.t('entity.person.labels.lastName'),
                  value: company?.personSelfEmployee?.lastName,
                }
              )
              .add({
                label: i18n.t('contractInformation.labels.tradeName'),
                value: company?.businessName,
              })
              .add({
                label: i18n.t('contractInformation.labels.registrationNumber'),
                value: company?.businessId,
              })
              .add({
                label: i18n.t('contractInformation.labels.vatNumber'),
                value: company?.taxId,
              })
              .add({
                label: i18n.t('contractInformation.labels.fileNumber'),
                value: company?.fileNumber,
              })
              .add({
                label: i18n.t('contractInformation.labels.countryOfRegistrationCode'),
                value: getCountryName(company?.country),
              })
              .add({
                label: i18n.t('contractInformation.labels.businessAddressId'),
                value: composeAddress(transformToCommonAddress(company)),
              })}
            data-testid={suffixTestId('selectLegalPerson', props)}
          />
        </Card>
      ))}
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.discard')}
          data-testid={suffixTestId('selectLegalPerson-discard', props)}
        />
        <Button
          variant="primary"
          title={i18n.t('general.actions.save')}
          onClick={onSubmit}
          data-testid={suffixTestId('selectLegalPerson-save', props)}
        />
      </ButtonGroup>
    </VStack>
  );
}
