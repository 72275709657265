import {FormControl, FormField} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemDiscountProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
}

export function RequestTabLabourBulkEditItemDiscount(
  props: RequestTabLabourBulkEditItemDiscountProps
) {
  const isDiscountDisabled = not(props.formApi.watch('isDiscountEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isDiscountEnabled"
            data-testid={suffixTestId('isDiscountEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isDiscountDisabled}
            control={props.control}
            type="number"
            name="discountRate"
            label={i18n.t('entity.warehouse.labels.manualDiscount')}
            suffix="%"
            decimalPlaces={2}
            minStepperValue={0}
            maxStepperValue={100}
            data-testid={suffixTestId('discountRate', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}
