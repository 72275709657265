import {isFeatureEnabled} from 'feature-flags';
import {Attributes, Card, DataStatus} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';

import {useGetVehicleV2Query} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';

import {composePath, parseDate} from 'shared';

type Props = {
  vehicleId: string;
  canEditVehicle: boolean;
  isSaleVehicle?: boolean;
};

export const TechnicalInspectionCard: FC<Props> = ({vehicleId, canEditVehicle, isSaleVehicle}) => {
  const navigate = useNavigate();
  const {data: vehicle, isLoading, isError} = useGetVehicleV2Query({vehicleId});

  const formatDateTime = useDateTimeFormatter();

  const handleOnClick = () => {
    if (!isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return navigate(
        composePath(vehiclesRoutes.editServiceVehicle, {
          params: {id: vehicleId},
          queryParams: {tab: 'vehicle-data'},
        })
      );
    }

    return navigate(
      composePath(vehiclesRoutes.editDetailTab, {params: {id: vehicleId, tab: 'vehicle-data'}})
    );
  };

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.technicalInspection')}
      actions={
        canEditVehicle
          ? [
              {
                variant: 'link',
                type: 'button',
                leftIcon: 'image/edit',
                onClick: handleOnClick,
                'data-testid': testIds.vehicles.vehicleData('technicalInspection-edit'),
                title: i18n.t('general.actions.edit'),
              },
            ]
          : undefined
      }
      data-testid={testIds.vehicles.vehicleData('technicalInspection')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Attributes
          data-testid={testIds.vehicles.vehicleData('technicalInspection-parameters')}
          rows={[
            {
              label: i18n.t('entity.vehicle.labels.inspectionValidUntil'),
              value:
                vehicle?.technicalInspectionValidUntil &&
                formatDateTime('dateShort', parseDate(vehicle?.technicalInspectionValidUntil)),

              testId: 'inspectionValidUntil',
            },
            {
              label: i18n.t('entity.vehicle.labels.acceptableTechnicalCondition'),
              value: isNil(vehicle?.isAcceptableForTechnicalInspection)
                ? null
                : vehicle?.isAcceptableForTechnicalInspection
                  ? i18n.t('general.labels.yes')
                  : i18n.t('general.labels.no'),
              testId: 'acceptableTechnicalCondition',
            },
            {
              label: i18n.t('entity.vehicle.labels.technicalInspectionNote'),
              value: vehicle?.technicalInspectionNote,
              testId: 'technicalInspectionNote',
            },
          ]}
        />
      </DataStatus>
    </Card>
  );
};
