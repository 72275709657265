import {
  GetServiceCaseActionsApiResponse,
  GetServiceCaseActionsApiArg,
  GetServiceCaseActionsApiResponseSchema,
  GetServiceCaseActionsApiArgSchema,
  PostServiceCaseActionCancelApiArg,
  PostServiceCaseActionCancelApiArgSchema,
  PostServiceCaseActionCancelResponseSchema,
  PostServiceCaseActionReOpenApiArg,
  PostServiceCaseActionReOpenApiArgSchema,
  PostServiceCaseActionReOpenResponseSchema,
} from '../types/metadaWorkshopServiceCaseActions';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceCaseActionsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceCaseActionCancel: build.mutation<void, PostServiceCaseActionCancelApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/action/cancel`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
        'serviceOrderMandatoryFields',
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
      ],
      extraOptions: {
        requestSchema: PostServiceCaseActionCancelApiArgSchema,
        responseSchema: PostServiceCaseActionCancelResponseSchema,
      },
    }),
    postServiceCaseActionReOpen: build.mutation<void, PostServiceCaseActionReOpenApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/action/re-open`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
        'serviceOrderMandatoryFields',
        'serviceOrders',
        'serviceOrder',
        'serviceOrderJobs',
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
      ],
      extraOptions: {
        requestSchema: PostServiceCaseActionReOpenApiArgSchema,
        responseSchema: PostServiceCaseActionReOpenResponseSchema,
      },
    }),
    getServiceCaseActions: build.query<
      GetServiceCaseActionsApiResponse,
      GetServiceCaseActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/actions`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: GetServiceCaseActionsApiResponseSchema,
        requestSchema: GetServiceCaseActionsApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostServiceCaseActionCancelMutation,
  usePostServiceCaseActionReOpenMutation,
  useGetServiceCaseActionsQuery,
} = metadaWorkshopServiceCaseActionsApi;
