import {Switch} from 'platform/components';
import {match} from 'ts-pattern';
import {DeepReadonly} from 'utility-types';

import {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {isNil} from 'ramda';

import {AdvertisementStatusEnum, FullVehicle} from '@dms/api';
import {
  useRouter,
  togglePlatformRequest,
  EPlatformUpdateTypes,
  AdvertisingPlatformCodeEnum,
} from '@dms/teas';

import {useBoolean} from 'shared';

type SwitcherProps = {
  code: `${AdvertisingPlatformCodeEnum}`;
  value?: boolean;
  currentStatus: AdvertisementStatusEnum;
  vehicleSaleState: FullVehicle['saleState'];
  inSale: boolean;
  isDisabled: boolean;
};

export const PlatformSwitcher = ({
  value: initialValue,
  code,
  currentStatus,
  vehicleSaleState,
  inSale,
  isDisabled,
}: DeepReadonly<SwitcherProps>): ReactElement => {
  const [value, setValue] = useState<boolean>(!!initialValue);
  const {params} = useRouter();
  const [isProcessing, startProcess, stopProcess] = useBoolean(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNil(initialValue)) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    match(currentStatus)
      .with(
        AdvertisementStatusEnum.PUBLISHING,
        AdvertisementStatusEnum.UPDATING,
        AdvertisementStatusEnum.UNPUBLISHING,
        startProcess
      )
      .otherwise(stopProcess);
  }, [currentStatus, startProcess, stopProcess, inSale, value, vehicleSaleState]);

  const updateEvent = useCallback(
    (option: EPlatformUpdateTypes) => {
      if (params && params.id) {
        // platformName identifier to catch loading state
        dispatch(
          togglePlatformRequest({
            vehicleId: String(params.id),
            platformCode: code,
            option,
          })
        );
      }
    },
    [dispatch, code, params]
  );

  const onChange = useCallback(
    (value: boolean) => {
      updateEvent(value ? EPlatformUpdateTypes.switchOff : EPlatformUpdateTypes.switchOn);
      setValue(value);
      startProcess();
    },
    [startProcess, updateEvent]
  );

  return (
    <>
      <Switch
        value={value}
        onChange={onChange}
        name={`advertisement-platform-switcher-${code}`}
        isDisabled={isProcessing || isDisabled}
        data-testid={`switch-advertisement-platforms-switcher-${code}`}
      />
    </>
  );
};
