import {User} from '@sentry/browser';
import {DataStatus, Tooltip} from 'platform/components';
import styled from 'styled-components';

import {FC, ReactElement, ReactNode} from 'react';

import {isNotNil} from 'ramda';

import {useGetUsersQuery, UserResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {ColumnType, DataType, Role, SimpleTable, SimpleTableProps, useRouter} from '@dms/teas';

import {composePath} from 'shared';

export const UsersTable = (): ReactElement => {
  const router = useRouter();
  const {data: users, isLoading} = useGetUsersQuery();

  /**
   * Set comma example => 'Role1', 'Role2'
   * 	from Array string
   * */
  const getComma = (userRoles: Role[], index: number) =>
    userRoles.length && userRoles.length - 1 > index ? ',' : '';

  const roles = (userRoles: Role[]) =>
    userRoles
      .map((role: Role, index: number) => `${role.title}${getComma(userRoles, index)} `)
      .join(' ');

  const columns: ColumnType<User>[] = [
    {
      Header: i18n.t('entity.user.labels.userName'),
      id: 'name',
    },
    {
      Header: i18n.t('page.settings.labels.userRole'),
      id: 'role',
    },
    {
      Header: i18n.t('general.labels.status'),
      id: 'status',
    },
    {
      Header: null,
      id: 'action',
    },
  ];

  const createRow = (user: UserResponseBody): DataType => ({
    id: user.id,
    blocked: user.blocked,
    name: `${user?.lastName} ${user?.firstName}`,
    // eslint-disable-next-line no-restricted-syntax
    role: role(user?.roles as unknown as Role[]),
    status: user?.blocked
      ? i18n.t('general.labels.blocked')
      : i18n.t('entity.settings.labels.active'),
  });

  /**
   * If user has Multiple roles have to show Tooltip with roles
   * */
  const role = (userRoles: Role[]): ReactNode => {
    if (userRoles.length <= 1) {
      return userRoles[0]?.title;
    }

    return (
      <Tooltip placement="top" label={roles(userRoles as Role[])}>
        {i18n.t('entity.warehouse.labels.multiple')}
      </Tooltip>
    );
  };

  return (
    <DataStatus isLoading={isLoading} minHeight={24}>
      <StyledTable<FC<SimpleTableProps<User>>>
        noZebra
        showRowDivider
        highlightRowOnHover
        tdPadding="default"
        data-testid={testIds.settings.userManagement('table')}
        columns={columns}
        rows={[{data: (users?.map(createRow) || []) as User[]}]}
        rowProps={(row: DataType) => ({
          className: row?.blocked ? 'disabled' : '',
        })}
        onRowClick={(row) => {
          if (isNotNil(row.id)) {
            router.push(
              composePath(settingsRoutes.userManagementDetail, {params: {id: row.id.toString()}})
            );
          }
        }}
      />
    </DataStatus>
  );
};

const StyledTable = styled(SimpleTable)`
  .simple-table-row {
    &.disabled {
      opacity: 0.5;
    }
  }
`;
