import {EmptyStatus, Button} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {TestIdProps} from 'shared';

interface EmptyScheduleProps extends TestIdProps {
  isLoading?: boolean;
  onOpenPlannerClick: () => void;
}

export function EmptySchedule(props: EmptyScheduleProps) {
  return (
    <VStack justify="center" align="center" spacing={4} height="100%">
      <EmptyStatus
        headline={i18n.t('entity.serviceScheduler.lables.noRepairSchedule')}
        subheadline={i18n.t('entity.serviceScheduler.lables.noRepairScheduleOpenPlanner')}
        data-testid={props['data-testid']}
      />
      <Button
        title={i18n.t('entity.serviceScheduler.actions.openPlanner')}
        onClick={props.onOpenPlannerClick}
        leftIcon="action/open_in_new"
        isLoading={props.isLoading}
        data-testid={props['data-testid']}
      />
    </VStack>
  );
}
