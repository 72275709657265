import {Attributes, Card, DataStatus} from 'platform/components';
import {Heading, VStack, Box} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil, isNotNil, mergeAll} from 'ramda';

import {useGetServiceCaseIntegrationsClaraVehicleDetailsQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {EMPTY_PLACEHOLDER, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface ClaraVehicleInfoViewProps extends RequiredTestIdProps {
  serviceCaseId: string;
}

export const ClaraVehicleInfoView = (props: ClaraVehicleInfoViewProps) => {
  const formatDate = useDateTimeFormatter();

  const {data, isError, isLoading} = useGetServiceCaseIntegrationsClaraVehicleDetailsQuery(
    {serviceCaseId: props.serviceCaseId},
    {skip: isNil(props.serviceCaseId)}
  );

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {vin: data?.vin, serviceCaseId: props.serviceCaseId}]);

  return (
    <DataStatus isError={isError} isLoading={isLoading}>
      <VStack spacing={4}>
        <Card variant="inlineGrey" title={i18n.t('entity.integration.labels.generalInformation')}>
          <Attributes
            size="third"
            data-testid={suffixTestId('attributes', props)}
            rows={[
              {
                label: i18n.t('entity.integration.labels.vin'),
                value: data?.vin ?? EMPTY_PLACEHOLDER,
              },
              {
                label: i18n.t('entity.integration.labels.fin'),
                value: data?.fin ?? EMPTY_PLACEHOLDER,
              },
              {
                label: i18n.t('entity.integration.labels.mileage'),
                value: `${data?.mileage ?? EMPTY_PLACEHOLDER} ${data?.mileageUnit}`,
              },
              {
                label: i18n.t('entity.integration.labels.warrantyRegistrationDate'),
                value: isNotNil(data?.warrantyRegistrationDate)
                  ? formatDate('dateShort', parseDate(data?.warrantyRegistrationDate))
                  : EMPTY_PLACEHOLDER,
              },
              {
                label: i18n.t('entity.integration.labels.serviceContract'),
                value: data?.hasActiveServiceContract
                  ? i18n.t('general.labels.yes')
                  : i18n.t('general.labels.no'),
              },
              {
                label: i18n.t('entity.integration.labels.serviceMeasures'),
                value: data?.hasActiveFieldMeasures
                  ? i18n.t('general.labels.yes')
                  : i18n.t('general.labels.no'),
              },
              {
                label: i18n.t('entity.integration.labels.externalVehicleInformation'),
                value: data?.externalVehicleInformation ?? EMPTY_PLACEHOLDER,
              },
            ]}
          />
        </Card>
        <Heading size={4} data-testid={suffixTestId('heading', props)}>
          {i18n.t('entity.integration.labels.warrantyCoverages')}
        </Heading>
        <Box height={100}>
          <DataGrid
            gridCode="warranty-coverage-daimler"
            queryModifier={queryModifier}
            data-testid={suffixTestId('dg', props)}
          />
        </Box>
      </VStack>
    </DataStatus>
  );
};
