import {Card, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {CSSProperties} from 'react';

import {isArray, isString} from 'ramda-adjunct';

import {useDeleteProductMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {AddOrEditProductCatalogItem} from './AddOrEditProductCatalogItem';

type DataGridCallbackRowDataType = {name: {value: string; style: CSSProperties}};

export function ProductCatalogGridCard(props: TestIdProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [deleteProduct] = useDeleteProductMutation();

  const openAddDialog = () =>
    openDialog(
      <AddOrEditProductCatalogItem
        onCancel={closeCurrentDialog}
        onSuccess={() => {
          refreshDataGrid();
          closeCurrentDialog();
        }}
        data-testid={suffixTestId('addDialog', props)}
      />,
      {title: i18n.t('entity.productCatalog.actions.addProduct'), scrollBehavior: 'outside'}
    );

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const productItem = (isArray(rowData) ? rowData[0] : rowData) as DataGridCallbackRowDataType;
    const id = isArray(rowId) ? rowId[0] : rowId;

    if (actionKey === 'edit') {
      openDialog(
        <AddOrEditProductCatalogItem
          productId={id}
          onCancel={closeCurrentDialog}
          onSuccess={() => {
            refreshData();
            closeCurrentDialog();
          }}
          data-testid={suffixTestId('editDialog', props)}
        />,
        {title: i18n.t('entity.productCatalog.actions.editProduct'), scrollBehavior: 'outside'}
      );
    }

    if (actionKey === 'delete') {
      openDeleteDialog({
        name: isString(productItem.name.value) ? productItem.name.value : undefined,
        onConfirm: async () => {
          await deleteProduct({id}).unwrap().then(refreshData).catch(handleApiError);
        },
      });
    }
  };

  return (
    <>
      <Card
        title={i18n.t('entity.productCatalog.labels.products')}
        actions={[
          {
            type: 'button',
            variant: 'link',
            onClick: openAddDialog,
            leftIcon: 'content/add_circle',
            title: i18n.t('entity.productCatalog.actions.addProduct'),
            'data-testid': suffixTestId('addButton', props),
          },
        ]}
      >
        <Box height={120} padding={4}>
          <DataGrid
            actionCallback={actionCallback}
            ref={dataGridRef}
            gridCode="product_catalog"
            emptyState={{
              action: {
                onClick: openAddDialog,
                title: i18n.t('entity.productCatalog.actions.addProduct'),
              },
            }}
            data-testid={suffixTestId('grid', props)}
          />
        </Box>
      </Card>
    </>
  );
}
