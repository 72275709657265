import {differenceInDays, startOfDay} from 'date-fns';
import {AppLanguage} from 'platform/locale';
import {match, Pattern} from 'ts-pattern';

import i18n from '@dms/i18n';

export function formatDateToNaturalLanguage(date: Date, locale: AppLanguage = 'en') {
  const normalizedDate = startOfDay(date);
  const today = startOfDay(new Date());

  const differenceDays = differenceInDays(normalizedDate, today);

  return match({differenceDays, locale})
    .returnType<string | null>()
    .with({differenceDays: 0}, () => i18n.t('general.labels.today'))
    .with({differenceDays: 1}, () => i18n.t('general.labels.tomorrow'))
    .with({locale: 'cs', differenceDays: Pattern.number.gt(1).and(Pattern.number.lt(5))}, () =>
      i18n.t('general.labels.inNDaysAlt', {n: differenceDays})
    )
    .with({differenceDays: Pattern.number.gt(1).and(Pattern.number.lt(365))}, ({differenceDays}) =>
      i18n.t('general.labels.inNDays', {n: differenceDays})
    )
    .with({differenceDays: -1}, () => i18n.t('general.labels.yesterday'))
    .with(
      {differenceDays: Pattern.number.lt(-1).and(Pattern.number.gt(-365))},
      ({differenceDays}) => i18n.t('general.labels.nDaysAgo', {n: Math.abs(differenceDays)})
    )
    .with({differenceDays: Pattern.number.lt(-365)}, () => i18n.t('general.labels.overAYearAgo'))
    .with({differenceDays: Pattern.number.gt(365)}, () => i18n.t('general.labels.overAYearFromNow'))
    .otherwise(() => null);
}
