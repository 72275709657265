import {Card, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback, useState} from 'react';

import {useDeleteSectorMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {DeleteDialog} from './DeleteDialog';

type SectorToDeleteType = null | {
  sectorId: string;
  refreshData: () => void;
};

export function ParkingDataGrid() {
  const navigate = useNavigate();
  const [sectorToDelete, setSectorToDelete] = useState<SectorToDeleteType>();
  const [deleteParkingSector] = useDeleteSectorMutation();

  const handleDeleteParkingSector = useCallback(() => {
    if (sectorToDelete) {
      deleteParkingSector({
        sectorId: sectorToDelete.sectorId,
      })
        .unwrap()
        .then(() => {
          sectorToDelete.refreshData();
          setSectorToDelete(null);
          showNotification.success(
            i18n.t('page.vehicleSettings.notifications.parkingSectorRemoved')
          );
        })
        .catch(handleApiError);
    }
  }, [sectorToDelete, deleteParkingSector]);

  const actionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, refreshData}) => {
      const sectorId = Array.isArray(rowId) ? rowId[0] : rowId;

      switch (actionKey) {
        case 'delete':
          setSectorToDelete({sectorId, refreshData});
          break;
        case 'edit':
        case 'detail':
          navigate(
            composePath(settingsRoutes.vehicleParkingEdit, {
              params: {
                id: sectorId,
              },
            })
          );
          break;
      }
    },
    [setSectorToDelete, navigate]
  );

  return (
    <>
      <DeleteDialog
        isOpen={!!sectorToDelete}
        onClose={() => setSectorToDelete(null)}
        onConfirm={handleDeleteParkingSector}
      />
      <Card
        actions={[
          {
            type: 'button',
            variant: 'link',
            onClick: () => navigate(settingsRoutes.vehicleParkingAdd),
            leftIcon: 'content/add',
            title: i18n.t('general.actions.add'),
          },
        ]}
        title={i18n.t('page.vehicleSettings.labels.parkingSectors')}
      >
        <Box height={68}>
          <DataGrid
            gridCode="parking-sector"
            actionCallback={actionCallback}
            emptyState={{
              headline: i18n.t('page.vehicleSettings.labels.emptyParkingSectors'),
            }}
          />
        </Box>
      </Card>
    </>
  );
}
