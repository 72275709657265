import {Checkbox, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isEmpty} from 'ramda';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useMakeModelFilter} from '../../../hooks/useMakeModelFilter';

export function MakeCheckboxList(props: TestIdProps) {
  const {makes, allSelectedMakes, selectMake, unselectMake, isLoading} = useMakeModelFilter();

  const handleToggleMake = (make: string, isSelected: boolean) => {
    isSelected ? selectMake(make) : unselectMake(make);
  };

  return (
    <DataStatus
      isLoading={isLoading}
      isEmpty={isEmpty(makes)}
      emptyMessage={i18n.t('page.filters.labels.noMakes')}
    >
      <VStack spacing={2}>
        {Object.values(makes ?? {})
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(({name, label}) => {
            const isSelected = allSelectedMakes.includes(name);

            return (
              <Checkbox
                data-testid={suffixTestId(`make-${name}`, props)}
                key={name}
                name={`make.${name}`}
                label={label}
                value={isSelected}
                onChange={(value) => handleToggleMake(name, value)}
              />
            );
          })}
      </VStack>
    </DataStatus>
  );
}
