import {
  Card,
  DataStatus,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {reverse} from 'ramda';

import {
  ContractInformationResponseBodyV2,
  useDeleteCustomerContractInformationMutation,
  useGetCustomerV2Query,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  ContractInformationForm,
  getContractSubTitle,
  getContractTitle,
  handleApiError,
  InfoCard,
  useCustomerContractAddress,
  useCustomerContractAttributes,
} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

interface ContractInformationCardProps extends TestIdProps {
  customerId: string;
}

export function ContractInformationCard(props: ContractInformationCardProps) {
  const [getAttributes] = useCustomerContractAttributes();
  const [getContractAddress] = useCustomerContractAddress();

  const {data, isLoading, isError} = useGetCustomerV2Query({customerId: props.customerId});
  const [deleteContract, {isLoading: isDeleteLoading}] =
    useDeleteCustomerContractInformationMutation();

  const onDelete = (contractInformationId: string) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteContract({
          customerId: props.customerId,
          contractInformationId,
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
    });

  return (
    <Card
      title={i18n.t('entity.contract.labels.contractInformation')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'content/add_circle',
          isDisabled: isLoading,
          title: i18n.t('general.actions.add'),
          onClick: () =>
            openDialog(
              <ContractInformationForm
                customerId={props.customerId}
                data-testid={suffixTestId('createContract', props)}
              />,
              {
                title: i18n.t('entity.customer.actions.newContractInformation'),
                withAdditionalFooter: true,
                'data-testid': suffixTestId('createContract', props),
              }
            ),
        },
      ]}
      data-testid={props['data-testid']}
    >
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        minHeight={23}
        data-testid={props['data-testid']}
      >
        <VStack spacing={4}>
          {reverse(data?.contractInformation ?? []).map(
            (contract: ContractInformationResponseBodyV2, index) => (
              <InfoCard
                key={contract.id}
                title={getContractTitle(contract)}
                subTitle={getContractSubTitle(contract)}
                description={[getContractAddress(contract)]}
                actions={[
                  {
                    type: 'iconButton',
                    severity: 'danger',
                    icon: 'action/delete',
                    isDisabled: isDeleteLoading,
                    onClick: () => onDelete(contract.id),
                  },
                  {
                    type: 'iconButton',
                    icon: 'image/edit',
                    onClick: () =>
                      openDialog(
                        <ContractInformationForm
                          customerId={props.customerId}
                          isEditContactInformation
                          contract={contract}
                          data-testid={suffixTestId('editContract', props)}
                        />,
                        {
                          title: i18n.t('entity.customer.actions.editBillingInformation'),
                          withAdditionalFooter: true,
                          'data-testid': suffixTestId('editContract', props),
                        }
                      ),
                  },
                ]}
                attributes={getAttributes(contract)}
                isOpenByDefault={index === 0}
                data-testid={suffixTestId(`contract-${index}`, props)}
              />
            )
          )}
        </VStack>
      </DataStatus>
    </Card>
  );
}
