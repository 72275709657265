import i18n from '@dms/i18n';

import {yupString} from 'shared';

export const yupPasswordValidation = yupString
  .required(i18n.t('general.notifications.errorPasswordsRequired'))
  .min(8, i18n.t('general.notifications.errorPasswordsLength'))
  .matches(/^(?:[A-Z]|[a-z]|[0-9]|.){8,}$/, {
    message: i18n.t('general.notifications.errorPasswordsFormat'),
  })
  .matches(/[A-Z]/, {
    message: i18n.t('general.notifications.errorPasswordsUppercase'),
  })
  .matches(/[a-z]/, {
    message: i18n.t('general.notifications.errorPasswordsLowercase'),
  })
  .matches(/[0-9]/, {
    message: i18n.t('general.notifications.errorPasswordsDigit'),
  });
