import {ForwardedRef, forwardRef, useEffect, useImperativeHandle} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';

import {ElementRenderer} from './components/ElementRenderer';
import {FieldValues} from './types/FieldValues';
import {FullSchema} from './types/FullSchema';
import {Schema} from './types/Schema';
import {rendererRegistry} from './utils/rendererRegistry';

type DynamicUiProps = {
  schema: Schema;
  isReadOnly?: boolean;
  defaultValues?: Record<string, unknown>;
  onChange?: (values: FieldValues) => void;
  onSubmit?: (values: FieldValues) => void;
};

export const DynamicUi = forwardRef(DynamicUiComponent);
export function DynamicUiComponent(props: DynamicUiProps, ref: ForwardedRef<unknown>) {
  const methods = useForm({mode: 'all', defaultValues: props.defaultValues});
  const values = useWatch({control: methods.control});

  const submit = async () => {
    const isFormValid = await methods.trigger();
    isFormValid && props.onSubmit?.(methods.getValues());
  };

  const reset = (values: FieldValues) => void methods.reset(values ?? {});

  useImperativeHandle(ref, () => ({submit, reset, getValues: methods.getValues}));

  useEffect(() => void props.onChange?.(values), [values]);

  return (
    <FormProvider {...methods}>
      <ElementRenderer
        rendererRegistry={rendererRegistry}
        schema={props.schema as FullSchema}
        isReadOnly={props.isReadOnly}
      />
    </FormProvider>
  );
}
