import {Grid} from 'platform/foundation';

import i18n from '@dms/i18n';

import {Nullish, TestIdProps} from 'shared';

import {ArticlePricingDetails} from '../types/ArticlePricingDetails';
import {PriceColumn} from './PriceColumn';

interface ArticleDetailCardPricesProps extends TestIdProps {
  prices: ArticlePricingDetails | null;
  currency: string | Nullish;
}

export function ArticleDetailCardPrices(props: ArticleDetailCardPricesProps) {
  return (
    <Grid columns={5}>
      <PriceColumn
        title={i18n.t('general.labels.averagePurchasePrice')}
        currency={props.currency}
        priceWithoutVat={props.prices?.averagePurchasePriceWithoutVat}
        priceWithVat={props.prices?.averagePurchasePriceWithVat}
      />
      <PriceColumn
        title={i18n.t('general.labels.lastPurchasePrice')}
        currency={props.currency}
        priceWithoutVat={props.prices?.lastPurchasePriceWithoutVat}
        priceWithVat={props.prices?.lastPurchasePriceWithVat}
      />
      <PriceColumn
        title={i18n.t('general.labels.recommendedPrice')}
        currency={props.currency}
        priceWithoutVat={props.prices?.recommendedPriceWithoutVat}
        priceWithVat={props.prices?.recommendedPriceWithVat}
      />
      <PriceColumn
        title={i18n.t('general.labels.salesBasePrice')}
        currency={props.currency}
        priceWithoutVat={props.prices?.saleBasePriceWithoutVat}
        priceWithVat={props.prices?.saleBasePriceWithVat}
      />
      <PriceColumn
        title={i18n.t('general.labels.warrantyPrice')}
        currency={props.currency}
        priceWithoutVat={props.prices?.warrantyPriceWithoutVat}
        priceWithVat={props.prices?.warrantyPriceWithVat}
      />
    </Grid>
  );
}
