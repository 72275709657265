import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {
  getAllAuditStructureTemplates,
  getAuditStructureForStructureSettings,
  getAuditStructureSettings,
  selectSettingBasicStructureByTemplate,
  selectStructureTemplate,
  useThunkDispatch,
} from '@dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {InspectionSettings} from './components/InspectionSettings';

export function VehicleInspectionsDetail() {
  const {id} = useParams();

  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const template = useSelector(selectStructureTemplate(id));
  const structure = useSelector(selectSettingBasicStructureByTemplate(id));

  useEffect(() => {
    if (!id) {
      return;
    }
    startLoading();
    dispatch(getAllAuditStructureTemplates.action({}))
      .then(() => {
        if (!template?.id) {
          return;
        }

        const promises: any[] = [
          dispatch(
            getAuditStructureSettings.action({
              typeOfControlId: id,
            })
          ),
        ];

        if (!structure) {
          promises.push(
            dispatch(
              getAuditStructureForStructureSettings.action({
                templateId: id,
                inspectionType: template?.inspectionType,
              })
            )
          );
        }

        return Promise.all(promises);
      })
      .finally(() => {
        stopLoading();
      });
  }, [dispatch, id, startLoading, stopLoading, structure, template?.id, template?.inspectionType]);

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.vehicleSettings.labels.inspectionsTitle'),
      href: settingsRoutes.vehicleInspections,
    },
  ];

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{title: i18n.t('page.vehicleSettings.labels.inspectionsTitle'), breadcrumbs}}
      data-testid="integrations-inspections-settings-modal"
      description={i18n.t('page.vehicleSettings.labels.inspectionDescription')}
    >
      <InspectionSettings />
    </SettingsTemplate>
  );
}
