import {DataStatus, Separator, Table, TableRow} from 'platform/components';
import {Align, Box, Grid, Heading, Text, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {flatten, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetBasicInformationServicePackageQuery,
  useLazyGetMakeModelWithMakeQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {DataPreview} from './DataPreview';

type Translations = {
  makes: string[] | undefined;
  models: string[] | undefined;
  modelFamilies: string[] | undefined;
};

interface ServicePackageDetailProps extends TestIdProps {
  servicePackageId: string;
}

export function ServicePackageDetail(props: ServicePackageDetailProps) {
  const {data, isError, isLoading} = useGetBasicInformationServicePackageQuery({
    servicePackageId: props.servicePackageId,
  });
  const [getApiMakeModel] = useLazyGetMakeModelWithMakeQuery();

  const [translations, setTranslations] = useState<Translations | null>(null);

  useEffect(() => {
    const vehicleType = data?.servicePackage?.vehicleType;
    const makes = data?.servicePackage?.vehicleMakes;
    const models = data?.servicePackage?.vehicleModels;
    const modelFamilies = data?.servicePackage?.vehicleModelFamilies;

    if (isNil(vehicleType) || isNil(makes)) {
      return;
    }

    Promise.all(
      makes.map((make) =>
        getApiMakeModel({vehicleType, make: make ?? undefined, lang: [i18n.language]})
      )
    ).then((data) => {
      const allModels = flatten(
        flatten(data.map(({data}) => data)).map((data) => data?.models ?? [])
      );

      const makeTranslations = flatten(data.map(({data}) => data)).map(
        (make) => make?.default_label ?? EMPTY_PLACEHOLDER
      );

      const modelTranslations = models?.map(
        (modelName) =>
          allModels.find(({model}) => model === modelName)?.labels?.[0]?.label ?? EMPTY_PLACEHOLDER
      );

      const modelFamiliesTranslations = modelFamilies?.map(
        (familyName) =>
          allModels.find((model) => model.model_group?.group === familyName)?.labels?.[0]?.label ??
          EMPTY_PLACEHOLDER
      );

      setTranslations({
        makes: makeTranslations,
        models: modelTranslations,
        modelFamilies: modelFamiliesTranslations,
      });
    });
  }, [data, getApiMakeModel]);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <Heading size={4}>{i18n.t('general.labels.basicInformation')}</Heading>
        <Grid columns={4}>
          <DataPreview
            value={data?.servicePackage?.name}
            label={i18n.t('general.labels.name')}
            data-testid={suffixTestId('name', props)}
          />
          <DataPreview
            value={data?.servicePackage?.number}
            label={i18n.t('general.labels.number')}
            data-testid={suffixTestId('number', props)}
          />
          <DataPreview
            value={data?.servicePackage?.servicePackageCategory?.name}
            label={i18n.t('general.labels.category')}
            data-testid={suffixTestId('category', props)}
          />
          <DataPreview
            value={data?.servicePackage?.competence?.label}
            label={i18n.t('entity.workshopServicePackages.labels.competence')}
            data-testid={suffixTestId('competence', props)}
          />
          <DataPreview
            value={data?.servicePackage?.estimatedTime?.toString()}
            label={i18n.t('entity.workshopServicePackages.labels.estimatedTime')}
            data-testid={suffixTestId('estimatedTime', props)}
          />
          <DataPreview
            value={translations?.makes?.join(', ')}
            label={i18n.t('entity.vehicle.labels.make')}
            data-testid={suffixTestId('make', props)}
          />
          <DataPreview
            value={translations?.modelFamilies?.join(', ')}
            label={i18n.t('entity.vehicle.labels.modelFamily')}
            data-testid={suffixTestId('modelFamily', props)}
          />
          <DataPreview
            value={translations?.models?.join(', ')}
            label={i18n.t('entity.vehicle.labels.model')}
            data-testid={suffixTestId('model', props)}
          />
        </Grid>
        <DataPreview
          value={data?.servicePackage?.desrciption}
          label={i18n.t('general.labels.description')}
          data-testid={suffixTestId('description', props)}
        />
        <Separator spacing={0} />
        <Heading size={4}>{i18n.t('entity.workshopServicePackages.labels.workItems')}</Heading>
        <DataStatus isEmpty={isNilOrEmpty(data?.servicePackage?.servicePackageItems)}>
          <Table
            data-testid={suffixTestId('table', props)}
            columns={[
              {
                element: (
                  <Box padding={4} backgroundColor="palettes.neutral.10.100">
                    <Align left>
                      <Heading size={5}>{i18n.t('general.labels.type')}</Heading>
                    </Align>
                  </Box>
                ),
              },
              {
                element: (
                  <Box padding={4} backgroundColor="palettes.neutral.10.100">
                    <Align left>
                      <Heading size={5}>{i18n.t('general.labels.number')}</Heading>
                    </Align>
                  </Box>
                ),
              },
              {
                width: '100%',
                element: (
                  <Box padding={4} backgroundColor="palettes.neutral.10.100">
                    <Align left>
                      <Heading size={5}>{i18n.t('general.labels.name')}</Heading>
                    </Align>
                  </Box>
                ),
              },
              {
                element: (
                  <Box padding={4} backgroundColor="palettes.neutral.10.100">
                    <Align right>
                      <Heading size={5}>{i18n.t('general.labels.amount')}</Heading>
                    </Align>
                  </Box>
                ),
              },
            ]}
          >
            {data?.servicePackage?.servicePackageItems?.map((item, index) => (
              <TableRow data-testid={suffixTestId(`tableRow-[${index}]`, props)} key={item?.id}>
                <Box paddingHorizontal={4} paddingVertical={2}>
                  <Text>{item?.type?.label ?? EMPTY_PLACEHOLDER}</Text>
                </Box>
                <Box paddingHorizontal={4} paddingVertical={2}>
                  <Text>{item?.number ?? EMPTY_PLACEHOLDER}</Text>
                </Box>
                <Box paddingHorizontal={4} paddingVertical={2}>
                  <Text>{item?.name ?? EMPTY_PLACEHOLDER}</Text>
                </Box>
                <Box paddingHorizontal={4} paddingVertical={2}>
                  <Text align="right" noWrap>
                    {`${item?.quantity ?? EMPTY_PLACEHOLDER} ${item?.unit ?? ''}`}
                  </Text>
                </Box>
              </TableRow>
            ))}
          </Table>
        </DataStatus>
      </VStack>
    </DataStatus>
  );
}
