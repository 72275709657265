import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Show, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {
  BusinessCaseResponseBody,
  SaleVehicleResponseBody,
  useEndBusinessCaseMutation,
  useEndPurchaseBrokerageBusinessCaseMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {businessCaseRoutes, vehiclesRoutes} from '@dms/routes';
import {handleApiError, useCodeList} from '@dms/shared';

import {TestIdProps, composePath, suffixTestId, useNavigate, yupString} from 'shared';

export type CloseDialogModalType = TestIdProps & {
  saleVehicle?: SaleVehicleResponseBody;
  businessCase: BusinessCaseResponseBody | undefined;
};

type FormValues = {
  reasonCloseCodeId: string;
  reasonCloseNote: string;
};

export function EndBusinessCaseForm(props: CloseDialogModalType) {
  const navigate = useNavigate();
  const [selectReasonClose] = useCodeList('unsuccessful_business_case_reason');
  const [endPurchaseBrokerageBusinessCase] = useEndPurchaseBrokerageBusinessCaseMutation();
  const [endBusinessCase, {isLoading}] = useEndBusinessCaseMutation();

  const reasonOptions = selectReasonClose?.map((item) => ({
    label: item.name,
    value: item.codeId,
  }));

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    if (isNil(props.businessCase)) {
      return;
    }

    const isBrokerageCancellation =
      props.businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE';

    const isOfferState = props.businessCase?.businessCaseState === 'OFFER';

    const submitAction =
      isBrokerageCancellation && isFalse(isOfferState)
        ? endPurchaseBrokerageBusinessCase
        : endBusinessCase;

    await submitAction({
      businessCaseId: props.businessCase.id,
      closeBusinessCaseRequestBody: data,
    })
      .unwrap()
      .then(() =>
        navigate(composePath(businessCaseRoutes.overview, {params: {id: props.businessCase?.id}}))
      )
      .catch(handleApiError);
  };

  return (
    <Form<FormValues> onSubmit={handleSubmit} schema={schema}>
      {(control) => (
        <VStack spacing={4}>
          <Show when={props.saleVehicle?.activeReservation?.paidAdvanceDeposit}>
            <Alert
              message={i18n.t('entity.vehicle.labels.reservationWithAdvanceDepositExists')}
              hyperlinks={[
                {
                  title: i18n.t('entity.vehicle.labels.reservations'),
                  href: props.saleVehicle?.vehicleId
                    ? composePath(vehiclesRoutes.reservations, {
                        params: {id: props.saleVehicle?.vehicleId},
                      })
                    : undefined,
                },
              ]}
              data-testid={suffixTestId('endBusinessCase-alert', props)}
            />
            <Space vertical={4} />
          </Show>
          <FormField
            type="choice"
            control={control}
            name="reasonCloseCodeId"
            label={i18n.t('general.labels.reason')}
            isRequired
            isDisabled={isLoading}
            options={reasonOptions}
            data-testid={suffixTestId('endBusinessCase-reasonCloseCodeId', props)}
          />
          <FormField
            control={control}
            type="textarea"
            name="reasonCloseNote"
            minRows={5}
            label={i18n.t('general.labels.note')}
            data-testid={suffixTestId('endBusinessCase-reasonCloseNote', props)}
          />
          <ButtonGroup align="right" data-testid={suffixTestId('closeDialog-actions', props)}>
            <Button
              onClick={closeCurrentDialog}
              variant="secondary"
              data-testid={suffixTestId('closeDialog-closeAction', props)}
              title={i18n.t('general.actions.cancel')}
            />
            <FormButton
              control={control}
              type="submit"
              data-testid={suffixTestId('closeDialog-submitAction', props)}
              title={i18n.t('general.actions.endCase')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  reasonCloseCodeId: yupString.required(),
  reasonCloseNote: yupString,
});
