import {
  Button,
  ButtonGroup,
  Checkbox,
  DataStatus,
  DialogFooter,
  Table,
  TableRow,
} from 'platform/components';
import {Box, Center, Heading, Text} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import {usePutEmployeeCompetenceLinkMutation, useGetIntegrationCompetencesQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface PairingWithPlannerProps extends RequiredTestIdProps {
  competenceId: string;
  onClose: () => void;
}

export function PairingWithPlanner(props: PairingWithPlannerProps) {
  const [selectedId, setSelectedId] = useState<string | undefined>();

  const {data, isLoading, isError} = useGetIntegrationCompetencesQuery();

  const [putEmployeeCompetenceLink, {isLoading: isPutEmployeeCompetenceLinkLoading}] =
    usePutEmployeeCompetenceLinkMutation();

  const handleConfirm = () => {
    if (isNil(selectedId)) {
      return;
    }

    putEmployeeCompetenceLink({
      contextTarget: 'PLANNER',
      competenceId: props.competenceId,
      contextId: selectedId,
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Table
        columns={[
          {
            element: (
              <Box
                backgroundColor="palettes.neutral.10.100"
                height={13}
                borderTop="1px solid"
                borderColor="general.separator"
              />
            ),
            width: 14,
          },
          {
            element: (
              <Box
                backgroundColor="palettes.neutral.10.100"
                height={13}
                paddingHorizontal={4}
                borderTop="1px solid"
                borderColor="general.separator"
              >
                <Center height="100%" justify="flex-start">
                  <Heading size={5} color="secondary">
                    {i18n.t('general.labels.name')}
                  </Heading>
                </Center>
              </Box>
            ),
          },
        ]}
      >
        {data?.map((plannerValue, index) => (
          <TableRow key={plannerValue.id} onClick={() => setSelectedId(plannerValue.id)}>
            <Box paddingHorizontal={4} height={13}>
              <Center height="100%" justify="flex-start">
                <Checkbox
                  value={plannerValue.id === selectedId}
                  onChange={() => setSelectedId(plannerValue.id)}
                  data-testid={suffixTestId(`planner-${index}`, props)}
                />
              </Center>
            </Box>
            <Box paddingHorizontal={4} height={13}>
              <Center height="100%" justify="flex-start">
                <Text
                  size="small"
                  alternative
                  data-testid={suffixTestId(`planner-${index}`, props)}
                >
                  {plannerValue.label}
                </Text>
              </Center>
            </Box>
          </TableRow>
        ))}
      </Table>
      <DialogFooter>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.discard')}
            onClick={props.onClose}
            variant="secondary"
            data-testid={suffixTestId('discard', props)}
          />
          <Button
            title={i18n.t('general.actions.confirm')}
            isDisabled={isNil(selectedId) || isPutEmployeeCompetenceLinkLoading}
            isLoading={isPutEmployeeCompetenceLinkLoading}
            onClick={handleConfirm}
            data-testid={suffixTestId('confirm', props)}
          />
        </ButtonGroup>
      </DialogFooter>
    </DataStatus>
  );
}
