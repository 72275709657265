import {Flag, FormControl, FormField} from 'platform/components';
import {Box, Grid, GridItem, HStack, Icon} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Path} from 'react-hook-form';

import {always} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';
import {ReceiveNoteCorrectionItemFormContext} from '../types/ReceiveNoteCorrectionItemFormContext';

interface ReceiveNoteCorrectionItemAfterRowProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteCorrectionItemForm>;
  formContext: ReceiveNoteCorrectionItemFormContext;
  unit: string;
  currency: string | Nullish;
  onQuantityChange: (
    value: number | null,
    changedField: Path<ReceiveNoteCorrectionItemForm>
  ) => boolean;
}

export function ReceiveNoteCorrectionItemAfterRow(props: ReceiveNoteCorrectionItemAfterRowProps) {
  const quantity = match(props.formContext)
    .with('receiveItem', always('receiveAfterQuantity'))
    .with('deliveryItem', always('deliveryAfterQuantity'))
    .exhaustive();

  const unitPrice = match(props.formContext)
    .with('receiveItem', always('receiveAfterUnitPrice'))
    .with('deliveryItem', always('deliveryAfterUnitPrice'))
    .exhaustive();

  const totalPrice = match(props.formContext)
    .with('receiveItem', always('receiveAfterStockValue'))
    .with('deliveryItem', always('deliveryAfterStockValue'))
    .exhaustive();

  return (
    <Grid columns={4} spacing={4} data-testid={props['data-testid']}>
      <GridItem span={1}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.formContext}.${quantity}` as Path<ReceiveNoteCorrectionItemForm>}
          suffix={props.unit}
          onChange={(value) =>
            props.onQuantityChange(
              value,
              `${props.formContext}.${quantity}` as Path<ReceiveNoteCorrectionItemForm>
            )
          }
          data-testid={suffixTestId('inputs.afterQuantity', props)}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.formContext}.${unitPrice}` as Path<ReceiveNoteCorrectionItemForm>}
          suffix={props.currency}
          isDisabled
          data-testid={suffixTestId('inputs.afterUnitPrice', props)}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.formContext}.${totalPrice}` as Path<ReceiveNoteCorrectionItemForm>}
          suffix={props.currency}
          isDisabled
          data-testid={suffixTestId('inputs.afterStockValue', props)}
        />
      </GridItem>
      <GridItem span={1}>
        <HStack spacing={2} align="center">
          <Icon value="navigation/chevron_left" color="palettes.neutral.800" size={4} />
          <Box width="100%">
            <Flag
              label={i18n.t('entity.warehouse.labels.afterCorrection')}
              colorScheme="black"
              size="large"
              isSubtle
              isFullWidth
              data-testid={suffixTestId('rowFlag', props)}
            />
          </Box>
        </HStack>
      </GridItem>
    </Grid>
  );
}
