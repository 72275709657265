import {Card, closeDialog, DataStatus, openDialog} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {useGetPostponeJobsQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {queryParams, PostponedJob, PostponeJobType} from '@dms/shared';

import {useQueryState} from 'shared';

import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {AddPostponedRequest} from './components/AddPostponedRequest';

export function Postponed() {
  const {serviceCaseId} = useWorkshopUrl();
  const {data, isLoading, isError} = useGetPostponeJobsQuery({serviceCaseId});
  const [jobId, setJobId, clearJobId] = useQueryState(queryParams.SERVICE_CASE_JOB_ID);

  const handleJobExpand = (id?: string | null) => {
    if (isNil(id)) {
      return;
    }
    if (jobId === id) {
      clearJobId();
    }
    if (jobId !== id) {
      setJobId(id);
    }
  };

  const handleAddJob = (postponedJob: PostponeJobType) => {
    openDialog(
      <AddPostponedRequest
        data-testid={testIds.workshop.serviceCaseDetail('addPostponedJobDialog')}
        serviceCaseId={serviceCaseId}
        postponeJobId={postponedJob.postponeJobId}
        onClose={() => closeDialog('addJobDialog')}
      />,
      {
        id: 'addJobDialog',
        title: `${i18n.t('entity.orderRequest.actions.addPostponedRequest')} (${
          postponedJob.name
        })`,
      }
    );
  };

  return (
    <Box flex={1} padding={4}>
      <Card title={i18n.t('entity.serviceCase.labels.postponed')}>
        <DataStatus
          isEmpty={data?.count === 0}
          isLoading={isLoading}
          isError={isError}
          emptyMessage={i18n.t('entity.orderRequest.labels.emptyPostponedRequests')}
        >
          <VStack spacing={4}>
            {data?.postponeJob?.map(
              (job, index) =>
                job &&
                job.postponeJobId && (
                  <PostponedJob
                    serviceCaseId={serviceCaseId}
                    isExpanded={job.postponeJobId === jobId}
                    onExpand={() => handleJobExpand(job.postponeJobId)}
                    onAddJob={() => handleAddJob(job)}
                    key={job.postponeJobId}
                    postponeJobData={job}
                    data-testid={testIds.workshop.serviceCaseDetail(`postponed-[${index}]`)}
                  />
                )
            )}
          </VStack>
        </DataStatus>
      </Card>
    </Box>
  );
}
