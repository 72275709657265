import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {FullVehicle} from '@dms/api';

import {CarFeatures} from '../../types/CarFeatures';
import {Highlight} from '../../types/Highlight';
import {Shortcoming} from '../../types/Shortcoming';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {toggleListItem} from '../../utils/someTeasUtils';
import {
  addCarDetailsHighlightsRequest,
  addCarDetailsShortcomingsRequest,
  deleteCarDetailsHighlight,
  deleteCarDetailsShortcoming,
  loadCarDetailsHighlightsRequest,
  loadCarDetailsShortcomingsRequest,
  loadCarDetailsVehicleDetailRequest,
  loadPremiumFeatures,
  removeCarDetailsFeatureHighlighted,
  setCarDetailsFeatureHighlighted,
  setVehiclePhotos,
  updateVehicleBranch,
} from './actions';
import {NAME} from './constants';

type CarDetailsReducerState = AsyncThunkState<{
  vehicleDetail: CarDetailsGeneralType<FullVehicle>;
  vehicleShortcomings: CarDetailsGeneralType<Shortcoming[]>;
  vehicleHighlights: CarDetailsGeneralType<Highlight[]>;
  premiumFeatures?: string[];
  featureScore?: number | null;
  photoSelectionActiveAlbum?: string | null;
  vehicleSelectedPhotos: string[];
}>;

type CarDetailsGeneralType<T> = {
  isLoading: boolean;
  error?: boolean;
  data?: T;
};

const initialState: CarDetailsReducerState = {
  vehicleDetail: {
    isLoading: true,
    data: undefined,
  },
  vehicleShortcomings: {
    isLoading: true,
    data: undefined,
  },
  vehicleHighlights: {
    isLoading: true,
    data: undefined,
  },
  premiumFeatures: [],
  featureScore: null,
  photoSelectionActiveAlbum: undefined,
  vehicleSelectedPhotos: [],
  errors: {},
  loading: {},
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    loadCarVehicleDetail: (state, {payload}) => {
      state.vehicleDetail.data = payload;
      state.vehicleDetail.isLoading = false;
    },
    toggleImageSelection: (state, {payload}) => {
      state.vehicleSelectedPhotos = toggleListItem(payload as string[])(
        state.vehicleSelectedPhotos
      );
    },
    toggleMultiSelectAlbum: (
      state,
      {payload}: PayloadAction<{id: string | null; reset?: boolean}>
    ) => {
      const {id, reset} = payload;
      let _photos = state.vehicleDetail?.data?.photos?.slice() || [];

      if (reset) {
        _photos =
          state.vehicleDetail.data?.photos?.map((photo) => ({
            ...photo,
            isSelected: false,
          })) || [];
        state.vehicleSelectedPhotos = [];
      }

      if (state.vehicleDetail.data) {
        state.vehicleDetail.data.photos = _photos;
      }

      state.photoSelectionActiveAlbum = id === state.photoSelectionActiveAlbum ? undefined : id;
    },
  },
  extraReducers: (builder) => {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    builder
      .addCase(setVehiclePhotos, (state, {payload}) => {
        if (state.vehicleDetail.data) {
          state.vehicleDetail.data.photos = payload;
        }
      })
      .addCase(deleteCarDetailsHighlight, (state, {payload: {id}}) => {
        const {data: _highlights} = state.vehicleHighlights;
        const foundIndex = _highlights?.findIndex((h) => h.id === id);

        if (typeof foundIndex === 'number') {
          _highlights?.splice(foundIndex, 1);
        }

        state.vehicleHighlights.data = _highlights;
      })
      .addCase(deleteCarDetailsShortcoming, (state, {payload: {id}}) => {
        const {data: _shortcomings} = state.vehicleShortcomings;
        const foundIndex = _shortcomings?.findIndex((h) => h.id === id);

        if (typeof foundIndex === 'number') {
          _shortcomings?.splice(foundIndex, 1);
        }

        state.vehicleShortcomings.data = _shortcomings;
      })
      .addCase(setCarDetailsFeatureHighlighted, (state, {payload: {key}}) => {
        const {features} = state.vehicleDetail.data || {};
        let newFeatures = features?.slice();
        const foundFeatIndex = newFeatures?.findIndex((f) => f.key === key);

        if (typeof foundFeatIndex === 'number') {
          const highlightedFeat = {
            ...features?.[foundFeatIndex],
            id: features?.[foundFeatIndex].id as string,
            translation: features?.[foundFeatIndex].translation as string,
            key: features?.[foundFeatIndex].key as string,
            isHighlighted: true,
          };

          // feature needs to be added/moved to the end of the array
          newFeatures = features?.filter((f) => f.key !== key).concat([highlightedFeat]);
        }

        if (state.vehicleDetail.data) {
          state.vehicleDetail.data.features = newFeatures;
        }
      })
      .addCase(removeCarDetailsFeatureHighlighted, (state, {payload: {key}}) => {
        const _features = state.vehicleDetail.data?.features?.slice() || [];
        const foundFeat = _features?.findIndex((f) => f.key === key);

        if (typeof foundFeat === 'number') {
          _features[foundFeat] = {
            ..._features[foundFeat],
            isHighlighted: false,
          };
        }

        if (state.vehicleDetail.data) {
          state.vehicleDetail.data.features = _features;
        }
      })
      .addCase(loadCarDetailsVehicleDetailRequest.pending, (state) => {
        state.vehicleDetail.isLoading = true;
      })
      .addCase(loadCarDetailsVehicleDetailRequest.fulfilled, (state, {payload}) => {
        state.vehicleDetail.error = false;
        state.vehicleDetail.isLoading = false;

        if (payload) {
          state.vehicleDetail.data = payload;
        }
      })
      .addCase(loadCarDetailsVehicleDetailRequest.rejected, (state) => {
        state.vehicleDetail.error = true;
        state.vehicleDetail.isLoading = false;
      })
      .addCase(loadCarDetailsShortcomingsRequest.pending, (state) => {
        state.vehicleShortcomings.error = false;
        state.vehicleShortcomings.isLoading = true;
      })
      .addCase(loadCarDetailsShortcomingsRequest.fulfilled, (state, {payload}) => {
        state.vehicleShortcomings.error = false;
        state.vehicleShortcomings.isLoading = false;
        state.vehicleShortcomings.data = payload;
      })
      .addCase(loadCarDetailsShortcomingsRequest.rejected, (state) => {
        state.vehicleShortcomings.error = true;
        state.vehicleShortcomings.isLoading = false;
      })
      .addCase(loadCarDetailsHighlightsRequest.pending, (state) => {
        state.vehicleHighlights.error = false;
        state.vehicleHighlights.isLoading = true;
      })
      .addCase(loadCarDetailsHighlightsRequest.fulfilled, (state, {payload}) => {
        state.vehicleHighlights.error = false;
        state.vehicleHighlights.isLoading = false;
        state.vehicleHighlights.data = payload;
      })
      .addCase(loadCarDetailsHighlightsRequest.rejected, (state) => {
        state.vehicleHighlights.error = true;
        state.vehicleHighlights.isLoading = false;
      })
      .addCase(addCarDetailsShortcomingsRequest.pending, (state) => {
        state.vehicleShortcomings.error = false;
        state.vehicleShortcomings.isLoading = true;
      })
      .addCase(addCarDetailsShortcomingsRequest.fulfilled, (state, {payload}) => {
        state.vehicleShortcomings.error = false;
        state.vehicleShortcomings.isLoading = false;
        state.vehicleShortcomings.data = (state.vehicleShortcomings.data || []).concat(payload);
      })
      .addCase(addCarDetailsShortcomingsRequest.rejected, (state) => {
        state.vehicleShortcomings.error = true;
        state.vehicleShortcomings.isLoading = false;
      })
      .addCase(addCarDetailsHighlightsRequest.pending, (state) => {
        state.vehicleHighlights.error = false;
        state.vehicleHighlights.isLoading = true;
      })
      .addCase(addCarDetailsHighlightsRequest.fulfilled, (state, {payload}) => {
        state.vehicleHighlights.error = false;
        state.vehicleHighlights.isLoading = false;
        state.vehicleHighlights.data = (state.vehicleHighlights.data || []).concat(payload);
      })
      .addCase(addCarDetailsHighlightsRequest.rejected, (state) => {
        state.vehicleHighlights.error = true;
        state.vehicleHighlights.isLoading = false;
      })
      .addCase(loadPremiumFeatures.fulfilled, (state, {payload}: {payload: CarFeatures}) => {
        state.featureScore = payload?.result?.featuresScore ?? null;
        if (payload?.result?.premiumFeatures?.length) {
          state.premiumFeatures = payload?.result?.premiumFeatures ?? [];
        }
      })
      .addCase(loadPremiumFeatures.rejected, (state) => {
        state.featureScore = null;
      });
    asyncThunkReducer(updateVehicleBranch.action, (state, {meta}) => {
      if (state.vehicleDetail.data && meta.arg.requestBody) {
        state.vehicleDetail.data.branchId = meta.arg.requestBody.branchId;
      }
    });
    handleThunkStates();
  },
});

const {
  reducer,
  actions: {loadCarVehicleDetail, toggleImageSelection, toggleMultiSelectAlbum},
} = slice;

export {loadCarVehicleDetail, toggleImageSelection, toggleMultiSelectAlbum};

export const carDetailsReducer = reducer;
