import {FormControl, FormField} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';

import {CurrencyCodeType, RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemSellingPriceProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
  currency: CurrencyCodeType;
}

export function RequestTabLabourBulkEditItemSellingPrice(
  props: RequestTabLabourBulkEditItemSellingPriceProps
) {
  const isSellingPriceDisabled = not(props.formApi.watch('isSellingPricePerUnitEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isSellingPricePerUnitEnabled"
            data-testid={suffixTestId('isSellingPricePerUnitEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isSellingPriceDisabled}
            control={props.control}
            type="currency"
            name="sellingPricePerUnit"
            label={i18n.t('entity.warehouse.labels.sellingPrice(hourlyRates)')}
            currency={props.currency}
            data-testid={suffixTestId('sellingPricePerUnit', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}
