import {Button, Card, DataStatus, Separator, Switch} from 'platform/components';
import {Grid, HStack, Heading, Space} from 'platform/foundation';

import {useGetCustomerContractBasicInformationQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {SummaryItem} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

interface ContractCardProps extends TestIdProps {
  customerContractId: string;
  isDefault: boolean;
  onSetDefault?: VoidFunction;
  onDetail?: VoidFunction;
}

export function ContractCard(props: ContractCardProps) {
  const {data, isLoading, isError} = useGetCustomerContractBasicInformationQuery({
    customerContractId: props.customerContractId,
  });

  return (
    <>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={43}>
        <Card variant="inlineGrey">
          <HStack justify="space-between">
            <Heading size={4}>{data?.name}</Heading>
            <Switch
              label={i18n.t('general.actions.setAsDefault')}
              value={props.isDefault}
              onChange={props.onSetDefault}
              data-testid={suffixTestId('setAsDefault', props)}
            />
          </HStack>
          <Separator />
          <Grid columns={4}>
            <SummaryItem
              isAlternative
              title={i18n.t('general.labels.name')}
              value={data?.name}
              data-testid={suffixTestId('name', props)}
            />
            <SummaryItem
              isAlternative
              title={i18n.t('general.labels.description')}
              value={data?.description}
              data-testid={suffixTestId('description', props)}
            />
            <SummaryItem
              isAlternative
              title={i18n.t('entity.order.labels.customerGroupForMaterialDelivery')}
              value={data?.materialDiscountGroup?.name}
              data-testid={suffixTestId('customerGroup', props)}
            />
          </Grid>
          <Space vertical={4} />
          <Button
            variant="link"
            title={i18n.t('general.actions.viewDetail')}
            size="small"
            onClick={props.onDetail}
            data-testid={suffixTestId('detail', props)}
          />
        </Card>
      </DataStatus>
    </>
  );
}
