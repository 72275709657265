import {Heading, Text, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {VehicleWarehouseInfoCard} from './VehicleWarehouseInfoCard';

interface VehicleWarehousePreviousEntitiesProps {
  vehicleId: string;
  saleVehicleIds: string[];
}

export function VehicleWarehousePreviousEntities(props: VehicleWarehousePreviousEntitiesProps) {
  return (
    <VStack spacing={4}>
      <Heading size={3}>{i18n.t('entity.vehicle.labels.previousVehicleWarehouseEntities')}</Heading>
      <Text size="xSmall" color="secondary">
        {i18n.t('entity.vehicle.labels.previousVehicleWarehouseEntitiesDescription')}
      </Text>

      <VStack spacing={4}>
        {props.saleVehicleIds.map((saleVehicleId) => (
          <>
            <VehicleWarehouseInfoCard
              key={saleVehicleId}
              vehicleId={props.vehicleId}
              saleVehicleId={saleVehicleId}
            />
          </>
        ))}
      </VStack>
    </VStack>
  );
}
