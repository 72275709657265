import TagManager from 'react-gtm-module';

import {isNilOrEmpty} from 'ramda-adjunct';

import {environment} from '@dms/environment';

import {isTestEnvironment} from '../../utils/isTestEnvironment';

const tagManagerArgs = {
  gtmId: environment.GTM_CONTAINER_ID,
};

export const gtmInit = () => {
  if (isNilOrEmpty(tagManagerArgs.gtmId) || isTestEnvironment) {
    return;
  }

  TagManager.initialize(tagManagerArgs);
};
