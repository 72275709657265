import {Attributes, DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetMaterialDiscountGroupDiscountsQuery} from '@dms/api';

import {Nullish, TestIdProps} from 'shared';

import {useDiscountAttributes} from './hooks/useDiscountAttributes';

interface DiscountAttributesProps extends TestIdProps {
  groupId: string | Nullish;
}

export function DiscountAttributes(props: DiscountAttributesProps) {
  const {data, isFetching} = useGetMaterialDiscountGroupDiscountsQuery(
    {groupId: props.groupId ?? ''},
    {skip: isNil(props.groupId)}
  );

  const discountAttributes = useDiscountAttributes(data);

  return (
    <Show when={isNotNil(props.groupId)}>
      <DataStatus isLoading={isFetching}>
        <Attributes size="quarter" rows={discountAttributes} data-testid={props['data-testid']} />
      </DataStatus>
    </Show>
  );
}
