import {environment} from '@dms/environment';
import i18n from '@dms/i18n';

export const API_URL = environment.API_URL;
export const AG_GRID_LICENSE_KEY = environment.AG_GRID_LICENSE_KEY;

export const ENV_TYPE = environment.ENV_TYPE;

// error
export const DEFAULT_ERROR_MESSAGE = i18n.t('general.notifications.defaultErrorMessage');

export enum TRANSLATED_LANGUAGES {
  czech = 'cs-CZ',
  english = 'en-GB',
  slovak = 'sk-SK',
  france = 'fr-FR',
  poland = 'pl-PL',
  germany = 'de-DE',
  spain = 'es-ES',
  italy = 'it-IT',
}

export const DIGITAL_CERTIFICATE_TRANSLATE_KEYS_MAP: Record<TRANSLATED_LANGUAGES, string> = {
  [TRANSLATED_LANGUAGES.czech]: 'cs',
  [TRANSLATED_LANGUAGES.english]: 'en',
  [TRANSLATED_LANGUAGES.germany]: 'de',
  [TRANSLATED_LANGUAGES.poland]: 'pl',
  [TRANSLATED_LANGUAGES.slovak]: 'sk',
  [TRANSLATED_LANGUAGES.spain]: 'es',
  [TRANSLATED_LANGUAGES.italy]: 'it',
  [TRANSLATED_LANGUAGES.france]: 'fr',
};

export const DEFAULT_LANG = TRANSLATED_LANGUAGES.english;

export const CURRENCY_CZK = 'CZK';
export const CURRENCY_HUF = 'HUF';

export const DEFAULT_CURRENCY = CURRENCY_CZK;

export const COUNTRY_CZE = 'CZE';

export const DEFAULT_COUNTRY = COUNTRY_CZE;

// COOKIES
export const PREV_URL_COOKIE = 'PREV_URL';

// ADMIN
export const ADMINISTRATOR_ROLE = 'MANAGER';
export const CUSTOM_ROLE = 'Custom';
