import {match} from 'ts-pattern';

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {sourcingRoutes} from '@dms/routes';
import {keyExtractor, SourcingTableDispatch} from '@dms/teas';

import {composePath, Nullish} from 'shared';

import {Row} from '../types/Row';
import {createRowElementId} from '../utils/createRowElementId';

export function useHandleKeyboards(
  selectedRow: Row | Nullish,
  data: Row[],
  dispatch: SourcingTableDispatch
) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const currentIndex = data.findIndex(
        (row) => isNotNil(selectedRow) && keyExtractor(row) === keyExtractor(selectedRow)
      );

      match(e.key)
        .with('ArrowDown', () => {
          const row = data[currentIndex + 1] ?? selectedRow;

          dispatch({type: 'selectRow', row});
          window.document
            .getElementById(createRowElementId(row))
            ?.scrollIntoView({block: 'nearest'});
        })
        .with('ArrowUp', () => {
          const row = data[currentIndex - 1] ?? selectedRow;
          dispatch({type: 'selectRow', row});
          window.document
            .getElementById(createRowElementId(row))
            ?.scrollIntoView({block: 'nearest'});
        })
        .with('ArrowRight', () =>
          dispatch({
            type: 'expandRow',
            row: selectedRow,
            isExpanded: true,
          })
        )
        .with('ArrowLeft', () =>
          dispatch({
            type: 'expandRow',
            row: selectedRow,
            isExpanded: false,
          })
        )
        .with('Enter', () => {
          navigate(
            composePath(sourcingRoutes.vehicleDetail, {
              params: {
                adId: selectedRow?.sourcingVehicle.adId,
              },
            })
          );
        });
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [data, selectedRow, dispatch]);
}
