import {isFeatureEnabled} from 'feature-flags';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {EntityResourceIds} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';

export const isEntityFeatureFlagEnabled = (resourceId: EntityResourceIds) =>
  match(resourceId)
    .with(EntityResourceIds.vehicle, () => isFeatureEnabled(featureFlags.VEHICLE_CUSTOM_FIELDS))
    .with(EntityResourceIds.interest, () => isFeatureEnabled(featureFlags.INTEREST_CUSTOM_FIELDS))
    .with(EntityResourceIds.customer, () => isFeatureEnabled(featureFlags.CUSTOMER_CUSTOM_FIELDS))
    .with(EntityResourceIds.businessCase, () =>
      isFeatureEnabled(featureFlags.BUSINESS_CASE_CUSTOM_FIELDS)
    )
    .with(EntityResourceIds.serviceCase, () =>
      isFeatureEnabled(featureFlags.SERVICE_CASE_CUSTOM_FIELDS)
    )
    .otherwise(always(true));
