import {FormControl, FormField, isCurrency} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetEmployeeCostCentersListQuery,
  useGetEmployeeWorkTeamsListQuery,
  useGetTenantQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY, getActiveOptions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {EmployeeOrganizationalForm} from '../types/employeeOrganizationalFormType';

interface EmployeeAllocationWorkshopDataProps extends TestIdProps {
  control: FormControl<EmployeeOrganizationalForm>;
  formApi: UseFormReturn<EmployeeOrganizationalForm>;
  index: number;
}

export function EmployeeAllocationWorkshopData(props: EmployeeAllocationWorkshopDataProps) {
  const {data: {currency: defaultCurrency} = {}} = useGetTenantQuery();

  const company = props.formApi.watch(`companyId`);
  const branch = props.formApi.watch(`branches.${props.index}.branchId`);

  const {costCenterOptions} = useGetEmployeeCostCentersListQuery(
    {company: company ?? '', branch: branch ?? ''},
    {
      selectFromResult: ({data}) => ({
        costCenterOptions: getActiveOptions(data?.costCenters),
      }),
      skip: isNilOrEmpty(company) || isNilOrEmpty(branch),
    }
  );

  const {workTeamOptions} = useGetEmployeeWorkTeamsListQuery(
    {company: company ?? '', branch: branch ?? ''},
    {
      selectFromResult: ({data}) => ({
        workTeamOptions: getActiveOptions(data?.workTeams),
      }),
      skip: isNilOrEmpty(company) || isNilOrEmpty(branch),
    }
  );

  return (
    <VStack spacing={4}>
      <HStack spacing={4}>
        <Box flex={1}>
          <FormField
            type="choice"
            control={props.control}
            name={`branches.${props.index}.workshopData.costCenterId`}
            label={i18n.t('entity.employeeCostCenters.labels.title')}
            options={costCenterOptions}
            isNotClearable
            data-testid={suffixTestId(`costCenter`, props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            type="choice"
            control={props.control}
            name={`branches.${props.index}.workshopData.workTeamId`}
            label={i18n.t('entity.employeeWorkTeams.labels.title')}
            options={workTeamOptions}
            isNotClearable
            data-testid={suffixTestId(`workTeam`, props)}
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        <Box flex={1}>
          <FormField
            type="currency"
            control={props.control}
            currency={isCurrency(defaultCurrency) ? defaultCurrency : DEFAULT_CURRENCY}
            name={`branches.${props.index}.workshopData.hourlyRate`}
            label={i18n.t('page.employeeDetail.labels.hourlyRate')}
            data-testid={suffixTestId(`hourlyRate`, props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            type="number"
            control={props.control}
            name={`branches.${props.index}.workshopData.performancePercentage`}
            label={`${i18n.t('page.employeeDetail.labels.performancePercentage')} (%)`}
            data-testid={suffixTestId(`performancePercentage`, props)}
          />
        </Box>
      </HStack>
    </VStack>
  );
}
