import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function UserOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('detail', () =>
        navigate(composePath(settingsRoutes.userManagementDetail, {params: {id}}))
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.user.labels.users'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.actions.newUser'),
            onClick: () => navigate(settingsRoutes.userManagementAddUser),
            'data-testid': testIds.settings.userManagement('newUserButton'),
          },
        ],
      }}
      data-testid={testIds.settings.userManagement('page')}
    >
      <DataGrid
        gridCode="user"
        actionCallback={actionCallback}
        data-testid={testIds.settings.userManagement('usersDG')}
      />
    </SettingsTemplate>
  );
}
