import {DataStatus, FormSubmitHandler, showNotification} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {always, defaultTo, find, head, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {
  BaseDirectSale,
  CheckoutCustomers,
  GetCheckoutByContextApiResponse,
  selectTenant,
  useGetDirectSaleVariantQuery,
  useLazyGetCheckoutByContextQuery,
  useLazyGetCustomerV2Query,
  usePatchCheckoutMutation,
  usePostCheckoutInvoiceMutation,
  usePostCheckoutMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  CheckoutBillingInformation,
  CheckoutPayment,
  CheckoutPaymentFormType,
  handleApiError,
  useBank,
} from '@dms/shared';

import {getApiDateString, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface CheckoutTabProps extends RequiredTestIdProps {
  directSale?: BaseDirectSale;
}

const contextTarget = 'direct-sale';

export function CheckoutTab(props: CheckoutTabProps) {
  const {directSaleId} = useWarehouseParams();
  const {getBankAccountByNumber} = useBank();

  const {data: {currency: defaultCurrency} = {}} = useSelector(selectTenant);

  const [checkout, setCheckout] = useState<GetCheckoutByContextApiResponse>(null);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState<boolean>(true);
  const [customerType, setCustomerType] = useState<string | null>(null);
  const [customerContractId, setCustomerContractId] = useState<string | null>(null);
  const [otherCustomerId, setOtherCustomerId] = useState<string | null>(null);
  const [customers, setCustomers] = useState<CheckoutCustomers | null>(null);

  const [getCustomer] = useLazyGetCustomerV2Query();
  const [getCheckout] = useLazyGetCheckoutByContextQuery();
  const [postCheckout] = usePostCheckoutMutation();
  const [patchCheckout] = usePatchCheckoutMutation();
  const [postCheckoutInvoice] = usePostCheckoutInvoiceMutation();

  useEffect(() => {
    reloadCheckout();
  }, [props.directSale]);

  useEffect(() => {
    const newCustomers = customers?.map((customer) => ({...customer})) ?? [];
    const selectedCustomer = find((customer) => customer?.type === 'OTHER', newCustomers);
    if (selectedCustomer) {
      selectedCustomer.id = otherCustomerId;
      setCustomers(newCustomers);
    }
  }, [otherCustomerId]);

  useEffect(() => {
    if (customerType != 'OTHER') {
      return;
    }

    const newCustomers = (customers ?? [])?.map((customer) => ({...customer}));
    const selectedCustomer = find((customer) => customer?.type === 'OTHER', newCustomers);

    if (selectedCustomer) {
      selectedCustomer.contactInformationId = customerContractId;
    }
  }, [customerContractId]);

  const {
    data: directSaleVariant,
    isLoading: isDirectSaleLoading,
    isError: hasDirectSaleError,
  } = useGetDirectSaleVariantQuery(
    {directSaleVariantId: defaultTo('', props.directSale?.directSaleVariantId)},
    {skip: isNil(props.directSale?.directSaleVariantId)}
  );

  const isLoading = isDirectSaleLoading || isCheckoutLoading;
  const isError = hasDirectSaleError;

  const selectedCustomer = find((customer) => customer?.type === customerType, customers ?? []);
  const selectedCustomerId = customerType === 'OTHER' ? otherCustomerId : selectedCustomer?.id;

  const reloadCheckout = () => {
    setIsCheckoutLoading(true);
    return getCheckout({contextId: directSaleId, contextTarget})
      .unwrap()
      .then((response) => {
        if (isNotNil(response)) {
          setCheckout(response);
          const customers = response?.customers ?? [];
          setCustomers(customers);

          return customers;
        } else if (isNotNil(props.directSale)) {
          setCheckout({
            totalAmount: props.directSale?.totalPrice,
            paymentInfo: {
              paymentAmount: {
                amount: props.directSale?.totalPrice?.withVat?.amount,
                currency: props.directSale?.totalPrice?.withVat?.currency,
                isPriceWithVat: true,
              },
              method: directSaleVariant?.documents?.defaultPaymentType,
            },
          });

          if (isNotNil(props.directSale?.customerId)) {
            return getCustomer({customerId: defaultTo('', props.directSale?.customerId)})
              .unwrap()
              .then((dsCustomer) => {
                const contract = head(dsCustomer?.contractInformation ?? []);
                const dsCheckoutCustomer = {
                  isSelected: true,
                  type: 'DEFAULT',
                  id: dsCustomer.id,
                  contactInformationId: contract?.id ?? null,
                };
                const customers = [dsCheckoutCustomer, ...initialCustomers];
                setCustomers(customers);

                return customers;
              })
              .catch(handleApiError);
          } else {
            const customers = [...initialCustomers];
            setCustomers([...initialCustomers]);

            return customers;
          }
        }
      })
      .then((customers) => {
        if (isNilOrEmpty(customers)) {
          return;
        }

        const selectedCustomer = find((customer) => isTrue(customer?.isSelected), customers ?? []);
        const firstCustomer = head(customers ?? []);

        const customer = selectedCustomer ?? firstCustomer;

        if (isNil(customer)) {
          return;
        }

        setCustomerType(customer.type);

        if (customer.type === 'OTHER') {
          setOtherCustomerId(customer.id);
        }

        if (isNotNil(customer.contactInformationId)) {
          return setCustomerContractId(customer.contactInformationId);
        }
      })
      .finally(() => {
        setIsCheckoutLoading(false);
      })
      .catch(handleApiError);
  };

  const handleCustomerTypeChange = (type: string) => {
    setCustomerType(type);

    const selectedCustomer = find((customer) => customer?.type === type, customers ?? []);

    if (type === 'OTHER') {
      setOtherCustomerId(selectedCustomer?.id ?? null);
    }

    if (isNotNil(selectedCustomer?.id) && isNil(selectedCustomer?.contactInformationId)) {
      getCustomer({customerId: selectedCustomer?.id ?? ''})
        .unwrap()
        .then((response) => {
          const contract = head(response?.contractInformation ?? []);
          setCustomerContractId(contract?.id ?? null);
        })
        .catch(handleApiError);
    } else {
      setCustomerContractId(selectedCustomer?.contactInformationId ?? null);
    }
  };

  const handleOtherCustomerSelect = (customerId: string) => {
    setOtherCustomerId(customerId);
    getCustomer({customerId})
      .unwrap()
      .then((response) => {
        const contract = head(response?.contractInformation ?? []);
        setCustomerContractId(contract?.id ?? null);
      })
      .catch(handleApiError);
  };

  const handleRemoveCustomer = () => {
    setOtherCustomerId(null);
    setCustomerContractId(null);
  };

  const handleSaveChanges = async (
    data: CheckoutPaymentFormType,
    shouldShowNottification?: boolean
  ) => {
    const selectedBankAccount = getBankAccountByNumber(data.bankAccount);

    const body = {
      customer: {
        contactInformationId: customerContractId ?? '',
        id: selectedCustomerId ?? '',
        type: customerType ?? '',
      },
      depositAllowed: false,
      depositPayment: [],
      paymentInfo: {
        foreignCurrencyPayment: data.currency
          ? {
              currency: data.currency,
              exchangeRate: data.exchangeRate ?? 0,
              ratio: data.ratio,
            }
          : undefined,
        incomingBankAccount: data.bankAccount
          ? {
              bankAccount: data.bankAccount,
              bankName: selectedBankAccount?.accountName,
              swift: selectedBankAccount?.swift,
              iban: selectedBankAccount?.iban,
            }
          : undefined,
        issueDate: getApiDateString(data.issuedOn),
        method: data.paymentMethod,
        paymentAmount: {
          amount: data.amount,
          currency: defaultCurrency ?? '',
          isPriceWithVat: true,
        },
        due: data.due,
        dueDate: getApiDateString(data.dueDate),
        taxableEventDate: getApiDateString(data.taxableEventDate),
        note: data.note,
        cashRegisterId: data.cashRegisterId,
      },
    };

    return await (
      isNotNil(checkout?.checkout?.id)
        ? patchCheckout({checkoutId: checkout?.checkout?.id ?? '', body})
        : postCheckout({
            context: {id: directSaleId, target: contextTarget},
            vehicle: isNotNil(props.directSale?.vehicleId)
              ? {
                  id: defaultTo('', props.directSale?.vehicleId),
                }
              : undefined,
            ...body,
          })
    )
      .unwrap()
      .then((response) => {
        if (shouldShowNottification) {
          showNotification.success();
        }

        return response;
      })

      .catch(handleApiError);
  };

  const handleIssuePayment: FormSubmitHandler<CheckoutPaymentFormType> = async (data, _, reset) => {
    await handleSaveChanges(data)
      .then((response) =>
        postCheckoutInvoice({
          checkoutId: defaultTo('', checkout?.checkout?.id ?? response?.checkout?.id),
          directSaleId,
        })
          .unwrap()
          .catch(handleApiError)
      )
      .then(() => reset(undefined, {keepValues: true}))
      .finally(() => reloadCheckout());
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <CheckoutBillingInformation
          customers={customers}
          customerTyp={customerType}
          customerId={selectedCustomerId}
          customerContractId={customerContractId}
          getChipLabelByType={getChipLabelByType}
          onCustomerTypChange={handleCustomerTypeChange}
          onOtherCustomerSelect={handleOtherCustomerSelect}
          onOtherCustomerRemove={handleRemoveCustomer}
          onCustomerContractChange={setCustomerContractId}
          hasInvoice={isNotNil(checkout?.invoice)}
          data-testid={suffixTestId('billingInfo', props)}
        />
        <CheckoutPayment
          invoice={checkout?.invoice}
          checkout={checkout}
          onSaveChanges={(data) => handleSaveChanges(data, true)}
          onIssuePayment={handleIssuePayment}
          isSubmitButtonDisabled={
            isNilOrEmpty(selectedCustomerId) || isNilOrEmpty(customerContractId)
          }
          shouldWatchForUnsavedChanges={true}
          data-testid={suffixTestId('payment', props)}
        />
      </VStack>
    </DataStatus>
  );
}

const initialCustomers: CheckoutCustomers = [
  {
    isSelected: false,
    type: 'OTHER',
    id: null,
    contactInformationId: null,
  },
];

const getChipLabelByType = (type: string | Nullish) =>
  match(type)
    .with('DEFAULT', always(i18n.t('entity.warehouse.label.directSaleCustomer')))
    .with('OTHER', always(i18n.t('entity.orderCheckout.labels.otherCustomer')))
    .with('OPERATOR', always(i18n.t('entity.ownership.label.vehicleOperator')))
    .with('OWNER', always(i18n.t('entity.ownership.label.vehicleOwner')))
    .otherwise(always(null));
