import {DataStatus} from 'platform/components';
import {Center} from 'platform/foundation';

import {FC, useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  getCustomTenantCatalogue,
  getTopMakes,
  loadCarVehicleDetail,
  useThunkDispatch,
  VehicleCreateContext,
  VehicleCreateForm,
} from '@dms/teas';

import {composePath, suffixTestId, TestIdProps, useBoolean} from 'shared';

export const CreateVehicle: FC = (props: TestIdProps) => {
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  useEffect(() => {
    startLoading();
    Promise.all([
      dispatch(loadCarVehicleDetail(undefined)),
      dispatch(getTopMakes.action({})),
      dispatch(getCustomTenantCatalogue.action({})),
      dispatch(getCebiaEquipmentCredentials()),
      dispatch(getCebiaAutotraceAndReport()),
    ]).finally(() => stopLoading());
  }, [dispatch, startLoading, stopLoading]);

  const onClick = useCallback(
    (vehicle: any, makeSaleVehicleAvailableForSale?: boolean) => {
      if (!vehicle.id) {
        return;
      }

      navigate(composePath(vehiclesRoutes.edit, {params: {id: vehicle.id}}), {
        state: {makeSaleVehicleAvailableForSale, ignoreUnmountEvents: true},
      });
    },
    [navigate]
  );

  return (
    <>
      <Helmet title={i18n.t('page.vehicleCreate.title')} />
      <DataStatus isLoading={isLoading} minHeight={100}>
        <VehicleCreateContext
          onClose={() => navigate(vehiclesRoutes.home)}
          onCreate={(id) =>
            navigate(composePath(vehiclesRoutes.detail, {params: {id}}), {
              state: {ignoreUnmountEvents: true},
            })
          }
        >
          <Center width="100%">
            <VehicleCreateForm
              onVehicleSelect={onClick}
              data-testid={suffixTestId('vehicleCreateForm', props)}
            />
          </Center>
        </VehicleCreateContext>
      </DataStatus>
    </>
  );
};
