import {Button, FormControl, FormField, openDialog, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {useAccM2StrediskoGetCentresQuery, useGetServiceOrderGroupQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {useGetVatRatesOptions} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {FormType} from '../../types/ServiceOrderVariant';
import {AllServiceOrderGroups} from './components/AllServiceOrderGroups';

interface AccountingTabProps extends RequiredTestIdProps {
  control: FormControl<FormType>;
  formApi: UseFormReturn<FormType>;
}

export function AccountingTab(props: AccountingTabProps) {
  const navigate = useNavigate();

  const [vatOptions] = useGetVatRatesOptions(true);

  const {data: serviceCentres, isLoading} = useAccM2StrediskoGetCentresQuery();

  const {data: orderGroups} = useGetServiceOrderGroupQuery();

  const serviceCentresOptions = serviceCentres?.m2centres?.map((centre) => ({
    label: centre?.name,
    value: centre?.code,
  }));

  const orderGroupsOptions = orderGroups?.map((group) => ({
    label: group?.name,
    value: group?.serviceOrderGroupId,
  }));

  const handleViewAll = () =>
    openDialog(
      <AllServiceOrderGroups data-testid={suffixTestId('allServiceOrderGroups', props)} />,
      {
        title: i18n.t('entity.accounting.lables.allServiceOrderGroups'),
      }
    );

  const excludedServiceCenterIds = props.formApi.watch('accounting.excludedServiceCenterIds');

  const filteredServiceCentresOptions = serviceCentresOptions?.filter(
    (option) => !excludedServiceCenterIds?.includes(option.value ?? '')
  );

  const excludedServiceOrderGroupIds = props.formApi.watch(
    'accounting.excludedServiceOrderGroupIds'
  );

  const filteredOrderGroupsOptions = orderGroupsOptions?.filter(
    (option) => !excludedServiceOrderGroupIds?.includes(option.value ?? '')
  );

  return (
    <>
      <SettingsSection>
        <VStack spacing={4}>
          <FormField
            control={props.control}
            type="radio"
            label={i18n.t('entity.accounting.labels.parametersForTax')}
            options={[
              {label: i18n.t('entity.accounting.lables.vatDeductible'), value: 'vatDeductible'},
              {
                label: i18n.t('entity.accounting.lables.notVatDeductible'),
                value: 'notVatDeductible',
              },
            ]}
            name="accounting.taxDeductible"
            data-testid={suffixTestId('accounting', props)}
          />
          <Show when={props.formApi.watch('accounting.taxDeductible') === 'notVatDeductible'}>
            <FormField
              control={props.control}
              type="choice"
              label={i18n.t('entity.accounting.labels.fixedVat')}
              options={vatOptions}
              name="accounting.fixedVatType"
              menuInPortal
              data-testid={suffixTestId('fixedVatType', props)}
            />
          </Show>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.accounting.labels.serviceCentres')}</Heading>
          <FormField
            control={props.control}
            label={i18n.t('entity.accounting.labels.excludedServiceCentres')}
            name="accounting.excludedServiceCenterIds"
            options={serviceCentresOptions}
            isLoading={isLoading}
            type="multiChoice"
            data-testid={suffixTestId('excludedServiceCentres', props)}
          />
          <HStack spacing={4} align="center">
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('entity.accounting.labels.defaultServiceCenter')}
                name="accounting.defaultServiceCenterId"
                options={filteredServiceCentresOptions}
                isLoading={isLoading}
                type="choice"
                data-testid={suffixTestId('defaultServiceCenter', props)}
              />
            </Box>
            <Box flex={1}>
              <VStack>
                <Space vertical={5} />
                <FormField
                  control={props.control}
                  type="checkbox"
                  label={i18n.t('entity.accounting.labels.lockDefault')}
                  name="accounting.isDefaultServiceCenterLocked"
                  data-testid={suffixTestId('lockDefaultServiceCenter', props)}
                />
              </VStack>
            </Box>
          </HStack>
          <Separator spacing={0} />
          <HStack justify="space-between" align="center">
            <Heading size={4}>{i18n.t('entity.accounting.labels.serviceOrderGroups')}</Heading>
            <Button
              title={i18n.t('general.actions.viewAll')}
              variant="link"
              leftIcon="action/visibility"
              onClick={handleViewAll}
              data-testid={suffixTestId('viewAll', props)}
            />
          </HStack>
          <FormField
            control={props.control}
            label={i18n.t('entity.accounting.labels.excludedServiceOrderGroups')}
            name="accounting.excludedServiceOrderGroupIds"
            options={orderGroupsOptions}
            type="multiChoice"
            data-testid={suffixTestId('excludedServiceOrderGroups', props)}
          />
          <HStack spacing={4} align="center">
            <Box flex={1}>
              <FormField
                control={props.control}
                label={i18n.t('entity.accounting.labels.defaultServiceOrderGroup')}
                name="accounting.defaultServiceOrderGroupId"
                options={filteredOrderGroupsOptions}
                type="choice"
                data-testid={suffixTestId('defaultServiceOrderGroup', props)}
              />
            </Box>
            <Box flex={1}>
              <VStack>
                <Space vertical={5} />
                <FormField
                  control={props.control}
                  type="checkbox"
                  label={i18n.t('entity.accounting.labels.lockDefault')}
                  name="accounting.isDefaultServiceOrderGroupLocked"
                  data-testid={suffixTestId('lockDefaultServiceCenter', props)}
                />
              </VStack>
            </Box>
          </HStack>
        </VStack>
      </SettingsSection>
      <Space vertical={20} />
      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.actions.discardChanges'),
            onClick: () => navigate(settingsRoutes.serviceOrderVariants),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: i18n.t('general.actions.saveChanges'),
          },
        ]}
        data-testid={suffixTestId('footer', props)}
      />
    </>
  );
}
