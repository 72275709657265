import {environment} from '@dms/environment';

import {TestIdProps} from 'shared';

import {DevSettingsSidebar} from './DevSettingsSidebar';
import {DeveloperSettingsProvider} from './hooks/DeveloperSettingsProvider';

const isDev = environment.ENV_TYPE === 'dev';

interface DevSettingsProps extends TestIdProps {}

export function DevSettings(props: DevSettingsProps) {
  if (!isDev) {
    return null;
  }

  return (
    <DeveloperSettingsProvider>
      <DevSettingsSidebar data-testid={props['data-testid']} />
    </DeveloperSettingsProvider>
  );
}
