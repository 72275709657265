import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteNegativeDocumentCancellationMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function InvoiceCanceling() {
  const navigate = useNavigate();
  const [deleteDefinition] = useDeleteNegativeDocumentCancellationMutation();

  const handleActionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, rowData, refreshData}) => {
      const id = isArray(rowId) ? head(rowId) : rowId;

      const branchName = path<string>(['branch', 'value', 'label'], rowData);

      if (isNil(id)) {
        showNotification.error();
        return;
      }

      const handleDeleteDefinition = (id: string) =>
        deleteDefinition({id})
          .unwrap()
          .then(refreshData)
          .then(() => showNotification.success())
          .catch(handleApiError);

      match(actionKey)
        .with('DETAIL', 'EDIT', () =>
          navigate(composePath(settingsRoutes.invoiceCancelingDetail, {params: {id}}))
        )
        .with('delete', () =>
          openDeleteDialog({
            onConfirm: () => handleDeleteDefinition(id),
            text: i18n.t('entity.accounting.labels.deleteDefinitionDeleteText', {
              name: branchName,
            }),
          })
        )
        .with('edit', () =>
          navigate(composePath(settingsRoutes.invoiceCancelingDetail, {params: {id}}))
        )
        .otherwise(() => showNotification.warning('unknown action key'));
    },
    [deleteDefinition, navigate]
  );

  return (
    <SettingsTemplate
      data-testid={testIds.settings.invoiceCanceling('header')}
      header={{
        title: i18n.t('page.accounting.labels.invoiceCancel'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.accounting.labels.newDefinition'),
            onClick: () => navigate(settingsRoutes.newInvoiceCanceling),
          },
        ],
      }}
      description={i18n.t('page.accounting.labels.invoiceCancelDescription')}
    >
      <DataGrid
        gridCode="negative-document-cancellation-definition"
        actionCallback={handleActionCallback}
        data-testid={testIds.settings.invoiceCanceling('datagrid')}
      />
    </SettingsTemplate>
  );
}
