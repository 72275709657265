import {Card, DataStatus, Textarea} from 'platform/components';
import {Grid, Text, ThemeColorTextPath, VStack} from 'platform/foundation';
import {useFormatNumber, useFormatPercentage} from 'platform/locale';
import {match} from 'ts-pattern';

import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {path} from 'ramda';

import {SourcingVehicleDetailResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {addCost, handleNoteChange, selectCostWithId, submitNoteChangeRequest} from '@dms/teas';

import {CostsTable} from './components/CostsTable';

interface CostsTabProps {
  id: string;
  vehicle: SourcingVehicleDetailResponseBody;
}

export function CostsTab({id, vehicle}: CostsTabProps) {
  const dispatch = useDispatch();
  const formatNumber = useFormatNumber();
  const formatPercentage = useFormatPercentage();
  const costs = useSelector(selectCostWithId(id)) ?? {
    isLoading: true,
  };

  const {isLoading, totalPrice, note} = costs;
  const price = path<number>(['sourcingVehicle', 'price', 'withVat'], vehicle);
  const sellingPrice = vehicle?.vehicleSummary?.sellingPrice ?? price;
  const margin = useMemo(
    () =>
      (sellingPrice ?? 0) -
      ((vehicle?.vehicleSummary?.purchasePrice ?? 0 ?? vehicle?.summary?.purchase ?? 0) +
        (totalPrice ?? 0)),
    [sellingPrice, totalPrice, vehicle]
  );
  const color = match<number, ThemeColorTextPath>(Math.sign(margin))
    .with(1, () => 'success')
    .with(-1, () => 'danger')
    .otherwise(() => 'primary');

  return (
    <VStack spacing={4}>
      <Grid columns={2} spacing={4}>
        <Card title={i18n.t('general.labels.summary')}>
          <DataStatus isLoading={isLoading}>
            <Grid columns={2} spacing={4}>
              <VStack align="center">
                <Text size="large" alternative>
                  {formatNumber(Math.round(totalPrice ?? 0))}
                </Text>
                <Text>{i18n.t('general.labels.totalCosts')}</Text>
              </VStack>
              <VStack align="center">
                <Text color={color} size="large" alternative>
                  {`${formatNumber(Math.round(margin ?? 0))} (${formatPercentage(margin / (sellingPrice ?? 0), 'nearest')})`}
                </Text>
                <Text>{i18n.t('entity.vehicle.labels.margin')}</Text>
              </VStack>
            </Grid>
          </DataStatus>
        </Card>

        <Card title={i18n.t('general.labels.notes')}>
          {isLoading ? (
            i18n.t('general.labels.loading')
          ) : (
            <Textarea
              name="cost"
              rows={2}
              value={note ?? null}
              onBlur={() => dispatch(submitNoteChangeRequest({vehicleId: id}))}
              onChange={(note) =>
                dispatch(
                  handleNoteChange({
                    vehicleId: id,
                    note: note ?? '',
                  })
                )
              }
              data-testid={testIds.sourcing.classifieds('costs-note')}
            />
          )}
        </Card>
      </Grid>

      <Card
        title={i18n.t('general.labels.costs')}
        actions={[
          {
            type: 'iconButton',
            onClick: () => dispatch(addCost({vehicleId: id})),
            isDisabled: isLoading,
            icon: 'content/add',
          },
        ]}
      >
        <CostsTable id={id} />
      </Card>
    </VStack>
  );
}
