import {OptionTypeBase} from 'platform/components';
import styled, {useTheme} from 'styled-components';

import {FC, ReactElement, cloneElement} from 'react';
import {
  CommonProps,
  components,
  OptionProps,
  Options,
  ValueContainerProps,
  GroupBase,
  SelectComponentsConfig,
} from 'react-select';

import i18n from '@dms/i18n';

import {noop} from '../../utils/someTeasUtils';
import {Checkbox} from '../Checkbox/Checkbox';
import {Dropdown, DropdownComponentEnhancedProps} from '../Dropdown/Dropdown';

const CheckboxWithoutHoverEffect = styled(Checkbox)`
  label {
    line-height: 16px;

    &:hover:after {
      box-shadow: none;
    }
  }
`;

const MultiValueInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
`;

const ValueContainer = ({
  children,
  getValue,
  ...props
}: ValueContainerProps<{[key: string]: string}, true>): ReactElement => {
  const length = getValue().length;
  const _children = children as [ReactElement[], ReactElement];
  return (
    <components.ValueContainer getValue={getValue} {...props}>
      <MultiValueInnerContainer>
        {length < 1 ? (
          <>{i18n.t('entity.sourcing.labels.selectedNothing')}</>
        ) : (
          <>
            {i18n.t('general.labels.selected')} ({length})
          </>
        )}
        {cloneElement(_children[1])}
      </MultiValueInnerContainer>
    </components.ValueContainer>
  );
};

const Option: FC<OptionProps<DropdownMultiPropsTypeOption, true>> = (props) => (
  <components.Option {...props}>
    <CheckboxWithoutHoverEffect checked={props.isSelected} label={props.children} onChange={noop} />
  </components.Option>
);

export type DropdownCommonOption = {label: string; value: string};
type DropdownMultiPropsTypeOption = {label: string; value: string} | {[key: string]: string};

type DropdownMultiProps<DDOptionType extends OptionTypeBase<any>> = {
  options: Options<DDOptionType>;
  showSelectedCount?: boolean;
  onChange?: (options?: Options<DDOptionType>) => void;
  setValue?: CommonProps<DDOptionType, true, GroupBase<DDOptionType>>['setValue'];
  getOptionLabel?: (option: {[key: string]: string}) => string;
  getOptionValue?: (option: {[key: string]: string}) => string;
} & Omit<DropdownComponentEnhancedProps<DDOptionType, true>, 'options' | 'onChange'>;

export const DropdownMulti = <DDOptionType extends OptionTypeBase<any>>({
  options,
  components,
  isClearable = false,
  showSelectedCount = false,
  onChange = noop,
  ...props
}: DropdownMultiProps<DDOptionType>): ReactElement => {
  const theme = useTheme();

  return (
    <Dropdown<DDOptionType, true>
      {...props}
      isMulti
      closeMenuOnSelect={false}
      isClearable={isClearable}
      onChange={onChange}
      options={options}
      components={
        (showSelectedCount
          ? {Option, ValueContainer, ...components}
          : {Option, ...components}) as SelectComponentsConfig<
          DDOptionType,
          true,
          GroupBase<DDOptionType>
        >
      }
      styles={{
        ...(props.styles || {}),
        valueContainer: (provided) => ({
          ...provided,
          flexWrap: 'unset',
          whiteSpace: 'nowrap',
          padding: '0 8px',
        }),
        option: (provided, {isSelected}: {isSelected: boolean}) => ({
          ...provided,
          backgroundColor: isSelected ? theme.colors.palettes.blue[20][100] : 'transparent',

          ':hover': {
            backgroundColor: isSelected
              ? theme.colors.palettes.blue[40][100]
              : theme.colors.palettes.neutral[20][100],
          },
        }),
      }}
      hideSelectedOptions={false}
      maxMenuHeight={320}
    />
  );
};
