import {isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {Channel, PatchTenantNotificationSettingsRequestBody, Receive} from '@dms/api';

import {buildObject, Nullish} from 'shared';

import type {FormValues} from '../types/FormValues';

export const getSubmitValues = (
  values: FormValues,
  allowedChannels: Partial<Record<Channel, boolean>>
) => {
  const {sms, email, toastNotification, force, settings} = values;

  const submitValues = buildObject<PatchTenantNotificationSettingsRequestBody>()
    .sms(shouldBeReceived(sms), isTrue(allowedChannels.sms))
    .email(shouldBeReceived(email), isTrue(allowedChannels.email))
    .toastNotification(
      shouldBeReceived(toastNotification),
      isTrue(allowedChannels.toastNotification)
    )
    .force(force)
    .settings(settings, isNotNilOrEmpty(settings))
    .build();

  return submitValues;
};

const shouldBeReceived = (value: boolean | Nullish): Receive =>
  isTrue(value) ? 'always' : 'never';
