import {Button} from 'platform/components';

import {useDeveloperSettings} from '../hooks/DeveloperSettingsProvider';
import {getFixedTranslation} from '../utils/getFixedTranslation';

export function ContentEditable() {
  const isContentEditable = document.body.contentEditable === 'true';

  const {close} = useDeveloperSettings();

  const handleToggleContentEditable = () => {
    document.body.contentEditable = isContentEditable ? 'false' : 'true';
    close();
  };

  return (
    <Button
      title={
        isContentEditable
          ? getFixedTranslation('page.settings.labels.hideContentEditable')
          : getFixedTranslation('page.settings.labels.showContentEditable')
      }
      onClick={handleToggleContentEditable}
      variant={isContentEditable ? 'danger' : 'secondary'}
      data-testid="layout-header-devSettings-contentEditable"
      leftIcon="content/text_format"
    />
  );
}
