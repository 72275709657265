import {DataStatus, openDialog, Actions, Action, closeCurrentDialog} from 'platform/components';
import {VStack, HStack, Heading} from 'platform/foundation';

import {equals, isNil} from 'ramda';
import {isFalsy, isNotNil} from 'ramda-adjunct';

import {useGetCustomerV2Query} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, buildArray, suffixTestId, TestIdProps} from 'shared';

import {ContractInformationForm} from '../ContactInformationForm/ContractInformationForm';
import {CustomerBillingInformationCard} from '../CustomerBillingInformationCard/CustomerBillingInformationCard';
import {CustomerMatchOrCreate} from '../CustomerWidgetCard/components/CustomerMatchOrCreate';

interface CheckoutBillingInformationListProps extends TestIdProps {
  customerId: string | Nullish;
  contractId: string | Nullish;
  isOtherCustomer?: boolean;
  isReadOnly?: boolean;
  hasVerification?: boolean;
  resourceId?: string;
  recordId?: string;
  onCustomerRemove: () => void;
  onCustomerSelect: (customerId: string) => void;
  onCustomerContractSelect: (contractId: string) => void;
}

export function CheckoutBillingInformationList(props: CheckoutBillingInformationListProps) {
  const {data, isLoading, isFetching, isError} = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {skip: isNil(props.customerId)}
  );

  const handleSelectCustomer = () => {
    openDialog(
      <CustomerMatchOrCreate
        data-testid={suffixTestId('selectCustomer', props)}
        secondStepComponentType="SERVICE_CASE"
        onCustomer={(customer) => {
          props.onCustomerSelect(customer.id);
          closeCurrentDialog();
        }}
      />,
      {title: i18n.t('entity.customer.labels.customer')}
    );
  };

  const actions = buildArray<Action>()
    .when(props.isOtherCustomer && isNotNil(props.customerId) && isFalsy(props.isReadOnly), {
      type: 'button',
      leftIcon: 'action/delete',
      title: i18n.t('entity.checkout.actions.removeCustomer'),
      variant: 'dangerLink',
      onClick: props.onCustomerRemove,
    })
    .when(isNotNil(props.customerId) && isFalsy(props.isReadOnly), {
      type: 'button',
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.customer.actions.addNewBillingInformation'),
      variant: 'ghostLink',
      onClick: () =>
        openDialog(
          <ContractInformationForm
            isBankListDisabled
            customerId={props.customerId!}
            data-testid={suffixTestId('createContract', props)}
          />,
          {
            title: i18n.t('entity.customer.actions.newContractInformation'),
            withAdditionalFooter: true,
            'data-testid': suffixTestId('createContract', props),
          }
        ),
    });

  return (
    <VStack spacing={4}>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.checkout.labels.billingInformation')}</Heading>
        <Actions actions={actions} data-testid={suffixTestId('actions', props)} />
      </HStack>
      <DataStatus
        isLoading={isLoading || isFetching}
        isEmpty={isNil(props.customerId)}
        isError={isError}
        minHeight={37}
        emptyMessage={i18n.t('entity.accounting.labels.noCustomerAssigned')}
        action={{title: i18n.t('general.actions.assignCustomer'), onClick: handleSelectCustomer}}
        data-testid={suffixTestId('list', props)}
      >
        <VStack spacing={4}>
          {props.customerId &&
            data?.contractInformation?.map((contract, index) => (
              <CustomerBillingInformationCard
                key={contract.id}
                customerId={props.customerId!}
                isSelected={equals(props.contractId, contract.id)}
                contract={contract}
                onSelect={(contract) => props.onCustomerContractSelect(contract.id)}
                isReadOnly={props.isReadOnly}
                hasVerification={props.hasVerification}
                resourceId={props.resourceId}
                recordId={props.recordId}
                data-testid={suffixTestId(`contract-${index}`, props)}
              />
            ))}
        </VStack>
      </DataStatus>
    </VStack>
  );
}
