import {Attributes, AttributesRow, Card} from 'platform/components';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type DeliveryNoteCorrectionItemDetails = {
  manufacturerNumber?: string;
  manufacturerName?: string;
  supplierOrderingNumber?: string;
  articleName?: string;
  articleDescription?: string;
};

interface DeliveryNoteCorrectionItemDetailCardProps extends RequiredTestIdProps {
  deliveryNoteCorrectionItemDetails: DeliveryNoteCorrectionItemDetails;
}

export function DeliveryNoteCorrectionItemDetailCard(
  props: DeliveryNoteCorrectionItemDetailCardProps
) {
  const deliveryNoteCorrectionItemRows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: props.deliveryNoteCorrectionItemDetails?.manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: props.deliveryNoteCorrectionItemDetails?.manufacturerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.supplierOrderingNumber'),
      value: props.deliveryNoteCorrectionItemDetails?.supplierOrderingNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: props.deliveryNoteCorrectionItemDetails?.articleName,
    },
    {
      label: i18n.t('entity.warehouse.labels.description'),
      value: props.deliveryNoteCorrectionItemDetails?.articleDescription,
    },
  ];
  return (
    <Card
      title={i18n.t('entity.warehouse.labels.deliveryNoteCorrectionItemDetail')}
      variant="inlineGrey"
      isExpandable
      data-testid={props['data-testid']}
    >
      <Attributes
        size="quarter"
        rows={deliveryNoteCorrectionItemRows}
        data-testid={suffixTestId('detailAttributes', props)}
      />
    </Card>
  );
}
