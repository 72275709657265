import {isNil} from 'ramda';

import {NotificationSettingsResponseBody} from '@dms/api';

import type {FormValues} from '../types/FormValues';

export const getDefaultValues = (
  notificationSettings: NotificationSettingsResponseBody | undefined
): FormValues | undefined => {
  if (isNil(notificationSettings)) {
    return undefined;
  }

  const {systemReceiveSettings, tenantReceiveSettings, tenantSettings, systemSettings} =
    notificationSettings;

  return {
    email: (tenantReceiveSettings?.email ?? systemReceiveSettings?.email) === 'always',
    sms: (tenantReceiveSettings?.sms ?? systemReceiveSettings?.sms) === 'always',
    toastNotification:
      (tenantReceiveSettings?.toastNotification ?? systemReceiveSettings?.toastNotification) ===
      'always',
    force: notificationSettings?.tenantSettingsForced ?? false,
    settings: tenantSettings ?? systemSettings,
  };
};
