import {Form, FormField, FormSubmitHandler} from 'platform/components';
import {Box} from 'platform/foundation';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  useGetDictionaryWorktypeQuery,
  usePatchDictionaryWorktypeMutation,
  usePostDictionaryWorktypeMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type WorkTypeFormType = {
  name?: string | null;
};

export function WorkTypeDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data, isLoading, isError} = useGetDictionaryWorktypeQuery(
    {workTypeId: id ?? ''},
    {skip: isNil(id)}
  );
  const [postWorkType, {isLoading: isPostLoading}] = usePostDictionaryWorktypeMutation();
  const [patchWorkType, {isLoading: isPatchLoading}] = usePatchDictionaryWorktypeMutation();

  const handleSubmit: FormSubmitHandler<WorkTypeFormType> = async (formData) => {
    if (isNil(formData?.name)) {
      return;
    }

    const body = {
      name: formData.name,
      isDefault: false,
      isActive: data?.isActive ?? true,
    };

    if (isNil(id)) {
      return await postWorkType({
        body,
      })
        .unwrap()
        .then(() => navigate(settingsRoutes.workType))
        .catch(handleApiError);
    }

    await patchWorkType({
      workTypeId: id,
      body,
    })
      .unwrap()
      .then(() => navigate(settingsRoutes.workType))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: data?.name ?? i18n.t('page.settings.actions.newWorkType'),
        breadcrumbs: [
          {
            label: i18n.t('entity.order.labels.workType'),
            href: settingsRoutes.workType,
          },
        ],
      }}
      isLoading={isLoading}
      isError={isError}
      isCreating={isNil(id)}
      data-testid={testIds.settings.workTypeDetail('template')}
    >
      <Form<WorkTypeFormType> onSubmit={handleSubmit} defaultValues={data}>
        {(control) => (
          <>
            <Box width={85}>
              <FormField
                control={control}
                name="name"
                type="text"
                label={i18n.t('general.labels.name')}
                isRequired
                data-testid={testIds.settings.workTypeDetail('name')}
              />
            </Box>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  variant: 'secondary',
                  onClick: () => navigate(settingsRoutes.workType),
                },
                {
                  control,
                  type: 'form-button',
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                  isLoading: isPostLoading || isPatchLoading,
                },
              ]}
              data-testid={testIds.settings.workTypeDetail('footer')}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}
