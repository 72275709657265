import {Label} from 'platform/components';
import {GridItem, Grid, Box} from 'platform/foundation';
import {Lightbox, LightboxImage, useLightbox} from 'platform/lightbox';

import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {selectAuditAssets} from '../../../store/carAudit/selectors';
import {AuditDataAssetsFilesBody} from '../../../types/AuditDataAssetsFilesBody';
import {getParsedValue} from '../utils/getParsedValue';
import {ImagePreview, ImagePreviewProps} from './ImagePreview';

type DamagePhotosProps = {
  categoryId: string;
  damageId: string;
  columns: number;
  locationPhotoParamId: string;
  detailPhotoParamId: string;
};

export const DamagePhotos: FC<DamagePhotosProps & TestIdProps> = ({
  categoryId,
  damageId,
  columns,
  locationPhotoParamId,
  detailPhotoParamId,
  ...rest
}) => {
  const locationAssets = useSelector(selectAuditAssets(categoryId, locationPhotoParamId));
  const detailAssets = useSelector(selectAuditAssets(categoryId, detailPhotoParamId));

  const [damageGalleryLightboxControls, {onOpen}] = useLightbox(damageId);

  const assets = useMemo(
    () => [...locationAssets, ...detailAssets],
    [locationAssets, detailAssets]
  );

  const damageImages = useMemo(
    () =>
      assets
        .filter((file) => getParsedValue(file?.meta)?.damageId === damageId)
        .map((asset) => ({...asset, src: asset.url, title: ''})),
    [assets, damageId]
  );

  const damageLightboxImages = assets.reduce((lightboxImages: LightboxImage[], asset) => {
    const imageUrl = asset.url || asset.resizeUrl;
    if (isNotNil(imageUrl) && getParsedValue(asset?.meta)?.damageId === damageId) {
      return [
        ...lightboxImages,
        {
          id: asset.imageId ?? asset.uploadedAssetsId,
          url: imageUrl.replace('resize', 'get'),
          resizeUrl: asset.resizeUrl,
          meta: asset.meta,
        },
      ];
    }
    return lightboxImages;
  }, []);

  if (!damageImages?.length) {
    return null;
  }

  const getImagePreviewProps = (
    image: AuditDataAssetsFilesBody,
    index: number
  ): ImagePreviewProps => ({
    resizeUrl: image.resizeUrl,
    onOpenClick: () => onOpen(index),
    ratio: '16 / 10',
  });

  return (
    <>
      <Label>{i18n.t('entity.photo.labels.photos')}</Label>
      <Grid columns={columns}>
        {damageImages?.map((image, index) => (
          <GridItem key={`damage-items-${image.imageId}`}>
            <Box width="100%" height="auto" borderRadius="small" position="relative">
              <ImagePreview
                {...getImagePreviewProps(image, index)}
                data-testid={suffixTestId(`preview-${image.imageId}`, rest)}
              />
            </Box>
          </GridItem>
        ))}
        <Lightbox
          data-testid={suffixTestId('damagePhotos', rest)}
          controls={damageGalleryLightboxControls}
          images={damageLightboxImages}
        />
      </Grid>
    </>
  );
};
