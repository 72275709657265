import {Link} from 'platform/foundation';

import {ConsentTemplate} from '@dms/api';
import i18n from '@dms/i18n';
import {downloadHostedFile} from '@dms/shared';

interface TemplatesCountProps {
  templates?: ConsentTemplate[];
}

export function TemplatesCount({templates}: TemplatesCountProps) {
  return (
    <>
      {templates?.map((template) => (
        <div key={template.fileUri}>
          <Link
            onClick={() => downloadHostedFile(template.fileUri, template.fileName)}
            download={template.fileName}
            title={
              template.isPrimary
                ? i18n.t('entity.gdpr.labels.template.defaultTemplateTitle', {
                    title: template.title,
                  })
                : template.title
            }
          />
        </div>
      ))}
    </>
  );
}
