import {
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  DialogFooter,
  Separator,
} from 'platform/components';
import {Box, Center, Heading, HStack, Icon, Image, Text, VStack} from 'platform/foundation';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {useGetTransactionQrCodeQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId} from 'shared';

import {StepContentProps} from '../types/StepContentProps';
import {CancelTransactionButton} from './CancelTransactionButton';

export function SignByQrCodeStep(props: StepContentProps) {
  const {
    data: qrCodeSrc,
    isLoading,
    isError,
  } = useGetTransactionQrCodeQuery(
    {transactionId: props.transaction?.transactionId ?? ''},
    {skip: isNilOrEmpty(props.transaction?.transactionId)}
  );

  return (
    <VStack spacing={4}>
      <HStack>
        <Box minWidth={52} height={52}>
          <DataStatus isLoading={isLoading} isEmpty={isError} minHeight={52}>
            <Center>
              {isNotNil(qrCodeSrc) && (
                <Image
                  src={qrCodeSrc}
                  width={52}
                  height={52}
                  data-testid={suffixTestId('qrCode', props)}
                />
              )}
            </Center>
          </DataStatus>
        </Box>

        <Separator orientation="vertical" />

        <Center>
          <VStack spacing={4}>
            <Icon value="content/qr-code" size={20} color="general.white" />
            <Box>
              <Heading size={3}>{i18n.t('entity.document.actions.qrCodeSent')}</Heading>
            </Box>

            <Box>
              <Text size="small" color="secondary">
                {i18n.t('entity.document.actions.qrCodeSentStep1')}
              </Text>
              <Text size="small" color="secondary">
                {i18n.t('entity.document.actions.qrCodeSentStep2')}
              </Text>
            </Box>
          </VStack>
        </Center>
      </HStack>

      <DialogFooter>
        <HStack justify="space-between">
          <CancelTransactionButton
            transactionId={props.transaction?.transactionId}
            data-testid={suffixTestId('seller', props)}
          />
          <ButtonGroup
            align="right"
            buttons={[
              {
                title: i18n.t('general.actions.finish'),
                onClick: closeCurrentDialog,
                'data-testid': suffixTestId('finish', props),
              },
            ]}
          />
        </HStack>
      </DialogFooter>
    </VStack>
  );
}
