import {CodeResponseBody, useReadCodelistQuery} from '@dms/api';

import {ApiError} from 'shared';

type CodeListIds =
  | 'interest_source'
  | 'unsuccessful_interest_reason'
  | 'unsuccessful_business_case_reason'
  | 'vehicle_source';

type CodeListReturnType = [
  CodeResponseBody[] | null,
  {isLoading: boolean; isError: boolean; error: ApiError | undefined},
];

/**
 * Loading of codelists is asynchronous.
 * Hook returns array of two items. First item is array of codes and second is loading flag.
 *
 * @param {CodeListIds} ids - id of codelist
 * @example
 * const [codes, {isLoading, isError, error}] = useCodeList('interest_source');
 */
export function useCodeList(ids: CodeListIds): CodeListReturnType {
  const {data, isLoading, isError, error} = useReadCodelistQuery({codelistId: ids});

  const codes = data?.codes.filter((code) => !code.isDisabled) ?? null;

  return [codes, {isLoading, isError, error}];
}
