import {GetEmployeePersonalDataApiResponse} from '@dms/api';

export function getPermanentAddress(employee: GetEmployeePersonalDataApiResponse) {
  if (!employee?.addressData) {
    return null;
  }

  return [
    employee.addressData.addressLine1,
    employee.addressData.addressLine2,
    employee.addressData.city,
    employee.addressData.postalCode,
    employee.addressData.countryCode,
  ]
    .filter(Boolean)
    .join(', ');
}
