import {closeCurrentDialog, openDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {useRef} from 'react';

import {isNil, isNotNil, path} from 'ramda';
import {isString} from 'ramda-adjunct';

import {useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, ExternalFilters} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {ArticleCreate, ArticleCreateDefaultValues} from '../../../../ArticleCreate/ArticleCreate';
import {MaterialPriceListTabExternalFilters} from './MaterialPriceListTabExternalFilters';

const SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID = 'supplier-order-material-external-filter';

interface MaterialPriceListProps extends TestIdProps {
  warehouseId: string;
  onAddMaterialToBasket: (articleId: string) => Promise<void>;
}

export function MaterialPriceListTab(props: MaterialPriceListProps) {
  const externalFilters = useRef<ArticleCreateDefaultValues>({});
  const [getArticleIdByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();

  const handleNewArticle = () => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          catalogNumber: externalFilters.current?.catalogNumber,
          manufacturerId: externalFilters.current?.manufacturerId,
          warehouseId: props.warehouseId,
        }}
        onArticleCreate={(createdArticleData) => {
          props.onAddMaterialToBasket(createdArticleData.articleId);
          closeCurrentDialog();
        }}
        submitButtonTitle={i18n.t('general.actions.createAndAdd')}
        data-testid={testIds.warehouse.articleList('createNewArticle')}
      />,
      {
        id: 'createNewArticleDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );
  };

  const handleAddMaterialToBasket = async (manufacturerNumber: string, manufacturerId: string) => {
    const articleIdResponse = await getArticleIdByManufacturerNumberAndManufacturerId({
      warehouseId: props.warehouseId,
      manufacturerNumber,
      manufacturerId,
    })
      .unwrap()
      .catch(handleApiError);

    const articleId = articleIdResponse?.articleId;

    if (isNotNil(articleId)) {
      props.onAddMaterialToBasket(articleId);
      return;
    }

    handleNewArticle();
  };

  const warehouseActionCallback: ActionCallback = ({actionKey, rowData}) => {
    const itemManufacturerNumber = path(['oemCode', 'value'], rowData) as string;
    const itemManufacturerId = path(['manufacturerId', 'value', 'id'], rowData) as string;

    if (isNil(itemManufacturerNumber)) {
      throw new Error('ItemManufacturerNumber is not defined');
    }

    if (isNil(itemManufacturerId)) {
      throw new Error('ItemManufacturerId is not defined');
    }

    match(actionKey)
      .with('addToBasket', () => {
        handleAddMaterialToBasket(itemManufacturerNumber, itemManufacturerId);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleExternalFiltersChange = (filters: ExternalFilters) => {
    const articleCreateDefaultValues: ArticleCreateDefaultValues = {};

    if (isString(filters.catalogNumber)) {
      articleCreateDefaultValues.catalogNumber = filters.catalogNumber;
    }

    if (isString(filters.manufacturer)) {
      articleCreateDefaultValues.manufacturerId = filters.manufacturer;
    }

    externalFilters.current = articleCreateDefaultValues;
  };

  return (
    <FullscreenDataGridWrapper
      externalFilterId={SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID}
      externalFilterHeight={100}
      hideCardWrapper
      data-testid={props['data-testid']}
    >
      <DataGrid
        gridCode="supplier-price-list-cart"
        actionCallback={warehouseActionCallback}
        externalFilterId={SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID}
        _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
        data-testid={suffixTestId('supplierPriceListBasket', props)}
        filterComponent={MaterialPriceListTabExternalFilters}
        onExternalFilterChange={handleExternalFiltersChange}
      />
    </FullscreenDataGridWrapper>
  );
}
