import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  ValidationErrors,
} from 'platform/components';
import {Box, Grid, Heading, Hide, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useRef, useState} from 'react';
import {DeepPartial, UseFormReset, UseFormReturn} from 'react-hook-form';

import {isNil, mergeDeepLeft, mergeDeepRight} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BigDecimal,
  BrokerageFeesPrice,
  BrokerageFeesPriceRequestBody,
  BrokerageFeesRequestBody,
  BrokerageServicesRequestBody,
  CalculatedBrokerageFeesProfit,
  CalculatedBrokerageServicesCost,
  CalculatedInvoicedBrokerageServices,
  CalculatedSalesCommissionFixed,
  CalculatedSalesCommissionMinimal,
  CalculatedSalesCommissionPercentage,
  InvoicedBrokerageServicesRequestBody,
  Money,
  NewOfferPurchaseBrokerageVehicleResponseBody,
  SalesCommissionRequestBody,
  useCalculateBrokerageFeesMutation,
  useGetBusinessCaseQuery,
  useGetVatRatesQuery,
  usePatchFeesOfferPurchaseBrokerageVehicleMutation,
  VatTypeEnum,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, PriceBox, useTenant} from '@dms/shared';
import {
  getDefaultPriceRequestBodyForEmptyValue,
  getPriceRequestBody,
  isValidPrice,
  LogicalException,
  PriceValue,
} from '@dms/teas';

import {getApiDateString, Nullish, suffixTestId, TestIdProps} from 'shared';

type WithPriceValue<Type, K extends keyof Type> = Omit<Type, K> & {[P in K]: PriceValue};

const getPriceWithVatDirection = (
  price: PriceValue | undefined,
  lastTouchedDirection: LastTouchedDirection
): PriceValue | null => {
  if (!price) {
    return null;
  }

  return {
    ...price,
    fromWithVat: isNil(lastTouchedDirection) || lastTouchedDirection === 'withVat',
  };
};

const getPriceForRequestBody = (
  price: PriceValue | null,
  currency: string
): BrokerageFeesPriceRequestBody | null =>
  isValidPrice(price)
    ? getPriceRequestBody(price)
    : getDefaultPriceRequestBodyForEmptyValue(price, currency);

type LastTouchedDirection = 'withVat' | 'withoutVat';

interface BusinessCasePurchaseVehicleBrokerageFeesFormProps extends TestIdProps {
  businessCaseId: string;
  offerId?: string;
  purchaseVehicleId: string;
  onClose: () => void;
  initialValues?: NewOfferPurchaseBrokerageVehicleResponseBody;
  isEditDisabled?: boolean;
}

export interface BrokerageFeesFormValues {
  brokerageServices?: {
    cost: WithPriceValue<CalculatedBrokerageServicesCost, 'price'> | null;
    invoiced: WithPriceValue<CalculatedInvoicedBrokerageServices, 'price'> | null;
  };
  salesCommission?: {
    isEnabled: boolean;
    fixed: WithPriceValue<CalculatedSalesCommissionFixed, 'price'> | null;
    percentage: WithPriceValue<CalculatedSalesCommissionPercentage, 'price'> | null;
    minimal: WithPriceValue<CalculatedSalesCommissionMinimal, 'price'> | null;
    total?: BrokerageFeesPrice | null;
  };
  profit?: CalculatedBrokerageFeesProfit;
  contractLength?: number | null;
}

const CALCULATION_DEBOUNCE_TIMEOUT = 1000;

export function BusinessCasePurchaseVehicleBrokerageFeesForm(
  props: BusinessCasePurchaseVehicleBrokerageFeesFormProps
) {
  const calculationTimeout = useRef<ReturnType<typeof setTimeout>>();

  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId: props.businessCaseId});
  const canShowDiscard =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE' &&
    isNotNil(businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0]?.brokerageFees);

  const sellingPriceWithVat =
    props.initialValues?.pricing?.sellingPrice?.priceNotDeductible ??
    props.initialValues?.pricing?.sellingPrice?.priceWithVat;

  const sellingPriceWithoutVat =
    props.initialValues?.pricing?.sellingPrice?.priceNotDeductible ??
    props.initialValues?.pricing?.sellingPrice?.priceWithoutVat;

  const isVatDeductible = Boolean(props.initialValues?.pricing?.vatDeductible);

  const lastTouchedDirection: LastTouchedDirection = isVatDeductible ? 'withVat' : 'withoutVat';

  const [lastTouchedBrokerageFeeField, setLastTouchedBrokerageFeeField] =
    useState<LastTouchedDirection>(lastTouchedDirection);
  const [lastTouchedMinimalCommissionField, setLastTouchedMinimalCommissionField] =
    useState<LastTouchedDirection>(lastTouchedDirection);
  const [lastTouchedFixedCommissionField, setLastTouchedFixedCommissionField] =
    useState<LastTouchedDirection>(lastTouchedDirection);

  const {tenantCurrency, tenantCountry} = useTenant();

  const [patchBrokerageFees] = usePatchFeesOfferPurchaseBrokerageVehicleMutation();
  const [calculateBrokerageFees] = useCalculateBrokerageFeesMutation();

  const {data: vatRates} = useGetVatRatesQuery();

  const vatRatesOptions = vatRates
    ?.find((rate) => rate.code === tenantCountry)
    ?.rates?.map((vatRate) => ({
      ...vatRate,
      value: vatRate.type,
      label: `${vatRate.rate} % ${vatRate.name}`,
      fieldLabel: `${vatRate.rate} %`,
    }));

  const defaultPriceValue: DeepPartial<BrokerageFeesPrice> = {
    vatType: VatTypeEnum.S,
    withVat: {
      currency: tenantCurrency,
    },
    withoutVat: {
      currency: tenantCurrency,
    },
  };

  const emptyValues: DeepPartial<BrokerageFeesFormValues> = {
    brokerageServices: {
      cost: {
        price: defaultPriceValue,
      },
      invoiced: {
        isEnabled: false,
        price: defaultPriceValue,
      },
    },
    salesCommission: {
      isEnabled: true,
      fixed: {
        price: defaultPriceValue,
      },
      minimal: {
        price: defaultPriceValue,
      },
      percentage: {
        price: defaultPriceValue,
        isWithVat: true,
      },
    },
  };

  const defaultValues: DeepPartial<BrokerageFeesFormValues> = mergeDeepLeft(
    {
      contractLength: props.initialValues?.brokerageFees?.contractLength,
      brokerageServices: props.initialValues?.brokerageFees?.brokerageServices,
      salesCommission: props.initialValues?.brokerageFees?.salesCommission,
    },
    emptyValues
  );

  const getMoneyPrice = (
    salesCommission: Money | Nullish,
    commissionFees: Money | Nullish
  ): Money => ({
    amount: (
      parseFloat(salesCommission?.amount ?? '0') + parseFloat(commissionFees?.amount ?? '0')
    ).toString(),
    currency: salesCommission?.currency ?? commissionFees?.currency ?? tenantCurrency,
  });

  const getRequestBody = (values: BrokerageFeesFormValues): BrokerageFeesRequestBody => {
    if (!sellingPriceWithVat || !sellingPriceWithoutVat) {
      throw new LogicalException('Cannot calculate brokerage fees without selling price');
    }

    const brokerageFeeWithDirection = getPriceWithVatDirection(
      values.brokerageServices?.invoiced?.price,
      lastTouchedBrokerageFeeField
    );

    const minimalWithDirection = getPriceWithVatDirection(
      values.salesCommission?.minimal?.price,
      lastTouchedMinimalCommissionField
    );
    const fixedWithDirection = getPriceWithVatDirection(
      values.salesCommission?.fixed?.price,
      lastTouchedFixedCommissionField
    );

    const minimal = getPriceForRequestBody(minimalWithDirection, tenantCurrency);
    const fixed = getPriceForRequestBody(fixedWithDirection, tenantCurrency);
    const fee = getPriceForRequestBody(brokerageFeeWithDirection, tenantCurrency);

    const invoiced: InvoicedBrokerageServicesRequestBody | null =
      values.brokerageServices?.invoiced?.isEnabled && fee
        ? {
            isEnabled: true,
            fee,
          }
        : null;

    const percentage: BigDecimal = isNotNil(values.salesCommission?.percentage?.percentage)
      ? String(values.salesCommission!.percentage!.percentage)
      : '0';

    const brokerageServices: BrokerageServicesRequestBody | null = {
      cost: null,
      invoiced,
    };

    const salesCommission: SalesCommissionRequestBody | null =
      values.salesCommission?.isEnabled && (fixed || percentage)
        ? {
            isWithVat: values.salesCommission?.percentage?.isWithVat ?? false,
            isEnabled: true,
            isVatDeductible,
            fixed,
            minimal,
            percentage,
          }
        : null;

    return {
      contractLength: isNotNil(values.contractLength) ? Number(values.contractLength) : null,
      vehicleSellingPrice: sellingPriceWithoutVat,
      brokerageServices,
      salesCommission,
      country: tenantCountry,
      date: getApiDateString(new Date()),
    };
  };

  const handleChange = (
    values: BrokerageFeesFormValues,
    _setErrors: (errors: ValidationErrors | null) => void,
    _reset: UseFormReset<BrokerageFeesFormValues>,
    formApi: UseFormReturn<BrokerageFeesFormValues>
  ) => {
    clearTimeout(calculationTimeout.current);

    calculationTimeout.current = setTimeout(() => {
      calculateBrokerageFees({
        body: getRequestBody(values),
      })
        .unwrap()
        .then((calculatedValues) => {
          formApi.reset(
            mergeDeepRight(
              props.initialValues ?? {},
              calculatedValues as Partial<BrokerageFeesFormValues>
            )
          );
        })
        .catch(handleApiError);
    }, CALCULATION_DEBOUNCE_TIMEOUT);
  };

  const handleSubmit: FormSubmitHandler<BrokerageFeesFormValues> = (values) =>
    patchBrokerageFees({
      businessCaseId: props.businessCaseId,
      offerId: props.offerId ?? '',
      offerPurchaseBrokerageVehicleId: props.purchaseVehicleId,
      body: getRequestBody(values),
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);

  return (
    <Form<BrokerageFeesFormValues>
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      onChange={handleChange}
      data-testid={testIds.businessCase.buying('brokerageFeesForm')}
    >
      {(control, formApi) => {
        const isWithBrokerageFees = formApi.watch('brokerageServices.invoiced.isEnabled');
        const isWithSalesCommission = formApi.watch('salesCommission.isEnabled');

        const percentagePrice = formApi.watch('salesCommission.percentage.price');

        const totalCommission = formApi.watch('salesCommission.total');
        const brokerageServicePrice = formApi.watch('brokerageServices.invoiced.price');

        return (
          <VStack spacing={4}>
            <Hide when={isNotNilOrEmpty(sellingPriceWithoutVat)}>
              <Alert
                data-testid={testIds.businessCase.buying(
                  'sellingPriceRequiredForBrokerageFeesCalculation'
                )}
                variant="warning"
                title={i18n.t(
                  'entity.businessCase.notifications.sellingPriceRequiredForBrokerageFeesCalculation'
                )}
              />
              <Space vertical={4} />
            </Hide>
            <Heading size={4}>{i18n.t('entity.businessCase.labels.contractLength')}</Heading>
            <Grid columns={2}>
              <FormField
                name="contractLength"
                type="number"
                label={i18n.t('entity.businessCase.labels.numberOfDays')}
                control={control}
                data-testid={suffixTestId('brokerageFees.numberOfDays', props)}
                isDisabled={
                  (isNilOrEmpty(sellingPriceWithoutVat) && isNilOrEmpty(sellingPriceWithVat)) ||
                  props.isEditDisabled
                }
              />
            </Grid>

            <Separator spacing={0} />

            <Heading size={4}>{i18n.t('entity.businessCase.labels.brokerageFees')}</Heading>
            <FormField
              name="brokerageServices.invoiced.isEnabled"
              type="switch"
              label={i18n.t('entity.businessCase.labels.invoice')}
              data-testid={suffixTestId('brokerageFees.isEnabled', props)}
              isDisabled={
                (isNilOrEmpty(sellingPriceWithoutVat) && isNilOrEmpty(sellingPriceWithVat)) ||
                props.isEditDisabled
              }
              control={control}
            />
            <Show when={isWithBrokerageFees}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        name="brokerageServices.invoiced.price.withoutVat.amount"
                        type="currency"
                        label={i18n.t('entity.businessCase.labels.serviceFeeWithoutVAT')}
                        onFocus={() => setLastTouchedBrokerageFeeField('withoutVat')}
                        isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                        currency={tenantCurrency}
                        decimalPlaces={2}
                        control={control}
                        data-testid={suffixTestId('brokerageFees.sellingPriceWithoutVat', props)}
                      />
                    </Box>
                    <Box width={22}>
                      <FormField
                        name="brokerageServices.invoiced.price.vatType"
                        label={i18n.t('entity.vehicle.labels.vat')}
                        type="choice"
                        options={vatRatesOptions}
                        isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                        isNotClearable
                        control={control}
                        data-testid={suffixTestId('brokerageFees.vatType', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <FormField
                    name="brokerageServices.invoiced.price.withVat.amount"
                    type="currency"
                    label={i18n.t('entity.businessCase.labels.serviceFee')}
                    onFocus={() => setLastTouchedBrokerageFeeField('withVat')}
                    data-testid={suffixTestId('brokerageFees.withVat.amount', props)}
                    isDisabled={
                      (isNilOrEmpty(sellingPriceWithoutVat) && isNilOrEmpty(sellingPriceWithVat)) ||
                      props.isEditDisabled
                    }
                    currency={tenantCurrency}
                    decimalPlaces={2}
                    control={control}
                  />
                </Box>
              </HStack>
            </Show>

            <Separator spacing={0} />

            <Heading size={4}>{i18n.t('entity.businessCase.labels.salesCommission')}</Heading>
            <FormField
              name="salesCommission.isEnabled"
              type="switch"
              label={i18n.t('entity.businessCase.labels.invoice')}
              isDisabled={
                (isNilOrEmpty(sellingPriceWithoutVat) && isNilOrEmpty(sellingPriceWithVat)) ||
                props.isEditDisabled
              }
              data-testid={suffixTestId('salesCommission.isEnabled', props)}
              control={control}
            />
            <Show when={isWithSalesCommission}>
              <Heading size={5}>
                {i18n.t('entity.businessCase.labels.percentageSalesCommission')}
              </Heading>
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <VStack spacing={4}>
                      <FormField
                        name="salesCommission.percentage.percentage"
                        type="number"
                        label={i18n.t('entity.businessCase.labels.percentSalesCommission')}
                        data-testid={suffixTestId('salesCommission.percentage', props)}
                        suffix="%"
                        isDisabled={
                          !sellingPriceWithVat || !sellingPriceWithoutVat || props.isEditDisabled
                        }
                        control={control}
                      />
                      <FormField
                        name="salesCommission.percentage.isWithVat"
                        type="switch"
                        data-testid={suffixTestId('salesCommission.isWithVat', props)}
                        label={i18n.t('entity.businessCase.labels.percentageWithVat')}
                        isDisabled={
                          !sellingPriceWithVat || !sellingPriceWithoutVat || props.isEditDisabled
                        }
                        control={control}
                      />
                    </VStack>
                  </Box>
                  <Box flex={1}>
                    <PriceBox
                      price={percentagePrice?.withVat}
                      priceSub={percentagePrice?.withoutVat}
                      align="flex-end"
                      data-testid="businessCase.purchaseVehicle.brokerageFees.totalSalesCommission"
                      isVatDeductible={isVatDeductible}
                    />
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <HStack spacing={4}>
                      <Box flex={1}>
                        <FormField
                          name="salesCommission.minimal.price.withoutVat.amount"
                          type="currency"
                          label={i18n.t(
                            'entity.businessCase.labels.salesCommissionMinimalWithoutVat'
                          )}
                          onFocus={() => setLastTouchedMinimalCommissionField('withoutVat')}
                          isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                          currency={tenantCurrency}
                          decimalPlaces={2}
                          control={control}
                          data-testid={suffixTestId('salesCommission.withoutVat.amount', props)}
                        />
                      </Box>
                      <Box width={22}>
                        <FormField
                          name="salesCommission.minimal.price.vatType"
                          label={i18n.t('entity.vehicle.labels.vat')}
                          type="choice"
                          options={vatRatesOptions}
                          onChange={(value) =>
                            formApi.setValue(
                              'salesCommission.fixed.price.vatType',
                              value as VatTypeEnum
                            )
                          }
                          isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                          isNotClearable
                          control={control}
                          data-testid={suffixTestId('salesCommission.vatType', props)}
                        />
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <FormField
                      name="salesCommission.minimal.price.withVat.amount"
                      type="currency"
                      label={i18n.t('entity.businessCase.labels.salesCommissionMinimal')}
                      onFocus={() => setLastTouchedMinimalCommissionField('withVat')}
                      isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                      currency={tenantCurrency}
                      decimalPlaces={2}
                      control={control}
                      data-testid={suffixTestId('salesCommission.minimal.amount', props)}
                    />
                  </Box>
                </HStack>

                <Heading size={5}>
                  {i18n.t('entity.businessCase.labels.nominalSalesCommission')}
                </Heading>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <HStack spacing={4}>
                      <Box flex={1}>
                        <FormField
                          name="salesCommission.fixed.price.withoutVat.amount"
                          type="currency"
                          label={i18n.t(
                            'entity.businessCase.labels.fixedSalesCommissionWithoutVat'
                          )}
                          onFocus={() => setLastTouchedFixedCommissionField('withoutVat')}
                          isDisabled={!sellingPriceWithoutVat}
                          data-testid={suffixTestId('salesCommission.fixed.amount', props)}
                          control={control}
                        />
                      </Box>
                      <Box width={22}>
                        <FormField
                          name="salesCommission.fixed.price.vatType"
                          label={i18n.t('entity.vehicle.labels.vat')}
                          type="choice"
                          options={vatRatesOptions}
                          onChange={(value) =>
                            formApi.setValue(
                              'salesCommission.minimal.price.vatType',
                              value as VatTypeEnum
                            )
                          }
                          isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                          isNotClearable
                          data-testid={suffixTestId('salesCommission.fixed.price', props)}
                          control={control}
                        />
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <FormField
                      name="salesCommission.fixed.price.withVat.amount"
                      type="currency"
                      label={i18n.t('entity.businessCase.labels.fixedSalesCommission')}
                      data-testid={suffixTestId('salesCommission.fixed.price.withVat', props)}
                      onFocus={() => setLastTouchedFixedCommissionField('withVat')}
                      isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                      control={control}
                    />
                  </Box>
                </HStack>
                <Separator spacing={0} />
                <HStack spacing={4}>
                  <Box flex={1}>
                    <Text color="secondary" size="xSmall">
                      {i18n.t('entity.businessCase.labels.totalSalesCommission')}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <PriceBox
                      price={getMoneyPrice(
                        totalCommission?.withVat,
                        brokerageServicePrice?.withVat
                      )}
                      priceSub={getMoneyPrice(
                        totalCommission?.withoutVat,
                        brokerageServicePrice?.withoutVat
                      )}
                      isVatDeductible
                      align="flex-end"
                      size="large"
                      data-testid="businessCase.purchaseVehicle.brokerageFees.totalSalesCommission"
                    />
                  </Box>
                </HStack>
              </VStack>
            </Show>
            <ButtonGroup align="right">
              <Show when={canShowDiscard}>
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={props.onClose}
                  isDisabled={!sellingPriceWithoutVat || props.isEditDisabled}
                  data-testid={testIds.businessCase.buying('brokerageFees-discard')}
                />
              </Show>
              <FormButton
                title={i18n.t('general.actions.confirm')}
                type="submit"
                isDisabled={
                  (isNilOrEmpty(sellingPriceWithoutVat) && isNilOrEmpty(sellingPriceWithVat)) ||
                  props.isEditDisabled
                }
                control={control}
                data-testid={testIds.businessCase.buying('brokerageFees-submit')}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}
