import {Card, FormControl} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {GetInvoiceV4ApiResponse} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {testIds} from '@dms/routes';

import {CancellingInvoiceDetail} from '../../components/CancellingInvoiceDetail';
import {CustomerAndSupplierDetail} from '../../components/CustomerAndSupplierDetail';
import {ListOfItemsDetail} from '../../components/ListOfItemsDetail';
import {PaymentDatesDetail} from '../../components/PaymentDatesDetail';
import {PaymentDetailsDetail} from '../../components/PaymentDetailsDetail';
import {PaymentsNotesDetail} from '../../components/PaymentsNotesDetail';
import {AccountingDocumentFormValues} from '../../types';

interface InvoiceDetailsProps {
  control: FormControl<AccountingDocumentFormValues>;
  invoice: GetInvoiceV4ApiResponse;
}

export function BalanceInvoiceDetails(props: InvoiceDetailsProps) {
  return (
    <VStack spacing={4}>
      <Card>
        <VStack spacing={4}>
          <CustomerAndSupplierDetail
            customer={props.invoice.customer}
            supplier={props.invoice.supplier}
            additionalCustomer={props.invoice.additionalCustomer}
            additionalFields={props.invoice.customFields}
            data-testid={testIds.accounting.balanceInvoiceDetail('customerAndSupplier')}
          />
          <PaymentDatesDetail control={props.control} />
        </VStack>
      </Card>

      <Show whenFeatureEnabled={featureFlags.ACCOUNTING_CANCEL_V2}>
        <CancellingInvoiceDetail
          cancelingInvoiceType="BALANCE_INVOICE"
          cancelingInvoiceId={props.invoice.cancelledDocumentId}
        />
      </Show>

      <PaymentDetailsDetail control={props.control} />

      <ListOfItemsDetail
        items={props.invoice.items}
        summary={props.invoice.documentSummary}
        exchangeRateRatioCalculation={props.invoice.exchangeRateRatioDocumentSummary}
        control={props.control}
      />

      <PaymentsNotesDetail control={props.control} />
    </VStack>
  );
}
