import {FlagProps} from 'platform/components';

import {isNotNil} from 'ramda';

import {ComplaintResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getComplaintFlag = (
  complaint: ComplaintResponseBody | Nullish
): Pick<FlagProps, 'colorScheme' | 'label'> =>
  isNotNil(complaint?.closeAt)
    ? {
        label: i18n.t('entity.vehicleComplaint.labels.resolved'),
        colorScheme: 'green',
      }
    : {
        label: i18n.t('entity.vehicleComplaint.labels.notResolved'),
        colorScheme: 'orange',
      };
