import {OptionType} from 'platform/components';

import {VehicleAgeType} from '@dms/api';
import i18n from '@dms/i18n';

export const ageTypeOptions: OptionType<VehicleAgeType>[] = [
  {
    label: i18n.t('general.labels.undecided'),
    value: 'VEHICLE_AGE_UNDECIDED',
  },
  {
    label: i18n.t('entity.customerContract.labels.vehicleAgeInMonths'),
    value: 'VEHICLE_AGE_IN_MONTHS',
  },
];
