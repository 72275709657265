import {FC, PropsWithChildren} from 'react';

import i18n from '@dms/i18n';
import {TypographyCard} from '@dms/teas';

import {BankAccounts} from './BankAccounts';
import {DeputyPersons} from './DeputyPersons';

export const BankInformation: FC<PropsWithChildren<any>> = () => (
  <TypographyCard title={i18n.t('entity.bank.labels.bankInformation')}>
    <DeputyPersons />
    <BankAccounts />
  </TypographyCard>
);
