import {Separator} from 'platform/components';
import {Grid} from 'platform/foundation';

import {FC} from 'react';

import i18n from '@dms/i18n';

import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useConditionContext} from '../hooks/useConditionContext';
import {AuditParamType} from '../types/AuditParamType';
import {findByParamType} from '../utils/findByParamType';
import {getFormFieldName} from '../utils/getFormFieldName';

type CategoryGeneralCommentProps = {
  category?: AuditCategoryOfStructure;
  paramDefinitionId?: string;
};

export const CategoryGeneralComment: FC<CategoryGeneralCommentProps> = ({
  category,
  paramDefinitionId,
}) => {
  const {isDisabledForUser} = useConditionContext();
  const {Field} = useFormRenderer();

  const generalComment = findByParamType(
    category?.paramDefinitions?.relatedActions,
    AuditParamType.GENERAL_COMMENT
  );

  const isMandatory = generalComment?.mandatory;

  const commentParamId = paramDefinitionId ?? generalComment?.id;

  if (!commentParamId || !category) {
    return null;
  }

  return (
    <>
      <Separator />
      <Grid columns={2}>
        <Field
          name={getFormFieldName(category.id, commentParamId)}
          as="text"
          multiline
          label={`${isMandatory ? '*' : ''} ${i18n.t('general.labels.note')}`}
          disabled={isDisabledForUser}
        />
      </Grid>
    </>
  );
};
