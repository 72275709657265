import {match} from 'ts-pattern';

import {always} from 'ramda';

import {
  employeeRoutes,
  vehiclesRoutes,
  accountingRoutes,
  businessCaseRoutes,
  customerRoutes,
  warehouseRoutes,
  workshopRoutes,
  interestRoutes,
} from '@dms/routes';

import {composePath} from 'shared';

type EntityResource =
  | 'BUSINESS_CASE'
  | 'VEHICLE'
  | 'EMPLOYEE'
  | 'INVOICE'
  | 'CASH_RECEIPT'
  | 'BALANCE_INVOICE'
  | 'INVOICE_PROFORMA'
  | 'CASH_REGISTER_DOCUMENT'
  | 'WAREHOUSE_ISSUE_NOTE_DETAIL'
  | 'WAREHOUSE_RECEIVE_NOTE_DETAIL'
  | 'WAREHOUSE_RECEIVE_NOTE_CORRECTION_DETAIL'
  | 'WAREHOUSE_DIRECT_SALE_DETAIL'
  | 'WAREHOUSE_SERVICE_ORDER_ISSUE_NOTE_DETAIL'
  | 'WORKSHOP_SERVICE_ORDER_DETAIL'
  | 'CUSTOMER'
  | 'INTEREST';

/**
 * @about This is a function that returns a string, which is a path to a resource. It is used in the DataGrid Link renderer.
 *
 * @example entityResourceResolver('VEHICLE', '123') // => '/vehicles/123'
 * @example entityResourceResolver('BUSINESS_CASE', '123') // => '/business-cases/123'
 */
export const entityResourceResolver = (entityResource: string, id: string): string =>
  match(entityResource as EntityResource)
    .with(
      'BUSINESS_CASE',
      always(
        composePath(businessCaseRoutes.overview, {params: {id}, isSearchParamsPreserved: false})
      )
    )
    .with(
      'VEHICLE',
      always(composePath(vehiclesRoutes.detail, {params: {id}, isSearchParamsPreserved: false}))
    )
    .with(
      'EMPLOYEE',
      always(composePath(employeeRoutes.detail, {params: {id}, isSearchParamsPreserved: false}))
    )
    .with(
      'CUSTOMER',
      always(composePath(customerRoutes.detail, {params: {id}, isSearchParamsPreserved: false}))
    )
    .with(
      'INVOICE',
      always(
        composePath(accountingRoutes.invoiceDetail, {params: {id}, isSearchParamsPreserved: false})
      )
    )
    .with(
      'CASH_RECEIPT',
      always(
        composePath(accountingRoutes.cashReceiptDetail, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'INVOICE_PROFORMA',
      always(
        composePath(accountingRoutes.proformaInvoiceDetail, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'CASH_REGISTER_DOCUMENT',
      always(
        composePath(accountingRoutes.cashReceiptDetail, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'BALANCE_INVOICE',
      always(
        composePath(accountingRoutes.balanceInvoiceDetail, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'WAREHOUSE_ISSUE_NOTE_DETAIL',
      always(
        composePath(warehouseRoutes.issueNoteDetailOverview, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'WAREHOUSE_RECEIVE_NOTE_DETAIL',
      always(
        composePath(warehouseRoutes.receiveNoteDetailOverview, {
          params: {receiveNoteId: id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'WAREHOUSE_RECEIVE_NOTE_CORRECTION_DETAIL',
      always(
        composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'WAREHOUSE_DIRECT_SALE_DETAIL',
      always(
        composePath(warehouseRoutes.directSalesDetailOverview, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'WAREHOUSE_SERVICE_ORDER_ISSUE_NOTE_DETAIL',
      always(
        composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    // TODO https://carvago.atlassian.net/browse/T20-68079
    .with(
      'WORKSHOP_SERVICE_ORDER_DETAIL',
      always(
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      'INTEREST',
      always(
        composePath(interestRoutes.detail, {
          params: {id},
          isSearchParamsPreserved: false,
        })
      )
    )
    .exhaustive();
