import {Card, openDialog} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions, Section} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, ActionCallback, QueryFilterObject} from 'features/datagrid';

import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../constants/missingEditingDetailsMessage';
import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {SupplierArticlesAddNew} from './components/SupplierArticlesAddNew';

interface SupplierArticlesProps extends RequiredTestIdProps {}

export function SupplierArticles(props: SupplierArticlesProps) {
  const {warehouseId, articleId} = useWarehouseParams();

  // TODO We don't have POST yet, keep commented out
  // const handleNew = () => {
  //   openDialog(<SupplierArticlesAddNew data-testid={suffixTestId('addNewDialog', props)} />, {
  //     id: 'addNewDialog',
  //     title: i18n.t('general.actions.addNew'),
  //     size: 'large',
  //     scrollBehavior: 'outside',
  //     withAdditionalFooter: true,
  //     'data-testid': suffixTestId('dialogs.addNew', props),
  //   });
  // };

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      throw new Error('Supplier article id is not defined');
    }

    match(actionKey)
      .with('edit', () => {
        const supplierArticleId = path(['supplierArticleId', 'value'], rowData) as string;

        if (isNil(supplierArticleId)) {
          throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
        }

        openDialog(
          <SupplierArticlesAddNew
            isEditing
            warehouseId={warehouseId}
            articleId={articleId}
            supplierArticleId={supplierArticleId}
            onAfterSubmit={refreshData}
            data-testid={suffixTestId('addNewDialog', props)}
          />,
          {
            id: 'editingDialog',
            title: i18n.t('entity.warehouse.labels.editSupplierArticle'),
            size: 'large',
            scrollBehavior: 'inside',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('dialogs.edit', props),
          }
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {articleId}]),
    [articleId]
  );

  return (
    <Section>
      <Card
        title={i18n.t('entity.warehouse.labels.supplierArticles')}
        // TODO We don't have POST yet, keep commented out
        // actions={[
        //   {
        //     type: 'button',
        //     variant: 'link',
        //     leftIcon: 'content/add_circle',
        //     title: i18n.t('general.actions.addNew'),
        //     onClick: handleNew,
        //     'data-testid': suffixTestId('actions.addNew', props),
        //   },
        // ]}
      >
        <Box height={68}>
          <DataGrid
            gridCode="warehouse-supplier-article"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('supplierArticles', props)}
          />
        </Box>
      </Card>
    </Section>
  );
}
