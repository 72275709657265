import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {
  useGetCustomerContractAssignedCustomerValidityQuery,
  usePatchCustomerContractAssignedCustomerValidityMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

type ValidityFormType = {
  validityUntil: string | Nullish;
};

interface ValidityFormProps extends TestIdProps {
  customerId: string;
  customerContractId: string;
  onClose: VoidFunction;
  afterSubmit: VoidFunction;
}

export function ValidityForm(props: ValidityFormProps) {
  const {data, isLoading, isError} = useGetCustomerContractAssignedCustomerValidityQuery({
    customerId: props.customerId,
    customerContractId: props.customerContractId,
  });

  const [patchAssignedCustomerValidity] =
    usePatchCustomerContractAssignedCustomerValidityMutation();

  const handleSubmit: FormSubmitHandler<ValidityFormType> = (data) =>
    patchAssignedCustomerValidity({
      customerId: props.customerId,
      customerContractId: props.customerContractId,
      body: {
        validityUntil: data.validityUntil ?? '',
      },
    })
      .unwrap()
      .then(() => {
        props.onClose();
        props.afterSubmit();
      })
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={25}>
      <Form<ValidityFormType> defaultValues={data} onSubmit={handleSubmit}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              type="apiDate"
              name="validityUntil"
              label={i18n.t('general.labels.validUntil')}
              data-testid={suffixTestId('validityUntil', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                onClick={props.onClose}
                variant="secondary"
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                title={i18n.t('general.actions.save')}
                type="submit"
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
