import validatePhone from 'phone';
import {string, object, SchemaOf, mixed} from 'yup';

import i18n from '@dms/i18n';

import {PhoneNumberData} from '../types/PhoneNumberData';

// @ts-expect-error Was typed as any before
export const $PhoneNumberData: SchemaOf<PhoneNumberData> = object({
  type: string().defined().nullable(),
  phoneNumber: mixed()
    .test('isPhone', i18n.t('general.validations.invalidPhoneNumber'), valid)
    .defined()
    .nullable(),
});

function valid(value: PhoneNumber | null) {
  if (value === null) {
    return false;
  }
  if (!value || !value.number || value?.number.length === 0) {
    return true;
  }
  return !!validatePhone(value.number, {
    country: value.countryCode,
    validateMobilePrefix: false,
  }).isValid;
}

type PhoneNumber = {
  number: string;
  countryCode: string;
  prefix: string;
};
