import {
  Action,
  closeCurrentDialog,
  openConfirmDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  ContractInformationResponseBodyV2,
  PaymentStateType,
  TaxDocumentForPaymentResponseBody,
  useGetCustomerV2Query,
  useReplaceInvoiceCustomerMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {accountingRoutes, testIds} from '@dms/routes';
import {
  BillingInformationListDialog,
  InvoicePaymentForm,
  handleApiError,
  useGetDocumentData,
  usePermissions,
} from '@dms/shared';

import {Nullish, buildArray, composePath} from 'shared';

import {InvoiceTypes} from '../pages/InvoiceOverview/types';
import {convertContractInformationToInvoiceCustomer} from '../utils/convertContractInformationToInvoiceCustomer';
import {isCanceledOrCanceling} from '../utils/isInvoiceCanceledOrCanceling';
import {useCancelDocument} from './useCancelDocument';
import {useDownloadDocument} from './useDownloadDocument';

/**
 *  document types that can create corrective-tax-document
 **/
const INVOICE_TYPES_THAT_CAN_CREATE_CORRECTIVE_DOCUMENT: InvoiceTypes[] = [
  'ISSUED',
  'BALANCE_INVOICE',
];

//  document types that can be paid
const INVOICE_TYPES_THAT_CAN_BE_PAID: InvoiceTypes[] = [
  'ISSUED',
  'BALANCE_INVOICE',
  'CORRECTIVE_TAX_DOCUMENT',
];
// payment states that can be paid
const PAYMENT_STATES_THAT_CAN_BE_PAID: PaymentStateType[] = [
  'overdue',
  'partially_paid',
  'reopened',
  'confirmed',
];

export const useDocumentActions = (documentType: InvoiceTypes, documentId: string): [Action[]] => {
  const {data: documentData} = useGetDocumentData(documentType, documentId);
  const [downloadDocument, {isLoading: isDownloading}] = useDownloadDocument();
  const [cancelDocument, {isLoading: isCancelling}] = useCancelDocument();
  const [replaceInvoiceCustomer] = useReplaceInvoiceCustomerMutation();

  const [canDownloadDocuments] = usePermissions({permissionKeys: ['downloadDocuments']});

  const {data: customerData} = useGetCustomerV2Query(
    {customerId: documentData?.customer?.id ?? ''},
    {skip: isNilOrEmpty(documentData?.customer?.id)}
  );

  const handleDownloadDocument = (isMargin: boolean) =>
    downloadDocument(documentType, documentId, isMargin);

  const handleCancelDocument = () =>
    openConfirmDialog({
      onConfirm: () => cancelDocument(documentType, documentId),
      'data-testid': testIds.accounting.invoiceDetail('cancel-invoice-dialog'),
      text: i18n.t('page.accounting.invoiceDetail.cancelDialogText', {
        invoiceNumber: documentData?.number,
      }),
    });

  const handleOpenNewCorrectiveTaxDocument = () => {
    window.open(
      composePath(accountingRoutes.newCorrectiveTaxDocument, {
        params: {id: documentId},
        queryParams: {invoiceType: documentType},
      }),
      '_blank'
    );
  };

  const handleUpdateBillingInformation = (data: ContractInformationResponseBodyV2) => {
    if (isNil(customerData?.id)) {
      showNotification.error();
      return;
    }

    replaceInvoiceCustomer({
      invoiceId: documentId,
      ...convertContractInformationToInvoiceCustomer(customerData?.id ?? '', data),
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const openBillingInformationDialog = () => {
    openDialog(
      <BillingInformationListDialog
        customerId={documentData?.customer?.id ?? ''}
        onSave={handleUpdateBillingInformation}
        onClose={closeCurrentDialog}
        data-testid={testIds.accounting.invoiceDetail('BillingInformationList')}
      />,
      {
        scrollBehavior: 'outside',
        'data-testid': testIds.accounting.invoiceDetail('BillingInformationListDialog'),
      }
    );
  };

  const canBePaid =
    documentData?.paymentState &&
    INVOICE_TYPES_THAT_CAN_BE_PAID.includes(documentType) &&
    PAYMENT_STATES_THAT_CAN_BE_PAID.includes(documentData?.paymentState);
  const isReadOnly =
    isCanceledOrCanceling(documentData?.paymentState) || isNotNil(documentData?.cancelledAt);

  const paymentDialogDocumentId = match(documentType)
    .with('PROFORMA', always({proformaId: documentId}))
    .with('CORRECTIVE_TAX_DOCUMENT', always({correctiveTaxDocumentId: documentId}))
    .otherwise(always({invoiceId: documentId}));

  const paymentFormTitle = match(documentType)
    .with(
      'CORRECTIVE_TAX_DOCUMENT',
      always(
        i18n.t('entity.invoice.labels.paymentOfCorrectiveTaxDocument', {
          number: documentData?.number,
        })
      )
    )
    .otherwise(
      always(
        i18n.t('entity.invoice.labels.paymentOfInvoice', {
          number: documentData?.number,
        })
      )
    );

  const cancelActionTitle = match(documentType)
    .with(
      'CORRECTIVE_TAX_DOCUMENT',
      always(i18n.t('entity.invoice.actions.cancelCorrectiveTaxDocument'))
    )
    .otherwise(always(i18n.t('entity.invoice.actions.cancelInvoice')));

  const downloadAction: Action = documentData?.items?.some((item) => item.type === 'margin')
    ? {
        title: i18n.t('general.actions.generatePDF'),
        leftIcon: 'editor/insert_drive_file',
        type: 'dropdown-button',
        menuItems: [
          {
            onClick: () => handleDownloadDocument(false),
            label: i18n.t('entity.invoice.actions.downloadStandardInvoice'),
          },
          {
            onClick: () => handleDownloadDocument(true),
            label: i18n.t('entity.invoice.actions.downloadMarginInvoice'),
          },
        ],
        variant: 'link',
        isLoading: isDownloading,
      }
    : {
        title: i18n.t('general.actions.generatePDF'),
        leftIcon: 'editor/insert_drive_file',
        onClick: () => handleDownloadDocument(false),
        isLoading: isDownloading,
        type: 'button',
        variant: 'link',
      };

  if (isReadOnly) {
    return [[downloadAction]];
  }

  const actions = buildArray<Action>()
    .when(!isDocumentExternal(documentData) && documentData?.cancellable, {
      title: cancelActionTitle,
      leftIcon: 'navigation/cancel',
      type: 'button',
      onClick: handleCancelDocument,
      isLoading: isCancelling,
      variant: 'dangerLink',
      size: 'small',
    })
    .when(INVOICE_TYPES_THAT_CAN_CREATE_CORRECTIVE_DOCUMENT.includes(documentType), {
      title: i18n.t('entity.invoice.actions.createCorrectiveTaxDocument'),
      leftIcon: 'editor/insert_drive_file',
      type: 'button',
      onClick: () =>
        openConfirmDialog({
          onConfirm: handleOpenNewCorrectiveTaxDocument,
          text: i18n.t('entity.invoice.notifications.generateCorrectiveTaxDocumentHelperText', {
            invoiceNumber: documentData?.number,
          }),
          'data-testid': testIds.accounting.invoiceDetail('generateCorrectiveTaxDoc'),
        }),
      variant: 'link',
    })
    .when(documentType === 'ISSUED', {
      leftIcon: 'image/edit',
      variant: 'link',
      type: 'button',
      title: i18n.t('entity.customer.actions.changeBillingInformation'),
      onClick: openBillingInformationDialog,
    })
    .when(canBePaid, {
      leftIcon: 'action/payment',
      variant: 'link',
      type: 'button',
      title: i18n.t('entity.order.actions.pay'),
      onClick: () =>
        openDialog(
          <InvoicePaymentForm
            {...paymentDialogDocumentId}
            data-testid={testIds.accounting.invoiceDetail('paymentDialog')}
          />,
          {
            title: paymentFormTitle,
            'data-testid': testIds.accounting.invoiceDetail('paymentDialog'),
            scrollBehavior: 'outside',
          }
        ),
    })
    .when(canDownloadDocuments, downloadAction);

  return [actions];
};

const isDocumentExternal = (documentData: Record<string, unknown> | Nullish) =>
  (documentData as TaxDocumentForPaymentResponseBody)?.source === 'external';
