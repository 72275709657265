import {
  Form,
  FormField,
  FormSubmitHandler,
  Label,
  TextInput,
  showNotification,
  PhoneNumber,
  DataStatus,
} from 'platform/components';
import {VStack, Text, Box, Grid, GridItem} from 'platform/foundation';

import {useGetCurrentUserInfoQuery, useGetTenantQuery, usePatchCurrentUserMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, usePhoneNumbers} from '@dms/shared';

import {useNavigate} from 'shared';

import {MyProfileFooter} from '../../../components/MyProfileFooter';
import {yupPersonalInformationSchema} from './yupPersonalInformationFormSchema';

type PersonalInformationFormType = {
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
};

export function PersonalInformationForm() {
  const navigate = useNavigate();
  const {countriesOptions} = usePhoneNumbers();
  const {
    data: user,
    isLoading: isUserLoading,
    isError: hasUserError,
  } = useGetCurrentUserInfoQuery();
  const [patchUser] = usePatchCurrentUserMutation();
  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const handleSubmit: FormSubmitHandler<PersonalInformationFormType> = async ({
    firstName,
    lastName,
    phoneNumber,
  }) => {
    const args = {
      updateCurrentUserRequestBody: {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
      },
    };

    return await patchUser(args)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);
  };

  const isLoading = isUserLoading || isTenantLoading;
  const hasError = hasUserError || hasTenantError;

  const defaultValues = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: (user?.phoneNumber as PhoneNumber) ?? {
      prefix: '',
      number: '',
      countryCode: tenant?.country,
    },
  };

  return (
    <DataStatus isLoading={isLoading} isError={hasError} minHeight={50}>
      <Box maxWidth={200}>
        <VStack spacing={6}>
          <Form<PersonalInformationFormType>
            shouldWatchForUnsavedChanges
            defaultValues={defaultValues}
            schema={yupPersonalInformationSchema}
            mode="onBlur"
            onSubmit={handleSubmit}
          >
            {(control, formApi) => (
              <>
                <Grid columns={2} spacing={6}>
                  <GridItem>
                    <FormField
                      control={control}
                      isRequired
                      type="text"
                      name="firstName"
                      label={i18n.t('entity.person.labels.firstName')}
                    />
                  </GridItem>
                  <GridItem>
                    <FormField
                      control={control}
                      isRequired
                      type="text"
                      name="lastName"
                      label={i18n.t('entity.person.labels.lastName')}
                    />
                  </GridItem>

                  <GridItem>
                    <TextInput
                      label={i18n.t('general.labels.email')}
                      value={user?.email ?? ''}
                      isDisabled
                    />
                  </GridItem>
                  <GridItem>
                    <FormField
                      type="phone"
                      control={control}
                      name="phoneNumber"
                      label={i18n.t('entity.person.labels.phoneNumber')}
                      countries={countriesOptions}
                    />
                  </GridItem>
                </Grid>

                <MyProfileFooter
                  actions={[
                    {
                      type: 'button',
                      onClick: () => formApi.reset(defaultValues),
                      title: i18n.t('general.actions.discardChanges'),
                      variant: 'secondary',
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      title: i18n.t('general.actions.saveChanges'),
                    },
                  ]}
                />
              </>
            )}
          </Form>

          <VStack spacing={6}>
            <VStack spacing={1}>
              <Label>{`${i18n.t('page.settings.labels.assignedRole')}:`}</Label>
              <Text>
                {user?.roles?.length
                  ? user.roles
                      .map((role) => role.title)
                      .join(', ')
                      .toString()
                  : '-'}
              </Text>
            </VStack>

            <VStack spacing={1}>
              <Label>{`${i18n.t('page.settings.labels.assignedBranches')}:`}</Label>
              <Text>
                {user?.branches?.length
                  ? user.branches
                      .map((branch) => branch.name)
                      .join(', ')
                      .toString()
                  : '-'}
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </Box>
    </DataStatus>
  );
}
