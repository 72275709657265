import {showNotification} from 'platform/components';

import {find, propEq} from 'ramda';

import {
  useChangeBranchBusinessCaseMutation,
  useGetBusinessCaseQuery,
  useGetCurrentUserInfoQuery,
} from '@dms/api';

import {TestIdProps} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {BranchChanger} from './BranchChanger';

export interface BusinessCaseBranchProps extends TestIdProps {
  recordId: string;
}

export function BusinessCaseBranch(props: BusinessCaseBranchProps) {
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId: props.recordId});
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();

  const [changeBranchBusinessCase] = useChangeBranchBusinessCaseMutation();

  const handleUpdateBusinessCaseBranch = (branchId: string) =>
    changeBranchBusinessCase({
      businessCaseId: props.recordId,
      changeBranchBusinessCaseRequestBody: {
        branchId,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const activeBranch = find(propEq(businessCase?.branchId, 'id'), currentUserInfo?.branches ?? []);

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={activeBranch}
      onChange={handleUpdateBusinessCaseBranch}
      data-testid={props['data-testid']}
    />
  );
}
