import i18n from 'i18next';
import {DataStatus, showNotification} from 'platform/components';

import {useParams, useSearchParams} from 'react-router-dom';

import {useApplySharedPresetMutation} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {useNavigate, useOnMount} from 'shared';

export function SharePreset() {
  const {code, sharedPresetId} = useParams();
  const [searchParams] = useSearchParams();
  const [applySharedPresetMutation, {isLoading, isError}] = useApplySharedPresetMutation();
  const navigate = useNavigate();

  useOnMount(() => {
    const redirectUrl = searchParams.get('q');

    if (code && sharedPresetId && redirectUrl) {
      applySharedPresetMutation({
        code,
        applySharedPresetRequestBody: {
          sharedPresetId,
        },
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.presetApplied'));
          navigate(decodeURIComponent(redirectUrl));
        })
        .catch(handleApiError);
    }
  });
  return <DataStatus isLoading={isLoading} isError={isError} />;
}
