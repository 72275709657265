import {Actions, ChipsOption, openConfirmDialog, showNotification} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {RefObject} from 'react';

import {isNotEmpty} from 'ramda';

import {useDeleteTaskMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps, useOnMount, useQueryState} from 'shared';

import {ActionCallback, DataGrid, DataGridRef} from 'features/datagrid';

import {PRESET_FILTERS} from '../../constants/filters';
import {useGridCustomFilters} from '../../hooks/useGridCustomFilters';
import {PresetFilter} from '../../types/PresetFilter';
import {openEditTaskFormDialog} from '../../utils/openEditTaskFormDialog';

interface TasksDataGridProps extends RequiredTestIdProps {
  dataGridRef: RefObject<DataGridRef>;
}

export function TasksDataGrid(props: TasksDataGridProps) {
  const [deleteTask] = useDeleteTaskMutation();
  const [queryFilterValue] = useQueryState('filter');

  const {
    filterValue,
    isLoading: isLoadingCustomFilter,
    onFilterChange,
    onInternalGridFilterUpdate,
  } = useGridCustomFilters('task');

  const presetFilters: ChipsOption[] = [
    {
      label: i18n.t('entity.task.labels.myTasks'),
      value: PRESET_FILTERS.ASSIGNED_TO_ME,
      isDisabled: isLoadingCustomFilter,
    },
    {
      label: i18n.t('entity.task.labels.byMeTasks'),
      value: PRESET_FILTERS.CREATED_BY_ME,
      isDisabled: isLoadingCustomFilter,
    },
  ] as const;

  useOnMount(() => {
    if (queryFilterValue) {
      onFilterChange(queryFilterValue as PresetFilter);
    }
  });

  const dataGridActionsHandler: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('detail', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot view multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': testIds.taskManager.taskList('editTaskDialog'),
        });
      })
      .with('edit', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot delete multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': testIds.taskManager.taskList('editTaskDialog'),
        });
      })
      .with('delete', () => {
        openConfirmDialog({
          onConfirm: () => {
            if (Array.isArray(rowId)) {
              // This should never happen, but just in case
              throw new Error('Cannot delete multiple tasks at once');
            }
            deleteTask({id: rowId})
              .unwrap()
              .then(() => {
                refreshData();
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
              })
              .catch(handleApiError);
          },
        });
      });
  };

  return (
    <VStack spacing={5} height="100%">
      <Actions
        data-testid={props['data-testid']}
        actions={[
          {
            type: 'chips',
            onChange: (value) => {
              onFilterChange(value?.[0] as PresetFilter);
            },
            isMultiple: false,
            value: isNotEmpty(filterValue) ? [filterValue!] : [],
            options: presetFilters,
          },
        ]}
      />
      <DataGrid
        // eslint-disable-next-line no-restricted-syntax
        key={filterValue}
        ref={props.dataGridRef}
        data-testid={props['data-testid']}
        actionCallback={dataGridActionsHandler}
        onFilterChanged={onInternalGridFilterUpdate}
        gridCode="task"
        emptyState={{
          headline: i18n.t('general.labels.noData'),
        }}
      />
    </VStack>
  );
}
