import {addDays, differenceInDays, endOfDay, endOfToday} from 'date-fns';
import {AlertVariants} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import i18n from '@dms/i18n';

type RegistrationAlertData = {
  title: string;
  description: string;
  variant: AlertVariants;
  remainingDaysForRegistration: number;
  dueDateForRegistration: Date;
};

/**
 *
 * @param purchaseDateAt - in format 2024-05-31
 * @param formatDateTime - utility function from useDateTimeFormatter()
 */
export const getRegistrationAlertData = ({
  purchaseDateAt,
  formatDateTime,
}: {
  purchaseDateAt: string | Date;
  formatDateTime: ReturnType<typeof useDateTimeFormatter>;
}): RegistrationAlertData => {
  const dueDate = addDays(endOfDay(purchaseDateAt), 90);
  const remainingDaysForRegistration = differenceInDays(dueDate, endOfToday());
  return {
    title: (() => {
      if (remainingDaysForRegistration === 0) {
        return i18n.t('entity.vehicle.registrationAlert.title.today');
      }
      if (remainingDaysForRegistration < 0) {
        return i18n.t('entity.vehicle.registrationAlert.title.beforeDays', {
          count: remainingDaysForRegistration * -1,
        });
      }
      return i18n.t('entity.vehicle.registrationAlert.title.withinDays', {
        count: String(remainingDaysForRegistration),
      });
    })(),
    description:
      remainingDaysForRegistration < 0
        ? i18n.t('entity.vehicle.registrationAlert.description.beforeDays', {
            date: formatDateTime('dateMedium', dueDate),
          })
        : i18n.t('entity.vehicle.registrationAlert.description.withinDays', {
            date: formatDateTime('dateMedium', dueDate),
          }),
    variant: remainingDaysForRegistration >= 0 ? 'warning' : 'error',
    remainingDaysForRegistration,
    dueDateForRegistration: dueDate,
  };
};
