import {
  GetServiceOrderCustomerContractDetailsApiArg,
  GetServiceOrderCustomerContractDetailsApiArgSchema,
  GetServiceOrderCustomerContractDetailsApiResponse,
  GetServiceOrderCustomerContractDetailsApiResponseSchema,
  PostServiceOrderCustomerContractDeleteApiArg,
  PostServiceOrderCustomerContractDeleteApiArgSchema,
  PostServiceOrderCustomerContractDeleteApiResponse,
  PostServiceOrderCustomerContractDeleteApiResponseSchema,
  PutServiceOrderCustomerContractChangeApiArg,
  PutServiceOrderCustomerContractChangeApiArgSchema,
  PutServiceOrderCustomerContractChangeApiResponse,
  PutServiceOrderCustomerContractChangeApiResponseSchema,
} from '../types/metadaWorkshopServiceOrderCustomerContract';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderCustomerContractApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderCustomerContractDetails: build.query<
      GetServiceOrderCustomerContractDetailsApiResponse,
      GetServiceOrderCustomerContractDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/customer-contract`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderCustomerContractDetailsApiResponseSchema,
        requestSchema: GetServiceOrderCustomerContractDetailsApiArgSchema,
      },
    }),
    postServiceOrderCustomerContractDelete: build.mutation<
      PostServiceOrderCustomerContractDeleteApiResponse,
      PostServiceOrderCustomerContractDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/customer-contract/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        'afsPaymentIssueRestriction',
      ],
      extraOptions: {
        responseSchema: PostServiceOrderCustomerContractDeleteApiResponseSchema,
        requestSchema: PostServiceOrderCustomerContractDeleteApiArgSchema,
      },
    }),
    putServiceOrderCustomerContractChange: build.mutation<
      PutServiceOrderCustomerContractChangeApiResponse,
      PutServiceOrderCustomerContractChangeApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/customer-contract`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderCustomerContract', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobTotalAmount', id: queryArg.serviceOrderId},
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        'afsPaymentIssueRestriction',
      ],
      extraOptions: {
        responseSchema: PutServiceOrderCustomerContractChangeApiResponseSchema,
        requestSchema: PutServiceOrderCustomerContractChangeApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderCustomerContractDetailsQuery,
  usePostServiceOrderCustomerContractDeleteMutation,
  usePutServiceOrderCustomerContractChangeMutation,
} = metadaWorkshopServiceOrderCustomerContractApi;
