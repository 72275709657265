import {showNotification} from 'platform/components';
import {Space, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function NotificationSettingsOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('detail', () =>
        navigate(composePath(settingsRoutes.notificationsDetail, {params: {id}}))
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.notifications.title'),
      }}
      data-testid={testIds.settings.notificationsOverview('settings-template')}
    >
      <Text color="secondary" size="small">
        {i18n.t('page.settings.notifications.subtitle')}
      </Text>
      <Space vertical={4} />
      <DataGrid
        gridCode="messaging-tenant-notification"
        actionCallback={actionCallback}
        data-testid={testIds.settings.notificationsOverview('datagrid')}
      />
    </SettingsTemplate>
  );
}
