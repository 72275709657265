import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';
import {object} from 'yup';

import {PostBillingInformationV2ApiArg, useGetTenantV2Query} from '@dms/api';
import i18n from '@dms/i18n';
import {useCurrencies} from '@dms/shared';

import {yupString} from 'shared';

type FormValues = PostBillingInformationV2ApiArg['bankAccounts'][number];

interface DeputyPersonFormProps {
  handleSubmit: (values: FormValues) => void;
  defaultValues?: FormValues;
}

export function BankAccountForm(props: DeputyPersonFormProps) {
  const {data: tenant} = useGetTenantV2Query();
  const {currencyOptions} = useCurrencies();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    const submitValues: FormValues = {
      ...values,
      isPrimary: false,
    };

    await props.handleSubmit(submitValues);
    closeCurrentDialog();
  };

  const defaultValues = {
    ...props.defaultValues,
    currency: props.defaultValues?.currency ?? tenant?.currency,
  };

  return (
    <Form<FormValues> defaultValues={defaultValues} onSubmit={handleSubmit} schema={schema}>
      {(control) => (
        <VStack spacing={5}>
          <Grid columns={4}>
            <GridItem span={4}>
              <FormField
                control={control}
                name="accountName"
                type="text"
                isRequired
                label={i18n.t('entity.bank.labels.accountName')}
              />
            </GridItem>
            <GridItem span={2}>
              <FormField
                control={control}
                name="accountNumber"
                type="text"
                isRequired
                label={i18n.t('entity.bank.labels.accountNumber')}
              />
            </GridItem>
            <GridItem span={2}>
              <FormField
                control={control}
                name="bankName"
                type="text"
                label={i18n.t('entity.bank.labels.name')}
              />
            </GridItem>
            <GridItem span={2}>
              <FormField
                control={control}
                name="iban"
                type="text"
                label={i18n.t('entity.bank.labels.iban')}
              />
            </GridItem>
            <FormField
              control={control}
              name="swift"
              type="text"
              label={i18n.t('entity.bank.labels.swift')}
            />

            <FormField
              control={control}
              name="currency"
              isNotClearable
              type="choice"
              label={i18n.t('entity.bank.labels.currency')}
              options={currencyOptions}
            />
          </Grid>

          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              variant="secondary"
            />
            <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  accountName: yupString.required(),
  accountNumber: yupString.required(),
  bankName: yupString.default(null),
  iban: yupString.default(null),
  swift: yupString.default(null),
  currency: yupString.default(null),
});
