import {DataStatus} from 'platform/components';

import {UserRole} from '@dms/api';

import {useInsuranceData} from '../../hooks/useInsuranceData';
import {RecommendReminderForm} from './components/RecommendReminderForm';
import {SellReminderForm} from './components/SellReminderForm';

interface InsuranceReminderProps {
  businessCaseId: string;
  onDone: VoidFunction;
  onCancel: VoidFunction;
}

export function InsuranceReminder(props: InsuranceReminderProps) {
  const insuranceData = useInsuranceData(props.businessCaseId, true);
  const isSeller = insuranceData.role === UserRole.SELLER;

  return (
    <DataStatus isLoading={insuranceData.isLoading} isError={insuranceData.isError} minHeight={50}>
      {isSeller ? (
        <SellReminderForm
          insuranceData={insuranceData}
          businessCaseId={props.businessCaseId}
          onCancel={props.onCancel}
          onDone={props.onDone}
        />
      ) : (
        <RecommendReminderForm
          insuranceData={insuranceData}
          businessCaseId={props.businessCaseId}
          onCancel={props.onCancel}
          onDone={props.onDone}
        />
      )}
    </DataStatus>
  );
}
