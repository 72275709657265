import {testIds} from '@dms/routes';

import {DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';

export function IssueNoteCorrections() {
  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode="warehouse-issue-note-corrections"
        data-testid={testIds.warehouse.issueNoteCorrections('list')}
      />
    </FullscreenDataGridWrapper>
  );
}
