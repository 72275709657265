import {openConfirmDialog} from 'platform/components';

import {isString} from 'ramda-adjunct';

import {useDeleteManufacturerMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehousesManufacturers() {
  const navigate = useNavigate();

  const [deleteManufacturer] = useDeleteManufacturerMutation();

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.manufacturers'),
  };

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData}) => {
    switch (actionKey) {
      case 'redirectDetail':
      case 'edit':
        if ('id' in rowData && isString(rowData.id)) {
          navigate(
            composePath(settingsRoutes.warehousesManufacturersDetail, {
              params: {id: rowData.id},
            })
          );
        }
        break;
      case 'delete':
        if ('id' in rowData) {
          openConfirmDialog({
            text: i18n.t('general.actions.delete'),
            onConfirm: () => {
              deleteManufacturer({manufacturerId: rowData.id as string})
                .unwrap()
                .then(() => refreshData())
                .catch(handleApiError);
            },
          });
        }
        break;
    }
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesManufacturers('template')}
      header={header}
    >
      <DataGrid
        gridCode="warehouse-manufacturer"
        actionCallback={actionCallback}
        emptyState={{
          headline: i18n.t('page.warehouseDetail.labels.emptyManufacturers'),
        }}
        data-testid={testIds.settings.warehousesManufacturers('warehouse-manufacturer')}
      />
    </SettingsTemplate>
  );
}
