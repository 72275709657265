import {useParams} from 'react-router-dom';

import {always, ifElse, isNil, reject} from 'ramda';

import {useGetServicePackageBasicInformationQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {Nullish} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {BasicInformationForm} from './(sections)/BasicInformationForm/BasicInformationForm';
import {RequestDetailForm} from './(sections)/RequestDetailForm/RequestDetailForm';

export function WorkshopServicePackageDetail() {
  const {id} = useParams();

  const isCreating = isNil(id);

  const {data, isLoading, isError} = useGetServicePackageBasicInformationQuery(
    {servicePackageId: id ?? ''},
    {skip: isCreating}
  );

  const header: SettingsTemplateHeader = {
    title: isCreating
      ? i18n.t('entity.workshopServicePackages.labels.newServicePackage')
      : (data?.name ?? undefined),
    breadcrumbs: [
      {
        label: i18n.t('entity.workshopServicePackages.labels.title'),
        href: settingsRoutes.workshopServicePackages,
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      isCreating={isCreating}
      isError={isError}
      isLoading={isLoading}
      tabs={[
        {
          queryId: 'basicInformation',
          title: i18n.t('entity.workshopServicePackages.labels.basicInformation'),
          content: (
            <BasicInformationForm
              id={id}
              defaultValues={{
                authorizationProfileIds: getDefaulArrayValue(data?.authorizationProfileIds),
                branchIds: getDefaulArrayValue(data?.branchIds),
                isLockServicePackage: data?.isLockServicePackage ?? undefined,
                name: data?.name ?? undefined,
                number: data?.number ?? undefined,
                servicePackageCategoryId: data?.servicePackageCategoryId ?? undefined,
                vehicleAgeFrom: data?.vehicleAgeFrom ?? undefined,
                vehicleAgeTo: data?.vehicleAgeTo ?? undefined,
                vehicleAgeType: data?.vehicleAgeType ?? undefined,
                vehicleMakes: getDefaulArrayValue(data?.vehicleMakes),
                vehicleModelFamilies: getDefaulArrayValue(data?.vehicleModelFamilies),
                vehicleModels: getDefaulArrayValue(data?.vehicleModels),
                vehicleType: data?.vehicleType ?? undefined,
                isDoNotApplyDiscount: data?.isDoNotApplyDiscount ?? undefined,
              }}
              data-testid={testIds.settings.workshopCreateServicePackages('basicInformation')}
            />
          ),
        },
        {
          queryId: 'requestDetail',
          title: i18n.t('entity.workshopServicePackages.labels.requestDetail'),
          content: id ? (
            <RequestDetailForm
              id={id}
              data-testid={testIds.settings.workshopCreateServicePackages('requestDetail')}
            />
          ) : null,
          isDisabled: isCreating,
        },
      ]}
      data-testid={testIds.settings.workshopCreateServicePackages('template')}
    />
  );
}

const getDefaulArrayValue = ifElse<
  (string | null)[] | Nullish,
  null | undefined,
  undefined,
  string[]
>(isNil, always(undefined), reject(isNil));
