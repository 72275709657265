import {object, array, boolean} from 'yup';

import i18n from '@dms/i18n';

import {yupNumber, yupString} from 'shared';

import {SymbolType} from '../types/MultipleLocationsForm';

export const multipleLocationsSchema = object().shape({
  definition: array().of(
    object().shape({
      isSelected: boolean(),
      beginAt: yupString.when('isSelected', {
        is: true,
        then: (schema) =>
          schema.required(i18n.t('general.validations.fieldIsRequired')).when('symbolType', {
            is: SymbolType.Alphabet,
            then: (schema) =>
              schema.matches(
                /^[A-Z]{1,2}$/,
                i18n.t('entity.tireWarehouses.validations.onlyTwoLettersAllowed')
              ),
            otherwise: (schema) =>
              schema.when('symbolType', {
                is: SymbolType.Numbers,
                then: (schema) =>
                  schema.matches(
                    /^\d+$/,
                    i18n.t('entity.tireWarehouses.validations.onlyNumbersAllowed')
                  ),
                otherwise: (schema) => schema.nullable(),
              }),
          }),
        otherwise: (schema) => schema.nullable(),
      }),
      number: yupNumber
        .required(i18n.t('general.validations.fieldIsRequired'))
        .min(1, i18n.t('general.validations.minValue', {min: 1}))
        .max(99, i18n.t('general.validations.maxValue', {max: 99})),
      vectorType: yupString,
      vectorPosition: yupNumber,
      symbolType: yupString,
    })
  ),
});
