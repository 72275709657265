import {openDialog} from 'platform/components';

import i18n from '@dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {EditTask} from '../components/EditTask/EditTask';
import {TaskRelationProps} from '../types/TaskRelationProps';

interface OpenEditTaskFormDialogProps extends RequiredTestIdProps, TaskRelationProps {
  id: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function openEditTaskFormDialog(props: OpenEditTaskFormDialogProps) {
  openDialog(
    <EditTask
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      id={props.id}
      data-testid={props['data-testid']}
      relatedRecordId={props.relatedRecordId}
      resourceId={props.resourceId}
    />,
    {
      'data-testid': props['data-testid'],
      title: i18n.t('entity.task.actions.editTask'),
      size: 'large',
      withAdditionalFooter: true,
    }
  );
}
