import {useState} from 'react';
// We need to use setSearchParams from react-router-dom to update the URL query params
// eslint-disable-next-line no-restricted-imports
import {useSearchParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetCurrentUserInfoQuery} from '@dms/api';
import {DataQueryOptions, useLazyDataQuery} from '@dms/shared';

import {buildObject} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {PRESET_FILTERS} from '../constants/filters';
import {PresetFilter} from '../types/PresetFilter';

export const useGridCustomFilters = (code: string) => {
  const [filterValue, setFilterValue] = useState<PresetFilter>();
  const [, setQueryParams] = useSearchParams();

  const [getGridQueryParams, {isLoading: isLoadingGridQuery}] = useLazyDataQuery(code);
  const {data: currentUser} = useGetCurrentUserInfoQuery();

  const updateFilterState = (filterType: PresetFilter) => {
    setFilterValue(filterType);
    setQueryParams({filter: filterType});
  };

  const clearFilters = () => {
    onFilterChange();
  };

  const onFilterChange = (option?: PresetFilter) => {
    if (option === undefined || option === filterValue) {
      getGridQueryParams({}).then((params) => {
        setFilterValue(undefined);
        setQueryParams({...params, filter: ''});
        refreshDatagrid(code);
      });
      return;
    }
    if (isNilOrEmpty(currentUser)) {
      return;
    }
    const options: DataQueryOptions = {
      filters: buildObject<{
        assignedTo: [string];
        reportedBy: [string];
      }>()
        .assignedTo([currentUser!.id], option === PRESET_FILTERS.ASSIGNED_TO_ME)
        .reportedBy([currentUser!.id], option === PRESET_FILTERS.CREATED_BY_ME)
        .build(),
    };
    getGridQueryParams(options).then((params) => {
      setFilterValue(option);
      setQueryParams({...params, filter: option});
      refreshDatagrid(code);
    });
  };

  // 'Any' is used here, because it is the type of the filters object in the grid component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInternalGridFilterUpdate = (filters: any) => {
    if (isNilOrEmpty(filters)) {
      clearFilters();
      return;
    }

    const isAssignedToCurrentUser =
      filters?.assignedTo?.length === 1 && filters?.assignedTo[0] === currentUser?.id;

    const isReportedByCurrentUser =
      filters?.reportedBy?.length === 1 && filters?.reportedBy[0] === currentUser?.id;

    const hasMultipleFilters = filters?.assignedTo?.length > 1 || filters?.reportedBy?.length > 1;

    if (isAssignedToCurrentUser) {
      updateFilterState(PRESET_FILTERS.ASSIGNED_TO_ME);
    } else if (isReportedByCurrentUser) {
      updateFilterState(PRESET_FILTERS.CREATED_BY_ME);
    } else if (hasMultipleFilters) {
      clearFilters();
    }
  };

  return {
    filterValue,
    onFilterChange,
    onInternalGridFilterUpdate,
    isLoading: isLoadingGridQuery,
  };
};
