import {DataStatus} from 'platform/components';

import {useGetMetadaServiceCaseQuery} from '@dms/api';
import {testIds} from '@dms/routes';
import {Section, VehicleOverview} from '@dms/shared';

import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';

export function Vehicle() {
  const {serviceCaseId} = useWorkshopUrl();

  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId});

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('vehicle')}>
      <DataStatus isLoading={isServiceCaseLoading} isError={isServiceCaseError} minHeight={100}>
        <VehicleOverview
          vehicleId={serviceCase?.vehicleId ?? ''}
          data-testid={testIds.workshop.serviceCaseDetail('vehicle')}
        />
      </DataStatus>
    </Section>
  );
}
