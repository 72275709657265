import validatePhone from 'phone';
import {boolean, object, mixed, SchemaOf, string} from 'yup';

import i18n from '@dms/i18n';

import {ContactPersonRequestBody} from '../types/ContactPersonRequestBody';

export const $ContactPersonRequestBody: SchemaOf<ContactPersonRequestBody> = object({
  firstName: string().defined().nullable(),
  lastName: string().defined().nullable(),
  isPrimary: boolean().defined().nullable(),
  role: string().defined().nullable(),
  emailAddress: string().defined().nullable(),
  phoneNumber: mixed()
    .test('isPhone', i18n.t('general.validations.invalidPhoneNumber'), valid)
    .defined()
    .nullable(),
});

function valid(value: PhoneNumber | null) {
  if (value === null) {
    return false;
  }
  if (!value || !value.number || value?.number.length === 0) {
    return true;
  }
  return !!validatePhone(value.number, {
    country: value.countryCode,
    validateMobilePrefix: false,
  }).isValid;
}

type PhoneNumber = {
  number: string;
  countryCode: string;
  prefix: string;
};
