import {
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useNavigate} from 'react-router-dom';

import {
  useGetBackgroundRemovalSettingsQuery,
  usePutBackgroundRemovalSettingsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

import {BackgroundRemovalFormValues} from '../types/BackgroundRemovalFormValues';
import {BackgroundSelector} from './BackgroundSelector';

const backgroundIds = ['49716', '61155', '79610', '12560'];

export function BackgroundRemovalFormOld() {
  const navigate = useNavigate();

  const {data: settings, isLoading, isError} = useGetBackgroundRemovalSettingsQuery();

  const [saveSettings] = usePutBackgroundRemovalSettingsMutation();

  const onSubmit: FormSubmitHandler<BackgroundRemovalFormValues> = (values) =>
    saveSettings({body: values})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        navigate(settingsRoutes.advertising);
      })
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<BackgroundRemovalFormValues>
        onSubmit={onSubmit}
        schema={schema}
        defaultValues={settings}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.labels.replaceBackground')}
              control={control}
              name="backgroundReplacementEnabled"
              type="switch"
              isDisabled
              data-testid="settings-backgroundRemovalFormOld-backgroundReplacementEnabled"
            />
            <Box paddingLeft={12}>
              <VStack spacing={4}>
                <Text size="small" color="tertiary">
                  {i18n.t('page.salesSettings.backgroundRemoval.description.replaceBackground')}
                </Text>
                <BackgroundSelector
                  name="backgroundId"
                  options={backgroundIds}
                  control={control}
                  formApi={formApi}
                />
              </VStack>
            </Box>

            <Separator spacing={2} />

            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.labels.replacePlate')}
              control={control}
              name="registrationPlateRemovalEnabled"
              type="switch"
              data-testid="settings-backgroundRemovalFormOld-registrationPlateRemovalEnabled"
            />
            <Box paddingLeft={12}>
              <VStack spacing={4}>
                <Text size="small" color="tertiary">
                  {i18n.t('page.salesSettings.backgroundRemoval.description.replacePlate')}
                </Text>
              </VStack>
            </Box>

            <Separator spacing={2} />

            {/* Hidden option - see T20-63137
              <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.labels.tintWindows')}
              control={control}
              name="windowTintingEnabled"
              type="switch"
              isDisabled
              data-testid="settings-backgroundRemovalFormOld-windowTintingEnabled"
            />
            <Box paddingLeft={12}>
              <Text size="small" color="tertiary">
                {i18n.t('page.salesSettings.backgroundRemoval.description.tintWindows')}
              </Text>
            </Box>

            <Separator spacing={2} />*/}

            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discardChanges')}
                variant="secondary"
                onClick={() => navigate(settingsRoutes.advertising)}
                data-testid="settings-backgroundRemovalFormOld-discard-btn"
              />
              <FormButton
                title={i18n.t('general.actions.saveAndClose')}
                control={control}
                type="submit"
                data-testid="settings-backgroundRemovalFormOld-submit-btn"
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  backgroundReplacementEnabled: boolean().defined().isTrue(),
  backgroundId: yupString,
  registrationPlateRemovalEnabled: boolean().defined(),
  //windowTintingEnabled: boolean().defined(),
});
