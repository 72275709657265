import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ViewUser} from './components/ViewUser';

export function UserEdit() {
  const {id} = useRequiredParams();

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.settings.labels.userManagement'),
      href: settingsRoutes.userManagement,
    },
  ];

  return (
    <SettingsTemplate header={{breadcrumbs}} data-testid="user-detail">
      <ViewUser id={id} />
    </SettingsTemplate>
  );
}
