import {FilterValues, SmartSearch, useLazyCreateDataQueryQuery} from '@dms/api';

import {convertToSearchParams, OrderByRequestBody} from 'features/datagrid';

export type DataQueryOptions = {
  filters?: FilterValues;
  order?: OrderByRequestBody[];
  smartSearch?: SmartSearch;
};

export function useLazyDataQuery(code: string) {
  const [createDataQuery, rest] = useLazyCreateDataQueryQuery();

  const getQueryParams = (options: DataQueryOptions) =>
    createDataQuery({
      code,
      createDataQueryRequestBody: {
        filters: options?.filters ?? {},
        order: options?.order ?? [],
        smartSearch: options?.smartSearch ?? null,
      },
    }).then(({data}) => {
      if (data?.dataQueryId) {
        return convertToSearchParams({
          dataGrids: {
            gridCode: code,
            queryId: data.dataQueryId,
            rowIndex: '1',
          },
        });
      }
    });

  return [
    getQueryParams,
    {
      gridCode: code,
      ...rest,
    },
  ] as const;
}
