import {
  closeDialog,
  DataStatus,
  FormSubmitHandler,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {Hide, VStack} from 'platform/foundation';

import {useEffect, useReducer} from 'react';

import {add} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useDeleteServiceOrderJobMutation,
  useGetServiceCaseOrderJobsQuery,
  usePatchServiceOrderJobMutation,
  usePostServiceOrderJobMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, queryParams, JobFormProps, JobType, PostponeDialog} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {Job} from '../../../../../components/Job/Job';
import {JobInput, JobInputForm} from '../../../../../components/JobInput/JobInput';
import {CustomPrice} from './CustomPrice';
import {MoveRequest} from './MoveRequest';

interface OrderJobsProps extends TestIdProps {
  orderId: string;
  orderVariantId?: string;
  serviceCaseId: string;
  isReadOnly?: boolean;
  datagridKey?: string;
  isReportButtonDisabled?: boolean;
}

export function OrderJobs(props: OrderJobsProps) {
  const [jobRefreshKey, reloadJob] = useReducer(add(1), 0);

  const {data, isFetching, isError} = useGetServiceCaseOrderJobsQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.filter((job) => !job?.isUncategorized || job?.hasItems),
      }),
    }
  );

  const [deleteServiceOrderJob] = useDeleteServiceOrderJobMutation();
  const [patchServiceOrderJob] = usePatchServiceOrderJobMutation();
  const [postServiceOrderJob] = usePostServiceOrderJobMutation();

  const [_, setOpendJobId] = useQueryState(queryParams.SERVICE_CASE_OPEN_JOB_ID);

  const onSubmit: FormSubmitHandler<JobInputForm> = async ({job}) => {
    await postServiceOrderJob({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      body: {name: job},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleUpdateJob: JobFormProps['onSubmit'] = async (data, reset) => {
    if (!data?.id) {
      return;
    }

    await patchServiceOrderJob({
      body: data,
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      serviceJobId: data?.id,
    })
      .unwrap()
      .then(() => {
        reset();
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const handleRemoveJob = (job: JobType | Nullish) => {
    if (!job?.id) {
      return;
    }

    openDeleteDialog({
      text: i18n.t('entity.orderRequest.actions.delete', {name: job?.name}),
      'data-testid': suffixTestId('removeJob', props),
      onConfirm: () =>
        deleteServiceOrderJob({
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.orderId,
          serviceJobId: job.id ?? '',
        })
          .unwrap()
          .catch(handleApiError),
    });
  };

  const handleMoveJob = (job: JobType | Nullish) => {
    if (!job?.id) {
      return;
    }

    openDialog(
      <MoveRequest
        serviceCaseId={props.serviceCaseId}
        serviceOrderId={props.orderId}
        serviceJobId={job.id}
        onClose={() => closeDialog('moveJobDialog')}
        data-testid={suffixTestId('moveJobDialog', props)}
      />,
      {
        id: 'moveJobDialog',
        title: `${i18n.t('entity.orderRequest.actions.moveRequest')} (${job?.name})`,
      }
    );
  };

  const handlePostponeJob = (job: JobType | Nullish) => {
    if (!job?.id) {
      return;
    }

    openDialog(
      <PostponeDialog
        dialogId="postpone-dialog"
        jobsIds={job.id}
        postponeUntil={null}
        reason={null}
        serviceCaseId={props.serviceCaseId}
        serviceOrderId={props.orderId}
        switchJobToPostponed
        data-testid={suffixTestId('postponeDialog', props)}
      />,
      {
        id: 'postpone-dialog',
        title: i18n.t('entity.serviceCase.labels.postpone'),
        size: 'small',
        withAdditionalFooter: true,
      }
    );
  };

  const onCustomPriceClose = () => {
    closeDialog('customPriceDialog');
    reloadJob();
  };

  const handleCustomPrice = (job: JobType | Nullish) => {
    if (!job) {
      return;
    }

    openDialog(
      <CustomPrice
        serviceCaseId={props.serviceCaseId}
        serviceOrderId={props.orderId}
        job={job}
        onClose={onCustomPriceClose}
      />,
      {
        id: 'customPriceDialog',
        title: i18n.t('entity.orderRequest.actions.customPrice'),
      }
    );
  };

  useEffect(() => {
    if (data?.length === 1) {
      setOpendJobId(data?.[0]?.id ?? '');
    }
    // We just want to open job when is only one in array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  return (
    <>
      <VStack spacing={4}>
        <DataStatus
          isLoading={isFetching}
          isError={isError}
          isEmpty={isNilOrEmpty(data)}
          minHeight={30}
          data-testid={suffixTestId('orders', props)}
          emptyMessage={i18n.t('entity.order.labels.emptyRequests')}
          emptySubheadline={i18n.t('entity.order.labels.emptyRequestsDescription')}
        >
          {data?.map((job, index) => (
            <Job
              key={`${job?.id ?? index}${jobRefreshKey}`}
              isReadOnly={props.isReadOnly}
              jobData={job}
              serviceCaseId={props.serviceCaseId}
              orderId={props.orderId}
              datagridKey={props.datagridKey}
              actions={[
                {
                  key: 'job-customPrice',
                  label: i18n.t('entity.orderRequest.actions.customPrice'),
                  onClick: () => handleCustomPrice(job),
                },
                {
                  key: 'job-postpone',
                  label: i18n.t('entity.orderRequest.actions.postpone'),
                  onClick: () => handlePostponeJob(job),
                },
                {
                  key: 'job-move',
                  label: i18n.t('entity.orderRequest.actions.moveRequest'),
                  onClick: () => handleMoveJob(job),
                },
                {
                  key: 'job-delete',
                  label: i18n.t('entity.orderRequest.actions.deleteRequest'),
                  onClick: () => handleRemoveJob(job),
                },
              ]}
              onSubmit={handleUpdateJob}
              isReportButtonDisabled={props.isReportButtonDisabled}
              data-testid={suffixTestId(`job-[${index}]`, props)}
            />
          ))}
        </DataStatus>
        <Hide when={props.isReadOnly}>
          <JobInput onSubmit={onSubmit} data-testid={suffixTestId('job', props)} />
        </Hide>
      </VStack>
    </>
  );
}
