import {Label, ColorSchemeType, DropdownTag, DropdownTagMenuItem} from 'platform/components';
import {Box, Show, Space, Text} from 'platform/foundation';

import {FC} from 'react';
import {FieldInputProps} from 'react-final-form';

import i18n from '@dms/i18n';
import {
  EVALUATION_UNKNOWN_COLOR,
  EVALUATION_UNKNOWN_FLAG,
  evaluationColors,
  getEvaluationTranslations,
} from '@dms/shared';

import {convertStringToCamelCase, TestIdProps} from 'shared';

import {AuditParamValue} from '../../../types/AuditParamValue';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useConditionContext} from '../hooks/useConditionContext';

export type OverallEvaluationProps = {
  label: string;
  options?: AuditParamValue[];
  name: string;
  hasError?: boolean;
} & TestIdProps;

export const OverallEvaluation: FC<OverallEvaluationProps> = ({
  label,
  options,
  name,
  hasError,
  ...rest
}) => {
  const {isDisabledForUser} = useConditionContext();

  const {Field} = useFormRenderer();

  const getEvaluationLabel = (value: number) => getEvaluationTranslations()[value];

  const getOptionsData = (input: FieldInputProps<string, HTMLElement>): DropdownTagMenuItem[] => {
    const _options: DropdownTagMenuItem[] = [
      {
        label: i18n.t('entity.condition.labels.statusUnknown'),
        onClick: () => input.onChange(0),
        flag: {
          label: EVALUATION_UNKNOWN_FLAG,
          colorScheme: EVALUATION_UNKNOWN_COLOR,
        },
      },
    ];

    options?.forEach((option) => {
      const value = parseInt(option.value);

      _options.push({
        label: getEvaluationLabel(value),
        onClick: () => input.onChange(value),
        flag: {
          label: option.value,
          colorScheme: evaluationColors[value],
        },
      });
    });

    return _options;
  };

  const getColorScheme = (
    input: FieldInputProps<string, HTMLElement>
  ): ColorSchemeType | undefined => {
    const option = getOptionsData(input).find(
      (option) => Number(option.flag?.label) === Number(input.value)
    );

    if (!input.value || !option?.flag) {
      return EVALUATION_UNKNOWN_COLOR;
    }

    return option.flag.colorScheme;
  };

  return (
    <Box>
      <Label>{label}</Label>
      <Space vertical={1} />
      <Field
        data-testid={convertStringToCamelCase(label)}
        name={name}
        component={({input}) => (
          <DropdownTag
            label={input.value ? input.value.toString() : EVALUATION_UNKNOWN_FLAG}
            colorScheme={getColorScheme(input)}
            dropdownItems={getOptionsData(input)}
            isDisabled={isDisabledForUser}
            data-testid={rest['data-testid']}
          />
        )}
      />
      <Show when={hasError}>
        <Box paddingTop={1}>
          <Text size="xSmall" color="danger">
            {i18n.t('general.validations.fieldIsRequired')}
          </Text>
        </Box>
      </Show>
    </Box>
  );
};
