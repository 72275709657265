import {Button, ButtonGroup, Chips, closeCurrentDialog} from 'platform/components';
import {Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {PurchaseVehicleType} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {Nullish} from 'shared';

const purchaseVehicleTypeOptions = [
  {
    label: i18n.t`entity.vehicle.labels.typeInStock`,
    value: 'IN_STOCK',
  },
  {
    label: i18n.t`entity.vehicle.labels.typeBrokerage`,
    value: 'BROKERAGE',
  },
];

interface SelectPurchaseFormProps {
  setCreateVehicleModalOpen: (isOpen: boolean, purchaseVehicleType: PurchaseVehicleType) => void;
}

export function SelectPurchaseForm(props: SelectPurchaseFormProps) {
  const [purchaseVehicleType, setPurchaseVehicleType] = useState<PurchaseVehicleType>('IN_STOCK');

  const onAddVehicleToBuy = () => {
    props.setCreateVehicleModalOpen(true, purchaseVehicleType);
    closeCurrentDialog();
  };

  const onChangePurchaseVehicleType = (purchaseVehicleType: string[] | Nullish) => {
    setPurchaseVehicleType(purchaseVehicleType?.[0] as PurchaseVehicleType);
  };

  const closeAndResetState = () => {
    closeCurrentDialog();
    setPurchaseVehicleType('IN_STOCK');
  };

  return (
    <VStack>
      <Chips
        label={i18n.t('page.businessCase.labels.selectPurchaseVehicleType')}
        data-testid={testIds.businessCase.detail('purchaseVehicleType')}
        onChange={onChangePurchaseVehicleType}
        value={[purchaseVehicleType as string]}
        options={purchaseVehicleTypeOptions}
      />

      <Space vertical={4} />
      <ButtonGroup
        align="right"
        data-testid={testIds.businessCase.detail('purchaseVehicleType-actions')}
      >
        <Button
          data-testid={testIds.businessCase.detail('purchaseVehicleType-actions-discard')}
          variant="secondary"
          onClick={closeAndResetState}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid={testIds.businessCase.detail('purchaseVehicleType-actions-confirm')}
          variant="primary"
          onClick={onAddVehicleToBuy}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </VStack>
  );
}
