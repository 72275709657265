import {Space} from 'platform/foundation';

import {FC, useMemo} from 'react';

import i18n from '@dms/i18n';

import {TestIdProps} from 'shared';

import {MainCategoryProps} from '../../../types/ConditionTypes';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findCategoryByUniqueKey} from '../utils/findCategoryByUniqueKey';
import {getDamageValues} from '../utils/getDamageValues';
import {CategoryGeneralComment} from './CategoryGeneralComment';
import {DamageCategory} from './DamageCategory';
import {ListOfDamages} from './ListOfDamages';
import {PaintAndThickness} from './PaintAndThickness';
import {
  TypeOfDamages,
  CategoriesLabel,
  LocationsSettingsGridProp,
  RelatedCategories,
} from './TypeOfDamages';

export const Exterior: FC<MainCategoryProps & TestIdProps> = ({
  data: categoryStructure,
  ...rest
}) => {
  const exteriorDamage = useMemo(
    () => findCategoryByUniqueKey(categoryStructure, AuditCategoryUniqueKey.EXTERIOR_DAMAGE),
    [categoryStructure]
  );

  const exteriorDamageValues = useMemo(() => getDamageValues(exteriorDamage), [exteriorDamage]);

  const relatedCategories: RelatedCategories = {
    [AuditCategoryUniqueKey.LF_DOOR]: AuditCategoryUniqueKey.L_MIRROR,
    [AuditCategoryUniqueKey.RF_DOOR]: AuditCategoryUniqueKey.R_MIRROR,
  };

  const categoriesGridSpan: LocationsSettingsGridProp = {
    [AuditCategoryUniqueKey.OTHER]: 3,
  };

  const categoriesLabel: CategoriesLabel = {
    [AuditCategoryUniqueKey.OTHER]: i18n.t('entity.vehicle.labels.otherLabel'),
  };

  return (
    <DamageCategory data-testid={rest['data-testid']}>
      <TypeOfDamages
        category={exteriorDamage}
        damageValues={exteriorDamageValues}
        locationsSettings={{
          relatedCategories,
          categoriesGridSpan,
          categoriesLabel,
          columns: 3,
        }}
        data-testid={rest['data-testid']}
      />
      <Space vertical={6} />
      <PaintAndThickness data-testid={rest['data-testid']} />
      <Space vertical={3} />
      <ListOfDamages category={exteriorDamage} data-testid={rest['data-testid']} />
      <CategoryGeneralComment category={categoryStructure} />
    </DamageCategory>
  );
};
