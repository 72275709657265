import {groupBy} from 'ramda';

import {OrderResponseBody} from '@dms/api';

import {Nullish} from 'shared';

export const getPaymentsByDiscriminator = (order: OrderResponseBody | Nullish) => {
  const sortedPayments = groupBy((payment) => payment.paymentDiscriminator, order?.payments ?? []);

  return {
    deposits: sortedPayments.DEPOSIT ?? [],
    purchases: sortedPayments.PURCHASE ?? [],
    balance: sortedPayments.BALANCE ?? [],
    balanceAndPurchases: (sortedPayments.BALANCE ?? []).concat(sortedPayments.PURCHASE ?? []),
  };
};
