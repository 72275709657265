import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloProvider as Provider,
} from '@apollo/client';
import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';

import {ReactNode} from 'react';

import {isNil} from 'ramda';

import {browserStorageKey} from '@dms/config';
import {environment} from '@dms/environment';

const getLink = () => {
  const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

  if (isNil(accessToken)) {
    return ApolloLink.from([]);
  }

  const httpLink = new HttpLink({uri: environment.APPSYNC_URL});
  const appSyncConfig = {
    url: environment.APPSYNC_URL,
    region: 'eu-west-1',
    auth: {type: 'AWS_LAMBDA', token: accessToken},
  } as const;

  return ApolloLink.from([
    createAuthLink(appSyncConfig),
    createSubscriptionHandshakeLink(appSyncConfig, httpLink),
  ]);
};

export const ApolloProvider = ({children}: {children: ReactNode}) => {
  const client = new ApolloClient({
    link: getLink(),
    cache: new InMemoryCache(),
  });

  return <Provider client={client}>{children}</Provider>;
};
