import {closeDialog, DataStatus, showNotification} from 'platform/components';

import {useGetStorageLocationByIdApiQuery, usePatchStorageLocationApiMutation} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {refreshDatagrid} from 'features/datagrid';

import {VectorType} from '../types/MultipleLocationsForm';
import {SingleLocationFormValue, StorageLocationDefinition} from '../types/SingleLocationFormValue';
import {SingleLocationForm} from './SingleLocationForm';

interface SingleLocationDetailProps {
  storageLocationId: string;
  dialogId: string;
}

export function SingleLocationDetail(props: SingleLocationDetailProps) {
  const locationDetailQuery = useGetStorageLocationByIdApiQuery({
    storageLocationId: props.storageLocationId,
  });

  const [updateStorageLocation, updateStorageLocationQuery] = usePatchStorageLocationApiMutation();

  const handleFormSubmit = async (formData: SingleLocationFormValue) => {
    await updateStorageLocation({
      storageLocationId: props.storageLocationId,
      body: {
        definition: {
          vectors: formData.storageLocation,
          separator: locationDetailQuery.data?.definition?.separator || '',
        },
        note: formData.note,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('storage-location-tire-warehouse');
        closeDialog(props.dialogId);
      })
      .catch(handleApiError);
  };

  const getDefaultValues = (): SingleLocationFormValue | undefined => {
    const vectors = locationDetailQuery.data?.definition?.vectors;

    if (!vectors) {
      return undefined;
    }

    return {
      storageLocation: vectors.map(
        (item) =>
          ({
            isSelected: item?.isSelected ?? false,
            vectorType: (item?.vectorType as VectorType) ?? VectorType.Aisle,
            vectorPosition: item?.vectorPosition ?? 1,
            symbol: item?.symbol ?? '',
          }) as StorageLocationDefinition
      ),
      note: locationDetailQuery.data?.note || '',
    };
  };

  return (
    <DataStatus isLoading={locationDetailQuery.isLoading} isError={locationDetailQuery.isError}>
      <SingleLocationForm
        dialogId={props.dialogId}
        isLoading={updateStorageLocationQuery.isLoading}
        defaultValues={getDefaultValues()}
        onFormSubmit={handleFormSubmit}
      />
    </DataStatus>
  );
}
