import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentRoundingDefinitionForm} from './components/DocumentRoundingDefinitionForm';

export function DocumentRoundingDefinitionsNew() {
  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('page.accounting.labels.documentRoundingDefinitions'),
      href: settingsRoutes.documentRoundingDefinitions,
    },
  ];

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.documentRoundingDefinitions'), breadcrumbs}}
      data-testid="settings-document-rounding-definition-add-page"
    >
      <DocumentRoundingDefinitionForm />
    </SettingsTemplate>
  );
}
