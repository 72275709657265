import {Button, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Right, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteServicePackageCategoryMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {PackagesCategoryForm} from './components/PackagesCategoryForm';

export function PackagesCategory(props: TestIdProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [deleteServicePackageCategory] = useDeleteServicePackageCategoryMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        openDialog(
          <PackagesCategoryForm
            categoryId={id}
            onClose={() => {
              closeCurrentDialog();
              refreshData();
            }}
            data-testid={suffixTestId('form', props)}
          />,
          {
            title: i18n.t('entity.workshopServicePackages.labels.editCategory'),
          }
        )
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServicePackageCategory({categoryId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(always(undefined));
  };

  return (
    <VStack spacing={4}>
      <Right>
        <Button
          variant="link"
          leftIcon="content/add_circle"
          title={i18n.t('entity.workshopServicePackages.actions.addCategory')}
          onClick={() =>
            openDialog(
              <PackagesCategoryForm
                onClose={() => {
                  closeCurrentDialog();
                  refreshDataGrid();
                }}
                data-testid={suffixTestId('form', props)}
              />,
              {
                title: i18n.t('entity.workshopServicePackages.labels.addCategory'),
              }
            )
          }
          data-testid={suffixTestId('add', props)}
        />
      </Right>
      <DataGrid
        ref={dataGridRef}
        gridCode="service-packages-categories"
        autoHeight
        actionCallback={actionCallback}
        data-testid={suffixTestId('dataGrid', props)}
      />
    </VStack>
  );
}
