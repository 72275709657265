import {Button, ButtonGroup} from 'platform/components';
import {Image, useElementSize, Box, HStack, Show, Text, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {useLazyGetDocument360RedirectUrlQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

export function SupportV1() {
  const [ref, width] = useElementSize<HTMLDivElement>();
  const [getDocument360RedirectUrl, {isLoading}] = useLazyGetDocument360RedirectUrlQuery();

  const handleRedirectToDoc360 = (redirectTo: string) =>
    getDocument360RedirectUrl({redirectTo})
      .unwrap()
      .then((res) => res.redirectUrl)
      .then(window.open)
      .catch(handleApiError);

  return (
    <Box
      ref={ref}
      backgroundColor="palettes.blue.10.100"
      borderRadius="medium"
      overflow="hidden"
      height="100%"
    >
      <HStack height="100%">
        <Box padding={4} height="100%" flexGrow={1}>
          <VStack height="100%" spacing={4} justify="space-between">
            <VStack spacing={4} grow={1}>
              <Text alternative>{i18n.t('page.homepage.labels.supportAndHelpdesk')}</Text>
              <Text size="small">
                {i18n.t('page.homepage.labels.supportAndHelpdeskDescription')}
              </Text>
            </VStack>
            <ButtonGroup>
              <Button
                data-testid={testIds.dashboard.home('button-webinars')}
                variant="outlined"
                onClick={() => handleRedirectToDoc360('/docs/webinar')}
                isLoading={isLoading}
                title={i18n.t('page.homepage.labels.webinars')}
              />
              <Button
                data-testid={testIds.dashboard.home('button-training')}
                variant="outlined"
                isLoading={isLoading}
                onClick={() => handleRedirectToDoc360('/skoleni')}
                title={i18n.t('page.homepage.labels.training')}
              />
              <Button
                variant="outlined"
                isLoading={isLoading}
                data-testid={testIds.dashboard.home('button-support')}
                onClick={() => handleRedirectToDoc360('/contacts#')}
                title={i18n.t('page.homepage.labels.support')}
              />
            </ButtonGroup>
          </VStack>
        </Box>
        <Show when={isNil(width) || width === 0 || width > 600}>
          <Image
            src="/assets/images/dashboard_supportImage.png"
            fit="cover"
            position="left center"
            maxWidth={100}
          />
        </Show>
      </HStack>
    </Box>
  );
}
