import {ValidationErrors} from 'platform/components';

import {isNil} from 'ramda';

import {Error, PostBillingInformationV2ApiArg} from '@dms/api';

const BANK_ACC_ERROR_REGEX = /Unknown bank account number country in (.+)\./;
const extractNumberFromErrorMessage = (input: string): string | null => {
  const match = input.match(BANK_ACC_ERROR_REGEX);
  if (match) {
    const numberMatch = match[0].match(BANK_ACC_ERROR_REGEX);
    if (numberMatch) {
      return numberMatch[1];
    }
  }
  return null;
};

export const getIsBankAccountError = (
  err: Error,
  values: PostBillingInformationV2ApiArg,
  setError: (errors: ValidationErrors | null) => void
): boolean => {
  const errorMessage = err?.data?.errors?.[0]?.message;
  if (isNil(errorMessage)) {
    return false;
  }

  const bankAccNumber = extractNumberFromErrorMessage(errorMessage);
  if (isNil(bankAccNumber)) {
    return false;
  }

  const errorBankAccIndex = values.bankAccounts.findIndex(
    (item) => item.accountNumber === bankAccNumber
  );

  setError([{name: `bankAccounts[${errorBankAccIndex}].accountNumber`, message: errorMessage}]);
  return true;
};
