import {isFeatureEnabled} from 'feature-flags';
import {TabProps, TabsHeader} from 'platform/components';
import {HStack, Show, Space} from 'platform/foundation';

import {FC, PropsWithChildren, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {selectSaleVehicle} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {
  ServiceCasePhotos,
  useAdPlatformsVehicleUpdateDialog,
  usePermissions,
  useVehiclePhotosPermissions,
} from '@dms/shared';
import {
  AppDispatch,
  Inspections,
  VehiclePhotosRoutes,
  loadCarDetailsVehicleDetailRequest,
  useRouter,
} from '@dms/teas';

import {buildArray, composePath, useRequiredParams} from 'shared';

import {VehiclePhotoGallery} from '../../../../../components/VehiclePhotoGallery/VehiclePhotoGallery';
import {VehicleCreateAlbum} from '../VehicleCreateAlbum';
import {BatchDownloadButton} from './BatchDownloadButton';
import {ConditionPhotos} from './ConditionPhotos';
import {InspectionPhotos} from './InspectionPhotos';
import {VehiclePhotos} from './VehiclePhotos';

export const Photos: FC<PropsWithChildren<any>> = () => {
  const router = useRouter();
  const {id: vehicleId} = useRequiredParams();
  const {tab, inspectionId} = useParams();

  const location = useLocation();
  const hasClassifiedsPhotosChanged = useRef(false);
  const dispatch = useDispatch<AppDispatch>();

  const [canReadServiceCase] = usePermissions({permissionKeys: ['serviceCaseRead']});

  const {canReadVehicleAlbumPhotos} = useVehiclePhotosPermissions({
    vehicleId,
  });

  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();
  const {data: saleVehicle} = useSelector(selectSaleVehicle(vehicleId));
  const isSaleVehicle = isNotNil(saleVehicle);

  useEffect(() => {
    if (!hasClassifiedsPhotosChanged.current) {
      return;
    }

    updateVehicleAds(vehicleId);

    hasClassifiedsPhotosChanged.current = false;
  }, [vehicleId, location, hasClassifiedsPhotosChanged]);

  if (inspectionId) {
    return <InspectionPhotos />;
  }

  const handleChangeTab = (tab: string) => {
    router.push(
      composePath(vehiclesRoutes.photos, {
        params: {
          id: vehicleId,
          tab,
        },
      })
    );
  };

  const handleClassifiedsPhotosChange = () => {
    hasClassifiedsPhotosChanged.current = true;
  };

  const refreshVehicleDetail = () => {
    dispatch(loadCarDetailsVehicleDetailRequest({vehicleId}));
  };

  const tabs = buildArray<TabProps>()
    .when(!isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) && canReadVehicleAlbumPhotos, {
      title: i18n.t('entity.vehicle.labels.vehiclePhotos'),
      id: VehiclePhotosRoutes.vehiclePhotos,
      'data-testid': testIds.vehicles.photos(VehiclePhotosRoutes.vehiclePhotos),
      content: (
        <VehiclePhotos
          vehicleId={vehicleId}
          setClassifiedPhotosHasChanged={handleClassifiedsPhotosChange}
        />
      ),
    })
    .when(isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) && canReadVehicleAlbumPhotos, {
      title: i18n.t('entity.vehicle.labels.vehiclePhotos'),
      id: VehiclePhotosRoutes.vehiclePhotos,
      'data-testid': testIds.vehicles.photos(VehiclePhotosRoutes.vehiclePhotos),
      content: <VehiclePhotoGallery />,
    })
    .when(
      isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) &&
        isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS_DEBUG) &&
        canReadVehicleAlbumPhotos,
      {
        title: `${i18n.t('entity.vehicle.labels.vehiclePhotos')} (Legacy)`,
        id: `${VehiclePhotosRoutes.vehiclePhotos}-legacy`,
        content: (
          <VehiclePhotos
            vehicleId={vehicleId}
            setClassifiedPhotosHasChanged={handleClassifiedsPhotosChange}
          />
        ),
      }
    )
    .when(isSaleVehicle, {
      title: i18n.t('entity.vehicle.labels.vehicleCondition'),
      id: VehiclePhotosRoutes.condition,
      'data-testid': testIds.vehicles.photos(VehiclePhotosRoutes.condition),
      content: <ConditionPhotos />,
    })
    .when(isSaleVehicle, {
      title: i18n.t('entity.vehicle.labels.inspections'),
      id: VehiclePhotosRoutes.inspections,
      'data-testid': testIds.vehicles.photos(VehiclePhotosRoutes.inspections),
      content: (
        <Inspections
          data-testid={testIds.vehicles.photos('photoInspection')}
          getRedirectRoute={(_id, _inspectionId) =>
            composePath(vehiclesRoutes.photoByInspectionId, {
              params: {
                id: _id,
                inspectionId: _inspectionId,
                tab: VehiclePhotosRoutes.inspections,
              },
            })
          }
        />
      ),
    })
    .when(isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase, {
      title: i18n.t('entity.vehicle.labels.serviceCasePhotos'),
      id: VehiclePhotosRoutes.serviceCase,
      'data-testid': testIds.vehicles.photos(VehiclePhotosRoutes.serviceCase),
      content: <ServiceCasePhotos vehicleId={vehicleId} />,
    });

  return (
    <>
      <HStack justify="space-between" width="100%" spacing={4}>
        <HStack>
          <TabsHeader
            variant="condensed"
            onChange={handleChangeTab}
            activeTabId={tab}
            tabs={tabs}
          />
        </HStack>
        <Space fillAvailable />
        <Show
          when={location.pathname.includes(VehiclePhotosRoutes.vehiclePhotos)}
          whenFeatureEnabled={featureFlags.VEHICLE_MULTIPLE_ALBUMS}
          whenFeatureDisabled={featureFlags.ACL_VEHICLE_PHOTOS}
        >
          <BatchDownloadButton />
          <VehicleCreateAlbum vehicleId={vehicleId} onComplete={refreshVehicleDetail} />
        </Show>
        <Show
          when={
            location.pathname.includes(VehiclePhotosRoutes.vehiclePhotos) &&
            isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) &&
            isSaleVehicle
          }
          whenFeatureEnabled={featureFlags.VEHICLE_MULTIPLE_ALBUMS}
        >
          <BatchDownloadButton saleVehicleId={saleVehicle?.id} />
          <VehicleCreateAlbum
            vehicleId={vehicleId}
            saleVehicleId={saleVehicle?.id}
            onComplete={refreshVehicleDetail}
          />
        </Show>
      </HStack>

      <Space vertical={4} />
      {(tabs.find((item) => item.id === tab) ?? tabs[0])?.content}
    </>
  );
};
