import {FormControl} from 'platform/components';
import {HStack, Text} from 'platform/foundation';

import {useWatch} from 'react-hook-form';

import {useGetTireWarehouseQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {MultipleLocationsForm} from '../types/MultipleLocationsForm';

interface LocationExampleProps {
  tireWarehouseId: string;
  control: FormControl<MultipleLocationsForm>;
}

export function LocationExample(props: LocationExampleProps) {
  const tireWarehouseQuery = useGetTireWarehouseQuery(
    {warehouseId: props.tireWarehouseId || ''},
    {skip: !props.tireWarehouseId}
  );

  const definition = useWatch({
    control: props.control,
    name: 'definition',
  });

  const result = definition
    .filter((item) => item.isSelected && item.beginAt)
    .map((item) => item.beginAt)
    .join(tireWarehouseQuery.data?.storageLocationSeparator || '');

  return (
    <HStack spacing={2}>
      <Text size="small" color="secondary">
        {i18n.t('entity.tireWarehouses.labels.locationExample')}
      </Text>
      <Text size="small">{result || EMPTY_PLACEHOLDER}</Text>
    </HStack>
  );
}
