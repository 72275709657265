import {useRef} from 'react';
import {useLocation} from 'react-router-dom';

import {vehiclesRoutes} from '@dms/routes';

import {composePath, isDefined, useNavigate, useOnMount} from 'shared';

import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {findCurrentRoute} from '../../../utils/findCurrentRoute';

function checkIsForeignInspection(
  vehicleId: string | undefined,
  inspection: LoadAuditDataResponseItemBody
) {
  return isDefined(vehicleId) && isDefined(inspection) && inspection.vehicleId !== vehicleId;
}

type NavigationCheckParams = {
  route: string;
  vehicleId: string | undefined;
  inspection: LoadAuditDataResponseItemBody | undefined;
  processed: boolean;
};

const checkShouldNavigateOfForeignInspection = (params: NavigationCheckParams): boolean => {
  if (params.processed) {
    return false;
  }
  if (params.route !== vehiclesRoutes.inspectionDetailTab) {
    return false;
  }
  if (
    !isDefined(params.inspection) ||
    checkIsForeignInspection(params.vehicleId, params.inspection)
  ) {
    return true;
  }

  return false;
};

export function useHandleForeignInspection(
  vehicleId: string | undefined,
  inspection: LoadAuditDataResponseItemBody | undefined
) {
  const navigate = useNavigate();
  const location = useLocation();
  const processedInspectionRef = useRef(false);

  const route = findCurrentRoute(location.pathname);

  const handleNavigationOfForeignInspection = () => {
    const shouldNavigateOfForeignInspection = checkShouldNavigateOfForeignInspection({
      route,
      vehicleId,
      inspection,
      processed: processedInspectionRef.current,
    });

    if (shouldNavigateOfForeignInspection) {
      processedInspectionRef.current = true;
      navigate(composePath(vehiclesRoutes.conditionsInspections, {params: {id: vehicleId}}), {
        replace: true,
      });
    }
  };

  useOnMount(handleNavigationOfForeignInspection);
}
