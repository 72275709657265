import {Button, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Heading, HStack, Show, VStack} from 'platform/foundation';

import {
  AfsAdditionalCustomerType,
  CustomerResponseBodyV2,
  useDeleteCheckoutAdditionalCustomerMutation,
  usePatchCheckoutAdditionalCustomerMutation,
  usePostCheckoutAdditionalCustomerMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {CustomerMatchOrCreate} from '../../CustomerWidgetCard/components/CustomerMatchOrCreate';
import {useBillingInformation} from '../hooks/useBillingInformation';
import {BillingInformationList} from './BillingInformationList';

interface AdditionalCustomersProps extends RequiredTestIdProps {
  checkoutId: string;
  recordId?: string;
  resourceId?: string;
}

const MAX_ADDITIONAL_CUSTOMERS = 1;

export function AdditionalCustomers(props: AdditionalCustomersProps) {
  const {billingInformation} = useBillingInformation(props.checkoutId);

  const [postCheckoutAdditionalCustomer] = usePostCheckoutAdditionalCustomerMutation();
  const [patchCheckoutAdditionalCustomer] = usePatchCheckoutAdditionalCustomerMutation();
  const [deleteCheckoutAdditionalCustomer] = useDeleteCheckoutAdditionalCustomerMutation();

  const handleAddAdditionalCustomer = (customer: CustomerResponseBodyV2) =>
    postCheckoutAdditionalCustomer({
      checkoutId: props.checkoutId,
      body: {additionalCustomerType: 'OPERATOR', customerId: customer.id},
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);

  const handleRemoveAdditionalCustomer = (additionalCustomerId: string) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteCheckoutAdditionalCustomer({checkoutId: props.checkoutId, additionalCustomerId})
          .unwrap()
          .catch(handleApiError),
    });

  const handleChangeAdditionalCustomer = (
    additionalCustomerId: string,
    contractInformationId: string,
    additionalCustomerType?: AfsAdditionalCustomerType
  ) =>
    patchCheckoutAdditionalCustomer({
      checkoutId: props.checkoutId,
      additionalCustomerId,
      body: {contractInformationId, additionalCustomerType: additionalCustomerType ?? 'OPERATOR'},
    })
      .unwrap()
      .catch(handleApiError);

  return (
    <VStack spacing={4} align="flex-start">
      {billingInformation?.additionalCustomers?.map((customer, index) => (
        <VStack spacing={4} key={customer.id} width="100%">
          <HStack justify="space-between" align="center">
            <Heading size={4}>
              {i18n.t('entity.accounting.labels.nthCustomerOnInvoice', {n: index + 2})}
            </Heading>
            <Button
              variant="errorLink"
              title={i18n.t('entity.checkout.actions.removeCustomer')}
              leftIcon="action/delete"
              onClick={() => handleRemoveAdditionalCustomer(customer.id)}
            />
          </HStack>
          <BillingInformationList
            checkoutId={props.checkoutId}
            customerId={customer.customerId}
            onAddContract={(contractId) => handleChangeAdditionalCustomer(customer.id, contractId)}
            selectedContractId={customer.contractInformationId}
            recordId={props.recordId}
            resourceId={props.resourceId}
            additionalCustomerType={{
              value: customer.additionalCustomerType,
              onChange: (type) =>
                handleChangeAdditionalCustomer(
                  customer.id,
                  customer.contractInformationId ?? '',
                  type
                ),
            }}
            data-testid={suffixTestId(`additionalCustomer-${index}`, props)}
          />
        </VStack>
      ))}
      <Show
        when={(billingInformation?.additionalCustomers?.length ?? 0) < MAX_ADDITIONAL_CUSTOMERS}
      >
        <Button
          title={i18n.t('entity.accounting.actions.addCustomerToInvoice')}
          onClick={() =>
            openDialog(
              <CustomerMatchOrCreate
                secondStepComponentType="BUSINESS_CASE"
                onCustomer={handleAddAdditionalCustomer}
              />,
              {title: i18n.t('entity.customer.labels.customer')}
            )
          }
          variant="link"
          leftIcon="content/add_circle"
        />
      </Show>
    </VStack>
  );
}
