import {Button, FormButton, FormControl, FormSubmitHandler, useDialog} from 'platform/components';
import {Show} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {CheckInHandOverFormType} from '../types/CheckInHandOverFormType';
import {UpdateMileageFuelTankConfirm} from './UpdateMileageFuelTankConfirm';

interface FormActionsProps extends RequiredTestIdProps {
  formApi: UseFormReturn<CheckInHandOverFormType>;
  control: FormControl<CheckInHandOverFormType>;
  isEditing: boolean;
  handleSubmit: FormSubmitHandler<CheckInHandOverFormType>;
  defaultMileage: number | Nullish;
  defaultFuelTank: number | Nullish;
}

export function FormActions(props: FormActionsProps) {
  const [isOpen, onOpen, onClose] = useDialog();

  const confirmMileageAndFuelTank =
    props.formApi.watch('mileage') !== props.defaultMileage ||
    props.formApi.watch('fuelTank') !== props.defaultFuelTank;

  const triggerSubmit = (updateMileageFuelTank: boolean) => {
    onClose();

    props.formApi.handleSubmit((formData) =>
      props.handleSubmit(
        {...formData, withFuelTankMileageUpdate: updateMileageFuelTank},
        () => false,
        props.formApi.reset
      )
    )();
  };

  return (
    <>
      <Show when={confirmMileageAndFuelTank}>
        <Button
          onClick={onOpen}
          data-testid={suffixTestId('save', props)}
          isLoading={props.formApi.formState.isSubmitting}
          title={
            props.isEditing
              ? i18n.t('general.actions.saveChanges')
              : i18n.t('entity.order.actions.complete')
          }
        />
        <UpdateMileageFuelTankConfirm
          isOpen={isOpen}
          dialogId="UpdateMileageFuelTank"
          onResult={triggerSubmit}
          data-testid={suffixTestId('confirmDialog', props)}
        />
      </Show>
      <Show when={!confirmMileageAndFuelTank}>
        <FormButton
          control={props.control}
          type="submit"
          data-testid={suffixTestId('save', props)}
          isLoading={props.formApi.formState.isSubmitting}
          title={
            props.isEditing
              ? i18n.t('general.actions.saveChanges')
              : i18n.t('entity.order.actions.complete')
          }
        />
      </Show>
    </>
  );
}
