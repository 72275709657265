import {yupResolver} from '@hookform/resolvers/yup';
import styled from 'styled-components';
import * as Yup from 'yup';

import {FC, useContext} from 'react';
import {FieldErrors, SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {
  inlineEditingContext,
  InlineEditingContextType,
  TypographyCard,
  selectCountries,
  BillingInformation,
  replaceValues,
} from '@dms/teas';

import {ContactInfoFields} from '../../Tenant/components/ContactInfoFields';
import {FormWithSubmitProvider} from '../../Tenant/components/FormContextWithSubmit';
import {contactInformationSchema} from '../../Tenant/components/validations';
import {ContactInfoFieldsFormType} from '../../Tenant/types';
import {BillingNameSection} from './BillingNameSection';
import {validationCheckerContext} from './validationCheckerContext';

const DividerStyled = styled.div`
  margin: ${({theme}) => theme.getSize(3)} 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  box-sizing: border-box;
  height: 1px;
  width: 100%;
`;

export interface BillingContactInformationForm extends ContactInfoFieldsFormType {
  billingName: string;
}

export interface BillingContactInformationProps {
  onSubmit?: (values: BillingContactInformationForm) => void;
  onSubmitError?: (errors: FieldErrors<BillingContactInformationForm>) => void;
}

export const BillingContactInformation: FC<BillingContactInformationProps> = ({
  onSubmit,
  onSubmitError,
}) => {
  const countries = useSelector(selectCountries);

  const validationChecker = useContext(validationCheckerContext);
  const {editedValue, updateMethod} = useContext(inlineEditingContext) as InlineEditingContextType<
    unknown,
    {data?: BillingInformation}
  >;
  const methods = useForm<BillingContactInformationForm>({
    resolver: yupResolver(billingContactInformationValidations()),
    defaultValues: {
      billingName: editedValue?.data?.billingName ?? undefined,
      ...editedValue?.data?.contactInformation,
    },
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    shouldUnregister: false,
  });

  const handleSubmit: SubmitHandler<BillingContactInformationForm> = (values) => {
    const {billingName, ...rest} = replaceValues(values, '', null);

    onSubmit?.(values);

    const _values = {
      billingName,
      contactInformation: rest,
    };

    validationChecker?.setValue('billingContactInfo', true);
    updateMethod(_values, _values);
  };

  const handleSubmitError: SubmitErrorHandler<BillingContactInformationForm> = (errors) => {
    onSubmitError?.(errors);
    validationChecker?.setValue('billingContactInfo', false);
  };

  return (
    <TypographyCard title={i18n.t('entity.invoice.labels.billingContractInformation')}>
      <FormWithSubmitProvider
        formProps={methods}
        onSubmit={methods.handleSubmit(handleSubmit, handleSubmitError)}
      >
        <form onSubmit={methods.handleSubmit(handleSubmit, handleSubmitError)}>
          <BillingNameSection />
          <DividerStyled />
          <ContactInfoFields
            countries={countries ?? []}
            required={[
              'registrationNumber',
              'companyName',
              'address.street',
              'address.city',
              'address.zipCode',
              'address.country',
            ]}
          />
        </form>
      </FormWithSubmitProvider>
    </TypographyCard>
  );
};

const billingContactInformationValidations = (): Yup.SchemaOf<BillingContactInformationForm> =>
  contactInformationSchema().concat(
    Yup.object().shape({
      billingName: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
    })
  );
