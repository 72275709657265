import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {PromoPhotosForm} from '../../components/PromoPhotosForm/PromoPhotosForm';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function PromoPhotoCreate() {
  return (
    <SettingsTemplate
      header={{
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
          {
            label: i18n.t('page.salesSettings.labels.promotionalPhotos'),
            href: settingsRoutes.advertisingPromoPhotos,
          },
        ],
      }}
      data-testid="settings-advertising-promoPhotos-create"
    >
      <PromoPhotosForm />
    </SettingsTemplate>
  );
}
