import {Tooltip} from 'platform/components';
import {Spinner, Center, Clickable, Icon, VStack} from 'platform/foundation';

import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {downloadTemplate, documentDownloadingSelector, uploadingTemplate} from '@dms/teas';

import {Actions, IconContainer} from '../styles';
import {TExtraTemplateFields} from '../types';
import {UploadDocumentTemplateFormContainer} from './UploadDocumentTemplateFormContainer';

interface ActionIconsProps {
  readonly template: TExtraTemplateFields;
}

// Action to download file
const DownLoadAction: FC<ActionIconsProps> = ({template}) => {
  const downloading = useSelector(documentDownloadingSelector);
  const dispatch = useDispatch();

  const onClick = () => {
    if (!downloading) {
      // download primary document
      if (template.customTemplates.length) {
        const primaryDocument = template.customTemplates.find((custom) => custom.primary);

        if (primaryDocument) {
          dispatch(
            downloadTemplate({
              url: template.url,
              type: 'docx',
              fileName: primaryDocument?.fileName,
              customTemplateId: primaryDocument?.id,
            })
          );
        } else {
          dispatch(
            downloadTemplate({
              url: template.url,
              fileName: template.code,
              type: 'docx',
            })
          );
        }
        return;
      }

      dispatch(downloadTemplate({url: template.url, fileName: template.code, type: 'docx'}));
    }
  };

  return (
    <Tooltip placement="top" label={i18n.t('entity.document.actions.tableDownload')}>
      <Clickable data-testid={testIds.settings.documentTemplates('download')} onClick={onClick}>
        <Center>
          <Icon value="action/get_app" />
        </Center>
      </Clickable>
    </Tooltip>
  );
};

export interface UploadActionProps {
  readonly code: string;
}

// Create new file for the template
const UploadAction: FC<UploadActionProps> = ({code}) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      {showForm ? (
        <UploadDocumentTemplateFormContainer
          open={showForm}
          code={code ?? ''}
          onClose={() => setShowForm(false)}
        />
      ) : null}
      <Tooltip placement="top" label={i18n.t('entity.document.actions.tableUpload')}>
        <Clickable
          onClick={() => setShowForm(!showForm)}
          data-testid={testIds.settings.documentTemplates('uploadIcon')}
        >
          <VStack align="center">
            <Icon value="content/add_circle" />
          </VStack>
        </Clickable>
      </Tooltip>
    </>
  );
};

export const ActionIcons: FC<ActionIconsProps> = ({template}) => {
  const isUploading = useSelector(uploadingTemplate(template.code));

  if (isUploading) {
    return (
      <Actions>
        <IconContainer>
          {i18n.t('general.labels.uploading')}... <Spinner size="small" />
        </IconContainer>
      </Actions>
    );
  }

  return (
    <Actions>
      <DownLoadAction template={template} />
      <UploadAction code={template.code} />
    </Actions>
  );
};
