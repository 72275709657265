import {Box, HStack, VStack, Text, Heading} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {PriceWithAndWithoutVat} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';

interface BasketSummaryProps extends TestIdProps {
  totalPrice: PriceWithAndWithoutVat | Nullish;
}

export function BasketSummary(props: BasketSummaryProps) {
  const formatCurrency = useFormatCurrency();

  const totalPriceWithoutVat = formatCurrency(
    props.totalPrice?.withoutVat.amount ?? 0,
    props.totalPrice?.withoutVat.currency ?? DEFAULT_CURRENCY,
    2
  );
  const totalPriceWithVat = `${formatCurrency(
    props.totalPrice?.withVat.amount ?? 0,
    props.totalPrice?.withVat.currency ?? DEFAULT_CURRENCY,
    2
  )} ${i18n.t('general.labels.withVat')}`;

  return (
    <HStack>
      <Box flex={1}>
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('general.labels.totalSellingPrice')}
          </Text>
          <Heading size={4} data-testid={suffixTestId('totalPriceWithoutVat', props)}>
            {totalPriceWithoutVat}
          </Heading>
          <Text
            size="xSmall"
            color="secondary"
            data-testid={suffixTestId('totalPriceWithVat', props)}
          >
            {totalPriceWithVat}
          </Text>
        </VStack>
      </Box>
    </HStack>
  );
}
