import {Checkbox} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {concat, filter} from 'ramda';
import {propNotEq} from 'ramda-adjunct';

import {AuditParamDefinitionsResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {StructureSettingsForm} from '../types/StructureSettingsForm';
import {getAllChildParams} from '../utils/getAllChildParams';
import {Parameter} from './Parameter';

interface ParameterListProps {
  formApi: UseFormReturn<StructureSettingsForm>;
  paramDefinitions: AuditParamDefinitionsResponseBody | Nullish;
  onNoneSelected: () => void;
}

export function ParameterList(props: ParameterListProps) {
  const childParams = concat(
    filter(propNotEq('categoryState', 'type'), props.paramDefinitions?.relatedActions ?? []),
    props.paramDefinitions?.additionalInformation ?? []
  );
  const allParams = getAllChildParams(childParams);

  const handleSelectAllParamsChange = (value: boolean) => {
    if (!value) {
      props.onNoneSelected();
    }

    allParams.forEach((param) => {
      props.formApi.setValue(`paramDefinitions.${param.id}.isChecked`, true);
    });
  };

  const handleSetAllMandatoryChange = (value: boolean) => {
    allParams.forEach((param) => {
      props.formApi.setValue(`paramDefinitions.${param.id}.isMandatory`, value);
    });
  };

  const isAllSelected = allParams.every(
    (param) => props.formApi.watch('paramDefinitions')[param.id]?.isChecked ?? true
  );

  const isAllManadatory = allParams.every(
    (param) => props.formApi.watch('paramDefinitions')[param.id]?.isMandatory ?? false
  );

  return (
    <>
      <Show when={allParams.length > 1}>
        <HStack>
          <Box flex={1}>
            <Checkbox
              label={i18n.t('general.actions.selectAll')}
              value={isAllSelected}
              onChange={handleSelectAllParamsChange}
            />
          </Box>
          <Box flex={1}>
            <Checkbox
              label={i18n.t('page.vehicleSettings.labels.mandatory')}
              value={isAllManadatory}
              onChange={handleSetAllMandatoryChange}
            />
          </Box>
        </HStack>
      </Show>
      <Box paddingLeft={4}>
        <VStack spacing={4}>
          {childParams.map((param) => (
            <Parameter
              key={param.id}
              parameter={param}
              formApi={props.formApi}
              isParentActive={true}
            />
          ))}
        </VStack>
      </Box>
    </>
  );
}
