import {object, array} from 'yup';

import i18n from '@dms/i18n';

import {yupString} from 'shared';

export const singleLocationSchema = object().shape({
  storageLocation: array().of(
    object().shape({
      symbol: yupString.when('isSelected', {
        is: true,
        then: (schema) =>
          schema
            .matches(
              /^[A-Z]+$|^[0-9]+$/,
              i18n.t('entity.tireWarehouses.validations.lettersOrNumbers')
            )
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
    })
  ),
});

// TODO: replace above solution with this one when ticket https://carvago.atlassian.net/browse/T20-70073 is done on Metada side

// export const singleLocationSchema = object().shape({
//   storageLocation: array().of(
//     object().shape({
//       symbol: yupString.when(['isSelected', 'vectorPosition'], {
//         is: (isSelected: boolean, vectorPosition: number) => isSelected && vectorPosition <= 4,
//         then: (schema) =>
//           schema
//             .matches(
//               /^[A-Z]+$|^[0-9]+$/,
//               i18n.t('entity.tireWarehouses.validations.lettersOrNumbers')
//             )
//             .required(),
//         otherwise: (schema) =>
//           schema.when(['isSelected', 'vectorPosition'], {
//             is: (isSelected: boolean, vectorPosition: number) => isSelected && vectorPosition === 5,
//             then: (schema) => schema.required(),
//             otherwise: (schema) => schema.optional(),
//           }),
//       }),
//     })
//   ),
// });
