import {
  Card,
  IconButton,
  openDeleteDialog,
  showNotification,
  DataStatus,
  Parameters,
  openDialog,
  Alert,
} from 'platform/components';
import {Box, HStack, Heading, VStack, Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {
  useDeletePostponeJobMutation,
  useGetPostponeJobQuery,
  usePatchPostponeJobMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {parseDate, suffixTestId, TestIdProps, useToggle} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {JobForm, JobFormProps} from '../JobForm/JobForm';
import {PostponeDialog} from '../PostponeJobDialog/PostponeDialog';
import {useSummaryParams} from './hooks/useSummaryParams';
import {PostponeJobType} from './types/PostponeJobType';
import {getIsExpired} from './utils/getIsExpired';
import {getIsExpiring} from './utils/getIsExpiring';

interface PostponedJobProps extends TestIdProps {
  serviceCaseId?: string;
  vehicleId?: string;
  postponeJobData?: PostponeJobType | null;
  isExpanded?: boolean;
  onExpand?: () => void;
  onAddJob?: () => void;
}

export function PostponedJob(props: PostponedJobProps) {
  const formatDateTime = useDateTimeFormatter();
  const [isExpanded, toggleExpand] = useToggle(false);
  const [deletePostponeJob] = useDeletePostponeJobMutation();
  const [patchPostponeJob] = usePatchPostponeJobMutation();
  const {data, isLoading} = useGetPostponeJobQuery(
    {postponeJobId: props.postponeJobData?.postponeJobId || ''},
    {skip: !(props.isExpanded || isExpanded)}
  );

  const summaryParams = useSummaryParams(props.postponeJobData);

  const dropdownIcon =
    props.isExpanded || isExpanded ? 'navigation/expand_less' : 'navigation/expand_more';

  const isExpiring = getIsExpiring(props.postponeJobData?.postponedUntil);
  const isExpired = getIsExpired(props.postponeJobData?.postponedUntil);

  const handleUpdatePostponedJob: JobFormProps['onSubmit'] = async (data, reset) => {
    if (!data?.postponeJobId) {
      return;
    }

    await patchPostponeJob({
      body: data,
      postponeJobId: data.postponeJobId,
      serviceCaseId: props.serviceCaseId,
      vehicleId: props.vehicleId,
    })
      .unwrap()
      .then(() => {
        reset();
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const handleDeleteJob = () => {
    openDeleteDialog({
      onConfirm: () =>
        deletePostponeJob({
          postponeJobId: props.postponeJobData?.postponeJobId || '',
          serviceCaseId: props.serviceCaseId,
          vehicleId: props.vehicleId,
        })
          .unwrap()
          .catch(handleApiError),
      text: i18n.t('entity.orderRequest.actions.deletePostponed', {
        name: props.postponeJobData?.name,
      }),
      'data-testid': suffixTestId('removeJob', props),
    });
  };

  const handlePostponeRequest = () => {
    if (!props.postponeJobData) {
      return;
    }

    openDialog(
      <PostponeDialog
        dialogId="postpone-dialog"
        jobsIds={props.postponeJobData.postponeJobId}
        reason={props.postponeJobData.postponeReason}
        postponeUntil={props.postponeJobData.postponedUntil}
        vehicleId={props.vehicleId}
        serviceCaseId={props.serviceCaseId}
        data-testid={suffixTestId('postponeDialog', props)}
      />,
      {
        id: 'postpone-dialog',
        title: i18n.t('entity.serviceCase.labels.postpone'),
        size: 'small',
        withAdditionalFooter: true,
      }
    );
  };

  return (
    <Card variant="inlineGrey">
      <Box data-testid={suffixTestId('head', props)}>
        <VStack spacing={4}>
          <HStack align="center" height="100%" justify="space-between">
            <VStack align="flex-start" spacing={1}>
              <Heading size={5} data-testid={suffixTestId('name', props)}>
                {props.postponeJobData?.name}
              </Heading>
              <Parameters parameters={summaryParams} data-testid={suffixTestId('summary', props)} />
            </VStack>
            <HStack align="center" height="100%" spacing={2}>
              <IconButton
                icon="action/delete"
                onClick={handleDeleteJob}
                size="small"
                data-testid={suffixTestId('delete', props)}
              />
              <IconButton
                icon="action/date_range"
                onClick={handlePostponeRequest}
                size="small"
                data-testid={suffixTestId('postpone', props)}
              />
              <Show when={props.onAddJob}>
                <IconButton
                  severity="informational"
                  icon="content/add_circle"
                  onClick={props.onAddJob}
                  size="small"
                  data-testid={suffixTestId('add', props)}
                />
              </Show>
              <IconButton
                icon={dropdownIcon}
                onClick={props.onExpand || toggleExpand}
                size="small"
                data-testid={suffixTestId('expand', props)}
              />
            </HStack>
          </HStack>
          <Show when={isExpiring && !isExpired}>
            <Alert
              variant="warning"
              title={i18n.t('entity.postponedJobs.label.expiringSoon')}
              message={i18n.t('entity.postponedJobs.label.postponedUntil', {
                date: props.postponeJobData?.postponedUntil
                  ? formatDateTime('dateShort', parseDate(props.postponeJobData?.postponedUntil))
                  : '',
              })}
              type="inline"
              data-testid={suffixTestId('expiring', props)}
            />
          </Show>
          <Show when={isExpired}>
            <Alert
              variant="error"
              title={i18n.t('entity.postponedJobs.label.expired')}
              message={i18n.t('entity.postponedJobs.label.postponedUntil', {
                date: props.postponeJobData?.postponedUntil
                  ? formatDateTime('dateShort', parseDate(props.postponeJobData?.postponedUntil))
                  : '',
              })}
              type="inline"
              data-testid={suffixTestId('expired', props)}
            />
          </Show>
        </VStack>
      </Box>
      <Show when={props.isExpanded || isExpanded}>
        <Box paddingTop={4}>
          <DataStatus isLoading={isLoading} minHeight={75}>
            <JobForm
              jobData={data}
              onSubmit={handleUpdatePostponedJob}
              data-testid={suffixTestId('jobForm', props)}
            />
          </DataStatus>
        </Box>
      </Show>
    </Card>
  );
}
