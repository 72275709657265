import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {
  getAllAuditStructureTemplates,
  getAuditStructureForStructureSettings,
  getAuditStructureSettings,
  selectConditionStructureTemplate,
  selectSettingBasicStructureByTemplate,
  useThunkDispatch,
} from '@dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ConditionSettings} from './components/ConditionSettings';

export function VehicleCondition() {
  const dispatch = useThunkDispatch();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const template = useSelector(selectConditionStructureTemplate);
  const structure = useSelector(selectSettingBasicStructureByTemplate(template?.id));

  useEffect(() => {
    startLoading();
    dispatch(getAllAuditStructureTemplates.action({}))
      .then(() => {
        if (!template?.id) {
          return;
        }

        const promises: any[] = [
          dispatch(
            getAuditStructureSettings.action({
              typeOfControlId: template.id,
            })
          ),
        ];

        if (!structure) {
          promises.push(
            dispatch(
              getAuditStructureForStructureSettings.action({
                inspectionType: template.inspectionType,
              })
            )
          );

          promises.push(
            dispatch(
              getAuditStructureForStructureSettings.action({
                templateId: template.id,
                inspectionType: template.inspectionType,
              })
            )
          );
        }

        return Promise.all(promises);
      })
      .finally(() => {
        stopLoading();
      });
  }, [dispatch, startLoading, stopLoading, structure, template?.id, template?.inspectionType]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.vehicleSettings.labels.condition')}}
      data-testid="settings-vehicle-condition"
      description={i18n.t('page.vehicleSettings.labels.conditionDescription')}
      isLoading={isLoading}
    >
      <ConditionSettings />
    </SettingsTemplate>
  );
}
