import {IconButton, openDialog, showNotification, Tooltip} from 'platform/components';
import {HStack, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, useCallback, useState} from 'react';

import {always} from 'ramda';

import {
  AdvertisementStatusEnum,
  AdvertisingPlatformCodeEnum,
  EntityResourceIds,
  useGetParticipationQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {usePermissions} from '@dms/shared';
import {getStringErrorMessage, PublishingService, useCallApi} from '@dms/teas';

import {PlatformEdit} from './PlatformEdit';

interface ActionsProps {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
  currentStatus: AdvertisementStatusEnum;
  platformTitle: string;
  inSale: boolean;
  statusTriggeredAt: string | null;
}

export const PlatformActions: FC<ActionsProps> = ({
  vehicleId,
  platformCode,
  currentStatus,
  platformTitle,
  inSale,
  statusTriggeredAt,
}) => {
  const update = useCallApi(PublishingService.updateVehicleOnPlatform);
  const repeatLastAction = useCallApi(PublishingService.repeatLastAction);
  const [clickedAt, setClickedAt] = useState<string | null | undefined>(undefined);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const [
    hasEditPlatformSettingsPermission,
    hasRepeatLastPublishingActionPermission,
    hasUpdateVehiclePlatformPermission,
  ] = usePermissions({
    permissionKeys: ['editPlatformSettings', 'repeatLastPublishingAction', 'updateVehiclePlatform'],
    scopes: {
      editPlatformSettings: {participation: vehicleParticipation},
      repeatLastPublishingAction: {participation: vehicleParticipation},
      updateVehiclePlatform: {participation: vehicleParticipation},
    },
  });

  // Prevent BE errors when interval between multiple triggers is less than approx. 5s
  const preventMultipleTriggers = clickedAt === statusTriggeredAt;

  const handleUpdate = useCallback(() => {
    setClickedAt(statusTriggeredAt);
    update({vehicleId, platformCode}).catch((error) => {
      showNotification.error(getStringErrorMessage(error));
    });
  }, [platformCode, vehicleId, update, statusTriggeredAt]);

  const handleRepeatLastAction = useCallback(() => {
    setClickedAt(statusTriggeredAt);
    repeatLastAction({vehicleId, platformCode}).catch((error) => {
      showNotification.error(getStringErrorMessage(error));
    });
  }, [platformCode, vehicleId, repeatLastAction, statusTriggeredAt]);

  const handleOpenDetail = () => {
    openDialog(
      <PlatformEdit
        vehicleId={vehicleId}
        platformCode={platformCode}
        isDisabled={!hasEditPlatformSettingsPermission}
      />,
      {
        title: i18n.t('entity.vehicle.labels.listingAttributes') + ` "${platformTitle}"`,
      }
    );
  };

  const showUpdateAction = currentStatus === AdvertisementStatusEnum.PUBLISHED;

  const showRepeatAction = match(currentStatus)
    .with(
      AdvertisementStatusEnum.PUBLISH_FAILED,
      AdvertisementStatusEnum.UNPUBLISH_FAILED,
      AdvertisementStatusEnum.UPDATE_FAILED,
      always(true)
    )
    .otherwise(always(false));

  return (
    <HStack align="center" justify="flex-end">
      <Show when={hasRepeatLastPublishingActionPermission && showRepeatAction && inSale}>
        <Tooltip label={i18n.t('page.advertisement.actions.repeatLastAction')}>
          <IconButton
            icon="AV/replay"
            onClick={handleRepeatLastAction}
            data-testid={`${platformCode}-repeat-last-action`}
            isDisabled={preventMultipleTriggers}
          />
        </Tooltip>
      </Show>

      <Show when={hasUpdateVehiclePlatformPermission && showUpdateAction && inSale}>
        <Tooltip label={i18n.t('page.advertisement.actions.updateAd')}>
          <IconButton
            icon="action/update"
            onClick={handleUpdate}
            data-testid={`${platformCode}-update-ad`}
            isDisabled={preventMultipleTriggers}
          />
        </Tooltip>
      </Show>

      <Tooltip label={`${i18n.t('page.advertisement.actions.editPlatform')} ${platformTitle}`}>
        <IconButton
          icon="navigation/chevron_right"
          onClick={handleOpenDetail}
          data-testid={`link-advertisement-platforms.${platformCode}`}
        />
      </Tooltip>
    </HStack>
  );
};
