import {isEmpty, isNil, keys} from 'ramda';

import {DrillDownLevel, DrillDownLevelName} from '@dms/api';

import {Nullish} from 'shared';

export function getPermanentLevelFilters(
  permanentFilters: Partial<Record<DrillDownLevelName, string>> | Nullish
): DrillDownLevel[] {
  if (isNil(permanentFilters) || isEmpty(permanentFilters)) {
    return [];
  }

  return keys(permanentFilters).map((levelName, index) => ({
    level: levelName,
    values: [permanentFilters[levelName] ?? ''],
    priority: index + 1,
  }));
}
