import {useSelector} from 'react-redux';

import {selectBranchList} from '@dms/api';
import {
  selectSeries,
  selectVehicleMakes,
  selectVehicleTypes,
  BranchListItem,
  Series,
  SeriesTypeEnum,
  VehicleCatalogueEnumOption,
  VehicleCatalogueMakeItem,
} from '@dms/teas';

type UseVehicleSeriesDefinitionSelectors = () => {
  vehicleTypes: VehicleCatalogueEnumOption[];
  makesList: VehicleCatalogueMakeItem[];
  branchList: BranchListItem[];
  seriesList: Series[];
};

export const useVehicleSeriesDefinitionSelectors: UseVehicleSeriesDefinitionSelectors = () => {
  const vehicleTypes = useSelector(selectVehicleTypes);
  const makesList = useSelector(selectVehicleMakes());
  const {data} = useSelector(selectBranchList);
  const seriesList = useSelector(selectSeries);
  const branchList = data?.branchListItems as BranchListItem[];
  return {
    vehicleTypes,
    makesList,
    branchList,
    seriesList: seriesList.filter(({type}) => type === SeriesTypeEnum.GENERAL_VEHICLE),
  };
};
