import {Grid} from 'platform/foundation';
import {LightboxVideo, useLightbox} from 'platform/lightbox';
import {RcFile} from 'rc-upload/lib/interface';

import {CSSProperties, useEffect} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {useAuditVideos} from '../hooks/useAuditVideos';
import {useConditionContext} from '../hooks/useConditionContext';
import {useVideoUploadContext} from '../hooks/useVideoUploadContext';
import {getPrecedingPlayableVideos} from '../utils/getPrecedingPlayableVideos';
import {isVideoPlayable} from '../utils/isVideoPlayable';
import {AuditVideo} from './AuditVideo';
import {AuditVideoLightbox} from './AuditVideoLightbox';
import {UploadVideo} from './UploadVideo';
import {VideoPlaceholder} from './VideoPlaceholder';

interface AuditVideosProps extends TestIdProps {
  paramDefinitionId: string;
  categoryId: string;
  ratio?: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
}

export function AuditVideos(props: AuditVideosProps) {
  const {videoAssets} = useAuditVideos(props.categoryId, props.paramDefinitionId);
  const {isDisabledForUser} = useConditionContext();
  const [lightboxUploadImagesGalleryControls, {onOpen: onGalleryOpen, isOpen: isGalleryOpen}] =
    useLightbox(`auditVideos-${props.categoryId}-${props.paramDefinitionId}`);
  const {
    uploadingVideos,
    handleStartVideoUpload,
    handleDeleteVideo,
    setBlockNavigationWhenUploading,
  } = useVideoUploadContext();

  useEffect(() => {
    setBlockNavigationWhenUploading(!isGalleryOpen);
  }, [isGalleryOpen, setBlockNavigationWhenUploading]);

  const onStartVideoUpload = (file: RcFile) =>
    handleStartVideoUpload(file, props.categoryId, props.paramDefinitionId);

  const onDeleteVideo = (video: LightboxVideo) =>
    handleDeleteVideo(video, props.categoryId, props.paramDefinitionId);

  return (
    <Grid columns={8}>
      <AuditVideoLightbox
        videoAssets={videoAssets}
        controls={lightboxUploadImagesGalleryControls}
        onDeleteVideo={onDeleteVideo}
      />

      {videoAssets?.map((videoAsset, index) => (
        <AuditVideo
          key={videoAsset.id}
          videoAsset={videoAsset}
          ratio={props.ratio}
          isLoading={!isVideoPlayable(videoAsset)}
          isDisabled={isDisabledForUser}
          onOpenPreview={() => onGalleryOpen(getPrecedingPlayableVideos(videoAssets, index))}
          onDeleteVideo={onDeleteVideo}
          data-testid={suffixTestId('auditVideo', props)}
        />
      ))}

      {uploadingVideos.map((task) => (
        <VideoPlaceholder key={task.id} ratio={props.ratio} progress={task.progress} />
      ))}

      <UploadVideo
        ratio={props.ratio}
        isMandatory={props.isMandatory}
        onStartVideoUpload={onStartVideoUpload}
      />
    </Grid>
  );
}
