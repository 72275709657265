import {IconButton} from 'platform/components';
import {HStack, Icon, Space, VStack, Text} from 'platform/foundation';

import {getFileIcon} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {FormDocument} from '../types/EmailSmsNotificationForm';

interface SelectedDocumentsListProps extends TestIdProps {
  documents: FormDocument[];
  onChange: (newDocuments: FormDocument[]) => void;
}

export function SelectedDocumentsList(props: SelectedDocumentsListProps) {
  return (
    <VStack spacing={2}>
      {props.documents.map((item) => (
        <HStack key={item.uuid}>
          <HStack spacing={4} align="center">
            <Icon value={getFileIcon(item.file.value.contentType)} />
            <Text size="small">{item.file.value.title}</Text>
          </HStack>
          <Space fillAvailable />
          <IconButton
            icon="navigation/cancel"
            severity="danger"
            onClick={() =>
              props.onChange(props.documents.filter((origin) => origin.uuid !== item.uuid))
            }
            data-testid={suffixTestId('removeDocument', props)}
          />
        </HStack>
      ))}
    </VStack>
  );
}
