import {Label, Switch} from 'platform/components';
import {Box, Grid, Show} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isTrue} from 'ramda-adjunct';

import {selectCurrentUserInfo} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {filterLimits, Slider} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../../FiltersContext/FiltersContext';

export function FilterStatistics(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();

  const {margin, priceCZK, daysOnDisplay, liquidity, reclaimableVat, carvagoAvailability} = filters;

  const selectedCurrency = useSelector(selectCurrentUserInfo)?.data?.settings.currency;
  const selectedCurrencySuffix = selectedCurrency ? ` (${selectedCurrency})` : null;
  return (
    <Grid columns={4} spacing={8} data-testid="block.filters-statistics">
      <Box>
        <Label>{`${i18n.t('general.labels.priceWithVat')} ${selectedCurrencySuffix}`}</Label>
        <Slider
          step={null}
          min={0}
          max={filterLimits.PRICE_MAX}
          marks={filterLimits.PRICE_MARKS}
          defaultValue={priceCZK || [0, filterLimits.PRICE_MAX]}
          showMaxPlus
          onChange={(_, newValue) => onUpdateFilters(['priceCZK'], newValue)}
          data-testid={suffixTestId('priceWithVat', props)}
        />
      </Box>

      <Box>
        <Label tooltip={i18n.t('entity.vehicle.labels.marginTooltip')}>
          {`${i18n.t('entity.vehicle.labels.margin')} ${selectedCurrencySuffix}`}
        </Label>
        <Slider
          step={1000}
          min={0}
          showMaxPlus
          max={filterLimits.MARGIN_LIMIT_MAX}
          defaultValue={margin || [0, filterLimits.MARGIN_LIMIT_MAX]}
          onChange={(_, newValue) => onUpdateFilters(['margin'], newValue)}
          data-testid={suffixTestId('margin', props)}
        />
      </Box>

      <Box>
        <Label tooltip={i18n.t('entity.vehicle.labels.daysOnDisplayTooltip')}>
          {i18n.t('entity.vehicle.labels.daysOnDisplay')}
        </Label>
        <Slider
          step={1}
          min={0}
          showMaxPlus
          max={filterLimits.DAYS_ON_DISPLAY}
          defaultValue={daysOnDisplay || [0, filterLimits.DAYS_ON_DISPLAY]}
          onChange={(_, newValue) => onUpdateFilters(['daysOnDisplay'], newValue)}
          data-testid={suffixTestId('daysOnDisplay', props)}
        />
      </Box>

      <Box>
        <Label tooltip={i18n.t('entity.vehicle.labels.liquidityTooltip')}>
          {i18n.t('entity.vehicle.labels.liquidity')}
        </Label>
        <Slider
          step={12}
          min={0}
          showMaxPlus
          max={filterLimits.LIQUIDITY_MAX}
          defaultValue={liquidity || [0, filterLimits.LIQUIDITY_MAX]}
          onChange={(_, newValue) => onUpdateFilters(['liquidity'], newValue)}
          data-testid={suffixTestId('liquidity', props)}
        />
      </Box>

      <Switch
        value={isTrue(reclaimableVat)}
        onChange={(checked) => onUpdateFilters(['reclaimableVat'], checked)}
        label={i18n.t('general.labels.reclaimableVAT')}
        data-testid={suffixTestId('reclaimableVat', props)}
      />

      <Show whenFeatureEnabled={featureFlags.SOURCING_CARVAGO_AVAILABILITY}>
        <Switch
          value={isTrue(carvagoAvailability)}
          onChange={(checked) => onUpdateFilters(['carvagoAvailability'], checked)}
          label={i18n.t('entity.sourcing.filters.labels.availableOnCarvago')}
          data-testid={suffixTestId('carvagoAvailability', props)}
        />
      </Show>
    </Grid>
  );
}
