import {useSelector} from 'react-redux';

import {useGetBranchQuery, selectActiveBranchId} from '@dms/api';

export const useGetCurrentBranch = () => {
  const activeBranchId = useSelector(selectActiveBranchId);

  const activeBranch = useGetBranchQuery({branchId: activeBranchId});

  return {activeBranchId, ...activeBranch};
};
