import {
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  RadioItem,
  showNotification,
  TextInput,
} from 'platform/components';
import {Grid, Show, Text, VStack} from 'platform/foundation';
import {boolean, number, object} from 'yup';

import {usePatchServiceCaseJobCustomPriceApiMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, useGetVatRatesOptions, useTenant, JobType} from '@dms/shared';

import {yupString} from 'shared';

interface CustomPriceProps {
  serviceCaseId: string;
  serviceOrderId: string;
  job: JobType;
  onClose: () => void;
}

interface CustomPriceFormFields {
  isPriceWithVat: boolean;
  isUniformVatType: boolean;
  isFixedPrice: boolean;
  amount?: number;
  currency: string;
  vatType?: string;
}

export function CustomPrice(props: CustomPriceProps) {
  const [vatOptions] = useGetVatRatesOptions();
  const {tenantCurrency} = useTenant();

  const [patchServiceCaseJobCustomPrice] = usePatchServiceCaseJobCustomPriceApiMutation();

  const onSubmit: FormSubmitHandler<CustomPriceFormFields> = async (values) => {
    if (!props.job.id) {
      return;
    }

    await patchServiceCaseJobCustomPrice({
      serviceCaseId: props.serviceCaseId,
      serviceJobId: props.job.id,
      serviceOrderId: props.serviceOrderId,
      body: {
        isFixedPrice: values.isFixedPrice,
        fixedPrice: values.isFixedPrice
          ? {
              isUniformVatType: values.isUniformVatType,
              amount: values.amount ?? 0,
              currency: tenantCurrency,
              vatType: values.isUniformVatType ? values.vatType : undefined,
              isPriceWithVat: values.isPriceWithVat,
            }
          : undefined,
      },
    })
      .unwrap()
      .then(() => {
        props.onClose();
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const defaultValues: CustomPriceFormFields = {
    isPriceWithVat: !!props.job.fixedPrice?.withVat,
    isUniformVatType: !!props.job.fixedPrice?.isUniformVatType,
    isFixedPrice: props.job.isFixedPrice ?? false,
    amount:
      props.job.fixedPrice?.withoutVat?.amount ??
      props.job.fixedPrice?.withVat?.amount ??
      undefined,
    currency:
      props.job.fixedPrice?.withoutVat?.currency ??
      props.job.fixedPrice?.withVat?.currency ??
      tenantCurrency,
    vatType: props.job.fixedPrice?.vat?.type ?? undefined,
  };

  return (
    <Form<CustomPriceFormFields> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
      {(control, formApi) => (
        <VStack spacing={4}>
          <Card
            title={i18n.t('entity.orderRequest.actions.allowCustomPrice')}
            variant="inlineGrey"
            control={{
              type: 'switch',
              value: formApi.watch('isFixedPrice'),
              onChange: (value) => formApi.setValue('isFixedPrice', value),
            }}
            isExpanded={formApi.watch('isFixedPrice')}
          >
            <Show when={formApi.watch('isFixedPrice')}>
              <VStack spacing={4}>
                <Grid columns={2}>
                  <VStack spacing={3}>
                    <RadioItem
                      value={!formApi.watch('isPriceWithVat')}
                      onChange={() => formApi.setValue('isPriceWithVat', false)}
                      children={i18n.t('general.labels.sellingPriceWithoutVat')}
                      data-testid={testIds.workshop.serviceCaseDetail(
                        'sellingPriceWithoutVat-isPriceWithVat'
                      )}
                    />
                    <Show when={!formApi.watch('isPriceWithVat')}>
                      <FormField
                        name="amount"
                        type="number"
                        control={control}
                        suffix={tenantCurrency}
                        isRequired
                        data-testid={testIds.workshop.serviceCaseDetail(
                          'sellingPriceWithoutVat-amount'
                        )}
                      />
                    </Show>
                    <Show when={formApi.watch('isPriceWithVat')}>
                      <TextInput
                        value=""
                        isDisabled
                        suffix={tenantCurrency}
                        data-testid={testIds.workshop.serviceCaseDetail('sellingPriceWithoutVat')}
                      />
                    </Show>
                  </VStack>
                  <VStack spacing={3}>
                    <RadioItem
                      value={formApi.watch('isPriceWithVat')}
                      onChange={() => formApi.setValue('isPriceWithVat', true)}
                      children={i18n.t('general.labels.sellingPriceWithVat')}
                      data-testid={testIds.workshop.serviceCaseDetail(
                        'sellingPriceWithVat-isPriceWithVat'
                      )}
                    />
                    <Show when={formApi.watch('isPriceWithVat')}>
                      <FormField
                        name="amount"
                        type="number"
                        control={control}
                        suffix={tenantCurrency}
                        isRequired
                        data-testid={testIds.workshop.serviceCaseDetail(
                          'sellingPriceWithVat-amount'
                        )}
                      />
                    </Show>
                    <Show when={!formApi.watch('isPriceWithVat')}>
                      <TextInput
                        value=""
                        isDisabled
                        suffix={tenantCurrency}
                        data-testid={testIds.workshop.serviceCaseDetail('sellingPriceWithVat')}
                      />
                    </Show>
                  </VStack>
                </Grid>
                <FormField
                  name="isUniformVatType"
                  type="switch"
                  control={control}
                  label={i18n.t('entity.orderRequest.actions.setUniformVatRate')}
                  data-testid={testIds.workshop.serviceCaseDetail('isUniformVatType')}
                />
                <Text size="xSmall" color="tertiary">
                  {i18n.t('entity.orderRequest.labels.uniformVatRate')}
                </Text>
                <Show when={formApi.watch('isUniformVatType')}>
                  <Grid columns={2}>
                    <FormField
                      control={control}
                      type="choice"
                      name="vatType"
                      options={vatOptions}
                      label={i18n.t('entity.addWork.lables.vat')}
                      placeholder={i18n.t('general.labels.select')}
                      menuInPortal
                      isRequired
                      data-testid={testIds.workshop.serviceCaseDetail('customPrice-vatType')}
                    />
                  </Grid>
                </Show>
              </VStack>
            </Show>
          </Card>
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.cancel')}
              variant="secondary"
              onClick={closeCurrentDialog}
              data-testid={testIds.workshop.serviceCaseDetail('customPrice-cancel')}
            />
            <FormButton
              title={i18n.t('general.actions.confirm')}
              type="submit"
              control={control}
              data-testid={testIds.workshop.serviceCaseDetail('customPrice-confirm')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  isFixedPrice: boolean().required(),
  amount: number().when('isFixedPrice', {
    is: true,
    then: number().required(),
  }),
  vatType: yupString.when('isFixedPrice', {
    is: true,
    then: yupString.when('isUniformVatType', {
      is: true,
      then: yupString.required(),
    }),
  }),
});
