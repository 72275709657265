import {Dialog} from 'platform/components';
import {Show} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';

import {
  GetOrderItemForMaterialApiResponse,
  GetServiceOrderJobItemWorkApiResponse,
  LabeledKey,
  useLazyGetOrderItemForMaterialQuery,
  useLazyGetServiceOrderJobItemWorkQuery,
} from '@dms/api';
import {EditMaterialItem, handleApiError} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ControlledRowControl} from 'features/datagrid';

import {MATERIAL_TYPE} from '../constants/materialType';
import {EditItem} from './EditItem/EditItem';

export type Item = (GetServiceOrderJobItemWorkApiResponse | GetOrderItemForMaterialApiResponse) & {
  rowId: string;
  jobId: string;
  priceType: string;
};

export type ReceiveJobItemRowData = {
  itemName: {value: string};
  itemType: {value: LabeledKey};
  itemPriceType: {value: LabeledKey};
  itemGoodwillRatio: {value: {label: string} | Nullish};
  serviceOrderJobItemId: {value: string};
  serviceJobId: {value: string};
};

export type RequestEditDialogProps = {
  gridCode: string;
  rowId?: string;
  serviceCaseId: string;
  orderId: string;
  itemData?: ReceiveJobItemRowData;
  dataQueryId?: string;
  itemType?: string;
  onAfterSubmit: () => void;
  onClose: () => void;
} & RequiredTestIdProps;

export function RequestEditDialog(props: RequestEditDialogProps) {
  const [item, setItem] = useState<Item | null>(null);

  const [getMaterialItem] = useLazyGetOrderItemForMaterialQuery();
  const [getLabourItem] = useLazyGetServiceOrderJobItemWorkQuery();

  const isItemLabour = item && 'workType' in item;
  const isItemMaterial = item && 'warehouse' in item;

  const onItemIdChanged = async (item: ReceiveJobItemRowData, rowId: string) => {
    const serviceItemId = item.serviceOrderJobItemId.value;
    const serviceJobId = item.serviceJobId.value;

    let response: Omit<Item, 'rowId' | 'priceType' | 'jobId'> | Nullish | void = null;

    if (item.itemType.value.key === MATERIAL_TYPE) {
      response = await getMaterialItem({
        serviceCaseId: props.serviceCaseId,
        serviceOrderId: props.orderId,
        serviceJobId,
        serviceItemId,
      })
        .unwrap()
        .catch(handleApiError);
    } else {
      response = await getLabourItem({
        serviceCaseId: props.serviceCaseId,
        serviceOrderId: props.orderId,
        serviceJobId,
        serviceItemId,
      })
        .unwrap()
        .catch(handleApiError);
    }

    setItem({...response, rowId, priceType: item.itemPriceType.value.label, jobId: serviceJobId});
  };

  useEffect(() => {
    if (
      props.rowId &&
      props.rowId !== item?.rowId &&
      props.itemData?.serviceOrderJobItemId?.value &&
      props.itemData?.serviceOrderJobItemId?.value !== item?.id
    ) {
      onItemIdChanged({...props.itemData}, props.rowId);
    }
  }, [props.itemData, props.rowId]);

  const itemName = item ? `${item?.name} (${item?.priceType})` : '';

  return (
    <>
      <Dialog
        title={itemName}
        isOpen={isNotNil(props.rowId)}
        scrollBehavior="inside"
        onClose={props.onClose}
        pagination={
          <Show when={isNotNil(props.dataQueryId) && isNotNil(item)}>
            <ControlledRowControl
              dataQueryId={props.dataQueryId as string}
              gridCode={props.gridCode}
              entityId={item?.rowId as string}
              onPageChange={(item) => {
                // eslint-disable-next-line no-restricted-syntax
                const castedRowData = item.cells as unknown as ReceiveJobItemRowData;
                onItemIdChanged(castedRowData, item.id);
              }}
            />
          </Show>
        }
        data-testid={props['data-testid']}
      >
        <Show when={isItemMaterial}>
          <EditMaterialItem
            itemId={item?.id ?? ''}
            onClose={props.onClose}
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.orderId ?? ''}
            serviceJobId={item?.jobId ?? ''}
            onEdited={props.onAfterSubmit}
            data-testid={suffixTestId('editMaterialItem', props)}
          />
        </Show>

        <Show when={isItemLabour}>
          <EditItem
            itemId={item?.id ?? ''}
            onClose={props.onClose}
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.orderId ?? ''}
            serviceJobId={item?.jobId ?? ''}
            onEdited={props.onAfterSubmit}
            data-testid={suffixTestId('editJobItem', props)}
          />
        </Show>
      </Dialog>
    </>
  );
}
