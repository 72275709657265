import i18n from '@dms/i18n';

export const CLASSIFIENDS_SORT_BY = [
  {
    name: i18n.t('entity.vehicle.labels.sortAlphabet'),
    orderBy: [
      'car.make',
      'car.model',
      'year',
      'cubic_capacity',
      'body_type',
      'power',
      'transmission',
      'drive_type',
      'fuel_type',
      'mileage_range',
      'price',
    ],
    id: 'ALPHABET',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByNewestAd'),
    orderBy: ['days_on_stock'],
    id: 'NEWEST_AD',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByOldestAd'),
    orderBy: ['-days_on_stock'],
    id: 'OLDES_AD',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByLowestMileage'),
    orderBy: ['mileage_range'],
    id: 'LOWEST_MILEAGE',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByNewestCar'),
    orderBy: ['-year'],
    id: 'NEWEST_CAR',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByHighestPrice'),
    orderBy: ['-price'],
    id: 'HIGHEST_PRICE',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByLowestPrice'),
    orderBy: ['price'],
    id: 'LOWEST_PRICE',
  },
  {
    name: i18n.t('entity.vehicle.labels.sortByFeatureRank'),
    orderBy: ['-feature_score'],
    id: 'FEATURE_RANK',
  },
];
