import {Card, Flag, Separator, Percentage, DataStatus, Status} from 'platform/components';
import {Text, Heading, Show, HStack, Space} from 'platform/foundation';

import {isNil, isNotEmpty} from 'ramda';

import {useSourcingGetCarFeaturesQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {VehicleCatalogueEnumOption} from '../../types/VehicleCatalogueEnumOption';

type FeatureMarketAnalysisCardProps = {
  allFeatures: VehicleCatalogueEnumOption[];
  vehicleFeatures: string[];
  vehicleMake: string | Nullish;
  vehicleModel: string | Nullish;
  vehicleYear: number | Nullish;
} & TestIdProps;

const PERCENTAGE_MULTIPLIER = 100;

export function FeatureMarketAnalysisCard(props: FeatureMarketAnalysisCardProps) {
  const {data, isLoading} = useSourcingGetCarFeaturesQuery(
    {
      body: {
        make: props.vehicleMake ?? '',
        model: props.vehicleModel ?? '',
        year: props.vehicleYear ?? 0,
        features: props.vehicleFeatures,
      },
    },
    {
      skip: isNil(props.vehicleMake) || isNil(props.vehicleModel) || isNil(props.vehicleYear),
    }
  );

  const highlightedItems = props.allFeatures
    .filter((item) => (item?.value ? data?.highlightedFeatures?.includes(item.value) : false))
    .map((i) => ({id: i.value || '', label: i.label}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const premiumItems = props.allFeatures
    .filter((item) => (item?.value ? data?.premiumFeatures?.includes(item.value) : false))
    .map((i) => ({id: i.value || '', label: i.label}))
    .sort(
      (a, b) =>
        (data?.premiumFeatures?.indexOf(a.id) ?? 0) - (data?.premiumFeatures?.indexOf(b.id) ?? 0)
    );

  const featureScore = data?.featuresScore;
  const percentage = isNil(featureScore) ? 0 : Math.floor(featureScore * PERCENTAGE_MULTIPLIER);

  return (
    <Card
      data-testid={suffixTestId('marketAnalysisCard', props)}
      title={i18n.t('entity.vehicle.labels.featureMarketAnalysis')}
    >
      <DataStatus isLoading={isLoading}>
        <Show whenFeatureEnabled={featureFlags.SALES_FEATURE_SCORING}>
          <Heading size={5}>{i18n.t('entity.vehicle.labels.featureScore')}</Heading>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.vehicle.labels.featureScoreSubtitle')}
          </Text>
          <Percentage
            percentage={percentage}
            data-testid={suffixTestId(`marketAnalysisCard-battery-percentage`, props)}
          />
          <Separator />
        </Show>
        <Heading size={5}>{i18n.t('entity.vehicle.labels.premium')}</Heading>
        <Text size="xSmall" color="tertiary">
          {i18n.t('entity.vehicle.labels.premiumSubtitle')}
        </Text>
        <Space vertical={4} />
        {isNotEmpty(premiumItems) ? (
          <HStack spacing={2} wrap>
            {premiumItems.map((item) => (
              <Flag
                key={item.id}
                colorScheme="blue"
                label={item.label ?? ''}
                data-testid={suffixTestId(`marketAnalysisCard-premium-item-${item.id}`, props)}
              />
            ))}
          </HStack>
        ) : (
          <Status headline={i18n.t('entity.vehicle.labels.noPremiumFeaturesToShow')} />
        )}
        <Separator />
        <Heading size={5}>{i18n.t('entity.vehicle.labels.highlight')}</Heading>
        <Text size="xSmall" color="tertiary">
          {i18n.t('entity.vehicle.labels.highlightSubtitle')}
        </Text>
        <Space vertical={4} />
        {isNotEmpty(highlightedItems) ? (
          <HStack spacing={2} wrap>
            {highlightedItems.map((item) => (
              <Flag
                key={item.id}
                colorScheme="blue"
                isSubtle
                data-testid={suffixTestId(`marketAnalysisCard-highlighted-item-${item.id}`, props)}
                label={item.label ?? ''}
              />
            ))}
          </HStack>
        ) : (
          <Status
            headline={i18n.t('entity.vehicle.labels.noFeaturesToShow')}
            data-testid={suffixTestId(`marketAnalysisCard-highlighted-empty`, props)}
          />
        )}
      </DataStatus>
    </Card>
  );
}
