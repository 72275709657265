import {match} from 'ts-pattern';

import {always} from 'ramda';

import {useGetInvoiceMarginsQuery, OrderResponseBody} from '@dms/api';
import {useGetCurrentBranch} from '@dms/shared';

import {useIsBusinessCaseVehicleDeductible} from '../../../utils/useIsBusinessCaseVehicleDeductible';

export const useIsStandardInvoiceDisabled = (order: OrderResponseBody, businessCaseId: string) => {
  const {data: invoiceMargins} = useGetInvoiceMarginsQuery();
  const {activeBranchId} = useGetCurrentBranch();
  const idDeductible = useIsBusinessCaseVehicleDeductible(businessCaseId);
  const currentInvoiceMargin =
    invoiceMargins?.find((item) => item.branchId === activeBranchId) ??
    invoiceMargins?.find((item) => item.isSystem) ??
    null;

  const isBrokerage = order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE';
  const shouldHideStandardInvoice =
    match([order.orderDiscriminator, idDeductible, isBrokerage])
      .with(
        ['PURCHASE_BROKERAGE_SALE', true, true],
        always(currentInvoiceMargin?.hideStandardInvoicesBrokerageNotVatDeductible)
      )
      .with(
        ['SALE', true, false],
        always(currentInvoiceMargin?.hideStandardInvoicesWarehouseVatDeductible)
      )
      .with(
        ['SALE', false, false],
        always(currentInvoiceMargin?.hideStandardInvoicesWarehouseNotVatDeductible)
      )
      .otherwise(always(false)) ?? false;

  return [shouldHideStandardInvoice];
};
