import {useLocation, useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {getEntityResourceIdFromPathname} from '../utils/getEntityResourceIdFromPathname';

export function useResourceIdentifier() {
  const location = useLocation();
  const params = useParams();

  const resourceId = getEntityResourceIdFromPathname(location.pathname);

  if (isNilOrEmpty(resourceId) || isNilOrEmpty(params.id)) {
    return {
      resourceId: undefined,
      resourceRecordId: undefined,
    };
  }

  return {
    resourceId,
    resourceRecordId: params.id,
  };
}
