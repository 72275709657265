import {
  Alert,
  Button,
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {
  always,
  defaultTo,
  identity,
  is,
  lensPath,
  mergeDeepLeft,
  mergeDeepRight,
  over,
  pipe,
} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  ProductRequestBody,
  selectTenant,
  useCreateProductMutation,
  useEditProductMutation,
  useGetProductQuery,
  VatRateType,
} from '@dms/api';
import i18n from '@dms/i18n';
import {CalculatePrice, handleApiError} from '@dms/shared';
import {selectVatRatesByCountryCode} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

interface AddOrEditProductCatalogItemProps extends TestIdProps {
  productId?: string;
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
}

/**
 * S stands for the standard VAT rate
 */
const DEFAULT_VAT_RATE_TYPE: VatRateType = 'S';
const DEFAULT_VALUES = {
  purchasePrice: {vatType: DEFAULT_VAT_RATE_TYPE},
  sellingPrice: {vatType: DEFAULT_VAT_RATE_TYPE},
};

export function AddOrEditProductCatalogItem(props: AddOrEditProductCatalogItemProps) {
  const isEdit = !!props.productId;

  const {data} = useSelector(selectTenant);

  const [editProduct] = useEditProductMutation();
  const [createProduct] = useCreateProductMutation();
  const {
    data: productItem,
    isLoading,
    isError,
  } = useGetProductQuery({productId: props.productId ?? ''}, {skip: !isEdit});

  const countryCode = data?.country;
  const currency = data?.currency;

  const vatRates = useSelector(selectVatRatesByCountryCode(countryCode))?.rates?.map((v) => ({
    ...v,
    value: v.type,
    label: `${v.rate} % ${v.name}`,
    fieldLabel: `${v.rate} %`,
  }));

  const [lastTouchedSellingField, setLastTouchedSellingField] = useState<
    'withVat' | 'withoutVat'
  >();
  const [lastTouchedPurchaseField, setLastTouchedPurchaseField] = useState<
    'withVat' | 'withoutVat'
  >();

  const defaultValues = mergeDeepRight(defaultTo({}, productItem), DEFAULT_VALUES);

  const handleChangeVatCode = (
    value: string | number | string[] | null,
    formApi: UseFormReturn<ProductRequestBody>
  ) => {
    if (!is(String, value)) {
      return;
    }
    if (!lastTouchedSellingField) {
      setLastTouchedSellingField('withVat');
    }
    if (!lastTouchedPurchaseField) {
      setLastTouchedPurchaseField('withVat');
    }
    formApi.setValue('sellingPrice.vatType', value as VatRateType);
    formApi.setValue('purchasePrice.vatType', value as VatRateType);
  };

  const handleSubmit: FormSubmitHandler<ProductRequestBody> = async (values) => {
    const purchasePriceVatRate = vatRates?.find(
      (rate) => rate.type === values.purchasePrice?.vatType
    )?.rate;
    const sellingPriceVatRate = vatRates?.find(
      (rate) => rate.type === values.sellingPrice?.vatType
    )?.rate;

    const isPurchasePriceEmpty =
      isNilOrEmpty(values.purchasePrice?.priceWoVat?.amount) &&
      isNilOrEmpty(values.purchasePrice?.priceWithVat?.amount);

    const bodyMetadata = {
      purchasePrice: isPurchasePriceEmpty
        ? null
        : {
            priceWithVat: {currency},
            priceWoVat: {currency},
            vatRate: purchasePriceVatRate,
          },
      sellingPrice: {
        priceWithVat: {currency},
        priceWoVat: {currency},
        vatRate: sellingPriceVatRate,
      },
    };

    const bodyValues = mergeDeepLeft(bodyMetadata, values);

    const body = pipe(
      over(lensPath(['purchasePrice', 'priceWoVat', 'amount']), String),
      over(lensPath(['purchasePrice', 'priceWithVat', 'amount']), String),
      over(lensPath(['sellingPrice', 'priceWoVat', 'amount']), String),
      over(lensPath(['sellingPrice', 'priceWithVat', 'amount']), String),
      over(lensPath(['purchasePrice']), isPurchasePriceEmpty ? always(null) : identity)
    )(bodyValues);

    const result = isEdit ? editProduct({body, id: props.productId ?? ''}) : createProduct({body});
    await result.unwrap().then(props.onSuccess).catch(handleApiError);
  };

  const handleCalculationChange = useCallback(
    (formApi: UseFormReturn<ProductRequestBody>, name: 'purchasePrice' | 'sellingPrice') =>
      (res: {withVat?: string} | {withoutVat?: string}) => {
        if ('withVat' in res) {
          formApi.setValue(`${name}.priceWithVat.amount`, res?.withVat || '');
        }

        if ('withoutVat' in res) {
          formApi.setValue(`${name}.priceWoVat.amount`, res?.withoutVat || '');
        }
      },
    []
  );

  return (
    /**
     * TODO: Refactor this - [T20-17714]
     */
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ProductRequestBody>
        schema={schema}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
      >
        {(control, formApi) => {
          const [
            purchasePriceVatType,
            purchasePriceWithVat,
            purchasePriceWithoutVat,
            sellingPriceVatType,
            sellingPriceWithVat,
            sellingPriceWithoutVat,
          ] = formApi.watch([
            'purchasePrice.vatType',
            'purchasePrice.priceWithVat.amount',
            'purchasePrice.priceWoVat.amount',
            'sellingPrice.vatType',
            'sellingPrice.priceWithVat.amount',
            'sellingPrice.priceWoVat.amount',
          ]);

          return (
            <VStack spacing={4}>
              <Box width="50%">
                <FormField
                  name="name"
                  type="text"
                  control={control}
                  label={i18n.t('entity.productCatalog.labels.itemName')}
                  data-testid={suffixTestId('name', props)}
                />
              </Box>

              {/* PURCHASE PRICE CALCULATION */}
              <CalculatePrice
                amount={
                  lastTouchedPurchaseField === 'withVat'
                    ? purchasePriceWithVat
                    : purchasePriceWithoutVat
                }
                countryCode={countryCode}
                type={lastTouchedPurchaseField}
                vatCode={purchasePriceVatType}
                onChange={handleCalculationChange(formApi, 'purchasePrice')}
              />
              {/* SELLING PRICE CALCULATION */}
              <CalculatePrice
                amount={
                  lastTouchedSellingField === 'withVat'
                    ? sellingPriceWithVat
                    : sellingPriceWithoutVat
                }
                countryCode={countryCode}
                type={lastTouchedSellingField}
                vatCode={sellingPriceVatType}
                onChange={handleCalculationChange(formApi, 'sellingPrice')}
              />
              <HStack>
                <Box width="50%">
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        type="number"
                        decimalPlaces={2}
                        control={control}
                        name="purchasePrice.priceWoVat.amount"
                        label={i18n.t('entity.productCatalog.labels.buyingPriceWithoutVat')}
                        onChange={() => setLastTouchedPurchaseField('withoutVat')}
                        data-testid={suffixTestId('purchasePriceWoVat', props)}
                      />
                    </Box>

                    <Box width={25}>
                      <FormField
                        label={i18n.t('general.labels.vat')}
                        type="choice"
                        control={control}
                        options={vatRates}
                        name="purchasePrice.vatType"
                        onChange={(value) => handleChangeVatCode(value, formApi)}
                        isNotClearable
                        menuInPortal
                        data-testid={suffixTestId('purchaseVatType', props)}
                      />
                    </Box>
                  </HStack>
                </Box>

                <Box flex={1} paddingLeft={4}>
                  <FormField
                    type="number"
                    decimalPlaces={2}
                    control={control}
                    name="purchasePrice.priceWithVat.amount"
                    label={i18n.t('entity.productCatalog.labels.buyingPriceWithVat')}
                    onChange={() => setLastTouchedPurchaseField('withVat')}
                    data-testid={suffixTestId('purchasePriceWithVat', props)}
                  />
                </Box>
              </HStack>
              <HStack>
                <Box width="50%">
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        type="number"
                        decimalPlaces={2}
                        control={control}
                        name="sellingPrice.priceWoVat.amount"
                        label={i18n.t('general.labels.sellingPriceWithoutVat')}
                        onChange={() => setLastTouchedSellingField('withoutVat')}
                        data-testid={suffixTestId('sellingPriceWoVat', props)}
                      />
                    </Box>

                    <Box width={25}>
                      <FormField
                        label={i18n.t('general.labels.vat')}
                        type="choice"
                        control={control}
                        options={vatRates}
                        name="sellingPrice.vatType"
                        onChange={(value) => handleChangeVatCode(value, formApi)}
                        isNotClearable
                        menuInPortal
                        data-testid={suffixTestId('sellingPriceVatType', props)}
                      />
                    </Box>
                  </HStack>
                </Box>

                <Box flex={1} paddingLeft={4}>
                  <FormField
                    type="number"
                    decimalPlaces={2}
                    control={control}
                    name="sellingPrice.priceWithVat.amount"
                    label={i18n.t('general.labels.sellingPriceWithVat')}
                    onChange={() => setLastTouchedSellingField('withVat')}
                    data-testid={suffixTestId('sellingPriceWithVat', props)}
                  />
                </Box>
              </HStack>
              <Show when={!purchasePriceWithVat && !purchasePriceWithoutVat}>
                <Alert variant="info" message={i18n.t('entity.productCatalog.labels.enterCosts')} />
              </Show>
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={props.onCancel}
                  title={i18n.t('general.actions.discard')}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  type="submit"
                  variant="primary"
                  control={control}
                  title={i18n.t('general.actions.save')}
                  data-testid={suffixTestId('add', props)}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = Yup.object().shape(
  {
    name: Yup.string().required().max(255),
    purchasePrice: Yup.object().shape({
      priceWithVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPricePurchasing',
          i18n.t('general.notifications.priceMin', {min: '0.01'}),
          (value) => !value || parseFloat(value) >= 0.01
        ),
      }),
      priceWoVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPricePurchasing',
          i18n.t('general.notifications.priceMin', {min: '0.01'}),
          (value) => !value || parseFloat(value) >= 0.01
        ),
      }),
    }),
    sellingPrice: Yup.object().shape({
      priceWithVat: Yup.object().shape({
        amount: Yup.mixed()
          .test(
            'minPriceSelling',
            i18n.t('general.notifications.priceMin', {min: '0.01'}),
            (value) => !value || parseFloat(value) >= 0.01
          )
          .test(
            'isRequiredSelling',
            i18n.t`general.notifications.fieldIsRequired`,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (value, context: Record<string, any>) => {
              const sellingPrice = context?.from?.[2]?.value?.sellingPrice;
              const purchasePrice = context?.from?.[2]?.value?.purchasePrice;

              return sellingPrice?.priceWoVat?.amount
                ? !!value
                : purchasePrice?.priceWithVat?.amount || purchasePrice?.priceWoVat?.amount;
            }
          ),
      }),
      priceWoVat: Yup.object().shape({
        amount: Yup.mixed()
          .test(
            'minPriceSelling',
            i18n.t('general.notifications.priceMin', {min: '0.01'}),
            (value) => !value || parseFloat(value) >= 0.01
          )
          .test(
            'isRequiredSelling',
            i18n.t`general.notifications.fieldIsRequired`,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (value, context: Record<string, any>) => {
              const sellingPrice = context?.from?.[2]?.value?.sellingPrice;
              const purchasePrice = context?.from?.[2]?.value?.purchasePrice;

              return sellingPrice?.priceWithVat?.amount
                ? !!value
                : purchasePrice?.priceWithVat?.amount || purchasePrice?.priceWoVat?.amount;
            }
          ),
      }),
    }),
  },
  [['purchasePrice', 'sellingPrice']]
);
