import {
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  openDialog,
  Separator,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {path} from 'ramda';
import {isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  useGetDirectSaleQuery,
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {TestIdProps} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {ArticleCreate} from '../../../ArticleCreate/ArticleCreate';
import {PriceListExternalFilters} from '../../../PriceList/components/PriceListExternalFilters';
import {
  ActionType,
  ItemData,
  ItemInformation,
  WarehouseAvailability,
  WarehouseAvailabilityQueryModifier,
} from '../../components/WarehouseAvailability';

const PRICE_LIST_EXTERNAL_FILTER_ID = 'price-list-external-filter';

interface PriceListTabProps extends TestIdProps {
  onWarehouseRequest: (warehouseArticleId: string) => Promise<void>;
  onWarehouseNonBinding: (warehouseArticleId: string) => Promise<void>;
  onWarehouseDispatch: (warehouseArticleId: string) => Promise<void>;
}

export function RequestTabMaterialPriceListTab(props: PriceListTabProps) {
  const {directSaleId} = useWarehouseParams();

  const [getArticleIdByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();

  const {
    data: directSale,
    isLoading: isDirectSaleLoading,
    isError: hasDirectSaleError,
  } = useGetDirectSaleQuery({directSaleId}, {skip: isNilOrEmpty(directSaleId)});

  const handleSendToBasket = (articleId: string, actionKey: ActionType) => {
    if (actionKey === 'request') {
      props.onWarehouseRequest(articleId);
    }
    if (actionKey === 'nonBinding') {
      props.onWarehouseNonBinding(articleId);
    }
    if (actionKey === 'dispatch') {
      props.onWarehouseDispatch(articleId);
    }

    closeCurrentDialog();
  };

  const handleOpenDialog = (
    itemInformation: ItemInformation,
    queryModifier: WarehouseAvailabilityQueryModifier
  ) => {
    const handleOnItemClick = async (item: ItemData) => {
      const articleIdResponse = await getArticleIdByManufacturerNumberAndManufacturerId({
        warehouseId: item.warehouseId,
        manufacturerNumber: item.catalogNumber,
        manufacturerId: item.manufacturerId,
      })
        .unwrap()
        .catch(handleApiError);

      const articleId = articleIdResponse?.articleId;

      if (articleId) {
        handleSendToBasket(articleId, item.actionKey);
        return;
      }

      openDialog(
        <ArticleCreate
          defaultValues={{
            warehouseId: item.warehouseId,
            catalogNumber: item.catalogNumber,
            manufacturerId: item.manufacturerId,
          }}
          hasAddToBasketButton
          onAddToBasket={(articleId) => {
            handleSendToBasket(articleId, item.actionKey);

            closeCurrentDialog();
          }}
          onArticleCreate={(createdArticleData) => {
            handleSendToBasket(createdArticleData.articleId, item.actionKey);

            closeCurrentDialog();
          }}
          onClose={() => closeDialog('createArticleDialog')}
          data-testid={testIds.warehouse.priceList('dialogs.createArticle')}
        />,
        {
          id: 'createArticleDialog',
          title: i18n.t('entity.warehouse.labels.createNewArticle'),
          withAdditionalFooter: true,
        }
      );
    };

    openDialog(
      <WarehouseAvailability
        onItemClick={handleOnItemClick}
        itemInformation={itemInformation}
        queryModifier={queryModifier}
      />,
      {
        id: 'warehouseAvailabilityDialog',
        title: i18n.t('entity.warehouse.labels.warehouseAvailability'),
        scrollBehavior: 'outside',
        size: 'large',
      }
    );
  };

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    const catalogNumber = path(['catalogNumber', 'value'], rowData);
    const name = path(['name', 'value'], rowData);
    const manufacturerLabel = path(['manufacturer', 'value', 'label'], rowData);
    const manufacturerId = path(['manufacturer', 'value', 'id'], rowData);
    const supplierLabel = path(['supplier', 'value', 'label'], rowData);

    if (!isString(catalogNumber)) {
      throw new Error('Catalog number is not defined');
    }

    if (!isString(name)) {
      throw new Error('Name is not defined');
    }

    if (!isString(manufacturerId)) {
      throw new Error('Manufacturer id is not defined');
    }

    if (!isString(manufacturerLabel)) {
      throw new Error('Manufacturer label is not defined');
    }

    if (!isString(supplierLabel)) {
      throw new Error('Supplier label is not defined');
    }

    const itemInformation: ItemInformation = {
      catalogNumber,
      name,
      manufacturerLabel,
      supplierLabel,
    };

    const queryModifier: WarehouseAvailabilityQueryModifier = {
      variantId: directSale?.directSaleVariantId as string,
      originObjectType: directSale?.originObjectType as string,
      manufacturerId,
      catalogNumber,
    };

    match(actionKey)
      .with('createArticle', () => {
        handleOpenDialog(itemInformation, queryModifier);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <DataStatus isLoading={isDirectSaleLoading} isError={hasDirectSaleError} minHeight={100}>
      <HStack height="100%" data-testid={props['data-testid']}>
        <Box flex={1}>
          <VStack height="100%">
            <Box>
              <div id={PRICE_LIST_EXTERNAL_FILTER_ID}></div>
            </Box>
          </VStack>
        </Box>

        <Separator orientation="vertical" />

        <Box flex={3}>
          <DataGrid
            // DG must be re-rendered on folder change to update the query modifier
            // eslint-disable-next-line no-restricted-syntax
            gridCode="supplier-price-list"
            externalFilterId={PRICE_LIST_EXTERNAL_FILTER_ID}
            actionCallback={actionCallback}
            _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
            filterComponent={PriceListExternalFilters}
            data-testid={testIds.warehouse.priceList('priceList')}
            emptyState={{
              headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
            }}
          />
        </Box>
      </HStack>
    </DataStatus>
  );
}
