import {ReactNode} from 'react';

import {
  useGetAlphaCatalogueQuery,
  useGetBankListQuery,
  useGetBranchListQuery,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetGendersQuery,
  useGetIdentityCardTypesQuery,
  useGetPhoneNumbersQuery,
  useGetPrioritizedCountriesQuery,
  useGetRoundTypeQuery,
  useGetTenantQuery,
  useGetVatRatesQuery,
} from '@dms/api';
import {browserStorageKey} from '@dms/config';
import {CommonSkeleton} from '@dms/shared';

interface PrefetchProps {
  children: ReactNode;
}

export function PrefetchBoundary(props: PrefetchProps) {
  const {data: tenantData, isLoading: isTenantLoading} = useGetTenantQuery();
  const {isLoading: isBranchListLoading} = useGetBranchListQuery();
  // ==> cached queries
  const {isLoading: isPhoneNumbersLoading} = useGetPhoneNumbersQuery();
  const {isLoading: isAlphaCatalogueLoading} = useGetAlphaCatalogueQuery(
    {
      lang: String(localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE)),
    },
    {skip: !tenantData}
  );
  const {isLoading: isCountriesLoading} = useGetCountriesQuery(undefined, {skip: !tenantData});
  const {isLoading: isPrioritizedCountriesLoading} = useGetPrioritizedCountriesQuery(
    {countryCode: tenantData?.country},
    {skip: !tenantData}
  );
  const {isLoading: isCurrenciesLoading} = useGetCurrenciesQuery(undefined, {skip: !tenantData});
  const {isLoading: isGendersLoading} = useGetGendersQuery(undefined, {skip: !tenantData});
  const {isLoading: isIdentityCardTypesLoading} = useGetIdentityCardTypesQuery(undefined, {
    skip: !tenantData,
  });
  const {isLoading: isGetRoundTypeLoading} = useGetRoundTypeQuery(undefined, {skip: !tenantData});
  const {isLoading: isGetVatRatesLoading} = useGetVatRatesQuery(undefined, {skip: !tenantData});
  const {isLoading: isGetBankListLoading} = useGetBankListQuery(
    {countryCode: tenantData?.country},
    {skip: !tenantData}
  );
  // ==> cached queries

  const isLoading =
    isTenantLoading ||
    isBranchListLoading ||
    isAlphaCatalogueLoading ||
    isCountriesLoading ||
    isPrioritizedCountriesLoading ||
    isCurrenciesLoading ||
    isGendersLoading ||
    isIdentityCardTypesLoading ||
    isPhoneNumbersLoading ||
    isGetRoundTypeLoading ||
    isGetVatRatesLoading ||
    isGetBankListLoading;

  if (isLoading) {
    return <CommonSkeleton />;
  }

  return <>{props.children}</>;
}
