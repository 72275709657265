import {VehicleCatalogueEnumOption} from '@dms/teas';

import {findFeatureItem} from './findFeatureItem';
import {isLastSelectedNode} from './isLastSelectedNode';

/**
 * Get items with whole object of the feature which is the last node in the Tree
 * @param selected array of keys - checked nodes
 * @param allFeatures list of all features from catalogue
 */
export const filterLastNodesOfFeaturesTree = (
  selected: string[] = [],
  allFeatures: VehicleCatalogueEnumOption[] = []
): VehicleCatalogueEnumOption[] =>
  selected
    .map((key) => findFeatureItem(key, allFeatures))
    .filter((featureItem): featureItem is VehicleCatalogueEnumOption =>
      featureItem ? isLastSelectedNode(featureItem, selected) : false
    );
