import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {closeCurrentDialog, showNotification} from 'platform/components';
import {Show, Spinner} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useDeleteTaskMutation, useEditTaskMutation, useGetTaskQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, usePermissions} from '@dms/shared';

import {getApiDateString, noop, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import type {TaskForm as TaskFormType} from '../../types/TaskForm';
import {TaskRelationProps} from '../../types/TaskRelationProps';
import {TaskRelations} from '../../types/TaskRelations';
import {castTimeToDateISOString} from '../../utils/castTimeToDateISOString';
import {TaskForm} from '../TaskForm/TaskForm';

interface EditTaskProps extends RequiredTestIdProps, TaskRelationProps {
  id: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function EditTask(props: EditTaskProps) {
  const [canDeleteTask, canEditTask] = usePermissions({
    permissionKeys: ['deleteTask', 'updateTask'],
  });

  const {
    data: task,
    isLoading: isTaskLoading,
    isError: hasTaskError,
    error: taskError,
  } = useGetTaskQuery({id: props.id});
  const [editTask] = useEditTaskMutation();
  const [deleteTask, {isLoading: isDeleteTaskLoading}] = useDeleteTaskMutation();

  if (hasTaskError) {
    if ((taskError as FetchBaseQueryError)?.status === 404) {
      showNotification.error(
        i18n.t('general.notifications.notFound', {
          item: i18n.t('entity.task.type.task'),
        })
      );
    } else {
      showNotification.error(i18n.t('general.labels.somethingWentWrong'));
    }
    closeCurrentDialog();
    return null;
  }

  const onEditTask = (data: TaskFormType) =>
    editTask({
      id: props.id,
      editTaskRequestBody: {
        ...data,
        dueDate: data?.dueDate ? getApiDateString(parseDate(data.dueDate)) : null,
        dueFrom:
          data?.dueDate && data?.dueFrom
            ? castTimeToDateISOString(data.dueDate, data.dueFrom)
            : null,
        dueTo:
          data?.dueDate && data?.dueTo ? castTimeToDateISOString(data.dueDate, data.dueTo) : null,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        props.onEdit?.();
      })
      .catch(handleApiError);

  const onDeleteTask = () =>
    deleteTask({id: props.id})
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        props.onDelete?.();
        showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
      })
      .catch(handleApiError);

  return (
    <>
      <Show when={isNotNilOrEmpty(task)}>
        <TaskForm
          values={task}
          onSubmit={onEditTask}
          isReadOnly={!canEditTask}
          onDelete={canDeleteTask ? onDeleteTask : undefined}
          isLoading={isDeleteTaskLoading}
          data-testid={suffixTestId('editTask', props)}
          relatedRecordId={task?.relatedRecordId?.id}
          resourceId={(task?.relatedRecordId?.resourceId as TaskRelations) ?? props.resourceId}
        />
      </Show>
      <Show when={isTaskLoading}>
        <TaskForm onSubmit={noop} data-testid={suffixTestId('skeletonTask', props)} />
        <Spinner variant="overlay" />
      </Show>
    </>
  );
}
