import {DataStatus} from 'platform/components';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {any, head, isNotNil} from 'ramda';
import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {carAuditApi} from '@dms/api';
import i18n from '@dms/i18n';
import {Main} from '@dms/shared';
import {
  loadVehicleDetailsData,
  useThunkDispatch,
  getAudit,
  selectCarAudit,
  selectAudit,
  selectStructure,
} from '@dms/teas';

import {useFetchAuditStructure} from '../../hooks/useFetchAuditStructure';
import {Photos} from './components/Photos';

export const InspectionPhotos: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId, inspectionId} = useParams();

  const structure = useSelector(selectStructure);
  const auditData = useSelector(selectAudit);
  const {fetchAuditStructure} = useFetchAuditStructure();
  const {loading} = useSelector(selectCarAudit);

  const structureQueryState = carAuditApi.endpoints.getAuditStructure.useQueryState({
    auditStructureId: structure?.id ?? '',
  });
  const auditQueryState = carAuditApi.endpoints.getAuditData.useQueryState({
    auditId: auditData?.id ?? '',
  });

  const isAuditLoading = any(isTrue, [
    loading.getAudit,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
    auditQueryState.isLoading,
    auditQueryState.isFetching,
    structureQueryState.isFetching,
    structureQueryState.isLoading,
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && isNotNil(inspectionId) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch).then(() => {
        dispatch(
          getAudit.action({
            auditId: inspectionId,
          })
        )
          .unwrap()
          .then((response) => {
            if (isNotNil(response)) {
              fetchAuditStructure(head(response));
            }
          });
      });
    }
  }, [vehicleId, inspectionId]);

  return (
    <Main>
      <DataStatus
        isLoading={isAuditLoading}
        isEmpty={isNilOrEmpty(structure)}
        emptyMessage={i18n.t`entity.condition.notifications.photosOfInspectionNotFound`}
        minHeight={50}
      >
        <Photos />
      </DataStatus>
    </Main>
  );
};
