import {Button, ButtonGroup, closeCurrentDialog} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

interface AllServiceOrderGroupsProps extends RequiredTestIdProps {}

export function AllServiceOrderGroups(props: AllServiceOrderGroupsProps) {
  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const groupId = isArray(rowId) ? rowId[0] : rowId;

    const path = composePath(settingsRoutes.workshopServiceOrderGroupDetail, {
      params: {
        id: groupId,
      },
    });

    match(actionKey)
      .with('redirectDetail', () => window.open(path))
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <VStack spacing={4}>
      <Box height={135}>
        <DataGrid
          gridCode="service-order-group-picker"
          actionCallback={actionCallback}
          data-testid={suffixTestId('dataGrid', props)}
        />
      </Box>
      <VStack spacing={4}>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            title={i18n.t('general.actions.close')}
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('discard', props)}
          />
        </ButtonGroup>
      </VStack>
    </VStack>
  );
}
