import {DataStatus, Pagination} from 'platform/components';

import {useRef, useState} from 'react';

import {useOnMount} from 'shared';

import {useHttpCalls} from '../hooks/useHttpCalls';
import {RowV5ResponseBody} from '../types/Api';

interface ControlledRowControlProps {
  gridCode: string;
  dataQueryId: string;
  onPageChange: (row: RowV5ResponseBody) => void;
  entityId: string;
}

export function ControlledRowControl(props: ControlledRowControlProps) {
  const http = useHttpCalls({gridCode: props.gridCode});
  const rows = useRef<RowV5ResponseBody[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowIndex, setRowIndex] = useState(1);

  useOnMount(() => {
    http.getDataByQuery(props.dataQueryId, 0, 1000, false).then((response) => {
      rows.current = response ?? [];
      const newRowIndex = response?.find((id) => id.id === props.entityId)?.rowNumber;
      setRowIndex(newRowIndex as number);
      setIsLoading(false);
    });
  });

  const handlePageChange = (newPage: number) => {
    const newRow = rows.current.find((row) => row.rowNumber === newPage);
    setRowIndex(newPage);
    props.onPageChange(newRow!);
  };

  if (isLoading) {
    return <DataStatus isLoading></DataStatus>;
  }

  return (
    <Pagination
      variant="text-only"
      showPrevButton
      showNextButton
      pagesQuantity={rows.current.length}
      onPageChange={handlePageChange}
      page={rowIndex}
    />
  );
}
