import {isFeatureEnabled} from 'feature-flags';
import createDecorator from 'final-form-calculate';
import {Alert, Button, ButtonGroup} from 'platform/components';
import {Grid, GridItem, Hide, HStack, Right, Show, Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import * as Yup from 'yup';

import {FC} from 'react';
import {FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';

import {defaultTo, path, pipe} from 'ramda';

import {
  BuyingOfferPurchaseVehicleResponseBody,
  DeductibleOrNotDeductiblePrice,
  PatchBuyingOfferPurchaseVehicleRequestBody,
  selectTenant,
  useGetCentralizedPricingQuery,
  VatTypeEnum,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  $PriceValue,
  Form,
  MinMaxAmountLimitType,
  PriceValue,
  WithValidationErrors,
} from '@dms/teas';

interface BuyingFormProps {
  /**
   * For validating maximum `buyingPrice`
   */
  maxBuyingPrice?: DeductibleOrNotDeductiblePrice;
  onSubmit?: (
    values: PatchBuyingOfferPurchaseVehicleRequestBody
  ) => Promise<WithValidationErrors<void>>;
  onDiscard?: () => void;
  initialValues?: BuyingOfferPurchaseVehicleResponseBody;
  showDiscard?: boolean;
}

export type PurchaseVehicleBuying = Omit<
  PatchBuyingOfferPurchaseVehicleRequestBody,
  'buyingPrice'
> & {
  buyingPrice: PriceValue;
};

const changeVatDeductible = createDecorator<PurchaseVehicleBuying>({
  field: /vatDeductible/,
  updates: {
    vatType: (vatDeductible: boolean) => (vatDeductible ? 'S' : 'Z'),
  },
  isEqual: (a?: boolean, b?: boolean) => b === undefined || a === b,
});

export const BusinessCasePurchaseVehicleBuyingForm: FC<BuyingFormProps> = ({
  onSubmit,
  onDiscard,
  initialValues,
  maxBuyingPrice,
  showDiscard,
}) => {
  const formatCurrency = useFormatCurrency();
  const isBusinessCaseSimplifiedPricingEnabled = isFeatureEnabled(
    featureFlags.BUSINESS_CASE_PRICING_SIMPLIFIED
  );

  const {data: centralizedPricing} = useGetCentralizedPricingQuery();

  const centralizedPricingValue = pipe(
    path<boolean>([0, 'value']),
    defaultTo(false)
  )(centralizedPricing);

  const isCentralizedPricingEnabled =
    centralizedPricingValue && isFeatureEnabled(featureFlags.SALES_CENTRALIZED_PRICING);

  const {data: {currency} = {}} = useSelector(selectTenant);

  const getFormValues = (
    values: BuyingOfferPurchaseVehicleResponseBody
  ): PurchaseVehicleBuying => ({
    buyingPrice: {
      withVat: values.buyingPrice.priceNotDeductible ?? values.buyingPrice.priceWithVat,
      withoutVat: values.buyingPrice.priceWithoutVat,
      fromWithVat:
        (values.buyingPrice.vatDirection ?? 'FROM_PRICE_WITH_VAT') === 'FROM_PRICE_WITH_VAT',
      vatType: values.vatType ?? (values.vatDeductible ? VatTypeEnum.S : VatTypeEnum.Z),
      vat: null,
      vatRate: '',
    },
    vatDeductible: values.vatDeductible,
    vatType: values.vatType,
    reason: values.reason,
  });

  const getRequestBody = (
    values: PurchaseVehicleBuying
  ): PatchBuyingOfferPurchaseVehicleRequestBody => ({
    buyingPrice: {
      priceNotDeductible: !values.vatDeductible ? values.buyingPrice.withVat : null,
      priceWithVat: values.vatDeductible ? values.buyingPrice.withVat : null,
      priceWithoutVat: values.vatDeductible ? values.buyingPrice.withoutVat : null,
      vatDirection: values.buyingPrice.fromWithVat
        ? 'FROM_PRICE_WITH_VAT'
        : 'FROM_PRICE_WITHOUT_VAT',
    },
    vatDeductible: values.vatDeductible,
    vatType: values.vatType,
    reason: values.reason,
  });

  const buyingMaxPrice = maxBuyingPrice?.priceNotDeductible ?? maxBuyingPrice?.priceWithVat;

  return (
    <Form<
      PurchaseVehicleBuying,
      BuyingOfferPurchaseVehicleResponseBody,
      PatchBuyingOfferPurchaseVehicleRequestBody
    >
      formId={testIds.businessCase.buying('buyingForm')}
      initialValues={initialValues}
      defaultValues={{
        buyingPrice: {
          withVat: {currency},
          withoutVat: {currency},
        },
        vatType: initialValues?.vatDeductible ? VatTypeEnum.S : VatTypeEnum.Z,
      }}
      getFormValues={getFormValues}
      getRequestBody={getRequestBody}
      schema={$BuyingFormValidations()}
      validateAfterSubmit
      onSubmit={onSubmit}
      decorators={[changeVatDeductible]}
      render={({Field, Subscribe, Condition, handleSubmit, form, usePriceCalculation}) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const calculatePrice = usePriceCalculation<
          PurchaseVehicleBuying,
          BuyingOfferPurchaseVehicleResponseBody
        >(form, 'buyingPrice');

        return (
          <Grid columns={4}>
            <Show when={buyingMaxPrice}>
              <GridItem span={4}>
                <Alert
                  data-testid={testIds.businessCase.buying('maxBuyingPrice')}
                  type="inline"
                  variant="info"
                  title={i18n.t('entity.businessCase.labels.maxBuyingPriceWithVAT', {
                    amount: formatCurrency(
                      Number(buyingMaxPrice?.amount ?? 0),
                      buyingMaxPrice?.currency ?? ''
                    ),
                  })}
                />
              </GridItem>
            </Show>

            <Show when={!buyingMaxPrice && isCentralizedPricingEnabled}>
              <GridItem span={4}>
                <Alert
                  message={i18n.t('general.labels.MaxBuyingPriceNotSet')}
                  variant="info"
                  data-testid={testIds.businessCase.buying('MaxBuyingPriceNotSet')}
                />
              </GridItem>
            </Show>

            <GridItem span={4}>
              <HStack align="center" height="3.5rem" spacing={4}>
                <Field
                  as="checkbox"
                  name="vatDeductible"
                  label={i18n.t('general.labels.vatDeductible')}
                />

                <Condition when="vatDeductible" is={true}>
                  <Field
                    as="vatType"
                    name="vatType"
                    placeholder={i18n.t('general.labels.select')}
                  />
                </Condition>
              </HStack>
            </GridItem>

            <Hide when={isBusinessCaseSimplifiedPricingEnabled}>
              <>
                <GridItem span={4}>
                  <HStack align="center" spacing={4}>
                    <Field
                      as="checkbox"
                      name="vatDeductible"
                      label={i18n.t('general.labels.vatDeductible')}
                    />

                    <Condition when="vatDeductible" is={true}>
                      <Field
                        as="vatType"
                        name="vatType"
                        placeholder={i18n.t('general.labels.select')}
                      />
                    </Condition>
                  </HStack>
                </GridItem>
                <GridItem span={4}>
                  <Text color="primary" size="small" alternative>
                    {i18n.t('general.labels.buyingPrice')}
                  </Text>
                </GridItem>
              </>
            </Hide>

            <Condition when="vatDeductible" is={true}>
              <GridItem key="buying-priceWithoutVat" span={2}>
                <Subscribe
                  name="vatType"
                  component={({input: {value: vatType}}) => (
                    <Field<PriceValue>
                      as="price"
                      name="buyingPrice"
                      label={i18n.t('general.labels.priceWithoutVat')}
                      vatType={vatType}
                      onChange={calculatePrice}
                    />
                  )}
                />
              </GridItem>
            </Condition>
            <GridItem key="buying-priceWithVat" span={2}>
              <Subscribe
                name="vatType"
                component={({input: {value: vatType}}) => (
                  <Field<PriceValue>
                    as="price"
                    withVat
                    name="buyingPrice"
                    label={i18n.t('general.labels.priceWithVat')}
                    vatType={vatType}
                    onChange={calculatePrice}
                  />
                )}
              />
            </GridItem>
            <GridItem span={4}>
              <Field multiline name="reason" label={i18n.t('general.labels.reason')} />
            </GridItem>
            <GridItem span={4}>
              <Right>
                <FormSpy
                  subscription={{submitting: true}}
                  render={({submitting}) => (
                    <ButtonGroup>
                      <Show when={showDiscard}>
                        <Button
                          data-testid={testIds.businessCase.buying('buying-discard')}
                          variant="secondary"
                          onClick={onDiscard}
                          title={i18n.t('general.actions.discard')}
                        />
                      </Show>
                      <Button
                        key="confirm"
                        data-testid={testIds.businessCase.buying('buying-confirm')}
                        onClick={handleSubmit}
                        isLoading={submitting}
                        title={i18n.t('general.actions.confirm')}
                      />
                    </ButtonGroup>
                  )}
                />
              </Right>
            </GridItem>
          </Grid>
        );
      }}
    />
  );
};

const $BuyingFormValidations = (limit?: MinMaxAmountLimitType) =>
  Yup.object({
    buyingPrice: $PriceValue(limit).required(),
    vatDeductible: Yup.bool().required(i18n.t('general.notifications.errorFieldRequired')),
    vatType: Yup.mixed().when('vatDeductible', {
      is: true,
      then: Yup.mixed()
        .oneOf(Object.values(VatTypeEnum), i18n.t('general.notifications.errorFieldRequired'))
        .required(i18n.t('general.notifications.errorFieldRequired')),
      otherwise: Yup.mixed().default(null),
    }),
    reason: Yup.string().defined().nullable(),
  });
