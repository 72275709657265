import {useCallback} from 'react';

import {defaultTo, map, negate, pipe, reject} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {InvoicingDocumentAccountingItemType, VatType} from '@dms/api';

import {Nullish} from 'shared';

import {PERCENTAGE_SIGN} from '../../../constants';
import {InvoiceItem} from '../../../types';

type RecalculateBodyItem = {
  unitType: 'percent' | 'standard';
  currency: string;
  unitPrice: string;
  unit: string;
  description: string;
  isUnitPriceWithVat: boolean;
  itemId: string;
  relatedItemId: string | null;
  type: InvoicingDocumentAccountingItemType;
  vatType: VatType;
  quantity: string;
};

export const useGetInvoiceItemsForRecalculation = (
  isCorrectiveTaxDocument: boolean,
  currency: string
) => {
  const mutateItems = useCallback(
    (invoiceItems: InvoiceItem[] | Nullish) =>
      pipe(
        defaultTo<InvoiceItem[]>([]),
        map((item: InvoiceItem) => {
          const unitType = item.unit === PERCENTAGE_SIGN ? 'percent' : 'standard';
          const pricePerUnit = item.pricePerUnit?.toString() ?? '0';
          const discountUnitPrice =
            isPositive(parseFloat(pricePerUnit)) && !isCorrectiveTaxDocument
              ? negate(parseFloat(pricePerUnit))?.toString()
              : pricePerUnit;

          const unitPrice = item.type === 'discount' ? discountUnitPrice : pricePerUnit;

          return {
            unitType,
            currency,
            unitPrice,
            unit: item.unit,
            description: item.description,
            isUnitPriceWithVat: item.isUnitPriceWithVat,
            itemId: item.itemId,
            relatedItemId: item.relatedItemId,
            type: item.type,
            vatType: item.vatType,
            quantity: item.quantity?.toString() || '0',
          } as const;
        }),
        reject(
          (item: RecalculateBodyItem) =>
            item.type === 'discount' &&
            ((item.unitPrice === '0' && item.unit !== PERCENTAGE_SIGN) ||
              (item.quantity === '0' && item.unit === PERCENTAGE_SIGN))
        )
      )(invoiceItems),
    [currency, isCorrectiveTaxDocument]
  );

  return [mutateItems] as const;
};
