import {ButtonGroup} from 'platform/components';
import {Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import {TreeFolderContextTarget} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {FolderTree} from '../FolderTree/FolderTree';
import {ActiveFolder} from '../MoveToFolder/types/activeFolder';

interface SelectFolderProps extends TestIdProps {
  catalogueIds: string[];
  contextTarget: TreeFolderContextTarget;
  onClose: VoidFunction;
  onSelect: (folder: ActiveFolder) => void;
}

export function SelectFolder(props: SelectFolderProps) {
  const [activeFolder, setActiveFolder] = useState<ActiveFolder>();

  return (
    <VStack spacing={1}>
      <FolderTree
        contextIds={props.catalogueIds}
        contextTarget={props.contextTarget}
        onFolderChange={(id, contextId) =>
          setActiveFolder(isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined)
        }
        activeFolderId={activeFolder?.id}
        data-testid={suffixTestId('catalogueTree', props)}
      />
      <Space vertical={1} />
      <ButtonGroup
        align="right"
        buttons={[
          {
            title: i18n.t('general.actions.discard'),
            onClick: props.onClose,
            variant: 'secondary',
          },
          {
            title: i18n.t('general.labels.select'),
            onClick: () => props.onSelect(activeFolder),
            isDisabled: !activeFolder,
            'data-testid': suffixTestId('select', props),
          },
        ]}
      />
    </VStack>
  );
}
