import {AutoSave, Card, DataStatus, Form, showNotification} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';

import {isNil} from 'ramda';
import {concatRight, isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  CreateMinipurchaseApiArg,
  interestApi,
  UpdateMinipurchaseApiArg,
  useCreateMinipurchaseMutation,
  useUpdateMinipurchaseMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {handleApiError, interestSearchParams, queryParams, Section} from '@dms/shared';

import {composePath, handleFormReset, useNavigate, useQueryState} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {OfferedVehicles} from '../../components/OfferedVehicles';
import {useInterestData} from '../../hooks/useInterestData';
import {
  ExternalFilterConnector,
  ExternalFilterConnectorRef,
} from './components/ExternalFilterConnector';
import {ExternalFilterForm} from './components/ExternalFilterForm';
import {ExternalFilter} from './types/ExternalFilter';
import {transformExternalFilterToMinipurchaseSpecification} from './utils/transformExternalFilterToMinipurchaseSpecification';
import {transformMinipurchaseToExternalFilter} from './utils/transformMinipurchaseToExternalFilter';

interface SellingProps {
  interestId: string;
}

export function Selling(props: SellingProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [_, setActiveSectionId] = useQueryState(queryParams.COMPONENT_SECTIONS_SECTION);

  const [tab, setTab] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const {interest, offeredVehiclesList, miniPurchase, isActionsDisabled, isLoading, isError} =
    useInterestData(props.interestId);

  const [createMinipurchase] = useCreateMinipurchaseMutation();
  const [updateMinipurchase] = useUpdateMinipurchaseMutation();

  const externalFilterRef = useRef<ExternalFilterConnectorRef>(null);

  useEffect(() => {
    if (
      !miniPurchase?.offeredVehiclesIds?.length &&
      tab === interestSearchParams.interestDetail.sellingTabs.offeredVehicles
    ) {
      setTab(interestSearchParams.interestDetail.sellingTabs.request);
    }
  }, [tab, miniPurchase?.offeredVehiclesIds?.length, setTab]);

  const onMiniPurchaseUpdate = (values: ExternalFilter) => {
    if (!miniPurchase || !miniPurchase?.id) {
      const arg: CreateMinipurchaseApiArg = {
        body: {
          interestId: props.interestId,
          filteredSpecifications: transformExternalFilterToMinipurchaseSpecification(values),
          additionalInformation: values.additionalInformation ?? null,
        },
      };
      createMinipurchase(arg)
        .unwrap()
        .then(() => {
          dispatch(interestApi.util.invalidateTags([{type: 'Interest', id: props.interestId}]));
          externalFilterRef.current?.onChange(values);
        })
        .catch(handleApiError);
    } else {
      const arg: UpdateMinipurchaseApiArg = {
        interestId: props.interestId,
        body: {
          ...miniPurchase,
          minipurchaseId: miniPurchase.id,
          filteredSpecifications: transformExternalFilterToMinipurchaseSpecification(values),
          additionalInformation: values.additionalInformation ?? null,
        },
      };
      updateMinipurchase(arg)
        .unwrap()
        .then(() => {
          externalFilterRef.current?.onChange(values);
        })
        .catch(handleApiError);
    }
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    if (!isString(rowId)) {
      showNotification.error(`Missing rowId for action ${actionKey}`);
      return;
    }

    match(actionKey)
      .with('detail', () => {
        navigate(composePath(vehiclesRoutes.detail, {params: {id: rowId}}));
      })
      .with('removeFromOfferedVehicle', () => {
        if (
          !miniPurchase ||
          !miniPurchase?.id ||
          isNil(miniPurchase?.offeredVehiclesIds?.find((id) => id === rowId))
        ) {
          showNotification.warning(i18n.t('entity.interest.actions.removeToOfferedVehicleWarning'));
          return;
        }
        const arg: UpdateMinipurchaseApiArg = {
          interestId: props.interestId,
          body: {
            ...miniPurchase,
            minipurchaseId: miniPurchase.id,
            offeredVehiclesIds: miniPurchase?.offeredVehiclesIds?.filter(
              (id: string) => id !== rowId
            ),
          },
        };
        updateMinipurchase(arg)
          .unwrap()
          .then(() =>
            showNotification.info(i18n.t('entity.interest.actions.removeToOfferedVehicle'))
          )
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('addToOfferedVehicle', () => {
        if (!miniPurchase || !miniPurchase?.id) {
          const arg: CreateMinipurchaseApiArg = {
            body: {
              interestId: props.interestId,
              offeredVehiclesIds: [rowId],
            },
          };
          createMinipurchase(arg)
            .unwrap()
            .then(() => {
              showNotification.info(i18n.t('entity.interest.actions.addToOfferedVehicle'));
            })
            .catch(handleApiError);
        } else {
          const arg: UpdateMinipurchaseApiArg = {
            interestId: props.interestId,
            body: {
              ...miniPurchase,
              minipurchaseId: miniPurchase.id,
              offeredVehiclesIds: concatRight(miniPurchase?.offeredVehiclesIds ?? [], [rowId]),
            },
          };
          updateMinipurchase(arg)
            .unwrap()
            .then(() => {
              dispatch(
                interestApi.util.invalidateTags([{type: 'miniPurchase', id: miniPurchase.id}])
              );
              showNotification.info(i18n.t('entity.interest.actions.addToOfferedVehicle'));
            })
            .then(refreshData)
            .catch(handleApiError);
        }
      })
      .otherwise(() => {
        showNotification.warning(`Action callback was not specified for action ${actionKey}`);
      });
  };

  return (
    <Section
      data-testid={testIds.interest.detail('selling')}
      actions={[
        {
          type: 'button',
          variant: 'outlined',
          onClick: () => setActiveSectionId(interestSearchParams.interestDetail.overview),
          'data-testid': testIds.interest.detail('sellingContinue'),
          isDisabled: !miniPurchase || isActionsDisabled,
          title: i18n.t('general.actions.continue'),
        },
      ]}
      tabs={[
        {
          title: i18n.t('entity.interest.labels.requestSpecification'),
          queryId: interestSearchParams.interestDetail.sellingTabs.request,
          content: (
            <DataStatus isLoading={isLoading} isError={isError} minHeight={175}>
              {interest && (
                <VStack spacing={4}>
                  <Form<ExternalFilter>
                    data-testid={testIds.interest.detail('requestSpecification')}
                    defaultValues={transformMinipurchaseToExternalFilter(miniPurchase)}
                  >
                    {(control, formApi) => (
                      <Card
                        isExpandable
                        data-testid={testIds.interest.detail('requestSpecification')}
                        title={i18n.t('entity.interest.labels.requestSpecification')}
                        actions={[
                          {
                            variant: 'link',
                            type: 'button',
                            leftIcon: 'action/delete',
                            isLoading,
                            isDisabled:
                              interest?.state === 'CLOSED' || interest?.state === 'UNSUCCESSFUL',
                            onClick: () => {
                              handleFormReset<ExternalFilter>(formApi, [
                                {name: 'type', value: ['VEHICLETYPE_PASSENGER_CAR']},
                                {name: 'make', value: null},
                                {name: 'modelFamily', value: null},
                                {name: 'fuelType', value: null},
                                {name: 'transmission', value: null},
                                {name: 'bodyStyle', value: null},
                                {name: 'drive', value: null},
                                {name: 'features', value: null},
                                {name: 'vatDeductible', value: null},
                                {name: 'bodyColor', value: null},
                                {name: 'cubicCapacityFrom', value: null},
                                {name: 'cubicCapacityTo', value: null},
                                {name: 'priceFrom', value: null},
                                {name: 'priceTo', value: null},
                                {name: 'yearFrom', value: null},
                                {name: 'yearTo', value: null},
                                {name: 'mileageFrom', value: null},
                                {name: 'mileageTo', value: null},
                                {name: 'powerFrom', value: null},
                                {name: 'powerTo', value: null},
                                {name: 'additionalInformation', value: null},
                              ]);
                              onMiniPurchaseUpdate(formApi.getValues());
                            },
                            'data-testid': testIds.interest.detail('resetRequestSpecification'),
                            title: i18n.t('entity.interest.actions.resetSpecification'),
                          },
                        ]}
                      >
                        <Box minHeight={125}>
                          <AutoSave<ExternalFilter> onSave={onMiniPurchaseUpdate} />
                          <ExternalFilterForm
                            data-testid={testIds.interest.detail('requestSpecification')}
                            control={control}
                            formApi={formApi}
                            onChange={onMiniPurchaseUpdate}
                          />
                          <div id={EXTERNAL_FILTER_ID} />
                        </Box>
                      </Card>
                    )}
                  </Form>
                  <Box height={175}>
                    <DataGrid
                      data-testid={testIds.interest.detail('selling')}
                      presetInstanceId={props.interestId}
                      gridCode="interest-vehicle-list"
                      filterComponent={(params) => (
                        <ExternalFilterConnector
                          ref={externalFilterRef}
                          onDataGridFilterChange={params.onChange}
                        />
                      )}
                      actionCallback={actionCallback}
                      externalFilterId={EXTERNAL_FILTER_ID}
                    />
                  </Box>
                </VStack>
              )}
            </DataStatus>
          ),
        },
        {
          title: `${i18n.t('entity.interest.labels.offeredVehicles')} (${offeredVehiclesList?.length ?? 0})`,
          isDisabled: isNilOrEmpty(offeredVehiclesList),
          queryId: interestSearchParams.interestDetail.sellingTabs.offeredVehicles,
          content: (
            <OfferedVehicles
              data-testid={testIds.interest.detail('selling')}
              interestId={props.interestId}
            />
          ),
        },
      ]}
    />
  );
}

const EXTERNAL_FILTER_ID = 'interest-vehicle-list-external-filter';
