import {useSelector} from 'react-redux';

import {
  FeaturesPicker,
  permanentVehicleType,
  selectFeatureCategories,
  selectVehicleFeatures,
} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../../FiltersContext/FiltersContext';

export function FilterFeatures(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();
  const {features: selectedFeatures} = filters;

  const featureCategories = useSelector(selectFeatureCategories(permanentVehicleType));
  const features = useSelector(selectVehicleFeatures(permanentVehicleType));

  return (
    <FeaturesPicker
      categories={featureCategories}
      features={features}
      selected={selectedFeatures || []}
      onFeatureSelect={(selected) => onUpdateFilters(['features'], selected)}
      allowSubItemMultiselect
      data-testid={suffixTestId('features', props)}
    />
  );
}
