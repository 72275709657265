import {match} from 'ts-pattern';

import {Nullish} from 'shared';

import {MandatoryFieldType} from '../types/ServiceOrderVariant';

export const getFieldsByOrderType = (code: string | Nullish) => {
  const fieldArray: MandatoryFieldType[] = [
    'generatingOrderSheet',
    'completionAtDate',
    'completionAtTime',
    'totalPriceEstimated',
    'serviceCenterId',
    'serviceOrderGroupId',
    'mileage',
    'fuelTank',
  ];

  match(code)
    .with('INS', () =>
      fieldArray.push(
        'insuranceCompany',
        'insuranceType',
        'insuranceEventNumber',
        'insuranceClaimNumber'
      )
    )
    .with('CON', () => fieldArray.push('provider', 'approvalNumber'))
    .with('COM', () => fieldArray.push('approvalNumber'))
    .with('CLA', () => fieldArray.push('claimSerialNumber'))
    .with('INT', () => fieldArray.push('costCenter'));

  return fieldArray;
};
