import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function Inspections() {
  const navigate = useNavigate();

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.vehicleSettings.labels.inspectionsTitle'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.condition.labels.newInspection'),
            onClick: () => navigate(settingsRoutes.inspectionCreate),
          },
        ],
      }}
      description={i18n.t('page.vehicleSettings.labels.inspectionsDescription')}
      data-testid={testIds.settings.inspections('template')}
    />
  );
}
