import {useGetPaymentTypeEnumQuery, useGetRoundingDefinitionListQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {DocumentRoundingDefinitionList} from './components/DocumentRoundingDefinitionList';

export function DocumentRoundingDefinitions() {
  const navigate = useNavigate();
  const {
    data: paymentTypes,
    isLoading: isPaymentTypesLoading,
    isError: isPaymentTypesError,
  } = useGetPaymentTypeEnumQuery();

  const {
    data: roundingDefinitions,
    isLoading: isRoundingDefinitionsLoading,
    isError: isRoundingDefinitionsError,
  } = useGetRoundingDefinitionListQuery({});

  const header: SettingsTemplateHeader = {
    title: i18n.t('page.accounting.labels.documentRoundingDefinitions'),
    actions: [
      {
        type: 'button',
        title: i18n.t('page.accounting.actions.addDefinition'),
        onClick: () => {
          navigate(settingsRoutes.documentRoundingDefinitionsAdd);
        },
        'data-testid': testIds.settings.documentRoundingDefinitionsEdit('createNew'),
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isPaymentTypesLoading || isRoundingDefinitionsLoading}
      isError={isRoundingDefinitionsError || isPaymentTypesError}
      data-testid="settings-document-rounding-definition-page"
    >
      <DocumentRoundingDefinitionList
        roundingDefinitions={roundingDefinitions}
        paymentTypes={paymentTypes}
      />
    </SettingsTemplate>
  );
}
