import {addMonths} from 'date-fns';
import {
  Button,
  ButtonGroup,
  Card,
  closeDialog,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import * as Yup from 'yup';

import {
  useGetServiceCheckInQuery,
  useGetVehicleV2Query,
  useUpdateVehicleV2Mutation,
  VehicleTypeEnumObject,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {getApiDateString, RequiredTestIdProps, suffixTestId, yupNumber, yupString} from 'shared';

import {ServiceType} from '../types/ServiceType';
import {UpdateServiceIntervalForm} from '../types/UpdateServiceIntervalForm';
import {fluidUpdate} from '../utils/fluidUpdate';
import {replacementUpdate} from '../utils/replacementUpdate';

interface UpdateDialogFormProps extends RequiredTestIdProps {
  dialogId: string;
  vehicleId: string;
  serviceCaseId: string;
}

export function UpdateDialogForm(props: UpdateDialogFormProps) {
  const vehicleV2Query = useGetVehicleV2Query({vehicleId: props.vehicleId});
  const checkinQuery = useGetServiceCheckInQuery({serviceCaseId: props.serviceCaseId});

  const isLoading = vehicleV2Query.isLoading || checkinQuery.isLoading;
  const isError = vehicleV2Query.isError || checkinQuery.isError;

  const [updateVehicle] = useUpdateVehicleV2Mutation();

  const getDefaultValues = (): UpdateServiceIntervalForm => ({
    date: getApiDateString(new Date()),
    mileage: checkinQuery.data?.mileage ?? null,
    options: [],
  });

  const handleSubmit: FormSubmitHandler<UpdateServiceIntervalForm> = (formData) => {
    if (!vehicleV2Query.data) {
      return Promise.reject();
    }

    const vehicleDataCopy = structuredClone(vehicleV2Query.data);

    formData.options.forEach((option) => {
      match(option)
        .with(ServiceType.ServiceInspection, () => {
          const act = vehicleDataCopy.serviceIntervals?.serviceInspection;

          if (!act) {
            return;
          }

          act.lastServiceInspectionDate = formData.date;
          act.lastServiceInspectionMileage = formData.mileage ?? 0;

          if (act.replacementAfterMonths) {
            act.nextServiceInspectionDate = getApiDateString(
              addMonths(act.lastServiceInspectionDate, act.replacementAfterMonths)
            );
          }

          if (act.replacementAfterMileage) {
            act.nextServiceInspectionMileage =
              act.lastServiceInspectionMileage + act.replacementAfterMileage;
          }
        })
        .with(ServiceType.EngineOil, () => {
          if (!vehicleDataCopy.serviceIntervals?.engineOil) {
            return;
          }

          vehicleDataCopy.serviceIntervals.engineOil = replacementUpdate(
            vehicleDataCopy.serviceIntervals.engineOil,
            formData
          );
        })
        .with(ServiceType.GearboxOil, () => {
          if (!vehicleDataCopy.serviceIntervals?.gearboxOil) {
            return;
          }

          vehicleDataCopy.serviceIntervals.gearboxOil = replacementUpdate(
            vehicleDataCopy.serviceIntervals.gearboxOil,
            formData
          );
        })
        .with(ServiceType.FourByFourOilDistributor, () => {
          if (!vehicleDataCopy.serviceIntervals?.fourByFourOilDistributor) {
            return;
          }

          vehicleDataCopy.serviceIntervals.fourByFourOilDistributor = replacementUpdate(
            vehicleDataCopy.serviceIntervals.fourByFourOilDistributor,
            formData
          );
        })
        .with(ServiceType.TimingBelt, () => {
          if (!vehicleDataCopy.serviceIntervals?.timingBelt) {
            return;
          }

          vehicleDataCopy.serviceIntervals.timingBelt = replacementUpdate(
            vehicleDataCopy.serviceIntervals.timingBelt,
            formData
          );
        })
        .with(ServiceType.BrakeFluid, () => {
          if (!vehicleDataCopy.serviceIntervals?.brakeFluid) {
            return;
          }

          vehicleDataCopy.serviceIntervals.brakeFluid = fluidUpdate(
            vehicleDataCopy.serviceIntervals.brakeFluid,
            formData
          );
        })
        .with(ServiceType.CoolantFluid, () => {
          if (!vehicleDataCopy.serviceIntervals?.coolantFluid) {
            return;
          }

          vehicleDataCopy.serviceIntervals.coolantFluid = fluidUpdate(
            vehicleDataCopy.serviceIntervals.coolantFluid,
            formData
          );
        })
        .exhaustive();
    });

    const {dimensions: _, ...rest} = vehicleDataCopy;

    const updatedVehicleData = {
      ...rest,
      make: rest.make || '',
      modelFamily: rest.modelFamily || '',
      type: rest.type || VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR,
    };

    return updateVehicle(updatedVehicleData)
      .unwrap()
      .then(() => {
        closeDialog(props.dialogId);
        showNotification.success();
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form defaultValues={getDefaultValues()} schema={schema} onSubmit={handleSubmit}>
        {(control, formApi) => (
          <VStack spacing={4}>
            <Text size="small">{i18n.t('entity.vehicle.labels.updateServiceIntervalsNote')}</Text>
            <Card variant="inlineGrey">
              <VStack>
                <Grid columns={2}>
                  <FormField
                    label={i18n.t('general.labels.date')}
                    type="apiDate"
                    name="date"
                    control={control}
                    isRequired
                    data-testid={suffixTestId('date', props)}
                  />
                  <FormField
                    label={i18n.t('entity.vehicle.labels.mileage')}
                    type="number"
                    name="mileage"
                    control={control}
                    suffix={i18n.t('general.metric.km')}
                    isRequired
                    data-testid={suffixTestId('mileage', props)}
                  />
                </Grid>
                <Separator />
                <VStack>
                  <FormField
                    type="checkboxes"
                    name="options"
                    control={control}
                    direction="vertical"
                    options={[
                      {
                        label: i18n.t('entity.vehicle.labels.serviceInspection'),
                        value: ServiceType.ServiceInspection,
                      },
                      {
                        label: i18n.t('entity.vehicle.labels.engineOil'),
                        value: ServiceType.EngineOil,
                      },
                      {
                        label: i18n.t('entity.vehicle.labels.gearboxOil'),
                        value: ServiceType.GearboxOil,
                      },
                      {
                        label: i18n.t('entity.vehicle.labels.fourByFourOilDistributor'),
                        value: ServiceType.FourByFourOilDistributor,
                      },
                      {
                        label: i18n.t('entity.vehicle.labels.timingBelt'),
                        value: ServiceType.TimingBelt,
                      },
                      {
                        label: i18n.t('entity.vehicle.labels.brakeFluid'),
                        value: ServiceType.BrakeFluid,
                      },
                      {
                        label: i18n.t('entity.vehicle.labels.coolantFluid'),
                        value: ServiceType.CoolantFluid,
                      },
                    ]}
                    data-testid={suffixTestId('options', props)}
                  />
                </VStack>
              </VStack>
            </Card>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.labels.discard')}
                variant="secondary"
                isDisabled={formApi.formState.isSubmitting}
                onClick={() => closeDialog(props.dialogId)}
                data-testid={suffixTestId('discard', props)}
              />
              <Button
                title={i18n.t('general.actions.update')}
                variant="primary"
                type="submit"
                isLoading={formApi.formState.isSubmitting}
                isDisabled={!formApi.formState.isValid}
                data-testid={suffixTestId('update', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = Yup.object().shape({
  date: yupString.required(),
  mileage: yupNumber.required(),
  options: Yup.array().of(Yup.string()).min(1).required(),
});
