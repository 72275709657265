import {
  Alert,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNil, isNotNil} from 'ramda';

import {
  GetDiscountGroupResponse,
  GetWarehousesResponse,
  usePatchDiscountGroupMutation,
  usePostDiscountGroupMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {getOptionsFromWarehouses, handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {DiscountGroupForm} from '../types/DiscountGroupForm';

export interface DiscountGroupDetailFormProps extends RequiredTestIdProps {
  discountGroup: GetDiscountGroupResponse | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
}

export function DiscountGroupDetailForm(props: DiscountGroupDetailFormProps) {
  const navigate = useNavigate();

  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [postDiscountGroup] = usePostDiscountGroupMutation();
  const [patchDiscountGroup] = usePatchDiscountGroupMutation();

  const handleSubmit: FormSubmitHandler<DiscountGroupForm> = async (formValues) => {
    if (isNotNil(props.discountGroup)) {
      return await patchDiscountGroup({body: formValues, discountGroupId: props.discountGroup.id})
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
        )
        .then(handleNavigateBack)
        .catch(handleApiError);
    }

    await postDiscountGroup(formValues)
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCreated')))
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesDiscountGroup);
  };

  return (
    <Form<DiscountGroupForm>
      schema={formSchema}
      defaultValues={props.discountGroup}
      onSubmit={handleSubmit}
    >
      {(control) => (
        <VStack spacing={4} width="50%" data-testid={props['data-testid']}>
          <Show when={duplicateError}>
            <Alert
              variant="error"
              title={i18n.t('entity.warehouse.labels.discountGroupAlreadyExists')}
            />
          </Show>

          <Grid columns={2}>
            <FormField
              isNotClearable
              isRequired
              isDisabled={isNotNil(props.discountGroup)}
              control={control}
              type="choice"
              name="warehouseId"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(props.warehouses)}
              data-testid={suffixTestId('inputs.warehouseId', props)}
            />
          </Grid>

          <Separator />

          <Grid columns={2}>
            <FormField
              isRequired
              control={control}
              type="text"
              name="name"
              label={i18n.t('general.labels.name')}
              data-testid={suffixTestId('inputs.name', props)}
            />
          </Grid>

          <Grid columns={2}>
            <FormField
              control={control}
              type="number"
              isStepperVisible
              name="regularOrderDiscount"
              label={`${i18n.t('entity.warehouse.labels.regularOrderDiscount')} (%)`}
              data-testid={suffixTestId('inputs.regularOrderDiscount', props)}
            />
            <FormField
              control={control}
              type="number"
              isStepperVisible
              name="expressOrderDiscount"
              label={`${i18n.t('entity.warehouse.labels.expressOrderDiscount')} (%)`}
              data-testid={suffixTestId('inputs.expressOrderDiscount', props)}
            />
          </Grid>

          <Space fillAvailable />

          <SettingsFooter
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: isNil(props.discountGroup)
                  ? i18n.t('general.actions.discard')
                  : i18n.t('general.actions.discardChanges'),
                onClick: handleNavigateBack,
                'data-testid': suffixTestId('actions.discard', props),
              },
              {
                type: 'form-button',
                control,
                buttonType: 'submit',
                variant: 'primary',
                title: isNil(props.discountGroup)
                  ? i18n.t('general.actions.save')
                  : i18n.t('general.actions.saveChanges'),
                'data-testid': suffixTestId('actions.submit', props),
              },
            ]}
          />
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  warehouseId: yupString.required(),
  name: yupString.required(),
});
