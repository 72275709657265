/**
 * To avoid merge conflicts, please add new params to a random location (not the last line).
 */
export enum queryParams {
  COMPONENT_SECTIONS_TAB = 'tab',
  COMPONENT_SECTIONS_SECTION = 'section',
  BUSINESS_CASE_CHECKOUT = 'checkoutSection',
  BUSINESS_CASE_CHECKOUT_ORDER = 'CheckoutOrder',
  BUSINESS_CASE_SALE_VEHICLE = 'businessCaseSaleVehiclesTabs',
  BUSINESS_CASE_PURCHASE_VEHICLE = 'businessCasePurchaseVehicles',
  BUSINESS_CASE_CHECKOUT_CREATED_CORRECTIVE_DOCUMENT_TYPE = 'createdCorrectiveDocumentType',
  SERVICE_CASE_ADD_WORK_JOB_ID = 'addWorkJobId',
  SERVICE_CASE_ADD_MATERIAL_REQUEST_JOB_ID = 'addMaterialRequestJobId',
  SERVICE_CASE_CHECKIN_HANDOVER = 'checkinHandover',
  SERVICE_CASE_INSPECTION = 'inspection',
  SERVICE_CASE_JOB_ID = 'jobId',
  SERVICE_CASE_OPEN_JOB_ID = 'openJobId',
  SERVICE_CASE_ID = 'serviceCaseId',
  SERVICE_CASE_ORDER_ID = 'orderId',
  SERVICE_CASE_ORDER_TAB = 'orderTab',
  SERVICE_CASE_REPAIR_ID = 'repairId',
  COMMENT_ID = 'commentId',
  VEHICLE_ADVERTISEMENT_PLATFORMS = 'platforms',
  VEHICLE_ADVERTISEMENT_PUBLISHING = 'publishing',
  VEHICLE_CREATE = 'createVehicle',
  REDIRECT_SOURCE = 'source',
  RETURN_URL = 'returnUrl',
  BRANCH_ID = 'branchId',
  VEHICLE_PURCHASE_TYPE = 'purchaseType',
  AFTERSALES_CHECKOUT_TAB = 'aftersalesCheckoutTab',
  CUSTOMER_ID = 'customerId',
}
/**
 * To avoid merge conflicts, please add new params to a random location (not the last line).
 */
