import {closeCurrentDialog} from 'platform/components';

import {useCreateTaskMutation} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {getApiDateString, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import type {TaskForm as TaskFormType} from '../../types/TaskForm';
import {TaskRelationProps} from '../../types/TaskRelationProps';
import {castTimeToDateISOString} from '../../utils/castTimeToDateISOString';
import {TaskForm} from '../TaskForm/TaskForm';

interface CreateTaskFormProps extends RequiredTestIdProps, TaskRelationProps {
  onCreate?: () => void;
}

export function CreateTask(props: CreateTaskFormProps) {
  const [createTask] = useCreateTaskMutation();

  const onCreateTask = (data: TaskFormType) =>
    createTask({
      createTaskRequestBody: {
        ...data,
        dueDate: data?.dueDate ? getApiDateString(parseDate(data.dueDate)) : null,
        dueFrom:
          data?.dueDate && data?.dueFrom
            ? castTimeToDateISOString(data.dueDate, data.dueFrom)
            : null,
        dueTo:
          data?.dueDate && data?.dueTo ? castTimeToDateISOString(data.dueDate, data.dueTo) : null,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        props.onCreate?.();
      })
      .catch(handleApiError);

  return (
    <TaskForm
      onSubmit={onCreateTask}
      data-testid={suffixTestId('createTask', props)}
      relatedRecordId={props.relatedRecordId}
      resourceId={props.resourceId}
    />
  );
}
