import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

import {isApiException} from '@dms/api';
import {ApiErrorOptions, handleApiError, queryParams, ORDER_TABS} from '@dms/shared';

import {useQueryState} from 'shared';

const VALIDATION_CODES = ['SVC-FE-ER-0118', 'SVC-FE-ER-0119'];

export function useHandlServiceOrderMandatoryFieldsError(orderId: string) {
  const [, setOrderTab] = useQueryState(queryParams.SERVICE_CASE_ORDER_TAB);
  const [, setOrderId] = useQueryState(queryParams.SERVICE_CASE_ORDER_ID);

  const handleMandatoryFieldsError = (error: FetchBaseQueryError, options?: ApiErrorOptions) => {
    const errorData = error.data;

    if (
      isApiException(errorData) &&
      errorData.errors.some((error) => VALIDATION_CODES.includes(error.code))
    ) {
      setOrderTab(ORDER_TABS.DETAIL);
      setOrderId(orderId);
    }

    handleApiError(error, options);
  };

  return handleMandatoryFieldsError;
}
