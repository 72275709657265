import {Dropdown, DropdownItem, Tooltip} from 'platform/components';
import {HStack, Icon, Text, ThemeIconKey} from 'platform/foundation';

import {useSetUserSettingsItemMutation, useSourcingAllowedCountryAndCurrencyQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useSourcingCurrency} from '../../../hooks/useSourcingCurrency';
import {useSourcingTargetCountry} from '../../../hooks/useSourcingTargetCountry';

export function HeaderSettings() {
  const [updateUserSettings] = useSetUserSettingsItemMutation();

  const {data} = useSourcingAllowedCountryAndCurrencyQuery();

  const country = useSourcingTargetCountry();
  const currency = useSourcingCurrency();

  const onChangeCountry = (value: string) => {
    updateUserSettings({
      settingKey: 'sourcing_buyer_country',
      userSettingRequestBody: {value},
    })
      .unwrap()
      .then(() => window.location.reload())
      .catch(handleApiError);
  };

  const onChangeCurrency = (value: string) => {
    updateUserSettings({
      settingKey: 'sourcing_currency',
      userSettingRequestBody: {value},
    })
      .unwrap()
      .then(() => window.location.reload())
      .catch(handleApiError);
  };

  return (
    <HStack spacing={4} align="center">
      <Dropdown
        dropdownControl={
          <Tooltip label={i18n.t('entity.address.labels.targetCountry')}>
            <HStack spacing={1} align="center">
              <Icon value={`flags/${country?.code}` as ThemeIconKey} size={8} />
              <Icon value="hardware/keyboard_arrow_down" />
            </HStack>
          </Tooltip>
        }
        data-testid={testIds.sourcing.classifieds('headerSettings-targetCountry')}
      >
        {data?.countries?.map((country) => (
          <DropdownItem
            key={country}
            leftIcon={`flags/${country}` as ThemeIconKey}
            label={country}
            onClick={() => onChangeCountry(country)}
          />
        ))}
      </Dropdown>
      <Dropdown
        dropdownControl={
          <Tooltip label={i18n.t('entity.bank.labels.currency')}>
            <HStack spacing={1} align="center">
              <Text>{currency?.name}</Text>
              <Icon value="hardware/keyboard_arrow_down" />
            </HStack>
          </Tooltip>
        }
        data-testid={testIds.sourcing.classifieds('headerSettings-currency')}
      >
        {data?.currencies?.map((currency) => (
          <DropdownItem
            key={currency}
            label={currency}
            onClick={() => onChangeCurrency(currency)}
          />
        ))}
      </Dropdown>
    </HStack>
  );
}
