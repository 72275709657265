import {ButtonGroup, Checkbox, closeCurrentDialog} from 'platform/components';
import {VStack, Text} from 'platform/foundation';

import {useState} from 'react';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface DeleteErrorLogsDialogBodyProps extends RequiredTestIdProps {
  onConfirm: (shouldDeleteUnresolved: boolean) => Promise<void>;
}

export function DeleteErrorLogsDialogBody(props: DeleteErrorLogsDialogBodyProps) {
  const [shouldDeleteUnresolved, setShouldDeleteUnresolved] = useState(false);

  return (
    <VStack spacing={4}>
      <Text>{i18n.t('page.accounting.labels.shouldDeleteUnresolvedMessage')}</Text>
      <Checkbox
        data-testid={suffixTestId('shouldDeleteUnresolved', props)}
        value={shouldDeleteUnresolved}
        onChange={setShouldDeleteUnresolved}
        label={i18n.t('page.accounting.labels.shouldDeleteUnresolved')}
      />

      <ButtonGroup
        align="right"
        data-testid={suffixTestId('ErrorLogsButtonGroup', props)}
        buttons={[
          {
            variant: 'secondary',
            title: i18n.t('general.actions.cancel'),
            onClick: closeCurrentDialog,
          },
          {
            variant: 'danger',
            title: i18n.t('general.actions.delete'),
            onClick: async () => {
              await props.onConfirm(shouldDeleteUnresolved);
              closeCurrentDialog();
            },
          },
        ]}
      />
    </VStack>
  );
}
