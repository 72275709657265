import {unwrapResult} from '@reduxjs/toolkit';

import {useEffect, useState} from 'react';

import {
  calculateFromWithoutVat,
  calculateFromWithVat,
  PriceValueType,
  VatCalculation,
  useThunkDispatch,
} from '@dms/teas';

export function usePriceCalculation(price: PriceValueType | undefined, countryCode?: string) {
  const [secondPrice, setSecondPrice] = useState<string>('');
  const dispatch = useThunkDispatch();

  useEffect(() => {
    const amount = !isNaN(Number(price?.value?.amount))
      ? Number(price?.value?.amount).toString()
      : '';

    const query = {
      countryCode: countryCode ?? '',
      amount,
      vatRateType: 'S', // According to https://carvago.atlassian.net/browse/T20-2641
    };

    async function fetchData(): Promise<void> {
      let res: VatCalculation | null;
      if (price?.withVat) {
        res = unwrapResult(await dispatch(calculateFromWithVat.action(query)));
      } else {
        res = unwrapResult(await dispatch(calculateFromWithoutVat.action(query)));
      }
      setSecondPrice((price?.withVat ? res?.withoutVat : res?.withVat) as string);
    }
    if (!isNaN(Number(price?.value?.amount))) {
      fetchData();
    }
  }, [price?.withVat, dispatch, price?.value?.amount]);

  return secondPrice;
}
