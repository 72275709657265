import i18n from '@dms/i18n';
import {ExpandHeader} from '@dms/teas';

export const tableColumns = [
  {
    Header: ExpandHeader,
    id: 'expand',
    width: 30,
  },
  {
    Header: i18n.t('entity.document.labels.documentTitle'),
    id: 'title',
  },
  {
    Header: i18n.t('entity.document.labels.templateTitle'),
    id: 'fileName',
  },
  {
    Header: '',
    id: 'actions',
    width: 80,
  },
];

export const expandTableColumns = [
  {
    Header: i18n.t('entity.document.labels.templateFile'),
    id: 'templateFile',
    width: 177,
  },
  {
    'data-testid': 'templateTitle',
    id: 'templateTitle',
    width: 218,
    Header: i18n.t('entity.document.labels.templateTitle'),
  },
  {
    id: 'state',
    width: 218,
    Header: i18n.t('entity.document.labels.state'),
  },
  {
    id: 'lastUpdate',
    width: 218,
    Header: i18n.t('entity.document.labels.lastUpdate'),
  },
  {
    id: 'addedBy',
    width: 218,
    Header: i18n.t('entity.document.labels.addedBy'),
  },
  {
    id: 'note',
    width: 218,
    Header: i18n.t('entity.document.labels.note'),
  },
  {
    Header: '',
    id: 'actions',
  },
];
