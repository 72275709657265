import {Tooltip, useAnimatedPopper} from 'platform/components';
import styled from 'styled-components';

import {useContext} from 'react';
import {useDispatch} from 'react-redux';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {assignUsersRequest} from '../../../store/assignment/reducer';
import {AsyncSearchDropdown} from '../../AsyncSearchDropdown/AsyncSearchDropdown';
import {DropdownCommonOption} from '../../DropdownMulti/DropdownMulti';
import {external, TAssignmentButtonType} from '../types/AssignmentType';
import {AssignmentContext} from './AssignmentContext';

export function AssignmentButton({
  modalEvent,
  loadOptions,
  ...rest
}: TAssignmentButtonType & TestIdProps) {
  const context = useContext(AssignmentContext);
  const dispatch = useDispatch();
  const {openPopper, referenceRef, popperProps, Popper} = useAnimatedPopper({
    placement: 'bottom-start',
  });

  const getTranslation = () => {
    switch (context.type) {
      case 'vehicle':
        return i18n.t('general.labels.assignVehicleHeaderTooltip');
      default:
        return i18n.t('general.labels.assignTooltip');
    }
  };

  return (
    <>
      <Tooltip label={getTranslation()}>
        <AssignButton
          onClick={openPopper}
          ref={referenceRef}
          data-testid={suffixTestId('assignButton', rest)}
          aria-haspopup="true"
        >
          {i18n.t('general.actions.assign')}
        </AssignButton>
      </Tooltip>

      <Popper {...popperProps}>
        <AsyncSearchDropdown
          name="assign-user"
          enterPreventDefault={true}
          loadOptions={loadOptions}
          noOptionsMessage={() => i18n.t('general.notifications.noResults')}
          data-testid={suffixTestId('assignDropdown', rest)}
          onChange={(item) => {
            if (!item) {
              return;
            }

            // to create new external user
            if ((item as DropdownCommonOption).value === external) {
              modalEvent(true);
            } else {
              // to add user to assignment list
              dispatch(
                assignUsersRequest({
                  userId: (item as DropdownCommonOption)?.value,
                  identity: {
                    id: context.assignmentIdentityId,
                    type: context.type,
                  },
                })
              );
            }
          }}
        />
      </Popper>
    </>
  );
}

const AssignButton = styled.span`
  cursor: pointer;
  background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  justify-content: center;
  height: 24px;
  border-radius: 5px;
  align-items: center;
  display: inline-flex;
  padding: 0 8px;
  color: ${({theme}) => theme.colors.palettes.neutral[700][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
`;
