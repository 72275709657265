import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  Tooltip,
} from 'platform/components';
import {Box, Hide, HStack, Space, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {head} from 'ramda';

import {
  GetLabourCatalogApiResponse,
  usePatchLabourCatalogCalculationPriceMutation,
  usePostLabourCatalogCalculationPriceMutation,
  usePostWorkBasketItemWithCalculationPriceMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useGetVatRatesOptions} from '../../../hooks/useGetVatRatesOptions';
import {useWorkTypeOptions} from '../../../hooks/useWorkTypeOptions';
import {handleApiError} from '../../../utils/handleApiError';
import {TreeFolderPath} from '../../TreeFolderPath/TreeFolderPath';
import {CatalogueFolder} from '../types/catalogueFolder';
import {FreePositionData} from '../types/freePositionData';

type CalculationPriceFormType = {
  name: string;
  number: string;
  labourCatalogCategoryId: string;
  workType: string;
  purchaseUnitPrice: number;
  sellingUnitPrice: number;
  unit: string;
  vatType: string;
  doNotApplyDiscount: boolean;
  timeOutputPerUnit: number;
  timeOutputUnit: string;
};

interface CalculationPriceFormProps extends TestIdProps {
  initalValues?: Partial<GetLabourCatalogApiResponse>;
  freePositionData?: FreePositionData;
  editedData?: GetLabourCatalogApiResponse;
  isFreePosition?: boolean;
  onClose: () => void;
  onSubmitted: (itemId?: string | null) => Promise<void>;
  catalogueFolder?: CatalogueFolder;
  onCatalogueFolderChange: VoidFunction;
  onInitialValuesChange: (values: Partial<GetLabourCatalogApiResponse>) => void;
  canEditCatalogueItem: boolean;
}

export function CalculationPriceForm(props: CalculationPriceFormProps) {
  const [
    postLabourCatalogCalculationPrice,
    {isLoading: isPostLabourCatalogCalculationPriceLoading},
  ] = usePostLabourCatalogCalculationPriceMutation();
  const [
    patchLabourCatalogCalculationPrice,
    {isLoading: isPatchLabourCatalogCalculationPriceLoading},
  ] = usePatchLabourCatalogCalculationPriceMutation();
  const [
    postWorkBasketItemWithCalculationPrice,
    {isLoading: isPostWorkBasketItemWithCalculationPriceLoading},
  ] = usePostWorkBasketItemWithCalculationPriceMutation();
  const {getOptionsWithSelectedValue, defaultWorkTypeId} = useWorkTypeOptions();

  const [vatOptions] = useGetVatRatesOptions();

  const workTypeOptions = getOptionsWithSelectedValue(props.editedData?.workType);

  const initialValues = props.editedData ?? props.initalValues;

  const handleSubmit: FormSubmitHandler<CalculationPriceFormType> = async (data) => {
    const body = {
      ...data,
      labourCatalogCategoryId:
        props.catalogueFolder?.contextId ?? props.editedData?.labourCatalogCategoryId,
    };

    if (props.editedData) {
      await patchLabourCatalogCalculationPrice({
        labourCatalogId: props.editedData.id ?? '',
        body,
      })
        .unwrap()
        .catch(handleApiError);
      await props.onSubmitted?.(props.editedData.id);
      return;
    }

    if (props.isFreePosition && props.freePositionData) {
      await postWorkBasketItemWithCalculationPrice({
        serviceCaseId: props.freePositionData.serviceCaseId,
        serviceJobId: props.freePositionData.serviceJobId,
        serviceOrderId: props.freePositionData.serviceOrderId,
        body: {
          ...data,
          isDoNotApplyDiscount: data.doNotApplyDiscount,
          quantity: 1,
          currency: DEFAULT_CURRENCY,
          isUnitPriceWithVat: false,
        },
      })
        .unwrap()
        .then(() => {
          props.onSubmitted?.();
        })
        .catch(handleApiError);
      return;
    }

    const response = await postLabourCatalogCalculationPrice({body}).unwrap().catch(handleApiError);
    await props.onSubmitted?.(response?.id);
  };

  const defaultValues: Partial<CalculationPriceFormType> = {
    name: initialValues?.name ?? undefined,
    number: initialValues?.number ?? undefined,
    workType: initialValues?.workType ?? defaultWorkTypeId ?? undefined,
    purchaseUnitPrice: initialValues?.purchaseUnitPrice ?? undefined,
    sellingUnitPrice: initialValues?.sellingUnitPrice ?? undefined,
    unit: initialValues?.unit ?? undefined,
    vatType: initialValues?.vatType ?? head(vatOptions)?.value,
    doNotApplyDiscount: initialValues?.isDoNotApplyDiscount ?? undefined,
    timeOutputPerUnit: initialValues?.timeOutputPerUnit ?? undefined,
    timeOutputUnit: initialValues?.timeOutputUnit ?? undefined,
  };

  const isSubmitLoading =
    isPostLabourCatalogCalculationPriceLoading ||
    isPatchLabourCatalogCalculationPriceLoading ||
    isPostWorkBasketItemWithCalculationPriceLoading;

  return (
    <Form<CalculationPriceFormType>
      schema={CalculationPriceFormSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        formApi.watch((data) => props.onInitialValuesChange(data));

        return (
          <VStack spacing={4}>
            <FormField
              control={control}
              type="text"
              name="name"
              label={i18n.t('general.labels.name')}
              isRequired
              data-testid={suffixTestId('name', props)}
            />
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="number"
                  label={i18n.t('general.labels.number')}
                  isRequired
                  data-testid={suffixTestId('number', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="workType"
                  options={workTypeOptions}
                  label={i18n.t('entity.addWork.lables.workCategory')}
                  placeholder={i18n.t('general.labels.select')}
                  isRequired
                  menuInPortal
                  data-testid={suffixTestId('workType', props)}
                />
              </Box>
            </HStack>
            <Hide when={props.isFreePosition}>
              <TreeFolderPath
                leafId={props.catalogueFolder?.id ?? props.editedData?.treeFolder?.id}
                onEdit={props.onCatalogueFolderChange}
                data-testid={suffixTestId('categoryPath', props)}
              />
            </Hide>
            <Separator spacing={0} />
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="number"
                  name="purchaseUnitPrice"
                  label={i18n.t('entity.addWork.lables.purchasePricePerUnit')}
                  isRequired
                  data-testid={suffixTestId('purchaseUnitPrice', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="number"
                  name="sellingUnitPrice"
                  label={i18n.t('entity.addWork.lables.sellingPricePerUnit')}
                  isRequired
                  data-testid={suffixTestId('sellingUnitPrice', props)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="unit"
                  label={i18n.t('entity.addWork.lables.unit')}
                  isRequired
                  data-testid={suffixTestId('unit', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="vatType"
                  options={vatOptions}
                  label={i18n.t('entity.addWork.lables.vat')}
                  placeholder={i18n.t('general.labels.select')}
                  isRequired
                  menuInPortal
                  data-testid={suffixTestId('vatType', props)}
                />
              </Box>
            </HStack>
            <Separator spacing={0} />
            <HStack spacing={4}>
              <Box flex={1}>
                <HStack spacing={4}>
                  <Box flex={3}>
                    <FormField
                      control={control}
                      type="number"
                      name="timeOutputPerUnit"
                      label={i18n.t('entity.addWork.lables.timeOutputPerUnit')}
                      data-testid={suffixTestId('timeOutputPerUnit', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="text"
                      name="timeOutputUnit"
                      label={i18n.t('entity.addWork.lables.unit')}
                      data-testid={suffixTestId('timeOutputUnit', props)}
                    />
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <Space vertical={6} />
                <HStack align="flex-start">
                  <Box paddingRight={4}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="doNotApplyDiscount"
                      data-testid={suffixTestId('doNotApplyDiscount', props)}
                    />
                  </Box>
                  <Text size="small">{i18n.t('entity.addWork.lables.dontApplyDiscount')}</Text>
                </HStack>
              </Box>
            </HStack>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discard', props)}
              />
              <Tooltip
                label={i18n.t('general.labels.noPermission')}
                isDisabled={props.canEditCatalogueItem}
              >
                <FormButton
                  control={control}
                  type="submit"
                  title={
                    props.editedData
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.create')
                  }
                  isLoading={isSubmitLoading}
                  isDisabled={!props.canEditCatalogueItem}
                  data-testid={suffixTestId('create', props)}
                />
              </Tooltip>
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const CalculationPriceFormSchema = Yup.object({
  name: Yup.string().required(),
  number: Yup.string().required(),
  workType: Yup.string().required(),
  purchaseUnitPrice: Yup.number().required(),
  sellingUnitPrice: Yup.number().required(),
  unit: Yup.string().required(),
  vatType: Yup.string().required(),
  doNotApplyDiscount: Yup.boolean().nullable(),
  timeOutputPerUnit: Yup.number().nullable(),
  timeOutputUnit: Yup.string().nullable(),
});
