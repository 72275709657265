import {Button, ButtonGroup, Dialog} from 'platform/components';
import {Space, Text} from 'platform/foundation';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

interface DeleteDialogProps {
  isOpen?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export function DeleteDialog({onClose, onConfirm, isOpen}: DeleteDialogProps) {
  return (
    <Dialog size="small" isOpen={!!isOpen} onClose={onClose}>
      <Text>{i18n.t('page.vehicleSettings.labels.parkingSectorRemovDescription')}</Text>
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button
          onClick={onClose}
          variant="secondary"
          data-testid={testIds.settings.vehicleSeriesDefinition('cancelDeletion')}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          variant="danger"
          onClick={onConfirm}
          data-testid={testIds.settings.vehicleSeriesDefinition('confirmDeletion')}
          title={i18n.t('general.actions.remove')}
        />
      </ButtonGroup>
    </Dialog>
  );
}
