import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import featureFlags from '@dms/feature-flags';
import {myProfileRoutes} from '@dms/routes';

import {Dashboard} from './pages/Dashboard/Dashboard';
import {Language} from './pages/Language/Language';
import {PersonalInformation} from './pages/PersonalInformation/PersonalInformation';
import {Security} from './pages/Security/Security';
import {MyProfileModuleProps} from './types/MyProfileModuleProps';

export const MyProfileModule = (props: MyProfileModuleProps): Module => ({
  id: moduleKeys.myProfile,
  layoutProps: {
    title: 'entity.user.labels.myProfile',
    icon: 'social/person',
  },
  routerProps: {
    path: myProfileRoutes.personalInformation,
    element: <PersonalInformation />,
  },
  requiredFeatureFlag: featureFlags.MY_PROFILE,
  content: [
    {
      id: 'my-profile-personal-information',
      routerProps: {
        path: myProfileRoutes.personalInformation,
        element: <PersonalInformation />,
      },
      layoutProps: {
        title: 'entity.user.labels.personalInformation',
        icon: 'social/person',
      },
    },
    {
      id: 'my-profile-security',
      routerProps: {
        path: myProfileRoutes.security,
        element: <Security />,
      },
      layoutProps: {
        title: 'entity.user.labels.security',
        icon: 'hardware/security',
      },
    },
    {
      id: 'my-profile-dashboard',
      routerProps: {
        path: myProfileRoutes.dashboard,
        element: <Dashboard />,
      },
      layoutProps: {
        title: 'page.myProfile.dashboard.sidebarTitle',
        icon: 'action/dashboard',
      },
    },
    {
      id: 'my-profile-language',
      routerProps: {
        path: myProfileRoutes.language,
        element: <Language persistor={props.persistor} />,
      },
      layoutProps: {
        title: 'entity.user.labels.langAndTime',
        icon: 'action/language',
      },
    },
  ],
});
