import {Grid, VStack} from 'platform/foundation';

import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {values} from 'ramda';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingAddVehicleHighlightBulkMutation,
  useSourcingAddVehicleShortcomingBulkMutation,
  useSourcingRemoveVehicleHighlightMutation,
  useSourcingRemoveVehicleShortcomingMutation,
  useSourcingSetFeatureAsHighlightedMutation,
  useSourcingSetFeatureAsNotHighlightedMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {
  CardTagSelection,
  CardTagSelectionTagType,
  loadHighlightsList,
  loadShortcomingsList,
  selectLists,
  TagType,
  VehicleCatalogueFeature,
} from '@dms/teas';

import {AllFeaturesCard} from './components/AllFeaturesCard/AllFeaturesCard';
import {HighlightedFeaturesCard} from './components/HighlightedFeaturesCard/HighlightedFeaturesCard';
import {SalesClaimNote} from './components/SalesClaimNote/SalesClaimNote';

interface HighlightsTabProps {
  adId: string;
  vehicle: SourcingVehicleDetailResponseBody;
}

export function HighlightsTab(props: HighlightsTabProps) {
  const dispatch = useDispatch();
  const lists = useSelector(selectLists);
  const {highlights, shortcomings} = props.vehicle;
  const [setFeatureAsHighlight] = useSourcingSetFeatureAsHighlightedMutation();
  const [setFeatureAsNotHighlight] = useSourcingSetFeatureAsNotHighlightedMutation();
  const [addVehicleHighlights] = useSourcingAddVehicleHighlightBulkMutation();
  const [removeVehicleHighlight] = useSourcingRemoveVehicleHighlightMutation();
  const [addVehicleShortcomings] = useSourcingAddVehicleShortcomingBulkMutation();
  const [removeVehicleShortcoming] = useSourcingRemoveVehicleShortcomingMutation();

  const sortByName = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const vehicleFeaturesWithTranslation = values(props.vehicle.features).filter(
    (feature) => feature.key !== feature.translation
  );

  const highlightedFeatureKeys = vehicleFeaturesWithTranslation
    .filter((f) => f.isHighlight)
    .sort((a, b) => sortByName(a.translation, b.translation))
    .map((f) => f.key);

  const featuresRemapped: VehicleCatalogueFeature[] = vehicleFeaturesWithTranslation
    .map((feature) => ({
      key: feature.key,
      oldKey: feature.key,
      parent: undefined,
      name: feature.translation,
      nameShort: feature.translation,
      category: undefined,
      isPremium: feature.isPremium,
    }))
    .sort((a, b) => sortByName(a.name, b.name));

  useEffect(() => {
    dispatch(loadHighlightsList());
    dispatch(loadShortcomingsList());
  }, [dispatch, props.adId]);

  const handleFeatureHighlightOn = (keys: string[]) => {
    keys.forEach((key) => {
      setFeatureAsHighlight({
        externalId: props.adId,
        featureKey: key,
      })
        .unwrap()
        .catch(handleApiError);
    });
  };

  const handleFeatureHighlightOff = (key: string) => {
    setFeatureAsNotHighlight({
      externalId: props.adId,
      featureKey: key,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleHighlightsAdd = (options: CardTagSelectionTagType[]) => {
    addVehicleHighlights({
      externalId: props.adId,
      highlights: options.map((highlight) => highlight.label),
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleHighlightDelete = (option: CardTagSelectionTagType) => {
    removeVehicleHighlight({
      externalId: props.adId,
      highlightId: option.id,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleShortcomingsAdd = (options: CardTagSelectionTagType[]) => {
    addVehicleShortcomings({
      externalId: props.adId,
      shortcomings: options.map((shortcoming) => shortcoming.label),
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleShortcomingDelete = (option: CardTagSelectionTagType) => {
    removeVehicleShortcoming({
      externalId: props.adId,
      shortcomingId: option.id,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const remapTitleToLabel = useCallback(
    (x: readonly TagType[]) => x?.map(({id, title}) => ({id, label: title})) || [],
    []
  );

  return (
    <Grid columns={[1, 2]} spacing={2}>
      <AllFeaturesCard
        featureItems={featuresRemapped}
        highlightedFeatures={highlightedFeatureKeys}
        onHighlightOn={handleFeatureHighlightOn}
        onHighlightOff={handleFeatureHighlightOff}
      />

      <VStack spacing={2} minWidth={0}>
        <CardTagSelection
          tags={remapTitleToLabel(highlights)}
          predefinedTags={remapTitleToLabel(lists.highlights.data ?? [])}
          onDelete={handleHighlightDelete}
          onCreate={handleHighlightsAdd}
          data-testid={testIds.vehicles.detail('highlightedFeatures')}
          title={i18n.t('entity.vehicle.labels.carHighlights')}
          editDialogTitle={i18n.t('entity.vehicle.labels.carHighlights')}
          emptyMessage={i18n.t('entity.vehicle.labels.highlightedFeaturesPlaceholder')}
        />

        <CardTagSelection
          tags={remapTitleToLabel(shortcomings)}
          predefinedTags={remapTitleToLabel(lists.shortcomings.data ?? [])}
          onDelete={handleShortcomingDelete}
          onCreate={handleShortcomingsAdd}
          data-testid={testIds.vehicles.detail('shortcomings')}
          title={i18n.t('entity.vehicle.labels.shortcomings')}
          editDialogTitle={i18n.t('entity.vehicle.labels.shortcomings')}
          emptyMessage={i18n.t('entity.vehicle.labels.shortcomingsPlaceholder')}
          tagsColorScheme="red"
        />

        <HighlightedFeaturesCard
          featureItems={featuresRemapped}
          highlightedFeatures={highlightedFeatureKeys}
          onHighlightOn={handleFeatureHighlightOn}
          onHighlightOff={handleFeatureHighlightOff}
        />

        <SalesClaimNote adId={props.adId} />
      </VStack>
    </Grid>
  );
}
