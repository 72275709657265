import {FormControl, FormField} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import i18n from '@dms/i18n';

import {MultipleLocationsForm, SymbolType, VectorType} from '../types/MultipleLocationsForm';

interface VectorRowProps {
  index: number;
  control: FormControl<MultipleLocationsForm>;
  formApi: UseFormReturn<MultipleLocationsForm>;
}

export function VectorRow(props: VectorRowProps) {
  const isSelected = useWatch({
    control: props.control,
    name: `definition.${props.index}.isSelected`,
  });

  return (
    <HStack spacing={4} align="center">
      <Box width={5}>
        <FormField
          type="checkbox"
          name={`definition.${props.index}.isSelected`}
          control={props.control}
          onChange={() => props.formApi.trigger()}
        />
      </Box>
      <Box flex={1}>
        <FormField
          type="choice"
          name={`definition.${props.index}.vectorType`}
          control={props.control}
          options={[
            {
              label: i18n.t('entity.tireWarehouses.labels.aisle'),
              value: VectorType.Aisle,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.rack'),
              value: VectorType.Rack,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.level'),
              value: VectorType.Level,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.position'),
              value: VectorType.Position,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.other'),
              value: VectorType.Other,
            },
          ]}
          isDisabled
          isNotClearable
        />
      </Box>
      <Box flex={1}>
        <FormField
          type="number"
          name={`definition.${props.index}.number`}
          control={props.control}
          minStepperValue={1}
          maxStepperValue={99}
          isDisabled={!isSelected}
          isStepperVisible
        />
      </Box>
      <Box flex={1}>
        <FormField
          type="choice"
          name={`definition.${props.index}.symbolType`}
          control={props.control}
          options={[
            {
              label: i18n.t('entity.tireWarehouses.labels.alphabet'),
              value: SymbolType.Alphabet,
            },
            {
              label: i18n.t('general.labels.number'),
              value: SymbolType.Numbers,
            },
          ]}
          value={SymbolType.Alphabet}
          isNotClearable
          onChange={() => props.formApi.trigger()}
          isDisabled={!isSelected}
        />
      </Box>
      <Box flex={1}>
        <FormField
          type="text"
          name={`definition.${props.index}.beginAt`}
          control={props.control}
          isDisabled={!isSelected}
        />
      </Box>
    </HStack>
  );
}
