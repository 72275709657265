import {
  CreateTaskApiArg,
  CreateTaskApiResponse,
  DeleteTaskApiArg,
  DeleteTaskApiResponse,
  EditTaskApiArg,
  EditTaskApiResponse,
  GetMyTasksApiArg,
  GetMyTasksApiResponse,
  GetMyTasksCountApiArg,
  GetMyTasksCountApiResponse,
  GetTaskApiArg,
  GetTaskApiResponse,
  GetTasksByMeApiArg,
  GetTasksByMeApiResponse,
} from '../types/taskManagement';
import {omneticApi as api} from './baseApi/omneticApi';

export const taskManagementApi = api.injectEndpoints({
  endpoints: (build) => ({
    createTask: build.mutation<CreateTaskApiResponse, CreateTaskApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/task-management/task`,
        method: 'PUT',
        body: queryArg.createTaskRequestBody,
      }),
      invalidatesTags: ['TaskCounter', 'TaskList'],
    }),
    deleteTask: build.mutation<DeleteTaskApiResponse, DeleteTaskApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/task-management/task/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error) => ['TaskCounter', 'TaskList'],
    }),
    editTask: build.mutation<EditTaskApiResponse, EditTaskApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/task-management/task/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editTaskRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'Task', id: queryArg.id},
        'TaskCounter',
        'TaskList',
      ],
    }),
    getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/task-management/task/${queryArg.id}`,
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'Task', id: queryArg.id}],
    }),
    getMyTasks: build.query<GetMyTasksApiResponse, GetMyTasksApiArg>({
      query: () => ({
        url: `/dms/v1/task-management/my-tasks`,
      }),
      providesTags: ['TaskList'],
    }),
    getMyTasksCount: build.query<GetMyTasksCountApiResponse, GetMyTasksCountApiArg>({
      query: () => ({
        url: `/dms/v1/task-management/my-tasks/count`,
      }),
      providesTags: ['TaskCounter'],
    }),
    getTasksByMe: build.query<GetTasksByMeApiResponse, GetTasksByMeApiArg>({
      query: () => ({
        url: `/dms/v1/task-management/tasks-by-me`,
      }),
      providesTags: ['TaskList'],
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useEditTaskMutation,
  useGetTaskQuery,
  useGetMyTasksQuery,
  useGetMyTasksCountQuery,
  useGetTasksByMeQuery,
} = taskManagementApi;
