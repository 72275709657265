import {DeleteDialog, Dropdown, IconButton, showNotification} from 'platform/components';
import {Spinner} from 'platform/foundation';

import {FC, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {
  DefaultTemplateService,
  deleteFile,
  downloadTemplate,
  getDocumentTemplate,
  setPrimaryTemplate,
  documentDownloadingSelector,
  selectCustomTemplate,
  useCallApi,
} from '@dms/teas';

import {TemplateUpdating} from '../styles';
import {ActionEventType, MenuActionProps} from '../types';
import {menuActions} from './menuActions';
import {UploadDocumentTemplateFormContainer} from './UploadDocumentTemplateFormContainer';

export const MenuAction: FC<MenuActionProps> = ({
  isPrimary,
  isSystem,
  isHideable,
  isHidden,
  templateCode,
  fileName,
  downloadUrl,
  customTemplateId,
}) => {
  const selectedDocument = useSelector(selectCustomTemplate(customTemplateId ?? ''));
  const [showModal, setModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const downloading = useSelector(documentDownloadingSelector);
  const dispatch = useDispatch();
  const hideTemplate = useCallApi(DefaultTemplateService.hideDefaultTemplate);
  const unhideTemplate = useCallApi(DefaultTemplateService.unhideDefaultTemplate);

  const handleActionEvent = (type: ActionEventType) => {
    switch (type) {
      case 'docx':
      case 'pdf':
        downloadEvent(type);
        break;
      case 'delete':
        setModal(true);
        break;
      case 'edit':
        setShowForm(true);
        break;
      case 'setPrimary':
        dispatch(setPrimaryTemplate(customTemplateId));
        break;
      case 'hide':
      case 'unhide':
        toggleHide();
        break;
      default:
        return;
    }
  };

  const downloadEvent = (type: string) => {
    if (downloading) {
      return;
    }

    dispatch(
      downloadTemplate({
        type,
        url: downloadUrl,
        fileName: fileName ?? templateCode,
        customTemplateId: isSystem ? null : customTemplateId,
      })
    );
  };

  const toggleHide = useCallback(() => {
    const action = isHidden ? unhideTemplate : hideTemplate;
    action({templateId: customTemplateId})
      .then(() => {
        dispatch(getDocumentTemplate);
        showNotification.success();
      })
      .catch((e) => {
        handleApiError(e);
      });
  }, [customTemplateId, dispatch, hideTemplate, isHidden, unhideTemplate]);

  const onDeleteConfirm = () => {
    dispatch(deleteFile(customTemplateId));
    setModal(false);
  };

  if (selectedDocument?.isLoading) {
    return (
      <TemplateUpdating>
        {i18n.t('general.labels.editing')}
        ... <Spinner size="small" />
      </TemplateUpdating>
    );
  }

  return (
    <>
      <Dropdown
        data-testid={testIds.settings.documentTemplates('actions')}
        dropdownControl={
          <IconButton
            size="small"
            data-testid={testIds.settings.documentTemplates('verticalButton')}
            icon="navigation/more_vert"
          />
        }
      >
        {menuActions({isPrimary, isSystem, isHideable, isHidden, handleActionEvent})}
      </Dropdown>
      {showForm ? (
        <UploadDocumentTemplateFormContainer
          open={showForm}
          code={templateCode}
          customTemplateId={customTemplateId}
          onClose={() => setShowForm(false)}
        />
      ) : null}

      <DeleteDialog
        isOpen={showModal}
        data-testid={testIds.settings.documentTemplates('delete')}
        onClose={() => setModal(false)}
        onConfirm={onDeleteConfirm}
        text={i18n.t('entity.document.labels.removeTitle')}
      />
    </>
  );
};
