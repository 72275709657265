import {isNotNilOrEmpty} from 'ramda-adjunct';

import {BusinessCaseResponseBody, useGetBusinessCaseQuery} from '@dms/api';

export function useBusinessCase(businessCaseId: string) {
  const {
    data: businessCase,
    isLoading: isLoadingBusinessCase,
    isError: hasBusinessCaseError,
  } = useGetBusinessCaseQuery({businessCaseId});

  const activeOffer = businessCase?.offers?.[0];

  const activeSellingVehicleId = activeOffer?.saleVehicles?.[0]?.vehicleId;

  const type = businessCase?.businessCaseInternalType;
  const isBrokerage =
    type === 'PURCHASE_BROKERAGE' || Boolean(businessCase?.brokerageBusinessCaseId);
  const isSelling = type === 'SELLING';
  const isBuying = type === 'BUYING';

  const activeOfferSaleVehicles = activeOffer?.saleVehicles ?? [];
  const activeOfferPurchaseVehicles = isBrokerage
    ? (activeOffer?.purchaseBrokerageVehicles ?? [])
    : (activeOffer?.purchaseVehicles ?? []);

  return {
    isLoading: isLoadingBusinessCase,
    isError: hasBusinessCaseError,

    businessCaseId: businessCase?.id,
    id: businessCase?.id,
    businessCase: businessCase ?? ({} as BusinessCaseResponseBody),
    offer: activeOffer,

    saleVehicles: activeOfferSaleVehicles,
    purchaseVehicles: activeOfferPurchaseVehicles,

    sellingVehicleId: activeSellingVehicleId,
    hasSaleVehicle: isNotNilOrEmpty(activeSellingVehicleId),

    type,
    state: businessCase?.businessCaseState,

    isBrokerage,
    isSelling,
    isBuying,
  };
}
