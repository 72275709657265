import {
  GetStorageLocationApiApiArg,
  GetStorageLocationApiApiResponse,
  PostStorageLocationDeleteApiApiArg,
  PostStorageLocationDeleteApiApiResponse,
  PostStorageLocationNoteApiApiArg,
  PostStorageLocationNoteApiApiResponse,
  PostStorageLocationAddLocationApiApiArg,
  PostStorageLocationAddLocationApiApiResponse,
  PostStorageLocationGenerateLocationApiApiArg,
  PostStorageLocationGenerateLocationApiApiResponse,
  GetStorageLocationGeneratorSettingApiApiArg,
  GetStorageLocationGeneratorSettingApiApiResponse,
  GetStorageLocationStatisticsApiApiArg,
  GetStorageLocationStatisticsApiApiResponse,
  GetStorageLocationByIdApiApiArg,
  GetStorageLocationByIdApiApiResponse,
  PatchStorageLocationApiApiArg,
  PatchStorageLocationApiApiResponse,
  PostStorageLocationOccupiedApiApiArg,
  PostStorageLocationOccupiedApiApiResponse,
  PostStorageLocationReleasedApiApiArg,
  PostStorageLocationReleasedApiApiResponse,
  PatchStorageLocationV2ApiResponse,
  PostStorageLocationAddLocationV2ApiResponse,
  PostStorageLocationAddLocationV2ApiArg,
  PostStorageLocationGenerateLocationV2ApiArg,
  PostStorageLocationGenerateLocationV2ApiResponse,
  PatchStorageLocationV2ApiArg,
} from '../types/metadaStorageLocation';
import {metadaApi} from './baseApi/metadaApi';

export const metadaStorageLocationApi = metadaApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    postStorageLocationDeleteApi: build.mutation<
      PostStorageLocationDeleteApiApiResponse,
      PostStorageLocationDeleteApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/delete`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postStorageLocationNoteApi: build.mutation<
      PostStorageLocationNoteApiApiResponse,
      PostStorageLocationNoteApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/note`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireWarehouseStorageLocationDetail'],
    }),
    getStorageLocationApi: build.query<
      GetStorageLocationApiApiResponse,
      GetStorageLocationApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.contextTarget}/${queryArg.contextId}`,
      }),
    }),
    postStorageLocationAddLocationApi: build.mutation<
      PostStorageLocationAddLocationApiApiResponse,
      PostStorageLocationAddLocationApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.contextTarget}/${queryArg.contextId}/add-location`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postStorageLocationGenerateLocationApi: build.mutation<
      PostStorageLocationGenerateLocationApiApiResponse,
      PostStorageLocationGenerateLocationApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.contextTarget}/${queryArg.contextId}/generate-location`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireWarehouseGeneratorSettings'],
    }),
    getStorageLocationGeneratorSettingApi: build.query<
      GetStorageLocationGeneratorSettingApiApiResponse,
      GetStorageLocationGeneratorSettingApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.contextTarget}/${queryArg.contextId}/generator-settings`,
      }),
      providesTags: ['tireWarehouseGeneratorSettings'],
    }),
    getStorageLocationStatisticsApi: build.query<
      GetStorageLocationStatisticsApiApiResponse,
      GetStorageLocationStatisticsApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.contextTarget}/${queryArg.contextId}/inventory`,
      }),
    }),
    getStorageLocationByIdApi: build.query<
      GetStorageLocationByIdApiApiResponse,
      GetStorageLocationByIdApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.storageLocationId}`,
      }),
      providesTags: (result, error, arg) => [
        {type: 'tireWarehouseStorageLocationDetail', id: arg.storageLocationId},
      ],
    }),
    patchStorageLocationApi: build.mutation<
      PatchStorageLocationApiApiResponse,
      PatchStorageLocationApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.storageLocationId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    postStorageLocationOccupiedApi: build.mutation<
      PostStorageLocationOccupiedApiApiResponse,
      PostStorageLocationOccupiedApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.storageLocationId}/occupied`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postStorageLocationReleasedApi: build.mutation<
      PostStorageLocationReleasedApiApiResponse,
      PostStorageLocationReleasedApiApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/storage-location/${queryArg.storageLocationId}/released`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postStorageLocationAddLocationV2: build.mutation<
      PostStorageLocationAddLocationV2ApiResponse,
      PostStorageLocationAddLocationV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v2/storage-location/${queryArg.contextTarget}/${queryArg.contextId}/add-location`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postStorageLocationGenerateLocationV2: build.mutation<
      PostStorageLocationGenerateLocationV2ApiResponse,
      PostStorageLocationGenerateLocationV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v2/storage-location/${queryArg.contextTarget}/${queryArg.contextId}/generate-location`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchStorageLocationV2: build.mutation<
      PatchStorageLocationV2ApiResponse,
      PatchStorageLocationV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v2/storage-location/${queryArg.storageLocationId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, arg) => [
        {type: 'tireWarehouseStorageLocationDetail', id: arg.storageLocationId},
      ],
    }),
  }),
});

export const {
  usePostStorageLocationDeleteApiMutation,
  usePostStorageLocationNoteApiMutation,
  useGetStorageLocationApiQuery,
  usePostStorageLocationAddLocationApiMutation,
  usePostStorageLocationGenerateLocationApiMutation,
  useGetStorageLocationGeneratorSettingApiQuery,
  useGetStorageLocationStatisticsApiQuery,
  useGetStorageLocationByIdApiQuery,
  usePatchStorageLocationApiMutation,
  usePostStorageLocationOccupiedApiMutation,
  usePostStorageLocationReleasedApiMutation,
  usePostStorageLocationAddLocationV2Mutation,
  usePostStorageLocationGenerateLocationV2Mutation,
  usePatchStorageLocationV2Mutation,
} = metadaStorageLocationApi;
