import {Option} from 'platform/components';

import {groupBy} from 'ramda';

import {
  GetApiMakeModelWithMakeApiResponse,
  GetCustomTenantCatalogueApiResponse,
  VehicleTypeEnumObject,
} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {GroupType} from '../types/GroupType';

const DEFAULT_MODEL_GROUP = 'MODEL_DEFAULT';

interface VehicleModelDescriptor {
  /** Finalized data for UI picker */
  groupedModelOptions: GroupType[];
  /** Ungrouped parts */
  detail: {
    /** Only globally recognized models from Alpha catalogue */
    globalModels: Option[];
    /** Only custom models known in this tenant */
    customModels: Option[];
    /** All models combined [global, custom, unknown] */
    flatModels: Option[];
  };
}

interface VehicleModelParserProps {
  vehicleType: VehicleTypeEnumObject | Nullish;
  make: string | Nullish;
  unknownModels?: Option[];
  vehicleModelData: GetApiMakeModelWithMakeApiResponse | Nullish;
  customTenantMakeModelData: GetCustomTenantCatalogueApiResponse | Nullish;
}

export const vehicleModelParser = (props: VehicleModelParserProps): VehicleModelDescriptor => {
  const unknownModels = props.unknownModels || [];

  const makeLabel =
    props.vehicleModelData?.[0]?.default_label || // global make label
    props.make || // custom make label
    i18n.t('entity.vehicle.labels.otherLabel'); // fallback make label

  const customTenantModelSlice =
    props.customTenantMakeModelData?.[props.vehicleType || '']?.makes?.[props.make || '']?.models;

  const customModels = customTenantModelSlice
    ? Object.keys(customTenantModelSlice).map(
        (key) =>
          ({
            label: key,
            value: key,
          }) as Option
      )
    : [];

  const globalModelsRaw = props.vehicleModelData?.[0]?.models || [];
  const globalModels = globalModelsRaw.map(
    (item) =>
      ({
        label: item.default_label,
        value: item.model,
      }) as Option
  );

  const modelsGroupedRaw = groupBy(
    (model) => model.model_group?.group || DEFAULT_MODEL_GROUP,
    globalModelsRaw
  );

  const groupedAllModelOptions: GroupType[] = Object.entries(modelsGroupedRaw).map(
    ([key, value]) => {
      const label = value?.[0]?.model_group?.default_label || makeLabel; // group label

      const optionsRaw =
        value?.map<Option>((item) => ({label: item.default_label, value: item.model})) || [];

      const defaultGroupOptions = [...optionsRaw, ...customModels, ...unknownModels];

      return {
        label,
        options: key === DEFAULT_MODEL_GROUP ? defaultGroupOptions : optionsRaw,
      };
    }
  );

  const flatModels = [...globalModels, ...customModels, ...unknownModels];

  const getGroupedModelOptions = () => {
    if (groupedAllModelOptions.length) {
      return groupedAllModelOptions;
    }

    if (customModels.length || unknownModels.length) {
      return [
        {
          label: makeLabel,
          options: [...customModels, ...unknownModels],
        },
      ];
    }

    return [];
  };

  return {
    groupedModelOptions: getGroupedModelOptions(),
    detail: {
      globalModels,
      customModels,
      flatModels,
    },
  };
};
