import {Form, FormField, FormSubmitHandler} from 'platform/components';
import {Grid} from 'platform/foundation';
import {object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  useCreateSectorMutation,
  useGetCurrentUserInfoQuery,
  useGetSectorQuery,
  useUpdateSectorMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupNumber, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type StorageLocationsForm = {
  branchId: string;
  name: string;
  prefix: string;
  numberOfSpaces: number;
};

export function TireStorageLocationsDetail() {
  const navigate = useNavigate();
  const {id} = useParams();

  const isCreating = isNil(id);

  const {
    data: sectorData,
    isLoading: isSectorLoading,
    isError: isSectorError,
  } = useGetSectorQuery({sectorId: id ?? ''}, {skip: isCreating});
  const {data: currentUser, isLoading: isUserInfoLoading} = useGetCurrentUserInfoQuery();
  const [createSector] = useCreateSectorMutation();
  const [updateSector] = useUpdateSectorMutation();

  const currentUserBranchesOptions = (currentUser?.branches ?? []).map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));

  const handleSubmit: FormSubmitHandler<StorageLocationsForm> = (data) =>
    (isCreating
      ? createSector({createSectorRequestBody: {...data, type: 'TIRE_ORDER'}})
      : updateSector({sectorId: id, updateSectorRequestBody: data})
    )
      .unwrap()
      .then(() => navigate(settingsRoutes.tireStorageLocation))
      .catch(handleApiError);

  return (
    <SettingsTemplate
      header={{
        breadcrumbs: [
          {
            label: i18n.t('entity.tiresInventory.labels.storageLocation'),
            href: settingsRoutes.tireStorageLocation,
          },
        ],
        title: sectorData?.name ?? i18n.t('entity.tiresInventory.labels.newLocation'),
      }}
      isCreating={isCreating}
      isLoading={isSectorLoading}
      isError={isSectorError}
      data-testid={testIds.settings.tireStorageLocation('template')}
    >
      <Form<StorageLocationsForm>
        onSubmit={handleSubmit}
        schema={schema}
        defaultValues={{...sectorData, numberOfSpaces: sectorData?.numberOfParkingSpaces}}
      >
        {(control) => (
          <>
            <SettingsSection>
              <Grid horizontalSpacing={6} verticalSpacing={6} columns={2}>
                <FormField
                  name="name"
                  type="text"
                  control={control}
                  label={i18n.t('general.labels.name')}
                  isRequired
                  data-testid={testIds.settings.tireStorageLocation('name')}
                />
                <FormField
                  name="branchId"
                  type="choice"
                  control={control}
                  label={i18n.t('entity.tiresInventory.labels.branch')}
                  options={currentUserBranchesOptions}
                  isLoading={isUserInfoLoading}
                  isRequired
                  data-testid={testIds.settings.tireStorageLocation('branch')}
                />
                <FormField
                  name="prefix"
                  type="text"
                  control={control}
                  label={i18n.t('entity.tiresInventory.labels.prefix')}
                  isRequired
                  data-testid={testIds.settings.tireStorageLocation('prefix')}
                />
                <FormField
                  name="numberOfSpaces"
                  type="integer"
                  control={control}
                  label={i18n.t('entity.tiresInventory.labels.numberOfSpaces')}
                  isStepperVisible
                  isRequired
                  data-testid={testIds.settings.tireStorageLocation('numberOfSpaces')}
                />
              </Grid>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: isCreating
                    ? i18n.t('general.actions.discard')
                    : i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.tireStorageLocation),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: isCreating
                    ? i18n.t('general.actions.create')
                    : i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  branchId: yupString.required(),
  name: yupString.required(),
  prefix: yupString.required(),
  numberOfSpaces: yupNumber.required(),
});
