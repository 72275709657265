import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetAuthorizationProfilesQuery,
  useGetBranchListQuery,
  useGetSeriesListQuery,
  useGetServiceOrderIssueVariantQuery,
  useGetTemplatesQuery,
  useGetTenantQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {ServiceOrderIssueVariantForm} from './components/ServiceOrderIssueVariantForm';

export function WarehouseServiceOrderIssueVariantsDetail() {
  const params = useParams();

  const {
    data: serviceOrderIssueVariant,
    isLoading: isServiceOrderIssueVariantLoading,
    isError: hasServiceOrderIssueVariantError,
  } = useGetServiceOrderIssueVariantQuery(
    {serviceOrderIssueVariantId: params.id as string},
    {skip: isNilOrEmpty(params.id)}
  );

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: isAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const {
    data: branches,
    isLoading: isBranchesLoading,
    isError: hasBranchesError,
  } = useGetBranchListQuery();

  const {
    data: seriesList,
    isLoading: isSeriesListLoading,
    isError: hasSeriesListError,
  } = useGetSeriesListQuery({type: ['workshop/service_order_issue']});

  const {
    data: issueDocumentTemplates,
    isLoading: isIssueDocumentTemplatesLoading,
    isError: hasIIssueDocumentTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'service-order-issue'});

  const isLoading =
    isServiceOrderIssueVariantLoading ||
    isTenantLoading ||
    isAuthorizationProfilesLoading ||
    isBranchesLoading ||
    isSeriesListLoading ||
    isIssueDocumentTemplatesLoading;

  const isError =
    hasServiceOrderIssueVariantError ||
    hasTenantError ||
    hasAuthorizationProfilesError ||
    hasBranchesError ||
    hasSeriesListError ||
    hasIIssueDocumentTemplatesError;

  const header: SettingsTemplateHeader = {
    title: isNil(params.id)
      ? i18n.t('entity.warehouse.labels.newServiceOrderIssueVariant')
      : (serviceOrderIssueVariant?.name ?? ''),
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.serviceOrderIssueVariants'),
        href: settingsRoutes.warehousesServiceOrderIssueVariants,
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail('template')}
    >
      <ServiceOrderIssueVariantForm
        serviceOrderIssueVariant={serviceOrderIssueVariant}
        authorizationProfiles={authorizationProfiles}
        branches={branches}
        series={seriesList}
        documentTemplates={issueDocumentTemplates}
        data-testid={testIds.settings.warehousesServiceOrderIssueVariantsDetail(
          'serviceOrderIssueVariantForm'
        )}
      />
    </SettingsTemplate>
  );
}
