import {
  Button,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionType,
  showNotification,
} from 'platform/components';
import {HStack, Right, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {pipe, reject} from 'ramda';

import {
  getOptionsFromServiceCaseOrderVariants,
  useGetServiceCaseOrderVariantsQuery,
  usePostServiceOrderChangeVariantMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

interface ChangeOrderVariantFormProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string;
  orderVariant: string | Nullish;
  onClose: () => void;
  onSubmitted: () => void;
}

type ChangeOrderVariantFormValues = {
  orderVariant: string;
};

export function ChangeOrderVariantForm(props: ChangeOrderVariantFormProps) {
  const [postServiceOrderChangeVariant, {isLoading}] = usePostServiceOrderChangeVariantMutation();

  const {data: orderVariants} = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const handleSubmit: FormSubmitHandler<ChangeOrderVariantFormValues> = ({orderVariant}) =>
    postServiceOrderChangeVariant({
      orderVariantId: orderVariant,
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
    })
      .unwrap()
      .then(() => {
        props.onSubmitted();
        props.onClose();
        showNotification.success();
      })
      .catch(handleApiError);

  const orderVariantOptions = pipe(
    getOptionsFromServiceCaseOrderVariants,
    reject<OptionType>((variant) => variant.value === props.orderVariant)
  )(orderVariants);

  return (
    <Form<ChangeOrderVariantFormValues>
      onSubmit={handleSubmit}
      schema={ChangeOrderVariantFormSchema}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="orderVariant"
            type="choice"
            options={orderVariantOptions}
            label={i18n.t('entity.order.labels.orderVariant')}
            placeholder={i18n.t('general.labels.select')}
            menuInPortal
            data-testid={suffixTestId('orderVariant', props)}
          />
          <Right>
            <HStack spacing={2}>
              <Button
                onClick={props.onClose}
                title={i18n.t('general.actions.cancel')}
                variant="secondary"
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.change')}
                isLoading={isLoading}
                data-testid={suffixTestId('change', props)}
              />
            </HStack>
          </Right>
        </VStack>
      )}
    </Form>
  );
}

const ChangeOrderVariantFormSchema = Yup.object({
  orderVariant: Yup.string().required(),
});
