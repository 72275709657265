import {Form, FormButton, FormField, FormSubmitHandler, OptionType} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';
import {object} from 'yup';

import {isNilOrEmpty} from 'ramda-adjunct';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {suffixTestId, yupString, TestIdProps} from 'shared';

export type CommentCardForm = {
  comment: string;
};

export interface CommentsCardInputProps extends TestIdProps {
  isSendCommentLoading?: boolean;
  onSubmit: FormSubmitHandler<CommentCardForm>;
  userOptions?: OptionType[];
}

export function CommentsCardInput(props: CommentsCardInputProps) {
  const defaultValues = {comment: ''};

  return (
    <Form
      schema={schema}
      onSubmit={props.onSubmit}
      defaultValues={defaultValues}
      data-testid={suffixTestId('comments', props)}
    >
      {(control, {watch}) => (
        <HStack spacing={4} align="center">
          <Box flex={1}>
            <Show whenFeatureEnabled={featureFlags.NOTIFICATIONS_COMMENT_MENTION}>
              <FormField
                control={control}
                name="comment"
                type="mentionsInput"
                options={props.userOptions}
                placeholder={i18n.t('page.comments.actions.writeAComment')}
                data-testid={suffixTestId('comments-formInput', props)}
              />
            </Show>
            <Show whenFeatureDisabled={featureFlags.NOTIFICATIONS_COMMENT_MENTION}>
              <FormField
                control={control}
                name="comment"
                type="textarea"
                placeholder={i18n.t('page.comments.actions.writeAComment')}
                data-testid={suffixTestId('comments-formInput', props)}
              />
            </Show>
          </Box>
          <FormButton
            control={control}
            type="submit"
            variant="primary"
            isLoading={props.isSendCommentLoading}
            isDisabled={isNilOrEmpty(watch('comment'))}
            data-testid={suffixTestId('comments-formSubmit', props)}
            title={i18n.t('general.actions.send')}
          />
        </HStack>
      )}
    </Form>
  );
}

const schema = object({
  comment: yupString,
});
