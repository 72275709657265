import {Box, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {MenuItemProps, useGetUserMenuPinsQuery} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {CommonSkeleton} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {Sidebar} from './components/Sidebar';
import {SidebarMenu} from './components/SidebarMenu';
import {Topbar} from './components/Topbar/Topbar';
import {addPinsToMenuItems} from './utils/addPinsToMenuItems';
import {isMenuItemActive} from './utils/isMenuItemActive';
import {transformPinnedMenuItems} from './utils/transformPinnedMenuItems';

interface LayoutProps extends TestIdProps {
  menuItems: MenuItemProps[];
  menuItemsById: Record<string, MenuItemProps>;
  createMenuItems: MenuItemProps[];
}

export function LayoutV2(props: LayoutProps) {
  const {data: pinnedMenuItems = [], isLoading} = useGetUserMenuPinsQuery();
  const location = useLocation();

  const settingsModule = props.menuItems.find((item) => item.id === moduleKeys.settings);
  const activeModule = useMemo(
    () => props.menuItems.find((menuItem) => isMenuItemActive(location.pathname, menuItem.path)),
    [location.pathname, props.menuItems]
  );
  const filteredMenuItems = props.menuItems.filter(
    (menuItem) =>
      // Setting have been moved to the top bar.
      menuItem.id !== moduleKeys.settings
  );

  const menuItemsWithPins = addPinsToMenuItems(filteredMenuItems, pinnedMenuItems);
  const sidebarPinnedMenuItems = transformPinnedMenuItems(pinnedMenuItems, props.menuItemsById);

  if (isLoading) {
    return <CommonSkeleton />;
  }

  return (
    <StyledLayout>
      <StyledGridItem rowSpan={2}>
        <Box
          height="100%"
          contain="layout"
          zIndex="MAIN_MENU"
          position="relative"
          backgroundColor="palettes.neutral.900.100"
          data-testid={suffixTestId('menuWrapper', props)}
        >
          <VStack height="100%">
            <SidebarMenu menuItemsWithPins={menuItemsWithPins} data-testid={props['data-testid']} />
            <Sidebar
              menuItemsById={props.menuItemsById}
              createMenuItems={props.createMenuItems}
              pinnedMenuItems={sidebarPinnedMenuItems}
              data-testid={props['data-testid']}
            />
          </VStack>
        </Box>
      </StyledGridItem>
      <Box
        zIndex="HEADER"
        contain="layout"
        backgroundColor="general.white"
        borderColor="general.separator"
        borderBottom="1px solid"
        data-testid={suffixTestId('headerWrapper', props)}
      >
        <Topbar settingsModule={settingsModule} activeModule={activeModule} data-testid="header" />
      </Box>
      <Box
        overflowY="auto"
        backgroundColor="palettes.neutral.10.100"
        data-testid={suffixTestId('layout-content', props)}
      >
        <Outlet />
      </Box>
    </StyledLayout>
  );
}

const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({theme}) => theme.getSize(12)} 1fr;
  grid-template-rows: ${({theme}) => theme.getSize(12)} 1fr;
`;

const StyledGridItem = styled.div<{colSpan?: number; rowSpan?: number}>`
  grid-column-end: span ${({colSpan}) => colSpan ?? 1};
  grid-row-end: span ${({rowSpan}) => rowSpan ?? 1};
`;
