import {differenceInDays, startOfDay} from 'date-fns';
import {AppLanguage} from 'platform/locale';
import {match, Pattern} from 'ts-pattern';

import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

export function formatDueDateToNaturalLanguage(date: Date, locale: AppLanguage) {
  const normalizedDate = startOfDay(date);
  const today = startOfDay(new Date());

  const differenceDays = differenceInDays(normalizedDate, today);

  return match({differenceDays, locale})
    .returnType<ReactElement | null>()
    .with({differenceDays: Pattern.number.lt(0)}, () => (
      <Trans i18nKey="entity.task.notifications.hasPassed" components={{bold: <strong />}} />
    ))
    .with({differenceDays: 0}, () => (
      <Trans i18nKey="entity.task.notifications.dueToday" components={{bold: <strong />}} />
    ))
    .with({differenceDays: 1}, () => (
      <Trans i18nKey="entity.task.notifications.dueTomorrow" components={{bold: <strong />}} />
    ))
    .with(
      {locale: 'cs', differenceDays: Pattern.number.gt(1).and(Pattern.number.lt(5))},
      ({differenceDays}) => (
        <Trans
          i18nKey="entity.task.notifications.dueInAlt"
          components={{bold: <strong />}}
          values={{n: differenceDays}}
        />
      )
    )
    .with({differenceDays: Pattern.number.gt(1)}, ({differenceDays}) => (
      <Trans
        i18nKey="entity.task.notifications.dueIn"
        values={{n: differenceDays}}
        components={{bold: <strong />}}
      />
    ))
    .otherwise(() => null);
}
