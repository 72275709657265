import {Button, FormControl, FormField, IconButton, Option, Tooltip} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form';

import {defaultTo, isEmpty, last, length, pipe, isNotEmpty} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {DiscountWorkRate} from '@dms/api';
import i18n from '@dms/i18n';

import {CurrencyCodeType, getRandomId, sanitizeObject, suffixTestId, TestIdProps} from 'shared';

type WorkRateListFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  formApi: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  categoryOptions: Option[];
  isDisabled: boolean;
  currency: CurrencyCodeType;
} & TestIdProps;
export function DiscountsTabWorkRateListField<TFieldValues extends FieldValues = FieldValues>(
  props: WorkRateListFieldProps<TFieldValues>
) {
  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: props.name as ArrayPath<TFieldValues>,
  });

  const defaultFields = isNotEmpty(fields) ? fields : [{}];
  const discountValueFields = props.formApi.watch(
    'workDiscount.discountValue' as Path<TFieldValues>
  ) ?? [{}];

  const isRemoveButtonDisabled = length(defaultFields) > 1;
  const isLastDiscountValueNil = pipe<[object[]], object, object, object, boolean>(
    last,
    defaultTo({}),
    sanitizeObject,
    isEmpty
  )(discountValueFields);

  const uniqueOptions =
    discountValueFields && isArray(discountValueFields)
      ? props.categoryOptions.map((categoryOption) => ({
          ...categoryOption,
          isDisabled: !!discountValueFields?.some((discountValue) =>
            'workType' in discountValue ? discountValue.workType === categoryOption.value : false
          ),
        }))
      : [];

  return (
    <>
      {defaultFields.map((field, index) => (
        <Grid key={getRandomId()} columns={4}>
          <FormField
            label={index === 0 ? i18n.t('general.actions.workType') : undefined}
            control={props.control}
            name={`workDiscount.discountValue.${index}.workType` as Path<TFieldValues>}
            type="choice"
            isNotClearable
            options={uniqueOptions}
            isDisabled={props.isDisabled}
            data-testid={suffixTestId(`workType-[${index}]`, props)}
          />
          <Tooltip
            label={i18n.t('entity.warehouse.labels.firstlyFulfillWorkTypeFiled')}
            isDisabled={isNotNilOrEmpty((discountValueFields[index] as DiscountWorkRate)?.workType)}
          >
            <FormField
              label={
                index === 0
                  ? `${i18n.t('general.actions.workRate')} ${i18n.t('general.labels.w/oVat')}`
                  : undefined
              }
              control={props.control}
              name={
                `workDiscount.discountValue.${index}.workRateWithoutVat.amount` as Path<TFieldValues>
              }
              type="currency"
              currency={props.currency}
              decimalPlaces={2}
              isDisabled={
                props.isDisabled ||
                isNilOrEmpty((discountValueFields[index] as DiscountWorkRate)?.workType)
              }
              data-testid={suffixTestId(`workRateWithoutVat-[${index}]`, props)}
            />
          </Tooltip>
          <Show when={isRemoveButtonDisabled}>
            <VStack justify="flex-end">
              <IconButton
                icon="navigation/close"
                severity="danger"
                onClick={() => remove(index)}
                isDisabled={props.isDisabled}
                data-testid={suffixTestId(`phoneNumberRemove-[${index}]`, props)}
              />
            </VStack>
          </Show>
        </Grid>
      ))}
      <Grid columns={8}>
        <Tooltip
          isDisabled={!isLastDiscountValueNil || props.isDisabled}
          description={i18n.t('entity.warehouse.labels.firstlyFulfillWorkRateInLastField')}
        >
          <Button
            isDisabled={isLastDiscountValueNil || props.isDisabled}
            leftIcon="content/add_circle"
            title={i18n.t('general.actions.addWorkType')}
            size="small"
            variant="link"
            onClick={() => append({} as FieldArray<TFieldValues, ArrayPath<TFieldValues>>)}
            data-testid={suffixTestId('phoneNumberAddAnother', props)}
          />
        </Tooltip>
      </Grid>
    </>
  );
}
