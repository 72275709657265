import {isFeatureEnabled} from 'feature-flags';
import {Button, FormButton, FormControl, openDialog} from 'platform/components';
import {Box, Hide, HStack, Icon, Right, Show, Spinner, Text, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {any, mergeDeepRight, not, values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CustomerAdvancedSearchApiArg,
  CustomerResponseBodyV2,
  useCustGetExternalCustomersQuery,
  useCustomerAdvancedSearchQuery,
  useGetAutostacjaSvConnectorConfigQuery,
  useGetVehicleQuery,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps, useDebouncedValue, useQueryState} from 'shared';

import {useCustomersMatch} from '../hooks/useCustomersMatch';
import {CreateCustomerFormType} from '../types/CreateCustomerFormType';
import {ConfirmCustomerCreation} from './ConfirmCustomerCreation';
import {MatchingCustomer} from './MatchingCustomer';
import {MatchingExternalCustomer, OnExternalCustomerSelectData} from './MatchingExternalCustomer';

const LIMIT_STEP = 4;

export interface MatchingCustomersProps extends TestIdProps {
  control: FormControl<CreateCustomerFormType>;
  similarCustomersArg: CustomerAdvancedSearchApiArg;
  onCustomerSelect: (customer: CustomerResponseBodyV2) => void;
  onExternalCustomerSelect: (customer: OnExternalCustomerSelectData) => void;
  vehicleId?: string | Nullish;
}

const DEBOUNCE_TIMEOUT = 700;

export function MatchingCustomers(props: MatchingCustomersProps) {
  const [similarCustomersApiArg, setApiArg] = useState(props.similarCustomersArg);
  const [limit, setLimit] = useState(LIMIT_STEP);
  const [externalLimit, setExternalLimit] = useState(LIMIT_STEP);

  const debouncedArg = useDebouncedValue(similarCustomersApiArg, DEBOUNCE_TIMEOUT);

  const [searchParamVehicleId] = useQueryState('vehicleId');

  const {data: autostacjaSettingsData} = useGetAutostacjaSvConnectorConfigQuery(undefined, {
    skip: not(isFeatureEnabled(featureFlags.AUTOSTACJA_CUSTOMER_WIDGET)),
  });
  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: searchParamVehicleId || props.vehicleId!},
    {skip: isNilOrEmpty(searchParamVehicleId) && isNilOrEmpty(props.vehicleId)}
  );

  const {
    data: similarCustomers,
    isLoading,
    isUninitialized,
    isFetching,
  } = useCustomerAdvancedSearchQuery(debouncedArg, {
    refetchOnMountOrArgChange: true,
    skip: not(any(isNotNilOrEmpty, values(debouncedArg))),
  });

  const {
    data: externalSimilarCustomers,
    isLoading: isExternalQueryLoading,
    isFetching: isExternalQueryFetching,
  } = useCustGetExternalCustomersQuery(
    {
      ...debouncedArg,
      ...(vehicleData?.make &&
        vehicleData.type && {
          make: vehicleData.make,
          vehicleType: vehicleData.type,
        }),
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        vehicleData?.make && vehicleData.type
          ? not(any(isNotNilOrEmpty, values(debouncedArg)))
          : (not(isFeatureEnabled(featureFlags.AUTOSTACJA_CUSTOMER_WIDGET)) &&
              not(autostacjaSettingsData?.enabled)) ||
            not(any(isNotNilOrEmpty, values(debouncedArg))),
    }
  );

  const limitedSimilarCustomers = similarCustomers?.slice(0, limit) ?? [];
  const viewMoreCount = (similarCustomers?.length ?? 0) - (limitedSimilarCustomers?.length ?? 0);

  const limitedExternalSimilarCustomers = externalSimilarCustomers?.slice(0, externalLimit) ?? [];
  const viewMoreExternalCount =
    (externalSimilarCustomers?.length ?? 0) - (limitedExternalSimilarCustomers?.length ?? 0);

  const {customersWithMatchProps, hasAnyCustomerFullMatch, hasLegalNumberFullMatch} =
    useCustomersMatch(limitedSimilarCustomers, debouncedArg);

  const handleOpenDialog = () =>
    openDialog(
      <ConfirmCustomerCreation
        control={props.control}
        data-testid={suffixTestId('createNewCustomer', props)}
      />,
      {
        size: 'small',
      }
    );

  useEffect(
    () => setApiArg((arg) => mergeDeepRight(arg, props.similarCustomersArg)),
    [props.similarCustomersArg]
  );

  const areQueriesLoading =
    isLoading || isFetching || isExternalQueryLoading || isExternalQueryFetching;

  if (isUninitialized) {
    return (
      <Right>
        <FormButton
          control={props.control}
          type="submit"
          variant="primary"
          title={i18n.t('entity.customer.actions.createNewCustomer')}
          data-testid={suffixTestId('createCustomer', props)}
        />
      </Right>
    );
  }

  return (
    <>
      <Show
        when={
          (limitedSimilarCustomers && limitedSimilarCustomers.length > 0) ||
          (limitedExternalSimilarCustomers && limitedExternalSimilarCustomers.length > 0)
        }
      >
        <VStack>
          <HStack align="center" justify="space-between">
            <HStack spacing={1}>
              <Show when={similarCustomers?.length}>
                <Text size="xSmall" color="secondary">
                  {`${i18n.t('entity.customer.labels.matchingCustomers')} (${
                    similarCustomers?.length
                  })`}
                </Text>
                <Text size="xSmall" color="secondary">
                  •
                </Text>
              </Show>
              <Show
                when={externalSimilarCustomers?.length}
                whenFeatureEnabled={featureFlags.AUTOSTACJA_CUSTOMER_WIDGET}
              >
                <Text size="xSmall" color="link">
                  {`${i18n.t('entity.customer.labels.externalMatchingCustomers')} (${externalSimilarCustomers?.length})`}
                </Text>
              </Show>
            </HStack>
            <Show when={areQueriesLoading}>
              <Spinner size="small" />
            </Show>
          </HStack>
          {customersWithMatchProps?.map((customer, index) =>
            customer.hasMatchInContacts ? (
              customer.matchedContacts.map((contact) => (
                <MatchingCustomer
                  key={customer.id}
                  onSelect={props.onCustomerSelect}
                  customer={customer}
                  contact={contact}
                  searchArg={debouncedArg}
                  data-testid={suffixTestId(`matching-[${index}]`, props)}
                  matchProps={contact.matchProps}
                />
              ))
            ) : (
              <MatchingCustomer
                key={customer.id}
                onSelect={props.onCustomerSelect}
                customer={customer}
                searchArg={debouncedArg}
                data-testid={suffixTestId(`matching-[${index}]`, props)}
                matchProps={customer.matchProps}
              />
            )
          )}
          <Show when={similarCustomers && similarCustomers.length > limit}>
            <Button
              isFullWidth
              title={i18n.t('entity.customer.actions.moreWithCount', {count: viewMoreCount})}
              variant="ghostLink"
              leftIcon="navigation/expand_more"
              onClick={() => setLimit(similarCustomers?.length ?? LIMIT_STEP)}
              data-testid={suffixTestId('moreCustomersButton', props)}
            />
          </Show>
          <Show when={limitedSimilarCustomers && limitedSimilarCustomers.length > LIMIT_STEP}>
            <Button
              isFullWidth
              title={i18n.t('entity.customer.actions.less')}
              variant="ghostLink"
              leftIcon="navigation/expand_less"
              onClick={() => setLimit(LIMIT_STEP)}
              data-testid={suffixTestId('lessCustomersButton', props)}
            />
          </Show>
        </VStack>
      </Show>
      <Show when={!similarCustomers?.length}>
        <HStack minHeight={44} spacing={4} align="center" justify="center">
          <Icon color="text.tertiary" value="communication/person_add_disabled" />
          <Text size="small" color="tertiary" data-testid={suffixTestId('empty', props)}>
            {i18n.t('entity.customer.labels.noResults')}
          </Text>
          <Box width={4} height={4}>
            <Show when={isLoading || isFetching}>
              <Spinner size="small" />
            </Show>
          </Box>
        </HStack>
      </Show>
      <Show
        when={externalSimilarCustomers && externalSimilarCustomers.length > 0}
        whenFeatureEnabled={featureFlags.AUTOSTACJA_CUSTOMER_WIDGET}
      >
        <VStack>
          <VStack spacing={1}>
            <Text size="xSmall" alternative>
              {`${i18n.t('entity.customer.labels.externalMatchingCustomers')} (${externalSimilarCustomers?.length})`}
            </Text>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.customer.labels.externalMatchingCustomersNote')}
            </Text>
          </VStack>
          {limitedExternalSimilarCustomers?.map((customer, index) =>
            customer ? (
              <MatchingExternalCustomer
                key={customer.externalId || index}
                customer={customer}
                onSelect={props.onExternalCustomerSelect}
              />
            ) : null
          )}
          <Show when={externalSimilarCustomers && externalSimilarCustomers.length > externalLimit}>
            <Button
              isFullWidth
              title={i18n.t('entity.customer.actions.moreWithCount', {
                count: viewMoreExternalCount,
              })}
              variant="ghostLink"
              leftIcon="navigation/expand_more"
              onClick={() => setExternalLimit(externalSimilarCustomers?.length ?? LIMIT_STEP)}
              data-testid={suffixTestId('moreExternalCustomersButton', props)}
            />
          </Show>
          <Show
            when={
              limitedExternalSimilarCustomers && limitedExternalSimilarCustomers.length > LIMIT_STEP
            }
          >
            <Button
              isFullWidth
              title={i18n.t('entity.customer.actions.less')}
              variant="ghostLink"
              leftIcon="navigation/expand_less"
              onClick={() => setExternalLimit(LIMIT_STEP)}
              data-testid={suffixTestId('lessExternalCustomersButton', props)}
            />
          </Show>
        </VStack>
      </Show>
      <Right>
        <Hide when={hasAnyCustomerFullMatch || hasLegalNumberFullMatch}>
          <FormButton
            control={props.control}
            type="submit"
            variant={similarCustomers?.length ? 'secondary' : 'primary'}
            title={i18n.t('entity.customer.actions.createNewCustomer')}
            data-testid={suffixTestId('createCustomer', props)}
          />
        </Hide>
        <Show when={hasAnyCustomerFullMatch || hasLegalNumberFullMatch}>
          <FormButton
            control={props.control}
            onClick={handleOpenDialog}
            variant={similarCustomers?.length ? 'secondary' : 'primary'}
            title={i18n.t('entity.customer.actions.createNewCustomer')}
            data-testid={suffixTestId('createCustomer', props)}
          />
        </Show>
      </Right>
    </>
  );
}
