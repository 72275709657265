import {VStack} from 'platform/foundation';

import {FC, PropsWithChildren} from 'react';

import {DeprecatedProfileLayout} from '@dms/my-profile';
import {testIds} from '@dms/routes';

import {AutoLogout} from './components/AutoLogout';
import {MyProfilePassword} from './components/MyProfilePassword';

export const Security: FC<PropsWithChildren<any>> = () => (
  <DeprecatedProfileLayout data-testid={testIds.myProfile.security('wrapper')}>
    <VStack>
      <MyProfilePassword />
      <AutoLogout />
    </VStack>
  </DeprecatedProfileLayout>
);
