import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Hide, VStack} from 'platform/foundation';
import {Pattern, match} from 'ts-pattern';
import {boolean, object, string} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {always, isNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {
  useGetNegativeDocumentCancellationQuery,
  usePostNegativeDocumentCancellationMutation,
  useUpdateNegativeDocumentCancellationMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useBranches} from '@dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accounting.labels.invoiceCanceling'),
    href: settingsRoutes.invoiceCanceling,
  },
];

// taken from https://www.figma.com/file/8IMrd0hS8wf2FMxGQtKkFI/Admin-settings?type=design&node-id=6234-213638&t=40CZEUpf9A9drviA-4
const NEW_DEFINITION_INPUT_WIDTH = 98;

type NewCancelingFormValues = {
  isActive: boolean;
  branchId: string;
};

export function CreateOrEditInvoiceCanceling() {
  const navigate = useNavigate();
  const {id} = useParams();
  const isCreating = isNil(id);

  const {
    data: branches,
    branchOptions,
    isLoading: loadingBranches,
    isError: isBranchesError,
  } = useBranches();

  const {
    data: definition,
    isLoading: isLoadingDefinition,
    isError: isDefinitionError,
  } = useGetNegativeDocumentCancellationQuery({id: id ?? ''}, {skip: isCreating});

  const isSystem = isTrue(definition?.isSystem);

  const [updateDefinition] = useUpdateNegativeDocumentCancellationMutation();
  const [createDefinition] = usePostNegativeDocumentCancellationMutation();

  const handleOnSubmit: FormSubmitHandler<NewCancelingFormValues> = async (data) =>
    await (isCreating ? createDefinition : updateDefinition)({...data, id: id ?? ''})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.invoiceCanceling))
      .catch(handleApiError);

  const isLoading = loadingBranches || isLoadingDefinition;
  const isError = isBranchesError || isDefinitionError;

  const pageTitle = match([isSystem, isCreating])
    .with([true, Pattern.any], always(i18n.t('page.accounting.labels.tenantDefinition')))
    .with([Pattern.any, true], always(i18n.t('page.accounting.labels.newDefinition')))
    .otherwise(
      () =>
        branches?.branchListItems.find((branch) => branch.id === definition?.branchId)
          ?.marketingName ?? ''
    );

  return (
    <SettingsTemplate
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.newInvoiceCanceling('header')}
      header={{title: pageTitle, breadcrumbs}}
      isCreating={isCreating}
    >
      <Form<NewCancelingFormValues>
        onSubmit={handleOnSubmit}
        schema={schema(isSystem)}
        defaultValues={definition}
      >
        {(control) => (
          <>
            <VStack spacing={6}>
              <FormField
                control={control}
                name="isActive"
                type="switch"
                label={i18n.t('entity.cashRegister.state.active')}
                data-testid={testIds.settings.invoiceCancelingDetail('isActive')}
              />
              <Hide when={isSystem}>
                <Box width={NEW_DEFINITION_INPUT_WIDTH}>
                  <FormField
                    control={control}
                    name="branchId"
                    type="choice"
                    isNotClearable
                    data-testid={testIds.settings.invoiceCancelingDetail('branchId')}
                    options={branchOptions}
                    label={i18n.t('entity.branch.labels.branch')}
                  />
                </Box>
              </Hide>
            </VStack>

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: i18n.t('general.actions.discard'),
                  onClick: () => navigate(settingsRoutes.invoiceCanceling),
                  'data-testid': testIds.settings.invoiceCancelingDetail('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  title: isCreating
                    ? i18n.t('general.actions.create')
                    : i18n.t('general.actions.save'),
                  buttonType: 'submit',
                  'data-testid': testIds.settings.invoiceCancelingDetail('save'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = (isSystem: boolean) =>
  object({
    isActive: boolean().nullable().default(false),
    branchId: string().when('isActive', {
      is: () => isSystem === true,
      then: (s) => s.nullable(),
      otherwise: (s) => s.required().nullable(),
    }),
  });
