import {getWorkspaceFromUri, useWorkspaceInfoQuery} from '@dms/api';

export function useCurrentWorkspace() {
  const {workspace} = getWorkspaceFromUri();
  const {data} = useWorkspaceInfoQuery({workspace: workspace || ''}, {skip: !workspace});

  if (!workspace) {
    throw new Error('Workspace is not specified in url.');
  }

  return data;
}
