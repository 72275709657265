import {DataStatus} from 'platform/components';

import {useGetStorageLocationGeneratorSettingApiQuery} from '@dms/api';

import {SymbolType, VectorType} from '../types/MultipleLocationsForm';
import {locationRowFactory} from '../utils/locationRowFactory';
import {CreateMultipleLocationsForm} from './CreateMultipleLocationsForm';

interface CreateMultipleLocationsProps {
  tireWarehouseId: string;
}

export function CreateMultipleLocations(props: CreateMultipleLocationsProps) {
  const lastMultipleQuery = useGetStorageLocationGeneratorSettingApiQuery({
    contextTarget: 'TIRE-WAREHOUSE',
    contextId: props.tireWarehouseId,
  });

  return (
    <DataStatus isLoading={lastMultipleQuery.isLoading}>
      <CreateMultipleLocationsForm
        tireWarehouseId={props.tireWarehouseId}
        rowDefinition={lastMultipleQuery.data?.map((item) =>
          locationRowFactory({
            isSelected: item?.isSelected,
            beginAt: item?.beginAt || '',
            number: item?.number || 0,
            symbolType: item?.symbolType as SymbolType,
            vectorType: item?.vectorType as VectorType,
            vectorPosition: item?.vectorPosition,
          })
        )}
      />
    </DataStatus>
  );
}
