import {Button, ButtonGroup} from 'platform/components';
import {Center, Show, Space} from 'platform/foundation';

import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {MenuItemProps, useSetUserMenuPinsMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {SortableSidebarItems} from './SortableSidebarItems';

interface SidebarEditProps extends TestIdProps {
  menuItemsById: Record<string, MenuItemProps>;
  pinnedMenuItems: string[];
  onSave: () => void;
  onCancel: () => void;
  onSettled: (error?: unknown) => void;
}

export function SidebarEdit(props: SidebarEditProps) {
  const {t} = useTranslation();
  const [setMenuPins, setMenuPinsState] = useSetUserMenuPinsMutation();
  const [menuItems, setMenuItems] = useState(
    props.pinnedMenuItems.map((item) => ({id: item, isPinned: true}))
  );

  const handleOnSave = () => {
    props.onSave();

    const pins = menuItems.filter((item) => item.isPinned).map((item) => ({item: item.id}));
    setMenuPins({items: pins})
      .unwrap()
      .then(() => props.onSettled())
      .catch((error) => {
        handleApiError(error);
        props.onSettled(error);
      });
  };

  return (
    <>
      <Show when={menuItems.length === 0}>
        <Center>{t('entity.user.labels.sidebarSettingsEmpty')}</Center>
      </Show>
      <SortableSidebarItems
        menuItems={menuItems}
        menuItemsById={props.menuItemsById}
        onChange={setMenuItems}
        data-testid={suffixTestId('sortableSidebarItems', props)}
        areActionsDisabled={setMenuPinsState.isLoading}
      />
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          title={i18n.t('general.actions.cancel')}
          onClick={props.onCancel}
          isDisabled={setMenuPinsState.isLoading}
          data-testid={suffixTestId('cancelButton', props)}
        />
        <Button
          title={i18n.t('general.actions.save')}
          isLoading={setMenuPinsState.isLoading}
          onClick={handleOnSave}
          data-testid={suffixTestId('saveButton', props)}
        />
      </ButtonGroup>
    </>
  );
}
