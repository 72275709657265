import {FormControl, FormField} from 'platform/components';

import {useEffect} from 'react';
import {useController} from 'react-hook-form';

import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {GeneralSettingsFormValues} from '../types/GeneralSettingsFormValues';

interface DescriptionFormFieldProps extends TestIdProps {
  control: FormControl<GeneralSettingsFormValues>;
  descriptionLabel: string;
  onDescriptionChange?: (description: string | null) => void;
  prefilledNote?: string | Nullish;
  isDisabled?: boolean;
}

export function DescriptionFormField(props: DescriptionFormFieldProps) {
  const {field} = useController({name: 'description', control: props.control});

  useEffect(() => {
    // we use onChange from useController instead of setValue from formApi, because of unsaved form - setValue didn't set field isDirty true
    if (props.prefilledNote) {
      field.onChange(props.prefilledNote);
    }
  }, [props.prefilledNote]);

  return (
    <FormField
      control={props.control}
      label={props.descriptionLabel}
      placeholder={i18n.t('page.advertisement.labels.descriptionPlaceholder')}
      name="description"
      type="textarea"
      isDisabled={props.isDisabled}
      onChange={props.onDescriptionChange}
      minRows={2}
      maxRows={15}
      data-testid={suffixTestId('description', props)}
    />
  );
}
