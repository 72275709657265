import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsStackItems} from '../../components/SettingsStackItems/SettingsStackItems';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const items = [
  {
    cyId: testIds.settings.interestDefinition('link'),
    title: i18n.t('entity.seriesDefinition.labels.interestCodeDefinition'),
    url: settingsRoutes.interestDefinition,
  },
];

export function Interest() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.interest.labels.interest')}}
      data-testid="integrations-business-case-settings-modal"
    >
      <SettingsStackItems items={items} />
    </SettingsTemplate>
  );
}
