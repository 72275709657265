import {Button, ButtonGroup, Separator, showNotification} from 'platform/components';

import {FC, useCallback} from 'react';
import {FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  useRouter,
  updateVehicleSeriesDefinition,
  updateVehicleSeriesSystemDefinition,
  selectVehicleSeriesDefinitionDetail,
  useApiDispatch,
} from '@dms/teas';

import {VehicleSeriesDefinitionFormType} from '../types';
import {VehicleSeriesDefinitionFormDefaultLayout} from './VehicleSeriesDefinitionFormDefaultLayout';
import {VehicleSeriesDefinitionFormWithLists} from './VehicleSeriesDefinitionFormWithLists';

export const VehicleSeriesDefinitionEdit: FC = () => {
  const apiDispatch = useApiDispatch();
  const router = useRouter();
  const definition = useSelector(selectVehicleSeriesDefinitionDetail);

  const handleSubmit = useCallback(
    async (values: VehicleSeriesDefinitionFormType): Promise<void> => {
      if (!definition) {
        return;
      }

      try {
        if (definition.isSystem) {
          await apiDispatch(updateVehicleSeriesSystemDefinition.action, {
            id: definition.id,
            requestBody: {
              name: values.name,
            },
          });
        } else {
          await apiDispatch(updateVehicleSeriesDefinition.action, {
            id: definition.id,
            requestBody: values,
          });
        }

        router.push(settingsRoutes.vehicleSeriesDefinition);
        showNotification.success(
          i18n.t('page.vehicleSeriesDefinition.notifications.definitionUpdated')
        );
      } catch (error: any) {
        throw error.validationErrors;
      }
    },
    [router, apiDispatch, definition]
  );

  return (
    <VehicleSeriesDefinitionFormDefaultLayout>
      <VehicleSeriesDefinitionFormWithLists
        onSubmit={handleSubmit}
        initialValues={definition}
        isSystem={definition?.isSystem}
      >
        <Separator />
        <FormSpy
          subscription={{submitting: true}}
          render={({form, submitting}) => (
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={() => router.push(settingsRoutes.vehicleSeriesDefinition)}
                data-testid={testIds.settings.vehicleEditSeriesDefinition('cancel')}
                title={i18n.t('general.actions.cancel')}
              />
              <Button
                onClick={() => form.submit()}
                isLoading={submitting}
                data-testid={testIds.settings.vehicleEditSeriesDefinition('update')}
                title={i18n.t('general.actions.update')}
              />
            </ButtonGroup>
          )}
        />
      </VehicleSeriesDefinitionFormWithLists>
    </VehicleSeriesDefinitionFormDefaultLayout>
  );
};
