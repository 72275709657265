import {
  Alert,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Show, Space, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {isNil, isNotNil} from 'ramda';

import {
  GetAuthorizationProfilesResponse,
  GetBranchListApiResponse,
  GetSeriesListApiResponse,
  GetServiceOrderIssueVariantResponse,
  GetTemplatesApiResponse,
  usePatchServiceOrderIssueVariantMutation,
  usePostServiceOrderIssueVariantMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {
  getOptionsFromAuthorizationProfiles,
  getOptionsFromBranches,
  handleApiError,
  useDuplicateErrorHandler,
} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {getOptionsFromSeriesList} from '../../../utils/getOptionsFromSeriesList';
import {getOptionsFromTemplates} from '../../../utils/getOptionsFromTemplates';
import {ServiceOrderIssueVariantFormType} from '../types/ServiceOrderIssueVariantFormType';

export interface ServiceOrderIssueVariantFormProps extends RequiredTestIdProps {
  serviceOrderIssueVariant: GetServiceOrderIssueVariantResponse | Nullish;
  authorizationProfiles: GetAuthorizationProfilesResponse[] | Nullish;
  branches: GetBranchListApiResponse | Nullish;
  series: GetSeriesListApiResponse | Nullish;
  documentTemplates: GetTemplatesApiResponse | Nullish;
}

export function ServiceOrderIssueVariantForm(props: ServiceOrderIssueVariantFormProps) {
  const navigate = useNavigate();

  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [postServiceOrderIssueVariant] = usePostServiceOrderIssueVariantMutation();
  const [patchServiceOrderIssueVariant] = usePatchServiceOrderIssueVariantMutation();

  const defaultValues: Partial<ServiceOrderIssueVariantFormType> = {
    name: props.serviceOrderIssueVariant?.name,
    branchId: props.serviceOrderIssueVariant?.branchId,
    authorizationProfileId: props.serviceOrderIssueVariant?.authorizationProfileId,
    docSeriesId: props.serviceOrderIssueVariant?.docSeriesId,
    documentTemplateId: props.serviceOrderIssueVariant?.documentTemplateId,
    isDefault: props.serviceOrderIssueVariant?.isDefault,
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesServiceOrderIssueVariants);
  };

  const handleSubmit: FormSubmitHandler<ServiceOrderIssueVariantFormType> = async (data) => {
    if (isNotNil(props.serviceOrderIssueVariant)) {
      return await patchServiceOrderIssueVariant({
        serviceOrderIssueVariantId: props.serviceOrderIssueVariant.serviceOrderIssueVariantId,
        body: data,
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
        )
        .then(handleNavigateBack)
        .catch(handleApiError);
    }

    await postServiceOrderIssueVariant(data)
      .unwrap()
      .then(() => {
        showNotification.success(
          i18n.t('entity.warehouse.notifications.serviceOrderIssueVariantCreated')
        );
      })
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  return (
    <Form<ServiceOrderIssueVariantFormType>
      schema={formSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      isFullHeight
    >
      {(control, formApi) => {
        const handleAuthorizationProfileChange = (
          authorizationProfileId: string | string[] | number | null
        ) => {
          if (isNil(authorizationProfileId)) {
            return;
          }

          const branchId = props.authorizationProfiles?.find(
            (authorizationProfile) => authorizationProfile.id === authorizationProfileId
          )?.branchId;

          formApi.setValue('branchId', branchId as string, {shouldValidate: true});
        };

        return (
          <VStack spacing={4} data-testid={props['data-testid']}>
            <Show when={duplicateError}>
              <Alert
                variant="error"
                title={i18n.t(
                  'entity.warehouse.notifications.serviceOrderIssueVariantAlreadyExists'
                )}
                data-testid={suffixTestId('serviceOrderIssueVariantAlreadyExists', props)}
              />
            </Show>

            <FormField
              control={control}
              type="switch"
              name="isDefault"
              label={i18n.t('entity.warehouse.labels.setAsDefaultHandlingUnit')}
              data-testid={suffixTestId('inputs.isDefault', props)}
            />

            <Grid columns={2}>
              <Separator />
            </Grid>

            <Grid columns={2}>
              <FormField
                control={control}
                type="text"
                name="name"
                label={i18n.t('entity.warehouse.labels.name')}
                isRequired
                data-testid={suffixTestId('inputs.name', props)}
              />
            </Grid>

            <Grid columns={4}>
              <GridItem span={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="authorizationProfileId"
                  label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                  options={getOptionsFromAuthorizationProfiles(props.authorizationProfiles)}
                  onChange={handleAuthorizationProfileChange}
                  isRequired
                  isNotClearable
                  data-testid={suffixTestId('inputs.authorizationProfileId', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  options={getOptionsFromBranches(props.branches)}
                  label={i18n.t('entity.branch.labels.branch')}
                  isDisabled
                  data-testid={suffixTestId('inputs.branchId', props)}
                />
              </GridItem>
            </Grid>

            <Grid columns={4}>
              <GridItem span={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="docSeriesId"
                  label={i18n.t('entity.warehouse.labels.serviceOrderIssueSeries')}
                  options={getOptionsFromSeriesList(props.series, 'workshop/service_order_issue')}
                  isRequired
                  isNotClearable
                  data-testid={suffixTestId('inputs.docSeriesId', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="documentTemplateId"
                  label={i18n.t('entity.warehouse.labels.serviceOrderIssueTemplate')}
                  options={getOptionsFromTemplates(props.documentTemplates)}
                  isRequired
                  isNotClearable
                  data-testid={suffixTestId('inputs.documentTemplateId', props)}
                />
              </GridItem>
            </Grid>
            <Space fillAvailable />
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: isNil(props.serviceOrderIssueVariant)
                    ? i18n.t('general.actions.discard')
                    : i18n.t('general.actions.discardChanges'),
                  onClick: handleNavigateBack,
                  'data-testid': suffixTestId('actions.discard', props),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  variant: 'primary',
                  title: isNil(props.serviceOrderIssueVariant)
                    ? i18n.t('general.actions.save')
                    : i18n.t('general.actions.saveChanges'),
                  'data-testid': suffixTestId('actions.submit', props),
                },
              ]}
            />
          </VStack>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  name: yupString.required(),
  authorizationProfileId: yupString.required(),
  docSeriesId: yupString.required(),
  documentTemplateId: yupString.required(),
  isDefault: boolean().default(false),
});
