import {DataStatus, Form, FormField} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {useGetBranchListQuery, useGetWarehousesQuery} from '@dms/api';
import {i18n} from '@dms/i18n';
import {getOptionsFromBranches, getOptionsFromWarehouses} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

type MaterialArticleTabExternalFiltersForm = Partial<{
  branchId: string;
  warehouseId: string;
  isAvailable: boolean;
}>;

type MaterialArticleTabExternalFiltersProps = {
  serviceOrderVariantId: string | Nullish;
} & FormToDataGridConnectorProps &
  TestIdProps;

export function MaterialArticleTabExternalFilters(props: MaterialArticleTabExternalFiltersProps) {
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery(
    {
      serviceOrderVariantId: props.serviceOrderVariantId as string,
    },
    {skip: isNil(props.serviceOrderVariantId)}
  );

  const {
    data: branches,
    isLoading: branchesLoading,
    isError: isBranchesError,
  } = useGetBranchListQuery();

  const isLoading = isWarehousesLoading || branchesLoading;
  const isError = isWarehousesError || isBranchesError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialArticleTabExternalFiltersForm> values={props.values} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="branchId"
              type="choice"
              label={i18n.t('entity.branch.labels.branch')}
              options={getOptionsFromBranches(branches)}
              data-testid={suffixTestId('branch', props)}
            />
            <FormField
              control={control}
              name="warehouseId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(warehouses)}
              data-testid={suffixTestId('warehouse', props)}
            />
            <FormField
              control={control}
              name="isAvailable"
              label={i18n.t('entity.warehouse.labels.availableOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
