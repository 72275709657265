import {
  GetSalePresetSettingsApiArg,
  GetSalePresetSettingsApiResponse,
  DisablePublishCebiaSmartCodeUrlApiArg,
  DisablePublishCebiaSmartCodeUrlApiResponse,
  CreateSalePresetSettingsApiArg,
  CreateSalePresetSettingsApiResponse,
  DeleteSalePresetSettingsApiArg,
  DeleteSalePresetSettingsApiResponse,
  UpdateSalePresetSettingsApiArg,
  UpdateSalePresetSettingsApiResponse,
  EditGeneralSettingsApiArg,
  EditGeneralSettingsApiResponse,
  EditPlatformSettingsApiArg,
  EditPlatformSettingsApiResponse,
  EditWindshieldSettingsApiArg,
  EditWindshieldSettingsApiResponse,
  EnablePublishCebiaSmartCodeUrlApiArg,
  EnablePublishCebiaSmartCodeUrlApiResponse,
  GetCebiaSmartCodeUrlConfigApiArg,
  GetCebiaSmartCodeUrlConfigApiResponse,
  GetBusinessCaseDocumentsSettingsApiResponse,
  PutBusinessCaseDocumentsSettingsApiArg,
  PutBusinessCaseDocumentsSettingsApiResponse,
  GetGeneralSettingsApiArg,
  GetGeneralSettingsApiResponse,
  GetGenerationsApiArg,
  GetGenerationsApiResponse,
  GetPlatformSettingsApiArg,
  GetPlatformSettingsApiResponse,
  GetSalesPlatformsApiArg,
  GetSalesPlatformsApiRes,
  GetVersionsApiArg,
  GetVersionsApiResponse,
  GetWindshieldSettingsApiArg,
  GetWindshieldSettingsApiResponse,
  UpdateVehicleIsInSaleApiArg,
  UpdateVehicleIsInSaleApiRes,
  UpdateVehicleOnAllPlatformsApiArg,
  UpdateVehicleOnAllPlatformsApiResponse,
  UpdateVehicleOnPlatformApiArg,
  UpdateVehicleOnPlatformApiResponse,
  CreatePurchaseBusinessCaseApiArg,
  CreatePurchaseBusinessCaseApiResponse,
  CreateSaleBusinessCaseApiArg,
  CreateSaleBusinessCaseApiResponse,
  UpdateKeyLocationApiArg,
  UpdateKeyLocationApiResponse,
  GetDigitalCertificateThemesApiResponse,
} from '../types/sales';
import {omneticApi} from './baseApi/omneticApi';

export const salesApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    updateVehicleIsInSale: build.mutation<UpdateVehicleIsInSaleApiRes, UpdateVehicleIsInSaleApiArg>(
      {
        query: ({vehicleId, publish}) => ({
          url: `/dms/v2/sales/vehicle/${vehicleId}/${publish ? 'publish' : 'unpublish'}`,
          method: 'PUT',
        }),
        invalidatesTags: ['AdGeneralSettings'],
      }
    ),
    getSalesPlatforms: build.query<GetSalesPlatformsApiRes, GetSalesPlatformsApiArg>({
      query: ({vehicleId}) => ({
        url: `/dms/v2/sales/publishing/vehicle/${vehicleId}/advertisements`,
      }),
      providesTags: ['AdGeneralSettings'],
    }),
    updateVehicleOnAllPlatforms: build.mutation<
      UpdateVehicleOnAllPlatformsApiResponse,
      UpdateVehicleOnAllPlatformsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/updateOnAllPlatforms`,
        method: 'PUT',
      }),
    }),
    updateVehicleOnPlatform: build.mutation<
      UpdateVehicleOnPlatformApiResponse,
      UpdateVehicleOnPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/platform/${queryArg.platformCode}/update`,
        method: 'PUT',
      }),
    }),
    editGeneralSettings: build.mutation<EditGeneralSettingsApiResponse, EditGeneralSettingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}`,
        method: 'PATCH',
        body: queryArg.patchVehicleAdvertisingSettingsRequestBody,
      }),
      invalidatesTags: ['AdGeneralSettings', 'AdPlatformSettings'],
    }),
    getGeneralSettings: build.query<GetGeneralSettingsApiResponse, GetGeneralSettingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: ['AdGeneralSettings'],
    }),
    editWindshieldSettings: build.mutation<
      EditWindshieldSettingsApiResponse,
      EditWindshieldSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/windshield`,
        method: 'PATCH',
        body: queryArg.patchVehicleWindshieldAdvertisingSettingsRequestBody,
      }),
      invalidatesTags: ['AdWindshieldSettings'],
    }),
    getWindshieldSettings: build.query<
      GetWindshieldSettingsApiResponse,
      GetWindshieldSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/windshield`,
      }),
      providesTags: ['AdWindshieldSettings'],
    }),
    editPlatformSettings: build.mutation<
      EditPlatformSettingsApiResponse,
      EditPlatformSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/platform/${queryArg.platformCode}`,
        method: 'PATCH',
        body: queryArg.vehiclePlatformAdvertisingSettingsRequestBody,
      }),
      invalidatesTags: ['AdPlatformSettings'],
    }),
    getPlatformSettings: build.query<GetPlatformSettingsApiResponse, GetPlatformSettingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/platform/${queryArg.platformCode}`,
      }),
      providesTags: ['AdPlatformSettings'],
    }),
    getBusinessCaseDocumentsSettings: build.query<
      GetBusinessCaseDocumentsSettingsApiResponse,
      void
    >({
      query: () => ({
        url: `/dms/v1/settings/business-case/documents`,
      }),
      providesTags: ['BusinessCaseSettings'],
    }),
    putBusinessCaseDocumentsSettings: build.mutation<
      PutBusinessCaseDocumentsSettingsApiResponse,
      PutBusinessCaseDocumentsSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/business-case/documents`,
        method: 'PUT',
        body: queryArg.businessCaseDocumentsSettingsRequestBody,
      }),
      invalidatesTags: ['BusinessCaseSettings'],
    }),
    createPurchaseBusinessCase: build.mutation<
      CreatePurchaseBusinessCaseApiResponse,
      CreatePurchaseBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/purchase`,
        method: 'POST',
        body: queryArg.createPurchaseBusinessCaseRequestBody,
      }),
      invalidatesTags: () => ['SaleVehicleActions'],
    }),
    createSaleBusinessCase: build.mutation<
      CreateSaleBusinessCaseApiResponse,
      CreateSaleBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/sale`,
        method: 'POST',
        body: queryArg.createSaleBusinessCaseRequestBody,
      }),
      invalidatesTags: (_, __, arg) => [
        {
          type: 'VehicleLastActiveBC',
          id: arg.createSaleBusinessCaseRequestBody.vehicleToSaleId,
        },
        {
          type: 'BusinessCaseCountByVehicle',
          id: arg.createSaleBusinessCaseRequestBody.vehicleToSaleId,
        },
      ],
    }),
    getSalePresetSettings: build.query<
      GetSalePresetSettingsApiResponse,
      GetSalePresetSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/sale-preset/${queryArg.salePresetId}`,
      }),
    }),
    createSalePresetSettings: build.mutation<
      CreateSalePresetSettingsApiResponse,
      CreateSalePresetSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/sale-preset`,
        method: 'POST',
        body: queryArg.createSalePresetSettingsRequestBody,
      }),
    }),
    deleteSalePresetSettings: build.mutation<
      DeleteSalePresetSettingsApiResponse,
      DeleteSalePresetSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/sale-preset/${queryArg.salePresetId}`,
        method: 'DELETE',
      }),
    }),
    updateSalePresetSettings: build.mutation<
      UpdateSalePresetSettingsApiResponse,
      UpdateSalePresetSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/sale-preset/${queryArg.salePresetId}`,
        method: 'PUT',
        body: queryArg.updateSalePresetSettingsRequestBody,
      }),
    }),
    updateKeyLocation: build.mutation<UpdateKeyLocationApiResponse, UpdateKeyLocationApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/keyLocation`,
        method: 'PATCH',
        body: queryArg.updateVehicleKeyLocationRequestBody,
      }),
      invalidatesTags: (_, __, arg) => [{type: 'Vehicle', id: arg.vehicleId}],
    }),
    disablePublishCebiaSmartCodeUrl: build.mutation<
      DisablePublishCebiaSmartCodeUrlApiResponse,
      DisablePublishCebiaSmartCodeUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/disable-publish-cebia-smart-code-url`,
        method: 'PUT',
      }),
      async onQueryStarted({vehicleId}, {dispatch, queryFulfilled}) {
        // OPTIMISTIC RESPONSE
        const patchResult = dispatch(
          salesApi.util.updateQueryData(
            'getPublishCebiaSmartCodeUrlConfig',
            {vehicleId},
            (draft) => {
              draft.publishCebiaSmartCodeUrl = false;
            }
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CebiaSmartCodeUrlConfig', id: queryArg.vehicleId},
      ],
    }),
    enablePublishCebiaSmartCodeUrl: build.mutation<
      EnablePublishCebiaSmartCodeUrlApiResponse,
      EnablePublishCebiaSmartCodeUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/enable-publish-cebia-smart-code-url`,
        method: 'PUT',
      }),
      async onQueryStarted({vehicleId}, {dispatch, queryFulfilled}) {
        // OPTIMISTIC RESPONSE
        const patchResult = dispatch(
          salesApi.util.updateQueryData(
            'getPublishCebiaSmartCodeUrlConfig',
            {vehicleId},
            (draft) => {
              draft.publishCebiaSmartCodeUrl = true;
            }
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CebiaSmartCodeUrlConfig', id: queryArg.vehicleId},
      ],
    }),
    getPublishCebiaSmartCodeUrlConfig: build.query<
      GetCebiaSmartCodeUrlConfigApiResponse,
      GetCebiaSmartCodeUrlConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/cebia-smart-code-url-config`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'CebiaSmartCodeUrlConfig', id: queryArg.vehicleId},
      ],
    }),
    getGenerations: build.query<GetGenerationsApiResponse, GetGenerationsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/generations`,
      }),
    }),
    getVersions: build.query<GetVersionsApiResponse, GetVersionsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/publishing/vehicle/${queryArg.vehicleId}/versions`,
      }),
    }),
    getDigitalCertificateThemes: build.query<GetDigitalCertificateThemesApiResponse, void>({
      query: () => ({
        url: `/dms/v2/sales/publishing/digital-certificate-themes`,
      }),
    }),
  }),
});

export const {
  useUpdateVehicleIsInSaleMutation,
  useGetSalesPlatformsQuery,
  useLazyGetSalesPlatformsQuery,
  useUpdateVehicleOnAllPlatformsMutation,
  useUpdateVehicleOnPlatformMutation,
  useEditGeneralSettingsMutation,
  useGetGeneralSettingsQuery,
  useEditWindshieldSettingsMutation,
  useGetWindshieldSettingsQuery,
  useEditPlatformSettingsMutation,
  useGetPlatformSettingsQuery,
  useDisablePublishCebiaSmartCodeUrlMutation,
  useEnablePublishCebiaSmartCodeUrlMutation,
  useGetPublishCebiaSmartCodeUrlConfigQuery,
  useGetVersionsQuery,
  useCreateSalePresetSettingsMutation,
  useDeleteSalePresetSettingsMutation,
  useUpdateSalePresetSettingsMutation,
  useGetSalePresetSettingsQuery,
  useGetGenerationsQuery,
  useGetDigitalCertificateThemesQuery,
  useGetBusinessCaseDocumentsSettingsQuery,
  useCreatePurchaseBusinessCaseMutation,
  useCreateSaleBusinessCaseMutation,
  useUpdateKeyLocationMutation,
  usePutBusinessCaseDocumentsSettingsMutation,
} = salesApi;
