import {MouseEvent as ReactMouseEvent, useCallback} from 'react';

import {isString} from 'ramda-adjunct';

import {ActionCallback} from '../types/ActionCallback';
import {GridApi} from '../types/AgGridTypes';
import {RowData} from '../types/RowData';
import {refreshDatagrid} from '../utils/refreshDatagrid';
import {getSearchParamsByGridCode} from '../utils/url/getSearchParamsByGridCode';
import {setSourceSearchParamsByGridCode} from '../utils/url/setSourceSearchParamsByGridCode';
import {useDeselectAll} from './useDeselectAll';
import {useHandleLink} from './useHandleLink';
import {useRedrawRows} from './useRedrawRows';
import {useRefreshCells} from './useRefreshCells';

export type ActionCallbackProxy = (
  actionKey: string,
  event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
) => void;

/**
 * Wraps action callback to another function that provieds most of the parameters.
 * We have to provide only actionKey and mouse event.
 */
export const useActionCallbackProxy = (
  actionCallback: ActionCallback | undefined,
  gridApi: GridApi,
  rowId: string | string[],
  rowData: RowData,
  gridCode: string
) => {
  const handleLink = useHandleLink();
  const refreshCells = useRefreshCells(gridApi);
  const redrawRows = useRedrawRows(gridApi);
  const deselectAll = useDeselectAll(gridApi);
  const searchParams = getSearchParamsByGridCode(gridCode);

  const actionCallbackProxy = useCallback(
    (actionKey: string, event?: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!actionCallback) {
        return;
      }

      const sourceSearchParams = setSourceSearchParamsByGridCode(
        gridCode,
        String(rowData.rowNumber)
      );

      const refreshData = () => refreshDatagrid(gridCode);

      const actionLink = actionCallback({
        actionKey,
        rowId,
        rowData,
        refreshData,
        refreshCells,
        redrawRows,
        deselectAll,
        sourceSearchParams,
        queryId: searchParams?.queryId ?? '',
      });
      if (isString(actionLink) && event) {
        handleLink(actionLink, event.nativeEvent);
      }
    },
    [
      actionCallback,
      gridCode,
      rowData,
      rowId,
      refreshCells,
      redrawRows,
      deselectAll,
      searchParams?.queryId,
      handleLink,
    ]
  );

  return actionCallbackProxy;
};
