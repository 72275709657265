import {GridItem, Grid, Hide, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import {VehicleTypeEnumObject} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {
  selectBodyStyles,
  selectDoorCounts,
  selectDrives,
  selectTransmissions,
} from '../../../store/vehicleCatalogue/selectors';
import {FuelTypeEnum} from '../../../types/FuelTypeEnum';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';
import {getSeatCountOptions} from '../utils/getSeatCountOptions';
import {ColorSpecifications} from './ColorSpecifications';
import {Fuel} from './Fuel';

export const ModelSpecification: FC<PropsWithChildren<any>> = () => {
  const {Field, Subscribe} = useFormRenderer<VehicleCreateFormState>();

  return (
    <Subscribe
      name="type"
      component={({input: {value: vehicleType}}) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const drivesList = useSelector(selectDrives(vehicleType));
        const transmissionsList = useSelector(selectTransmissions(vehicleType));
        const doorTypesList = useSelector(selectDoorCounts(vehicleType));
        const bodyTypeOptions = useSelector(selectBodyStyles(vehicleType));

        return (
          <>
            <Grid columns={2}>
              <Show when={bodyTypeOptions?.length}>
                <GridItem span={2}>
                  <FittedFieldWrapper>
                    <Subscribe
                      name="type"
                      component={({input: {value: vehicleType}}) => (
                        <Field<VehicleTypeEnumObject>
                          name="bodyStyle"
                          as="chips"
                          label={i18n.t('entity.vehicle.labels.body')}
                          options={bodyTypeOptions}
                          limit={
                            vehicleType === VehicleTypeEnumObject.VEHICLETYPE_MOTORCYCLE ? 9 : 11
                          }
                          enabledDeselect
                        />
                      )}
                    />
                  </FittedFieldWrapper>
                </GridItem>
              </Show>
              <Hide
                when={
                  vehicleType === VehicleTypeEnumObject.VEHICLETYPE_TRAILER ||
                  vehicleType === VehicleTypeEnumObject.VEHICLETYPE_SEMI_TRAILER
                }
              >
                <GridItem span={2}>
                  <FittedFieldWrapper>
                    <Fuel />
                  </FittedFieldWrapper>
                </GridItem>
                <Show when={transmissionsList?.length}>
                  <FittedFieldWrapper>
                    <Field
                      as="chips"
                      name="transmission"
                      label={i18n.t('entity.vehicle.labels.transmission')}
                      options={transmissionsList}
                      enabledDeselect
                    />
                  </FittedFieldWrapper>
                </Show>
                <Show when={drivesList?.length}>
                  <FittedFieldWrapper>
                    <Field
                      as="chips"
                      name="drive"
                      label={i18n.t('entity.vehicle.labels.drive')}
                      options={drivesList}
                      enabledDeselect
                    />
                  </FittedFieldWrapper>
                </Show>
                <Subscribe<FuelTypeEnum>
                  name="fuelType"
                  component={({input: {value: fuelType}}) => (
                    <GridItem span={2}>
                      <FittedFieldWrapper>
                        <Grid columns={4}>
                          <Field
                            as="integer"
                            name="power"
                            label={i18n.t(`entity.vehicle.labels.power2`)}
                            suffix={i18n.t('general.metric.kW')}
                          />
                          <Show whenFeatureEnabled={featureFlags.CORE_MAX_POWER}>
                            <Field
                              as="integer"
                              name="engine.maximalPower"
                              label={i18n.t(`entity.vehicle.labels.maximalPower`)}
                              suffix={i18n.t('general.metric.kW')}
                            />
                          </Show>
                          <Hide
                            when={
                              fuelType === FuelTypeEnum.FUELTYPE_ELECTRIC ||
                              fuelType === FuelTypeEnum.FUELTYPE_HYDROGEN
                            }
                          >
                            <Field
                              as="integer"
                              maxLength={5}
                              name="engine.engineVolume"
                              label={i18n.t('entity.vehicle.labels.engineCapacity')}
                              suffix={i18n.t('general.metric.ccm')}
                            />
                            <Field
                              as="integer"
                              name="engine.cylinderCount"
                              label={i18n.t(`entity.vehicle.labels.numberOfCylinders2`)}
                            />
                          </Hide>
                          <Field
                            as="integer"
                            name="engine.gearCount"
                            label={i18n.t(`entity.vehicle.labels.numberOfGears2`)}
                          />
                        </Grid>

                        <Grid columns={4}>
                          <Show when={fuelType === FuelTypeEnum.FUELTYPE_HYBRID}>
                            <Field
                              as="integer"
                              name="engine.powerCombustionEngine"
                              label={i18n.t(`entity.vehicle.labels.powerCombustionEngine`)}
                              suffix={i18n.t('general.metric.kW')}
                            />
                            <Field
                              as="integer"
                              name="engine.powerElectricEngine"
                              label={i18n.t(`entity.vehicle.labels.powerElectricEngine`)}
                              suffix={i18n.t('general.metric.kW')}
                            />
                          </Show>
                        </Grid>
                      </FittedFieldWrapper>
                    </GridItem>
                  )}
                />
                <Show when={doorTypesList?.length}>
                  <Field
                    as="chips"
                    name="doorCountCategory"
                    label={i18n.t('entity.vehicle.labels.numberOfDoorsFull')}
                    options={doorTypesList}
                    enabledDeselect
                  />
                </Show>
                <Field
                  as="chips"
                  name="modelSpecification.seatCount"
                  label={i18n.t('entity.vehicle.labels.numberOfSeatsFull')}
                  getOptionValue={({id}) => id}
                  options={getSeatCountOptions}
                  enabledDeselect
                />
              </Hide>
            </Grid>
            <Hide
              when={
                vehicleType === VehicleTypeEnumObject.VEHICLETYPE_TRAILER ||
                vehicleType === VehicleTypeEnumObject.VEHICLETYPE_SEMI_TRAILER
              }
            >
              <ColorSpecifications />
            </Hide>
          </>
        );
      }}
    />
  );
};

/**
 * due to old form system usage, we need to remove default bottom padding to group-up form fields according to the designs
 * @deprecated
 */
const FittedFieldWrapper = ({children}: PropsWithChildren) => (
  <div
    css={css`
      margin-bottom: -16px;
    `}
  >
    {children}
  </div>
);
