import {DataStatus} from 'platform/components';

import {useGetEarningQuery} from '@dms/api';
import {VehicleEarningCostForm} from '@dms/shared';

interface EditEarningFormProps {
  vehicleId: string;
  earningId: string | null;
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
  isCostsEditable?: boolean;
}

export function EditEarningForm(props: EditEarningFormProps) {
  const {data, isLoading, isError} = useGetEarningQuery(
    {earningId: props.earningId ?? ''},
    {skip: !props.earningId}
  );

  return (
    <DataStatus spacing={10} isLoading={isLoading} isError={isError}>
      <VehicleEarningCostForm
        entity="earning"
        defaultValues={data}
        entityId={props.earningId}
        vehicleId={props.vehicleId}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
        isCostsEditable={props.isCostsEditable}
      />
    </DataStatus>
  );
}
