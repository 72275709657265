import {match, Pattern} from 'ts-pattern';

import {useMemo} from 'react';

import {always, isNotNil, pick, values} from 'ramda';

import {
  CustomerAdvancedSearchApiArg,
  CustomerAdvancedSearchFieldMatchTypeResponseBody,
  CustomerAdvancedSearchResultContactResponseBody,
  CustomerAdvancedSearchResultResponseBody,
} from '@dms/api';

import {EMPTY_PLACEHOLDER} from 'shared';

import {useAddress} from '../../../hooks/useAddress';

const REQUIRED_MATCH_ARRAY = ['FULL', 'PARTIAL'];

type Match<T> = {
  type: T | undefined;
  highlight: string | null | undefined;
};

export interface CustomerMatchResult {
  shortAddress: string | null;
  customerName: string;
  customerNameMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody | undefined;
  customerPhone: Match<CustomerAdvancedSearchFieldMatchTypeResponseBody>;
  customerEmail: Match<CustomerAdvancedSearchFieldMatchTypeResponseBody>;
  isLegalNumberFullMatch: boolean;
  isPhoneNumberFullMatch: boolean;
  isEmailFullMatch: boolean;
  isNameFullMatch: boolean;
  contactName: string;
  contactNameMatchType: CustomerAdvancedSearchFieldMatchTypeResponseBody | undefined;
  isContactNameFullMatch: boolean;
  isNamePartialMatch: boolean;
  isLegalNumberErrorMatch: boolean;
  isPhoneNumberErrorMatch: boolean;
  isEmailErrorMatch: boolean;
  isNameErrorMatch: boolean;
  isFullMatch: boolean;
  isErrorMatch: boolean;
}

export const useCustomersMatch = (
  customers: CustomerAdvancedSearchResultResponseBody[],
  searchArg: CustomerAdvancedSearchApiArg
) => {
  const {composeShortAddress} = useAddress();

  const getCustomerMatch = (
    customer: CustomerAdvancedSearchResultResponseBody,
    contact?: CustomerAdvancedSearchResultContactResponseBody
  ): CustomerMatchResult => {
    const defaultContact = customer.contacts?.[0];

    const address = match({
      businessAddress: customer.businessAddress,
      foundingPersonAddress: customer.foundingPerson?.address,
    })
      .with({businessAddress: Pattern.not(Pattern.nullish)}, (c) => c.businessAddress)
      .with({foundingPersonAddress: Pattern.not(Pattern.nullish)}, (c) => c.foundingPersonAddress)
      .otherwise(() => null);

    const shortAddress =
      address && composeShortAddress({id: '', address, type: null, invalid: null});

    const customerName = match(customer)
      .with({tradeNameHighlight: Pattern.string}, (c) => c.tradeNameHighlight)
      .with(
        {foundingPerson: {firstNameHighlight: Pattern.string, lastNameHighlight: Pattern.string}},
        (c) => `${c.foundingPerson?.firstNameHighlight} ${c.foundingPerson?.lastNameHighlight}`
      )
      .otherwise(() => EMPTY_PLACEHOLDER);

    const customerNameMatchType = match(customer)
      .with({tradeNameHighlight: Pattern.string}, (c) => c.tradeNameMatchType)
      .otherwise((c) => c.foundingPerson?.lastNameMatchType);

    const customerPhone = match({
      foundingPerson: customer.foundingPerson,
      contact,
      defaultContact,
    })
      .when(
        ({foundingPerson}) =>
          isNotNil(foundingPerson) && REQUIRED_MATCH_ARRAY.includes(foundingPerson?.phoneMatchType),
        ({foundingPerson}) => ({
          type: foundingPerson?.phoneMatchType,
          highlight: foundingPerson?.phoneHighlight,
        })
      )
      .when(
        ({contact}) => isNotNil(contact) && REQUIRED_MATCH_ARRAY.includes(contact?.phoneMatchType),
        ({contact}) => ({
          type: contact?.phoneMatchType,
          highlight: contact?.phoneHighlight,
        })
      )
      .when(
        ({defaultContact}) =>
          isNotNil(defaultContact) && REQUIRED_MATCH_ARRAY.includes(defaultContact?.phoneMatchType),
        ({defaultContact}) => ({
          type: defaultContact?.phoneMatchType,
          highlight: defaultContact?.phoneHighlight,
        })
      )
      .otherwise<{
        type: CustomerAdvancedSearchFieldMatchTypeResponseBody;
        highlight: string | null;
      }>((c) => ({
        type: 'NONE',
        highlight: c.foundingPerson?.phone ?? c.contact?.phone ?? c.defaultContact?.phone,
      }));

    const customerEmail = match({
      foundingPerson: customer.foundingPerson,
      contact,
      defaultContact,
    })
      .when(
        ({foundingPerson}) =>
          isNotNil(foundingPerson) && REQUIRED_MATCH_ARRAY.includes(foundingPerson?.emailMatchType),
        ({foundingPerson}) => ({
          type: foundingPerson?.emailMatchType,
          highlight: foundingPerson?.emailHighlight,
        })
      )
      .when(
        ({contact}) => isNotNil(contact) && REQUIRED_MATCH_ARRAY.includes(contact?.emailMatchType),
        ({contact}) => ({
          type: contact?.emailMatchType,
          highlight: contact?.emailHighlight,
        })
      )
      .when(
        ({defaultContact}) =>
          isNotNil(defaultContact) && REQUIRED_MATCH_ARRAY.includes(defaultContact?.emailMatchType),
        ({defaultContact}) => ({
          type: defaultContact?.emailMatchType,
          highlight: defaultContact?.emailHighlight,
        })
      )
      .otherwise<{
        type: CustomerAdvancedSearchFieldMatchTypeResponseBody;
        highlight: string | null;
      }>((c) => ({
        type: 'NONE',
        highlight: c.foundingPerson?.email ?? c.contact?.email ?? c.defaultContact?.email,
      }));

    const matchArray = [
      {matchType: customerNameMatchType, fieldName: 'name'},
      {matchType: contact?.lastNameMatchType, fieldName: 'name'},
      {matchType: customer.registrationNumberMatchType, fieldName: 'legalNumber'},
      {matchType: customer.vatNumberMatchType, fieldName: 'legalNumber'},
      {matchType: customerPhone?.type, fieldName: 'phoneNumber'},
      {matchType: customerEmail?.type, fieldName: 'email'},
    ];

    // Full matches
    const isAllMatchesFull = (fieldName: keyof CustomerAdvancedSearchApiArg): boolean =>
      matchArray.some((match) => match.fieldName === fieldName && match.matchType === 'FULL');

    const isLegalNumberFullMatch = isAllMatchesFull('legalNumber');
    const isPhoneNumberFullMatch = isAllMatchesFull('phoneNumber');
    const isEmailFullMatch = isAllMatchesFull('email');
    const isNameFullMatch =
      isAllMatchesFull('name') &&
      (isLegalNumberFullMatch || isPhoneNumberFullMatch || isEmailFullMatch);

    const contactName = match(contact)
      .with(
        {firstNameHighlight: Pattern.string, lastNameHighlight: Pattern.string},
        (c) => `${c.firstNameHighlight} ${c.lastNameHighlight}`
      )
      .otherwise(() => EMPTY_PLACEHOLDER);

    const contactNameMatchType = match([
      contact?.lastNameMatchType,
      isLegalNumberFullMatch || isPhoneNumberFullMatch || isEmailFullMatch,
    ])
      .with(['FULL', true], always<CustomerAdvancedSearchFieldMatchTypeResponseBody>('FULL'))
      .with(['FULL', false], always<CustomerAdvancedSearchFieldMatchTypeResponseBody>('PARTIAL'))
      .otherwise(([matchType]) => matchType);

    const isContactNameFullMatch = contactNameMatchType === 'FULL';

    const isNamePartialMatch = matchArray
      .filter((match) => match.fieldName === 'name' && match.matchType)
      .every((match) => match.matchType === 'PARTIAL');

    // Error matches
    const isAllMatchesNone = (fieldName: keyof CustomerAdvancedSearchApiArg): boolean =>
      matchArray
        .filter((match) => match.fieldName === fieldName && match.matchType)
        .every((match) => match.matchType === 'NONE' && searchArg[fieldName]);

    const isLegalNumberErrorMatch = isAllMatchesNone('legalNumber');
    const isPhoneNumberErrorMatch = isAllMatchesNone('phoneNumber');
    const isEmailErrorMatch = isAllMatchesNone('email');
    const isNameErrorMatch = isAllMatchesNone('name');

    // Final matches
    const isFullMatch =
      !isNameErrorMatch &&
      !isLegalNumberErrorMatch &&
      !isPhoneNumberErrorMatch &&
      !isEmailErrorMatch &&
      (isNameFullMatch ||
        isLegalNumberFullMatch ||
        isPhoneNumberFullMatch ||
        isEmailFullMatch ||
        isContactNameFullMatch);

    const isErrorMatch =
      isLegalNumberErrorMatch || isPhoneNumberErrorMatch || isEmailErrorMatch || isNameErrorMatch;

    return {
      shortAddress,
      customerName,
      customerNameMatchType,
      customerPhone,
      customerEmail,
      isLegalNumberFullMatch,
      isPhoneNumberFullMatch,
      isEmailFullMatch,
      isNameFullMatch,
      contactName,
      contactNameMatchType,
      isContactNameFullMatch,
      isNamePartialMatch,
      isLegalNumberErrorMatch,
      isPhoneNumberErrorMatch,
      isEmailErrorMatch,
      isNameErrorMatch,
      isFullMatch,
      isErrorMatch,
    };
  };

  const customersWithMatchProps = useMemo(
    () =>
      customers.map((customer) => ({
        ...customer,
        matchProps: getCustomerMatch(customer),
        matchedContacts: customer.contacts
          .filter((contact) =>
            values(pick(['lastNameMatchType', 'emailMatchType', 'phoneMatchType'], contact)).some(
              (v) => REQUIRED_MATCH_ARRAY.includes(String(v))
            )
          )
          .map((contact) => ({
            ...contact,
            matchProps: getCustomerMatch(customer, contact),
          })),
      })),
    [customers, searchArg]
  );

  const hasAnyCustomerFullMatch = useMemo(
    () =>
      customersWithMatchProps.some(
        (customer) =>
          customer.matchProps.isFullMatch ||
          customer.matchedContacts.some((contact) => contact.matchProps.isFullMatch)
      ),
    [customersWithMatchProps]
  );

  const hasLegalNumberFullMatch = useMemo(
    () =>
      customersWithMatchProps.some(
        (customer) =>
          customer.matchProps.isLegalNumberFullMatch ||
          customer.matchedContacts.some((contact) => contact.matchProps.isLegalNumberFullMatch)
      ),
    [customersWithMatchProps]
  );

  return {
    customersWithMatchProps,
    hasAnyCustomerFullMatch,
    hasLegalNumberFullMatch,
  };
};
