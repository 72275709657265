import {useRef, useEffect, ReactNode, ReactElement} from 'react';
import {FieldValues, FormProvider, UseFormReturn} from 'react-hook-form';

import {noop} from '@dms/teas';

import {FormContextWithSubmit} from '../utils/FormContextWithSubmit';

interface FormContextWithSubmitProps<T extends FieldValues> {
  children?: ReactNode;
  formProps: UseFormReturn<T>;
  onSubmit: () => void;
  onChange?: () => void;
}

export function FormWithSubmitProvider<T extends FieldValues>({
  children,
  formProps,
  onSubmit,
  onChange,
}: FormContextWithSubmitProps<T>): ReactElement {
  const submitRef = useRef<{
    onSubmit: () => void;
    onChange: () => void;
  }>({onSubmit: noop, onChange: noop});

  useEffect(() => {
    submitRef.current.onSubmit = onSubmit ?? noop;
    submitRef.current.onChange = onChange ?? noop;
  }, [onSubmit, onChange]);

  return (
    <FormContextWithSubmit.Provider value={submitRef}>
      <FormProvider {...formProps}>{children}</FormProvider>
    </FormContextWithSubmit.Provider>
  );
}
