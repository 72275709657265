import {FormControl, FormField, Option} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemCostCenterProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
  costCenterOptions: Option[];
  areCostCenterOptionsLoading: boolean;
}

export function RequestTabLabourBulkEditItemCostCenter(
  props: RequestTabLabourBulkEditItemCostCenterProps
) {
  const isCostCenterDisabled = not(props.formApi.watch('isCostCenterEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isCostCenterEnabled"
            data-testid={suffixTestId('isCostCenterEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isCostCenterDisabled}
            isLoading={props.areCostCenterOptionsLoading}
            control={props.control}
            type="choice"
            name="costCenterId"
            label={i18n.t('entity.warehouse.labels.costCenter')}
            filterOption={(option) => props.formApi.watch('costCenterId') !== option.value}
            options={props.costCenterOptions}
            menuInPortal
            data-testid={suffixTestId('costCenterId', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}
