import {DataStatus, IconButton} from 'platform/components';
import {HStack, Heading, Space, VStack} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {carAuditApi} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {
  TRANSLATED_LANGUAGES,
  selectStructure,
  selectUserSelectedLanguage,
  VehiclePhotosRoutes,
  selectCarAudit,
  selectAudit,
} from '@dms/teas';

import {useRequiredParams, composePath} from 'shared';

import {AuditPhotos} from './AuditPhotos';
import {BatchDownloadButton} from './BatchDownloadButton';

export const InspectionPhotos: FC = () => {
  const {id: vehicleId} = useRequiredParams();
  const navigate = useNavigate();
  const structure = useSelector(selectStructure);
  const auditData = useSelector(selectAudit);
  const locale = useSelector(selectUserSelectedLanguage);
  const {loading} = useSelector(selectCarAudit);

  const structureQueryState = carAuditApi.endpoints.getAuditStructure.useQueryState({
    auditStructureId: structure?.id ?? '',
  });
  const auditQueryState = carAuditApi.endpoints.getAuditData.useQueryState({
    auditId: auditData?.id ?? '',
  });

  const handleBackButton = () => {
    navigate(
      composePath(vehiclesRoutes.photos, {
        params: {
          id: vehicleId,
          tab: VehiclePhotosRoutes.inspections,
        },
      })
    );
  };

  return (
    <DataStatus
      isLoading={
        loading.getAudits ||
        loading.getAuditStructure ||
        auditQueryState.isLoading ||
        auditQueryState.isFetching ||
        structureQueryState.isFetching ||
        structureQueryState.isLoading
      }
      isEmpty={isNilOrEmpty(structure)}
      emptyMessage={i18n.t`entity.condition.notifications.photosOfInspectionNotFound`}
      minHeight={50}
    >
      <VStack spacing={4}>
        <HStack spacing={3} align="center">
          <IconButton icon="navigation/arrow_back_ios" size="small" onClick={handleBackButton} />
          <Heading size={3}>{getTranslation(locale, structure?.name ?? undefined)}</Heading>
          <Space fillAvailable />
          <BatchDownloadButton />
        </HStack>
        <AuditPhotos data-testid={testIds.vehicles.photos('inspection')} />
      </VStack>
    </DataStatus>
  );
};

const getTranslation = (locale: TRANSLATED_LANGUAGES, translatable?: Record<string, string>) => {
  if (!translatable) {
    return '';
  }
  switch (locale) {
    case TRANSLATED_LANGUAGES.czech:
      return translatable.cs;
    case TRANSLATED_LANGUAGES.slovak:
      return translatable.sk;
    case TRANSLATED_LANGUAGES.germany:
      return translatable.de;
    case TRANSLATED_LANGUAGES.france:
      return translatable.fr;
    case TRANSLATED_LANGUAGES.spain:
      return translatable.es;
    case TRANSLATED_LANGUAGES.poland:
      return translatable.pl;
    case TRANSLATED_LANGUAGES.italy:
      return translatable.it;
    default:
      return translatable.en;
  }
};
