import {
  ButtonGroup,
  FormButton,
  FormControl,
  Separator,
  Tooltip,
  closeCurrentDialog,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {Box, Show, Space, VStack} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {ReservationForm} from './ReservationInfo';

interface ReservationFooterProps extends TestIdProps {
  control: FormControl<ReservationForm>;
  reservationItemId?: string;
  isSubmitDisabled?: boolean;
  cancelAndDeleteConfirmText?: string;
  onCancel?: (reservationItemId: string) => void;
  onCancelAndDelete?: (reservationItemId: string) => void;
}

export function ReservationFooter(props: ReservationFooterProps) {
  const handleCancelReservation = () => {
    if (isNil(props.reservationItemId)) {
      showNotification.error();
      return;
    }

    props.onCancel?.(props.reservationItemId);
  };

  const handleCancelAndDeleteReservation = () => {
    if (isNil(props.reservationItemId)) {
      showNotification.error();
      return;
    }

    props.onCancelAndDelete?.(props.reservationItemId);
  };

  const showCancelConfirmation = () => {
    openDeleteDialog({
      text: i18n.t('entity.warehouse.actions.cancelReservationConfirm'),
      onConfirm: handleCancelReservation,
      'data-testid': suffixTestId('deleteDialog.cancel', props),
    });
  };

  const showCancelAndDeleteConfirmation = () => {
    openDeleteDialog({
      text: props.cancelAndDeleteConfirmText,
      onConfirm: handleCancelAndDeleteReservation,
      'data-testid': suffixTestId('deleteDialog.cancelAndDelete', props),
    });
  };

  return (
    <Box position="absolute" bottom={0} left={0} right={0}>
      <VStack>
        <Separator spacing={0} />
        <Box padding={4}>
          <ButtonGroup align="right">
            <Show when={isNotNil(props.reservationItemId) && props.onCancel}>
              <FormButton
                variant="dangerLink"
                title={i18n.t('entity.warehouse.actions.cancelReservation')}
                onClick={showCancelConfirmation}
                control={props.control}
                data-testid={suffixTestId('actions.cancel', props)}
              />
              <Show when={props.onCancelAndDelete}>
                <Space horizontal={2} />
                <FormButton
                  variant="dangerLink"
                  title={i18n.t('entity.warehouse.actions.cancelAndDeleteReservation')}
                  onClick={showCancelAndDeleteConfirmation}
                  control={props.control}
                  data-testid={suffixTestId('actions.cancelAndDelete', props)}
                />
              </Show>
              <Space fillAvailable />
            </Show>
            <FormButton
              variant="secondary"
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              control={props.control}
              data-testid={suffixTestId('actions.discard', props)}
            />
            <Tooltip
              isDisabled={!props.isSubmitDisabled}
              description={i18n.t('entity.warehouse.labels.missingCustomerId')}
              placement="top"
            >
              <FormButton
                type="submit"
                variant="primary"
                title={
                  isNil(props.reservationItemId)
                    ? i18n.t('general.actions.reserve')
                    : i18n.t('general.actions.saveChanges')
                }
                isDisabled={props.isSubmitDisabled}
                control={props.control}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </Tooltip>
          </ButtonGroup>
        </Box>
      </VStack>
    </Box>
  );
}
