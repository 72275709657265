import {Action, Actions, Card, Separator} from 'platform/components';
import {Box, Center, getSize, HStack, Show, Text, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {useRef} from 'react';

import i18n from '@dms/i18n';
import {SourcingContent, TilesWrapper} from '@dms/teas';

import {buildArray, noop, suffixTestId, TestIdProps} from 'shared';

import {SourcingFilterPresetsControl} from '../../hooks/useSourcingFilterPresets';
import {FilterHeader} from '../FilterHeaderChips/FilterHeader';
import {FilterPresetButtons} from '../FilterPresetButtons/FilterPresetButtons';
import {FilterFeatures} from './components/FilterFeatures/FilterFeatures';
import {FilterMakesModels} from './components/FilterMakesModels/FilterMakesModels';
import {FiltersNavigation} from './components/FilterNavigation/FiltersNavigation';
import {FilterSeller} from './components/FilterSeller/FilterSeller';
import {FilterSpecification} from './components/FilterSpecification/FilterSpecification';
import {FilterStatistics} from './components/FilterStatistics/FilterStatistics';
import {FilterBlockEnum} from './enums/FilterBlockEnum';

const PAGE_HEADER_HEIGHT = 13;

export interface FiltersProps extends TestIdProps {
  filterPresetsControl: SourcingFilterPresetsControl;
  count: number;
  onClose: () => void;
}

export function Filters(props: FiltersProps) {
  const tilesWrapperRef = useRef<HTMLDivElement>(null);
  const formatNumber = useFormatNumber();

  const filterPresetActions = buildArray<Action>()
    .when(!!props.filterPresetsControl?.selectedFilterPreset, {
      type: 'button',
      title: i18n.t('entity.sourcing.filterPresets.actions.update'),
      variant: 'outlined',
      onClick: props.filterPresetsControl?.onUpdateSelectedFilterPreset ?? noop,
      'data-testid': suffixTestId('filterPresetsUpdate', props),
    })
    .when(!!props.filterPresetsControl?.selectedFilterPreset, {
      type: 'dropdown-iconButton',
      priority: 'tertiary',
      icon: 'navigation/more_vert',
      menuItems: [
        {
          label: i18n.t('entity.sourcing.filterPresets.actions.rename'),
          onClick: props.filterPresetsControl?.onRenameSelectedFilterPreset ?? noop,
        },
        {
          label: i18n.t('entity.sourcing.filterPresets.actions.delete'),
          onClick: props.filterPresetsControl?.onDeleteSelectedFilterPreset ?? noop,
        },
      ],
      'data-testid': suffixTestId('filterPresetsMore', props),
    })
    .add({
      type: 'button',
      title: i18n.t('page.filters.actions.showOffersWithCount', {
        count: props.count,
        formattedCount: formatNumber(props.count, 0),
      }),
      onClick: () => {
        props.onClose();
      },
      'data-testid': suffixTestId('showOffersWithCount', props),
    });

  return (
    <VStack height={`calc(100vh - ${getSize(PAGE_HEADER_HEIGHT)})`}>
      <Box backgroundColor="palettes.white.10.100">
        <Box paddingHorizontal={4} paddingVertical={3}>
          <HStack spacing={4}>
            <Center>
              <Text size="large" alternative>
                {i18n.t('page.filters.labels.filters')}
              </Text>
            </Center>
            <Show when={!props.filterPresetsControl.isEmpty}>
              <Separator orientation="vertical" spacing={0} />
            </Show>
            <FilterPresetButtons
              filterPresetsControl={props.filterPresetsControl}
              isSortingEnabled
            />
            <Actions
              actions={filterPresetActions}
              data-testid={suffixTestId('filterLayoutActions', props)}
            />
          </HStack>
        </Box>
        <Separator spacing={0} />
        <Box padding={4}>
          <FilterHeader
            filterPresetsControl={props.filterPresetsControl}
            showSaveButton
            data-testid={suffixTestId('filterHeader', props)}
          />
        </Box>
        <Separator spacing={0} />
      </Box>
      <SourcingContent>
        <Box padding={4} paddingRight={0} width={75}>
          <FiltersNavigation
            tilesWrapperRef={tilesWrapperRef}
            data-testid={suffixTestId('filtersNavigation', props)}
          />
        </Box>
        <TilesWrapper id="tilesWrapper" ref={tilesWrapperRef}>
          <div id={FilterBlockEnum.seller}>
            <Card title={i18n.t('page.filters.labels.seller')}>
              <FilterSeller data-testid={suffixTestId('filterSeller', props)} />
            </Card>
          </div>
          <div id={FilterBlockEnum.statistics}>
            <Card title={i18n.t('page.filters.labels.statistics')}>
              <FilterStatistics data-testid={suffixTestId('filterStatistics', props)} />
            </Card>
          </div>
          <div id={FilterBlockEnum.makesAndModels}>
            <Card title={i18n.t('page.filters.labels.makeAndModels')}>
              <FilterMakesModels data-testid={suffixTestId('filterMakesModels', props)} />
            </Card>
          </div>
          <div id={FilterBlockEnum.specification}>
            <Card title={i18n.t('page.filters.labels.specification')}>
              <FilterSpecification data-testid={suffixTestId('filterSpecification', props)} />
            </Card>
          </div>
          <div id={FilterBlockEnum.features}>
            <Card title={i18n.t('general.labels.allFeatures')}>
              <FilterFeatures data-testid={suffixTestId('filterFeatures', props)} />
            </Card>
          </div>
        </TilesWrapper>
      </SourcingContent>
    </VStack>
  );
}
