import {Action, Attributes, Card, Flag, openDialog, Separator} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';

import {toPairs} from 'ramda';

import {NotificationDefinitionResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {buildArray} from 'shared';

import {EmailExamplePreview} from './EmailExamplePreview';
import {NotificationExamplePreview} from './NotificationExamplePreview';

interface NotificationCardProps {
  notification: NotificationDefinitionResponseBody;
}

export function NotificationCard(props: NotificationCardProps) {
  const notificationRows = [
    {
      label: i18n.t('page.demo.notificationObligation'),
      value: props.notification.obligation,
    },
    {
      label: i18n.t('page.demo.notificationChannels'),
      content: (
        <HStack spacing={2}>
          {props.notification.channels?.map((channel) => (
            <Flag label={channel} key={channel} size="small" colorScheme="blue" isSubtle />
          ))}
        </HStack>
      ),
    },
  ];

  const recipientsSettingsRows = toPairs(props.notification.defaultRecipientSettings ?? {}).map(
    ([key, value]) => ({label: key, value})
  );

  const openNotificationPreviewDialog = () =>
    openDialog(<NotificationExamplePreview notificationId={props.notification.id} />, {
      disableBodyPadding: true,
      withAdditionalFooter: true,
      title: i18n.t('page.demo.notificationPreview'),
    });

  const openEmailPreviewDialog = () =>
    openDialog(<EmailExamplePreview notificationId={props.notification.id} />, {
      disableBodyPadding: true,
      size: 'large',
      withAdditionalFooter: true,
      title: i18n.t('page.demo.emailPreview'),
    });

  const actions = buildArray<Action>()
    .when(props.notification.channels.includes('email'), {
      type: 'button',
      variant: 'link',
      title: i18n.t('page.demo.emailPreview'),
      onClick: openEmailPreviewDialog,
    })
    .when(props.notification.channels.includes('toastNotification'), {
      type: 'button',
      variant: 'link',
      title: i18n.t('page.demo.notificationPreview'),
      onClick: openNotificationPreviewDialog,
    });

  return (
    <Card
      title={props.notification.id}
      key={props.notification.id}
      variant={props.notification.isEnabled ? 'inlineGrey' : 'inlineWhite'}
      isExpandable
      isClosedByDefault
      actions={actions}
    >
      <Attributes size="quarter" rows={notificationRows} />
      <Separator />
      <Heading size={4}>{i18n.t('page.demo.defaultNotificationSettings')}</Heading>
      <Attributes size="quarter" rows={recipientsSettingsRows} />
    </Card>
  );
}
