import {Nullish} from 'utility-types';

import {GetTireSetApiResponse} from '@dms/api';

export const extractWheelIds = (data?: GetTireSetApiResponse | Nullish) => {
  if (!data) {
    return [];
  }

  const wheelIds = data.wheels?.filter((item) => !!item).map((item) => ({id: item.id || ''}));

  if (!wheelIds?.length) {
    return [];
  }

  return wheelIds;
};
