import {openDialog} from 'platform/components';

import {isNil, isNotNil} from 'ramda';

import {
  ComplaintResponseBody,
  InspectionType,
  useCreateAuditMutation,
  useLazyGetInspectionTemplatesQuery,
  usePatchComplaintInspectionMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {composePath, Nullish} from 'shared';

import {VehicleComplaintWizard} from '../components/VehicleComplaintWizard/VehicleComplaintWizard';
import {handleApiError} from '../utils/handleApiError';

interface VehicleComplaintHookProps {
  vehicleId: string;
  saleBusinessCaseId: string | Nullish;
}

const INSPECTION_TYPE = InspectionType.COMPLAINT;

export function useVehicleComplaint(props: VehicleComplaintHookProps) {
  const [getInspectionTemplates, {isLoading: isTemplatesLoading}] =
    useLazyGetInspectionTemplatesQuery();
  const [addAuditToComplaint, {isLoading: isComplaintLoading}] =
    usePatchComplaintInspectionMutation();
  const [createAudit, {isLoading: isAuditLoading}] = useCreateAuditMutation();

  const isInspectionButtonLoading = isTemplatesLoading || isComplaintLoading || isAuditLoading;

  const openInspection = (inspectionId: string) => {
    window.open(
      composePath(vehiclesRoutes.inspectionDetail, {
        params: {
          id: props.vehicleId,
          inspectionId,
        },
      })
    );
  };

  const handleInspectionButton = (complaint: ComplaintResponseBody) => {
    isNotNil(complaint.inspectionId)
      ? openInspection(complaint.inspectionId)
      : handleCreateInspection(complaint.id);
  };

  const handleCreateInspection = (complaintId: string) => {
    getInspectionTemplates({})
      .unwrap()
      .then((inspectionTemplates) => {
        const inspectionTemplate = inspectionTemplates.find(
          (template) => template.inspectionType === INSPECTION_TYPE
        );

        if (!inspectionTemplate) {
          return Promise.reject();
        }

        return createAudit({
          vehicleId: props.vehicleId,
          body: {
            inspectionType: INSPECTION_TYPE,
            auditStructureId: inspectionTemplate.id,
          },
        }).unwrap();
      })
      .then((audit) =>
        addAuditToComplaint({
          complaintId,
          body: {
            inspectionId: audit.id,
          },
        })
          .unwrap()
          .then(() => {
            openInspection(audit.id);
          })
      )
      .catch(handleApiError);
  };

  const openComplaintWizard = () =>
    openDialog(
      <VehicleComplaintWizard
        vehicleId={props.vehicleId}
        onInspectionClick={handleInspectionButton}
        isInspectionButtonLoading={isInspectionButtonLoading}
        saleBusinessCaseId={props.saleBusinessCaseId}
      />,
      {
        title: i18n.t('entity.vehicle.actions.newComplaint'),
        size: 'large',
      }
    );

  const editComplaint = (complaintId: string | Nullish, code: string | Nullish) => {
    if (isNil(complaintId) || isNil(code)) {
      return;
    }

    openDialog(
      <VehicleComplaintWizard
        vehicleId={props.vehicleId}
        complaintId={complaintId}
        onInspectionClick={handleInspectionButton}
        isInspectionButtonLoading={isInspectionButtonLoading}
        saleBusinessCaseId={props.saleBusinessCaseId}
      />,
      {
        title: `${i18n.t('entity.vehicleComplaint.labels.complaint')} ${code}`,
        size: 'large',
      }
    );
  };

  return {openComplaintWizard, editComplaint, handleInspectionButton, isInspectionButtonLoading};
}
