import {CheckboxTreeOptionType} from 'platform/components';

import {useState} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetAlphaCatalogueQuery, GetAlphaCatalogueApiResponse} from '@dms/api';
import i18n, {FALLBACK_LANGUAGE} from '@dms/i18n';

import {Nullish} from 'shared';

export type VehicleFeaturesReturnType = {
  featureOptions: CheckboxTreeOptionType[] | Nullish;
  filteredFeatureOptions: CheckboxTreeOptionType[] | Nullish;
  featureSearchValue: string | null;
  setFeatureSearchValue: (value: string | null) => void;
  isError: boolean;
  isLoading: boolean;
};

/**
 * @deprecated Use "useVehicleFeaturesV2" instead
 */
export function useVehicleFeatures(type: string | Nullish): VehicleFeaturesReturnType {
  const {data, isError, isLoading} = useGetAlphaCatalogueQuery({
    lang: i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE,
  });

  const vehicleType = isNotNilOrEmpty(type) ? type! : 'VEHICLETYPE_PASSENGER_CAR';

  const [featureSearchValue, setFeatureSearchValue] = useState<string | null>(null);

  const featureKeys = data?.find(
    (catalogue) => catalogue.vehicle_type === vehicleType && catalogue.property_name === 'feature'
  )?.keys;

  const featureThree = getFeatureThreeOptions(featureKeys);
  const featureOptions = isNotNilOrEmpty(featureThree) ? featureThree : null;

  const filteredFeatures =
    featureSearchValue && featureKeys
      ? featureKeys.filter((feature) =>
          feature.labels[0]?.label
            .toLocaleUpperCase()
            .includes(featureSearchValue.toLocaleUpperCase())
        )
      : featureKeys;

  const filteredFeatureOptions = isNotNilOrEmpty(featureKeys)
    ? getFeatureThreeOptions(getFilteredFeaturesWithParents(filteredFeatures, featureKeys!))
    : null;

  return {
    featureOptions,
    filteredFeatureOptions,
    featureSearchValue,
    setFeatureSearchValue,
    isError,
    isLoading,
  };
}

export function getFilteredFeaturesWithParents(
  filteredKeys: GetAlphaCatalogueApiResponse[number]['keys'] | Nullish,
  keys: GetAlphaCatalogueApiResponse[number]['keys']
): GetAlphaCatalogueApiResponse[number]['keys'] {
  if (!filteredKeys || !filteredKeys?.length) {
    return [];
  }

  const missingParent = filteredKeys.filter(
    (item) => !filteredKeys.some((otherItem) => otherItem.const_key === item.parent)
  );
  const missingParentItems = keys.filter((item) =>
    missingParent.some((otherItem) => item.const_key === otherItem.parent)
  );

  return filteredKeys.concat(getFilteredFeaturesWithParents(missingParentItems, keys));
}

export function getFeatureThreeOptions(
  keys: GetAlphaCatalogueApiResponse[number]['keys'] | Nullish,
  id?: string
): CheckboxTreeOptionType[] {
  return (
    keys
      ?.filter((item) => item.parent === (id ?? null))
      .map((item) => ({
        label: item.labels[0]?.label ?? item.const_key,
        value: item.const_key,
        options: getFeatureThreeOptions(keys, item.const_key),
      })) ?? []
  );
}
