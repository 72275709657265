import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {loadDocumentSeriesList, SeriesTypeEnum, useThunkDispatch} from '@dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CustomerSeriesDefinitionDetail} from './components/CustomerSeriesDefinitionDetail';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.seriesDefinition.labels.customerCodeDefinition'),
    href: settingsRoutes.customerCodeDefinition,
  },
];

export function CustomerCodeDefinitionDetail() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(
      loadDocumentSeriesList({
        type: [SeriesTypeEnum.GENERAL_CUSTOMER],
        config: {sendBranch: false},
      })
    );
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{breadcrumbs}}
      data-testid="integrations-customer-definitions-settings-modal"
    >
      <CustomerSeriesDefinitionDetail />
    </SettingsTemplate>
  );
}
