import {Button, closeCurrentDialog} from 'platform/components';
import {Link, Right, Space, Text, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {TestIdProps, composePath, suffixTestId, useNavigate} from 'shared';

interface VehicleTypeFailedNotificationProps extends TestIdProps {
  vehicleId: string;
}

export function VehicleTypeFailedNotification(props: VehicleTypeFailedNotificationProps) {
  const navigate = useNavigate();

  const handleRedirectToPublishing = () => {
    navigate(composePath(vehiclesRoutes.publishing, {params: {id: props.vehicleId}}));
  };

  return (
    <VStack spacing={2}>
      <Text>{i18n.t('entity.vehicle.labels.vehicleTypeUpdateFailed')}</Text>

      <Link
        onClick={handleRedirectToPublishing}
        title={i18n.t('entity.vehicle.actions.redirectToPublication')}
        data-testid={suffixTestId('redirectToPublication', props)}
      />

      <Space vertical={2} />

      <Right>
        <Button
          title={i18n.t('general.actions.confirm')}
          data-testid={suffixTestId('confirm', props)}
          onClick={closeCurrentDialog}
        />
      </Right>
    </VStack>
  );
}
