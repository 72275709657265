import {Clickable, Icon} from 'platform/foundation';
import styled, {css, DefaultTheme} from 'styled-components';

import {Fragment, ReactNode, RefObject, UIEvent, useMemo, useRef, useState} from 'react';
import {Draggable, Droppable} from 'react-beautiful-dnd';

import {SourcingVehicleDetailResponseBody} from '@dms/api';
import {DragAndDropContext, DraggedItem, noop} from '@dms/teas';

import {isNotNilOrZero, suffixTestId, TestIdProps} from 'shared';

import {SectionType} from '../../../types/SectionType';
import {ComparisonTableColumnClone} from './ComparisonTableColumnClone';

const Container = styled(DragAndDropContext)`
  height: 100%;
  width: calc(100% - 16px);
  position: relative;
  z-index: ${({theme}) => theme.zIndices.SIDEBAR};
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
`;

type TrProps = {
  isEven?: boolean;
};

const Tr = styled.tr<TrProps>`
  & > td.is-selected {
    right: 0;
    left: 150px;
    position: sticky;
    background-color: #f5fbff; /* TODO: update color once design is finished */
    border: 0;
    opacity: 1;
    filter: none;

    &::before {
      content: '';
      left: -1px;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      border-right: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
      border-left: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
    }
  }

  ${({isEven, theme}) =>
    isEven
      ? `
				> td {
					background-color: ${theme.colors.palettes.neutral[10][100]};
				}

				> td.is-selected {
					background-color: #EEF7FF; // TODO: update color once design is finished
				}
			`
      : null}
`;

type ThProps = {
  hasBorder?: boolean;
  disabled?: boolean;
};

const Th = styled.td<ThProps>`
  top: 0;
  padding: 0;
  max-width: 150px;
  min-width: 150px;
  position: sticky;
  background-color: ${({theme}) =>
    theme.colors.palettes.white[10][100]}; /* TODO: update color once design is finished */

  border-right: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  vertical-align: top;
  z-index: 9;

  ${({disabled}) =>
    disabled &&
    `
			pointer-events: none;
		`}

  ${({theme, hasBorder}) =>
    hasBorder
      ? `
				&:after {
					content: "";
					width: 100%;
					height: 1px;
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					border-bottom: 1px solid ${theme.colors.palettes.neutral[40][100]};
				}
			`
      : null}
`;

type TdProps = {
  isTitle?: boolean;
  isSubtitle?: boolean;
  disabled?: boolean;
  theme: DefaultTheme;
};

const Td = styled.td<TdProps>`
  height: 24px;
  padding: 0 8px;
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  border-right: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  z-index: 9;

  ${({theme, isTitle}) =>
    isTitle
      ? `
        background-color: ${theme.colors.palettes.neutral[20][100]};
        border-bottom: 1px solid ${theme.colors.palettes.neutral[40][100]};
      `
      : null}

  ${({theme, isSubtitle}) =>
    isSubtitle
      ? `
        border-bottom: 0;
        background-color: ${theme.colors.palettes.neutral[20][100]} !important;
      `
      : null}

  ${({theme, isTitle, isSubtitle}) =>
    isTitle || isSubtitle
      ? `
				left: 0;
				padding: 0 0 0 16px;
				border-right: 0;
				position: sticky;
				z-index: ${theme.zIndices.MAIN_MENU};

				&:after {
					content: "";
					width: 1px;
					height: 100%;
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					border-right: 1px solid ${theme.colors.palettes.neutral[40][100]};
				}
			`
      : null}

  ${({disabled}) =>
    disabled &&
    `
		filter: grayscale(1) opacity(0.6);
		pointer-events: none;
	`}
`;

const LeftTopEmptyCell = styled.td<ThProps>`
  top: 0;
  left: 0;
  z-index: ${({theme}) => theme.zIndices.MODAL};
  min-width: 150px;
  position: sticky;
  background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }

  &::before {
    width: 1px;
    height: 100%;
    right: 0;
    top: 0;
    border-right: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  }

  &::after {
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    border-bottom: ${({theme, hasBorder}) =>
      hasBorder ? `1px solid ${theme.colors.palettes.neutral[40][100]}` : 'none'};
  }
`;

type OrderButtonsContainerProps = {
  $hasHover?: boolean;
};

const OrderButtonTh = styled.th<OrderButtonsContainerProps>`
  height: 24px;
  padding: 0 8px;
  position: relative;

  > div {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    height: 20px;
    vertical-align: middle;
    color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
    visibility: ${({$hasHover}) => ($hasHover ? 'visible' : 'hidden')};
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
`;

const OrderButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  background: none;
`;

interface ComparisonTableProps extends TestIdProps {
  data: SourcingVehicleDetailResponseBody[];
  sections: SectionType[];
  onDragEnd?: (draggedItem: DraggedItem) => void;
  renderHeaderCell?: (item: SourcingVehicleDetailResponseBody) => ReactNode;
}

export function ComparisonTable({
  data = [],
  sections = [],
  onDragEnd,
  renderHeaderCell = noop,
  ...props
}: ComparisonTableProps) {
  const scrollTop = useRef(0);
  const isTicking = useRef(false);

  const containerRef = useRef<HTMLDivElement>();
  const [hasBorder, setHasBorder] = useState(false);
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const EMPTY_TD = useMemo(
    () =>
      new Array(data.length).fill(null).map((_, index) => (
        <Td
          key={index}
          css={css`
            border-bottom: 1px solid ${({theme}: TdProps) => theme.colors.palettes.neutral[40][100]};
            background-color: ${({theme}: TdProps) =>
              theme.colors.palettes
                .white[10][100]}; /* TODO: update color once design is finished */
          `}
        />
      )),
    [data.length]
  );

  const handleMouseEnter = (i: number) => () => {
    setColumnIndex(i);
  };

  const handleMouseLeave = () => {
    setColumnIndex(null);
  };

  const handleScroll = (e: UIEvent<HTMLElement>) => {
    scrollTop.current = e.currentTarget.scrollTop;

    requestTick();
  };

  const update = () => {
    isTicking.current = false;

    setHasBorder(scrollTop.current >= 40);
  };

  const requestTick = () => {
    if (!isTicking.current) {
      requestAnimationFrame(update);
    }

    isTicking.current = true;
  };

  return (
    <Container onDragEnd={onDragEnd}>
      <TableContainer onScroll={handleScroll} ref={containerRef as RefObject<HTMLDivElement>}>
        <Table data-testid={suffixTestId('comparisonTable', props)}>
          <thead>
            {typeof onDragEnd === 'function' ? (
              <tr>
                <LeftTopEmptyCell />
                {data.map((d, i) => {
                  const vehicleData = d.sourcingVehicle;
                  return (
                    <OrderButtonTh key={vehicleData?.id} $hasHover={columnIndex === i}>
                      <Droppable
                        droppableId={vehicleData?.id}
                        renderClone={(provided) => (
                          <ComparisonTableColumnClone
                            vehicle={d}
                            sections={sections}
                            ref={provided.innerRef}
                            {...provided}
                            data-testid={suffixTestId('comparisonTableColumnClone', props)}
                          />
                        )}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            <OrderButton
                              type="button"
                              onClick={() =>
                                onDragEnd({
                                  source: {
                                    index: i,
                                    droppableId: vehicleData?.id,
                                  },
                                  destination: {
                                    index: i === 0 ? i : i - 1,
                                    droppableId: vehicleData?.id,
                                  },
                                })
                              }
                            >
                              <Icon value="navigation/arrow_back_ios" size={4} />
                            </OrderButton>
                            <Draggable index={i} draggableId={vehicleData?.id}>
                              {(providedDrag) => (
                                <div
                                  ref={providedDrag.innerRef}
                                  {...providedDrag.draggableProps}
                                  {...providedDrag.dragHandleProps}
                                  style={{}}
                                >
                                  <OrderButton type="button">
                                    <Icon value="action/drag_indicator" size={4} />
                                  </OrderButton>
                                </div>
                              )}
                            </Draggable>

                            <OrderButton type="button">
                              <Clickable
                                onClick={() =>
                                  onDragEnd({
                                    source: {
                                      index: i,
                                      droppableId: vehicleData?.id,
                                    },
                                    destination: {
                                      index: i === data.length ? i : i + 1,
                                      droppableId: vehicleData?.id,
                                    },
                                  })
                                }
                              >
                                <Icon value="navigation/arrow_forward_ios" size={4} />
                              </Clickable>
                            </OrderButton>

                            <div
                              css={css`
                                display: none;
                              `}
                            >
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </OrderButtonTh>
                  );
                })}
              </tr>
            ) : null}
            <tr>
              <LeftTopEmptyCell hasBorder={hasBorder} />
              {data.map((d, i) => (
                <Th
                  key={i}
                  hasBorder={hasBorder}
                  onMouseEnter={handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                  disabled={!d.sourcingVehicle.isAvailable}
                >
                  {renderHeaderCell(d)}
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sections.map(({key, Header, items}) => (
              <Fragment key={key}>
                <tr>
                  <Td isTitle>{Header}</Td>
                  {EMPTY_TD.map((td) => td)}
                </tr>
                {items.map(({Header: SubtitleHeader, Cell, trCss, tdCss, subtitleCss}, index) => (
                  <Tr key={index} isEven={isNotNilOrZero(index % 2)} css={trCss}>
                    <Td css={subtitleCss} isSubtitle>
                      {SubtitleHeader}
                    </Td>
                    {data.map((d, i) => (
                      <Td key={i} css={tdCss} disabled={!d.sourcingVehicle.isAvailable}>
                        {typeof Cell === 'function' ? Cell(d) : null}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
