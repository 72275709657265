import {Alert} from 'platform/components';

import {FC} from 'react';

import i18n from '@dms/i18n';

import {HintType, HintTypeEnum} from '../types/CommonVehicleTypes';

type HintsProp = {
  hintType: HintType;
  callback?: () => void;
  createMode?: boolean;
};

export const VehicleDuplicatesHints: FC<HintsProp> = ({hintType, callback, createMode}) => {
  switch (hintType) {
    case HintTypeEnum.ErrorVin:
      return (
        <Alert
          type="banner"
          variant="error"
          title={i18n.t('entity.vehicle.labels.VINExists')}
          message={
            createMode
              ? i18n.t('entity.vehicle.labels.correctVIN')
              : i18n.t('entity.vehicle.labels.cannotCreateSameVIN')
          }
        />
      );
    case HintTypeEnum.InfoLicense:
      return (
        <Alert
          type="banner"
          variant="info"
          title={i18n.t('entity.vehicle.labels.licensePlateExists')}
          message={createMode ? i18n.t('entity.vehicle.labels.selectExistingVehicle') : undefined}
          onClose={callback}
        />
      );
    default:
      return null;
  }
};
