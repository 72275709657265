import {ButtonGroup, DialogFooter} from 'platform/components';
import {Grid, Heading, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import i18n from '@dms/i18n';

import {suffixTestId} from 'shared';

import {SignDocumentStep} from '../types/SignDocumentStep';
import {StepContentProps} from '../types/StepContentProps';
import {ModeBox} from './ModeBox';

export function ModeSelectStep(props: StepContentProps) {
  const [mode, setMode] = useState<'onSite' | 'remote' | null>(null);

  const handleRedirect = () => {
    const redirectStep: SignDocumentStep =
      mode === 'onSite' ? 'OnSite_deviceSelect' : 'Remote_signatoriesSelect';

    props.setSigningStep(redirectStep);
  };

  return (
    <VStack spacing={8}>
      <Heading size={3}>{i18n.t('entity.document.labels.chooseMode')}</Heading>

      <Grid columns={2}>
        <ModeBox
          isSelected={mode === 'onSite'}
          description={i18n.t('entity.document.labels.onSiteDescription')}
          title={i18n.t('entity.document.labels.onSite')}
          icon="action/send_to_mobile"
          onClick={() => setMode('onSite')}
        />

        <ModeBox
          isSelected={mode === 'remote'}
          description={i18n.t('entity.document.labels.remoteDescription')}
          title={i18n.t('entity.document.labels.remote')}
          icon="action/mail_upload"
          onClick={() => setMode('remote')}
        />
      </Grid>

      <DialogFooter>
        <ButtonGroup
          buttons={[
            {
              title: i18n.t('general.actions.continue'),
              variant: 'primary',
              isDisabled: isNil(mode),
              onClick: handleRedirect,
            },
          ]}
          align="right"
          data-testid={suffixTestId('continue', props)}
        />
      </DialogFooter>
    </VStack>
  );
}
