import {FC, ReactNode} from 'react';

import i18n from '@dms/i18n';

import {noop} from '../../utils/someTeasUtils';
import {BulkWrapper, BulkButtonsWrapper, BulkButtonsDivider, BulkButton} from './styles';

export type ActionType = {
  label?: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export type BulkActionsProps = {
  itemsSelected: number;
  actions?: ActionType[];
  onCancel?: () => void;
  tableName?: string;
};

export const BulkActionsBar: FC<BulkActionsProps> = ({
  itemsSelected,
  actions,
  onCancel,
  tableName,
}) => (
  <BulkWrapper>
    <div>
      {itemsSelected}&nbsp;
      {i18n.t('general.labels.itemsSelected')}
    </div>
    <BulkButtonsWrapper>
      {actions?.map(({label, icon, onClick, disabled}) => (
        <BulkButton
          key={`${tableName}-${label}`}
          compact
          primary
          icon={icon}
          onClick={() => (onClick !== undefined ? onClick() : noop)}
          disabled={disabled}
          data-testid={`${tableName}-${label}`}
        >
          {label}
        </BulkButton>
      ))}
      <BulkButtonsDivider />
      <BulkButton
        compact
        primary
        onClick={() => (onCancel !== undefined ? onCancel() : noop)}
        data-testid={`${tableName}-cancel`}
      >
        {i18n.t('general.actions.cancel')}
      </BulkButton>
    </BulkButtonsWrapper>
  </BulkWrapper>
);
