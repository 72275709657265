import {Box, HStack, VStack} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {selectTenant} from '@dms/api';
import i18n from '@dms/i18n';
import {AutoSave, Form, ContactPersonRequestBody, $ContactPersonRequestBody} from '@dms/teas';

export interface ContactPersonFormProps {
  initialValues?: ContactPersonRequestBody;
  onChange: (requestBody: ContactPersonRequestBody) => void;
}

export const ContactPersonForm: FC<ContactPersonFormProps> = ({initialValues, onChange}) => {
  const {data} = useSelector(selectTenant);
  const countryCode = data?.country;

  return (
    <Form<ContactPersonRequestBody>
      onSubmit={onChange}
      initialValues={initialValues}
      schema={$ContactPersonRequestBody}
      defaultValues={{
        isPrimary: false,
        phoneNumber: {
          countryCode,
        },
      }}
      render={({Field, handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <AutoSave<ContactPersonRequestBody> save={onChange} />
          <VStack spacing={0}>
            <HStack spacing={4}>
              <Field name="firstName" label={i18n.t('entity.person.labels.firstName')} />
              <Field name="lastName" label={i18n.t('entity.person.labels.lastName')} />
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <Field name="role" label={i18n.t('entity.person.labels.role')} />
              </Box>
              <Box flex={1} />
            </HStack>
            <HStack spacing={4}>
              <Field
                as="phone"
                name="phoneNumber"
                label={i18n.t('entity.person.labels.phoneNumber')}
              />
              <Field name="emailAddress" label={i18n.t('general.labels.emailAddress')} />
            </HStack>
          </VStack>
        </form>
      )}
    />
  );
};
