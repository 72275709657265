import {isString} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehouseAccount() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    switch (actionKey) {
      case 'redirectDetail':
        if ('id' in rowData && isString(rowData.id)) {
          navigate(composePath(settingsRoutes.warehousesAccountDetail, {params: {id: rowData.id}}));
        }
        break;
    }
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.warehouseAccount'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newWarehouseAccount'),
        onClick: () => navigate(settingsRoutes.warehousesAccountCreate),
      },
    ],
  };

  return (
    <SettingsTemplate data-testid={testIds.settings.warehousesAccount('template')} header={header}>
      <DataGrid
        gridCode="warehouse-account"
        actionCallback={actionCallback}
        emptyState={{
          headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
        }}
      />
    </SettingsTemplate>
  );
}
