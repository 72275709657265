import {
  Dropdown,
  DropdownItem,
  openDialog,
  closeCurrentDialog,
  openDeleteDialog,
} from 'platform/components';
import {HStack, Link} from 'platform/foundation';

import {isNil} from 'ramda';

import {useGetBusinessCaseQuery, usePatchBusinessCaseCustomCodeMutation} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {CustomIdForm} from './CustomIdForm';

export interface CustomIdProps extends TestIdProps {
  recordId: string;
}

export function BusinessCaseCustomId(props: CustomIdProps) {
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId: props.recordId});
  const [patchBusinessCaseCustomCode] = usePatchBusinessCaseCustomCodeMutation();

  const handleUpdateCustomId = async (customCode: string | null) => {
    await patchBusinessCaseCustomCode({
      businessCaseId: props.recordId,
      patchBusinessCaseCustomCodeRequestBody: {customCode},
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const openCustomIdDialog = (defaultValue?: string | Nullish) =>
    openDialog(
      <CustomIdForm
        onClose={closeCurrentDialog}
        onSubmit={({customCode}) => handleUpdateCustomId(customCode ?? null)}
        customCode={defaultValue}
        text={i18n.t('page.businessCase.labels.customIdDescription')}
      />,
      {
        size: 'small',
        title: defaultValue
          ? i18n.t('entity.businessCase.actions.editCustomId')
          : i18n.t('entity.businessCase.actions.addCustomId'),
      }
    );

  const onCustomIdClick = () => {
    if (businessCase?.customCode) {
      return;
    }
    openCustomIdDialog();
  };

  const handleDelete = () => {
    openDeleteDialog({onConfirm: () => handleUpdateCustomId(null)});
  };

  if (isNil(businessCase?.customCode)) {
    return (
      <HStack align="center" spacing={1}>
        <Link
          size="small"
          leftIcon="action/code"
          title={i18n.t('entity.businessCase.labels.customId')}
          onClick={onCustomIdClick}
          data-testid={suffixTestId('customId', props)}
        />
      </HStack>
    );
  }

  return (
    <Dropdown
      dropdownControl={
        <Link
          size="small"
          leftIcon="action/code"
          title={businessCase?.customCode}
          onClick={onCustomIdClick}
          data-testid={suffixTestId('customId', props)}
        />
      }
      data-testid={suffixTestId('customId-dropdown', props)}
    >
      <DropdownItem
        label={i18n.t('general.actions.edit')}
        onClick={() => openCustomIdDialog(businessCase?.customCode)}
        data-testid={suffixTestId('customId-dropdown-edit', props)}
      />
      <DropdownItem
        label={i18n.t('general.actions.delete')}
        onClick={handleDelete}
        data-testid={suffixTestId('customId-dropdown-delete', props)}
      />
    </Dropdown>
  );
}
