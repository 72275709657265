import {GridItem, Grid} from 'platform/foundation';

import {FC} from 'react';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {useFormRenderer, VehicleSeriesDefinition, MakeSelect} from '@dms/teas';

import {useVehicleSeriesDefinitionContext} from '../hooks/useVehicleSeriesDefinitionContext';
import {VehicleSeriesDefinitionFormType} from '../types';

export const VehicleSeriesDefinitionFormFields: FC = () => {
  const {
    Field,
    Subscribe,
    form: {mutators},
  } = useFormRenderer<VehicleSeriesDefinitionFormType, VehicleSeriesDefinition>();
  const {vehicleTypes, branchList, seriesList, isSystem} = useVehicleSeriesDefinitionContext();

  return (
    <>
      <Grid columns={2}>
        <Field name="name" label={i18n.t('entity.seriesDefinition.labels.definitionName')} />
        <Field
          name="vehicleType"
          as="select"
          disabled={isSystem}
          options={vehicleTypes}
          label={i18n.t('entity.vehicle.labels.type')}
          placeholder={i18n.t('general.labels.select')}
          onChange={() => mutators.resetMakes?.()}
        />
      </Grid>
      <Grid columns={2}>
        <GridItem span={2}>
          <Subscribe
            name="vehicleType"
            component={({input: {value: vehicleType}}) => (
              <MakeSelect<VehicleSeriesDefinitionFormType>
                name="makes"
                multiple
                label={i18n.t('entity.vehicle.labels.make')}
                vehicleType={vehicleType}
                disabled={isSystem}
                closeMenuOnSelect={false}
                hasOptionCheckbox
                data-testid={testIds.vehicles.edit('vehicleDefinition')}
              />
            )}
          />
        </GridItem>
      </Grid>
      <Grid columns={2}>
        <Field
          name="branchId"
          as="select"
          disabled={isSystem}
          options={branchList}
          label={i18n.t('entity.branch.labels.branch')}
          placeholder={i18n.t('general.labels.select')}
          getOptionLabel={({marketingName}) => marketingName ?? ''}
          getOptionValue={({id}) => id}
        />
        <Field
          name="seriesId"
          as="select"
          disabled={isSystem}
          options={seriesList}
          label={i18n.t('page.vehicleSeriesDefinition.labels.definitionVehicleSeries')}
          placeholder={i18n.t('general.labels.select')}
          getOptionLabel={({name}) => name}
          getOptionValue={({id}) => id}
        />
      </Grid>
    </>
  );
};
