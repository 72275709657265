import {addDays, compareAsc, subDays} from 'date-fns';
import Decimal from 'decimal.js';
import {
  Button,
  ButtonGroup,
  Choice,
  CurrencyInput,
  FormButton,
  FormControl,
  FormField,
  Label,
  NumberInput,
  openConfirmDialog,
  OptionType,
  Tooltip,
} from 'platform/components';
import {Grid, GridItem, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {head, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AftersalesPaymentMethod,
  Payment,
  useGetTenantQuery,
  usePutCheckoutSetTypeOfSaleMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {
  buildArray,
  CurrencyCodeType,
  Nullish,
  precisionCalculation,
  RequiredTestIdProps,
  suffixTestId,
  useBoolean,
} from 'shared';

import {DEFAULT_FOREIGN_CURRENCY} from '../../../constants/currency';
import {useBank} from '../../../hooks/useBank';
import {useCashRegister} from '../../../hooks/useCashRegister';
import {handleApiError} from '../../../utils/handleApiError';
import {PredefinedNotes} from '../../PredefinedNotes/PredefinedNotes';
import {useFormValueChange} from '../hooks/useFormValueChange';
import {CheckoutPaymentFormType} from '../types/CheckoutPaymentFormType';

interface CheckoutPaymentFormBodyProps extends RequiredTestIdProps {
  checkoutId: string;
  control: FormControl<CheckoutPaymentFormType>;
  formApi: UseFormReturn<CheckoutPaymentFormType>;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  isSubmitButtonDisabled?: boolean;
  issueButtonBlockers?: string[];
  isForeignCurrencyAllowed?: boolean;
  payment: Payment;
  totalAmount?: number;
  onAddAnotherDepositClick?: () => Promise<unknown>;
  onRemoveDepositClick?: () => Promise<unknown>;
  onSaveChanges: (data: CheckoutPaymentFormType) => Promise<unknown>;
}

export function CheckoutPaymentFormBody(props: CheckoutPaymentFormBodyProps) {
  const {control, formApi} = props;

  const [oldPaymentMethod, setOldPaymentMethod] = useState<string | Nullish>(
    formApi.getValues('method')
  );

  const {bankAccountOptions} = useBank();
  const {cashRegisterOptions} = useCashRegister();
  const {data} = useGetTenantQuery();

  const [isSaveLoading, startSaveLoading, stopSaveLoading] = useBoolean();
  const [isAddLoading, startAddLoading, stopAddLoading] = useBoolean();
  const [isRemoveLoading, startRemoveLoading, stopRemoveLoading] = useBoolean();

  const [putCheckoutSetTypeOfSale] = usePutCheckoutSetTypeOfSaleMutation();

  const [issueDate, paymentMethod, note, exchangeRate, amount] = formApi.watch([
    'issueDate',
    'method',
    'note',
    'exchangeRate',
    'amount',
  ]);

  const minTaxableEventDate = issueDate ? subDays(issueDate, 15) : new Date();
  const maxTaxableEventDate = issueDate ? issueDate : new Date();

  const currencyData = data?.currency as CurrencyCodeType | undefined;

  const percentage = props.totalAmount
    ? parseFloat(
        precisionCalculation
          .multiply(precisionCalculation.divide(amount, props.totalAmount), 100)
          .toFixed(2)
      )
    : 0;

  useFormValueChange(
    formApi.watch('exchangeRate'),
    props.payment.exchangeRate ? parseFloat(props.payment.exchangeRate ?? '0') : null,
    (value) => formApi.setValue('exchangeRate', value)
  );

  useFormValueChange(formApi.watch('amount'), props.payment.paymentAmount.amount, (value) =>
    formApi.setValue('amount', value)
  );

  const handlePercentageChange = (value: number | null) => {
    const newPercentage = (props.totalAmount ?? 0) * ((value ?? 0) / 100);

    formApi.setValue('amount', newPercentage);
  };

  const afterPaymentChange = (newValue: AftersalesPaymentMethod) => {
    const issuedOn = formApi.getValues('issueDate');

    if (newValue === 'BANK_TRANSFER' && isNotNil(issuedOn)) {
      const DEFAULT_DUE_DATE = 14;

      formApi.setValue('due', DEFAULT_DUE_DATE, {shouldValidate: true});
      formApi.setValue('dueDate', addDays(issuedOn, DEFAULT_DUE_DATE), {shouldValidate: true});

      if (isNilOrEmpty(formApi.getValues('bankAccount'))) {
        formApi.setValue('bankAccount', head(bankAccountOptions)?.value ?? '', {
          shouldValidate: true,
        });
      }

      return;
    }

    if (newValue === 'CASH' && isNilOrEmpty(formApi.getValues('cashRegisterId'))) {
      formApi.setValue('cashRegisterId', head(cashRegisterOptions)?.value ?? '', {
        shouldValidate: true,
      });
    }

    if (isNotNil(issuedOn)) {
      formApi.setValue('due', 0, {shouldValidate: true});
      formApi.setValue('dueDate', formApi.getValues('issueDate'), {shouldValidate: true});
    }
  };

  const handleSetPaymentMethod = (value: AftersalesPaymentMethod) => {
    setOldPaymentMethod(value);
    formApi.setValue('method', value);
    afterPaymentChange(value);
  };

  const handlePaymentMethodChange = (newValue: AftersalesPaymentMethod | Nullish) => {
    if (isNil(newValue)) {
      return;
    }

    if (newValue === 'INTERNAL') {
      openConfirmDialog({
        onConfirm: async () => {
          await putCheckoutSetTypeOfSale({
            checkoutId: props.checkoutId,
            body: {typeOfSale: 'INTERNAL'},
          })
            .unwrap()
            .catch(handleApiError);
          handleSetPaymentMethod(newValue);
          handleSaveChanges();
        },
        text: i18n.t('entity.checkout.labels.freeOfChargeHelperText'),
      });
      return;
    }

    if (oldPaymentMethod === 'INTERNAL') {
      openConfirmDialog({
        onConfirm: async () => {
          await putCheckoutSetTypeOfSale({
            checkoutId: props.checkoutId,
            body: {typeOfSale: 'DEFAULT'},
          })
            .unwrap()
            .catch(handleApiError);
          handleSetPaymentMethod(newValue);
          handleSaveChanges();
        },
        text: i18n.t('entity.invoice.notifications.freeOfChargeChangeHelperText'),
      });
      return;
    }

    handleSetPaymentMethod(newValue);
  };

  const handleIssuedOnChange = (issueDate: Date | Nullish) => {
    if (isNil(issueDate)) {
      return;
    }

    const due = formApi.getValues('due') ?? 0;
    formApi.setValue('dueDate', addDays(issueDate, due), {shouldValidate: true});

    const taxableEventDate = formApi.getValues('dateOfTaxableSupply');

    const minTaxableEventDate = subDays(issueDate, 15);
    const maxTaxableEventDate = issueDate;

    if (!taxableEventDate) {
      return formApi.setValue('dateOfTaxableSupply', issueDate, {shouldValidate: true});
    }

    if (compareAsc(taxableEventDate, minTaxableEventDate) < 0) {
      return formApi.setValue('dateOfTaxableSupply', minTaxableEventDate, {shouldValidate: true});
    }

    if (compareAsc(taxableEventDate, maxTaxableEventDate) > 0) {
      return formApi.setValue('dateOfTaxableSupply', maxTaxableEventDate, {shouldValidate: true});
    }
  };

  const handleDueChange = (due: number | Nullish) => {
    if (isNil(due)) {
      return;
    }

    const issuedOn = formApi.getValues('issueDate');

    if (isNotNil(issuedOn)) {
      formApi.setValue('dueDate', addDays(issuedOn, due), {shouldValidate: true});
    }
  };

  const handleDueDateChange = (date: Date | Nullish) => {
    if (isNil(date)) {
      return;
    }

    const due = formApi.getValues('due') ?? 0;
    formApi.setValue('issueDate', subDays(date, due), {shouldValidate: true});
  };

  const handleSaveChanges = () => {
    startSaveLoading();
    props.onSaveChanges(formApi.getValues()).finally(stopSaveLoading);
  };

  const handleAddAnotherDeposit = () => {
    if (!props.onAddAnotherDepositClick) {
      return;
    }

    startAddLoading();
    props.onAddAnotherDepositClick().finally(stopAddLoading);
  };

  const handleRemoveDeposit = () => {
    if (!props.onRemoveDepositClick) {
      return;
    }

    startRemoveLoading();
    props.onRemoveDepositClick().finally(stopRemoveLoading);
  };

  const orderPaymentOptions = buildArray<OptionType<AftersalesPaymentMethod>>()
    .whenNot(props.disallowedPaymentMethods?.includes('BANK_TRANSFER'), {
      label: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
      value: 'BANK_TRANSFER',
    })
    .whenNot(props.disallowedPaymentMethods?.includes('CARD'), {
      label: i18n.t('entity.invoice.paymentMethod.card'),
      value: 'CARD',
    })
    .whenNot(props.disallowedPaymentMethods?.includes('CASH'), {
      label: i18n.t('entity.invoice.paymentMethod.cash'),
      value: 'CASH',
    })
    .whenNot(props.disallowedPaymentMethods?.includes('INTERNAL'), {
      label: i18n.t('general.labels.internal'),
      value: 'INTERNAL',
    });

  const foreignCurrencyAmount = exchangeRate
    ? parseFloat(new Decimal(amount).div(exchangeRate).toFixed(2))
    : 0;

  return (
    <VStack spacing={4}>
      <Grid spacing={4} columns={4}>
        <FormField
          control={control}
          name="amount"
          type="currency"
          currency={currencyData}
          label={i18n.t('general.labels.amount')}
          isDisabled={props.payment.discriminator === 'BALANCE'}
          isRequired
          data-testid={suffixTestId('amount', props)}
        />
        <Show when={props.payment.discriminator === 'DEPOSIT'}>
          <NumberInput
            suffix="%"
            value={percentage}
            onChange={handlePercentageChange}
            label={i18n.t('entity.order.labels.percentage')}
          />
        </Show>
        <Show when={props.isForeignCurrencyAllowed}>
          <FormField
            control={control}
            name="exchangeRate"
            type="currency"
            currency={currencyData}
            label={i18n.t('general.labels.exchangeRate')}
            isRequired
            data-testid={suffixTestId('exchangeRate', props)}
          />
          <CurrencyInput
            value={foreignCurrencyAmount}
            isDisabled
            label={i18n.t('entity.checkout.labels.amountInForeignCurrency')}
            currency={
              (props.payment.foreignCurrencyPaymentAmount?.currency ??
                DEFAULT_FOREIGN_CURRENCY) as CurrencyCodeType
            }
          />
        </Show>
      </Grid>
      <Grid spacing={4} columns={4}>
        <FormField
          control={control}
          name="issueDate"
          type="date"
          isRequired
          isRelativeDatesHidden
          label={i18n.t('entity.checkout.labels.issuedOn')}
          onChange={handleIssuedOnChange}
          data-testid={suffixTestId('issueDate', props)}
        />
        <FormField
          control={control}
          name="due"
          type="number"
          label={i18n.t('entity.checkout.labels.due')}
          onChange={handleDueChange}
          data-testid={suffixTestId('due', props)}
          minStepperValue={0}
          isStepperVisible
          isRequired
        />
        <FormField
          control={control}
          name="dueDate"
          type="date"
          isRelativeDatesHidden
          label={i18n.t('entity.checkout.labels.dueDate')}
          onChange={handleDueDateChange}
          data-testid={suffixTestId('dueDate', props)}
          isRequired
        />
        <FormField
          control={control}
          name="dateOfTaxableSupply"
          type="date"
          isRelativeDatesHidden
          label={i18n.t('entity.checkout.labels.taxableEventDate')}
          data-testid={suffixTestId('taxableEventDate', props)}
          maxDate={maxTaxableEventDate}
          minDate={minTaxableEventDate}
          isRequired
        />
        <GridItem span={2}>
          <Choice<AftersalesPaymentMethod>
            name="paymentMethod"
            value={formApi.getValues('method')}
            label={i18n.t('entity.checkout.labels.paymentMethod')}
            options={orderPaymentOptions}
            onChange={(value) => handlePaymentMethodChange(value)}
            isNotClearable
            data-testid={suffixTestId('paymentMethod', props)}
          />
        </GridItem>
        <GridItem span={2}>
          <Show when={paymentMethod === 'BANK_TRANSFER'}>
            <FormField
              control={control}
              name="bankAccount"
              type="choice"
              options={bankAccountOptions}
              label={i18n.t('entity.checkout.labels.incomingBankAccount')}
              isRequired
              isNotClearable
              data-testid={suffixTestId('incomingBankAccount', props)}
            />
          </Show>
          <Show when={paymentMethod === 'CASH'}>
            <FormField
              control={control}
              name="cashRegisterId"
              type="choice"
              options={cashRegisterOptions}
              label={i18n.t('entity.checkout.labels.cashRegister')}
              isNotClearable
              data-testid={suffixTestId('incomingBankAccount', props)}
            />
          </Show>
        </GridItem>
      </Grid>
      <VStack spacing={1}>
        <HStack justify="space-between" align="flex-end">
          <Label>{i18n.t('general.labels.note')}</Label>
          <PredefinedNotes
            note={note ?? null}
            onPrefill={(note) => props.formApi.setValue('note', note)}
            resource="SERVICE_CASE"
            context="service_checkout"
            isLinkVariant
            data-testid={suffixTestId('predefinedNotes', props)}
          />
        </HStack>
        <FormField
          control={control}
          name="note"
          type="textarea"
          data-testid={suffixTestId('note', props)}
        />
      </VStack>
      <HStack>
        {props.onAddAnotherDepositClick && (
          <Button
            variant="link"
            title={i18n.t('entity.checkout.addAnotherPayment')}
            leftIcon="content/add_circle"
            onClick={handleAddAnotherDeposit}
            isDisabled={isAddLoading}
            isLoading={isAddLoading}
          />
        )}
        <Space fillAvailable />
        <ButtonGroup>
          {props.onRemoveDepositClick && (
            <Button
              variant="dangerLink"
              title={i18n.t('entity.checkout.removePayment')}
              onClick={handleRemoveDeposit}
              isDisabled={isRemoveLoading}
              isLoading={isRemoveLoading}
            />
          )}
          <Button
            title={i18n.t('general.actions.saveChanges')}
            variant="secondary"
            onClick={handleSaveChanges}
            isDisabled={isSaveLoading || props.isSubmitButtonDisabled}
            isLoading={isSaveLoading}
            data-testid={suffixTestId('saveChanges', props)}
          />
          <Tooltip
            label={props.issueButtonBlockers?.map((blocker) => (
              <Text size="xSmall" alternative color="white" key={blocker}>
                {blocker}
              </Text>
            ))}
            placement="top-end"
            hasAutoWidth
          >
            <FormButton
              control={control}
              type="submit"
              isDisabled={
                props.isSubmitButtonDisabled || isNotNilOrEmpty(props.issueButtonBlockers)
              }
              title={i18n.t('entity.checkout.actions.issuePayment')}
              data-testid={suffixTestId('issuePayment', props)}
            />
          </Tooltip>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
}
