import {formatISO, isValid} from 'date-fns';
import {
  Actions,
  Card,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormField,
  isCurrency,
  Label,
} from 'platform/components';
import {Grid, Heading, HStack, Show, Space, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {boolean, mixed, number, object, string} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {head, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil, isTrue} from 'ramda-adjunct';

import {
  Money,
  useGetCashRegisterListQuery,
  useGetPaymentTypesForInvoicePaymentQuery,
  useShowPaymentPrescriptionQuery,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {
  EMPTY_PLACEHOLDER,
  getApiDateString,
  Nullish,
  parseDate,
  POSITIVE_NUMBER_REGEX,
  suffixTestId,
  yupString,
} from 'shared';

import {useGetDocumentData} from '../../hooks/useGetDocumentData';
import {getPaymentTypeFromMethod} from '../../utils/getPaymentTypeFromMethod';
import {CashRegisterInput} from './components/CashRegisterInput';
import {useSubmitInvoicePayment} from './hooks/useSubmitInvoicePayment';
import {InvoicePaymentFormValues} from './types/InvoicePaymentFormValues';
import {InvoicePaymentModalProps} from './types/InvoicePaymentModalProps';
import {getDefaultCreateReceiptByPaymentMethod} from './utils/getDefaultCreateReceiptByPaymentMethod';
import {getInvoiceIdFromProps} from './utils/getInvoiceIdFromProps';

const DEFAULT_EXCHANGE_RATE_AMOUNT = '1';

export function InvoicePaymentForm(props: InvoicePaymentModalProps) {
  const TODAYS_DATE = getApiDateString(new Date());
  const formatCurrency = useFormatCurrency();

  const {id, type} = getInvoiceIdFromProps(props);

  const formatHeadingPrice = (price: Money | Nullish) =>
    isNotNil(price)
      ? formatCurrency(parseFloat(price.amount), price.currency, 2)
      : EMPTY_PLACEHOLDER;

  const {
    data: invoice,
    isLoading: isInvoiceLoading,
    isError: isInvoiceError,
  } = useGetDocumentData(type, id);

  const {
    data: prescription,
    isLoading: isPrescriptionLoading,
    isError: isPrescriptionError,
  } = useShowPaymentPrescriptionQuery(
    {paymentPrescriptionId: invoice?.paymentPrescriptionId ?? ''},
    {skip: isNilOrEmpty(invoice?.paymentPrescriptionId)}
  );

  const {
    data: cashRegisters,
    isLoading: isLoadingCashRegister,
    isError: isCashRegisterError,
  } = useGetCashRegisterListQuery(
    {currency: prescription?.price.currency ?? ''},
    {skip: isNil(prescription?.price?.currency)}
  );

  const {
    data: paymentTypes,
    isLoading: isPaymentTypesLoading,
    isError: isPaymentTypesError,
  } = useGetPaymentTypesForInvoicePaymentQuery({});

  const paymentTypesOptions = paymentTypes?.map((item) => ({
    value: item.key.toLocaleUpperCase(),
    label: item.value,
  }));

  const isLoading =
    isInvoiceLoading || isPrescriptionLoading || isPaymentTypesLoading || isLoadingCashRegister;
  const isError =
    isInvoiceError || isPrescriptionError || isPaymentTypesError || isCashRegisterError;

  const remainsToPay = formatHeadingPrice(prescription?.remainsToPay);
  const amountToPay = formatHeadingPrice(prescription?.price);

  const currency = isCurrency(prescription?.price?.currency)
    ? prescription?.price?.currency
    : undefined;

  const invoiceCurrency = isCurrency(invoice?.currency) ? invoice?.currency : undefined;
  const isPaymentInDifferentCurrencies = currency !== invoiceCurrency;

  const [handleSubmit] = useSubmitInvoicePayment(props);

  const paymentType = getPaymentTypeFromMethod(invoice?.paymentInfo?.paymentMethod, false);

  const initialValues: Partial<InvoicePaymentFormValues> = {
    amount: prescription?.remainsToPay.amount,
    paymentDate: TODAYS_DATE,
    paymentType,
    shouldCreateReceipt: getDefaultCreateReceiptByPaymentMethod(
      invoice?.paymentInfo?.paymentMethod
    ),
    cashRegister: head(cashRegisters ?? [])?.id,
    exchangeRateRatio: {
      amount: DEFAULT_EXCHANGE_RATE_AMOUNT,
      ratio: invoice?.exchangeRateRatio?.ratio?.toString() ?? null,
    },
  };

  const handleOnPaymentTypeChange = (
    paymentType: string | string[] | Nullish | number,
    formApi: UseFormReturn<InvoicePaymentFormValues>
  ) => {
    if (paymentType !== 'BANK_TRANSFER') {
      formApi.resetField('cashRegister');
    }

    formApi.setValue('shouldCreateReceipt', getDefaultCreateReceiptByPaymentMethod(paymentType));
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={50}>
      <VStack spacing={4}>
        <HStack spacing={4}>
          <VStack width={41}>
            <Label>{i18n.t('general.labels.amount')}</Label>
            <Heading size={4} data-testid={suffixTestId('amountToPay', props)}>
              {amountToPay}
            </Heading>
          </VStack>

          <Show when={prescription?.state !== 'completed'}>
            <VStack width={41}>
              <Label>{i18n.t('entity.invoice.labels.remaining')}</Label>

              <Heading size={4} color="danger" data-testid={suffixTestId('remainsToPay', props)}>
                {remainsToPay}
              </Heading>
            </VStack>
          </Show>
        </HStack>

        <Form<InvoicePaymentFormValues>
          defaultValues={initialValues}
          onSubmit={handleSubmit}
          schema={getSchema(isPaymentInDifferentCurrencies)}
        >
          {(control, formApi) => {
            const paymentType = formApi.watch('paymentType');
            const isPayingByCardOrCash = paymentType === 'PAYMENT_CARD' || paymentType === 'CASH';

            return (
              <>
                <Card variant="inlineGrey">
                  <Grid columns={2}>
                    <FormField
                      name="amount"
                      control={control}
                      type="currency"
                      currency={currency}
                      isDisabled={type === 'PROFORMA'}
                      label={i18n.t('general.labels.amount')}
                      data-testid={suffixTestId('amount', props)}
                    />
                    <FormField
                      name="paymentDate"
                      control={control}
                      isRequired
                      maxDate={new Date()}
                      type="apiDate"
                      label={i18n.t('entity.invoice.labels.dateOfPayment')}
                      data-testid={suffixTestId('paymentDate', props)}
                    />
                    <VStack spacing={2}>
                      <FormField
                        name="paymentType"
                        control={control}
                        onChange={(paymentType) => handleOnPaymentTypeChange(paymentType, formApi)}
                        options={paymentTypesOptions}
                        isRequired
                        isNotClearable
                        type="choice"
                        menuInPortal
                        label={i18n.t('entity.bank.labels.paymentType')}
                        data-testid={suffixTestId('paymentType', props)}
                      />
                      <Show when={isPayingByCardOrCash}>
                        <FormField
                          data-testid={suffixTestId('shouldCreateReceipt', props)}
                          name="shouldCreateReceipt"
                          control={control}
                          type="checkbox"
                          label={i18n.t('entity.cashRegister.labels.createCashReceipt')}
                        />
                      </Show>
                    </VStack>

                    <Show when={isPayingByCardOrCash}>
                      <CashRegisterInput
                        control={control}
                        prescriptionCurrency={prescription?.price.currency}
                        data-testid={suffixTestId('cashRegister', props)}
                      />

                      <Show
                        whenFeatureEnabled={featureFlags.SALES_FOREIGN_CURRENCY}
                        when={isPaymentInDifferentCurrencies}
                      >
                        <FormField
                          name="exchangeRateRatio.amount"
                          control={control}
                          isDisabled
                          type="currency"
                          currency={currency}
                          label={i18n.t('general.labels.amount')}
                          data-testid={suffixTestId('exchangeRateRatio-amount', props)}
                        />
                        <FormField
                          name="exchangeRateRatio.ratio"
                          control={control}
                          type="currency"
                          currency={invoiceCurrency}
                          label={i18n.t('general.labels.exchangeRate')}
                          data-testid={suffixTestId('exchangeRateRatio-ratio', props)}
                        />
                      </Show>
                    </Show>
                  </Grid>
                </Card>
                <Space vertical={4} />
                <Actions
                  align="right"
                  data-testid={suffixTestId('invoicePaymentForm-actions', props)}
                  actions={[
                    {
                      title: i18n.t('general.actions.cancel'),
                      type: 'button',
                      onClick: closeCurrentDialog,
                      variant: 'secondary',
                    },
                    {
                      title: i18n.t('general.actions.confirm'),
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      'data-testid': suffixTestId('submit', props),
                    },
                  ]}
                />
              </>
            );
          }}
        </Form>
      </VStack>
    </DataStatus>
  );
}

const getSchema = (isForeignCurrencyEnabled: boolean) =>
  object({
    amount: string()
      .matches(POSITIVE_NUMBER_REGEX, i18n.t('general.notifications.numberPositive'))
      .nullable()
      .required(),
    paymentType: string().nullable().required(),
    shouldCreateReceipt: boolean().default(false),
    paymentDate: string()
      .required()
      .transform((stringDate: string | Nullish) => {
        if (!stringDate) {
          return undefined;
        }
        const parsedDate = parseDate(stringDate);
        if (!parsedDate || !isValid(parsedDate)) {
          return undefined;
        }
        return formatISO(parsedDate.getTime(), {representation: 'date'});
      })
      .test('mustBePast', i18n.t('general.validations.mustBePastOrPresent'), (stringDate) => {
        if (!stringDate) {
          return true;
        }

        const currentDate = Date.now();
        const date = parseDate(stringDate).getTime();

        return date <= currentDate;
      }),
    cashRegister: yupString.when(['shouldCreateReceipt', 'paymentType'], {
      is: (shouldCreateReceipt: boolean, paymentType: string) =>
        isTrue(shouldCreateReceipt) && paymentType !== 'BANK_TRANSFER',
      then: (schema) => schema.required(),
    }),
    exchangeRateRatio: mixed().when([], {
      is: () => isForeignCurrencyEnabled,
      then: object({
        amount: string()
          .oneOf(['1', '100', '1000'], i18n.t('general.notifications.ratioAmountError'))
          .nullable(),
        ratio: number().positive().nullable().required(),
      }),
      otherwise: mixed().nullable(),
    }),
  });
