import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Form,
  FormButton,
  FormField,
  OptionType,
  showNotification,
} from 'platform/components';
import {Grid, Heading, Hide, HStack, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {object, string} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {AftersalesPaymentMethod, Payment, usePostCheckoutRecordPaidMutation} from '@dms/api';
import i18n from '@dms/i18n';

import {
  buildArray,
  CurrencyCodeType,
  getApiDateString,
  Nullish,
  RequiredTestIdProps,
  suffixTestId,
  yupDate,
  yupNumber,
  yupString,
} from 'shared';

import {useBank} from '../../../hooks/useBank';
import {useCashRegister} from '../../../hooks/useCashRegister';
import {handleApiError} from '../../../utils/handleApiError';

interface PayDialogProps extends RequiredTestIdProps {
  checkoutId: string;
  payment: Payment;
  onClose: () => void;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
}

export function PayDialog(props: PayDialogProps) {
  const {getBankAccountByNumber, bankAccountOptions} = useBank();
  const {cashRegisters} = useCashRegister();
  const formatCurrency = useFormatCurrency();

  const [postCheckoutRecordPaid] = usePostCheckoutRecordPaidMutation();

  const paymentAmount = props.payment.foreignCurrencyPaymentAmount ?? props.payment.paymentAmount;
  const remainingPaymentAmount =
    props.payment.remainingForeignCurrencyPaymentAmount ?? props.payment.remainingPaymentAmount;

  const handlePayPayment = (data: PayFormType) => {
    const bankAccount = getBankAccountByNumber(data.tenantBankAccount);

    return postCheckoutRecordPaid({
      checkoutId: props.checkoutId,
      paymentId: props.payment.checkoutPaymentId,
      body: {
        amount: data.amount,
        currency: paymentAmount.currency,
        payDate: getApiDateString(data.payDate),
        withCashRegisterDocument: data.cashRegisterId ? data.withCashRegisterDocument : false,
        cashRegisterId: data.cashRegisterId,
        paymentMethod: data.paymentMethod,
        tenantBankAccount: bankAccount
          ? {
              currencyCode: bankAccount.currency ?? paymentAmount.currency,
              isPrimary: !!bankAccount.isPrimary,
              iban: bankAccount.iban,
              name: bankAccount.bankName,
              accountName: bankAccount.accountName ?? '',
              accountNumber: bankAccount.accountNumber ?? '',
              swift: bankAccount.swift,
            }
          : undefined,
      },
    })
      .unwrap()
      .then(props.onClose)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const currencyData = paymentAmount.currency as CurrencyCodeType;

  const orderPaymentOptions = buildArray<OptionType<AftersalesPaymentMethod>>()
    .whenNot(props.disallowedPaymentMethods?.includes('BANK_TRANSFER'), {
      label: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
      value: 'BANK_TRANSFER',
    })
    .whenNot(props.disallowedPaymentMethods?.includes('CARD'), {
      label: i18n.t('entity.invoice.paymentMethod.card'),
      value: 'CARD',
    })
    .whenNot(props.disallowedPaymentMethods?.includes('CASH'), {
      label: i18n.t('entity.invoice.paymentMethod.cash'),
      value: 'CASH',
    })
    .whenNot(props.disallowedPaymentMethods?.includes('INTERNAL'), {
      label: i18n.t('general.labels.internal'),
      value: 'INTERNAL',
    });

  const handlePaymentMethodChange = (
    formApi: UseFormReturn<PayFormType>,
    value: AftersalesPaymentMethod | Nullish
  ) => {
    formApi.resetField('cashRegisterId');
    formApi.setValue('withCashRegisterDocument', value === 'CARD' || value === 'CASH');
  };

  const getCashRegisterOptions = (paymentType: AftersalesPaymentMethod) =>
    cashRegisters
      .filter((cashRegister) => cashRegister.eligibleForCardPayments || paymentType === 'CASH')
      .map((cashRegister) => ({
        value: cashRegister.id,
        label: cashRegister.name,
      }));

  return (
    <VStack spacing={4}>
      <HStack spacing={20}>
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('general.labels.amount')}
          </Text>
          <Heading size={4} data-testid={props['data-testid']}>
            {formatCurrency(paymentAmount.amount, paymentAmount.currency, 2)}
          </Heading>
        </VStack>
        <VStack spacing={1}>
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.invoice.labels.remaining')}
          </Text>
          <Heading size={4} data-testid={props['data-testid']}>
            {formatCurrency(remainingPaymentAmount.amount, remainingPaymentAmount.currency, 2)}
          </Heading>
        </VStack>
      </HStack>
      <Form<PayFormType>
        defaultValues={{
          amount: remainingPaymentAmount.amount,
          payDate: new Date(),
          cashRegisterId: props.payment.cashRegisterId ?? undefined,
          paymentMethod: props.payment.method,
          tenantBankAccount: props.payment.bankAccount?.number ?? undefined,
          withCashRegisterDocument:
            props.payment.method === 'CARD' || props.payment.method === 'CASH',
        }}
        schema={formSchema}
        onSubmit={handlePayPayment}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <Card variant="inlineGrey">
              <Grid columns={2}>
                <FormField
                  control={control}
                  name="amount"
                  type="currency"
                  currency={currencyData}
                  label={i18n.t('general.labels.amount')}
                  isRequired
                  data-testid={suffixTestId('amount', props)}
                />
                <FormField
                  control={control}
                  name="payDate"
                  type="date"
                  isRelativeDatesHidden
                  label={i18n.t('entity.checkout.labels.payDate')}
                  data-testid={suffixTestId('payDate', props)}
                  isRequired
                />
                <FormField
                  control={control}
                  name="paymentMethod"
                  type="choice"
                  label={i18n.t('entity.checkout.labels.paymentMethod')}
                  options={orderPaymentOptions}
                  isNotClearable
                  isRequired
                  menuInPortal
                  onChange={(value) =>
                    handlePaymentMethodChange(formApi, value as AftersalesPaymentMethod)
                  }
                  data-testid={suffixTestId('paymentMethod', props)}
                />
                <Show when={formApi.watch('paymentMethod') === 'BANK_TRANSFER'}>
                  <FormField
                    control={control}
                    name="tenantBankAccount"
                    type="choice"
                    options={bankAccountOptions}
                    label={i18n.t('entity.checkout.labels.incomingBankAccount')}
                    isRequired
                    isNotClearable
                    menuInPortal
                    data-testid={suffixTestId('incomingBankAccount', props)}
                  />
                </Show>
                <Show
                  when={
                    formApi.watch('paymentMethod') === 'CASH' ||
                    formApi.watch('paymentMethod') === 'CARD'
                  }
                >
                  <FormField
                    control={control}
                    name="cashRegisterId"
                    type="choice"
                    options={getCashRegisterOptions(formApi.watch('paymentMethod'))}
                    label={i18n.t('entity.checkout.labels.cashRegister')}
                    isNotClearable
                    menuInPortal
                    data-testid={suffixTestId('incomingBankAccount', props)}
                  />
                  <Hide when={getCashRegisterOptions(formApi.watch('paymentMethod')).length === 0}>
                    <FormField
                      control={control}
                      name="withCashRegisterDocument"
                      type="checkbox"
                      label={i18n.t('entity.cashRegister.labels.createCashReceipt')}
                      isDisabled={formApi.watch('paymentMethod') === 'CASH'}
                    />
                  </Hide>
                  <Show when={getCashRegisterOptions(formApi.watch('paymentMethod')).length === 0}>
                    <Checkbox
                      label={i18n.t('entity.cashRegister.labels.createCashReceipt')}
                      isDisabled
                      value={false}
                    />
                  </Show>
                </Show>
              </Grid>
            </Card>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                onClick={props.onClose}
                variant="secondary"
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.confirm')}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </VStack>
  );
}

type PayFormType = {
  payDate: Date;
  amount: number;
  paymentMethod: AftersalesPaymentMethod;
  cashRegisterId: string;
  withCashRegisterDocument: boolean;
  tenantBankAccount: string;
};

const formSchema = object({
  amount: yupNumber.required(),
  paymentMethod: yupString.required(),
  payDate: yupDate.required(),
  tenantBankAccount: string().when('method', {
    is: 'BANK_TRANSFER',
    then: string().required().nullable(),
    otherwise: string().optional().nullable(),
  }),
  cashRegisterId: string().when('method', {
    is: 'CASH',
    then: string().required().nullable(),
    otherwise: string().optional().nullable(),
  }),
});
