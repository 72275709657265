import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {BasicCategoryOfStructureResponseBody} from '@dms/api';

import {CategoryUniqueKey, SECOND_SET_VALUES} from '../constants';

export const getParams = (category: BasicCategoryOfStructureResponseBody) =>
  match([category.uniqueKey, category.key])
    .when(
      ([, key]) => SECOND_SET_VALUES[key ?? ''],
      always(category.childCategories?.[0]?.paramDefinitions)
    )
    .when(
      ([uniqueKey]) => uniqueKey === CategoryUniqueKey.PHOTODOCUMENTATION,
      always({
        relatedActions:
          category.childCategories
            ?.flatMap((category) => category.paramDefinitions?.relatedActions)
            .filter(isNotNil) ?? null,
        additionalInformation:
          category.childCategories
            ?.flatMap((category) => category.paramDefinitions?.additionalInformation)
            .filter(isNotNil) ?? null,
      })
    )
    .when(
      ([uniqueKey]) => uniqueKey === CategoryUniqueKey.EQUIPMENT,
      always(category.childCategories?.[0]?.childCategories?.[0]?.paramDefinitions)
    )
    .otherwise(always(category.paramDefinitions));
