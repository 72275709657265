import {isFeatureEnabled} from 'feature-flags';
import {ButtonActionProps} from 'platform/components';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {always} from 'ramda';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds} from '@dms/routes';
import {Main, PageTab, usePermissions} from '@dms/shared';

import {buildArray} from 'shared';

import {BusinessCaseComplaintsList} from './BusinessCaseComplaintsList/BusinessCaseComplaintsList';
import {BusinessCaseListOS} from './BusinessCaseList/(sections)/OSBusinessCaseList';
import {BusinessCaseList} from './BusinessCaseList/BusinessCaseList';

export function BusinessCases() {
  const navigateTo = useNavigate();
  const [
    hasComplaintReadPermission,
    hasBusinessCasePurchasePermission,
    hasBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    hasAddVehicleToSellPermission,
    canViewBusinessCaseSelling,
    canViewBusinessCasePurchase,
  ] = usePermissions({
    permissionKeys: [
      'complaintRead',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'addVehicleToSell',
      'viewBusinessCaseSelling',
      'viewBusinessCasePurchase',
    ],
    scopes: {
      // No need for participation scope. ACL leads to DG with complaints -> BE handles DG ACL
      complaintRead: {
        participation: undefined,
      },
      // No need for participation scope. ACL leads to all BCs -> BE handles DG ACL
      viewBusinessCasePurchase: {
        participation: undefined,
      },
      // No need for participation scope. ACL leads to all BCs -> BE handles DG ACL
      viewBusinessCaseSelling: {
        participation: undefined,
      },
    },
  });

  const canCreatePurchase = hasBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;
  const canCreateSell = hasBusinessCaseSellingPermission && hasAddVehicleToSellPermission;

  const handleClickNewConcept = () => {
    navigateTo(businessCaseRoutes.concept);
  };

  const tabs = buildArray<PageTab>()
    .add({
      id: 'business-cases-list',
      title: i18n.t('page.businessCases.title'),
      href: businessCaseRoutes.home,
      content: <BusinessCaseList />,
    })
    .when(hasComplaintReadPermission && isFeatureEnabled(featureFlags.SALES_CLAIMS), {
      id: 'business-cases-complaints-list',
      title: i18n.t('entity.vehicle.labels.complaints'),
      href: businessCaseRoutes.complaintsList,
      content: <BusinessCaseComplaintsList />,
    })
    .whenFeatureEnabled(featureFlags.OS_BUSINESS_CASES, {
      id: 'business-cases-list-open-search',
      title: i18n.t('page.businessCases.360businessCasesView.title'),
      href: businessCaseRoutes.openSearch,
      content: <BusinessCaseListOS />,
    });

  return (
    <>
      <Helmet title={i18n.t('page.businessCases.title')} />
      <Main
        actions={match(
          (canCreatePurchase && canViewBusinessCasePurchase) ||
            (canCreateSell && canViewBusinessCaseSelling)
        )
          .with(
            true,
            always([
              {
                type: 'button',
                variant: 'primary',
                onClick: handleClickNewConcept,
                'data-testid': testIds.businessCase.home('createNew'),
                title: i18n.t('entity.businessCase.createNew'),
              } as ButtonActionProps,
            ])
          )
          .otherwise(always(undefined))}
        tabs={tabs}
        isFullHeight
      ></Main>
    </>
  );
}
