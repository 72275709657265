import {DropdownItem, FormControl, showNotification} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';

import {isEmpty} from 'ramda';

import {
  ContextTemplateResponseBody,
  GetContextTemplateListApiResponse,
  useRenderServiceOrderSmsTemplateMutation,
} from '@dms/api';
import {i18n} from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {VARIABLE_REGEX, handleApiError, replaceVariables} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {EmailSmsNotificationForm} from '../types/EmailSmsNotificationForm';

interface Props {
  formApi: UseFormReturn<EmailSmsNotificationForm>;
  control: FormControl<EmailSmsNotificationForm>;
  renderTemplate: ReturnType<typeof useRenderServiceOrderSmsTemplateMutation>[0];
  templates: GetContextTemplateListApiResponse | undefined;
  serviceOrderId: string;
  serviceCaseId: string;
}

export const TemplatesSmsDropdownContent = (props: Props) => {
  const navigate = useNavigate();
  const onTemplateClick = (template: ContextTemplateResponseBody) =>
    props
      .renderTemplate({
        renderServiceOrderSmsTemplateRequestBody: {
          template: template.body,
          serviceOrderId: props.serviceOrderId,
          serviceCaseId: props.serviceCaseId,
        },
      })
      .unwrap()
      .then((response) => {
        props.formApi.setValue('sms.data.message', replaceVariables(response.body));

        if (VARIABLE_REGEX.test(response.body)) {
          showNotification.warning(i18n.t('general.notifications.notAllPlaceholdersFilled'));
        }
      })
      .catch(handleApiError);

  if (isEmpty(props.templates)) {
    return (
      <DropdownItem
        label={i18n.t('general.actions.addNewTemplate')}
        onClick={() =>
          navigate(
            composePath(settingsRoutes.messageTemplatesNew, {
              params: {type: 'sms'},
            })
          )
        }
      />
    );
  }

  return (
    <>
      {props.templates?.map((template) => (
        <DropdownItem
          label={template.name}
          key={template.id}
          onClick={() => onTemplateClick(template)}
        />
      ))}
    </>
  );
};
