import {Checkbox} from 'platform/components';
import {Box} from 'platform/foundation';
import styled from 'styled-components';

import {FeatureKey} from '@dms/api';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {hasChildWithSelectedFeature} from '../utils/hasChildWithSelectedFeature';
import {FeatureItem} from './FeatureItem';

interface FeatureTreeProps extends RequiredTestIdProps {
  feature: FeatureKey;
  selectedFeature: string | undefined;
  onChange: (oldFeature: string | Nullish, newFeature: string) => void;
}

export function FeatureTree(props: FeatureTreeProps) {
  const updateSelectedFeature = (newFeature: string) => {
    props.onChange(props.selectedFeature, newFeature);
  };

  const hasChildren = !!props.feature.children.length;
  const isSelected = props.selectedFeature === props.feature.const_key;

  const isIndeterminate =
    hasChildren && hasChildWithSelectedFeature(props.feature, props.selectedFeature);

  return (
    <TreeBox>
      <Box paddingBottom={4}>
        <Checkbox
          value={isSelected}
          onChange={() => updateSelectedFeature(props.feature.const_key)}
          isIndeterminate={isIndeterminate}
          label={props.feature.labels[0].label}
          data-testid={suffixTestId('feature', props)}
        />
      </Box>
      {hasChildren &&
        props.feature.children.map((subItem, index) => (
          <FeatureItem
            key={subItem.const_key}
            selectedFeature={props.selectedFeature}
            updateSelectedFeature={updateSelectedFeature}
            feature={subItem}
            data-testid={suffixTestId(`level-${index}`, props)}
          />
        ))}
    </TreeBox>
  );
}

const TreeBox = styled.div`
  break-inside: avoid-column;
`;
