import {FormControl, FormField} from 'platform/components';

import {useWatch} from 'react-hook-form';

import {filter, isNil, map, pipe} from 'ramda';

import {CashRegisterResponseBody, useGetCashRegisterListQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {InvoicePaymentFormValues} from '../types/InvoicePaymentFormValues';

interface CashRegisterInputProps extends TestIdProps {
  prescriptionCurrency: string | Nullish;
  control: FormControl<InvoicePaymentFormValues>;
}

export function CashRegisterInput(props: CashRegisterInputProps) {
  const {data: cashRegisters} = useGetCashRegisterListQuery(
    {currency: props.prescriptionCurrency},
    {skip: isNil(props.prescriptionCurrency)}
  );

  const paymentType = useWatch({control: props.control, name: 'paymentType'});

  const cashRegisterOptions = pipe(
    filter(
      (item: CashRegisterResponseBody) =>
        paymentType !== 'PAYMENT_CARD' || item.eligibleForCardPayments
    ),
    map(cashRegisterToOption)
  )(cashRegisters ?? []);

  return (
    <FormField
      label={i18n.t('entity.lineItems.labels.cashRegister')}
      name="cashRegister"
      control={props.control}
      type="choice"
      options={cashRegisterOptions}
      data-testid={suffixTestId('cashRegister', props)}
    />
  );
}

const cashRegisterToOption = (item: CashRegisterResponseBody) => ({
  value: item.id,
  label: item.name,
});
