import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DropdownDivider,
  DropdownGroup,
  DropdownItem,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openDialog,
  OptionType,
  showNotification,
} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useDeletePredefinedNoteMutation,
  useGetListPredefinedNoteQuery,
  usePostPredefinedNoteMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../utils/handleApiError';

interface AdvertisementPredefinedNotesProps extends RequiredTestIdProps {
  note: string | null;
  resource: string;
  context: string;
  onPrefill: (note: string) => void;
  isLinkVariant?: boolean;
}

interface SaveFormFields {
  name: string;
}

interface DeleteFormFields {
  id: string;
}

export function PredefinedNotesDropdownBody(props: AdvertisementPredefinedNotesProps) {
  const {data: predefinedNotes} = useGetListPredefinedNoteQuery({
    resourceId: props.resource,
    context: props.context,
  });
  const [saveNote, {isLoading: isSaveLoading}] = usePostPredefinedNoteMutation();
  const [deleteNote, {isLoading: isDeleteLoading}] = useDeletePredefinedNoteMutation();

  const predefinedNotesOptions: OptionType[] | undefined = predefinedNotes?.notes?.map((note) => ({
    value: note.id,
    label: note.name,
  }));

  const handleSave: FormSubmitHandler<SaveFormFields> = (values: SaveFormFields) =>
    saveNote({
      predefinedNoteRequestBody: {
        name: values.name,
        text: props.note ?? '',
        resourceId: props.resource,
        context: props.context,
      },
    })
      .unwrap()
      .then(closeCurrentDialog)
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const handleDelete: FormSubmitHandler<DeleteFormFields> = (values: DeleteFormFields) =>
    deleteNote({
      id: values.id,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .then(() => {
        showNotification.success();
      })
      .catch(handleApiError);

  const onSaveClick = () => {
    openDialog(
      <Form<SaveFormFields> schema={saveSchema} onSubmit={handleSave}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="name"
              type="text"
              label={i18n.t('entity.vehicle.labels.templateName')}
              isRequired
              data-testid={suffixTestId('templateName', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.cancel')}
                variant="secondary"
                isDisabled={isSaveLoading}
                onClick={closeCurrentDialog}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                title={i18n.t('general.actions.save')}
                type="submit"
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>,
      {
        title: i18n.t('entity.vehicle.labels.saveTemplate'),
        size: 'small',
      }
    );
  };

  const onDeleteClick = () => {
    openDialog(
      <Form<DeleteFormFields> schema={deleteSchema} onSubmit={handleDelete}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="id"
              type="choice"
              options={predefinedNotesOptions}
              label={i18n.t('entity.vehicle.labels.template')}
              isRequired
              data-testid={suffixTestId('template', props)}
            />
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.cancel')}
                variant="secondary"
                isDisabled={isDeleteLoading}
                onClick={closeCurrentDialog}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                title={i18n.t('general.actions.delete')}
                variant="danger"
                type="submit"
                data-testid={suffixTestId('delete', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>,
      {
        title: i18n.t('entity.vehicle.labels.deleteTemplate'),
        size: 'small',
        scrollBehavior: 'outside',
      }
    );
  };

  return (
    <>
      <DropdownGroup>
        <DropdownItem
          label={i18n.t('entity.vehicle.labels.saveAsTemplate')}
          leftIcon="content/save"
          onClick={onSaveClick}
          data-testid={suffixTestId('saveAsTemplate', props)}
        />
        <Show when={isNotNilOrEmpty(predefinedNotes?.notes)}>
          <DropdownItem
            label={i18n.t('entity.vehicle.labels.deleteTemplate')}
            leftIcon="action/delete"
            severity="danger"
            onClick={onDeleteClick}
            data-testid={suffixTestId('deleteTemplate', props)}
          />
        </Show>
      </DropdownGroup>
      <Show when={isNotNilOrEmpty(predefinedNotes?.notes)}>
        <DropdownDivider />
        <DropdownGroup label={i18n.t('entity.vehicle.labels.fillFromTemplate')}>
          {predefinedNotes?.notes.map((note, index) => (
            <DropdownItem
              label={note.name}
              key={note.id}
              onClick={() => props.onPrefill(note.text)}
              data-testid={suffixTestId(`predefinedNotes-[${index}]`, props)}
            />
          ))}
        </DropdownGroup>
      </Show>
    </>
  );
}

const saveSchema = object({
  name: string().required(),
});

const deleteSchema = object({
  id: string().required(),
});
