import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Separator,
  showNotification,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {GridItem, Grid} from 'platform/foundation';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {
  patchEmailConfig,
  selectEmailConfig,
  SmtpCredentials,
  SmtpEncryption,
  $EmailSmtpCredentials,
  useApiDispatch,
} from '@dms/teas';

const SMTP_ENCRYPTION_OPTIONS = [
  {label: 'TLS', value: 'tls'},
  {label: 'SSL', value: 'ssl'},
];

export const EmailServicesSMTP: FC<PropsWithChildren<any>> = () => {
  const apiDispatch = useApiDispatch();
  const emailConfig = useSelector(selectEmailConfig);

  const updateCredentials: FormSubmitHandler<SmtpCredentials> = (values) =>
    // eslint-disable-next-line no-restricted-syntax
    apiDispatch(patchEmailConfig.action, {
      requestBody: {
        credentials: {
          user: values.user,
          defaultEmail: values.defaultEmail,
          encryption: values.encryption as SmtpEncryption | null,
          password: values.password,
          host: values.host,
          port: values.port,
        },
      },
    })
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);

  return (
    <Card title={i18n.t('page.integrations.labels.serverSMTP.settings')}>
      <Form<SmtpCredentials>
        onSubmit={updateCredentials}
        schema={$EmailSmtpCredentials}
        defaultValues={emailConfig?.credentials}
      >
        {(control, formApi) => (
          <>
            <Grid columns={12} spacing={4}>
              <GridItem span={6}>
                <FormField
                  type="email"
                  control={control}
                  name="defaultEmail"
                  label={i18n.t('page.integrations.labels.serverSMTP.outgoingEmail')}
                />
              </GridItem>
              <GridItem span={6}>
                <FormField
                  type="text"
                  control={control}
                  name="host"
                  label={i18n.t('page.integrations.labels.serverSMTP.host')}
                />
              </GridItem>
              <GridItem span={6}>
                <FormField
                  type="text"
                  control={control}
                  name="user"
                  label={i18n.t('page.integrations.labels.serverSMTP.userNameEmail')}
                />
              </GridItem>
              <GridItem span={6}>
                <FormField
                  type="password"
                  control={control}
                  name="password"
                  label={i18n.t('general.labels.password')}
                />
              </GridItem>
              <GridItem span={6}>
                <FormField
                  type="number"
                  control={control}
                  name="port"
                  label={i18n.t('page.integrations.labels.serverSMTP.port')}
                />
              </GridItem>
              <GridItem span={6}>
                <FormField
                  type="choice"
                  control={control}
                  name="encryption"
                  options={SMTP_ENCRYPTION_OPTIONS}
                  label={i18n.t('page.integrations.labels.serverSMTP.encryption')}
                />
              </GridItem>
            </Grid>
            <Separator />
            <ButtonGroup align="right">
              <Button
                data-testid={testIds.settings.emailServices('submit')}
                type="submit"
                title={i18n.t('general.actions.update')}
                isDisabled={formApi.formState.isSubmitting}
                isLoading={formApi.formState.isSubmitting}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </Card>
  );
};
