import {DeleteDialog, showNotification} from 'platform/components';
import {Heading, Icon} from 'platform/foundation';

import {FC, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  BranchListItemResponseBody,
  DocumentLimitDefinitionResponseBody,
  selectBranchList,
  useGetDocumentLimitDefinitionListQuery,
  useRemoveDocumentLimitDefinitionMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {Button, ColumnType, LinkButton, SimpleTable, useRouter} from '@dms/teas';

import {composePath} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ActionButtonsColumn, CardFullWidth} from '../CashRegisters/styles';

export const CashLimits: FC = () => {
  const router = useRouter();

  const {data: list, isLoading, isError} = useGetDocumentLimitDefinitionListQuery({});
  const [removeDefinition] = useRemoveDocumentLimitDefinitionMutation();

  const {data} = useSelector(selectBranchList);
  const branches = data?.branchListItems;

  const [deletedItem, setDeletedItem] = useState<string | null>(null);

  const closeModal = () => {
    setDeletedItem(null);
  };

  const handleItemDeletion = (id: string) => {
    removeDefinition({id})
      .unwrap()
      .then(() => {
        setDeletedItem(null);
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const columns = useMemo<ColumnType<DocumentLimitDefinitionResponseBody>[]>(
    () => [
      {
        Header: i18n.t('entity.branch.labels.branch'),
        id: 'branchId',
        width: '30%',
        Cell: ({row}) => {
          const getContent = () => {
            if (row.branchId == null) {
              return i18n.t('entity.tenant.labels.tenant');
            }
            const found = (branches as BranchListItemResponseBody[]).find(
              (branch) => branch.id === row.branchId
            );

            return found?.marketingName ?? '-';
          };

          return (
            <Heading size={5}>
              {`${getContent()} ${row.system && `(${i18n.t('general.labels.system')})`}`}
            </Heading>
          );
        },
      },
      {
        Header: i18n.t('general.labels.currency'),
        id: 'currency',
        Cell: ({row}) => row.currency,
      },
      {
        Header: null,
        id: 'action',
        Cell: ({row, index}) => (
          <ActionButtonsColumn>
            <Link to={composePath(settingsRoutes.cashLimitsEdit, {params: {id: row.id}})}>
              <Button
                compact
                icon={<Icon value="image/edit" />}
                data-testid={testIds.settings.cashLimits(`row-${index}-editButton`)}
              />
            </Link>
            {!row.system ? (
              <Button
                compact
                data-testid={testIds.settings.cashLimits(`row-${index}-removeButton`)}
                icon={<Icon value="action/delete" />}
                onClick={(e) => {
                  e.stopPropagation();

                  setDeletedItem(row.id);
                }}
              />
            ) : null}
          </ActionButtonsColumn>
        ),
      },
    ],
    [branches]
  );

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.cashRegisterLimitsTitle')}}
      data-testid="settings-cash-limits"
      isLoading={isLoading}
      isError={isError}
    >
      <>
        <CardFullWidth
          noPadding
          title={i18n.t('page.accounting.labels.definitions')}
          headerContent={
            <LinkButton
              href={settingsRoutes.cashLimitsAdd}
              data-testid={testIds.settings.cashLimits('createNew')}
            >
              <Icon value="content/add_circle" /> {i18n.t('page.accounting.actions.addDefinition')}
            </LinkButton>
          }
        >
          <SimpleTable
            noZebra
            showRowDivider
            highlightRowOnHover
            columns={columns}
            getRowId={(row) => row.id}
            tdPadding="default"
            rows={[{data: list ?? []}]}
            onRowClick={(row) =>
              router.push(composePath(settingsRoutes.cashLimitsEdit, {params: {id: row.id}}))
            }
          />
        </CardFullWidth>

        <DeleteDialog
          isOpen={!!deletedItem}
          onClose={closeModal}
          onConfirm={() => {
            deletedItem && handleItemDeletion(deletedItem);
          }}
          data-testid={testIds.settings.cashLimits('deleteLimitDefinitionNotification')}
          text={i18n.t('entity.cashRegisterLimits.notifications.deleteLimitDefinitionNotification')}
        />
      </>
    </SettingsTemplate>
  );
};
