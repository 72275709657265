import {Chips, Separator} from 'platform/components';
import {Box, Heading, Space} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useConditionContext} from '../hooks/useConditionContext';
import {AuditParamType} from '../types/AuditParamType';
import {findByParamType} from '../utils/findByParamType';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getTranslation} from '../utils/getTranslation';
import {resetCategoryFields} from '../utils/resetCategoryFields';
import {WheelsSet} from './WheelsSet';

const SECOND_SET_NO_OPTION = 'NO';

type SecondWheelsSetProps = {
  category?: AuditCategoryOfStructure;
};

export const SecondWheelsSet: FC<SecondWheelsSetProps> = ({category: secondSet}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const {isDisabledForUser} = useConditionContext();
  const {
    Field: FormField,
    Condition,
    form: {
      mutators: {setFieldValue},
    },
  } = useFormRenderer();

  if (!secondSet) {
    return null;
  }

  const secondSetToggleParam = findByParamType(
    secondSet?.paramDefinitions?.relatedActions,
    AuditParamType.TOGGLE
  );

  const secondSetToggleValue = secondSetToggleParam?.values?.[0];

  const secondSetRadioButtonsParam = findByParamType(
    secondSetToggleValue?.content?.relatedActions,
    AuditParamType.RADIO_BUTTONS
  );

  if (!secondSetRadioButtonsParam || !secondSetToggleParam || !secondSetToggleValue) {
    return null;
  }

  const secondSetChipFieldName = getFormFieldName(secondSet.id, secondSetRadioButtonsParam.id);

  return (
    <>
      <Separator />
      <Heading size={3}>{i18n.t('entity.condition.labels.2ndSet')}</Heading>
      <Space vertical={4} />

      <FormField
        data-testid={'secondWheelsSet_' + secondSet.name}
        name={getFormFieldName(secondSet.id, secondSetToggleParam.id)}
        component={() => <></>}
      />

      <FormField
        name={secondSetChipFieldName}
        data-testid={'secondWheelsSet_' + secondSetChipFieldName}
        component={({input}) => {
          const toggleFieldName = getFormFieldName(secondSet.id, secondSetToggleParam.id);

          const handleChipsChange = (newValue: string[] | Nullish) => {
            const [value] = newValue ?? [];
            const oldValue = String(input.value);

            if (oldValue === value) {
              return;
            }

            const paramValue = secondSetRadioButtonsParam.values.find(
              (val) => val.value === oldValue
            );
            const categoryForReset = secondSet.childCategories?.find(
              (cat) => cat.key === paramValue?.openCategoryWithKey || ''
            );

            if (categoryForReset) {
              resetCategoryFields(categoryForReset, setFieldValue);
            }

            setFieldValue(
              toggleFieldName,
              String(value) === SECOND_SET_NO_OPTION ? null : secondSetToggleValue.value
            );
            input.onChange(String(value ?? null));
          };

          const chips = [
            {
              label: i18n.t('general.labels.no'),
              value: SECOND_SET_NO_OPTION,
              isDisabled: isDisabledForUser,
            },
          ];

          secondSetRadioButtonsParam.values.forEach((paramValue) => {
            if (!paramValue.label) {
              return;
            }

            chips.push({
              label: getTranslation(locale, paramValue.label),
              value: paramValue.value,
              isDisabled: isDisabledForUser,
            });
          });

          return (
            <Box width="100%" paddingBottom={4} position="relative">
              <Chips
                data-testid="secondWheelsSet_chips"
                value={[String(input.value)]}
                options={chips}
                onChange={handleChipsChange}
                isDeselectable
              />
            </Box>
          );
        }}
      />
      {secondSetRadioButtonsParam.values.map((paramValue) => {
        const secondSetCategory = secondSet.childCategories?.find(
          (category) => category.key === paramValue.openCategoryWithKey
        );

        if (
          !secondSetCategory?.visible ||
          !paramValue.openCategoryWithKey ||
          !secondSetCategory.childCategories
        ) {
          return null;
        }

        return (
          <Condition key={paramValue.value} when={secondSetChipFieldName} is={paramValue.value}>
            <WheelsSet
              categories={secondSetCategory.childCategories}
              categoryKey={paramValue.openCategoryWithKey}
            />
          </Condition>
        );
      })}
    </>
  );
};
