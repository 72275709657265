import {DataStatus} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {EntityResourceIds, useGetVehicleV2Query} from '@dms/api';
import i18n from '@dms/i18n';
import {CommentsWidget, VehicleServiceIntervalsCard} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useRequiredParams} from 'shared';

interface VehicleServiceIntervalsProps extends RequiredTestIdProps {}

export function VehicleServiceIntervals(props: VehicleServiceIntervalsProps) {
  const {id: vehicleId} = useRequiredParams();
  const vehicleV2Query = useGetVehicleV2Query({vehicleId});

  return (
    <DataStatus isLoading={vehicleV2Query.isLoading} isError={vehicleV2Query.isError}>
      <Grid columns={2}>
        <GridItem>
          <VehicleServiceIntervalsCard
            vehicleData={vehicleV2Query.data}
            data-testid={suffixTestId('serviceIntervals', props)}
          />
        </GridItem>
        <GridItem>
          <CommentsWidget
            title={i18n.t('entity.vehicle.labels.vehicleComments')}
            resourceId={EntityResourceIds.vehicle}
            recordId={vehicleId}
          />
        </GridItem>
      </Grid>
    </DataStatus>
  );
}
