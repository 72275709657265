import {BreadcrumbType} from 'platform/components';
import {ParsedUrlQuery} from 'querystring';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {composePath} from 'shared';

export const getBackBreadcrumb = (query: ParsedUrlQuery): BreadcrumbType => {
  switch (query.from) {
    case 'tenant':
      return {
        label: i18n.t('page.settings.labels.tenantProfile'),
        href: settingsRoutes.tenant,
      };
    case 'branch':
      return {
        label: i18n.t('entity.branch.labels.branches'),
        href: query.branchId
          ? composePath(settingsRoutes.branchesEdit, {params: {id: query.branchId as string}})
          : settingsRoutes.branchesNew,
      };
    default:
      return {
        label: i18n.t('entity.invoice.labels.billingInformationManagement'),
        href: settingsRoutes.billingInformation,
      };
  }
};
