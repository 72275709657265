import i18n from '@dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {JbrSettings} from './components/JbrSettings';

export function Jbr() {
  return (
    <SettingsTemplate
      data-testid="integrations-jbr"
      header={{title: i18n.t('page.integrations.labels.jbr')}}
    >
      <JbrSettings />
    </SettingsTemplate>
  );
}
