import {ButtonGroup, Card, IconButton, showNotification} from 'platform/components';
import {Box, Hide} from 'platform/foundation';

import {FC, MouseEvent, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  SimpleTable,
  ColumnType,
  VehicleSeriesDefinitionService,
  selectVehicleCatalogue,
  vehicleSeriesDefinitionActions,
  selectVehicleSeriesDefinitionList,
  VehicleSeriesDefinition,
  getStringErrorMessage,
} from '@dms/teas';

import {composePath} from 'shared';

import {useVehicleSeriesDefinitionSelectors} from '../utils';
import {DeleteDialog} from './DeleteDialog';

export const VehicleSeriesDefinitionList: FC = () => {
  const navigate = useNavigate();
  const definitions = useSelector(selectVehicleSeriesDefinitionList);
  const {makes: makesList, customMakes: customMakesList} = useSelector(selectVehicleCatalogue);
  const {vehicleTypes, branchList, seriesList} = useVehicleSeriesDefinitionSelectors();
  const [deleteId, setDeleteId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmDelete = () => {
    if (!deleteId) {
      return;
    }

    setIsLoading(true);
    VehicleSeriesDefinitionService.deleteVehicleSeriesDefinition({
      id: deleteId,
    })
      .then((_) => {
        dispatch(vehicleSeriesDefinitionActions.removeListItem(deleteId));
        showNotification.success(
          i18n.t('page.vehicleSeriesDefinition.notifications.definitionDeleted')
        );
        setDeleteId(undefined);
        setIsLoading(false);
      })
      .catch((error) => {
        showNotification.error(getStringErrorMessage(error));
        setIsLoading(false);
      });
  };

  const columns = useMemo<ColumnType<VehicleSeriesDefinition>[]>(
    () => [
      {
        Header: i18n.t('entity.seriesDefinition.labels.definitionName'),
        id: 'name',
      },
      {
        Header: i18n.t('entity.vehicle.labels.type'),
        id: 'vehicleType',
        Cell: ({row}) => {
          if (row.vehicleType == null) {
            return i18n.t('general.labels.all');
          }

          return vehicleTypes.find((type) => type.value === row.vehicleType)?.label;
        },
      },
      {
        Header: i18n.t('entity.vehicle.labels.make'),
        id: 'make',
        Cell: ({row}) => {
          if (row.vehicleType == null || row.makes == null) {
            return i18n.t('general.labels.all');
          }

          return (
            [
              ...(makesList?.[row?.vehicleType] || []),
              ...(customMakesList?.[row?.vehicleType] || []),
            ]
              ?.filter(({value}) => !!row.makes?.find((make) => make === value))
              .map((i) => i.label)
              .join(', ') ?? ''
          );
        },
      },
      {
        Header: i18n.t('entity.branch.labels.branch'),
        id: 'branch',
        Cell: ({row}) => {
          if (!row.branchId) {
            return i18n.t('general.labels.all');
          }

          return branchList.find(({id}) => id === row.branchId)?.marketingName ?? '';
        },
      },
      {
        Header: i18n.t('page.vehicleSeriesDefinition.labels.definitionVehicleSeries'),
        id: 'vehicleSeries',
        Cell: ({row}) => seriesList.find(({id}) => id === row.seriesId)?.name ?? '',
      },
      {
        Header: '',
        id: 'actions',
        width: 100,
        Cell: ({row}) => (
          <div
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
          >
            <Box paddingHorizontal={3}>
              <ButtonGroup>
                <IconButton
                  key="edit"
                  size="small"
                  onClick={() =>
                    navigate(
                      composePath(settingsRoutes.vehicleEditSeriesDefinition, {
                        params: {id: row.id},
                      })
                    )
                  }
                  data-testid={testIds.settings.vehicleSeriesDefinition('edit')}
                  icon="image/edit"
                />
                <Hide when={row?.isSystem}>
                  <IconButton
                    size="small"
                    onClick={() => setDeleteId(row.id)}
                    data-testid={testIds.settings.vehicleSeriesDefinition('delete')}
                    icon="action/delete"
                  />
                </Hide>
              </ButtonGroup>
            </Box>
          </div>
        ),
      },
    ],
    [vehicleTypes, makesList, customMakesList, branchList, seriesList, navigate]
  );

  return (
    <Card
      title={i18n.t('entity.seriesDefinition.labels.definitions')}
      actions={[
        {
          onClick: () => navigate(settingsRoutes.vehicleNewSeriesDefinition),
          variant: 'link',
          type: 'button',
          leftIcon: 'image/edit',
          'data-testid': testIds.settings.vehicleSeriesDefinition('create'),
          title: i18n.t('page.vehicleSeriesDefinition.actions.newDefinition'),
        },
      ]}
    >
      <SimpleTable
        noZebra
        showRowDivider
        tdPadding="default"
        highlightRowOnHover
        rows={[
          {
            data: definitions,
          },
        ]}
        getRowId={({id}) => id}
        columns={columns}
        onRowClick={(row) =>
          navigate(composePath(settingsRoutes.vehicleEditSeriesDefinition, {params: {id: row.id}}))
        }
        data-testid={testIds.settings.vehicleSeriesDefinition('table')}
      />
      <DeleteDialog
        isOpen={!!deleteId}
        isLoading={isLoading}
        onConfirm={handleConfirmDelete}
        onClose={() => {
          setDeleteId(undefined);
          setIsLoading(false);
        }}
      />
    </Card>
  );
};
