import {Option} from 'platform/components';

import {groupBy, isNotNil} from 'ramda';
import {concatRight, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetMakeModelWithMakesQuery} from '@dms/api';
import i18n, {FALLBACK_LANGUAGE} from '@dms/i18n';

import {Nullish} from 'shared';

import {sortByLabel} from '../utils/sortByLabel';
import {useCustomTenantCatalogue} from './useCustomTenantCatalogue';

type GroupType = {
  label: string;
  options: readonly Option[];
};

type ModelOption = {
  make: string;
  makeLabel: string;
  modelFamilyGroup?: Option | Nullish;
} & Option;

type UseVehicleModelReturnType = [
  {
    modelOptions: ModelOption[] | Nullish;
    groupedModelOptionsByMake: GroupType[] | Nullish;
    groupedModelOptionsBySeries: GroupType[] | Nullish;
  },
  {isLoading: boolean; isError: boolean},
];

export function useVehicleModel(
  vehicleType: string | Nullish,
  makes: string[] | Nullish
): UseVehicleModelReturnType {
  const {data, isLoading, isError} = useGetMakeModelWithMakesQuery(
    {
      vehicleType: vehicleType ?? 'VEHICLETYPE_PASSENGER_CAR',
      makes: makes ?? [''],
      lang: [i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: isNilOrEmpty(vehicleType) || isNilOrEmpty(makes)}
  );

  const {
    customModelOptions,
    isLoading: isCustomTenantCatalogueLoading,
    isError: isCustomTenantCatalogueErrored,
  } = useCustomTenantCatalogue(vehicleType);

  const baseOptions =
    data?.reduce((modelOption: ModelOption[], catalogue) => {
      if (!catalogue?.models?.length) {
        return modelOption;
      }
      const options = catalogue.models.map((model) => ({
        label: model.labels?.[0]?.label ?? model.default_label,
        value: model.model ?? '',
        make: catalogue.make,
        makeLabel: catalogue.labels?.[0]?.label ?? catalogue.default_label,
        modelFamilyGroup: model.model_group
          ? {
              label:
                model.model_group?.labels?.[0]?.label ?? model.model_group?.default_label ?? '',
              value: model.model_group?.group ?? '',
            }
          : null,
      }));
      return concatRight(modelOption, options);
    }, []) ?? null;

  const baseModelOptions: ModelOption[] | Nullish =
    isNotNilOrEmpty(customModelOptions) && isNotNilOrEmpty(baseOptions)
      ? [...customModelOptions!, ...baseOptions!]
      : baseOptions;

  const modelOptions: ModelOption[] | Nullish = sortByLabel(baseModelOptions);

  const groupedModelOptionsByMake: GroupType[] | Nullish = isNotNilOrEmpty(modelOptions)
    ? sortByLabel(
        makes?.reduce((modelGroup: GroupType[], make) => {
          if (isNilOrEmpty(make)) {
            return modelGroup;
          }
          const optionsByMake = modelOptions!.filter((option) => option.make === make);
          if (!optionsByMake || !optionsByMake?.length) {
            return modelGroup;
          }
          return [
            ...modelGroup,
            {
              label: optionsByMake[0].makeLabel,
              options: optionsByMake,
            },
          ];
        }, [])
      )
    : null;

  const labelOthers = i18n.t('entity.vehicle.labels.otherLabel');

  const groupedModelOptionsBySeries: GroupType[] | Nullish = isNotNilOrEmpty(modelOptions)
    ? sortByLabel(
        makes?.reduce((modelGroup: GroupType[], make) => {
          if (isNilOrEmpty(make)) {
            return modelGroup;
          }
          const optionsByMake = modelOptions!.filter((option) => option.make === make);
          if (!optionsByMake || !optionsByMake?.length) {
            return modelGroup;
          }
          const hasFamilyGroup = optionsByMake.some((option) =>
            isNotNil(option.modelFamilyGroup?.label)
          );

          if (hasFamilyGroup) {
            const modelMapBySeries = groupBy(
              (option) => option.modelFamilyGroup?.label ?? labelOthers,
              optionsByMake
            );
            const modelGroupBySeries = Object.entries(modelMapBySeries).map(([label, options]) => ({
              label,
              options: options ?? [],
            }));

            return [...modelGroup, ...modelGroupBySeries];
          }

          return [
            ...modelGroup,
            {
              label: optionsByMake[0].makeLabel,
              options: optionsByMake,
            },
          ];
        }, [])
      )?.sort((a) => (a.label === labelOthers ? 1 : -1))
    : null;

  return [
    {modelOptions, groupedModelOptionsByMake, groupedModelOptionsBySeries},
    {
      isLoading: isLoading || isCustomTenantCatalogueLoading,
      isError: isError || isCustomTenantCatalogueErrored,
    },
  ];
}
