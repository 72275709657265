import {AxiosError} from 'axios';

import {ReactNode} from 'react';

import {ApiException} from '@dms/api';

import {PossibleObject} from '../types/PossibleObject';

export const getErrorMessages = (
  error: AxiosError<ApiException>,
  formattingErrorHandler?: (item: PossibleObject) => string
): ReactNode[] => {
  const {data} = error.response ?? {};

  if (data?.errors.length) {
    return data.errors.map((item) =>
      formattingErrorHandler ? (
        <div key={`${item.code}-${item.message}`}>{formattingErrorHandler(item) ?? item?.code}</div>
      ) : (
        <div key={`${item.code}-${item.message}`}>{item?.message ?? item?.code} </div>
      )
    );
  }

  if (data?.validationErrors.length) {
    return data.validationErrors.map((item) =>
      formattingErrorHandler ? (
        <div key={`${item.field}-${item.message}`}>{formattingErrorHandler(item)}</div>
      ) : (
        <div key={`${item.field}-${item.message}`}>{item?.message}</div>
      )
    );
  }

  if (data?.error?.message) {
    return [data.error.message];
  }
  return [];
};
