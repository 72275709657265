import {Alpha3CountryCode, PhoneInput, PhoneNumber, Tooltip} from 'platform/components';
import {Box, HStack, Heading, Icon, Space, VStack} from 'platform/foundation';
import styled from 'styled-components';
import {v4 as uuid} from 'uuid';

import {FC, useState, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import {selectTenant} from '@dms/api';
import i18n from '@dms/i18n';
import {DEFAULT_COUNTRY} from '@dms/shared';
import {
  GrayBox,
  useInlineEditing,
  TextField,
  selectPhoneNumbers,
  PutTenantRequestBody,
  noop,
} from '@dms/teas';

import {withInlineEditing} from '../../../utils/inlineEditingTransformation';
import {TenantCache, UpdatedTenant} from '../types';

const IconWrapper = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.palettes.neutral[60][100]};
  font-size: ${({theme}) => theme.fontSizes.text.base};
  margin-left: ${({theme}) => theme.getSize(2)};
`;

const PublicContactsComponent: FC<PropsWithChildren<any>> = () => {
  const {data: {country: countryCode} = {country: DEFAULT_COUNTRY}} = useSelector(selectTenant);

  const defaultPhoneNumber = {
    countryCode,
    number: '',
    prefix: '',
  };

  // This key is only used for re-render - only change this, when you need to reset default value of this form
  const [rerenderKey] = useState(uuid());
  const inlineEditing = useInlineEditing<Partial<PutTenantRequestBody>, TenantCache>();
  const publicContacts: UpdatedTenant['publicContacts'] = inlineEditing.editedValue?.data
    ?.publicContacts ?? {
    website: null,
    emailAddress: null,
    phoneNumber: defaultPhoneNumber,
  };
  const phoneList = useSelector(selectPhoneNumbers);

  return (
    <GrayBox key={rerenderKey}>
      <HStack align="center">
        <Heading size={5}>{i18n.t('entity.customer.labels.publicContacts')}</Heading>{' '}
        <Tooltip placement="top" label={i18n.t('entity.customer.labels.publicContactsHelperText')}>
          <IconWrapper>
            <Icon value="action/info" />
          </IconWrapper>
        </Tooltip>
      </HStack>
      <Space vertical={3} />
      <VStack>
        <HStack spacing={4}>
          <Box flex={1}>
            {withInlineEditing(
              <PhoneInput
                data-testid="phoneNumber"
                label={i18n.t('entity.person.labels.phoneNumber')}
                countries={phoneList.map((phone) => ({
                  countryCode: phone.code as Alpha3CountryCode,
                  dialCode: phone.prefix,
                  name: phone.name,
                }))}
                preferredCountries={phoneList
                  .filter((phone) => phone.preferred)
                  .map((phone) => phone.code as Alpha3CountryCode)}
                value={(publicContacts?.phoneNumber ?? defaultPhoneNumber) as PhoneNumber}
                onChange={noop}
              />,
              {
                onChangePropPath: ['onChange'],
                inlineEditingTransformation: (phoneNumber) => ({
                  publicContacts: {...publicContacts, phoneNumber},
                }),
              }
            )}
          </Box>
          <Box flex={1}>
            {withInlineEditing(
              <TextField
                name="publicContacts-emailAddress"
                label={i18n.t('general.labels.email')}
                defaultValue={publicContacts?.emailAddress ?? ''}
              />,
              {
                onChangePropPath: ['onBlur'],
                inlineEditingTransformation: ({target: {value}}) => ({
                  publicContacts: {...publicContacts, emailAddress: value || null},
                }),
              }
            )}
          </Box>
        </HStack>
        <Space vertical={4} />
        {withInlineEditing(
          <TextField
            name="publicContacts-website"
            label={i18n.t('general.labels.website')}
            defaultValue={publicContacts?.website ?? ''}
          />,
          {
            onChangePropPath: ['onBlur'],
            inlineEditingTransformation: ({target: {value}}) => ({
              publicContacts: {...publicContacts, website: value || null},
            }),
          }
        )}
      </VStack>
    </GrayBox>
  );
};

export const PublicContacts = PublicContactsComponent;
