import {equals} from 'ramda';

import {
  BaseSupplier,
  GetAuthorizationProfilesResponse,
  GetCurrenciesApiResponse,
  GetDeliveryNoteResponse,
  GetPaymentTypeEnumApiResponse,
  GetWarehousesResponse,
} from '@dms/api';

import {Nullish} from 'shared';

import {ReceiveNoteBasicInformationReadOnlyFields} from '../../../../../types/ReceiveNoteBasicInformationReadOnlyFields';
import {getSupplierById} from '../../../../../utils/getSupplierById';
import {getWarehouseById} from '../../../../../utils/getWarehouseById';

type CreateReceiveNoteBasicInformationFields = {
  deliveryNote: GetDeliveryNoteResponse | Nullish;
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: GetAuthorizationProfilesResponse[] | Nullish;
  paymentTypes: GetPaymentTypeEnumApiResponse | Nullish;
  currencies: GetCurrenciesApiResponse | Nullish;
};

export const createReceiveNoteBasicInformationFields = (
  props: CreateReceiveNoteBasicInformationFields
): ReceiveNoteBasicInformationReadOnlyFields => {
  const supplierName = getSupplierById(
    props.suppliers ?? [],
    props.deliveryNote?.receiveNote?.supplierId
  )?.name;

  const warehouseName = getWarehouseById(
    props.warehouses ?? [],
    props.deliveryNote?.receiveNote?.warehouseId
  )?.name;

  const authorizationProfileName = props.authorizationProfiles?.find((authorizationProfile) =>
    equals(authorizationProfile.id, props.deliveryNote?.receiveNote?.authorizationProfileId)
  )?.name;

  const number = props.deliveryNote?.receiveNote?.receiveNoteNumber;

  const isCorrectionCompleted = props.deliveryNote?.receiveNote?.state === 'COMPLETED';

  const date = isCorrectionCompleted ? props.deliveryNote?.receiveNote?.updated : null;

  const invoiceNumber = props.deliveryNote?.receiveNote?.invoiceNumber;

  const invoiceIssueDate = props.deliveryNote?.receiveNote?.invoiceDate;

  const paymentType = props.paymentTypes?.find((paymentType) =>
    equals(paymentType.key, props.deliveryNote?.receiveNote?.paymentType)
  )?.value;

  const currency = props.currencies?.find((currency) =>
    equals(currency.code, props.deliveryNote?.receiveNote?.currency)
  )?.code;

  const exchangeRate = props.deliveryNote?.receiveNote?.exchangeRate;

  const ncConversion = props.deliveryNote?.receiveNote?.ncConversion;

  const note = props.deliveryNote?.receiveNote?.note;

  return {
    supplierName,
    warehouseName,
    authorizationProfileName,
    number,
    date,
    invoiceNumber,
    invoiceIssueDate,
    paymentType,
    currency,
    exchangeRate,
    ncConversion,
    note,
  };
};
