import {AnimatePresence, motion} from 'framer-motion';
import {Integer, ThemeIconKey, Box, Show} from 'platform/foundation';

import {ReactNode, useEffect} from 'react';

import {useBoolean, TestIdProps} from 'shared';

import {Separator} from '../../Separator/Separator';
import {MenuAction, MenuBadge} from '../types';
import {MenuItem} from './MenuItem';

interface MenuGroupProps extends TestIdProps {
  id: string;
  label: string;
  items: ReactNode;
  hasSeparator?: boolean;
  leftIcon?: ThemeIconKey;
  isActive?: boolean;
  depth?: Integer;
  isDark?: boolean;
  badge?: MenuBadge;
  actions?: MenuAction[];
  onClick?: VoidFunction;
  isSelected?: boolean;
  tooltip?: ReactNode;
  isDefaultOpen?: boolean;
  shouldTruncateLongText?: boolean;
}

export function MenuGroup(props: MenuGroupProps) {
  const [isOpen, setOpen, , toggleOpen] = useBoolean(props.isActive || props.isDefaultOpen);

  // If group was opened from parent, it should force local state to update.
  useEffect(() => {
    if (props.isActive) {
      setOpen();
    }
  }, [props.isActive]);

  const handleClick = () => (!props.isSelected && props.onClick ? props.onClick() : toggleOpen());

  const depth: Integer = props.depth || 0;

  return (
    <>
      <Box>
        <MenuItem
          isGroup={!props.isSelected}
          id={props.id}
          label={props.label}
          onClick={handleClick}
          leftIcon={props.leftIcon}
          isActive={props.isActive}
          isDark={props.isDark}
          depth={depth}
          badge={props.badge}
          rightIcon={isOpen ? 'navigation/expand_less' : 'navigation/expand_more'}
          actions={props.actions}
          tooltip={props.tooltip}
          shouldTruncateLongText={props.shouldTruncateLongText}
          data-testid={props['data-testid']}
        />

        <AnimatePresence initial={false}>
          {isOpen ? (
            <motion.div
              key="cardBody"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: {
                  height: 'auto',
                  transition: {
                    duration: 0.3,
                    ease: [0.3, 0.1, 0.75, 0.9],
                  },
                  transformOrigin: 'top',
                },
                hidden: {
                  height: 0,
                  transition: {
                    duration: 0.25,
                    ease: [0.3, 0.1, 0.75, 0.9],
                  },
                  transformOrigin: 'top',
                },
              }}
              style={{
                flexGrow: 1,
              }}
            >
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  visible: {
                    opacity: 1,
                    overflow: 'hidden',
                    transition: {
                      delay: 0.15,
                      duration: 0.15,
                    },
                    transformOrigin: 'top',
                  },
                  hidden: {
                    opacity: 0,
                    transition: {
                      delay: 0,
                      duration: 0.1,
                    },
                    transformOrigin: 'top',
                  },
                }}
                style={{
                  height: '100%',
                }}
              >
                <Box paddingTop={1}>{props.items}</Box>
              </motion.div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Box>
      <Show when={props.hasSeparator}>
        <Box opacity={props.isDark ? 0.2 : 1}>
          <Separator spacing={3} />
        </Box>
      </Show>
    </>
  );
}
