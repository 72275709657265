/* eslint-disable eag/match-named-export */
import {ColorSchemeType} from 'platform/components';

import i18n from '@dms/i18n';

export const EVALUATION_UNKNOWN_FLAG = '?';
export const EVALUATION_UNKNOWN_COLOR: ColorSchemeType = 'neutral';

export const evaluationColors: Record<number, ColorSchemeType> = {
  1: 'blue',
  2: 'green',
  3: 'yellow',
  4: 'orange',
  5: 'red',
};

export const getEvaluationTranslations = (): Record<number, string> => ({
  1: i18n.t('entity.condition.labels.statusAboveAverage'),
  2: i18n.t('entity.condition.labels.statusAverage'),
  3: i18n.t('entity.condition.labels.statusSlightlyBelowAverage'),
  4: i18n.t('entity.condition.labels.statusVeryBelowAverage'),
  5: i18n.t('entity.condition.labels.statusDamaged'),
});
