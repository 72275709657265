import {FormControl, FormField, OptionType, TextInput} from 'platform/components';
import {Grid, Show} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';

import {useState} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {useGetTenantQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DefinitionFormValues} from '../types/DefinitionFormValues';

interface AdditionalFieldsByTypeProps extends RequiredTestIdProps {
  formApi: UseFormReturn<DefinitionFormValues>;
  control: FormControl<DefinitionFormValues>;
  hasDisabledInputs?: boolean;
}

export function AdditionalFieldsByType(props: AdditionalFieldsByTypeProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {data: tenant} = useGetTenantQuery();
  const valueType = useWatch({control: props.control, name: 'valueType'});

  const [allowedValues, setAllowedValues] = useState<OptionType[]>([]);

  const handleCreateOption = (val: string) => {
    const currentInputValues = props.formApi.getValues('allowedValues') ?? [];
    const newRecord = {value: val, label: val};

    props.formApi.setValue('allowedValues', [...currentInputValues, newRecord.value]);
    setAllowedValues((prev) => [...prev, newRecord]);
  };

  return (
    <>
      <Show when={valueType === 'string'}>
        <FormField
          type="creatableMultiChoice"
          control={props.control}
          name="allowedValues"
          label={i18n.t('page.generalSettings.labels.allowedValues')}
          data-testid={suffixTestId('allowedValues', props)}
          onCreateOption={handleCreateOption}
          options={allowedValues}
          isDisabled={props.hasDisabledInputs}
          noOptionsMessage={() => i18n.t('page.generalSettings.labels.typeToCreate')}
          helperText={i18n.t('page.generalSettings.labels.limitValues')}
        />
      </Show>
      <Show when={valueType === 'money'}>
        <Grid columns={3}>
          <TextInput
            label={i18n.t('general.labels.currencySymbol')}
            value={formatCurrencySymbol(tenant?.currency) || EMPTY_PLACEHOLDER}
            data-testid={suffixTestId('currencySymbol', props)}
            isDisabled
          />
        </Grid>
      </Show>
    </>
  );
}
