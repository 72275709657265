/* eslint-disable no-restricted-syntax */
import {boolean, number, object, SchemaOf, string} from 'yup';

import {EngineRequestBody} from '@dms/api';
import i18n from '@dms/i18n';

const BE_MAX_INTEGER_ALLOWED = 32767;
const MAX_NUMBER_OF_CYLINDERS = BE_MAX_INTEGER_ALLOWED;
const MAX_NUMBER_OF_GEARS = BE_MAX_INTEGER_ALLOWED;

const transformNaNTo =
  <T>(transformValue: T) =>
  (val: number): number | T => {
    if (isNaN(val)) {
      return transformValue;
    }
    return val;
  };

// eslint-disable-next-line no-restricted-syntax
export const $EngineRequestBody = object().shape({
  carbonDioxideEmission: number().transform(transformNaNTo(null)).nullable(),
  cylinderCount: number()
    .transform(transformNaNTo(null))
    .nullable()
    .max(MAX_NUMBER_OF_CYLINDERS, i18n.t('entity.vehicle.labels.maxCylinderCount')),
  emissionClass: string().nullable(),
  engineCode: string().nullable(),
  engineName: string().nullable(),
  engineNumber: string().nullable(),
  engineVolume: number().transform(transformNaNTo(null)).nullable(),
  fuelConsumptionCombined: number().transform(transformNaNTo(null)).nullable(),
  fuelConsumptionExtraUrban: number().transform(transformNaNTo(null)).nullable(),
  fuelConsumptionUrban: number().transform(transformNaNTo(null)).nullable(),
  gearCount: number()
    .transform(transformNaNTo(null))
    .nullable()
    .max(MAX_NUMBER_OF_GEARS, i18n.t('entity.vehicle.labels.maxGearCount')),
  hasDpf: boolean().nullable(),
  maxTorque: string().nullable(),
  electricEngineNote: string().nullable(),
  maximalPower: number().nullable(),
}) as unknown as SchemaOf<Partial<EngineRequestBody>>;
