import {format, parseISO} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {
  ButtonProps,
  Card,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  openDialog,
  showNotification,
} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {v4 as uuid} from 'uuid';

import {Helmet} from 'react-helmet-async';
import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {head, indexBy, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  CreateCashReceiptV4RequestBody,
  useCreateCashReceiptV4Mutation,
  useGetCashRegisterListQuery,
  useGetTenantQuery,
  useGetVatRatesQuery,
  useLazyGetInvoiceV4Query,
  useRenderIncomeCashReceiptDocumentMutation,
  VatTypeEnum,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {accountingRoutes, testIds} from '@dms/routes';
import {downloadFile, handleApiError, useGetCurrentBranch, usePermissions} from '@dms/shared';

import {buildArray, getIsoStringWithoutTimezone} from 'shared';

import {BasicInformation} from '../../components/BasicInformation';
import {CashRegisterAlerts} from '../../components/CashRegisterAlerts';
import {CustomerAndSupplier} from '../../components/CustomerAndSupplier';
import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsForm} from '../../components/listOfItems/ListOfItemsForm';
import {PairedInvoice} from '../../components/PairedInvoice/PairedInvoice';
import {PairWithInvoiceIdForm} from '../../components/PairWithInvoiceIdForm';
import {
  DATE_FORMAT,
  DEFAULT_EXCHANGE_RATE_AMOUNT,
  DEFAULT_EXCHANGE_RATE_TYPE,
  getEmptyItem,
  LIST_ITEM_FIELD_NAME,
} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {AccountingDocumentFormValues} from '../../types';
import {$CreateIncomeCashReceiptSchema} from './$CreateIncomeCashReceiptSchema';

export function CreateIncomeCashReceipt() {
  const navigate = useNavigate();

  const {
    data: cashRegisters,
    isLoading: isLoadingCashRegisters,
    isError: isCashRegisterError,
  } = useGetCashRegisterListQuery({
    inactive: false,
    withValidIncomeSeries: true,
  });
  const cashRegistersById = indexBy((item) => item.id, cashRegisters ?? []);
  const {activeBranchId, data: currentBranch} = useGetCurrentBranch();

  const [getInvoiceQuery, {currentData: invoice}] = useLazyGetInvoiceV4Query();
  const [createCashReceipt] = useCreateCashReceiptV4Mutation();
  const [renderCashReceipt] = useRenderIncomeCashReceiptDocumentMutation();
  const [canReadInvoices] = usePermissions({permissionKeys: ['readInvoice']});

  const {data: tenant, isLoading: isLoadingTenant, isError: isGetTenantError} = useGetTenantQuery();
  const {isLoading: isLoadingVatRates, isError: isGetVatRatesError} = useGetVatRatesQuery();

  const getFirstRegisterId = (val: string | undefined) =>
    head((cashRegisters ?? []).filter((register) => register.currency === val))?.id ?? null;

  const isLoading = isLoadingCashRegisters || isLoadingTenant || isLoadingVatRates;
  const isError = isCashRegisterError || isGetTenantError || isGetVatRatesError;

  const handlePairWithInvoice = (formApi: UseFormReturn<AccountingDocumentFormValues>) => {
    const {customer} = formApi.getValues();

    const setInvoice = (invoiceId: string) => {
      getInvoiceQuery({invoiceId})
        .unwrap()
        .then((invoiceData) => {
          const invoiceCurrency =
            invoiceData.exchangeRateRatio?.currency ?? invoiceData.currency ?? null;
          const priceWithVat =
            invoiceData.exchangeRateRatioDocumentSummary?.priceWithVat.amount ??
            invoiceData.totalPriceWithVat.amount ??
            null;

          const priceWithoutVat =
            invoiceData.exchangeRateRatioDocumentSummary?.priceWithoutVat.amount ??
            invoiceData.totalPriceWithoutVat.amount ??
            null;

          formApi.setValue('customer', invoiceData.customer);
          formApi.setValue('paymentInfo.variableSymbol', invoiceData.number);
          formApi.setValue('paymentType', 'income/invoice_payment');
          formApi.setValue('currency', invoiceCurrency);
          formApi.setValue('cashRegisterId', getFirstRegisterId(invoiceCurrency));
          formApi.setValue(
            'reason',
            i18n.t('entity.invoice.labels.paymentOfInvoice', {number: invoiceData.number})
          );
          formApi.setValue(
            'exchangeRateRatio.amount',
            invoiceData.exchangeRateRatio?.amount?.toString() ?? null
          );
          formApi.setValue('exchangeRateRatio.ratio', invoiceData.exchangeRateRatio?.ratio ?? null);
          formApi.setValue(LIST_ITEM_FIELD_NAME, [
            {
              description: i18n.t('entity.invoice.labels.paymentOfInvoice', {
                number: invoiceData.number,
              }),
              isUnitPriceWithVat: true,
              quantity: '1',
              pricePerUnit: priceWithVat,
              itemId: uuid(),
              unit: 'pcs',
              vatType: VatTypeEnum.Z,
              priceWithoutVat,
              priceWithVat,
              relatedItemId: null,
              type: 'standard',
            },
          ]);
        })
        .catch(handleApiError)
        .finally(() => formApi.setValue('id', invoiceId));
    };

    openDialog(
      <PairWithInvoiceIdForm
        onSubmit={setInvoice}
        customerId={customer?.id}
        data-testid={testIds.accounting.createCashReceiptIncome('pair-invoice-form')}
      />,
      {
        size: 'large',
        title: i18n.t('entity.accounting.labels.listOfInvoices'),
        'data-testid': testIds.accounting.createCashReceiptIncome('pair-invoice-form'),
      }
    );
  };

  const handleSubmit: FormSubmitHandler<AccountingDocumentFormValues> = async (data) => {
    const cashRegisterCurrency = cashRegistersById[data.cashRegisterId ?? '']?.currency;
    const tenantCurrency = tenant?.currency;

    if (isNil(cashRegisterCurrency) || isNil(currentBranch) || isNil(tenantCurrency)) {
      showNotification.error();
      return;
    }

    const shouldSubmitExchangeRatio =
      isFeatureEnabled(featureFlags.SALES_FOREIGN_CURRENCY) &&
      cashRegisterCurrency !== tenantCurrency;

    const createCashRegisterDocumentRequestBody: CreateCashReceiptV4RequestBody = {
      cashRegisterId: data.cashRegisterId ?? '',
      issuedAt: getIsoStringWithoutTimezone(parseISO(data.issueDate)),
      items: data.invoiceItems.map((item) => ({
        description: item.description,
        unitPrice: {
          amount: item.pricePerUnit?.toString() ?? '0',
          currency: cashRegisterCurrency,
        },
        quantity: item.quantity.toString(),
        unit: item.unit ?? 'pcs',
        vatType: item.vatType,
        withVat: item.isUnitPriceWithVat,
      })),
      kindOfPayment: data.paymentType ?? 'income/current_payment',
      paidByCard: data.paymentInfo.paymentMethod === 'CARD',
      type: 'income',
      purpose: data.reason,
      customerId: data.customer?.id,
      relatedInvoicingDocumentId: data.id ?? null,
      relatedInvoicingDocumentType: data.id ? 'invoice' : null,
      note: data.footerNote,
      variableSymbol: data.paymentInfo.variableSymbol,
      branchId: activeBranchId,
      billingInformationId: currentBranch.billingInformation?.id ?? 's',
      exchangeRateRatio:
        shouldSubmitExchangeRatio &&
        data.exchangeRateRatio?.amount &&
        data?.exchangeRateRatio?.ratio
          ? {
              amount: parseFloat(data.exchangeRateRatio?.amount),
              ratio: data?.exchangeRateRatio?.ratio.toString(),
              currency: cashRegisterCurrency,
            }
          : null,
      documentSource: {
        documentSource: 'internal',
        moduleSource: 'accounting',
        moduleSourceId: null,
        serviceSource: 'omnetic',
      },
    };

    await createCashReceipt({createCashRegisterDocumentRequestBody})
      .unwrap()
      .then(async (cashReceipt) => {
        if (data.downloadAfterCreation && cashReceipt) {
          await renderCashReceipt({
            renderIncomeCashReceiptDocumentRequestBody: {cashRegisterDocumentId: cashReceipt?.id},
          })
            .unwrap()
            .then((render) => render.pdfUrl)
            .then(downloadFile)
            .catch(handleApiError);
        }
      })
      .then(() => showNotification.success())
      .then(() => navigate(accountingRoutes.cashReceiptsOverview))
      .catch(handleApiError);
  };

  const defaultValues: Partial<AccountingDocumentFormValues> = {
    cashRegisterId: cashRegisters?.[0]?.id,
    paymentInfo: {
      paymentMethod: 'CASH',
    },
    paymentType: 'income/current_payment',
    invoiceItems: [getEmptyItem({unit: 'pcs'})],
    dateOfTaxableSupply: format(new Date(), DATE_FORMAT),
    issueDate: format(new Date(), DATE_FORMAT),
    exchangeRateRatio: {
      amount: DEFAULT_EXCHANGE_RATE_AMOUNT,
      currency: head(cashRegisters ?? [])?.currency ?? null,
      ratio: null,
      type: DEFAULT_EXCHANGE_RATE_TYPE,
    },
  };

  const pageTitle = i18n.t('page.accounting.cashReceipt.newIncomeDocument');

  return (
    <>
      <Helmet title={pageTitle} />
      <DataStatus minHeight="80vh" isLoading={isLoading} isError={isError}>
        <Form<AccountingDocumentFormValues>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          schema={$CreateIncomeCashReceiptSchema}
        >
          {(control, formApi) => {
            const invoiceId = formApi.watch('id');
            const canPairWithInvoice = isNil(invoiceId) && canReadInvoices;

            const pairWithInvoiceButtons = buildArray<ButtonProps>().when(canPairWithInvoice, {
              title: i18n.t('entity.cashRegister.labels.pairWithInvoice'),
              leftIcon: 'action/compare_arrows',
              onClick: () => handlePairWithInvoice(formApi),
              'data-testid': testIds.accounting.createCashReceiptIncome('select-invoice'),
            });

            return (
              <InvoiceVatRateProvider control={control} listItemType="cash-receipts">
                <VStack>
                  <InvoiceDetailHeader
                    title={pageTitle}
                    actions={[
                      {
                        type: 'form-button',
                        buttonType: 'submit',
                        title: i18n.t('page.accounting.cashRegister.createAndDownload'),
                        variant: 'secondary',
                        onClick: () => formApi.setValue('downloadAfterCreation', true),
                        control,
                      },
                      {
                        type: 'form-button',
                        buttonType: 'submit',
                        control,
                        title: i18n.t('general.actions.create'),
                        'data-testid': testIds.accounting.createCashReceiptIncome('create-receipt'),
                      },
                    ]}
                  />
                  <CashRegisterAlerts type="income" control={control} />

                  <InvoicePage>
                    <Card>
                      <CustomerAndSupplier
                        control={control}
                        formApi={formApi}
                        buttons={pairWithInvoiceButtons}
                        data-testid={testIds.accounting.createCashReceiptIncome(
                          'customer-and-supplier'
                        )}
                      />
                    </Card>

                    <Show when={isNotNil(invoice)}>
                      <PairedInvoice
                        control={control}
                        formApi={formApi}
                        invoiceDocument={invoice}
                        invoiceType="invoice"
                        data-testid={testIds.accounting.createCashReceiptIncome('paired-invoice')}
                      />
                    </Show>

                    <BasicInformation
                      control={control}
                      formApi={formApi}
                      documentType="income"
                      data-testid={testIds.accounting.createCashReceiptIncome('basic-information')}
                    />

                    <ListOfItemsForm
                      control={control}
                      formApi={formApi}
                      listItemType="cash-receipts"
                      data-testid={testIds.accounting.createCashReceiptIncome('list-of-items')}
                    />

                    <Card>
                      <FormField
                        control={control}
                        name="footerNote"
                        type="textarea"
                        label={i18n.t('general.labels.note')}
                        data-testid={testIds.accounting.createCashReceiptIncome('note')}
                      />
                    </Card>
                  </InvoicePage>
                </VStack>
              </InvoiceVatRateProvider>
            );
          }}
        </Form>
      </DataStatus>
    </>
  );
}
