import {Action, Alert, Button, Card, DataStatus, Separator} from 'platform/components';
import {Align, Grid, Hide, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC} from 'react';

import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  IdentityCardResponseBodyV2,
  PersonResponseBodyV2,
  useGetCustomerV2Query,
} from '@dms/api';
import i18n from '@dms/i18n';
import {NoPermissionTooltip, useAddress, useAvailableVerificationRegisters} from '@dms/shared';
import {
  WithValidationErrors,
  CheckoutContractInformationBody,
  IdentityCardData,
  LegalFormEnum,
  PersonRequestBody,
  getContractName,
  getContractType,
} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {CustomerVerification} from '../../CustomerVerification/CustomerVerification';
import {
  CheckoutContractDeputyPerson,
  CheckoutContractDeputyPersonFormState,
} from './CheckoutContractDeputyPerson';
import {CheckoutContractInfo} from './CheckoutContractInfo';
import {
  CheckoutContractInformationForm,
  CheckoutContractInformationFormState,
} from './CheckoutContractInformationForm';
import {CheckoutPersonContractInformationFormState} from './CheckoutPersonContractInformationForm';

interface CheckoutContractInformationItemProps {
  customerId: string;
  checkoutContractInformation?: CheckoutContractInformationBody;
  isSoftDeleted?: boolean;
  isRadioDisabled?: boolean;
  isEditDisabled?: boolean;
  isExpandable?: boolean;
  checked?: boolean;
  expanded?: boolean;
  editOpen: boolean;
  actions?: Action[];
  loading?: boolean;
  id?: string;
  permanentPersonId?: string;
  disableDeputyPerson?: boolean;
  toggleExpand?: () => void;
  toggleEdit: () => void;
  onControlChange?: (checked: boolean) => void;
  onSubmit: (
    requestBody: CheckoutContractInformationFormState
  ) => Promise<WithValidationErrors<void>>;
  handleSelectDeputyPerson?: (values: CheckoutContractDeputyPersonFormState) => Promise<void>;
  handleSubmitDeputyPerson?: (
    requestBody: CheckoutPersonContractInformationFormState
  ) => Promise<WithValidationErrors<void>>;
  handlePersonSubmit: (
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleIdentityCardSubmit: (
    person: PersonResponseBodyV2
  ) => (
    cardData: IdentityCardData,
    id: string | null
  ) => Promise<IdentityCardResponseBodyV2 | null>;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  isAdditionalCustomer?: boolean;
  isDeputyPersonHidden?: boolean;
}

export const CheckoutContractInformationItem: FC<
  CheckoutContractInformationItemProps & TestIdProps
> = ({
  isSoftDeleted,
  checkoutContractInformation,
  checked,
  expanded,
  actions,
  editOpen,
  disableDeputyPerson,
  id,
  permanentPersonId,
  toggleExpand,
  toggleEdit,
  onSubmit,
  handleSubmitDeputyPerson,
  handleSelectDeputyPerson,
  onControlChange,
  handlePersonSubmit,
  handleAddressSubmit,
  handleIdentityCardSubmit,
  ...props
}) => {
  const {composeAddress} = useAddress();
  const {
    data: customer,
    isLoading,
    isError,
  } = useGetCustomerV2Query({customerId: props.customerId}, {skip: isNilOrEmpty(props.customerId)});
  const {registers, isLoading: isLoadingAvailableVerifyingRegisters} =
    useAvailableVerificationRegisters();

  const customerContractInformationV2 = customer?.contractInformation?.find(
    (contract) => contract.id === checkoutContractInformation?.customerContractInformation?.id
  );

  const customerContractInformation = checkoutContractInformation?.customerContractInformation;

  const composedAddress = match(customerContractInformationV2?.legalForm)
    .with('SELF_EMPLOYED', () =>
      composeAddress(customerContractInformationV2?.businessInfo?.address?.address)
    )
    .with('LEGAL_ENTITY', () =>
      composeAddress(customerContractInformationV2?.businessInfo?.address?.address)
    )
    .with('NATURAL_PERSON', () =>
      composeAddress(customerContractInformationV2?.person?.permanentAddress?.address)
    )
    .otherwise(() => null);

  const onSelectDeputyPerson = async (
    values: CheckoutContractDeputyPersonFormState
  ): Promise<void> => {
    if (!handleSelectDeputyPerson) {
      return;
    }

    const filteredValues = {
      deputyPersons: values.deputyPersons.filter(Boolean),
    };

    return await handleSelectDeputyPerson(filteredValues);
  };

  return (
    <DataStatus isEmpty={isNilOrEmpty(customer)} isLoading={isLoading} isError={isError}>
      <Card
        data-testid={props['data-testid']}
        variant="inlineWhite"
        isExpandable={props.isExpandable}
        isExpanded={expanded}
        onExpandButtonClick={toggleExpand}
        title={
          checkoutContractInformation
            ? getContractName(customerContractInformationV2)
            : i18n.t('entity.customer.actions.newContractInformation')
        }
        subtitle={getContractType(customerContractInformationV2)}
        parameters={composedAddress ? [composedAddress] : undefined}
        parametersProps={{noWrap: undefined}}
        control={{
          type: 'radio',
          'data-testid': props['data-testid'],
          isDisabled: isSoftDeleted || props.isRadioDisabled,
          value: !!checked,
          onChange: () => onControlChange?.(true),
        }}
        actions={actions}
      >
        {editOpen ? (
          <div id={id}>
            <CheckoutContractInformationForm
              customerId={props.customerId}
              data-testid={suffixTestId('contractInformation', props)}
              checkoutContractInformation={checkoutContractInformation}
              onSubmit={onSubmit}
              handleDiscard={toggleEdit}
              handleAddressSubmit={handleAddressSubmit}
              handlePersonSubmit={handlePersonSubmit}
              handleIdentityCardSubmit={handleIdentityCardSubmit}
              permanentPersonId={permanentPersonId}
              isAdditionalCustomer={props.isAdditionalCustomer}
            />
          </div>
        ) : (
          <Grid columns={1}>
            {isSoftDeleted ? (
              <Alert
                data-testid={suffixTestId('softDeletes', props)}
                variant="warning"
                title={i18n.t('general.notifications.contractSoftDeleted.title')}
              />
            ) : (
              <Align left>
                <NoPermissionTooltip shouldShowTooltip={Boolean(props.isEditDisabled)}>
                  <Button
                    data-testid={suffixTestId('edit', props)}
                    variant="link"
                    leftIcon="image/edit"
                    onClick={toggleEdit}
                    isDisabled={props.isEditDisabled}
                    title={i18n.t('general.actions.edit')}
                  />
                </NoPermissionTooltip>
              </Align>
            )}
            <CheckoutContractInfo
              data-testid={props['data-testid']}
              person={customerContractInformationV2?.person}
              businessInfo={customerContractInformationV2?.businessInfo}
              identityCards={checkoutContractInformation?.selectedIdentityCards}
              isAdditionalCustomer={props.isAdditionalCustomer}
            />
          </Grid>
        )}
        <DataStatus isLoading={isLoadingAvailableVerifyingRegisters}>
          <Show when={customerContractInformation?.id && isPositive(registers?.length)}>
            <CustomerVerification
              contractInformationId={customerContractInformation?.id ?? ''}
              data-testid={suffixTestId('verification', props)}
            />
          </Show>
        </DataStatus>
        <Hide when={props.isDeputyPersonHidden}>
          <Separator />
          <CheckoutContractDeputyPerson
            customerId={props.customerId}
            data-testid={suffixTestId('deputyPerson', props)}
            contractInformation={checkoutContractInformation?.customerContractInformation}
            deputyPerson={checkoutContractInformation?.deputyPersons}
            handlePersonSubmit={handlePersonSubmit}
            handleIdentityCardSubmit={handleIdentityCardSubmit}
            handleAddressSubmit={handleAddressSubmit}
            handleSubmitDeputyPerson={handleSubmitDeputyPerson}
            handleSelectDeputyPerson={onSelectDeputyPerson}
            disabled={disableDeputyPerson || isSoftDeleted}
            editable={!isSoftDeleted && !disableDeputyPerson}
            multiple={customerContractInformationV2?.legalForm !== LegalFormEnum.NATURAL_PERSON}
            mandatory={customerContractInformationV2?.legalForm !== LegalFormEnum.NATURAL_PERSON}
          />
        </Hide>
      </Card>
    </DataStatus>
  );
};
