import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {useDeleteSmallUnitMaterialApiMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function WorkshopSmallUnitMaterials() {
  const navigate = useNavigate();

  const [deleteSmallUnitMaterial] = useDeleteSmallUnitMaterialApiMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.workshopSmallUnitMaterialDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteSmallUnitMaterial({smallUnitMaterialId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.workshopMinorUnitMaterial.labels.minorUnitMaterial'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.actions.newSmallUnitMaterial'),
            onClick: () => navigate(settingsRoutes.workshopSmallUnitMaterialCreate),
          },
        ],
      }}
      data-testid={testIds.settings.workshopSmallUnitMaterial('template')}
    >
      <DataGrid
        gridCode="small-unit-material"
        actionCallback={actionCallback}
        data-testid={testIds.settings.workshopSmallUnitMaterial('datagrid')}
      />
    </SettingsTemplate>
  );
}
