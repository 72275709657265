import {Dialog} from 'platform/components';
import {Box, HStack, Show, Spinner, Text, VStack} from 'platform/foundation';

import {isPositive} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

export interface DownloadDialogProps {
  isOpen?: boolean;
  fileName?: string;
  fileCount?: number;
  withWrapper?: boolean;
}
export function DownloadDialog({isOpen, withWrapper, ...file}: DownloadDialogProps) {
  const displayFileInfo = isPositive(file.fileCount) && file.fileName;

  const content = (
    <VStack spacing={2}>
      <Show when={displayFileInfo}>
        <HStack align="center" spacing={2}>
          <Box flexShrink={0}>
            <Text>
              {i18n.t('entity.vehicle.labels.fileName')}
              {': '}
            </Text>
          </Box>
          <Text size="small" color="secondary">
            {file.fileName}
          </Text>
        </HStack>
        <HStack align="center" spacing={2}>
          <Text>
            {i18n.t('entity.vehicle.labels.numberOfPictures')}
            {': '}
          </Text>
          <Text size="small" color="secondary">
            {file.fileCount}
          </Text>
        </HStack>
      </Show>
      <Box paddingVertical={3}>
        <HStack justify="center">
          <Spinner variant="default" />
        </HStack>
      </Box>
    </VStack>
  );
  return withWrapper ? (
    <Dialog
      title={i18n.t('entity.vehicle.labels.downloadPhotos')}
      isOpen={isOpen ?? false}
      size="small"
      data-testid={testIds.vehicles.home('download-dialog')}
    >
      {content}
    </Dialog>
  ) : (
    content
  );
}
