import {Icon} from 'platform/foundation';

import {filter, isNotNil} from 'ramda';

import {MenuItemProps, usePinUserMenuItemMutation, useUnpinUserMenuItemMutation} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {suffixTestId} from 'shared';

import {MenuItemWithPin} from '../utils/addPinsToMenuItems';
import {MenuItemActionButton} from './MenuItemActionButton';

type PinProps = Pick<MenuItemWithPin<MenuItemProps>, 'path' | 'id' | 'isPinned' | 'legacyPinId'>;

export function MenuItemPin(props: PinProps) {
  const [unpinUserMenuItem] = useUnpinUserMenuItemMutation();
  const [pinUserMenuItem] = usePinUserMenuItemMutation();

  const testIdBase = `routePath=${props.path};id=${props.id}-menuItem`;

  // Because of difference in logic from legacy sidebar, we need to do two checks.
  // `props.isPinned` represents the new way of pinning items,
  // while `props.legacyPinId` represents the old way.
  const isPinned = props.isPinned || Boolean(props.legacyPinId);

  const handlePin = () => {
    if (!isPinned) {
      return pinUserMenuItem({item: props.id}).unwrap().catch(handleApiError);
    }

    const idsToUnpin = filter(isNotNil, [
      props.legacyPinId, // Unpin legacy pinned item
      props.isPinned ? props.id : null,
    ]);
    for (const pinId of idsToUnpin) {
      unpinUserMenuItem({item: pinId}).unwrap().catch(handleApiError);
    }
  };

  return (
    <MenuItemActionButton
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handlePin();
      }}
    >
      <Icon
        size={4}
        color="text.white"
        value={isPinned ? 'custom/push_pin' : 'custom/push_pin_outlined'}
        data-testid={suffixTestId('pin', {'data-testid': testIdBase})}
      />
    </MenuItemActionButton>
  );
}
