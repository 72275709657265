import {useCallback, useRef, useState} from 'react';

import {isNotNil, mergeAll} from 'ramda';

import {GetWarehousesResponse} from '@dms/api';

import {QueryFilterObject} from 'features/datagrid';

import {DataGridTreeFolderHandle} from '../types/treeFolder/DataGridTreeFolderHandle';

type TreeFolderFilters = {
  availableWarehouses?: GetWarehousesResponse;
  warehouseId?: string;
};

export function useWarehouseDataGridTreeFolder(filters?: TreeFolderFilters) {
  const ref = useRef<DataGridTreeFolderHandle>();
  const [dataGridModifier, setDataGridModifier] = useState<{
    key: string;
    queryModifier: (filter: QueryFilterObject) => QueryFilterObject;
  }>();

  const availableWarehousesIds = filters?.availableWarehouses?.map(
    (availableWarehouse) => availableWarehouse.id
  );

  const setRef = useCallback((node: any) => {
    if (isNotNil(node)) {
      ref.current = node;

      setDataGridModifier({
        key: `${node?.activeFolderId ?? '0'}_${node?.includeSubfolders ?? '0'}`,
        queryModifier: (filter: QueryFilterObject) =>
          mergeAll([
            filter,
            node?.activeFolderId
              ? node?.includeSubfolders
                ? {treeFolderIds: [node?.activeFolderId], treeFolderId: undefined}
                : {treeFolderId: node?.activeFolderId, treeFolderIds: undefined}
              : {treeFolderIds: undefined, treeFolderId: undefined},
            {availableWarehousesList: availableWarehousesIds},
            {warehouseId: filter?.warehouseId ?? filters?.warehouseId},
          ]),
      });
    }
  }, []);

  return [setRef, dataGridModifier, ref.current?.activeFolderId] as const;
}
