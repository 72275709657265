import {Parameters} from 'platform/components';
import {Align, Box, Heading, HStack, Space, Text} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';
import styled, {css} from 'styled-components';

import {FC} from 'react';

import {isNil} from 'ramda';

import {SourcingVehicleDetailResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CountryFlag, Rank} from '@dms/teas';

import {PlaceholderVehicleImage} from '../../../../../../../components/PlaceholderVehicleImage/PlaceholderVehicleImage';
import {detailFirstLine} from '../../../../../../../utils/detailFirstLine';
import {detailSecondLine} from '../../../../../../../utils/detailSecondLine';
import {getVehiclePrice} from '../../../../../../../utils/getVehiclePrice';
import {getVehicleSellerCountry} from '../../../../../../../utils/getVehicleSellerCountry';

const Container = styled.div`
  height: 102px;
  display: flex;
  min-width: 400px;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: 0 4px 32px rgb(0 0 0 / 16%);
`;

const ImgWrapper = styled.div`
  width: 132px;
  height: 100%;
  background-size: cover;
  background-position: center center;

  .img-wrapper {
    height: 100%;
  }
`;

export type PriceMapTooltipProps = {
  active?: boolean;
  isInComparison?: boolean;
  payload?: {payload?: SourcingVehicleDetailResponseBody}[];
  currentVehicle?: SourcingVehicleDetailResponseBody;
};

export const PriceMapTooltip: FC<PriceMapTooltipProps> = ({
  active,
  payload,
  currentVehicle,
  isInComparison = false,
}) => {
  const formatNumber = useFormatNumber();

  if (!active || isNil(payload?.[0]?.payload)) {
    return null;
  }

  const [{payload: vehicle}] = payload;
  const {summary} = vehicle;
  const vehicleData = vehicle.sourcingVehicle;
  const isCurrentVehicle =
    isNil(currentVehicle) || currentVehicle?.sourcingVehicle?.id === vehicleData?.id;
  const vehiclePrice = getVehiclePrice({
    vehicle,
  });

  const formattedVehiclePrice = formatNumber(vehiclePrice, 0);
  const sellerCountry = getVehicleSellerCountry(vehicleData);

  return (
    <Container data-testid="price-map-tooltip">
      <ImgWrapper>
        <PlaceholderVehicleImage
          src={vehicleData?.thumbnailUrl?.full}
          alt={vehicleData?.make?.translation}
        />
      </ImgWrapper>
      <Box paddingHorizontal={3} paddingVertical={2}>
        <HStack align="center" spacing={2}>
          <Heading size={4}>{detailFirstLine(vehicleData)}</Heading>
          {sellerCountry && <CountryFlag country={sellerCountry} />}
        </HStack>
        <Parameters
          parameters={detailSecondLine(formatNumber, vehicleData)}
          color="secondary"
          data-testid={testIds.sourcing.vehicleDetail('priceMapTooltip-Parameters')}
        />
        <Space vertical={2} />

        <HStack>
          <Box flex={7}>
            <HStack>
              <Box flex={7}>
                <Text size="xSmall" color="secondary">
                  {isInComparison
                    ? i18n.t('entity.vehicle.labels.adPrice')
                    : isCurrentVehicle
                      ? i18n.t('entity.vehicle.labels.retailPrice')
                      : i18n.t('entity.vehicle.labels.originalPrice')}
                </Text>
              </Box>
              <Box flex={5} data-testid="price-map-tooltip-retail-price">
                <Heading size={6} alternative>
                  {formattedVehiclePrice}
                </Heading>
              </Box>
            </HStack>

            <div
              css={css`
                height: 4px;
              `}
            />

            {'similarity' in vehicleData && typeof vehicleData?.similarity?.score === 'number' ? (
              <HStack>
                <Box flex={7}>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.vehicle.labels.carSimilarity')}
                  </Text>
                </Box>
                <Box flex={5} data-testid="price-map-tooltip-car-similarity">
                  <Heading size={6} alternative>
                    {`${String(Math.round(vehicleData?.similarity?.score * 100))} %`}
                  </Heading>
                </Box>
              </HStack>
            ) : null}
          </Box>

          <Box flex={5}>
            <HStack>
              <Box flex={1}>
                <Align right>
                  <Text size="xSmall" color="secondary">
                    {i18n.t('entity.vehicle.labels.features')}
                  </Text>
                </Align>
              </Box>
              <Box flex={1}>
                <Align right>
                  <Rank
                    rank={summary?.rank ?? undefined}
                    data-testid={testIds.sourcing.vehicleDetail('priceMapTooltip-Rank')}
                  />
                </Align>
              </Box>
            </HStack>
          </Box>
        </HStack>
      </Box>
    </Container>
  );
};
