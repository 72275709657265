import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {
  InvoiceDocumentTypes,
  useGetBalanceInvoiceV4Query,
  useGetCorrectiveTaxDocumentV4Query,
  useGetInvoiceProformaV4Query,
  useGetInvoiceV4Query,
  useGetTaxDocumentForPaymentQuery,
} from '@dms/api';

import {Nullish} from 'shared';

export type AccountingDocument = ReturnType<typeof useGetAccountingDocuments>['data'];

export const useGetAccountingDocuments = (
  id: string | Nullish,
  documentType: InvoiceDocumentTypes | Nullish
) => {
  const hasInvalidParams = isNil(id) || isNil(documentType);
  const getSkipArgFor = (type: InvoiceDocumentTypes) => hasInvalidParams || documentType !== type;

  const {
    data: invoiceData,
    isLoading: isLoadingInvoice,
    isError: isInvoiceError,
  } = useGetInvoiceV4Query({invoiceId: id!}, {skip: getSkipArgFor('invoice')});
  const {
    data: balanceInvoiceData,
    isLoading: isLoadingBalanceInvoice,
    isError: isBalanceInvoiceError,
  } = useGetBalanceInvoiceV4Query(
    {balanceInvoiceId: id!},
    {skip: getSkipArgFor('balance_invoice')}
  );
  const {
    data: proformaInvoiceData,
    isLoading: isLoadingProformaInvoice,
    isError: isProformaInvoiceError,
  } = useGetInvoiceProformaV4Query(
    {invoiceProformaId: id!},
    {skip: getSkipArgFor('invoice_proforma')}
  );
  const {
    data: taxDocumentForPaymentData,
    isLoading: isLoadingTaxDocumentForPayment,
    isError: isTaxDocumentForPaymentError,
  } = useGetTaxDocumentForPaymentQuery(
    {id: id!},
    {skip: getSkipArgFor('tax_document_for_payment')}
  );
  const {
    data: correctiveTaxDocumentData,
    isLoading: isCorrectiveTaxDocumentLoading,
    isError: isCorrectiveTaxDocumentError,
  } = useGetCorrectiveTaxDocumentV4Query(
    {correctiveTaxDocumentId: id!},
    {skip: getSkipArgFor('corrective_tax_document')}
  );

  const isError =
    isBalanceInvoiceError ||
    isInvoiceError ||
    isProformaInvoiceError ||
    isTaxDocumentForPaymentError ||
    isCorrectiveTaxDocumentError;

  const isLoading =
    isLoadingInvoice ||
    isLoadingBalanceInvoice ||
    isLoadingProformaInvoice ||
    isLoadingTaxDocumentForPayment ||
    isCorrectiveTaxDocumentLoading;

  const data =
    match(documentType)
      .with('invoice', always(invoiceData))
      .with('balance_invoice', always(balanceInvoiceData?.invoice))
      .with('invoice_proforma', always(proformaInvoiceData))
      .with('tax_document_for_payment', always(taxDocumentForPaymentData))
      .with('corrective_tax_document', always(correctiveTaxDocumentData))
      .otherwise(always(null)) ?? null;

  return {data, isError, isLoading} as const;
};
