import {OptionType} from 'platform/components';

import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {selectSeries, SeriesTypeEnum} from '@dms/teas';

type UseBranchSeriesProps = {
  branchId?: string;
  seriesType?: SeriesTypeEnum;
};

export const useSeries = ({branchId, seriesType}: UseBranchSeriesProps) => {
  const series = useSelector(selectSeries);

  const seriesOptions = useMemo(
    () =>
      series.reduce<Array<OptionType>>((memo, option) => {
        if (option.type === seriesType) {
          memo.push({value: option.id, label: option.name});
        }
        return memo;
      }, []),
    [series, seriesType]
  );

  const dropdownPlaceholder = !branchId
    ? i18n.t('entity.accounting.labels.selectSeries')
    : seriesOptions.length === 0
      ? i18n.t('entity.cashRegister.notifications.noSeriesAvailable')
      : undefined;

  return {
    seriesOptions,
    dropdownPlaceholder,
  };
};
