import {useDeepCompareMemoize} from 'platform/components';

import {useMemo} from 'react';

import {isEmpty, isNil} from 'ramda';

import {useListPermissionExpressionQuery} from '@dms/api';

import {useScopeEvaluator} from './hooks/useScopeEvaluator';
import {PermissionsKeys, Scopes} from './permissions';
import {areParamsWithScope} from './utils/areParamsWithScope';
import {findUserAction} from './utils/findUserAction';
import {findUserField} from './utils/findUserField';
import {getRequestedPermissionsFromKeys} from './utils/getRequestedPermissionsFromKeys';
import {isActionPermission} from './utils/isActionPermission';

type UsePermissionsArg<Keys extends PermissionsKeys[]> = {
  permissionKeys: Keys;
} & Scopes<Keys>;

export function usePermissions<Keys extends PermissionsKeys[]>(params: UsePermissionsArg<Keys>) {
  const {data: userPermissions, requestId, isSuccess} = useListPermissionExpressionQuery();
  const [evaluatePermission] = useScopeEvaluator();

  const requestedPermissions = useMemo(
    () => getRequestedPermissionsFromKeys(params.permissionKeys),

    /**
     * params.permissionKeys is hardcoded
     * https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/7314#note_401393
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.permissionKeys.length]
  );

  const resolvedPermissions = useMemo(
    () =>
      requestedPermissions.map((item) => {
        const foundPermission = isActionPermission(item)
          ? userPermissions?.actions?.find(findUserAction(item))
          : userPermissions?.fields?.find(findUserField(item));

        if (isNil(foundPermission)) {
          return null;
        }
        if (isEmpty(foundPermission)) {
          return null;
        }

        return {
          ...foundPermission,
          key: item.key,
        };
      }),
    /**
     * requestedPermissions is object fetched by BE, we use requestId to invalidate it
     * params.permissionKeys is hardcoded
     * https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/7314#note_401393
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestedPermissions.length, requestId, isSuccess]
  );

  const resolvedScopes = areParamsWithScope(params) ? (params.scopes ?? {}) : {};
  const memoizedScopes = useDeepCompareMemoize(resolvedScopes);

  const evaluatedPermissions = useMemo(
    () => evaluatePermission(resolvedPermissions, memoizedScopes),
    [evaluatePermission, resolvedPermissions, memoizedScopes]
  );

  return evaluatedPermissions;
}
