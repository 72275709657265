import {Grid} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {
  selectBodyColors,
  selectColorTypes,
  selectInteriorColors,
  selectInteriorMaterials,
} from '../../../store/vehicleCatalogue/selectors';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

export const ColorSpecifications: FC<PropsWithChildren<any>> = () => {
  const {Field, Subscribe} = useFormRenderer<VehicleCreateFormState>();

  return (
    <div
      css={css`
        margin-bottom: -16px;
      `}
    >
      <Grid columns={1}>
        <Subscribe
          name="type"
          component={({input: {value: vehicleType}}) => {
            /* eslint-disable react-hooks/rules-of-hooks */
            const colorTypes = useSelector(selectColorTypes(vehicleType));

            if (!colorTypes.length) {
              return null;
            }

            return (
              <Field
                as="chips"
                name="additionalInformation.exteriorColorSpecification"
                label={i18n.t('entity.vehicle.labels.bodyColorType')}
                options={colorTypes}
                enabledDeselect
              />
            );
          }}
        />
      </Grid>

      <Grid columns={4}>
        <Subscribe
          name="type"
          component={({input: {value: vehicleType}}) => {
            /* eslint-disable react-hooks/rules-of-hooks */
            const bodyColorOptions = useSelector(selectBodyColors(vehicleType));

            return (
              <Field
                as="select"
                name="additionalInformation.exteriorColor"
                label={i18n.t('entity.vehicle.labels.bodyColor')}
                options={bodyColorOptions}
              />
            );
          }}
        />
        <Field
          name="additionalInformation.manufacturerColorName"
          label={i18n.t('entity.vehicle.labels.colorName')}
        />
        <Field
          name="additionalInformation.manufacturerColorCode"
          label={i18n.t('entity.vehicle.labels.manufacturerColorCode')}
        />
      </Grid>

      <Subscribe
        name="type"
        component={({input: {value: vehicleType}}) => {
          /* eslint-disable react-hooks/rules-of-hooks */
          const interiorColorOptions = useSelector(selectInteriorColors(vehicleType));
          const interiorMaterialOptions = useSelector(selectInteriorMaterials(vehicleType));
          return (
            <>
              {!!interiorMaterialOptions.length && (
                <Grid columns={1}>
                  <Field
                    as="chips"
                    name="additionalInformation.interiorMaterial"
                    label={i18n.t('entity.vehicle.labels.interiorMaterial')}
                    options={interiorMaterialOptions}
                    enabledDeselect
                  />
                </Grid>
              )}
              <Grid columns={4}>
                <Field
                  as="select"
                  name="additionalInformation.interiorColor"
                  label={i18n.t('entity.vehicle.labels.interiorColor')}
                  options={interiorColorOptions}
                />
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
};
