import {Card, DataStatus, Separator} from 'platform/components';
import {Grid, Heading, VStack} from 'platform/foundation';

import {useGetCustomerContractBasicInformationQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import {DiscountAttributes} from '../../DiscountAttributes/DiscountAttributes';
import {SummaryItem} from '../../SummaryItem/SummaryItem';

interface ContractBasicInfoTabProps extends TestIdProps {
  customerContractId: string;
}

export function ContractBasicInfoTab(props: ContractBasicInfoTabProps) {
  const {data, isLoading, isError} = useGetCustomerContractBasicInformationQuery({
    customerContractId: props.customerContractId,
  });

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={50}>
      <Card title={i18n.t('general.labels.basicInformation')}>
        <VStack spacing={4}>
          <Grid columns={4}>
            <SummaryItem
              isAlternative
              title={i18n.t('general.labels.name')}
              value={data?.name}
              data-testid={suffixTestId('name', props)}
            />
            <SummaryItem
              isAlternative
              title={i18n.t('general.labels.description')}
              value={data?.description}
              data-testid={suffixTestId('description', props)}
            />
            <SummaryItem
              isAlternative
              title={i18n.t('entity.order.labels.customerGroupForMaterialDelivery')}
              value={data?.materialDiscountGroup?.name}
              data-testid={suffixTestId('customerGroup', props)}
            />
          </Grid>
          <Separator spacing={0} />
          <Heading size={5}>{i18n.t('entity.workshopCustomerGroup.labels.customerGroups')}</Heading>
          <DiscountAttributes
            groupId={data?.materialDiscountGroup?.id}
            data-testid={suffixTestId('discounts', props)}
          />
        </VStack>
      </Card>
    </DataStatus>
  );
}
