import {match} from 'ts-pattern';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';

export function CostCorrections() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    match(actionKey)
      .with('redirectDetail', 'details', () =>
        navigate(composePath(warehouseRoutes.costCorrectionDetailOverview, {params: {id}}))
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode="warehouse-issue-note-cost-corrections-list"
        actionCallback={actionCallback}
        data-testid={testIds.warehouse.issueNotes('cost-corrections')}
      />
    </FullscreenDataGridWrapper>
  );
}
