import {FieldValues} from 'platform/components';
import {match} from 'ts-pattern';

import {always, indexBy, isNotNil} from 'ramda';
import {isDate} from 'ramda-adjunct';

import {
  DefinitionValueType,
  EntityResourceIds,
  FieldValue,
  TypedAnyValue,
  useGetFieldDefinitionListQuery,
  useGetTenantQuery,
} from '@dms/api';

import {getApiDateString, getApiDateTimeString} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {isTrueStringOrTrueBoolean} from '../utils/isTrueStringOrTrueBoolean';

type GetSubmitFieldValuesProps = {resourceId: EntityResourceIds};

export const useGetSubmitFieldValues = ({resourceId}: GetSubmitFieldValuesProps) => {
  const {data: tenant} = useGetTenantQuery();
  const {data: definitions} = useGetFieldDefinitionListQuery({
    resourceId,
    type: 'tenant',
  });
  const definitionsById = indexBy((item) => item.id, definitions ?? []);

  const getFieldValues = (values: FieldValues) =>
    Object.entries(values)
      .map(([key, value]) => {
        const valueType = definitionsById[key]?.valueType;

        const formattedValue = getFormattedValue(
          value,
          valueType,
          tenant?.currency ?? DEFAULT_CURRENCY
        );

        return {
          definitionId: key,
          value: {
            type: valueType,
            value: formattedValue,
          },
        } as FieldValue;
      })
      // TODO: remove after https://carvago.atlassian.net/browse/T20-58489 is resolved
      .filter((item) => isNotNil(item.value.value) && isNotNil(item.value.type));

  return [getFieldValues];
};

const getFormattedValue = (
  value: TypedAnyValue['value'],
  valueType: DefinitionValueType,
  tenantCurrency: string
): TypedAnyValue['value'] =>
  match(valueType)
    .with('date', always(isDate(value) ? getApiDateString(value) : null))
    .with('datetime', always(isDate(value) ? getApiDateTimeString(value) : null))
    .with(
      'money',
      always(isNotNil(value) ? {amount: value.toString(), currency: tenantCurrency} : null)
    )
    .with('boolean', always(isTrueStringOrTrueBoolean(value)))
    .with('integer', always(value ?? null))
    .with('decimal', always(value?.toString() ?? null))
    .otherwise(always(value?.toString() ?? null));
