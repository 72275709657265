import {filter, uniq} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {MenuItemProps} from '@dms/api';
import {moduleKeys} from '@dms/config';

export function transformPinnedMenuItems(
  pinnedMenuItems: string[],
  menuItemsById: Record<string, Pick<MenuItemProps, 'id' | 'content'>>
) {
  return uniq(
    filter(
      isNotNil,
      pinnedMenuItems.map((id) => {
        if (id === moduleKeys.settings) {
          // Historically settings were in the sidebar. They have been moved to top bar.
          // It's not possible to add settings to the pinned items anymore, but some users might have it already pinned.
          return null;
        }

        const menuItem = menuItemsById[id];
        if (!menuItem) {
          // There are some legacy pinned items that are not in the menuItems anymore, it has to be filtered out.
          return null;
        }

        return menuItem.id;
      })
    )
  );
}
