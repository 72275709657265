import {Box, getSize, Grid, Show, VStack} from 'platform/foundation';

import {ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';

import i18n from '@dms/i18n';

type SourcingLayoutProps = {
  header: ReactNode;
  content: ReactNode;
  iframe: ReactNode;
  pageTitle?: string;
  noResultsContent?: ReactNode;
};

export function SourcingLayout(props: SourcingLayoutProps) {
  const documentTitle = props.pageTitle || i18n.t('page.sourcing.labels.sourcing');

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Box height={`calc(100vh - ${getSize(12)})`} overflow="hidden">
        {props.noResultsContent ?? (
          <Grid columns={[1, 1, 2, 2]} spacing={0}>
            <Box height={`calc(100vh - ${getSize(21)})`} padding={4}>
              <VStack spacing={4} height={`calc(100vh - ${getSize(21)})`}>
                <Box flexShrink={0}>{props.header}</Box>
                {props.content}
              </VStack>
            </Box>
            <Show onNotebook onDesktop>
              {props.iframe}
            </Show>
          </Grid>
        )}
      </Box>
    </>
  );
}
