import {testIds} from '@dms/routes';
import {Main, ServiceCampaigns} from '@dms/shared';

interface VehicleCampaignsProps {
  vehicleId: string;
}

export function Campaigns(props: VehicleCampaignsProps) {
  return (
    <Main data-testid={testIds.vehicles.serviceCampaigns('page')}>
      <ServiceCampaigns
        vehicleId={props.vehicleId}
        data-testid={testIds.vehicles.serviceCampaigns('serviceCampaigns')}
      />
    </Main>
  );
}
