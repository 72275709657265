import {Card} from 'platform/components';
import {Grid, Heading, Space} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {FC, useState} from 'react';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamValue} from '../../../types/AuditParamValue';
import {ConditionDamageLocation} from '../hooks/useConditionDamageLocation';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {DamageLocation} from './DamageLocation';
import {LegendOfDamage} from './LegendOfDamage';

type LocationsSettingsRecord<T> = Partial<Record<AuditCategoryUniqueKey, T>>;
export type RelatedCategories = LocationsSettingsRecord<AuditCategoryUniqueKey>;
export type LocationsSettingsGridProp = LocationsSettingsRecord<number>;
export type CategoriesLabel = LocationsSettingsRecord<string>;

type LocationsSettings = {
  relatedCategories?: RelatedCategories;
  categoriesGridSpan?: LocationsSettingsGridProp;
  categoriesGridRowSpan?: LocationsSettingsGridProp;
  categoriesGridRowStart?: LocationsSettingsGridProp;
  categoriesGridColStart?: LocationsSettingsGridProp;
  categoriesLabel?: CategoriesLabel;
  columns: number;
  rows?: number;
  defaultGridSpan?: number;
};

type TypeOfDamagesProps = {
  category?: AuditCategoryOfStructure;
  damageValues?: AuditParamValue[];
  locationsSettings: LocationsSettings;
};

export const TypeOfDamages: FC<TypeOfDamagesProps & TestIdProps> = ({
  category,
  damageValues,
  locationsSettings,
  ...rest
}) => {
  const {
    relatedCategories,
    categoriesGridSpan,
    categoriesGridRowSpan,
    categoriesGridRowStart,
    categoriesGridColStart,
    categoriesLabel,
    columns,
    rows,
    defaultGridSpan,
  } = locationsSettings;
  const [highlightedDamageType, setHighlightedDamageType] = useState<string | null>(null);

  const isRelatedCategory = (categoryKey: AuditCategoryUniqueKey) => {
    if (!relatedCategories) {
      return false;
    }

    return Object.values(relatedCategories).findIndex((value) => value === categoryKey) >= 0;
  };

  const getCategoryByKey = (
    categoryKey?: AuditCategoryUniqueKey
  ): AuditCategoryOfStructure | undefined =>
    category?.childCategories?.find((childCategory) => childCategory.uniqueKey === categoryKey);

  return (
    <ConditionDamageLocation.Provider
      value={{
        highlightedDamageType,
        onDamageLegendClick: (value) => {
          setHighlightedDamageType(value);
          setTimeout(() => {
            setHighlightedDamageType(null);
          }, 500);
        },
      }}
    >
      <Heading size={3} data-testid={suffixTestId('typeOfDamages-title', rest)}>
        {i18n.t('entity.condition.labels.typeOfDamages')}
      </Heading>
      <Space vertical={4} />
      <Card variant="inlineWhite" data-testid={suffixTestId('typeOfDamages', rest)}>
        <Grid columns={4}>
          <GridItem>
            {damageValues
              ?.filter(
                (damage) =>
                  damage.value !== AuditCategoryUniqueKey.OTHER_DEFINE_IN_NOTE &&
                  damage.value !== AuditCategoryUniqueKey.OTHER_DEFINE_IN_THE_NOTE
              )
              .map((damageValue, index) => (
                <LegendOfDamage
                  key={`legend-damage-${damageValue.label}-${index}`}
                  value={damageValue}
                  data-testid={suffixTestId(`typeOfDamages-[${index}]`, rest)}
                />
              ))}
          </GridItem>
          <GridItem $colSpan={3}>
            <GridOverrideWrapper $rows={rows}>
              <Grid columns={columns} spacing={2}>
                {category?.childCategories?.map((category) => {
                  const categoryKey = category.uniqueKey as AuditCategoryUniqueKey;

                  if (isRelatedCategory(categoryKey)) {
                    return null;
                  }

                  return (
                    <GridItem
                      key={`type-of-damage-item-${category.id}`}
                      $colSpan={categoriesGridSpan?.[categoryKey] ?? defaultGridSpan ?? 1}
                      $rowSpan={categoriesGridRowSpan?.[categoryKey]}
                      $rowStart={categoriesGridRowStart?.[categoryKey]}
                      $colStart={categoriesGridColStart?.[categoryKey]}
                    >
                      <DamageLocation
                        category={category}
                        relatedCategory={getCategoryByKey(relatedCategories?.[categoryKey])}
                        label={categoriesLabel?.[categoryKey]}
                        data-testid={suffixTestId('typeOfDamages', rest)}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            </GridOverrideWrapper>
          </GridItem>
        </Grid>
      </Card>
    </ConditionDamageLocation.Provider>
  );
};

const GridOverrideWrapper = styled.div<{$rows?: number}>`
  ${({$rows}) =>
    isNotNil($rows) &&
    css`
      & > div {
        grid-template-rows: repeat(${$rows}, 1fr);
      }
    `}
`;

const GridItem = styled.div<{
  $colSpan?: number;
  $rowStart?: number;
  $rowSpan?: number;
  $colStart?: number;
}>`
  ${({$colSpan}) =>
    isNotNil($colSpan) &&
    css`
      grid-column-end: span ${$colSpan};
    `}
  ${({$rowSpan}) =>
    isNotNil($rowSpan) &&
    css`
      grid-row-end: span ${$rowSpan};
    `}
	${({$rowStart}) =>
    isNotNil($rowStart) &&
    css`
      grid-row-start: ${$rowStart};
    `}
	${({$colStart}) =>
    isNotNil($colStart) &&
    css`
      grid-column-start: ${$colStart};
    `}
`;
