import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray, noop} from 'ramda-adjunct';

import {
  useDeleteEmployeeProfessionMutation,
  usePostEmployeeProfessionSetAsActiveMutation,
  usePostEmployeeProfessionSetAsInactiveMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function EmployeeProfessionsOverview() {
  const navigate = useNavigate();

  const [postEmployeeProfessionSetAsInactive] = usePostEmployeeProfessionSetAsInactiveMutation();
  const [postEmployeeProfessionSetAsActive] = usePostEmployeeProfessionSetAsActiveMutation();
  const [deleteEmployeeProfession] = useDeleteEmployeeProfessionMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.employeeProfessionsDetail, {params: {id}}))
      )
      .with('setAsInactive', () =>
        postEmployeeProfessionSetAsInactive({professionId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsActive', () =>
        postEmployeeProfessionSetAsActive({professionId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteEmployeeProfession({id}).unwrap().then(refreshData).catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.employeeProfessions.labels.title'),
        actions: [
          {
            type: 'button',
            onClick: () => navigate(settingsRoutes.employeeProfessionsCreate),
            title: i18n.t('entity.employeeProfessions.labels.newProfession'),
          },
        ],
      }}
      data-testid={testIds.settings.employeeProfessions('settingsTemplate')}
    >
      <DataGrid
        gridCode="employee-profession"
        actionCallback={actionCallback}
        data-testid={testIds.settings.employeeProfessions('employee-profession')}
      />
    </SettingsTemplate>
  );
}
