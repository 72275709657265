import {useLocale} from 'platform/locale';

import {FC} from 'react';

import {testIds} from '@dms/routes';
import {SimpleTable, ExpandType} from '@dms/teas';

import {expandTableColumns} from '../const';
import {Container} from '../styles';
import {TExtraTemplateFields, TemplateRow} from '../types';
import {getCustomTemplateData} from '../utils';

export const DocumentExpandTable: FC<ExpandType<TExtraTemplateFields>> = ({row}) => {
  const {localeConfig, language} = useLocale();
  const data = getCustomTemplateData(row, localeConfig, language);

  return (
    <Container data-testid={testIds.settings.documentTemplates('customTemplate')}>
      <SimpleTable<TemplateRow>
        className="custom-template-table"
        rowProps={({warning}) => ({className: warning ? 'warning' : ''})}
        showCellDivider
        data-testid={testIds.settings.documentTemplates(`row-${row.code}`)}
        rows={[{data}]}
        columns={expandTableColumns}
      />
    </Container>
  );
};
