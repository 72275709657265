import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useParams} from 'react-router-dom';

import {
  GetBackgroundRemovalResponseBody,
  useCreateBackgroundRemovalMutation,
  useUpdateBackgroundRemovalV2Mutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {
  handleApiError,
  ORIGINAL_BACKGROUND_REMOVAL_IDS,
  MERCEDES_BACKGROUND_REMOVAL_IDS,
  MERCEDES_BACKGROUND_ZIZAK_REMOVAL_IDS,
  MERCEDES_ALL_BACKGROUND_IDS,
} from '@dms/shared';

import {useNavigate, yupString} from 'shared';

import {BackgroundRemovalFormValues} from '../types/BackgroundRemovalFormValues';
import {BackgroundSelector} from './BackgroundSelector';

type BackgroundRemovalFormType = {
  settings?: GetBackgroundRemovalResponseBody;
};

export function BackgroundRemovalForm(props: BackgroundRemovalFormType) {
  const {id} = useParams();
  const navigate = useNavigate();

  const backgroundIds = [
    ...ORIGINAL_BACKGROUND_REMOVAL_IDS,
    ...(isFeatureEnabled(featureFlags.MERCEDES_BACKGROUND_PHOTOS)
      ? MERCEDES_BACKGROUND_REMOVAL_IDS
      : []),
    ...(isFeatureEnabled(featureFlags.MERCEDES_BACKGROUND_ZIZAK)
      ? MERCEDES_BACKGROUND_ZIZAK_REMOVAL_IDS
      : []),
  ];

  const [createConfiguration] = useCreateBackgroundRemovalMutation();
  const [updateConfiguration] = useUpdateBackgroundRemovalV2Mutation();

  const onSubmit: FormSubmitHandler<BackgroundRemovalFormValues> = async (values) => {
    const args = {
      name: values.name,
      backgroundId: values.backgroundId,
      registrationPlateRemovalEnabled: values.registrationPlateRemovalEnabled,
      windowTintingEnabled: false, // See T20-63137
    };
    if (id) {
      await updateConfiguration({settingId: id, updateBackgroundRemovalRequestBody: args})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          navigate(settingsRoutes.backgroundRemoval);
        })
        .catch(handleApiError);
    } else {
      await createConfiguration({createBackgroundRemovalRequestBody: args})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          navigate(settingsRoutes.backgroundRemoval);
        })
        .catch(handleApiError);
    }
  };

  return (
    <Form<BackgroundRemovalFormValues>
      onSubmit={onSubmit}
      schema={schema}
      defaultValues={{
        name: props.settings?.name ?? '',
        backgroundId: props.settings?.backgroundId ?? '49716',
        backgroundReplacementEnabled: props.settings?.backgroundReplacementEnabled ?? true,
        registrationPlateRemovalEnabled: props.settings?.registrationPlateRemovalEnabled ?? false,
        windowTintingEnabled: props.settings?.windowTintingEnabled ?? false,
      }}
    >
      {(control, formApi) => {
        const currentBgId = formApi.watch('backgroundId');
        const isMercedesBg = MERCEDES_ALL_BACKGROUND_IDS.includes(currentBgId);

        return (
          <VStack spacing={4}>
            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.configurationName')}
              control={control}
              name="name"
              type="text"
              isRequired
              data-testid="backgroundRemoval-configurationName"
            />

            <Separator spacing={2} />

            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.labels.replaceBackground')}
              control={control}
              name="backgroundReplacementEnabled"
              type="switch"
              isDisabled
              data-testid="backgroundRemoval-backgroundReplacementEnabled"
            />
            <Box paddingLeft={12}>
              <VStack spacing={4}>
                <Text size="small" color="tertiary">
                  {i18n.t('page.salesSettings.backgroundRemoval.description.replaceBackground')}
                </Text>
                <BackgroundSelector
                  name="backgroundId"
                  options={backgroundIds}
                  control={control}
                  formApi={formApi}
                  externalOnClick={(selectedBgId) => {
                    if (MERCEDES_ALL_BACKGROUND_IDS.includes(selectedBgId)) {
                      formApi.setValue('registrationPlateRemovalEnabled', false);
                    }
                  }}
                />
              </VStack>
            </Box>

            <Separator spacing={2} />

            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.labels.replacePlate')}
              control={control}
              name="registrationPlateRemovalEnabled"
              type="switch"
              data-testid="backgroundRemoval-registrationPlateRemovalEnabled"
              isDisabled={isMercedesBg}
            />
            <Box paddingLeft={12}>
              <VStack spacing={4}>
                <Text size="small" color="tertiary">
                  {i18n.t('page.salesSettings.backgroundRemoval.description.replacePlate')}
                </Text>
              </VStack>
            </Box>

            <Separator spacing={2} />

            {/* Hidden option - see T20-63137
            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.labels.tintWindows')}
              control={control}
              name="windowTintingEnabled"
              type="switch"
              isDisabled={!props.settings?.windowTintingEnabled}
              data-testid="backgroundRemoval-windowTintingEnabled"
            />
            <Box paddingLeft={12}>
              <Text size="small" color="tertiary">
                {i18n.t('page.salesSettings.backgroundRemoval.description.tintWindows')}
              </Text>
            </Box>

            <Separator spacing={2} />
            */}

            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discardChanges')}
                variant="secondary"
                onClick={() => navigate(settingsRoutes.backgroundRemoval)}
                data-testid="backgroundRemoval-discardChangesBtn"
              />
              <FormButton
                title={i18n.t('general.actions.saveAndClose')}
                control={control}
                type="submit"
                data-testid="backgroundRemoval-submitBtn"
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const schema = object({
  backgroundReplacementEnabled: boolean().defined().isTrue(),
  backgroundId: yupString,
  registrationPlateRemovalEnabled: boolean().defined(),
  //windowTintingEnabled: boolean().defined(),
  name: yupString.required(),
});
