import {isString} from 'ramda-adjunct';

import {
  UpdateWatermarkDetailRequestBody,
  UpdateWatermarkRequestBody,
  WatermarkPositionEnum,
} from '@dms/api';

import {WatermarkFormType, WatermarkFormTypeOld} from '../types/watermarkForm';

export const getRequestData = (data: WatermarkFormType): UpdateWatermarkDetailRequestBody => ({
  name: data.name,
  size: data.size,
  position: data.position as WatermarkPositionEnum,
  fitToImage: data.fitToImage,
  addOnlyForFirstPhoto: data.addOnlyForFirstPhoto === 'true',
  fileId: (isString(data.fileId) ? data.fileId : data.fileId?.id) ?? null,
});

// TODO - delete component after featureFlag "settings_watermarks" is removed
export const getRequestOldData = (data: WatermarkFormTypeOld): UpdateWatermarkRequestBody => ({
  size: data.size,
  position: data.position as WatermarkPositionEnum,
  fitToImage: data.fitToImage.includes('fitToImage'),
  addOnlyForFirstPhoto: data.addOnlyForFirstPhoto === 'true',
  fileId: data.fileId?.id ?? null,
});
