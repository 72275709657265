import {testIds} from '@dms/routes';
import {Main} from '@dms/shared';

import {VehicleCustomersCard} from './components/VehicleCustomersCard';

export function Customers() {
  return (
    <Main data-testid={testIds.vehicles.customers('page')}>
      <VehicleCustomersCard />
    </Main>
  );
}
