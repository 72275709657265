import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head, isNil, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteBusinessCaseProformaInvoiceSettingMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {Nullish, composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function ProformaCreationOverview() {
  const navigate = useNavigate();
  const handleCreate = () => navigate(settingsRoutes.ProformaCreationCreate);
  const handleEdit = (id: string) =>
    navigate(composePath(settingsRoutes.ProformaCreationEdit, {params: {id}}));

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [deleteProformaCreation] = useDeleteBusinessCaseProformaInvoiceSettingMutation();

  const handleDelete = (id: string, branchName: string | Nullish) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteProformaCreation({id})
          .unwrap()
          .then(() => showNotification.success())
          .then(refreshDataGrid)
          .catch(handleApiError),
      text: i18n.t('entity.accounting.labels.deleteDefinitionDeleteText', {
        name: branchName,
      }),
    });

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    const branchName = path<string>(['branch', 'value', 'label'], rowData);

    if (isNil(id)) {
      showNotification.error();
      return;
    }
    match(actionKey)
      .with('EDIT', () => handleEdit(id))
      .with('DELETE', () => handleDelete(id, branchName))
      .otherwise(() => handleEdit(id));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accountingSettings.labels.proformaCreation'),
        actions: [
          {
            type: 'button',
            onClick: handleCreate,
            title: i18n.t('page.accounting.actions.addDefinition'),
          },
        ],
      }}
      description={i18n.t('page.accountingSettings.labels.proformaCreationDescription')}
      data-testid={testIds.settings.ProformaCreationOverview('settings-template')}
    >
      <DataGrid
        gridCode="checkout-proforma-invoice-settings"
        actionCallback={actionCallback}
        ref={dataGridRef}
        data-testid={testIds.settings.ProformaCreationOverview('datagrid')}
      />
    </SettingsTemplate>
  );
}
