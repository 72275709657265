import {Card, FormControl, FormField, Radio, Separator, Switch} from 'platform/components';
import {Clickable, Grid, Heading, HStack, Show, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DiscountType} from '../../../../../types/discountType';
import {MaterialDiscountOption} from '../types/materialDiscountOption';

const MATERIAL_OPTIONS = [
  {
    label: i18n.t('entity.order.labels.discountOnSalePrice'),
    value: MaterialDiscountOption.DiscountOnSalePrice,
  },
  {
    label: i18n.t('entity.order.labels.marginToPurchasePrice'),
    value: MaterialDiscountOption.MarginToPurchasePrice,
  },
];

interface DiscountOnMaterialCardProps extends RequiredTestIdProps {
  control: FormControl<DiscountType>;
  selectedOption: MaterialDiscountOption | null;
  onOptionChange: (value: MaterialDiscountOption | null) => void;
  isExpanded: boolean;
  onExpandChange: () => void;
  isReadOnly?: boolean;
}

export function DiscountOnMaterialCard(props: DiscountOnMaterialCardProps) {
  return (
    <Card variant="inlineGrey">
      <Clickable onClick={props.onExpandChange} isDisabled={props.isReadOnly}>
        <HStack align="center" spacing={3}>
          <Switch
            value={props.isExpanded}
            onChange={props.onExpandChange}
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('expand-switch', props)}
          />
          <Heading size={4}>{i18n.t('entity.order.labels.discountOnMaterial')}</Heading>
        </HStack>
      </Clickable>
      <Show when={props.isExpanded}>
        <Separator />
        <VStack spacing={4}>
          <Radio
            options={MATERIAL_OPTIONS}
            value={props.selectedOption}
            onChange={(value) => props.onOptionChange(value as MaterialDiscountOption)}
            isDisabled={props.isReadOnly}
            data-testid={suffixTestId('material-options', props)}
          />
          <Grid columns={4} spacing={4}>
            <Show when={props.selectedOption === MaterialDiscountOption.DiscountOnSalePrice}>
              <FormField
                control={props.control}
                name="material.percentDiscount.discountOnSalePriceRate"
                type="number"
                label={i18n.t('entity.order.labels.percentage')}
                suffix="%"
                decimalPlaces={2}
                isDisabled={props.isReadOnly}
                data-testid={suffixTestId('discount-on-sale-price', props)}
              />
            </Show>
            <Show when={props.selectedOption === MaterialDiscountOption.MarginToPurchasePrice}>
              <FormField
                control={props.control}
                name="material.percentDiscount.discountMarginToPurchasePriceRate"
                type="number"
                label={i18n.t('entity.order.labels.percentage')}
                suffix="%"
                decimalPlaces={2}
                isDisabled={props.isReadOnly}
                data-testid={suffixTestId('margin-to-purchase-price', props)}
              />
            </Show>
          </Grid>
        </VStack>
      </Show>
    </Card>
  );
}
