import {Button, openDeleteDialog, showNotification} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteSalePresetSettingsMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function SalePreset() {
  const navigate = useNavigate();
  const [deleteSalePresetSettings] = useDeleteSalePresetSettingsMutation();

  const handleActionCallback: ActionCallback = useCallback(({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('edit', () => navigate(composePath(settingsRoutes.salePresetDetail, {params: {id}})))
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteSalePresetSettings({salePresetId: id})
              .unwrap()
              .then(refreshData)
              .then(() => showNotification.success())
              .catch(handleApiError),
          text: i18n.t('page.salesSettings.salePreset.deleteSettings'),
        })
      )
      .otherwise(() => showNotification.warning('unknown action key'));
  }, []);

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettings.labels.salePreset'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      data-testid="settings-salePreset"
      description={i18n.t('page.salesSettings.salePreset.description')}
    >
      <HStack align="center" justify="space-between">
        <Heading size={4}>{i18n.t('page.salesSettings.salePreset.configurationList')}</Heading>
        <Button
          leftIcon="content/add"
          title={i18n.t('page.salesSettings.salePreset.addConfiguration')}
          variant="link"
          onClick={() => navigate(settingsRoutes.salePresetCreate)}
          data-testid="settings-salePreset-addConfiguration-btn"
        />
      </HStack>
      <DataGrid
        gridCode="sale-preset"
        actionCallback={handleActionCallback}
        data-testid="settings-salePreset-datagrid"
      />
    </SettingsTemplate>
  );
}
