import {useSelector} from 'react-redux';

import {find, map, pipe, propOr} from 'ramda';

import {Rate, selectTenant, useGetVatRatesQuery, VatRate} from '@dms/api';

export type VatRateOption = {
  value: string;
  label: string;
  fieldLabel: string;
  type: string;
  name: string;
  rate: string;
  activeFrom: string;
};

export function useVatRatesOptions(): [VatRateOption[]] {
  const {data: selectedTenant} = useSelector(selectTenant);
  const {data: vatRates} = useGetVatRatesQuery();

  return [
    pipe(
      find((item: VatRate) => item.code === selectedTenant?.country),
      propOr([], 'rates'),
      map((rate: Rate) => ({
        ...rate,
        value: rate.type,
        label: `${rate.rate} % ${rate.name}`,
        fieldLabel: `${rate.rate} %`,
      }))
    )(vatRates ?? []),
  ];
}
