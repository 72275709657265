import {isNil, isNotNil, reject} from 'ramda';

import {BusinessCaseResponseBody, SaleVehicleResponseBody} from '@dms/api';

import {Nullish} from 'shared';

import {EntityHeaderFlag} from '../components/EntityHeader/EntityHeader';
import {getBusinessCaseStateFlag} from '../utils/getBusinessCaseStateFlag';
import {getBusinessCaseTradeTypeFlag} from '../utils/getBusinessCaseTradeTypeFlag';
import {getBusinessCaseTypeFlag} from '../utils/getBusinessCaseTypeFlag';

const stockTypeFlag = (
  businessCase: BusinessCaseResponseBody | undefined,
  vehicle: SaleVehicleResponseBody | Nullish
) =>
  businessCase?.businessCaseState !== 'CONCEPT' && isNotNil(vehicle)
    ? getBusinessCaseTradeTypeFlag(
        vehicle?.type === 'BROKERAGE' || businessCase?.brokerageBusinessCaseId
          ? 'BROKERAGE'
          : 'IN_STOCK'
      )
    : undefined;

export function useGetFlags(
  businessCase: BusinessCaseResponseBody | undefined,
  vehicle: SaleVehicleResponseBody | Nullish
): [EntityHeaderFlag[]] {
  const typeFlag = getBusinessCaseTypeFlag(businessCase?.businessCaseType);
  const stateFlag = getBusinessCaseStateFlag(businessCase?.businessCaseState);
  const stockFlag = stockTypeFlag(businessCase, vehicle);

  const flags = reject(isNil, [typeFlag, stockFlag, stateFlag]);

  return [flags];
}
