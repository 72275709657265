import {FormControl} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import {SingleLocationFormValue} from '../types/SingleLocationFormValue';
import {SimpleVectorRow} from './SimpleVectorRow';

interface SimpleVectorsArrayProps {
  control: FormControl<SingleLocationFormValue>;
  formApi: UseFormReturn<SingleLocationFormValue>;
  isSelectVisible: boolean;
}

export function SimpleVectorsArray(props: SimpleVectorsArrayProps) {
  const fieldsArray = useFieldArray({
    control: props.control,
    name: 'storageLocation',
  });

  return (
    <VStack spacing={4}>
      {fieldsArray.fields.map((item, index) => (
        <SimpleVectorRow
          key={item.id}
          index={index}
          control={props.control}
          formApi={props.formApi}
          isSelectVisible={props.isSelectVisible}
        />
      ))}
    </VStack>
  );
}
