import {ReactNode} from 'react';

import {useRefreshDataGrid} from 'features/datagrid';

import {VehicleWarehouseContext} from './VehicleWarehouseContext';

type VehicleWarehouseProviderProps = {
  children: ReactNode;
};

export const VehicleWarehouseProvider = (props: VehicleWarehouseProviderProps) => {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  return (
    <VehicleWarehouseContext.Provider value={{dataGridRef, refreshDataGrid}}>
      {props.children}
    </VehicleWarehouseContext.Provider>
  );
};
