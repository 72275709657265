import {isNotNil} from 'ramda';

import {
  getAuditStructure,
  LoadAuditDataResponseItemBody,
  selectCarAudit,
  useThunkDispatch,
} from '@dms/teas';

import {Nullish} from 'shared';

export function useFetchAuditStructure() {
  const dispatch = useThunkDispatch();

  const {loading} = getAuditStructure.useThunkState(selectCarAudit);

  const fetchAuditStructure = (auditStructure: LoadAuditDataResponseItemBody | Nullish) => {
    if (isNotNil(auditStructure?.controlStructureId) && !loading) {
      dispatch(getAuditStructure.action({auditStructureId: auditStructure?.controlStructureId}));
    }
  };

  return {fetchAuditStructure};
}
