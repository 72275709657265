import {DataStatus} from 'platform/components';
import {Link, Show, VStack} from 'platform/foundation';
import {Lightbox, LightboxImage, useLightbox} from 'platform/lightbox';

import {defaultTo, filter, find, forEach, isNotNil, path, pipe, prop, sortBy} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetPromoPhotoForVehicleQuery,
  useGetVehicleQuery,
  VehicleAlbum,
  VehicleAlbums,
  VehiclePhoto,
  VehiclePromoPhotoResponseBody,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {filterSortImageByWeight, ImagesCardView, VehiclePhotosRoutes} from '@dms/teas';

import {composePath, useRequiredParams} from 'shared';

export function PublishedImages() {
  const {id} = useRequiredParams();

  const {data: vehicle, isLoading} = useGetVehicleQuery({
    vehicleId: id,
  });

  const [lightboxControls, {onOpen}] = useLightbox('published');

  const {data: promoPhotos = [], isLoading: isPromoPhotosLoading} = useGetPromoPhotoForVehicleQuery(
    {
      vehicleId: vehicle?.id ?? '',
    }
  );

  const hasPromoPhotos = (albumId?: string) =>
    pipe(
      defaultTo<VehicleAlbum[]>([]),
      find((album: VehicleAlbum) => album.id === albumId),
      (album) => album?.name === VehicleAlbums.PHOTOS_CAR_SALES
    )(vehicle?.albums);

  const getVehicleAndPromoPhotosSorted = (photos: VehiclePhoto[], albumId: string | undefined) => {
    const vehiclePhotos = filterSortImageByWeight(photos ?? [], albumId);

    if (hasPromoPhotos(albumId)) {
      const sortedPromoPhotos = sortBy(prop('position'), promoPhotos);
      forEach<VehiclePromoPhotoResponseBody>((promoPhoto) => {
        const taggedPromoPhoto = {
          ...promoPhoto,
          isPromoPhoto: true,
        };
        vehiclePhotos.splice(promoPhoto.position - 1, 0, taggedPromoPhoto);
      }, sortedPromoPhotos);
    }

    return vehiclePhotos;
  };

  const images = pipe(
    filter((album: VehicleAlbum) => album.name === VehicleAlbums.PHOTOS_CAR_SALES),
    defaultTo<VehicleAlbum[]>([]),
    path<string>(['0', 'id']),
    (id) => getVehicleAndPromoPhotosSorted(vehicle?.photos ?? [], id)
  )(defaultTo<VehicleAlbum[]>([])(vehicle?.albums));

  const lightboxImages = images.reduce((lightboxImages: LightboxImage[], image) => {
    const imageUrl = image.url || image.originalUri || image.resizeUrl;
    if (isNotNil(imageUrl)) {
      return [
        ...lightboxImages,
        {
          id: image.id,
          url: imageUrl.replace('resize', 'get'),
          resizeUrl: image.resizeUrl,
          title: image.name,
          fileName: image.name,
        },
      ];
    }
    return lightboxImages;
  }, []);

  const handleOnImageClick = (id: string) => {
    const detailIndex = lightboxImages.findIndex((image) => image.id === id);
    onOpen(detailIndex ?? 0);
  };

  const editPhotos = isNotNil(vehicle?.id) ? (
    <Link
      title={i18n.t('entity.photo.actions.editImages')}
      leftIcon="image/edit"
      href={composePath(vehiclesRoutes.photos, {
        params: {
          id: vehicle?.id,
          tab: VehiclePhotosRoutes.vehiclePhotos,
        },
      })}
      size="small"
      data-testid="link-advertisement-generalData-edit-images"
    />
  ) : null;

  return (
    <VStack spacing={4}>
      <DataStatus isLoading={isLoading || isPromoPhotosLoading} isEmpty={isNilOrEmpty(images)}>
        <ImagesCardView onImageClick={handleOnImageClick} images={images} />
      </DataStatus>
      {editPhotos}
      <Show when={isNotNilOrEmpty(lightboxImages)}>
        <Lightbox
          data-testid={testIds.vehicles.publishing('advertisement')}
          controls={lightboxControls}
          images={lightboxImages}
        />
      </Show>
    </VStack>
  );
}
