import {isNil, reject} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getRealProductionDate = (manufacturedOn: string | Nullish) => {
  if (isNil(manufacturedOn)) {
    return null;
  }

  const [year, month] = manufacturedOn.split('-');

  const monthLabel = monthOptions.find((item) => item.id === parseFloat(month).toString())?.label;

  return reject(isNil, [monthLabel, year]).join(' ');
};

const monthOptions = [
  {
    id: '1',
    label: i18n.t('general.months.January'),
  },
  {
    id: '2',
    label: i18n.t('general.months.February'),
  },
  {
    id: '3',
    label: i18n.t('general.months.March'),
  },
  {
    id: '4',
    label: i18n.t('general.months.April'),
  },
  {
    id: '5',
    label: i18n.t('general.months.May'),
  },
  {
    id: '6',
    label: i18n.t('general.months.June'),
  },
  {
    id: '7',
    label: i18n.t('general.months.July'),
  },
  {
    id: '8',
    label: i18n.t('general.months.August'),
  },
  {
    id: '9',
    label: i18n.t('general.months.September'),
  },
  {
    id: '10',
    label: i18n.t('general.months.October'),
  },
  {
    id: '11',
    label: i18n.t('general.months.November'),
  },
  {
    id: '12',
    label: i18n.t('general.months.December'),
  },
] as const;
