import {
  Button,
  ButtonGroup,
  Card,
  DynamicUi,
  DynamicUiRefProps,
  RendererProps,
} from 'platform/components';
import {Box, HStack, Scroll, Space, VStack} from 'platform/foundation';

import {createRef, useState} from 'react';

import i18n from '@dms/i18n';

import {ErrorBoundary} from './components/ErrorBoundary';
import {JsonInput} from './components/JsonInput';

export function DynamicUiBasicForm() {
  const [jsonValue, setJsonValue] = useState<RendererProps | null>(null);
  const [formOutput, setFormOutput] = useState<Record<string, unknown> | null>(null);
  const [formValues, setFormValues] = useState<Record<string, unknown>>({});

  const [isOutputExpanded, setIsOutputExpanded] = useState(false);

  const dynamicUIRef = createRef<DynamicUiRefProps>();
  const [key, setKey] = useState(0);

  const updateComponent = () => setKey((prevKey) => prevKey + 1);
  const handleSubmit = (formValues: Record<string, unknown>) => {
    setIsOutputExpanded(true);
    setFormOutput(formValues);
  };

  return (
    <VStack justify="stretch" align="center" grow={1} height="100%" maxWidth="100%">
      <Box padding={4} height="100%" maxWidth="100%">
        <Box maxWidth="100%" width={459} height="100%" boxShadow="elevation_2">
          <HStack height="100%">
            <JsonInput setJsonValue={setJsonValue} />

            <VStack width="50%" justify="space-between" maxHeight="100%">
              <ErrorBoundary key={JSON.stringify(jsonValue)}>
                <Box padding={10} flexGrow={1} overflow="hidden" position="relative">
                  <Scroll auto maxHeight="100%">
                    {jsonValue && (
                      <DynamicUi
                        schema={jsonValue}
                        ref={dynamicUIRef}
                        onSubmit={handleSubmit}
                        onChange={setFormValues}
                        key={key}
                      />
                    )}

                    <Space vertical={4} />
                    <ButtonGroup align="right">
                      <Button
                        onClick={updateComponent}
                        title={i18n.t('page.datagrid.labels.reset')}
                        variant="secondary"
                      />
                      <Button
                        type="submit"
                        onClick={() => dynamicUIRef.current?.submit()}
                        title={i18n.t('general.actions.saveChanges')}
                      />
                    </ButtonGroup>
                  </Scroll>
                </Box>

                <Box>
                  <Card
                    isExpandable
                    variant="inlineGrey"
                    isClosedByDefault
                    title="Current form values"
                  >
                    <pre>{JSON.stringify(formValues, null, 2)}</pre>
                  </Card>
                  <Card
                    isExpandable
                    isExpanded={isOutputExpanded}
                    onExpandButtonClick={() => setIsOutputExpanded((val) => !val)}
                    variant="inlineGrey"
                    isClosedByDefault
                    title="Submit values"
                  >
                    <pre>{JSON.stringify(formOutput, null, 2)}</pre>
                  </Card>
                </Box>
              </ErrorBoundary>
            </VStack>
          </HStack>
        </Box>
      </Box>
    </VStack>
  );
}
