import {FormControl, FormField, Tooltip} from 'platform/components';
import {Grid, Show, Text, VStack} from 'platform/foundation';

import {useWatch} from 'react-hook-form';

import {GetDirectSaleVariantsResponse} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {getOptionsFromDirectSaleVariants} from '../utils/getOptionsFromDirectSaleVariants';

interface AllowDirectSalesProps extends TestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  directSaleVariants: GetDirectSaleVariantsResponse | Nullish;
}

export function AllowDirectSales(props: AllowDirectSalesProps) {
  const allowDirectSales = useWatch({name: 'allowDirectSaleIssue'});

  return (
    <>
      <FormField
        name="allowDirectSaleIssue"
        type="switch"
        label={i18n.t('entity.warehouse.labels.allowDirectSales')}
        control={props.control}
        data-testid={props['data-testid']}
      />
      <Show when={allowDirectSales}>
        <VStack spacing={2}>
          <Grid columns={2}>
            <FormField
              name="allowedDirectSaleVariants"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.allowedDirectSalesVariants')}
              options={getOptionsFromDirectSaleVariants(props.directSaleVariants)}
              control={props.control}
              isNotClearable
              data-testid={suffixTestId('allowedDirectSaleVariants', props)}
            />
          </Grid>
          <Tooltip>
            <Text size="xSmall" color="tertiary" noWrap>
              {i18n.t('entity.warehouse.labels.allowedDirectSaleVariantsText')}
            </Text>
          </Tooltip>
        </VStack>
      </Show>
    </>
  );
}
