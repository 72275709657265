import {
  Card,
  DropdownActionMenuItem,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {head, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {DeleteMessageTemplateApiArg, useDeleteTemplateMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, usePermissions} from '@dms/shared';

import {buildArray, composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export const MessageTemplates = () => {
  const navigate = useNavigate();
  const [deleteTemplateMutation] = useDeleteTemplateMutation();

  const [canCreateMessageTemplate] = usePermissions({
    permissionKeys: ['createMessageTemplate'],
  });

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData, rowData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    const templateType = path<DeleteMessageTemplateApiArg['templateType']>(
      ['type', 'value'],
      rowData
    );
    if (!id || !templateType) {
      throw new Error('Invalid data for callback');
    }

    match(actionKey)
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteTemplateMutation({
              templateType,
              templateId: id,
            })
              .unwrap()
              .then(() => showNotification.success())
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('edit', 'detail', () =>
        navigate(
          composePath(settingsRoutes.messageTemplatesEdit, {params: {id, type: templateType}})
        )
      );
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.customFields('template')}
      header={{
        title: i18n.t('page.messageTemplates.labels.list'),
        subtitle: i18n.t('page.messageTemplates.labels.listDescription'),
      }}
    >
      <Card
        actions={
          canCreateMessageTemplate
            ? [
                {
                  type: 'dropdown-button',
                  variant: 'link',
                  menuItems: buildArray<DropdownActionMenuItem>()
                    .add({
                      onClick: () =>
                        navigate(
                          composePath(settingsRoutes.messageTemplatesNew, {
                            params: {
                              type: 'sms',
                            },
                          })
                        ),
                      label: i18n.t('page.messageTemplates.labels.sms'),
                    })
                    .add({
                      onClick: () =>
                        navigate(
                          composePath(settingsRoutes.messageTemplatesNew, {
                            params: {
                              type: 'email',
                            },
                          })
                        ),
                      label: i18n.t('page.messageTemplates.labels.email'),
                    }),
                  leftIcon: 'content/add',
                  title: i18n.t('page.messageTemplates.actions.addNewTemplate'),
                },
              ]
            : []
        }
        title={i18n.t('page.messageTemplates.labels.templateList')}
      >
        <DataGrid
          autoHeight
          gridCode="messaging-context-template"
          actionCallback={actionCallback}
          data-testid={testIds.settings.messageTemplates('dg')}
        />
      </Card>
    </SettingsTemplate>
  );
};
