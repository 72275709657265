import {Form} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {VinDecoderField} from '../../VinDecoderField/VinDecoderField';
import {MatchingVehicles} from './MatchingVehicles';

type VehicleFinderFormType = {
  licencePlate: string;
  vin: string;
};

interface VehicleMatchOrCreateProps extends TestIdProps {
  onVehicleSelect?: (vehicleId: string) => Promise<void> | void;
}

export function VehicleMatchOrCreate(props: VehicleMatchOrCreateProps) {
  return (
    <Form<VehicleFinderFormType> data-testid={suffixTestId('form', props)}>
      {(control, formApi) => (
        <VStack spacing={4}>
          <VinDecoderField<VehicleFinderFormType>
            control={control}
            formApi={formApi}
            registrationPlateField={{
              name: 'licencePlate',
              label: i18n.t('entity.vehicle.labels.licensePlate'),
            }}
            vinField={{name: 'vin', label: i18n.t('entity.vehicle.labels.vinCode')}}
            isStatic
            data-testid={suffixTestId('vinDecoder', props)}
          />
          <MatchingVehicles
            vin={formApi.watch('vin')}
            registrationPlateContains={formApi.watch('licencePlate')}
            onVehicleSelect={(vehicleId) => props.onVehicleSelect?.(vehicleId)}
            data-testid={suffixTestId('matchingVehicles', props)}
          />
        </VStack>
      )}
    </Form>
  );
}
