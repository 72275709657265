import {showNotification} from 'platform/components';
import {Box, Space, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {myProfileRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {DeprecatedProfileLayout} from '../../components/DeprecatedProfileLayout/DeprecatedProfileLayout';
import {MyProfileHeader} from '../../components/MyProfileTemplate/components/MyProfileHeader';

/**
 * @deprecated used only to support legacy myProfile, logic is the same, but return JSX and styles differ
 */
export function DeprecatedNotificationOverview() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      showNotification.error();
      return;
    }

    match(actionKey)
      .with('detail', () =>
        navigate(composePath(myProfileRoutes.notificationDetail, {params: {id}}))
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <DeprecatedProfileLayout>
      <VStack width="100%" spacing={6} maxHeight="100%">
        <MyProfileHeader
          title={i18n.t('page.settings.notifications.title')}
          breadcrumbs={[
            {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
            {label: i18n.t('page.settings.notifications.title'), isCurrentPage: true},
          ]}
        />

        <Box paddingHorizontal={6} width="100%">
          <Text color="secondary" size="small">
            {i18n.t('page.settings.notifications.subtitle')}
          </Text>

          <Space vertical={6} />

          <Box position="relative">
            <DataGrid
              gridCode="messaging-user-notification"
              actionCallback={actionCallback}
              autoHeight
              data-testid={testIds.myProfile.notifications('datagrid')}
            />
          </Box>
        </Box>
      </VStack>
    </DeprecatedProfileLayout>
  );
}
