import {isFeatureEnabled} from 'feature-flags';
import {Attributes, Card} from 'platform/components';

import {FC} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {FullState, FullVehicle} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {selectServiceBook, selectServiceBookStat} from '@dms/teas';

import {composePath} from 'shared';

type ModelSpecificationCardProps = {
  state: Partial<FullState>;
  vehicle?: Partial<FullVehicle>;
  canEditVehicle: boolean;
  isSaleVehicle?: boolean;
};

const ModelSpecificationCard: FC<ModelSpecificationCardProps> = ({
  vehicle,
  state,
  canEditVehicle,
  isSaleVehicle,
}) => {
  const navigate = useNavigate();

  const serviceBookType = useSelector(selectServiceBook(state?.serviceBookType, vehicle?.type));
  const serviceBookState = useSelector(
    selectServiceBookStat(state?.serviceBookState, vehicle?.type)
  );

  const handleOnClick = () => {
    if (!vehicle?.id) {
      return;
    }

    if (!isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return navigate(
        composePath(vehiclesRoutes.editServiceVehicle, {
          params: {id: vehicle.id || ''},
          queryParams: {tab: 'vehicle-data'},
        })
      );
    }

    return navigate(
      composePath(vehiclesRoutes.editDetailTab, {
        params: {id: vehicle.id || '', tab: 'vehicle-data'},
      })
    );
  };

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.serviceBook')}
      actions={
        canEditVehicle
          ? [
              {
                variant: 'link',
                type: 'button',
                leftIcon: 'image/edit',
                onClick: handleOnClick,
                'data-testid': testIds.vehicles.vehicleData('serviceBook-edit'),
                title: i18n.t('general.actions.edit'),
              },
            ]
          : undefined
      }
      data-testid={testIds.vehicles.vehicleData('serviceBook')}
    >
      <Attributes
        data-testid={testIds.vehicles.vehicleData('serviceBook-parameters')}
        rows={[
          {
            label: i18n.t('general.labels.type'),
            value: serviceBookType?.label,
            testId: 'type',
          },
          {
            label: i18n.t('entity.vehicle.labels.serviceBookStateLabel'),
            value: serviceBookState?.label,
            testId: 'stateLabel',
          },
        ]}
      />
    </Card>
  );
};

export const ServiceBookCard = ModelSpecificationCard;
