import {showNotification} from 'platform/components';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  CreateInvoiceDraftApiArg,
  PaymentInfoRequestBody,
  useCreateInvoiceDraftMutation,
  useGetTenantQuery,
  useInvoicingDocumentRecalculateMutation,
  useUpdateInvoiceDraftMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {accountingRoutes} from '@dms/routes';
import {handleApiError, useGetCurrentBranch} from '@dms/shared';

import {ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY, PERCENTAGE_SIGN} from '../../../constants';
import {AccountingDocumentFormValues, InvoiceItem} from '../../../types';

export const useSubmitDraft = () => {
  const navigate = useNavigate();
  const {data: tenant} = useGetTenantQuery();
  const {data: currentBranch} = useGetCurrentBranch();
  const {id: invoiceDraftId} = useParams();

  const [_, {data: summary}] = useInvoicingDocumentRecalculateMutation({
    fixedCacheKey: ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });

  const [createNewDraft] = useCreateInvoiceDraftMutation();
  const [updateDraft] = useUpdateInvoiceDraftMutation();
  const currency = tenant?.currency as string;

  const submitDraft = async (formData: AccountingDocumentFormValues<PaymentInfoRequestBody>) => {
    if (!currentBranch?.billingInformation) {
      return;
    }

    const items = formData.invoiceItems.map((item: InvoiceItem) => {
      const discount =
        summary?.items.find(
          (summaryItem) => summaryItem.itemId === item.itemId && summaryItem.type === 'discount'
        )?.discount ?? null;

      return {
        currency,
        description: item.description,
        isUnitPriceWithVat: item.isUnitPriceWithVat,
        itemId: item.itemId,
        pricePerUnit: item.pricePerUnit?.toString() || '0',
        quantity: item.unit === PERCENTAGE_SIGN ? '1' : item.quantity?.toString() || '0',
        type: item.type,
        unit: item.unit,
        vatType: item.vatType,
        discount: item.type === 'discount' ? discount : null,
        relatedItemId: item.relatedItemId,
      };
    });

    const bankName =
      currentBranch?.billingInformation?.bankAccounts.find(
        (item) => item.accountNumber === formData.paymentInfo.bankAccount
      )?.bankName ?? '';
    const submitBody: CreateInvoiceDraftApiArg = {
      invoiceDraftV2RequestBody: {
        billingInformationId: currentBranch.billingInformation.id,
        branchId: currentBranch.id,
        customer: formData.customer ?? undefined,
        currency: tenant?.currency,
        dueDate: formData.dueDate,
        dateOfTaxableSupply: formData.dateOfTaxableSupply,
        items,
        footerNote: formData.footerNote,
        issueDate: formData.issueDate,
        additionalCustomer: isNotNil(formData.additionalCustomer?.customer.id)
          ? {
              contractInformationId: formData.additionalCustomer.contractInformationId,
              customerId: formData.additionalCustomer.customer.id,
              type: formData.additionalCustomer.type,
            }
          : null,
        internalNote: formData.internalNote,
        paymentInfo: {
          iban: formData.paymentInfo.iban?.toString() ?? null,
          swift: formData.paymentInfo.swift?.toString() ?? null,
          constantSymbol: formData.paymentInfo?.constantSymbol?.toString() ?? null,
          specificSymbol: formData.paymentInfo?.specificSymbol?.toString() ?? null,
          variableSymbol: formData.paymentInfo?.variableSymbol?.toString() ?? null,
          bankAccount: formData.paymentInfo?.bankAccount || null,
          paymentMethod: formData.paymentInfo?.paymentMethod || 'BANK_TRANSFER',
          bankName,
        },
        exchangeRateRatio: formData.isExchangeRateRatioEnabled
          ? {
              amount: parseFloat(formData.exchangeRateRatio?.amount ?? ''),
              currency: formData.exchangeRateRatio?.currency ?? '',
              ratio: formData.exchangeRateRatio?.ratio?.toString() ?? '',
            }
          : null,
      },
    };

    const submitAction = isNil(invoiceDraftId)
      ? createNewDraft(submitBody)
      : updateDraft({
          id: invoiceDraftId,
          invoiceDraftV2RequestBody: submitBody.invoiceDraftV2RequestBody,
        });

    await submitAction
      .unwrap()
      .then(() => navigate(accountingRoutes.invoiceOverview))
      .then(() => showNotification.success(i18n.t('entity.invoice.notifications.draftSaved')))
      .catch(handleApiError);
  };

  return [submitDraft] as const;
};
