import {Box, HStack, Show} from 'platform/foundation';

import {MenuItemProps} from '@dms/api';
import {DevSettings} from '@dms/dev-settings';
import {featureFlags} from '@dms/feature-flags';
import {usePermissions} from '@dms/shared';
import {TaskCenterPopup} from '@dms/task-manager';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {NotificationsPopup} from '../../../NotificationsPopup/NotificationsPopup';
import {HeaderContent} from './HeaderContent';
import {MyProfileAndSettings} from './MyProfileAndSettings';
import {ReleasePopup} from './ReleasePopup';
import {Settings} from './Settings';

interface TopbarProps extends RequiredTestIdProps {
  activeModule?: MenuItemProps;
  settingsModule?: MenuItemProps;
}

export function Topbar(props: TopbarProps) {
  const [canReadTasks] = usePermissions({
    permissionKeys: ['readTask'],
  });
  return (
    <Box paddingHorizontal={4} height="100%" paddingVertical={2}>
      <HStack align="center" justify="space-between" height="100%">
        <HeaderContent
          title={props.activeModule?.layoutProps?.title}
          data-testid={suffixTestId('header-content', props)}
        />
        <HStack spacing={2} align="center">
          <Show whenFeatureEnabled={featureFlags.CORE_TASK_MANAGEMENT} when={canReadTasks}>
            <TaskCenterPopup data-testid="layout-header" />
          </Show>
          <Show whenFeatureEnabled={featureFlags.NOTIFICATION_CENTER}>
            <NotificationsPopup data-testid="layout-header" />
          </Show>
          <ReleasePopup data-testid="layout-header" />
          <Settings settingsModule={props.settingsModule} data-testid="layout-header" />
          <DevSettings data-testid="layout-header" />
          <MyProfileAndSettings data-testid="layout-header" />
        </HStack>
      </HStack>
    </Box>
  );
}
