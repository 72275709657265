import {FormControl, FormField, Label, TextInput} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {head, pipe, uniq} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  VehicleModel,
  useGetAllApiEnumsQuery,
  useGetMakeModelWithMakeQuery,
  useLazyGetMakeModelWithMakeQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import {BasicInformationFormType} from '../types/basicInformationFormType';

const VEHICLE_TYPE_PROPERTY = 'vehicle_type';

interface VehicleFieldsProps extends TestIdProps {
  control: FormControl<BasicInformationFormType>;
  formApi: UseFormReturn<BasicInformationFormType>;
}

export function VehicleFields(props: VehicleFieldsProps) {
  const vehicleType = props.formApi.watch('vehicleType');
  const vehicleMakes = props.formApi.watch('vehicleMakes');

  const [isModelsLoading, setModelLoading] = useState(false);
  const [models, setModels] = useState<VehicleModel[] | undefined>(undefined);

  const [getApiMakeModelWithMake] = useLazyGetMakeModelWithMakeQuery();
  const {data: makes, isLoading: isMakesLoading} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicleType ?? '',
      lang: [i18n.language],
    },
    {
      skip: !vehicleType,
    }
  );
  const {data: vehicleTypes} = useGetAllApiEnumsQuery(
    {lang: [i18n.language]},
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.find((item) => item.property_name === VEHICLE_TYPE_PROPERTY)?.keys,
      }),
    }
  );

  useEffect(() => {
    if (isNilOrEmpty(vehicleMakes)) {
      setModels(undefined);
      return;
    }

    setModelLoading(true);

    Promise.all(
      vehicleMakes?.map((make) =>
        getApiMakeModelWithMake({
          vehicleType: vehicleType ?? '',
          lang: [i18n.language],
          make,
        }).unwrap()
      ) ?? []
    ).then((data) => {
      const modelsArray = data.flatMap((response) => response[0].models ?? []);

      setModels(modelsArray);
      setModelLoading(false);
    });
  }, [vehicleMakes, vehicleType, getApiMakeModelWithMake]);

  const isMakeSelectEnabled = isNotNil(vehicleType);
  const isModelSelectEnabled = isNotNil(vehicleMakes);

  return (
    <VStack spacing={4}>
      <FormField
        control={props.control}
        type="chips"
        name="vehicleType"
        options={
          vehicleTypes?.map((type) => ({
            label: head(type.labels)?.label ?? '',
            value: type.const_key,
          })) ?? []
        }
        label={i18n.t('entity.vehicle.labels.vehicleType')}
        onChange={() => props.formApi.setValue('vehicleMakes', undefined)}
        data-testid={suffixTestId('vehicleType', props)}
      />
      <Show when={isMakeSelectEnabled}>
        <FormField
          data-testid={suffixTestId('vehicleMakes', props)}
          control={props.control}
          type="multiChoice"
          name="vehicleMakes"
          label={i18n.t('entity.vehicle.labels.make')}
          options={makes?.map((make) => ({
            label: make.default_label,
            value: make.make,
          }))}
          isLoading={isMakesLoading}
          onChange={() => {
            props.formApi.setValue('vehicleModels', undefined);
            props.formApi.setValue('vehicleModelFamilies', undefined);
          }}
          isNotClearable
        />
      </Show>
      <Hide when={isMakeSelectEnabled}>
        <VStack>
          <Label>{i18n.t('entity.vehicle.labels.make')}</Label>
          <TextInput data-testid={suffixTestId('vehicleMakes', props)} value={null} isDisabled />
        </VStack>
      </Hide>
      <Show when={isModelSelectEnabled}>
        <FormField
          control={props.control}
          type="multiChoice"
          name="vehicleModelFamilies"
          label={i18n.t('entity.vehicle.labels.modelFamily')}
          options={getModelFamilies(models ?? []).map((group) => ({
            label: group?.default_label,
            value: group?.group,
          }))}
          isLoading={isModelsLoading}
          data-testid={suffixTestId('vehicleModelFamilies', props)}
          isNotClearable
        />
        <FormField
          control={props.control}
          type="multiChoice"
          name="vehicleModels"
          label={i18n.t('entity.vehicle.labels.model')}
          options={models?.map((model) => ({
            label: model.default_label,
            value: model.model,
          }))}
          isLoading={isModelsLoading}
          data-testid={suffixTestId('vehicleModels', props)}
          isNotClearable
        />
      </Show>
      <Hide when={isModelSelectEnabled}>
        <VStack>
          <Label>{i18n.t('entity.vehicle.labels.modelFamily')}</Label>
          <TextInput data-testid={suffixTestId('modelFamily', props)} value={null} isDisabled />
        </VStack>
        <VStack>
          <Label>{i18n.t('entity.vehicle.labels.model')}</Label>
          <TextInput data-testid={suffixTestId('model', props)} value={null} isDisabled />
        </VStack>
      </Hide>
    </VStack>
  );
}

const getModelFamilies = pipe(
  (models: VehicleModel[]) => models.map((model) => model.model_group),
  (groups) => groups.filter(isNotNil),
  uniq
);
