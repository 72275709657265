import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openDialog,
  showNotification,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useCreateSaleVehicleAlbumMutation, useCreateVehicleAlbumMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

interface FormValues {
  description: string;
}

interface VehicleCreateAlbumProps {
  vehicleId: string;
  saleVehicleId?: string;
  onComplete?: () => void;
}

export function VehicleCreateAlbum(props: VehicleCreateAlbumProps) {
  const [createVehicleAlbum] = useCreateVehicleAlbumMutation();
  const [createSaleVehicleAlbum] = useCreateSaleVehicleAlbumMutation();

  const onSubmitVehicle: FormSubmitHandler<FormValues> = (values) =>
    createVehicleAlbum({
      createVehicleAlbumRequestBody: {
        name: 'PHOTOS_CUSTOM',
        description: values.description,
        vehicleId: props.vehicleId,
        serviceCaseId: null,
      },
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .then(props.onComplete)
      .catch(handleApiError);

  const onSubmitSaleVehicle: FormSubmitHandler<FormValues> = (values) =>
    createSaleVehicleAlbum({
      createSaleVehicleAlbumRequestBody: {
        name: 'PHOTOS_CUSTOM',
        description: values.description,
        saleVehicleId: props.saleVehicleId!,
      },
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .then(props.onComplete)
      .catch(handleApiError);

  const onCreateAlbumButtonClick = () => {
    openDialog(
      <VStack spacing={4}>
        <Text size="small" color="secondary">
          {i18n.t('entity.vehicle.albums.dialog.text')}
        </Text>
        <Form<FormValues>
          onSubmit={props.saleVehicleId ? onSubmitSaleVehicle : onSubmitVehicle}
          schema={schema}
        >
          {(control) => (
            <VStack spacing={4}>
              <FormField
                control={control}
                name="description"
                type="text"
                label={i18n.t('entity.vehicle.labels.albumName')}
                isRequired
              />
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.cancel')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                />
                <FormButton
                  title={i18n.t('general.actions.save')}
                  type="submit"
                  control={control}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </VStack>,
      {
        title: i18n.t('entity.vehicle.albums.create'),
        size: 'small',
      }
    );
  };

  return (
    <Button
      title={i18n.t('entity.vehicle.albums.create')}
      leftIcon="image/photo_album"
      variant="outlined"
      onClick={onCreateAlbumButtonClick}
    />
  );
}

const schema = object({
  description: yupString.required(),
});
