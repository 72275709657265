import {filter, uniq} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {MenuItemProps} from '@dms/api';
import {moduleKeys} from '@dms/config';

export function transformPinnedMenuItems(
  pinnedMenuItems: string[],
  menuItemsById: Record<string, Pick<MenuItemProps, 'id' | 'content'>>
) {
  return uniq(
    filter(
      isNotNil,
      pinnedMenuItems.map((id) => {
        if (id === moduleKeys.settings) {
          // Historically settings were in the sidebar. They have been moved to top bar.
          // It's not possible to add settings to the pinned items anymore, but some users might have it already pinned.
          return null;
        }

        const menuItem = menuItemsById[id];
        if (!menuItem) {
          // There are some legacy pinned items that are not in the menuItems anymore, it has to be filtered out.
          return null;
        }

        if ((menuItem.content?.length ?? 0) > 0) {
          // Historically only first level menu items could have been pinned.
          // Currently only menu items without subitems can be pinned.
          // In order to keep pinned items from the past, those with subitems are transformed to their first subitem,
          // since they route to the same place.
          // Once the sidebar is well established, the pinned items can be migrated to the new format in the database.
          // After that, this  part of the code can be removed.
          const firstContentItem = menuItem.content?.[0];

          if (firstContentItem) {
            return firstContentItem.id;
          }

          return null;
        }

        return menuItem.id;
      })
    )
  );
}
