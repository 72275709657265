import {css} from 'styled-components';

import {ReactElement} from 'react';

import {testIds} from '@dms/routes';

import {RolesTable} from './RolesTable';

export const Roles = (): ReactElement => (
  <div
    css={css`
      width: 100%;
      overflow-x: auto;
    `}
    data-testid={testIds.settings.userManagement('rolesContainer')}
  >
    <RolesTable data-testid={testIds.settings.userManagement('rolesTable')} />
  </div>
);
