import {isFeatureEnabled} from 'feature-flags';
import {Button, FormControl, FormField, IconButton} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {useFieldArray, UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  selectActiveBranchId,
  useGetBranchQuery,
  useGetEmployeeCostCentersListQuery,
  useGetEmployeeMechanicsQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantQuery,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {WorkSplittingFormType} from '../types/WorkSplittingFormType';

const DEFAULT_RATIO = 100;

interface WorkSplittingMechanicsProps extends RequiredTestIdProps {
  control: FormControl<WorkSplittingFormType>;
  formApi: UseFormReturn<WorkSplittingFormType>;
  serviceCaseId: string;
  serviceOrderId: string;
}

export function WorkSplittingMechanics(props: WorkSplittingMechanicsProps) {
  const branchId = useSelector(selectActiveBranchId);
  const {data: branchDetail} = useGetBranchQuery({branchId});
  const {data: order} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {data: mechanics, isLoading: isMechanicOptionsLoading} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {
      skip:
        isNil(orderVariant?.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );
  const {data: costCenterOptions, isLoading: isCostCenterOptionsLoading} =
    useGetEmployeeCostCentersListQuery(
      {company: branchDetail?.billingInformation?.id ?? '', branch: branchDetail?.id ?? ''},
      {
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.costCenters?.map((costCenter) => ({
            label: costCenter?.description,
            value: costCenter?.id,
          })),
        }),
        skip:
          isNilOrEmpty(branchDetail?.billingInformation?.id) ||
          isNilOrEmpty(branchDetail?.id) ||
          !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
      }
    );

  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: 'assignMechanics',
  });

  useEffect(() => {
    if (fields.length === 1) {
      props.formApi.setValue('assignMechanics.0.ratio', DEFAULT_RATIO);
    }
  }, [fields.length, props.formApi]);

  const handleMechanicChange = (index: number) => (value: string | number | string[] | null) => {
    if (!isString(value)) {
      return;
    }

    const selectedMechanic = mechanics?.employees?.find((mechanic) => mechanic?.id === value);

    props.formApi.setValue(
      `assignMechanics.${index}.costCenterId`,
      selectedMechanic?.costCenterId ?? null
    );
  };

  const mechanicOptions = mechanics?.employees?.map((mechanic) => ({
    label: mechanic?.name,
    value: mechanic?.id,
  }));

  return (
    <VStack spacing={4}>
      {fields.map((field, index) => (
        <HStack key={field.id} spacing={4}>
          <Box flex={2}>
            <FormField
              control={props.control}
              label={i18n.t('entity.order.labels.mechanic')}
              name={`assignMechanics.${index}.id`}
              type="choice"
              filterOption={(option) =>
                props.formApi
                  .watch('assignMechanics')
                  .every((mechanic) => mechanic?.id !== option.value)
              }
              onChange={handleMechanicChange(index)}
              options={mechanicOptions}
              isLoading={isMechanicOptionsLoading}
              data-testid={suffixTestId('mechanic', props)}
              menuInPortal
            />
          </Box>
          <Box flex={1}>
            <FormField
              control={props.control}
              label={i18n.t('entity.order.labels.costCenter')}
              name={`assignMechanics.${index}.costCenterId`}
              data-testid={suffixTestId('costCenter', props)}
              type="choice"
              options={costCenterOptions}
              isLoading={isCostCenterOptionsLoading}
              menuInPortal
            />
          </Box>
          <Box flex={1}>
            <HStack spacing={4}>
              <FormField
                control={props.control}
                label={i18n.t('entity.orderRequest.labels.ratio')}
                name={`assignMechanics.${index}.ratio`}
                type="integer"
                isDisabled={fields.length === 1}
                data-testid={suffixTestId('ratio', props)}
              />
              <Show when={fields.length > 1}>
                <VStack>
                  <Space vertical={5} />
                  <IconButton
                    icon="content/clear"
                    onClick={() => remove(index)}
                    priority="tertiary"
                    severity="danger"
                    data-testid={suffixTestId('removeMechanic', props)}
                  />
                </VStack>
              </Show>
            </HStack>
          </Box>
        </HStack>
      ))}
      <Box>
        <Button
          title={i18n.t('entity.order.actions.addMechanic')}
          leftIcon="content/add_circle"
          variant="link"
          onClick={() => append({id: null, costCenterId: null, ratio: null})}
          data-testid={suffixTestId('addMechanic', props)}
        />
      </Box>
    </VStack>
  );
}
