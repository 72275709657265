import {isFeatureEnabled} from 'feature-flags';

import {FC, PropsWithChildren} from 'react';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BranchOverview} from '../BranchOverview/BranchOverview';
import {BranchesList} from './components/BranchesList';

const UserSettingsPage: FC<PropsWithChildren<any>> = () => {
  if (isFeatureEnabled(featureFlags.TENANT_V2)) {
    return <BranchOverview />;
  }

  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.branch.labels.branches')}}
      data-testid="branches-settings-modal"
    >
      <BranchesList />
    </SettingsTemplate>
  );
};

export const Branches = UserSettingsPage;
