import styled from 'styled-components';

import {TextField} from '@dms/teas';

export const Wrapper = styled.form`
  label {
    font-size: 12px;
  }
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 12px;
`;
