import styled from 'styled-components';

import {DropdownMulti, SimpleTable, TypographyCard} from '@dms/teas';

export const SearchContainer = styled.div`
  padding: ${({theme}) => theme.getSize(3)};
  max-width: 175px;
`;

export const DropdownMultiStyled = styled(DropdownMulti)`
  width: 200px;
`;

export const StyledCard = styled(TypographyCard)`
  width: 100%;
`;

export const StyledTable = styled(SimpleTable)`
  .action-column {
    width: 32px;
  }

  .row-disabled td:not(:last-child) {
    opacity: 0.5;
  }
`;
