import {Space} from 'platform/foundation';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {LocaleConfigForm} from './components/LocaleConfigForm';

export function Locale() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('general.localeConfig.localization')}}
      data-testid={testIds.settings.locale('wrapper')}
    >
      <LocaleConfigForm data-testid="locale-config" />
      <Space vertical={4} />
    </SettingsTemplate>
  );
}
