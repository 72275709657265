import {PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';
import {all, call, takeLatest, put, debounce} from 'typed-redux-saga';

import {
  CebiaAutotracerAndReportConfigRequestBody,
  CebiaFeatureDecoderConfigRequestBody,
  ApiException,
} from '@dms/api';
import i18n from '@dms/i18n';

import {IntegratedService} from '../../services/IntegratedService';
import {callApiSaga} from '../../utils/api';
import {
  saveCebiaAutotraceAndReportData,
  getCebiaEquipmentCredentials,
  saveCebiaEquipmentCredentials,
  updateCebiaEquipmentCredential,
  patchFeatureDecoderConfig,
  getCebiaAutotraceAndReport,
  patchCebiaAutotraceAndReport,
} from './reducer';

function* getCebiaAutotraceAndReportGen(): Generator {
  try {
    const response = yield* callApiSaga(IntegratedService.getCebiaAutotracerAndReportConfig, {});
    yield* put(saveCebiaAutotraceAndReportData(response));
  } catch (exception: any) {
    yield* call(handlingError, {error: exception});
  }
}

function* patchFeatureDecoderConfigGen(
  action: PayloadAction<CebiaFeatureDecoderConfigRequestBody>
): Generator {
  try {
    yield* callApiSaga(IntegratedService.patchCebiaFeatureDecoderConfig, {
      requestBody: action.payload,
    });
    yield* call(() =>
      showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
    );
    yield* call(getCebiaEquipmentCredentialGen);
  } catch (exception: any) {
    yield* call(handlingError, {error: exception});
  }
}

function* patchCebiaAutotraceAndReportGen(
  action: PayloadAction<CebiaAutotracerAndReportConfigRequestBody>
): Generator {
  try {
    // call API and refresh data
    yield* callApiSaga(IntegratedService.patchCebiaAutotracerAndReportConfig, {
      requestBody: action.payload,
    });
    yield* call(() =>
      showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
    );
    yield* call(getCebiaAutotraceAndReportGen);
  } catch (exception: any) {
    yield* call(handlingError, {error: exception});
  }
}

function* getCebiaEquipmentCredentialGen(): Generator {
  try {
    const response = yield* callApiSaga(IntegratedService.getCebiaFeatureDecoderConfig, {});
    yield* put(saveCebiaEquipmentCredentials(response));
  } catch (exception: any) {
    yield* call(handlingError, {error: exception});
  }
}

function* updateCebiaEquipmentCredentialGen(
  action: PayloadAction<CebiaFeatureDecoderConfigRequestBody>
): Generator {
  try {
    yield* callApiSaga(IntegratedService.patchCebiaFeatureDecoderConfig, {
      requestBody: action.payload,
    });
    yield* call(getCebiaEquipmentCredentialGen);
  } catch (exception: any) {
    yield* call(handlingError, {error: exception});
  }
}

function* handlingError({error}: {error: unknown}): Generator {
  yield* call(() =>
    showNotification.error(
      (error as AxiosError<ApiException>)?.response?.data?.errors?.[0]?.message
    )
  );
}

export function* integrationsSettingsSaga(): Generator {
  yield* all([
    takeLatest(getCebiaAutotraceAndReport, getCebiaAutotraceAndReportGen),
    takeLatest(getCebiaEquipmentCredentials, getCebiaEquipmentCredentialGen),
    takeLatest(updateCebiaEquipmentCredential, updateCebiaEquipmentCredentialGen),
    debounce(300, patchFeatureDecoderConfig, patchFeatureDecoderConfigGen),
    debounce(300, patchCebiaAutotraceAndReport, patchCebiaAutotraceAndReportGen),
  ]);
}
