import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ParkingSectorForm} from './components/ParkingSectorForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.vehicleSettings.labels.parking'),
    href: settingsRoutes.vehicleParking,
  },
];

export function VehicleParkingAdd() {
  return (
    <SettingsTemplate
      header={{breadcrumbs}}
      data-testid={testIds.settings.vehicleParkingAdd('modal')}
    >
      <ParkingSectorForm />
    </SettingsTemplate>
  );
}
