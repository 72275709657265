import {DataStatus, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {
  useGetVehisExtraLinkQuery,
  useGetVehisPlatformQuery,
  useVehisAuthenticateMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {AdvertisingPlatformCodeEnum} from '@dms/teas';

import {useBranchId} from '../../../hooks/useBranchId';
import {advertisingPlatformNames} from '../consts/advertisingPlatformNames';
import {
  PlatformAuthenticationFormUrlExport,
  PlatformAuthenticationFormUrlExportProps,
} from './components/PlatformAuthenticationFormUrlExport';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

export function PlatformSettingsVehis() {
  const branchId = useBranchId();
  const params = {branchId};
  const {data, isLoading, isError} = useGetVehisPlatformQuery({params});
  const {data: extraLinkData} = useGetVehisExtraLinkQuery(params);
  const [saveAuthentication] = useVehisAuthenticateMutation();

  const onSubmitCredentials: PlatformAuthenticationFormUrlExportProps['onSubmit'] = (values) =>
    saveAuthentication({
      body: values,
      params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <DataStatus isLoading={isLoading} isError={isError}>
        {isNotNil(data) && isNotNil(extraLinkData) && (
          <PlatformAuthenticationFormUrlExport
            platformName={advertisingPlatformNames[AdvertisingPlatformCodeEnum.VEHIS]}
            defaultValues={data.credentials}
            extraLink={extraLinkData.link}
            onSubmit={onSubmitCredentials}
            data-testid={testIds.settings.advertisingPlatforms(
              'platformAuthenticationFormUrlExport'
            )}
          />
        )}
        <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.VEHIS} />
      </DataStatus>
    </Show>
  );
}
