import {Card} from 'platform/components';

import {FC} from 'react';

import i18n from '@dms/i18n';
import {generateInfoCardRows} from '@dms/teas';

import {FormContainerN20} from '../styles';

type BillingCompanyCardProps = {
  city?: string;
  street?: string;
  vatNumber?: string | null;
  fileNumber?: string | null;
  companyName?: string;
  registrationNumber?: string;
};

export const BillingCompanyCard: FC<BillingCompanyCardProps> = ({
  city,
  street,
  companyName,
  vatNumber,
  fileNumber,
  registrationNumber,
}) => (
  <Card variant="inlineGrey" title={i18n.t('entity.customer.labels.billingCompany')}>
    <FormContainerN20>
      {generateInfoCardRows([
        {
          key: 'companyName',
          label: i18n.t('entity.customer.labels.companyName'),
          value: companyName,
        },
        {
          key: 'businessAddress',
          label: i18n.t('entity.address.labels.businessAddress'),
          value: `${street}, ${city}`,
        },
        {
          key: 'registrationNumber',
          label: i18n.t('entity.customer.labels.registrationNumber'),
          value: registrationNumber,
        },
        {
          key: 'vatNumber',
          label: i18n.t('entity.customer.labels.vatNumber'),
          value: vatNumber,
        },
        {
          key: 'fileNumber',
          label: i18n.t('entity.customer.labels.fileNumber'),
          value: fileNumber,
        },
      ])}
    </FormContainerN20>
  </Card>
);
