import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {ServiceOrderIssueVariant} from '@dms/api';

import {Nullish} from 'shared';

export const getOptionsFromIssueNoteVariants = (
  issueNoteVariants: ServiceOrderIssueVariant[] | Nullish
): Option[] => {
  if (isNil(issueNoteVariants)) {
    return [];
  }
  return issueNoteVariants.map((issueNoteVariant) => ({
    label: issueNoteVariant.name,
    value: issueNoteVariant.serviceOrderIssueVariantId,
  }));
};
