import {Parameters} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';

import {testIds} from '@dms/routes';

import {useInterval} from 'shared';

export function Clock() {
  const format = useDateTimeFormatter();
  const [date, setDate] = useState(new Date());

  useInterval(() => setDate(new Date()), 1000);

  return (
    <Parameters
      parameters={[format('timeShort', date), format('dateMedium', date)]}
      color="secondary"
      size="small"
      data-testid={testIds.dashboard.home('clock')}
    />
  );
}
