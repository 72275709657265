/* eslint-disable eag/match-named-export */

/* eslint-disable eag/no-index */
import {defaultTo, find, head, map, pipe} from 'ramda';

import {AuditCategoryUniqueKey, BasicCategoryOfStructure} from '@dms/teas';

/**
 * @about We need to know, with categoryIds are for equipment due to using non-legacy code in that part
 */
export const getEquipmentIdsFromCategories = (category: BasicCategoryOfStructure[]) =>
  pipe(
    find((cat: BasicCategoryOfStructure) => cat.uniqueKey === AuditCategoryUniqueKey.EQUIPMENT),
    (cat) => head(cat?.childCategories ?? [])?.childCategories,
    defaultTo([]),
    map((item: BasicCategoryOfStructure) => item.id)
  )(category);

/**
 *
 * @param category needed to match catalogue with sctructure data
 * @returns
 */
export const getEquipmentUniqueKeyToIdDictionary = (
  category: BasicCategoryOfStructure | undefined
) =>
  category?.childCategories?.[0]?.childCategories
    ? Object.fromEntries(
        category?.childCategories?.[0]?.childCategories?.map((feature) => [
          feature.uniqueKey,
          feature.id,
        ])
      )
    : {};
