import {groupBy} from 'ramda';

import {
  PermissionV2ResponseBody,
  ProtectedGroupResponseBody,
  ProtectedUnitResponseBody,
  useListPermissionsV2Query,
  useListProtectedUnitsQuery,
} from '@dms/api';

export type AclActionWithPermission = {
  id: string;
  name: string;
  permission: PermissionV2ResponseBody | null;
};

export type ProtectedUnitWithPermission = ProtectedUnitResponseBody & {
  permission: PermissionV2ResponseBody | null;
};
export type TableDataType = {
  id: string;
  name: string;
  protectedUnits: ProtectedUnitWithPermission[];
  children: TableDataType[];
};

export const useGetACLData = (roleId: string) => {
  const {
    data: resources,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = useListProtectedUnitsQuery();
  const {
    data: permissions,
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
  } = useListPermissionsV2Query({
    roleId,
  });

  const permissionsByResourceId = groupBy((permission) => permission.group.id, permissions ?? []);

  const mapResource = (resource: ProtectedGroupResponseBody): TableDataType => {
    const children = resource.children.map(mapResource);
    const permission = permissionsByResourceId[resource.id];

    return {
      ...resource,
      children,
      protectedUnits: resource.protectedUnits.map((action) => {
        const actionPermission = permission?.find((item) => item.protectedUnit?.id === action.id);

        return {
          ...action,
          permission: actionPermission ?? null,
        };
      }),
    };
  };

  const isLoading = isLoadingResources || isLoadingPermissions;
  const isError = isErrorResources || isErrorPermissions;

  const tableData = resources?.map(mapResource);

  return {tableData, isLoading, isError};
};
