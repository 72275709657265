import {UrlBuilderConfig, getWorkspaceFromUri, urlBuilder} from '@dms/api';
import {environment} from '@dms/environment';

export function getDigitalCertificateUrl(inspectionId: string, locale: string) {
  const {workspace} = getWorkspaceFromUri();

  const builderConfig: UrlBuilderConfig = {
    workspace,
    protocol: window.location.protocol,
    domain: environment.DIGITAL_CERTIFICATE_DOMAIN,
    route: `/${inspectionId}`,
    searchParam: `?lang=${locale}`,
  };

  return urlBuilder(builderConfig);
}
