import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {isNil, always} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getInterestTypeFlagProps = (type: string | Nullish) => {
  if (isNil(type)) {
    return null;
  }

  return match<string, BaseFlagProps | null>(type)
    .with(
      'SELLING',
      always({
        colorScheme: 'orange',
        label: i18n.t('page.businessCase.labels.typeSelling'),
        isSubtle: true,
      })
    )
    .with(
      'BUYING',
      always({
        colorScheme: 'green',
        label: i18n.t('page.businessCase.labels.typeBuying'),
        isSubtle: true,
      })
    )
    .with(
      'SWAP',
      always({
        colorScheme: 'purple',
        label: i18n.t('page.businessCase.labels.typeSwap'),
        isSubtle: true,
      })
    )
    .otherwise(always(null));
};
