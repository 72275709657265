import {subDays} from 'date-fns';
import {FormField} from 'platform/components';
import {Grid, Heading, Text, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {suffixTestId} from 'shared';

import {ReservationStepProps} from '../types/ReservationStepProps';

interface DateStepProps extends ReservationStepProps {}

const YESTERDAY_DATE = subDays(new Date(), 1);

export function DateStep(props: DateStepProps) {
  return (
    <VStack spacing={4}>
      <Heading size={3}>{i18n.t('entity.vehicle.labels.reservationDate')}</Heading>
      <Text size="small" color="secondary">
        {i18n.t('entity.vehicle.labels.reservationDateDescription')}
      </Text>
      <VStack spacing={4}>
        <Grid columns={2}>
          <VStack spacing={4}>
            <FormField
              label={i18n.t('entity.vehicle.labels.reserveUntil')}
              name="reserveUntil"
              type="date"
              minDate={YESTERDAY_DATE}
              control={props.control}
              isRequired
              data-testid={suffixTestId('resdate', props)}
            />
          </VStack>
        </Grid>
        <FormField
          label={i18n.t('general.labels.note')}
          name="note"
          type="textarea"
          minRows={2}
          data-testid={suffixTestId('note', props)}
          control={props.control}
        />
      </VStack>
    </VStack>
  );
}
