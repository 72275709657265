import {DeepPath, DeepPathArray} from '@hookform/strictly-typed';

import {ReactElement} from 'react';
import {UseFormReturn, FormState, Path, Control, Controller, FieldValues} from 'react-hook-form';
import {GetOptionLabel, GroupBase, OnChangeValue, Options} from 'react-select';

import {path} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {Dropdown, DropdownComponentEnhancedProps} from '../../Dropdown/Dropdown';
import {FieldProps} from '../FieldProps';
import {FieldLabel, ValidationMessage} from '../styles';

type getOptionValueUnstrict<OptionType> = (option: OptionType | null | undefined) => string;

export interface DropdownFieldProps<
  TValues extends FieldValues,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
  TOptionType extends Record<string, unknown> = Record<string, unknown>,
> extends FieldProps<TValues, TPath> {
  options: Options<TOptionType>;
  getOptionValue?: getOptionValueUnstrict<TOptionType>;
  getOptionLabel?: GetOptionLabel<TOptionType>;
  onChange?: (values: any, formState: FormState<TValues>) => void;
  defaultValue?: string extends keyof TValues ? TValues[keyof TValues & string] : string;
  dropdownProps?: Omit<
    DropdownComponentEnhancedProps<TValues>,
    'options' | 'getOptionValue' | 'getOptionLabel' | 'onChange' | 'defaultValue'
  >;
}
/**
 * @deprecated - use platform instead
 */
export function useDropdownFields<
  TValues extends Record<string, string | undefined>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
>({
  control,
  formState: {errors},
  getValues,
  formState,
  setValue,
  trigger,
}: UseFormReturn<TValues>): (fieldProps: DropdownFieldProps<TValues, TPath>) => ReactElement {
  return ({
    label,
    name,
    onChange,
    options,
    getOptionValue,
    dropdownProps,
    defaultValue,
    links,
    preventTrigger,
    ...rest
  }: DropdownFieldProps<TValues, TPath>) => {
    const stringifiedName = Array.isArray(name) ? name.join('.') : String(name);
    return (
      <>
        {label && <FieldLabel className="label">{i18n.t(label)}</FieldLabel>}
        <Controller
          name={stringifiedName}
          control={control as Control<Record<string, any>>}
          defaultValue={defaultValue as any}
          render={(props) => {
            const handleChange = (value: OnChangeValue<TValues, false>) => {
              props.field.onChange(getOptionValue ? getOptionValue(value) : value);

              !preventTrigger && trigger();

              if (links) {
                if (Array.isArray(links)) {
                  links.forEach((linkName) =>
                    setValue(
                      linkName as Path<TValues>,
                      (getOptionValue ? getOptionValue(value) : value) as any
                    )
                  );
                } else {
                  setValue(
                    links as Path<TValues>,
                    (getOptionValue ? getOptionValue(value) : value) as any
                  );
                }
              }

              if (onChange) {
                const values = getValues();
                onChange(values, formState);
              }
            };

            return (
              <Dropdown<TValues>
                {...props.field}
                {...rest}
                name={stringifiedName}
                {...dropdownProps}
                options={options as ReadonlyArray<GroupBase<TValues>> | Options<TValues>}
                getOptionValue={getOptionValue}
                value={(options as Options<TValues>)?.find(
                  (option) =>
                    (getOptionValue ? getOptionValue(option) : option) === props.field.value
                )}
                onChange={handleChange}
              />
            );
          }}
        />
        {
          // eslint-disable-next-line no-restricted-syntax
          path(isString(name) ? [name] : Array.from(name as unknown as string[]), errors) && (
            <ValidationMessage data-testid={`dropdown-${stringifiedName}-error-text`}>
              {path(
                isString(name)
                  ? [name, 'message']
                  : // eslint-disable-next-line no-restricted-syntax
                    [...Array.from(name as unknown as string[]), 'message'],
                errors
              )}
            </ValidationMessage>
          )
        }
      </>
    );
  };
}
