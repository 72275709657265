import {Separator} from 'platform/components';
import {Grid, Show} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {concat, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {AuditParamDefinition} from '@dms/api';

import {suffixTestId, TestIdProps} from 'shared';

import {selectPhotoDocumentation} from '../../../store/carAudit/selectors';
import {useConditionContext} from '../hooks/useConditionContext';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findByParamType} from '../utils/findByParamType';
import {findCategoryByUniqueKey} from '../utils/findCategoryByUniqueKey';
import {PhotoDocumentationCard} from './PhotoDocumentationCard';

export function PhotoDocumentation(props: TestIdProps) {
  const photoDocumentation = useSelector(selectPhotoDocumentation);
  const {isCreateVehicle} = useConditionContext();

  if (!photoDocumentation) {
    return null;
  }

  const morePhotos = findCategoryByUniqueKey(
    photoDocumentation,
    AuditCategoryUniqueKey.MORE_PHOTOS
  );
  const morePhotosParam = findByParamType(
    morePhotos?.paramDefinitions?.relatedActions,
    AuditParamType.MORE_PHOTOS
  );

  const videos = findCategoryByUniqueKey(photoDocumentation, AuditCategoryUniqueKey.VIDEOS);
  const videosParam = findByParamType(
    videos?.paramDefinitions?.relatedActions,
    AuditParamType.VIDEO
  );

  const paramDefinitions = photoDocumentation.childCategories?.reduce(
    (relatedActions: AuditParamDefinition[], category) => {
      if (isNotNil(category.paramDefinitions?.relatedActions) && category.uniqueKey !== 'VIDEOS') {
        const actions = category.paramDefinitions.relatedActions.filter(
          (relatedAction) => relatedAction.type !== AuditParamType.MORE_PHOTOS
        );
        return isNotNilOrEmpty(actions) ? concat(relatedActions, actions) : relatedActions;
      }
      return relatedActions;
    },
    []
  );

  return (
    <>
      <Grid columns={8} align="flex-end">
        {paramDefinitions?.map((paramDefinition, index) => (
          <PhotoDocumentationCard
            key={`${paramDefinition.id}-${paramDefinition.label}`}
            categoryId={photoDocumentation.id}
            paramDefinition={paramDefinition}
            label={paramDefinition.label}
            data-testid={suffixTestId(`photoDocumentationCard-[${index}]`, props)}
          />
        ))}
      </Grid>
      <Separator />
      <Show when={isNotNilOrEmpty(morePhotosParam) && isNotNilOrEmpty(morePhotos)}>
        <PhotoDocumentationCard
          categoryId={morePhotos?.id!}
          paramDefinition={morePhotosParam!}
          label={morePhotosParam?.label}
          multiple
          data-testid={suffixTestId('morePhotoDocumentationCard', props)}
        />
      </Show>
      {/*
        AuditVideos component is not ready to be used in VehicleCreateForm (isCreateVehicle)

        To use it while vehicle is being created, instead of calling onVideoProcessed
        call pushConditionAsset from VehicleCreate context and implement assigning the video
        to the audit in VehicleCreateForm's saveConditionData (there the videos are taken
        from the context by getConditionAssets)
       */}
      <Show when={isNotNilOrEmpty(videosParam) && isNotNilOrEmpty(videos) && !isCreateVehicle}>
        <Separator />
        <PhotoDocumentationCard
          categoryId={videos?.id!}
          paramDefinition={videosParam!}
          label={videosParam?.label}
          multiple
          isVideo
          data-testid={suffixTestId('videosDocumentationCard', props)}
        />
      </Show>
    </>
  );
}
