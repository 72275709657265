import {Card, Checkbox, FormControl, FormField, Separator} from 'platform/components';
import {GridItem, Grid, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Path, UseFormReturn, useWatch} from 'react-hook-form';

import {always} from 'ramda';
import {isFalse, isTrue} from 'ramda-adjunct';

import {DefaultPaymentType, GetTemplatesApiResponse, SeriesResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {getOptionsFromSeriesList} from '../../../../../utils/getOptionsFromSeriesList';
import {getOptionsFromTemplates} from '../../../../../utils/getOptionsFromTemplates';
import {DirectSaleVariantForm} from '../../../types/DirectSaleVariantForm';

interface PaymentCardProps extends TestIdProps {
  title: string;
  paymentType: DefaultPaymentType;
  control: FormControl<DirectSaleVariantForm>;
  formApi: UseFormReturn<DirectSaleVariantForm>;
  docSeriesId: Path<DirectSaleVariantForm>;
  invoiceTemplateId: Path<DirectSaleVariantForm>;
  isEnable: Path<DirectSaleVariantForm>;
  seriesList?: SeriesResponseBody[];
  invoiceTemplates?: GetTemplatesApiResponse;
}

export function PaymentCard(props: PaymentCardProps) {
  const isPaymentTypeEnabled = useWatch({name: props.isEnable});
  const defaultPaymentType = useWatch({name: 'documents.defaultPaymentType'});

  const handlePaymentTypeToggle = (value: boolean) => {
    const paymenTypeIsDefault = props.paymentType === defaultPaymentType;

    props.formApi.setValue(props.isEnable, value);

    match([paymenTypeIsDefault, isFalse(value)])
      .with([true, true], () => {
        props.formApi.setValue('documents.defaultPaymentType', null);
        props.formApi.setValue(props.docSeriesId, null);
        props.formApi.setValue(props.invoiceTemplateId, null);
      })
      .with([false, true], () => {
        props.formApi.setValue(props.docSeriesId, null);
        props.formApi.setValue(props.invoiceTemplateId, null);
      })
      .otherwise(always(null));
  };

  const handleDefaultPaymentChange = () => {
    match(props.paymentType)
      .with('CASH', () => props.formApi.setValue('documents.defaultPaymentType', 'CASH'))
      .with('CARD', () => props.formApi.setValue('documents.defaultPaymentType', 'CARD'))
      .with('BANK_TRANSFER', () =>
        props.formApi.setValue('documents.defaultPaymentType', 'BANK_TRANSFER')
      )
      .with('INTERNAL', () => props.formApi.setValue('documents.defaultPaymentType', 'INTERNAL'))
      .exhaustive();
  };

  return (
    <Card
      title={props.title}
      variant="inlineWhite"
      isExpanded={isTrue(isPaymentTypeEnabled)}
      control={{
        type: 'switch',
        value: isPaymentTypeEnabled,
        onChange: handlePaymentTypeToggle,
      }}
      data-testid={suffixTestId('card', props)}
    >
      <VStack>
        <Grid columns={2}>
          <GridItem span={2}>
            <Checkbox
              label={i18n.t('entity.warehouse.labels.defaultPaymentMethod')}
              value={props.paymentType === defaultPaymentType}
              onChange={handleDefaultPaymentChange}
              data-testid={suffixTestId('isDefaultPaymentType', props)}
            />
          </GridItem>
        </Grid>

        <Separator />

        <Grid columns={2}>
          <GridItem span={1}>
            <FormField
              isRequired={isPaymentTypeEnabled}
              isNotClearable={isPaymentTypeEnabled}
              control={props.control}
              name={props.docSeriesId}
              type="choice"
              label={i18n.t('entity.warehouse.labels.invoiceSeries')}
              options={getOptionsFromSeriesList(props.seriesList, 'accounting/invoice')}
              data-testid={suffixTestId('docSeriesId', props)}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              isRequired={isPaymentTypeEnabled}
              isNotClearable={isPaymentTypeEnabled}
              control={props.control}
              name={props.invoiceTemplateId}
              type="choice"
              label={i18n.t('entity.warehouse.labels.invoiceTemplate')}
              options={getOptionsFromTemplates(props.invoiceTemplates)}
              data-testid={suffixTestId('invoiceTemplateId', props)}
            />
          </GridItem>
        </Grid>
      </VStack>
    </Card>
  );
}
