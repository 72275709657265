import {FormControl, FormField} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import i18n from '@dms/i18n';

import {VectorType} from '../types/MultipleLocationsForm';
import {SingleLocationFormValue} from '../types/SingleLocationFormValue';

interface SimpleVectorRowProps {
  index: number;
  isSelectVisible: boolean;
  control: FormControl<SingleLocationFormValue>;
  formApi: UseFormReturn<SingleLocationFormValue>;
}

export function SimpleVectorRow(props: SimpleVectorRowProps) {
  const isSelected = useWatch({
    control: props.control,
    name: `storageLocation.${props.index}.isSelected`,
  });

  return (
    <HStack spacing={4} align="center">
      <Show when={props.isSelectVisible}>
        <Box width={5}>
          <FormField
            type="checkbox"
            name={`storageLocation.${props.index}.isSelected`}
            control={props.control}
            onChange={() => props.formApi.trigger()}
          />
        </Box>
      </Show>
      <Box flex={1}>
        <FormField
          type="choice"
          name={`storageLocation.${props.index}.vectorType`}
          control={props.control}
          options={[
            {
              label: i18n.t('entity.tireWarehouses.labels.aisle'),
              value: VectorType.Aisle,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.rack'),
              value: VectorType.Rack,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.level'),
              value: VectorType.Level,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.position'),
              value: VectorType.Position,
            },
            {
              label: i18n.t('entity.tireWarehouses.labels.other'),
              value: VectorType.Other,
            },
          ]}
          isDisabled
          isNotClearable
        />
      </Box>
      <Box flex={1}>
        <FormField
          type="text"
          name={`storageLocation.${props.index}.symbol`}
          control={props.control}
          isDisabled={!isSelected}
        />
      </Box>
    </HStack>
  );
}
