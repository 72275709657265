import {
  Action,
  Attributes,
  Card,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {
  ContractInformationResponseBodyV2,
  useDeleteCustomerContractInformationMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {buildArray, EMPTY_PLACEHOLDER, RequiredTestIdProps, suffixTestId} from 'shared';

import {useCustomerContractAddress} from '../../../hooks/useCustomerContractAddress';
import {useCustomerContractAttributes} from '../../../hooks/useCustomerContractAttributes';
import {getContractSubTitle} from '../../../utils/getContractSubTitle';
import {getContractTitle} from '../../../utils/getContractTitle';
import {handleApiError} from '../../../utils/handleApiError';
import {ContractInformationForm} from '../../ContactInformationForm/ContractInformationForm';
import {CustomerVerification} from '../../CustomerVerification/CustomerVerification';
import {AdditionalCustomerTypeProps} from '../types/AdditionalCustomerTypeProps';

interface CustomerContractCardProps extends RequiredTestIdProps {
  isSelected: boolean;
  onSelect: () => void;
  customerId: string;
  customerContract: ContractInformationResponseBodyV2;
  recordId?: string;
  resourceId?: string;
  additionalCustomerType?: AdditionalCustomerTypeProps;
}

export function CustomerContractCard(props: CustomerContractCardProps) {
  const [getContractAddress] = useCustomerContractAddress();
  const [getAttributes] = useCustomerContractAttributes();

  const [deleteContract, {isLoading: isDeleteLoading}] =
    useDeleteCustomerContractInformationMutation();

  const onDelete = () =>
    openDeleteDialog({
      onConfirm: () =>
        deleteContract({
          customerId: props.customerId,
          contractInformationId: props.customerContract.id,
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
    });

  return (
    <Card
      title={getContractTitle(props.customerContract)}
      subtitle={getContractSubTitle(props.customerContract)}
      parameters={[getContractAddress(props.customerContract)]}
      isExpanded={props.isSelected}
      hasSeparator={false}
      variant="inlineWhite"
      isClosedByDefault
      control={{
        type: 'radio',
        value: props.isSelected,
        onChange: props.onSelect,
      }}
      actions={buildArray<Action>()
        .when(props.additionalCustomerType && props.isSelected, {
          type: 'dropdown-button',
          variant: 'outlined',
          title: match(props.additionalCustomerType?.value)
            .with('OPERATOR', always(i18n.t('entity.accounting.labels.operator')))
            .with('RECIPIENT', always(i18n.t('entity.accounting.labels.recipient')))
            .when(isNil, always(EMPTY_PLACEHOLDER))
            .exhaustive(),
          rightIcon: 'navigation/expand_more',
          menuItems: [
            {
              label: i18n.t('entity.accounting.labels.operator'),
              isDisabled: props.additionalCustomerType?.value === 'OPERATOR',
              onClick: () => props.additionalCustomerType?.onChange('OPERATOR'),
            },
            {
              label: i18n.t('entity.accounting.labels.recipient'),
              isDisabled: props.additionalCustomerType?.value === 'RECIPIENT',
              onClick: () => props.additionalCustomerType?.onChange('RECIPIENT'),
            },
          ],
        })
        .add({
          type: 'iconButton',
          severity: 'danger',
          icon: 'action/delete',
          isDisabled: isDeleteLoading,
          onClick: onDelete,
        })
        .add({
          type: 'iconButton',
          icon: 'image/edit',
          onClick: () =>
            openDialog(
              <ContractInformationForm
                customerId={props.customerId}
                isEditContactInformation
                contract={props.customerContract}
                data-testid={suffixTestId('editContract', props)}
              />,
              {
                title: i18n.t('entity.customer.actions.editBillingInformation'),
                withAdditionalFooter: true,
                'data-testid': suffixTestId('editContract', props),
              }
            ),
        })}
      data-testid={suffixTestId('contract', props)}
    >
      <VStack spacing={4} align="flex-start">
        <Attributes
          rows={getAttributes(props.customerContract)}
          data-testid={suffixTestId('info', props)}
        />
        <CustomerVerification
          contractInformationId={props.customerContract.id}
          customerId={props.customerId}
          recordId={props.recordId}
          resourceId={props.resourceId}
          data-testid={suffixTestId('verification', props)}
        />
      </VStack>
    </Card>
  );
}
