import {Checkbox, Flag, Tooltip} from 'platform/components';
import {
  Image,
  Box,
  Clickable,
  Show,
  Spinner,
  ClickableProps,
  Icon,
  Hide,
} from 'platform/foundation';

import {pickAll} from 'ramda';

import {FileOperationStateHttpBody} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {Sortable} from './dragndrop/Sortable';

interface AlbumImageProps extends Omit<ClickableProps, 'children'>, TestIdProps {
  id: string;
  src: string;
  isLoading?: boolean;
  isSelected?: boolean;
  isSelectable?: boolean;
  isCoverPhoto?: boolean;
  isPromoPhoto?: boolean;
  isGhost?: boolean;
  isDisabledDndDrop?: boolean;
  isDisabledDndDrag?: boolean;
  onClick?: () => void;
  isProcessing?: {
    message: string;
    state: FileOperationStateHttpBody;
  };
}

const getClickableProps = pickAll([
  'isDisabled',
  'onClick',
  'onMouseWheelClick',
  'onContextMenu',
  'onMouseEnter',
  'onMouseLeave',
  'role',
]);

export function AlbumImage(props: AlbumImageProps) {
  const clickableProps = getClickableProps(props) satisfies ClickableProps;

  const isSelected = (props.isSelectable && props.isSelected) ?? false;

  return (
    <Sortable
      id={props.id}
      disabledDrag={props.isDisabledDndDrag}
      disabledDrop={props.isDisabledDndDrop}
      withHandle={props.isSelectable}
    >
      {({isDragging, handleProps}) => (
        <Clickable
          data-testid={suffixTestId('albumImage', props)}
          {...clickableProps}
          onClick={(event) => {
            event.preventDefault();
            props.onClick?.();
          }}
          onContextMenu={(event) => {
            event.preventDefault();
            props.onContextMenu?.(event);
          }}
          isDisabled={props.isDisabled || props.isLoading}
        >
          <Show when={props.isSelectable}>
            <Box position="absolute" zIndex="OVERLAY" paddingVertical={2} paddingHorizontal={1}>
              <div {...handleProps}>
                <Icon value="action/drag_indicator" color="general.white" />
              </div>
            </Box>
          </Show>
          <Box
            ratio="4 / 3"
            width="100%"
            overflow="hidden"
            position="relative"
            borderColor={isSelected ? 'palettes.blue.60.100' : 'general.transparent'}
            border="3px solid"
            borderRadius="small"
            opacity={isDragging || props.isGhost ? 0.5 : 1}
          >
            <Image
              data-testid={suffixTestId('albumImage', props)}
              width="100%"
              draggable={false}
              borderRadius={isSelected ? undefined : 'small'}
              height="100%"
              src={props.src}
              fit="cover"
              hasSpinner
            />
            <Show when={props.isLoading}>
              <Spinner data-testid={suffixTestId('albumImage', props)} variant="overlay" />
            </Show>
            <Show when={props.isProcessing && props.isProcessing?.state !== 'SUCCESS'}>
              <Box position="absolute" top={0} left={0} padding={2}>
                <Tooltip label={props.isProcessing?.message}>
                  <Hide when={props.isProcessing?.state === 'FAILED'}>
                    <Icon value="action/watch_later" color="severity.informational" />
                  </Hide>
                  <Show when={props.isProcessing?.state === 'FAILED'}>
                    <Icon value="alert/warning" color="severity.danger" />
                  </Show>
                </Tooltip>
              </Box>
            </Show>
            <Show when={props.isPromoPhoto}>
              <Box position="absolute" top={0} right={0} padding={2}>
                <Flag
                  data-testid={suffixTestId('isPromoPhoto-flag', props)}
                  label={i18n.t('entity.photo.labels.promoPhoto')}
                  colorScheme="black"
                  isSubtle
                  size="small"
                />
              </Box>
            </Show>
            <Show when={props.isCoverPhoto}>
              <Box position="absolute" top={0} right={0} padding={2}>
                <Flag
                  data-testid={suffixTestId('isCoverPhoto-flag', props)}
                  label={i18n.t('entity.photo.labels.coverPhoto')}
                  colorScheme="blue"
                  isSubtle
                  size="small"
                />
              </Box>
            </Show>
            <Show when={props.isSelectable && !props.isLoading && !props.isDisabled}>
              <Box position="absolute" bottom={0} right={0} padding={2}>
                <Checkbox
                  value={isSelected}
                  onChange={props.onClick}
                  data-testid={suffixTestId('albumImage', props)}
                />
              </Box>
            </Show>
          </Box>
        </Clickable>
      )}
    </Sortable>
  );
}
