import {
  Alert,
  ButtonGroup,
  ButtonProps,
  closeCurrentDialog,
  DataStatus,
  showNotification,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {includes} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  PatchVehicleAdvertisingSettingsRequestBody,
  useEditGeneralSettingsMutation,
  useGetGeneralSettingsQuery,
  VehicleAdvertisingSettingsResponseBody,
  VehicleGeneralAdvertisingAttributeRequestBody,
  VehicleGeneralAdvertisingSettingsRequestBody,
  VehicleWindshieldAdvertisingSettingsResponseBody,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, useAdPlatformsVehicleUpdateDialog} from '@dms/shared';
import {$AdvertisingSettingsRequestBody, Form} from '@dms/teas';

import {appendSuffix, buildArray, suffixTestId, TestIdProps} from 'shared';

import {AdvertisingAttributeField} from './AdvertisingAttributeField';

interface AdSettingsProps extends TestIdProps {
  vehicleId: string;
  isDisabled: boolean;
}

const SKIPPED_FIELDS = [
  'title_extension',
  'description',
  'condition',
  'version',
  'generation',
  'digital_certificate',
  'digital_certificate_theme',
  'video_url',
];

export function AdSettings(props: AdSettingsProps) {
  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();

  const {
    data: adSettings,
    isLoading,
    isError,
  } = useGetGeneralSettingsQuery(
    {
      vehicleId: props.vehicleId,
    },
    {
      skip: isNilOrEmpty(props.vehicleId),
      selectFromResult: ({data, ...res}) => ({
        ...res,
        data: {
          ...data,
          general: {
            attributes: data?.general.attributes.filter(
              (attribute) => !includes(attribute.code, SKIPPED_FIELDS)
            ),
          },
        } as VehicleAdvertisingSettingsResponseBody,
      }),
    }
  );

  const [editAdSettings, {isLoading: isEditLoading}] = useEditGeneralSettingsMutation();

  const handleSubmit = async (values: PatchVehicleAdvertisingSettingsRequestBody) => {
    await editAdSettings({
      vehicleId: props.vehicleId,
      patchVehicleAdvertisingSettingsRequestBody: values,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .then(() => {
        showNotification.success(
          i18n.t('general.notifications.advertisement.windshieldDataEditSubtitle')
        );
        updateVehicleAds(props.vehicleId);
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNilOrEmpty(adSettings)}>
      <Form<
        VehicleGeneralAdvertisingSettingsRequestBody,
        VehicleAdvertisingSettingsResponseBody,
        PatchVehicleAdvertisingSettingsRequestBody
      >
        onSubmit={handleSubmit}
        formId={appendSuffix('adSettingsForm', props['data-testid'])}
        schema={$AdvertisingSettingsRequestBody}
        getFormValues={(settings) => ({attributes: settings.general.attributes})}
        getRequestBody={(values) => ({
          general: {
            attributes: values.attributes.map((attribute) => ({
              ...attribute,
              value: attribute.override ? attribute.value : null,
            })) as VehicleGeneralAdvertisingAttributeRequestBody[],
          },
        })}
        initialValues={adSettings}
        render={(formRendererProps) => {
          const {handleSubmit, FieldArray} = formRendererProps;

          return (
            <form onSubmit={handleSubmit}>
              <Grid columns={12}>
                <GridItem span={12}>
                  <Alert
                    data-testid={suffixTestId('adSettings-alert', props)}
                    type="inline"
                    variant="info"
                    title={i18n.t('general.notifications.advertisement.generalDataEdit')}
                  />
                </GridItem>
                <FieldArray<VehicleWindshieldAdvertisingSettingsResponseBody>
                  subscription={{}}
                  name="attributes"
                >
                  {({fields}) =>
                    fields.map((name) => (
                      <AdvertisingAttributeField
                        key={name}
                        name={name}
                        isDisabled={props.isDisabled}
                      />
                    ))
                  }
                </FieldArray>
                <GridItem span={12}>
                  <ButtonGroup
                    align="right"
                    buttons={buildArray<ButtonProps>()
                      .add({
                        title: i18n.t('general.actions.discard'),
                        variant: 'secondary',
                        onClick: closeCurrentDialog,
                      })
                      .whenNot(props.isDisabled, {
                        title: i18n.t('general.actions.confirm'),
                        onClick: handleSubmit,
                        isLoading: isEditLoading,
                      })}
                  />
                </GridItem>
              </Grid>
            </form>
          );
        }}
      />
    </DataStatus>
  );
}
