import {useAnimatedPopper} from 'platform/components';
import {Box, HStack, Icon, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {MenuItemProps} from '@dms/api';
import i18n from '@dms/i18n';

import {useOutsideClick} from 'shared';

import {MenuLink} from './MenuLink';
import {SidebarButton} from './SidebarButton';

export function SidebarOverflowMenu(props: {
  pinnedMenuItems: string[];
  menuItemsById: Record<string, MenuItemProps>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const {togglePopper, closePopper, referenceRef, popperProps, Popper, isOpen} = useAnimatedPopper({
    placement: 'right-end',
    isOutsideClickIgnored: true,
    gutter: 8,
  });

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <Box ref={ref}>
      <SidebarButton ref={referenceRef} onClick={togglePopper} isActive={isOpen}>
        <Icon color="text.white" size={6} value="navigation/more_vert" />
      </SidebarButton>
      <Popper {...popperProps}>
        <Box
          padding={2}
          borderRadius="medium"
          overflowY="auto"
          maxHeight="100vh"
          backgroundColor="palettes.neutral.900.100"
        >
          <VStack>
            {props.pinnedMenuItems.map((id) => {
              const menuItem = props.menuItemsById[id];
              if (!menuItem) {
                return null;
              }

              return (
                <MenuLink to={menuItem.path} key={menuItem.id}>
                  <HStack align="center" spacing={3}>
                    <Icon size={4} value={menuItem.layoutProps.icon} />
                    {i18n.t(menuItem.layoutProps.title)}
                  </HStack>
                </MenuLink>
              );
            })}
          </VStack>
        </Box>
      </Popper>
    </Box>
  );
}
