import {isFeatureEnabled} from 'feature-flags';
import {Nullish} from 'utility-types';

import {isNotNil} from 'ramda';

import {SaleVehicleResponseBody} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {EntityHeaderControl, usePermissions, useVehicleWarehouse} from '@dms/shared';

import {buildArray} from 'shared';

interface UseGetVehicleControlsProps {
  isSaleVehicle: boolean;
  vehicleId: string;
  saleVehicle: SaleVehicleResponseBody | Nullish;
}

export function useGetVehicleControls(props: UseGetVehicleControlsProps) {
  const {isVehicleWarehouseEnabled} = useVehicleWarehouse({
    vehicleId: props.vehicleId,
  });

  const [hasVehicleReadWarehouseInfoPermission] = usePermissions({
    permissionKeys: ['vehicleReadWarehouseInformation'],
  });

  if (!props.isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
    return buildArray<EntityHeaderControl>(['SERVICE_BRANCH', 'ASSIGNEE', 'VH_CUSTOM_ID']);
  }

  return buildArray<EntityHeaderControl>([
    'ASSIGNEE',
    isVehicleWarehouseEnabled ? 'VEHICLE_LOCATION' : 'LOCATION',
  ])
    .when(isNotNil(props.saleVehicle), 'PARKING')
    .when(isNotNil(props.saleVehicle), 'KEY')
    .when(
      isVehicleWarehouseEnabled &&
        isNotNil(props.saleVehicle) &&
        hasVehicleReadWarehouseInfoPermission,
      'VEHICLE_WAREHOUSE'
    )
    .add('VH_CUSTOM_ID');
}
