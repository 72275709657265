import {
  Button,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';
import {object, SchemaOf, boolean, string} from 'yup';

import {DefaultValues} from 'react-hook-form';

import i18n from '@dms/i18n';

interface VehicleFinancialInstitutionFormType {
  isFinanced: boolean;
  institution: string | null;
}

export interface VehicleFinancialInstitutionFormProps {
  onSubmit: FormSubmitHandler<VehicleFinancialInstitutionFormType>;
  defaultValues: DefaultValues<VehicleFinancialInstitutionFormType>;
}

const schema: SchemaOf<VehicleFinancialInstitutionFormType> = object({
  isFinanced: boolean().required(),
  institution: string().default(null).when('isFinanced', {
    is: true,
    then: string().nullable().required(),
  }),
});

export function VehicleFinancialInstitutionForm(props: VehicleFinancialInstitutionFormProps) {
  return (
    <Form<VehicleFinancialInstitutionFormType>
      onSubmit={props.onSubmit}
      defaultValues={props.defaultValues}
      schema={schema}
    >
      {(control, formApi) => {
        const isFinanced = formApi.watch('isFinanced');

        return (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="isFinanced"
              label={i18n.t('entity.vehicleFinancialInstitution.labels.financed')}
              type="checkbox"
            />
            <FormField
              control={control}
              name="institution"
              type="text"
              label={i18n.t('entity.vehicleFinancialInstitution.labels.institution')}
              isRequired={isFinanced}
              isDisabled={!isFinanced}
            />
            <HStack spacing={2} justify="flex-end">
              <Button
                onClick={closeCurrentDialog}
                isDisabled={formApi.formState.isSubmitting}
                title={i18n.t('general.actions.discard')}
                variant="secondary"
              />
              <FormButton
                type="submit"
                control={control}
                title={i18n.t('general.actions.save')}
                isDisabled={formApi.formState.isSubmitting}
                isLoading={formApi.formState.isSubmitting}
              />
            </HStack>
          </VStack>
        );
      }}
    </Form>
  );
}
