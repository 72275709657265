import {getIn} from 'final-form';

import {FC, useRef, useEffect} from 'react';
import {Field as FinalFormField} from 'react-final-form';

import {Money, VatTypeEnum} from '@dms/api';

import {suffixTestId} from 'shared';

import {PriceValue} from '../../types/PriceValue';
import {useFormRenderer} from '../FinalForm/hooks/useFormRenderer';
import {MoneyField} from '../MoneyField/MoneyField';
import {PriceFieldProps} from './PriceFieldProps';
import {isPriceFromWithVat} from './utils/isPriceFromWithVat';

/**
 * @deprecated - use platform instead
 */
export const PriceField: FC<PriceFieldProps> = ({
  name,
  label,
  commonFieldProps = {},
  withVat,
  vatType,
  getIsValid,
  getErrorMessage,
  countryCode,
  onChange,
  helperText,
  ...props
}) => {
  const {form} = useFormRenderer<Record<string, unknown>>();
  const ref = useRef<VatTypeEnum | undefined>(vatType);

  useEffect(() => {
    const values = form.getState()?.values;
    const value = getIn(values, name) as PriceValue;
    if (ref.current === vatType) {
      return;
    }

    ref.current = vatType;

    if (Boolean(withVat) === isPriceFromWithVat(value)) {
      onChange?.(
        isPriceFromWithVat(value) ? value?.withVat?.amount : value?.withoutVat?.amount,
        isPriceFromWithVat(value),
        vatType || VatTypeEnum.S,
        countryCode
      );
    }
  }, [vatType]);

  return (
    <FinalFormField name={`${name}.fromWithVat`}>
      {({input: {onChange: changeFromWithVat}}) => {
        const handleChange = (value: Money) => {
          changeFromWithVat(Boolean(withVat));
          onChange?.(value.amount, Boolean(withVat), vatType || VatTypeEnum.S, countryCode);
        };

        return (
          <MoneyField
            name={`${name}.${withVat ? 'withVat' : 'withoutVat'}`}
            label={label}
            commonFieldProps={{
              ...commonFieldProps,
              'data-testid': suffixTestId(withVat ? 'withVat' : 'withoutVat', props),
            }}
            getErrorMessage={getErrorMessage}
            getIsValid={getIsValid}
            onChange={handleChange}
            helperText={helperText}
            data-testid={suffixTestId(withVat ? 'withVat' : 'withoutVat', props)}
          />
        );
      }}
    </FinalFormField>
  );
};
