import {isFeatureEnabled} from 'feature-flags';
import {showNotification} from 'platform/components';
import {Icon, Show} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';

import {isNotNil} from 'ramda';

import {
  useGetCebiaAutotracerAndReportConfigQuery,
  useGetCebiaFeatureDecoderConfigQuery,
  useGetCebiaRokvyConfigQuery,
  useGetCebiaVinDecoderConfigQuery,
  useGetOmneticVinDecoderConfigQuery,
  usePatchCebiaAutotracerAndReportConfigMutation,
  usePatchCebiaFeatureDecoderConfigMutation,
  usePatchCebiaRokvyConfigMutation,
  usePatchCebiaVinDecoderConfigMutation,
  usePatchOmneticVinDecoderConfigMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {buildArray} from 'shared';

import {StackItem, Wrapper} from '../../../components/SettingsStackItems/SettingsStackItems';
import {VehicleServiceContentItem} from './VehicleServiceContentItem';

export function VehicleServicesList() {
  const {data: cebiaAutotracerAndReport} = useGetCebiaAutotracerAndReportConfigQuery();
  const {data: cebiaFeatureDecoder} = useGetCebiaFeatureDecoderConfigQuery();
  const {data: cebiaRokvy} = useGetCebiaRokvyConfigQuery();
  const {data: omneticVinDecoder} = useGetOmneticVinDecoderConfigQuery();
  const {data: cebiaVinDecoder} = useGetCebiaVinDecoderConfigQuery();
  const [patchCebiaAutotracerAndReportConfig] = usePatchCebiaAutotracerAndReportConfigMutation();
  const [patchCebiaFeatureDecoderConfig] = usePatchCebiaFeatureDecoderConfigMutation();
  const [patchCebiaRokvyConfig] = usePatchCebiaRokvyConfigMutation();
  const [patchOmneticVinDecoder] = usePatchOmneticVinDecoderConfigMutation();
  const [patchCebiaVinDecoder] = usePatchCebiaVinDecoderConfigMutation();

  const updateAutotracerAndReport = (enabled: boolean) => {
    patchCebiaAutotracerAndReportConfig({
      cebiaAutotracerAndReportConfigRequestBody: {enabled},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const updateCebiaVinDecoder = (enabled: boolean) => {
    if (enabled) {
      patchOmneticVinDecoder({body: {enabled: false}});
    }

    patchCebiaVinDecoder({
      body: {enabled},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const updateCebiaFeatureDecoder = (enabled: boolean) => {
    patchCebiaFeatureDecoderConfig({
      cebiaFeatureDecoderConfigRequestBody: {enabled},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const updateCebiaRokvy = (enabled: boolean) => {
    patchCebiaRokvyConfig({
      cebiaRokvyConfigRequestBody: {enabled},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const updateOmneticVinDecoder = (enabled: boolean) => {
    if (!isFeatureEnabled(featureFlags.OMNETIC_VIN_DECODER)) {
      throw new Error('Feature flag OMNETIC_VIN_DECODER is not enabled!');
    }

    if (enabled) {
      patchCebiaVinDecoder({body: {enabled: false}});
    }

    patchOmneticVinDecoder({
      body: {enabled},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const items = buildArray<{title: ReactNode; url?: string}>([
    {
      title: (
        <VehicleServiceContentItem
          switcherName="cebia-equipment-autotrace"
          isDisabled={!cebiaAutotracerAndReport?.credentials}
          switcherOn={cebiaAutotracerAndReport?.enabled}
          onSwitcherToggle={updateAutotracerAndReport}
          title={i18n.t('page.integrations.labels.cebia')}
          label={i18n.t('page.integrations.labels.cebiaAutotraceDescription')}
        />
      ),
      url: settingsRoutes.vehicleServicesCebia,
    },
    {
      title: (
        <VehicleServiceContentItem
          switcherName="cebia-vin-decoder"
          switcherOn={cebiaVinDecoder?.enabled}
          onSwitcherToggle={updateCebiaVinDecoder}
          title={i18n.t('page.integrations.labels.cebiaVinDecoder')}
          label={i18n.t('page.integrations.labels.cebiaVinDecoderSubtext')}
        />
      ),
    },
    {
      title: (
        <VehicleServiceContentItem
          switcherName="cebia-equipment-switcher"
          isDisabled={!cebiaFeatureDecoder?.credentials}
          switcherOn={cebiaFeatureDecoder?.enabled}
          onSwitcherToggle={updateCebiaFeatureDecoder}
          title={i18n.t('page.integrations.labels.cebiaEquipment')}
          label={i18n.t('page.integrations.labels.cebiaEquipmentSubtext')}
        />
      ),
      url: settingsRoutes.vehicleServicesFeatures,
    },
    {
      title: (
        <VehicleServiceContentItem
          switcherName="cebia-rokvy-switcher"
          isDisabled={!cebiaRokvy?.credentials}
          switcherOn={cebiaRokvy?.enabled}
          onSwitcherToggle={updateCebiaRokvy}
          title={i18n.t('page.integrations.labels.cebiaRokvy')}
          label={i18n.t('page.integrations.labels.cebiaRokvyDescription')}
        />
      ),
      url: settingsRoutes.vehicleServicesRokVy,
    },
  ]).whenFeatureEnabled(featureFlags.OMNETIC_VIN_DECODER, {
    title: (
      <VehicleServiceContentItem
        switcherName="omnetic-vin-decoder"
        switcherOn={omneticVinDecoder?.enabled}
        onSwitcherToggle={updateOmneticVinDecoder}
        title={i18n.t('page.integrations.labels.omneticVinDecoder')}
        label={i18n.t('page.integrations.labels.omneticVinDecoderDescription')}
      />
    ),
  });

  return (
    <Wrapper>
      {items.map(({title, url}, index) => (
        <VehicleServicesStackItemInner
          key={url}
          to={url}
          data-testid={testIds.settings.vehicleServices(`link-${index}`)}
        >
          {title}
          <Show when={isNotNil(url)}>
            <Icon value="navigation/chevron_right" />
          </Show>
        </VehicleServicesStackItemInner>
      ))}
    </Wrapper>
  );
}

const VehicleServicesStackItemInner = styled(StackItem)`
  align-items: start;
`;
