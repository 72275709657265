import {Tooltip} from 'platform/components';
import {Box, Icon} from 'platform/foundation';

import i18n from '@dms/i18n';

type MatchingIconProps = {
  isFullMatch: boolean;
};

export const MatchingIcon = ({isFullMatch}: MatchingIconProps) => {
  const backgroundColor = isFullMatch ? 'palettes.green.20.100' : 'palettes.orange.20.100';
  const iconColor = isFullMatch ? 'palettes.green.70.100' : 'palettes.orange.70.100';
  const icon = isFullMatch ? 'action/check_circle' : 'alert/error';

  return (
    <Tooltip
      placement="top"
      label={
        isFullMatch
          ? i18n.t('entity.customer.labels.fullMatchTooltip')
          : i18n.t('entity.customer.labels.errorMatchTooltip')
      }
    >
      <Box borderRadius="circular" padding={2} backgroundColor={backgroundColor}>
        <Icon color={iconColor} value={icon} />
      </Box>
    </Tooltip>
  );
};
