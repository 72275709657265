import {Checkbox} from 'platform/components';

import {FC, ReactElement} from 'react';

import {useFormRenderer} from '@dms/teas';

import {VISIBLE_TYPE, MANDATORY_TYPE} from '../constants';
import {useConfirmationDialog} from '../hooks/useConfirmationDialog';
import {AuditSettingsOptionType} from '../types';

type AuditSettingsOptionsCheckBoxProps = {
  paramDefinitionId: string;
  label: string;
  type: AuditSettingsOptionType;
  onChange: (isChecked: boolean) => void;
  hasChildren: boolean;
  isDisabled?: boolean;
};

export const AuditSettingsOptionsCheckBox: FC<AuditSettingsOptionsCheckBoxProps> = ({
  paramDefinitionId,
  label,
  type,
  onChange,
  hasChildren,
  isDisabled,
}) => {
  const {
    Field,
    Subscribe,
    form: {
      mutators: {setFieldValue},
    },
  } = useFormRenderer();
  const {getConfirmation} = useConfirmationDialog();

  const isVisibleType = type === VISIBLE_TYPE;

  const renderField = (isDisabled?: boolean): ReactElement => (
    <Field
      name={`paramDefinitions.${paramDefinitionId}.${type}`}
      component={({input}) => {
        const isChecked = (() => {
          if (input.value === undefined || input.value === '') {
            return isVisibleType;
          }

          return Boolean(input.value);
        })();

        const handleChange = async (): Promise<void> => {
          const _isChecked = !isChecked;
          if (!_isChecked) {
            if (hasChildren && isVisibleType) {
              await getConfirmation();
            }

            if (isVisibleType) {
              setFieldValue(`paramDefinitions.${paramDefinitionId}.${MANDATORY_TYPE}`, false);
            }
          }

          if (!isVisibleType && _isChecked) {
            setFieldValue(`paramDefinitions.${paramDefinitionId}.${VISIBLE_TYPE}`, true);
          }

          input.onChange(_isChecked);
          onChange(_isChecked);
        };

        return (
          <Checkbox
            label={label}
            value={isChecked}
            onChange={handleChange}
            isDisabled={isDisabled}
          />
        );
      }}
    />
  );

  if (isVisibleType) {
    return renderField(isDisabled);
  }

  return (
    <Subscribe
      name={`paramDefinitions.${paramDefinitionId}.${VISIBLE_TYPE}`}
      component={({input}) => renderField(input.value === false)}
    />
  );
};
