import {Flag} from 'platform/components';

import {useMemo} from 'react';

import {BusinessCaseType} from '@dms/api';

import {suffixTestId, TestIdProps} from 'shared';

import {getBusinessCaseTypeFlag} from '../../utils/getBusinessCaseTypeFlag';

export type BusinessCaseTypeFlagProps = {
  type: BusinessCaseType;
} & TestIdProps;

function BusinessCaseTypeFlag(props: BusinessCaseTypeFlagProps) {
  const flagParams = useMemo(() => getBusinessCaseTypeFlag(props.type), [props.type]);

  if (!flagParams) {
    return null;
  }

  return (
    <Flag
      key={props.type}
      size="small"
      colorScheme={flagParams.colorScheme}
      label={flagParams.label}
      isSubtle
      data-testid={suffixTestId('businessCaseType', props)}
    />
  );
}

export {BusinessCaseTypeFlag};
