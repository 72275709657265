import {Label} from 'platform/components';
import {Box, Grid, Space} from 'platform/foundation';
import styled from 'styled-components';

import {ReactElement, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isEmpty} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {selectUserId, selectUserInfo, fetchFullRoleRequest, TypographyCard} from '@dms/teas';

import {ListComponent} from './List';
import {NameField} from './NameField';
import {PhoneField, Props} from './PhoneField';

export const Profile = (): ReactElement => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId) as string;
  const user = useSelector(selectUserInfo);
  const {firstName, lastName, email, phoneNumber, roles} = user || {};

  useEffect(() => {
    if (isEmpty(roles)) {
      dispatch(fetchFullRoleRequest);
    }
  }, [dispatch, userId, user, roles]);

  return (
    <ProfileCard
      data-testid={testIds.myProfile.personalInformation('wrapper')}
      title={i18n.t('entity.user.labels.personalInformation')}
    >
      <Grid columns={2} spacing={4}>
        <NameField
          fieldName="firstName"
          required
          label={i18n.t('entity.person.labels.firstName')}
          value={firstName ?? ''}
        />
        <NameField
          fieldName="lastName"
          required
          label={i18n.t('entity.person.labels.lastName')}
          value={lastName ?? ''}
        />
        <NameField
          disabled
          fieldName="email"
          label={i18n.t('general.labels.emailAddress')}
          value={email ?? ''}
        />
        <Box>
          <Label>{i18n.t('entity.person.labels.phoneNumber')}</Label>
          <PhoneField fieldName="phoneNumber" value={phoneNumber as Props['value']} />
        </Box>
      </Grid>
      <Space vertical={4} />
      <ListComponent />
    </ProfileCard>
  );
};

const ProfileCard = styled(TypographyCard)`
  width: 583px;
  margin-bottom: ${({theme}) => theme.getSize(4)};
`;
