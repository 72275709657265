import {ReactNode} from 'react';

import {useGetCurrentUserInfoQuery, useGetRolesQuery} from '@dms/api';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';

import {CI_MODE, createContext, useBoolean} from 'shared';

import {DEBUG_ROLE_REFIX} from '../utils/const';

type DeveloperSettingsContext = {
  settings: {
    isRoleDebuggingEnabled: boolean;
    isTranslationsSettingsEnabled: boolean;
    isContentEditableSettingsEnabled: boolean;
    isMockingFlagsSettingsEnabled: boolean;
    apiTrafficValidation: {
      isValidating: boolean;
      start: () => void;
      stop: () => void;
    };
  };
  isAnyDevSettingEnabled: boolean;
  isOpen: boolean;
  close: () => void;
  open: () => void;
};

const [Provider, useDeveloperSettings] = createContext<DeveloperSettingsContext>({
  name: 'developerSettings',
});

interface DeveloperSettingsProviderProps {
  children: ReactNode;
}

export function DeveloperSettingsProvider(props: DeveloperSettingsProviderProps) {
  const [isOpen, open, close] = useBoolean();
  const [isValidatingApiTraffic, startApiTrafficValidation, stopApiTrafficValidation] =
    useBoolean();

  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const {data: roles} = useGetRolesQuery({});

  const isMockingFlagsSettingsEnabled =
    localStorage.getItem(browserStorageKey.MOCK_FLAGS_KEY) !== null;
  const isContentEditableSettingsEnabled = document.body.contentEditable === 'true';
  const isTranslationsSettingsEnabled = i18n.language === CI_MODE;
  const isRoleDebuggingEnabled =
    roles?.some((role) => role.title === `${DEBUG_ROLE_REFIX}-${currentUser?.id}`) ?? false;

  const isAnyDevSettingEnabled =
    isRoleDebuggingEnabled ||
    isTranslationsSettingsEnabled ||
    isContentEditableSettingsEnabled ||
    isMockingFlagsSettingsEnabled;

  const settings: DeveloperSettingsContext['settings'] = {
    isMockingFlagsSettingsEnabled,
    isContentEditableSettingsEnabled,
    isTranslationsSettingsEnabled,
    isRoleDebuggingEnabled,
    apiTrafficValidation: {
      isValidating: isValidatingApiTraffic,
      start: startApiTrafficValidation,
      stop: stopApiTrafficValidation,
    },
  };

  return (
    <Provider value={{isOpen, close, open, isAnyDevSettingEnabled, settings}}>
      {props.children}
    </Provider>
  );
}

export {useDeveloperSettings};
