import {isFeatureEnabled} from 'feature-flags';
import {Card, FormControl, FormField, isCurrency} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNotNil, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {useGetTenantQuery, useLazyFindConversionRateQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {handleApiError, useCurrencies, useGetCurrentBranch} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useDebouncedCallback, useQueryState} from 'shared';

import {invoicePaymentOptions} from '../constants';
import {AccountingDocumentFormValues} from '../types';
import {bankAccountToOption} from '../utils/bankAccountToOption';
import {useRecalculateInvoiceItems} from './listOfItems/hooks/useRecalculateInvoiceItems';

interface CreateInvoicePaymentDetailsProps extends RequiredTestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  isCorrectiveTaxDocument?: boolean;
}

export function PaymentDetails(props: CreateInvoicePaymentDetailsProps) {
  const {currencyOptions} = useCurrencies();
  const [findConversionRate, {isLoading}] = useLazyFindConversionRateQuery();
  const [checkoutId] = useQueryState('checkoutId');
  const isCreatingCorrectiveTaxDocumentForBC = isNotNil(checkoutId);

  const {data: branch} = useGetCurrentBranch();
  const {data: tenant} = useGetTenantQuery();

  const [
    isForeignCurrencyCardOpen,
    paymentMethod,
    foreignCurrency,
    dateOfTaxableSupply,
    foreignRatio,
  ] = useWatch({
    control: props.control,
    name: [
      'isExchangeRateRatioEnabled',
      'paymentInfo.paymentMethod',
      'exchangeRateRatio.currency',
      'dateOfTaxableSupply',
      'exchangeRateRatio.ratio',
    ],
  });

  const [recalculateItems] = useRecalculateInvoiceItems({
    control: props.control,
    formApi: props.formApi,
    listItemType: 'invoices',
  });

  const isPaymentBankTransferOrOffset =
    paymentMethod === 'BANK_TRANSFER' || paymentMethod === 'OFFSET';

  const handleCardOnChange = (val: boolean) => {
    props.formApi.setValue('isExchangeRateRatioEnabled', val);
    recalculateItems();
  };

  const currency = isNotNil(tenant) && isCurrency(tenant?.currency) ? tenant.currency : undefined;
  const branchAccountsOptions = branch?.billingInformation?.bankAccounts.map(bankAccountToOption);
  const shouldFetchConversionRateForCTD =
    props.isCorrectiveTaxDocument && isNotNilOrEmpty(foreignRatio);

  useEffect(() => {
    if (
      !isString(foreignCurrency) ||
      !currency ||
      !isFeatureEnabled(featureFlags.SALES_FOREIGN_CURRENCY) ||
      !isForeignCurrencyCardOpen ||
      shouldFetchConversionRateForCTD
    ) {
      return;
    }

    findConversionRate({
      domesticCurrency: currency,
      foreignCurrency,
      validOn: dateOfTaxableSupply,
    })
      .unwrap()
      .then((rate) => {
        const amount = rate?.amount ?? 1;
        const ratio = rate?.ratio ?? '';

        props.formApi.setValue('exchangeRateRatio.amount', amount.toString());
        props.formApi.setValue('exchangeRateRatio.ratio', ratio);
      })
      .catch(handleApiError);
  }, [
    isForeignCurrencyCardOpen,
    props.isCorrectiveTaxDocument,
    foreignCurrency,
    dateOfTaxableSupply,
    currency,
    findConversionRate,
    props.formApi,
  ]);

  const debouncedRecalculateItems = useDebouncedCallback(recalculateItems, 200);

  const paymentTypeOptions = invoicePaymentOptions.filter(
    ({value}) => !(isCreatingCorrectiveTaxDocumentForBC && value === 'FREE_OF_CHARGE')
  );

  return (
    <Card
      title={i18n.t('entity.invoice.labels.paymentDetails')}
      data-testid={suffixTestId('section', props)}
    >
      <VStack spacing={4}>
        <Grid columns={4}>
          <FormField
            name="paymentInfo.paymentMethod"
            control={props.control}
            type="choice"
            label={i18n.t('entity.accounting.labels.paymentMethod')}
            options={paymentTypeOptions}
            onChange={() => debouncedRecalculateItems()}
            isNotClearable
            isRequired
            data-testid={suffixTestId('paymentMethod', props)}
          />
          <FormField
            name="paymentInfo.bankAccount"
            control={props.control}
            type="choice"
            label={i18n.t('general.labels.bankAccount')}
            options={branchAccountsOptions}
            isNotClearable
            isRequired={isPaymentBankTransferOrOffset}
            data-testid={suffixTestId('bankAccount', props)}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            name="paymentInfo.variableSymbol"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.variableSymbol')}
            maxLength={10}
            data-testid={suffixTestId('variableSymbol', props)}
          />
          <FormField
            name="paymentInfo.constantSymbol"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.constantSymbol')}
            maxLength={4}
            data-testid={suffixTestId('constantSymbol', props)}
          />
          <FormField
            name="paymentInfo.specificSymbol"
            control={props.control}
            type="text"
            label={i18n.t('entity.accounting.labels.specificSymbol')}
            maxLength={10}
            data-testid={suffixTestId('specificSymbol', props)}
          />
        </Grid>
        <Card
          variant="inlineGrey"
          isExpandable
          isClosedByDefault={!isForeignCurrencyCardOpen}
          title={i18n.t('entity.invoice.labels.additionalPaymentInformation')}
          data-testid={suffixTestId('additional-information', props)}
        >
          <VStack spacing={4}>
            <Grid columns={4}>
              <FormField
                name="paymentInfo.iban"
                control={props.control}
                type="text"
                label={i18n.t('entity.bank.labels.iban')}
                data-testid={suffixTestId('iban', props)}
              />
              <FormField
                name="paymentInfo.swift"
                control={props.control}
                type="text"
                label={i18n.t('entity.bank.labels.swift')}
                data-testid={suffixTestId('swift', props)}
              />
            </Grid>
            <Show whenFeatureEnabled={featureFlags.SALES_FOREIGN_CURRENCY}>
              <Card
                variant="inlineGrey"
                control={{
                  type: 'switch',
                  value: isForeignCurrencyCardOpen,
                  onChange: handleCardOnChange,
                }}
                data-testid={suffixTestId('foreignCurrency', props)}
                title={i18n.t('general.labels.foreignCurrency')}
                isExpanded={isForeignCurrencyCardOpen}
              >
                <Grid columns={4}>
                  <FormField
                    name="exchangeRateRatio.currency"
                    control={props.control}
                    type="choice"
                    isNotClearable
                    isDisabled={isLoading}
                    onChange={() => debouncedRecalculateItems()}
                    options={currencyOptions?.filter((item) => item.value !== tenant?.currency)}
                    label={i18n.t('general.labels.currency')}
                    data-testid={suffixTestId('currency', props)}
                  />
                  <FormField
                    name="exchangeRateRatio.amount"
                    control={props.control}
                    type="number"
                    label={i18n.t('general.labels.amount')}
                    data-testid={suffixTestId('exchangeRateRatio.amount', props)}
                    minStepperValue={0}
                    isDisabled
                  />
                  <FormField
                    name="exchangeRateRatio.ratio"
                    control={props.control}
                    type="currency"
                    onChange={() => debouncedRecalculateItems()}
                    label={i18n.t('general.labels.exchangeRate')}
                    currency={currency}
                    isDisabled={isLoading}
                    data-testid={suffixTestId('exchange-ratio', props)}
                  />
                  <FormField
                    name="exchangeRateRatio.type"
                    control={props.control}
                    type="text"
                    label={i18n.t('general.labels.accountingType')}
                    data-testid={suffixTestId('exchangeRateRatio.type', props)}
                    isDisabled
                  />
                </Grid>
              </Card>
            </Show>
          </VStack>
        </Card>
      </VStack>
    </Card>
  );
}
