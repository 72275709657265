import {Button, DataStatus, Separator, showNotification} from 'platform/components';
import {Box, Heading, Show, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useNavigate} from 'react-router-dom';

import {
  useDeleteTireWarehouseMutation,
  useGetTireWarehouseSettingsQuery,
  usePostWarehouseEnablingSetAsAllowMutation,
  usePostWarehouseEnablingSetAsDisallowMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function TireWarehouses() {
  const navigate = useNavigate();

  const tireWarehousesQuery = useGetTireWarehouseSettingsQuery();
  const [deleteTireWarehouse] = useDeleteTireWarehouseMutation();

  const [postWarehouseEnablingSetAsAllow, postWarehouseEnablingSetAsAllowStatus] =
    usePostWarehouseEnablingSetAsAllowMutation();

  const [postWarehouseEnablingSetAsDisallow, postWarehouseEnablingSetAsDisallowStatus] =
    usePostWarehouseEnablingSetAsDisallowMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = Array.isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.tireWarehousesDetail, {params: {id}}))
      )
      .with('delete', () =>
        deleteTireWarehouse({warehouseId: id})
          .unwrap()
          .then(() => {
            showNotification.success();
            refreshData();
          })
          .catch(handleApiError)
      )
      .otherwise(() => showNotification.error(`Action ${actionKey} is not implemented`));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.tireWarehouses.labels.title'),
        actions: tireWarehousesQuery.data?.isWarehouseAllowed
          ? [
              {
                type: 'button',
                title: i18n.t('entity.tireWarehouses.labels.create'),
                to: {pathname: settingsRoutes.tireWarehousesCreate},
              },
            ]
          : [],
      }}
      data-testid={testIds.settings.tireWarehouses('template')}
    >
      <VStack spacing={6} height="100%">
        <VStack spacing={2}>
          <Heading size={4}>{i18n.t('entity.tireWarehouses.labels.heading')}</Heading>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.tireWarehouses.labels.description')}
          </Text>
        </VStack>
        <DataStatus
          isLoading={tireWarehousesQuery.isLoading || tireWarehousesQuery.isFetching}
          isError={tireWarehousesQuery.isError}
        >
          <Box>
            <Show when={tireWarehousesQuery.data?.isWarehouseAllowed}>
              <Button
                title={i18n.t('entity.tireWarehouses.labels.disable')}
                variant="secondary"
                isLoading={postWarehouseEnablingSetAsDisallowStatus.isLoading}
                onClick={() =>
                  postWarehouseEnablingSetAsDisallow()
                    .unwrap()
                    .then(() => showNotification.success())
                    .catch(handleApiError)
                }
              />
            </Show>
            <Show when={!tireWarehousesQuery.data?.isWarehouseAllowed}>
              <Button
                title={i18n.t('entity.tireWarehouses.labels.enable')}
                isLoading={postWarehouseEnablingSetAsAllowStatus.isLoading}
                onClick={() =>
                  postWarehouseEnablingSetAsAllow()
                    .unwrap()
                    .then(() => showNotification.success())
                    .catch(handleApiError)
                }
              />
            </Show>
          </Box>
          <Show when={!!tireWarehousesQuery.data?.isWarehouseAllowed}>
            <Separator />
            <Box height="100%">
              <DataGrid gridCode="tire-warehouse" actionCallback={actionCallback} />
            </Box>
          </Show>
        </DataStatus>
      </VStack>
    </SettingsTemplate>
  );
}
