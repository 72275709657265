import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {accountingRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {AccountingOverview} from './pages/AccountingOverview/AccountingOverview';
import {BalanceInvoiceDetail} from './pages/BalanceInvoiceDetail/BalanceInvoiceDetail';
import {CashReceiptDetail} from './pages/CashReceiptDetail/CashReceiptDetail';
import {CorrectiveTaxDocumentDetail} from './pages/CorrectiveTaxDocumentDetail/CorrectiveTaxDocumentDetail';
import {CreateCorrectiveTaxDocument} from './pages/CreateCorrectiveTaxDocument/CreateCorrectiveTaxDocument';
import {CreateExpenseCashReceipt} from './pages/CreateExpenseCashReceipt/CreateExpenseCashReceipt';
import {CreateIncomeCashReceipt} from './pages/CreateIncomeCashReceipt/CreateIncomeCashReceipt';
import {CreateInvoice} from './pages/CreateInvoice/CreateInvoice';
import {InvoiceDetail} from './pages/InvoiceDetail/InvoiceDetail';
import {ProformaInvoiceDetail} from './pages/ProformaInvoiceDetail/ProformaInvoiceDetail';
import {TaxDocumentForPaymentDetail} from './pages/TaxDocumentForPaymentDetail/TaxDocumentForPaymentDetail';

export const AccountingModule: Module = {
  id: moduleKeys.accounting,
  alwaysShowContent: true,
  layoutProps: {
    title: 'module.accounting.title',
    icon: 'custom/invoice',
    iconV2: 'sidebar/point_of_sale',
  },
  routerProps: {
    path: accountingRoutes.accountingOverview,
    element: <AccountingOverview />,
  },
  requiredPermission: permissions.readInvoice,
  requiredFeatureFlag: featureFlags.ACCOUNTING,
  content: [
    {
      id: 'invoice-overview',
      routerProps: {
        path: accountingRoutes.invoiceOverview,
        element: <AccountingOverview />,
      },
      layoutProps: {
        title: 'page.accounting.invoiceList.title',
        icon: 'sidebar/invoice',
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'accounting-cashReceipt',
      routerProps: {
        path: accountingRoutes.cashReceiptsOverview,
        element: <AccountingOverview />,
      },
      layoutProps: {
        title: 'page.accounting.cashRegister.title',
        icon: 'sidebar/paid',
      },
      requiredPermission: permissions.readCashRegisterDocument,
    },
    {
      id: 'invoice-new',
      routerProps: {
        path: accountingRoutes.invoiceNew,
        element: <CreateInvoice />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'incomeCashReceipt-new',
      routerProps: {
        path: accountingRoutes.createCashReceiptIncome,
        element: <CreateIncomeCashReceipt />,
      },
      requiredPermission: permissions.readCashRegisterDocument,
    },
    {
      id: 'expenseCashReceipt-new',
      routerProps: {
        path: accountingRoutes.createCashReceiptExpense,
        element: <CreateExpenseCashReceipt />,
      },
      requiredPermission: permissions.readCashRegisterDocument,
    },
    {
      id: 'CashReceipt-detail',
      routerProps: {
        path: accountingRoutes.cashReceiptDetail,
        element: <CashReceiptDetail />,
      },
      requiredPermission: permissions.readCashRegisterDocument,
    },
    {
      id: 'invoice-draft',
      routerProps: {
        path: accountingRoutes.invoiceDraft,
        element: <CreateInvoice />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'invoice-detail',
      routerProps: {
        path: accountingRoutes.invoiceDetail,
        element: <InvoiceDetail />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'balance-invoice-detail',
      routerProps: {
        path: accountingRoutes.balanceInvoiceDetail,
        element: <BalanceInvoiceDetail />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'tax-document-for-payment-detail',
      routerProps: {
        path: accountingRoutes.taxDocumentForPaymentDetail,
        element: <TaxDocumentForPaymentDetail />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'corrective-tax-document-detail',
      routerProps: {
        path: accountingRoutes.correctiveTaxDocumentDetail,
        element: <CorrectiveTaxDocumentDetail />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'proforma-invoice-detail',
      routerProps: {
        path: accountingRoutes.proformaInvoiceDetail,
        element: <ProformaInvoiceDetail />,
      },
      requiredPermission: permissions.readInvoice,
    },
    {
      id: 'create-corrective-tax-document',
      routerProps: {
        path: accountingRoutes.newCorrectiveTaxDocument,
        element: <CreateCorrectiveTaxDocument />,
      },
      requiredPermission: permissions.readInvoice,
    },
  ],
};
