import {showNotification} from 'platform/components';

import {useNavigate} from 'react-router-dom';

import {usePostTireWarehouseMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath} from 'shared';

import {SettingsTemplate} from '../../../../components/SettingsTemplate/SettingsTemplate';
import {GeneralTab} from '../../components/GeneralTab';
import {TireWarehouseForm} from '../../types/TireWarehouseForm';

export function Create() {
  const [postTireWarehouse, postTireWarehouseStatus] = usePostTireWarehouseMutation();
  const navigate = useNavigate();

  const createTireWarehouse = (data: TireWarehouseForm) => {
    postTireWarehouse({body: data})
      .unwrap()
      .then((res) => {
        showNotification.success();

        if (res?.warehouseId) {
          navigate(
            composePath(settingsRoutes.tireWarehousesDetail, {params: {id: res.warehouseId}})
          );
        }
      })
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs: [
          {
            label: i18n.t('entity.tireWarehouses.labels.title'),
            href: settingsRoutes.tireWarehouses,
          },
        ],
        title: i18n.t('entity.tireWarehousesCreate.labels.title'),
      }}
      tabs={[
        {
          queryId: 'general',
          title: i18n.t('general.labels.general'),
          content: (
            <GeneralTab
              onSubmit={createTireWarehouse}
              isLoading={postTireWarehouseStatus.isLoading}
            />
          ),
        },
        {
          queryId: 'location',
          title: i18n.t('entity.tireWarehouses.labels.storageLocations'),
          content: null,
          isDisabled: true,
        },
      ]}
      isCreating
      data-testid={testIds.settings.tireWarehousesCreate('template')}
    />
  );
}
