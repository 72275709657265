import {isFeatureEnabled} from 'feature-flags';
import {Action, Card, closeDialog, openDialog} from 'platform/components';

import {mergeAll} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetTireIdsQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, tiresInventoryRoutes} from '@dms/routes';

import {buildArray, composePath} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {EditTireSet} from '../EditTireSet/EditTireSet';
import {Section} from '../Sections/components/Section';

export interface TiresProps {
  vehicleId?: string;
  customerId?: string;
  gridCode:
    | 'tire-order-wheel-service-case'
    | 'tire-order-wheel-service-case-creating'
    | 'vehicle-tire-list'
    | 'customer-tire-list';
}

type ReceiveJobItemRowData = {
  orderId: {value: string};
  orderSetId: {value: string};
  setNumber?: {value?: string};
  tireManufacturer?: {value?: {label?: string}};
  tireSeason?: {value?: {label?: string}};
};

export function Tires(props: TiresProps) {
  const tireOrderIdsQuery = useGetTireIdsQuery({
    customerId: props.customerId,
    vehicleId: props.vehicleId,
  });

  const firstTireOrder = tireOrderIdsQuery.data?.[0];
  const hasTireOrder = isNotNilOrEmpty(firstTireOrder);

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([
      filter,
      {
        vehicleId: props.vehicleId ? props.vehicleId : undefined,
        customerId: props.customerId ? props.customerId : undefined,
      },
    ]);

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData}) => {
    const castedRowData = rowData as ReceiveJobItemRowData;

    if (actionKey === 'redirectDetail') {
      const titleData = [
        castedRowData?.tireManufacturer?.value?.label,
        castedRowData?.tireSeason?.value?.label,
      ].filter(isNotNilOrEmpty);

      openDialog(
        <EditTireSet
          tireOrderId={castedRowData?.orderId?.value}
          setId={castedRowData?.orderSetId?.value}
          onClose={() => closeDialog('editTireSet')}
          onEdit={refreshData}
          data-testid={testIds.tiresInventory.tireOrderDetail('tire-set-dialog')}
        />,
        {
          id: 'editTireSet',
          title: `${castedRowData?.setNumber?.value} - ${titleData.join(', ')}`,
          size: 'large',
        }
      );
    }
  };

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('tires')}>
      <Card
        title={i18n.t('page.tiresInventory.labels.tires')}
        actions={buildArray<Action>()
          .whenFeatureNotEnabled(featureFlags.PNEUHOTEL_PROCESS_FLOW, {
            type: 'button',
            variant: 'link',
            leftIcon: 'action/open_in_new',
            title: i18n.t('page.tiresInventory.labels.tiresInventory'),
            onClick: () => window.open(tiresInventoryRoutes.overview, '_blank'),
          })
          .when(isFeatureEnabled(featureFlags.PNEUHOTEL_PROCESS_FLOW) && hasTireOrder, {
            type: 'button',
            variant: 'link',
            leftIcon: 'action/open_in_new',
            title: i18n.t('page.tiresInventory.labels.tiresInventory'),
            onClick: () => {
              window.open(
                composePath(tiresInventoryRoutes.tireOrderDetail, {
                  params: {id: tireOrderIdsQuery.data?.[0]?.orderId},
                  isSearchParamsPreserved: false,
                }),
                '_blank'
              );
            },
          })}
      >
        <DataGrid
          data-testid="tires-grid"
          gridCode={props.gridCode}
          autoHeight
          actionCallback={actionCallback}
          queryModifier={queryModifier}
        />
      </Card>
    </Section>
  );
}
