import {Button, ButtonGroup, Checkbox, Dialog} from 'platform/components';
import {Space} from 'platform/foundation';

import {FC, useState, PropsWithChildren} from 'react';

import i18n from '@dms/i18n';

import {DialogConfig} from '../types/DialogConfig';
import {ConfirmationDialogCtx} from '../utils/ConfirmationDialogCtx';

export const ConfirmationDialogProvider: FC<PropsWithChildren<any>> = ({children}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDisabled, setDialogDisabled] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({});

  const openDialog = (config: DialogConfig) => {
    if (dialogDisabled) {
      config?.resolve?.();
      return;
    }

    setDialogOpen(true);
    setDialogConfig(config);
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const handleConfirm = () => {
    resetDialog();
    dialogConfig?.resolve?.();
  };

  const handleDismiss = () => {
    resetDialog();
    dialogConfig?.reject?.();
  };

  return (
    <ConfirmationDialogCtx.Provider value={{openDialog}}>
      <Dialog
        title={i18n.t('page.vehicleSettings.labels.categoryTurnOffConfirmationText')}
        size="small"
        isOpen={dialogOpen}
        onClose={handleDismiss}
      >
        <Checkbox
          label={i18n.t('general.labels.notShowAgain')}
          onChange={setDialogDisabled}
          value={dialogDisabled}
        />

        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={handleDismiss}
            title={i18n.t('general.actions.cancel')}
          />
          <Button onClick={handleConfirm} title={i18n.t('general.actions.confirm')} />
        </ButtonGroup>
      </Dialog>
      {children}
    </ConfirmationDialogCtx.Provider>
  );
};
