import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {isTrue} from 'ramda-adjunct';

import {GetTenantV2ApiRes} from '@dms/api';
import i18n from '@dms/i18n';
import {usePhoneNumbers} from '@dms/shared';

import {yupString} from 'shared';

type FormValues = GetTenantV2ApiRes['contactPersons'][number];

interface EditContactPersonFormProps {
  onSubmit: (item: FormValues) => void;
  defaultValues?: FormValues;
}

export function ContactPersonForm(props: EditContactPersonFormProps) {
  const {countriesOptions} = usePhoneNumbers();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    await props.onSubmit(values);
    closeCurrentDialog();
  };

  return (
    <Form<FormValues> onSubmit={handleSubmit} defaultValues={props.defaultValues} schema={schema}>
      {(control) => (
        <VStack spacing={5}>
          <Grid columns={2}>
            <GridItem span={2}>
              <FormField
                control={control}
                name="isPrimary"
                isDisabled={isTrue(props.defaultValues?.isPrimary)}
                type="switch"
                label={i18n.t('entity.customer.labels.contactPersonsHelperText')}
              />
            </GridItem>

            <FormField
              control={control}
              name="firstName"
              type="text"
              label={i18n.t('general.labels.firstName')}
            />
            <FormField
              control={control}
              name="lastName"
              type="text"
              label={i18n.t('general.labels.lastName')}
            />

            <FormField
              control={control}
              name="role"
              type="text"
              label={i18n.t('entity.person.labels.role')}
            />
          </Grid>
          <Grid columns={2}>
            <FormField
              control={control}
              name="phoneNumber"
              type="phone"
              countries={countriesOptions}
              label={i18n.t('entity.phoneNumber.labels.number')}
            />
            <FormField
              control={control}
              name="emailAddress"
              type="text"
              label={i18n.t('general.labels.email')}
            />
          </Grid>

          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              onClick={closeCurrentDialog}
              variant="secondary"
            />
            <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  isPrimary: boolean().default(false).required(),
  firstName: yupString.default(null),
  lastName: yupString.default(null),
  role: yupString.default(null),
  phoneNumber: object().default(null).nullable(),
  emailAddress: yupString.default(null),
});
