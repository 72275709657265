import {Button, closeDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Heading, HStack, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {isEmpty, isNil, isNotNil, join, map, pipe, reject, uniq} from 'ramda';
import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  LabeledKey,
  useDeleteServiceOrderItemsMutation,
  useGetServiceCaseOrderJobsQuery,
  usePostServiceItemsMaterialPartIssuedMutation,
  usePostServiceItemsMaterialPartRequestMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, queryParams, catchUnhandledDataGridActions} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {BulkAssignMechanicForm} from '../../../../../components/BulkAssignMechanicForm';
import {BulkDiscountForm} from '../../../../../components/BulkDiscountForm';
import {GoodwillForm} from '../../../../../components/Goodwill/GoodwillForm';
import {MoveRequestItem} from '../../../../../components/MoveRequestItem/MoveRequestItem';
import {
  RequestEditDialog,
  RequestEditDialogProps,
} from '../../../../../components/RequestEditDialog';
import {WorkSplittingForm} from '../../../../../components/WorkSplitting/WorkSplittingForm';

type ReceiveJobItemRowData = {
  id: string;
  itemName: {value: string};
  serviceJobId: {value: string};
  itemType: {value: LabeledKey};
  itemPriceType: {value: LabeledKey};
  jobName: {value: string};
  itemGoodwillRatio: {value: {label: string} | Nullish};
  serviceOrderJobItemId: {value: string};
};

interface OrderItemsDataGridProps extends TestIdProps {
  serviceCaseId: string | Nullish;
  orderId: string | Nullish;
}

export function OrderItemsDataGrid(props: OrderItemsDataGridProps) {
  const [, setAddWorkJobId] = useQueryState(queryParams.SERVICE_CASE_ADD_WORK_JOB_ID);
  const [, setAddMaterialRequestJobId] = useQueryState(
    queryParams.SERVICE_CASE_ADD_MATERIAL_REQUEST_JOB_ID
  );
  const [editDialogProps, setEditDialogProps] = useState<RequestEditDialogProps | null>(null);

  const {data: uncategorizedJob} = useGetServiceCaseOrderJobsQuery(
    {
      serviceCaseId: props.serviceCaseId ?? '',
      serviceOrderId: props.orderId ?? '',
    },
    {
      skip: !props.serviceCaseId || !props.orderId,
      selectFromResult: ({data}) => ({data: data?.find((job) => job?.isUncategorized)}),
    }
  );

  const [deleteServiceOrderItems] = useDeleteServiceOrderItemsMutation();
  const [postServiceItemsMaterialPartRequest] = usePostServiceItemsMaterialPartRequestMutation();
  const [postServiceItemsMaterialPartIssued] = usePostServiceItemsMaterialPartIssuedMutation();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      serviceCaseId: String(props.serviceCaseId),
      serviceOrderId: String(props.orderId),
    }),
    [props.orderId, props.serviceCaseId]
  );

  const actionCallback: ActionCallback = ({queryId, rowId, actionKey, rowData, refreshData}) => {
    const castedRowData = rowData as ReceiveJobItemRowData | ReceiveJobItemRowData[];

    const itemId = isArray(castedRowData)
      ? castedRowData[0].serviceOrderJobItemId.value
      : castedRowData.serviceOrderJobItemId.value;

    const itemJobId = isArray(castedRowData) ? undefined : castedRowData.serviceJobId.value;

    const items = isArray(castedRowData)
      ? castedRowData.map((row) => ({
          serviceItemId: row.serviceOrderJobItemId.value,
          serviceJobId: row.serviceJobId.value,
        }))
      : [{serviceItemId: itemId, serviceJobId: itemJobId ?? ''}];

    const requestNames = isArray(castedRowData)
      ? getRequestNamesString(castedRowData)
      : castedRowData.jobName.value;

    const hasSomeGoodwill =
      isArray(castedRowData) &&
      castedRowData.some(
        (data) =>
          isNotNilOrEmpty(data.itemGoodwillRatio.value) &&
          Number(data.itemGoodwillRatio.value?.label) !== 100
      );

    match(actionKey)
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderItems({
              serviceOrderId: props.orderId ?? '',
              serviceCaseId: props.serviceCaseId ?? '',
              body: {items},
            })
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('edit', 'redirectDetail', () => {
        if (isArray(castedRowData) || isNil(castedRowData)) {
          return;
        }
        setEditDialogProps({
          gridCode: 'service-case-order-items',
          rowId: isArray(rowId) ? rowId[0] : rowId,
          dataQueryId: queryId,
          serviceCaseId: props.serviceCaseId ?? '',
          orderId: props.orderId ?? '',
          itemData: castedRowData,
          onAfterSubmit: refreshData,
          onClose: () => setEditDialogProps(null),
          'data-testid': props['data-testid'] as string,
        });
      })
      .with('move', () =>
        openDialog(
          <MoveRequestItem
            onClose={() => closeDialog('moveRequestItem')}
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onMoveItems={refreshData}
            data-testid={suffixTestId('moveRequestItem', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.moveRequestItem'),
            id: 'moveRequestItem',
          }
        )
      )
      .with('goodwill', () =>
        openDialog(
          <GoodwillForm
            onClose={() => closeDialog('goodwill')}
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            requestName={requestNames}
            items={items}
            onSaved={refreshData}
            isWithMultipleGoodwill={hasSomeGoodwill}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.goodwill'),
            id: 'goodwill',
          }
        )
      )
      .with('workSplitting', () =>
        openDialog(
          <WorkSplittingForm
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('workSplitting')}
            data-testid={suffixTestId('workSplitting', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.workSplitting'),
            id: 'workSplitting',
          }
        )
      )
      .with('request', () =>
        postServiceItemsMaterialPartRequest({
          body: {serviceItems: items},
          serviceCaseId: props.serviceCaseId ?? '',
          serviceOrderId: props.orderId ?? '',
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('issue', () =>
        postServiceItemsMaterialPartIssued({
          body: {serviceItems: items},
          serviceCaseId: props.serviceCaseId ?? '',
          serviceOrderId: props.orderId ?? '',
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('assignMechanic', () =>
        openDialog(
          <BulkAssignMechanicForm
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('assignMechanic')}
            data-testid={suffixTestId('assignMechanic', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.assignMechanic'),
            id: 'assignMechanic',
          }
        )
      )
      .with('discount', () =>
        openDialog(
          <BulkDiscountForm
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('manualDiscount')}
            data-testid={suffixTestId('manualDiscount', props)}
          />,
          {
            title: i18n.t('entity.orderItem.labels.manualDiscount'),
            id: 'manualDiscount',
            size: 'small',
          }
        )
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleOpenAddWork = () => {
    uncategorizedJob?.id && setAddWorkJobId(uncategorizedJob.id);
  };

  const handleOpenAddMaterialRequest = () => {
    uncategorizedJob?.id && setAddMaterialRequestJobId(uncategorizedJob.id);
  };

  return (
    <>
      <Show when={isNotNil(editDialogProps)}>
        <RequestEditDialog {...(editDialogProps as RequestEditDialogProps)} />
      </Show>
      <HStack justify="space-between">
        <Heading size={4}>{i18n.t('entity.orderRequest.labels.allItems')}</Heading>
        <HStack align="center" spacing={2}>
          <Button
            variant="ghostLink"
            leftIcon="content/add_circle"
            onClick={handleOpenAddWork}
            data-testid={suffixTestId('addWork', props)}
            title={i18n.t('entity.addWork.actions.addWork')}
          />
          <Button
            variant="ghostLink"
            leftIcon="content/add_circle"
            onClick={handleOpenAddMaterialRequest}
            data-testid={suffixTestId('addMaterial', props)}
            title={i18n.t('entity.addMaterial.actions.addMaterial')}
          />
        </HStack>
      </HStack>
      <DataGrid
        autoHeight
        gridCode="service-case-order-items"
        queryModifier={queryModifier}
        actionCallback={actionCallback}
        data-testid={suffixTestId('serviceCaseOrderItems', props)}
      />
    </>
  );
}

const getRequestNamesString = pipe(
  map((row: ReceiveJobItemRowData) => row.jobName.value),
  reject(isEmpty),
  uniq,
  join(',')
);
