import {BreadcrumbType, Form, FormField, FormSubmitHandler} from 'platform/components';
import {object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetEmployeeProfessionQuery,
  usePatchEmployeeProfessionMutation,
  usePostEmployeeProfessionMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type ProfessionFormType = {description: string};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.employeeProfessions.labels.title'),
    href: settingsRoutes.employeeProfessions,
  },
];

export function EmployeeProfessionsDetail() {
  const navigate = useNavigate();
  const {id} = useParams();

  const [postEmployeeProfession] = usePostEmployeeProfessionMutation();
  const [patchEmployeeProfession] = usePatchEmployeeProfessionMutation();
  const {data, isError, isLoading} = useGetEmployeeProfessionQuery(
    {id: id ?? ''},
    {skip: isNil(id)}
  );

  const handleSubmit: FormSubmitHandler<ProfessionFormType> = async ({description}) => {
    const request = isNil(id)
      ? postEmployeeProfession({body: {description}})
      : patchEmployeeProfession({body: {description}, id});

    await request
      .unwrap()
      .then(() => navigate(settingsRoutes.employeeProfessions))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        breadcrumbs,
        title: data?.description ?? i18n.t('entity.employeeProfessions.labels.newProfession'),
      }}
      isLoading={isLoading}
      isError={isError}
      isCreating={isNilOrEmpty(id)}
      data-testid={testIds.settings.employeeProfessionsDetail('settingsTemplate')}
    >
      <Form<ProfessionFormType>
        defaultValues={{description: data?.description ?? undefined}}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <>
            <SettingsSection>
              <FormField
                control={control}
                type="text"
                name="description"
                label={i18n.t('general.labels.description')}
                data-testid={testIds.settings.employeeProfessionsDetail('description')}
              />
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  onClick: () => navigate(settingsRoutes.employeeProfessions),
                  title: id
                    ? i18n.t('general.actions.discardChanges')
                    : i18n.t('general.actions.discard'),
                  variant: 'secondary',
                  'data-testid': testIds.settings.employeeProfessionsDetail('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: id
                    ? i18n.t('general.actions.saveAndClose')
                    : i18n.t('general.actions.create'),
                  'data-testid': testIds.settings.employeeProfessionsDetail('save'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({description: yupString.required()});
