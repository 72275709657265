import {
  Action,
  Actions,
  Breadcrumbs,
  BreadcrumbType,
  Separator,
  TabsHeader,
} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {queryParams, SectionTab} from '@dms/shared';

import {suffixTestId, TestIdProps, useQueryState} from 'shared';

export interface SettingsHeaderProps extends TestIdProps {
  title?: string;
  subtitle?: string;
  isTitleTeriary?: boolean;
  breadcrumbs?: BreadcrumbType[];
  actions?: Action[];
  tabs?: SectionTab[];
}

export function SettingsHeader(props: SettingsHeaderProps) {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const tabs = (props.tabs ?? [])?.map(({queryId, ...item}) => ({
    id: queryId,
    ...item,
    'data-testid': suffixTestId(queryId, props),
  }));

  return (
    <VStack>
      <Box paddingTop={6} paddingHorizontal={6} paddingBottom={isNotNilOrEmpty(props.tabs) ? 0 : 6}>
        <Show when={isNotNilOrEmpty(props.breadcrumbs)}>
          <Breadcrumbs
            breadcrumbs={props.breadcrumbs}
            data-testid={suffixTestId('settingsHeader-breadcrumbs', props)}
          />
          <Space vertical={8} />
        </Show>
        <HStack align="center" spacing={3} justify="space-between">
          <Heading
            size={1}
            color={props.isTitleTeriary ? 'tertiary' : undefined}
            data-testid={suffixTestId('settingsHeader-title', props)}
          >
            {props.title}
          </Heading>
          <Actions
            actions={props.actions}
            data-testid={suffixTestId('settingsHeader-actions', props)}
          />
        </HStack>
        <Show when={isNotNil(props.subtitle)}>
          <Space vertical={4} />
          <Text size="small" color="secondary">
            {props.subtitle}
          </Text>
        </Show>
        <Show when={isNotNil(props.tabs)}>
          <Space vertical={6} />
          <TabsHeader
            data-testid={suffixTestId('settingsHeader-tabs', props)}
            variant="condensed"
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={setActiveTabId}
          />
        </Show>
      </Box>
      <Separator spacing={0} />
    </VStack>
  );
}
