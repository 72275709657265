import {Alert, Button, Flag, showNotification} from 'platform/components';
import {Box, Center, HStack, Heading, Hide, Image, Show, Text, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {useLazyGetAutofillFeaturesQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useTabs} from '../../../hooks/useTabs';
import {ServiceVehicleFormTabs} from '../../../types/ServiceVehicleFormTabs';
import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';

interface AutofillWidgetProps extends RequiredTestIdProps {
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function AutofillWidget(props: AutofillWidgetProps) {
  const {setSelectedTab} = useTabs();

  const vin = props.formApi.watch('vin');
  const hasVin = !!vin;

  const [getAutofillFeatures, getAutofillFeaturesQueryState] = useLazyGetAutofillFeaturesQuery();
  const isFilled = getAutofillFeaturesQueryState.isSuccess;

  const handleFeatureAutofill = () => {
    if (!hasVin) {
      return;
    }

    getAutofillFeatures({vin})
      .unwrap()
      .then((response) => {
        props.formApi.setValue('features', response.features);
        showNotification.success(
          i18n.t('entity.vehicle.notifications.autofillFeaturesUpdate', {
            count: response.features.length,
          })
        );
      })
      .catch(showNotification.error);
  };

  return (
    <VStack spacing={2}>
      <Box backgroundColor="palettes.neutral.10.100" borderRadius="medium" overflow="hidden">
        <HStack>
          <Box
            paddingLeft={9}
            paddingRight={9}
            minHeight="100%"
            backgroundColor="palettes.blue.10.100"
          >
            <Center minHeight="100%">
              <Image
                src="/assets/images/cebia_logo.png"
                maxWidth={20}
                data-testid={suffixTestId('cebia-image', props)}
              />
            </Center>
          </Box>
          <Box padding={4}>
            <VStack spacing={3}>
              <VStack spacing={2}>
                <Heading size={4}>{i18n.t('entity.vehicle.labels.autofillFeatures')}</Heading>
                <Text size="xSmall">{i18n.t('entity.vehicle.labels.cebiaWidgetInfoText')}</Text>
              </VStack>
              <Show when={isFilled}>
                <Flag
                  colorScheme="green"
                  data-testid={suffixTestId('autofilled', props)}
                  label={i18n.t('entity.vehicle.labels.autofilled')}
                />
              </Show>
              <Hide when={isFilled}>
                <Box>
                  <Button
                    variant="secondary"
                    data-testid={suffixTestId('autofill', props)}
                    isDisabled={!hasVin}
                    isLoading={getAutofillFeaturesQueryState.isLoading}
                    onClick={handleFeatureAutofill}
                    title={i18n.t('entity.vehicle.actions.autofill')}
                  />
                </Box>
              </Hide>
            </VStack>
          </Box>
        </HStack>
      </Box>
      <Show when={!hasVin}>
        <Box>
          <Alert
            type="inline"
            variant="warning"
            data-testid={suffixTestId('vinRequired', props)}
            message={i18n.t('entity.vehicle.notifications.vinCodeRequired')}
            hyperlinks={[
              {
                title: i18n.t('entity.vehicle.actions.enterVIN'),
                onClick: () => setSelectedTab(ServiceVehicleFormTabs.BasicInformation),
                'data-testid': suffixTestId('enterVin', props),
                size: 'small',
              },
            ]}
          />
        </Box>
      </Show>
      <Show when={getAutofillFeaturesQueryState.isSuccess}>
        <Box>
          <Alert
            type="inline"
            variant="success"
            data-testid={suffixTestId('autofillSuccess', props)}
            message={i18n.t('entity.vehicle.notifications.autofillFeaturesUpdate', {
              count: getAutofillFeaturesQueryState.data?.features.length ?? 0,
            })}
          />
        </Box>
      </Show>
    </VStack>
  );
}
