import {Action, Card} from 'platform/components';

import {usePatchUnreadSentToastNotificationsMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {NotificationsWidget, useNotifications} from '@dms/notifications';
import {handleApiError} from '@dms/shared';

import {buildArray} from 'shared';

const NOTIFICATION_PAGE_SIZE = 6;

export function MyNotifications() {
  const {
    data: notificationData,
    isLoading,
    isFetching,
    isError,
    hasUnreadNotifications,
  } = useNotifications(NOTIFICATION_PAGE_SIZE);

  const [patchAllUnreadNotifications, {isLoading: isPatching}] =
    usePatchUnreadSentToastNotificationsMutation();

  const handleMarkAllAsRead = () => {
    patchAllUnreadNotifications({body: {isRead: true}})
      .unwrap()
      .catch(handleApiError);
  };

  return (
    <Card
      title={i18n.t('page.homepage.labels.myNotifications')}
      actions={buildArray<Action>().when(hasUnreadNotifications, {
        title: i18n.t('general.notifications.markAllAsRead'),
        onClick: handleMarkAllAsRead,
        variant: 'link',
        type: 'button',
        isDisabled: isPatching,
      })}
      hasSeparator={false}
    >
      <NotificationsWidget
        notificationData={notificationData}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        pageSize={NOTIFICATION_PAGE_SIZE}
        isDisabled={isPatching}
        isHeaderHidden
      />
    </Card>
  );
}
