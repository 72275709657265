import {Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {FC} from 'react';

import {Money} from '@dms/api';
import i18n from '@dms/i18n';

export const PlatformPrice: FC<{price: Money | null}> = ({price}) => {
  const formatCurrency = useFormatCurrency();

  return price ? (
    <div>{formatCurrency(parseFloat(price.amount), price.currency)}</div>
  ) : (
    <Text size="xSmall" color="danger">
      {i18n.t('general.notifications.noValue')}
    </Text>
  );
};
