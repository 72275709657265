import {isNil} from 'ramda';

import {GetEmployeeMechanicsApiResponse} from '@dms/api';

import {Nullish} from 'shared';

export const getOptionsFromMechanics = (mechanics: GetEmployeeMechanicsApiResponse | Nullish) => {
  if (isNil(mechanics?.employees)) {
    return [];
  }

  return mechanics?.employees?.map((mechanic) => ({
    label: mechanic?.name,
    value: mechanic?.id,
  }));
};
