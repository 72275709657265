import {Card, Label} from 'platform/components';
import {Box, Space} from 'platform/foundation';

import {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {suffixTestId, capitalize, TestIdProps} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {setAuditLocalData} from '../../../store/carAudit/reducer';
import {selectAuditLocalData} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {AuditParamValue} from '../../../types/AuditParamValue';
import {TConditionForm, TConditionFormValue} from '../../../types/ConditionTypes';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useConditionContext} from '../hooks/useConditionContext';
import {AuditParamType} from '../types/AuditParamType';
import {findByParamType} from '../utils/findByParamType';
import {getBaseFormFieldProps} from '../utils/getBaseFormFieldProps';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getTranslation} from '../utils/getTranslation';
import {parseNumber} from '../utils/parseNumber';
import {AuditFormField} from './AuditFormField';
import {CardImageUpload} from './CardImageUpload';

const firstModifiedWheelPrefix = 'firstModifiedWheelKeyIn';

type WheelProps = {
  data: AuditCategoryOfStructure;
  categoryKey: string;
  hasWheelSetData: boolean;
};

export const Wheel: FC<WheelProps & TestIdProps> = ({
  data,
  categoryKey,
  hasWheelSetData,
  ...rest
}) => {
  const dispatch = useThunkDispatch();
  const {isDisabledForUser} = useConditionContext();
  const locale = useSelector(selectUserSelectedLanguage);
  const auditLocalData = useSelector(selectAuditLocalData);
  const {
    Field,
    form: {
      mutators: {setFieldValue},
    },
  } = useFormRenderer<TConditionForm>();

  const additionalComment = findByParamType(
    data.paramDefinitions?.additionalInformation,
    AuditParamType.ADDITIONAL_COMMENT
  );

  const setToAuditLocalData = (key: string, value: string) => {
    dispatch(setAuditLocalData({[key]: value}));
  };

  const getCloneFieldsByParamDefinition = useCallback((paramDefinition: AuditParamDefinition) => {
    if (!paramDefinition.relatedParamDefinitionIds) {
      return [];
    }

    return paramDefinition.relatedParamDefinitionIds.map((relatedParam) =>
      getFormFieldName(relatedParam.categoryId, relatedParam.paramDefinitionId)
    );
  }, []);

  const cloneValueToOtherWheels = (
    paramDefinition: AuditParamDefinition,
    value: TConditionFormValue,
    type: string
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    const _value = value as unknown as AuditParamValue;
    const fieldForClone = getCloneFieldsByParamDefinition(paramDefinition);

    fieldForClone.forEach((fieldName) => {
      setFieldValue(
        fieldName,
        type === AuditParamType.NUMBER
          ? parseNumber(String(_value?.value ?? _value))
          : (_value?.value ?? _value)
      );
    });
  };

  const firstModifiedDataName = `${firstModifiedWheelPrefix}${capitalize(categoryKey)}`;

  const isFirstModifiedWheel = () => auditLocalData?.[firstModifiedDataName] === data.uniqueKey;

  let timeout: NodeJS.Timeout | undefined;
  const handleChangeField = (
    paramDefinition: AuditParamDefinition,
    value: TConditionFormValue,
    type: string
  ) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if (!hasWheelSetData) {
        setToAuditLocalData(firstModifiedDataName, data.uniqueKey);
      }
      if (!isFirstModifiedWheel() && auditLocalData?.[firstModifiedDataName] !== undefined) {
        setToAuditLocalData(firstModifiedDataName, '');
      }
      if (isFirstModifiedWheel() || !hasWheelSetData) {
        cloneValueToOtherWheels(paramDefinition, value, type);
      }
    }, 300);
  };

  return (
    <Card
      title={getTranslation(locale, data.name)}
      variant="inlineGrey"
      data-testid={suffixTestId(data.id, rest)}
    >
      {data.paramDefinitions?.relatedActions?.map((paramDefinition) => {
        const isMandatory = paramDefinition.mandatory;

        if (paramDefinition.type === AuditParamType.PHOTO) {
          return (
            <Box key={`wheelPhoto-${paramDefinition.id}`}>
              <Label isRequired={isMandatory}>{i18n.t('entity.photo.labels.photo')}</Label>
              <CardImageUpload
                categoryId={data.id}
                paramDefinitionId={paramDefinition.id}
                mandatory={isMandatory}
                data-testid={suffixTestId(paramDefinition.id, rest)}
                ratio="4 / 3"
              />
              <Space vertical={3} />
            </Box>
          );
        }

        const getAsType = () => {
          if (paramDefinition.type === AuditParamType.PILL_RADIO) {
            return AuditParamType.DROPDOWN;
          }

          return paramDefinition.type;
        };

        return (
          <AuditFormField
            key={`photoField-${paramDefinition.id}`}
            paramDefinition={paramDefinition}
            categoryId={data.id}
            onChange={(value: TConditionFormValue) => {
              handleChangeField(paramDefinition, value, getAsType());
            }}
            asType={getAsType()}
            uniqueKey={data.uniqueKey}
            data-testid={suffixTestId(paramDefinition.id, rest)}
          />
        );
      })}
      {additionalComment && (
        <Field
          {...getBaseFormFieldProps(data.id, additionalComment, locale)}
          as="text"
          label={i18n.t('general.labels.note')}
          multiline
          disabled={isDisabledForUser}
        />
      )}
    </Card>
  );
};
