import {
  Button,
  ButtonGroup,
  DataStatus,
  DialogFooter,
  Label,
  Search,
  Table,
  TableRow,
  TableRowAction,
  closeCurrentDialog,
  openDialog,
} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useDeferredValue, useState} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {CompGetInfoV3ApiResponse} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, buildArray, suffixTestId} from 'shared';

import {SelectLegalPerson} from './SelectLegalPerson';

interface BusinessesTableProps extends TestIdProps {
  data: CompGetInfoV3ApiResponse;
  onSuccess: (company: CompGetInfoV3ApiResponse) => void;
}

export function BusinessesTable(props: BusinessesTableProps) {
  const [search, setSearch] = useState<string | null>(null);
  const deferredSearch = useDeferredValue(search);
  const toLowerCaseWithoutDiacritics = (val: string) =>
    val
      .toLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '');

  const searchedBusinessName = props.data?.filter((business) =>
    search
      ? toLowerCaseWithoutDiacritics(business?.businessName ?? '').includes(deferredSearch ?? '')
      : props.data
  );
  const searchedBusinessId = props.data?.filter((business) =>
    search ? business?.businessId?.includes(deferredSearch ?? '') : props.data
  );

  const joinSearchedWithoutDuplicates = [
    ...new Set([...(searchedBusinessName || []), ...(searchedBusinessId || [])]),
  ];

  const filteredBusinesses = joinSearchedWithoutDuplicates;

  const handleOnClick = (id?: string | null) => {
    closeCurrentDialog();
    openDialog(
      <SelectLegalPerson
        data-testid={props['data-testid']}
        data={props.data ? props.data?.filter((i) => i?.businessId === id) : []}
        onSubmit={props.onSuccess}
      />,
      {
        title: i18n.t('entity.customer.labels.businessInformation'),
        scrollBehavior: 'outside',
      }
    );
  };

  return (
    <Box padding={4}>
      <VStack spacing={4}>
        <Box width={60}>
          <Search value={search} onChange={setSearch} />
        </Box>
        <Divider />
        <DataStatus isEmpty={isNilOrEmpty(filteredBusinesses)} minHeight={100}>
          <Table
            tableLayout="fixed"
            variant="bordered"
            columns={[
              {
                width: 25,
                element: (
                  <Box paddingHorizontal={2} paddingBottom={2}>
                    <Label>{i18n.t('entity.businessInfo.labels.registrationNumber')}</Label>
                  </Box>
                ),
              },
              {
                width: 55,
                element: (
                  <Box paddingHorizontal={2} paddingBottom={2}>
                    <Label>{i18n.t('entity.documentSeries.name')}</Label>
                  </Box>
                ),
              },
              {
                width: 30,
                element: (
                  <Box paddingHorizontal={2} paddingBottom={2}>
                    <Label>{i18n.t('entity.address.labels.city')}</Label>
                  </Box>
                ),
              },
              {
                width: 30,

                element: (
                  <Box paddingHorizontal={2} paddingBottom={2}>
                    <Label>{i18n.t('entity.address.labels.street')}</Label>
                  </Box>
                ),
              },
              {
                width: 12,
              },
            ]}
          >
            {filteredBusinesses?.map((item) => (
              <TableRow
                key={item?.businessId}
                onClick={() => handleOnClick(item?.businessId)}
                actions={{
                  primary: buildArray<TableRowAction>().when(isNotNilOrEmpty(props.data), {
                    title: i18n.t('acl.dialog.button.submit'),
                    icon: 'hardware/keyboard_arrow_right',
                    onClick: () => handleOnClick(item?.businessId),
                  }),
                }}
              >
                <Box padding={1}>
                  <Text size="small">{item?.businessId}</Text>
                </Box>
                <Box padding={1}>
                  <Text size="small">{item?.businessName}</Text>
                </Box>
                <Box padding={1}>{item?.city}</Box>
                <Box padding={1}>{item?.street}</Box>
              </TableRow>
            ))}
          </Table>
        </DataStatus>
        <DialogFooter>
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('general.actions.cancel')}
              data-testid={suffixTestId('selectBusinessFromTable-cancel', props)}
            />
          </ButtonGroup>
        </DialogFooter>
      </VStack>
    </Box>
  );
}

const Divider = styled.div`
  height: 1px;
  position: relative;
  background-color: ${({theme}) => theme.colors.general.separator};
`;
