import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {allPass, defaultTo, filter, find, groupBy, map, pipe, prop, propEq} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  ExpandCell,
  SimpleTable,
  selectDocumentKinds,
  selectDocumentTemplates,
  selectUploadingTemplate,
  DocumentKind,
  ExtendedTemplate,
  TemplateV2 as Template,
} from '@dms/teas';

import {convertStringToCamelCase} from 'shared';

import {tableColumns} from '../const';
import {Container} from '../styles';
import {TExtraTemplateFields} from '../types';
import {BadgeComponent} from './Badge';
import {DocumentExpandTable} from './DocumentExpandTable';
import {ActionIcons} from './DocumentIcons';

interface DocumentTableProps {
  readonly templates: string[];
  readonly groupName: string;
}

export const DocumentTable = ({templates, groupName}: DocumentTableProps): ReactElement => {
  const documentTemplates = useSelector(selectDocumentTemplates);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean | undefined>>({});
  const uploadingTemplate = useSelector(selectUploadingTemplate);
  const documentKinds = useSelector(selectDocumentKinds);

  useEffect(() => {
    if (uploadingTemplate !== null && !uploadingTemplate.isUploading) {
      setExpandedRows({...expandedRows, [uploadingTemplate.documentKindCode]: true});
    }
  }, [JSON.stringify(uploadingTemplate)]);

  const onRowExpanded = (row: TExtraTemplateFields, expanded: boolean) => {
    setExpandedRows({...expandedRows, [row.code]: expanded});
  };

  const allCustomTemplates = useCallback(
    (id: string) =>
      groupBy(
        prop('documentKindCode'),
        filter((template) => template.id !== id, documentTemplates ?? [])
      ),
    [documentTemplates]
  );

  const modificationForTableData = useCallback(
    (item: Template): TExtraTemplateFields => {
      const customTemplates = allCustomTemplates(item.id)[item.documentKindCode] ?? [];
      const length = customTemplates?.length;
      const title = customTemplates?.find((template) => template.primary)?.title;
      const documentKindTitle =
        find(propEq(item.documentKindCode, 'code'), documentKinds)?.title ?? '';

      const rowData: TExtraTemplateFields = {
        url: item.url,
        code: item.documentKindCode,
        templateId: item.id,
        customTemplates,
        expanded: expandedRows[item.documentKindCode] ?? false,
        templateTitle: item?.title,
        title: <BadgeComponent title={documentKindTitle} length={length} />,
        fileName: title || i18n.t('general.labels.default'),
        defaultTitle: item?.fileName,
        expand: length > 0 ? <ExpandCell /> : null,
        system: item.system,
        primary: item.primary,
        hidden: !!item.hidden,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedBy,
        note: item.note,
      };
      rowData.actions = <ActionIcons template={rowData} />;
      return rowData;
    },
    [expandedRows, allCustomTemplates, documentKinds]
  );

  const data = useMemo(() => {
    if (!(documentKinds?.length && templates?.length && documentTemplates?.length)) {
      return [];
    }

    return pipe(
      filter((doc: DocumentKind) => templates.includes(doc.code)),
      map<DocumentKind, ExtendedTemplate>((kind) =>
        pipe(
          find<ExtendedTemplate>(
            allPass([propEq(kind.code, 'documentKindCode'), propEq(true, 'primary')])
          ),
          defaultTo(
            filter((item: ExtendedTemplate) => item.documentKindCode === kind.code)(
              documentTemplates
            )[0]
          )
        )(documentTemplates)
      ),
      map(modificationForTableData)
    )(documentKinds);
  }, [documentKinds, documentTemplates, modificationForTableData, templates]);

  return (
    <Container data-testid={testIds.settings.documentTemplates('container')}>
      <SimpleTable<TExtraTemplateFields>
        ExpandContent={DocumentExpandTable}
        rows={[{data}]}
        data-testid={testIds.settings.documentTemplates(
          `templates-${convertStringToCamelCase(groupName)}`
        )}
        onRowExpanded={onRowExpanded}
        columns={tableColumns}
      />
    </Container>
  );
};
