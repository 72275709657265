import i18n from 'i18next';

import {useCallback} from 'react';

import {noop} from 'shared';

import {ActionCallback} from '../types/ActionCallback';
import {ActionDefinitionObject} from '../types/ActionDefinition';
import {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
} from '../types/AgGridTypes';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {RowData} from '../types/RowData';
import {refreshDatagrid} from '../utils/refreshDatagrid';
import {getSearchParamsByGridCode} from '../utils/url/getSearchParamsByGridCode';
import {setSourceSearchParamsByGridCode} from '../utils/url/setSourceSearchParamsByGridCode';
import {useDeselectAll} from './useDeselectAll';
import {useRedrawRows} from './useRedrawRows';
import {useRefreshCells} from './useRefreshCells';

const NEW_TAB_SUFFIX = '_newTab';

export enum BuildinMenuItem {
  autoSizeAll = 'autoSizeAll',
  expandAll = 'expandAll',
  contractAll = 'contractAll',
  copy = 'copy',
  copyWithHeaders = 'copyWithHeaders',
  copyWithGroupHeaders = 'copyWithGroupHeaders',
  paste = 'paste',
  resetColumns = 'resetColumns',
  export = 'export',
  csvExport = 'csvExport',
  excelExport = 'excelExport',
  chartRange = 'chartRange',
  separator = 'separator',
}

/**
 * Returns getContextMenuItems funtion that is passed to AgGrid
 * RESOURCES:
 * - https://www.ag-grid.com/react-data-grid/context-menu/
 */
export const useContextMenu = (
  gridApi: GridApi | undefined,
  gridProps: DataGridProps,
  settings: FeGridSettings,
  definition: TransformedDefinitionApiResponse,
  gridCode: string
): GetContextMenuItems => {
  const refreshCells = useRefreshCells(gridApi);
  const redrawRows = useRedrawRows(gridApi);
  const deselectAll = useDeselectAll(gridApi);
  const searchParams = getSearchParamsByGridCode(gridCode);

  return useCallback(
    (getContextMenuItemsProps: GetContextMenuItemsParams) => {
      // @ts-ignore
      const actionsFilter = gridProps.actionsFilter;
      const defaultItems = (getContextMenuItemsProps.defaultItems || []).filter(
        (item) => item !== BuildinMenuItem.separator && item !== BuildinMenuItem.export
      ) as Array<BuildinMenuItem>;
      const defaultItemsWithoutSeparator = defaultItems.filter(
        (item) => item !== BuildinMenuItem.separator && item !== BuildinMenuItem.export
      );
      if (getContextMenuItemsProps.node) {
        const row = getContextMenuItemsProps.node.data as RowData;
        const actionCallback: ActionCallback = gridProps.actionCallback ?? noop;

        if (row && row.actions && definition.behavior.actionColumnEnabled) {
          // @ts-ignore
          const actionDefs = gridProps.actionsDefinition || {};

          const validActions = Object.keys(actionDefs)
            .filter((actionDefKey) => actionDefs[actionDefKey]?.singleAction)
            .filter((actionDefKey) =>
              [...row.actions.primary, ...row.actions.secondary, row.actions.rowClick].includes(
                actionDefKey
              )
            )
            .filter((actionDefKey) => {
              if (actionsFilter) {
                return actionsFilter(actionDefKey, row);
              }
              return true;
            })
            .reduce((acc, actionDefKey) => {
              acc[actionDefKey] = actionDefs[actionDefKey];

              if (acc[actionDefKey].isLink) {
                acc[`${actionDefKey}${NEW_TAB_SUFFIX}`] = {
                  ...acc[actionDefKey],
                  title: `${acc[actionDefKey].title} (${i18n.t('page.datagrid.labels.newTab')})`,
                };
              }

              return acc;
            }, {} as ActionDefinitionObject);

          const refreshData = () => refreshDatagrid(gridCode);

          const actionItems: Array<MenuItemDef> = Object.entries(validActions).map(
            ([key, action]) => ({
              name: action.title,
              action: () =>
                actionCallback({
                  actionKey: key,
                  rowId: row.id,
                  rowData: row,
                  refreshData,
                  refreshCells,
                  redrawRows,
                  deselectAll,
                  sourceSearchParams: setSourceSearchParamsByGridCode(
                    gridProps.gridCode,
                    String(row.rowNumber)
                  ),
                  queryId: searchParams?.queryId ?? '',
                }),
              disabled: false,
            })
          );

          const separator = actionItems.length > 0 ? BuildinMenuItem.separator : '';

          return [...actionItems, separator, ...defaultItemsWithoutSeparator];
        }
      }

      return defaultItems;
    },
    [
      // @ts-ignore
      gridProps.actionsDefinition,
      gridProps.actionCallback,
      gridProps.gridCode,
      // @ts-ignore
      gridProps.actionsFilter,
      refreshCells,
      redrawRows,
      deselectAll,
    ]
  );
};
