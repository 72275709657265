import {Button, ButtonGroup, Dialog} from 'platform/components';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

interface ChangeVehicleDialogProps {
  isConfirmChangeVehicleOpen: boolean;
  toggleIsConfirmChangeVehicleOpen: () => void;
  onChangeVehicleConfirm: () => void;
}

export function ChangeVehicleDialog(props: ChangeVehicleDialogProps) {
  return (
    <Dialog
      data-testid={testIds.businessCase.selling('change-vehicle-confirm')}
      isOpen={props.isConfirmChangeVehicleOpen}
      onClose={props.toggleIsConfirmChangeVehicleOpen}
      size="small"
      title={i18n.t`page.businessCase.labels.confirmChangeVehicle`}
    >
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          onClick={props.toggleIsConfirmChangeVehicleOpen}
          title={i18n.t('general.labels.no')}
        />
        <Button
          variant="primary"
          onClick={props.onChangeVehicleConfirm}
          title={i18n.t('general.labels.yes')}
        />
      </ButtonGroup>
    </Dialog>
  );
}
