import {Action, Card} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {always, mergeAll} from 'ramda';

import {GetServiceOrderIssueNoteResponse} from '@dms/api';
import i18n from '@dms/i18n';
import {Section} from '@dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface DocumentsProps extends TestIdProps {
  serviceOrderId: string;
  state: GetServiceOrderIssueNoteResponse['state'] | Nullish;
}

export function Documents(props: DocumentsProps) {
  // TODO
  const handleOpenAddDialog = () => {};

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceCaseOrderId: props.serviceOrderId}]),
    [props.serviceOrderId]
  );

  const documentsActions = match(props.state)
    .returnType<Action[] | undefined>()
    .with(
      'PENDING',
      always([
        {
          type: 'button',
          variant: 'ghostLink',
          leftIcon: 'content/add_circle',
          title: i18n.t('entity.warehouse.actions.addDocument'),
          onClick: handleOpenAddDialog,
          'data-testid': suffixTestId('action.addDocument', props),
        },
      ])
    )
    .otherwise(always(undefined));

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <Card title={i18n.t('page.warehouse.labels.documents')} actions={documentsActions}>
        <Box height="65vh">
          <DataGrid
            gridCode="document-context-list-service-case-order"
            emptyState={{headline: i18n.t('general.labels.noData')}}
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('documents', props)}
          />
        </Box>
      </Card>
    </Section>
  );
}
