import {Button, ButtonGroup} from 'platform/components';
import {Heading, Space, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {useBoolean} from 'shared';

interface DiscountsTabChangeDiscountType {
  onDiscard: () => void;
  onAcceptChange: () => void;
}

export function DiscountTabsChangeDiscountType(props: DiscountsTabChangeDiscountType) {
  const [isLoading, startLoading] = useBoolean();

  const handleSubmit = () => {
    startLoading();
    props.onAcceptChange();
  };

  return (
    <VStack spacing={4}>
      <Space vertical={2} />
      <Heading size={4}>{i18n.t('warehouse.labels.changeDiscountTypeHeading')}</Heading>
      <Space vertical={2} />
      <ButtonGroup align="right">
        <Button
          isLoading={isLoading}
          variant="secondary"
          title={i18n.t('entity.warehouse.actions.doNotChangeDiscountType')}
          onClick={props.onDiscard}
        />
        <Button
          isLoading={isLoading}
          variant="danger"
          title={i18n.t('entity.warehouse.actions.changeDiscountType')}
          onClick={handleSubmit}
        />
      </ButtonGroup>
    </VStack>
  );
}
