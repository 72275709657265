import {
  Action,
  Actions,
  closeDialog,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  IconButton,
  openConfirmDialog,
  openDialog,
  showNotification,
  useDialog,
} from 'platform/components';
import {Hide, HStack, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {RefObject} from 'react';
import {useSearchParams} from 'react-router-dom';

import {defaultTo, includes, isNil, not} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  getApiErrorMessages,
  OrderActionKey,
  useGetOrderActionsQuery,
  useGetServiceOrderQuery,
  usePostOrderActionMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  handleApiError,
  ORDER_TABS,
  printFile,
  queryParams,
  workshopSearchParams,
} from '@dms/shared';

import {buildArray, Nullish, openFile, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGridRef} from 'features/datagrid';

import {EmailSmsNotificationBoundary} from '../../../../../components/EmailSmsNotifications/EmailSmsNotificationBoundary';
import {useRefreshDocumentCount} from '../../../../../hooks/useRefreshDocumentCount';
import {OrderType} from '../../../../../types/OrderType';
import {useHandlServiceOrderMandatoryFieldsError} from '../hooks/useHandlServiceOrderMandatoryFieldsError';
import {ChangeOrderVariantForm} from './ChangeOrderVariantForm';
import {DecisionOnClaimModal} from './DecisionOnClaimModal';
import {IntegrationsDropdown} from './IntegrationsDropdown';
import {PackagesDropdown} from './PackagesDropdown';
import {SynchronizeCustomerConfirm} from './SynchronizeCustomerConfirm';

interface OrderCardProps extends RequiredTestIdProps {
  order: OrderType;
  isExpanded: boolean;
  onExpand: () => void;
  onDataChange: () => void;
  serviceOrderDocumentDataGrid: RefObject<DataGridRef>;
  refreshServiceOrderDocumentDataGrid: () => void;
  isOrderReadOnly: boolean;
}

export function OrderActions(props: OrderCardProps) {
  const [, setSearchParams] = useSearchParams();
  const handleMandatoryFieldsError = useHandlServiceOrderMandatoryFieldsError(props.order.id ?? '');

  const [
    isSynchronizeCustomerDialogOpen,
    openSynchronizeCustomerDialog,
    closeSynchronizeCustomerDialog,
  ] = useDialog();

  const {data: serviceOrder} = useGetServiceOrderQuery(
    {
      serviceCaseId: String(props.order.serviceCaseId),
      serviceOrderId: String(props.order.id),
    },
    {skip: !props.order.serviceCaseId || !props.order.id}
  );
  const {data: orderActions} = useGetOrderActionsQuery(
    {
      serviceCaseId: String(props.order.serviceCaseId),
      serviceOrderId: String(props.order.id),
    },
    {skip: !props.order.serviceCaseId || !props.order.id}
  );

  const [postOrderAction, postOrderActionStatus] = usePostOrderActionMutation();

  const refreshDocumentCount = useRefreshDocumentCount(defaultTo('', props.order.serviceCaseId));

  const handleOrderCancel = () => {
    openConfirmDialog({
      text: i18n.t('entity.order.labels.cancel', {name: props.order.number}),
      'data-testid': suffixTestId('cancel', props),
      onConfirm: () =>
        postOrderAction({
          serviceCaseId: String(props.order.serviceCaseId),
          serviceOrderId: String(props.order.id),
          actionKey: 'cancel',
        })
          .unwrap()
          .then(() => showNotification.success(i18n.t('entity.order.notifications.success-cancel')))
          .catch(handleApiError),
    });
  };

  const handleOpenOrderVariantDialog = () => {
    if (
      isNil(props.order.id) ||
      isNil(props.order?.serviceCaseId) ||
      isNil(props.order?.serviceOrderVariantId)
    ) {
      throw new Error('The dialog cannot be opened due to missing data');
    }

    openDialog(
      <ChangeOrderVariantForm
        orderId={props.order?.id ?? ''}
        serviceCaseId={props.order?.serviceCaseId ?? ''}
        orderVariant={props.order?.serviceOrderVariantId ?? ''}
        onSubmitted={props.onDataChange}
        onClose={() => closeDialog('changeOrderVariantDialog')}
        data-testid={suffixTestId('chnageOrderVariant', props)}
      />,
      {
        id: 'changeOrderVariantDialog',
        size: 'small',
        title: i18n.t('entity.order.actions.changeOrder'),
      }
    );
  };

  const handleOrderActionClick = (actionKey: OrderActionKey | Nullish) => {
    match(actionKey)
      .with('change-order', handleOpenOrderVariantDialog)
      .with('cancel', handleOrderCancel)
      .with('payment', () =>
        setSearchParams({
          [queryParams.COMPONENT_SECTIONS_SECTION]: workshopSearchParams.serviceDetail.orders,
          [queryParams.SERVICE_CASE_ORDER_TAB]: String(ORDER_TABS.CHECKOUT),
          [queryParams.SERVICE_CASE_ORDER_ID]: props.order?.id ?? '',
        })
      )
      .with('order-summary', () => {}) // TODO - handle order summary
      .with('send-email-sms', () => {
        if (!props.order.id || !props.order.serviceCaseId) {
          return;
        }

        openDialog(
          <EmailSmsNotificationBoundary
            orderId={props.order.id}
            serviceCaseId={props.order.serviceCaseId}
            dialogId="email-sms-notification-dialog"
            serviceOrder={serviceOrder}
            data-testid={suffixTestId('emailSmsNotification', props)}
          />,
          {
            id: 'email-sms-notification-dialog',
            size: 'large',
            title: i18n.t('entity.order.actions.sendEmailSms'),
          }
        );
      })
      .with('decision-on-claim', () => {
        if (!props.order.serviceCaseId || !props.order.id) {
          return;
        }

        openDialog(
          <DecisionOnClaimModal
            serviceCaseId={props.order.serviceCaseId}
            serviceOrderId={props.order.id}
            onClose={() => closeDialog('decision-on-claim-dialog')}
            data-testid={suffixTestId('decisionOnClaim', props)}
          />,
          {
            id: 'decision-on-claim-dialog',
            title: i18n.t('entity.order.labels.decisionOnClaim'),
          }
        );
      })
      .with('synchronize-customer', () => {
        openSynchronizeCustomerDialog();
      })
      .otherwise(() => {
        if (isNil(actionKey)) {
          throw new Error(`Action callback was not specified for action ${actionKey}`);
        }

        postOrderAction({
          serviceCaseId: String(props.order.serviceCaseId),
          serviceOrderId: String(props.order.id),
          actionKey,
        })
          .unwrap()
          .then((action) => {
            match(actionKey)
              .with('order-list', (key) => printFile(action?.[key]?.pdfUrl ?? ''))
              .with('claim-handling-protocol', 'claim-resolution-protocol', () =>
                printFile(action?.document?.pdfUrl ?? '')
              )
              .with('offer', (key) => openFile(action?.[key]?.pdfUrl ?? ''))
              .otherwise(() => null);

            showNotification.success(i18n.t(`entity.order.notifications.success-${actionKey}`), {
              'data-testid': suffixTestId('successNotification', props),
            });

            refreshDocumentCount();
            if (isNotNil(props.serviceOrderDocumentDataGrid.current)) {
              props.refreshServiceOrderDocumentDataGrid();
            }
          })
          .catch((error) =>
            handleMandatoryFieldsError(error, {
              silent: true,
              callback: () =>
                showNotification.error(
                  getApiErrorMessages(
                    error,
                    i18n.t(`entity.order.notifications.error-${actionKey}`)
                  )
                ),
            })
          );
      });
  };

  const cardActions = buildArray<Action>()
    .when(!!orderActions?.orderActions?.optionClick, {
      type: 'button',
      title: i18n.t(`entity.order.actions.${orderActions?.orderActions?.optionClick}`),
      variant: 'outlined',
      isLoading: postOrderActionStatus.isLoading,
      isDisabled:
        orderActions?.orderActions?.options?.find(
          (option) => option?.key === orderActions?.orderActions?.optionClick
        )?.style === 'disabled',
      onClick: (event) => {
        event.stopPropagation();
        handleOrderActionClick(orderActions?.orderActions?.optionClick);
      },
    })
    .add({
      type: 'iconButton',
      priority: 'tertiary',
      icon: props.isExpanded ? 'navigation/expand_less' : 'navigation/expand_more',
      onClick: (event) => {
        event.stopPropagation();
        props.onExpand();
      },
    });

  const isActionsDropdownVisible =
    !!orderActions?.orderActions?.options?.some((option) => option?.style !== 'hidden') &&
    not(includes(props.order?.state, ['ARCHIVED', 'CANCELLED']));

  return (
    <HStack spacing={2}>
      <Show when={isActionsDropdownVisible}>
        <Dropdown
          placement="bottom-end"
          dropdownControl={
            <IconButton
              size="small"
              icon="navigation/more_vert"
              priority="tertiary"
              data-testid={suffixTestId('small', props)}
            />
          }
          onButtonClick={(event) => event.stopPropagation()}
          data-testid={suffixTestId('actionsDropdown', props)}
        >
          {orderActions?.orderActions?.options?.map((option) => (
            <Hide when={option?.style === 'hidden'} key={option?.position}>
              <Show when={option?.type === 'divider'}>
                <DropdownDivider />
              </Show>
              <Show when={option?.type === 'list-item'}>
                <DropdownItem
                  label={i18n.t(`entity.order.actions.${option?.key}`)}
                  isDisabled={option?.style === 'disabled'}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOrderActionClick(option?.key);
                  }}
                />
              </Show>
            </Hide>
          ))}
        </Dropdown>
      </Show>
      <IntegrationsDropdown
        serviceCaseId={defaultTo('', props.order.serviceCaseId)}
        serviceOrderId={defaultTo('', props.order.id)}
      />
      <Hide when={props.isOrderReadOnly}>
        <PackagesDropdown
          serviceCaseId={defaultTo('', props.order.serviceCaseId)}
          serviceOrderId={defaultTo('', props.order.id)}
          data-testid={suffixTestId('packages', props)}
        />
      </Hide>
      <Actions size="small" data-testid={suffixTestId('actions', props)} actions={cardActions} />
      <SynchronizeCustomerConfirm
        dialogId="synchronize-customer-dialog"
        isOpen={isSynchronizeCustomerDialogOpen}
        onComplete={closeSynchronizeCustomerDialog}
        serviceCaseId={props.order.serviceCaseId}
        serviceOrderId={props.order.id}
        data-testid={suffixTestId('synchronizeCustomer', props)}
      />
    </HStack>
  );
}
