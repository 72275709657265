import {Dialog, showNotification} from 'platform/components';
import {Text} from 'platform/foundation';

import {usePostOrderActionMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface SynchronizeCustomerConfirmProps extends RequiredTestIdProps {
  dialogId: string;
  isOpen: boolean;
  onComplete: () => void;
  serviceCaseId: string | Nullish;
  serviceOrderId: string | Nullish;
}

export function SynchronizeCustomerConfirm(props: SynchronizeCustomerConfirmProps) {
  const [postOrderAction, postOrderActionStatus] = usePostOrderActionMutation();

  const isTransferEnabled = !!postOrderActionStatus.originalArgs?.body?.transferCustomerContract;

  const handleSynchronizeCustomer = (transferCustomerContract: boolean) => {
    if (!props.serviceCaseId || !props.serviceOrderId) {
      return;
    }

    postOrderAction({
      actionKey: 'synchronize-customer',
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        transferCustomerContract,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.order.notifications.success-synchronize-customer'));
        props.onComplete();
      })
      .catch(handleApiError);
  };

  return (
    <Dialog
      size="small"
      id={props.dialogId}
      isOpen={props.isOpen}
      buttons={[
        {
          variant: 'secondary',
          onClick: () => handleSynchronizeCustomer(false),
          isLoading: !isTransferEnabled && postOrderActionStatus.isLoading,
          isDisabled: postOrderActionStatus.isLoading,
          title: i18n.t('general.labels.no'),
          'data-testid': suffixTestId('cancel', props),
        },
        {
          variant: 'primary',
          onClick: () => handleSynchronizeCustomer(true),
          isLoading: isTransferEnabled && postOrderActionStatus.isLoading,
          isDisabled: postOrderActionStatus.isLoading,
          title: i18n.t('general.labels.yes'),
          'data-testid': suffixTestId('confirm', props),
        },
      ]}
      data-testid={suffixTestId('customerContractTransfer', props)}
    >
      <Text>{i18n.t('entity.order.label.synchronizeCustomer')}</Text>
    </Dialog>
  );
}
