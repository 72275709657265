import {Button, openDialog, useDialog} from 'platform/components';
import {HStack, Text, VStack} from 'platform/foundation';

import {useCallback} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import i18n from '@dms/i18n';
import {UploadDocumentsDialog} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useRefreshDocumentCount} from '../../../hooks/useRefreshDocumentCount';
import {EmailSmsNotificationForm, FormDocument} from '../types/EmailSmsNotificationForm';
import {SelectDocuments} from './SelectDocuments';
import {SelectedDocumentsList} from './SelectedDocumentsList';

interface DocumentsViewProps extends RequiredTestIdProps {
  orderId: string;
  serviceCaseId: string;
}

export function DocumentsView(props: DocumentsViewProps) {
  const {control} = useFormContext<EmailSmsNotificationForm>();
  const refreshDocumentCount = useRefreshDocumentCount(props.serviceCaseId);

  const {
    field: {onChange, value, onBlur},
  } = useController({control, name: 'email.data.documents'});

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const updateDocumentSelection = useCallback(
    (newDocuments: FormDocument[]) => {
      onChange(newDocuments);
      onBlur();
    },
    [onBlur, onChange]
  );

  return (
    <VStack spacing={2}>
      <Text size="small">{i18n.t('entity.document.labels.attachments')}</Text>
      <SelectedDocumentsList documents={value} onChange={onChange} />
      <HStack spacing={4}>
        <Button
          variant="link"
          leftIcon="content/add_circle"
          title={i18n.t('entity.document.labels.addDocument')}
          data-testid={suffixTestId('labels.addDocument', props)}
          onClick={() =>
            openDialog(
              <SelectDocuments
                dialogId="sms-email-select-document"
                selectedDocuments={value}
                orderId={props.orderId}
                updateDocumentSelection={updateDocumentSelection}
              />,
              {
                id: 'sms-email-select-document',
                title: i18n.t('entity.document.labels.selectDocuments'),
              }
            )
          }
        />
        <Button
          variant="link"
          leftIcon="action/backup"
          title={i18n.t('entity.document.actions.uploadDocument')}
          onClick={openUploadDialog}
          data-testid={suffixTestId('actions.uploadDocument', props)}
        />
      </HStack>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        contextTarget="service-case"
        contextId={props.serviceCaseId}
        onDocumentCreated={(documents) => {
          const uploadedDocs: FormDocument[] = documents.map((item) => ({
            file: {
              style: [],
              value: {
                contentType: item.file.type,
                id: item.fileId,
                remoteUrl: item.fileUri,
                title: item.file.name,
              },
            },
            uuid: crypto.randomUUID(),
            rowId: '',
          }));

          onChange(uploadedDocs);
          refreshDocumentCount();
        }}
        bulkContext={[
          {target: 'service-case-order', targetId: props.orderId},
          {target: 'service-case', targetId: props.serviceCaseId},
        ]}
      />
    </VStack>
  );
}
