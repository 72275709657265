import {Attributes, AttributesRow, Card, DataStatus} from 'platform/components';
import {Box, Link, Show, Space} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useCallback} from 'react';

import {isNil, isNotNil, mergeAll, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetServiceOrderReturnResponse,
  useGetCustomerV2Query,
  useGetUserQuery,
  useGetVehicleQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';
import {getCustomerName, getUserName, queryParams, Section} from '@dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  useBoolean,
} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {ServiceOrderReturnsMaterialReturnModal} from './components/ServiceOrderReturnsMaterialReturnModal';

interface OverviewProps extends RequiredTestIdProps {
  serviceOrderReturn: GetServiceOrderReturnResponse | Nullish;
  isServiceOrderReturnLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const {serviceOrderReturnId} = useWarehouseParams();

  const formatDateTime = useDateTimeFormatter();

  const [isReturnMaterialModalVisible, openReturnMaterialModal, closeReturnMaterialModal] =
    useBoolean(false);

  const isServiceOrderReturnCompleted =
    props.serviceOrderReturn?.serviceOrderReturn?.state === 'COMPLETED';

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: props.serviceOrderReturn?.serviceOrder?.vehicleId as string},
    {skip: isNilOrEmpty(props.serviceOrderReturn?.serviceOrder?.vehicleId)}
  );

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: hasCustomerError,
  } = useGetCustomerV2Query(
    {customerId: props.serviceOrderReturn?.serviceOrder?.customerId as string},
    {skip: isNilOrEmpty(props.serviceOrderReturn?.serviceOrder?.customerId)}
  );

  const {data: returnedByUser, isLoading: isReturnedByUserLoading} = useGetUserQuery(
    {
      id: props.serviceOrderReturn?.serviceOrderReturn?.returnedBy as string,
    },
    {
      skip: isNilOrEmpty(props.serviceOrderReturn?.serviceOrderReturn?.returnedBy),
    }
  );

  const {data: receivedByUser, isLoading: isReceivedByUserLoading} = useGetUserQuery(
    {
      id: props.serviceOrderReturn?.serviceOrderReturn?.updatedBy as string,
    },
    {
      skip:
        not(isServiceOrderReturnCompleted) &&
        isNilOrEmpty(props.serviceOrderReturn?.serviceOrderReturn?.updatedBy),
    }
  );

  const isLoading =
    props.isServiceOrderReturnLoading ||
    isVehicleLoading ||
    isCustomerLoading ||
    isReturnedByUserLoading ||
    isReceivedByUserLoading;

  const isError = hasVehicleError || hasCustomerError;

  const formatToShortDateTime = (date: string | Nullish) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const actionCallback: ActionCallback = () => {};

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderReturnId}]),
    [serviceOrderReturnId]
  );

  const customerName = getCustomerName(customer);

  const basicInformationAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.returnedBy'),
      value: getUserName(returnedByUser),
    },
    {
      label: i18n.t('general.labels.receivedBy'),
      value: getUserName(receivedByUser),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToShortDateTime(props.serviceOrderReturn?.serviceOrderReturn?.createdAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfReturn'),
      value: formatToShortDateTime(props.serviceOrderReturn?.serviceOrderReturn?.updatedAt),
    },
  ];

  const serviceOrderAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderNumber'),
      content: isNotNil(props.serviceOrderReturn?.serviceOrder?.number) ? (
        <Link
          title={props.serviceOrderReturn?.serviceOrder?.number}
          rightIcon="action/open_in_new"
          size="small"
          href={composePath(workshopRoutes.serviceCaseDetail, {
            params: {id: props.serviceOrderReturn?.serviceOrderReturn?.serviceCaseId},
            queryParams: {
              [queryParams.SERVICE_CASE_ORDER_ID]:
                props.serviceOrderReturn?.serviceOrderReturn?.serviceOrderId,
            },
          })}
          target="_blank"
          data-testid={suffixTestId('linkToServiceOrder', props)}
        />
      ) : (
        EMPTY_PLACEHOLDER
      ),
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderType'),
      value: props.serviceOrderReturn?.serviceOrder?.type,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderVariant'),
      value: props.serviceOrderReturn?.serviceOrder?.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: customerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: vehicle?.title,
    },
  ];

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={60}>
        <Card title={i18n.t('general.labels.basicInformation')}>
          <Attributes
            rows={basicInformationAttributes}
            size="quarter"
            data-testid={suffixTestId('basicInformationAttributes', props)}
          />

          <Space vertical={4} />

          <Card title={i18n.t('entity.warehouse.labels.serviceOrder')} variant="inlineGrey">
            <Attributes
              rows={serviceOrderAttributes}
              size="quarter"
              data-testid={suffixTestId('serviceOrderAttributes', props)}
            />
          </Card>
        </Card>

        <Space vertical={4} />

        <Card
          title={i18n.t('entity.warehouse.labels.workItems')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'content/add_circle',
              title: i18n.t('general.actions.add'),
              onClick: openReturnMaterialModal,
              isDisabled: isServiceOrderReturnCompleted,
              'data-testid': suffixTestId('actions.add', props),
            },
          ]}
        >
          <Box height="30vh">
            <DataGrid
              gridCode="warehouse-service-order-parts-return-item"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              data-testid={suffixTestId('returnedItems', props)}
            />
          </Box>
        </Card>
      </DataStatus>

      <Show when={isReturnMaterialModalVisible}>
        <ServiceOrderReturnsMaterialReturnModal
          serviceOrderReturnId={serviceOrderReturnId}
          serviceOrderId={props.serviceOrderReturn?.serviceOrderReturn?.serviceOrderId as string}
          authorizationProfileId={props.serviceOrderReturn?.serviceOrder?.authorizationProfileId}
          onClose={closeReturnMaterialModal}
          data-testid={suffixTestId('dialogs.returnMaterial', props)}
        />
      </Show>
    </Section>
  );
}
