import {Button} from 'platform/components';
import {Grid, Right, HStack, Box} from 'platform/foundation';

import {FC} from 'react';

import {AddressRequestBodyV2, AddressResponseBodyV2} from '@dms/api';
import i18n from '@dms/i18n';
import {Form, FormRenderProps, $AddressData} from '@dms/teas';

import {appendSuffix, suffixTestId, TestIdProps} from 'shared';

export interface AddressFormProps {
  initialValues?: AddressResponseBodyV2;
  WrapperComponent?: FC<FormRenderProps<AddressRequestBodyV2, AddressResponseBodyV2>>;
  onSubmit: (requestBody: AddressRequestBodyV2) => Promise<Record<string, string> | void>;
  loading?: boolean;
}

const getFormValues = (address: AddressResponseBodyV2): AddressResponseBodyV2 => address;

export const AddressForm: FC<AddressFormProps & TestIdProps> = ({
  initialValues,
  onSubmit,
  loading,
  WrapperComponent,
  ...props
}) => (
  <Form<AddressRequestBodyV2, AddressResponseBodyV2>
    formId={appendSuffix('form', props['data-testid'])}
    onSubmit={(data: AddressRequestBodyV2) => {
      onSubmit({...data, invalid: null});
    }}
    schema={$AddressData}
    initialValues={initialValues}
    getFormValues={getFormValues}
    render={(formRendererProps) => {
      const {Field, handleSubmit} = formRendererProps;

      const form = (
        <>
          <Grid columns={2}>
            <Field name="address.street" label={i18n.t(`entity.address.labels.street`)} />
            <HStack spacing={4}>
              <Field
                name="address.descriptiveNumber"
                label={i18n.t(`entity.address.labels.descriptiveNumber`)}
              />
              <Field
                name="address.orientationNumber"
                label={i18n.t(`entity.address.labels.orientationNumber`)}
              />
            </HStack>
          </Grid>
          <Grid columns={2}>
            <Field name="address.city" label={i18n.t('entity.address.labels.city')} />
            <HStack spacing={4}>
              <Box width={25}>
                <Field name="address.zip" label={i18n.t('entity.address.labels.postalCode')} />
              </Box>
              <Box flex={1}>
                <Field
                  as="country-select"
                  name="address.country"
                  label={i18n.t('entity.address.labels.countryCode')}
                />
              </Box>
            </HStack>
          </Grid>
          <Grid columns={2}>
            <Field as="text" name="type" label={i18n.t('entity.address.labels.type')} />
          </Grid>
        </>
      );

      if (WrapperComponent) {
        return (
          <WrapperComponent
            {...formRendererProps}
            data-testid={suffixTestId('addressForm-wrapper', props)}
          >
            {form}
          </WrapperComponent>
        );
      }

      return (
        <>
          {form}
          <Grid columns={1}>
            <Right>
              <Button
                variant="primary"
                isDisabled={loading}
                onClick={handleSubmit}
                data-testid={suffixTestId('save', props)}
                title={i18n.t('general.actions.save')}
              />
            </Right>
          </Grid>
        </>
      );
    }}
  />
);
