import {closeDialog, showNotification} from 'platform/components';

import {usePostStorageLocationAddLocationApiMutation} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {refreshDatagrid} from 'features/datagrid';

import {SingleLocationFormValue} from '../types/SingleLocationFormValue';
import {SingleLocationForm} from './SingleLocationForm';

export interface CreateSingleLocationProps {
  tireWarehouseId: string;
  dialogId: string;
}

export function CreateSingleLocation(props: CreateSingleLocationProps) {
  const [createLocation, queryStatus] = usePostStorageLocationAddLocationApiMutation();

  const handleSubmit = async (formData: SingleLocationFormValue) => {
    await createLocation({
      contextTarget: 'TIRE-WAREHOUSE',
      contextId: props.tireWarehouseId,
      body: {
        note: formData.note,
        storageLocation: formData.storageLocation.map((item) =>
          item.symbol ? item : {...item, symbol: ''}
        ),
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('storage-location-tire-warehouse');
        closeDialog(props.dialogId);
      })
      .catch(handleApiError);
  };

  return (
    <SingleLocationForm
      dialogId={props.dialogId}
      isLoading={queryStatus.isLoading}
      onFormSubmit={handleSubmit}
      isRowCheckEnabled
    />
  );
}
