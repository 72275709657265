import {Label} from 'platform/components';
import {Box, Display, Link} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

interface StatisticColumnProps extends TestIdProps {
  label: string;
  count?: string | number;
  href?: string;
}

export function StatisticColumn(props: StatisticColumnProps) {
  const formatNumber = useFormatNumber();

  return (
    <>
      <Box paddingBottom={2}>
        <Label data-testid={suffixTestId('label', props)}>{props.label}</Label>
      </Box>
      <Box paddingBottom={4}>
        <Display data-testid={suffixTestId('heading', props)} size={1} headingLevel={2}>
          {formatNumber(parseInt(String(props.count ?? '0'), 10), 0)}
        </Display>
      </Box>
      {props.href && (
        <Link
          size="small"
          href={props.href}
          data-testid={suffixTestId('button-view-all', props)}
          title={i18n.t('general.actions.viewAll')}
        />
      )}
    </>
  );
}
