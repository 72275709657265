import {FormControl, FormField} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';

import {CurrencyCodeType, RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemPurchasePriceProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
  currency: CurrencyCodeType;
}

export function RequestTabLabourBulkEditItemPurchasePrice(
  props: RequestTabLabourBulkEditItemPurchasePriceProps
) {
  const isPurchasePriceDisabled = not(props.formApi.watch('isPurchasePricePerUnitEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isPurchasePricePerUnitEnabled"
            data-testid={suffixTestId('isPurchasePricePerUnitEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isPurchasePriceDisabled}
            control={props.control}
            type="currency"
            name="purchasePricePerUnit"
            label={i18n.t('entity.warehouse.labels.purchasePrice(hourlyRates)')}
            currency={props.currency}
            data-testid={suffixTestId('purchasePricePerUnit', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}
