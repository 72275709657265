import {OptionType} from 'platform/components';

import i18n from '@dms/i18n';

export const DISCOUNT_CHANGE_OPTIONS: OptionType[] = [
  {
    label: i18n.t('entity.customerContract.labels.removeDiscounts'),
    value: 'remove',
  },
  {
    label: i18n.t('entity.customerContract.labels.keepDiscounts'),
    value: 'keep',
  },
];
