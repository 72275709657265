import {
  Card,
  Parameters,
  Actions,
  Action,
  Attributes,
  AttributesRow,
  Parameter,
} from 'platform/components';
import {HStack, VStack, Space, Show, Text, Hide} from 'platform/foundation';

import {isNonEmptyArray, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {Nullish, useToggle, buildArray, DOT_CHARACTER, suffixTestId, TestIdProps} from 'shared';

import {ActionParameters} from '../ActionParameters/ActionParameters';

interface InfoCardProps extends TestIdProps {
  title: string | Nullish;
  subTitle?: string | Nullish;
  description?: Parameter[] | Nullish;
  status?: Parameter[] | Nullish;
  actions?: Action[];
  attributes?: AttributesRow[];
  isOpenByDefault?: boolean;
  shouldShowActionParameters?: boolean;
  customerId?: string;
  selectedContactId?: string;
}

export function InfoCard(props: InfoCardProps) {
  const [isOpen, toggleOpen] = useToggle(props.isOpenByDefault);

  const actions = buildArray<Action>(props.actions).when(isNonEmptyArray(props.attributes), {
    type: 'iconButton',
    icon: isOpen ? 'navigation/expand_less' : 'navigation/expand_more',
    onClick: toggleOpen,
  });

  return (
    <Card variant="inlineWhite">
      <HStack align="flex-start">
        <VStack>
          <HStack spacing={1}>
            <Text size="small" alternative data-testid={suffixTestId('title', props)}>
              {props.title}
            </Text>
            <Show when={isNotNilOrEmpty(props.subTitle)}>
              <Text color="tertiary" size="small">
                {DOT_CHARACTER}
              </Text>
              <Text size="small" data-testid={suffixTestId('subTitle', props)}>
                {props.subTitle}
              </Text>
            </Show>
          </HStack>
          <HStack spacing={1}>
            <Show when={isNotNilOrEmpty(props.description)}>
              <Show when={props.shouldShowActionParameters}>
                <ActionParameters
                  parameters={props.description}
                  customerId={props.customerId}
                  color="secondary"
                  size="small"
                  tooltipLabelSms={i18n.t('general.actions.sendMessage')}
                  tooltipLabelEmail={i18n.t('general.actions.sendEmail')}
                  selectedContactId={props.selectedContactId}
                  data-testid={suffixTestId('description', props)}
                />
              </Show>
              <Hide when={props.shouldShowActionParameters}>
                <Parameters
                  parameters={props.description}
                  color="secondary"
                  size="small"
                  data-testid={suffixTestId('description', props)}
                />
              </Hide>
            </Show>
            <Show when={isNotNilOrEmpty(props.status)}>
              <Show when={isNotNilOrEmpty(props.status) && isNotNilOrEmpty(props.description)}>
                <Text color="tertiary" size="small">
                  {DOT_CHARACTER}
                </Text>
              </Show>
              <Parameters
                parameters={props.status}
                color="danger"
                size="small"
                data-testid={suffixTestId('status', props)}
              />
            </Show>
          </HStack>
        </VStack>
        <Space fillAvailable />
        <Actions size="small" actions={actions} data-testid={suffixTestId('actions', props)} />
      </HStack>
      <Show when={isOpen && isNonEmptyArray(props.attributes)}>
        <Space vertical={4} />
        <Attributes rows={props.attributes} size="third" data-testid={props['data-testid']} />
      </Show>
    </Card>
  );
}
