import {match} from 'ts-pattern';

import {useNavigate, useParams} from 'react-router-dom';

import {testIds, vehiclesRoutes} from '@dms/routes';
import {ServiceVehicleFormMode, ServiceVehicleFormVariant, ServiceVehicleForm} from '@dms/shared';

import {composePath} from 'shared';

interface EditOrCloneServiceVehicleProps {
  mode: ServiceVehicleFormMode;
  variant: ServiceVehicleFormVariant;
}

export function EditOrCloneServiceVehicle(props: EditOrCloneServiceVehicleProps) {
  const routeParams = useParams();
  const vehicleId = routeParams.id;

  const navigate = useNavigate();

  return (
    <ServiceVehicleForm
      mode={props.mode}
      variant={props.variant}
      vehicleId={vehicleId}
      onDiscard={() => navigate(-1)}
      onSuccess={(vehicleId) =>
        navigate(composePath(vehiclesRoutes.detail, {params: {id: vehicleId}}))
      }
      data-testid={match(props.mode)
        .with(ServiceVehicleFormMode.EDIT, () => testIds.vehicles.editServiceVehicle('edit'))
        .otherwise(() => testIds.vehicles.cloneServiceVehicle('clone'))}
    />
  );
}
