import {isParameterWithTooltip, openDialog, Parameter, Tooltip} from 'platform/components';
import {Hide, HStack, Link, Show, Text} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {Fragment} from 'react';

import {always, defaultTo, reject} from 'ramda';
import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {
  DOT_CHARACTER,
  EMPTY_PLACEHOLDER,
  getRandomId,
  Nullish,
  suffixTestId,
  TestIdProps,
} from 'shared';

import {SendEmail} from '../SendEmail/SendEmail';
import {SendSms} from '../SendSms/SendSms';

interface ActionParametersProps extends TestIdProps {
  parameters: Parameter[] | Nullish;
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'white';
  size?: 'base' | 'small' | 'xSmall';
  customerId?: string;
  tooltipLabelSms?: string;
  tooltipLabelEmail?: string;
  selectedContactId?: string;
}

export function ActionParameters(props: ActionParametersProps) {
  const size = props.size ?? 'xSmall';
  const filteredParameters = reject(isNilOrEmpty, props.parameters ?? []);
  if (!filteredParameters?.length) {
    return null;
  }

  return (
    <HStack spacing={1} data-testid={props['data-testid']} wrap align="center">
      {filteredParameters.map((parameter, index) => {
        const hasTooltip = isParameterWithTooltip(parameter);
        const title = hasTooltip ? parameter.title : parameter;
        const isTitleEmail = title?.includes('@');
        const tooltip = match([hasTooltip, isTitleEmail])
          .with(
            [true, Pattern.boolean],
            always(parameter && typeof parameter !== 'string' ? parameter.tooltip : '')
          )
          .with([Pattern.boolean, true], always(props.tooltipLabelEmail))
          .otherwise(always(props.tooltipLabelSms));

        const shouldShowAction =
          title !== EMPTY_PLACEHOLDER &&
          title !== i18n.t('entity.customer.labels.noEmail') &&
          title !== i18n.t('entity.customer.labels.noPhoneNumber');

        return (
          <Fragment key={`${parameter}_${getRandomId()}`}>
            <Show when={isPositive(index)}>
              <Text color="tertiary" size={size}>
                {DOT_CHARACTER}
              </Text>
            </Show>
            <Show when={shouldShowAction}>
              <Tooltip label={tooltip} placement="top" hasAutoWidth>
                <Link
                  data-testid={`${props['data-testid']}-sendLink-[${index.toString()}]`}
                  onClick={() =>
                    index === 0
                      ? openDialog(
                          <SendSms
                            data-testid={`${props['data-testid']}-sendSmsModal`}
                            customerId={defaultTo('', props.customerId)}
                            selectedContactId={props.selectedContactId}
                          />,
                          {
                            scrollBehavior: 'outside',
                            title: i18n.t('general.labels.smsMessage'),
                          }
                        )
                      : openDialog(
                          <SendEmail
                            data-testid={`${props['data-testid']}-sendEmailModal`}
                            customerId={props.customerId}
                          />,
                          {
                            scrollBehavior: 'outside',
                            title: i18n.t('general.labels.email'),
                          }
                        )
                  }
                  title={title}
                  size={size}
                />
              </Tooltip>
            </Show>
            <Hide when={shouldShowAction}>
              <Text
                data-testid={suffixTestId(`parameter-[${index}]`, props)}
                color={props.color}
                size={size}
                noWrap
              >
                {title}
              </Text>
            </Hide>
          </Fragment>
        );
      })}
    </HStack>
  );
}
