import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {BaseDirectSaleVariant} from '@dms/api';

import {Nullish} from 'shared';

export const getOptionsFromDirectSaleVariants = (
  directSaleVariants: BaseDirectSaleVariant[] | Nullish
): Option[] => {
  if (isNil(directSaleVariants)) {
    return [];
  }
  return directSaleVariants.map((directSaleVariant) => ({
    label: directSaleVariant.name,
    value: directSaleVariant.directSaleVariantId,
  }));
};
