import {ColorSchemeType, getColorsFromScheme} from 'platform/components';
import {Box} from 'platform/foundation';

interface DotProps {
  color: ColorSchemeType;
  isSubtle?: boolean;
}

export function DotIcon(props: DotProps) {
  const {backgroundColor} = getColorsFromScheme(props.color, props.isSubtle);

  return <Box width={4} height={4} backgroundColor={backgroundColor} borderRadius="circular" />;
}
