import {Attributes, AttributesRow, Card, DataStatus} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetManufacturerQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';
import {ReceiveNoteItemDetails} from '../../../../../types/ReceiveNote';

interface DeliveryItemDetailCardProps extends TestIdProps {
  deliveryItemDetail: ReceiveNoteItemDetails['deliveryItemDetail'] | Nullish;
  deliveryNoteQuantity: number | Nullish;
  handlingUnit: string | Nullish;
  isDisabled: boolean;
  isExpanded: boolean;
  isRefreshingDetails: boolean;
}

export function DeliveryItemDetailCard(props: DeliveryItemDetailCardProps) {
  const manufacturerNumber = props.deliveryItemDetail?.manufacturerNumber;

  const manufacturerId = props.deliveryItemDetail?.manufacturerId;

  const deliveryNoteQuantity = `${props.deliveryNoteQuantity ?? 0} ${props.handlingUnit?.toLowerCase() ?? FALLBACK_HANDLING_UNIT.toLowerCase()}`;

  const supplierNumber = props.deliveryItemDetail?.supplierNumber;

  const name = props.deliveryItemDetail?.name;

  const description = props.deliveryItemDetail?.description;

  const {data: manufacturer, isLoading: isManufacturerLoading} = useGetManufacturerQuery(
    {manufacturerId: manufacturerId as string},
    {skip: isNilOrEmpty(manufacturerId)}
  );

  const isLoading = props.isRefreshingDetails || isManufacturerLoading;

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: manufacturer?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.deliveryNoteQuantity'),
      value: deliveryNoteQuantity,
    },
    {
      label: i18n.t('entity.warehouse.labels.supplierNumber'),
      value: supplierNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: name,
    },
    {
      label: i18n.t('entity.warehouse.labels.description'),
      value: description,
    },
  ];

  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.warehouse.labels.deliveryItemDetail')}
      isClosedByDefault={props.isDisabled}
      isExpandable={props.isExpanded}
      data-testid={props['data-testid']}
    >
      <DataStatus isLoading={isLoading} minHeight={49}>
        <Attributes rows={rows} data-testid={suffixTestId('attributes', props)} />
      </DataStatus>
    </Card>
  );
}
