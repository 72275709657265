import {TabProps, Tabs} from 'platform/components';
import {Show} from 'platform/foundation';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {selectSaleVehicle} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {usePermissions} from '@dms/shared';

import {buildArray, composePath} from 'shared';

import {useRouter} from '../../hooks/useRouter';
import {selectInspections} from '../../store/carAudit/selectors';
import {InspectionIdForAuditId} from '../../types/InspectionIdForAuditId';
import {Audit} from './components/Audit';
import {ConditionHeader} from './components/ConditionHeader';
import {InspectionHeader} from './components/InspectionHeader';
import {Inspections} from './components/Inspections';
import {NewInspectionButton} from './components/NewInspectionButton';
import {useHandleForeignInspection} from './hooks/useHandleForeignInspection';

interface NavigationTab {
  label: string;
  route: string;
}

export function VehicleInspection(props: Omit<InspectionIdForAuditId, 'auditId'>) {
  const router = useRouter();
  const {id, inspectionId} = useParams();
  const {data: saleVehicle} = useSelector(selectSaleVehicle(id ?? ''));
  const isSaleVehicle = isNotNil(saleVehicle);

  const inspections = useSelector(selectInspections);
  const currentInspection = inspections?.find((inspection) => inspection.id === inspectionId);

  const [hasReadInspectionPermission, hasCreateInspectionPermission] = usePermissions({
    permissionKeys: ['readInspection', 'createInspection'],
    scopes: {
      readInspection: {inspectionType: currentInspection?.inspectionType},
    },
  });

  useHandleForeignInspection(id, currentInspection);

  const navigationTabs = buildArray<NavigationTab & {id: string}>()
    .when(isSaleVehicle, {
      label: i18n.t('entity.vehicle.labels.vehicleCondition'),
      route: vehiclesRoutes.conditionDetail,
      id: 'vehicleCondition',
    })
    .when(hasReadInspectionPermission, {
      label: i18n.t('entity.vehicle.labels.inspections'),
      route: vehiclesRoutes.conditionsInspections,
      id: 'inspections',
    });

  if (inspectionId) {
    return (
      <Audit
        data-testid="inspection"
        notFoundText={i18n.t('entity.condition.notifications.inspectionNotFound')}
        vehicleId={id}
        header={<InspectionHeader data-testid="inspection-header" />}
      />
    );
  }
  const activeTabId =
    navigationTabs.find((navigationTab) => navigationTab.route === router.route)?.id ??
    navigationTabs[0]?.id;

  const handleChangeTab = (tabId: string) => {
    const nextTabRoute = navigationTabs.find((item) => item.id === tabId)?.route as `${string}/:id`;

    if (nextTabRoute) {
      router.push(composePath(nextTabRoute, {params: {id}}));
    }
  };

  const tabs = buildArray<TabProps>()
    .when(isSaleVehicle, {
      id: 'vehicleCondition',
      title: i18n.t('entity.vehicle.labels.vehicleCondition'),
      'data-testid': testIds.vehicles.conditions('condition'),
      content: (
        <Audit
          data-testid={testIds.vehicles.conditions('auditTab')}
          notFoundText={i18n.t('entity.condition.notifications.vehicleConditionNotFound')}
          vehicleId={id}
          header={
            <ConditionHeader
              data-testid={testIds.vehicles.conditions('auditTab')}
              shouldUseAuditIdForUpdateTemplate={props.shouldUseAuditIdForUpdateTemplate}
            />
          }
        />
      ),
    })
    .when(hasReadInspectionPermission, {
      id: 'inspections',
      title: i18n.t('entity.vehicle.labels.inspections'),
      'data-testid': testIds.vehicles.conditions('inspections'),
      content: (
        <>
          <Show when={hasCreateInspectionPermission}>
            <NewInspectionButton />
          </Show>
          <Inspections
            data-testid={testIds.vehicles.conditions('inspections-section')}
            getRedirectRoute={(_id, _inspectionId) =>
              composePath(vehiclesRoutes.inspectionDetail, {
                params: {
                  id: _id,
                  inspectionId: _inspectionId,
                },
              })
            }
          />
        </>
      ),
    });

  return (
    <Tabs
      onChange={handleChangeTab}
      variant="condensed"
      activeTabId={activeTabId}
      tabs={tabs}
      data-testid={testIds.vehicles.conditionDetail()}
    />
  );
}
