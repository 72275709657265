import {Attributes, AttributesRow, Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

type ArticleDetailAttributes = {
  name: string | Nullish;
  number: string | Nullish;
  manufacturerName: string | Nullish;
  storageLocation: string | Nullish;
  categoryName: string | Nullish;
};

interface SupplierArticlesAddNewArticleDetailProps extends RequiredTestIdProps {
  isArticleDetailFetching: boolean;
  articleDetail: ArticleDetailAttributes;
  onRefreshArticleDetail: VoidFunction;
}

export function SupplierArticlesAddNewArticleDetail(
  props: SupplierArticlesAddNewArticleDetailProps
) {
  const articleDetail: AttributesRow[] = [
    {label: i18n.t('entity.warehouse.labels.name'), value: props.articleDetail?.name},
    {label: i18n.t('entity.warehouse.labels.number'), value: props.articleDetail?.number},
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: props.articleDetail?.manufacturerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.storageLocation'),
      value: props.articleDetail?.storageLocation,
    },
    {label: i18n.t('entity.warehouse.labels.category'), value: props.articleDetail?.categoryName},
  ];

  return (
    <VStack spacing={4}>
      <Card
        title={i18n.t('entity.warehouse.labels.articleDetail')}
        variant="inlineGrey"
        actions={[
          {
            type: 'button',
            variant: 'link',
            size: 'small',
            leftIcon: 'navigation/refresh',
            title: i18n.t('general.actions.refresh'),
            onClick: props.onRefreshArticleDetail,
            'data-testid': suffixTestId('actions.refreshArticleDetail', props),
          },
        ]}
        data-testid={props['data-testid']}
      >
        <DataStatus isLoading={props.isArticleDetailFetching} minHeight={41}>
          <Attributes
            rows={articleDetail}
            size="quarter"
            data-testid={suffixTestId('attributes', props)}
          />
        </DataStatus>
      </Card>
    </VStack>
  );
}
