/* eslint-disable eag/match-named-export */
import {z} from 'zod';

import {BasePriceSchema} from './aftersalesCommon';
import {BaseVoidResponseSchema} from './base';

export const BillingRecipientSchema = z.enum([
  'OPERATOR',
  'OWNER',
  'USER',
  'MANAGER',
  'INVOICE',
  'OTHER',
  'DEFAULT',
]);
export type BillingRecipient = z.infer<typeof BillingRecipientSchema>;

export const AfsAdditionalCustomerTypeSchema = z.enum(['OPERATOR', 'RECIPIENT']);
export type AfsAdditionalCustomerType = z.infer<typeof AfsAdditionalCustomerTypeSchema>;

export const AddressSchema = z.object({
  street: z.string(),
  descriptiveNumber: z.string().nullable(),
  orientationNumber: z.string().nullable(),
  city: z.string(),
  zip: z.string(),
  addressComplement: z.string().nullable(),
  country: z.string(),
  prefix: z.string().nullable(),
  district: z.string().nullable(),
  state: z.string().nullable(),
  postal: z.string().nullish(),
  coordinates: z
    .object({
      latitude: z.string(),
      longitude: z.string(),
    })
    .nullish(),
  externalAddressId: z.string().nullish(),
});

export type Address = z.infer<typeof AddressSchema>;

export const BankAccountSchema = z.object({
  id: z.string().nullish(),
  name: z.string().nullish(),
  countryCode: z.string().nullish(),
  ownerName: z.string().nullish(),
  iban: z.string().nullish(),
  swiftBic: z.string().nullish(),
  currency: z.string().nullish(),
  number: z.string().nullish(),
  bankCode: z.string().nullish(),
});
export type BankAccount = z.infer<typeof BankAccountSchema>;

export const IdentityCardSchema = z.object({
  id: z.string(),
  type: z.string().nullable(),
  cardNumber: z.string(),
  issuedOn: z.string().nullable(),
  validUntil: z.string().nullable(),
  issuer: z.string().nullable(),
  issuedInCountryCode: z.string().nullable(),
  note: z.string().nullable(),
});
export type IdentityCard = z.infer<typeof IdentityCardSchema>;

export const PersonSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  middleName: z.string().nullable(),
  titleBefore: z.string().nullable(),
  titleAfter: z.string().nullable(),
  genderKey: z.string().nullable(),
  roles: z.string().nullable(),
  citizenshipCode: z.string().nullable(),
  birthdate: z.string().nullable(),
  personalIdentifier: z.string().nullable(),
  phoneNumbers: z.array(
    z.object({
      type: z.string().nullable(),
      countryCode: z.string(),
      prefix: z.string(),
      number: z.string(),
    })
  ),
  emails: z.array(
    z.object({
      type: z.string().nullable(),
      email: z.string(),
    })
  ),
  identityCards: z.array(IdentityCardSchema),
  permanentAddress: z
    .object({
      id: z.string(),
      address: AddressSchema,
      type: z.string().nullable(),
      invalid: z.boolean().nullable(),
    })
    .nullable(),
});
export type Person = z.infer<typeof PersonSchema>;

export const BusinessInfoSchema = z.object({
  id: z.string().nullish(),
  businessInfo: z
    .object({
      countryOfRegistrationCode: z.string().nullish(),
      registrationNumber: z.string().nullish(),
      vatNumber: z.string().nullish(),
      tradeName: z.string().nullish(),
      fileNumber: z.string().nullish(),
    })
    .nullish(),
  address: z
    .object({
      id: z.string().nullish(),
      address: AddressSchema.nullish(),
      type: z.string().nullish(),
      invalid: z.boolean().nullish(),
    })
    .nullish(),
});
export type BusinessInfo = z.infer<typeof BusinessInfoSchema>;

export const BillingInformationSchema = z.object({
  customerInformation: z.object({
    id: z.string(),
  }),
  contractInformation: z
    .object({
      id: z.string().nullish(),
      permanent: z.boolean().nullish(),
      legalForm: z.enum(['LEGAL_ENTITY', 'NATURAL_PERSON', 'SELF_EMPLOYED']).nullish(),
      bankAccounts: z.array(BankAccountSchema).nullish(),
      person: PersonSchema.nullish(),
      businessInfo: BusinessInfoSchema.nullish(),
      customFieldsPayload: z
        .array(
          z.object({
            definitionId: z.string().nullish(),
            value: z
              .object({
                type: z
                  .enum(['integer', 'decimal', 'string', 'money', 'date', 'datetime', 'boolean'])
                  .nullish(),
                value: z.array(z.boolean().nullish()).nullish(),
              })
              .nullish(),
          })
        )
        .nullish(),
      isDeleted: z.boolean().nullish(),
    })
    .nullish(),
  selectedIdentityCards: z.array(IdentityCardSchema).nullish(),
  isAbroadSaleAllowed: z.boolean(),
  abroadSale: z.string().nullish(),
});
export type BillingInformation = z.infer<typeof BillingInformationSchema>;

export const ForeignCurrencyPaymentSchema = z.object({
  exchangeRateRatio: z.number(),
  exchangeCurrency: z.string(),
});
export type ForeignCurrencyPayment = z.infer<typeof ForeignCurrencyPaymentSchema>;

export const AftersalesPaymentMethodSchema = z.enum(['BANK_TRANSFER', 'CASH', 'CARD', 'INTERNAL']);
export type AftersalesPaymentMethod = z.infer<typeof AftersalesPaymentMethodSchema>;

export const AftersalesPaymentStateSchema = z.enum(['CONCEPT', 'PENDING', 'PAID']);
export type AftersalesPaymentState = z.infer<typeof AftersalesPaymentStateSchema>;

export const FileMetadataSchema = z.object({
  fileId: z.string(),
  name: z.string(),
  fileUrl: z.string(),
  remoteId: z.string().nullish(),
  documentType: z
    .enum([
      'PAYMENT_INVOICE',
      'PAYMENT_PROFORMA_INVOICE',
      'INTERNAL_INVOICE_DOCUMENT',
      'PAYMENT_TAX_DOCUMENT',
      'PAYMENT_CASH_RECEIPT',
    ])
    .nullish(),
  createdAt: z.string().nullish(),
  createdBy: z.string(),
});
export type FileMetadata = z.infer<typeof FileMetadataSchema>;

export const PaymentSchema = z.object({
  checkoutPaymentId: z.string(),
  discriminator: z.enum(['BALANCE', 'DEPOSIT']),
  paymentAmount: BasePriceSchema,
  remainingPaymentAmount: BasePriceSchema,
  exchangeRate: z.string().nullish(),
  foreignCurrencyPaymentAmount: BasePriceSchema.nullish(),
  remainingForeignCurrencyPaymentAmount: BasePriceSchema.nullish(),
  issuedOn: z.string().nullish(),
  due: z.number().nullish(),
  dueDate: z.string().nullish(),
  dateOfTaxableSupply: z.string().nullish(),
  method: AftersalesPaymentMethodSchema,
  bankAccount: z
    .object({
      id: z.string(),
      isPrimary: z.boolean(),
      name: z.string().nullish(),
      number: z.string().nullish(),
      bankName: z.string().nullish(),
      iban: z.string().nullish(),
      swift: z.string().nullish(),
      currency: z.string(),
    })
    .nullish(),
  cashRegisterId: z.string().nullish(),
  note: z.string().nullish(),
  paymentState: AftersalesPaymentStateSchema,
  payDate: z.string().nullish(),
  files: z.array(FileMetadataSchema).nullish(),
  invoiceId: z.string().nullish(),
  invoices: z.array(FileMetadataSchema).nullish(),
  proformaInvoiceId: z.string().nullish(),
  proformaInvoices: z.array(FileMetadataSchema).nullish(),
  internalInvoices: z.array(FileMetadataSchema).nullish(),
  taxDocumentId: z.string().nullish(),
  taxableDocuments: z.array(FileMetadataSchema).nullish(),
  cashRegisterDocumentId: z.string().nullish(),
  cashReceipts: z.array(FileMetadataSchema).nullish(),
});
export type Payment = z.infer<typeof PaymentSchema>;

export const GetCheckoutPaymentByIdApiResponseSchema = PaymentSchema.nullish();
export type GetCheckoutPaymentByIdApiResponse = z.infer<
  typeof GetCheckoutPaymentByIdApiResponseSchema
>;

export const GetCheckoutPaymentsApiResponseSchema = z.array(PaymentSchema).nullish();
export type GetCheckoutPaymentsApiResponse = z.infer<typeof GetCheckoutPaymentsApiResponseSchema>;

export const PostCheckoutAdditionalCustomerApiResponseSchema = z
  .object({checkoutAdditionalCustomerId: z.string()})
  .nullish();
export type PostCheckoutAdditionalCustomerApiResponse = z.infer<
  typeof PostCheckoutAdditionalCustomerApiResponseSchema
>;

export const DeleteCheckoutAdditionalCustomerApiArgSchema = z.object({
  checkoutId: z.string(),
  additionalCustomerId: z.string(),
});
export type DeleteCheckoutAdditionalCustomerApiArg = z.infer<
  typeof DeleteCheckoutAdditionalCustomerApiArgSchema
>;

export const PatchCheckoutAdditionalCustomerApiArgSchema = z.object({
  checkoutId: z.string(),
  additionalCustomerId: z.string(),
  body: z
    .object({
      additionalCustomerType: AfsAdditionalCustomerTypeSchema,
      contractInformationId: z.string(),
    })
    .nullish(),
});
export type PatchCheckoutAdditionalCustomerApiArg = z.infer<
  typeof PatchCheckoutAdditionalCustomerApiArgSchema
>;

export const GetCheckoutAllowedCustomerApiResponseSchema = z
  .array(
    z.object({
      billingRecipient: BillingRecipientSchema,
      customerId: z.string(),
    })
  )
  .nullish();
export type GetCheckoutAllowedCustomerApiResponse = z.infer<
  typeof GetCheckoutAllowedCustomerApiResponseSchema
>;

export const AdditionalCustomerSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  additionalCustomerType: AfsAdditionalCustomerTypeSchema,
  contractInformationId: z.string().nullable(),
});

export type AdditionalCustomer = z.infer<typeof AdditionalCustomerSchema>;

export const GetCheckoutBillingInformationApiResponseSchema = z
  .object({
    additionalCustomers: z.array(AdditionalCustomerSchema).nullable(),
    billingRecipient: BillingRecipientSchema,
    isEditBillingRecipientAllowed: z.boolean(),
    billingInformation: BillingInformationSchema,
  })
  .nullish();
export type GetCheckoutBillingInformationApiResponse = z.infer<
  typeof GetCheckoutBillingInformationApiResponseSchema
>;

export const PatchCheckoutForeignCurrencyPaymentDisallowApiResponseSchema = BaseVoidResponseSchema;

export type PatchCheckoutForeignCurrencyPaymentDisallowApiResponse = z.infer<
  typeof PatchCheckoutForeignCurrencyPaymentDisallowApiResponseSchema
>;

export const PostCheckoutDepositPaymentCreateApiResponseSchema = z
  .object({
    depositPaymentId: z.string(),
  })
  .nullish();
export type PostCheckoutDepositPaymentCreateApiResponse = z.infer<
  typeof PostCheckoutDepositPaymentCreateApiResponseSchema
>;

export const PostCheckoutForeignCurrencyPaymentAllowApiResponseSchema = BaseVoidResponseSchema;

export type PostCheckoutForeignCurrencyPaymentAllowApiResponse = z.infer<
  typeof PostCheckoutForeignCurrencyPaymentAllowApiResponseSchema
>;

export const PostCheckoutPaymentIssueApiResponseSchema = z
  .object({
    invoiceId: z.string(),
  })
  .nullish();
export type PostCheckoutPaymentIssueApiResponse = z.infer<
  typeof PostCheckoutPaymentIssueApiResponseSchema
>;

export const PutCheckoutDepositPaymentAllowApiResponseSchema = z
  .object({
    depositPaymentId: z.string(),
  })
  .nullish();
export type PutCheckoutDepositPaymentAllowApiResponse = z.infer<
  typeof PutCheckoutDepositPaymentAllowApiResponseSchema
>;

export const GetCheckoutByIdApiResponseSchema = z
  .object({
    id: z.string(),
    context: z
      .object({
        target: z.enum(['service-order', 'direct-sale']),
        id: z.string(),
      })
      .nullish(),
    typeOfSale: z.enum(['DEFAULT', 'INTERNAL']),
    billingRecipient: BillingRecipientSchema,
    isEditBillingRecipientAllowed: z.boolean(),
    billingInformation: BillingInformationSchema.nullish(),
    totalPrice: z
      .object({
        withoutVat: BasePriceSchema.nullish(),
        vat: BasePriceSchema.nullish(),
        withVat: BasePriceSchema.nullish(),
      })
      .nullish(),
    currency: z.string(),
    isForeignCurrencyPayment: z.boolean(),
    foreignCurrencyPayment: z
      .object({
        exchangeRateRatio: z.number(),
        exchangeCurrency: z.string(),
      })
      .nullish(),
    isDepositAllowed: z.boolean(),
  })
  .nullish();

export const CheckoutIdPaymentIdArgSchema = z.object({
  checkoutId: z.string(),
  paymentId: z.string(),
});
export type GetCheckoutPaymentByIdApiArg = z.infer<typeof CheckoutIdPaymentIdArgSchema>;

export type DeleteCheckoutDepositPaymentApiArg = z.infer<typeof CheckoutIdPaymentIdArgSchema>;

export type PostCheckoutPaymentCancelApiArg = z.infer<typeof CheckoutIdPaymentIdArgSchema>;

export const PatchCheckoutPaymentApiArgSchema = CheckoutIdPaymentIdArgSchema.extend({
  body: z.object({
    paymentAmount: BasePriceSchema.nullish(),
    exchangeRate: z.string().nullish(),
    foreignCurrencyPaymentAmount: BasePriceSchema.nullish(),
    issueDate: z.string(),
    due: z.number().nullish(),
    dueDate: z.string(),
    dateOfTaxableSupply: z.string(),
    method: AftersalesPaymentMethodSchema,
    bankAccount: z
      .object({
        isPrimary: z.boolean(),
        name: z.string().nullish(),
        number: z.string().nullish(),
        bankName: z.string().nullish(),
        iban: z.string().nullish(),
        swift: z.string().nullish(),
        currency: z.string(),
      })
      .nullish(),
    cashRegisterId: z.string().nullish(),
    note: z.string().nullish(),
  }),
});
export type PatchCheckoutPaymentApiArg = z.infer<typeof PatchCheckoutPaymentApiArgSchema>;

export const PostCheckoutPaymentIssueApiArgSchema = CheckoutIdPaymentIdArgSchema.extend({
  body: z
    .object({
      issuedOn: z.string(),
    })
    .nullish(),
});
export type PostCheckoutPaymentIssueApiArg = z.infer<typeof PostCheckoutPaymentIssueApiArgSchema>;

export const PostCheckoutRecordPaidApiArgSchema = CheckoutIdPaymentIdArgSchema.extend({
  body: z.object({
    payDate: z.string(),
    amount: z.number(),
    currency: z.string(),
    paymentMethod: AftersalesPaymentMethodSchema.nullish(),
    cashRegisterId: z.string().nullish(),
    withCashRegisterDocument: z.boolean(),
    tenantBankAccount: z
      .object({
        name: z.string().nullish(),
        accountName: z.string(),
        accountNumber: z.string(),
        isPrimary: z.boolean(),
        currencyCode: z.string(),
        swift: z.string().nullish(),
        iban: z.string().nullish(),
      })
      .nullish(),
  }),
});
export type PostCheckoutRecordPaidApiArg = z.infer<typeof PostCheckoutRecordPaidApiArgSchema>;

export const CheckoutIdArgSchema = z.object({
  checkoutId: z.string(),
});
export type GetCheckoutByIdApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type PutCheckoutDepositPaymentAllowApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type PutCheckoutDepositPaymentDisallowApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type GetCheckoutPaymentsApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type GetCheckoutAllowedCustomerApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type GetCheckoutBillingInformationApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type PatchCheckoutForeignCurrencyPaymentDisallowApiArg = z.infer<typeof CheckoutIdArgSchema>;

export type PostCheckoutDepositPaymentCreateApiArg = z.infer<typeof CheckoutIdArgSchema>;

export const PutCheckoutSetBillingInformationApiArgSchema = CheckoutIdArgSchema.extend({
  body: z.object({
    billingRecipient: BillingRecipientSchema,
    customerId: z.string(),
  }),
});

export type PutCheckoutSetBillingInformationApiArg = z.infer<
  typeof PutCheckoutSetBillingInformationApiArgSchema
>;

export const PostCheckoutAdditionalCustomerApiArgSchema = CheckoutIdArgSchema.extend({
  body: z
    .object({
      customerId: z.string(),
      additionalCustomerType: AfsAdditionalCustomerTypeSchema,
    })
    .nullish(),
});
export type PostCheckoutAdditionalCustomerApiArg = z.infer<
  typeof PostCheckoutAdditionalCustomerApiArgSchema
>;

export const PostCheckoutForeignCurrencyPaymentAllowApiArgSchema = CheckoutIdArgSchema.extend({
  body: ForeignCurrencyPaymentSchema.nullish(),
});

export type PostCheckoutForeignCurrencyPaymentAllowApiArg = z.infer<
  typeof PostCheckoutForeignCurrencyPaymentAllowApiArgSchema
>;

export const PutCheckoutSetContractInformationApiArgSchema = CheckoutIdArgSchema.extend({
  body: z
    .object({
      customerContractInformationId: z.string(),
    })
    .nullish(),
});
export type PutCheckoutSetContractInformationApiArg = z.infer<
  typeof PutCheckoutSetContractInformationApiArgSchema
>;

export type GetCheckoutByIdApiResponse = z.infer<typeof GetCheckoutByIdApiResponseSchema>;

export const PutCheckoutDepositPaymentDisallowApiResponseSchema = BaseVoidResponseSchema;

export type PutCheckoutDepositPaymentDisallowApiResponse = z.infer<
  typeof PutCheckoutDepositPaymentDisallowApiResponseSchema
>;

export const PutCheckoutSetBillingInformationApiResponseSchema = BaseVoidResponseSchema;

export type PutCheckoutSetBillingInformationApiResponse = z.infer<
  typeof PutCheckoutSetBillingInformationApiResponseSchema
>;

export const PutCheckoutSetContractInformationApiResponseSchema = BaseVoidResponseSchema;

export type PutCheckoutSetContractInformationApiResponse = z.infer<
  typeof PutCheckoutSetContractInformationApiResponseSchema
>;

export const PatchCheckoutPaymentApiResponseSchema = BaseVoidResponseSchema;

export type PatchCheckoutPaymentApiResponse = z.infer<typeof PatchCheckoutPaymentApiResponseSchema>;

export const DeleteCheckoutAdditionalCustomerApiResponseSchema = BaseVoidResponseSchema;

export type DeleteCheckoutAdditionalCustomerApiResponse = z.infer<
  typeof DeleteCheckoutAdditionalCustomerApiResponseSchema
>;

export const DeleteCheckoutDepositPaymentApiResponseSchema = BaseVoidResponseSchema;

export type DeleteCheckoutDepositPaymentApiResponse = z.infer<
  typeof DeleteCheckoutDepositPaymentApiResponseSchema
>;

export const PatchCheckoutAdditionalCustomerApiResponseSchema = BaseVoidResponseSchema;

export type PatchCheckoutAdditionalCustomerApiResponse = z.infer<
  typeof PatchCheckoutAdditionalCustomerApiResponseSchema
>;

export const PostCheckoutPaymentCancelApiResponseSchema = BaseVoidResponseSchema;

export type PostCheckoutPaymentCancelApiResponse = z.infer<
  typeof PostCheckoutPaymentCancelApiResponseSchema
>;

export const PostCheckoutRecordPaidApiResponseSchema = BaseVoidResponseSchema;

export type PostCheckoutRecordPaidApiResponse = z.infer<
  typeof PostCheckoutRecordPaidApiResponseSchema
>;

export const GetCheckoutLinkForServiceOrderApiArgSchema = z.object({serviceOrderId: z.string()});

export type GetCheckoutLinkForServiceOrderApiArg = z.infer<
  typeof GetCheckoutLinkForServiceOrderApiArgSchema
>;

export const GetCheckoutLinkForServiceOrderApiResponseSchema = z.object({
  checkoutId: z.string(),
  contextTarget: z.string(),
  contextId: z.string(),
});

export type GetCheckoutLinkForServiceOrderApiResponse = z.infer<
  typeof GetCheckoutLinkForServiceOrderApiResponseSchema
>;

export type GetCheckoutPaymentIssueRestrictionApiArg = z.infer<typeof CheckoutIdPaymentIdArgSchema>;

export const GetCheckoutPaymentIssueRestrictionApiResponseSchema = z.array(
  z.object({message: z.string(), code: z.string()})
);

export type GetCheckoutPaymentIssueRestrictionApiResponse = z.infer<
  typeof GetCheckoutPaymentIssueRestrictionApiResponseSchema
>;

export const PutCheckoutSetTypeOfSaleApiResponseSchema = BaseVoidResponseSchema;

export type PutCheckoutSetTypeOfSaleApiResponse = z.infer<
  typeof PutCheckoutSetTypeOfSaleApiResponseSchema
>;

export const PutCheckoutSetTypeOfSaleApiArgSchema = z.object({
  checkoutId: z.string(),
  body: z.object({
    typeOfSale: z.enum(['DEFAULT', 'INTERNAL']),
  }),
});
export type PutCheckoutSetTypeOfSaleApiArg = z.infer<typeof PutCheckoutSetTypeOfSaleApiArgSchema>;
