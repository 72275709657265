import {showNotification} from 'platform/components';

import {Dispatch} from 'redux';

import {
  getDocumentTemplate,
  setUploadProcessing,
  setUploadProgressOfDocumentTemplateFile,
  updateTemplateRequest,
  uploadDocumentTemplateRequest,
  TUploadDocumentTemplateRequest,
  UpdateDocumentTemplateRequest,
  noop,
} from '@dms/teas';

export const uploadFile = (
  dispatch: Dispatch,
  file: File,
  code: string,
  title: string,
  note?: string,
  customTemplateId?: string
) => {
  const payload: TUploadDocumentTemplateRequest | UpdateDocumentTemplateRequest = {
    code,
    file,
    title,
    note,
    customTemplateId,
    onError: (status: string) => {
      showNotification.error(`uploadFileService error - ${status}`);
    },
    onProgress: (percent: number) => {
      dispatch(
        setUploadProgressOfDocumentTemplateFile({
          code,
          progress: percent,
        })
      );
    },
    onFinishS3Put: noop,
    onGetUploadedFile: (result: boolean) => {
      dispatch(setUploadProcessing({code, processing: !result}));
    },
    onSuccess: () => {
      dispatch(getDocumentTemplate);
    },
  };

  if (customTemplateId) {
    dispatch(updateTemplateRequest(payload as UpdateDocumentTemplateRequest));
  } else {
    dispatch(uploadDocumentTemplateRequest(payload as TUploadDocumentTemplateRequest));
  }
};
