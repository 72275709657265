import {Attributes, AttributesRow} from 'platform/components';
import {Heading, Link, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';
import {queryParams} from '@dms/shared';

import {TestIdProps, composePath, suffixTestId} from 'shared';

import {warehouseSearchParams} from '../../../../../utils/warehouseSearchParams';

interface ServiceOrderDetailsProps extends TestIdProps {
  caseId: string;
  orderId: string;
  rows: AttributesRow[];
}

export function ServiceOrderDetails(props: ServiceOrderDetailsProps) {
  return (
    <VStack spacing={3}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.serviceOrder')}</Heading>
      <Attributes
        size="quarter"
        rows={props.rows}
        data-testid={suffixTestId('information', props)}
      />
      <Link
        size="small"
        rightIcon="action/launch"
        href={composePath(workshopRoutes.serviceCaseDetail, {
          params: {
            id: props.caseId,
          },
          queryParams: {
            section: warehouseSearchParams.serviceDetail.orders,
            [queryParams.SERVICE_CASE_ORDER_ID]: props.orderId,
          },
        })}
        target="_blank"
        title={i18n.t('entity.warehouse.actions.viewServiceOrder')}
      />
    </VStack>
  );
}
