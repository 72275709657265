import {createContext} from 'react';

import {Rate} from '@dms/api';

import {vatRateToOption} from './vatRateToOption';

type InvoiceVatType = {
  rates: Rate[];
  rateOptions: ReturnType<typeof vatRateToOption>[];
};

export const InvoiceVatRateContext = createContext<InvoiceVatType>({rateOptions: [], rates: []});
