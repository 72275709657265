import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';

import {ApiException} from '@dms/api';

import {BillingInformationService} from '../../services/BillingInformationService';
import {BillingInformation} from '../../types/BillingInformation';
import {createAsyncThunk} from '../../utils/createAsyncThunk';
import {getStringErrorMessage} from '../../utils/getStringErrorMessage';
import {BILLING_INFORMATION_SLICE_NAME} from './constants';
import {setBillingInformation} from './reducer';

export const fetchBillingInformation = createAsyncThunk<BillingInformation, string>(
  `${BILLING_INFORMATION_SLICE_NAME}/fetchBillingInformation`,
  async (billingInformationId, {extra, rejectWithValue, dispatch}) => {
    try {
      const response = await extra.callApi(BillingInformationService.getBillingInformation, {
        billingInformationId,
      });

      dispatch(setBillingInformation(response));

      return response;
    } catch (error: any) {
      showNotification.error(getStringErrorMessage(error as AxiosError<ApiException>));
      return rejectWithValue(error);
    }
  }
);
