import {Box, Center} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {customerRoutes, testIds} from '@dms/routes';
import {CustomerWidgetCard, EntityHeader, Page, Section} from '@dms/shared';

import {composePath} from 'shared';

export function CreateCustomer() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title={i18n.t('page.customerCreate.title')} />
      <Page
        isFullHeight
        header={
          <EntityHeader
            data-testid={testIds.customer.create('header')}
            title={i18n.t('page.customerCreate.title')}
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: i18n.t('general.actions.discard'),
                onClick: () => navigate(customerRoutes.overview),
              },
            ]}
            flags={[{label: i18n.t('general.labels.draft'), colorScheme: 'neutral'}]}
            icon="social/person_add"
            isTagDeletable
            isTagUpdatable
            isTagReadable
            isTagCreatable
            isTagAssignable
            isTagUnassignable
          />
        }
        data-testid={testIds.employee.createEmployee('createEmployee')}
      >
        <Section>
          <Center>
            <Box maxWidth={166} width="100%">
              <CustomerWidgetCard
                customTitle={i18n.t('entity.customer.labels.customerInformation')}
                secondStepComponentType="SERVICE_CASE"
                onCustomer={(customer) =>
                  navigate(composePath(customerRoutes.detail, {params: {id: customer.id}}))
                }
                data-testid={testIds.customer.create('widget')}
              />
            </Box>
          </Center>
        </Section>
      </Page>
    </>
  );
}
