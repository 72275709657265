import {Choice} from 'platform/components';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {pipe, defaultTo, trim} from 'ramda';

import {useGetTenantQuery, useGetUsersQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {getNaturalPersonFullName} from '../../../utils/getNaturalPersonFullName';
import {SignatoriesFormValues} from '../types/SignatoriesFormValues';

interface UserSelectChoiceProps extends RequiredTestIdProps {
  formApi: UseFormReturn<SignatoriesFormValues>;
  onAfterUserSelect: () => void;
  defaultUserId: string | null;
}

export function UserSelectChoice(props: UserSelectChoiceProps) {
  const [selectedUserId, setSelectedUserId] = useState(props.defaultUserId);

  const {data: users, isLoading} = useGetUsersQuery();
  const {data: tenant} = useGetTenantQuery();
  const userOptions =
    users?.map((user) => ({value: user.id, label: getNaturalPersonFullName(user)})) ?? [];

  const handleOnChange = (userId: string | null) => {
    setSelectedUserId(userId);

    const selectedUser = users?.find((user) => user.id === userId);
    const phoneNumber = selectedUser?.phoneNumber;
    const tenantFallbackPhoneNumber =
      selectedUser?.phoneNumber ?? tenant?.publicContacts?.phoneNumber;

    props.formApi.setValue('seller.name', getNaturalPersonFullName(selectedUser) ?? '');
    props.formApi.setValue('seller.email', getFormPhoneValue(selectedUser?.email));
    props.formApi.setValue('seller.phoneNumber.number', getFormPhoneValue(phoneNumber?.number));
    props.formApi.setValue(
      'seller.phoneNumber.prefix',
      getFormPhoneValue(tenantFallbackPhoneNumber?.prefix)
    );
    props.formApi.setValue(
      'seller.phoneNumber.countryCode',
      tenantFallbackPhoneNumber?.countryCode ?? ''
    );
    props.onAfterUserSelect();
  };

  return (
    <Choice
      isLoading={isLoading}
      value={selectedUserId}
      onChange={handleOnChange}
      options={userOptions}
      isNotClearable
      menuInPortal
      data-testid={suffixTestId('user-select', props)}
      label={i18n.t('entity.document.actions.searchForUser')}
    />
  );
}

const getFormPhoneValue = pipe(defaultTo<string | undefined>(''), trim);
