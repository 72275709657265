import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ProductCatalogGridCard} from './components/ProductCatalogGridCard';

export function ProductCatalog() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.productCatalog.labels.productCatalog')}}
      data-testid={testIds.settings.productCatalog('layout')}
    >
      <ProductCatalogGridCard />
    </SettingsTemplate>
  );
}
