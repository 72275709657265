import {z} from 'zod';

import {BaseVoidRequestSchema} from './base';

/**
 * Base types
 */
const BaseSupplierSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type BaseSupplier = z.infer<typeof BaseSupplierSchema>;

const WarehouseSupplierSchema = z.object({
  id: z.string().nullable(),
});

export type WarehouseSupplier = z.infer<typeof WarehouseSupplierSchema>;

const BaseSupplierPriceListSchema = z.object({
  supplierId: z.string(),
  priceListId: z.string(),
  make: z.string().nullish(),
});

const SupplierPriceListSchema = z
  .object({priceListName: z.string()})
  .merge(BaseSupplierPriceListSchema);

export type SupplierPriceList = z.infer<typeof SupplierPriceListSchema>;

/**
 * GET
 */
export const GetSuppliersRequestSchema = BaseVoidRequestSchema;
export type GetSuppliersRequest = z.infer<typeof GetSuppliersRequestSchema>;

export const GetSuppliersResponseSchema = z.object({
  supplier: z.array(BaseSupplierSchema),
});
export type GetSuppliersResponse = z.infer<typeof GetSuppliersResponseSchema>;

/**
 * POST
 */

/**
 * PATCH
 */
