import {Button, showNotification} from 'platform/components';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useApiDispatch} from '../../../hooks/useApiDispatch';
import {finishAudit} from '../../../store/carAudit/actions';
import {selectAudit, selectCarAudit} from '../../../store/carAudit/selectors';
import {selectUserName} from '../../../store/user/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {useConditionContext} from '../hooks/useConditionContext';

interface FinishInspectionProps extends TestIdProps {
  buttonTitle?: string;
  showWhenFinished?: boolean;
  isDisabled?: boolean;
}

export const FinishInspection = (props: FinishInspectionProps) => {
  const apiDispatch = useApiDispatch();
  const {isDisabledAuditEdit, loadActualDataAndValidate} = useConditionContext();
  const isDisabledForUser = isDisabledAuditEdit(false);
  const audit = useSelector(selectAudit);
  const userName = useSelector(selectUserName);
  const {loading} = useSelector(selectCarAudit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAlreadyFinished = audit?.state === LoadAuditDataResponseItemBody.state.FINISHED_AUDIT;

  const handleFinish = async (): Promise<void> => {
    if (!audit || isDisabledForUser) {
      return;
    }
    setIsLoading(true);
    try {
      await loadActualDataAndValidate();
      try {
        await apiDispatch(finishAudit.action, {
          auditId: audit.id,
          finishedAt: new Date().toISOString(),
          userName,
        });
        showNotification.success(i18n.t('entity.condition.notifications.inspectionFinished'));
      } catch (finishAuditError: any) {
        handleApiError(finishAuditError?.response);
      }
    } catch (_err) {
      showNotification.warning(i18n.t('entity.condition.labels.updateConflicts'));
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = () => {
    if (isDisabledForUser) {
      return true;
    }

    if (isLoading) {
      return false;
    }

    const {updateAudit, getAuditAsset, imageUpload, deleteAuditAsset} = loading;
    const isStateForCheck = audit?.state === LoadAuditDataResponseItemBody.state.FOR_CHECK;

    return (
      isStateForCheck ||
      updateAudit ||
      getAuditAsset ||
      imageUpload ||
      deleteAuditAsset ||
      isAlreadyFinished ||
      props.isDisabled
    );
  };

  return !isAlreadyFinished || props.showWhenFinished ? (
    <Button
      onClick={handleFinish}
      isDisabled={isDisabled()}
      isLoading={isLoading}
      data-testid={suffixTestId('finishInspection', props)}
      title={props.buttonTitle || i18n.t('general.actions.finish')}
    />
  ) : null;
};
