import {isFeatureEnabled} from 'feature-flags';
import {DataStatus, FormControl, FormField, LabelActions, openDropdown} from 'platform/components';
import {Box, Grid, Show, Spinner} from 'platform/foundation';

import {useEffect, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {useGetContextTemplateListQuery, useRenderServiceOrderSmsTemplateMutation} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {FormContactPerson} from '../../../hooks/useContactPersons';
import {EmailSmsNotificationForm} from '../types/EmailSmsNotificationForm';
import {TemplatesSmsDropdownContent} from './TemplatesSmsDropdownContent';

interface SmsSectionProps extends RequiredTestIdProps {
  control: FormControl<EmailSmsNotificationForm>;
  formApi: UseFormReturn<EmailSmsNotificationForm>;
  personsWithPhones: FormContactPerson[];
  orderId?: string;
  serviceCaseId: string;
}

export function SmsSection(props: SmsSectionProps) {
  const {watch, setValue} = props.formApi;

  const enableSms = watch('sms.isEnabled');
  const selectedPerson = watch('sms.data.person');
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isTemplatesError,
  } = useGetContextTemplateListQuery({
    type: 'sms',
    context: 'service-order',
  });
  const [renderTemplate, {isLoading: isRenderTemplateInProgress}] =
    useRenderServiceOrderSmsTemplateMutation();

  const availablePhones = useMemo(
    () => props.personsWithPhones.find((item) => item.value === selectedPerson)?.phoneNumbers || [],
    [props.personsWithPhones, selectedPerson]
  );

  useEffect(() => {
    setValue('sms.data.phone', availablePhones[0]?.value || '');
  }, [availablePhones, setValue]);

  return (
    <DataStatus isLoading={isLoadingTemplates} isError={isTemplatesError}>
      <FormField
        control={props.control}
        type="switch"
        name="sms.isEnabled"
        label={i18n.t('entity.workshop.labels.enableSmsNotifications')}
        data-testid={suffixTestId('sms.isEnabled', props)}
      />
      <Show when={enableSms}>
        <Grid columns={2}>
          <FormField
            control={props.control}
            name="sms.data.person"
            type="choice"
            label={i18n.t('entity.businessCase.labels.contactPerson')}
            options={props.personsWithPhones}
            onChange={() => setValue('sms.data.phone', '')}
            data-testid={suffixTestId('sms.data.person', props)}
            isNotClearable
            isRequired
          />
          <FormField
            control={props.control}
            name="sms.data.phone"
            type="choice"
            label={i18n.t('entity.person.labels.phoneNumber')}
            data-testid={suffixTestId('sms.data.phone', props)}
            options={availablePhones}
            isNotClearable
            isRequired
          />
        </Grid>
        <Box position="relative">
          <Show when={isRenderTemplateInProgress}>
            <Spinner variant="overlay" />
          </Show>
          <FormField
            control={props.control}
            type="textarea"
            name="sms.data.message"
            minRows={5}
            label={i18n.t('general.labels.message')}
            data-testid={suffixTestId('sms.data.message', props)}
            labelActions={buildArray<LabelActions[number]>().when(
              isFeatureEnabled(featureFlags.MESSAGE_TEMPLATES) && props.orderId,
              {
                leftIcon: 'content/add_circle',
                title: i18n.t('general.labels.templates'),
                onClick: (e) =>
                  openDropdown(
                    e,
                    <TemplatesSmsDropdownContent
                      formApi={props.formApi}
                      control={props.control}
                      renderTemplate={renderTemplate}
                      templates={templates}
                      serviceCaseId={props.serviceCaseId}
                      serviceOrderId={props.orderId || ' '}
                    />,
                    {
                      isHeightLimited: true,
                    }
                  ),
              }
            )}
            isRequired
            isResizable
          />
        </Box>
      </Show>
    </DataStatus>
  );
}
