import {ThemeColorPath} from 'platform/foundation';
import {match} from 'ts-pattern';

import {TaskPriorityEnum, TaskPriorityEnumSchema} from '@dms/api';

export function getColorByPriority(isSubtle: boolean, priority: TaskPriorityEnum) {
  return match({isSubtle, priority})
    .returnType<ThemeColorPath>()
    .with({priority: TaskPriorityEnumSchema.enum.LOW, isSubtle: false}, () => 'general.accent')
    .with(
      {priority: TaskPriorityEnumSchema.enum.MEDIUM, isSubtle: false},
      () => 'palettes.orange.70.100'
    )
    .with(
      {priority: TaskPriorityEnumSchema.enum.HIGH, isSubtle: false},
      () => 'palettes.red.60.100'
    )
    .with({priority: TaskPriorityEnumSchema.enum.LOW, isSubtle: true}, () => 'palettes.blue.10.100')
    .with(
      {priority: TaskPriorityEnumSchema.enum.MEDIUM, isSubtle: true},
      () => 'palettes.orange.10.100'
    )
    .with({priority: TaskPriorityEnumSchema.enum.HIGH, isSubtle: true}, () => 'palettes.red.10.100')
    .exhaustive();
}
