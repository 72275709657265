import {FormProps} from 'platform/components';

import {GetGeneralSettingApiResponse} from '@dms/api';

import {TiresInventoryFormType} from '../types/tiresInventoryFormType';

export const getDefaultValuesFromGeneralSettings = (
  data: GetGeneralSettingApiResponse
): FormProps<TiresInventoryFormType>['defaultValues'] => ({
  ...data,
  storeUntilSettings: data?.storeUntilSettings?.map((store) => ({
    preFillDate: {
      month: store?.preFillDate?.month ?? undefined,
      day: store?.preFillDate?.day ?? undefined,
    },
    receivedAt: {
      month: store?.receivedAt?.month ?? undefined,
      day: store?.receivedAt?.day ?? undefined,
    },
    receivedTo: {
      month: store?.receivedTo?.month ?? undefined,
      day: store?.receivedTo?.day ?? undefined,
    },
  })),
});
