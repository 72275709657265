import {Heading, Space, VStack} from 'platform/foundation';

import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {selectInterior} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditCategoryOfStructure} from '../../../types/AuditCategoryOfStructure';
import {MainCategoryProps} from '../../../types/ConditionTypes';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findCategoryByUniqueKey} from '../utils/findCategoryByUniqueKey';
import {getDamageValues} from '../utils/getDamageValues';
import {getTranslation} from '../utils/getTranslation';
import {AuditFormField} from './AuditFormField';
import {CategoryGeneralComment} from './CategoryGeneralComment';
import {DamageCategory} from './DamageCategory';
import {ListOfDamages} from './ListOfDamages';
import {CategoriesLabel, LocationsSettingsGridProp, TypeOfDamages} from './TypeOfDamages';

export const Interior: FC<MainCategoryProps & TestIdProps> = ({
  data: categoryStructure,
  ...rest
}) => {
  const interiorDamage = useMemo(
    () => findCategoryByUniqueKey(categoryStructure, AuditCategoryUniqueKey.INTERIOR_DAMAGE),
    [categoryStructure]
  );

  const interiorDamageValues = useMemo(() => getDamageValues(interiorDamage), [interiorDamage]);

  const categoriesGridSpan: LocationsSettingsGridProp = {
    [AuditCategoryUniqueKey.DRIVERS_SEAT]: 1,
    [AuditCategoryUniqueKey.PASSENGER_SEAT]: 1,
    [AuditCategoryUniqueKey.OTHERS]: 6,
  };

  const categoriesGridRowSpan: LocationsSettingsGridProp = {
    [AuditCategoryUniqueKey.COLUMN_TRIM_ON_THE_LEFT]: 2,
    [AuditCategoryUniqueKey.COLUMN_TRIM_ON_THE_RIGHT]: 2,
  };

  const categoriesGridRowStart: LocationsSettingsGridProp = {
    [AuditCategoryUniqueKey.DRIVING_WHEEL]: 2,
    [AuditCategoryUniqueKey.COLUMN_TRIM_ON_THE_LEFT]: 1,
  };

  const categoriesGridColStart: LocationsSettingsGridProp = {
    [AuditCategoryUniqueKey.COLUMN_TRIM_ON_THE_LEFT]: 1,
  };

  const categoriesLabel: CategoriesLabel = {
    [AuditCategoryUniqueKey.OTHERS]: i18n.t('entity.vehicle.labels.otherLabel'),
    [AuditCategoryUniqueKey.CEILINGS]: i18n.t('entity.condition.labels.ceilings'),
    [AuditCategoryUniqueKey.CARPET]: i18n.t('entity.condition.labels.carpet'),
    [AuditCategoryUniqueKey.SUITCASE]: i18n.t('entity.condition.labels.suitcase'),
  };

  return (
    <DamageCategory data-testid={rest['data-testid']}>
      <TypeOfDamages
        data-testid={rest['data-testid']}
        category={interiorDamage}
        damageValues={interiorDamageValues}
        locationsSettings={{
          categoriesLabel,
          categoriesGridSpan,
          categoriesGridRowSpan,
          categoriesGridRowStart,
          categoriesGridColStart,
          columns: 6,
          rows: 5,
          defaultGridSpan: 2,
        }}
      />
      <Space vertical={6} />
      <InteriorAdditionalInformation data-testid={rest['data-testid']} />
      <Space vertical={6} />
      <ListOfDamages data-testid={rest['data-testid']} category={interiorDamage} />
      <CategoryGeneralComment category={categoryStructure} />
    </DamageCategory>
  );
};

const InteriorAdditionalInformation: FC<TestIdProps> = (props) => {
  const categoryStructure = useSelector(selectInterior);
  const locale = useSelector(selectUserSelectedLanguage);

  const additionalInformation = useMemo((): AuditCategoryOfStructure[] | null => {
    if (!categoryStructure) {
      return null;
    }

    return (
      categoryStructure.childCategories?.filter((category) => {
        const accessoriesCategoryKeys: string[] = [
          AuditCategoryUniqueKey.VEHICLE_NON_SMOKING,
          AuditCategoryUniqueKey.NO_ODOR_INDOORS,
        ];

        return category.visible && accessoriesCategoryKeys.includes(category.uniqueKey);
      }) ?? null
    );
  }, [categoryStructure]);

  return (
    <VStack spacing={4}>
      <Heading size={4} data-testid={suffixTestId('interiorAdditionalInformation', props)}>
        {i18n.t('general.labels.additionalInformation')}
      </Heading>
      <VStack spacing={3}>
        {additionalInformation?.map((category) =>
          category.paramDefinitions?.relatedActions?.map(
            (paramDefinition, paramDefinitionIndex) => (
              <AuditFormField
                key={`interiorAdditionalInformation-${paramDefinition.id}-${category.id}`}
                paramDefinition={paramDefinition}
                categoryId={category.id}
                label={getTranslation(locale, category.name)}
                uniqueKey={`additionalInformation.${category.uniqueKey}`}
                data-testid={suffixTestId(
                  `interiorAdditionalInformation-[${paramDefinitionIndex}]`,
                  props
                )}
              />
            )
          )
        )}
      </VStack>
    </VStack>
  );
};
