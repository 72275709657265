import {showNotification} from 'platform/components';

import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {
  PaymentInfoRequestBody,
  businessCaseApi,
  useCreateCheckoutCorrectiveTaxDocumentMutation,
} from '@dms/api';
import {businessCaseRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {AccountingDocumentFormValues} from '../../../types';
import {getValidatedCheckoutSearchParams} from '../utils/getValidatedCheckoutSearchParams';
import {useGetCorrectiveTaxForBCDocumentBody} from './useGetCorrectiveTaxForBCDocumentBody';

export const useHandleSubmitCheckoutCorrectiveDocument = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getRequestBody] = useGetCorrectiveTaxForBCDocumentBody();
  const [searchParams] = useSearchParams();

  const [createCorrectiveTaxDocument] = useCreateCheckoutCorrectiveTaxDocumentMutation();

  const submitCorrectiveTaxDocument = async (
    formData: AccountingDocumentFormValues<PaymentInfoRequestBody>
  ) => {
    const {businessCaseId, type, ...checkoutParams} =
      getValidatedCheckoutSearchParams(searchParams);
    const requestBody = getRequestBody(formData);

    await createCorrectiveTaxDocument({
      ...checkoutParams,
      type,
      requestBody,
    })
      .unwrap()
      .then(() => showNotification.success())
      .then(() => {
        dispatch(
          businessCaseApi.util.invalidateTags([
            {
              type: 'BusinessCaseActions',
              id: businessCaseId,
            },
            {type: 'BusinessCaseDetail', id: businessCaseId},
          ])
        );
        navigate(
          composePath(businessCaseRoutes.checkout, {
            params: {id: businessCaseId},
            queryParams: {
              CheckoutOrder: 'payment',
              createdCorrectiveDocumentType: type,
            },
          })
        );
      })
      .catch(handleApiError);
  };

  return [submitCorrectiveTaxDocument] as const;
};
