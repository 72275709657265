import {reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {BankAccountResponseBody} from '@dms/api';

export const bankAccountToOption = (account: BankAccountResponseBody) => {
  const accountParts = [account.accountNumber, account.bankName, account.currency];

  return {
    label: reject(isNilOrEmpty, accountParts).join(', '),
    value: account.accountNumber,
  };
};
