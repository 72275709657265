import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  FormSubmitHandler,
  Label,
  TextInput,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import i18n from '@dms/i18n';

import {yupString} from 'shared';

import {CreateTireLocationForm} from '../types/CreateTireLocationForm';

const createTireLocationSchema = object({
  location: yupString.required(i18n.t('general.validations.fieldIsRequired')),
  allowMultipleUsage: boolean().required(),
  note: yupString,
});

interface SingleTireLocationFormProps {
  isLoading: boolean;
  defaultValues?: CreateTireLocationForm;
  handleSubmit: FormSubmitHandler<CreateTireLocationForm>;
  handleClose: () => void;
}

export function SingleTireLocationForm(props: SingleTireLocationFormProps) {
  const getDefaultValues = () => {
    if (props.defaultValues) {
      return props.defaultValues;
    }

    return {
      allowMultipleUsage: false,
      location: '',
      note: '',
    };
  };

  return (
    <Form<CreateTireLocationForm>
      onSubmit={props.handleSubmit}
      defaultValues={getDefaultValues()}
      schema={createTireLocationSchema}
    >
      {(control) => (
        <VStack spacing={4}>
          <VStack spacing={1}>
            <HStack spacing={4}>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.vector.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.vector')}
                </Label>
              </Box>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.symbol.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.symbol')}
                </Label>
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <TextInput value={i18n.t('entity.tireWarehouses.labels.other')} isDisabled />
              </Box>
              <Box flex={1}>
                <FormField type="text" name="location" control={control} />
              </Box>
            </HStack>
          </VStack>
          <FormField
            type="textarea"
            name="note"
            control={control}
            label={i18n.t('general.labels.note')}
            minRows={1}
            isResizable
          />
          <FormField
            type="checkbox"
            name="allowMultipleUsage"
            control={control}
            label={i18n.t('entity.tireWarehouses.labels.unlimitedUsage')}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.labels.discard')}
              variant="secondary"
              onClick={props.handleClose}
              isDisabled={props.isLoading}
            />
            <Button
              title={i18n.t('general.actions.create')}
              type="submit"
              isLoading={props.isLoading}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
