import {Flag, TableRow} from 'platform/components';
import {Box, Hide, Spinner, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isNil, reject} from 'ramda';

import {EMPTY_PLACEHOLDER, getNaturalPersonFullName, usePermissions} from '@dms/shared';

import {parseDate, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {selectAssignments} from '../../../store/assignment/selectors';
import {selectStructures} from '../../../store/carAudit/selectors';
import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {getStatusColorScheme} from '../utils/getStatusColorScheme';
import {getStatusTranslation} from '../utils/getStatusTranslation';
import {getTranslation} from '../utils/getTranslation';
import {InspectionRatings} from './InspectionRatings';

interface InspectionProps extends TestIdProps {
  getRedirectRoute: (id: string, inspectionId: string) => string;
  openLinksInNewTab?: boolean;
  auditData: LoadAuditDataResponseItemBody;
}

export function Inspection({
  getRedirectRoute,
  openLinksInNewTab,
  auditData,
  ...rest
}: InspectionProps) {
  const {id: vehicleId} = useRequiredParams();
  const locale = useSelector(selectUserSelectedLanguage);
  const assignments = useSelector(selectAssignments);
  const structures = useSelector(selectStructures);
  const navigate = useNavigate();
  const formatDateTime = useDateTimeFormatter();

  const inspectionScope = {inspectionType: auditData.inspectionType};

  const [hasPermissionToReadInspection] = usePermissions({
    permissionKeys: ['readInspection'],
    scopes: {
      readInspection: inspectionScope,
    },
  });

  const handleInspectionClick = (inspectionId: string) => {
    const redirectRoute = getRedirectRoute(vehicleId as string, inspectionId);

    if (openLinksInNewTab) {
      return window.open(redirectRoute, '_blank');
    }

    navigate(getRedirectRoute(vehicleId, inspectionId));
  };

  const getAssignee = (auditId: string) => {
    const auditAssignments = assignments?.vehicle_audit?.[auditId] || [];

    const assignees = reject(
      isNil,
      auditAssignments.map((assignment) => {
        if (assignment?.user) {
          return getNaturalPersonFullName(assignment.user) || assignment.user.email || null;
        }
        return null;
      })
    );

    if (assignees?.length > 0) {
      return assignees.join(', ');
    }

    return null;
  };

  return (
    <TableRow
      data-testid={suffixTestId('tableRow', rest)}
      onClick={() => {
        if (hasPermissionToReadInspection) {
          handleInspectionClick(auditData.id);
        }
      }}
    >
      <Box paddingLeft={4} paddingVertical={4} minHeight={13}>
        <Text size="small" data-testid={suffixTestId('name', rest)}>
          {structures?.[auditData.controlStructureId]?.name ? (
            getTranslation(locale, structures[auditData.controlStructureId].name)
          ) : (
            <Spinner size="small" />
          )}
        </Text>
      </Box>
      <Flag
        colorScheme={getStatusColorScheme(auditData.state)}
        isSubtle
        label={getStatusTranslation(auditData.state)}
        data-testid={suffixTestId('inspection-status-flag', rest)}
      />
      <Text size="small" data-testid={suffixTestId('updateAt', rest)}>
        {formatDateTime('dateTimeLong', parseDate(auditData.updateAt as string))}
      </Text>
      <Text size="small" data-testid={suffixTestId('user', rest)}>
        {auditData.user ?? EMPTY_PLACEHOLDER}
      </Text>
      <Text size="small" data-testid={suffixTestId('asignee', rest)}>
        {getAssignee(auditData.id) ?? EMPTY_PLACEHOLDER}
      </Text>
      <Hide
        when={auditData.inspectionType === LoadAuditDataResponseItemBody.inspectionType.COMPLAINT}
      >
        <InspectionRatings data-testid={rest['data-testid']} inspection={auditData} />
      </Hide>
    </TableRow>
  );
}
