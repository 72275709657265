import {isFeatureEnabled} from 'feature-flags';
import {Parameter} from 'platform/components';

import {GetVehicleApiResponseV2, useGetLastActiveServiceCaseQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {useGetVehicleHeaderV2, usePermissions} from '@dms/shared';

import {Nullish, buildArray} from 'shared';

export function useGetVehicleHeaderInformationParameters(
  isSaleVehicle: boolean,
  vehicle: GetVehicleApiResponseV2 | Nullish
) {
  const [canReadServiceCase] = usePermissions({permissionKeys: ['serviceCaseRead']});
  const canReadWorkshop = canReadServiceCase && isFeatureEnabled(featureFlags.ACL_WORKSHOP);

  const {data: lastActiveServiceCase} = useGetLastActiveServiceCaseQuery(
    {vehicleId: vehicle?.id || ''},
    {skip: !vehicle?.id || !canReadWorkshop}
  );

  const newVehicleHeader = useGetVehicleHeaderV2({
    serviceCaseNumber: lastActiveServiceCase?.number,
    publicId: vehicle?.publicId,
    vin: vehicle?.vin,
    registrationPlate: vehicle?.registrationPlate,
    firstRegistrationOn: vehicle?.firstRegistrationOn,
    warrantyDate: vehicle?.warrantyDate,
    isVehicleDetail: true,
  });

  if (isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2) && !isSaleVehicle) {
    return newVehicleHeader;
  }

  return buildArray<Parameter>()
    .when(vehicle?.publicId, {
      title: vehicle?.publicId ?? '',
      tooltip: i18n.t('entity.vehicle.labels.publicId'),
    })
    .when(vehicle?.externalId, {
      title: vehicle?.externalId ?? '',
      tooltip: i18n.t('entity.vehicle.labels.externalId'),
    })
    .add({
      title: vehicle?.vin || i18n.t('entity.vehicle.notifications.noVIN'),
      tooltip: i18n.t('entity.vehicle.labels.vin'),
    })
    .add({
      title: vehicle?.registrationPlate || i18n.t('entity.vehicle.notifications.noLicencePlate'),
      tooltip: i18n.t('entity.vehicle.labels.licensePlate'),
    });
}
