import {isValid} from 'date-fns';
import {DataStatus, Table, TableRow} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo, dropLast, join, pipe, split} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER, numberToTimeString} from '@dms/shared';

import {Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

import {useJobTime} from '../../hooks/useJobTime';

interface JobTrackedInfoProps extends TestIdProps {
  jobName: string;
  estimatedTime?: number | Nullish;
  serviceCaseId?: string;
  serviceOrderId?: string;
  serviceCaseJobId?: string | Nullish;
}

export function JobTrackedInfo(props: JobTrackedInfoProps) {
  const formatDateTime = useDateTimeFormatter();

  const jobTime = useJobTime({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
    serviceCaseJobId: props.serviceCaseJobId,
  });

  return (
    <VStack spacing={4}>
      <Heading size={4}>{props.jobName}</Heading>
      <DataStatus isEmpty={isNilOrEmpty(jobTime?.timeTrackingItems)}>
        <Table data-testid={suffixTestId('table', props)}>
          <TableRow color="palettes.neutral.10.100" data-testid={suffixTestId('table-date', props)}>
            <Box paddingVertical={4}>
              <Box paddingHorizontal={4} borderRight="1px solid" borderColor="general.separator">
                <Heading size={5} color="secondary">
                  {i18n.t('general.labels.date')}
                </Heading>
              </Box>
            </Box>
            <Box paddingVertical={4}>
              <Box paddingHorizontal={4} borderRight="1px solid" borderColor="general.separator">
                <Heading size={5} color="secondary">
                  {i18n.t('entity.order.labels.from')}
                </Heading>
              </Box>
            </Box>
            <Box paddingVertical={4}>
              <Box paddingHorizontal={4} borderRight="1px solid" borderColor="general.separator">
                <Heading size={5} color="secondary">
                  {i18n.t('entity.order.labels.until')}
                </Heading>
              </Box>
            </Box>
            <Box paddingVertical={4}>
              <Box paddingHorizontal={4} borderRight="1px solid" borderColor="general.separator">
                <Heading size={5} color="secondary">
                  {i18n.t('entity.order.labels.numberOfHours')}
                </Heading>
              </Box>
            </Box>
            <Box padding={4}>
              <Heading size={5} color="secondary">
                {i18n.t('entity.order.labels.mechanic')}
              </Heading>
            </Box>
          </TableRow>
          {jobTime?.timeTrackingItems?.map((item, index) => (
            <TableRow
              key={`${item?.from}-${item?.date}`}
              data-testid={suffixTestId(`table-row-[${index}]`, props)}
            >
              <Box paddingHorizontal={4}>
                <HStack height={10} align="center">
                  <Text size="small">
                    {item?.date
                      ? formatDateTime(
                          'dateShort',
                          isValid(parseDate(item.date)) ? parseDate(item.date) : new Date()
                        )
                      : EMPTY_PLACEHOLDER}
                  </Text>
                </HStack>
              </Box>
              <Box paddingHorizontal={4}>
                <HStack height={10} align="center">
                  <Text size="small">
                    {item?.from
                      ? formatDateTime('timeShort', parseDate(item.from))
                      : EMPTY_PLACEHOLDER}
                  </Text>
                </HStack>
              </Box>
              <Box paddingHorizontal={4}>
                <HStack height={10} align="center">
                  <Text size="small">
                    {item?.to ? formatDateTime('timeShort', parseDate(item.to)) : EMPTY_PLACEHOLDER}
                  </Text>
                </HStack>
              </Box>
              <Box paddingHorizontal={4}>
                <HStack height={10} align="center">
                  <Text size="small">
                    {item?.hours ? getFormatedTime(item.hours) : EMPTY_PLACEHOLDER}
                  </Text>
                </HStack>
              </Box>
              <Box paddingHorizontal={4}>
                <HStack height={10} align="center">
                  <Text size="small">{item?.employeeName ?? EMPTY_PLACEHOLDER}</Text>
                </HStack>
              </Box>
            </TableRow>
          ))}
        </Table>
        <HStack justify="space-between">
          <Text size="small" alternative>
            {i18n.t('general.labels.total')}
          </Text>
          <HStack spacing={1}>
            <Text size="small" color="tertiary">
              {isNotNil(props.estimatedTime)
                ? `${numberToTimeString(props.estimatedTime)} (${i18n.t('entity.orderRequest.labels.estimatedTime')})`
                : i18n.t('entity.orderRequest.labels.noEstimatedTime')}
            </Text>
            <Text size="small" alternative>
              {getFormatedTime(jobTime?.totalTimeTracked)}
            </Text>
          </HStack>
        </HStack>
      </DataStatus>
    </VStack>
  );
}

const getFormatedTime = pipe(defaultTo('00:00:00'), split(':'), dropLast(1), join(':'));
