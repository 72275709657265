import {BreadcrumbType} from 'platform/components';

import {useGetRoundingDefinitionQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentRoundingDefinitionForm} from './components/DocumentRoundingDefinitionForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accounting.labels.documentRoundingDefinitions'),
    href: settingsRoutes.documentRoundingDefinitions,
  },
];

export function DocumentRoundingDefinitionsEdit() {
  const {id} = useRequiredParams();
  const {data, isLoading, isError} = useGetRoundingDefinitionQuery({
    id,
  });

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accounting.labels.documentRoundingDefinitions'), breadcrumbs}}
      data-testid="settings-document-rounding-definition-add-page"
      isLoading={isLoading}
      isError={isError}
    >
      <DocumentRoundingDefinitionForm roundingDefinition={data} />
    </SettingsTemplate>
  );
}
