import {Choice, MultiChoice, showNotification} from 'platform/components';
import {Grid} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {difference, isEmpty, isNil} from 'ramda';

import {
  BranchResponseBody,
  useGetCurrentUserInfoQuery,
  useGetUserQuery,
  usePatchUserMutation,
  userApi,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, useBranches, useGetCurrentBranch} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface UserBranchesProps extends RequiredTestIdProps {
  userId: string;
}
export function UserBranches(props: UserBranchesProps) {
  const dispatch = useDispatch();
  const {activeBranchId} = useGetCurrentBranch();
  const {branchOptions, isLoading: isLoadingRoles} = useBranches();
  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const {data: user} = useGetUserQuery({id: props.userId});

  const [patchUser, {isLoading: isPatchingUser}] = usePatchUserMutation();

  const isUserCurrentUser = props.userId === currentUser?.id;
  const userBranches = user?.branches?.map((item) => item.id) ?? [];
  const userBranchOptions = user?.branches?.map(userBranchesToOptions) ?? [];

  const handlePatchUserBranches = (branches: string[] | null) => {
    if (isNil(branches) || isEmpty(branches)) {
      showNotification.error(i18n.t('general.notifications.errorBranchIsRequired'));
      return;
    }

    const removedBranches = difference(userBranches, branches);
    if (removedBranches.includes(activeBranchId)) {
      showNotification.error(i18n.t('general.notifications.errorCannotRemoveActiveBranch'));
      return;
    }

    patchUser({userId: props.userId, updateUserRequestBody: {branchIds: branches}})
      .unwrap()
      .then(() => {
        if (isUserCurrentUser) {
          dispatch(userApi.util.invalidateTags(['CurrentUser']));
        }
      })
      .catch(handleApiError);
  };

  const handlePatchUserDefaultBranch = (branch: string | null) => {
    if (isNil(branch)) {
      showNotification.error();
      return;
    }

    patchUser({userId: props.userId, updateUserRequestBody: {defaultBranchId: branch}})
      .unwrap()
      .catch(handleApiError);
  };

  return (
    <Grid columns={2}>
      <MultiChoice
        value={userBranches}
        isLoading={isLoadingRoles || isPatchingUser}
        isRequired
        onChange={handlePatchUserBranches}
        isSearchable
        isNotClearable
        options={branchOptions}
        label={i18n.t('entity.branch.labels.userHasAccessToBranches')}
        helperText={i18n.t('entity.branch.labels.branchesHelperText')}
        data-testid={suffixTestId('userBranches-branches', props)}
        closeMenuOnSelect={false}
      />
      <Choice
        value={user?.defaultBranch?.id || null}
        isNotClearable
        options={userBranchOptions}
        onChange={handlePatchUserDefaultBranch}
        isLoading={isPatchingUser}
        isRequired
        isDisabled={userBranches.length <= 1}
        label={i18n.t('entity.branch.labels.defaultBranch')}
        helperText={i18n.t('entity.branch.labels.defaultBranchHelperText')}
        data-testid={suffixTestId('userBranches-defaultBranch', props)}
      />
    </Grid>
  );
}

const userBranchesToOptions = (item: BranchResponseBody) => ({
  value: item.id,
  label: item.companyName ?? item.name,
});
