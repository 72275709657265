import {isNotNilOrEmpty} from 'ramda-adjunct';

import {CustomerResponseBodyV2} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {isCustomerNaturalPerson} from './isCustomerNaturalPerson';

export function getCustomerEmail(customer: CustomerResponseBodyV2 | Nullish) {
  if (!customer) {
    return null;
  }

  if (isCustomerNaturalPerson(customer)) {
    const email = customer.foundingPerson?.emails[0]?.email;
    return isNotNilOrEmpty(email) ? email! : i18n.t('entity.customer.labels.noEmail');
  }

  const contact = customer.contacts?.[0];
  const contactEmail = contact?.emails[0]?.email;

  return isNotNilOrEmpty(contactEmail) ? contactEmail! : i18n.t('entity.customer.labels.noEmail');
}
