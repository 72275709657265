import {Option} from 'platform/components';

import {
  GetServiceOrderVariantApiArg,
  GetServiceOrderVariantApiResponse,
  PatchServiceOrderVariantApiArg,
  PatchServiceOrderVariantApiResponse,
  PostServiceOrderVariantApiArg,
  PostServiceOrderVariantApiResponse,
  GetServiceOrderVariantDocumentsApiArg,
  GetServiceOrderVariantDocumentsApiResponse,
  PatchServiceOrderVariantDocumentsApiArg,
  PatchServiceOrderVariantDocumentsApiResponse,
  GetServiceOrderVariantMandatoryFieldsApiResponse,
  GetServiceOrderVariantMandatoryFieldsApiArg,
  PatchServiceOrderVariantMandatoryFieldsApiResponse,
  PatchServiceOrderVariantMandatoryFieldsApiArg,
  PostServiceOrderVariantSetAsActiveApiResponse,
  PostServiceOrderVariantSetAsActiveApiArg,
  PostServiceOrderVariantSetAsInactiveResponse,
  PostServiceOrderVariantSetAsInactiveApiArg,
  DeleteServiceOrderVariantResponse,
  DeleteServiceOrderVariantApiArg,
  GetSettingsServiceOrderVariantsApiResponse,
  GetSettingsServiceOrderVariantsApiArg,
  GetServiceOrderVariantPaymentTypeApiResponse,
  GetServiceOrderVariantPaymentTypeApiArg,
  PatchServiceOrderVariantPaymentTypeApiApiResponse,
  PatchServiceOrderVariantPaymentTypeApiApiArg,
  PostServiceOrderVariantApiResponseSchema,
  PostServiceOrderVariantApiArgSchema,
  PostServiceOrderVariantSetAsActiveApiArgSchema,
  PostServiceOrderVariantSetAsActiveApiResponseSchema,
  PostServiceOrderVariantSetAsInactiveApiArgSchema,
  PostServiceOrderVariantSetAsInactiveResponseSchema,
  DeleteServiceOrderVariantResponseSchema,
  DeleteServiceOrderVariantApiArgSchema,
  GetServiceOrderVariantApiResponseSchema,
  GetServiceOrderVariantApiArgSchema,
  PatchServiceOrderVariantApiResponseSchema,
  PatchServiceOrderVariantApiArgSchema,
  GetServiceOrderVariantDocumentsApiResponseSchema,
  GetServiceOrderVariantDocumentsApiArgSchema,
  PatchServiceOrderVariantDocumentsApiResponseSchema,
  PatchServiceOrderVariantDocumentsApiArgSchema,
  GetServiceOrderVariantMandatoryFieldsApiResponseSchema,
  GetServiceOrderVariantMandatoryFieldsApiArgSchema,
  PatchServiceOrderVariantMandatoryFieldsApiResponseSchema,
  PatchServiceOrderVariantMandatoryFieldsApiArgSchema,
  GetSettingsServiceOrderVariantsApiArgSchema,
  GetSettingsServiceOrderVariantsApiResponseSchema,
  GetServiceOrderVariantPaymentTypeApiArgSchema,
  GetServiceOrderVariantPaymentTypeApiResponseSchema,
  PatchServiceOrderVariantPaymentTypeApiResponseSchema,
  PatchServiceOrderVariantPaymentTypeApiArgSchema,
  GetServiceOrderVariantAccountingApiResponse,
  GetServiceOrderVariantAccountingApiArg,
  PatchServiceOrderVariantAccountingApiResponse,
  PatchServiceOrderVariantAccountingApiArg,
  GetServiceOrderVariantAccountingApiResponseSchema,
  GetServiceOrderVariantAccountingApiArgSchema,
  PatchServiceOrderVariantAccountingApiResponseSchema,
  PatchServiceOrderVariantAccountingApiArgSchema,
  PostServiceOrderVariantSetAsDefaultApiArg,
  PostServiceOrderVariantSetAsDefaultApiResponse,
} from '../types/metadaWorkshopServiceOrderVariant';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopServiceOrderVariantApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceOrderVariant: build.mutation<
      PostServiceOrderVariantApiResponse,
      PostServiceOrderVariantApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostServiceOrderVariantApiResponseSchema,
        requestSchema: PostServiceOrderVariantApiArgSchema,
      },
      invalidatesTags: ['serviceOrderVariants'],
    }),
    postServiceOrderVariantSetAsActive: build.mutation<
      PostServiceOrderVariantSetAsActiveApiResponse,
      PostServiceOrderVariantSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/set-as-active`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderVariants',
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderVariantSetAsActiveApiResponseSchema,
        requestSchema: PostServiceOrderVariantSetAsActiveApiArgSchema,
      },
    }),
    postServiceOrderVariantSetAsInactive: build.mutation<
      PostServiceOrderVariantSetAsInactiveResponse,
      PostServiceOrderVariantSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/set-as-inactive`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderVariants',
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderVariantSetAsInactiveResponseSchema,
        requestSchema: PostServiceOrderVariantSetAsInactiveApiArgSchema,
      },
    }),
    deleteServiceOrderVariant: build.mutation<
      DeleteServiceOrderVariantResponse,
      DeleteServiceOrderVariantApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderVariants',
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: DeleteServiceOrderVariantResponseSchema,
        requestSchema: DeleteServiceOrderVariantApiArgSchema,
      },
    }),
    getServiceOrderVariant: build.query<
      GetServiceOrderVariantApiResponse,
      GetServiceOrderVariantApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderVariantApiResponseSchema,
        requestSchema: GetServiceOrderVariantApiArgSchema,
      },
    }),
    patchServiceOrderVariant: build.mutation<
      PatchServiceOrderVariantApiResponse,
      PatchServiceOrderVariantApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderVariants',
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderVariantApiResponseSchema,
        requestSchema: PatchServiceOrderVariantApiArgSchema,
      },
    }),
    getServiceOrderVariantDocuments: build.query<
      GetServiceOrderVariantDocumentsApiResponse,
      GetServiceOrderVariantDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/document`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderVariantDocumentsApiResponseSchema,
        requestSchema: GetServiceOrderVariantDocumentsApiArgSchema,
      },
    }),
    patchServiceOrderVariantDocuments: build.mutation<
      PatchServiceOrderVariantDocumentsApiResponse,
      PatchServiceOrderVariantDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/document`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderVariant', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderVariantDocumentsApiResponseSchema,
        requestSchema: PatchServiceOrderVariantDocumentsApiArgSchema,
      },
    }),
    getServiceOrderVariantMandatoryFields: build.query<
      GetServiceOrderVariantMandatoryFieldsApiResponse,
      GetServiceOrderVariantMandatoryFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/mandatory-fields`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'mandatoryFields', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderVariantMandatoryFieldsApiResponseSchema,
        requestSchema: GetServiceOrderVariantMandatoryFieldsApiArgSchema,
      },
    }),
    patchServiceOrderVariantMandatoryFields: build.mutation<
      PatchServiceOrderVariantMandatoryFieldsApiResponse,
      PatchServiceOrderVariantMandatoryFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/mandatory-fields`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'serviceOrderMandatoryFields',
        {type: 'mandatoryFields', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderVariantMandatoryFieldsApiResponseSchema,
        requestSchema: PatchServiceOrderVariantMandatoryFieldsApiArgSchema,
      },
    }),
    getSettingsServiceOrderVariants: build.query<Option[], GetSettingsServiceOrderVariantsApiArg>({
      query: () => ({
        url: `service/v1/settings/service-order-variants`,
      }),
      transformResponse: (response: GetSettingsServiceOrderVariantsApiResponse) =>
        response?.serviceOrderVariants?.serviceOrderVariant?.map((variant) => ({
          label: variant?.name ?? '',
          value: variant?.id ?? '',
        })) ?? [],
      extraOptions: {
        responseSchema: GetSettingsServiceOrderVariantsApiResponseSchema,
        requestSchema: GetSettingsServiceOrderVariantsApiArgSchema,
      },
    }),
    getSettingsServiceOrderVariantsAll: build.query<
      GetSettingsServiceOrderVariantsApiResponse,
      GetSettingsServiceOrderVariantsApiArg
    >({
      query: () => ({
        url: `service/v1/settings/service-order-variants`,
      }),
      extraOptions: {
        responseSchema: GetSettingsServiceOrderVariantsApiResponseSchema,
        requestSchema: GetSettingsServiceOrderVariantsApiArgSchema,
      },
      providesTags: ['serviceOrderVariants'],
    }),
    getServiceOrderVariantPaymentType: build.query<
      GetServiceOrderVariantPaymentTypeApiResponse,
      GetServiceOrderVariantPaymentTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/payment-type`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'paymentTypes', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderVariantPaymentTypeApiResponseSchema,
        requestSchema: GetServiceOrderVariantPaymentTypeApiArgSchema,
      },
    }),
    patchServiceOrderVariantPaymentType: build.mutation<
      PatchServiceOrderVariantPaymentTypeApiApiResponse,
      PatchServiceOrderVariantPaymentTypeApiApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/payment-type`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'paymentTypes', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderVariantPaymentTypeApiResponseSchema,
        requestSchema: PatchServiceOrderVariantPaymentTypeApiArgSchema,
      },
    }),
    getServiceOrderVariantAccounting: build.query<
      GetServiceOrderVariantAccountingApiResponse,
      GetServiceOrderVariantAccountingApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/accounting`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'serviceOrderVariantAccounting', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderVariantAccountingApiResponseSchema,
        requestSchema: GetServiceOrderVariantAccountingApiArgSchema,
      },
    }),
    patchServiceOrderVariantAccounting: build.mutation<
      PatchServiceOrderVariantAccountingApiResponse,
      PatchServiceOrderVariantAccountingApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/accounting`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceOrderVariantAccounting', id: queryArg.serviceOrderVariantId},
      ],
      extraOptions: {
        responseSchema: PatchServiceOrderVariantAccountingApiResponseSchema,
        requestSchema: PatchServiceOrderVariantAccountingApiArgSchema,
      },
    }),
    postServiceOrderVariantSetAsDefault: build.mutation<
      PostServiceOrderVariantSetAsDefaultApiResponse,
      PostServiceOrderVariantSetAsDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-order-variant/${queryArg.serviceOrderVariantId}/set-as-default`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  usePostServiceOrderVariantMutation,
  usePostServiceOrderVariantSetAsActiveMutation,
  usePostServiceOrderVariantSetAsInactiveMutation,
  useDeleteServiceOrderVariantMutation,
  useGetServiceOrderVariantQuery,
  usePatchServiceOrderVariantMutation,
  useGetServiceOrderVariantDocumentsQuery,
  usePatchServiceOrderVariantDocumentsMutation,
  useGetServiceOrderVariantMandatoryFieldsQuery,
  usePatchServiceOrderVariantMandatoryFieldsMutation,
  useGetSettingsServiceOrderVariantsQuery,
  useGetServiceOrderVariantPaymentTypeQuery,
  usePatchServiceOrderVariantPaymentTypeMutation,
  useGetServiceOrderVariantAccountingQuery,
  usePatchServiceOrderVariantAccountingMutation,
  useGetSettingsServiceOrderVariantsAllQuery,
  usePostServiceOrderVariantSetAsDefaultMutation,
} = metadaWorkshopServiceOrderVariantApi;
