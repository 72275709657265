import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {head} from 'ramda';
import {isArray, isString, noop} from 'ramda-adjunct';

import {
  useDeleteMaterialDiscountGroupMutation,
  usePostMaterialDiscountGroupSetActiveMutation,
  usePostMaterialDiscountGroupSetInActiveMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WorkshopCustomerGroups() {
  const navigate = useNavigate();

  const [deleteMaterialDiscountGroup] = useDeleteMaterialDiscountGroupMutation();
  const [materialDiscountGroupSetActive] = usePostMaterialDiscountGroupSetActiveMutation();
  const [materialDiscountGroupSetInActive] = usePostMaterialDiscountGroupSetInActiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        if (isString(id)) {
          navigate(composePath(settingsRoutes.workshopCustomerGroupDetail, {params: {id}}));
        }
      })
      .with('delete', () => {
        if (isString(id)) {
          openDeleteDialog({
            onConfirm: () =>
              deleteMaterialDiscountGroup({groupId: id})
                .unwrap()
                .then(refreshData)
                .catch(handleApiError),
          });
        }
      })
      .with('setAsActive', () => {
        if (isString(id)) {
          materialDiscountGroupSetActive({groupId: id})
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        }
      })
      .with('setAsInactive', () => {
        if (isString(id)) {
          materialDiscountGroupSetInActive({groupId: id})
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        }
      })
      .otherwise(noop);
  };

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.rabatDiscounts.labels.rabatDiscounts'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.rabatDiscounts.actions.newRabatDiscount'),
        onClick: () => navigate(settingsRoutes.workshopCustomerGroupCreate),
      },
    ],
  };

  return (
    <SettingsTemplate header={header} data-testid={testIds.settings.workshopCustomerGroups('page')}>
      <DataGrid gridCode="material-discount-group" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}
