import {concat, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {CustomerResponseBodyV2, PersonResponseBodyV2} from '@dms/api';

import {isCustomerNaturalPerson} from './isCustomerNaturalPerson';

export function getCustomerContacts(
  customer?: CustomerResponseBodyV2
): PersonResponseBodyV2[] | null {
  if (isNil(customer)) {
    return null;
  }
  return isCustomerNaturalPerson(customer!) && isNotNil(customer!.foundingPerson)
    ? concat(customer!.contacts, [customer!.foundingPerson])
    : customer!.contacts;
}
