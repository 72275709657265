import {yupResolver} from '@hookform/resolvers/yup';
import {HStack, Icon} from 'platform/foundation';

import {FC, useRef, useEffect, useState, PropsWithChildren, ReactElement, ChangeEvent} from 'react';
import {SubmitHandler, Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  TextField,
  selectIntegrationsSettings,
  getCebiaEquipmentCredentials,
  updateCebiaEquipmentCredential,
  CebiaEquipmentCredentials,
} from '@dms/teas';

import {ButtonSave, ButtonWrapper, WrapperCard} from './styles';
import {cebiaEquipmentAuthValidations} from './validations';

export const CebiaEquipmentAuthentication: FC<PropsWithChildren<any>> = (): ReactElement => {
  const dispatch = useDispatch();
  const [showPassword, setViewPassword] = useState(false);
  const integrationsSettings = useSelector(selectIntegrationsSettings);
  const form = useForm<CebiaEquipmentCredentials>({
    resolver: yupResolver(cebiaEquipmentAuthValidations()),
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const {
    formState,
    reset,
    control,
    formState: {errors},
  } = form;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const {hash} = window.location;

    if (hash === '#cebia-equipment-login') {
      wrapperRef?.current?.scrollIntoView();
      (wrapperRef?.current?.querySelector('input[name="username"]') as HTMLInputElement).focus();
    }

    dispatch(getCebiaEquipmentCredentials());
  }, [dispatch]);

  useEffect(() => {
    reset({
      username: integrationsSettings.cebiaEquipmentCredentials?.credentials?.username ?? '',
      password: '',
    });
  }, [integrationsSettings.cebiaEquipmentCredentials]);

  const onValid: SubmitHandler<CebiaEquipmentCredentials> = (credentials) => {
    dispatch(updateCebiaEquipmentCredential({credentials}));
  };
  const onSubmit = () => {
    form.handleSubmit(onValid)();
  };

  return (
    <WrapperCard
      ref={wrapperRef}
      id="cebia-equipment-authentication"
      title={i18n.t('general.labels.accessDataAuth')}
    >
      <HStack spacing={4}>
        <Controller
          name="username"
          control={control}
          render={({field: {onChange, value, ...rest}}) => (
            <TextField
              {...rest}
              type="text"
              value={value ?? ''}
              label={i18n.t('entity.user.labels.userName')}
              error={Boolean(errors.username?.message)}
              helperText={errors.username?.message}
              onChange={(e) => onChange(removeSpace(e))}
              data-testid="cebia-equipment-auth-form-field-username"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({field: {onChange, value, ...rest}}) => (
            <TextField
              {...rest}
              type={showPassword || !formState.isDirty ? 'text' : 'password'}
              label={i18n.t('general.labels.password')}
              placeholder="•••••"
              value={value ?? ''}
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message}
              endAdornment={{
                variant: 'button',
                disableFocusToInput: true,
                Icon: showPassword ? (
                  <Icon value="action/visibility" />
                ) : (
                  <Icon value="action/visibility_off" />
                ),
                onClick: () => setViewPassword(!showPassword),
              }}
              onChange={(e) => onChange(removeSpace(e))}
              data-testid="cebia-equipment-auth-form-field-password"
            />
          )}
        />
      </HStack>
      <ButtonWrapper>
        <ButtonSave
          primary
          disabled={!formState.isValid}
          onClick={onSubmit}
          data-testid={testIds.settings.vehicleServicesCebia('saveButton')}
        >
          {i18n.t('general.actions.save')}
        </ButtonSave>
      </ButtonWrapper>
    </WrapperCard>
  );
};

const removeSpace = (e: ChangeEvent<HTMLInputElement>) => e.target.value.replace(/\s+/g, '');
