import {
  Chip,
  EmptyStatus,
  EmptyStatusAction,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Heading, Show, Space, Text, VStack} from 'platform/foundation';

import {FC, ReactNode} from 'react';
import {useSelector} from 'react-redux';

import {filter, isNil, isNotNil, map} from 'ramda';
import {isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetParticipationQuery,
  useSetFeatureAsHighlightedMutation,
  useSetFeatureAsNotHighlightedMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, usePermissions} from '@dms/shared';
import {selectSalesVehicleDetail, VehicleCatalogueEnumOption} from '@dms/teas';

import {convertStringToCamelCase, sortByAsciiNumberLast} from 'shared';

interface FeaturesDetailProps {
  categories: VehicleCatalogueEnumOption[];
  features: (VehicleCatalogueEnumOption & {isHighlighted?: boolean})[];
  addFeaturesAction?: EmptyStatusAction;
}

export const FeaturesDetail: FC<FeaturesDetailProps> = ({
  categories,
  features,
  addFeaturesAction,
}) => {
  const {data: vehicle} = useSelector(selectSalesVehicleDetail);
  const [setFeatureAsHighlighted] = useSetFeatureAsHighlightedMutation();
  const [setFeatureAsNotHighlighted] = useSetFeatureAsNotHighlightedMutation();

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: vehicle?.id!,
    },
    {
      skip: isNil(vehicle),
    }
  );

  const [canUpdateFeature] = usePermissions({
    permissionKeys: ['updateFeature'],
    scopes: {
      updateFeature: {participation: vehicleParticipation},
    },
  });

  const nonEmptyCategories = categories?.filter((item) =>
    features.some((feature) => feature.categories?.includes(item.value))
  );

  const handleSetFeature = (featureKey: string, value: boolean) => {
    if (!canUpdateFeature) {
      return;
    }
    if (isNil(vehicle)) {
      showNotification.error();
      return;
    }

    const clickAction = value ? setFeatureAsNotHighlighted : setFeatureAsHighlighted;

    clickAction({vehicleId: vehicle.id, featureKey}).unwrap().catch(handleApiError);
  };

  const renderFeature = (item: FeaturesDetailProps['features'][number]) => (
    <Box paddingVertical={1}>
      <Show whenFeatureEnabled={featureFlags.VEHICLE_HIGHLIGHTED_FEATURES}>
        <Chip
          onChange={(value) => handleSetFeature(item.value, value)}
          value={isTrue(item.isHighlighted)}
          label={item.label}
          data-testid={testIds.vehicles.features(convertStringToCamelCase(item.value))}
          size="small"
          variant="ghost"
        />
      </Show>
      <Show whenFeatureDisabled={featureFlags.VEHICLE_HIGHLIGHTED_FEATURES}>
        <Text
          color="primary"
          size="small"
          data-testid={testIds.vehicles.features(convertStringToCamelCase(item.value))}
        >
          {item.label}
        </Text>
      </Show>
    </Box>
  );

  const renderFeaturesInCategory = (categoryKey: string) => {
    const filteredList = filter(
      (feature) => feature?.categories?.includes(categoryKey) ?? false,
      features
    );
    const sortedList = filteredList.sort((a, b) => sortByAsciiNumberLast(a.label, b.label));
    const mappedList = map(renderFeature, sortedList);
    // WORKAROUND: column-width is just a workaround for elements growing because of the long list
    //  of equipment name. The text overflows, so it's still visible, and it keeps the same style,
    //  and alignment as before with grid-template-columns.
    //  grid-template-rows doesn't work because it grows randomly based on each element in column,
    //  hence not keeping the ratio between each section same
    return (
      <Show when={isNotNilOrEmpty(mappedList)}>
        <div
          css={`
            column-count: 3;
            column-width: 20px;
          `}
        >
          {mappedList}
        </div>
      </Show>
    );
  };

  const renderCategory = (category: VehicleCatalogueEnumOption, level = 0): ReactNode => (
    <>
      <>
        {level === 0 ? (
          <Box>
            <Heading
              size={4}
              data-testid={testIds.vehicles.features(
                `featuresDetail-${convertStringToCamelCase(category.value)}`
              )}
            >
              {category.label}
            </Heading>
          </Box>
        ) : (
          <Box paddingTop={8}>
            <Heading
              size={5}
              data-testid={testIds.vehicles.features(
                `featuresDetail-${convertStringToCamelCase(category.value)}`
              )}
            >
              {category.label}
            </Heading>
          </Box>
        )}
        <Box paddingTop={4} />
        {renderFeaturesInCategory(category.value)}
      </>
      {!!category.children?.length &&
        category.children.map(
          (child, index) =>
            features.some((feature) => feature.categories?.includes(child.value)) && (
              <div key={index}>{renderCategory(child, level + 1)}</div>
            )
        )}
    </>
  );

  return (
    <div>
      <Show
        whenFeatureEnabled={featureFlags.VEHICLE_HIGHLIGHTED_FEATURES}
        when={isNotNilOrEmpty(features)}
      >
        <Text color="secondary" size="small">
          {i18n.t('entity.vehicle.labels.clickOnFeatureAlert')}
        </Text>
        <Space vertical={2} />
      </Show>
      <Space vertical={2} />
      {features.length ? (
        nonEmptyCategories.map((category, i) => (
          <div
            key={category.value}
            data-testid={testIds.vehicles.features(convertStringToCamelCase(category.value))}
          >
            {renderCategory(category)}
            <Show when={i !== nonEmptyCategories.length - 1}>
              <Separator />
            </Show>
          </div>
        ))
      ) : (
        <Box>
          <EmptyStatus
            action={{
              title: i18n.t('entity.vehicle.actions.addFeatures'),
              leftIcon: 'content/add_circle',
              ...(addFeaturesAction ?? {}),
            }}
            headline={i18n.t('entity.vehicle.labels.noFeaturesToShow')}
            data-testid={testIds.vehicles.features('featuresDetail-noFeaturesToShow')}
          />
        </Box>
      )}
      <Show
        when={isNotNil(vehicle) && isNotNilOrEmpty(vehicle.featuresByManufacturer)}
        whenFeatureEnabled={featureFlags.SALES_FEATURES_ADDITIONAL_INFO}
      >
        <Separator />
        <VStack spacing={2}>
          <Heading size={4}>{i18n.t('entity.vehicle.labels.featuresByManufacturer')}</Heading>
          <Box paddingLeft={2}>
            <Text size="small" preWrap>
              {vehicle?.featuresByManufacturer}
            </Text>
          </Box>
        </VStack>
      </Show>
    </div>
  );
};
