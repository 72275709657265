import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {KonzultaConfigRequestBody} from '@dms/api';

import {CebiaAutotracerAndReportConfig} from '../../types/CebiaAutotracerAndReportConfig';
import {CebiaAutotracerAndReportConfigRequestBody} from '../../types/CebiaAutotracerAndReportConfigRequestBody';
import {CebiaFeatureDecoderConfig} from '../../types/CebiaFeatureDecoderConfig';
import {CebiaFeatureDecoderConfigRequestBody} from '../../types/CebiaFeatureDecoderConfigRequestBody';
import {EmailConfig} from '../../types/EmailConfig';
import {EmailConfigRequestBody} from '../../types/EmailConfigRequestBody';
import {KonzultaConfig} from '../../types/KonzultaConfig';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {getKonzultaConfig, patchKonzultaConfig, getEmailConfig, patchEmailConfig} from './actions';

const STATE_NAME = 'generalSettings/Integrations';

export interface IntegrationsSliceType extends AsyncThunkState {
  cebiaEquipmentCredentials?: CebiaFeatureDecoderConfig | null;
  cebiaAutotraceAndReport?: CebiaAutotracerAndReportConfig | null;
  smsGataways: {
    konzulta?: KonzultaConfig | null;
  };
  emailServices: {
    smtp?: EmailConfig | null;
  };
}

const initialState: IntegrationsSliceType = {
  loading: {},
  errors: {},
  cebiaEquipmentCredentials: null,
  cebiaAutotraceAndReport: null,
  smsGataways: {
    konzulta: null,
  },
  emailServices: {
    smtp: null,
  },
};

const integrationsSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    saveCebiaAutotraceAndReportData(
      state,
      {payload}: PayloadAction<CebiaAutotracerAndReportConfig>
    ) {
      state.cebiaAutotraceAndReport = payload;
    },
    saveCebiaEquipmentCredentials(state, {payload}: PayloadAction<CebiaFeatureDecoderConfig>) {
      state.cebiaEquipmentCredentials = payload;
    },
    setEmailConfig(state, {payload}: PayloadAction<Partial<EmailConfigRequestBody>>) {
      state.emailServices.smtp = {...state.emailServices.smtp, ...payload};
    },
    setKonzultaConfig(state, {payload}: PayloadAction<Partial<KonzultaConfigRequestBody>>) {
      state.smsGataways.konzulta = {...state.smsGataways.konzulta, ...payload};
    },
    getCebiaEquipmentCredentials() {
      return;
    },
  },
  extraReducers(builder) {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    asyncThunkReducer(getKonzultaConfig.action, (state, {payload}) => {
      state.smsGataways.konzulta = payload;
    });

    asyncThunkReducer(patchKonzultaConfig.action, (state, {payload}) => {
      state.smsGataways.konzulta = {...state.smsGataways.konzulta, ...payload};
    });

    asyncThunkReducer(getEmailConfig.action, (state, {payload}) => {
      state.emailServices.smtp = payload;
    });

    asyncThunkReducer(patchEmailConfig.action, (state, {payload}) => {
      state.emailServices.smtp = {...state.emailServices.smtp, ...payload};
    });

    handleThunkStates();
  },
});

const {reducer, actions} = integrationsSlice;

export const patchFeatureDecoderConfig = createAction<CebiaFeatureDecoderConfigRequestBody>(
  `${STATE_NAME}/patchFeatureDecoderConfig`
);

export const patchCebiaAutotraceAndReport = createAction<CebiaAutotracerAndReportConfigRequestBody>(
  `${STATE_NAME}/patchCebiaAutotraceAndReport`
);

export const getCebiaAutotraceAndReport = createAction(`${STATE_NAME}/getCebiaAutotraceAndReport`);

export const updateCebiaEquipmentCredential = createAction<CebiaFeatureDecoderConfigRequestBody>(
  `${STATE_NAME}/updateCebiaEquipmentCredential`
);

export const {
  setEmailConfig,
  setKonzultaConfig,
  saveCebiaAutotraceAndReportData,
  saveCebiaEquipmentCredentials,
  getCebiaEquipmentCredentials,
} = actions;

export const TenantReducer = reducer;
