import {Card, closeCurrentDialog, DataStatus, openDialog} from 'platform/components';
import {Scroll, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, isNil, reject} from 'ramda';

import {LabourtBasketItemType, useGetBasketLabourItemsApiQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {BasketItem} from './BasketItem';
import {EditWorkItem} from './EditWorkItem';

interface WorkBasketCardProps extends TestIdProps {
  packageId: string;
}

export function WorkBasketCard(props: WorkBasketCardProps) {
  const {data, isLoading, isError} = useGetBasketLabourItemsApiQuery({
    packageId: props.packageId,
  });

  const handleEditItem = (item: LabourtBasketItemType) =>
    openDialog(
      <EditWorkItem
        itemId={item.basketItemId ?? ''}
        context="basket"
        onClose={closeCurrentDialog}
      />,
      {
        title: item.name ?? '',
      }
    );

  const count = data?.labourBasketItem?.length ?? 0;
  const basketItems = reject(isNil, data?.labourBasketItem ?? []);

  return (
    <Card
      isFullHeight
      title={`${i18n.t('general.labels.selected')} (${count})`}
      data-testid={suffixTestId('header', props)}
    >
      <VStack height="100%">
        <Scroll flex={1} auto>
          <VStack height="100%" spacing={4}>
            <DataStatus
              isLoading={isLoading}
              isError={isError}
              minHeight="100%"
              isEmpty={count === 0}
            >
              {basketItems.map((item, index) => (
                <BasketItem
                  key={item?.basketItemId}
                  id={item.basketItemId ?? ''}
                  name={item.number ?? ''}
                  quantity={item.quantity ?? 0}
                  subtitle={item.name ?? ''}
                  parameters={[getPriceTypeLabel(item.priceType)]}
                  onEditClick={() => handleEditItem(item)}
                  data-testid={suffixTestId(`basketItem-[${index}]`, props)}
                />
              ))}
            </DataStatus>
          </VStack>
        </Scroll>
      </VStack>
    </Card>
  );
}

const getPriceTypeLabel = (priceType: string | Nullish) =>
  match(priceType)
    .with('SVCPRICETYPE_TIME_NORM', always(i18n.t('entity.addWork.lables.timeNorm')))
    .with(
      'SVCPRICETYPE_CALCULATION_PRICE',
      always(i18n.t('entity.addWork.lables.calculationPrice'))
    )
    .with('SVCPRICETYPE_DIRECT_PRICE', always(i18n.t('entity.addWork.lables.directPrice')))
    .with('SVCPRICETYPE_COOPERATION', always(i18n.t('entity.addWork.lables.cooperation')))
    .otherwise(always(undefined));
