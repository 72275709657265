import {BreadcrumbType, Card} from 'platform/components';

import {useNavigate} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {EditGdprConsentTypeForm} from './components/EditGdprConsentTypeForm';

export function EditGdprConsentType() {
  const navigate = useNavigate();
  const {id} = useRequiredParams();

  function handleSuccess() {
    navigate(settingsRoutes.gdprConsentTypes);
  }

  function handleCancel() {
    navigate(settingsRoutes.gdprConsentTypes);
  }

  const breadcrumbs: BreadcrumbType[] = [
    {
      label: i18n.t('entity.gdpr.labels.gdprConsentTypes'),
      href: settingsRoutes.gdprConsentTypes,
    },
  ];

  return (
    <SettingsTemplate header={{breadcrumbs}} data-testid="edit-gdpr-consent-type-page">
      <Card title={i18n.t('page.settings.labels.editConsentType')}>
        <EditGdprConsentTypeForm onSuccess={handleSuccess} onCancel={handleCancel} id={id} />
      </Card>
    </SettingsTemplate>
  );
}
