import {DataStatus, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {useAutocarisAuthenticateMutation, useGetAutocarisPlatformQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {handleApiError} from '@dms/shared';
import {AdvertisingPlatformCodeEnum} from '@dms/teas';

import {useBranchId} from '../../../hooks/useBranchId';
import {advertisingPlatformNames} from '../consts/advertisingPlatformNames';
import {
  PlatformAuthenticationForm,
  PlatformAuthenticationFormProps,
} from './components/PlatformAuthenticationForm';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

export function PlatformSettingsAutocaris() {
  const branchId = useBranchId();
  const params = {branchId};
  const {data, isLoading, isError} = useGetAutocarisPlatformQuery({params});
  const [saveAuthentication] = useAutocarisAuthenticateMutation();

  const onSubmitCredentials: PlatformAuthenticationFormProps['onSubmit'] = (values) =>
    saveAuthentication({
      body: values,
      params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <DataStatus isLoading={isLoading} isError={isError}>
        {isNotNil(data) && (
          <PlatformAuthenticationForm
            platformName={advertisingPlatformNames[AdvertisingPlatformCodeEnum.AUTOCARIS]}
            defaultValues={data.credentials}
            onSubmit={onSubmitCredentials}
          />
        )}
        <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.AUTOCARIS} />
      </DataStatus>
    </Show>
  );
}
