/* eslint-disable eag/match-named-export */
import {z} from 'zod';

import {RecordIdHttpModelSchema, UuidResponseBodySchema} from './api';

// Enums
export const TaskPriorityEnumSchema = z.enum(['LOW', 'MEDIUM', 'HIGH']);
export const TaskTypeEnumSchema = z.enum(['TASK', 'MEETING', 'CALL']);
export const TaskStateEnumSchema = z.enum(['TODO', 'IN_PROGRESS', 'DONE']);

// Models
export const CreateTaskRequestBodySchema = z.object({
  title: z.string(),
  priority: TaskPriorityEnumSchema,
  type: TaskTypeEnumSchema,
  state: TaskStateEnumSchema,
  reportedBy: z.string(),
  assignedTo: z.string(),
  dueDate: z.string().nullable(),
  dueFrom: z.string().nullable(),
  dueTo: z.string().nullable(),
  relatedRecordId: RecordIdHttpModelSchema.nullable(),
  description: z.string().nullable(),
});

export const EditTaskRequestBodySchema = CreateTaskRequestBodySchema;

export const TaskResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
  priority: TaskPriorityEnumSchema,
  type: TaskTypeEnumSchema,
  state: TaskStateEnumSchema,
  createdAt: z.string(),
  createdBy: z.string(),
  reportedBy: z.string(),
  assignedTo: z.string(),
  dueDate: z.string().nullable(),
  dueFrom: z.string().nullable(),
  dueTo: z.string().nullable(),
  finishedAt: z.string().nullable(),
  relatedRecordId: RecordIdHttpModelSchema.nullable(),
  description: z.string().nullable(),
});

export const GetMyTasksCountResponseBodySchema = z.object({
  count: z.number(),
});

// API Response Schemas
export const CreateTaskApiResponseSchema = UuidResponseBodySchema; // status 201
export const DeleteTaskApiResponseSchema = z.void(); // status 204
export const EditTaskApiResponseSchema = z.void(); // status 204
export const GetTaskApiResponseSchema = TaskResponseBodySchema; // status 200
export const GetMyTasksApiResponseSchema = z.array(TaskResponseBodySchema); // status 200
export const GetMyTasksCountApiResponseSchema = GetMyTasksCountResponseBodySchema; // status 200
export const GetTasksByMeApiResponseSchema = z.array(TaskResponseBodySchema); // status 200

// API Arguments Schemas
export const CreateTaskApiArgSchema = z.object({
  createTaskRequestBody: CreateTaskRequestBodySchema,
});

export const DeleteTaskApiArgSchema = z.object({
  id: z.string(),
});

export const EditTaskApiArgSchema = z.object({
  id: z.string(),
  editTaskRequestBody: EditTaskRequestBodySchema,
});

export const GetTaskApiArgSchema = z.object({
  id: z.string(),
});

export const GetMyTasksApiArgSchema = z.void();
export const GetMyTasksCountApiArgSchema = z.void();
export const GetTasksByMeApiArgSchema = z.void();

// Exported Types (Inferred)
export type TaskPriorityEnum = z.infer<typeof TaskPriorityEnumSchema>;
export type TaskTypeEnum = z.infer<typeof TaskTypeEnumSchema>;
export type TaskStateEnum = z.infer<typeof TaskStateEnumSchema>;
export type CreateTaskRequestBody = z.infer<typeof CreateTaskRequestBodySchema>;
export type EditTaskRequestBody = z.infer<typeof EditTaskRequestBodySchema>;
export type TaskResponseBody = z.infer<typeof TaskResponseBodySchema>;
export type GetMyTasksCountResponseBody = z.infer<typeof GetMyTasksCountResponseBodySchema>;
export type CreateTaskApiResponse = z.infer<typeof CreateTaskApiResponseSchema>;
export type DeleteTaskApiResponse = z.infer<typeof DeleteTaskApiResponseSchema>;
export type EditTaskApiResponse = z.infer<typeof EditTaskApiResponseSchema>;
export type GetTaskApiResponse = z.infer<typeof GetTaskApiResponseSchema>;
export type GetMyTasksApiResponse = z.infer<typeof GetMyTasksApiResponseSchema>;
export type GetMyTasksCountApiResponse = z.infer<typeof GetMyTasksCountApiResponseSchema>;
export type GetTasksByMeApiResponse = z.infer<typeof GetTasksByMeApiResponseSchema>;
export type CreateTaskApiArg = z.infer<typeof CreateTaskApiArgSchema>;
export type DeleteTaskApiArg = z.infer<typeof DeleteTaskApiArgSchema>;
export type EditTaskApiArg = z.infer<typeof EditTaskApiArgSchema>;
export type GetTaskApiArg = z.infer<typeof GetTaskApiArgSchema>;
export type GetMyTasksApiArg = z.infer<typeof GetMyTasksApiArgSchema>;
export type GetMyTasksCountApiArg = z.infer<typeof GetMyTasksCountApiArgSchema>;
export type GetTasksByMeApiArg = z.infer<typeof GetTasksByMeApiArgSchema>;
