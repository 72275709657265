import {isFeatureEnabled} from 'feature-flags';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {UserManagementTabs} from './components/UserManagementTabs';
import {UserOverview} from './UserOverview';

export function UserOverviewWrapper() {
  if (isFeatureEnabled(featureFlags.SETTINGS_MANAGEMENT_SPLIT)) {
    return <UserOverview />;
  }

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.settings.labels.userManagement')}}
      data-testid="settings-userManagement"
    >
      <UserManagementTabs />
    </SettingsTemplate>
  );
}
