import {
  Alert,
  ButtonGroup,
  ButtonProps,
  closeCurrentDialog,
  DataStatus,
  showNotification,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {includes} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  PatchVehicleWindshieldAdvertisingSettingsRequestBody,
  useEditWindshieldSettingsMutation,
  useGetWindshieldSettingsQuery,
  VehicleWindshieldAdvertisingSettingsResponseBody,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {$AdvertisingSettingsRequestBody, Form} from '@dms/teas';

import {appendSuffix, buildArray, suffixTestId, TestIdProps} from 'shared';

import {AdvertisingAttributeField} from './AdvertisingAttributeField';

interface WindshieldEditProps extends TestIdProps {
  vehicleId: string;
  isDisabled: boolean;
}

const SKIPPED_FIELDS = ['title_extension', 'description'];

export function WindshieldEdit(props: WindshieldEditProps) {
  const {
    data: listingAttributes,
    isLoading,
    isError,
  } = useGetWindshieldSettingsQuery(
    {
      vehicleId: props.vehicleId,
    },
    {
      skip: isNilOrEmpty(props.vehicleId),
      selectFromResult: ({data, ...res}) => ({
        ...res,
        data: {
          ...data,
          attributes: data?.attributes.filter(
            (attribute) => !includes(attribute.code, SKIPPED_FIELDS)
          ),
        } as VehicleWindshieldAdvertisingSettingsResponseBody,
      }),
    }
  );

  const [editWindshieldSettings, {isLoading: isEditLoading}] = useEditWindshieldSettingsMutation();

  const handleSubmit = async (values: PatchVehicleWindshieldAdvertisingSettingsRequestBody) => {
    await editWindshieldSettings({
      vehicleId: props.vehicleId,
      patchVehicleWindshieldAdvertisingSettingsRequestBody: values,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNilOrEmpty(listingAttributes)}>
      <Form<
        VehicleWindshieldAdvertisingSettingsResponseBody,
        VehicleWindshieldAdvertisingSettingsResponseBody,
        PatchVehicleWindshieldAdvertisingSettingsRequestBody
      >
        formId={appendSuffix('windshieldEditForm', props['data-testid'])}
        onSubmit={handleSubmit}
        schema={$AdvertisingSettingsRequestBody}
        getFormValues={(settings) => ({
          attributes: settings.attributes,
        })}
        getRequestBody={(values) => ({
          attributes: values.attributes.map((attribute) => ({
            ...attribute,
            value: attribute.override ? attribute.value : null,
          })),
        })}
        initialValues={listingAttributes}
        render={(formRendererProps) => {
          const {handleSubmit, FieldArray} = formRendererProps;

          const actions = buildArray<ButtonProps>()
            .add({
              title: i18n.t('general.actions.discard'),
              variant: 'secondary',
              onClick: closeCurrentDialog,
            })
            .whenNot(props.isDisabled, {
              title: i18n.t('general.actions.confirm'),
              onClick: handleSubmit,
              isLoading: isEditLoading,
            });

          return (
            <form onSubmit={handleSubmit}>
              <Grid columns={12}>
                <GridItem span={12}>
                  <Alert
                    data-testid={suffixTestId('windshieldEdit-alert', props)}
                    type="inline"
                    variant="info"
                    title={i18n.t('general.notifications.advertisement.windshieldDataEditTitle')}
                    message={i18n.t(
                      'general.notifications.advertisement.windshieldDataEditSubtitle'
                    )}
                  />
                </GridItem>
                <FieldArray<VehicleWindshieldAdvertisingSettingsResponseBody> name="attributes">
                  {({fields}) =>
                    fields.map((name, index) => (
                      <AdvertisingAttributeField
                        key={`${name}_${index}`}
                        name={name}
                        isDisabled={props.isDisabled}
                      />
                    ))
                  }
                </FieldArray>
                <GridItem span={12}>
                  <ButtonGroup align="right" buttons={actions} />
                </GridItem>
              </Grid>
            </form>
          );
        }}
      />
    </DataStatus>
  );
}
