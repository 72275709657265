import {
  GetGeneralSettingsStorageLocationApiResponse,
  GetTireWarehouseSettingsApiResponse,
} from '@dms/api';

import {Nullish} from 'shared';

export const getStorageLocationPicker = (
  generalSettingsStorageLocation: GetGeneralSettingsStorageLocationApiResponse | Nullish,
  tireWarehouseSettings: GetTireWarehouseSettingsApiResponse | Nullish
) => {
  if (tireWarehouseSettings?.isWarehouseAllowed) {
    return 'STORAGE_LOCATION';
  }

  if (generalSettingsStorageLocation?.isStoreLocationAllowed) {
    return 'STORAGE_SECTOR';
  }

  return 'MANUAL';
};
