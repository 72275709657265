import {z} from 'zod';

import {FileResponseBodySchema} from '../types/api';
import {
  AddUpsellFromCatalogApiArg,
  AddUpsellFromCatalogApiResponse,
  ChangeBranchBusinessCaseApiArg,
  ChangeBranchBusinessCaseApiResponse,
  CloseBusinessCaseApiArg,
  CloseBusinessCaseApiResponse,
  CreateAgreementOnFutureContractApiArg,
  CreateAgreementOnFutureContractApiResponse,
  CreateBusinessCaseApiArg,
  CreateBusinessCaseApiResponse,
  CreateBusinessCaseOfferApiArg,
  CreateBusinessCaseOfferApiRes,
  CreateBusinessCaseSeriesDefinitionApiArg,
  CreateBusinessCaseSeriesDefinitionApiResponse,
  CreatePurchaseBrokerageCancelationDocumentApiArg,
  CreatePurchaseBrokerageCancelationDocumentApiResponse,
  CreateSaleOfferDocumentApiArg,
  CreateSaleOfferDocumentApiResponse,
  CreateUpsellApiArg,
  CreateUpsellApiResponse,
  DeleteOfferPurchaseBrokerageVehicleApiArg,
  DeleteOfferPurchaseBrokerageVehicleApiResponse,
  DeleteOfferPurchaseVehicleApiArg,
  DeleteOfferPurchaseVehicleApiResponse,
  DeleteOfferSaleVehicleApiArg,
  DeleteOfferSaleVehicleApiResponse,
  DeleteUpsellApiArg,
  DeleteUpsellApiResponse,
  DeprecatedGetVerificationRegistersApiArg,
  DeprecatedGetVerificationRegistersApiResponse,
  DeprecatedVerifyApiArg,
  EndBusinessCaseApiArg,
  EndBusinessCaseApiResponse,
  EndPurchaseBrokerageBusinessCaseApiArg,
  PutCloseContractApiResponse,
  EndPurchaseBrokerageBusinessCaseApiResponse,
  GetBusinessCaseSettingsApiResponse,
  PutBusinessCaseSettingsApiArg,
  PutBusinessCaseSettingsApiResponse,
  FinishPricesUpsellApiArg,
  FinishPricesUpsellApiResponse,
  GetActiveBusinessCaseByCustomerApiArg,
  GetActiveBusinessCaseByCustomerApiResponse,
  GetBusinessCaseActionsApiArg,
  GetBusinessCaseActionsApiResponse,
  GetBusinessCaseApiArg,
  GetBusinessCaseApiResponse,
  GetBusinessCaseByBusinessCaseApiArg,
  GetBusinessCaseByBusinessCaseApiRes,
  GetBusinessCaseCheckoutApiArg,
  GetBusinessCaseCheckoutApiResponse,
  GetBusinessCasesByVehicleCountApiArg,
  GetBusinessCasesByVehicleCountApiResponse,
  GetBusinessCaseSeriesDefinitionApiArg,
  GetBusinessCaseSeriesDefinitionApiResponse,
  GetBusinessCaseSeriesDefinitionListApiArg,
  GetBusinessCaseSeriesDefinitionListApiResponse,
  GetBusinessCaseStatisticsApiArg,
  GetBusinessCaseStatisticsApiResponse,
  GetCustomerRequestedPriceApiArg,
  GetCustomerRequestedPriceApiRes,
  GetLastActiveBusinessCaseApiArg,
  GetLastActiveBusinessCaseApiResponse,
  GetLastActiveBusinessCaseByCustomerApiArg,
  GetLastActiveBusinessCaseByCustomerApiResponse,
  GetLastActiveBuyingBusinessCaseApiArg,
  GetLastActiveBuyingBusinessCaseApiRes,
  GetLastActiveSellingBusinessCaseApiArg,
  GetLastActiveSellingBusinessCaseApiRes,
  GetPurchaseBrokerageTemplatesApiArg,
  GetPurchaseBrokerageTemplatesApiResponse,
  GetPurchaseTemplatesApiResponse,
  GetSaleOfferTemplatesApiArg,
  GetSaleOfferTemplatesApiResponse,
  GetSaleTemplatesApiResponse,
  OfferBusinessCaseInsuranceApiArg,
  PatchBusinessCaseApiArg,
  PatchBusinessCaseApiResponse,
  PatchBusinessCaseCustomCodeApiArg,
  PatchBusinessCaseCustomCodeApiResponse,
  PatchPricingOfferPurchaseVehicleApiArg,
  PatchPricingOfferPurchaseVehicleApiResponse,
  PatchUpsellApiArg,
  PatchUpsellApiResponse,
  PutBusinessCaseContactPersonApiArg,
  PutBusinessCaseContactPersonApiResponse,
  PutBusinessCaseSeriesDefinitionApiArg,
  PutBusinessCaseSeriesDefinitionApiResponse,
  PutCustomerRequestedPriceApiArg,
  PutCustomerRequestedPriceApiRes,
  PutPurchaseVehiclePricingCentralizedApiArg,
  PutPurchaseVehiclePricingCentralizedApiRes,
  RecommendBusinessCaseCustomerApiArg,
  SetReadyForPricingApiArg,
  SetReadyForPricingApiRes,
  PatchFeesOfferPurchaseBrokerageVehicleApiResponse,
  PatchFeesOfferPurchaseBrokerageVehicleApiArg,
  CalculateBrokerageFeesApiResponse,
  CalculateBrokerageFeesApiArg,
  TakeoverBusinessCaseApiResponse,
  TakeoverBusinessCaseApiArg,
  DeleteBusinessCaseApiResponse,
  DeleteBusinessCaseApiArg,
  GetPurchaseOfferTemplatesApiResponse,
  GetPurchaseOfferTemplatesApiArg,
  CreatePurchaseOfferDocumentApiResponse,
  CreatePurchaseOfferDocumentApiArg,
  PutBackToOfferApiArg,
  PutBackToOfferApiResponse,
  PutCloseContractApiArg,
  PutReopenContractApiResponse,
  PutReopenContractApiArg,
  CreateOfferPurchaseVehicleApiResponse,
  CreateOfferPurchaseVehicleApiArg,
  ChangeToPurchaseBrokeragePurchaseVehicleApiArg,
  ChangeToPurchaseBrokeragePurchaseVehicleApiResponse,
  MarkOfferDocumentsAsActualApiResponse,
  MarkOfferDocumentsAsActualApiArg,
  CreateSaleVehicleApiArg,
  CreateSaleVehicleApiResponse,
  PatchSaleVehicleApiArg,
  PatchSaleVehicleApiResponse,
  PatchBuyingOfferPurchaseVehicleApiArg,
  PatchBuyingOfferPurchaseVehicleApiResponse,
  SelectBusinessCaseCheckoutOrderContractInformationApiArg,
  SelectBusinessCaseCheckoutOrderContractInformationApiResponse,
  SelectBusinessCaseCheckoutOrderDeputyPersonsApiArg,
  SelectBusinessCaseCheckoutOrderDeputyPersonsApiResponse,
  CreateOfferApiArg,
  CreateOfferApiResponse,
  DeleteOfferApiArg,
  DeleteOfferApiResponse,
  SwitchOfferSaleVehicleApiResponse,
  SwitchOfferSaleVehicleApiArg,
  FindBusinessCasesByVehicleApiArg,
  FindBusinessCasesByVehicleApiResponse,
  GetActiveBusinessCasesByVehicleApiArg,
  GetActiveBusinessCasesByVehicleApiResponse,
  GetCheckoutForBusinessCaseApiResponse,
  GetCheckoutForBusinessCaseApiArg,
  GetPurchaseBusinessCasePricesOverviewApiResponse,
  GetPurchaseBusinessCasePricesOverviewApiArg,
  GetSaleBusinessCasePricesOverviewApiResponse,
  GetSaleBusinessCasePricesOverviewApiArg,
  PricePurchaseBusinessCaseApiResponse,
  PricePurchaseBusinessCaseApiArg,
  BusinessCaseSeriesDefinitionResponseBodySchema,
  GetBusinessCaseSeriesDefinitionListApiArgSchema,
  BusinessCaseSeriesDefinitionRequestBodySchema,
  GetBusinessCaseSeriesDefinitionApiArgSchema,
  PutBusinessCaseSeriesDefinitionApiArgSchema,
  ChangeBranchBusinessCaseApiResponseSchema,
  ChangeBranchBusinessCaseApiArgSchema,
  BusinessCaseResponseBodySchema,
  CloseBusinessCaseApiArgSchema,
  EndBusinessCaseApiArgSchema,
  EndPurchaseBrokerageBusinessCaseApiArgSchema,
  LastActiveBusinessCaseResponseBodySchema,
  GetLastActiveBusinessCaseApiArgSchema,
  GetActiveBusinessCaseByCustomerApiResponseSchema,
  GetActiveBusinessCaseByCustomerApiArgSchema,
  CreateAgreementOnFutureContractApiArgSchema,
  GetPurchaseTemplatesApiResponseSchema,
  GetPurchaseBrokerageTemplatesApiResponseSchema,
  GetPurchaseBrokerageTemplatesApiArgSchema,
  CreatePurchaseBrokerageCancelationDocumentApiArgSchema,
  GetSaleTemplatesApiResponseSchema,
  CreateSaleOfferDocumentApiArgSchema,
  GetSaleOfferTemplatesApiResponseSchema,
  GetSaleOfferTemplatesApiArgSchema,
  PutBackToOfferApiResponseSchema,
  PutBackToOfferApiArgSchema,
  PutCloseContractApiArgSchema,
  PutCloseContractApiResponseSchema,
  PutReopenContractApiResponseSchema,
  PutReopenContractApiArgSchema,
  DeprecatedGetVerificationRegistersApiResponseSchema,
  DeprecatedGetVerificationRegistersApiArgSchema,
  DeprecatedVerifyApiArgSchema,
  SetReadyForPricingApiResSchema,
  SetReadyForPricingApiArgSchema,
  GetBusinessCaseCheckoutApiArgSchema,
  GetCustomerRequestedPriceApiResSchema,
  GetCustomerRequestedPriceApiArgSchema,
  CreateBusinessCaseOfferApiArgSchema,
  PutCustomerRequestedPriceApiResSchema,
  PutCustomerRequestedPriceApiArgSchema,
  PutPurchaseVehiclePricingCentralizedApiResSchema,
  PutPurchaseVehiclePricingCentralizedApiArgSchema,
  OfferResponseBodySchema,
  AddUpsellFromCatalogApiArgSchema,
  CreateUpsellApiArgSchema,
  DeleteUpsellApiArgSchema,
  PatchUpsellApiArgSchema,
  UpsellResponseBodySchema,
  FinishPricesUpsellApiArgSchema,
  GetCheckoutForBusinessCaseApiArgSchema,
  BusinessCaseStatisticsResponseBodySchema,
  GetBusinessCaseStatisticsApiArgSchema,
  CreateBusinessCaseApiArgSchema,
  GetBusinessCaseApiArgSchema,
  DeleteOfferSaleVehicleApiArgSchema,
  PatchSaleVehicleApiArgSchema,
  PatchBuyingOfferPurchaseVehicleApiArgSchema,
  CreateSaleVehicleApiArgSchema,
  DeleteOfferPurchaseBrokerageVehicleApiResponseSchema,
  DeleteOfferPurchaseBrokerageVehicleApiArgSchema,
  DeleteOfferPurchaseVehicleApiResponseSchema,
  DeleteOfferPurchaseVehicleApiArgSchema,
  CreateOfferPurchaseVehicleApiArgSchema,
  PatchBusinessCaseCustomCodeApiArgSchema,
  PatchPricingOfferPurchaseVehicleApiArgSchema,
  PatchFeesOfferPurchaseBrokerageVehicleApiArgSchema,
  CalculateBrokerageFeesApiArgSchema,
  CalculatedBrokerageFeesResponseBodySchema,
  GetLastActiveBuyingBusinessCaseApiArgSchema,
  GetLastActiveSellingBusinessCaseApiArgSchema,
  OfferBusinessCaseInsuranceApiArgSchema,
  GetBusinessCaseByBusinessCaseApiArgSchema,
  RecommendBusinessCaseCustomerApiArgSchema,
  GetBusinessCasesByVehicleCountApiArgSchema,
  BusinessCaseCountResponseBodySchema,
  GetBusinessCaseActionsApiArgSchema,
  BusinessCaseActionsV2ResponseBodySchema,
  PatchBusinessCaseApiArgSchema,
  PutBusinessCaseContactPersonApiArgSchema,
  TakeoverBusinessCaseApiResponseSchema,
  TakeoverBusinessCaseApiArgSchema,
  DeleteBusinessCaseApiResponseSchema,
  DeleteBusinessCaseApiArgSchema,
  CreatePurchaseOfferDocumentApiArgSchema,
  GetPurchaseOfferTemplatesApiResponseSchema,
  GetPurchaseOfferTemplatesApiArgSchema,
  ChangeToPurchaseBrokeragePurchaseVehicleApiArgSchema,
  BusinessCaseSettingsResponseBodySchema,
  PutBusinessCaseSettingsApiResponseSchema,
  PutBusinessCaseSettingsApiArgSchema,
  MarkOfferDocumentsAsActualApiResponseSchema,
  MarkOfferDocumentsAsActualApiArgSchema,
  SelectBusinessCaseCheckoutOrderContractInformationApiArgSchema,
  SelectBusinessCaseCheckoutOrderDeputyPersonsApiArgSchema,
  CreateOfferApiArgSchema,
  DeleteOfferApiArgSchema,
  DeleteOfferApiResponseSchema,
  SwitchOfferSaleVehicleApiArgSchema,
  PricePurchaseBusinessCaseApiResponseSchema,
  PricePurchaseBusinessCaseApiArgSchema,
  FindBusinessCasesByVehicleApiResponseSchema,
  FindBusinessCasesByVehicleApiArgSchema,
  GetActiveBusinessCasesByVehicleApiResponseSchema,
  GetActiveBusinessCasesByVehicleApiArgSchema,
  PurchaseBusinessCasePricesOverviewResponseBodySchema,
  GetPurchaseBusinessCasePricesOverviewApiArgSchema,
  SaleBusinessCasePricesOverviewResponseBodySchema,
  GetSaleBusinessCasePricesOverviewApiArgSchema,
  GetLastActiveBusinessCaseByCustomerApiResponseSchema,
  GetLastActiveBusinessCaseByCustomerApiArgSchema,
  PatchBusinessCaseVehicleToBrokeragePricingApiResponse,
  PatchBusinessCaseVehicleToBrokeragePricingApiArg,
  PatchBusinessCaseVehicleToBuyPricingApiResponse,
  PatchBusinessCaseVehicleToBuyPricingApiArg,
} from '../types/businessCase';
import {CheckoutResponseBodySchema, OrderResponseBodySchema} from '../types/checkout';
import {omneticApi} from './baseApi/omneticApi';

export const businessCaseApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getBusinessCaseSeriesDefinitionList: build.query<
      GetBusinessCaseSeriesDefinitionListApiResponse,
      GetBusinessCaseSeriesDefinitionListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/series-definition`,
        queryArg,
      }),
      providesTags: ['BCList'],
      extraOptions: {
        requestSchema: GetBusinessCaseSeriesDefinitionListApiArgSchema,
        responseSchema: BusinessCaseSeriesDefinitionResponseBodySchema,
      },
    }),

    createBusinessCaseSeriesDefinition: build.mutation<
      CreateBusinessCaseSeriesDefinitionApiResponse,
      CreateBusinessCaseSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/series-definition`,
        method: 'POST',
        body: queryArg.businessCaseSeriesDefinitionRequestBody,
        queryArg,
      }),
      invalidatesTags: ['BCList', 'VehicleLastActiveBC'],
      extraOptions: {
        requestSchema: BusinessCaseSeriesDefinitionRequestBodySchema,
        responseSchema: BusinessCaseSeriesDefinitionResponseBodySchema,
      },
    }),

    getBusinessCaseSeriesDefinition: build.query<
      GetBusinessCaseSeriesDefinitionApiResponse,
      GetBusinessCaseSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/series-definition/${queryArg.seriesDefinitionId}`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetBusinessCaseSeriesDefinitionApiArgSchema,
        responseSchema: BusinessCaseSeriesDefinitionResponseBodySchema,
      },
    }),

    putBusinessCaseSeriesDefinition: build.mutation<
      PutBusinessCaseSeriesDefinitionApiResponse,
      PutBusinessCaseSeriesDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/series-definition/${queryArg.seriesDefinitionId}`,
        method: 'PUT',
        body: queryArg.putBusinessCaseSeriesDefinitionRequestBody,
        queryArg,
      }),
      invalidatesTags: ['BCList'],
      extraOptions: {
        requestSchema: PutBusinessCaseSeriesDefinitionApiArgSchema,
        responseSchema: BusinessCaseSeriesDefinitionResponseBodySchema,
      },
    }),

    changeBranchBusinessCase: build.mutation<
      ChangeBranchBusinessCaseApiResponse,
      ChangeBranchBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/change-branch`,
        method: 'POST',
        body: queryArg.changeBranchBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: ['BusinessCaseDetail', 'BusinessCaseActions'],
      extraOptions: {
        requestSchema: ChangeBranchBusinessCaseApiArgSchema,
        responseSchema: ChangeBranchBusinessCaseApiResponseSchema,
      },
    }),

    closeBusinessCase: build.mutation<CloseBusinessCaseApiResponse, CloseBusinessCaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/closeCase`,
        method: 'PUT',
        queryArg,
      }),
      invalidatesTags: [
        'BusinessCaseDetail',
        'SaleVehicle',
        'BusinessCaseActions',
        'SaleVehicleActions',
        'Vehicle',
      ],
      extraOptions: {
        requestSchema: CloseBusinessCaseApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),

    endBusinessCase: build.mutation<EndBusinessCaseApiResponse, EndBusinessCaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/end`,
        method: 'PUT',
        body: queryArg.closeBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: [
        'BusinessCaseDetail',
        'SaleVehicle',
        'BusinessCaseActions',
        'SaleVehicleActions',
        'Vehicle',
      ],
      extraOptions: {
        requestSchema: EndBusinessCaseApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),

    endPurchaseBrokerageBusinessCase: build.mutation<
      EndPurchaseBrokerageBusinessCaseApiResponse,
      EndPurchaseBrokerageBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/endPurchaseBrokerage`,
        method: 'PUT',
        body: queryArg.closeBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: [
        'BusinessCaseDetail',
        'SaleVehicle',
        'BusinessCaseActions',
        'SaleVehicleActions',
        'Vehicle',
        'Checkout',
      ],
      extraOptions: {
        requestSchema: EndPurchaseBrokerageBusinessCaseApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),

    getLastActiveBusinessCase: build.query<
      GetLastActiveBusinessCaseApiResponse,
      GetLastActiveBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-vehicle/${queryArg.vehicleId}/last-active`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'VehicleLastActiveBC', id: queryArg.vehicleId},
      ],
      extraOptions: {
        requestSchema: GetLastActiveBusinessCaseApiArgSchema,
        responseSchema: LastActiveBusinessCaseResponseBodySchema,
      },
    }),

    getLastActiveBusinessCaseByCustomer: build.query<
      GetLastActiveBusinessCaseByCustomerApiResponse,
      GetLastActiveBusinessCaseByCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-customer/${queryArg.customerId}/last-active`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetLastActiveBusinessCaseByCustomerApiArgSchema,
        responseSchema: GetLastActiveBusinessCaseByCustomerApiResponseSchema,
      },
    }),

    getActiveBusinessCaseByCustomer: build.query<
      GetActiveBusinessCaseByCustomerApiResponse,
      GetActiveBusinessCaseByCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-customer/${queryArg.customerId}/active`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetActiveBusinessCaseByCustomerApiArgSchema,
        responseSchema: GetActiveBusinessCaseByCustomerApiResponseSchema,
      },
    }),

    createAgreementOnFutureContract: build.mutation<
      CreateAgreementOnFutureContractApiResponse,
      CreateAgreementOnFutureContractApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/agreement-on-future-contract`,
        method: 'PUT',
        body: queryArg.agreementOnFutureRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'documentsCount', id: queryArg.agreementOnFutureRequestBody.businessCaseId},
      ],
      extraOptions: {
        requestSchema: CreateAgreementOnFutureContractApiArgSchema,
        responseSchema: FileResponseBodySchema,
      },
    }),

    getPurchaseTemplates: build.query<GetPurchaseTemplatesApiResponse, void>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/purchase/templates`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: z.void(),
        responseSchema: GetPurchaseTemplatesApiResponseSchema,
      },
    }),

    getPurchaseBrokerageTemplates: build.query<
      GetPurchaseBrokerageTemplatesApiResponse,
      GetPurchaseBrokerageTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/purchase-brokerage/templates`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetPurchaseBrokerageTemplatesApiArgSchema,
        responseSchema: GetPurchaseBrokerageTemplatesApiResponseSchema,
      },
    }),

    createPurchaseBrokerageCancelationDocument: build.mutation<
      CreatePurchaseBrokerageCancelationDocumentApiResponse,
      CreatePurchaseBrokerageCancelationDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/brokerage-contract-cancelation`,
        method: 'PUT',
        body: queryArg.brokerageContractCancelationRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail'},
        {type: 'SaleVehicle'},
        {
          type: 'documentsCount',
          id: queryArg.brokerageContractCancelationRequestBody.businessCaseId,
        },
      ],
      extraOptions: {
        requestSchema: CreatePurchaseBrokerageCancelationDocumentApiArgSchema,
        responseSchema: FileResponseBodySchema,
      },
    }),

    getSaleTemplates: build.query<GetSaleTemplatesApiResponse, void>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/sale/templates`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: z.void(),
        responseSchema: GetSaleTemplatesApiResponseSchema,
      },
    }),

    createSaleOfferDocument: build.mutation<
      CreateSaleOfferDocumentApiResponse,
      CreateSaleOfferDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/sale-offer`,
        method: 'PUT',
        body: queryArg.saleOfferRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'documentsCount', id: queryArg.saleOfferRequestBody.businessCaseId},
      ],
      extraOptions: {
        requestSchema: CreateSaleOfferDocumentApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),

    getSaleOfferTemplates: build.query<
      GetSaleOfferTemplatesApiResponse,
      GetSaleOfferTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/sale-offer/templates`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetSaleOfferTemplatesApiArgSchema,
        responseSchema: GetSaleOfferTemplatesApiResponseSchema,
      },
    }),

    backToOffer: build.mutation<PutBackToOfferApiResponse, PutBackToOfferApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/backToOffer`,
        method: 'PUT',
        queryArg,
      }),
      invalidatesTags: [
        'BusinessCaseDetail',
        'Vehicle',
        'SaleVehicle',
        'BusinessCaseActions',
        'SaleVehicleActions',
      ],
      extraOptions: {
        requestSchema: PutBackToOfferApiArgSchema,
        responseSchema: PutBackToOfferApiResponseSchema,
      },
    }),

    closeContract: build.mutation<PutCloseContractApiResponse, PutCloseContractApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/closeContract`,
        method: 'PUT',
        queryArg,
      }),
      invalidatesTags: [
        'BusinessCaseDetail',
        'Vehicle',
        'SaleVehicle',
        'BusinessCaseActions',
        'SaleVehicleActions',
      ],
      extraOptions: {
        requestSchema: PutCloseContractApiArgSchema,
        responseSchema: PutCloseContractApiResponseSchema,
      },
    }),

    reopenContract: build.mutation<PutReopenContractApiResponse, PutReopenContractApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/reopen`,
        method: 'PUT',
        queryArg,
      }),
      invalidatesTags: [
        'BusinessCaseDetail',
        'Vehicle',
        'SaleVehicle',
        'BusinessCaseActions',
        'SaleVehicleActions',
      ],
      extraOptions: {
        requestSchema: PutReopenContractApiArgSchema,
        responseSchema: PutReopenContractApiResponseSchema,
      },
    }),

    deprecatedGetVerificationRegisters: build.query<
      DeprecatedGetVerificationRegistersApiResponse,
      DeprecatedGetVerificationRegistersApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/customer-legal-verification/${queryArg.contractInformationId}/registers`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'CustomerVerificationRegisters', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: DeprecatedGetVerificationRegistersApiArgSchema,
        responseSchema: DeprecatedGetVerificationRegistersApiResponseSchema,
      },
    }),

    deprecatedVerify: build.mutation<unknown, DeprecatedVerifyApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/customer-legal-verification/${queryArg.contractInformationId}/verify`,
        method: 'PUT',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CustomerVerificationRegisters', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: DeprecatedVerifyApiArgSchema,
        responseSchema: z.unknown(),
      },
    }),

    setReadyForPricing: build.mutation<SetReadyForPricingApiRes, SetReadyForPricingApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/purchase-vehicle/${queryArg.offerPurchaseVehicleId}/set-ready-for-pricing`,
        method: 'PATCH',
        queryArg,
      }),
      extraOptions: {
        requestSchema: SetReadyForPricingApiArgSchema,
        responseSchema: SetReadyForPricingApiResSchema,
      },
    }),
    getBusinessCaseCheckout: build.query<
      GetBusinessCaseCheckoutApiResponse,
      GetBusinessCaseCheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/checkout`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetBusinessCaseCheckoutApiArgSchema,
        responseSchema: CheckoutResponseBodySchema,
      },
    }),
    getCustomerRequestedPrice: build.query<
      GetCustomerRequestedPriceApiRes,
      GetCustomerRequestedPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-vehicle/${queryArg.offerPurchaseVehicleId}/customer-requested-price`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        'CustomerRequestedPrice',
        {type: 'offer', id: queryArg.offerId},
      ],
      extraOptions: {
        requestSchema: GetCustomerRequestedPriceApiArgSchema,
        responseSchema: GetCustomerRequestedPriceApiResSchema,
      },
    }),
    createBusinessCaseOffer: build.mutation<
      CreateBusinessCaseOfferApiRes,
      CreateBusinessCaseOfferApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/business-case/${body.businessCaseId}/offer`,
        method: 'POST',
        body,
        queryArg: body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail'},
        {type: 'documentsCount', id: queryArg.businessCaseId},
        {type: 'SaleVehicle'},
        {type: 'BusinessCaseActions'},
        {type: 'SaleVehicleActions'},
      ],
      extraOptions: {
        requestSchema: CreateBusinessCaseOfferApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    putCustomerRequestedPrice: build.mutation<
      PutCustomerRequestedPriceApiRes,
      PutCustomerRequestedPriceApiArg
    >({
      query: ({businessCaseId, offerId, offerPurchaseVehicleId, ...body}) => ({
        url: `/dms/v1/business-case/${businessCaseId}/offer/${offerId}/purchase-vehicle/${offerPurchaseVehicleId}/customer-requested-price`,
        method: 'PUT',
        body,
        queryArg: body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'CustomerRequestedPrice',
        {type: 'offer', id: queryArg.offerId},
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'Vehicle', id: queryArg.offerPurchaseVehicleId},
      ],
      extraOptions: {
        requestSchema: PutCustomerRequestedPriceApiArgSchema,
        responseSchema: PutCustomerRequestedPriceApiResSchema,
      },
    }),

    putPurchaseVehiclePricingCentralized: build.mutation<
      PutPurchaseVehiclePricingCentralizedApiRes,
      PutPurchaseVehiclePricingCentralizedApiArg
    >({
      query: ({businessCaseId, offerPurchaseVehicleId, ...body}) => ({
        url: `/dms/v1/business-case/${businessCaseId}/purchase-vehicle/${offerPurchaseVehicleId}/pricing-centralized`,
        method: 'PUT',
        body,
        queryArg: body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'CustomerRequestedPrice',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'Vehicle', id: queryArg.offerPurchaseVehicleId},
      ],
      extraOptions: {
        requestSchema: PutPurchaseVehiclePricingCentralizedApiArgSchema,
        responseSchema: PutPurchaseVehiclePricingCentralizedApiResSchema,
      },
    }),
    addUpsellFromCatalog: build.mutation<
      AddUpsellFromCatalogApiResponse,
      AddUpsellFromCatalogApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}/upsell-from-catalog`,
        body: queryArg.body,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        'BusinessCaseSalePrices',
        'Checkout',
        'offer',
      ],
      extraOptions: {
        requestSchema: AddUpsellFromCatalogApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    createUpsell: build.mutation<CreateUpsellApiResponse, CreateUpsellApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}/upsell`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        'BusinessCaseSalePrices',
        'Checkout',
        'offer',
      ],
      extraOptions: {
        requestSchema: CreateUpsellApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    deleteUpsell: build.mutation<DeleteUpsellApiResponse, DeleteUpsellApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}/upsell/${queryArg.upsellId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'offer', id: queryArg.offerId},
        'BusinessCaseSalePrices',
        'Checkout',
        'offer',
      ],
      extraOptions: {
        requestSchema: DeleteUpsellApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    patchUpsell: build.mutation<PatchUpsellApiResponse, PatchUpsellApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}/upsell/${queryArg.upsellId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'offer', id: queryArg.offerId},
        'BusinessCaseSalePrices',
        'Checkout',
        'offer',
      ],
      extraOptions: {
        requestSchema: PatchUpsellApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    finishPricesUpsell: build.mutation<FinishPricesUpsellApiResponse, FinishPricesUpsellApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}/upsell/finish-prices`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'offer', id: queryArg.offerId}],
      extraOptions: {
        requestSchema: FinishPricesUpsellApiArgSchema,
        responseSchema: UpsellResponseBodySchema,
      },
    }),
    getCheckoutForBusinessCase: build.query<
      GetCheckoutForBusinessCaseApiResponse,
      GetCheckoutForBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/checkout`,
        queryArg,
      }),
      providesTags: ['Checkout'],
      extraOptions: {
        requestSchema: GetCheckoutForBusinessCaseApiArgSchema,
        responseSchema: CheckoutResponseBodySchema,
      },
    }),
    getBusinessCaseStatistics: build.query<
      GetBusinessCaseStatisticsApiResponse,
      GetBusinessCaseStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/statistics/business-case/`,
        params: {branchId: queryArg.branchId},
        queryArg,
      }),
      providesTags: ['BusinessCaseDetail'],
      extraOptions: {
        requestSchema: GetBusinessCaseStatisticsApiArgSchema,
        responseSchema: BusinessCaseStatisticsResponseBodySchema,
      },
    }),

    createBusinessCase: build.mutation<CreateBusinessCaseApiResponse, CreateBusinessCaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case`,
        method: 'POST',
        body: queryArg.createBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => {
        const vehicleId =
          queryArg.createBusinessCaseRequestBody.vehicleToSaleId ??
          queryArg.createBusinessCaseRequestBody.vehicleToPurchaseBrokerageId ??
          queryArg.createBusinessCaseRequestBody.vehicleToPurchaseId ??
          '';
        return [
          {
            type: 'VehicleLastActiveBC',
            id: vehicleId,
          },
          {
            type: 'BusinessCaseCountByVehicle',
            id: vehicleId,
          },
        ];
      },
      extraOptions: {
        requestSchema: CreateBusinessCaseApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    getBusinessCase: build.query<GetBusinessCaseApiResponse, GetBusinessCaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: GetBusinessCaseApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    deleteOfferSaleVehicle: build.mutation<
      DeleteOfferSaleVehicleApiResponse,
      DeleteOfferSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'offer', id: queryArg.offerId},
        'BusinessCaseActions',
        'SaleVehicleActions',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: DeleteOfferSaleVehicleApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    patchSaleVehicle: build.mutation<PatchSaleVehicleApiResponse, PatchSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle/${queryArg.offerSaleVehicleId}`,
        method: 'PATCH',
        body: queryArg.offerSaleVehiclePatchRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'offer', id: queryArg.offerId},
        'BusinessCaseSalePrices',
        'Checkout',
      ],
      extraOptions: {
        requestSchema: PatchSaleVehicleApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    patchBuyingOfferPurchaseVehicle: build.mutation<
      PatchBuyingOfferPurchaseVehicleApiResponse,
      PatchBuyingOfferPurchaseVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-vehicle/${queryArg.offerPurchaseVehicleId}/buying`,
        method: 'PATCH',
        body: queryArg.patchBuyingOfferPurchaseVehicleRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.offerPurchaseVehicleId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'SaleVehicleActions', id: queryArg.offerPurchaseVehicleId},
        {type: 'offer', id: queryArg.offerId},
      ],
      extraOptions: {
        requestSchema: PatchBuyingOfferPurchaseVehicleApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    createSaleVehicle: build.mutation<CreateSaleVehicleApiResponse, CreateSaleVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/sale-vehicle`,
        method: 'POST',
        body: queryArg.offerSaleVehicleRequestBody,
        queryArg,
      }),
      extraOptions: {
        requestSchema: CreateSaleVehicleApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    deleteOfferPurchaseBrokerageVehicle: build.mutation<
      DeleteOfferPurchaseBrokerageVehicleApiResponse,
      DeleteOfferPurchaseBrokerageVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-brokerage-vehicle/${queryArg.offerPurchaseBrokerageVehicleId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.offerPurchaseBrokerageVehicleId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'SaleVehicleActions', id: queryArg.offerPurchaseBrokerageVehicleId},
        {type: 'offer', id: queryArg.offerId},
      ],
      extraOptions: {
        requestSchema: DeleteOfferPurchaseBrokerageVehicleApiArgSchema,
        responseSchema: DeleteOfferPurchaseBrokerageVehicleApiResponseSchema,
      },
    }),
    deleteOfferPurchaseVehicle: build.mutation<
      DeleteOfferPurchaseVehicleApiResponse,
      DeleteOfferPurchaseVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-vehicle/${queryArg.offerPurchaseVehicleId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.offerPurchaseVehicleId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'SaleVehicleActions', id: queryArg.offerPurchaseVehicleId},
        {type: 'offer', id: queryArg.offerId},
      ],
      extraOptions: {
        requestSchema: DeleteOfferPurchaseVehicleApiArgSchema,
        responseSchema: DeleteOfferPurchaseVehicleApiResponseSchema,
      },
    }),
    createOfferPurchaseVehicle: build.mutation<
      CreateOfferPurchaseVehicleApiResponse,
      CreateOfferPurchaseVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-vehicle`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
        {type: 'offer', id: queryArg.offerId},
      ],
      extraOptions: {
        requestSchema: CreateOfferPurchaseVehicleApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    patchBusinessCaseCustomCode: build.mutation<
      PatchBusinessCaseCustomCodeApiResponse,
      PatchBusinessCaseCustomCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/customCode`,
        method: 'PATCH',
        body: queryArg.patchBusinessCaseCustomCodeRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: PatchBusinessCaseCustomCodeApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    patchPricingOfferPurchaseVehicle: build.mutation<
      PatchPricingOfferPurchaseVehicleApiResponse,
      PatchPricingOfferPurchaseVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-vehicle/${queryArg.offerPurchaseVehicleId}/pricing`,
        method: 'PATCH',
        body: queryArg.patchPricingOfferPurchaseVehicleRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'offer', id: queryArg.offerId}],
      extraOptions: {
        requestSchema: PatchPricingOfferPurchaseVehicleApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    patchFeesOfferPurchaseBrokerageVehicle: build.mutation<
      PatchFeesOfferPurchaseBrokerageVehicleApiResponse,
      PatchFeesOfferPurchaseBrokerageVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-brokerage-vehicle/${queryArg.offerPurchaseBrokerageVehicleId}/fees`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.offerPurchaseBrokerageVehicleId},
        {type: 'SaleVehicleActions', id: queryArg.offerPurchaseBrokerageVehicleId},
        'Checkout',
        'offer',
        'BusinessCasePurchasePrices',
      ],
      extraOptions: {
        requestSchema: PatchFeesOfferPurchaseBrokerageVehicleApiArgSchema,
        responseSchema: OfferResponseBodySchema,
      },
    }),
    calculateBrokerageFees: build.mutation<
      CalculateBrokerageFeesApiResponse,
      CalculateBrokerageFeesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/brokerage-fees/calculation`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        requestSchema: CalculateBrokerageFeesApiArgSchema,
        responseSchema: CalculatedBrokerageFeesResponseBodySchema,
      },
    }),
    getLastActiveBuyingBusinessCase: build.query<
      GetLastActiveBuyingBusinessCaseApiRes,
      GetLastActiveBuyingBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-vehicle/${queryArg.vehicleId}/last-buying`,
        queryArg,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'VehicleLastActiveBC', id: queryArg.vehicleId},
      ],
      keepUnusedDataFor: 0,
      extraOptions: {
        requestSchema: GetLastActiveBuyingBusinessCaseApiArgSchema,
        responseSchema: LastActiveBusinessCaseResponseBodySchema,
      },
    }),
    getLastActiveSellingBusinessCase: build.query<
      GetLastActiveSellingBusinessCaseApiRes,
      GetLastActiveSellingBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-vehicle/${queryArg.vehicleId}/last-selling`,
        queryArg,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'VehicleLastActiveBC', id: queryArg.vehicleId},
      ],
      keepUnusedDataFor: 0,
      extraOptions: {
        requestSchema: GetLastActiveSellingBusinessCaseApiArgSchema,
        responseSchema: LastActiveBusinessCaseResponseBodySchema,
      },
    }),
    offerBusinessCaseInsurance: build.mutation<undefined, OfferBusinessCaseInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer-insurance`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: OfferBusinessCaseInsuranceApiArgSchema,
        responseSchema: z.undefined(),
      },
    }),
    getBusinessCaseByBusinessCase: build.query<
      GetBusinessCaseByBusinessCaseApiRes,
      GetBusinessCaseByBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-business-case/${queryArg.businessCaseId}`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetBusinessCaseByBusinessCaseApiArgSchema,
        responseSchema: LastActiveBusinessCaseResponseBodySchema,
      },
    }),
    recommendBusinessCaseCustomer: build.mutation<undefined, RecommendBusinessCaseCustomerApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/recommend-customer`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: RecommendBusinessCaseCustomerApiArgSchema,
        responseSchema: z.undefined(),
      },
    }),
    getBusinessCasesByVehicleCount: build.query<
      GetBusinessCasesByVehicleCountApiResponse,
      GetBusinessCasesByVehicleCountApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-vehicle/${queryArg.vehicleId}/count`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseCountByVehicle', id: queryArg.vehicleId},
      ],
      extraOptions: {
        requestSchema: GetBusinessCasesByVehicleCountApiArgSchema,
        responseSchema: BusinessCaseCountResponseBodySchema,
      },
    }),
    getBusinessCaseActions: build.query<
      GetBusinessCaseActionsApiResponse,
      GetBusinessCaseActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/business-case/${queryArg.businessCaseId}/actions`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: GetBusinessCaseActionsApiArgSchema,
        responseSchema: BusinessCaseActionsV2ResponseBodySchema,
      },
    }),
    patchBusinessCase: build.mutation<PatchBusinessCaseApiResponse, PatchBusinessCaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}`,
        method: 'PATCH',
        body: queryArg.patchBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: PatchBusinessCaseApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    putBusinessCaseContactPerson: build.mutation<
      PutBusinessCaseContactPersonApiResponse,
      PutBusinessCaseContactPersonApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/contact-person`,
        method: 'PUT',
        body: queryArg.putBusinessCaseContactPersonRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: PutBusinessCaseContactPersonApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    takeoverBusinessCase: build.mutation<
      TakeoverBusinessCaseApiResponse,
      TakeoverBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/takeover`,
        method: 'PUT',
        body: queryArg.takeoverBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: TakeoverBusinessCaseApiArgSchema,
        responseSchema: TakeoverBusinessCaseApiResponseSchema,
      },
    }),
    deleteBusinessCase: build.mutation<DeleteBusinessCaseApiResponse, DeleteBusinessCaseApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        'SaleVehicle',
      ],
      extraOptions: {
        requestSchema: DeleteBusinessCaseApiArgSchema,
        responseSchema: DeleteBusinessCaseApiResponseSchema,
      },
    }),

    createPurchaseOfferDocument: build.mutation<
      CreatePurchaseOfferDocumentApiResponse,
      CreatePurchaseOfferDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/purchase-offer`,
        method: 'PUT',
        body: queryArg.purchaseOfferRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'documentsCount', id: queryArg.purchaseOfferRequestBody.businessCaseId},
      ],
      extraOptions: {
        requestSchema: CreatePurchaseOfferDocumentApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    getPurchaseOfferTemplates: build.query<
      GetPurchaseOfferTemplatesApiResponse,
      GetPurchaseOfferTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/document/purchase-offer/templates`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetPurchaseOfferTemplatesApiArgSchema,
        responseSchema: GetPurchaseOfferTemplatesApiResponseSchema,
      },
    }),
    changeToPurchaseBrokeragePurchaseVehicle: build.mutation<
      ChangeToPurchaseBrokeragePurchaseVehicleApiResponse,
      ChangeToPurchaseBrokeragePurchaseVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-vehicle/${queryArg.offerPurchaseVehicleId}/change-to-purchase-brokerage`,
        method: 'PUT',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'offer', id: queryArg.offerId},
        'SaleVehicleActions',
        'SaleVehicle',
        'BusinessCasePurchasePrices',
      ],
      extraOptions: {
        requestSchema: ChangeToPurchaseBrokeragePurchaseVehicleApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    changeToStockPurchaseVehicle: build.mutation<
      ChangeToPurchaseBrokeragePurchaseVehicleApiResponse,
      ChangeToPurchaseBrokeragePurchaseVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/purchase-brokerage-vehicle/${queryArg.offerPurchaseVehicleId}/change-to-stock`,
        method: 'PUT',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'offer', id: queryArg.offerId},
        'SaleVehicleActions',
        'SaleVehicle',
        'BusinessCasePurchasePrices',
      ],
      extraOptions: {
        requestSchema: ChangeToPurchaseBrokeragePurchaseVehicleApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    getBusinessCaseSettings: build.query<GetBusinessCaseSettingsApiResponse, void>({
      query: (queryArg) => ({
        url: `/dms/v1/settings/business-case`,
        queryArg,
      }),
      providesTags: ['BusinessCaseSettings'],
      extraOptions: {
        requestSchema: z.void(),
        responseSchema: BusinessCaseSettingsResponseBodySchema,
      },
    }),
    putBusinessCaseSettings: build.mutation<
      PutBusinessCaseSettingsApiResponse,
      PutBusinessCaseSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/business-case`,
        method: 'PUT',
        body: queryArg.businessCaseSettingsRequestBody,
        queryArg,
      }),
      invalidatesTags: ['BusinessCaseSettings'],
      extraOptions: {
        requestSchema: PutBusinessCaseSettingsApiArgSchema,
        responseSchema: PutBusinessCaseSettingsApiResponseSchema,
      },
    }),
    markOfferDocumentsAsActual: build.mutation<
      MarkOfferDocumentsAsActualApiResponse,
      MarkOfferDocumentsAsActualApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/mark-documents-as-actual`,
        method: 'PUT',
        queryArg,
      }),
      extraOptions: {
        requestSchema: MarkOfferDocumentsAsActualApiArgSchema,
        responseSchema: MarkOfferDocumentsAsActualApiResponseSchema,
      },
    }),
    selectBusinessCaseCheckoutOrderContractInformation: build.mutation<
      SelectBusinessCaseCheckoutOrderContractInformationApiResponse,
      SelectBusinessCaseCheckoutOrderContractInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/checkout/orders/${queryArg.orderId}/select-contract-information`,
        method: 'PUT',
        body: queryArg.selectContractInformationRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'Checkout',
        {type: 'offer', id: queryArg.orderId},
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: SelectBusinessCaseCheckoutOrderContractInformationApiArgSchema,
        responseSchema: OrderResponseBodySchema,
      },
    }),
    selectBusinessCaseCheckoutOrderDeputyPersons: build.mutation<
      SelectBusinessCaseCheckoutOrderDeputyPersonsApiResponse,
      SelectBusinessCaseCheckoutOrderDeputyPersonsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/checkout/orders/${queryArg.orderId}/select-deputy-persons`,
        method: 'PUT',
        body: queryArg.selectDeputyPersonsRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'Checkout',
        {type: 'offer', id: queryArg.orderId},
      ],
      extraOptions: {
        requestSchema: SelectBusinessCaseCheckoutOrderDeputyPersonsApiArgSchema,
        responseSchema: OrderResponseBodySchema,
      },
    }),
    createOffer: build.mutation<CreateOfferApiResponse, CreateOfferApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer`,
        method: 'POST',
        body: queryArg.offerRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'Checkout',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: CreateOfferApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    deleteOffer: build.mutation<DeleteOfferApiResponse, DeleteOfferApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'Checkout',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
      ],
      extraOptions: {
        requestSchema: DeleteOfferApiArgSchema,
        responseSchema: DeleteOfferApiResponseSchema,
      },
    }),
    switchOfferSaleVehicle: build.mutation<
      SwitchOfferSaleVehicleApiResponse,
      SwitchOfferSaleVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/offer/${queryArg.offerId}/switch-sale-vehicle`,
        method: 'PUT',
        body: {vehicleId: queryArg.vehicleId},
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'Checkout',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        {type: 'BusinessCaseActions', id: queryArg.businessCaseId},
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'Vehicle', id: queryArg.vehicleId},
        'BusinessCaseSalePrices',
      ],
      extraOptions: {
        requestSchema: SwitchOfferSaleVehicleApiArgSchema,
        responseSchema: BusinessCaseResponseBodySchema,
      },
    }),
    pricePurchaseBusinessCase: build.mutation<
      PricePurchaseBusinessCaseApiResponse,
      PricePurchaseBusinessCaseApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/price`,
        method: 'PATCH',
        body: queryArg.pricePurchaseBusinessCaseRequestBody,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'SaleVehicle',
        'SaleVehicleActions',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        'vehicleWarehouseInformation',
        'Vehicle',
        'Checkout',
        'offer',
        'BusinessCasePurchasePrices',
      ],
      extraOptions: {
        requestSchema: PricePurchaseBusinessCaseApiArgSchema,
        responseSchema: PricePurchaseBusinessCaseApiResponseSchema,
      },
    }),
    findBusinessCasesByVehicle: build.query<
      FindBusinessCasesByVehicleApiResponse,
      FindBusinessCasesByVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/find-by-vehicles`,
        params: {vehicles: queryArg.vehicles},
        queryArg,
      }),
      extraOptions: {
        requestSchema: FindBusinessCasesByVehicleApiArgSchema,
        responseSchema: FindBusinessCasesByVehicleApiResponseSchema,
      },
    }),
    getActiveBusinessCasesByVehicle: build.query<
      GetActiveBusinessCasesByVehicleApiResponse,
      GetActiveBusinessCasesByVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/by-vehicle/${queryArg.vehicleId}/active`,
        queryArg,
      }),
      extraOptions: {
        requestSchema: GetActiveBusinessCasesByVehicleApiArgSchema,
        responseSchema: GetActiveBusinessCasesByVehicleApiResponseSchema,
      },
    }),
    getPurchaseBusinessCasePricesOverview: build.query<
      GetPurchaseBusinessCasePricesOverviewApiResponse,
      GetPurchaseBusinessCasePricesOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/purchase/prices-overview`,
        queryArg,
      }),
      providesTags: ['BusinessCasePurchasePrices'],
      extraOptions: {
        requestSchema: GetPurchaseBusinessCasePricesOverviewApiArgSchema,
        responseSchema: PurchaseBusinessCasePricesOverviewResponseBodySchema,
      },
    }),
    getSaleBusinessCasePricesOverview: build.query<
      GetSaleBusinessCasePricesOverviewApiResponse,
      GetSaleBusinessCasePricesOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/sale/prices-overview`,
        queryArg,
      }),
      providesTags: ['BusinessCaseSalePrices'],
      extraOptions: {
        requestSchema: GetSaleBusinessCasePricesOverviewApiArgSchema,
        responseSchema: SaleBusinessCasePricesOverviewResponseBodySchema,
      },
    }),
    patchBusinessCaseVehicleToBrokeragePricing: build.mutation<
      PatchBusinessCaseVehicleToBrokeragePricingApiResponse,
      PatchBusinessCaseVehicleToBrokeragePricingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/vehicle-to-brokerage/${queryArg.vehicleId}/pricing`,
        method: 'PATCH',
        body: queryArg.patchBusinessCaseVehicleToBrokeragePricingRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'SaleVehicle',
        'SaleVehicleActions',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        'vehicleWarehouseInformation',
        'Vehicle',
        'Checkout',
        'offer',
        'BusinessCasePurchasePrices',
      ],
    }),
    patchBusinessCaseVehicleToBuyPricing: build.mutation<
      PatchBusinessCaseVehicleToBuyPricingApiResponse,
      PatchBusinessCaseVehicleToBuyPricingApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/${queryArg.businessCaseId}/vehicle-to-buy/${queryArg.vehicleId}/pricing`,
        method: 'PATCH',
        body: queryArg.patchBusinessCaseVehicleToBuyPricingRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'SaleVehicle',
        'SaleVehicleActions',
        {type: 'BusinessCaseDetail', id: queryArg.businessCaseId},
        'vehicleWarehouseInformation',
        'Vehicle',
        'Checkout',
        'offer',
        'BusinessCasePurchasePrices',
      ],
    }),
  }),
});

export const {
  useGetCheckoutForBusinessCaseQuery,
  useGetActiveBusinessCasesByVehicleQuery,
  useFindBusinessCasesByVehicleQuery,
  useLazyFindBusinessCasesByVehicleQuery,
  useSwitchOfferSaleVehicleMutation,
  useCreateOfferMutation,
  useDeleteOfferMutation,
  useSelectBusinessCaseCheckoutOrderContractInformationMutation,
  useSelectBusinessCaseCheckoutOrderDeputyPersonsMutation,
  usePatchBuyingOfferPurchaseVehicleMutation,
  usePatchSaleVehicleMutation,
  useCreateSaleVehicleMutation,
  useMarkOfferDocumentsAsActualMutation,
  useChangeToPurchaseBrokeragePurchaseVehicleMutation,
  useChangeToStockPurchaseVehicleMutation,
  useCreateOfferPurchaseVehicleMutation,
  useOfferBusinessCaseInsuranceMutation,
  useRecommendBusinessCaseCustomerMutation,
  useAddUpsellFromCatalogMutation,
  useGetBusinessCaseSeriesDefinitionListQuery,
  useCreateBusinessCaseSeriesDefinitionMutation,
  useGetBusinessCaseSeriesDefinitionQuery,
  useChangeBranchBusinessCaseMutation,
  useLazyGetBusinessCaseSeriesDefinitionQuery,
  useCloseBusinessCaseMutation,
  usePutBusinessCaseSeriesDefinitionMutation,
  useGetLastActiveBusinessCaseQuery,
  useDeprecatedGetVerificationRegistersQuery,
  useCreateBusinessCaseOfferMutation,
  useDeprecatedVerifyMutation,
  useEndBusinessCaseMutation,
  useEndPurchaseBrokerageBusinessCaseMutation,
  useGetBusinessCaseStatisticsQuery,
  useSetReadyForPricingMutation,
  useGetCustomerRequestedPriceQuery,
  usePutCustomerRequestedPriceMutation,
  usePutPurchaseVehiclePricingCentralizedMutation,
  useCreateAgreementOnFutureContractMutation,
  useGetPurchaseTemplatesQuery,
  useReopenContractMutation,
  useGetPurchaseBrokerageTemplatesQuery,
  useLazyGetPurchaseBrokerageTemplatesQuery,
  useGetSaleOfferTemplatesQuery,
  useCreatePurchaseBrokerageCancelationDocumentMutation,
  useGetSaleTemplatesQuery,
  useCreateSaleOfferDocumentMutation,
  useGetBusinessCaseCheckoutQuery,
  useCreateBusinessCaseMutation,
  useGetBusinessCaseQuery,
  useLazyGetBusinessCaseQuery,
  useBackToOfferMutation,
  usePatchBusinessCaseCustomCodeMutation,
  useDeleteOfferSaleVehicleMutation,
  useDeleteOfferPurchaseBrokerageVehicleMutation,
  useDeleteOfferPurchaseVehicleMutation,
  useGetActiveBusinessCaseByCustomerQuery,
  useGetLastActiveBusinessCaseByCustomerQuery,
  usePatchPricingOfferPurchaseVehicleMutation,
  usePatchFeesOfferPurchaseBrokerageVehicleMutation,
  useCalculateBrokerageFeesMutation,
  useGetBusinessCasesByVehicleCountQuery,
  useGetLastActiveSellingBusinessCaseQuery,
  useGetLastActiveBuyingBusinessCaseQuery,
  useGetBusinessCaseByBusinessCaseQuery,
  useCreateUpsellMutation,
  useDeleteUpsellMutation,
  useCloseContractMutation,
  usePatchUpsellMutation,
  useGetBusinessCaseSettingsQuery,
  usePutBusinessCaseSettingsMutation,
  useFinishPricesUpsellMutation,
  useGetBusinessCaseActionsQuery,
  usePatchBusinessCaseMutation,
  usePutBusinessCaseContactPersonMutation,
  useTakeoverBusinessCaseMutation,
  useDeleteBusinessCaseMutation,
  useCreatePurchaseOfferDocumentMutation,
  useGetPurchaseOfferTemplatesQuery,
  usePricePurchaseBusinessCaseMutation,
  useGetSaleBusinessCasePricesOverviewQuery,
  useGetPurchaseBusinessCasePricesOverviewQuery,
  usePatchBusinessCaseVehicleToBuyPricingMutation,
  usePatchBusinessCaseVehicleToBrokeragePricingMutation,
} = businessCaseApi;
