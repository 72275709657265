import {closeDialog, FormSubmitHandler, showNotification} from 'platform/components';

import {usePostStorageLocationAddLocationV2Mutation} from '@dms/api';

import {handleApiError} from '../../utils/handleApiError';
import {SingleTireLocationForm} from './components/SingleTireLocationForm';

interface CreateTireLocationForm {
  location: string;
  allowMultipleUsage: boolean;
  note: string;
}

interface CreateTireLocationProps {
  tireWarehouseId: string;
  dialogId: string;
  onSuccess: () => void;
}

export function CreateTireLocation(props: CreateTireLocationProps) {
  const [createSingleLocation, queryStatus] = usePostStorageLocationAddLocationV2Mutation();

  const handleClose = () => {
    closeDialog(props.dialogId);
  };

  const handleSubmit: FormSubmitHandler<CreateTireLocationForm> = async (formData) => {
    await createSingleLocation({
      contextTarget: 'TIRE-WAREHOUSE',
      contextId: props.tireWarehouseId,
      body: formData,
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        props.onSuccess();
        handleClose();
      })
      .catch(handleApiError);
  };

  return (
    <SingleTireLocationForm
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      isLoading={queryStatus.isLoading}
    />
  );
}
