import {isTrue} from 'ramda-adjunct';

import {
  useGetVehicleSalesActionsQuery,
  VehicleSalesAction,
  VehicleSalesActionsResponseBody,
} from '@dms/api';

import {Nullish} from 'shared';

export function useSaleVehicleActions(vehicleId: string) {
  const {data} = useGetVehicleSalesActionsQuery({vehicleId}, {refetchOnFocus: true});

  return {isSaleActionEnabled: isSaleActionEnabled(data)};
}

export const isSaleActionEnabled =
  (data: VehicleSalesActionsResponseBody | Nullish) => (actionKey: VehicleSalesAction) =>
    isTrue(data?.actions?.includes(actionKey));
