import {Flag} from 'platform/components';

import {useMemo} from 'react';

import {BusinessCaseState} from '@dms/api';

import {suffixTestId, TestIdProps} from 'shared';

import {getBusinessCaseStateFlag} from '../../utils/getBusinessCaseStateFlag';

export type BusinessCaseStateFlagProps = {
  state: BusinessCaseState;
} & TestIdProps;

function BusinessCaseStateFlag(props: BusinessCaseStateFlagProps) {
  const flagParams = useMemo(() => getBusinessCaseStateFlag(props.state), [props.state]);

  if (!flagParams) {
    return null;
  }

  return (
    <Flag
      key={props.state}
      size="small"
      colorScheme={flagParams.colorScheme}
      label={flagParams.label}
      data-testid={suffixTestId('businessCaseState', props)}
    />
  );
}

export {BusinessCaseStateFlag};
