import {Button, Dropdown, Flag} from 'platform/components';
import {Box, Text} from 'platform/foundation';
import styled from 'styled-components';

import {FC, useCallback, useEffect, useRef, useState} from 'react';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {convertStringToCamelCase} from 'shared';

import {HighlightItemType} from './types/HighlightItemType';

const VirtualWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const TagsWrapper = styled.div<{$isAbsolute?: boolean}>`
  width: 100%;
  flex: 1 1 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: ${({$isAbsolute}) => ($isAbsolute ? 'absolute' : 'relative')};
`;

const HiddenTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type StyledTagProps = {
  $isHidden?: boolean;
};

const FlagWrapper = styled.div<StyledTagProps>`
  margin-right: ${({theme}) => theme.getSize(1)};
  margin-bottom: ${({theme}) => theme.getSize(1)};

  span {
    word-break: break-word;
    white-space: initial;
  }
  ${({$isHidden}) =>
    $isHidden
      ? `
			height: 0px;
			padding-top: 0;
			padding-bottom: 0;
			overflow: hidden;
			margin-bottom: 0;
			margin-top: 0;
		`
      : ''};
`;

export type ComponentProps = {
  data: HighlightItemType[];
  onRecalculate?: (visibleCount: number) => void;
};

export const VehicleSnippetHighlightsWithMore: FC<ComponentProps> = ({
  data = [],
  onRecalculate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(typeof window === 'undefined' ? 0 : window.innerWidth);
  const [visibleCount, setVisibleCount] = useState<number>(0);

  const mounted = useRef(false);
  const divRef = useRef<HTMLDivElement>(null);
  const moreButtonRef = useRef<HTMLDivElement>(null);

  const realWrapperRef = useRef<HTMLDivElement>(null);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (!realWrapperRef.current) {
      return;
    }

    const handleResize = (width: number) => {
      setWidth(width);
    };

    const ro = new ResizeObserver(([entry]) => {
      handleResize(entry.contentRect.width);
    });

    ro.observe(realWrapperRef.current);

    return () => {
      ro.disconnect();
    };
  }, []);

  useEffect(() => {
    mounted.current && onRecalculate?.(visibleCount);

    mounted.current = true;
  }, [visibleCount]);

  const recalculateVisibleCount = useCallback(() => {
    // Additional pixels to prevent "more" button appearing in third row
    const errorCorrection = 10;

    const width = divRef.current?.getBoundingClientRect().width ?? 0;
    const moreButtonWidth = moreButtonRef.current?.offsetWidth ?? 0;
    const widthMinusButton = width - moreButtonWidth;
    const children = Array.from(divRef.current?.children ?? []);

    let count = 0;
    let usedWidth = 0;
    let isSecondRow = false;

    for (let i = 0; i < children.length; i++) {
      if (children[i] === moreButtonRef.current) {
        continue;
      }

      const style = getComputedStyle(children[i]);
      const elWidth = (children[i] as HTMLSpanElement).offsetWidth;
      const totalWidth =
        elWidth + parseInt(style.marginLeft || '0', 10) + parseInt(style.marginRight || '0', 10);

      if (usedWidth + totalWidth + errorCorrection >= (isSecondRow ? widthMinusButton : width)) {
        if (isSecondRow) {
          break;
        }

        i -= 1;
        usedWidth = 0;
        isSecondRow = true;
      } else {
        count += 1;
        usedWidth += totalWidth;
      }
    }

    setVisibleCount(count);
  }, []);

  useEffect(() => {
    recalculateVisibleCount();
  }, [recalculateVisibleCount, width, data]);

  const hiddenTagsCount = data.length - visibleCount;

  return (
    <Box width="100%">
      {/* Virtual wrapper */}
      <VirtualWrapper>
        <TagsWrapper $isAbsolute ref={divRef}>
          {data.map(({key, label, ...props}) => (
            <FlagWrapper key={key} $isHidden={true}>
              <Flag
                {...props}
                data-testid={testIds.vehicles.detail(
                  `virtual-highlight-${convertStringToCamelCase(label)}`
                )}
                label={label}
              />
            </FlagWrapper>
          ))}
        </TagsWrapper>
      </VirtualWrapper>
      {/* Real wrapper */}
      <TagsWrapper ref={realWrapperRef} data-testid={testIds.vehicles.detail('highlights-tags')}>
        {data.slice(0, visibleCount).map(({key, label, ...props}, index) => (
          <FlagWrapper key={key} $isHidden={visibleCount === 0 ? false : index + 1 > visibleCount}>
            <Flag
              {...props}
              data-testid={testIds.vehicles.detail(
                `real-highlight-${convertStringToCamelCase(label)}`
              )}
              label={label}
            />
          </FlagWrapper>
        ))}

        <div
          ref={moreButtonRef}
          onMouseOver={handleOpen}
          onFocus={handleOpen}
          onMouseOut={handleClose}
          onBlur={handleClose}
          style={{display: hiddenTagsCount > 0 ? 'block' : 'none'}}
          data-testid={testIds.vehicles.detail('moreHighlights-wrapper')}
        >
          <Button
            variant="link"
            size="small"
            data-testid={testIds.vehicles.detail('moreHighlights-moreButton')}
            title={`+${hiddenTagsCount} ${i18n.t('general.labels.more')}`}
          />
        </div>
        <Box width="100%" position="absolute" bottom={0} paddingBottom={1} left={0}>
          <Dropdown
            isOpen={isOpen}
            dropdownControl={<div />}
            data-testid={testIds.vehicles.detail('moreHighlights-dropdown')}
          >
            <HiddenTagsContainer style={{width}}>
              {data.slice(visibleCount, data.length).map(({key, label, ...props}) => (
                <FlagWrapper key={key}>
                  <Flag
                    {...props}
                    data-testid={testIds.vehicles.detail(`highlights-${key}`)}
                    label={label}
                  />
                </FlagWrapper>
              ))}
            </HiddenTagsContainer>
          </Dropdown>
        </Box>
        {!data.length && (
          <Text
            color="tertiary"
            size="xSmall"
            data-testid={testIds.vehicles.detail('noHighlights')}
          >
            {i18n.t('entity.vehicle.labels.noHighlights')}
          </Text>
        )}
      </TagsWrapper>
    </Box>
  );
};
