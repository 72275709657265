import {showNotification} from 'platform/components';

import {head} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  OrderResponseBody,
  useCreateBrokerageInternalInvoiceDocumentDocumentMutation,
  useCreateBrokerageInternalInvoiceDocumentWithVatDocumentMutation,
  useCreateInternalCorrectiveTaxDocumentDocumentMutation,
  useCreateInternalInvoiceDocumentDocumentMutation,
  useCreateInternalInvoiceDocumentWithVatDocumentMutation,
  useLazyGetBrokerageInternalInvoiceDocumentTemplatesQuery,
  useLazyGetBrokerageInternalInvoiceDocumentWithVatTemplatesQuery,
  useLazyGetInternalCorrectiveTaxDocumentTemplatesQuery,
  useLazyGetInternalInvoiceDocumentTemplatesQuery,
  useLazyGetInternalInvoiceDocumentWithVatTemplatesQuery,
} from '@dms/api';
import {handleApiError} from '@dms/shared';

export const useGenerateInternalInvoice = (
  order: OrderResponseBody,
  paymentId: string,
  checkoutId: string,
  taxDocumentId?: string
) => {
  const isBrokerageInternalDocument =
    order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES' ||
    order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE';

  const [getInternalDocumentTemplates, {isLoading: isDocumentTemplatesLoading}] =
    useLazyGetInternalInvoiceDocumentTemplatesQuery();
  const [getInternalDocumentWithVatTemplates, {isLoading: isDocumentWithVatTemplatesLoading}] =
    useLazyGetInternalInvoiceDocumentWithVatTemplatesQuery();
  const [createInternalDocument, {isLoading: isCreateInternalDocumentLoading}] =
    useCreateInternalInvoiceDocumentDocumentMutation();
  const [createInternalDocumentWithVat, {isLoading: isCreateInternalDocumentWithVatLoading}] =
    useCreateInternalInvoiceDocumentWithVatDocumentMutation();
  const [
    createInternalCorrectiveTaxDocument,
    {isLoading: isCreateInternalCorrectiveTaxDocumentLoading},
  ] = useCreateInternalCorrectiveTaxDocumentDocumentMutation();
  const [
    getInternalCorrectiveTaxDocumentTemplates,
    {isLoading: isInternalCorrectiveTaxDocumentTemplatesLoading},
  ] = useLazyGetInternalCorrectiveTaxDocumentTemplatesQuery();
  const [getBrokerageInternalDocumentTemplates, {isLoading: isBrokerageDocumentTemplatesLoading}] =
    useLazyGetBrokerageInternalInvoiceDocumentTemplatesQuery();
  const [
    getBrokerageInternalDocumentWithVatTemplates,
    {isLoading: isBrokerageDocumentWithVatTemplatesLoading},
  ] = useLazyGetBrokerageInternalInvoiceDocumentWithVatTemplatesQuery();
  const [createBrokerageInternalDocument, {isLoading: isCreateBrokerageInternalDocumentLoading}] =
    useCreateBrokerageInternalInvoiceDocumentDocumentMutation();
  const [
    createBrokerageInternalDocumentWithVat,
    {isLoading: isCreateBrokerageInternalDocumentWithVatLoading},
  ] = useCreateBrokerageInternalInvoiceDocumentWithVatDocumentMutation();

  const isLoading =
    isDocumentTemplatesLoading ||
    isDocumentWithVatTemplatesLoading ||
    isCreateInternalDocumentLoading ||
    isCreateInternalDocumentWithVatLoading ||
    isBrokerageDocumentTemplatesLoading ||
    isBrokerageDocumentWithVatTemplatesLoading ||
    isCreateBrokerageInternalDocumentLoading ||
    isCreateBrokerageInternalDocumentWithVatLoading ||
    isCreateInternalCorrectiveTaxDocumentLoading ||
    isInternalCorrectiveTaxDocumentTemplatesLoading;

  const generateInternalInvoiceDocument = (): Promise<unknown> =>
    (isBrokerageInternalDocument
      ? getBrokerageInternalDocumentTemplates
      : getInternalDocumentTemplates)()
      .unwrap()
      .then((templates) => {
        const defaultTemplate = templates.find((template) => template.primary) || head(templates);

        if (isNilOrEmpty(defaultTemplate)) {
          showNotification.error('general.notifications.noTemplateFound');

          return;
        }

        return (
          isBrokerageInternalDocument ? createBrokerageInternalDocument : createInternalDocument
        )({
          internalInvoiceDocumentRequestBody: {
            orderId: order.id,
            paymentId,
            templateId: defaultTemplate!.id,
            checkoutId,
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);

  const generateInternalInvoiceDocumentDeductible = (): Promise<unknown> =>
    (isBrokerageInternalDocument
      ? getBrokerageInternalDocumentWithVatTemplates
      : getInternalDocumentWithVatTemplates)()
      .unwrap()
      .then((templates) => {
        const defaultTemplate = templates.find((template) => template.primary) || head(templates);

        if (isNilOrEmpty(defaultTemplate)) {
          showNotification.error('general.notifications.noTemplateFound');

          return;
        }

        return (
          isBrokerageInternalDocument
            ? createBrokerageInternalDocumentWithVat
            : createInternalDocumentWithVat
        )({
          internalInvoiceDocumentRequestBody: {
            orderId: order.id,
            paymentId,
            templateId: defaultTemplate!.id,
            checkoutId,
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);

  const generateInternalCorrectiveTaxDocument = () =>
    getInternalCorrectiveTaxDocumentTemplates()
      .unwrap()
      .then((templates) => {
        const defaultTemplate = templates.find((template) => template.primary) || head(templates);

        if (isNilOrEmpty(defaultTemplate)) {
          showNotification.error('general.notifications.noTemplateFound');

          return;
        }

        return createInternalCorrectiveTaxDocument({
          internalCorrectiveTaxDocumentRequestBody: {
            orderId: order.id,
            paymentId,
            templateId: defaultTemplate!.id,
            checkoutId,
            taxDocumentId,
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);

  return {
    generateInternalInvoiceDocumentDeductible,
    generateInternalInvoiceDocument,
    generateInternalCorrectiveTaxDocument,
    isLoading,
  };
};
