import {showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {useDeleteConfigurationMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function InvoiceCostOverview() {
  const navigate = useNavigate();
  const [deleteConfiguration] = useDeleteConfigurationMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.invoiceCostDetail, {params: {id}}))
      )
      .with('delete', () =>
        deleteConfiguration({id})
          .unwrap()
          .then(() => showNotification.success())
          .then(refreshData)
          .catch(handleApiError)
      );
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accountingSettings.labels.invoiceCostGeneration'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.accounting.labels.newDefinition'),
            onClick: () => navigate(settingsRoutes.invoiceCostNew),
          },
        ],
      }}
      description={i18n.t('page.accountingSettings.labels.invoiceCostGenerationDescription')}
      data-testid={testIds.settings.invoiceCostOverview('page')}
    >
      <DataGrid
        gridCode="invoice-cost-item"
        actionCallback={actionCallback}
        data-testid={testIds.settings.invoiceCostOverview('data-grid')}
      />
    </SettingsTemplate>
  );
}
