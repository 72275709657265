import {Form, FormButton, FormField, FormSubmitHandler, Separator} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useState} from 'react';
import {DefaultValues} from 'react-hook-form';

import {ConsentChannel, ConsentDuration, ConsentSubject} from '@dms/api';
import i18n from '@dms/i18n';
import {useBranches} from '@dms/shared';

import {ConsentTypeFormType} from './components/ConsentTypeFormTemplate';
import {ConsentTypeFormTemplates} from './components/ConsentTypeFormTemplates';

interface GdprConsentTypeFormProps {
  submitTitle: string;
  onSubmit: FormSubmitHandler<ConsentTypeFormType>;
  defaultValues?: DefaultValues<ConsentTypeFormType>;
  onSuccess: () => void;
  onCancel: () => void;
}

export function GdprConsentTypeForm(props: GdprConsentTypeFormProps) {
  const {branchOptions} = useBranches();
  const [subject, setSubject] = useState<string | null>(props.defaultValues?.subject ?? null);

  return (
    <Form<ConsentTypeFormType>
      onSubmit={props.onSubmit}
      schema={schema}
      defaultValues={props.defaultValues}
    >
      {(control) => (
        <VStack>
          <Box width="50%">
            <FormField
              name="name"
              type="text"
              control={control}
              label={i18n.t('entity.gdpr.labels.consentType.name')}
              data-testid="consent-type-form-name"
              maxLength={100}
            />
          </Box>
          <Separator />
          <FormField
            name="subject"
            type="radio"
            control={control}
            label={i18n.t('entity.gdpr.labels.consentType.subject')}
            data-testid="consent-type-form-subject"
            options={subjectOptions}
            onChange={setSubject}
          />
          {ConsentSubject.BRANCH === subject && (
            <>
              <Separator />
              <Box width="50%">
                <FormField
                  name="branchId"
                  type="choice"
                  control={control}
                  label={i18n.t('entity.gdpr.labels.consentType.branch')}
                  data-testid="consent-type-form-branch"
                  options={branchOptions}
                />
              </Box>
            </>
          )}
          <Separator />
          <Box width="50%">
            <FormField
              name="duration"
              type="choice"
              control={control}
              label={i18n.t('entity.gdpr.labels.consentType.duration')}
              data-testid="consent-type-form-duration"
              options={durationOptions}
            />
          </Box>
          <Separator />
          <FormField
            name="channels"
            type="checkboxes"
            control={control}
            label={i18n.t('entity.gdpr.labels.consentType.channels')}
            data-testid="consent-type-form-channels"
            options={channelOptions}
          />
          <Separator />
          <VStack spacing={6}>
            <ConsentTypeFormTemplates control={control} />
            <HStack spacing={2} justify="flex-end">
              <FormButton
                control={control}
                variant="secondary"
                onClick={props.onCancel}
                data-testid="consent-type-form-cancel"
                title={i18n.t('general.actions.cancel')}
              />
              <FormButton
                control={control}
                type="submit"
                variant="primary"
                data-testid="consent-type-form-submit"
                title={props.submitTitle}
              />
            </HStack>
          </VStack>
        </VStack>
      )}
    </Form>
  );
}

const channelOptions = Object.values(ConsentChannel).map((channel) => ({
  value: channel,
  label: i18n.t(`general.labels.${channel}`),
}));

const durationOptions = Object.values(ConsentDuration).map((duration) => ({
  value: duration,
  label: i18n.t(`entity.gdpr.labels.consentType.durations.${duration}`),
}));

const subjectOptions = Object.values(ConsentSubject).map((subject) => ({
  value: subject,
  label: i18n.t(`entity.gdpr.labels.consentType.subjects.${subject}`),
}));

const schema = Yup.object().shape({
  name: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  subject: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  duration: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
  branchId: Yup.string()
    .nullable()
    .when('subject', {
      is: 'branch',
      then: Yup.string().nullable().required(i18n.t('general.validations.fieldIsRequired')),
    }),
  channels: Yup.array()
    .min(1, i18n.t('entity.gdpr.validations.consentType.channelsAreRequired'))
    .required(i18n.t('entity.gdpr.validations.consentType.channelsAreRequired')),
});
