import {Action, FlagProps, Parameter, showNotification} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, defaultTo, isEmpty, isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetReceiveNoteItemsCountQuery,
  useGetReceiveNoteQuery,
  useGetUserQuery,
  usePatchReceiveNoteManualStateMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
} from '@dms/shared';

import {
  buildArray,
  composePath,
  EMPTY_PLACEHOLDER,
  generateHashFromObjects,
  parseDate,
  useNavigate,
} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {isNilOrZero} from '../../utils/isNilOrZero';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function ReceiveNoteDetail() {
  const navigate = useNavigate();

  const {receiveNoteId} = useWarehouseParams();

  const dateTimeFormatter = useDateTimeFormatter();

  const {
    data: receiveNoteItemCount,
    isLoading: isReceiveNoteItemCountLoading,
    isError: hasReceiveNoteItemCountError,
  } = useGetReceiveNoteItemsCountQuery({receiveNoteId}, {skip: isNilOrEmpty(receiveNoteId)});

  const {
    data: receiveNote,
    isLoading: isReceiveNoteLoading,
    isError: hasReceiveNoteError,
  } = useGetReceiveNoteQuery({receiveNoteId}, {skip: isNilOrEmpty(receiveNoteId)});

  const {data: createdByUser, isLoading: isCreatedByUserLoading} = useGetUserQuery(
    {id: receiveNote?.createdBy!},
    {skip: isNil(receiveNote?.createdBy)}
  );

  const {data: updatedByUser, isLoading: isUpdatedByUserLoading} = useGetUserQuery(
    {id: receiveNote?.updatedBy!},
    {skip: isNil(receiveNote?.updatedBy)}
  );

  const [patchReceiveNoteManualStateManual, {isLoading: isPatchingReceiveNote}] =
    usePatchReceiveNoteManualStateMutation();

  const handleComplete = () => {
    patchReceiveNoteManualStateManual({
      receiveNoteId,
      body: {
        state: 'COMPLETED',
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('page.warehouse.notification.receiveNoteCompleted'));
        navigate(warehouseRoutes.receiveNoteList);
      })
      .catch(handleApiError);
  };

  const handleDiscard = () => {
    navigate(warehouseRoutes.receiveNoteList);
  };

  const handleCorrective = () =>
    navigate(
      composePath(warehouseRoutes.receiveNoteCorrectionCreate, {
        params: {receiveNoteId},
        isSearchParamsPreserved: false,
      })
    );

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  const isLoading =
    isReceiveNoteItemCountLoading ||
    isReceiveNoteLoading ||
    isCreatedByUserLoading ||
    isUpdatedByUserLoading;

  const hasError = hasReceiveNoteItemCountError || hasReceiveNoteError;

  const isDraftReceiveNote = isNil(receiveNote);
  const isPendingReceiveNote = receiveNote?.state === 'PENDING';
  const isCompletedReceiveNote = receiveNote?.state === 'COMPLETED';

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(createdByUser) ?? EMPTY_PLACEHOLDER}`;
  const createdAt = `${i18n.t('general.labels.createdAt')}: ${formatToShortDate(receiveNote?.created)}`;

  const updatedBy = `${i18n.t('general.labels.updatedBy')}: ${getUserName(updatedByUser) ?? EMPTY_PLACEHOLDER}`;
  const updatedAt = `${i18n.t('general.labels.updatedAt')}: ${formatToShortDate(receiveNote?.updated)}`;

  const completedBy = `${i18n.t('general.labels.completedBy')}: ${isCompletedReceiveNote ? getUserName(updatedByUser) : EMPTY_PLACEHOLDER}`;
  const completedAt = `${i18n.t('general.labels.completedAt')}: ${isCompletedReceiveNote ? formatToShortDate(receiveNote?.updated) : EMPTY_PLACEHOLDER}`;

  const headerTitle = isEmpty(receiveNoteId)
    ? i18n.t('entity.warehouse.labels.newReceiveNote')
    : `${i18n.t('entity.warehouse.labels.receiveNote')} ${defaultTo('', receiveNote?.receiveNoteNumber)}`;

  const headerParameters = match(isDraftReceiveNote)
    .returnType<Parameter[]>()
    .with(true, always([]))
    .otherwise(always([createdBy, createdAt, updatedBy, updatedAt, completedBy, completedAt]));

  const headerActions = buildArray<Action>()
    .when(isDraftReceiveNote, {
      type: 'button',
      onClick: handleDiscard,
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      'data-testid': testIds.warehouse.receiveNoteDetailOverview('headerActions.discard'),
    })
    .when(isPendingReceiveNote, {
      type: 'button',
      onClick: handleComplete,
      title: i18n.t('general.actions.complete'),
      variant: 'primary',
      isDisabled: isNilOrZero(receiveNoteItemCount?.count),
      isLoading: isPatchingReceiveNote,
      'data-testid': testIds.warehouse.receiveNoteDetailOverview('headerActions.complete'),
    })
    .when(isCompletedReceiveNote, {
      type: 'button',
      variant: 'dangerOutlined',
      title: i18n.t('entity.warehouse.actions.corrective'),
      onClick: handleCorrective,
      'data-testid': testIds.warehouse.receiveNoteDetailOverview('headerActions.corrective'),
    });

  const headerFlags = match(receiveNote?.state)
    .returnType<FlagProps[]>()
    .with('PENDING', () => [{colorScheme: 'blue', label: i18n.t('general.labels.pending')}])
    .with('COMPLETED', () => [{colorScheme: 'green', label: i18n.t('general.labels.completed')}])
    .otherwise(() => [{colorScheme: 'neutral', label: i18n.t('entity.warehouse.labels.draft')}]);

  const header: DetailTemplateHeader = {
    title: headerTitle,
    icon: 'action/deployed',
    parameters: headerParameters,
    recordId: receiveNote?.receiveNoteId,
    resourceId: EntityResourceIds.receiveNote,
    flags: headerFlags,
    actions: headerActions,
    controls: ['ASSIGNEE'],
  };

  const receiveNoteDetailHref = isNotNilOrEmpty(receiveNoteId)
    ? composePath(warehouseRoutes.receiveNoteDetailOverview, {
        params: {receiveNoteId},
      })
    : warehouseRoutes.receiveNoteCreate;

  const navigation = buildArray<NavigationItem>([])
    .add({
      id: 'receiveNoteDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: receiveNoteDetailHref,
      content: (
        <Overview
          receiveNote={receiveNote}
          isReceiveNoteLoading={isReceiveNoteLoading}
          hasReceiveNoteError={hasReceiveNoteError}
          data-testid={testIds.warehouse.receiveNoteDetailOverview('sections.overview')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteDetailOverview('navigation.overview'),
    })
    .when(isNotNilOrEmpty(receiveNoteId), {
      id: 'receiveNoteDocuments',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.receiveNoteDetailDocuments, {
        params: {receiveNoteId},
      }),
      content: (
        <Documents
          data-testid={testIds.warehouse.receiveNoteDetailDocuments('sections.documents')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteDetailDocuments('navigation.documents'),
    });

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.receiveNote')} />
      <DetailTemplate
        key={generateHashFromObjects(receiveNote)}
        isLoading={isLoading}
        isError={hasError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.receiveNoteDetailOverview('page')}
      />
    </>
  );
}
