import {openDialog, closeDialog, AlertProps, Hyperlink} from 'platform/components';
import {match} from 'ts-pattern';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  usePostAvailableXentryOrdersMutation,
  usePostIntegrationsForXentryLinkMutation,
  usePostServiceCaseIntegrationsXentryOrderManualCreationMutation,
  useGetServiceCaseIntegrationsQuery,
  XentryIntegrationAction,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, EMPTY_PLACEHOLDER} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ConfirmChangeXentryOrderDialog} from '../components/ConfirmChangeXentryOrderDialog';
import {ConfirmUnlinkDialog} from '../components/ConfirmUnlinkDialog';
import {LinkXentry} from '../components/LinkXentry';
import {XENTRY_INTEGRATION_CODE} from '../constants/xentryIntegrationCode';

interface XentryIntegrationProps extends RequiredTestIdProps {
  serviceCaseId: string;
}

export function useXentryIntegration(props: XentryIntegrationProps) {
  const {data: integrations} = useGetServiceCaseIntegrationsQuery(
    {serviceCaseId: props.serviceCaseId},
    {skip: isNilOrEmpty(props.serviceCaseId)}
  );
  const [postAvailableXentryOrders] = usePostAvailableXentryOrdersMutation({
    fixedCacheKey: 'open-link-xentry',
  });
  const [postIntegrationsForXentryLink] = usePostIntegrationsForXentryLinkMutation();
  const [postServiceCaseIntegrationsXentryOrderManualCreation] =
    usePostServiceCaseIntegrationsXentryOrderManualCreationMutation();

  const linkedXentryIntegration = integrations?.caseIntegration?.find(
    (caseIntegration) => caseIntegration?.integration?.code === XENTRY_INTEGRATION_CODE
  );

  const handleLinkXetry = (xentryOrderId: string) =>
    postIntegrationsForXentryLink({serviceCaseId: props.serviceCaseId, body: {xentryOrderId}})
      .unwrap()
      .catch(handleApiError);

  const handleCreateNewXetry = () =>
    postServiceCaseIntegrationsXentryOrderManualCreation({
      serviceCaseId: props.serviceCaseId,
    })
      .unwrap()
      .catch(handleApiError);

  const openLinkXentryDialog = () => {
    postAvailableXentryOrders({serviceCaseId: props.serviceCaseId}).unwrap();

    openDialog(
      <LinkXentry
        serviceCaseId={props.serviceCaseId}
        onClose={() => closeDialog('selectXentryDialog')}
        onLink={(xentryOrderId) =>
          handleLinkXetry(xentryOrderId).then(() => closeDialog('selectXentryDialog'))
        }
        onLinkNew={() => handleCreateNewXetry().then(() => closeDialog('selectXentryDialog'))}
        data-testid={suffixTestId('linkXentry', props)}
      />,
      {title: i18n.t('entity.integration.labels.selectXentryOrder'), id: 'selectXentryDialog'}
    );
  };

  const openUnlinkConfirm = () =>
    openDialog(
      <ConfirmUnlinkDialog
        dialogId="confirmUnlinkXentryOrder"
        serviceCaseId={props.serviceCaseId}
        xentryId={linkedXentryIntegration?.integration?.externalId}
        data-testid={suffixTestId('unlinkXentry', props)}
      />,
      {
        id: 'confirmUnlinkXentryOrder',
        size: 'small',
      }
    );

  const openChangeConfirm = (xentryId: string | Nullish, onConfirm: () => unknown) =>
    openDialog(
      <ConfirmChangeXentryOrderDialog
        dialogId="confirmChangeXentryOrder"
        serviceCaseId={props.serviceCaseId}
        xentryId={xentryId}
        onConfirm={onConfirm}
        data-testid={suffixTestId('unlinkXentry', props)}
      />,
      {
        id: 'confirmChangeXentryOrder',
        size: 'small',
      }
    );

  const openChangeXentryDialog = () => {
    postAvailableXentryOrders({serviceCaseId: props.serviceCaseId}).unwrap();

    openDialog(
      <LinkXentry
        serviceCaseId={props.serviceCaseId}
        linkedXentryId={linkedXentryIntegration?.integration?.externalId}
        onClose={() => closeDialog('changeXentryDialog')}
        onLink={(xentryOrderId) => {
          openChangeConfirm(xentryOrderId, () => {
            handleLinkXetry(xentryOrderId);
            closeDialog('changeXentryDialog');
          });
        }}
        onLinkNew={() => {
          openChangeConfirm(null, () => {
            handleCreateNewXetry();
            closeDialog('changeXentryDialog');
          });
        }}
        data-testid={suffixTestId('linkXentry', props)}
      />,
      {title: i18n.t('entity.integration.labels.selectXentryOrder'), id: 'changeXentryDialog'}
    );
  };

  const getAction = (action: XentryIntegrationAction | Nullish) => {
    const baseAction = {isDisabled: action?.style === 'disabled', size: 'small' as const};

    return match<string | Nullish, Hyperlink>(action?.key)
      .with('xentry-job-link', () => ({
        ...baseAction,
        title: i18n.t('entity.integration.actions.link'),
        leftIcon: 'content/link',
        onClick: openLinkXentryDialog,
      }))
      .with('xentry-job-unlink', () => ({
        ...baseAction,
        title: i18n.t('entity.integration.actions.unlink'),
        leftIcon: 'content/link_off',
        onClick: openUnlinkConfirm,
      }))
      .with('xentry-job-change', () => ({
        ...baseAction,
        title: i18n.t('entity.integration.actions.unlinkAndChange'),
        leftIcon: 'content/link_off',
        onClick: openChangeXentryDialog,
      }))
      .with('xentry-job-hyperlink', () => ({
        ...baseAction,
        title: i18n.t('entity.integration.actions.viewOrder'),
        leftIcon: 'action/open_in_new',
        onClick: () => window.open(linkedXentryIntegration?.integration?.viewDetailsLink ?? ''),
      }))
      .otherwise(() => ({title: EMPTY_PLACEHOLDER}));
  };

  const isLinked = !!linkedXentryIntegration?.integration?.externalId;

  const xentryAlert: AlertProps | undefined = linkedXentryIntegration
    ? {
        title: isLinked
          ? i18n.t('entity.integration.labels.successLinkedXentry', {
              xentryId: linkedXentryIntegration?.integration?.externalId,
            })
          : i18n.t('entity.integration.labels.warningNotLinkedXentry'),
        variant: isLinked ? 'success' : 'warning',
        hyperlinks: linkedXentryIntegration.action
          ?.filter((action) => action?.style !== 'hidden')
          .map(getAction),
        'data-testid': testIds.workshop.serviceCaseDetail('linkedXentry'),
      }
    : undefined;

  return {xentryAlert};
}
