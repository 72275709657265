import {DropdownTag} from 'platform/components';

import {Control, FieldPathByValue, useController} from 'react-hook-form';

import {TaskStateEnum, TaskStateEnumSchema} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {TASK_STATE_COLOR_MAP, TASK_STATE_LABEL_MAP} from '../../constants/task';
import {TaskForm} from '../../types/TaskForm';

interface TaskStatusSwitcherProps extends RequiredTestIdProps {
  name: FieldPathByValue<TaskForm, string>;
  control: Control<TaskForm>;
  isDisabled?: boolean;
}

export function TaskStateSwitcherField(props: TaskStatusSwitcherProps) {
  const {field} = useController({name: props.name, control: props.control});

  return (
    <DropdownTag
      isDisabled={props.isDisabled}
      data-testid={props['data-testid']}
      size="large"
      colorScheme={TASK_STATE_COLOR_MAP[field.value as TaskStateEnum] ?? 'neutral'}
      label={i18n.t(TASK_STATE_LABEL_MAP[field.value as TaskStateEnum]) ?? 'Unknown'}
      dropdownItems={TaskStateEnumSchema.options
        .filter((option) => option !== field.value)
        .map((option) => ({
          label: i18n.t(TASK_STATE_LABEL_MAP[option]),
          onClick: () => field.onChange(option),
        }))}
    />
  );
}
