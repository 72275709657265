import {getAllTreeKeys, VehicleCatalogueEnumOption} from '@dms/teas';

/**
 * To make decision, if is this node the last selected in Tree structure
 * Required for filters to use only last nodes
 * @param node
 * @param selected
 */
export const isLastSelectedNode = (node: VehicleCatalogueEnumOption, selected: string[]) => {
  const allChildren = getAllTreeKeys(node);
  allChildren.pop(); //whithout key of current node
  return !node?.children?.length || !allChildren?.some((x) => selected?.includes(x));
};
