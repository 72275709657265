import {OmneticKeys} from 'i18next';

import {isNotNil} from 'ramda';

import {browserStorageKey} from '@dms/config';
import {i18n, FALLBACK_LANGUAGE} from '@dms/i18n';

import {LiteralUnionAutocomplete} from 'shared';

export const getFixedTranslation = (key: LiteralUnionAutocomplete<OmneticKeys>) => {
  const lastKnownLanguage = localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE);

  if (isNotNil(lastKnownLanguage) && !i18n.languages.includes(lastKnownLanguage)) {
    i18n.loadLanguages(lastKnownLanguage);
  }

  const t = i18n.getFixedT(lastKnownLanguage ?? FALLBACK_LANGUAGE);
  return t(key);
};
