import {Helmet} from 'react-helmet-async';

import {defaultTo} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetCostCorrectionQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {DetailTemplate, DetailTemplateHeader, NavigationItem} from '@dms/shared';

import {composePath, generateHashFromObjects} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {Overview} from './(sections)/Overview/Overview';

export function CostCorrectionDetail() {
  const {costCorrectionId} = useWarehouseParams();

  const {
    data: costCorrection,
    isLoading: isCostCorrectionLoading,
    isError: hasCostCorrectionError,
  } = useGetCostCorrectionQuery({costCorrectionId}, {skip: isNilOrEmpty(costCorrectionId)});

  const header: DetailTemplateHeader = {
    title: `${i18n.t('entity.warehouse.labels.costCorrection')} ${defaultTo('', costCorrection?.costCorrectionInfo.number)}`,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'overview',
      label: i18n.t('general.labels.overview'),
      href: composePath(warehouseRoutes.costCorrectionDetailOverview, {
        params: {id: costCorrectionId},
      }),
      content: (
        <Overview
          costCorrection={costCorrection}
          isCostCorrectionLoading={isCostCorrectionLoading}
          data-testid={testIds.warehouse.costCorrectionDetailOverview('section.overview')}
        />
      ),
      'data-testid': testIds.warehouse.costCorrectionDetailOverview('navigation.overview'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.costCorrectionDetail')} />
      <DetailTemplate
        key={generateHashFromObjects(costCorrection)}
        isLoading={isCostCorrectionLoading}
        isError={hasCostCorrectionError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.costCorrectionDetailOverview('page')}
      />
    </>
  );
}
