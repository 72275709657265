import {DataStatus, Switch} from 'platform/components';
import {HStack} from 'platform/foundation';

import {
  useIsPublishingAutomaticUpdateEnabledQuery,
  useSetPublishingAutomaticUpdateEnabledMutation,
} from '@dms/api';
import {i18n} from '@dms/i18n';
import {testIds} from '@dms/routes';

export function AutomaticUpdateControl() {
  const [setAutomaticUpdate] = useSetPublishingAutomaticUpdateEnabledMutation();
  const {data: isAutomaticUpdate, isLoading} = useIsPublishingAutomaticUpdateEnabledQuery();

  return (
    <HStack align="center" spacing={4}>
      <DataStatus isLoading={isLoading}>
        <Switch
          label={i18n.t('entity.advertisingPlatforms.labels.enableAutomaticUpdate')}
          helperText={i18n.t('entity.advertisingPlatforms.labels.enableAutomaticUpdateDescription')}
          value={!!isAutomaticUpdate?.value}
          onChange={setAutomaticUpdate}
          data-testid={testIds.settings.advertisingPlatforms('enableAutomaticUpdate')}
        />
      </DataStatus>
    </HStack>
  );
}
