import {showNotification} from 'platform/components';

import {useSourcingGetPriceReportMutation} from '@dms/api';
import {i18n} from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {useSourcingTargetCountry} from '@dms/teas';

const openInNewWindow = (url: string) => {
  const win = window.open(url, '_blank');
  win?.focus();
};

export function useCreatePriceReport(externalId: string) {
  const [getPriceReport, {isLoading: isPriceReportCreating}] = useSourcingGetPriceReportMutation();
  const targetCountry = useSourcingTargetCountry();

  const createPriceReport = () => {
    showNotification.info(i18n.t('general.notifications.creatingPriceReport'));
    getPriceReport({externalId, country: targetCountry?.code ?? 'CZE'})
      .unwrap()
      .then((data) => openInNewWindow(data.url))
      .catch(handleApiError);
  };

  return {createPriceReport, isPriceReportCreating};
}
