import {Column, openDeleteDialog, Table, TableRow, TableRowAction} from 'platform/components';
import {Align} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {
  PaymentTypeResponseBody,
  RoundingDefinitionResponseBody,
  selectBranchList,
  useDeleteRoundingDefinitionMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError} from '@dms/shared';
import {PaymentTypeEnum, Tag} from '@dms/teas';

import {buildArray, composePath, useNavigate} from 'shared';

type Props = {
  paymentTypes: PaymentTypeResponseBody[] | undefined;
  roundingDefinitions: RoundingDefinitionResponseBody[] | undefined;
};

export const DocumentRoundingDefinitionList = (props: Props) => {
  const navigate = useNavigate();
  const {data: {branchListItems: branchList} = {}} = useSelector(selectBranchList);
  const [deleteRoundingDefinition] = useDeleteRoundingDefinitionMutation();

  const columns: Column[] = [
    {
      element: <Align left>{i18n.t('entity.branch.labels.branch')}</Align>,
    },
    {
      element: <Align left>{i18n.t('entity.bank.labels.paymentType')}</Align>,
    },
  ];

  const onNavigateToEdit = (id: string) => {
    navigate(
      composePath(settingsRoutes.documentRoundingDefinitionsEdit, {
        params: {
          id,
        },
      })
    );
  };

  return (
    <Table columns={columns}>
      {props.roundingDefinitions?.map((roundingDefinition) => {
        const branchName = branchList?.find(
          (branch) => branch?.id === roundingDefinition.branchId
        )?.marketingName;

        const paymentType = props.paymentTypes?.find(
          (payment) => payment.key === roundingDefinition.paymentType
        );

        return (
          <TableRow
            key={roundingDefinition.id}
            onClick={() => onNavigateToEdit(roundingDefinition.id)}
            actions={{
              primary: buildArray<TableRowAction>()
                .add({
                  title: i18n.t('general.labels.edit'),
                  icon: 'image/edit',
                  onClick: () => onNavigateToEdit(roundingDefinition.id),
                })
                .whenNot(roundingDefinition.isSystem, {
                  title: i18n.t('general.labels.delete'),
                  icon: 'action/delete',
                  onClick: () => {
                    const selectedItemBranchName = branchList?.find(
                      (branch) => branch?.id === roundingDefinition.branchId
                    )?.marketingName;

                    openDeleteDialog({
                      text: i18n.t(
                        'entity.accounting.labels.deleteRoundingDefinitionConfirmationText',
                        {
                          branch: selectedItemBranchName,
                        }
                      ),
                      onConfirm: () =>
                        deleteRoundingDefinition({
                          id: roundingDefinition.id,
                        })
                          .unwrap()
                          .catch(handleApiError),
                    });
                  },
                }),
            }}
          >
            <strong>{branchName ?? EMPTY_PLACEHOLDER}</strong>
            <Tag
              yellow={paymentType?.key === PaymentTypeEnum.CASH}
              magenta={paymentType?.key === PaymentTypeEnum.BANK_TRANSFER}
              purple={paymentType?.key === PaymentTypeEnum.PAYMENT_CARD}
            >
              {paymentType?.value}
            </Tag>
          </TableRow>
        );
      })}
    </Table>
  );
};
