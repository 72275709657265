import {formatISO} from 'date-fns';
import {
  Actions,
  Card,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  isCurrency,
  showNotification,
} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {boolean, mixed, number, object, string} from 'yup';

import {UseFormReturn} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {always, filter, head, isNil, isNotEmpty, isNotNil, map, not, pipe} from 'ramda';
import {isFalse, isNilOrEmpty, isString, isTrue} from 'ramda-adjunct';

import {
  BankAccountResponseBody,
  CashRegisterResponseBody,
  EntityResourceIds,
  InvoiceDocumentTypes,
  omneticApi,
  OrderPaymentResponseBody,
  TenantBankAccount,
  useGetOrderQuery,
  useGetParticipationQuery,
  useRecordPaymentMutation,
  useShowPaymentPrescriptionQuery,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {
  DEFAULT_CURRENCY,
  getDefaultCreateReceiptByPaymentMethod,
  getPaymentTypeFromMethod,
  handleApiError,
  NoPermissionTooltip,
  useBank,
  useCashRegister,
  useGetAccountingDocuments,
  usePermissions,
} from '@dms/shared';

import {
  buildArray,
  getApiDateString,
  Nullish,
  parseDate,
  POSITIVE_NUMBER_REGEX,
  suffixTestId,
  TestIdProps,
  yupString,
} from 'shared';

import {useGetCreateTaxDocumentForPayment} from '../hooks/useGetCreateTaxDocumentForPayment';
import {PaymentLimitAlert} from './PaymentLimitAlert';
import {PaymentPriceBox} from './PaymentPriceBox';

interface InvoicePaymentModalProps extends TestIdProps {
  invoiceId: string | Nullish;
  orderId: string;
  checkoutId: string;
  payment: OrderPaymentResponseBody;
  isPartialPaymentEnabled?: boolean;
  documentType: InvoiceDocumentTypes;
  businessCaseId?: string;
}

export type InvoicePaymentFormValues = {
  amount: string;
  paymentDate: string;
  paymentType: 'CASH' | 'PAYMENT_CARD' | 'BANK_TRANSFER' | 'OFFSET';
  cashRegister: string;
  shouldCreateReceipt: boolean | Nullish;
  shouldCreateTaxDocumentForPayment: boolean | Nullish;
  tenantBankAccountId: string | null;
  exchangeRateRatio: {
    amount: string | null;
    ratio: string | null;
  };
};

const DEFAULT_EXCHANGE_RATE_AMOUNT = '1';

export function PaymentForm(props: InvoicePaymentModalProps) {
  const todayDate = getApiDateString(new Date());
  const {data: order} = useGetOrderQuery({checkoutId: props.checkoutId, orderId: props.orderId});
  const [recordPayment] = useRecordPaymentMutation();

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: props.businessCaseId ?? '',
      resourceId: EntityResourceIds.businessCase,
    },
    {skip: isNil(props.businessCaseId)}
  );

  const [canEditDepositPayment, canEditPayment] = usePermissions({
    permissionKeys: ['businessCaseEditSaleDepositPaymentDate', 'businessCaseEditSalePaymentDate'],
    scopes: {
      businessCaseEditSaleDepositPaymentDate: {participation: businessCaseParticipation},
      businessCaseEditSalePaymentDate: {participation: businessCaseParticipation},
    },
  });

  const {bankAccountOptions, getBankAccountByNumber} = useBank();
  const [createTaxDocumentForPayment, taxDocumentForPaymentIssuedType] =
    useGetCreateTaxDocumentForPayment(order, props.businessCaseId);

  const dispatch = useDispatch();
  const {
    data: invoice,
    isError: isAccountingDocumentError,
    isLoading: isAccountingDocumentLoading,
  } = useGetAccountingDocuments(props.invoiceId, props.documentType);

  const {
    data: prescription,
    isLoading: isPrescriptionLoading,
    isError: isPrescriptionError,
  } = useShowPaymentPrescriptionQuery(
    {paymentPrescriptionId: invoice?.paymentPrescriptionId ?? ''},
    {skip: isNilOrEmpty(invoice?.paymentPrescriptionId)}
  );

  const currency =
    props.payment.foreignCurrencyAmount?.currency ??
    props.payment.amount?.currency ??
    DEFAULT_CURRENCY;

  const paymentCurrency = isCurrency(currency) ? currency : DEFAULT_CURRENCY;

  const {
    cashRegisters,
    isLoading: isLoadingCashRegisters,
    isError: isCashRegistersError,
  } = useCashRegister(paymentCurrency);

  const isLoading = isPrescriptionLoading || isAccountingDocumentLoading || isLoadingCashRegisters;
  const isError = isPrescriptionError || isAccountingDocumentError || isCashRegistersError;

  const isForeignCurrencyPayment = isNotNil(props.payment.foreignCurrencyAmount);

  const handleSubmit: FormSubmitHandler<InvoicePaymentFormValues> = async (submitData) => {
    const shouldCreateTaxDocumentForPayment =
      isNotNil(props.payment.proformaInvoiceId) &&
      isTrue(submitData.shouldCreateTaxDocumentForPayment);
    const bankAccount = submitData.tenantBankAccountId
      ? getBankAccountByNumber(submitData.tenantBankAccountId)
      : null;

    await recordPayment({
      checkoutId: props.checkoutId,
      orderId: props.orderId,
      paymentId: props.payment.id,
      recordPayment: {
        amount: submitData.amount,
        cashRegisterId: submitData.cashRegister,
        createCashRegisterDocument:
          submitData.paymentType !== 'BANK_TRANSFER'
            ? (submitData.shouldCreateReceipt ?? false)
            : null,
        currency: paymentCurrency ?? DEFAULT_CURRENCY,
        payDate: submitData.paymentDate,
        paymentMethod: submitData.paymentType,
        tenantBankAccount: bankAccountToTenantBankAccount(bankAccount),
      },
    })
      .unwrap()
      .then(async (data) => {
        if (shouldCreateTaxDocumentForPayment) {
          const transactionId = data.incomeTransactions?.[0]?.transactionId;

          if (isNil(transactionId)) {
            return showNotification.error();
          }

          await createTaxDocumentForPayment(transactionId);
        }
      })
      .then(() => showNotification.success())
      .then(() =>
        props.businessCaseId
          ? dispatch(
              omneticApi.util.invalidateTags([
                {type: 'BusinessCaseDetail', id: props.businessCaseId},
                {type: 'BusinessCaseActions', id: props.businessCaseId},
              ])
            )
          : {}
      )
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const isPurchaseOrder =
    order?.orderDiscriminator === 'PURCHASE' ||
    order?.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE';

  const initialValues: Partial<InvoicePaymentFormValues> = {
    amount:
      prescription?.remainsToPay.amount ??
      props.payment.foreignCurrencyAmount?.amount ??
      props.payment.amount?.amount,
    paymentDate: todayDate,
    paymentType: getPaymentTypeFromMethod(props.payment.paymentMethod, isPurchaseOrder),
    shouldCreateReceipt: getDefaultCreateReceiptByPaymentMethod(props.payment.paymentMethod),
    tenantBankAccountId: bankAccountOptions?.[0]?.value ?? null,
    shouldCreateTaxDocumentForPayment: taxDocumentForPaymentIssuedType === 'automatic',
    cashRegister: head(cashRegisters ?? [])?.id,
    exchangeRateRatio: {
      amount: DEFAULT_EXCHANGE_RATE_AMOUNT,
      ratio: props.payment.exchangeRate ?? null,
    },
  };

  const secondPaymentMaxPrice =
    prescription?.price.amount !== prescription?.remainsToPay.amount
      ? prescription?.remainsToPay.amount
      : null;

  const dateOfPaymentLabel =
    props.payment.paymentDiscriminator === 'DEPOSIT'
      ? i18n.t('entity.invoice.labels.dateOfPaymentWithDUZP')
      : i18n.t('entity.invoice.labels.dateOfPayment');

  const maxPriceForPayment = match(props.documentType)
    .with('invoice_proforma', always(prescription?.remainsToPay.amount))
    .otherwise(always(secondPaymentMaxPrice));

  const foreignAmountCurrency = props.payment.amount?.currency ?? DEFAULT_CURRENCY;
  const foreignCurrency = isCurrency(foreignAmountCurrency)
    ? foreignAmountCurrency
    : DEFAULT_CURRENCY;

  const handlePaymentTypeChange =
    (formApi: UseFormReturn<InvoicePaymentFormValues>) => (paymentType: string | number | null) => {
      if (!isString(paymentType)) {
        return;
      }

      const firstCashRegisterOption = cashRegisters?.filter(filterNonCardOptions(paymentType));
      const firstCashRegisterOptionId = head(firstCashRegisterOption ?? [])?.id;

      formApi.setValue('shouldCreateReceipt', getDefaultCreateReceiptByPaymentMethod(paymentType));

      if (isNotNil(firstCashRegisterOptionId) && isNotEmpty(firstCashRegisterOptionId)) {
        formApi.setValue('cashRegister', firstCashRegisterOptionId);
      }
    };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={50}>
      <Form<InvoicePaymentFormValues>
        defaultValues={initialValues}
        onSubmit={handleSubmit}
        schema={getSchema(isForeignCurrencyPayment, maxPriceForPayment, props.documentType)}
      >
        {(control, formApi) => {
          const [paymentType, amount] = formApi.watch(['paymentType', 'amount']);
          const isPayingByCardOrCash = paymentType === 'PAYMENT_CARD' || paymentType === 'CASH';

          const cashRegisterOptions = pipe(
            filter(filterNonCardOptions(paymentType)),
            map(cashRegisterToOption)
          )(cashRegisters ?? []);

          return (
            <VStack spacing={4}>
              <PaymentLimitAlert
                paymentMethod={paymentType}
                documentType={order?.orderDiscriminator === 'SALE' ? 'income' : 'expense'}
                price={{amount, currency: paymentCurrency}}
              />
              <PaymentPriceBox payment={props.payment} />

              <Card variant="inlineGrey">
                <Grid columns={2}>
                  <FormField
                    name="amount"
                    control={control}
                    type="currency"
                    isDisabled={isFalse(props.isPartialPaymentEnabled)}
                    decimalPlaces={2}
                    currency={paymentCurrency}
                    label={i18n.t('general.labels.amount')}
                    data-testid={suffixTestId('amount', props)}
                  />
                  <NoPermissionTooltip
                    shouldShowTooltip={
                      (isFalse(canEditDepositPayment) || isFalse(canEditPayment)) &&
                      order?.orderDiscriminator === 'SALE'
                    }
                  >
                    <FormField
                      name="paymentDate"
                      control={control}
                      isRequired
                      maxDate={new Date()}
                      type="apiDate"
                      label={dateOfPaymentLabel}
                      data-testid={suffixTestId('dateOfPayment', props)}
                      isDisabled={not(canEditDepositPayment) || not(canEditPayment)}
                    />
                  </NoPermissionTooltip>
                  <VStack spacing={2}>
                    <FormField
                      name="paymentType"
                      control={control}
                      options={getPaymentMethodOptions(isPurchaseOrder)}
                      onChange={handlePaymentTypeChange(formApi)}
                      isRequired
                      isNotClearable
                      isDisabled={isPurchaseOrder}
                      type="choice"
                      menuInPortal
                      label={i18n.t('entity.bank.labels.paymentType')}
                      data-testid={suffixTestId('paymentType', props)}
                    />
                    <Show when={isPayingByCardOrCash}>
                      <FormField
                        data-testid={suffixTestId('shouldCreateReceipt', props)}
                        name="shouldCreateReceipt"
                        isDisabled={paymentType === 'CASH'}
                        control={control}
                        type="checkbox"
                        label={i18n.t('entity.cashRegister.labels.createCashReceipt')}
                      />
                    </Show>
                    <Show when={props.payment.paymentDiscriminator === 'DEPOSIT'}>
                      <FormField
                        data-testid={suffixTestId('shouldCreateTaxDocumentForPayment', props)}
                        name="shouldCreateTaxDocumentForPayment"
                        control={control}
                        isDisabled={taxDocumentForPaymentIssuedType !== 'manual'}
                        type="checkbox"
                        label={i18n.t(
                          'entity.cashRegister.labels.shouldCreateTaxDocumentForPayment'
                        )}
                      />
                    </Show>
                  </VStack>
                  <Show when={isPayingByCardOrCash}>
                    <VStack spacing={2}>
                      <FormField
                        label={i18n.t('entity.lineItems.labels.cashRegister')}
                        name="cashRegister"
                        control={control}
                        type="choice"
                        options={cashRegisterOptions}
                        data-testid={suffixTestId('cashRegister', props)}
                      />
                    </VStack>
                    <Show
                      whenFeatureEnabled={featureFlags.SALES_FOREIGN_CURRENCY}
                      when={isForeignCurrencyPayment}
                    >
                      <FormField
                        name="exchangeRateRatio.amount"
                        control={control}
                        isDisabled
                        type="currency"
                        currency={paymentCurrency}
                        label={i18n.t('general.labels.amount')}
                        data-testid={suffixTestId('exchangeRateRatioAmount', props)}
                      />
                      <FormField
                        name="exchangeRateRatio.ratio"
                        control={control}
                        type="currency"
                        isRequired
                        currency={foreignCurrency}
                        label={i18n.t('general.labels.exchangeRate')}
                        data-testid={suffixTestId('exchangeRateRatioRatio', props)}
                      />
                    </Show>
                  </Show>
                </Grid>
              </Card>
              <Actions
                align="right"
                data-testid={suffixTestId('paymentFormActions', props)}
                actions={[
                  {
                    title: i18n.t('general.actions.cancel'),
                    type: 'button',
                    onClick: closeCurrentDialog,
                    variant: 'secondary',
                    'data-testid': suffixTestId('close', props),
                  },
                  {
                    title: i18n.t('general.actions.confirm'),
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    'data-testid': suffixTestId('submit', props),
                  },
                ]}
              />
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const filterNonCardOptions = (paymentType: string | Nullish) => (item: CashRegisterResponseBody) =>
  paymentType !== 'PAYMENT_CARD' || item.eligibleForCardPayments;

const cashRegisterToOption = (item: CashRegisterResponseBody) => ({
  value: item.id,
  label: item.name,
});

const bankAccountToTenantBankAccount = (
  acc: BankAccountResponseBody | Nullish
): TenantBankAccount | null =>
  isNil(acc)
    ? null
    : {
        accountName: acc.accountName ?? '',
        accountNumber: acc.accountNumber ?? '',
        bankName: acc.bankName ?? '',
        currencyCode: acc.currency,
        iban: acc.iban,
        isPrimary: acc.isPrimary || false,
        swiftBic: acc.swift,
      };

const getSchema = (
  isForeignCurrencyEnabled: boolean,
  maxPrice: string | Nullish,
  documentType: InvoiceDocumentTypes
) =>
  object({
    amount: string()
      .nullable()
      .required()
      .matches(POSITIVE_NUMBER_REGEX, i18n.t('general.notifications.numberPositive'))

      .when('paymentType', {
        is: () => isNotNil(maxPrice),
        then: (schema) =>
          schema.test(
            'Test for max price',
            documentType === 'invoice_proforma'
              ? i18n.t('general.notifications.amountExceedsProformaInvoiceAmount')
              : i18n.t('general.notifications.amountExceedsInvoiceAmount'),
            (amount) => parseFloat(amount ?? '0') <= parseFloat(maxPrice ?? '0')
          ),
        otherwise: (schema) => schema,
      })
      .matches(POSITIVE_NUMBER_REGEX, i18n.t('general.notifications.numberPositive'))
      .nullable()
      .required(),
    paymentType: string().nullable().required(),
    shouldCreateReceipt: boolean(),
    paymentDate: string()
      .required()
      .transform((stringDate: string | Nullish) => {
        if (!stringDate) {
          return undefined;
        }
        return formatISO(parseDate(stringDate)?.getTime(), {representation: 'date'});
      })
      .test('mustBePast', i18n.t('general.validations.mustBePastOrPresent'), (stringDate) => {
        if (!stringDate) {
          return true;
        }

        const currentDate = Date.now();
        const date = parseDate(stringDate).getTime();

        return date <= currentDate;
      }),
    cashRegister: yupString.when(['shouldCreateReceipt', 'paymentType'], {
      is: (shouldCreateReceipt: boolean, paymentType: string) =>
        isTrue(shouldCreateReceipt) && paymentType !== 'BANK_TRANSFER',
      then: (schema) => schema.required(),
    }),
    exchangeRateRatio: mixed().when('paymentType', {
      is: (type: string) => type !== 'BANK_TRANSFER' && isForeignCurrencyEnabled,
      then: object({
        amount: string()
          .oneOf(['1', '100', '1000'], i18n.t('general.notifications.ratioAmountError'))
          .nullable(),
        ratio: number().positive().nullable().required(),
      }),
      otherwise: mixed().nullable(),
    }),
  });

export const getPaymentMethodOptions = (isPurchasePayment: boolean) =>
  buildArray([
    {
      label: i18n.t('entity.checkout.labels.paymentMethods.bankTransfer'),
      value: 'BANK_TRANSFER',
    },
    {
      label: i18n.t('entity.checkout.labels.paymentMethods.cash'),
      value: 'CASH',
    },
    {
      label: i18n.t('entity.checkout.labels.paymentMethods.card'),
      value: 'PAYMENT_CARD',
    },
  ]).when(isPurchasePayment, {
    label: i18n.t('entity.invoice.paymentMethod.offset'),
    value: 'OFFSET',
  });
