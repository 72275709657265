import {ButtonProps, Card, showNotification} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {useState} from 'react';

import {head, isNil} from 'ramda';

import {BulkPostSupplierOrderItemsRequest, useBulkPostSupplierOrderItemsMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {FullScreenModal, handleApiError} from '@dms/shared';

import {Nullish, TestIdProps, composePath, suffixTestId, useNavigate} from 'shared';

import {DataGrid, RowData} from 'features/datagrid';

const SUPPLIER_ORDER_PENDING_LIST_EXTERNAL_FILTER_ID =
  'supplier-order-pending-list-external-filter';

interface AddToSupplierOrderModalProps extends TestIdProps {
  itemId: string[];
  onClose: () => void;
}

export function AddToSupplierOrderModal(props: AddToSupplierOrderModalProps) {
  const navigate = useNavigate();

  const [selectedSupplierOrder, setSelectedSupplierOrder] = useState<string | Nullish>(null);

  const [postSupplierOrderItems, {isLoading: isAddingSupplierOrderItems}] =
    useBulkPostSupplierOrderItemsMutation();

  const handleAddItems = async () => {
    if (isNil(selectedSupplierOrder)) {
      return;
    }

    const request: BulkPostSupplierOrderItemsRequest = {
      orderId: selectedSupplierOrder,
      body: {requestItemId: props.itemId, itemState: 'ORDER'},
    };

    await postSupplierOrderItems(request)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('page.warehouse.notification.supplierOrderCreated'));

        navigate(
          composePath(warehouseRoutes.supplierOrderDetailOverview, {
            params: {
              id: selectedSupplierOrder,
            },
          })
        );
      })
      .catch(handleApiError);
  };

  const handleOnRowSelectionChange = (rows: RowData[]) => {
    setSelectedSupplierOrder(head(rows)?.id);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: props.onClose,
      'data-testid': suffixTestId('actions.discard', props),
    },
    {
      type: 'submit',
      title: i18n.t('general.actions.add'),
      isLoading: isAddingSupplierOrderItems,
      onClick: handleAddItems,
      'data-testid': suffixTestId('actions.add', props),
    },
  ];

  return (
    <FullScreenModal headline={i18n.t('general.labels.addToSupplierOrder')} actions={actions}>
      <Box padding={4} height="100%">
        <VStack spacing={4} height="100%">
          {/* Element for DG external filter */}
          {/* eslint-disable-next-line react/forbid-dom-props */}
          <div id={SUPPLIER_ORDER_PENDING_LIST_EXTERNAL_FILTER_ID} />
          <Card title={i18n.t('page.warehouse.labels.supplierOrderList')} isFullHeight>
            <DataGrid
              gridCode="warehouse-supplier-orders-pending"
              onRowSelectionChange={handleOnRowSelectionChange}
              externalFilterId={SUPPLIER_ORDER_PENDING_LIST_EXTERNAL_FILTER_ID}
              autoHeight
              data-testid={suffixTestId('supplierOrderPendingList', props)}
            />
          </Card>
        </VStack>
      </Box>
    </FullScreenModal>
  );
}
