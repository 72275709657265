import {closeDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {isNil} from 'ramda';
import {isArray, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useDeleteServiceOrderItemsMutation,
  usePostServiceItemsMaterialPartIssuedMutation,
  usePostServiceItemsMaterialPartRequestMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError, catchUnhandledDataGridActions} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {BulkAssignMechanicForm} from '../../BulkAssignMechanicForm';
import {BulkDiscountForm} from '../../BulkDiscountForm';
import {GoodwillForm} from '../../Goodwill/GoodwillForm';
import {MoveRequestItem} from '../../MoveRequestItem/MoveRequestItem';
import {
  RequestEditDialogProps,
  RequestEditDialog,
  ReceiveJobItemRowData,
} from '../../RequestEditDialog';
import {WorkSplittingForm} from '../../WorkSplitting/WorkSplittingForm';

interface JobItemsDataGridProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string | Nullish;
  jobId: string | Nullish;
  datagridKey: string | Nullish;
  requestName: string | Nullish;
}

export function JobItemsDataGrid(props: JobItemsDataGridProps) {
  const [deleteServiceOrderItems] = useDeleteServiceOrderItemsMutation();
  const [postServiceItemsMaterialPartRequest] = usePostServiceItemsMaterialPartRequestMutation();
  const [postServiceItemsMaterialPartIssued] = usePostServiceItemsMaterialPartIssuedMutation();

  const [editDialogProps, setEditDialogProps] = useState<RequestEditDialogProps | null>(null);

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      serviceJobId: props.jobId ?? '',
      serviceOrderId: props.orderId ?? '',
      serviceCaseId: props.serviceCaseId,
    }),
    [props.jobId, props.orderId, props.serviceCaseId]
  );

  const actionCallback: ActionCallback = ({
    rowId,
    actionKey,
    rowData,
    refreshData,
    queryId,
    deselectAll,
  }) => {
    const castedRowData = rowData as ReceiveJobItemRowData | ReceiveJobItemRowData[];

    const items = isArray(castedRowData)
      ? castedRowData.map((selectedRow) => ({
          serviceItemId: selectedRow.serviceOrderJobItemId.value,
          serviceJobId: props.jobId || '',
        }))
      : [
          {
            serviceItemId: castedRowData.serviceOrderJobItemId.value,
            serviceJobId: props.jobId || '',
          },
        ];

    const hasSomeGoodwill =
      isArray(castedRowData) &&
      castedRowData.some(
        (data) =>
          isNotNilOrEmpty(data.itemGoodwillRatio.value) &&
          Number(data.itemGoodwillRatio.value?.label) !== 100
      );

    match(actionKey)
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderItems({
              serviceOrderId: props.orderId ?? '',
              serviceCaseId: props.serviceCaseId,
              body: {items},
            })
              .unwrap()
              .then(() => {
                deselectAll();
                refreshData();
              })
              .catch(handleApiError),
        })
      )
      .with('edit', 'redirectDetail', () => {
        if (isArray(castedRowData) || isNil(castedRowData)) {
          return;
        }
        setEditDialogProps({
          gridCode: 'service-case-order-job-items',
          rowId: isArray(rowId) ? rowId[0] : rowId,
          dataQueryId: queryId,
          serviceCaseId: props.serviceCaseId,
          orderId: props.orderId ?? '',
          itemData: castedRowData,
          onAfterSubmit: refreshData,
          onClose: () => setEditDialogProps(null),
          'data-testid': props['data-testid'] as string,
        });
      })
      .with('move', () =>
        openDialog(
          <MoveRequestItem
            onClose={() => closeDialog('moveRequestItem')}
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={String(props.orderId)}
            hiddenServiceJob={props.jobId}
            items={items}
            onMoveItems={refreshData}
            data-testid={suffixTestId('moveItem', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.moveRequestItem'),
            id: 'moveRequestItem',
          }
        )
      )
      .with('goodwill', () =>
        openDialog(
          <GoodwillForm
            onClose={() => closeDialog('goodwill')}
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={String(props.orderId)}
            requestName={props.requestName}
            items={items}
            onSaved={refreshData}
            isWithMultipleGoodwill={hasSomeGoodwill}
            data-testid={suffixTestId('goodwillDialog', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.goodwill'),
            id: 'goodwill',
          }
        )
      )
      .with('workSplitting', () =>
        openDialog(
          <WorkSplittingForm
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('workSplitting')}
            data-testid={suffixTestId('workSplitting', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.workSplitting'),
            id: 'workSplitting',
          }
        )
      )
      .with('request', () =>
        postServiceItemsMaterialPartRequest({
          body: {serviceItems: items},
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.orderId ?? '',
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('issue', () =>
        postServiceItemsMaterialPartIssued({
          body: {serviceItems: items},
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.orderId ?? '',
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('assignMechanic', () =>
        openDialog(
          <BulkAssignMechanicForm
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('assignMechanic')}
            data-testid={suffixTestId('assignMechanic', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.assignMechanic'),
            id: 'assignMechanic',
          }
        )
      )
      .with('discount', () =>
        openDialog(
          <BulkDiscountForm
            serviceCaseId={props.serviceCaseId ?? ''}
            serviceOrderId={props.orderId ?? ''}
            items={items}
            onSubmit={refreshData}
            onClose={() => closeDialog('manualDiscount')}
            data-testid={suffixTestId('manualDiscount', props)}
          />,
          {
            title: i18n.t('entity.orderItem.labels.manualDiscount'),
            id: 'manualDiscount',
            size: 'small',
          }
        )
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <>
      <Show when={isNotNil(editDialogProps)}>
        <RequestEditDialog {...(editDialogProps as RequestEditDialogProps)} />
      </Show>
      <DataGrid
        autoHeight
        gridCode="service-case-order-job-items"
        queryModifier={queryModifier}
        actionCallback={actionCallback}
        data-testid={suffixTestId('dataGrid', props)}
      />
    </>
  );
}
