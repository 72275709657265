import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionType,
  showNotification,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import {array, boolean, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  PatchCustomerContractIndividualPriceMaterialApiArg,
  useGetArticleQuery,
  useGetCustomerContractIndividualPriceMaterialQuery,
  useGetManufacturersQuery,
  useGetTenantQuery,
  useGetWarehousesQuery,
  useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery,
  useLazyGetArticleQuery,
  usePatchCustomerContractIndividualPriceMaterialMutation,
  usePostCustomerContractIndividualPriceMaterialMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  getActiveManufacturerOptions,
  getDecimalFromPercentage,
  getOptionsFromWarehouses,
  getPercentageFromDecimal,
  handleApiError,
} from '@dms/shared';

import {CurrencyCodeType, suffixTestId, TestIdProps, yupNumber, yupString} from 'shared';

import {IndividualMaterialPriceFormType} from '../types/IndividualMaterialPriceFormType';
import {IndividualMaterialPriceAllowedVariant} from './IndividualMaterialPriceAllowedVariant';

interface IndividualMaterialPriceFormProps extends TestIdProps {
  individualPriceMaterialId?: string;
  customerContractId?: string;
  onClose: VoidFunction;
  afterSubmit: VoidFunction;
}

export function IndividualMaterialPriceForm(props: IndividualMaterialPriceFormProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {
    data,
    isLoading: isCustomerContractIndividualPriceMaterialLoading,
    isError: isCustomerContractIndividualPriceMaterialError,
  } = useGetCustomerContractIndividualPriceMaterialQuery(
    {
      customerContractId: props.customerContractId ?? '',
      individualPriceMaterialId: props.individualPriceMaterialId ?? '',
    },
    {skip: isNil(props.customerContractId) || isNil(props.individualPriceMaterialId)}
  );
  const {data: tenant, isLoading: isTenantLoading, isError: isTenantError} = useGetTenantQuery();
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();
  const {
    data: article,
    isLoading: isArticleLoading,
    isError: isArticleError,
  } = useGetArticleQuery(
    {articleId: data?.articleId ?? '', warehouseId: data?.warehouseId ?? ''},
    {skip: isNil(data)}
  );
  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const [getArticleByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();
  const [getArticle] = useLazyGetArticleQuery();

  const [patchIndividualMaterialPrice] = usePatchCustomerContractIndividualPriceMaterialMutation();
  const [postIndividualMaterialPrice] = usePostCustomerContractIndividualPriceMaterialMutation();

  const isLoading =
    isCustomerContractIndividualPriceMaterialLoading ||
    isTenantLoading ||
    isWarehousesLoading ||
    isArticleLoading ||
    isManufacturersLoading;
  const isError =
    isCustomerContractIndividualPriceMaterialError ||
    isTenantError ||
    isWarehousesError ||
    isArticleError ||
    isManufacturersError;

  const handleCheckItem = (formApi: UseFormReturn<IndividualMaterialPriceFormType>) => {
    const warehouseId = formApi.getValues('warehouseId');
    const manufacturerNumber = formApi.getValues('manufacturerNumber');
    const manufacturerId = formApi.getValues('manufacturerId');

    if (isNil(warehouseId)) {
      throw new Error('warehouseId is not defined');
    }

    if (isNil(manufacturerNumber)) {
      throw new Error('manufacturerNumber is not defined');
    }

    if (isNil(manufacturerId)) {
      throw new Error('manufacturerId is not defined');
    }

    getArticleByManufacturerNumberAndManufacturerId({
      manufacturerNumber,
      manufacturerId,
      warehouseId,
    })
      .unwrap()
      .then((result) => {
        if (result?.articleId) {
          showNotification.success(i18n.t('entity.customerContract.labels.articleFound'));
          formApi.clearErrors('manufacturerNumber');

          getArticle({
            articleId: result.articleId,
            warehouseId,
          })
            .unwrap()
            .then((article) => {
              formApi.setValue('name', article.name);
            })
            .catch(handleApiError);
        } else {
          formApi.setError('manufacturerNumber', {
            message: i18n.t('entity.customerContract.labels.articleError'),
          });
        }
      })
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<IndividualMaterialPriceFormType> = async (
    data,
    setErrors
  ) => {
    const articleId = await getArticleByManufacturerNumberAndManufacturerId({
      manufacturerNumber: data.manufacturerNumber ?? '',
      manufacturerId: data.manufacturerId ?? '',
      warehouseId: data.warehouseId ?? '',
    })
      .unwrap()
      .then((result) => {
        if (result?.articleId) {
          return result.articleId;
        } else {
          setErrors([
            {
              name: 'manufacturerNumber',
              message: i18n.t('entity.customerContract.labels.articleError'),
            },
          ]);
        }
      })
      .catch(handleApiError);

    if (!articleId) {
      return;
    }

    const body: PatchCustomerContractIndividualPriceMaterialApiArg['body'] = {
      articleId,
      discountSettingsType: data.discountSettingsType ?? 'DISCOUNT_SALE_PRICE',
      discountSettingsValue:
        data.discountSettingsType !== 'DISCOUNT_FIXED_PRICE' && isNotNil(data.discountSettingsValue)
          ? (getDecimalFromPercentage(data.discountSettingsValue) ?? 0)
          : (data.discountSettingsValue ?? 0),
      isDirectSaleVariant: data.isDirectSaleVariant,
      directSaleVariantIds: data.isDirectSaleVariant
        ? data.directSaleVariantIds?.filter(isNotNil)
        : undefined,
      isServiceOrderVariant: data.isServiceOrderVariant,
      serviceOrderVariantIds: data.isServiceOrderVariant
        ? data.serviceOrderVariantIds?.filter(isNotNil)
        : undefined,
      warehouseId: data.warehouseId ?? '',
    };

    await (
      isNotNil(props.individualPriceMaterialId)
        ? patchIndividualMaterialPrice({
            customerContractId: props.customerContractId ?? '',
            individualPriceMaterialId: props.individualPriceMaterialId ?? '',
            body,
          })
        : postIndividualMaterialPrice({
            customerContractId: props.customerContractId ?? '',
            body,
          })
    )
      .unwrap()
      .then(() => {
        props.onClose();
        props.afterSubmit();
      })
      .catch(handleApiError);
  };

  const activeManufacturerOptions = getActiveManufacturerOptions(manufacturers);
  const warehouseOptions = getOptionsFromWarehouses(warehouses);

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={120}>
      <Form<IndividualMaterialPriceFormType>
        defaultValues={{
          ...data,
          discountSettingsType: data?.discountSettingsType ?? 'DISCOUNT_SALE_PRICE',
          discountSettingsValue:
            isNotNil(data) &&
            data.discountSettingsType !== 'DISCOUNT_FIXED_PRICE' &&
            isNotNil(data.discountSettingsValue)
              ? getPercentageFromDecimal(data.discountSettingsValue)
              : data?.discountSettingsValue,
          isServiceOrderVariant: defaultTo(true)(data?.isServiceOrderVariant),
          isDirectSaleVariant: defaultTo(true)(data?.isDirectSaleVariant),
          name: article?.name,
          manufacturerNumber: article?.manufacturerNumber,
        }}
        schema={formSchema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => {
          const isCheckItemButtonDisabled =
            isNil(formApi.watch('warehouseId')) ||
            isNil(formApi.watch('manufacturerNumber')) ||
            isNil(formApi.watch('manufacturerId'));

          return (
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="manufacturerNumber"
                    type="text"
                    label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                    isRequired
                    data-testid={suffixTestId('manufacturerNumber', props)}
                  />
                </Box>
              </HStack>
              <HStack spacing={4} align="flex-end">
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="warehouseId"
                    type="choice"
                    label={i18n.t('entity.warehouse.labels.warehouse')}
                    isNotClearable
                    isRequired
                    options={warehouseOptions}
                    isLoading={isWarehousesLoading}
                    data-testid={suffixTestId('warehouseId', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isNotClearable
                    isRequired
                    control={control}
                    name="manufacturerId"
                    type="choice"
                    label={i18n.t('entity.warehouse.labels.manufacturer')}
                    options={activeManufacturerOptions}
                    data-testid={suffixTestId('manufacturer', props)}
                  />
                </Box>
              </HStack>
              <HStack spacing={4} align="flex-end">
                <Box flex={1}>
                  <Button
                    title={i18n.t('general.labels.checkItem')}
                    variant="secondary"
                    onClick={() => handleCheckItem(formApi)}
                    isDisabled={isCheckItemButtonDisabled}
                    data-testid={suffixTestId('checkItem', props)}
                  />
                  <Space fillAvailable />
                </Box>
              </HStack>
              <FormField
                control={control}
                name="name"
                type="text"
                label={i18n.t('general.labels.name')}
                isDisabled
                data-testid={suffixTestId('name', props)}
              />

              <IndividualMaterialPriceAllowedVariant
                control={control}
                data-testid={suffixTestId('individualMaterialPriceAllowedVariant', props)}
              />
              <Card
                variant="inlineGrey"
                title={i18n.t('entity.workshopCustomerGroup.labels.discountSettings')}
              >
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="discountSettingsType"
                      type="radio"
                      options={getDiscountOptions(
                        formatCurrencySymbol(tenant?.currency as CurrencyCodeType)
                      )}
                      direction="column"
                      spacing={4}
                      data-testid={suffixTestId('discountSettingsType', props)}
                    />
                    <Space vertical={4} />
                    <FormField
                      control={control}
                      name="discountSettingsValue"
                      type="number"
                      isStepperVisible
                      minStepperValue={0}
                      decimalPlaces={2}
                      helperText={i18n.t('entity.customerContract.labels.discountDescription')}
                      data-testid={suffixTestId('discountSettingsValue', props)}
                    />
                  </Box>
                  <Space fillAvailable />
                </HStack>
              </Card>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  onClick={props.onClose}
                  variant="secondary"
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  control={control}
                  title={
                    isNotNil(props.individualPriceMaterialId)
                      ? i18n.t('general.actions.saveChanges')
                      : i18n.t('general.actions.create')
                  }
                  type="submit"
                  data-testid={suffixTestId('submit', props)}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const formSchema = object({
  warehouseId: yupString.required(),
  manufacturerNumber: yupString.required(),
  manufacturerId: yupString.required(),
  serviceOrderVariantIds: array().of(yupString).optional(),
  discountSettingsValue: yupNumber.when('discountSettingsType', {
    is: 'DISCOUNT_FIXED_PRICE',
    then: yupNumber.min(0).required(),
    otherwise: yupNumber.min(0).max(100).required(),
  }),
  isServiceOrderVariant: boolean()
    .required()
    .test(
      'atLeastOneTrueServiceOrderVariant',
      i18n.t('entity.customerContract.labels.variantValidation'),
      (value, context) => value || context.parent.isDirectSaleVariant
    ),
  isDirectSaleVariant: boolean()
    .required()
    .test(
      'atLeastOneTrueDirectSaleVariant',
      i18n.t('entity.customerContract.labels.variantValidation'),
      (value, context) => value || context.parent.isServiceOrderVariant
    ),
});

const getDiscountOptions = (
  currency: string
): OptionType<IndividualMaterialPriceFormType['discountSettingsType']>[] => [
  {
    value: 'DISCOUNT_SALE_PRICE',
    label: i18n.t('entity.customerContract.labels.discountOnSalePrice'),
  },
  {
    value: 'DISCOUNT_MARGIN_PURCHASE_PRICE',
    label: i18n.t('entity.customerContract.labels.marginToPurchasePrice', {currency}),
  },
  {
    value: 'DISCOUNT_FIXED_PRICE',
    label: i18n.t('entity.customerContract.labels.fixedPrice', {currency}),
  },
];
