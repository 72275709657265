import {NumberFormatter} from 'platform/locale';

import {append, curry, ifElse, includes, without} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {CURRENCY_CZK, downloadFile} from '@dms/shared';

import {CURRENCY_HUF} from '../types/CommonAppTypes';

export const noop = (..._args: any[]): any => {};

export const openInNew = (url: string) => {
  const win = window.open(url, '_blank');

  win?.focus();
};

export const afterLastSlash = (pathname: string | number | symbol) => {
  const stringPathName = String(pathname);
  const slashPosition = stringPathName.lastIndexOf('/') + 1;
  return stringPathName.substring(slashPosition);
};

export type NumberNotationFormattingOptions = {
  undefinedResult?: string;
  forceThousands?: boolean;
  keepThousands?: boolean;
};

export const formatNumberNotation = (
  formatNumber: NumberFormatter,
  currency: string,
  num: null | number,
  {undefinedResult, forceThousands, keepThousands}: NumberNotationFormattingOptions = {
    undefinedResult: undefined,
    forceThousands: false,
    keepThousands: false,
  }
) => {
  const formatToThousands =
    !keepThousands && (forceThousands || [CURRENCY_CZK, CURRENCY_HUF].includes(currency));

  if (typeof num !== 'number') {
    return undefinedResult;
  }

  const numRounded = Math.round(num);
  const numToDisplay = formatToThousands ? Math.round(num / 1000) : numRounded;
  const result = formatNumber(numToDisplay, 0) ?? '';

  return formatToThousands ? `${result}k` : `${result}`;
};

// number 0.7999 should be desplayed like 79%
export const decimalToPercentRank = (num?: number) =>
  isNotNil(num) ? `${Math.floor(num * 100)}%` : 'N/A';

export const downloadCsv = (urlData: string, fileName: string) =>
  downloadFile(`data:text/csv;charset=UTF-8,\uFEFF${encodeURIComponent(urlData)}`, {fileName});

export const findByKey = <U extends Record<string, unknown>, K extends keyof U>(
  coll: U[],
  key: K,
  value: U[K]
): U | null => {
  if (!(coll && Array.isArray(coll))) {
    return null;
  }
  return coll.find((item) => item[key] === value) ?? null;
};

// Helper function to toggle item into list
export const toggleListItem = curry((value, list) =>
  ifElse(includes(value), without([value]), append(value))(list)
);
