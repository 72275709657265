import {Action, Attributes, Card, Flag, openDialog, showNotification} from 'platform/components';

import {isTrue} from 'ramda-adjunct';

import {
  PairedDeviceResponseBody,
  useGetBranchListQuery,
  useGetEsignoServiceQuery,
  useUnpairDeviceMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {getDeviceStateFlagProps, handleApiError} from '@dms/shared';

import {buildArray, RequiredTestIdProps} from 'shared';

import {PairDeviceStep} from '../types/PairDeviceStep';
import {getBranchesNames} from '../utils/getBranchesNames';
import {PairDeviceStepper} from './pairDeviceStepper/PairDeviceStepper';

interface DeviceAttributesProps extends RequiredTestIdProps {
  device: PairedDeviceResponseBody;
}

export function DeviceAttributes(props: DeviceAttributesProps) {
  const {data: branches} = useGetBranchListQuery();
  const [unpairDevice] = useUnpairDeviceMutation();

  const {data: esignoService} = useGetEsignoServiceQuery();
  const isEsignoEnabled = isTrue(esignoService?.enabled);

  const openPairDeviceDialog = (state: PairDeviceStep, pairedDeviceId?: string) =>
    openDialog(
      <PairDeviceStepper
        state={state}
        pairedDeviceId={pairedDeviceId}
        data-testid={testIds.settings.signatures('pairDevice')}
      />,
      {
        title:
          state === 'paired'
            ? i18n.t('entity.settings.labels.editDevice')
            : i18n.t('entity.settings.labels.addDevice'),
      }
    );

  const handleUnpairDevice = (pairedDeviceId: string) =>
    unpairDevice({pairedDeviceId})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
      .catch(handleApiError);

  return (
    <Card
      variant="inlineGrey"
      key={props.device.pairedDeviceId}
      title={props.device.name}
      actions={buildArray<Action>()
        .when(
          props.device.state !== 'inactive' && isEsignoEnabled,

          {
            type: 'button',
            variant: 'link',
            title: i18n.t('general.actions.edit'),
            onClick: () => openPairDeviceDialog(props.device.state, props.device.pairedDeviceId),
          }
        )
        .when(isEsignoEnabled, {
          type: 'button',
          variant: 'dangerLink',
          title: i18n.t('general.actions.remove'),
          onClick: () => handleUnpairDevice(props.device.pairedDeviceId),
        })}
    >
      <Attributes
        size="quarter"
        data-testid={testIds.settings.signatures(`${props.device.name}-attributes`)}
        rows={[
          {
            label: i18n.t('general.labels.status'),
            content: (
              <Flag
                {...getDeviceStateFlagProps(props.device.state)}
                isSubtle
                data-testid={testIds.settings.signatures(`${props.device.name}-state`)}
              />
            ),
          },
          {
            label: i18n.t('entity.branch.labels.branches'),
            value: getBranchesNames(branches, props.device.branches),
          },
        ]}
      />
    </Card>
  );
}
