import {AnimatePresence, motion} from 'framer-motion';
import {IconButton, Separator} from 'platform/components';
import {getCssSize, Heading, HStack, Space, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

import {AclDebugCard} from './components/AclDebugCard';
import {ApiValidation} from './components/ApiValidation';
import {CacheCleanup} from './components/CacheCleanup';
import {ContentEditable} from './components/ContentEditable';
import {DevSettingsHeaderButton} from './components/DevSettingsHeaderButton';
import {FeatureFlagsMock} from './components/FeatureFlagsMock';
import {TranslationDebug} from './components/TranslationDebug';
import {useDeveloperSettings} from './hooks/DeveloperSettingsProvider';
import {getFixedTranslation} from './utils/getFixedTranslation';

interface DevSettingsProps extends TestIdProps {}

export function DevSettingsSidebar(props: DevSettingsProps) {
  const theme = useTheme();

  const {open, isOpen, close, isAnyDevSettingEnabled} = useDeveloperSettings();

  return (
    <>
      <DevSettingsHeaderButton
        isOpen={isOpen}
        onClick={open}
        isAnyDevSettingEnabled={isAnyDevSettingEnabled}
        data-testid={props['data-testid']}
      />

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              key="modal-background"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.3}}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: theme.zIndices.MODAL,
              }}
              onClick={close}
            />

            <motion.div
              key="modal-content"
              initial={{x: '100%'}}
              animate={{x: '0%'}}
              exit={{x: '100%'}}
              transition={{duration: 0.3}}
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                maxWidth: '80vw',
                width: getCssSize(300),
                height: '100vh',
                backgroundColor: 'white',
                zIndex: theme.zIndices.MODAL_COMPONENT,
                boxShadow: '-5px 0px 15px rgba(0, 0, 0, 0.2)',
                padding: '20px',
              }}
            >
              <HStack justify="space-between" align="center">
                <Heading data-testid={suffixTestId('devSettings-activeBranch', props)} size={2}>
                  {getFixedTranslation('page.settings.labels.devSettings')}
                </Heading>

                <IconButton icon="content/clear" onClick={close} priority="secondary" />
              </HStack>

              <Separator />

              <HStack spacing={2}>
                <TranslationDebug />
                <ContentEditable />
                <CacheCleanup />
              </HStack>

              <Space vertical={4} />

              <VStack spacing={2}>
                <FeatureFlagsMock />
                <AclDebugCard />
                <ApiValidation />
              </VStack>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
