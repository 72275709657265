import {Separator, Actions, Action} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {Nullish, TestIdProps} from 'shared';

export interface MyProfileFooterProps extends TestIdProps {
  actions: Action[] | Nullish;
}

export function MyProfileFooter(props: MyProfileFooterProps) {
  return (
    <Box position="absolute" bottom={0} left={0} right={0}>
      <VStack>
        <Separator spacing={0} />
        <Box padding={6}>
          <Actions actions={props.actions} align="right" />
        </Box>
      </VStack>
    </Box>
  );
}
