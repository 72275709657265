import {isFeatureEnabled} from 'feature-flags';
import {Parameter} from 'platform/components';
import {useDateTimeFormatter, useFormatNumber} from 'platform/locale';

import {FullVehicleResponseBody, GetMetadaServiceCaseApiResponse} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import {useGetVehicleHeaderV2} from '@dms/shared';

import {Nullish, buildArray, parseDate} from 'shared';

export function useServiceCaseVehicleDraftParameters(
  serviceCaseId: string | Nullish,
  serviceCase: GetMetadaServiceCaseApiResponse | Nullish,
  vehicle: FullVehicleResponseBody | Nullish
) {
  const formatDateTime = useDateTimeFormatter();
  const formatNumber = useFormatNumber();

  const newVehicleHeader = useGetVehicleHeaderV2({
    serviceCaseNumber: serviceCase?.number,
    publicId: vehicle?.publicId,
    vin: vehicle?.vehicle.vin,
    registrationPlate: vehicle?.vehicle.registrationPlate,
    firstRegistrationOn: vehicle?.vehicle.firstRegistrationOn,
    warrantyDate: vehicle?.vehicle.warrantyDate,
    isVehicleDetail: false,
  });

  if (isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
    return newVehicleHeader;
  }

  return buildArray<Parameter>()
    .when(serviceCaseId, serviceCase?.number)
    .when(serviceCaseId && vehicle?.vin, vehicle?.vin)
    .when(serviceCaseId && vehicle?.vehicle.registrationPlate, vehicle?.vehicle.registrationPlate)
    .when(serviceCaseId && vehicle?.vehicle.firstRegistrationOn, () =>
      formatDateTime('dateShort', parseDate(vehicle?.vehicle.firstRegistrationOn ?? ''))
    )
    .when(serviceCaseId && vehicle?.modelSpecification?.warrantyDate, () =>
      formatDateTime('dateShort', parseDate(vehicle?.modelSpecification?.warrantyDate ?? ''))
    )
    .when(
      serviceCaseId && vehicle?.modelSpecification?.warrantyMileage,
      formatNumber(vehicle?.modelSpecification?.warrantyMileage ?? 0)
    );
}
