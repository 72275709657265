import {Checkbox} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';
import {isBoolean, isTrue} from 'ramda-adjunct';

import {useGetCustomerV2Query, useGetTenantQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {getCustomFieldPayload, getFormAdditionalField} from '@dms/shared';

import {AccountingDocumentFormValues} from '../types';

interface AffiliatedEntityCheckboxProps {
  customerId: string | undefined;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  contractInformationId: string | undefined;
}

export function AffiliatedEntityCheckbox(props: AffiliatedEntityCheckboxProps) {
  const {data: tenant} = useGetTenantQuery();
  const {data: customer} = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {skip: isNil(props.customerId)}
  );

  const isPolandTenant = tenant?.country === 'POL';
  const formAffiliatedEntity = getFormAdditionalField(props.formApi.watch('customFieldsPayload'));
  const selectedContractInformation = customer?.contractInformation.find(
    (info) => info.id === props.contractInformationId
  );
  const customerCustomFields = getFormAdditionalField(
    selectedContractInformation?.customFieldsPayload
  );

  const initialAffiliatedEntity = isTrue(customerCustomFields?.affiliatedEntity);
  const formAffiliatedEntityValue = formAffiliatedEntity?.affiliatedEntity;
  const value = isBoolean(formAffiliatedEntityValue)
    ? formAffiliatedEntityValue
    : initialAffiliatedEntity;

  const handleCheckBoxOnChange = (value: boolean) =>
    props.formApi.setValue('customFieldsPayload', getCustomFieldPayload({affiliatedEntity: value}));

  if (!isPolandTenant) {
    return null;
  }

  return (
    <Checkbox
      value={value}
      onChange={handleCheckBoxOnChange}
      label={i18n.t('general.labels.affiliatedEntity')}
    />
  );
}
