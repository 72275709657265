import {mapObjIndexed} from 'ramda';
import {isPlainObj} from 'ramda-adjunct';

import {CentralizedPrice, PutPurchaseVehiclePricingCentralizedApiArg} from '@dms/api';

import {NullishBoolean} from 'shared';

import {$VatDeductibleSchema, $VatNotDeductibleSchema} from './$PricingFormSchema';

type NestedValuesType = {amount: string; currency: string} | null | string;
type ValueType =
  PutPurchaseVehiclePricingCentralizedApiArg[keyof PutPurchaseVehiclePricingCentralizedApiArg];

export const addCurrencyToAmount = (value: ValueType, currency: string) => {
  if (isPlainObj(value)) {
    return mapObjIndexed<NestedValuesType, NestedValuesType, keyof CentralizedPrice>((val) =>
      isPlainObj(val) && val.amount ? {amount: val.amount.toString(), currency} : null
    )(value);
  }

  return value;
};

export const getPricingFormScheme = (isVatDeductible: NullishBoolean) =>
  isVatDeductible ? $VatDeductibleSchema : $VatNotDeductibleSchema;
