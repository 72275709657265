import {Card, DataStatus} from 'platform/components';
import {Box, Hide, HStack, Icon, Image, Show, Space, Text} from 'platform/foundation';

import {ReactElement} from 'react';
import {Link} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  PlatformCode,
  useGetPlatformsQuery,
  useIsPublishingMultiBranchEnabledQuery,
  useUpdatePlatformMutation,
} from '@dms/api';
import featureFlags from '@dms/feature-flags';
import {settingsRoutes, testIds} from '@dms/routes';
import {fakePlatforms, platformImageURLs} from '@dms/shared';
import {Switcher} from '@dms/teas';

import {composePath} from 'shared';

import {useBranchId} from '../../../hooks/useBranchId';
import {SwitchWrapper} from '../../VehicleServices/components/styles';
import {AutomaticUpdateControl} from './AutomaticUpdateControl';
import {MultiBranchControl} from './MultiBranchControl';

export function GeneralAdvertisementPlatforms() {
  const branchId = useBranchId();
  const {data: isMultiBranch} = useIsPublishingMultiBranchEnabledQuery();
  const isSettingsSynchronised = isMultiBranch === isNotNil(branchId);

  const {data: platforms, isLoading} = useGetPlatformsQuery(
    {branchId},
    {skip: !isSettingsSynchronised}
  );

  const [updateStatus] = useUpdatePlatformMutation();

  const platformLink = (code: string) =>
    composePath(settingsRoutes.advertisingPlatformsDetail, {
      params: {code},
    });

  const items = platforms?.map(
    (item, index): ReactElement => (
      <Link
        key={item.code}
        to={platformLink(item.code)}
        data-testid={testIds.settings.advertisingPlatforms(`link-platform-${item.code}`)}
      >
        <Box
          height={10}
          paddingHorizontal={4}
          borderTop={index > 0 ? '1px solid' : 'none'}
          borderColor="palettes.neutral.40.100"
        >
          <HStack height="100%" align="center">
            <SwitchWrapper
              onClick={(e) => e.stopPropagation()}
              data-testid={testIds.settings.advertisingPlatforms(`switchWrapper-${item.code}`)}
            >
              <Switcher
                name={`platform-${item.code}`}
                value={item.enabled}
                disabled={!item?.hasCredentials}
                onChange={(value) => {
                  updateStatus({
                    platformCode: item.code as PlatformCode,
                    enabled: value,
                    branchId,
                  });
                }}
              />
            </SwitchWrapper>

            <Hide when={isNil(platformImageURLs[item.code])}>
              <Image
                src={platformImageURLs[item.code]}
                alt={item.title}
                height={4}
                data-testid={testIds.settings.advertisingPlatforms(`logo-${item.code}`)}
              />
            </Hide>
            <Show when={isNil(platformImageURLs[item.code])}>
              <Text data-testid={testIds.settings.advertisingPlatforms(`logo-${item.code}`)}>
                {item.title}
              </Text>
            </Show>

            <Space fillAvailable />
            <Icon value="navigation/chevron_right" />
          </HStack>
        </Box>
      </Link>
    )
  );

  // TODO T20-40939 remove
  const fakeItems = fakePlatforms.map(({code, title}) => (
    <Link
      key={code}
      to={platformLink(code)}
      data-testid={testIds.settings.advertisingPlatforms(`link-platform-${code}`)}
    >
      <Box
        height={10}
        paddingHorizontal={4}
        borderTop="1px solid"
        borderColor="palettes.neutral.40.100"
      >
        <HStack height="100%" align="center">
          <SwitchWrapper
            onClick={(e) => e.stopPropagation()}
            data-testid={testIds.settings.advertisingPlatforms(`switchWrapper-${code}`)}
          >
            <Switcher name={`platform-${code}`} value={false} disabled={true} onChange={() => {}} />
          </SwitchWrapper>

          <Hide when={isNil(platformImageURLs[code])}>
            <Image
              src={platformImageURLs[code]}
              alt={title}
              height={4}
              data-testid={testIds.settings.advertisingPlatforms(`logo-${code}`)}
            />
          </Hide>
          <Show when={isNil(platformImageURLs[code])}>
            <Text data-testid={testIds.settings.advertisingPlatforms(`logo-${code}`)}>{title}</Text>
          </Show>

          <Space fillAvailable />
          <Icon value="navigation/chevron_right" />
        </HStack>
      </Box>
    </Link>
  ));

  return (
    <>
      <Show whenFeatureEnabled={featureFlags.SETTINGS_ADVERTISEMENT_AUTOMATIC_UPDATE}>
        <AutomaticUpdateControl
          data-testid={testIds.settings.advertisingPlatforms('automaticUpdateControl')}
        />
        <Space vertical={4} />
      </Show>
      <Show whenFeatureEnabled={featureFlags.SETTINGS_ADVERTISEMENT_MULTIBRANCH}>
        <MultiBranchControl />
        <Space vertical={4} />
      </Show>
      <Card>
        <DataStatus isLoading={isLoading}>
          {items}
          <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_FAKE_PLATFORMS}>{fakeItems}</Show>
        </DataStatus>
      </Card>
    </>
  );
}
