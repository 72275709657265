import {Actions} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

export interface OpenXentryLinkProps extends TestIdProps {
  xentryLink: string | Nullish;
  onClose: () => void;
}

export function OpenXentryLink(props: OpenXentryLinkProps) {
  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('entity.addMaterial.lables.openXentryLink')}</Heading>
      <Actions
        align="right"
        actions={[
          {
            type: 'button',
            title: i18n.t('general.actions.discard'),
            variant: 'secondary',
            onClick: props.onClose,
          },
          {
            type: 'button',
            leftIcon: 'action/open_in_new',
            title: i18n.t('entity.addMaterial.actions.openLink'),
            variant: 'primary',
            isDisabled: isNil(props.xentryLink),
            onClick: () => props.xentryLink && window.open(props.xentryLink, '_blank'),
          },
        ]}
        data-testid={suffixTestId('actions', props)}
      />
    </VStack>
  );
}
