import {Button, ButtonGroup, closeDialog, Form, FormField, Label} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {VectorType} from '../types/MultipleLocationsForm';
import {SingleLocationFormValue} from '../types/SingleLocationFormValue';
import {singleLocationSchema} from '../utils/singleLocationSchema';
import {SimpleVectorsArray} from './SimpleVectorsArray';

export interface SingleLocationFormProps {
  dialogId: string;
  isLoading: boolean;
  isRowCheckEnabled?: boolean;
  defaultValues?: SingleLocationFormValue;
  onFormSubmit: (formData: SingleLocationFormValue) => Promise<void>;
}

export function SingleLocationForm(props: SingleLocationFormProps) {
  const getDefaultValue = (): SingleLocationFormValue => {
    if (props.defaultValues) {
      return props.defaultValues;
    }

    return {
      storageLocation: [
        {
          isSelected: true,
          vectorType: VectorType.Aisle,
          vectorPosition: 1,
          symbol: '',
        },
        {
          isSelected: true,
          vectorType: VectorType.Rack,
          vectorPosition: 2,
          symbol: '',
        },
        {
          isSelected: true,
          vectorType: VectorType.Level,
          vectorPosition: 3,
          symbol: '',
        },
        {
          isSelected: true,
          vectorType: VectorType.Position,
          vectorPosition: 4,
          symbol: '',
        },
        {
          isSelected: true,
          vectorType: VectorType.Other,
          vectorPosition: 5,
          symbol: '',
        },
      ],
      note: '',
    };
  };

  return (
    <Form<SingleLocationFormValue>
      defaultValues={getDefaultValue()}
      onSubmit={props.onFormSubmit}
      schema={singleLocationSchema}
      mode="onChange"
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <VStack spacing={1}>
            <HStack spacing={4}>
              <Show when={props.isRowCheckEnabled}>
                <Box width={5} />
              </Show>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.vector.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.vector')}
                </Label>
              </Box>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.symbol.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.symbol')}
                </Label>
              </Box>
            </HStack>
            <SimpleVectorsArray
              control={control}
              formApi={formApi}
              isSelectVisible={props.isRowCheckEnabled ?? false}
            />
          </VStack>
          <FormField
            type="textarea"
            name="note"
            control={control}
            label={i18n.t('general.labels.note')}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.cancel')}
              variant="secondary"
              onClick={() => closeDialog(props.dialogId)}
              isDisabled={props.isLoading}
            />
            <Button
              title={i18n.t('general.actions.save')}
              variant="primary"
              type="submit"
              isLoading={props.isLoading}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
