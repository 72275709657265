import {testIds} from '@dms/routes';

import {DataGrid} from 'features/datagrid';

import {useVehiclesActionCallback} from '../../hooks/useVehiclesActionCallback';

export function AdvertisedVehicles() {
  const [actionCallback] = useVehiclesActionCallback();

  return (
    <DataGrid
      gridCode="vehicles-in-advertisement"
      data-testid={testIds.vehicles.home('advertisement')}
      // eslint-disable-next-line no-restricted-syntax
      key="vehicles-in-advertisement"
      actionCallback={actionCallback}
    />
  );
}
