import {Card, DataStatus, Separator} from 'platform/components';
import {Box, Grid, Show} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetCustomerContractLabourDiscountsQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import {PriceSummaryItem} from '../../PriceSummaryItem/PriceSummaryItem';
import {SummaryItem} from '../../SummaryItem/SummaryItem';

interface ContractWorkDiscountsTabProps extends TestIdProps {
  customerContractId: string;
}

export function ContractWorkDiscountsTab(props: ContractWorkDiscountsTabProps) {
  const formatPercentage = useFormatPercentage();

  const {data, isLoading, isError} = useGetCustomerContractLabourDiscountsQuery({
    customerContractId: props.customerContractId,
  });

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={isNilOrEmpty(data?.labourDiscount)}
      minHeight={50}
    >
      <Card title={i18n.t('entity.customerContract.labels.workDiscounts')}>
        {data?.labourDiscount?.map((discount, index) => (
          <Box key={discount?.id}>
            <Show when={index > 0}>
              <Separator />
            </Show>
            <Grid columns={4}>
              <SummaryItem
                isAlternative
                title={i18n.t('general.labels.name')}
                value={discount?.name}
                data-testid={suffixTestId('name', props)}
              />
              <SummaryItem
                isAlternative
                title={i18n.t('entity.customerContract.labels.discountType')}
                value={discount?.discountSettingsType?.name}
                data-testid={suffixTestId('discountType', props)}
              />
              <SummaryItem
                isAlternative
                title={i18n.t('entity.addWork.lables.workCategory')}
                value={discount?.workTypes?.map((workType) => workType?.name).join(', ')}
                data-testid={suffixTestId('workCategory', props)}
              />
              <Show when={isNotNil(discount?.discountedRate)}>
                <PriceSummaryItem
                  title={i18n.t('general.labels.rate')}
                  price={discount?.discountedRate?.amount}
                  currency={discount?.discountedRate?.currency}
                  data-testid={suffixTestId('discountedRate', props)}
                />
              </Show>
              <Show when={isNotNil(discount?.percentageDiscount)}>
                <SummaryItem
                  title={i18n.t('general.labels.discount')}
                  value={formatPercentage((discount?.percentageDiscount ?? 0) / 100)}
                  data-testid={suffixTestId('percentageDiscount', props)}
                />
              </Show>
            </Grid>
          </Box>
        ))}
      </Card>
    </DataStatus>
  );
}
