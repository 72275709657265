import {AttributesRow} from 'platform/components';
import {formatPhoneNumber, useDateTimeFormatter} from 'platform/locale';

import {isFalsy, isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {PersonResponseBodyV2} from '@dms/api';
import i18n from '@dms/i18n';

import {buildArray, parseDate} from 'shared';

import {getNaturalPersonFullName} from '../utils/getNaturalPersonFullName';
import {useAddress} from './useAddress';
import {useCountry} from './useCountry';
import {useGender} from './useGender';
import {useIdentityCard} from './useIdentityCard';

type AttributesOptions = {
  isRoleHidden?: boolean;
  isEmailsHidden?: boolean;
  isPhoneNumbersHidden?: boolean;
  isPersonalIdentifierHidden?: boolean;
};

export function useCustomerPersonAttributes(displayAftersalesVersion?: boolean) {
  const formatDateTime = useDateTimeFormatter();

  const {composeAddress} = useAddress();
  const {getCountryName} = useCountry();
  const {composeIdentityCard} = useIdentityCard();
  const {getGenderName} = useGender();

  const getAttributes = (contact: PersonResponseBodyV2, options?: AttributesOptions) => {
    const person = buildArray<AttributesRow>()
      .add({
        label: i18n.t('entity.person.labels.fullName'),
        value: getNaturalPersonFullName(contact),
      })
      .when(isString(contact?.roles) && isFalsy(options?.isRoleHidden), {
        label: i18n.t('entity.person.labels.role'),
        value: contact.roles,
      })
      .when(isFalsy(displayAftersalesVersion), {
        label: i18n.t('entity.person.labels.genderKey'),
        value: getGenderName(contact?.genderKey),
      })
      .when(isFalsy(displayAftersalesVersion), {
        label: i18n.t('entity.person.labels.citizenshipCode'),
        value: getCountryName(contact?.citizenshipCode),
      })
      .add({
        label: i18n.t('entity.person.labels.birthdate'),
        value: isNotNilOrEmpty(contact?.birthdate)
          ? formatDateTime('dateShort', parseDate(contact.birthdate!))
          : null,
      })
      .when(isFalsy(options?.isPersonalIdentifierHidden), {
        label: i18n.t('entity.person.labels.personalIdentifier'),
        value: contact?.personalIdentifier,
      });

    isFalsy(options?.isPhoneNumbersHidden) &&
      person.whenNot(contact?.phoneNumbers?.length, {
        label: i18n.t('entity.phoneNumber.labels.number'),
        value: null,
      });

    isFalsy(options?.isPhoneNumbersHidden) &&
      contact?.phoneNumbers?.forEach((item) => {
        person.add({
          label: `${i18n.t('entity.phoneNumber.labels.number')} ${
            isNotNilOrEmpty(item.type) ? `(${item.type})` : ''
          }`,
          value: formatPhoneNumber(`${item.prefix}${item.number}`),
        });
      });

    isFalsy(options?.isEmailsHidden) &&
      person.whenNot(contact?.emails?.length, {
        label: i18n.t('entity.email.labels.email'),
        value: null,
      });

    isFalsy(options?.isEmailsHidden) &&
      contact?.emails?.forEach((item) => {
        person.add({
          label: `${i18n.t('entity.email.labels.email')} ${
            isNotNilOrEmpty(item.type) ? `(${item.type})` : ''
          }`,
          value: item.email,
        });
      });

    person.add({
      label: `${i18n.t('entity.address.labels.fullAddress')} ${
        isNotNilOrEmpty(contact?.permanentAddress?.type)
          ? `(${contact!.permanentAddress!.type})`
          : ''
      }`,
      value: composeAddress(contact?.permanentAddress?.address),
    });

    if (isFalsy(displayAftersalesVersion)) {
      person.whenNot(contact?.identityCards?.length, {
        label: i18n.t('entity.identityCard.labels.fullIdentityCard'),
        value: null,
      });

      contact?.identityCards?.forEach((item) => {
        person.add({
          label: i18n.t('entity.identityCard.labels.fullIdentityCard'),
          value: composeIdentityCard(item),
        });
      });
    }

    return person;
  };

  return [getAttributes];
}
