import validatePhone from 'phone';
import {
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  openDialog,
  Separator,
} from 'platform/components';
import {Align, Box, Grid, Heading, HStack, Show, VStack} from 'platform/foundation';
import * as Yup from 'yup';
import {boolean, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {eqProps, find, lensPath, map, not, pipe, set} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CompGetInfoV3ApiResponse,
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  CreateCustomerAddressV2ApiResponse,
  CustomerRequestBody,
  CustomerResponseBodyV2,
  EmailData,
  PhoneNumberData,
  PhoneNumber,
  CountryAlpha3CodeEnum,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {Nullish, suffixTestId, TestIdProps, yupString} from 'shared';

import {useAddress} from '../../hooks/useAddress';
import {useCustomerAddress} from '../../hooks/useCustomerAddress';
import {useCustomerGroups} from '../../hooks/useCustomerGroups';
import {useGender} from '../../hooks/useGender';
import {useParsePersonalId} from '../../hooks/useParsePersonalId';
import {useTenant} from '../../hooks/useTenant';
import {getCustomerDefaultValues} from '../../utils/getCustomerDefaultValues';
import {isPolishTenant} from '../../utils/isPolishTenant';
import {InternationalAddressForm} from '../AddressForm/InternationalAddressForm';
import {CountrySelect} from '../CountrySelect/CountrySelect';
import {DecodeBusinessIdButton} from '../DecodeBusinessIdButton/DecodeBusinessIdButton';
import {EmailList} from '../EmailList/EmailList';
import {IdentityCardList} from '../IdentityCardList/IdentityCardList';
import {PhoneNumberList} from '../PhoneNumberList/PhoneNumberList';

interface EditPhysicalPersonFormProps extends TestIdProps {
  isLoading?: boolean;
  customer: CustomerResponseBodyV2;
  onSubmit: FormSubmitHandler<CustomerRequestBody>;
  onClose?: () => void;
}

export function EditPhysicalPersonForm(props: EditPhysicalPersonFormProps) {
  const {
    addressOptions,
    openCreateAddressDialog,
    openEditAddressDialog,
    createCustomerAddress,
    isCreateCustomerAddressLoading,
  } = useCustomerAddress(props.customer.id);
  const {customerGroupOptions} = useCustomerGroups();
  const {genderOptions} = useGender();

  const parsePersonalId = useParsePersonalId();
  const {composeAddress} = useAddress();

  const handleDecodeBusinessIdForm =
    (formApi: UseFormReturn<CustomerRequestBody>) => (company: CompGetInfoV3ApiResponse) => {
      const prepareAddressesStructure = (a: AddressResponseBodyV2) => ({
        id: a.id,
        address: composeAddress(a.address),
      });

      const compareWhetherAddressAlreadyExists = (address: {id: string; address: string | null}) =>
        eqProps('address', address, company?.[0]);

      const findCustomerAddress = pipe(
        map(prepareAddressesStructure),
        find(compareWhetherAddressAlreadyExists)
      )(props.customer?.addresses ?? []);

      const hasExistingAddress = isNotNilOrEmpty(findCustomerAddress);

      /**
       * if the decoded address does not exist in the customer entity,
       * offer the user to add the address to their existing
       */
      if (not(hasExistingAddress)) {
        const addressData: AddressRequestBodyV2 = {
          address: {
            street: company?.[0]?.street ?? '',
            descriptiveNumber: company?.[0]?.descriptiveNumber ?? null,
            orientationNumber: company?.[0]?.orientationNumber ?? null,
            city: company?.[0]?.city ?? '',
            zip: company?.[0]?.zipCode ?? '',
            state: company?.[0]?.state,
            country: company?.[0]?.country ?? '',
            addressComplement: null,
            prefix: company?.[0]?.prefix ?? null,
            district: null,
          },
          type: i18n.t('entity.person.permanentAddressId'),
          invalid: null,
        };

        openDialog(
          <InternationalAddressForm
            data-testid={testIds.customer.detail('decode-dialog')}
            isLoading={isCreateCustomerAddressLoading}
            address={addressData}
            onSubmit={createCustomerAddress((address) => {
              formApi?.setValue('selfEmployedBusinessAddressId', address.id);
              formApi?.setValue('foundingPerson.permanentAddressId', address.id);
            })}
          />,
          {
            'data-testid': testIds.customer.detail('decode-dialog'),
            title: i18n.t('entity.customer.labels.wouldYouLikeToAddNewAddress'),
            withAdditionalFooter: true,
            scrollBehavior: 'outside',
          }
        );
      }
      /**
       * if the address exists in the customer entity, set its default value in the form
       */
      if (hasExistingAddress && findCustomerAddress?.id) {
        formApi.setValue('selfEmployedBusinessAddressId', findCustomerAddress.id);
      }

      const personData = {
        firstName: company?.[0]?.personSelfEmployee?.firstName ?? null,
        lastName: company?.[0]?.personSelfEmployee?.lastName ?? null,
      };
      const isPolish = isPolishTenant(company?.[0]?.country as CountryAlpha3CodeEnum);
      if (isPolish) {
        set(lensPath(['foundingPerson', 'personData']), personData);
      }

      pipe(
        set(lensPath(['selfEmployedBusinessInfoData']), {
          countryOfRegistrationCode: company?.[0]?.country ?? null,
          registrationNumber: company?.[0]?.businessId,
          vatNumber: company?.[0]?.taxId ?? null,
          fileNumber: company?.[0]?.fileNumber ?? null,
          tradeName: company?.[0]?.businessName ?? null,
        }),
        formApi.reset
      )(formApi.getValues());
    };

  const onCreateAddress =
    (formApi: UseFormReturn<CustomerRequestBody>) =>
    (address: CreateCustomerAddressV2ApiResponse) =>
      formApi.setValue('foundingPerson.permanentAddressId', address.id);

  const onCreateSelfEmployedBusinessAddress =
    (formApi: UseFormReturn<CustomerRequestBody>) =>
    (address: CreateCustomerAddressV2ApiResponse) =>
      formApi.setValue('selfEmployedBusinessAddressId', address.id);

  const onSubmit: FormSubmitHandler<CustomerRequestBody> = async (values, a, b) => {
    await schema.validate(values);
    await props.onSubmit(values, a, b);
  };

  const {tenantPhoneInfo} = useTenant();

  return (
    <Form<CustomerRequestBody>
      onSubmit={onSubmit}
      defaultValues={getCustomerDefaultValues(props.customer, tenantPhoneInfo)}
      schema={schema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="foundingPerson.personData.titleBefore"
                maxLength={10}
                label={i18n.t('entity.person.labels.titleBefore')}
                data-testid={suffixTestId('titleBefore', props)}
              />
            </Box>
            <Box flex={2}>
              <FormField
                control={control}
                type="text"
                name="foundingPerson.personData.firstName"
                isRequired
                maxLength={64}
                label={i18n.t('entity.person.labels.firstName')}
                data-testid={suffixTestId('firstName', props)}
              />
            </Box>
            <Box flex={2}>
              <FormField
                control={control}
                type="text"
                name="foundingPerson.personData.lastName"
                isRequired
                maxLength={64}
                label={i18n.t('entity.person.labels.lastName')}
                data-testid={suffixTestId('lastName', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="foundingPerson.personData.titleAfter"
                maxLength={10}
                label={i18n.t('entity.person.labels.titleAfter')}
                data-testid={suffixTestId('titleAfter', props)}
              />
            </Box>
          </HStack>
          <Card variant="inlineGrey">
            <VStack spacing={4}>
              <Box width="fit-content">
                <FormField
                  control={control}
                  type="switch"
                  name="selfEmployed"
                  label={i18n.t('entity.customer.labels.isSelfEmployed')}
                  data-testid={suffixTestId('selfEmployed', props)}
                />
              </Box>
              <Show when={formApi.watch('selfEmployed')}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="text"
                      name="selfEmployedBusinessInfoData.registrationNumber"
                      label={i18n.t('entity.businessInfo.labels.registrationNumber')}
                      data-testid={suffixTestId('registrationNumber', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="text"
                      name="selfEmployedBusinessInfoData.vatNumber"
                      label={i18n.t('entity.businessInfo.labels.vatNumber')}
                      data-testid={suffixTestId('vatNumber', props)}
                    />
                  </Box>
                  <HStack height={13} align="flex-end">
                    <DecodeBusinessIdButton
                      businessId={formApi.watch('selfEmployedBusinessInfoData.registrationNumber')}
                      vatId={formApi.watch('selfEmployedBusinessInfoData.vatNumber')}
                      countryCode={formApi.watch(
                        'selfEmployedBusinessInfoData.countryOfRegistrationCode'
                      )}
                      onSuccess={handleDecodeBusinessIdForm(formApi)}
                      data-testid={suffixTestId('decode', props)}
                    />
                  </HStack>
                </HStack>
                <Box flex={1}>
                  <FormField
                    control={control}
                    type="text"
                    name="selfEmployedBusinessInfoData.tradeName"
                    label={i18n.t('entity.businessInfo.labels.tradeName')}
                    isRequired
                    data-testid={suffixTestId('tradeName', props)}
                  />
                </Box>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="text"
                      name="selfEmployedBusinessInfoData.fileNumber"
                      label={i18n.t('entity.businessInfo.labels.fileNumber')}
                      data-testid={suffixTestId('fileNumber', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <CountrySelect<CustomerRequestBody>
                      control={control}
                      name="selfEmployedBusinessInfoData.countryOfRegistrationCode"
                      label={i18n.t('entity.businessInfo.labels.countryOfRegistrationCode')}
                      isRequired
                      menuInPortal
                      data-testid={suffixTestId('countryOfRegistrationCode', props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      type="choice"
                      name="selfEmployedBusinessAddressId"
                      options={addressOptions}
                      isRequired
                      label={i18n.t('contractInformation.labels.businessAddressId')}
                      menuInPortal
                      data-testid={suffixTestId('permanentAddressId', props)}
                    />
                  </Box>
                  <HStack height={13} align="flex-end">
                    <IconButton
                      icon="image/edit"
                      severity="informational"
                      isDisabled={isNilOrEmpty(formApi.watch('selfEmployedBusinessAddressId'))}
                      onClick={() =>
                        openEditAddressDialog(formApi.watch('selfEmployedBusinessAddressId')!)
                      }
                      data-testid={suffixTestId('editPermanentAddressId', props)}
                    />
                  </HStack>
                </HStack>
                <Align left>
                  <Button
                    leftIcon="content/add_circle"
                    title={i18n.t('entity.customer.actions.newAddress')}
                    size="small"
                    variant="link"
                    onClick={() =>
                      openCreateAddressDialog({
                        onSuccess: onCreateSelfEmployedBusinessAddress(formApi),
                      })
                    }
                  />
                </Align>
              </Show>
            </VStack>
          </Card>
          <HStack spacing={4}>
            <Box flex={1}>
              <CountrySelect<CustomerRequestBody>
                control={control}
                name="foundingPerson.personData.citizenshipCode"
                label={i18n.t('entity.person.labels.citizenshipCode')}
                onChange={() => parsePersonalId(formApi)}
                menuInPortal
                data-testid={suffixTestId('citizenshipCode', props)}
              />
            </Box>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    type="text"
                    name="foundingPerson.personData.personalIdentifier"
                    label={i18n.t('entity.person.labels.personalIdentifier')}
                    onChange={() => parsePersonalId(formApi)}
                    data-testid={suffixTestId('personalIdentifier', props)}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="foundingPerson.personData.genderKey"
                options={genderOptions}
                label={i18n.t('entity.person.labels.genderKey')}
                menuInPortal
                data-testid={suffixTestId('genderKey', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                type="apiDate"
                name="foundingPerson.personData.birthdate"
                isRelativeDatesHidden
                label={i18n.t('entity.person.labels.birthdate')}
                data-testid={suffixTestId('birthdate', props)}
              />
            </Box>
          </HStack>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.person.labels.contactInformation')}</Heading>
          <PhoneNumberList<CustomerRequestBody>
            control={control}
            formApi={formApi}
            name="foundingPerson.phoneNumbers"
            data-testid={suffixTestId('phoneNumberList', props)}
          />
          <EmailList<CustomerRequestBody>
            control={control}
            formApi={formApi}
            name="foundingPerson.emails"
            data-testid={suffixTestId('emailList', props)}
          />
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('entity.person.permanentAddressId')}</Heading>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                type="choice"
                name="foundingPerson.permanentAddressId"
                options={addressOptions}
                label={i18n.t('entity.address.labels.selectAddress')}
                menuInPortal
                data-testid={suffixTestId('permanentAddressId', props)}
              />
            </Box>
            <HStack height={13} align="flex-end">
              <IconButton
                icon="image/edit"
                severity="informational"
                isDisabled={isNilOrEmpty(formApi.watch('foundingPerson.permanentAddressId'))}
                onClick={() =>
                  openEditAddressDialog(formApi.watch('foundingPerson.permanentAddressId')!)
                }
                data-testid={suffixTestId('editPermanentAddressId', props)}
              />
            </HStack>
          </HStack>
          <Align left>
            <Button
              leftIcon="content/add_circle"
              title={i18n.t('entity.customer.actions.newAddress')}
              size="small"
              variant="link"
              onClick={() => openCreateAddressDialog({onSuccess: onCreateAddress(formApi)})}
              data-testid={suffixTestId('newAddress', props)}
            />
          </Align>
          <Separator spacing={0} />
          <Heading size={4}>{i18n.t('general.labels.identityCard')}</Heading>
          <IdentityCardList<CustomerRequestBody>
            name="foundingPerson.identityCards"
            control={control}
            formApi={formApi}
            data-testid={suffixTestId('identityCardList', props)}
          />
          <Show whenFeatureEnabled={featureFlags.CORE_CUSTOMER_GROUPS}>
            <Separator spacing={0} />
            <Heading size={4}>{i18n.t('entity.person.segmentation')}</Heading>
            <Grid columns={2}>
              <FormField
                name="customerGroups"
                label={i18n.t('entity.person.labels.customerGroups')}
                type="multiChoice"
                options={customerGroupOptions}
                control={control}
                isNotClearable
              />
            </Grid>
          </Show>
          <DialogFooter data-testid={props['data-testid']}>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose ?? closeCurrentDialog}
                isDisabled={props.isLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={props.isLoading}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
}

const schema = object().shape({
  selfEmployed: boolean(),
  foundingPerson: object().shape(
    {
      personData: object().shape({
        titleBefore: yupString.optional(),
        firstName: yupString.required().max(64),
        lastName: yupString.required().max(64),
        titleAfter: yupString.optional(),
        genderKey: yupString.optional(),
        citizenshipCode: yupString.optional(),
        personalIdentifier: yupString.optional(),
      }),
      permanentAddressId: yupString.nullable(),
      phoneNumbers: Yup.array().when('emails', {
        is: (emails?: EmailData[]) =>
          emails?.length && emails.length <= 1 && isNilOrEmpty(emails?.[0]?.email),
        then: (schema) =>
          schema
            .of(
              Yup.object().shape({
                type: yupString.optional(),
                phoneNumber: Yup.mixed()
                  .test(
                    'isPhone',
                    i18n.t('general.validations.invalidPhoneNumber'),
                    (phone?: PhoneNumber | Nullish) => {
                      if (isNilOrEmpty(phone?.number)) {
                        return true;
                      }
                      return validatePhone(phone?.number || '', {
                        country: phone?.countryCode,
                        validateMobilePrefix: false,
                      }).isValid;
                    }
                  )
                  .test(
                    'required',
                    i18n.t('general.validations.phoneOrEmail'),
                    (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
                  ),
              })
            )
            .min(1),
        otherwise: (schema) =>
          schema.of(
            Yup.object().shape({
              type: yupString.optional(),
              phoneNumber: Yup.mixed().test(
                'isPhone',
                i18n.t('general.validations.invalidPhoneNumber'),
                (phone?: PhoneNumber | Nullish) => {
                  if (isNilOrEmpty(phone?.number)) {
                    return true;
                  }
                  return validatePhone(phone?.number || '', {
                    country: phone?.countryCode,
                    validateMobilePrefix: false,
                  }).isValid;
                }
              ),
            })
          ),
      }),
      emails: Yup.array().when('phoneNumbers', {
        is: (phoneNumbers?: PhoneNumberData[]) =>
          phoneNumbers?.length &&
          phoneNumbers?.length <= 1 &&
          isNilOrEmpty(phoneNumbers?.[0]?.phoneNumber?.number),
        then: (schema) =>
          schema
            .of(
              Yup.object().shape({
                type: yupString.optional(),
                email: yupString.required(i18n.t('general.validations.phoneOrEmail')),
              })
            )
            .min(1),
        otherwise: (schema) =>
          schema.of(
            Yup.object().shape({
              type: yupString.optional(),
              email: yupString.optional().default(''),
            })
          ),
      }),
    },
    [['emails', 'phoneNumbers']]
  ),
  selfEmployedBusinessInfoData: object().when('selfEmployed', {
    is: true,
    then: object().shape(
      {
        countryOfRegistrationCode: yupString.required(),
        registrationNumber: yupString.when('vatNumber', {
          is: (value: string | Nullish) => isNilOrEmpty(value),
          then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
        }),
        vatNumber: yupString.when('registrationNumber', {
          is: (value: string | Nullish) => isNilOrEmpty(value),
          then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
        }),
        tradeName: yupString.required(),
        fileNumber: yupString.optional(),
      },
      [['registrationNumber', 'vatNumber']]
    ),
    otherwise: (schema) => schema.nullable(),
  }),
  selfEmployedBusinessAddressId: yupString.when('selfEmployed', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
});
