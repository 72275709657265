import {Alpha3CountryCode, DataStatus, Label, Search, useAnimatedPopper} from 'platform/components';
import {
  Box,
  Clickable,
  getCssSize,
  HStack,
  Integer,
  Scroll,
  Text,
  ThemeColorTextPath,
  VStack,
} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {useRef, useState} from 'react';

import {always, defaultTo, isEmpty, isNil, pipe, reject, trim} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CustomerAdvancedSearchResultResponseBody,
  useCustomerAdvancedSearchQuery,
  useGetTenantQuery,
  useLazyGetCustomerV2Query,
} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, useDebouncedCallback} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../constants/placeholders';
import {useAddress} from '../../hooks/useAddress';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';

interface SaneCustomer {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  prefix: string;
  countryCode: Alpha3CountryCode;
}

interface CustomerSelectChoiceProps extends TestIdProps {
  onCustomerSelect: (saneCustomer: SaneCustomer) => void;
  onAfterCustomerSelect?: () => void;
}

const DEFAULT_POPPER_WIDTH_PX = 560;

export function CustomerSelectChoice(props: CustomerSelectChoiceProps) {
  const {composeAddress} = useAddress();
  const [getCustomer] = useLazyGetCustomerV2Query();
  const {data: tenant} = useGetTenantQuery();
  const searchRef = useRef<null | HTMLDivElement>(null);

  const [searchString, setSearchString] = useState<string | null>(null);
  const [debouncedSearchString, setDebouncedSearchString] = useState<string | null>(null);

  const {data: matchingCustomers, isFetching: isFetchingCustomers} = useCustomerAdvancedSearchQuery(
    {name: debouncedSearchString},
    {skip: (debouncedSearchString?.length ?? 0) < 3, refetchOnMountOrArgChange: true}
  );

  const {openPopper, referenceRef, popperProps, Popper, closePopper} = useAnimatedPopper({
    placement: 'bottom-start',
    strategy: 'fixed',
    gutter: 10,
  });

  const updateDebouncedSearchString = useDebouncedCallback(
    (val: string | null) => setDebouncedSearchString(val),
    500
  );

  const handleOnChange = (val: string | null) => {
    setSearchString(val);
    updateDebouncedSearchString(val);
    openPopper();
  };

  const getCustomerInfo = (customer: CustomerAdvancedSearchResultResponseBody) => {
    const customerInfo = customer?.foundingPerson ?? customer?.contacts?.[0];

    const info = reject(isNil, [
      customerInfo?.email,
      customerInfo?.phone,
      customer?.registrationNumber
        ? `${i18n.t('entity.invoice.labels.registrationNumber')}: ${customer.registrationNumber}`
        : null,
    ]);

    return info.join(', ') || EMPTY_PLACEHOLDER;
  };

  const handleSelectCustomer = (customer: CustomerAdvancedSearchResultResponseBody) => {
    getCustomer({customerId: customer.id})
      .unwrap()
      .then((customer) => {
        const customerInfo = customer?.foundingPerson ?? customer?.contacts?.[0];

        if (!customerInfo) {
          return;
        }

        const email = customerInfo.emails?.[0]?.email;
        const customerPhoneNumber = customerInfo.phoneNumbers?.[0];
        const phoneNumber = customerPhoneNumber ?? tenant?.publicContacts?.phoneNumber;

        props.onCustomerSelect({
          id: customerInfo.id,
          name: getNaturalPersonFullName(customerInfo) ?? '',
          email: email || '',
          phoneNumber: getFormPhoneValue(phoneNumber?.number),
          prefix: getFormPhoneValue(phoneNumber?.prefix),
          countryCode: phoneNumber?.countryCode as Alpha3CountryCode,
        });

        setSearchString(
          [customer.foundingPerson?.firstName, customer.foundingPerson?.lastName]
            .filter((item) => isNotNilOrEmpty(item))
            .join(' ')
        );

        closePopper();
        props.onAfterCustomerSelect?.();
      })
      .catch(handleApiError);
  };

  const popperWidth = Math.floor(
    (searchRef.current?.clientWidth ?? DEFAULT_POPPER_WIDTH_PX) / 4
  ) as Integer;

  return (
    <>
      <Clickable onClick={openPopper}>
        <Box ref={referenceRef}>
          <VStack>
            <Label>{i18n.t('entity.document.actions.searchForCustomer')}</Label>
            <Box ref={searchRef}>
              <Search
                value={searchString}
                onChange={handleOnChange}
                onClear={closePopper}
                label={i18n.t('general.labels.searchPlaceholder')}
              />
            </Box>
          </VStack>
        </Box>
      </Clickable>
      <Popper {...popperProps}>
        <Box
          width={popperWidth}
          backgroundColor="general.white"
          borderRadius="medium"
          boxShadow="elevation_2"
        >
          <DataStatus
            isLoading={isFetchingCustomers}
            spacing={20}
            isEmpty={isEmpty(matchingCustomers ?? [])}
            emptyMessage={i18n.t('entity.customer.labels.noResults')}
          >
            <Scroll auto maxHeight={80}>
              <Box paddingVertical={2}>
                <VStack>
                  {matchingCustomers?.map((customer) => {
                    const customerInfo = customer?.foundingPerson ?? customer?.contacts?.[0];

                    const firstName =
                      isNilOrEmpty(customerInfo?.firstName) && isNilOrEmpty(customerInfo?.lastName)
                        ? customer.tradeName
                        : customerInfo?.firstName;

                    return (
                      <HoverableBox key={customer.id}>
                        <Clickable onClick={() => handleSelectCustomer(customer)}>
                          <HStack align="center">
                            <Box width="35%" paddingVertical={2}>
                              <HStack spacing={2}>
                                <Text
                                  color={getColorBasedOnMatch(customerInfo?.firstNameMatchType)}
                                >
                                  {firstName}
                                </Text>
                                <Text
                                  color={getColorBasedOnMatch(customerInfo?.lastNameMatchType)}
                                  overflowWrap="anywhere"
                                >
                                  {customerInfo?.lastName}
                                </Text>
                              </HStack>
                            </Box>
                            <VStack>
                              <Text size="xSmall" color="secondary">
                                {getCustomerInfo(customer)}
                              </Text>

                              <Text size="xSmall" color="tertiary">
                                {composeAddress(customerInfo?.address)}
                              </Text>
                            </VStack>
                          </HStack>
                        </Clickable>
                      </HoverableBox>
                    );
                  })}
                </VStack>
              </Box>
            </Scroll>
          </DataStatus>
        </Box>
      </Popper>
    </>
  );
}

const HoverableBox = styled.div`
  padding-left: ${getCssSize(4)};
  padding-right: ${getCssSize(4)};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
`;

const getColorBasedOnMatch = (matchType: string | undefined) =>
  match<string | undefined, ThemeColorTextPath>(matchType)
    .with('FULL', always('success'))
    .with('PARTIAL', always('warning'))
    .otherwise(always('primary'));

const getFormPhoneValue = pipe(defaultTo<string | undefined>(''), trim);
