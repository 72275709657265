import {VehicleCatalogueEnumOption, VehicleCatalogueFuelTypeProperties} from '@dms/teas';

export const getSecondaryFuelType = (
  fuelType: VehicleCatalogueEnumOption<VehicleCatalogueFuelTypeProperties>,
  secondaryFuelType: string
) => {
  const secondaryTypes = fuelType?.properties?.find(
    (property) => property.name === 'secondary_fuel_type'
  )?.value;

  if (Array.isArray(secondaryTypes)) {
    return secondaryTypes.find((fuel: string) => fuel === secondaryFuelType);
  }

  if (secondaryTypes === secondaryFuelType) {
    return secondaryFuelType;
  }
};
