import {DataStatus} from 'platform/components';
import {
  Box,
  getSize,
  Grid,
  Heading,
  HStack,
  Link,
  Scroll,
  Show,
  Stack,
  Text,
  useElementSize,
  VStack,
} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {isNil, isNotEmpty, min} from 'ramda';
import {floor, isNotNil} from 'ramda-adjunct';

import {useSourcingVehicleDetailQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {useSourcingCurrency} from '@dms/teas';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {AdServerLogo} from './components/AdServerLogo';
import {Gallery} from './components/Gallery';
import {PriceTable} from './components/PriceTable';
import {Seller} from './components/Seller';
import {VehicleDescription} from './components/VehicleDescription';
import {VehicleSpecificationColumn} from './components/VehicleSpecificationColumn';

interface VehicleDetailProps extends TestIdProps {
  vehicleId: string | Nullish;
  isScrollDisabled?: boolean;
}

const CONTAINER_WIDTH_BREAKPOINT = 850;

export function VehiclePreview(props: VehicleDetailProps) {
  const formatNumber = useFormatNumber();
  const currency = useSourcingCurrency();

  const [ref, width] = useElementSize<HTMLDivElement>();
  const isSmallContainerWidth = (width ?? 0) < CONTAINER_WIDTH_BREAKPOINT;
  const featureColumnsCount = isNil(width) ? 3 : min(floor(width / 200), 3);

  const {data, isLoading, isError, isFetching} = useSourcingVehicleDetailQuery(
    {
      externalId: props.vehicleId ?? '',
      currency: currency?.code,
    },
    {skip: isNil(props.vehicleId)}
  );

  const sourcingVehicle = data?.sourcingVehicle;
  const originalPrice = data?.summary.original;

  return (
    <Box
      backgroundColor="general.white"
      height="100%"
      borderLeft="1px solid"
      borderColor="palettes.neutral.40.100"
      overflow="hidden"
      ref={ref}
    >
      <Show when={isNotNil(props.vehicleId)}>
        <DataStatus
          spacing={12}
          minHeight="100%"
          isLoading={isLoading || isFetching}
          isError={isError}
        >
          <Scroll
            height={!props.isScrollDisabled ? `calc(100vh - ${getSize(12)})` : undefined}
            auto
          >
            <Box padding={4}>
              {isNotNil(sourcingVehicle) && (
                <VStack spacing={6}>
                  <HStack justify="space-between" align="center">
                    {sourcingVehicle.server ? (
                      <AdServerLogo server={sourcingVehicle.server} maxHeight={8} maxWidth={32} />
                    ) : (
                      <Box />
                    )}
                    <Link
                      title={i18n.t('entity.sourcing.vehiclePreview.actions.openOriginalUrl')}
                      rightIcon="action/open_in_new"
                      size="small"
                      href={sourcingVehicle.url?.full}
                      target="_blank"
                      data-testid={suffixTestId('openOriginalUrl', props)}
                    />
                  </HStack>
                  <Heading size={1}>{sourcingVehicle?.adTitle}</Heading>
                  <Stack
                    direction={isSmallContainerWidth ? 'column' : 'row'}
                    spacing={4}
                    width="100%"
                  >
                    <Gallery images={sourcingVehicle?.pictureUrls} />
                    <VStack spacing={10} grow={1} minWidth={isSmallContainerWidth ? undefined : 80}>
                      <VStack grow={1} spacing={6}>
                        {isNotNil(originalPrice) && isNotNil(sourcingVehicle.price) && (
                          <PriceTable originalPrice={originalPrice} price={sourcingVehicle.price} />
                        )}
                        <Show when={sourcingVehicle.price?.vatReclaimable}>
                          <Text size="xSmall" color="tertiary">
                            {`${i18n.t('entity.sourcing.vehiclePreview.labels.vatIncluded', {
                              vat: formatNumber(sourcingVehicle.price?.vatRate, 2),
                            })} (${sourcingVehicle?.seller?.country?.translation})`}
                          </Text>
                        </Show>
                      </VStack>
                      {sourcingVehicle.seller && <Seller seller={sourcingVehicle.seller} />}
                    </VStack>
                  </Stack>
                  <VStack spacing={6}>
                    <Heading size={3}>
                      {i18n.t('entity.sourcing.vehiclePreview.labels.specification')}
                    </Heading>
                    <HStack spacing={4} wrap>
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.vehicle.labels.year')}
                        icon="automotive/calendar"
                        value={sourcingVehicle.year}
                      />
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.sourcing.vehiclePreview.labels.power')}
                        icon="automotive/engine"
                        value={
                          isNotNil(sourcingVehicle.power)
                            ? `${sourcingVehicle.power} ${i18n.t('general.metric.kW')}`
                            : null
                        }
                      />
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.sourcing.vehiclePreview.labels.differential')}
                        icon="automotive/differential"
                        value={sourcingVehicle.driveType?.translation}
                      />
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.sourcing.vehiclePreview.labels.mileage')}
                        icon="automotive/mileage"
                        value={
                          isNotNil(sourcingVehicle.mileage)
                            ? `${formatNumber(sourcingVehicle.mileage)} ${i18n.t(
                                'general.metric.km'
                              )}`
                            : null
                        }
                      />
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.sourcing.vehiclePreview.labels.transmission')}
                        icon="automotive/transmission"
                        value={sourcingVehicle.transmission?.translation}
                      />
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.sourcing.vehiclePreview.labels.fuel')}
                        icon="automotive/fuel_type"
                        value={sourcingVehicle.fuelType?.translation}
                      />
                      <VehicleSpecificationColumn
                        label={i18n.t('entity.sourcing.vehiclePreview.labels.bodyType')}
                        icon="automotive/car"
                        value={sourcingVehicle.carStyle?.translation}
                      />
                    </HStack>
                  </VStack>
                  <Show when={isNotEmpty(sourcingVehicle?.features)}>
                    <VStack spacing={6}>
                      <Heading size={3}>
                        {i18n.t('entity.sourcing.vehiclePreview.labels.vehicleFeatures')}
                      </Heading>
                      <Grid columns={featureColumnsCount} spacing={2}>
                        {sourcingVehicle?.features
                          .filter((feature) => feature.key !== feature.translation)
                          .map((feature) => (
                            <Text key={feature.key} size="small">
                              {feature.translation}
                            </Text>
                          ))}
                      </Grid>
                    </VStack>
                  </Show>
                  <Show when={sourcingVehicle.description}>
                    <VStack spacing={6}>
                      <Heading size={3}>
                        {i18n.t('entity.sourcing.vehiclePreview.labels.vehicleDescription')}
                      </Heading>
                      <VehicleDescription
                        description={sourcingVehicle.description}
                        data-testid={suffixTestId('vehicleDescription', props)}
                      />
                    </VStack>
                  </Show>
                </VStack>
              )}
            </Box>
          </Scroll>
        </DataStatus>
      </Show>
    </Box>
  );
}
