import {Textarea} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {CreateVehicleRequestBody} from '../../../types/CreateVehicleRequestBody';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';

export function FeaturesAdditionalInfo() {
  const {form, Subscribe} = useFormRenderer<CreateVehicleRequestBody>();

  return (
    <VStack spacing={2}>
      <Heading size={4} color="secondary">
        {i18n.t('entity.vehicle.labels.featuresByManufacturer')}
      </Heading>
      <Subscribe
        name="featuresByManufacturer"
        component={({input}) => (
          <Textarea
            value={input.value}
            minRows={3}
            onChange={(value) => form.change('featuresByManufacturer', value)}
          />
        )}
      />
    </VStack>
  );
}
