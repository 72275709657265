import {DeleteDialog, Dropdown, DropdownItem, IconButton} from 'platform/components';
import {Icon} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {FC, MouseEvent as ReactMouseEvent, useState} from 'react';

import {SeriesResponseBody, SeriesTypeResponseBody, useBatchDeleteSeriesMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {BulkActionsBar, Checkbox, ColumnType, DataType, Tag, findByKey} from '@dms/teas';

import {composePath, noop, parseDate, useNavigate} from 'shared';

import {useRowSelect} from '../../../utils/useRowSelect';
import {StyledTable} from './styles';

export type SeriesTableProps = {
  series?: SeriesResponseBody[] | undefined;
  seriesTypes?: SeriesTypeResponseBody[] | undefined;
};

export const SeriesTable: FC<SeriesTableProps> = ({series = [], seriesTypes = []}) => {
  const navigate = useNavigate();
  const formatDateTime = useDateTimeFormatter();

  const seriesKeys = series.map((item) => item.id);
  const [seriesToDelete, setSeriesToDelete] = useState<SeriesResponseBody[]>([]);
  const {isSelected, allSelected, toggle, toggleAll, someSelected, selectedCount, deselectAll} =
    useRowSelect(seriesKeys);

  const [deleteSeries] = useBatchDeleteSeriesMutation();

  const columns: ColumnType[] = [
    {
      Header: (
        <Checkbox
          data-testid={testIds.settings.vehicleSeriesDefinition('header')}
          checked={allSelected() || someSelected()}
          onChange={noop}
          onClick={() => toggleAll()}
          isAllRowsSelected={someSelected()}
        />
      ),
      id: 'checkbox',
      className: 'action-column',
    },
    {
      Header: i18n.t('general.labels.name'),
      id: 'productName',
    },
    {
      Header: i18n.t('entity.seriesTable.labels.type'),
      id: 'type',
    },
    {
      Header: i18n.t('entity.seriesTable.labels.lastNumber'),
      id: 'lastNumber',
    },
    {
      Header: i18n.t('entity.seriesTable.labels.lastDate'),
      id: 'lastDate',
    },
    {
      Header: '',
      id: 'action',
      className: 'action-column',
    },
  ];

  const handleDelete = () =>
    deleteSeries({
      body: {
        items: seriesToDelete.map((item) => ({id: item.id})),
      },
    })
      .unwrap()
      .then(() => {
        deselectAll();
        setSeriesToDelete([]);
      })
      .catch(handleApiError);

  const createRow = (item: SeriesResponseBody, i: number): DataType => {
    /* Disable rule because we can't use SeriesType.code enum from generated code */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const seriesType = findByKey(seriesTypes, 'code', item.type as any);

    return {
      id: item.id,
      checkbox: (
        <Checkbox
          data-testid={testIds.settings.vehicleSeriesDefinition(`checkbox-${item.id}`)}
          checked={isSelected(item.id)}
          onChange={noop}
          disabled={item.system}
          onClick={(e: ReactMouseEvent) => {
            e.stopPropagation();

            if (item.system) {
              return;
            }

            toggle(item.id);
          }}
        />
      ),
      productName: item.name ?? '-',
      type: <Tag {...{[seriesType?.color as string]: true}}>{seriesType?.name ?? '-'}</Tag>,
      lastNumber: item.lastIssueInfo?.number ?? '-',
      lastDate: item.lastIssueInfo?.issuedAt
        ? formatDateTime('dateShort', parseDate(item.lastIssueInfo?.issuedAt))
        : '-',
      action: (
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown dropdownControl={<IconButton icon="navigation/more_horiz" />}>
            <DropdownItem
              onClick={() =>
                navigate(composePath(settingsRoutes.seriesDefinitionEdit, {params: {id: item.id}}))
              }
              label={i18n.t('general.actions.edit')}
              leftIcon="editor/border_color"
            />
            {!item?.system && (
              <DropdownItem
                onClick={() => setSeriesToDelete([item])}
                label={i18n.t('general.actions.delete')}
                leftIcon="action/delete"
              />
            )}
          </Dropdown>
        </div>
      ),
    };
  };

  return (
    <div>
      {selectedCount > 0 && (
        <BulkActionsBar
          tableName="general-series-table"
          itemsSelected={selectedCount}
          actions={[
            {
              label: i18n.t('general.actions.delete'),
              icon: <Icon value="action/delete_outline" />,
              onClick: () => setSeriesToDelete(series.filter((item) => isSelected(item.id))),
            },
          ]}
          onCancel={() => deselectAll()}
        />
      )}

      <StyledTable
        noZebra
        showRowDivider
        highlightRowOnHover
        tdPadding="default"
        columns={columns}
        rows={[{data: series?.map(createRow) || []}]}
        onRowClick={(row) =>
          navigate(
            composePath(settingsRoutes.seriesDefinitionEdit, {params: {id: row.id as string}})
          )
        }
        data-testid={testIds.settings.vehicleSeriesDefinition('table')}
      />

      <DeleteDialog
        isOpen={seriesToDelete.length !== 0}
        data-testid={testIds.settings.vehicleSeriesDefinition('deleteModal')}
        text={i18n.t('entity.documentSeries.labels.deleteSeriesConfirmationText', {
          series: seriesToDelete.map(({name}) => `"${name}"`).join(', '),
        })}
        onClose={() => setSeriesToDelete([])}
        onConfirm={handleDelete}
      />
    </div>
  );
};
