import {BreadcrumbType} from 'platform/components';

import {useParams} from 'react-router-dom';

import {useGetSectorQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ParkingSectorForm} from './components/ParkingSectorForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.vehicleSettings.labels.parking'),
    href: settingsRoutes.vehicleParking,
  },
];

export function VehicleParkingEdit() {
  const {id} = useParams();
  const {data, isLoading} = useGetSectorQuery({sectorId: id ?? ''});

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{breadcrumbs}}
      data-testid={testIds.settings.vehicleParkingEdit('modal')}
    >
      <ParkingSectorForm editData={data} />
    </SettingsTemplate>
  );
}
