import {DataStatus} from 'platform/components';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  selectCarAudit,
  selectNewestConditionSettingsStructure,
  selectSettingBasicStructure,
  selectStructureSetting,
  CreateTypeOfControlSettingsRequestBody,
} from '@dms/teas';

import {getStructureVersion} from '../utils/getStructureVersion';
import {AuditSettingsCategories} from './AuditSettingsCategory';
import {ConfirmationDialogProvider} from './AuditSettingsConfirmDialog';
import {AuditSettingsFooter} from './AuditSettingsFooter';
import {AuditSettingsForm} from './AuditSettingsForm';
import {AuditSettingsInspectionHeader} from './AuditSettingsInspectionHeader';
import {AuditSettingsValidateField} from './AuditSettingsValidateField';

interface AuditSettingsProps {
  type?: CreateTypeOfControlSettingsRequestBody['inspectionType'];
  isCreate?: boolean;
  templateId?: string;
}

export const AuditSettings: FC<AuditSettingsProps> = (props) => {
  const {isCreate, templateId} = props;
  const {loading} = useSelector(selectCarAudit);
  const structureSettingData = useSelector(selectStructureSetting);
  const structure = useSelector(selectSettingBasicStructure(templateId ?? 'newest'));
  const newestStructureSettings = useSelector(selectNewestConditionSettingsStructure);

  const isLoading = isCreate
    ? loading.getAuditStructureForStructureSettings
    : loading.getAuditStructureForStructureSettings || loading.getAuditStructureSettings;

  const isEmpty = isCreate
    ? isNilOrEmpty(structure)
    : isNilOrEmpty(structureSettingData) || isNilOrEmpty(structure);

  if (isLoading || isEmpty) {
    /**
     * Early return fixes bug caused by empty data for structure or structureSettingData
     * we need to show empty state before using this data
     * https://carvago-cn.sentry.io/issues/5559026384/
     */
    return <DataStatus isLoading={isLoading} isEmpty={isEmpty} />;
  }
  const isVehicleCondition = props.type === 'vehicleCondition';

  const structureVersion = getStructureVersion(
    isVehicleCondition,
    structure,
    newestStructureSettings
  );

  return (
    <AuditSettingsForm
      structureVersion={structureVersion}
      categories={structure?.categories}
      {...props}
    >
      {isVehicleCondition ? <AuditSettingsValidateField /> : <AuditSettingsInspectionHeader />}
      <ConfirmationDialogProvider>
        <AuditSettingsCategories categories={structure?.categories} />
      </ConfirmationDialogProvider>
      <AuditSettingsFooter {...props} />
    </AuditSettingsForm>
  );
};
