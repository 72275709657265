import type {PermissionsRecordType} from '../permissions';

export const taskManagementPermissions = {
  createTask: {
    resourceId: 'TASK',
    actionId: 'CREATE',
    scopes: [],
  },
  readTask: {
    resourceId: 'TASK',
    actionId: 'READ',
    scopes: [],
  },
  updateTask: {
    resourceId: 'TASK',
    actionId: 'UPDATE',
    scopes: [],
  },
  deleteTask: {
    resourceId: 'TASK',
    actionId: 'DELETE',
    scopes: [],
  },
} satisfies PermissionsRecordType;
