import {Option} from 'platform/components';
import {match} from 'ts-pattern';

import {uniq} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetAlphaCatalogueQuery, GetAlphaCatalogueApiResponse, Key} from '@dms/api';
import {browserStorageKey} from '@dms/config';

import {Nullish} from 'shared';

import {sortByLabel} from '../utils/sortByLabel';

export type OptionWithProperties<T = string, P = string> = {
  label: string;
  value: T;
  properties: Option<P>[] | Nullish;
};

export type VehicleCatalogueReturnType = [
  {
    getVehicleType: (carStyle: string | Nullish) => string | Nullish;
    getBodyStyle: (bodyStyle: string | Nullish) => string | Nullish;
    getFuelType: (fuelType: string | Nullish) => string | Nullish;
    getHybridType: (hybridType: string | Nullish) => string | Nullish;
    getBatteryType: (batteryType: string | Nullish) => string | Nullish;
    getChargingConnectorType: (chargingConnectorType: string | Nullish) => string | Nullish;
    getBatteryOwnershipType: (batteryOwnershipType: string | Nullish) => string | Nullish;
    getDrive: (drive: string | Nullish) => string | Nullish;
    getFeature: (feature: string | Nullish) => string | Nullish;
    getTransmission: (transmission: string | Nullish) => string | Nullish;
    getColorType: (colorType: string | Nullish) => string | Nullish;
    getBodyColor: (bodyColor: string | Nullish) => string | Nullish;
    getBodyColorHEX: (bodyColor: string | Nullish) => string | Nullish;
    getInteriorMaterial: (interiorMaterial: string | Nullish) => string | Nullish;
    getEmissionClass: (emissionClass: string | Nullish) => string | Nullish;
    getDoorCount: (doorCount: string | Nullish) => string | Nullish;
    getHandDrive: (handDriveType: string | Nullish) => string | Nullish;
    handDriveOptions: Option[] | Nullish;
    driveOptions: Option[] | Nullish;
    fuelTypeOptions: OptionWithProperties[] | Nullish;
    hybridTypeOptions: Option[] | Nullish;
    batteryTypeOptions: Option[] | Nullish;
    batteryOwnershipTypeOptions: Option[] | Nullish;
    chargingConnectorTypeOptions: Option[] | Nullish;
    vehicleStyleOptions: Option[] | Nullish;
    transmissionOptions: Option[] | Nullish;
    featureOptions: Option[] | Nullish;
    bodyColorTypeOptions: Option[] | Nullish;
    bodyColorOptions: Option[] | Nullish;
    doorCountOptions: Option[] | Nullish;
    interiorMaterialOptions: Option[] | Nullish;
    vehicleTypeOptions: Option[] | Nullish;
    serviceBookStateOptions: Option[] | Nullish;
    serviceBookTypeOptions: Option[] | Nullish;
  },
  {
    isError: boolean;
    isLoading: boolean;
  },
];

export function useVehicleCatalogue(type: string | Nullish): VehicleCatalogueReturnType {
  const {data, isError, isLoading} = useGetAlphaCatalogueQuery({
    lang: String(localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE)),
  });

  const vehicleType = isNotNilOrEmpty(type) ? type! : 'VEHICLETYPE_PASSENGER_CAR';

  const catalogueData = makeVehicleCatalogueByVehicleType(data);

  const vehicleTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'vehicle_type',
    catalogueData
  );

  const driveOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'drive',
    catalogueData
  );

  const fuelTypeOptions: OptionWithProperties[] | Nullish =
    getOptionsWithPropertiesByVehicleTypeAndProperty(
      vehicleType,
      'fuel_type',
      catalogueData
    )?.reduce((options: OptionWithProperties[], option) => {
      if (option.value === 'FUELTYPE_PETROL' || option.value === 'FUELTYPE_DIESEL') {
        return [option, ...options];
      }
      return [...options, option];
    }, []);

  const hybridTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'hybrid_type',
    catalogueData
  );

  const batteryTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'battery_type',
    catalogueData
  );

  const chargingConnectorTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'charging_connector_type',
    catalogueData
  );

  const batteryOwnershipTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'battery_ownership_type',
    catalogueData
  );

  const handDriveOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'hand_drive_type',
    catalogueData
  );

  const vehicleStyleOptions: Option[] | Nullish = match(vehicleType)
    .with('VEHICLETYPE_PASSENGER_CAR', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'car_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_BUS', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'bus_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_CARAVAN', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'caravan_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_MOTORCYCLE', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'motorcycle_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_TRUCK', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'truck_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_SEMI_TRUCK', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'semi_truck_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_VAN', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'van_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_SEMI_TRAILER', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'semi_trailer_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_TRAILER', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'trailer_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_CONSTRUCTION_MACHINE', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'construction_machine_style', catalogueData)
      )
    )
    .with('VEHICLETYPE_AGRICULTURAL_MACHINE', () =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'agriculture_machine_style', catalogueData)
      )
    )
    .otherwise(() =>
      mergeVanMpvInOptions(
        getOptionsByVehicleTypeAndProperty(vehicleType, 'car_style', catalogueData)
      )
    );

  const transmissionOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'transmission',
    catalogueData
  );

  const bodyColorTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'color_type',
    catalogueData
  );

  const bodyColorOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'body_color',
    catalogueData
  );

  const doorCountOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'door_count',
    catalogueData
  )?.filter((option) => option.label.includes('/'));

  const interiorMaterialOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'interior_material',
    catalogueData
  );

  const serviceBookTypeOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'service_book_type',
    catalogueData
  );

  const serviceBookStateOptions: Option[] | Nullish = getOptionsByVehicleTypeAndProperty(
    vehicleType,
    'service_book_state',
    catalogueData
  );

  const featureOptions = getOptionsByVehicleTypeAndProperty(vehicleType, 'feature', catalogueData);

  const getVehicleType = (style: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'vehicle_type', style, catalogueData);

  const getBodyColor = (bodyColor: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'body_color', bodyColor, catalogueData);

  const getBodyColorHEX = (bodyColor: string | Nullish) => {
    const bodyColors = data?.find((item) => item.property_name === 'body_color')?.keys ?? [];
    const bodyColorObject = bodyColors.find((key) => key.const_key === bodyColor);

    return bodyColorObject?.properties
      ?.find((property) => property.name === 'color_code')
      ?.value?.toString();
  };

  const getBatteryType = (batteryType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'battery_type',
      batteryType,
      catalogueData
    );

  const getChargingConnectorType = (chargingConnectorType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'charging_connector_type',
      chargingConnectorType,
      catalogueData
    );

  const getBatteryOwnershipType = (batteryOwnershipType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'battery_ownership_type',
      batteryOwnershipType,
      catalogueData
    );

  const getColorType = (colorType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'color_type', colorType, catalogueData);

  const getInteriorMaterial = (interiorMaterial: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'interior_material',
      interiorMaterial,
      catalogueData
    );

  const getEmissionClass = (emissionClass: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'emission_class',
      emissionClass,
      catalogueData
    );

  const getHandDrive = (handDriveType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'hand_drive_type',
      handDriveType,
      catalogueData
    );

  const getDoorCount = (doorCount: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'door_count', doorCount, catalogueData);

  const getFuelType = (fuelType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'fuel_type', fuelType, catalogueData);

  const getHybridType = (hybridType: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'hybrid_type', hybridType, catalogueData);

  const getBodyStyle = (carStyle: string | Nullish) => {
    if (carStyle === 'CARSTYLE_VAN_MPV') {
      return 'VAN/MPV';
    }

    return match(vehicleType)
      .with('VEHICLETYPE_PASSENGER_CAR', () =>
        getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'car_style', carStyle, catalogueData)
      )
      .with('VEHICLETYPE_BUS', () =>
        getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'bus_style', carStyle, catalogueData)
      )
      .with('VEHICLETYPE_CARAVAN', () =>
        getPropertyLabelByVehicleTypeAndProperty(
          vehicleType,
          'caravan_style',
          carStyle,
          catalogueData
        )
      )
      .with('VEHICLETYPE_MOTORCYCLE', () =>
        getPropertyLabelByVehicleTypeAndProperty(
          vehicleType,
          'motorcycle_style',
          carStyle,
          catalogueData
        )
      )
      .with('VEHICLETYPE_TRUCK', () =>
        getPropertyLabelByVehicleTypeAndProperty(
          vehicleType,
          'truck_style',
          carStyle,
          catalogueData
        )
      )
      .with('VEHICLETYPE_SEMI_TRUCK', () =>
        getPropertyLabelByVehicleTypeAndProperty(
          vehicleType,
          'semi_truck_style',
          carStyle,
          catalogueData
        )
      )
      .with('VEHICLETYPE_VAN', () =>
        getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'van_style', carStyle, catalogueData)
      )
      .otherwise(() =>
        getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'car_style', carStyle, catalogueData)
      );
  };

  const getDrive = (drive: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'drive', drive, catalogueData);

  const getFeature = (feature: string | Nullish) =>
    // Alpha return interior material as a feature
    feature?.startsWith('INTERIORMATERIAL_')
      ? getPropertyLabelByVehicleTypeAndProperty(
          vehicleType,
          'interior_material',
          feature,
          catalogueData
        )
      : getPropertyLabelByVehicleTypeAndProperty(vehicleType, 'feature', feature, catalogueData);

  const getTransmission = (transmission: string | Nullish) =>
    getPropertyLabelByVehicleTypeAndProperty(
      vehicleType,
      'transmission',
      transmission,
      catalogueData
    );

  return [
    {
      getVehicleType,
      getBodyStyle,
      getFuelType,
      getHybridType,
      getBatteryType,
      getChargingConnectorType,
      getBatteryOwnershipType,
      getHandDrive,
      getDrive,
      getFeature,
      getTransmission,
      getColorType,
      getBodyColor,
      getBodyColorHEX,
      getInteriorMaterial,
      getEmissionClass,
      getDoorCount,
      handDriveOptions,
      driveOptions,
      fuelTypeOptions,
      hybridTypeOptions,
      vehicleStyleOptions,
      batteryTypeOptions,
      batteryOwnershipTypeOptions,
      chargingConnectorTypeOptions,
      transmissionOptions,
      bodyColorTypeOptions,
      bodyColorOptions,
      doorCountOptions,
      interiorMaterialOptions,
      vehicleTypeOptions,
      featureOptions,
      serviceBookStateOptions,
      serviceBookTypeOptions,
    },
    {isError, isLoading},
  ];
}

type CatalogueByVehicleType = {
  [vehicleType: string]: {
    [propertyName: string]: Key[];
  };
};

export function makeVehicleCatalogueByVehicleType(catalogue?: GetAlphaCatalogueApiResponse) {
  return catalogue?.reduce((catalogueData: CatalogueByVehicleType, catalogueItem) => {
    if (!catalogueItem.vehicle_type || !catalogueItem.property_name) {
      return catalogueData;
    }
    if (catalogueItem.vehicle_type in catalogueData) {
      return {
        ...catalogueData,
        [catalogueItem.vehicle_type]: {
          ...catalogueData[catalogueItem.vehicle_type],
          [catalogueItem.property_name]: catalogueItem.keys,
        },
      };
    }
    return {
      ...catalogueData,
      [catalogueItem.vehicle_type]: {
        [catalogueItem.property_name]: catalogueItem.keys,
      },
    };
  }, {});
}

export function getOptionsByVehicleTypeAndProperty(
  vehicleType: string,
  property: string,
  catalogue: CatalogueByVehicleType | Nullish
) {
  return catalogue?.[vehicleType]?.[property]?.map((item) => ({
    label: item.labels[0]?.label ?? item.const_key,
    value: item.const_key,
  }));
}

export function getOptionsWithPropertiesByVehicleTypeAndProperty(
  vehicleType: string,
  property: string,
  catalogue: CatalogueByVehicleType | Nullish
) {
  return catalogue?.[vehicleType]?.[property]?.map((item) => ({
    label: item.labels[0]?.label ?? item.const_key,
    value: item.const_key,
    properties: item.properties?.map((property) => ({
      label: property.name,
      value: property.value,
    })),
  }));
}

export function getPropertyLabelByVehicleTypeAndProperty(
  vehicleType: string,
  property: string,
  key: string | Nullish,
  catalogue: CatalogueByVehicleType | Nullish
) {
  return (
    catalogue?.[vehicleType]?.[property]?.find((e) => e.const_key === key)?.labels[0]?.label ?? key
  );
}

export function mergeVanMpvInOptions(options: {label: string; value: string}[] | Nullish) {
  if (isNilOrEmpty(options)) {
    return null;
  }
  return sortByLabel(
    uniq(
      options!.map((option) =>
        option.value === 'CARSTYLE_VAN' || option.value === 'CARSTYLE_MPV'
          ? {label: 'VAN/MPV', value: 'CARSTYLE_VAN_MPV'}
          : option
      )
    )
  );
}
