import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {GdprConsentsList} from '../../GdprConsentsList/GdprConsentsList';
import {useGetCustomersDetails} from '../hooks/useGetCustomersDetails';

interface GDPRCollectorProps {
  vehicleId: string;
}

export const GDPRCollector = (props: GDPRCollectorProps) => {
  const {vehicleId} = props;
  const {gdprCustomers, isLoading, isError} = useGetCustomersDetails({vehicleId});

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        {gdprCustomers.map((customer) => (
          <GdprConsentsList
            key={customer.id}
            customerId={customer.id}
            title={customer.fullName}
            subtitle={customer.ownershipTypeTranslated}
          />
        ))}
      </VStack>
    </DataStatus>
  );
};
