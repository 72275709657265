import {useRef} from 'react';

import {isNil} from 'ramda';

import {DataGridRef} from '../types/DataGridRef';

/**
 * @deprecated
 * use refreshDatagrid(gridCode) function from `features/datagrid`
 */
export const useRefreshDataGrid = () => {
  const ref = useRef<DataGridRef>(null);

  const refreshData = () => {
    if (!ref?.current || ref?.current?.isLoading) {
      return;
    }
    if (isNil(ref.current.refreshData)) {
      throw new Error('Ref object has not been assigned');
    }
    ref.current.refreshData();
  };

  return [ref, refreshData] as const;
};
