import {closeCurrentDialog, closeDialog, openDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, Main} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../components/FullscreenDataGridWrapper';
import {PriceListRowData} from '../../types/PriceListRowData';
import {ArticleCreate} from '../ArticleCreate/ArticleCreate';
import {PriceListDetail} from '../PriceListDetail/PriceListDetail';
import {PriceListExternalFilters} from './components/PriceListExternalFilters';

const PRICE_LIST_EXTERNAL_FILTER_ID = 'price-list-external-filter';

export function PriceList() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const priceListId = isArray(rowId) ? head(rowId) : rowId;
    const priceListRowData = rowData as PriceListRowData;

    if (isNil(priceListId)) {
      throw new Error('Price list id was not defined');
    }

    match(actionKey)
      .with('redirectDetail', () => {
        openDialog(
          <PriceListDetail
            id={priceListId}
            priceListRowData={priceListRowData}
            data-testid={testIds.warehouse.priceList('dialogs.detail')}
          />,
          {
            id: 'detailDialog',
            title: priceListRowData?.name?.value,
            size: 'large',
            withAdditionalFooter: true,
          }
        );
      })
      .with('createArticle', () => {
        openDialog(
          <ArticleCreate
            defaultValues={{
              catalogNumber: priceListRowData?.catalogNumber?.value,
              manufacturerId: priceListRowData?.manufacturerId?.value?.id,
              name: priceListRowData?.name?.value,
            }}
            onArticleCreate={(createdArticleData) => {
              if (createdArticleData.submittedButton === 'CREATE_AND_EDIT') {
                return navigate(
                  composePath(warehouseRoutes.articleDetailOverview, {
                    params: {
                      warehouseId: createdArticleData.formValues.warehouseId,
                      id: createdArticleData.articleId,
                    },
                  })
                );
              }

              closeCurrentDialog();
            }}
            onClose={() => closeDialog('createArticleDialog')}
            hasCreateAndEditButton
            data-testid={testIds.warehouse.priceList('dialogs.createArticle')}
          />,
          {
            id: 'createArticleDialog',
            title: i18n.t('entity.warehouse.labels.createNewArticle'),
            withAdditionalFooter: true,
          }
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.priceList')} />
      <Main heading={i18n.t('page.warehouse.labels.priceList')}>
        <FullscreenDataGridWrapper
          externalFilterId={PRICE_LIST_EXTERNAL_FILTER_ID}
          externalFilterHeight={105}
          data-testid={testIds.warehouse.priceList('externalFilterWithFolderTree')}
        >
          <DataGrid
            // DG must be re-rendered on folder change to update the query modifier
            // eslint-disable-next-line no-restricted-syntax
            gridCode="supplier-price-list"
            externalFilterId={PRICE_LIST_EXTERNAL_FILTER_ID}
            actionCallback={actionCallback}
            _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
            filterComponent={PriceListExternalFilters}
            data-testid={testIds.warehouse.priceList('priceList')}
            emptyState={{
              headline: i18n.t('page.warehouseDetail.labels.emptyAssortment'),
            }}
          />
        </FullscreenDataGridWrapper>
      </Main>
    </>
  );
}
