import {BreadcrumbType} from 'platform/components';

import {useGetSeriesQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {useRequiredParams} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DocumentSeriesForm} from './components/DocumentSeriesForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.document.labels.seriesTitle'),
    href: settingsRoutes.seriesDefinition,
  },
];

export function SeriesDefinitionEdit() {
  const {id} = useRequiredParams();

  const {
    data: series,
    isLoading,
    isError,
  } = useGetSeriesQuery({seriesId: id}, {refetchOnMountOrArgChange: true});

  return (
    <SettingsTemplate
      header={{breadcrumbs, title: i18n.t('entity.documentSeries.actions.updateSeries')}}
      data-testid="general-document-series-modal"
      isLoading={isLoading}
      isError={isError}
    >
      <DocumentSeriesForm series={series} />
    </SettingsTemplate>
  );
}
