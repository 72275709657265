import {DataStatus} from 'platform/components';
import {CartesianGrid, Scatter, ScatterChart, ScatterProps, Tooltip, XAxis, YAxis} from 'recharts';
import styled, {useTheme} from 'styled-components';

import {useCallback, useMemo} from 'react';
import {AutoSizer} from 'react-virtualized';

import {compact} from 'ramda-adjunct';

import {SourcingVehicleDetailResponseBody, VehicleTypeForPriceMap} from '@dms/api';

import {Circle} from './components/Circle';
import {MileageTickLabel} from './components/MileageTickLabel';
import {PriceMapTooltip} from './components/PriceMapTooltip';
import {PriceTickLabel} from './components/PriceTickLabel';

interface PriceMapProps {
  onStockVehicles?: VehicleTypeForPriceMap[];
  soldRecentlyVehicles?: VehicleTypeForPriceMap[];
  soldVehicles?: VehicleTypeForPriceMap[];
  currentVehicle: SourcingVehicleDetailResponseBody;
  selectedVehicleAdId?: string;
  onSelectVehicle: (vehicleAdId?: string) => void;
  isLoading: boolean;
}

interface OnClickHandleArgs {
  payload: SourcingVehicleDetailResponseBody;
}

const log10 = (n: number) => Math.floor(Math.log(n) * Math.LOG10E);

const roundNumber = (n: number) => {
  if (n === 0) {
    return 0;
  }

  const log = 10 ** log10(n);

  return Math.ceil(n / log) * log;
};

const getVehicleMileage = (vehicle: SourcingVehicleDetailResponseBody | VehicleTypeForPriceMap) =>
  vehicle?.sourcingVehicle?.mileage;
const getVehiclePrice = (vehicle: SourcingVehicleDetailResponseBody | VehicleTypeForPriceMap) =>
  vehicle?.sourcingVehicle?.price?.withVat;

export function PriceMap({
  currentVehicle,
  soldVehicles = [],
  onStockVehicles = [],
  soldRecentlyVehicles = [],
  selectedVehicleAdId,
  onSelectVehicle,
  isLoading,
}: PriceMapProps) {
  const theme = useTheme();
  const currentVehicleData = currentVehicle.sourcingVehicle;
  const currentVehicleWithPosition = useMemo(
    () => [
      {
        ...(currentVehicle || {}),
        mileage: currentVehicleData?.mileage,
        price: currentVehicle?.summary?.retail,
      },
    ],
    [currentVehicle, currentVehicleData]
  );

  const handleClick = useCallback(
    (selected: OnClickHandleArgs) => {
      onSelectVehicle(selected.payload.sourcingVehicle.adId);
    },
    [onSelectVehicle]
  );

  const {roundedMinPrice, roundedMinMileage} = useMemo(() => {
    const vehicles = [...onStockVehicles, ...soldRecentlyVehicles, ...soldVehicles];

    const min = Math.min(
      ...compact(vehicles.filter(getVehiclePrice).map(getVehiclePrice)),
      ...compact([currentVehicle?.summary?.retail].filter(Boolean))
    );

    return {
      roundedMinPrice: roundNumber(min),
      roundedMinMileage: roundNumber(
        Math.min(
          ...compact([...vehicles, currentVehicle].filter(getVehicleMileage).map(getVehicleMileage))
        )
      ),
    };
  }, [onStockVehicles, soldRecentlyVehicles, soldVehicles, currentVehicle]);

  const isEmpty = !onStockVehicles.length && !soldRecentlyVehicles.length && !soldVehicles.length;

  return (
    <PriceMapContainer data-testid="price-map-container">
      <DataStatus isLoading={isLoading} isEmpty={isEmpty} minHeight="100%">
        <AutoSizer>
          {({width, height}) => (
            <ScatterChart
              width={width}
              height={height}
              margin={{
                top: 24,
                right: 16,
                bottom: 8,
                left: 8,
              }}
              data-testid="price-map-scatter-chart"
            >
              <CartesianGrid
                strokeWidth={1}
                vertical={false}
                stroke={theme.colors.palettes.neutral[40][100]}
              />

              <XAxis
                stroke="0"
                type="number"
                name="mileage"
                dataKey="mileage"
                height={32}
                interval={0}
                tickCount={8}
                tickMargin={8}
                domain={['dataMin', 'auto']}
                padding={{right: 40, left: 40}}
                tick={(props) => <MileageTickLabel {...props} minValue={roundedMinMileage} />}
              />

              <YAxis
                stroke="0"
                name="price"
                type="number"
                dataKey="price"
                width={95}
                interval={0}
                tickCount={10}
                tickMargin={0}
                domain={['dataMin', 'auto']}
                tick={(props) => (
                  <PriceTickLabel
                    {...props}
                    minValue={roundedMinPrice}
                    currency={currentVehicleData?.price?.currency?.translation}
                  />
                )}
              />

              <Scatter
                name="currentVehicle"
                data={currentVehicleWithPosition}
                fill={theme.colors.palettes.blue[60][100]}
                shape={(props: ScatterProps) => <Circle {...props} isCurrentCar />}
                onClick={() => {
                  onSelectVehicle();
                }}
              />

              {onStockVehicles.length ? (
                <Scatter
                  name="onStockVehicles"
                  data={onStockVehicles}
                  fill={theme.colors.palettes.blue[50][100]}
                  shape={(props: ScatterProps) => (
                    <Circle {...props} selectedVehicleAdId={selectedVehicleAdId} />
                  )}
                  onClick={handleClick}
                />
              ) : null}

              {soldRecentlyVehicles.length ? (
                <Scatter
                  name="soldRecentlyVehicles"
                  data={soldRecentlyVehicles}
                  fill={theme.colors.palettes.orange[50][100]}
                  shape={(props: ScatterProps) => (
                    <Circle {...props} selectedVehicleAdId={selectedVehicleAdId} />
                  )}
                  onClick={handleClick}
                />
              ) : null}

              {soldVehicles.length ? (
                <Scatter
                  name="soldVehicles"
                  data={soldVehicles}
                  fill={theme.colors.palettes.green[50][100]}
                  shape={(props: ScatterProps) => (
                    <Circle {...props} selectedVehicleAdId={selectedVehicleAdId} />
                  )}
                  onClick={handleClick}
                />
              ) : null}

              <Tooltip
                cursor={false}
                isAnimationActive={false}
                content={(props) => <PriceMapTooltip {...props} currentVehicle={currentVehicle} />}
              />
            </ScatterChart>
          )}
        </AutoSizer>
      </DataStatus>
    </PriceMapContainer>
  );
}

const PriceMapContainer = styled.div`
  width: 100%;
  height: 100%;

  .recharts-text {
    fill: ${({theme}) => theme.colors.palettes.neutral[400][100]};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.16px;
  }
`;
