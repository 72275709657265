import {Show, Space} from 'platform/foundation';

import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {MultiBranchControl} from './components/MultiBranchControl';
import {QrCodeDefinitionForm} from './components/QrCodeDefinitionForm';

export function QrCodeDefinition() {
  /**
   * Loading needs to be handled inside <QrCodeDefinitionForm/> to avoid cache sharing issues
   */
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettings.labels.qrCodeDefinitionTitle'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      data-testid="settings-advertising-qrCodeDefinition"
    >
      <Show whenFeatureEnabled={featureFlags.SETTINGS_ADVERTISEMENT_MULTIBRANCH}>
        <MultiBranchControl />
        <Space vertical={4} />
      </Show>
      <QrCodeDefinitionForm />
    </SettingsTemplate>
  );
}
