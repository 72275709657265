import {Show} from 'platform/foundation';

import featureFlags from '@dms/feature-flags';

import {DeprecatedNotificationDetail} from './DeprecatedNotificationDetail';
import {NotificationDetail} from './NotificationDetail';

export function NotificationDetailWrapper() {
  return (
    <>
      <Show whenFeatureEnabled={featureFlags.MY_PROFILE}>
        <NotificationDetail />
      </Show>

      {/* When MY_PROFILE FF gets remove, delete this wrapper */}
      <Show whenFeatureDisabled={featureFlags.MY_PROFILE}>
        <DeprecatedNotificationDetail />
      </Show>
    </>
  );
}
