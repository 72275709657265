import {isFeatureEnabled} from 'feature-flags';
import {Show} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {isNotNil} from 'ramda';

import {EntityResourceIds, useGetParticipationQuery} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  DetailTemplate,
  EMPTY_PLACEHOLDER,
  SectionItem,
  getCustomerName,
  interestSearchParams,
  usePermissions,
} from '@dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {ActivityLogs} from './(sections)/ActiveLogs/ActivityLogs';
import {Buying} from './(sections)/Buying/Buying';
import {Customer} from './(sections)/Customer/Customer';
import {Overview} from './(sections)/Overview/Overview';
import {Selling} from './(sections)/Selling/Selling';
import {useInterestActions} from './hooks/useInterestActions';
import {useInterestData} from './hooks/useInterestData';
import {useInterestFlags} from './hooks/useInterestFlags';
import {useInterestParameters} from './hooks/useInterestParameters';
import {useInterestSnippets} from './hooks/useInterestSnippets';

export function InterestDetail() {
  const {id: interestId} = useRequiredParams();

  const {interest, customer, isLoading, isError} = useInterestData(interestId);

  const snippets = useInterestSnippets(interestId);
  const flags = useInterestFlags(interestId);
  const actions = useInterestActions(interestId);
  const parameters = useInterestParameters(interestId);

  const {data: interestParticipation} = useGetParticipationQuery({
    recordId: interestId,
    resourceId: EntityResourceIds.interest,
  });

  const [
    canReadInterestTag,
    hasAddInterestTagPermission,
    hasUpdateInterestTagPermission,
    hasAddInterestTagToRecordPermission,
    hasRemoveInterestTagFromRecordPermission,
    hasRemoveInterestTagPermission,
    hasClearInterestTagExpirationPermission,
    hasSetInterestTagExpirationPermission,
  ] = usePermissions({
    permissionKeys: [
      'interestTagRead',
      'interestTagAdd',
      'interestTagUpdate',
      'interestTagAddToRecord',
      'interestTagRemoveFromRecord',
      'interestTagRemove',
      'interestTagClearExpiration',
      'interestTagSetExpiration',
    ],
    scopes: {
      interestTagAddToRecord: {participation: interestParticipation},
      interestTagRemoveFromRecord: {participation: interestParticipation},
      interestTagRead: {participation: interestParticipation},
    },
  });

  const canCreateTag = hasAddInterestTagPermission && hasUpdateInterestTagPermission;

  const canAssignOrUnassignTag =
    hasAddInterestTagToRecordPermission && hasRemoveInterestTagFromRecordPermission;

  const canDeleteTag =
    hasRemoveInterestTagPermission &&
    hasClearInterestTagExpirationPermission &&
    hasSetInterestTagExpirationPermission;

  const sections = buildArray<SectionItem>()
    .add({
      id: interestSearchParams.interestDetail.overview,
      label: i18n.t('entity.interest.labels.overviewTab'),
      'data-testid': testIds.interest.detail('sectionItem-overview'),
      content: <Overview interestId={interestId} />,
    })
    .add({
      id: interestSearchParams.interestDetail.customer,
      label: i18n.t('entity.interest.labels.customerTab'),
      'data-testid': testIds.interest.detail('sectionItem-customer'),
      content: <Customer interestId={interestId} />,
    })
    .add({
      id: interestSearchParams.interestDetail.selling,
      label: i18n.t('entity.interest.labels.sellingTab'),
      'data-testid': testIds.interest.detail('sectionItem-selling'),
      content: <Selling interestId={interestId} />,
    })
    .add({
      id: interestSearchParams.interestDetail.buying,
      label: i18n.t('entity.interest.labels.buyingTab'),
      'data-testid': testIds.interest.detail('sectionItem-buying'),
      content: <Buying interestId={interestId} />,
      hasSeparator: isFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS),
    })
    .whenFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS, {
      id: interestSearchParams.interestDetail.actionLogs,
      label: i18n.t('general.labels.activityLogs'),
      'data-testid': testIds.interest.detail('sectionItem-activityLogs'),
      content: <ActivityLogs interestId={interestId} />,
    });

  return (
    <>
      <Show when={isNotNil(customer)}>
        <Helmet title={i18n.t('page.interest.title', {name: getCustomerName(customer)})} />
      </Show>
      <DetailTemplate
        key={interest?.id}
        isLoading={isLoading}
        isError={isError}
        header={{
          title: getCustomerName(customer) ?? EMPTY_PLACEHOLDER,
          icon: 'content/next_week',
          height: 61,
          recordId: interestId,
          resourceId: EntityResourceIds.interest,
          controls: ['ASSIGNEE', 'INTEREST_BRANCH'],
          flags,
          parameters,
          snippets,
          actions,
        }}
        sections={sections}
        data-testid={testIds.interest.detail('header')}
        isTagDeletable={canDeleteTag}
        isTagUpdatable={canCreateTag}
        isTagReadable={canReadInterestTag}
        isTagCreatable={canCreateTag}
        isTagAssignable={canAssignOrUnassignTag}
        isTagUnassignable={canAssignOrUnassignTag}
      />
    </>
  );
}
