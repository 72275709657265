import {Card, FieldValues, FormControl, FormField} from 'platform/components';
import {HStack, Show, Text, VStack} from 'platform/foundation';

import {FieldPathByValue} from 'react-hook-form';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps} from 'shared';

type Setting = {key: string; label: string; additionalInfo?: string};

interface ImportSettingsProps<T extends Setting, U extends FieldValues>
  extends RequiredTestIdProps {
  settings: T[];

  control: FormControl<U>;
}

export function ImportSettings<T extends Setting, U extends FieldValues>(
  props: ImportSettingsProps<T, U>
) {
  return (
    <Card
      data-testid="inlineGreyWithTitle"
      title={i18n.t('entity.calculationType.labels.importSettings')}
      variant="inlineGrey"
      isFullHeight
    >
      <VStack spacing={4}>
        <Text color="secondary">{i18n.t('entity.calculationType.labels.importSettingsInfo')}</Text>
        {props.settings.map((setting) => (
          <HStack key={setting.key} align="center" spacing={3}>
            <FormField
              name={setting.key as FieldPathByValue<U, boolean | Nullish>}
              type="switch"
              control={props.control}
            />
            <VStack>
              <Text>{setting.label}</Text>
              <Show when={setting.additionalInfo}>
                <Text>{setting.additionalInfo}</Text>
              </Show>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </Card>
  );
}
