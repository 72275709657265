import {Checkbox} from '../../Checkbox/Checkbox';
import {useFieldProps} from '../hooks/useFieldProps';
import {CheckboxRendererResponseBody} from '../types/modelSchema';

export function CheckboxRenderer(props: CheckboxRendererResponseBody) {
  const {name, tooltip} = props;
  const {onChange, value, isRequired, error} = useFieldProps(props);

  return (
    <Checkbox
      data-testid={name}
      label={props.label}
      value={value}
      isRequired={isRequired}
      errorMessage={error?.message}
      onChange={onChange}
      tooltip={tooltip}
      isDisabled={props.isReadOnly}
    />
  );
}
