import {VStack, Text} from 'platform/foundation';

import {Permission} from '@dms/api';
import {isActionPermission, permissions} from '@dms/shared';

const mapPermissions = () => {
  const output: Record<string, {title: string}> = {};

  for (const key in permissions) {
    if (permissions.hasOwnProperty(key)) {
      const permission = (permissions as Record<string, Permission>)[key];

      const protectedUnitId = isActionPermission(permission)
        ? permission.actionId
        : permission.fieldId;
      const newKey = `${permission.resourceId}${protectedUnitId}`;

      output[newKey] = {
        title: key,
      };
    }
  }

  return output;
};

const protectedUnitTitlesByRecordAndAction = mapPermissions();

export function ActionTooltip(props: {protectedUnitId: string; resourceId: string}) {
  const key = `${props.resourceId}${props.protectedUnitId}`;
  const title = protectedUnitTitlesByRecordAndAction[key]?.title;

  return (
    <VStack>
      <Text alternative color="white" size="small">
        {title}
      </Text>
    </VStack>
  );
}
