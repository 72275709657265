import {match} from 'ts-pattern';

import {Navigate} from 'react-router-dom';

import {settingsRoutes} from '@dms/routes';

import {useRequiredParams} from 'shared';

import {CreateMessageTemplateForm} from './components/CreateMessageTemplateForm';

export const MessageTemplateNew = () => {
  const {type} = useRequiredParams();
  return match(type)
    .with('email', 'sms', (type) => <CreateMessageTemplateForm templateType={type} />)
    .otherwise(() => <Navigate to={settingsRoutes.messageTemplates} />);
};
