import {Card} from 'platform/components';
import {Box, Grid, Hide, Show, VStack} from 'platform/foundation';

import {useCallback, useEffect} from 'react';

import {isNil, mergeAll} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  OfferResponseBody,
  useGetBusinessCaseQuery,
  useGetParticipationQuery,
  useGetSaleVehicleQuery,
  useGetVehicleV2Query,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CommentsWidget, PriceReportWidget, usePermissions, usePricePermissions} from '@dms/shared';

import {useRequiredParams} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {getPurchaseVehicleIdFromBusinessCase} from '../../../../utils/getPurchaseVehicleIdFromBusinessCase';
import {CentralizedPricingWidget} from './CentralizedPricing/index';
import {PricingWidget} from './SimplifiedPricing/index';

interface PricingTabProps {
  isCentralizedPricingEnabled?: boolean;
  isBrokerage?: boolean;
  offer: OfferResponseBody;
}

function PricingTab(props: PricingTabProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: purchaseVehicleId ?? '',
    },
    {skip: isNil(purchaseVehicleId)}
  );

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: businessCaseId,
    },
    {skip: isNil(businessCaseId)}
  );

  const {data: vehicle} = useGetVehicleV2Query(
    {vehicleId: purchaseVehicleId!},
    {skip: !purchaseVehicleId}
  );

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
    hasBusinessCasePurchasePricePermission,
    canReadPriceReport,
  ] = usePermissions({
    permissionKeys: [
      'vehicleCommentRead',
      'vehicleCommentAdd',
      'vehicleCommentDelete',
      'businessCasePurchasePrice',
      'createVehiclePriceReport',
    ],
    scopes: {
      vehicleCommentAdd: {participation: vehicleParticipation},
      vehicleCommentRead: {participation: vehicleParticipation},
      businessCasePurchasePrice: {participation: businessCaseParticipation},
    },
  });

  const {canReadVehiclePurchasePrice} = usePricePermissions({
    vehicleRecordId: purchaseVehicleId,
    businessCaseRecordId: null,
  });

  const {requestId} = useGetSaleVehicleQuery(
    {vehicleId: purchaseVehicleId ?? ''},
    {skip: isNil(purchaseVehicleId)}
  );

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: purchaseVehicleId}]),
    [purchaseVehicleId]
  );

  useEffect(() => {
    if (!dataGridRef.current) {
      return;
    }

    refreshDataGrid();
  }, [dataGridRef, requestId, refreshDataGrid]);

  return (
    <Grid columns={2}>
      <VStack spacing={4}>
        {props.isCentralizedPricingEnabled && !props.isBrokerage ? (
          <CentralizedPricingWidget />
        ) : (
          <PricingWidget
            isReadonly={!hasBusinessCasePurchasePricePermission}
            isBrokerage={props.isBrokerage}
            offer={props.offer}
          />
        )}

        <Show whenFeatureEnabled={featureFlags.PRICE_REPORT_WIDGET} when={canReadPriceReport}>
          <PriceReportWidget
            resourceId={purchaseVehicleId}
            vehicleType={vehicle?.type}
            context="vehicle"
            data-testid={testIds.businessCase.buying('priceReportWidget')}
          />
        </Show>
      </VStack>
      <Hide when={isNilOrEmpty(purchaseVehicleId)}>
        <VStack spacing={4}>
          <Show
            when={
              businessCase?.businessCaseInternalType === 'BUYING' && canReadVehiclePurchasePrice
            }
          >
            <Card title={i18n.t('entity.vehicle.labels.pricingHistory')} variant="inlineWhite">
              <Box minHeight={40}>
                <DataGrid
                  ref={dataGridRef}
                  gridCode="business_case_price_snapshot"
                  autoHeight
                  queryModifier={queryModifier}
                  data-testid={testIds.businessCase.buying('business_case_price_snapshot')}
                />
              </Box>
            </Card>
          </Show>
          <Show when={hasVehicleCommentReadPermission}>
            <CommentsWidget
              cardVariant="inlineWhite"
              title={i18n.t('entity.vehicle.labels.vehicleComments')}
              resourceId={EntityResourceIds.vehicle}
              recordId={purchaseVehicleId!}
              isAddDisabled={!hasVehicleCommentAddPermission}
              isDeleteDisabled={!hasVehicleCommentDeletePermission}
              data-testid={testIds.businessCase.buying('commentsWidget')}
            />
          </Show>
        </VStack>
      </Hide>
    </Grid>
  );
}

export const BusinessCasePurchaseVehiclePricing = PricingTab;
