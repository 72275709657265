import {Checkbox, DataStatus, Table} from 'platform/components';
import {Box, HStack, Heading, Scroll} from 'platform/foundation';

import {isEmpty} from 'ramda';

import {useGrantAllPermissionMutation, useRevokeAllPermissionMutation} from '@dms/api';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {getFixedTranslation} from '../../utils/getFixedTranslation';
import {ResourceItem} from './components/ResourceItem';
import {useGetACLData} from './hooks/useGetACLData';
import {filterAndMapResourcesBySearchString} from './utils/filterAndMapResourcesBySearchString';
import {getResourceCheckboxValues} from './utils/getResourceCheckboxValues';

interface AccessControlListParams extends TestIdProps {
  roleId: string;
  searchString: string | null;
}

export function AclDebugList(props: AccessControlListParams) {
  const [grantAllPermission] = useGrantAllPermissionMutation();
  const [revokeAllPermission] = useRevokeAllPermissionMutation();

  const {tableData, isError, isLoading} = useGetACLData(props.roleId);
  const filteredResources = filterAndMapResourcesBySearchString(props.searchString, tableData);

  const firstLevelCheckboxValues = filteredResources.map(getResourceCheckboxValues);
  const hasAllResourcesChecked = firstLevelCheckboxValues.every((item) => item.isChecked);
  const hasResourcesIndeterminate =
    !hasAllResourcesChecked &&
    firstLevelCheckboxValues.some((item) => item.isIndeterminate || item.isChecked);

  const handleCheckAll = (isGranting: boolean) => {
    const checkboxAction = isGranting ? grantAllPermission : revokeAllPermission;

    checkboxAction({roleId: props.roleId}).unwrap().catch(handleApiError);
  };

  return (
    <DataStatus
      spacing={10}
      isEmpty={isEmpty(filteredResources)}
      isLoading={isLoading}
      isError={isError}
    >
      <Scroll height={150} always>
        <Table
          data-testid={testIds.settings.roleManagementDetail('aclTable')}
          columns={[
            {
              element: (
                <Box paddingHorizontal={2} paddingVertical={4}>
                  <HStack align="center" spacing={21}>
                    <Checkbox
                      value={hasAllResourcesChecked}
                      isIndeterminate={hasResourcesIndeterminate}
                      onChange={handleCheckAll}
                      data-testid={suffixTestId('select-resource', props)}
                    />
                    <Box
                      paddingHorizontal={2}
                      borderLeft="solid 1px"
                      borderColor="general.separator"
                    >
                      <Heading color="secondary" size={5}>
                        {getFixedTranslation('page.settings.role.tabs.label.permissions')}
                      </Heading>
                    </Box>
                  </HStack>
                </Box>
              ),
              width: '70%',
            },
            {
              element: (
                <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
                  <HStack>
                    <Heading color="secondary" size={5}>
                      {getFixedTranslation('acl.permission.condition')}
                    </Heading>
                  </HStack>
                </Box>
              ),
              width: '30%',
            },
          ]}
        >
          {filteredResources?.map((resource) => (
            <ResourceItem
              data={resource}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={resource.id}
              roleId={props.roleId}
            />
          ))}
        </Table>
      </Scroll>
    </DataStatus>
  );
}
