import {Card} from 'platform/components';

import {useCallback} from 'react';

import {isArray} from 'ramda-adjunct';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, tiresInventoryRoutes} from '@dms/routes';
import {Section, SectionTab, useCustomerUrl, Tires as TiresDG} from '@dms/shared';

import {buildArray, composePath} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

export function Tires() {
  const {customerId} = useCustomerUrl();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      customerId,
    }),
    [customerId]
  );

  const tireOrdersActionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    switch (actionKey) {
      case 'detail':
        const path = composePath(tiresInventoryRoutes.tireOrderDetail, {params: {id}});
        window.open(path, '_blank')?.focus();

        break;
    }
  };

  const tabs = buildArray<SectionTab>([])
    .whenFeatureEnabled(featureFlags.AFTS_TIRE_ORDERS_CUSTOMER_CARD, {
      queryId: 'tireOrders',
      content: (
        <Card
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'action/open_in_new',
              title: i18n.t('page.tiresInventory.labels.tiresInventory'),
              onClick: () => window.open(tiresInventoryRoutes.overview, '_blank'),
            },
          ]}
          title={i18n.t('page.tiresInventory.labels.tireOrders')}
        >
          <DataGrid
            autoHeight
            gridCode="customer-tire-order"
            data-testid={testIds.customer.detail('tireOrders')}
            actionCallback={tireOrdersActionCallback}
            queryModifier={queryModifier}
          />
        </Card>
      ),
      title: i18n.t('page.tiresInventory.labels.tireOrders'),
    })
    .whenFeatureEnabled(featureFlags.AFTS_LIST_TIRES_CUSTOMER_CARD, {
      queryId: 'tires',
      content: <TiresDG customerId={customerId} gridCode="customer-tire-list" />,
      title: i18n.t('entity.customer.labels.listOfTires'),
    });

  return <Section tabs={tabs} />;
}
