import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';

import {Hyperlink, HyperlinkProps, LinkButton, TypographyCard} from '@dms/teas';

export const ContactPersonFormWrapper = styled.div`
  margin-top: ${({theme}) => theme.getSize(4)};
`;

export const RemoveButton = styled(Hyperlink)`
  margin-top: 7px;
  line-height: 16px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  color: ${({theme}) => theme.colors.palettes.red[70][100]};

  &:active,
  &:hover,
  &:visited {
    color: ${({theme}) => theme.colors.palettes.red[70][100]} !important;
  }

  &:before {
    border-color: ${({theme}) => theme.colors.palettes.red[70][100]} !important;
  }
`;

export const RemovePersonID = styled(RemoveButton)`
  margin-top: -10px !important;
  margin-bottom: 5px;
  display: block;
`;

const NewButton = styled(Hyperlink)`
  margin-top: 20px;
`;

export const AddPersonId = styled(NewButton)`
  display: block;
  margin-top: -7px !important;
  margin-bottom: 10px;
  line-height: 16px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
`;

export const AddContactPersonButton = styled(LinkButton)`
  margin-top: ${({theme}) => theme.getSize(3)};
`;

export const RemoveContactPersonButton = styled(LinkButton)`
  color: ${({theme}) => theme.colors.palettes.red[70][100]};
  margin-top: ${({theme}) => theme.getSize(3)};
`;

const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};

  .MuiGrid-grid-xs-8 {
    input {
      color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
      line-height: 20px;
    }
  }
`;

export const EmptyTextContainer = styled.div`
  margin-top: 8px;

  h4 {
    color: ${({theme}) => theme.colors.palettes.neutral[200][100]};
  }

  button {
    font-size: ${({theme}) => theme.fontSizes.text.small};
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  svg {
    color: ${({theme}) => theme.colors.palettes.neutral[80][100]};
  }
`;

export const StyledCard = styled(TypographyCard)`
  .card-content {
    padding: 0;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom: 0;
    overflow: hidden;
  }
`;

export const Divider = styled.div`
  background-color: white;
  height: ${({theme}) => theme.getSize(4)};
`;

export const FormContainerN20 = styled(FormContainer)`
  background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
`;

export const StyledLink = styled(Hyperlink).attrs<{icon?: ReactNode}, HyperlinkProps>((props) => ({
  icon: props.icon ?? <Icon value="action/open_in_new" />,
}))`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
`;

export const BillingInformationDetailContainer = styled.div`
  padding: ${({theme}) => theme.getSize(4)};

  .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  }
`;

export const H4WithMargin = styled.div`
  margin-bottom: ${({theme}) => theme.getSize(4)};
`;

export const Container = styled.div`
  width: 100%;
`;
