import {useAnimatedPopper} from 'platform/components';
import {Icon, Box, Center, Clickable} from 'platform/foundation';
import styled from 'styled-components';

import {MenuItemProps} from '@dms/api';

import {MenuItem} from './MenuItem';

interface CreateMenuItemProps {
  createMenuItems: MenuItemProps[];
}

export function CreateMenuItem(props: CreateMenuItemProps) {
  const {openPopper, referenceRef, popperProps, Popper, closePopper, isOpen} = useAnimatedPopper({
    placement: 'right-start',
  });

  return (
    <Clickable
      onClick={openPopper}
      onMouseEnter={openPopper}
      onMouseLeave={closePopper}
      data-testid="layout-createMenuItemContainer"
    >
      <StyledMenuItem ref={referenceRef} $isOpen={isOpen} data-testid="layout-createMenu">
        <Center width={12} height={12}>
          <Box
            height={8}
            width={8}
            borderRadius="medium"
            backgroundColor="palettes.blue.70.100"
            data-testid="layout-createMenu"
          >
            <Center width="100%" height="100%">
              <Icon value="content/add" color="palettes.neutral.10.100" size={6} />
            </Center>
          </Box>
        </Center>
      </StyledMenuItem>
      <Popper {...popperProps}>
        {props.createMenuItems.map((module) => (
          <MenuItem key={`${module.id}_${module.path}_${module.parentId}`} module={module} />
        ))}
      </Popper>
    </Clickable>
  );
}

type StyledMenuItemProps = {
  $isOpen: boolean;
};

const StyledMenuItem = styled.div<StyledMenuItemProps>`
  background-color: ${(props) =>
    props.$isOpen
      ? props.theme.colors.palettes.blue['70']['100']
      : props.theme.colors.palettes.neutral['900']['100']};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.blue['70']['100']};
  }
`;
