import {showNotification} from 'platform/components';

import {FC, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {patchEmailConfig, setEmailConfig, selectEmailConfig, useApiDispatch} from '@dms/teas';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../../components/SettingsStackItems/SettingsStackItems';

export const EmailServices: FC = () => {
  const dispatch = useDispatch();
  const apiDispatch = useApiDispatch();
  const emailConfig = useSelector(selectEmailConfig);

  const handleSwitchChange = useCallback(
    (enabled: boolean) => {
      dispatch(setEmailConfig({enabled}));
      apiDispatch(patchEmailConfig.action, {
        requestBody: {enabled},
      })
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        })
        .catch(() => {
          showNotification.error(i18n.t('general.notifications.changesSavingFailed'));
        });
    },
    [dispatch, apiDispatch]
  );

  const items = useMemo(
    () =>
      [
        {
          cyId: testIds.settings.emailServicesSmtp('link'),
          title: i18n.t('page.integrations.labels.emailServerSMTP'),
          url: settingsRoutes.emailServicesSmtp,
          switchProps: {
            name: 'smtp-server',
            value: emailConfig?.enabled,
            onChange: handleSwitchChange,
          },
        },
      ] as SettingsStackItemProps[],
    [emailConfig, handleSwitchChange]
  );

  return <SettingsStackItems items={items} />;
};
