import {bool, object} from 'yup';

export const yupDashboardFormSchema = object().shape({
  salesFunnelBusinessCases: bool(),
  salesFunnelInterests: bool(),
  hasSalesFunnel: bool(),
  vehicleStock: bool(),
  latestVehicles: bool(),
  latestBusinessCases: bool(),
  latestInterests: bool(),
  notifications: bool(),
  myTasks: bool(),
});
