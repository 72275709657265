import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {vehiclesRoutes} from '@dms/routes';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback} from 'features/datagrid';

export function useVehiclesActionCallback(): [ActionCallback] {
  const navigate = useNavigate();

  return [
    ({actionKey, rowId, sourceSearchParams}) => {
      if (isArray(rowId)) {
        return;
      }

      const path = composePath(vehiclesRoutes.detail, {
        queryParams: sourceSearchParams,
        params: {id: rowId},
        isSearchParamsPreserved: false,
      });

      match(actionKey)
        .with('detail', () => navigate(path))
        .with('detail_newTab', () => window.open(path))
        .otherwise(noop);
    },
  ];
}
