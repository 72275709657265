import {Action} from 'platform/components';

import {Helmet} from 'react-helmet-async';
import {useMatch} from 'react-router-dom';

import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {Main, PageTab} from '@dms/shared';

import {buildArray, useNavigate} from 'shared';

import {CostCorrections} from './components/CostCorrections';
import {IssueNoteCorrections} from './components/IssueNoteCorrections';
import {IssueNotes} from './components/IssueNotes';
import {ServiceOrderIssues} from './components/ServiceOrderIssues';
import {ServiceOrderReturns} from './components/ServiceOrderReturns';

export function ServiceOrderIssueList() {
  const navigate = useNavigate();

  const serviceOrderIssuesMatch = useMatch(warehouseRoutes.serviceOrderIssues);
  const issueNotesMatch = useMatch(warehouseRoutes.issueNotes);
  const serviceOrderReturnsMatch = useMatch(warehouseRoutes.serviceOrderReturns);

  const tabs: PageTab[] = [
    {
      id: 'serviceOrderIssues',
      title: i18n.t('page.warehouse.labels.serviceOrderIssues'),
      content: <ServiceOrderIssues />,
      href: warehouseRoutes.serviceOrderIssues,
    },
    {
      id: 'serviceOrderReturns',
      title: i18n.t('page.warehouse.labels.serviceOrderReturns'),
      content: <ServiceOrderReturns />,
      href: warehouseRoutes.serviceOrderReturns,
    },
    {
      id: 'issueNotes',
      title: i18n.t('page.warehouse.labels.issueNotes'),
      content: <IssueNotes />,
      href: warehouseRoutes.issueNotes,
    },
    {
      id: 'issueNoteCorrections',
      title: i18n.t('page.warehouse.labels.issueNoteCorrections'),
      content: <IssueNoteCorrections />,
      href: warehouseRoutes.issueNoteCorrections,
    },
    {
      id: 'costCorrections',
      title: i18n.t('page.warehouse.labels.costCorrections'),
      content: <CostCorrections />,
      href: warehouseRoutes.costCorrections,
    },
  ];

  const actions = buildArray<Action>()
    .when(serviceOrderIssuesMatch || issueNotesMatch, {
      type: 'button',
      title: i18n.t('entity.warehouse.labels.newServiceOrderIssue'),
      onClick: () => navigate(warehouseRoutes.serviceOrderIssueCreate),
      'data-testid': testIds.warehouse.serviceOrderIssues('newServiceOrderIssue'),
    })
    .when(serviceOrderReturnsMatch, {
      type: 'button',
      title: i18n.t('page.warehouse.actions.newServiceOrderReturn'),
      onClick: () => navigate(warehouseRoutes.serviceOrderReturnsCreate),
      'data-testid': testIds.warehouse.serviceOrderReturns('newServiceOrderReturn'),
    });

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main isFullHeight tabs={tabs} actions={actions} />
    </>
  );
}
