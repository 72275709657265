import {Card, DataStatus} from 'platform/components';

import {isNil, path} from 'ramda';

import {
  GetCustomerRequestedPriceApiArg,
  SaleVehicleResponseBody,
  useGetBusinessCaseQuery,
  useGetCustomerRequestedPriceQuery,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {Nullish, useRequiredParams, useToggle} from 'shared';

import {getHeaderActions} from '../../SimplifiedPricing/utils/formGetters';
import {RequestedPriceDetail} from './RequestedPriceDetail';
import {RequestedPriceForm} from './RequestedPriceForm';

interface RequestedPriceWidgetProps {
  BusinessCaseProgressState: SaleVehicleResponseBody['buyingState'] | Nullish;
}

export function RequestedPriceWidget(props: RequestedPriceWidgetProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? (businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0] ?? null)
      : (businessCase?.offers?.[0]?.purchaseVehicles?.[0] ?? null);

  const requestedPriceProps: GetCustomerRequestedPriceApiArg = {
    businessCaseId,
    offerPurchaseVehicleId: purchaseVehicle?.id ?? '',
    offerId: businessCase?.offers[0]?.id ?? '',
  };

  const {
    data: requestedPrice,
    isError: isRequestedPriceError,
    error,
    isLoading: isLoadingRequestedPrice,
  } = useGetCustomerRequestedPriceQuery(requestedPriceProps);
  const isRequestedPriceEmpty = path(['status'], error) === 404;

  const [isDetail, toggleDetail] = useToggle(!!purchaseVehicle?.costs.costsOn);

  const editable =
    (businessCase?.actions.purchaseEditVehicle ?? false) &&
    props.BusinessCaseProgressState === 'buying' &&
    isNil(purchaseVehicle?.pricing.pricedOn);

  const isLoading = isLoadingRequestedPrice;
  const isError = isRequestedPriceError && !isRequestedPriceEmpty;

  return (
    <Card
      actions={getHeaderActions(isDetail, editable, toggleDetail)}
      title={i18n.t('general.labels.requestedPrice')}
      data-testid={testIds.businessCase.buying('requested-pricing-card')}
      variant="inlineWhite"
    >
      {isDetail ? (
        <DataStatus isError={isError} isLoading={isLoading}>
          <RequestedPriceDetail requestedPrice={requestedPrice} />
        </DataStatus>
      ) : (
        <DataStatus isError={isError}>
          <RequestedPriceForm toggleDetail={toggleDetail} {...requestedPriceProps} />
        </DataStatus>
      )}
    </Card>
  );
}
