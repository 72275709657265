import {isFalse, isTrue} from 'ramda-adjunct';

import {MinipurchaseResponseBody} from '@dms/api';

import {Nullish} from 'shared';

import {ExternalFilter} from '../types/ExternalFilter';

export const transformMinipurchaseToExternalFilter = (
  miniPurchase: MinipurchaseResponseBody | Nullish
): ExternalFilter => ({
  type: [miniPurchase?.filteredSpecifications?.type ?? 'VEHICLETYPE_PASSENGER_CAR'],
  make: miniPurchase?.filteredSpecifications?.make ?? null,
  modelFamily: miniPurchase?.filteredSpecifications?.modelFamily ?? null,
  fuelType: miniPurchase?.filteredSpecifications?.fuelType ?? null,
  transmission: miniPurchase?.filteredSpecifications?.transmission ?? null,
  bodyStyle: miniPurchase?.filteredSpecifications?.bodyStyle ?? null,
  bodyColor: miniPurchase?.filteredSpecifications?.bodyColor ?? null,
  drive: miniPurchase?.filteredSpecifications?.drive ?? null,
  priceFrom: miniPurchase?.filteredSpecifications?.price?.[0] ?? null,
  priceTo: miniPurchase?.filteredSpecifications?.price?.[1] ?? null,
  mileageFrom: miniPurchase?.filteredSpecifications?.mileage?.[0] ?? null,
  mileageTo: miniPurchase?.filteredSpecifications?.mileage?.[1] ?? null,
  powerFrom: miniPurchase?.filteredSpecifications?.power?.[0] ?? null,
  powerTo: miniPurchase?.filteredSpecifications?.power?.[1] ?? null,
  yearFrom: miniPurchase?.filteredSpecifications?.year?.[0] ?? null,
  yearTo: miniPurchase?.filteredSpecifications?.year?.[1] ?? null,
  cubicCapacityFrom: miniPurchase?.filteredSpecifications?.cubicCapacity?.[0] ?? null,
  cubicCapacityTo: miniPurchase?.filteredSpecifications?.cubicCapacity?.[1] ?? null,
  vatDeductible: isTrue(miniPurchase?.filteredSpecifications?.vatDeductible)
    ? ['true']
    : isFalse(miniPurchase?.filteredSpecifications?.vatDeductible)
      ? ['false']
      : null,
  additionalInformation: miniPurchase?.additionalInformation ?? null,
  features: miniPurchase?.filteredSpecifications?.features ?? [],
});
