import {Card, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {usePatchArticleDefaultSupplierMutation, usePatchArticleInfoMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {CatalogFilter} from '../../../../../types/CatalogFilter';

interface OverviewCatalogsInformationProps extends TestIdProps {
  catalogFilter: CatalogFilter;
}

export function OverviewCatalogsInformation(props: OverviewCatalogsInformationProps) {
  const {articleId} = useWarehouseParams();

  const [patchArticleInfo] = usePatchArticleInfoMutation();
  const [setDefaultSupplier] = usePatchArticleDefaultSupplierMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    const name = path(['name', 'value'], rowData) as string;
    const supplierId = path(['supplierId', 'value'], rowData) as string;

    if (isNil(id)) {
      throw new Error('Row id is not defined');
    }

    if (isNil(name)) {
      throw new Error('Name is not defined');
    }

    if (isNil(supplierId)) {
      throw new Error('Supplier id is not defined');
    }

    match(actionKey)
      .with('updateArticle', () => {
        patchArticleInfo({articleId, body: {name}})
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.warehouse.notifications.articleInfoUpdated'))
          )
          .then(refreshData);
      })
      .with('setAsDefault', () => {
        setDefaultSupplier({articleId, body: {supplierId}})
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.warehouse.notifications.defaultSupplierSet'))
          )
          .then(refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          branchId: props.catalogFilter.branchId,
          supplierId: props.catalogFilter.supplierId,
          manufacturerId: props.catalogFilter.manufacturerId,
          oemCode: props.catalogFilter.manufacturerNumber,
        },
      ]),
    [
      props.catalogFilter.branchId,
      props.catalogFilter.supplierId,
      props.catalogFilter.manufacturerId,
      props.catalogFilter.manufacturerNumber,
    ]
  );

  return (
    <Card title={i18n.t('entity.warehouse.labels.catalogsInformation')}>
      <DataGrid
        autoHeight
        gridCode="warehouse-article-catalogs-info"
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        data-testid={suffixTestId('datagrid', props)}
      />
    </Card>
  );
}
