import {find, propEq} from 'ramda';

import {useGetBillingInformationListQuery} from '@dms/api';

export function useBillingInformation() {
  const {data} = useGetBillingInformationListQuery();

  const options = data?.billingInformationListItems.map((billingInformation) => ({
    label: billingInformation.companyName,
    value: billingInformation.id,
  }));

  const getNameById = (id: string) => find(propEq(id, 'value'), options ?? [])?.label;

  return {options, getNameById};
}
