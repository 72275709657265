import {z} from 'zod';

import {BasePriceSchema} from './aftersalesCommon';

/**
 * GET
 */
export const GetCostCorrectionRequestSchema = z.object({
  costCorrectionId: z.string(),
});
export type GetCostCorrectionRequest = z.infer<typeof GetCostCorrectionRequestSchema>;

export const GetCostCorrectionResponseSchema = z.object({
  costCorrectionInfo: z.object({
    costCorrectionId: z.string(),
    number: z.string(),
    warehouseId: z.string(),
    createdBy: z.string(),
    dateAndTimeOfTheCreation: z.string(),
  }),
  receiveNoteCorrectionInfo: z.object({
    receiveNoteCorrectionId: z.string(),
    number: z.string(),
    createdBy: z.string(),
    createdDate: z.string(),
  }),
  totalValueItems: BasePriceSchema,
});
export type GetCostCorrectionResponse = z.infer<typeof GetCostCorrectionResponseSchema>;
