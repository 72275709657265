import {useEffect} from 'react';

import i18n from '@dms/i18n';
import {getKonzultaConfig, useThunkDispatch} from '@dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {Gateways} from './components/Gateways';

export function SmsGateways() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getKonzultaConfig.action({}));
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.smsGateways')}}
      data-testid="integrations-smsGateways-settings"
    >
      <Gateways />
    </SettingsTemplate>
  );
}
