import {Actions, CreatableMultiChoice, MultiChoice, OptionType} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {curry, difference, eqBy, forEach, map, pipe, prop, propEq, unionWith} from 'ramda';
import {findOr} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {CardTagSelectionTagType} from './types';

type EditDialogProps = {
  tags: CardTagSelectionTagType[];
  predefinedTags: CardTagSelectionTagType[];
  onClose: () => void;
  onDelete: (opt: CardTagSelectionTagType) => void;
  onCreate: (opt: CardTagSelectionTagType[]) => void;
  isCreationDisabled?: boolean;
};

export function EditDialog(props: EditDialogProps) {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [value, setValue] = useState<string[]>([]);

  // predefinedTags and tags are provided asynchronously, so it is not possible to use initialState.
  useEffect(() => {
    const oldValue = mapTagsToIds(props.tags);
    const options = mapTagsToOptions(
      unionWith(eqBy(prop('label')), props.tags, props.predefinedTags)
    );

    setValue(oldValue);
    setOptions(options);
  }, [props.predefinedTags, props.tags]);

  const handleSave = () => {
    const oldValue = mapTagsToIds(props.tags);

    const added = mapValuesToTags(options, difference(value, oldValue));
    const deleted = mapValuesToTags(options, difference(oldValue, value));

    forEach(props.onDelete, deleted);
    props.onCreate(added);

    props.onClose();
  };

  const handleCreateOption = (inputValue: string) => {
    setOptions((prev) => [
      ...prev,
      {
        value: inputValue,
        label: inputValue,
      },
    ]);

    setValue((prev) => [...prev, inputValue]);
  };

  const multiChoiceProps = {
    hasOptionCheckbox: true,
    closeMenuOnSelect: false,
    value,
    options,
    onChange: (value: string[] | null) => setValue(value ?? []),
    isNotClearable: true,
  };

  return (
    <VStack spacing={4}>
      <Show when={props.isCreationDisabled}>
        <MultiChoice {...multiChoiceProps} />
      </Show>
      <Hide when={props.isCreationDisabled}>
        <CreatableMultiChoice {...multiChoiceProps} onCreateOption={handleCreateOption} />
      </Hide>
      <Actions
        align="right"
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            onClick: props.onClose,
            title: i18n.t('general.actions.discard'),
          },
          {
            type: 'button',
            variant: 'primary',
            onClick: handleSave,
            title: i18n.t('general.actions.save'),
          },
        ]}
      />
    </VStack>
  );
}

const tagToOption = (tag: CardTagSelectionTagType): OptionType => ({
  value: tag.id,
  label: tag.label,
});
const tagId = (tag: CardTagSelectionTagType): string => tag.id;
const mapTagsToOptions = map(tagToOption);
const mapTagsToIds = map(tagId);

// Currying is necessary for mapValuesToTags usage in map.
const findOptionByValue = curry(
  (options: OptionType[], value: string): OptionType =>
    findOr({value, label: value}, propEq(value, 'value'), options)
);
const optionToTag = (option: OptionType): CardTagSelectionTagType => ({
  id: option.value,
  label: option.label,
});
const mapValuesToTags = (options: OptionType[], values: string[]): CardTagSelectionTagType[] =>
  pipe(map<string, OptionType>(findOptionByValue(options)), map(optionToTag))(values);
