import {findTopMostItem, VehicleCatalogueEnumOption} from '@dms/teas';

/**
 * We need to find whole item with given key.
 * Features are nested, so we need to find the highest node and then we are going through all children (recursively).
 * @param key key of feature, we are looking whole object
 * @param allFeatures list of all features from catalogue
 */
export const findFeatureItem = (
  key: string,
  allFeatures?: VehicleCatalogueEnumOption[]
): VehicleCatalogueEnumOption | undefined => {
  const topLevelItem = findTopMostItem(key, allFeatures);

  if (topLevelItem?.value === key) {
    return topLevelItem;
  }

  const findItemRecursive = (
    findKey: string,
    nodes: VehicleCatalogueEnumOption[]
  ): VehicleCatalogueEnumOption | undefined => {
    for (const item of nodes) {
      if (item.value === findKey) {
        return item;
      }
      if (item.children?.length) {
        const found = findItemRecursive(findKey, item.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };
  return findItemRecursive(key, topLevelItem?.children ?? []);
};
