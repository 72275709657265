import {parseISO} from 'date-fns';
import {Column, DataStatus, Flag, IconButton, Label, Table, TableRow} from 'platform/components';
import {Box, Hide, HStack, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  accountingApi,
  InvoiceProformaPaymentResponseBody,
  metadaAftersalesCheckoutApi,
  Payment,
  useCancelTransactionFromPaymentPrescriptionMutation,
  useGetInvoicePaymentListQuery,
  useGetInvoiceProformaPaymentListQuery,
  useRenderCorrectiveTaxDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
  useRenderTaxDocumentForPaymentDocumentMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {
  buildArray,
  convertStringToCamelCase,
  EMPTY_PLACEHOLDER,
  openFile,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';

interface PaymentsListProps extends RequiredTestIdProps {
  checkoutId: string;
  payment: Payment;
}

export function PaymentsList(props: PaymentsListProps) {
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useFormatCurrency();

  const {
    data: proformaPayments,
    isLoading: isLoadingProformaPayments,
    isFetching: isFetchingProformaPayments,
    isError: isErrorProformaPayments,
  } = useGetInvoiceProformaPaymentListQuery(
    {invoiceId: props.payment.proformaInvoiceId ?? ''},
    {skip: isNil(props.payment.proformaInvoiceId), refetchOnMountOrArgChange: true}
  );

  const {
    data: invoicePayments,
    isLoading: isLoadingInvoicePayments,
    isFetching: isFetchingInvoicePayments,
    isError: isErrorInvoicePayments,
  } = useGetInvoicePaymentListQuery(
    {invoiceId: props.payment.invoiceId ?? ''},
    {skip: isNil(props.payment.invoiceId), refetchOnMountOrArgChange: true}
  );

  const [renderTaxDocumentForPayment, {isLoading: isRenderingTaxDocumentForPayment}] =
    useRenderTaxDocumentForPaymentDocumentMutation();

  const [renderIncomeCashReceipt, {isLoading: isRenderingIncomeCashReceipt}] =
    useRenderIncomeCashReceiptDocumentMutation();

  const [renderCorrectiveTaxDocument, {isLoading: isRenderingCorrectiveTaxDocument}] =
    useRenderCorrectiveTaxDocumentMutation();

  const [cancelTransactionFromPaymentPrescription, {isLoading: isWithdrawalPending}] =
    useCancelTransactionFromPaymentPrescriptionMutation();

  const openTaxDocumentForPayment = (documentId: string) =>
    renderTaxDocumentForPayment({renderTaxDocumentForPaymentDocumentRequestBody: {documentId}})
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .catch(handleApiError);

  const openCashReceipt = (cashRegisterDocumentId: string) => {
    renderIncomeCashReceipt({
      renderIncomeCashReceiptDocumentRequestBody: {cashRegisterDocumentId},
    })
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .then(() => {
        accountingApi.util.invalidateTags(['invoicePayment', 'invoiceProformaPaymentList']);
      })
      .catch(handleApiError);
  };

  const openCorrectiveTaxDocument = (documentId: string) =>
    renderCorrectiveTaxDocument({renderCorrectiveTaxDocumentRequestBody: {documentId}})
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .catch(handleApiError);

  const handleWithdrawPayment = (paymentId: string, transactionId: string) => {
    cancelTransactionFromPaymentPrescription({
      paymentPrescriptionId: paymentId,
      transactionId: transactionId,
    })
      .unwrap()
      .then(() =>
        metadaAftersalesCheckoutApi.util.invalidateTags([
          {type: 'afsPaymentIssueRestriction', id: 'LIST'},
          {type: 'afsPaymentIssueRestriction', id: props.payment.checkoutPaymentId},
          {type: 'afsPaymentById', id: props.payment.checkoutPaymentId},
          {type: 'afsPaymentsList', id: props.payment.checkoutPaymentId},
          'profitabilityHistory',
          'serviceJobActions',
          'orderActions',
          'serviceJobActions',
          'serviceActions',
          'serviceCase',
          'serviceCaseTotalAmount',
          'serviceOrders',
          'serviceOrder',
          'serviceOrderJobs',
        ])
      )
      .catch(handleApiError);
  };

  const columns = buildArray<Column>()
    .add({
      width: 20,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('general.labels.type')}</Label>
        </Box>
      ),
    })
    .add({
      width: 35,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.bank.labels.paymentType')}</Label>
        </Box>
      ),
    })
    .add({
      width: 35,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.checkout.labels.amount')}</Label>
        </Box>
      ),
    })
    .add({
      width: 30,
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.invoice.labels.dateOfPayment')}</Label>
        </Box>
      ),
    })
    .add({
      element: (
        <Box paddingHorizontal={2}>
          <Label>{i18n.t('entity.checkout.labels.file')}</Label>
        </Box>
      ),
    })
    .add({
      width: 18,
    });

  const isPaymentDeposit = props.payment.discriminator === 'DEPOSIT';
  const isLoading = isLoadingProformaPayments || isLoadingInvoicePayments;
  const isError = isErrorProformaPayments || isErrorInvoicePayments;
  const isRendering =
    isRenderingTaxDocumentForPayment ||
    isRenderingIncomeCashReceipt ||
    isRenderingCorrectiveTaxDocument;

  const payments = [...(proformaPayments ?? []), ...(invoicePayments ?? [])];
  const arePaymentsEmpty = isNilOrEmpty(payments);

  const isFetchingPayments = isFetchingProformaPayments || isFetchingInvoicePayments;

  return (
    <DataStatus isEmpty={arePaymentsEmpty} isLoading={isLoading} isError={isError} spacing={5}>
      <Table
        tableLayout="fixed"
        columns={columns}
        data-testid={suffixTestId('payment-table', props)}
      >
        {payments?.map((item) => {
          const isPaymentCancelledOrCancelling =
            isNotNil(item.cancelingTransactionId) || isNotNil(item.cancelledTransactionId);
          const shouldHideRowActions = isPaymentDeposit || isPaymentCancelledOrCancelling;
          const hasCashReceiptId = isNotNil(item.cashRegisterDocumentId);
          const hasTaxDocumentForPaymentId = hasTaxDocumentForPayment(item);
          const hasCorrectiveTaxDocumentId = isNotNil(
            item.correctiveTaxDocumentForTaxDocumentForPaymentId
          );

          return (
            <TableRow key={item.paymentId} data-testid={suffixTestId('table-row', props)}>
              <Box padding={2}>
                <Flag
                  label={i18n.t(`entity.checkout.labels.${item.source}`)}
                  colorScheme={item.source === 'external' ? 'yellow' : 'green'}
                  isSubtle
                  data-testid={suffixTestId('transaction-source', props)}
                />
              </Box>

              <Box padding={2}>
                <Text size="small">
                  {i18n.t(
                    `entity.invoice.paymentMethod.${convertStringToCamelCase(item.paymentType)}`
                  )}
                </Text>
              </Box>
              <Box padding={2}>
                <Text size="small">
                  {formatCurrency(parseFloat(item.amount.amount), item.amount.currency, 2)}
                </Text>
              </Box>
              <Box padding={2}>
                <Text size="small">{formatDateTime('dateShort', parseISO(item.paymentDate))}</Text>
              </Box>

              <Box padding={2}>
                {hasTaxDocumentForPaymentId && (
                  <Link
                    isDisabled={isRendering}
                    size="small"
                    title={`${i18n.t('entity.accounting.labels.taxDocumentForPayment')} ${
                      item.taxDocumentForPaymentNumber
                    }`}
                    onClick={() => openTaxDocumentForPayment(item.taxDocumentForPaymentId!)}
                    data-testid={suffixTestId('transaction-taxDocumentForPayment', props)}
                  />
                )}

                <Show when={hasCashReceiptId}>
                  <Link
                    isDisabled={isRendering}
                    size="small"
                    title={`${i18n.t('entity.checkout.labels.cashReceipt')} ${
                      item.cashRegisterDocumentNumber
                    }`}
                    onClick={() => openCashReceipt(item.cashRegisterDocumentId!)}
                    data-testid={suffixTestId('transaction-cashReceipt', props)}
                  />
                </Show>

                <Show when={hasCorrectiveTaxDocumentId}>
                  <Link
                    isDisabled={isRendering}
                    size="small"
                    title={`${i18n.t('entity.checkout.labels.correctiveTaxDocument')} ${
                      item.correctiveTaxDocumentForTaxDocumentForPaymentNumber
                    }`}
                    onClick={() =>
                      openCorrectiveTaxDocument(
                        item.correctiveTaxDocumentForTaxDocumentForPaymentId!
                      )
                    }
                    data-testid={suffixTestId('transaction-correctiveTaxDocument', props)}
                  />
                </Show>

                <Hide
                  when={
                    hasTaxDocumentForPaymentId || hasCashReceiptId || hasCorrectiveTaxDocumentId
                  }
                >
                  {EMPTY_PLACEHOLDER}
                </Hide>
              </Box>
              <HStack justify="flex-end">
                <Hide when={shouldHideRowActions}>
                  <IconButton
                    severity="danger"
                    icon="action/delete"
                    onClick={() => handleWithdrawPayment(item.paymentId, item.transactionId)}
                    isDisabled={isWithdrawalPending || isFetchingPayments}
                    data-testid={suffixTestId('transaction-delete', props)}
                  />
                </Hide>
              </HStack>
            </TableRow>
          );
        })}
      </Table>
    </DataStatus>
  );
}

const hasTaxDocumentForPayment = (val: unknown): val is InvoiceProformaPaymentResponseBody =>
  isNotNil((val as InvoiceProformaPaymentResponseBody)?.taxDocumentForPaymentId);
