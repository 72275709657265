import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  DropdownActionMenuItem,
  FlagProps,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {always, any, defaultTo, find, isNil, isNotNil, pipe, prop, reject, sortBy} from 'ramda';
import {
  concatAll,
  isNonEmptyArray,
  isNonPositive,
  isPositive,
  isTruthy,
  mapIndexed,
} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetCaseItemsTotalAmountQuery,
  useGetCustomerV2Query,
  useGetDocumentContextCountQuery,
  useGetMetadaServiceCaseQuery,
  useGetPostponeJobsQuery,
  useGetServiceCaseActionsQuery,
  useGetServiceCaseOrderVariantsQuery,
  useGetServiceCheckInQuery,
  useGetTireVehicleCountQuery,
  useGetVehicleCustomerQuery,
  useGetVehicleLocalActionNoteQuery,
  useGetVehicleQuery,
  useGetVehicleServiceCampaignQuery,
  usePostServiceCaseActionReOpenMutation,
  usePostServiceCaseActionCancelMutation,
  usePostServiceOrderMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, workshopRoutes} from '@dms/routes';
import {
  DEFAULT_CURRENCY,
  DEFAULT_PRICE,
  DetailTemplate,
  DetailTemplateHeader,
  OwnershipSection,
  SectionItem,
  Tires,
  getCustomerName,
  handleApiError,
  queryParams,
  usePermissions,
  useStateFlag,
  workshopSearchParams,
} from '@dms/shared';

import {Nullish, buildArray, buildObject, composePath, useQueryState} from 'shared';

import {SOURCE_DATA_GRID} from 'features/datagrid';

import {IntegrationsDropdown} from '../../components/IntegrationsDropdown/IntegrationsDropdown';
import {CHECKIN_SECTION, HANDOVER_SECTION} from '../../constants/detailSections';
import {useWorkshopUrl} from '../../hooks/useWorkshopUrl';
import {useXentryIntegration} from '../../hooks/useXentryIntegration';
import {getSparePartStateFlag} from '../../utils/getSparePartStateFlag';
import {getTimeTrackingStateFlag} from '../../utils/getTimeTrackingStateFlag';
import {ActivityLogs} from './(sections)/ActivityLogs/ActivityLogs';
import {CheckinHandover} from './(sections)/CheckinHandover/CheckinHandover';
import {ServiceCaseDocuments} from './(sections)/Documents/ServiceCaseDocuments';
import {HistoryOfRepairs} from './(sections)/HistoryOfRepairs/HistoryOfRepairs';
import {Orders} from './(sections)/Order/Orders';
import {Overview} from './(sections)/Overview/Overview';
import {Photos} from './(sections)/Photos/Photos';
import {Postponed} from './(sections)/PostponedRequests/Postponed';
import {VehicleCampaigns} from './(sections)/ServiceCampaigns/VehicleCampaigns';
import {ServiceIntervals} from './(sections)/ServiceIntervals/ServiceIntervals';
import {Vehicle} from './(sections)/Vehicle/Vehicle';
import {useServiceCaseVehicleHeaderParameters} from './hooks/useServiceCaseVehicleHeaderParameters';
import {ServiceActionOptionType} from './types/serviceActionOptionType';
import {getMarkersFlags} from './utils/getMarkersFlags';

export function ServiceCaseDetail() {
  const formatCurrency = useFormatCurrency();
  const navigate = useNavigate();

  const [isChronosEnabled, canReadPneuhotel, canCreateServiceOrder] = usePermissions({
    permissionKeys: ['chronosUser', 'pneuhotelRead', 'serviceOrderCreate'],
  });

  const {serviceCaseId} = useWorkshopUrl();

  const [searchParams, setSearchParams] = useSearchParams();

  const [, setOrderId] = useQueryState(queryParams.SERVICE_CASE_ORDER_ID);

  const [postServiceOrder, {isLoading: isPostServiceOrderLoading}] = usePostServiceOrderMutation();
  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isFetching: isServiceCaseFetching,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId});
  const {
    data: checkIn,
    isLoading: isCheckInLoading,
    isError: isCheckinError,
  } = useGetServiceCheckInQuery({serviceCaseId});
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', serviceCase?.vehicleId)},
    {skip: !serviceCase?.vehicleId}
  );
  const {data: postponedJobs} = useGetPostponeJobsQuery({serviceCaseId});
  const {data: orderVariants} = useGetServiceCaseOrderVariantsQuery({serviceCaseId});
  const {
    data: vehicleCustomers,
    isLoading: isVehicleCustomersLoading,
    isError: isVehicleCustomersError,
  } = useGetVehicleCustomerQuery({vehicleId: vehicle?.id}, {skip: !vehicle});

  const vehicleOperator = find(
    (vehicleCustomer) => vehicleCustomer?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
    vehicleCustomers ?? []
  );

  const {xentryAlert} = useXentryIntegration({
    serviceCaseId,
    'data-testid': testIds.workshop.createServiceCase('xentry'),
  });

  const {
    data: vehicleOperatorCustomer,
    isLoading: isVehicleOperatorCustomerLoading,
    isError: isVehicleOperatorCustomerError,
  } = useGetCustomerV2Query(
    {customerId: vehicleOperator?.customerId ?? ''},
    {skip: !vehicleOperator?.customerId}
  );
  const {data: vehicleServiceCampaign} = useGetVehicleServiceCampaignQuery(
    {vehicleId: defaultTo('', serviceCase?.vehicleId)},
    {skip: !serviceCase?.vehicleId}
  );
  const {data: vehicleTires} = useGetTireVehicleCountQuery(
    {vehicleId: defaultTo('', serviceCase?.vehicleId)},
    {skip: !serviceCase?.vehicleId}
  );

  const {data: serviceCaseActions} = useGetServiceCaseActionsQuery({serviceCaseId});

  const [postServiceCaseActionCancel] = usePostServiceCaseActionCancelMutation();
  const [postServiceCaseActionReOpen] = usePostServiceCaseActionReOpenMutation();

  const vehicleLocalActionNoteQuery = useGetVehicleLocalActionNoteQuery(
    {vehicleId: serviceCase?.vehicleId},
    {skip: !serviceCase?.vehicleId}
  );

  const documentCountQuery = useGetDocumentContextCountQuery(
    {contextTarget: 'service-case', contextId: serviceCaseId},
    {skip: !serviceCaseId}
  );

  const {data: itemsTotalAmount} = useGetCaseItemsTotalAmountQuery(
    {serviceCaseId},
    {skip: !serviceCaseId}
  );

  const recallMessage = vehicleLocalActionNoteQuery.currentData?.local_action_note_text;

  const stateFlag = useStateFlag(serviceCase?.state);
  const ordersStateFlag = useStateFlag(serviceCase?.ordersState);
  const markersFlags = getMarkersFlags(serviceCase?.markers);
  const timeTrackingFlag =
    isFeatureEnabled(featureFlags.ACL_CHRONOS) && isChronosEnabled
      ? getTimeTrackingStateFlag(serviceCase?.timeTrackingState)
      : undefined;
  const sparePartFlag = getSparePartStateFlag(serviceCase?.sparePartWarehouseState);

  const activeServiceCampaignsLength =
    vehicleServiceCampaign?.serviceCampaign?.filter(
      (campaign) => campaign.kind === 'ACTIVE_CAMPAIGN'
    ).length ?? 0;

  const isError = any(isTruthy, [
    isServiceCaseError,
    isCheckinError,
    isVehicleError,
    isVehicleCustomersError,
    isVehicleOperatorCustomerError,
  ]);
  const isLoading = any(isTruthy, [
    isServiceCaseLoading,
    isServiceCaseFetching,
    isCheckInLoading,
    isVehicleLoading,
    isVehicleCustomersLoading,
    isVehicleOperatorCustomerLoading,
  ]);

  const vehicleHeaderParameters = useServiceCaseVehicleHeaderParameters(
    serviceCase,
    vehicle,
    checkIn
  );

  useEffect(() => {
    if (serviceCase?.state === 'DRAFT') {
      const searchParams = new URLSearchParams(window.location.search);
      navigate(
        composePath(workshopRoutes.draftServiceCase, {
          params: {id: serviceCaseId},
          queryParams: {
            tab: workshopSearchParams.create.serviceJobs,
            [SOURCE_DATA_GRID]: searchParams.get(SOURCE_DATA_GRID),
          },
        })
      );
    }
  }, [serviceCase?.state]);

  const handleNavigateCheckinHandover = (tab: string) => {
    searchParams.set(
      queryParams.COMPONENT_SECTIONS_SECTION,
      workshopSearchParams.serviceDetail.checkinHandover
    );
    searchParams.set(queryParams.SERVICE_CASE_CHECKIN_HANDOVER, tab);
    setSearchParams(searchParams);
  };

  const handleCreateOrder = (orderVariantId?: string | null) => {
    if (isNil(orderVariantId)) {
      return;
    }
    postServiceOrder({serviceCaseId, body: {orderVariantId}})
      .unwrap()
      .then((order) => {
        const createdId = order?.id;
        if (isNil(createdId)) {
          return;
        }
        setOrderId(createdId);
        showNotification.success(i18n.t('entity.order.labels.createSuccess'));
      })
      .catch(handleApiError);
  };

  const handleActionClick = (actionKey: 'cancel' | 'reOpen' | Nullish) => {
    if (isNil(actionKey)) {
      return;
    }

    const confirmText = match(actionKey)
      .with(
        'cancel',
        always(i18n.t('entity.serviceCase.labels.cancelService', {name: serviceCase?.name}))
      )
      .with(
        'reOpen',
        always(i18n.t('entity.serviceCase.labels.reOpenService', {name: serviceCase?.name}))
      )
      .exhaustive();

    openConfirmDialog({
      text: confirmText,
      onConfirm: () => {
        match(actionKey)
          .with('cancel', () =>
            postServiceCaseActionCancel({serviceCaseId: serviceCase?.id ?? ''})
              .unwrap()
              .catch(handleApiError)
          )
          .with('reOpen', () =>
            postServiceCaseActionReOpen({serviceCaseId: serviceCase?.id ?? ''})
              .unwrap()
              .catch(handleApiError)
          )
          .exhaustive();
      },
    });
  };

  const sections = buildArray<SectionItem>([
    {
      id: workshopSearchParams.serviceDetail.overview,
      label: i18n.t('entity.serviceCase.labels.overview'),
      content: <Overview />,
      hasSeparator: true,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-overview'),
    },
    {
      id: workshopSearchParams.serviceDetail.orders,
      label: i18n.t('entity.serviceCase.labels.orders'),
      content: <Orders />,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-orders'),
    },
    {
      id: workshopSearchParams.serviceDetail.checkinHandover,
      label: i18n.t('entity.workshop.labels.checkinAndHandover'),
      content: <CheckinHandover vehicleId={serviceCase?.vehicleId ?? ''} />,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-checkinHandover'),
    },
    {
      id: workshopSearchParams.serviceDetail.documents,
      label: i18n.t('general.labels.documents'),
      content: <ServiceCaseDocuments vehicleId={serviceCase?.vehicleId} />,
      badge: !!documentCountQuery.data?.count
        ? {value: documentCountQuery.data.count, colorScheme: 'neutral'}
        : undefined,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-documents'),
    },
    {
      id: workshopSearchParams.serviceDetail.photos,
      label: i18n.t('entity.photo.labels.photos'),
      content: <Photos />,
      hasSeparator: true,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-photos'),
    },
    {
      id: workshopSearchParams.serviceDetail.vehicle,
      label: i18n.t('entity.vehicle.labels.vehicle'),
      content: <Vehicle />,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-vehicle'),
    },
  ])
    .whenFeatureEnabled(featureFlags.AFS_SERVICE_CASE_OWNERSHIP, {
      id: workshopSearchParams.serviceDetail.ownership,
      label: i18n.t('entity.serviceCase.labels.ownership'),
      content: <OwnershipSection vehicleId={serviceCase?.vehicleId ?? ''} showGdprConsents />,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-ownership'),
    })
    .add({
      id: workshopSearchParams.serviceDetail.postponed,
      label: i18n.t('entity.serviceCase.labels.postponed'),
      content: <Postponed />,
      badge: isPositive(postponedJobs?.count)
        ? {value: String(postponedJobs?.count ?? ''), colorScheme: 'neutral'}
        : undefined,
      isDisabled: isNonPositive(postponedJobs?.count),
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-postponed'),
    })
    .when(
      isFeatureEnabled(featureFlags.PNEUHOTEL_BASIC_VIEW) &&
        canReadPneuhotel &&
        isNotNil(serviceCase?.vehicleId),
      {
        id: workshopSearchParams.serviceDetail.tires,
        label: i18n.t('page.tiresInventory.labels.tires'),
        content: (
          <Tires
            vehicleId={serviceCase?.vehicleId ?? ''}
            gridCode="tire-order-wheel-service-case"
          />
        ),
        isDisabled: isNonPositive(vehicleTires?.count),
        'data-testid': testIds.workshop.serviceCaseDetail('navigation-tires'),
      }
    )
    .add({
      id: workshopSearchParams.serviceDetail.serviceIntervals,
      label: i18n.t('entity.vehicle.labels.serviceIntervals'),
      content: (
        <ServiceIntervals vehicleId={serviceCase?.vehicleId} serviceCaseId={serviceCase?.id} />
      ),
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-serviceIntervals'),
    })
    .add({
      id: workshopSearchParams.serviceDetail.historyOfRepairs,
      label: i18n.t('entity.serviceCase.labels.historyOfRepairs'),
      content: <HistoryOfRepairs vehicleId={serviceCase?.vehicleId} />,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-historyOfRepairs'),
    })
    .add({
      id: workshopSearchParams.serviceDetail.serviceCampaigns,
      label: i18n.t('entity.campaigns.labels.serviceCampaigns'),
      content: <VehicleCampaigns vehicleId={serviceCase?.vehicleId} />,
      badge: isPositive(activeServiceCampaignsLength)
        ? {colorScheme: 'neutral', value: activeServiceCampaignsLength}
        : undefined,
      isDisabled: isNonPositive(activeServiceCampaignsLength),
      hasSeparator: true,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-serviceCampaigns'),
    })
    .add({
      id: workshopSearchParams.serviceDetail.activityLogs,
      label: i18n.t('general.labels.activityLogs'),
      content: <ActivityLogs />,
      'data-testid': testIds.workshop.serviceCaseDetail('navigation-activityLogs'),
    });

  const getHeader = buildObject<DetailTemplateHeader>()
    .title(`${vehicle?.title} - ${getCustomerName(vehicleOperatorCustomer)}`)
    .height(38)
    .resourceId(EntityResourceIds.serviceCase)
    .recordId(defaultTo(undefined, serviceCase?.id))
    .controls(['ASSIGNEE'])
    .icon('action/build')
    .flags(
      concatAll<FlagProps[]>([
        buildArray<FlagProps>()
          .when(stateFlag, stateFlag as FlagProps)
          .when(ordersStateFlag, ordersStateFlag as FlagProps)
          .when(isNotNil(timeTrackingFlag), timeTrackingFlag!)
          .when(isNotNil(sparePartFlag), sparePartFlag!),
        markersFlags,
      ])
    )
    .parameters(vehicleHeaderParameters)
    .primaryParameter(
      formatCurrency(
        defaultTo(DEFAULT_PRICE, itemsTotalAmount?.totalPrice?.withVat?.amount),
        defaultTo(DEFAULT_CURRENCY, itemsTotalAmount?.totalPrice?.withVat?.currency),
        2
      ) ?? '',
      Boolean(itemsTotalAmount?.totalPrice?.withVat)
    )
    .secondaryParameter(
      `${formatCurrency(
        defaultTo(DEFAULT_PRICE, itemsTotalAmount?.totalPrice?.withoutVat?.amount),
        defaultTo(DEFAULT_CURRENCY, itemsTotalAmount?.totalPrice?.withoutVat?.currency),
        2
      )} ${i18n.t('entity.serviceCase.labels.withoutVat')}`,
      Boolean(itemsTotalAmount?.totalPrice?.withoutVat)
    )
    .actions(
      buildArray<Action>()
        .when(
          isNonEmptyArray(
            getMenuItemsFromActions(() => {})(serviceCaseActions?.caseActions?.options)
          ),
          {
            type: 'dropdown-iconButton',
            icon: 'navigation/more_vert',
            priority: 'secondary',
            menuItems: getMenuItemsFromActions(handleActionClick)(
              serviceCaseActions?.caseActions?.options
            ),
          }
        )
        .when(canCreateServiceOrder, {
          type: 'dropdown-button',
          variant: 'secondary',
          rightIcon: 'navigation/expand_more',
          title: i18n.t('entity.order.actions.createOrder'),
          isLoading: isPostServiceOrderLoading,
          isDisabled: serviceCase?.state === 'HANDED_OVER',
          menuItems:
            orderVariants?.map((variant) => ({
              label: variant?.name ?? '',
              onClick: () => handleCreateOrder(variant?.id),
            })) ?? [],
        })
        .whenNot(canCreateServiceOrder, {
          type: 'buttonWithTooltip',
          variant: 'secondary',
          rightIcon: 'navigation/expand_more',
          title: i18n.t('entity.order.actions.createOrder'),
          isDisabled: true,
          tooltip: {label: i18n.t('general.labels.noPermission')},
        })
        .add({
          type: 'custom',
          component: <IntegrationsDropdown serviceCaseId={serviceCaseId} />,
        })
        .whenNot(checkIn, {
          type: 'button',
          title: i18n.t('entity.workshop.labels.checkIn'),
          variant: 'primary',
          onClick: () => handleNavigateCheckinHandover(CHECKIN_SECTION),
        })
        .when(checkIn, {
          type: 'button',
          title: i18n.t('entity.workshop.labels.handover'),
          variant: 'primary',
          onClick: () => handleNavigateCheckinHandover(HANDOVER_SECTION),
        })
    )
    .alert(recallMessage ? {type: 'banner', message: recallMessage} : xentryAlert)
    .build();

  return (
    <>
      <Helmet title={i18n.t('page.workshop.labels.serviceCase')} />
      <DetailTemplate
        isLoading={isLoading}
        isError={isError}
        header={getHeader}
        sections={sections}
        defaultSection={workshopSearchParams.serviceDetail.orders}
        data-testid={testIds.workshop.serviceCaseDetail('page')}
      />
    </>
  );
}

const isDivider = (option: DropdownActionMenuItem) => option.label === 'divider';
const isHidden = (option: ServiceActionOptionType) => option?.style === 'hidden';
const transformToDropdown =
  (onClick: (key: 'cancel' | 'reOpen' | Nullish) => void) =>
  (option: ServiceActionOptionType, index: number, list: ServiceActionOptionType[]) => ({
    label: option?.key ? i18n.t(`entity.service.actions.${option?.key}`) : (option?.type ?? ''),
    isDisabled: option?.style === 'disabled',
    onClick: () => onClick(option?.key),
    hasSeparator: list[index + 1]?.type === 'divider',
  });

const getMenuItemsFromActions = (onClick: (key: 'cancel' | 'reOpen' | Nullish) => void) =>
  pipe<
    [ServiceActionOptionType[] | Nullish],
    ServiceActionOptionType[],
    ServiceActionOptionType[],
    ServiceActionOptionType[],
    DropdownActionMenuItem[],
    DropdownActionMenuItem[]
  >(
    defaultTo([]),
    reject(isHidden),
    sortBy(prop('position')),
    mapIndexed(transformToDropdown(onClick)),
    reject(isDivider)
  );
