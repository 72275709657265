import {Button} from 'platform/components';
import {Box, Show} from 'platform/foundation';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilters} from '../../FiltersContext/FiltersContext';

interface FilterTagButtonsProps extends TestIdProps {
  tagCount: number;
  collapsedTagCount: number;
  isExpanded: boolean;
  toggleIsExpanded: () => void;
}

export function FilterTagButtons(props: FilterTagButtonsProps) {
  const {onClearFilters} = useFilters();

  return (
    <>
      <Show when={props.collapsedTagCount > 0}>
        <Box paddingLeft={2}>
          <Button
            variant="link"
            size="small"
            title={
              props.isExpanded
                ? i18n.t('general.actions.hide')
                : i18n.t('entity.sourcing.labels.nMore', {
                    count: props.collapsedTagCount,
                  })
            }
            onClick={props.toggleIsExpanded}
            data-testid={suffixTestId('chipsExpandToggle', props)}
          />
        </Box>
      </Show>
      <Show when={props.tagCount > 0}>
        <Box paddingLeft={2}>
          <Button
            title={i18n.t('entity.sourcing.labels.clearFilters')}
            size="small"
            variant="dangerLink"
            leftIcon="action/delete"
            onClick={onClearFilters}
            data-testid={suffixTestId('filterClear', props)}
          />
        </Box>
      </Show>
    </>
  );
}
