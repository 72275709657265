import {Card, Separator} from 'platform/components';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

const EXTERNAL_FILTER_ID = 'serviceCampaignVehicles';

interface ServiceCampaingVehiclesProps extends RequiredTestIdProps {
  serviceCampaignId: string;
}

export function ServiceCampaignVehicles(props: ServiceCampaingVehiclesProps) {
  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {manufacturerActionNumber: props.serviceCampaignId}]);

  return (
    <Card title={i18n.t('page.workshop.labels.campaignVehicles')}>
      <div id={EXTERNAL_FILTER_ID} />
      <Separator />
      <DataGrid
        autoHeight
        gridCode="service-campaign-hyundai-vehicles"
        externalFilterId={EXTERNAL_FILTER_ID}
        data-testid={suffixTestId('serviceCampaignVehicleDataGrid', props)}
        queryModifier={queryModifier}
      />
    </Card>
  );
}
