import {DataStatus} from 'platform/components';

import {FC, PropsWithChildren, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {any, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Main} from '@dms/shared';
import {loadVehicleDetailsData, selectCarAudit, useThunkDispatch} from '@dms/teas';

import {useRequiredParams} from 'shared';

import {AdvertisementContent} from './components/AdvertisementContent';

export const Advertisements: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId} = useRequiredParams();

  const {loading} = useSelector(selectCarAudit);
  const isAuditLoading = any(isTrue, [
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(vehicleId) && !isAuditLoading) {
      loadVehicleDetailsData(vehicleId, dispatch);
    }
  }, [vehicleId]);

  return (
    <Main>
      <DataStatus isLoading={isAuditLoading} minHeight={100}>
        <AdvertisementContent />
      </DataStatus>
    </Main>
  );
};
