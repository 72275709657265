import {isNil, isNotNil, all} from 'ramda';
import {isArray, isTrue} from 'ramda-adjunct';

import {Permission, PermissionExpressionListResponseBody} from '@dms/api';
import {isActionPermission} from '@dms/shared';

import {Nullish} from 'shared';

export const resolvePermission = (
  permission: Permission | Permission[] | Nullish,
  userPermissions: PermissionExpressionListResponseBody | Nullish
) => {
  if (isNil(permission)) {
    return true;
  }

  if (isNil(userPermissions)) {
    return false;
  }

  if (isArray(permission)) {
    const foundPermissions = permission
      .map((singlePermission) =>
        isActionPermission(singlePermission)
          ? userPermissions?.actions?.find(
              ({actionId, resourceId}) =>
                actionId === singlePermission?.actionId &&
                resourceId === singlePermission?.resourceId
            )
          : userPermissions?.fields?.find(
              ({fieldId, resourceId}) =>
                fieldId === singlePermission?.fieldId && resourceId === singlePermission?.resourceId
            )
      )
      .filter(isNotNil);
    return all(
      isTrue,
      foundPermissions.map(
        (singlePermission) => singlePermission.permissionsExpression.result !== 'DISALLOW'
      )
    );
  } else {
    const foundPermission = isActionPermission(permission)
      ? userPermissions?.actions?.find(
          ({actionId, resourceId}) =>
            actionId === permission?.actionId && resourceId === permission?.resourceId
        )
      : userPermissions?.fields?.find(
          ({fieldId, resourceId}) =>
            fieldId === permission?.fieldId && resourceId === permission?.resourceId
        );

    if (isNil(foundPermission)) {
      return true;
    }

    return foundPermission.permissionsExpression.result !== 'DISALLOW';
  }
};
