import {Card, Label} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {mergeAll, path} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions} from '@dms/shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

export type ItemInformation = {
  catalogNumber: string;
  name: string;
  manufacturerLabel: string;
  supplierLabel: string;
};

export type WarehouseAvailabilityQueryModifier = {
  manufacturerId: string;
  variantId: string;
  catalogNumber: string;
  originObjectType: string;
};

export type ActionType = 'request' | 'nonBinding' | 'dispatch';

export type ItemData = {
  actionKey: ActionType;
  warehouseId: string;
  catalogNumber: string;
  manufacturerId: string;
};

interface WarehouseAvailabilityProps {
  itemInformation: ItemInformation;
  queryModifier: WarehouseAvailabilityQueryModifier;
  onItemClick?: (item: ItemData) => void;
}

export function WarehouseAvailability(props: WarehouseAvailabilityProps) {
  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([
      filter,
      {
        originObjectType: props.queryModifier?.originObjectType,
        manufacturerId: props.queryModifier?.manufacturerId,
        variantId: props.queryModifier?.variantId,
        catalogNumber: props.queryModifier?.catalogNumber,
      },
    ]);

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    const warehouseId = path(['warehouseId', 'value', 'key'], rowData);

    if (!isString(warehouseId)) {
      throw new Error('Warehouse id is not defined');
    }

    const catalogNumber = props.queryModifier.catalogNumber;
    const manufacturerId = props.queryModifier.manufacturerId;

    match(actionKey)
      .with('request', () => {
        props.onItemClick?.({
          actionKey: 'request',
          warehouseId,
          catalogNumber,
          manufacturerId,
        });
      })
      .with('nonBinding', () => {
        props.onItemClick?.({
          actionKey: 'nonBinding',
          warehouseId,
          catalogNumber,
          manufacturerId,
        });
      })
      .with('dispatch', () => {
        props.onItemClick?.({
          actionKey: 'dispatch',
          warehouseId,
          catalogNumber,
          manufacturerId,
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <VStack spacing={4}>
      <Card
        title={i18n.t('entity.warehouse.labels.itemInformation')}
        data-testid="inlineWhite"
        variant="inlineGrey"
      >
        <HStack spacing={4}>
          <VStack width={44}>
            <Label>{i18n.t('entity.warehouse.labels.catalogueNumber')}</Label>
            <Text overflowWrap="anywhere">{props.itemInformation.catalogNumber}</Text>
          </VStack>
          <VStack width={44}>
            <Label>{i18n.t('entity.warehouse.labels.name')}</Label>
            <Text overflowWrap="anywhere">{props.itemInformation.name}</Text>
          </VStack>
          <VStack width={44}>
            <Label>{i18n.t('entity.warehouse.labels.manufacturer')}</Label>
            <Text overflowWrap="anywhere">{props.itemInformation.manufacturerLabel}</Text>
          </VStack>
          <VStack width={44}>
            <Label>{i18n.t('entity.warehouse.labels.supplier')}</Label>
            <Text overflowWrap="anywhere">{props.itemInformation.supplierLabel}</Text>
          </VStack>
        </HStack>
      </Card>
      <Box height="52vh">
        <DataGrid
          gridCode="supplier-article-warehouse-availability"
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid="supplier-article-warehouse-availability"
        />
      </Box>
    </VStack>
  );
}
