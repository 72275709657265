import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object, SchemaOf} from 'yup';

import {UpdateInterestApiArg, useUpdateInterestMutation} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId, yupString} from 'shared';

import {useCodeList} from '../../hooks/useCodeList';
import {handleApiError} from '../../utils/handleApiError';

type EndCaseForm = {
  reasonCloseNote?: string | Nullish;
  reasonCloseCodeId: string;
};

interface InterestEndCaseDialogProps extends RequiredTestIdProps {
  interestId: string;
  onSubmit?: () => void;
}

export function InterestEndCaseDialog(props: InterestEndCaseDialogProps) {
  const [reasonCloseList, {isLoading: isCodeListLoading, isError}] = useCodeList(
    'unsuccessful_interest_reason'
  );

  const [updateInterest, {isLoading: isUpdateInterestLoading}] = useUpdateInterestMutation();

  const onSubmit: FormSubmitHandler<EndCaseForm> = async (data) => {
    const args: UpdateInterestApiArg = {
      id: props.interestId,
      updateInterestRequestBody: {
        state: 'UNSUCCESSFUL',
        reasonCloseNote: data.reasonCloseNote,
        reasonCloseCodeId: data.reasonCloseCodeId,
      },
    };
    await updateInterest(args)
      .unwrap()
      .then(() => props.onSubmit?.())
      .then(() => closeCurrentDialog())
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isCodeListLoading} isError={isError} minHeight={25}>
      <Form<EndCaseForm> schema={schema} onSubmit={onSubmit}>
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              type="choice"
              name="reasonCloseCodeId"
              label={i18n.t('general.labels.reason')}
              isRequired
              options={reasonCloseList?.map((listItem) => ({
                label: listItem.name,
                value: listItem.codeId,
              }))}
              data-testid={suffixTestId('reasonCloseCodeId', props)}
            />
            <FormField
              control={control}
              type="textarea"
              name="reasonCloseNote"
              label={i18n.t('general.labels.note')}
              minRows={3}
              data-testid={suffixTestId('reasonCloseNote', props)}
            />
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={closeCurrentDialog}
                isDisabled={isUpdateInterestLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={isUpdateInterestLoading}
                title={i18n.t('general.actions.endCase')}
                data-testid={suffixTestId('endCase', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema: SchemaOf<EndCaseForm> = object({
  reasonCloseCodeId: yupString.required(),
  reasonCloseNote: yupString,
});
