import {Heading} from 'platform/foundation';

import {head, isEmpty, isNotNil, length} from 'ramda';

import {useSourcingListUserFavouriteQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {CURRENCY_CZK, formatDocumentTitle} from '@dms/shared';
import {
  COUNTRY_CZE,
  DEFAULT_ORDER_ID,
  useSourcingCurrency,
  useSourcingTargetCountry,
} from '@dms/teas';

import {SourcingDataStatus} from '../../components/SourcingDataStatus/SourcingDataStatus';
import {SourcingLayout} from '../../components/SourcingLayout/SourcingLayout';
import {useSourcingTable} from '../../components/SourcingTable/hooks/useSourcingTable';
import {useSourcingTableColumns} from '../../components/SourcingTable/hooks/useSourcingTableColumns';
import {SourcingTable} from '../../components/SourcingTable/SourcingTable';
import {VehiclePreview} from '../../components/VehiclePreview/VehiclePreview';
import {CLASSIFIENDS_SORT_BY} from '../../constants/classifiendsSortBy';
import {getSourcingVehicleId} from '../../utils/getSourcingVehicleId';

export function UserFavouritePage() {
  const currency = useSourcingCurrency();
  const targetCountry = useSourcingTargetCountry();
  const [state, dispatch] = useSourcingTable('user-favourite', DEFAULT_ORDER_ID);

  const sortByOptions =
    classifiedsSortByOptions?.map((item) => ({
      value: item.id,
      label: item.name,
      orderBy: item.orderBy,
    })) ?? [];
  const orderBy = sortByOptions.find((item) => item.value === state.orderBy)?.orderBy;

  const {data, isLoading, isFetching, error} = useSourcingListUserFavouriteQuery({
    orderBy: isEmpty(orderBy) ? undefined : orderBy?.join(','),
    currency: currency?.code ?? CURRENCY_CZK,
    buyerCountry: targetCountry?.code ?? COUNTRY_CZE,
    size: 1000,
  });

  const columns = useSourcingTableColumns();
  const rows = data?.data ?? [];

  return (
    <SourcingDataStatus
      isEmpty={!isLoading && isEmpty(rows)}
      isError={isNotNil(error)}
      iconUrl="/assets/images/empty_images/empty-favourite.svg"
      text={i18n.t('entity.vehicle.notifications.nothingFavorite')}
    >
      <SourcingLayout
        header={
          <Heading size={2} alternative>
            {i18n.t('entity.vehicle.labels.favouritePage')}
          </Heading>
        }
        content={
          <SourcingTable
            state={state}
            dispatch={dispatch}
            columns={columns}
            sortByOptions={sortByOptions}
            data={rows}
            isLoading={isLoading || isFetching}
            error={error}
            count={length(rows)}
          />
        }
        iframe={
          <VehiclePreview vehicleId={getSourcingVehicleId(state.selectedRow ?? head(rows))} />
        }
        pageTitle={formatDocumentTitle(
          i18n.t('general.actions.favourites'),
          i18n.t('page.sourcing.labels.classifieds'),
          i18n.t('page.sourcing.labels.sourcing')
        )}
      />
    </SourcingDataStatus>
  );
}

const classifiedsSortByOptions = [
  {
    name: i18n.t('entity.vehicle.labels.sortByLastAdded'),
    orderBy: [] as string[],
    id: DEFAULT_ORDER_ID,
  },
  ...CLASSIFIENDS_SORT_BY,
];
