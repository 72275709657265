import {differenceInCalendarDays} from 'date-fns';
import {DataStatus, FormField} from 'platform/components';
import {Grid, Heading, Text, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {isNotNaN} from 'ramda-adjunct';

import {useGetTenantQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {parseDate, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {PredefinedTextArea} from '../../PredefinedTextArea/PredefinedTextArea';
import {ComplaintForm} from '../types/complaintForm';
import {ComplaintStepProps} from '../types/complaintStepProps';
import {Resolution} from '../types/resolution';

interface ComplaintResultProps extends ComplaintStepProps {}

interface ResolutionOption {
  label: string;
  value: Resolution;
}

const resolution: ResolutionOption[] = [
  {
    label: i18n.t('general.labels.yes'),
    value: 'YES',
  },
  {
    label: i18n.t('general.labels.no'),
    value: 'NO',
  },
];

export function ComplaintResult(props: ComplaintResultProps) {
  const formatNumber = useFormatNumber();

  const {data: tenant, isLoading, isError} = useGetTenantQuery();

  const currency = tenant?.currency ?? DEFAULT_CURRENCY;
  const dateReceived = parseDate(props.formApi.watch('receiveAt'));
  const daysSinceReceived = differenceInCalendarDays(
    parseDate(props.formApi.watch('closeAt') ?? ''),
    dateReceived
  );

  return (
    <VStack spacing={4}>
      <Heading size={3}>{i18n.t('entity.vehicleComplaint.labels.complaintResult')}</Heading>

      <DataStatus isLoading={isLoading} isError={isError}>
        <Grid columns={2}>
          <FormField
            name="closeAt"
            label={i18n.t('entity.vehicleComplaint.labels.resolvedOn')}
            type="apiDate"
            control={props.control}
            data-testid={suffixTestId('closeAt', props)}
          />
          <VStack spacing={2}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.vehicleComplaint.labels.daysSinceReceived')}
            </Text>
            <Text alternative>
              {isNotNaN(daysSinceReceived) ? formatNumber(daysSinceReceived) : EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
        </Grid>

        <PredefinedTextArea<ComplaintForm>
          name="resultNote"
          label={i18n.t('entity.vehicleComplaint.labels.result')}
          context="result-note"
          resource="COMPLAINT"
          rows={4}
          isResizable
          formApi={props.formApi}
        />

        <PredefinedTextArea<ComplaintForm>
          name="technicalReport"
          label={i18n.t('entity.vehicleComplaint.labels.technicalReport')}
          resource="COMPLAINT"
          context="result-report"
          isResizable
          formApi={props.formApi}
        />

        <Grid columns={2}>
          <FormField
            name="cost"
            label={i18n.t('entity.vehicleComplaint.labels.cost')}
            type="currency"
            currency={currency}
            control={props.control}
            data-testid={suffixTestId('cost', props)}
          />
          <FormField
            name="returnMileage"
            label={i18n.t('entity.vehicleComplaint.labels.returnMileage')}
            type="integer"
            suffix={i18n.t('general.metric.km')}
            control={props.control}
            data-testid={suffixTestId('returnMileage', props)}
          />
        </Grid>

        <Grid columns={2}>
          <FormField
            name="vehicleReturned"
            label={i18n.t('entity.vehicleComplaint.labels.vehicleReturned')}
            type="chips"
            options={resolution}
            isDeselectable
            control={props.control}
            data-testid={suffixTestId('vehicleReturned', props)}
          />
          <FormField
            name="customerSatisfied"
            label={i18n.t('entity.vehicleComplaint.labels.customerSatisfied')}
            type="chips"
            options={resolution}
            isDeselectable
            control={props.control}
            data-testid={suffixTestId('customerSatisfied', props)}
          />
        </Grid>
      </DataStatus>
    </VStack>
  );
}
