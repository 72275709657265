import {Buffer} from 'buffer';

import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import '@dms/i18n';

import {App} from './App';

// Polyfill Buffer for Apollo Client
window.Buffer = Buffer;

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
