import {DynamicUi} from 'platform/components';

import {forwardRef, useImperativeHandle} from 'react';
import {createPortal} from 'react-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useTimeout} from 'shared';

import {useDataGridContext} from '../context/useDataGridContext';
import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {IToolPanelParams} from '../types/AgGridTypes';
import {dynamicUiReturnValuesToFormFilterValues} from '../utils/dynamicUiReturnValuesToFormFilterValues';
import {validateExternalFiltersKeys} from '../utils/validateExternalFiltersKeys';

export const CustomFiltersPanelWrapper = forwardRef(({api}: IToolPanelParams, ref) => {
  const {
    externalFilterId,
    filterSchema,
    onExternalFilterChange,
    filterComponent: FilterComponent,
  } = useDataGridContext();

  useImperativeHandle(ref, () => ({}));

  const el = document.getElementById(externalFilterId || '');

  const {filterValues} = useDataGridFiltersModel();

  useTimeout(() => {
    if (onExternalFilterChange) {
      onExternalFilterChange(api.getFilterModel());
    }
  }, 10);

  const handleFilterValue = (newFilterValues: Record<string, any>) => {
    validateExternalFiltersKeys(newFilterValues, api);

    if (
      JSON.stringify(filterValues) === JSON.stringify(newFilterValues) ||
      isNilOrEmpty(newFilterValues)
    ) {
      return;
    }

    if (onExternalFilterChange) {
      onExternalFilterChange(dynamicUiReturnValuesToFormFilterValues(newFilterValues));
    }

    api?.setFilterModel(dynamicUiReturnValuesToFormFilterValues(newFilterValues));
    api.onFilterChanged();
  };

  const handleFilterFormValue = (newFilterValues: Record<string, any>) => {
    validateExternalFiltersKeys(newFilterValues, api);

    if (
      JSON.stringify(filterValues) === JSON.stringify(newFilterValues) ||
      isNilOrEmpty(newFilterValues)
    ) {
      return;
    }

    if (onExternalFilterChange) {
      onExternalFilterChange(newFilterValues);
    }

    api?.setFilterModel(newFilterValues);
    api.onFilterChanged();
  };

  if (isNil(el)) {
    return null;
  }

  if (FilterComponent) {
    return createPortal(
      <FilterComponent onChange={handleFilterFormValue} values={filterValues ?? {}} saveOnChange />,
      el
    );
  }

  if (filterSchema) {
    return createPortal(
      <DynamicUi
        key={JSON.stringify(filterValues)}
        defaultValues={filterValues ?? {}}
        schema={filterSchema}
        onChange={handleFilterValue}
      />,
      el
    );
  }
});
