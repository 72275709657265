import {DataStatus} from 'platform/components';

import {useGetCostQuery} from '@dms/api';

import {VehicleEarningCostForm} from '../../VehicleEarningCostForm/VehicleEarningCostForm';

interface EditCostFormProps {
  vehicleId: string;
  costId: string | null;
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
  isSimplified?: boolean;
  isCostsEditable?: boolean;
}

export function EditCostForm(props: EditCostFormProps) {
  const {data, isLoading, isError} = useGetCostQuery(
    {costId: props.costId ?? ''},
    {skip: !props.costId}
  );

  return (
    <DataStatus spacing={10} isLoading={isLoading} isError={isError}>
      <VehicleEarningCostForm
        entity="cost"
        defaultValues={data}
        entityId={props.costId}
        vehicleId={props.vehicleId}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
        isSimplified={props.isSimplified}
        isCostsEditable={props.isCostsEditable}
      />
    </DataStatus>
  );
}
