import {Show, VStack} from 'platform/foundation';

import {testIds} from '@dms/routes';
import {usePricePermissions, VehicleCostsGridCard} from '@dms/shared';

import {useRequiredParams} from 'shared';

import {EarningsCostsSummary} from './EarningsCostsSummary';
import {VehicleEarningsGridCard} from './VehicleEarningsGridCard';

export function VehicleEarningsAndCosts() {
  const {id: vehicleId} = useRequiredParams();

  const {canReadVehicleCosts: canReadCosts, canReadVehicleEarnings: canReadEarnings} =
    usePricePermissions({vehicleRecordId: vehicleId, businessCaseRecordId: null});

  return (
    <VStack spacing={4}>
      <EarningsCostsSummary
        vehicleId={vehicleId}
        data-testid={testIds.vehicles.finances('summary')}
      />
      <Show when={canReadEarnings}>
        <VehicleEarningsGridCard
          vehicleId={vehicleId}
          data-testid={testIds.vehicles.finances('earnings')}
          isCostsEditable
        />
      </Show>
      <Show when={canReadCosts}>
        <VehicleCostsGridCard
          vehicleId={vehicleId}
          data-testid={testIds.vehicles.finances('costs')}
        />
      </Show>
    </VStack>
  );
}
