import {Attributes, AttributesRow} from 'platform/components';
import {Heading, Link, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';

import {TestIdProps, composePath, suffixTestId} from 'shared';

import {warehouseSearchParams} from '../../../../../utils/warehouseSearchParams';

interface DirectSaleDetailsProps extends TestIdProps {
  directSaleId: string;
  rows: AttributesRow[];
}

export function DirectSaleDetails(props: DirectSaleDetailsProps) {
  return (
    <VStack spacing={3}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.directSales')}</Heading>
      <Attributes
        size="quarter"
        rows={props.rows}
        data-testid={suffixTestId('information', props)}
      />
      <Link
        size="small"
        rightIcon="action/launch"
        href={composePath(warehouseRoutes.directSalesDetailOverview, {
          params: {
            id: props.directSaleId,
          },
          queryParams: {
            section: warehouseSearchParams.directSaleDetail.directSale,
          },
          isSearchParamsPreserved: false,
        })}
        target="_blank"
        title={i18n.t('entity.warehouse.actions.viewDirectSales')}
        data-testid={suffixTestId('viewDirectSales', props)}
      />
    </VStack>
  );
}
