import {match} from 'ts-pattern';

import {Navigate} from 'react-router-dom';

import {settingsRoutes} from '@dms/routes';

import {useRequiredParams} from 'shared';

import {EditMessageTemplateForm} from './components/EditMessageTemplateForm';

export const MessageTemplateEdit = () => {
  const {id, type} = useRequiredParams();

  return match(type)
    .with('email', 'sms', (type) => (
      <EditMessageTemplateForm templateType={type} messageTemplateId={id} />
    ))
    .otherwise(() => <Navigate to={settingsRoutes.messageTemplates} />);
};
