import {
  Alert,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {
  ArticleCategoryBase,
  GetArticleCategoryResponse,
  usePatchArticleCategoryMutation,
  usePostArticleCategoryMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {ArticleCategoryForm} from '../types/ArticleCategoryForm';

export interface ArticleCategoryDetailFormProps extends RequiredTestIdProps {
  id: string | Nullish;
  articleCategory: GetArticleCategoryResponse | Nullish;
}

export function ArticleCategoryDetailForm(props: ArticleCategoryDetailFormProps) {
  const navigate = useNavigate();

  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [postArticleCategory] = usePostArticleCategoryMutation();
  const [patchArticleCategory] = usePatchArticleCategoryMutation();

  const handleSubmit: FormSubmitHandler<ArticleCategoryForm> = async (formValues) => {
    const requestBody: ArticleCategoryBase = {
      ...formValues,
      system: false,
    };

    if (isNotNil(props.articleCategory)) {
      return await patchArticleCategory({articleCategoryId: props.id as string, body: requestBody})
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
        )
        .then(handleNavigateBack)
        .catch(handleApiError);
    }

    await postArticleCategory({body: requestBody})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCreated')))
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesArticleCategory);
  };

  const defaultValues: Partial<ArticleCategoryForm> = {
    ...props.articleCategory,
    isDefault: props.articleCategory?.isDefault ?? false,
    active: props.articleCategory?.active ?? true,
  };

  return (
    <Form<ArticleCategoryForm>
      schema={formSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    >
      {(control, formApi) => {
        const isActive = formApi.watch('active');

        const handleActiveStateChange = (
          isActive: boolean,
          formApi: UseFormReturn<ArticleCategoryForm>
        ) => {
          if (isActive) {
            return;
          }

          formApi.setValue('isDefault', false);
        };

        return (
          <VStack spacing={4} data-testid={props['data-testid']}>
            <Show when={duplicateError}>
              <Alert
                variant="error"
                title={i18n.t('entity.warehouse.labels.articleCategoryAlreadyExist')}
                data-testid={suffixTestId('duplicityAlert', props)}
              />
            </Show>

            <FormField
              isDisabled={isFalse(isActive)}
              control={control}
              type="switch"
              name="isDefault"
              label={i18n.t('general.actions.setAsDefault')}
              data-testid={suffixTestId('inputs.isDefault', props)}
            />

            <FormField
              control={control}
              type="switch"
              name="active"
              onChange={(value) => handleActiveStateChange(value, formApi)}
              label={i18n.t('entity.warehouse.labels.setAsActive')}
              data-testid={suffixTestId('inputs.isActive', props)}
            />

            <Separator />

            <Grid columns={4}>
              <FormField
                isRequired
                control={control}
                type="text"
                name="name"
                label={i18n.t('entity.warehouse.labels.name')}
                data-testid={suffixTestId('inputs.name', props)}
              />
              <FormField
                control={control}
                type="text"
                name="code"
                label={i18n.t('entity.warehouse.labels.code')}
                data-testid={suffixTestId('inputs.code', props)}
              />
            </Grid>

            <Space fillAvailable />

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: isNil(props.articleCategory)
                    ? i18n.t('general.actions.discard')
                    : i18n.t('general.actions.discardChanges'),
                  onClick: handleNavigateBack,
                  'data-testid': suffixTestId('actions.discard', props),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  variant: 'primary',
                  title: isNil(props.articleCategory)
                    ? i18n.t('general.actions.save')
                    : i18n.t('general.actions.saveChanges'),
                  'data-testid': suffixTestId('actions.submit', props),
                },
              ]}
            />
          </VStack>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  isDefault: boolean().default(false),
  isActive: boolean().default(true),
  name: yupString.required(),
  code: yupString,
});
