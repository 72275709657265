import {Card, FormControl, FormField, Label} from 'platform/components';
import {GridItem, Grid, Link, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {UseFormReturn} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';

import i18n from '@dms/i18n';

import {parseDate, suffixTestId, RequiredTestIdProps} from 'shared';

import {useRedirectToInvoice} from '../../hooks/useRedirectToInvoice';
import {AccountingDocumentFormValues} from '../../types';

interface CorrectiveTaxDocumentDetailsProps extends RequiredTestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
}

export function CorrectiveTaxDocumentDetails(props: CorrectiveTaxDocumentDetailsProps) {
  const [searchParams] = useSearchParams();
  const formatDateTime = useDateTimeFormatter();

  const [redirectToInvoice] = useRedirectToInvoice();
  const invoiceType = searchParams.get('invoiceType');

  const {issueDate, number, id} = props.formApi.getValues();

  const handleRedirectToInvoice = () => redirectToInvoice(invoiceType, id);

  return (
    <Card
      title={i18n.t('entity.correctiveTaxDocument.labels.taxDocumentDetails')}
      data-testid={suffixTestId('section', props)}
    >
      <Grid columns={4}>
        <VStack>
          <Label>{i18n.t('entity.warehouse.labels.invoiceNumber')}</Label>
          <Link
            title={number}
            onClick={handleRedirectToInvoice}
            data-testid={suffixTestId('invoiceNumber', props)}
          />
        </VStack>

        <GridItem span={3}>
          <VStack>
            <Label>{i18n.t('general.labels.description')}</Label>
            <Text size="small" data-testid={suffixTestId('description', props)}>
              {i18n.t('entity.correctiveTaxDocument.labels.descriptionContent', {
                invoiceNumber: number,
                issueDate: issueDate ? formatDateTime('dateShort', parseDate(issueDate)) : '–',
              })}
            </Text>
          </VStack>
        </GridItem>

        <FormField
          name="dateOfReception"
          control={props.control}
          type="apiDate"
          isRequired
          label={i18n.t('entity.correctiveTaxDocument.labels.dateOfReception')}
          data-testid={suffixTestId('dateOfReception', props)}
        />

        <GridItem span={3}>
          <FormField
            name="reason"
            control={props.control}
            type="textarea"
            isRequired
            label={i18n.t('general.labels.reason')}
            data-testid={suffixTestId('reason', props)}
          />
        </GridItem>
      </Grid>
    </Card>
  );
}
