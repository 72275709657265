import {Box} from 'platform/foundation';

import {mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';
import {Section, SectionTab, useCustomerUrl} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

type ServiceOrderRow = {
  serviceCaseId: {value: string};
};

export function ServiceCases() {
  const {customerId} = useCustomerUrl();
  const navigate = useNavigate();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {vehicleOwnerships: customerId}]);

  const serviceCaseActionCallback: ActionCallback = ({actionKey, rowId}) => {
    if (actionKey !== 'redirectDetail') {
      return;
    }

    const id = isArray(rowId) ? rowId[0] : rowId;

    navigate(composePath(workshopRoutes.serviceCaseDetail, {params: {id}}));
  };

  const serviceOrderActionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    if (actionKey !== 'redirectDetail') {
      return;
    }

    const orderId = isArray(rowId) ? rowId[0] : rowId;
    const orderData = rowData as ServiceOrderRow;

    navigate(
      composePath(workshopRoutes.serviceCaseDetail, {
        params: {id: orderData.serviceCaseId.value},
        queryParams: {
          section: 'orders',
          orderId,
        },
      })
    );
  };

  const tabs: SectionTab[] = [
    {
      queryId: 'service-cases',
      title: i18n.t('page.workshop.labels.serviceCases'),
      content: (
        <Box height={175}>
          <DataGrid
            // DG in tabs needs a key to force rerender
            // eslint-disable-next-line no-restricted-syntax
            key="service-case"
            data-testid="customer-service-case"
            gridCode="customer-service-case"
            queryModifier={queryModifier}
            actionCallback={serviceCaseActionCallback}
          />
        </Box>
      ),
    },
    {
      queryId: 'service-orders',
      title: i18n.t('page.workshop.labels.serviceOrders'),
      content: (
        <Box height={175}>
          <DataGrid
            // DG in tabs needs a key to force rerender
            // eslint-disable-next-line no-restricted-syntax
            key="service-order"
            data-testid="customer-service-order"
            gridCode="customer-service-order"
            queryModifier={queryModifier}
            actionCallback={serviceOrderActionCallback}
          />
        </Box>
      ),
    },
  ];

  return <Section tabs={tabs} />;
}
