import {Button, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {useGetCebiaReportsQuery, useGetUserQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {CebiaReportLine} from './components/CebiaReportLine';
import {CebiaWidgetLogo} from './components/CebiaWidgetLogo';

export interface CebiaReportWidgetProps extends TestIdProps {
  vehicleId?: string;
}

export function CebiaReportWidget(props: CebiaReportWidgetProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data: reports = []} = useGetCebiaReportsQuery(
    {vehicleId: props.vehicleId!},
    {skip: isNil(props.vehicleId)}
  );

  const [newestReport, ...olderReports] = reports;

  const {data: user} = useGetUserQuery(
    {id: newestReport?.createdBy ?? ''},
    {skip: isNilOrEmpty(newestReport?.createdBy)}
  );

  const userFullName = getNaturalPersonFullName(user);

  if (reports.length === 0) {
    return null;
  }

  return (
    <VStack spacing={2}>
      <Box
        backgroundColor="palettes.neutral.10.100"
        borderRadius="medium"
        overflow="hidden"
        position="relative"
      >
        <HStack>
          <CebiaWidgetLogo alt={i18n.t('page.integrations.labels.cebiaReportTitle')} />
          <Box padding={4} width="100%" backgroundColor="palettes.neutral.10.100">
            <VStack spacing={3}>
              <VStack spacing={2}>
                <Heading size={4}>{i18n.t('page.integrations.labels.cebiaReportTitle')}</Heading>
                <Text size="xSmall">
                  {i18n.t('page.integrations.labels.cebiaReportWidgetDescription')}
                </Text>
              </VStack>
              <HStack spacing={3}>
                <Button
                  onClick={() => window.open(newestReport!.url)}
                  variant="secondary"
                  rightIcon="action/launch"
                  title={i18n.t('page.integrations.labels.cebiaReportViewResults')}
                  data-testid={suffixTestId('cebiaReportWidget-viewResults', props)}
                />
              </HStack>
              <HStack spacing={3}>
                <Text size="xSmall" color="tertiary">
                  {`${i18n.t('entity.cebiaReport.recentReport')} ${
                    newestReport?.createdAt
                      ? formatDateTime('dateShort', parseDate(newestReport.createdAt))
                      : ''
                  }`}
                  {isNotNilOrEmpty(userFullName) ? `, ${userFullName}` : ''}
                </Text>
              </HStack>
              <Show when={isPositive(olderReports.length)}>
                <Separator spacing={1} />
                <VStack spacing={3}>
                  {olderReports.map((report) => (
                    <CebiaReportLine key={report.id} report={report} />
                  ))}
                </VStack>
              </Show>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </VStack>
  );
}
