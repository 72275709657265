/* eslint-disable eag/match-named-export */
import {createContext} from 'shared';

import {DataGridPermissionKeysEnum} from '../types/DataGridPermissionKeysEnum';
import {EntityResourceResolver} from '../types/EntityResourceResolver';
import {UserOptionsQuery} from '../types/UserOptionsQuery';

export type StartedExport = {
  exportId: string;
  gridCode: string;
};

export type DataGridConfigProviderProps = {
  entityResourceResolver?: EntityResourceResolver;
  agGridEnterpriseLicenseKey?: string;
  // function which returns absolute url for given share link, if it is not supplied, the share button will be hidden
  buildAbsoluteUrl?: (url: string) => string;
  useGetUserOptions?: () => UserOptionsQuery;
  usePermissions: (props: {permissionKeys: DataGridPermissionKeysEnum[]}) => boolean[];
  handleExportSuccessfullyStarted?: (startedExport: StartedExport) => void;
};

export const [DataGridConfigProvider, useDataGridConfig] =
  createContext<DataGridConfigProviderProps>({
    strict: true,
    name: 'DataGridConfigProvider',
  });
