import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {buildArray} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {GeneralAdvertisementPlatforms} from './components/GeneralAdvertisementPlatforms';

export function AdvertisingPlatforms() {
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.labels.advertisementPlatform'),
        breadcrumbs: buildArray<BreadcrumbType>().add({
          label: i18n.t('page.salesSettings.labels.advertising'),
          href: settingsRoutes.advertising,
        }),
      }}
      description={i18n.t('page.settings.labels.platformDescription')}
      data-testid="settings-advertising-platforms"
    >
      <GeneralAdvertisementPlatforms />
    </SettingsTemplate>
  );
}
