import {isAfter} from 'date-fns';
import {Button, DeleteDialog, Dropdown, DropdownItem, IconButton} from 'platform/components';
import {Icon} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import styled from 'styled-components';

import {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {defaultTo, path, pipe} from 'ramda';

import {
  CashRegisterResponseBody,
  useGetSeriesListQuery,
  useGetTenantRegisterListQuery,
  useInactivateCashRegisterMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError} from '@dms/shared';
import {
  BulkActionsBar,
  Checkbox,
  ColumnType,
  DataType,
  SimpleTable,
  findByKey,
  noop,
  selectCurrencies,
  selectUserBranches,
} from '@dms/teas';

import {buildArray, composePath, getApiDateString, parseDate} from 'shared';

import {useRowSelect} from '../../../utils/useRowSelect';
import {CardFullWidth} from '../styles';
import {CashRegisterStateEnum} from '../types/CashRegisterStateEnum';
import {CashRegisterStateTag} from './CashRegisterStateTag';

const AlignRight = styled.div`
  text-align: right;
  margin-right: 40px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

export const CashRegisterList: FC = () => {
  const navigate = useNavigate();
  const formatCurrency = useFormatCurrency();

  const [inactivateCashRegister] = useInactivateCashRegisterMutation();

  const {data: cashRegisterList} = useGetTenantRegisterListQuery({inactive: true});
  const {data: seriesList} = useGetSeriesListQuery({type: ['accounting/cash_register_document']});

  const cashRegisterListKeys = (cashRegisterList ?? [])
    .filter(({system}) => !system)
    .map((cashRegister) => cashRegister.id);
  const currencies = useSelector(selectCurrencies);
  const branches = useSelector(selectUserBranches);
  const [cashRegistersToDelete, setCashRegistersToDelete] = useState<CashRegisterResponseBody[]>(
    []
  );
  const {isSelected, allSelected, toggle, toggleAll, someSelected, selectedCount, deselectAll} =
    useRowSelect(cashRegisterListKeys);

  const deleteCashRegisters = async (cashRegisters: CashRegisterResponseBody[]): Promise<void> => {
    await Promise.all(
      cashRegisters.map((cashRegister) =>
        inactivateCashRegister({cashRegisterId: cashRegister.id}).unwrap().catch(handleApiError)
      )
    );
  };

  const getConfirmTitle = (cashRegisters: CashRegisterResponseBody[]): string | undefined => {
    if (cashRegisters.length === 0) {
      return undefined;
    }

    if (cashRegisters.length === 1) {
      return `${i18n.t('general.actions.delete')} ${cashRegisters[0].name}`;
    }

    return i18n.t('page.accountingSettings.labels.deleteMultileCashRegisters');
  };

  const columns = buildArray<ColumnType>([
    {
      Header: (
        <Checkbox
          checked={allSelected() || someSelected()}
          onChange={noop}
          onClick={() => toggleAll()}
          isAllRowsSelected={someSelected()}
          data-testid={testIds.settings.cashRegisters('header-checkbox')}
        />
      ),
      id: 'checkbox',
    },
    {Header: i18n.t('entity.cashRegister.parameters.code'), id: 'code'},
    {
      Header: i18n.t('entity.cashRegister.parameters.description'),
      id: 'name',
    },
    {Header: i18n.t('entity.cashRegister.parameters.state'), id: 'state'},
    {Header: i18n.t('entity.cashRegister.parameters.currency'), id: 'currencyName'},
    {Header: i18n.t('entity.cashRegister.parameters.asOf'), id: 'activeFrom'},
    {
      Header: i18n.t('entity.cashRegister.parameters.expenseSeries'),
      id: 'expenseSeriesName',
    },
    {
      Header: i18n.t('entity.cashRegister.parameters.incomeSeries'),
      id: 'incomeSeriesName',
    },
    {Header: i18n.t('entity.cashRegister.parameters.branch'), id: 'branchName'},
  ])
    .add({
      Header: i18n.t('entity.cashRegister.parameters.eligibleForCardPayments'),
      id: 'eligibleForCardPayments',
    })
    .add({
      Header: <AlignRight>{i18n.t('entity.cashRegister.parameters.currentState')}</AlignRight>,
      id: 'currentBalance',
    })
    .add({Header: null, id: 'action'});

  const createRow = (item: CashRegisterResponseBody): DataType => {
    let state: CashRegisterStateEnum | undefined;
    const currentBalance = getCurrentBalance(item);
    const branchName = findByKey(branches ?? [], 'id', item.branchId)?.name;
    const currencyName = findByKey(currencies, 'code', item.currency)?.name;
    const expenseSeriesName = findByKey(seriesList ?? [], 'id', item.expenseSeriesId)?.name;
    const incomeSeriesName = findByKey(seriesList ?? [], 'id', item.incomeSeriesId)?.name;
    const eligibleForCardPayments = item.eligibleForCardPayments
      ? i18n.t('general.labels.yes')
      : '';

    if (!item?.active && item?.closedAt) {
      state = CashRegisterStateEnum.DELETED;
    } else if (item?.active && isAfter(new Date(), parseDate(item?.activeFrom))) {
      state = CashRegisterStateEnum.ACTIVE;
    } else {
      state = CashRegisterStateEnum.NOT_ACTIVE;
    }

    return {
      id: item.id,
      checkbox: (
        <Checkbox
          checked={isSelected(item.id)}
          onChange={noop}
          data-testid={testIds.settings.cashRegisters('register-name')}
          onClick={(e) => {
            e.stopPropagation();
            toggle(item.id);
          }}
        />
      ),
      code: item.code ?? EMPTY_PLACEHOLDER,
      name: <strong>{item.name ?? EMPTY_PLACEHOLDER}</strong>,
      state: <CashRegisterStateTag state={state} />,
      currencyName: currencyName ?? EMPTY_PLACEHOLDER,
      activeFrom: item.activeFrom
        ? getApiDateString(parseDate(item.activeFrom))
        : EMPTY_PLACEHOLDER,
      expenseSeriesName: expenseSeriesName ?? EMPTY_PLACEHOLDER,
      incomeSeriesName: incomeSeriesName ?? EMPTY_PLACEHOLDER,
      branchName: branchName ?? EMPTY_PLACEHOLDER,
      eligibleForCardPayments: eligibleForCardPayments ?? EMPTY_PLACEHOLDER,
      currentBalance: (
        <AlignRight>
          {item.currentBalance
            ? formatCurrency(currentBalance, item.currentBalance?.currency)
            : EMPTY_PLACEHOLDER}
        </AlignRight>
      ),
      action: (
        <div onClick={(event) => event.stopPropagation()}>
          <Dropdown
            data-testid={testIds.settings.cashRegisters('dropdown-control-action')}
            dropdownControl={
              <IconButton
                icon="navigation/more_vert"
                size="small"
                data-testid={testIds.settings.cashRegisters('dropdown-action-button')}
              />
            }
          >
            <DropdownItem
              onClick={() =>
                navigate(composePath(settingsRoutes.cashRegistersDetail, {params: {id: item.id}}))
              }
              label={i18n.t('general.actions.edit')}
              leftIcon="image/edit"
            />
            {!item?.system && state !== CashRegisterStateEnum.DELETED && currentBalance === 0 && (
              <DropdownItem
                onClick={() => setCashRegistersToDelete([item])}
                label={i18n.t('general.actions.delete')}
                leftIcon="action/delete"
              />
            )}
          </Dropdown>
        </div>
      ),
    };
  };

  return (
    <CardFullWidth
      title={i18n.t('page.accounting.labels.cashRegisters')}
      headerContent={
        <Button
          variant="link"
          leftIcon="content/add_circle"
          title={i18n.t('page.accountingSettings.labels.newCashRegister')}
          data-testid={testIds.settings.cashRegisters('createNew')}
          onClick={() => navigate(settingsRoutes.newCashRegister)}
        />
      }
      noPadding
    >
      {selectedCount > 0 && (
        <BulkActionsBar
          itemsSelected={selectedCount}
          actions={[
            {
              label: i18n.t('general.actions.delete'),
              icon: <Icon value="action/delete_outline" />,
              onClick: () =>
                setCashRegistersToDelete(
                  (cashRegisterList ?? []).filter((cashRegister) => isSelected(cashRegister.id))
                ),
            },
          ]}
          onCancel={() => deselectAll()}
        />
      )}

      <TableContainer>
        <SimpleTable
          noZebra
          showRowDivider
          tdPadding="default"
          highlightRowOnHover
          columns={columns}
          rows={[{data: (cashRegisterList || []).map(createRow)}]}
          onRowClick={(row) =>
            navigate(
              composePath(settingsRoutes.cashRegistersDetail, {params: {id: row.id as string}})
            )
          }
        />
      </TableContainer>

      <DeleteDialog
        isOpen={cashRegistersToDelete.length !== 0}
        text={getConfirmTitle(cashRegistersToDelete)}
        onClose={() => setCashRegistersToDelete([])}
        onConfirm={() => {
          deselectAll();
          deleteCashRegisters(cashRegistersToDelete);
          setCashRegistersToDelete([]);
        }}
        data-testid={testIds.settings.cashRegisters('cashRegistersToDelete')}
      />
    </CardFullWidth>
  );
};

const getCurrentBalance = pipe<any, string | undefined, number, number>(
  path(['currentBalance', 'amount']),
  Number,
  defaultTo(0)
);
