import {Switch} from 'platform/components';
import {HStack} from 'platform/foundation';

import {FC} from 'react';

import {testIds} from '@dms/routes';
import {noop} from '@dms/teas';

import {Subtext, SwitchWrapper} from './styles';

export type VehicleServiceContentItemProps = {
  switcherName: string;
  switcherOn?: boolean;
  onSwitcherToggle?: typeof noop;
  title: string;
  label: string;
  isDisabled?: boolean;
};

export const VehicleServiceContentItem: FC<VehicleServiceContentItemProps> = ({
  switcherName,
  switcherOn,
  onSwitcherToggle,
  title,
  label,
  isDisabled,
}) => (
  <HStack align="flex-start">
    <SwitchWrapper onClick={(e) => e.stopPropagation()}>
      <Switch
        name={switcherName}
        isDisabled={isDisabled}
        onChange={onSwitcherToggle}
        value={!!switcherOn}
        data-testid={testIds.settings.vehicleServices(`switch-${switcherName}`)}
      />
    </SwitchWrapper>
    <div>
      {title}
      <Subtext>{label}</Subtext>
    </div>
  </HStack>
);
