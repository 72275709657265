import {useParams} from 'react-router-dom';

import {defaultTo} from 'ramda';

type WarehouseArticleParams = {
  id: string;
  warehouseId: string;
  directSaleVariantId: string;
  receiveNoteId: string;
};

export function useWarehouseParams() {
  const params = useParams<WarehouseArticleParams>();

  return {
    articleId: defaultTo('', params.id),
    warehouseId: defaultTo('', params.warehouseId),
    receiveNoteId: defaultTo('', params.receiveNoteId),
    directSaleId: defaultTo('', params.id),
    directSaleVariantId: defaultTo('', params.directSaleVariantId),
    serviceOrderIssueNoteId: defaultTo('', params.id),
    deliveryNoteId: defaultTo('', params.id),
    supplierOrderId: defaultTo('', params.id),
    issueNoteId: defaultTo('', params.id),
    receiveNoteCorrectionId: defaultTo('', params.id),
    serviceOrderReturnId: defaultTo('', params.id),
    costCorrectionId: defaultTo('', params.id),
  };
}
