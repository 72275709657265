export enum VectorType {
  Aisle = 'AISLE',
  Rack = 'RACK',
  Level = 'LEVEL',
  Position = 'POSITION',
  Other = 'OTHER',
}

export enum SymbolType {
  Alphabet = 'ALPHABET',
  Numbers = 'NUMBERS',
}

export interface LocationRow {
  isSelected: boolean;
  vectorType: VectorType;
  vectorPosition: number;
  number: number;
  symbolType: SymbolType;
  beginAt: string;
}

export type MultipleLocationsForm = {
  definition: LocationRow[];
  allowMultipleUsage: boolean;
};
