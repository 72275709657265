import {array, mixed, number, object, string} from 'yup';

import i18n from '@dms/i18n';

import {POSITIVE_NUMBER_REGEX, yupString} from 'shared';

// TODO: https://carvago.atlassian.net/browse/T20-30755
export const $CreateCorrectiveTaxDocumentSchema = object({
  documentSeriesId: string().required(),
  issueDate: string().required(),
  dueDate: string().required(),
  dateOfTaxableSupply: string().required(),
  dateOfReception: yupString.required(),
  reason: yupString.required(),
  dueSinceIssueDateInDays: number().min(0).nullable(),
  internalNote: yupString,
  footerNote: yupString,
  paymentInfo: object({
    paymentMethod: mixed().oneOf([
      'BANK_TRANSFER',
      'CARD',
      'CASH',
      'CASH_ON_DELIVERY',
      'OFFSET',
      'FREE_OF_CHARGE',
    ]),
    bankAccount: string().when('paymentMethod', {
      is: 'BANK_TRANSFER',
      then: yupString.required(),
      otherwise: yupString,
    }),
    variableSymbol: yupString.max(10),
    constantSymbol: yupString.max(4).min(4),
    specificSymbol: yupString.max(10),
    iban: yupString,
    swift: yupString,
  }),
  invoiceItems: array().of(
    object({
      pricePerUnit: string().nullable().required(),
      quantity: string()
        .matches(POSITIVE_NUMBER_REGEX, i18n.t('general.notifications.numberPositive'))
        .nullable()
        .required(),
      vatType: mixed()
        .required()
        .oneOf([['S', 'R', 'MR', 'E', 'Z', 'SR', 'P']]),
      name: yupString,
    })
  ),
});
