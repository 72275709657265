import {Action, Button, Separator, openDeleteDialog, showNotification} from 'platform/components';
import {Box, Heading, Show, Space, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useDeleteSectorMutation,
  useGetGeneralSettingsStorageLocationQuery,
  usePostStorageLocationSettingsSetAsAllowMutation,
  usePostStorageLocationSettingsSetAsDisallowMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {buildArray, composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {type: 'TIRE_ORDER'}]);

export function TireStorageLocations() {
  const navigate = useNavigate();

  const {data, isLoading, isError, isFetching} = useGetGeneralSettingsStorageLocationQuery();
  const [postStorageLocationSettingsSetAsAllow, {isLoading: isAllowLocationLoading}] =
    usePostStorageLocationSettingsSetAsAllowMutation();
  const [postStorageLocationSettingsSetAsDisallow, {isLoading: isDisallowLocationLoading}] =
    usePostStorageLocationSettingsSetAsDisallowMutation();
  const [deleteSector] = useDeleteSectorMutation();

  const handleUpdateStorageLocation = () => {
    const postAction = data?.isStoreLocationAllowed
      ? postStorageLocationSettingsSetAsDisallow
      : postStorageLocationSettingsSetAsAllow;

    postAction().unwrap().catch(handleApiError);
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('detail', () =>
        navigate(composePath(settingsRoutes.tireStorageLocationDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteSector({sectorId: id})
              .unwrap()
              .then(() => {
                refreshData();
                showNotification.success();
              })
              .catch(handleApiError),
        })
      )
      .otherwise(always(undefined));
  };

  const isUpdateSettingsLoading = isAllowLocationLoading || isDisallowLocationLoading;

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.tiresInventory.labels.storageLocation'),
        actions: buildArray<Action>().when(data?.isStoreLocationAllowed, {
          title: i18n.t('entity.tiresInventory.actions.newLocation'),
          type: 'button',
          onClick: () => navigate(settingsRoutes.tireCreateStorageLocation),
        }),
      }}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.tireStorageLocation('template')}
    >
      <Heading size={4}>{i18n.t('entity.tiresInventory.labels.storageLocationSettings')}</Heading>
      <Space vertical={2} />
      <Text size="xSmall" color="tertiary">
        {i18n.t('entity.tiresInventory.labels.storageLocationSettingsDescription')}
      </Text>
      <Space vertical={6} />
      <Button
        title={
          data?.isStoreLocationAllowed
            ? i18n.t('entity.tiresInventory.actions.disableStorageLocation')
            : i18n.t('entity.tiresInventory.actions.enableStorageLocation')
        }
        variant={data?.isStoreLocationAllowed ? 'secondary' : 'primary'}
        onClick={handleUpdateStorageLocation}
        isLoading={isUpdateSettingsLoading || isFetching}
      />
      <Show when={data?.isStoreLocationAllowed}>
        <Separator spacing={6} />
        <Box maxHeight="100%">
          <DataGrid
            gridCode="storage-sector"
            autoHeight
            actionCallback={actionCallback}
            queryModifier={queryModifier}
          />
        </Box>
      </Show>
    </SettingsTemplate>
  );
}
