import {FormControl, FormField, Option} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemWorkTypeProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
  workTypeOptions: Option[];
  areWorkTypesLoading: boolean;
}

export function RequestTabLabourBulkEditItemWorkType(
  props: RequestTabLabourBulkEditItemWorkTypeProps
) {
  const isWorkTypeDisabled = not(props.formApi.watch('isWorkTypeEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isWorkTypeEnabled"
            data-testid={suffixTestId('isWorkTypeEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isWorkTypeDisabled}
            isLoading={props.areWorkTypesLoading}
            control={props.control}
            type="choice"
            name="workType"
            label={i18n.t('entity.warehouse.labels.workType')}
            options={props.workTypeOptions}
            menuInPortal
            data-testid={suffixTestId('workType', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}
