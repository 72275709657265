import {FormControl, FormField, Separator, Option} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {SalesPriceCalculation} from './SalesPriceCalculation';

interface PricingFormProps extends RequiredTestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  formApi: UseFormReturn<WarehouseSettingsForm>;
  salesPriceCalculationOptions: Option[] | Nullish;
  baseSourcePriceOptions: Option[] | Nullish;
}

export function PricingForm(props: PricingFormProps) {
  return (
    <VStack spacing={6}>
      <Heading size={3}>{i18n.t('entity.warehouse.labels.pricing')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="commercialOrderMarginMinimum"
            type="number"
            label={`${i18n.t('entity.warehouse.labels.minimumMargin')} (%)`}
            control={props.control}
            isStepperVisible
            data-testid={suffixTestId('inputs.commercialOrderMarginMinimum', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={2}>
        <Separator spacing={0} />
      </Grid>

      <Heading size={4}>{i18n.t('entity.warehouse.labels.defaltArticlePricing')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <SalesPriceCalculation
            control={props.control}
            formApi={props.formApi}
            calculationSalesPrice={props.salesPriceCalculationOptions}
            data-testid={suffixTestId('inputs.salesPriceCalculation', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            name="basePriceSource"
            type="radio"
            isRequired
            label={i18n.t('entity.warehouse.labels.basePriceSource')}
            options={props.baseSourcePriceOptions || []}
            control={props.control}
            direction="column"
            spacing={3}
            tooltip={i18n.t('entity.warehouse.labels.basePriceSourceTooltip')}
            data-testid={suffixTestId('inputs.basePriceSource', props)}
          />
        </GridItem>
      </Grid>

      <FormField
        name="unitSalesPriceWithVat"
        type="switch"
        label={i18n.t('entity.warehouse.labels.unitSalesPriceWithVat')}
        control={props.control}
        data-testid={suffixTestId('inputs.unitSalesPriceWithVat', props)}
      />

      <FormField
        name="blockPricingSettingOnArticle"
        type="switch"
        label={i18n.t('entity.warehouse.labels.blockPricingSettingOnArticle')}
        control={props.control}
        data-testid={suffixTestId('inputs.blockPricingSettingOnArticle', props)}
      />
    </VStack>
  );
}
