import {Action, Actions, Alert, Label, Parameter, Parameters} from 'platform/components';
import {HStack, Heading, Hide, Show, Space, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  VehicleActiveReservationApiResponse,
  VehicleReservationResponseBody,
  useGetParticipationQuery,
} from '@dms/api';
import i18n from '@dms/i18n';

import {TestIdProps, buildArray, parseDate, suffixTestId} from 'shared';

import {usePermissions} from '../../../hooks/usePermissions/usePermissions';

interface ActiveReservationDetailProps extends TestIdProps {
  reservation: VehicleReservationResponseBody | VehicleActiveReservationApiResponse;
  isCancelled?: boolean;
  onEdit?: VoidFunction;
  onDelete?: VoidFunction;
}

export function ActiveReservationDetail(props: ActiveReservationDetailProps) {
  const formatDateTime = useDateTimeFormatter();
  const reservationDate = parseDate(props.reservation.createdAt);
  const expirationDate = parseDate(props.reservation.reservedUntil);
  const cancellationDate = parseDate(props.reservation.canceledAt);

  const {id: vehicleId} = useParams();

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: vehicleId ?? '',
    },
    {skip: isNil(vehicleId)}
  );

  const [hasVehicleCancelReservationPermission, hasVehicleUpdateReservationPermission] =
    usePermissions({
      permissionKeys: ['vehicleCancelReservation', 'vehicleUpdateReservation'],
      scopes: {
        vehicleCancelReservation: {participation: vehicleParticipation},
        vehicleUpdateReservation: {participation: vehicleParticipation},
      },
    });

  const actions = buildArray<Action>()
    .when(isNotNil(props.onEdit) && hasVehicleUpdateReservationPermission, {
      leftIcon: 'image/edit',
      type: 'button',
      title: i18n.t('general.actions.edit'),
      variant: 'ghostLink',
      onClick: props.onEdit!,
      'data-testid': suffixTestId('edit', props),
    })
    .when(
      !props.reservation.paidDeposit &&
        isNotNil(props.onDelete) &&
        hasVehicleCancelReservationPermission,
      {
        leftIcon: 'action/delete',
        type: 'button',
        title: i18n.t('general.actions.delete'),
        variant: 'dangerGhost',
        onClick: props.onDelete!,
        'data-testid': suffixTestId('delete', props),
      }
    );

  return (
    <VStack spacing={4}>
      <VStack spacing={1}>
        <HStack align="center" justify="space-between">
          <Heading size={4}>
            {i18n.t(
              props.isCancelled
                ? 'entity.vehicle.labels.recentReservation'
                : 'entity.vehicle.labels.activeReservation'
            )}
          </Heading>
          <Actions
            actions={actions}
            data-testid={suffixTestId('vehicleReservationActions', props)}
          />
        </HStack>

        <Parameters
          color="secondary"
          parameters={buildArray<Parameter>()
            .add(
              `${i18n.t('entity.vehicle.labels.reserved')}: ${
                reservationDate ? formatDateTime('dateTimeShort', reservationDate) : null
              }`
            )
            .when(
              props.reservation.createdBy,
              `${i18n.t('general.labels.author')}: ${props.reservation.createdBy.fullName}`
            )}
          data-testid={suffixTestId('vehicleReservationParameters', props)}
        />

        <Space vertical={4} />

        <Show when={isNotNilOrEmpty(props.reservation.note)}>
          <VStack spacing={1}>
            <Label>{i18n.t('general.labels.note')}</Label>
            <Text size="small">{props.reservation.note}</Text>
          </VStack>
        </Show>
      </VStack>
      <Hide when={props.isCancelled}>
        <Alert
          type="inline"
          variant="info"
          title={`${i18n.t('entity.vehicle.labels.expirationDate')}:`}
          message={expirationDate ? formatDateTime('dateShort', expirationDate) : undefined}
          data-testid={suffixTestId('vehicleReservationAlert-expirationDate', props)}
        />
      </Hide>
      <Show when={props.isCancelled}>
        <Alert
          type="inline"
          variant="error"
          title={i18n.t('entity.vehicle.labels.reservationCancelled')}
          message={cancellationDate ? formatDateTime('dateTimeShort', cancellationDate) : undefined}
          data-testid={suffixTestId('vehicleReservationAlert-reservationCancelled', props)}
        />
      </Show>
    </VStack>
  );
}
