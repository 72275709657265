import {unwrapResult} from '@reduxjs/toolkit';
import {Alert, AlertVariants, Button, Dialog, Flag} from 'platform/components';
import {Spinner, Box, Center, Heading, Hide, HStack, Show, Text, VStack} from 'platform/foundation';

import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';

import {composePath, useNavigate} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {getAutofillFeatures, putAutofillFeature} from '../../../store/carDetails/actions';
import {selectIntegrationsSettings} from '../../../store/generalSettings/selectors';
import {noop} from '../../../utils/someTeasUtils';
import {CebiaWidgetLogo} from '../../CebiaWidget/components/CebiaWidgetLogo';
import {useVehicleCreateContext} from '../../VehicleCreateContext/hooks/useVehicleCreateContext';

export type CebiaAutofillProps = {
  vehicleId?: string; // if vehicleId is passed, PUT endpoint will be used instead of GET
  vin?: string | null;
  onSuccess?: (features: string[]) => void;
  decoderType?: string;
  image?: string;
};

export function CebiaAutofillWidget({
  vin,
  onSuccess,
  vehicleId,
  decoderType,
  image,
}: CebiaAutofillProps) {
  const apiDispatch = useThunkDispatch();
  const {setTabIndex} = useVehicleCreateContext();
  const {cebiaEquipmentCredentials} = useSelector(selectIntegrationsSettings);
  const navigate = useNavigate();

  const [isFilled, setIsFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [variant, setVariant] = useState<AlertVariants | null>(null);

  const handleAutofill = useCallback(async () => {
    try {
      if (!vin) {
        setVariant('warning');
        setMessage(i18n.t('entity.vehicle.notifications.vinCodeRequired'));
        return;
      }

      setIsLoading(true);
      let features: string[] = [];
      if (vehicleId) {
        features = unwrapResult(
          await apiDispatch(putAutofillFeature.action({vehicleId, decoderType}))
        ).features;
      } else if (vin) {
        features = unwrapResult(
          await apiDispatch(getAutofillFeatures.action({vin, decoderType}))
        ).features;
      }

      onSuccess?.(features);

      setIsFilled(true);
      setVariant('success');
      setMessage(
        i18n.t('entity.vehicle.notifications.autofillFeaturesUpdate', {
          count: features?.length,
        })
      );
      setIsLoading(false);
    } catch (error: any) {
      const message = error?.response?.data?.errors?.[0]?.message;
      setIsLoading(false);

      setMessage(message);
      setVariant('warning');
    }
  }, [vin, onSuccess, apiDispatch]);

  const handleSetVin = useCallback(
    () =>
      vehicleId
        ? navigate(composePath(vehiclesRoutes.edit, {params: {id: vehicleId}}))
        : setTabIndex(0),
    [navigate, setTabIndex, vehicleId]
  );

  if (!cebiaEquipmentCredentials?.enabled) {
    return null;
  }

  return (
    <VStack spacing={2}>
      <Box backgroundColor="palettes.neutral.10.100" borderRadius="medium" overflow="hidden">
        <HStack>
          <CebiaWidgetLogo alt={i18n.t('entity.vehicle.labels.autofillFeatures')} image={image} />
          <Box padding={4}>
            <VStack spacing={3}>
              <VStack spacing={2}>
                <Heading size={4}>{i18n.t('entity.vehicle.labels.autofillFeatures')}</Heading>
                <Text size="xSmall">{i18n.t('entity.vehicle.labels.cebiaWidgetInfoText')}</Text>
              </VStack>
              <Show when={isFilled}>
                <Flag
                  colorScheme="green"
                  data-testid="button-features.integrations.autofilled"
                  label={i18n.t('entity.vehicle.labels.autofilled')}
                />
              </Show>
              <Hide when={isFilled}>
                <Box>
                  <Button
                    variant="secondary"
                    isLoading={isLoading}
                    onClick={handleAutofill}
                    data-testid="button-features.integrations.autofill"
                    title={i18n.t('entity.vehicle.actions.autofill')}
                  />
                </Box>
              </Hide>
            </VStack>
          </Box>
        </HStack>
      </Box>
      <Show when={variant}>
        <Box>
          <Alert
            type="inline"
            variant={variant ?? undefined}
            message={message}
            onClose={() => {
              setMessage('');
              setVariant(null);
            }}
            data-testid={testIds.vehicles.create('vinAlert')}
            hyperlinks={
              variant === 'warning'
                ? [
                    {
                      title: i18n.t('entity.vehicle.actions.enterVIN'),
                      onClick: handleSetVin,
                      'data-testid': 'button-features.integrations.enterVIN',
                    },
                  ]
                : undefined
            }
          />
        </Box>
      </Show>
      <Show when={isLoading}>
        <Dialog
          isOpen={isLoading}
          onClose={noop}
          size="small"
          data-testid={testIds.vehicles.create('loadingFeatures')}
        >
          <Center>
            <Spinner />

            <Box paddingLeft={4}>
              <Text alternative>{i18n.t('entity.vehicle.labels.loadingFeatures')}</Text>
            </Box>
          </Center>
        </Dialog>
      </Show>
    </VStack>
  );
}
