import {Checkbox, CheckboxProps, IconButton, Separator, Tooltip} from 'platform/components';
import {Box, Clickable, HStack, Icon, Show, Space, Text, ThemeColorPath} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isNil} from 'ramda';
import {isNotNil, isNotNilOrEmpty, padCharsStart} from 'ramda-adjunct';

import {testIds} from '@dms/routes';

import {Row} from '../types/Row';
import {Column} from '../utils/getSourcingTableColumns';
import {TableDetailRow} from './TableDetailRow';

interface TableRowProps {
  columns: Column[];
  row: Row;
  index: number;
  onClick?: () => void;
  isSelected: boolean;
  isChecked: boolean;
  isExpanded: boolean;
  onExpandedChange: (row: Row, isExpanded: boolean) => void;
  onCheck: (checked: boolean) => void;
  setLastVisitedVehicle: (adId: string, index: number) => void;
}

export function SourcingTableRow(props: TableRowProps) {
  const indexFrom1 = props.index + 1;

  const leftBorderColor = match<any, ThemeColorPath | undefined>(props.row)
    .with({inCart: true}, () => 'palettes.blue.60.100')
    .with({favourite: true}, () => 'palettes.orange.50.100')
    .with({comparison: true}, () => 'palettes.purple.60.100')
    .with({seen: true}, () => 'palettes.neutral.40.100')
    .otherwise(() => undefined);

  const onCheckboxChange: CheckboxProps['onChange'] = (value) => {
    props.onCheck(value);
  };

  return (
    <Clickable onClick={props.onClick}>
      <Box
        paddingVertical={2}
        paddingHorizontal={3}
        backgroundColor={
          props.isSelected
            ? 'palettes.blue.20.100'
            : props.isExpanded
              ? 'palettes.neutral.10.100'
              : undefined
        }
        position="relative"
      >
        <Box
          position="absolute"
          left={0}
          top={0}
          bottom={0}
          width={1}
          backgroundColor={leftBorderColor}
        />
        <HStack spacing={2}>
          <HStack width={14} spacing={2}>
            <Clickable onClick={(event) => event.stopPropagation()}>
              <Checkbox
                value={props.isChecked}
                onChange={onCheckboxChange}
                data-testid={testIds.sourcing.classifieds('sourcingTable-checkbox')}
              />
            </Clickable>
            <Box>
              <Text size="xxSmall" color="secondary">
                {padCharsStart('0', 4, indexFrom1.toString())}
              </Text>
              <Space vertical={1} />
              <Show when={isNotNilOrEmpty(props.row.vehicleSummary?.comment)}>
                <Tooltip label={props.row.vehicleSummary?.comment}>
                  <Icon value="communication/comment" />
                </Tooltip>
              </Show>
            </Box>
          </HStack>
          {props.columns.map((column) => (
            <Box
              key={column.id}
              width={column.width}
              minWidth={isNil(column.width) ? 65 : 0}
              flex={isNotNil(column.width) ? undefined : 1}
            >
              <column.Cell row={{original: props.row as any}} />
            </Box>
          ))}
          <Box width={6}>
            <IconButton
              icon={
                props.isExpanded ? 'hardware/keyboard_arrow_up' : 'hardware/keyboard_arrow_down'
              }
              onClick={(event) => {
                event.stopPropagation();
                props.onExpandedChange(props.row, !props.isExpanded);
              }}
              size="small"
              data-testid={testIds.sourcing.classifieds('sourcingTable-iconButton')}
            />
          </Box>
        </HStack>
        <Show when={props.isExpanded}>
          <Box paddingLeft={16}>
            <Separator orientation="horizontal" spacing={2} />
            <TableDetailRow
              row={{
                index: props.index,
                original: props.row as any,
              }}
              setLastVisitedVehicle={props.setLastVisitedVehicle}
              data-testid={testIds.sourcing.classifieds('sourcingTable-detailRow')}
            />
          </Box>
        </Show>
      </Box>
    </Clickable>
  );
}
