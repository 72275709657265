import {Menu, MenuLink} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {ReactElement, ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';

import {browserStorageKey} from '@dms/config';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {myProfileRoutes} from '@dms/routes';
import {FullScreenModal} from '@dms/shared';

import {buildArray, suffixTestId, TestIdProps, useNavigate} from 'shared';

export interface ProfileLayoutProps extends TestIdProps {
  children: ReactNode;
}

export function DeprecatedProfileLayout(props: ProfileLayoutProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const onClose = () => {
    const backUrl = localStorage.getItem(browserStorageKey.BACKUP_URL);
    localStorage.removeItem(browserStorageKey.BACKUP_URL);
    navigate(backUrl || '/');
  };

  const menuItems = buildArray<MenuLink>([
    {
      id: myProfileRoutes.personalInformation,
      label: i18n.t`entity.user.labels.personalInformation`,
      leftIcon: 'social/person',
      hasSeparator: false,
    },
    {
      id: myProfileRoutes.security,
      label: i18n.t`entity.user.labels.security`,
      leftIcon: 'hardware/security',
      hasSeparator: true,
    },
    {
      id: myProfileRoutes.dashboard,
      label: i18n.t`page.myProfile.dashboard.sidebarTitle`,
      leftIcon: 'action/dashboard',
      hasSeparator: false,
    },
    {
      id: myProfileRoutes.language,
      label: i18n.t`entity.user.labels.langAndTime`,
      leftIcon: 'social/publicon',
      hasSeparator: false,
    },
  ]).whenFeatureEnabled(featureFlags.NOTIFICATION_SETTINGS, {
    id: myProfileRoutes.notifications,
    label: i18n.t('page.settings.notifications.title'),
    leftIcon: 'alert/notification_important',
    hasSeparator: false,
  });

  return (
    <>
      <Helmet>
        <title>{i18n.t('entity.user.labels.myProfile')}</title>
      </Helmet>

      <FullScreenModal headline={i18n.t('entity.user.labels.myProfile')} onClose={onClose}>
        <Box padding={4} minHeight="100%">
          <HStack spacing={4} height="100%">
            <Box flexBasis="15rem" flexShrink={0} flexGrow={0}>
              <Menu
                items={menuItems}
                activeItemId={location.pathname}
                onItemChange={navigate}
                data-testid={suffixTestId('sidebar', props)}
              />
            </Box>
            <HStack justify="center" grow={1} minWidth={176} height="100%">
              {props.children}
            </HStack>
          </HStack>
        </Box>
      </FullScreenModal>
    </>
  );
}
