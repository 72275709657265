import {Separator} from 'platform/components';
import {Grid, Heading, Hide, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {useSelector} from 'react-redux';

import {defaultTo, pipe, toUpper} from 'ramda';

import i18n from '@dms/i18n';
import {UmweltplaketteEnum} from '@dms/shared';

import {selectEmissionClasses} from '../../../store/vehicleCatalogue/selectors';
import {FuelTypeEnum} from '../../../types/FuelTypeEnum';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';
import {Dimensions} from './Dimensions';

interface AdditionalInformationProps {
  shouldShowUmweltplakette?: boolean;
}

export function AdditionalInformation(props: AdditionalInformationProps) {
  const {Field, Subscribe} = useFormRenderer<VehicleCreateFormState>();

  const UmweltplaketteOptions = [
    {
      label: i18n.t('entity.vehicle.labels.umweltplakette.none'),
      value: UmweltplaketteEnum.none,
    },
    {
      label: i18n.t('entity.vehicle.labels.umweltplakette.red'),
      value: UmweltplaketteEnum.red,
    },
    {
      label: i18n.t('entity.vehicle.labels.umweltplakette.yellow'),
      value: UmweltplaketteEnum.yellow,
    },
    {
      label: i18n.t('entity.vehicle.labels.umweltplakette.green'),
      value: UmweltplaketteEnum.green,
    },
  ];

  return (
    <Grid columns={1}>
      <Subscribe
        name="type"
        component={({input: {value: vehicleType}}) => {
          const isTrailerOrSemiTrailer = [
            'VEHICLETYPE_TRAILER',
            'VEHICLETYPE_SEMI_TRAILER',
          ].includes(vehicleType);
          if (isTrailerOrSemiTrailer) {
            return null;
          }
          return (
            <>
              <Hide when={isTrailerOrSemiTrailer}>
                <Heading size={4}>{i18n.t('entity.vehicle.labels.engine')}</Heading>
                <div
                  css={css`
                    margin-bottom: -16px;
                  `}
                >
                  <Grid columns={4}>
                    <Field
                      name="engine.engineName"
                      label={i18n.t('entity.vehicle.labels.engineName')}
                      format={toUpperCase}
                    />
                    <Field
                      name="engine.engineNumber"
                      label={i18n.t('entity.vehicle.labels.engineNumber')}
                      format={toUpperCase}
                    />
                    <Field
                      name="engine.engineCode"
                      label={i18n.t('entity.vehicle.labels.engineCode')}
                      format={toUpperCase}
                    />
                    <Field
                      name="engine.maxTorque"
                      label={i18n.t('entity.vehicle.labels.maxTorque')}
                    />
                  </Grid>
                  <Grid columns={4}>
                    <Subscribe<FuelTypeEnum>
                      name="fuelType"
                      component={({input: {value: FuelType}}) => {
                        const isNotElectricNorHybrid =
                          FuelType !== FuelTypeEnum.FUELTYPE_ELECTRIC &&
                          FuelType !== FuelTypeEnum.FUELTYPE_HYBRID;

                        if (isNotElectricNorHybrid) {
                          return null;
                        }

                        return (
                          <Field
                            name="engine.electricEngineNote"
                            label={i18n.t('entity.vehicle.labels.electricEngineNote')}
                          />
                        );
                      }}
                    />
                  </Grid>
                </div>
                <Separator spacing={0} />
                <Heading size={4}>
                  {i18n.t('entity.vehicle.labels.consumptionAndEmissionSectionTitle')}
                </Heading>
                <div
                  css={css`
                    margin-bottom: -16px;
                  `}
                >
                  <Grid columns={4}>
                    <Field
                      as="number"
                      name="engine.fuelConsumptionCombined"
                      label={i18n.t('entity.vehicle.labels.combined')}
                      suffix={i18n.t(`general.metric.l100km`)}
                    />
                    <Field
                      as="number"
                      name="engine.fuelConsumptionUrban"
                      label={i18n.t('entity.vehicle.labels.urban')}
                      suffix={i18n.t(`general.metric.l100km`)}
                    />
                    <Field
                      as="number"
                      name="engine.fuelConsumptionExtraUrban"
                      label={i18n.t('entity.vehicle.labels.extraUrban')}
                      suffix={i18n.t(`general.metric.l100km`)}
                    />
                  </Grid>
                </div>
              </Hide>
              <Subscribe<FuelTypeEnum>
                name="fuelType"
                component={({input: {value: FuelType}}) => {
                  const shouldHide =
                    FuelType === FuelTypeEnum.FUELTYPE_ELECTRIC ||
                    FuelType === FuelTypeEnum.FUELTYPE_HYDROGEN;

                  if (shouldHide) {
                    return null;
                  }

                  return (
                    <>
                      <div
                        css={css`
                          margin-bottom: -16px;
                        `}
                      >
                        <Grid columns={4}>
                          <Subscribe
                            name="type"
                            component={({input: {value: vehicleType}}) => {
                              /* eslint-disable react-hooks/rules-of-hooks */
                              const emissionClassList = useSelector(
                                selectEmissionClasses(vehicleType)
                              );
                              return (
                                <Field
                                  as="select"
                                  name="engine.emissionClass"
                                  label={i18n.t('entity.vehicle.labels.emissionClass')}
                                  options={emissionClassList}
                                />
                              );
                            }}
                          />
                          <Field
                            as="integer"
                            name="engine.carbonDioxideEmission"
                            label={i18n.t('entity.vehicle.labels.emissionsCo2WO')}
                            suffix={i18n.t('general.metric.gkm')}
                          />
                          <Show when={props.shouldShowUmweltplakette}>
                            <Field
                              as="select"
                              name="umweltplakette"
                              label={i18n.t('entity.vehicle.labels.umweltplakette.title')}
                              options={UmweltplaketteOptions}
                            />
                          </Show>
                        </Grid>
                      </div>

                      <div
                        css={css`
                          margin-bottom: -16px;
                        `}
                      >
                        <Grid columns={1}>
                          <Field
                            as="checkbox"
                            name="engine.hasDpf"
                            label={i18n.t('entity.vehicle.labels.particulateFilter')}
                          />
                        </Grid>
                      </div>
                      <Separator spacing={0} />
                    </>
                  );
                }}
              />
            </>
          );
        }}
      />

      <Heading size={4}>{i18n.t('entity.vehicle.labels.dimensions')}</Heading>

      <Dimensions />
    </Grid>
  );
}

const toUpperCase = pipe(defaultTo(''), toUpper);
