import {z} from 'zod';

import {AssignedMechanicSchema, PriceWithAndWithoutVatSchema} from './aftersalesCommon';
import {BaseIdSchema, BaseVoidResponseSchema} from './base';
import {BaseDirectSaleIdSchema, BaseDirectSaleLabourItemSchema} from './metadaWarehouseDirectSale';

/**
 * GET
 */
export const GetDirectSaleLabourBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleLabourBasketItemsRequest = z.infer<
  typeof GetDirectSaleLabourBasketItemsRequestSchema
>;

export const GetDirectSaleLabourBasketItemsResponseSchema = z.object({
  workBasketItem: z.array(BaseDirectSaleLabourItemSchema),
  workBasketTotalPrice: PriceWithAndWithoutVatSchema,
  assignMechanicId: z.string().nullish(),
});
export type GetDirectSaleLabourBasketItemsResponse = z.infer<
  typeof GetDirectSaleLabourBasketItemsResponseSchema
>;

/**
 * POST
 */

export const BaseDirectSaleAssignMechanicsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z
    .object({
      assignMechanics: AssignedMechanicSchema,
    })
    .optional(),
});
export type BaseDirectSaleAssignMechanicsRequest = z.infer<
  typeof BaseDirectSaleAssignMechanicsRequestSchema
>;

export const PostDirectSaleLabourItemsMechanicRequestSchema =
  BaseDirectSaleAssignMechanicsRequestSchema;
export type PostDirectSaleLabourItemsMechanicRequest = z.infer<
  typeof PostDirectSaleLabourItemsMechanicRequestSchema
>;

export const PostDirectSaleLabourItemsMechanicResponseSchema = BaseVoidResponseSchema;
export type PostDirectSaleLabourItemsMechanicResponse = z.infer<
  typeof PostDirectSaleLabourItemsMechanicResponseSchema
>;

export const PostDirectSaleLabourItemToBasketRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    labourCatalogId: z.string(),
  }),
});
export type PostDirectSaleLabourItemToBasketRequest = z.infer<
  typeof PostDirectSaleLabourItemToBasketRequestSchema
>;

export const PostDirectSaleLabourItemToBasketResponseSchema = z.object({
  workBasketItem: BaseIdSchema,
});
export type PostDirectSaleLabourItemToBasketResponse = z.infer<
  typeof PostDirectSaleLabourItemToBasketResponseSchema
>;

/**
 * PATCH
 */
export const PatchDirectSaleLabourBasketItemQuantityRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchDirectSaleLabourBasketItemQuantityRequest = z.infer<
  typeof PatchDirectSaleLabourBasketItemQuantityRequestSchema
>;

export const PatchDirectSaleLabourBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleLabourBasketItemQuantityResponse = z.infer<
  typeof PatchDirectSaleLabourBasketItemQuantityResponseSchema
>;

export const BulkPatchDirectSaleLabourBasketItemRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    itemId: z.array(z.string()),
    itemSettings: z.object({
      isMechanic: z.boolean(),
      mechanicId: z.string().nullable(),
      isCostCenter: z.boolean(),
      costCenterId: z.string().nullable(),
      isDiscount: z.boolean(),
      discountRate: z.number().nullable(),
      isVatType: z.boolean(),
      vatType: z.string().nullable(),
      isWorkType: z.boolean(),
      workType: z.string().nullable(),
      isPurchasePricePerUnit: z.boolean(),
      purchasePricePerUnit: z.number().nullable(),
      isSellingPricePerUnit: z.boolean(),
      sellingPricePerUnit: z.number().nullable(),
    }),
  }),
});
export type BulkPatchDirectSaleLabourBasketItemRequest = z.infer<
  typeof BulkPatchDirectSaleLabourBasketItemRequestSchema
>;

export const BulkPatchDirectSaleLabourBasketItemResponseSchema = BaseVoidResponseSchema;
export type BulkPatchDirectSaleLabourBasketItemResponse = z.infer<
  typeof BulkPatchDirectSaleLabourBasketItemResponseSchema
>;

/**
 * DELETE
 */

export const DeleteDirectSaleLabourBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type DeleteDirectSaleLabourBasketItemsRequest = z.infer<
  typeof DeleteDirectSaleLabourBasketItemsRequestSchema
>;

export const DeleteDirectSaleLabourBasketItemsResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleLabourBasketItemsResponse = z.infer<
  typeof DeleteDirectSaleLabourBasketItemsResponseSchema
>;

export const DeleteDirectSaleLabourBasketItemRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
});
export type DeleteDirectSaleLabourBasketItemRequest = z.infer<
  typeof DeleteDirectSaleLabourBasketItemRequestSchema
>;

export const DeleteDirectSaleLabourBasketItemResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleLabourBasketItemResponse = z.infer<
  typeof DeleteDirectSaleLabourBasketItemResponseSchema
>;
