import i18n from '@dms/i18n';
import {EntityHeaderFlag} from '@dms/shared';

import {buildArray} from 'shared';

import {useInterestData} from './useInterestData';

export function useInterestFlags(interestId: string) {
  const {interest} = useInterestData(interestId);

  return buildArray<EntityHeaderFlag>()
    .when(interest?.state === 'NEW', {
      colorScheme: 'blue',
      label: i18n.t('general.labels.new'),
      isSubtle: true,
    })
    .when(interest?.state === 'CLOSED', {
      colorScheme: 'green',
      label: i18n.t('general.labels.closed'),
      isSubtle: true,
    })
    .when(interest?.state === 'OPEN', {
      colorScheme: 'orange',
      label: i18n.t('general.labels.open'),
      isSubtle: true,
    })
    .when(interest?.state === 'UNSUCCESSFUL', {
      colorScheme: 'red',
      label: i18n.t('general.labels.unsuccessful'),
      isSubtle: true,
    })
    .when(interest?.type === 'SELLING', {
      colorScheme: 'orange',
      label: i18n.t('page.businessCase.labels.typeSelling'),
      isSubtle: true,
    })
    .when(interest?.type === 'BUYING', {
      colorScheme: 'green',
      label: i18n.t('page.businessCase.labels.typeBuying'),
      isSubtle: true,
    })
    .when(interest?.type === 'SWAP', {
      colorScheme: 'purple',
      label: i18n.t('page.businessCase.labels.typeSwap'),
      isSubtle: true,
    });
}
