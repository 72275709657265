import {differenceInCalendarDays} from 'date-fns';
import {Alert} from 'platform/components';
import {Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import {i18n} from '@dms/i18n';

import {Nullish, parseDate} from 'shared';

interface TechnicalInspectionAlertProps {
  date: string | Nullish;
}

const MOT_MAX_DAYS_ALERT = 30;

export function TechnicalInspectionAlert(props: TechnicalInspectionAlertProps) {
  const formatDate = useDateTimeFormatter();

  if (isNil(props.date)) {
    return null;
  }

  const parsedDate = parseDate(props.date);
  const daysToMot = differenceInCalendarDays(parsedDate!, new Date());

  return (
    <Show when={daysToMot <= MOT_MAX_DAYS_ALERT}>
      <Show when={daysToMot < 0}>
        <Alert
          title={i18n.t('entity.vehicle.labels.motExpired')}
          message={i18n.t('entity.vehicle.labels.motExpiredOn', {
            date: formatDate('dateShort', parsedDate!),
          })}
          variant="error"
        />
      </Show>
      <Show when={daysToMot >= 0}>
        <Alert
          title={i18n.t('entity.vehicle.labels.motExpire', {count: daysToMot, n: daysToMot})}
          variant="warning"
        />
      </Show>
    </Show>
  );
}
