import {openDeleteDialog, Preview, PreviewAction, Tooltip} from 'platform/components';
import {Box, Icon, Integer, Show, useElementSize, makeStorageUrl} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {CSSProperties} from 'react';

import {isNotNil, round} from 'ramda-adjunct';

import {i18n} from '@dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {useConditionContext} from '../hooks/useConditionContext';

export interface ImagePreviewProps extends TestIdProps {
  resizeUrl?: string;
  isImageRotationInProgress?: boolean;
  onDelete?: () => void;
  onOpenClick: () => void;
  ratio?: CSSProperties['aspectRatio'];
}

export function ImagePreview(props: ImagePreviewProps) {
  const theme = useTheme();
  const [ref, width, height] = useElementSize<HTMLDivElement>();

  const heightInSteps = round(round(height ?? 1) * theme.space.step) as Integer;
  const widthInSteps = round(round(width ?? 1) * theme.space.step) as Integer;

  const {isDisabledForUser} = useConditionContext();

  return (
    <Box
      ref={ref}
      width="100%"
      ratio={props.ratio}
      borderRadius="xSmall"
      position="relative"
      overflow="hidden"
    >
      <Show when={props.isImageRotationInProgress}>
        <Box position="absolute" top={0} left={0} zIndex="ROTATE_OVERLAY">
          <Tooltip label={i18n.t('entity.photo.labels.image.processing')}>
            <Icon value="action/watch_later" color="severity.informational" />
          </Tooltip>
        </Box>
      </Show>
      <Preview
        url={props.resizeUrl}
        makeUrl={makeStorageUrl}
        height={heightInSteps}
        width={widthInSteps}
        fit="cover"
        actions={buildArray<PreviewAction>()
          .add({
            icon: 'action/visibility',
            onClick: props.onOpenClick,
          })
          .when(!isDisabledForUser && isNotNil(props.onDelete), {
            icon: 'action/delete',
            onClick: () => {
              openDeleteDialog({
                onConfirm: props.onDelete!,
              });
            },
          })}
        data-testid={suffixTestId('imagePreview', props)}
      />
    </Box>
  );
}
