/* eslint-disable eag/match-named-export */
import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {vehiclesRoutes} from '@dms/routes';
import {permissions, ServiceVehicleFormMode, ServiceVehicleFormVariant} from '@dms/shared';

import {CloneVehicle} from './pages/CloneVehicle/CloneVehicle';
import {CreateVehicle} from './pages/CreateVehicle/CreateVehicle';
import {EditOrCloneServiceVehicle} from './pages/EditOrCloneServiceVehicle/EditOrCloneServiceVehicle';
import {EditVehicle} from './pages/EditVehicle/EditVehicle';
import {NewServiceVehicle} from './pages/NewServiceVehicle/NewServiceVehicle';
import {VehicleDetail} from './pages/VehicleDetail/VehicleDetail';
import {Vehicles} from './pages/Vehicles/Vehicles';
import {WarehouseMovements} from './pages/WarehouseMovements/WarehouseMovements';

export const VehiclesModule: Module = {
  id: moduleKeys.vehicles,
  requiredPermission: permissions.vehicleRead,
  requiredFeatureFlag: featureFlags.ACL_CORE,
  layoutProps: {
    title: 'page.vehicles.title',
    icon: 'maps/directions_car',
    iconV2: 'sidebar/directions_car',
  },
  routerProps: {
    path: vehiclesRoutes.home,
    element: <Vehicles />,
  },
  content: [
    {
      id: 'vehicles.home',
      routerProps: {
        path: vehiclesRoutes.home,
        element: <Vehicles />,
      },
      layoutProps: {
        title: 'page.vehicles.title',
        icon: 'sidebar/directions_car',
      },
    },
    {
      id: 'vehicles.inAdvertisement',
      routerProps: {
        path: vehiclesRoutes.inAdvertisement,
        element: <Vehicles />,
      },
    },
    {
      id: 'vehicles.vehicleWarehouses',
      routerProps: {
        path: vehiclesRoutes.vehicleWarehouses,
        element: <Vehicles />,
      },
    },
    {
      id: 'vehicles.vehicleOpenSearch',
      routerProps: {
        path: vehiclesRoutes.vehicleOpenSearch,
        element: <Vehicles />,
      },
    },
    {
      id: 'vehicles.saleVehicleOpenSearch',
      routerProps: {
        path: vehiclesRoutes.saleVehicleOpenSearch,
        element: <Vehicles />,
      },
    },
    {
      id: 'vehicles.serviceVehicleOpenSearch',
      routerProps: {
        path: vehiclesRoutes.serviceVehicleOpenSearch,
        element: <Vehicles />,
      },
    },
    {
      id: 'vehicles.warehouseMovements',
      routerProps: {
        path: vehiclesRoutes.warehouseMovements,
        element: <WarehouseMovements />,
      },
    },
    {
      id: 'vehicles.add',
      routerProps: {
        path: vehiclesRoutes.create,
        element: <CreateVehicle />,
      },
      layoutProps: {
        title: 'entity.vehicle.actions.createVehicle',
        isPartOfCreateMenuItem: true,
      },
    },
    {
      id: 'vehicles.add-service',
      routerProps: {
        path: vehiclesRoutes.createServiceVehicle,
        element: <NewServiceVehicle />,
      },
    },
    {
      id: 'vehicles.edit',
      routerProps: {
        path: vehiclesRoutes.edit,
        element: <EditVehicle />,
      },
    },
    {
      id: 'vehicles.edit-service',
      routerProps: {
        path: vehiclesRoutes.editServiceVehicle,
        element: (
          <EditOrCloneServiceVehicle
            mode={ServiceVehicleFormMode.EDIT}
            variant={ServiceVehicleFormVariant.EXTENDED}
          />
        ),
      },
    },
    {
      id: 'vehicles.clone',
      routerProps: {
        path: vehiclesRoutes.clone,
        element: <CloneVehicle />,
      },
    },
    {
      id: 'vehicles.clone-service',
      routerProps: {
        path: vehiclesRoutes.cloneServiceVehicle,
        element: (
          <EditOrCloneServiceVehicle
            mode={ServiceVehicleFormMode.CLONE}
            variant={ServiceVehicleFormVariant.EXTENDED}
          />
        ),
      },
    },
    {
      id: 'vehicles.editDetailTab',
      routerProps: {
        path: vehiclesRoutes.editDetailTab,
        element: <EditVehicle />,
      },
    },

    {
      id: 'vehicles.details',
      routerProps: {
        path: vehiclesRoutes.detail,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.vehicleData',

      routerProps: {
        path: vehiclesRoutes.vehicleData,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.features',
      routerProps: {
        path: vehiclesRoutes.features,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.serviceIntervals',
      routerProps: {
        path: vehiclesRoutes.serviceIntervals,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.insights',
      routerProps: {
        path: vehiclesRoutes.insights,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.conditionsInspections',
      routerProps: {
        path: vehiclesRoutes.conditionsInspections,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.inspectionDetail',
      routerProps: {
        path: vehiclesRoutes.inspectionDetail,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.inspectionDetail',
      routerProps: {
        path: vehiclesRoutes.inspectionDetailTab,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.conditions',
      routerProps: {
        path: vehiclesRoutes.conditions,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.conditionDetail',
      routerProps: {
        path: vehiclesRoutes.conditionDetail,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.conditionDetailTab',
      routerProps: {
        path: vehiclesRoutes.conditionDetailTab,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.photos',
      routerProps: {
        path: vehiclesRoutes.photos,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.photos',
      routerProps: {
        path: vehiclesRoutes.photoByInspectionId,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.publishing',
      routerProps: {
        path: vehiclesRoutes.publishing,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.businessCases',
      routerProps: {
        path: vehiclesRoutes.businessCases,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}.complaints`,
      routerProps: {
        path: vehiclesRoutes.complaints,
        element: <VehicleDetail />,
      },
      requiredPermission: permissions.complaintRead,
    },
    {
      id: `${moduleKeys.vehicles}-finances`,
      routerProps: {
        path: vehiclesRoutes.finances,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-finances-prices`,
      routerProps: {
        path: vehiclesRoutes.financesPrices,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-finances-earnings-costs`,
      routerProps: {
        path: vehiclesRoutes.financesEarningsCosts,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-interests`,
      routerProps: {
        path: vehiclesRoutes.interests,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-customers`,
      routerProps: {
        path: vehiclesRoutes.customers,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-documents`,
      routerProps: {
        path: vehiclesRoutes.documents,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-reservations`,
      routerProps: {
        path: vehiclesRoutes.reservations,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-test-drives`,
      routerProps: {
        path: vehiclesRoutes.testDrives,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-postponed-jobs`,
      routerProps: {
        path: vehiclesRoutes.postponedJobs,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-ownership`,
      routerProps: {
        path: vehiclesRoutes.ownership,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-history-of-repairs`,
      routerProps: {
        path: vehiclesRoutes.historyOfRepairs,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-warehouse-info`,
      routerProps: {
        path: vehiclesRoutes.vehicleWarehouseInfo,
        element: <VehicleDetail />,
      },
    },
    {
      id: 'vehicles.threeHundredAndSixtyPhotos',
      routerProps: {
        path: vehiclesRoutes.threeHundredAndSixtyPhotos,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-activity-logs`,
      routerProps: {
        path: vehiclesRoutes.activityLog,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-serviceCampaigns`,
      routerProps: {
        path: vehiclesRoutes.serviceCampaigns,
        element: <VehicleDetail />,
      },
    },
    {
      id: `${moduleKeys.vehicles}-tires`,
      routerProps: {
        path: vehiclesRoutes.tires,
        element: <VehicleDetail />,
      },
    },
  ],
};
