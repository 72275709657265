/**
 * @deprecated - use platform instead
 */
import {format as dateFnsFormat, getYear, isValid, parse} from 'date-fns';
import {
  Checkbox,
  CheckboxProps,
  Chips,
  Choice,
  ChoiceProps,
  CreatableChoice,
  CreatableChoiceProps,
  CreatableMultiChoice,
  CreatableMultiChoiceProps,
  DatePicker,
  DatePickerProps,
  MultiChoice,
  MultiChoiceProps,
  NumberInput,
  NumberInputProps,
  NumberInputWithOptions,
  OptionTypeBase,
  PasswordInput,
  PhoneNumber,
  Switch,
  SwitchProps,
  Textarea,
  TextareaProps,
  TextInput,
} from 'platform/components';
import {AnySchema} from 'yup';

import {ComponentType, ReactElement, ReactNode, useContext, useMemo} from 'react';
import {
  FieldMetaState,
  FieldRenderProps,
  Field as FinalFormField,
  UseFieldConfig,
} from 'react-final-form';
import {useSelector} from 'react-redux';
import {GroupBase, OptionsOrGroups} from 'react-select';

import {inc, isEmpty, isNil, range} from 'ramda';
import {isNilOrEmpty, isNotNil, isNumber, isString} from 'ramda-adjunct';

import {cachedApi, selectTenant, VatTypeEnum} from '@dms/api';
import i18n from '@dms/i18n';

import {getApiDateString, Nullish, parseDate, TestIdProps} from 'shared';

import {PossibleObject} from '../../../types/PossibleObject';
import {PhoneField} from '../../PhoneField/PhoneField';
import {PriceField} from '../../PriceField/PriceField';
import {PriceFieldProps} from '../../PriceField/PriceFieldProps';
import {VatSelect} from '../../VatTypeField/VatTypeField';
import {FormContext} from '../FormContext';
import {FieldName} from '../types/FieldName';
import {FieldType} from '../types/FieldType';
import {getFieldDefaultValue} from '../utils/getFieldDefaultValue';
import {getFieldType} from '../utils/getFieldType';
import {getIsRequired} from '../utils/getIsRequired';
import {removeAllCommasExceptFirst} from '../utils/removeAllCommasExceptFirst';
import {FormFieldWrapper} from './FormFieldWrapper';

/**
 * @deprecated - use platform instead
 */
export type FormFieldProps<
  T extends PossibleObject,
  K extends PossibleObject,
  M extends boolean,
> = {
  /** Type of input element */
  as?: FieldType;
  /** Options when using dropdown, chips, etc. */
  options?: Array<any>;
  /** Default field value - use only in cases default values for whole form are not an option */
  defaultValue?: any;
  /** Should return string value to display as select value/option */
  getOptionLabel?: (obj: any) => string;
  /** Should return string value to store in state */
  getOptionValue?: (obj: any) => any;
  /** Use textarea for text input */
  multiline?: boolean;
  /** Use multi select */
  multiple?: boolean;
  /** Require this field */
  required?: boolean;
  /** Disable the field */
  disabled?: boolean;
  /** For price field - wether this input is FROM_PRICE_WITH_VAT  */
  withVat?: boolean;
  /** For price field - wether this input is FROM_PRICE_WITH_VAT  */
  vatType?: VatTypeEnum;
  /** For price field - country code for computing a second price  */
  // NOTE: Made as standalone field (not as part of priceProps object)
  // in the sake of possible performance optimization with useMemo hook
  countryCode?: PriceFieldProps['countryCode'];
  /** A function that takes the value from the input and name of the field and converts the value into the value you want stored as this field's value in the form */
  parse?: UseFieldConfig<any>['parse'];
  /** A function that takes the value from the form values and the name of the field and formats the value to give to the input */
  format?: UseFieldConfig<any>['format'];
  /** Max number of characters in text inputs */
  maxLength?: number;
  /** Show counter for text inputs */
  isCounterVisible?: boolean;
  /** Regex pattern for text inputs */
  pattern?: string | RegExp;
  /** Min number value */
  min?: number;
  /** Max number value */
  max?: number;
  /** Max number value */
  limit?: number;
  /** Format on blur */
  formatOnBlur?: boolean;
  /** Enable chips deselect */
  enabledDeselect?: boolean;
  /** Input label */
  label?: string | Nullish;
  /** Input placeholder */
  placeholder?: string | ReactElement;
  name: FieldName<T, K>;
  suffix?: string | Nullish;
  /** Custom component to render field */
  component?: ComponentType<FieldRenderProps<M extends true ? any[] : any>>;
  dateProps?: Partial<DatePickerProps>;
  numberProps?: Partial<NumberInputProps>;
  checkboxProps?: Partial<CheckboxProps>;
  switchProps?: Partial<SwitchProps>;
  textareaProps?: Partial<TextareaProps>;
  selectProps?: Partial<
    Omit<ChoiceProps<any>, 'onChange'> & {
      creatable?: boolean;
      creatableOption?: boolean;
      handleCreate?: (value?: string) => void;
      formatCreateLabel?: (input: string) => ReactNode;
    } & {isValidNewOption?: (inputValue: string) => boolean}
  >;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  isLoading?: boolean;
  helperText?: string;
  isMinYearCar?: boolean;
} & TestIdProps;

/**
 * Field component used to render inputs
 * It provides interface for defining fields of only existing properties of FormValues object
 * @deprecated - use platform FormField instead
 */
export function FormField<
  /** Type of object (passed down by Form component) to take names from */
  T extends PossibleObject,
  /** Type of parent (passed down by Form component) the array is in */
  K extends PossibleObject,
  M extends boolean,
>(props: FormFieldProps<T, K, M>) {
  // For country select
  const {data: {country: tenantCountryCOde} = {}} = useSelector(selectTenant);
  const selectPrioritizedCountries = useMemo(
    () => cachedApi.endpoints.getPrioritizedCountries.select({countryCode: tenantCountryCOde}),
    [tenantCountryCOde]
  );
  const {data: countries} = useSelector(selectPrioritizedCountries);

  // In field array, we combine the name of the field itself with provided indexed path
  // eslint-disable-next-line no-restricted-syntax
  const _name = props.name as unknown as string | string[];

  // We assume that option has label property by default
  const getDefaultOptionLabel = (obj: any) => {
    if (typeof obj === 'string') {
      return obj;
    }
    return (obj as OptionTypeBase<number | string | Nullish>)?.label?.toString() || '';
  };

  // We assume that option has value property by default
  const getDefaultOptionValue = (obj: any) => {
    if (typeof obj === 'string') {
      return obj;
    }
    return (obj as OptionTypeBase<number | string | Nullish>)?.value?.toString() || '';
  };

  const parseValue = (value: unknown): any => {
    if (as === 'date') {
      return getApiDateString(value as Date) as any;
    } else if (as === 'year') {
      return dateFnsFormat(value as Date, 'yyyy') as any;
    } else if (as === 'number' || as === 'integer') {
      if (as === 'integer') {
        const parsedValue = parseInt((`${value}` as string)?.replace(/\D/g, ''), 10);

        if (isNaN(parsedValue)) {
          return getDefaultParseValue('') as any;
        } else {
          return parsedValue as any;
        }
      } else {
        const currentValue = isNil(value) ? '' : `${value}`;
        const parsedValue = currentValue.replace(',', '.').replace(/[^0-9|.|-]/g, '');
        const filteredCharacters = removeAllCommasExceptFirst(parsedValue);

        if (isNilOrEmpty(filteredCharacters)) {
          return getDefaultParseValue(filteredCharacters) as any;
        }

        if (isNaN(Number(filteredCharacters))) {
          return '';
        }

        return filteredCharacters.toString() as any;
      }
    } else {
      if (pattern) {
        return (value as string)?.replace(pattern, '') as any;
      }
      return value as any;
    }
  };

  const getDefaultParseValue = (value: unknown): any =>
    isNil(value) || value === ''
      ? (getFieldDefaultValue(_name, schema) as any)
      : (parseValue(value) as any);

  const getDefaultFormatValue = (value?: any): unknown => {
    if (as === 'date') {
      if (isNil(value) || isEmpty(value)) {
        return null;
      }
      return parseDate(value as string);
    } else if (as === 'year') {
      return parseApiYear(value as string);
    } else if (as === 'integer') {
      return value ? parseInt(value as string) : getDefaultParseValue(value);
    } else if (as === 'number') {
      return value
        ? Number(value as string).toFixed(props.numberProps?.decimalPlaces ?? 2)
        : getDefaultParseValue(value);
    } else if (as === 'text') {
      return value || '';
    } else {
      return value;
    }
  };

  const {
    as: propAs,
    label,
    placeholder,
    name,
    options,
    multiple,
    getOptionLabel = getDefaultOptionLabel,
    getOptionValue = getDefaultOptionValue,
    parse = getDefaultParseValue,
    format = getDefaultFormatValue,
    formatOnBlur = true,
    multiline,
    suffix,
    disabled,
    component,
    dateProps,
    numberProps,
    checkboxProps,
    switchProps,
    selectProps,
    textareaProps,
    defaultValue,
    onChange,
    onBlur,
    isLoading,
    maxLength,
    isCounterVisible,
    min,
    max,
    pattern,
    limit,
    enabledDeselect,
    withVat,
    vatType,
    countryCode,
    helperText,
    isMinYearCar,
    ...rest
  } = props;
  const fullName = typeof _name === 'string' ? _name : _name.join('.');

  const {schema, submitCount, validateAfterSubmit, formId} = useContext(FormContext);

  const property = (schema?.fields[name as string] as AnySchema) || undefined;
  const as: FieldType = propAs || getFieldType(property);
  const required = getIsRequired(_name, schema);

  const testId = props['data-testid'] ?? [formId, fullName.replace('.', '-')].join('-');

  const getIsValid = (meta: FieldMetaState<unknown>): boolean => {
    if (
      (meta.error || meta.submitError) &&
      (!validateAfterSubmit || submitCount > 0) &&
      (meta.touched || submitCount > 0)
    ) {
      return false;
    }

    return true;
  };

  const getErrorMessage = (meta: FieldMetaState<unknown>): string | undefined => {
    if (
      (meta.error || meta.submitError) &&
      (!validateAfterSubmit || submitCount > 0) &&
      (meta.touched || submitCount > 0)
    ) {
      return meta.error || meta.submitError;
    }

    return undefined;
  };

  const commonFieldProps = {
    maxLength,
    isRequired: required || rest.required,
    isDisabled: disabled,
    'data-testid': testId,
  };

  const commonInputProps = {
    'data-testid': testId,
  };

  if (component !== undefined) {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={defaultValue}>
        {component as any}
      </FinalFormField>
    );
  }

  if (as === 'hidden') {
    return (
      <FinalFormField<any>
        name={fullName}
        parse={parse}
        format={format}
        defaultValue={defaultValue}
      >
        {({input}) => (
          <input {...input} {...commonInputProps} value={input.value as string} type="hidden" />
        )}
      </FinalFormField>
    );
  } else if (as === 'text' || as === 'nullableText') {
    return (
      <FinalFormField<any>
        name={fullName}
        parse={parse}
        format={format}
        defaultValue={defaultValue}
        formatOnBlur={formatOnBlur}
      >
        {({input, meta}) => {
          if (multiline) {
            return (
              <FormFieldWrapper data-testid={testId}>
                <Textarea
                  {...input}
                  {...commonInputProps}
                  {...commonFieldProps}
                  isInvalid={!getIsValid(meta)}
                  label={label}
                  placeholder={isString(placeholder) ? placeholder : undefined}
                  isDisabled={disabled}
                  maxLength={maxLength}
                  value={input.value as string}
                  {...{
                    name: fullName,
                  }}
                  {...rest}
                  minRows={3}
                  {...textareaProps}
                  onBlur={(value) => {
                    onBlur?.();
                    input?.onBlur?.(value);
                  }}
                  errorMessage={getErrorMessage(meta)}
                />
              </FormFieldWrapper>
            );
          }
          return (
            <FormFieldWrapper data-testid={testId}>
              <TextInput
                {...input}
                {...commonInputProps}
                {...commonFieldProps}
                isInvalid={!getIsValid(meta)}
                label={label}
                isDisabled={disabled}
                maxLength={maxLength}
                isCounterVisible={isCounterVisible}
                value={(input.value as string) || ''}
                onChange={(value) => {
                  onChange?.(value);
                  input?.onChange?.(value);
                }}
                onBlur={(value) => {
                  onBlur?.();
                  input?.onBlur?.(value);
                }}
                suffix={suffix}
                errorMessage={getErrorMessage(meta)}
              />
            </FormFieldWrapper>
          );
        }}
      </FinalFormField>
    );
  } else if (as === 'password') {
    return (
      <FinalFormField<any>
        name={fullName}
        parse={parse}
        format={format}
        defaultValue={defaultValue}
      >
        {({input, meta}) => (
          <FormFieldWrapper data-testid={testId}>
            <PasswordInput
              {...input}
              {...commonInputProps}
              {...commonFieldProps}
              isInvalid={!getIsValid(meta)}
              label={label}
              value={input.value as string}
              errorMessage={getErrorMessage(meta)}
            />
          </FormFieldWrapper>
        )}
      </FinalFormField>
    );
  } else if (as === 'number' || as === 'integer') {
    return (
      <FinalFormField<any>
        name={fullName}
        parse={parse}
        format={format}
        defaultValue={defaultValue}
        formatOnBlur={formatOnBlur}
      >
        {({input, meta}) => (
          <FormFieldWrapper data-testid={testId}>
            {numberProps?.isStepperVisible ? (
              <NumberInput
                {...input}
                {...commonInputProps}
                {...commonFieldProps}
                isInvalid={!getIsValid(meta)}
                label={label}
                value={input.value as number}
                onChange={(value) => {
                  onChange?.(value);
                  input.onChange(value);
                }}
                onBlur={(value) => {
                  onBlur?.();
                  input.onBlur(value);
                }}
                decimalPlaces={0}
                step={1}
                minStepperValue={min}
                maxStepperValue={max}
                {...numberProps}
              />
            ) : (
              <TextInput
                {...input}
                {...commonInputProps}
                {...commonFieldProps}
                label={label}
                value={!isNaN(input.value as number) ? `${input.value}` : ''}
                onChange={(value) => {
                  // If min value is defined and value is less then min return min
                  if (min !== undefined && !isNil(value) && Number(value) < min) {
                    onChange?.(min.toString() as any);
                    input.onChange(min.toString() as any);
                  }
                  // If max value is defined and value is bigger then max return max
                  else if (max !== undefined && !isNil(value) && Number(value) > max) {
                    onChange?.(max.toString() as any);
                    input.onChange(max.toString() as any);
                  } else {
                    onChange?.(value);
                    input.onChange(value);
                  }
                }}
                onBlur={(value) => {
                  onBlur?.();
                  input.onBlur(value);
                }}
                suffix={suffix}
                errorMessage={getErrorMessage(meta)}
              />
            )}
          </FormFieldWrapper>
        )}
      </FinalFormField>
    );
  } else if (as === 'checkbox') {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={defaultValue}>
        {({input, meta}) => (
          <FormFieldWrapper data-testid={testId}>
            <Checkbox
              {...input}
              {...commonInputProps}
              {...commonFieldProps}
              isInvalid={!getIsValid(meta)}
              label={label}
              value={!!input.value}
              onChange={() => {
                input.onChange(!input.value);
                onChange?.(!input.value);
              }}
              name={fullName}
              {...checkboxProps}
              errorMessage={getErrorMessage(meta)}
            />
          </FormFieldWrapper>
        )}
      </FinalFormField>
    );
  } else if (as === 'switch') {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={defaultValue}>
        {({input, meta}) => (
          <FormFieldWrapper data-testid={testId}>
            <Switch
              {...input}
              {...commonInputProps}
              {...commonFieldProps}
              isInvalid={!getIsValid(meta)}
              label={label}
              value={!!input.value}
              onChange={() => input.onChange(!input.value)}
              name={fullName}
              {...switchProps}
              errorMessage={getErrorMessage(meta)}
            />
          </FormFieldWrapper>
        )}
      </FinalFormField>
    );
  } else if (as === 'chips') {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={defaultValue}>
        {({input, meta}) => {
          if (!options) {
            return null;
          }

          return (
            <FormFieldWrapper data-testid={testId}>
              <Chips
                {...commonInputProps}
                {...commonFieldProps}
                isInvalid={!getIsValid(meta)}
                label={label}
                limit={
                  input.value &&
                  isNotNil(limit) &&
                  options.findIndex((obj) => getOptionValue(obj) === input.value) > limit
                    ? undefined
                    : isNotNil(limit) && limit !== 0 && limit < options.length
                      ? limit
                      : undefined
                }
                options={options.map((option) => ({
                  isDisabled: disabled,
                  value: getOptionValue(option) as string,
                  label: getOptionLabel(option) as string,
                }))}
                value={(Array.isArray(input.value) ? input.value : [input.value]) as string[]}
                isDeselectable={enabledDeselect}
                isMultiple={multiple}
                onChange={(value) => {
                  const _value = multiple ? value : value?.[0];

                  onChange?.(_value);
                  input.onChange(_value);
                }}
                errorMessage={getErrorMessage(meta)}
              />
            </FormFieldWrapper>
          );
        }}
      </FinalFormField>
    );
  } else if (as === 'phone') {
    return (
      <PhoneField
        {...commonInputProps}
        name={fullName}
        label={label}
        commonFieldProps={commonFieldProps}
        parse={parse as UseFieldConfig<PhoneNumber>['parse']}
        getErrorMessage={getErrorMessage}
        getIsValid={getIsValid}
        defaultValue={defaultValue}
      />
    );
  } else if (as === 'price') {
    return (
      <PriceField
        {...commonInputProps}
        name={fullName}
        label={label}
        commonFieldProps={commonFieldProps}
        getErrorMessage={getErrorMessage}
        getIsValid={getIsValid}
        onChange={onChange}
        withVat={withVat}
        vatType={vatType}
        countryCode={countryCode}
        helperText={helperText}
      />
    );
  } else if (as === 'vatType') {
    return (
      <VatSelect
        {...commonInputProps}
        name={fullName}
        label={label}
        commonFieldProps={commonFieldProps}
        getErrorMessage={getErrorMessage}
        getIsValid={getIsValid}
      />
    );
  } else if (as === 'date') {
    return (
      <FinalFormField<any>
        name={fullName}
        parse={parse}
        format={format}
        defaultValue={defaultValue}
      >
        {({input, meta}) => (
          <FormFieldWrapper data-testid={testId}>
            <DatePicker
              {...commonInputProps}
              {...commonFieldProps}
              isInvalid={!getIsValid(meta)}
              label={label}
              onChange={input.onChange}
              value={input.value as Date}
              isRelativeDatesHidden
              isDisabled={disabled}
              {...dateProps}
              {...rest}
              errorMessage={getErrorMessage(meta)}
            />
          </FormFieldWrapper>
        )}
      </FinalFormField>
    );
  } else if (as === 'year') {
    const minYear = isMinYearCar ? new Date().getFullYear() - 15 : 1900;
    const maxYear = getYear(dateProps?.maxDate ?? new Date());
    const options = range(minYear, inc(maxYear)).map((year) => ({
      label: year.toString(),
      value: year,
    }));

    return (
      <FinalFormField<any>
        name={fullName}
        parse={parse}
        format={format}
        defaultValue={defaultValue}
      >
        {({input, meta}) => (
          <FormFieldWrapper data-testid={testId}>
            <Choice
              {...input}
              {...commonInputProps}
              {...commonFieldProps}
              options={options}
              isInvalid={!getIsValid(meta)}
              label={label}
              onChange={(value) => {
                if (multiple) {
                  return;
                }
                const date =
                  isNotNil(value) && isNumber(value) ? new Date(value, FIRST_MONTH) : null;

                onChange?.(date);
                input.onChange(date);
              }}
              value={isNotNil(input.value) ? getYear(input.value) : null}
              isDisabled={disabled}
              errorMessage={getErrorMessage(meta)}
            />
          </FormFieldWrapper>
        )}
      </FinalFormField>
    );
  } else if (as === 'select') {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={defaultValue}>
        {({input, meta}) => {
          const {onFocus, ...inputProps} = input;
          const isGrouped = options?.some((item) =>
            Object.prototype.hasOwnProperty.call(item, 'options')
          );

          const value = input.value as string | string[] | number;

          let opts: OptionsOrGroups<
            OptionTypeBase<number | string | Nullish> & {className?: string},
            GroupBase<OptionTypeBase<number | string | Nullish> & {className?: string}>
          > = options?.map((option) => ({
            value: getOptionValue(option),
            label: getOptionLabel(option),
            fieldLabel: option?.fieldLabel,
          })) || [];

          if (isGrouped) {
            opts = (options as GroupBase<OptionTypeBase<number | string | Nullish>>[])?.map(
              (parent) => ({
                label: parent?.label,
                options: parent?.options?.map((x) => ({
                  // eslint-disable-next-line no-restricted-syntax
                  value: getOptionValue(x as unknown as any),
                  // eslint-disable-next-line no-restricted-syntax
                  label: getOptionLabel(x as unknown as any),
                  fieldLabel: x?.fieldLabel,
                })),
              })
            );
          }

          if (selectProps?.creatableOption) {
            opts = [
              ...opts,
              {
                value: 'createNew',
                label: i18n.t('general.labels.createNew'),
                className: 'option-create-new',
              },
            ];
          }

          return (
            <FormFieldWrapper data-testid={testId}>
              {selectProps?.creatable ? (
                <>
                  {multiple ? (
                    <CreatableMultiChoice<number | string | Nullish>
                      {...inputProps}
                      {...commonInputProps}
                      {...commonFieldProps}
                      isInvalid={!getIsValid(meta)}
                      // eslint-disable-next-line no-restricted-syntax
                      {...((selectProps as unknown as Partial<
                        CreatableMultiChoiceProps<number | string | Nullish>
                      >) || {})}
                      label={label}
                      name={fullName}
                      options={opts}
                      onChange={(value) => {
                        onChange?.(value);
                        input.onChange(value);
                      }}
                      onCreateOption={(value) => selectProps?.handleCreate?.(value)}
                      placeholder={(placeholder as string) || i18n.t('general.labels.select')}
                      noOptionsMessage={
                        selectProps?.noOptionsMessage || (() => i18n.t('general.labels.noOptions'))
                      }
                      formatCreateLabel={
                        selectProps?.formatCreateLabel ||
                        ((inputValue: string) =>
                          `${i18n.t('general.actions.create')} ${inputValue}`)
                      }
                      isLoading={isLoading}
                      value={value as string[]}
                      errorMessage={getErrorMessage(meta)}
                    />
                  ) : (
                    <CreatableChoice<number | string | Nullish>
                      {...inputProps}
                      {...commonInputProps}
                      {...commonFieldProps}
                      isInvalid={!getIsValid(meta)}
                      // eslint-disable-next-line no-restricted-syntax
                      {...((selectProps as unknown as Partial<
                        CreatableChoiceProps<number | string | Nullish>
                      >) || {})}
                      label={label}
                      name={fullName}
                      options={opts}
                      onChange={(value) => {
                        onChange?.(value);
                        input.onChange(value);
                      }}
                      onCreateOption={selectProps?.handleCreate}
                      placeholder={(placeholder as string) || i18n.t('general.labels.select')}
                      noOptionsMessage={
                        selectProps?.noOptionsMessage || (() => i18n.t('general.labels.noOptions'))
                      }
                      formatCreateLabel={
                        selectProps?.formatCreateLabel ||
                        ((inputValue: string) =>
                          `${i18n.t('general.actions.create')} ${inputValue}`)
                      }
                      isLoading={isLoading}
                      value={value as string | number}
                      errorMessage={getErrorMessage(meta)}
                    />
                  )}
                </>
              ) : (
                <>
                  {multiple ? (
                    <MultiChoice
                      {...inputProps}
                      {...commonInputProps}
                      {...commonFieldProps}
                      // eslint-disable-next-line no-restricted-syntax
                      {...((selectProps as unknown as Partial<MultiChoiceProps<number | string>>) ||
                        {})}
                      label={label}
                      name={fullName}
                      options={opts}
                      onChange={(value) => {
                        if (
                          (value as string[] | number[]).findIndex((val) => val === 'createNew') !==
                          -1
                        ) {
                          selectProps?.handleCreate?.();
                        } else {
                          onChange?.(value);
                          input.onChange?.(value);
                        }
                      }}
                      placeholder={(placeholder as string) || i18n.t('general.labels.select')}
                      noOptionsMessage={
                        selectProps?.noOptionsMessage || (() => i18n.t('general.labels.noOptions'))
                      }
                      isLoading={isLoading}
                      value={value as string[]}
                      errorMessage={getErrorMessage(meta)}
                      isNotClearable={selectProps?.isNotClearable}
                    />
                  ) : (
                    <Choice
                      {...inputProps}
                      {...commonInputProps}
                      {...commonFieldProps}
                      // eslint-disable-next-line no-restricted-syntax
                      {...((selectProps as unknown as Partial<ChoiceProps<number | string>>) || {})}
                      label={label}
                      name={fullName}
                      options={opts}
                      onChange={(value) => {
                        if (value === 'createNew') {
                          selectProps?.handleCreate?.();
                        } else {
                          onChange?.(value);
                          input.onChange(value);
                        }
                      }}
                      placeholder={(placeholder as string) || i18n.t('general.labels.select')}
                      noOptionsMessage={
                        selectProps?.noOptionsMessage || (() => i18n.t('general.labels.noOptions'))
                      }
                      isLoading={isLoading}
                      value={value as string | number}
                      errorMessage={getErrorMessage(meta)}
                      isNotClearable={selectProps?.isNotClearable}
                    />
                  )}
                </>
              )}
            </FormFieldWrapper>
          );
        }}
      </FinalFormField>
    );
  } else if (as === 'country-select') {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={defaultValue}>
        {({input, meta}) => {
          const value = input.value as string | string[] | number;

          const opts =
            countries?.map((option) => ({
              value: option.code,
              label: option.name,
            })) || [];

          return (
            <FormFieldWrapper data-testid={testId}>
              <Choice
                {...input}
                {...commonInputProps}
                {...commonFieldProps}
                // eslint-disable-next-line no-restricted-syntax
                {...((selectProps as unknown as Partial<ChoiceProps<string | number>>) || {})}
                label={label}
                name={fullName}
                options={opts}
                onChange={(value) => {
                  onChange?.(value);
                  input.onChange(value);
                }}
                placeholder={placeholder as string}
                noOptionsMessage={
                  selectProps?.noOptionsMessage || (() => i18n.t('general.labels.noOptions'))
                }
                value={value as string | number}
                errorMessage={getErrorMessage(meta)}
              />
            </FormFieldWrapper>
          );
        }}
      </FinalFormField>
    );
  } else if (as === 'numberInputWithOptions') {
    return (
      <FinalFormField<any> name={fullName} parse={parse} defaultValue={props.defaultValue}>
        {({input}) => {
          const value = isNotNil(input.value) ? parseInt(input.value) : null;

          return (
            <FormFieldWrapper data-testid={testId}>
              <NumberInputWithOptions
                name={fullName}
                options={props.options ?? []}
                onChange={(value) => {
                  onChange?.(value);
                  input.onChange(value);
                }}
                value={value}
                placeholder={isString(props.placeholder) ? props.placeholder : undefined}
                label={props.label}
                isClearable
              />
            </FormFieldWrapper>
          );
        }}
      </FinalFormField>
    );
  }

  return null;
}

const parseApiYear = (value?: string | null): Date | undefined => {
  if (!value) {
    return undefined;
  }
  const parsed = parse(value, 'yyyy', new Date());
  if (isValid(parsed)) {
    return parsed;
  } else {
    return undefined;
  }
};

const FIRST_MONTH = 0;
