import {Parameter} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import i18n from '@dms/i18n';

import {EMPTY_PLACEHOLDER, Nullish, buildArray, parseDate} from 'shared';

interface HeaderData {
  serviceCaseNumber: string | Nullish;
  publicId: string | Nullish;
  vin: string | Nullish;
  registrationPlate: string | Nullish;
  firstRegistrationOn: string | Nullish;
  warrantyDate: string | Nullish;
  isVehicleDetail: boolean | Nullish;
  isNotInService?: boolean | Nullish;
  mileage?: string | Nullish;
  fuelTank?: string | Nullish;
}

export function useGetVehicleHeaderV2(data: HeaderData): Parameter[] {
  const formatDateTime = useDateTimeFormatter();
  const formatDate = (dateValue: string) => formatDateTime('dateShort', parseDate(dateValue));

  return buildArray<Parameter>()
    .when(data.isVehicleDetail, {
      title: data?.publicId || EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.vehicle.labels.publicId'),
    })
    .whenNot(data.isVehicleDetail, {
      title: data?.serviceCaseNumber || EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.serviceCase.labels.number'),
    })
    .add({
      title: data?.vin || EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.vehicle.labels.vin'),
    })
    .add({
      title: data?.registrationPlate || EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.vehicle.labels.licensePlate'),
    })
    .add({
      title: data?.firstRegistrationOn ? formatDate(data?.firstRegistrationOn) : EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.vehicle.labels.firstRegistration'),
    })
    .add({
      title: data?.warrantyDate ? formatDate(data?.warrantyDate) : EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.vehicle.labels.warrantyUntil'),
    })
    .when(data.isNotInService, {
      title: i18n.t('entity.vehicle.labels.isNotInService'),
      tooltip: i18n.t('entity.vehicle.labels.isNotInServiceTooltip'),
    })
    .whenNot(data.isVehicleDetail, {
      title: data.mileage || EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.vehicle.labels.actualMileage'),
    })
    .whenNot(data.isVehicleDetail, {
      title: data.fuelTank || EMPTY_PLACEHOLDER,
      tooltip: i18n.t('entity.workshop.labels.fuelTank'),
    });
}
