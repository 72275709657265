import {createContext} from 'react';

import {DataGridRef} from 'features/datagrid';

type VehicleWarehouseContextType = {
  dataGridRef: React.MutableRefObject<DataGridRef | null>;
  refreshDataGrid: () => void;
};

export const VehicleWarehouseContext = createContext<VehicleWarehouseContextType | null>(null);
