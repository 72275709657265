import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {JbrSettingsCredentialsForm} from './components/JbrSettingsCredentialsForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.integrations.labels.jbr'),
    href: settingsRoutes.jbr,
  },
];

export function JbrSettingsCredentials() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.jbrAuth'), breadcrumbs}}
      data-testid="jbr-settings-credentials"
    >
      <JbrSettingsCredentialsForm />
    </SettingsTemplate>
  );
}
