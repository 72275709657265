export const workshopSearchParams = {
  serviceDetail: {
    overview: 'overview',
    orders: 'orders',
    ownership: 'ownership',
    vehicle: 'vehicle',
    checkout: 'checkout',
    photos: 'photos',
    documents: 'documents',
    checkinHandover: 'checkinHandover',
    postponed: 'postponed',
    serviceIntervals: 'serviceIntervals',
    historyOfRepairs: 'historyOfRepairs',
    tires: 'tires',
    serviceCampaigns: 'serviceCampaigns',
    activityLogs: 'activityLogs',
  },
  create: {
    customerVehicle: 'customerVehicle',
    serviceJobs: 'serviceJobs',
    schedule: 'schedule',
  },
};
