import {Pattern} from '../types/Pattern';
import {getRawStartingNumber} from './getRawStartingNumber';

export const getFormattedStartingNumber = (
  pattern: Omit<Pattern, 'from' | 'startingNumber'> & {
    startingNumber: string | number;
  },
  shouldUseRawStartingNumber?: boolean
) => {
  const prefix = pattern.prefix?.trim() || '';
  const padLength = pattern.length - prefix.length;
  return (
    prefix +
    `${shouldUseRawStartingNumber ? pattern.startingNumber : getRawStartingNumber(pattern)}`.padStart(
      padLength,
      '0'
    )
  );
};
