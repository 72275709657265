import {Separator} from 'platform/components';
import {Heading, Hide, VStack} from 'platform/foundation';

import {filter, groupBy, map, pipe} from 'ramda';

import {MakeFieldDefinitionResponseBody, useGetFieldDefinitionListQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {isEntityFeatureFlagEnabled} from '@dms/shared';

import {isLastIndexInArray} from 'shared';

import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ResourceDefinitionList} from './components/ResourceDefinitionList';
import {CUSTOM_FIELDS_RESOURCES, CustomFieldsResource} from './constants/customFieldsResources';

export function CustomFieldsDefinitions() {
  const {
    data: definitionList,
    isLoading,
    isError,
  } = useGetFieldDefinitionListQuery({type: 'tenant'});

  const definitionListByResourceId = groupBy(
    (definition: MakeFieldDefinitionResponseBody) => definition.resource.id,
    definitionList ?? []
  );

  const customFieldsDefinitions = pipe(
    filter((item: CustomFieldsResource) => isEntityFeatureFlagEnabled(item.resourceId)),
    map((item) => ({
      ...item,
      definitions: definitionListByResourceId[item.resourceId] ?? [],
    }))
  )(CUSTOM_FIELDS_RESOURCES);

  return (
    <SettingsTemplate
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.customFields('template')}
      header={{
        title: i18n.t('page.generalSettings.labels.customFields'),
        subtitle: i18n.t('page.generalSettings.labels.customFieldsDescriptions'),
      }}
    >
      <SettingsSection>
        {customFieldsDefinitions.map(({resourceId, definitions, label}, index) => (
          <>
            <VStack spacing={2}>
              <Heading size={4}>{label}</Heading>

              <ResourceDefinitionList
                definitions={definitions}
                resourceId={resourceId}
                key={resourceId}
                data-testid={testIds.settings.customFields(resourceId)}
              />
            </VStack>

            <Hide when={isLastIndexInArray(customFieldsDefinitions, index)}>
              <Separator />
            </Hide>
          </>
        ))}
      </SettingsSection>
    </SettingsTemplate>
  );
}
