import {Button, ButtonGroup, Dialog} from 'platform/components';
import {Grid, Right, Space, Box} from 'platform/foundation';

import {FC, useState} from 'react';
import {FormSpy} from 'react-final-form';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {useFormRenderer, useRouter, CreateTypeOfControlSettingsRequestBody} from '@dms/teas';

import {AuditStructureSettingsForm} from '../types';

interface AuditSettingsFooterProps {
  type?: CreateTypeOfControlSettingsRequestBody['inspectionType'];
  isCreate?: boolean;
}

export const AuditSettingsFooter: FC<AuditSettingsFooterProps> = ({type, isCreate}) => {
  const router = useRouter();
  const {form} = useFormRenderer<AuditStructureSettingsForm>();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const handleConfirmAndSave = () => {
    form.submit();
    setIsConfirmDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleDiscard = () => {
    router.push(settingsRoutes.vehicleInspections);
  };

  const handleSubmit = () => {
    const dirtyFields = form.getState().dirtyFields;
    const dirtyFieldsLength = Object.keys(dirtyFields).length;

    if (dirtyFieldsLength === 0 || (dirtyFields?.name && dirtyFieldsLength === 1)) {
      form.submit();
    } else {
      setIsConfirmDialogOpen(true);
    }
  };

  const isCondition =
    type === CreateTypeOfControlSettingsRequestBody.inspectionType.VEHICLE_CONDITION;

  return (
    <>
      <Box paddingTop={6}>
        <Grid columns={1}>
          <Right>
            <FormSpy
              render={({submitting}) => (
                <ButtonGroup>
                  <Button
                    variant="outlined"
                    onClick={handleDiscard}
                    title={i18n.t('general.actions.discard')}
                  />
                  <Button
                    onClick={handleSubmit}
                    isLoading={submitting}
                    title={
                      isCondition
                        ? i18n.t('page.vehicleSettings.actions.saveCondition')
                        : isCreate
                          ? i18n.t('page.vehicleSettings.actions.createInspection')
                          : i18n.t('page.vehicleSettings.actions.saveInspection')
                    }
                  />
                </ButtonGroup>
              )}
            />
          </Right>
        </Grid>
      </Box>

      <Dialog
        title={
          isCondition
            ? i18n.t('page.vehicleSettings.labels.saveConditionConfirmationText')
            : i18n.t('page.vehicleSettings.labels.saveInspectionConfirmationText')
        }
        size="small"
        isOpen={isConfirmDialogOpen}
        onClose={handleCancel}
      >
        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={handleCancel}
            title={i18n.t('general.actions.cancel')}
          />
          <Button onClick={handleConfirmAndSave} title={i18n.t('general.actions.confirmAndSave')} />
        </ButtonGroup>
      </Dialog>
    </>
  );
};
