import {DataStatus, FormField} from 'platform/components';
import {Grid} from 'platform/foundation';

import {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import {useGetBranchListQuery, useGetCurrenciesQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {BranchOption, DefinitionFormType} from './types';

interface BranchSectionProps {
  edit: boolean;
  isSystem: boolean;
}

export function BranchSection(props: BranchSectionProps) {
  const form = useFormContext<DefinitionFormType>();
  const {data: currenciesData, isLoading: currenciesLoading} = useGetCurrenciesQuery();
  const {data: branchesData, isLoading: branchesLoading} = useGetBranchListQuery();

  const branchOptions: BranchOption[] = useMemo(() => {
    const branches =
      branchesData?.branchListItems.map((branch) => ({
        value: branch.id,
        label: branch.marketingName ?? '',
      })) ?? [];

    return branches;
  }, [props.edit, branchesData]);

  const currencyOptions = currenciesData?.map((currency) => ({
    value: currency.code,
    label: currency.name,
  }));

  return (
    <DataStatus isLoading={currenciesLoading || branchesLoading}>
      <Grid columns={2}>
        <FormField
          /**
           * Will be resolved by https://carvago.atlassian.net/browse/T20-24077
           * Details: https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/2425#note_259960
           */
          // @ts-ignore
          control={form.control}
          name="branchId"
          type="choice"
          label={i18n.t('entity.branch.labels.branch')}
          options={branchOptions}
          isDisabled={props.edit}
          tooltip={i18n.t('entity.cashRegisterLimits.labels.branchTooltip')}
          data-testid={testIds.settings.cashLimits('branchId')}
        />
        <FormField
          /**
           * Will be resolved by https://carvago.atlassian.net/browse/T20-24077
           * Details: https://gitlab.eag.guru/teas/frontend-monorepo/-/merge_requests/2425#note_259960
           */
          // @ts-ignore
          control={form.control}
          name="currency"
          type="choice"
          label={i18n.t('general.labels.currency')}
          options={currencyOptions}
          isDisabled={props.isSystem}
          data-testid={testIds.settings.cashLimits('currency')}
        />
      </Grid>
    </DataStatus>
  );
}
