import {Button, ButtonGroup, Dialog} from 'platform/components';
import {Space} from 'platform/foundation';

import {FC, useEffect, useState} from 'react';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';

interface ConfirmDialogProps {
  isOpen?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  type: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onConfirm: onConfirmProp,
  onClose: onCloseProp,
  isOpen,
  type,
}) => {
  const [oldType, setOldType] = useState<string>(type);
  const [newType, setNewType] = useState<string>(type);

  const {form} = useFormRenderer();
  useEffect(() => {
    if (newType !== type && isOpen) {
      setOldType(newType);
    }
    setNewType(type);
  }, [type, isOpen]);

  const onClose = () => {
    form.mutators.changeType(oldType);
    onCloseProp?.();
  };

  const onConfirm = () => {
    form.mutators.resetTypeDependentFields();
    onConfirmProp?.();
  };

  return (
    <Dialog
      size="small"
      isOpen={!!isOpen}
      onClose={() => onClose?.()}
      data-testid={testIds.vehicles.create('createMode-dialog')}
    >
      {i18n.t('entity.vehicle.labels.changeTypeConfirmation')}
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          onClick={onClose}
          data-testid={testIds.vehicles.create('createMode-discard')}
          title={i18n.t('general.actions.discard')}
        />
        <Button
          variant="primary"
          onClick={onConfirm}
          data-testid={testIds.vehicles.create('createMode-save')}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </Dialog>
  );
};
