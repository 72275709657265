import {Button, closeDialog} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback, useState} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';

import {TestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {FormDocument} from '../types/EmailSmsNotificationForm';

interface SelectDocumentsProps extends TestIdProps {
  dialogId: string;
  orderId: string;
  selectedDocuments: FormDocument[];
  updateDocumentSelection: (newDocuments: FormDocument[]) => void;
}

export function SelectDocuments(props: SelectDocumentsProps) {
  const [newDocuments, setNewDocuments] = useState<FormDocument[]>([]);

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceCaseOrderId: props.orderId}]),
    [props.orderId]
  );

  return (
    <VStack spacing={4}>
      <DataGrid
        autoHeight
        gridCode="document-context-list-service-case-order-select"
        data-testid={suffixTestId('document-context-list-service-case-order-select', props)}
        queryModifier={queryModifier}
        onRowSelectionChange={(data) => {
          const newlySelectedDocs = data.map(
            (item) =>
              ({
                rowId: item.id,
                file: item.fileName,
                uuid: window.crypto.randomUUID(),
              }) as FormDocument
          );

          setNewDocuments(newlySelectedDocs);
        }}
      />
      <HStack spacing={2} justify="flex-end">
        <Button
          type="button"
          variant="secondary"
          title={i18n.t('general.labels.discard')}
          onClick={() => closeDialog(props.dialogId)}
          data-testid={suffixTestId('general.labels.discard', props)}
        />
        <Button
          type="button"
          variant="primary"
          title={i18n.t('general.labels.add')}
          isDisabled={!newDocuments.length}
          onClick={() => {
            props.updateDocumentSelection([...props.selectedDocuments, ...newDocuments]);
            closeDialog(props.dialogId);
          }}
          data-testid={suffixTestId('general.labels.add', props)}
        />
      </HStack>
    </VStack>
  );
}
