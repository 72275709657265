import {DefaultValues} from 'react-hook-form';

import {isNotNil} from 'ramda-adjunct';

import {GetEmployeeOrgranisationalDataApiResponse} from '@dms/api';

import {EMPTY_BRANCH} from '../../../constants/emptyBranchObject';
import {EmployeeOrganizationalForm} from '../types/employeeOrganizationalFormType';

export const getOrganizationPlacementFormDefaultValues = (
  data?: GetEmployeeOrgranisationalDataApiResponse
): DefaultValues<EmployeeOrganizationalForm> => ({
  companyId: data?.organisationalData?.companyId,
  note: data?.note ?? undefined,
  branches: data?.organisationalData?.branches?.map((branch) => ({
    branchId: branch?.branchId ?? undefined,
    professionId: branch?.professionId ?? undefined,
    workshopData: {
      workTeamId: branch?.workshopData?.workTeamId ?? undefined,
      hourlyRate: branch?.workshopData?.hourlyRate?.amount ?? undefined,
      performancePercentage: isNotNil(branch?.workshopData?.performancePercentage)
        ? (branch?.workshopData?.performancePercentage ?? 0) * 100
        : undefined,
      costCenterId: branch?.workshopData?.costCenterId ?? undefined,
    },
    authorizations:
      branch?.authorizations?.map((authorization) => ({
        authorizationProfileId: authorization?.authorizationProfileId,
        isToOffer: authorization?.isToOffer,
        competencesIds: authorization?.competencesIds ?? [],
      })) ?? [],
  })) ?? [EMPTY_BRANCH],
});
