import {isFeatureEnabled} from 'feature-flags';
import {Card} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';
import {isArray, noop} from 'ramda-adjunct';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {Section, SectionTab, useCustomerUrl, usePermissions} from '@dms/shared';

import {buildArray, composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

export function Vehicles() {
  const navigate = useNavigate();
  const {customerId} = useCustomerUrl();

  const [canReadServiceCase] = usePermissions({permissionKeys: ['serviceCaseRead']});
  const canReadWorkshop = isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      customer: [customerId],
    }),
    [customerId]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    switch (actionKey) {
      case 'detail':
        navigate(
          composePath(vehiclesRoutes.detail, {params: {id}, isSearchParamsPreserved: false})
        );
        break;
    }
  };

  const ownershipQueryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {customerId}]),
    [customerId]
  );

  const ownershipActionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(vehiclesRoutes.detail, {params: {id}, isSearchParamsPreserved: false}))
      )
      .otherwise(noop);
  };

  const tabs = buildArray<SectionTab>([
    {
      queryId: 'vehicle',
      content: (
        <Card title={i18n.t('entity.customer.labels.soldVehicles')}>
          {/*  Datagrid inside tabs need to be wrapped in Box with unique key, to force Datagrid rerender between tab switching  */}
          <Box height={100} key="customer-vehicles-list">
            <DataGrid
              gridCode="customer-vehicles-list"
              data-testid={testIds.customer.detail('interests')}
              actionCallback={actionCallback}
              queryModifier={queryModifier}
            />
          </Box>
        </Card>
      ),
      title: i18n.t('entity.customer.labels.soldVehicles'),
    },
  ]).when(canReadWorkshop, {
    queryId: 'ownership',
    content: (
      <Card title={i18n.t('entity.customer.labels.serviceVehicles')}>
        {/*  Datagrid inside tabs need to be wrapped in Box with unique key, to force Datagrid rerender between tab switching  */}
        <Box key="customer-vehicles-ownership">
          <DataGrid
            autoHeight
            data-testid={testIds.customer.detail('ownership')}
            gridCode="customer-vehicles-ownership"
            actionCallback={ownershipActionCallback}
            queryModifier={ownershipQueryModifier}
          />
        </Box>
      </Card>
    ),
    title: i18n.t('entity.customer.labels.serviceVehicles'),
  });

  return <Section tabs={tabs} />;
}
