import {Card, showNotification} from 'platform/components';

import {FC, PropsWithChildren, ReactElement} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {
  patchKonzultaConfig,
  selectKonzultaConfig,
  KonzultaCredentials,
  useApiDispatch,
} from '@dms/teas';

import {ServiceAuthenticationForm} from './ServiceAuthenticationForm';

export const KonzultaCredentialsAuth: FC<PropsWithChildren<any>> = (): ReactElement => {
  const konzultaConfig = useSelector(selectKonzultaConfig);

  const apiDispatch = useApiDispatch();

  const updateCredentials = (formData: KonzultaCredentials) => {
    apiDispatch(patchKonzultaConfig.action, {
      requestBody: {...konzultaConfig, credentials: formData},
    })
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(() => {
        showNotification.error(i18n.t('general.notifications.changesSavingFailed'));
      });
  };

  return (
    <Card title={i18n.t('general.labels.accessDataAuth')}>
      <ServiceAuthenticationForm
        initialValues={konzultaConfig?.credentials ?? undefined}
        onSubmit={updateCredentials}
      />
    </Card>
  );
};
