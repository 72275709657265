import {BreadcrumbType} from 'platform/components';

import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {
  loadDocumentSeriesList,
  getInterestSeriesDefinition,
  SeriesTypeEnum,
  useThunkDispatch,
} from '@dms/teas';

import {useBoolean} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {InteresDefinitionDetailComponent} from './components/InteresDefinitionDetailComponent';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.interest.labels.interest'),
    href: settingsRoutes.interest,
  },
  {
    label: i18n.t('entity.seriesDefinition.labels.interestCodeDefinition'),
    href: settingsRoutes.interestDefinition,
  },
];

export function InterestDefinitionDetail() {
  const {id} = useParams();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (!id) {
      return;
    }
    startLoading();
    Promise.all([
      dispatch(getInterestSeriesDefinition.action({seriesDefinitionId: id})),
      dispatch(
        loadDocumentSeriesList({
          type: [SeriesTypeEnum.INTEREST_INTEREST, SeriesTypeEnum.GENERAL_VEHICLE],
          config: {sendBranch: false},
        })
      ),
    ]).finally(() => {
      stopLoading();
    });
  }, [dispatch, id, startLoading, stopLoading]);

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{breadcrumbs}}
      data-testid="integrations-interest-definitions-settings-modal"
    >
      <InteresDefinitionDetailComponent />
    </SettingsTemplate>
  );
}
