import {sub} from 'date-fns';
import {array, boolean, date, object, SchemaOf} from 'yup';

import {SeriesPatternStateEnum, SeriesType} from '@dms/api';
import {i18n} from '@dms/i18n';

import {yupNumber, yupString} from 'shared';

import {DocumentSeriesFormValues} from '../types/DocumentSeriesFormValues';

const patternsSchema: SchemaOf<DocumentSeriesFormValues['patterns']> = array()
  .of(
    object().shape({
      prefix: yupString.trim().required(i18n.t('entity.documentSeries.itemValidations.prefix')),
      startingNumber: yupString.required(
        i18n.t('entity.documentSeries.itemValidations.startingNumber')
      ),
      length: yupNumber.required(),
      from: date()
        .when('state', ([state], schema) =>
          state === 'not_used'
            ? schema.min(sub(new Date(), {days: 1}), (minDate: Date) =>
                i18n.t('entity.documentSeries.itemValidations.fromMin', {minDate})
              )
            : schema
        )
        .required(i18n.t('entity.documentSeries.itemValidations.from'))
        .nullable(),
      state: yupString.required() as SchemaOf<SeriesPatternStateEnum>,
    })
  )
  .min(1, i18n.t('entity.documentSeries.validations.items'));

export const documentSeriesFormSchema: SchemaOf<DocumentSeriesFormValues> = object().shape({
  name: yupString.trim().required(i18n.t('entity.documentSeries.validations.name')),
  type: yupString
    .required(i18n.t('entity.documentSeries.validations.type'))
    .nullable() as SchemaOf<SeriesType>,
  branches: array().of(object()).min(1, i18n.t('entity.documentSeries.validations.branch')),
  patterns: patternsSchema,
  unique: boolean().required(),
  withPrefix: yupString.when('type', {
    is: 'accounting/invoice',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.default('onlyStartingNumber'),
  }) as SchemaOf<DocumentSeriesFormValues['withPrefix']>,
});
