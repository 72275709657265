import {Translation} from '@dms/teas';

export const getCarStyle = (carStyle?: Translation | string | null) => {
  if (typeof carStyle === 'string') {
    return carStyle;
  }
  // Override was requered in this task https://carvago.atlassian.net/browse/T20-20004
  if (carStyle?.key === 'CARSTYLE_SUV_OFFROAD') {
    return 'SUV';
  }
  return carStyle?.translation?.toLowerCase() ?? '';
};
