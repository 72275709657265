import validatePhone from 'phone';
import {
  Button,
  ButtonGroup,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Grid, Heading, HStack, Show, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';
import {array, object} from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {always, any, defaultTo, ifElse, isNil, isNotNil, pipe, values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AddrDetailApiResponse,
  AddressRequestBodyV2,
  CommonAddressRequestBody,
  CreateCustomerRequestBody,
  CustomerResponseBodyV2,
  EmailData,
  PhoneNumber,
  PhoneNumberData,
  useCreateCustomerAddressV2Mutation,
  useCreateCustomerContactMutation,
  useCreateCustomerMutation,
  useLazyGetCustomerV2Query,
  usePatchCustomerMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps, yupString} from 'shared';

import {emptyAddressPol, emptyPersonData} from '../../../constants/emptyValues';
import {useCustomerGroups} from '../../../hooks/useCustomerGroups';
import {useInstitution} from '../../../hooks/useInstitution';
import {useTenant} from '../../../hooks/useTenant';
import {handleApiError} from '../../../utils/handleApiError';
import {isAddressCompleted} from '../../../utils/isAddressCompleted';
import {AddressSearch} from '../../AddressSearch/AddressSearch';
import {CountrySelect} from '../../CountrySelect/CountrySelect';
import {DecodeBusinessIdButton} from '../../DecodeBusinessIdButton/DecodeBusinessIdButton';
import {EmailList} from '../../EmailList/EmailList';
import {PhoneNumberList} from '../../PhoneNumberList/PhoneNumberList';
import {CreateCustomerProps} from '../types/CreateCustomerProps';
import {LegalPersonFormType} from '../types/LegalPersonFormType';
import {handleDecodeBusinessIdForm} from '../utils/handleDecodeBusinessIdForm';

interface NewLegalPersonFormProps extends TestIdProps {
  customer: CreateCustomerProps;
  onCreate: (customer: CustomerResponseBodyV2) => void;
  onClose: () => void;
}

export function LegalPersonForm(props: NewLegalPersonFormProps) {
  const {institutionOptions} = useInstitution();
  const {customerGroupOptions} = useCustomerGroups();

  const [getCustomer, {isLoading: isGetCustomerLoading}] = useLazyGetCustomerV2Query();
  const [createCustomer, {isLoading: isCreateCustomerLoading}] = useCreateCustomerMutation();
  const [createCustomerAddress, {isLoading: isCreateCustomerAddressLoading}] =
    useCreateCustomerAddressV2Mutation();
  const [patchCustomer, {isLoading: isPatchCustomerLoading}] = usePatchCustomerMutation();
  const [createContact, {isLoading: isCreateCustomerContractLoading}] =
    useCreateCustomerContactMutation();

  const onSubmit: FormSubmitHandler<LegalPersonFormType> = async (data) => {
    const createCustomerRequestBody: CreateCustomerRequestBody = {
      customerDiscriminator: data.customerData.customerDiscriminator,
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      registrationNumber: data.businessInfo?.businessInfoData.registrationNumber ?? null,
      tradeName: data.businessInfo?.businessInfoData.tradeName ?? null,
      customerGroups: data.customerGroups ?? [],
    };

    await createCustomer({createCustomerRequestBody})
      .unwrap()
      .then(async ({id: customerId}) => {
        if (isAddressCompleted(data?.addressData)) {
          await createCustomerAddress({
            customerId,
            addressRequestBody: {
              address: data.addressData?.address ?? emptyAddressPol.address,
              type: null,
              invalid: null,
            },
          })
            .unwrap()
            .then((address) => {
              if (data.businessInfo) {
                data.businessInfo.businessAddressId = address.id;
              }
            })
            .catch(handleApiError);
        }

        if (isNilOrEmpty(data.contactPerson.phoneNumbers?.[0]?.phoneNumber?.number)) {
          data.contactPerson.phoneNumbers = [];
        }

        if (isNilOrEmpty(data.contactPerson.emails?.[0]?.email)) {
          data.contactPerson.emails = [];
        }

        if (isNilOrEmpty(data?.contactPerson.identityCards?.[0]?.cardData?.cardNumber)) {
          data.contactPerson.identityCards = [];
        }

        await createContact({
          customerId,
          personRequestBody: data.contactPerson,
        })
          .unwrap()
          .catch(handleApiError);

        await patchCustomer({
          customerId,
          customerRequestBody: data,
        })
          .unwrap()
          .catch(handleApiError);

        await getCustomer({customerId}).unwrap().then(props.onCreate).catch(handleApiError);
      })
      .catch(handleApiError);
  };

  const {tenantPhoneInfo, tenantCountry} = useTenant();

  const setAddressFormSearch =
    (formApi: UseFormReturn<LegalPersonFormType>) => (address: AddrDetailApiResponse) => {
      if (isNil(tenantCountry)) {
        showNotification.error();
        return;
      }

      formApi.setValue(
        'addressData.address',
        {
          city: address?.city ?? '',
          street: address?.street ?? '',
          zip: address?.zipCode ?? '',
          state: address?.state ?? null,
          prefix: address?.prefix ?? null,
          descriptiveNumber: address?.descriptiveNumber ?? null,
          orientationNumber: address?.orientationNumber ?? null,
          addressComplement: null,
          district: null,
          country: tenantCountry,
        },
        {shouldValidate: true}
      );
    };

  const defaultValues: LegalPersonFormType = {
    customerData: {
      customerDiscriminator: props.customer.customerDiscriminator,
      customerSource: null,
    },
    customerGroups: props.customer.customerGroups,
    foundingPerson: null,
    businessInfo: {
      businessAddressId: null,
      businessInfoData: {
        registrationNumber: props.customer.registrationNumber ?? null,
        tradeName: props.customer.tradeName || null,
        countryOfRegistrationCode: null,
        fileNumber: null,
        vatNumber: props.customer.vatNumber ?? null,
      },
    },
    contactPerson: {
      personData: emptyPersonData,
      permanentAddressId: null,
      phoneNumbers: [
        {
          phoneNumber: isNotNilOrEmpty(props.customer.phoneNumber?.number)
            ? props.customer.phoneNumber!
            : {
                prefix: tenantPhoneInfo.prefix,
                number: '',
                countryCode: tenantPhoneInfo.countryCode,
              },
          type: null,
        },
      ],
      emails: [
        {
          email: props.customer.email ?? '',
          type: null,
        },
      ],
      identityCards: [],
    },
    addressData: {
      address: {
        city: props.customer.address?.city ?? '',
        street: props.customer.address?.street ?? '',
        zip: props.customer.address?.zip ?? '',
        state: props.customer.address?.state ?? null,
        prefix: props.customer.address?.prefix ?? null,
        descriptiveNumber: props.customer.address?.descriptiveNumber ?? null,
        orientationNumber: props.customer.address?.orientationNumber ?? null,
        addressComplement: props.customer.address?.addressComplement ?? null,
        district: props.customer.address?.district ?? null,
        country: props.customer.address?.country ?? '',
        coordinates:
          props.customer.address?.coordinates &&
          isNotNil(props.customer.address.coordinates.longitude) &&
          isNotNil(props.customer.address.coordinates.latitude)
            ? {
                longitude: String(props.customer.address.coordinates.longitude),
                latitude: String(props.customer.address.coordinates.latitude),
              }
            : null,
        postal: props.customer.address?.postal || null,
      },
      type: null,
      invalid: null,
    },
  };

  const isLoading =
    isCreateCustomerLoading ||
    isCreateCustomerAddressLoading ||
    isPatchCustomerLoading ||
    isCreateCustomerContractLoading ||
    isGetCustomerLoading;

  return (
    <Form<LegalPersonFormType> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
      {(control, formApi) => (
        <>
          <VStack spacing={4}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="businessInfo.businessInfoData.registrationNumber"
                  label={i18n.t('entity.businessInfo.labels.registrationNumber')}
                  data-testid={suffixTestId('registrationNumber', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="businessInfo.businessInfoData.vatNumber"
                  label={i18n.t('entity.businessInfo.labels.vatNumber')}
                  data-testid={suffixTestId('vatNumber', props)}
                />
              </Box>
              <HStack height={13} align="flex-end">
                <DecodeBusinessIdButton
                  businessId={formApi.watch('businessInfo.businessInfoData.registrationNumber')}
                  vatId={formApi.watch('businessInfo.businessInfoData.vatNumber')}
                  countryCode={formApi.watch(
                    'businessInfo.businessInfoData.countryOfRegistrationCode'
                  )}
                  onSuccess={handleDecodeBusinessIdForm(formApi)}
                  data-testid={suffixTestId('decode', props)}
                />
              </HStack>
            </HStack>
            <Box flex={1}>
              <FormField
                control={control}
                type="text"
                name="businessInfo.businessInfoData.tradeName"
                label={i18n.t('entity.businessInfo.labels.tradeName')}
                isRequired
                data-testid={suffixTestId('tradeName', props)}
              />
            </Box>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="businessInfo.businessInfoData.fileNumber"
                  label={i18n.t('entity.businessInfo.labels.fileNumber')}
                  data-testid={suffixTestId('fileNumber', props)}
                />
              </Box>
              <Box flex={1}>
                <CountrySelect<LegalPersonFormType>
                  control={control}
                  name="businessInfo.businessInfoData.countryOfRegistrationCode"
                  label={i18n.t('entity.businessInfo.labels.countryOfRegistrationCode')}
                  isRequired
                  menuInPortal
                  data-testid={suffixTestId('countryOfRegistrationCode', props)}
                />
              </Box>
            </HStack>
            <Separator spacing={0} />
            <Heading size={4}>{i18n.t('entity.businessCase.labels.contactPerson')}</Heading>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="contactPerson.personData.titleBefore"
                  maxLength={10}
                  label={i18n.t('entity.person.labels.titleBefore')}
                  data-testid={suffixTestId('titleBefore', props)}
                />
              </Box>
              <Box flex={2}>
                <FormField
                  control={control}
                  type="text"
                  name="contactPerson.personData.firstName"
                  maxLength={64}
                  label={i18n.t('entity.person.labels.firstName')}
                  data-testid={suffixTestId('firstName', props)}
                />
              </Box>
              <Box flex={2}>
                <FormField
                  control={control}
                  type="text"
                  name="contactPerson.personData.lastName"
                  isRequired
                  maxLength={64}
                  label={i18n.t('entity.person.labels.lastName')}
                  data-testid={suffixTestId('lastName', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="contactPerson.personData.titleAfter"
                  maxLength={10}
                  label={i18n.t('entity.person.labels.titleAfter')}
                  data-testid={suffixTestId('titleAfter', props)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  name="contactPerson.personData.roles"
                  type="text"
                  label={i18n.t('entity.person.labels.role')}
                  data-testid={suffixTestId('roles', props)}
                />
              </Box>
              <Box flex={1}>
                <Space fillAvailable />
              </Box>
            </HStack>
            <PhoneNumberList<LegalPersonFormType>
              control={control}
              formApi={formApi}
              name="contactPerson.phoneNumbers"
              data-testid={suffixTestId('phoneNumberList', props)}
            />
            <EmailList<LegalPersonFormType>
              control={control}
              formApi={formApi}
              name="contactPerson.emails"
              data-testid={suffixTestId('emailList', props)}
            />
            <Separator spacing={0} />
            <Heading size={4}>{i18n.t('entity.businessInfo.labels.businessAddressId')}</Heading>
            <AddressSearch onSelect={setAddressFormSearch(formApi)} />
            <Show when={tenantCountry !== 'POL'}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.street"
                    type="text"
                    label={i18n.t('entity.address.labels.street')}
                    data-testid={suffixTestId('street', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.descriptiveNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.descriptiveNumber')}
                        data-testid={suffixTestId('descriptiveNumber', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.orientationNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.orientationNumber')}
                        data-testid={suffixTestId('orientationNumber', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.city"
                    type="text"
                    label={i18n.t('entity.address.labels.city')}
                    data-testid={suffixTestId('city', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.district"
                        type="text"
                        label={i18n.t('entity.address.labels.district')}
                        data-testid={suffixTestId('postalCode', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        isRequired
                        control={control}
                        name="addressData.address.zip"
                        type="text"
                        label={i18n.t('entity.address.labels.zipCode')}
                        data-testid={suffixTestId('zipCode', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <CountrySelect<LegalPersonFormType>
                    isRequired
                    control={control}
                    name="addressData.address.country"
                    label={i18n.t('entity.address.labels.country')}
                    menuInPortal
                    data-testid={suffixTestId('country', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="addressData.address.addressComplement"
                    type="text"
                    label={i18n.t('entity.address.labels.addressComplement')}
                    data-testid={suffixTestId('addressComplement', props)}
                  />
                </Box>
              </HStack>
              <FormField
                control={control}
                name="addressData.type"
                type="text"
                label={i18n.t('entity.address.labels.type')}
                data-testid={suffixTestId('type', props)}
              />
              <Space fillAvailable />
            </Show>
            <Show when={tenantCountry === 'POL'}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.street"
                    type="text"
                    label={i18n.t('entity.address.labels.street')}
                    data-testid={suffixTestId('street', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.descriptiveNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.descriptiveNumber')}
                        data-testid={suffixTestId('descriptiveNumber', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.orientationNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.orientationNumber')}
                        data-testid={suffixTestId('orientationNumber', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.city"
                    type="text"
                    label={i18n.t('entity.address.labels.city')}
                    data-testid={suffixTestId('city', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.district"
                        type="text"
                        label={i18n.t('entity.address.labels.district')}
                        data-testid={suffixTestId('postalCode', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        isRequired
                        control={control}
                        name="addressData.address.zip"
                        type="text"
                        label={i18n.t('entity.address.labels.zipCode')}
                        data-testid={suffixTestId('zipCode', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <CountrySelect<LegalPersonFormType>
                    isRequired
                    control={control}
                    name="addressData.address.country"
                    label={i18n.t('entity.address.labels.country')}
                    menuInPortal
                    data-testid={suffixTestId('country', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="addressData.address.addressComplement"
                    type="text"
                    label={i18n.t('entity.address.labels.addressComplement')}
                    data-testid={suffixTestId('addressComplement', props)}
                  />
                </Box>
              </HStack>
              <FormField
                control={control}
                name="addressData.type"
                type="text"
                label={i18n.t('entity.address.labels.type')}
                data-testid={suffixTestId('type', props)}
              />
              <Space fillAvailable />
            </Show>
            <Separator spacing={0} />
            <Heading size={4}>{i18n.t('entity.businessInfo.labels.institutions.title')}</Heading>
            <FormField
              control={control}
              type="multiChoice"
              name="institutions"
              menuPlacement="top"
              options={institutionOptions}
              label={i18n.t('entity.address.labels.selectInstitutions')}
              data-testid={suffixTestId('institution', props)}
              isNotClearable
            />
            <Show whenFeatureEnabled={featureFlags.CORE_CUSTOMER_GROUPS}>
              <Separator spacing={0} />
              <Heading size={4}>{i18n.t('entity.person.segmentation')}</Heading>
              <Grid columns={2}>
                <FormField
                  name="customerGroups"
                  label={i18n.t('entity.person.labels.customerGroups')}
                  type="multiChoice"
                  options={customerGroupOptions}
                  menuPlacement="top"
                  menuInPortal
                  isNotClearable
                  control={control}
                  data-testid={suffixTestId('customerGroups', props)}
                />
              </Grid>
            </Show>
          </VStack>
          <DialogFooter>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                isDisabled={isLoading}
                onClick={props.onClose}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={isLoading}
                title={i18n.t('general.actions.create')}
                data-testid={suffixTestId('create', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </>
      )}
    </Form>
  );
}

const schema = object().shape(
  {
    businessInfo: object().shape({
      businessInfoData: object().shape(
        {
          countryOfRegistrationCode: yupString.required(),
          registrationNumber: yupString.when('vatNumber', {
            is: (value: string | Nullish) => isNilOrEmpty(value),
            then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
          }),
          vatNumber: yupString.when('registrationNumber', {
            is: (value: string | Nullish) => isNilOrEmpty(value),
            then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
          }),
          tradeName: yupString.required(),
          fileNumber: yupString.optional(),
        },
        [['registrationNumber', 'vatNumber']]
      ),
    }),
    customerGroups: array(yupString),
    contactPerson: object().shape(
      {
        personData: object().shape({
          titleBefore: yupString.optional(),
          firstName: yupString.optional().max(64),
          lastName: yupString.required().max(64),
          titleAfter: yupString.optional(),
          roles: yupString.optional(),
        }),
        phoneNumbers: Yup.array().when('emails', {
          is: (emails?: EmailData[]) =>
            emails?.length && emails.length <= 1 && isNilOrEmpty(emails?.[0]?.email),
          then: (schema) =>
            schema
              .of(
                Yup.object().shape({
                  type: yupString.optional(),
                  phoneNumber: Yup.mixed()
                    .test(
                      'isPhone',
                      i18n.t('general.validations.invalidPhoneNumber'),
                      (phone?: PhoneNumber | Nullish) => {
                        if (isNilOrEmpty(phone?.number)) {
                          return true;
                        }
                        return validatePhone(phone?.number || '', {
                          country: phone?.countryCode,
                          validateMobilePrefix: false,
                        }).isValid;
                      }
                    )
                    .test(
                      'required',
                      i18n.t('general.validations.phoneOrEmail'),
                      (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
                    ),
                })
              )
              .min(1),
          otherwise: (schema) =>
            schema.of(
              Yup.object().shape({
                type: yupString.optional(),
                phoneNumber: Yup.mixed().test(
                  'isPhone',
                  i18n.t('general.validations.invalidPhoneNumber'),
                  (phone?: PhoneNumber | Nullish) => {
                    if (isNilOrEmpty(phone?.number)) {
                      return true;
                    }
                    return validatePhone(phone?.number || '', {
                      country: phone?.countryCode,
                      validateMobilePrefix: false,
                    }).isValid;
                  }
                ),
              })
            ),
        }),
        emails: Yup.array().when('phoneNumbers', {
          is: (phoneNumbers?: PhoneNumberData[]) =>
            phoneNumbers?.length &&
            phoneNumbers?.length <= 1 &&
            isNilOrEmpty(phoneNumbers?.[0]?.phoneNumber?.number),
          then: (schema) =>
            schema
              .of(
                Yup.object().shape({
                  type: yupString.optional(),
                  email: yupString.required(i18n.t('general.validations.phoneOrEmail')),
                })
              )
              .min(1),
          otherwise: (schema) =>
            schema.of(
              Yup.object().shape({
                type: yupString.optional(),
                email: yupString.optional().default(''),
              })
            ),
        }),
      },
      [['emails', 'phoneNumbers']]
    ),
    addressData: Yup.object().when('addressData', {
      is: (addressData: AddressRequestBodyV2 | Nullish) =>
        ifElse(
          isNotNilOrEmpty,
          pipe<
            [CommonAddressRequestBody | Nullish],
            CommonAddressRequestBody | object,
            (string | {latitude: string; longitude: string} | boolean | Nullish)[],
            boolean
          >(defaultTo({}), values, any(isNotNilOrEmpty)),
          always(false)
        )(addressData?.address),
      then: (schema) =>
        schema.shape({
          address: Yup.object().shape({
            street: Yup.string().required().nullable(),
            descriptiveNumber: Yup.string().optional().nullable(),
            orientationNumber: Yup.string().optional().nullable(),
            city: Yup.string().required().nullable(),
            district: Yup.string().optional().nullable(),
            zip: Yup.string().required().nullable(),
            country: Yup.string().required().nullable(),
            addressComplement: Yup.string().nullable().optional().nullable(),
            coordinates: Yup.object()
              .shape({
                latitude: yupString,
                longitude: yupString,
              })
              .optional()
              .nullable(),
            postal: Yup.string().optional().nullable(),
          }),
          type: Yup.string().nullable().optional(),
        }),
    }),
  },
  [['addressData', 'addressData']]
);
