import {Choice, DataStatus, OptionTypeBase} from 'platform/components';
import {match} from 'ts-pattern';

import {FilterOptionOption} from 'react-select/dist/declarations/src/filters';

import {i18n} from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useMakeModelFilter} from '../../../hooks/useMakeModelFilter';
import {getOptionType} from '../../../utils/getOptionType';
import {getHighlightedText} from '../utils/getHighlightedText';

export function MakesModelsSearch(props: TestIdProps) {
  const {models, modelFamilies, makes, isLoading, selectMakeModel} = useMakeModelFilter();

  const options = Object.entries({...makes, ...modelFamilies, ...models}).map(([key, value]) => ({
    value: key,
    ...value,
  }));

  const filterOption = (
    option: FilterOptionOption<OptionTypeBase<string | Nullish>>,
    inputValue: string
  ) =>
    isLoading
      ? false
      : match<[number, 'make' | 'modelFamily' | 'model']>([
          inputValue.length,
          getOptionType(option.value),
        ])
          .with([0, 'make'], () => true)
          .with([0, 'modelFamily'], () => false)
          .with([1, 'modelFamily'], () => false)
          .with([0, 'model'], () => false)
          .with([1, 'model'], () => false)
          .with([2, 'model'], () => false)
          .otherwise(() => option.label.toLowerCase().includes(inputValue.toLowerCase()));

  return (
    <DataStatus isLoading={isLoading}>
      <Choice
        value={null}
        onChange={selectMakeModel}
        options={options}
        filterOption={filterOption}
        formatOptionLabel={(option, {context, inputValue}) =>
          context === 'menu' && inputValue.length > 0
            ? getHighlightedText(option.label ?? '', inputValue)
            : option.label
        }
        placeholder={i18n.t('general.labels.searchFor')}
        data-testid={suffixTestId('search', props)}
      />
    </DataStatus>
  );
}
