import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';
import {object} from 'yup';

import {useParams} from 'react-router-dom';

import {
  GetSalePresetSettingsResponseBody,
  useCreateSalePresetSettingsMutation,
  useGetBackgroundRemovalListQuery,
  useGetPromotionalPhotosQuery,
  useGetWatermarkListQuery,
  useListThemeQuery,
  useUpdateSalePresetSettingsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError, MakeSelect} from '@dms/shared';

import {useNavigate, yupString} from 'shared';

import {SalePresetFormValues} from '../types/SalePresetFormValues';

type SalePresetFormProps = {
  settings?: GetSalePresetSettingsResponseBody;
};

export function SalePresetForm(props: SalePresetFormProps) {
  const {id} = useParams();
  const navigate = useNavigate();

  const {data: watermarkListData} = useGetWatermarkListQuery();
  const {data: backgroundRemovalListData} = useGetBackgroundRemovalListQuery();
  const {data: promotionalPhotosData} = useGetPromotionalPhotosQuery();
  const {data: themeListData} = useListThemeQuery();
  const [createSalePreset] = useCreateSalePresetSettingsMutation();
  const [updateSalePreset] = useUpdateSalePresetSettingsMutation();

  const watermarkListOptions = watermarkListData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const backgroundRemovalOptions = backgroundRemovalListData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const promotionalPhotosOptions = promotionalPhotosData?.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const themeListOptions = themeListData?.map((item) => ({
    value: item.themeId,
    label: item.name,
  }));

  const onSubmit: FormSubmitHandler<SalePresetFormValues> = async (values) => {
    const dataToSend = {
      name: values.name,
      makes: values.makes,
      watermarkId: values.watermarkId,
      backgroundRemovalId: values.backgroundRemovalId,
      promotionalPhotoId: values.promotionalPhotoId,
      digitalCertificateCode: values.digitalCertificateCode,
    };
    if (id) {
      await updateSalePreset({
        salePresetId: id,
        updateSalePresetSettingsRequestBody: dataToSend,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          navigate(settingsRoutes.salePreset);
        })
        .catch(handleApiError);
    } else {
      await createSalePreset({
        createSalePresetSettingsRequestBody: dataToSend,
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          navigate(settingsRoutes.salePreset);
        })
        .catch(handleApiError);
    }
  };

  return (
    <Form<SalePresetFormValues>
      onSubmit={onSubmit}
      schema={schema}
      defaultValues={{
        name: props.settings?.name ?? '',
        makes: props.settings?.makes ?? [],
        watermarkId: props.settings?.watermarkId ?? null,
        backgroundRemovalId: props.settings?.backgroundRemovalId ?? null,
        promotionalPhotoId: props.settings?.promotionalPhotoId ?? null,
        digitalCertificateCode: props.settings?.digitalCertificateCode ?? null,
      }}
      isFullHeight
    >
      {(control) => (
        <VStack spacing={4}>
          <VStack spacing={4} maxWidth={177}>
            <FormField
              control={control}
              type="text"
              name="name"
              isRequired
              label={i18n.t('page.salesSettings.salePreset.labels.name')}
            />
            <HStack spacing={6}>
              <Box flex={0.5}>
                <MakeSelect
                  vehicleType="VEHICLETYPE_PASSENGER_CAR"
                  control={control}
                  name="makes"
                  label={i18n.t('page.salesSettings.salePreset.labels.makes')}
                  isMultiple
                  data-testid="salePreset-make-select"
                />
              </Box>
              <Space />
            </HStack>
            <HStack spacing={6}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="watermarkId"
                  options={watermarkListOptions ?? []}
                  label={i18n.t('page.salesSettings.salePreset.labels.watermark')}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="digitalCertificateCode"
                  options={themeListOptions ?? []}
                  label={i18n.t('page.salesSettings.salePreset.labels.digitalCertificate')}
                />
              </Box>
            </HStack>
            <HStack spacing={6}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="backgroundRemovalId"
                  options={backgroundRemovalOptions ?? []}
                  label={i18n.t('page.salesSettings.salePreset.labels.backgroundRemoval')}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  options={promotionalPhotosOptions ?? []}
                  name="promotionalPhotoId"
                  label={i18n.t('page.salesSettings.salePreset.labels.promotionalPhoto')}
                />
              </Box>
            </HStack>
          </VStack>
          <Separator spacing={2} />

          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discardChanges')}
              variant="secondary"
              onClick={() => navigate(settingsRoutes.salePreset)}
              data-testid="salePreset-discardChangesBtn"
            />
            <FormButton
              title={i18n.t('general.actions.saveAndClose')}
              control={control}
              type="submit"
              data-testid="salePreset-submitBtn"
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = object({
  name: yupString.required().max(255),
  makes: Yup.array(yupString),
  watermarkId: yupString,
  backgroundRemovalId: yupString,
  promotionalPhotoId: yupString,
  digitalCertificateCode: yupString,
});
