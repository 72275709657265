import {HStack, Heading, Icon} from 'platform/foundation';

import {OrderResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

interface PaymentTitleProps {
  order: OrderResponseBody | Nullish;
}

export const PaymentTitle = ({order}: PaymentTitleProps) => {
  if (!order) {
    return null;
  }

  if (order.payments.some((payment) => payment?.paymentDiscriminator === 'PURCHASE')) {
    return (
      <HStack spacing={2} align="center">
        <Icon value="communication/call_made" color="general.accent" />
        <Heading size={4}>{i18n.t('entity.checkout.labels.outgoingPayment')}</Heading>
      </HStack>
    );
  }

  return (
    <HStack spacing={2} align="center">
      <Icon value="action/payment" color="general.accent" />
      <Heading size={4}>
        {order.depositAllowed
          ? i18n.t('entity.checkout.labels.supplementaryPayment')
          : i18n.t('entity.checkout.labels.totalPayment')}
      </Heading>
    </HStack>
  );
};
