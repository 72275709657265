import {
  Attributes,
  AttributesRow,
  Card,
  DataStatus,
  FormSubmitHandler,
  closeCurrentDialog,
  openDialog,
  showNotification,
} from 'platform/components';

import {isNil, isNotNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useGetCustomerPaymentQuery,
  useGetCustomerV2Query,
  usePatchCustomerPaymentMutation,
  usePostCustomerPaymentMutation,
  usePutCustomerPaymentSettingsMutation,
} from '@dms/api';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {EMPTY_PLACEHOLDER, TestIdProps, buildArray, suffixTestId} from 'shared';

import {AbroadSaleType, PaymentSettings} from '../types/PaymentSettings';
import {IN_EUROPE} from '../utils/const';
import {PaymentSettingsForm} from './PaymentSettingsForm';

interface PaymentSettingsCardProps extends TestIdProps {
  customerId: string;
}

export function PaymentSettingsCard(props: PaymentSettingsCardProps) {
  const {data, isLoading, isError} = useGetCustomerPaymentQuery({customerId: props.customerId});
  const {data: customerData} = useGetCustomerV2Query({customerId: props.customerId});

  const [patchCustomerPayment] = usePatchCustomerPaymentMutation();
  const [postCustomerPayment] = usePostCustomerPaymentMutation();
  const [patchCustomerAbroadSale] = usePutCustomerPaymentSettingsMutation();
  const hasAbroadSale = isNotNil(customerData?.abroadSale);

  const handleSubmit: FormSubmitHandler<PaymentSettings> = async (formData) => {
    let isError: boolean = false;
    if (formData.allowAbroadSale) {
      await patchCustomerAbroadSale({
        customerId: props.customerId,
        paymentSettingsRequestBody: {
          abroadSale: isArray(formData.abroadSale) ? formData.abroadSale[0] : formData.abroadSale,
        },
      })
        .unwrap()
        .catch((e) => {
          handleApiError(e);
          isError = true;
        });
    } else if (hasAbroadSale) {
      await patchCustomerAbroadSale({
        customerId: props.customerId,
        paymentSettingsRequestBody: {
          abroadSale: null,
        },
      })
        .unwrap()
        .catch((e) => {
          handleApiError(e);
          isError = true;
        });
    }
    (isNil(data)
      ? postCustomerPayment({
          customerId: props.customerId,
          body: {
            allowBankTransfer: formData.allowBankTransfer,
            email: formData.email,
            invoiceMaturity: formData.invoiceMaturity ?? 0,
            paymentLimit: formData.paymentLimit,
          },
        })
      : patchCustomerPayment({
          customerId: props.customerId,
          body: {
            allowBankTransfer: formData.allowBankTransfer,
            email: formData.email,
            invoiceMaturity: formData.invoiceMaturity ?? 0,
            paymentLimit: formData.paymentLimit,
          },
        })
    )
      .unwrap()
      .catch((e) => {
        handleApiError(e);
        isError = true;
      });
    if (!isError) {
      showNotification.success();
      closeCurrentDialog();
    }
  };

  const handleEdit = () =>
    openDialog(
      <PaymentSettingsForm
        defaultValues={{
          allowBankTransfer: data?.allowBankTransfer ?? false,
          email: data?.email ?? undefined,
          invoiceMaturity: data?.invoiceMaturity ?? undefined,
          allowAbroadSale: isNotNil(customerData?.abroadSale) ?? false,
          abroadSale: (customerData?.abroadSale as AbroadSaleType) ?? 'IN_EUROPE',
          paymentLimit: data?.paymentLimit ?? undefined,
        }}
        onClose={closeCurrentDialog}
        onSubmit={handleSubmit}
      />,
      {title: i18n.t('entity.customerPaymentSettings.labels.paymentSettings')}
    );

  const isBankTransferAllowed = data?.allowBankTransfer;

  const abroadSaleName =
    customerData?.abroadSale === IN_EUROPE
      ? i18n.t('entity.customerPaymentSettings.labels.saleToEU')
      : i18n.t('entity.customerPaymentSettings.labels.saleOutsideEU');

  const attributes = buildArray<AttributesRow>([])
    .whenFeatureEnabled(featureFlags.CUSTOMER_ABROAD_SALE_SETTING, {
      label: i18n.t('entity.customerPaymentSettings.labels.abroadSale'),
      value: hasAbroadSale ? abroadSaleName : i18n.t('general.labels.notAllowed'),
    })
    .add({
      label: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
      value: isBankTransferAllowed
        ? i18n.t('general.labels.allowed')
        : i18n.t('general.labels.notAllowed'),
    })
    .when(isBankTransferAllowed, {
      label: i18n.t('entity.customerPaymentSettings.labels.invoiceMaturity'),
      value: data?.invoiceMaturity ?? EMPTY_PLACEHOLDER,
    })
    .when(isBankTransferAllowed, {
      label: i18n.t('entity.customerPaymentSettings.labels.paymentLimit'),
      value: data?.paymentLimit ?? EMPTY_PLACEHOLDER,
    })
    .when(isBankTransferAllowed, {
      label: i18n.t('entity.customerPaymentSettings.labels.email'),
      value: data?.email ?? EMPTY_PLACEHOLDER,
    });

  return (
    <Card
      title={i18n.t('entity.customerPaymentSettings.labels.paymentSettings')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'image/edit',
          title: i18n.t('general.actions.edit'),
          onClick: handleEdit,
          'data-testid': suffixTestId('edit', props),
        },
      ]}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Attributes rows={attributes} data-testid={suffixTestId('attributes', props)} />
      </DataStatus>
    </Card>
  );
}
