import {Attributes, AttributesRow, Card} from 'platform/components';

import {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {find, isNil, propEq} from 'ramda';

import {FullState, ModelSpecification, VehicleDataResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {useCodeList} from '@dms/shared';
import {useRouter, selectCountries, selectCondition, VehicleType} from '@dms/teas';

import {buildArray, composePath} from 'shared';

type ModelSpecificationCardProps = {
  id: string;
  type?: VehicleType;
  state: Partial<FullState>;
  modelSpecification: Partial<ModelSpecification>;
  vehicle: Partial<VehicleDataResponseBody>;
  canEditVehicle: boolean;
  vehicleSourceCodeId?: string | null;
};

const ModelSpecificationCard: FC<ModelSpecificationCardProps> = ({
  id,
  type,
  state,
  modelSpecification,
  vehicle,
  vehicleSourceCodeId,
  canEditVehicle,
}) => {
  const router = useRouter();

  const {mileage, ownerCount, primaryKeyCount, hasCoc} = state;
  const {originCountry, firstRegistrationCountry, lastRegistrationCountry, isRegistered} =
    modelSpecification;

  const condition = useSelector(selectCondition(state.condition, type));
  const countryList = useSelector(selectCountries);

  const [vehicleSourceCodes] = useCodeList('vehicle_source');

  const handleOnClick = useCallback(() => {
    if (id) {
      router.push(composePath(vehiclesRoutes.editDetailTab, {params: {id, tab: 'vehicle-data'}}));
    }
  }, [id, router]);

  const isTrailerOrSemiTrailer =
    type === 'VEHICLETYPE_TRAILER' || type === 'VEHICLETYPE_SEMI_TRAILER';

  const rows = buildArray<AttributesRow>([])
    .add({
      label: i18n.t('entity.vehicle.labels.condition'),
      value: condition?.label,
      testId: 'condition',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.countryOfOrigin'),
      value: find(propEq(originCountry, 'code'), countryList)?.name,
      testId: 'countryOfOrigin',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.firstRegistrationCountry'),
      value: find(propEq(firstRegistrationCountry, 'code'), countryList)?.name,
      testId: 'firstRegistrationCountry',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.lastRegistrationCountry'),
      value: find(propEq(lastRegistrationCountry, 'code'), countryList)?.name,
      testId: 'lastRegistrationCountry',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.vehicleSource'),
      value: vehicleSourceCodes?.find((source) => source.codeId === vehicleSourceCodeId)?.name,
      testId: 'vehicleSource',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.registered'),
      value: isRegistered ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
      testId: 'registered',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.hasCoc'),
      value: hasCoc ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
      testId: 'hasCoc',
    })
    .whenNot(isTrailerOrSemiTrailer, {
      label: `${i18n.t('entity.vehicle.labels.mileage')} (${i18n.t('general.metric.km')})`,
      value: isNil(mileage) ? '' : `${mileage}`,
      testId: 'mileage',
    })
    .whenNot(isTrailerOrSemiTrailer, {
      label: `${i18n.t('entity.vehicle.labels.realMileage')} (${i18n.t('general.metric.km')})`,
      value: isNil(vehicle?.realMileage) ? '' : `${vehicle.realMileage}`,
      testId: 'realMileage',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.previousOwners'),
      value: isNil(ownerCount) ? '' : `${ownerCount}`,
      testId: 'previousOwners',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.numberOfKeysFull'),
      value: isNil(primaryKeyCount) ? '' : `${primaryKeyCount}`,
      testId: 'numberOfKeysFull',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.vehicleRegistration'),
      value: vehicle.vehicleRegistrationNumber,
      testId: 'vehicleRegistration',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.registrationCertificate'),
      value: vehicle.registrationCertificateNumber,
      testId: 'registrationCertificate',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.otherRecords'),
      value: vehicle.otherRecords,
      testId: 'otherRecords',
    });

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.generalData')}
      actions={
        canEditVehicle
          ? [
              {
                type: 'button',
                variant: 'link',
                leftIcon: 'image/edit',
                onClick: handleOnClick,
                'data-testid': testIds.vehicles.vehicleData('conditionsCard-edit'),
                title: i18n.t('general.actions.edit'),
              },
            ]
          : undefined
      }
      data-testid={testIds.vehicles.vehicleData('conditionsCard')}
    >
      <Attributes
        data-testid={testIds.vehicles.vehicleData('conditionsCard-parameters')}
        rows={rows}
      />
    </Card>
  );
};

export const ConditionsCard = ModelSpecificationCard;
