import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray, noop} from 'ramda-adjunct';

import {
  useDeleteEmployeeCostCenterMutation,
  usePostEmployeeCostCenterSetAsActiveMutation,
  usePostEmployeeCostCenterSetAsInactiveMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function EmployeeCostCentersOverview() {
  const navigate = useNavigate();

  const [deleteEmployeeCostCenter] = useDeleteEmployeeCostCenterMutation();
  const [postEmployeeCostCenterSetAsActive] = usePostEmployeeCostCenterSetAsActiveMutation();
  const [postEmployeeCostCenterSetAsInactive] = usePostEmployeeCostCenterSetAsInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.employeeCostCentersDetail, {params: {id}}))
      )
      .with('setAsInactive', () =>
        postEmployeeCostCenterSetAsInactive({costCenterId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsActive', () =>
        postEmployeeCostCenterSetAsActive({costCenterId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteEmployeeCostCenter({id}).unwrap().then(refreshData).catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.employeeCostCenters.labels.title'),
        actions: [
          {
            type: 'button',
            onClick: () => navigate(settingsRoutes.employeeCostCentersCreate),
            title: i18n.t('entity.employeeCostCenters.labels.newCostCenter'),
          },
        ],
      }}
      data-testid={testIds.settings.employeeCostCenters('settingsTemplate')}
    >
      <DataGrid
        gridCode="employee-cost-centre"
        actionCallback={actionCallback}
        data-testid={testIds.settings.employeeCostCenters('cost-center')}
      />
    </SettingsTemplate>
  );
}
