import {PurchaseVehicleType} from '@dms/api';

import {createContext} from 'shared';

type SelectVehicleContextType = {
  isVehicleModalOpen: boolean;
  setCreateVehicleModalOpen: (isOpen: boolean, purchaseVehicleType: PurchaseVehicleType) => void;
  isSelectSaleVehicleOpen: boolean;
  setSelectSaleVehicleOpen: (isOpen: boolean) => void;
  purchaseVehicleType: PurchaseVehicleType;
};

export const [Provider, useSelectVehicleContext] = createContext<SelectVehicleContextType>();
