import {DataStatus, Separator} from 'platform/components';
import {Box, Grid, Heading, HStack, VStack} from 'platform/foundation';

import {groupBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useListNotificationDefinitionsQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {NotificationCard} from './components/NotificationCard';

export function NotificationPreview() {
  const {data, isLoading, isError} = useListNotificationDefinitionsQuery();

  const dataByAvailability = groupBy(
    ({isEnabled}) => (isEnabled ? 'enabled' : 'disabled'),
    data ?? []
  );

  return (
    <HStack justify="center" align="center" grow={1} height="100%" maxWidth="100%">
      <Box maxWidth="100%" width={459} height="100%" padding={4}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Grid columns={2}>
            <VStack spacing={2}>
              <Box>
                <Heading size={2}>{i18n.t('entity.settings.labels.active')}</Heading>
                <Separator spacing={0} />
              </Box>

              <DataStatus isEmpty={isNilOrEmpty(dataByAvailability.enabled)} spacing={30}>
                <VStack spacing={2}>
                  {dataByAvailability.enabled?.map((notification) => (
                    <NotificationCard key={notification.id} notification={notification} />
                  ))}
                </VStack>
              </DataStatus>
            </VStack>

            <VStack spacing={2}>
              <Box>
                <Heading size={2}>{i18n.t('entity.employee.labels.flagInactive')}</Heading>
                <Separator spacing={0} />
              </Box>

              <DataStatus isEmpty={isNilOrEmpty(dataByAvailability.disabled)} spacing={30}>
                <VStack spacing={2}>
                  {dataByAvailability.disabled?.map((notification) => (
                    <NotificationCard key={notification.id} notification={notification} />
                  ))}
                </VStack>
              </DataStatus>
            </VStack>
          </Grid>
        </DataStatus>
      </Box>
    </HStack>
  );
}
