import {DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {Suspense, lazy} from 'react';

import featureFlags from '@dms/feature-flags';

const Overview = lazy(() => import('./components/Overview'));
const OverviewV2 = lazy(() => import('./components/OverviewV2'));

export function Analytics() {
  return (
    <Suspense fallback={<DataStatus minHeight={100} isLoading />}>
      <Show whenFeatureEnabled={featureFlags.GOOD_DATA_COULD}>
        <OverviewV2 />
      </Show>
      <Show whenFeatureDisabled={featureFlags.GOOD_DATA_COULD}>
        <Overview />
      </Show>
    </Suspense>
  );
}
