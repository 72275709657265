import {showNotification} from 'platform/components';
import {match, Pattern} from 'ts-pattern';

import {usePatchUserNotificationSettingsMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {noop} from 'shared';

import {RedirectModule} from '../types/RedirectModule';
import {RedirectSubmodule} from '../types/RedirectSubmodule';
import {RedirectView} from '../types/RedirectView';

type handleAdditionalLogicProps = {
  module: RedirectModule | null;
  view: RedirectView | null;
  id: string | null;
  submodule: RedirectSubmodule | null;
  submoduleId: string | null;
};

export const useAdditionalLogic = (props: handleAdditionalLogicProps) => {
  const [patchUserNotificationSettings] = usePatchUserNotificationSettingsMutation();

  const unsubscribeFromNotifications = async (notificationId: string) =>
    await patchUserNotificationSettings({
      id: notificationId,
      patchUserNotificationSettingsRequestBody: {email: 'never'},
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('notifications.labels.unsubscribedSuccessfully')))
      .catch(handleApiError);

  const handleAdditionalLogic = () =>
    match(props)
      .with(
        {module: 'notification', view: 'emailUnsubscribe', id: Pattern.string},
        async (moduleProps) => await unsubscribeFromNotifications(moduleProps.id)
      )
      .otherwise(noop);

  return [handleAdditionalLogic];
};
