import {Separator} from 'platform/components';
import {Box, HStack, Icon, Text, VStack} from 'platform/foundation';

import {Link} from 'react-router-dom';

import {useReadCodelistsQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {composePath} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function Codelists() {
  const {data} = useReadCodelistsQuery({});

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.codelists.pageTitle')}}
      data-testid={testIds.settings.codelists('layout')}
    >
      <Box
        borderRadius="small"
        boxShadow="elevation_1"
        border="1px"
        borderColor="palettes.neutral.60.100"
        backgroundColor="general.white"
      >
        {data?.map((codelist) => (
          <Link
            to={composePath(settingsRoutes.codelistDetail, {
              params: {id: codelist.codelistId},
            })}
            key={codelist.codelistId}
          >
            <VStack height={12}>
              <Box paddingHorizontal={4} paddingVertical={3}>
                <HStack justify="space-between">
                  <Text>{codelist.name}</Text>
                  <Icon value="navigation/chevron_right" />
                </HStack>
              </Box>
              <Separator spacing={0} />
            </VStack>
          </Link>
        ))}
      </Box>
    </SettingsTemplate>
  );
}
