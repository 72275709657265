import {showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, equals, isNil, not} from 'ramda';
import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  DeletePartsRequestReturnItemsRequest,
  HeaderDynamicActionsEventRequest,
  PartsRequestReturnBasketItem,
  PostPartsRequestReturnItemRequest,
  RequestItem,
  useDeletePartsRequestReturnItemsMutation,
  useGetPartsRequestReturnBasketQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  usePatchPartsRequestReturnBasketItemQuantityMutation,
  usePostPartsRequestReturnItemMutation,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {
  AfterSalesMaterialReturnBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  useInvalidBasketItemsIds,
  useBasketMechanic,
} from '@dms/shared';

import {noop, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {createHeaderActions} from '../../../../../utils/createHeaderActions';
import {ServiceOrderReturnsMaterialReturnList} from './ServiceOrderReturnsMaterialReturnList';

interface ServiceOrderReturnsMaterialReturnModalProps extends RequiredTestIdProps {
  serviceOrderReturnId: string;
  serviceOrderId: string;
  authorizationProfileId: string | Nullish;
  onClose: VoidFunction;
}

export function ServiceOrderReturnsMaterialReturnModal(
  props: ServiceOrderReturnsMaterialReturnModalProps
) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetPartsRequestReturnBasketQuery({originEntityHeaderId: props.serviceOrderReturnId});

  const {
    data: partsRequestActions,
    isLoading: isPartsRequestActionsLoading,
    isError: isPartsRequestActionsError,
  } = useGetWarehouseHeaderDynamicActionsQuery({
    id: props.serviceOrderReturnId,
    resource: 'parts-request-return',
  });

  const [addItemsToBasket] = usePostPartsRequestReturnItemMutation();

  const [patchBasketItemQuantity] = usePatchPartsRequestReturnBasketItemQuantityMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useDeletePartsRequestReturnItemsMutation();

  const [putPartsRequestAction, {isLoading: isPutPartsRequestActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const basketItems = basket?.requestReturnBasketItem ?? [];
  const basketTotalPrice = basket?.requestReturnBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const {
    mechanics,
    areMechanicsLoading,
    mechanicPayload,
    inputMechanicId,
    mechanicError,
    setMechanicError,
    handleMechanicChange,
  } = useBasketMechanic({
    authorizationProfileId: props.authorizationProfileId,
    assignedMechanicId: basketMechanicId,
  });

  const isLoading = isBasketLoading || isPartsRequestActionsLoading;

  const hasError = hasBasketError || isPartsRequestActionsError;

  const handleAddToBasket = async (selectedItems: RequestItem[]) => {
    const requestBody: PostPartsRequestReturnItemRequest['body'] = {
      originEntityHeaderId: props.serviceOrderReturnId,
      originEntityHeaderType: 'service-order-return',
      originEntityType: 'svc_job_item',
      requestItems: selectedItems,
    };

    await addItemsToBasket({
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchBasketItemQuantity({
      originEntityHeaderId: props.serviceOrderReturnId,
      requestItemId: itemId,
      body: {quantity: quantity.newQuantity as number},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody: DeletePartsRequestReturnItemsRequest['body'] = {
      requestItemId: ids,
    };

    await deleteBasketItems({
      originEntityHeaderId: props.serviceOrderReturnId,
      body: requestBody,
    })
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const handleActionClick = (actionKey: string) => {
    if (actionKey === 'complete' && isNil(mechanicPayload)) {
      return setMechanicError(i18n.t('general.notifications.fieldIsRequired'));
    }

    const requestBody = match(actionKey)
      .returnType<HeaderDynamicActionsEventRequest['body']>()
      .with(
        'complete',
        always({
          actionKey,
          returnedBy: mechanicPayload?.id,
          originEntityHeaderType: 'service-order-return',
        })
      )
      .otherwise(always({actionKey, originEntityHeaderType: 'service-order-return'}));

    return putPartsRequestAction({
      id: props.serviceOrderReturnId,
      resource: 'parts-request-return',
      body: requestBody,
    })
      .unwrap()
      .then(() =>
        actionKey === 'complete'
          ? showNotification.success(i18n.t('entity.warehouse.notifications.returnCompleted'))
          : noop
      )
      .then(props.onClose)
      .catch(handleApiError);
  };

  const dynamicActions = createHeaderActions({
    actions: partsRequestActions?.actions,
    callback: handleActionClick,
    isLoading: isPutPartsRequestActionLoading,
  });

  return (
    <FullScreenModal
      headline={i18n.t('general.actions.returnMaterial')}
      headerActions={dynamicActions}
      data-testid={props['data-testid']}
    >
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <ServiceOrderReturnsMaterialReturnList
              serviceOrderId={props.serviceOrderId}
              dataGridRef={dataGridRef}
              onAddToBasket={handleAddToBasket}
              data-testid={suffixTestId('list', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialReturnBasket<PartsRequestReturnBasketItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading,
                hasError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{
                mechanics,
                areMechanicsLoading,
                isMechanicRequired: true,
                selectedMechanicId: inputMechanicId,
                mechanicError,
                onMechanicChange: handleMechanicChange,
              }}
              onQuantityChange={handleQuantityChange}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
