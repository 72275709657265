import {CardRendererResponseBody, RendererProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNotNil} from 'ramda';

import i18n from '@dms/i18n';

const trueRegex = /{{\s*true\s*}}/;
const falseRegex = /{{\s*false\s*}}/;

// Recursive function to update options in "select" renderer elements
export const parsedRendererProps = (schema: RendererProps): RendererProps => {
  if (schema.renderer === 'select' && schema.defaultOptions) {
    const updatedOptions = schema.defaultOptions.map((option) => ({
      ...option,
      label: match(option.label)
        .when((label) => trueRegex.test(label), always(i18n.t('general.labels.yes')))
        .when((label) => falseRegex.test(label), always(i18n.t('general.labels.no')))
        .otherwise(() => option.label),
    }));
    return {...schema, defaultOptions: updatedOptions};
  }

  // Recursively update nested elements
  if (hasSchemaChildElements(schema)) {
    const updatedElements = schema.elements.map(parsedRendererProps);
    return {...schema, elements: updatedElements} as RendererProps;
  }

  return schema;
};

const hasSchemaChildElements = (
  schema: RendererProps
): schema is RendererProps & {elements: RendererProps[]} =>
  isNotNil((schema as CardRendererResponseBody).elements);
