import {join, pipe, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {CommonAddressResponseBody} from '@dms/api';

export const composeAddressLineFromCommonAddress = (address: CommonAddressResponseBody): string => {
  const formatStreet = pipe(
    reject(isNilOrEmpty),
    join(' ')
  )([
    address.street,
    pipe(reject(isNilOrEmpty), join('/'))([address.descriptiveNumber, address.orientationNumber]),
  ]);

  const formatCityAndDistrict = pipe(
    reject(isNilOrEmpty),
    join(' - ')
  )([address.city, address.district]);

  const addressParts = [formatStreet, formatCityAndDistrict, address.zip, address.country];

  return pipe(reject(isNilOrEmpty), join(', '))(addressParts).trim();
};
