import {AdvertisingPlatformCodeEnum} from '@dms/teas';

export const advertisingPlatformNames: {[key in AdvertisingPlatformCodeEnum]: string} = {
  [AdvertisingPlatformCodeEnum.SAUTO]: 'Sauto',
  [AdvertisingPlatformCodeEnum.TIPCARS]: 'TipCars',
  [AdvertisingPlatformCodeEnum.MOBILE_DE]: 'Mobile.de',
  [AdvertisingPlatformCodeEnum.MYWEB]: 'My Web',
  [AdvertisingPlatformCodeEnum.FACEBOOK]: 'Facebook',
  [AdvertisingPlatformCodeEnum.AUTOSOFT]: 'Autosoft',
  [AdvertisingPlatformCodeEnum.AUTOCARIS]: 'AutoCaris',
  [AdvertisingPlatformCodeEnum.OTOMOTO]: 'Otomoto',
  [AdvertisingPlatformCodeEnum.OLX]: 'Olx',
  [AdvertisingPlatformCodeEnum.SKODA_PLUS]: 'Škoda Plus',
  [AdvertisingPlatformCodeEnum.DAS_WELT_AUTO]: 'Das WeltAuto',
  [AdvertisingPlatformCodeEnum.MERCEDES_NA_SKLADE]: 'Mercedes na skladě',
  [AdvertisingPlatformCodeEnum.HYUNDAI_PROMISE]: 'Hyundai Promise',
  [AdvertisingPlatformCodeEnum.FORD_UZYWANE]: 'Ford Używane',
  [AdvertisingPlatformCodeEnum.AUTOPLAC_PL]: 'Autoplac.pl',
  [AdvertisingPlatformCodeEnum.VEHIS]: 'Vehis',
  [AdvertisingPlatformCodeEnum.MAMGO]: 'BNP Paribas | MamGO',
  [AdvertisingPlatformCodeEnum.FORD_GERONIMO]: 'Ford Geronimo',
};
