import {FormControl, FormField, Option} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

interface RequestTabLabourBulkEditItemMechanicProps extends RequiredTestIdProps {
  control: FormControl<RequestTabLabourBulkEditItemForm>;
  formApi: UseFormReturn<RequestTabLabourBulkEditItemForm>;
  mechanicOptions: Option[];
  areMechanicOptionsLoading: boolean;
}

export function RequestTabLabourBulkEditItemMechanic(
  props: RequestTabLabourBulkEditItemMechanicProps
) {
  const isMechanicDisabled = not(props.formApi.watch('isMechanicEnabled'));

  return (
    <Box flex={1}>
      <HStack align="center" spacing={5}>
        <Box paddingTop={5}>
          <FormField
            control={props.control}
            type="switch"
            name="isMechanicEnabled"
            data-testid={suffixTestId('isMechanicEnabled', props)}
          />
        </Box>
        <Box flex={1}>
          <FormField
            isDisabled={isMechanicDisabled}
            isLoading={props.areMechanicOptionsLoading}
            control={props.control}
            type="choice"
            name="mechanicId"
            label={i18n.t('entity.warehouse.labels.mechanic')}
            filterOption={(option) => props.formApi.watch('mechanicId') !== option.value}
            options={props.mechanicOptions}
            menuInPortal
            data-testid={suffixTestId('mechanicId', props)}
          />
        </Box>
      </HStack>
    </Box>
  );
}
