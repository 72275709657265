import {Checkbox, CheckboxProps} from 'platform/components';
import {GridItem, Text, Grid, Box} from 'platform/foundation';

import {FC, useState, useCallback, ReactElement} from 'react';

import i18n from '@dms/i18n';
import {useFormRenderer} from '@dms/teas';

import {VISIBLE_TYPE, MANDATORY_TYPE} from '../constants';
import {useConfirmationDialog} from '../hooks/useConfirmationDialog';
import {
  AuditSettingsOption,
  AuditStructureSettingsForm,
  SelectAllStates,
  AuditSettingsOptionType,
  SelectAllState,
  AuditSettingsOptionChildren,
  StructureSettingsItem,
} from '../types';
import {getSelectAllStateByType} from '../utils/legacy_utils';
import {AuditSettingsOptionsCheckBox} from './AuditSettingsOptionsCheckBox';

interface AuditSettingsOptionsProps {
  options: AuditSettingsOption[];
  isExpanded: boolean;
  onAllOptionsUnchecked: () => void;
}

export const AuditSettingsOptions: FC<AuditSettingsOptionsProps> = ({
  options,
  isExpanded,
  onAllOptionsUnchecked,
}) => {
  const {getConfirmation} = useConfirmationDialog();
  const {
    form: {
      mutators: {setFieldValue},
      getState,
    },
    Subscribe,
  } = useFormRenderer<AuditStructureSettingsForm>();

  const [selectAllStates, setSelectAllStates] = useState<SelectAllStates>(() => ({
    visible: getSelectAllStateByType(getState, options)(VISIBLE_TYPE),
    mandatory: getSelectAllStateByType(getState, options)(MANDATORY_TYPE),
  }));

  const setSelectAllState = (type: AuditSettingsOptionType, state: SelectAllState) => {
    setSelectAllStates({
      ...selectAllStates,
      [type]: state,
    });
  };

  const handleOptionChange = (
    isChecked: boolean,
    type: AuditSettingsOptionType,
    children: AuditSettingsOptionChildren[]
  ) => {
    const isVisibleType = type === VISIBLE_TYPE;

    if (children.length && isVisibleType) {
      for (const child of children) {
        selectOptions(child.options, isChecked, type, true);
        if (!isChecked && type === VISIBLE_TYPE) {
          selectOptions(child.options, false, MANDATORY_TYPE, true);
        }
      }
    }

    if (isVisibleType) {
      const visibleState = getSelectAllStateByType(getState, options)(VISIBLE_TYPE);
      const mandatoryState = getSelectAllStateByType(getState, options)(MANDATORY_TYPE);

      setSelectAllStates({
        [VISIBLE_TYPE]: visibleState,
        [MANDATORY_TYPE]: mandatoryState,
      });

      if (!visibleState.isIndeterminate && !visibleState.isChecked) {
        onAllOptionsUnchecked();
      }
    }

    if (type === MANDATORY_TYPE) {
      setSelectAllState(type, getSelectAllStateByType(getState, options)(type));
    }
  };

  const selectOptions = (
    options: AuditSettingsOption[],
    value: boolean,
    type: AuditSettingsOptionType,
    isForce?: boolean,
    isResetAll?: boolean
  ) => {
    const formValues = getState()?.values;

    for (const {paramDefinitionId, children} of options) {
      const optionValue = formValues?.paramDefinitions?.[paramDefinitionId];
      const visibleOptionValue = optionValue?.visible;
      const mandatoryOptionValue = optionValue?.mandatory;
      const isOptionDisabled = type === MANDATORY_TYPE && visibleOptionValue === false;
      const hasUpdate = (() => {
        if (!isForce && type === VISIBLE_TYPE && typeof visibleOptionValue !== 'boolean') {
          return false;
        }

        return value ? !isOptionDisabled : true;
      })();

      if (hasUpdate) {
        setFieldValue(`paramDefinitions.${paramDefinitionId}.${type}`, value);
      }

      if (isResetAll && mandatoryOptionValue === true) {
        setFieldValue(`paramDefinitions.${paramDefinitionId}.${MANDATORY_TYPE}`, false);
      }

      if (!children.length) {
        continue;
      }

      for (const child of children) {
        selectOptions(child.options, value, type, isForce, isResetAll);
      }
    }
  };

  const getSelectAllProps = useCallback(
    (type: AuditSettingsOptionType): CheckboxProps => ({
      onChange: async () => {
        const state = selectAllStates[type];
        const isChecked = !state.isChecked;

        if (!isChecked) {
          await getConfirmation();
        }

        if (isChecked && type === MANDATORY_TYPE) {
          selectOptions(options, isChecked, VISIBLE_TYPE, true);
        }
        selectOptions(options, isChecked, type);
        setSelectAllState(type, getSelectAllStateByType(getState, options)(type));

        if (!isChecked && type === VISIBLE_TYPE) {
          onAllOptionsUnchecked();
        }
      },
      value: selectAllStates[type].isChecked,
      isIndeterminate: selectAllStates[type].isIndeterminate,
      isDisabled:
        type === MANDATORY_TYPE &&
        !selectAllStates[VISIBLE_TYPE].isChecked &&
        !selectAllStates[VISIBLE_TYPE].isIndeterminate,
    }),
    [selectAllStates]
  );

  const renderOption = (
    {label, paramDefinitionId, children}: AuditSettingsOption,
    type: AuditSettingsOptionType,
    isDisabled: boolean
  ): ReactElement => (
    <AuditSettingsOptionsCheckBox
      type={type}
      label={label}
      paramDefinitionId={paramDefinitionId}
      hasChildren={Boolean(children.length)}
      isDisabled={isDisabled}
      onChange={(isChecked) => handleOptionChange(isChecked, type, children)}
    />
  );

  const renderOptions = (
    option: AuditSettingsOption,
    parentValue?: StructureSettingsItem,
    isChild?: boolean
  ): ReactElement => {
    const spacer = isChild ? 8 : 4;

    return (
      <>
        <Box paddingLeft={spacer}>
          {renderOption(option, VISIBLE_TYPE, parentValue?.visible === false)}
        </Box>
        <Box paddingLeft={spacer}>
          {renderOption(
            {
              ...option,
              label: i18n.t`page.vehicleSettings.labels.mandatory`,
            },
            MANDATORY_TYPE,
            parentValue?.mandatory !== true
          )}
        </Box>
      </>
    );
  };

  const renderChildOptions = (options: AuditSettingsOption[], parentId: string): ReactElement => (
    <Subscribe
      name={`paramDefinitions.${parentId}`}
      component={({input}) => (
        <Grid columns={2}>{options.map((option) => renderOptions(option, input.value, true))}</Grid>
      )}
    />
  );

  return (
    <Grid columns={2}>
      <>
        {!(options.length === 1 && !options?.[0]?.children?.length) && (
          <>
            <Checkbox
              label={i18n.t('general.actions.selectAll')}
              {...getSelectAllProps(VISIBLE_TYPE)}
              data-testid="auditSettings_SelectAllVisible"
            />
            <Checkbox
              label={i18n.t('general.actions.allMandatory')}
              {...getSelectAllProps(MANDATORY_TYPE)}
              data-testid="auditSettings_SelectAllMandatory"
            />
          </>
        )}
        {options.map((option) => (
          <>
            {renderOptions(option)}
            {!!option.children?.length && (
              <GridItem span={2}>
                {option.children.map(({label, options}, index) => {
                  if (!label) {
                    return renderChildOptions(options, option.paramDefinitionId);
                  }

                  return (
                    <Box
                      paddingBottom={option.children.length - 1 === index ? 0 : 4}
                      key={option.label}
                    >
                      <Box paddingLeft={8}>
                        <Text size="small" alternative>
                          {label}
                        </Text>
                      </Box>
                      <Box paddingTop={2}>
                        {renderChildOptions(options, option.paramDefinitionId)}
                      </Box>
                    </Box>
                  );
                })}
              </GridItem>
            )}
          </>
        ))}
      </>
    </Grid>
  );
};
