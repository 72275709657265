import i18n from 'i18next';
import {Button, Dropdown, DropdownDivider, DropdownGroup, DropdownItem} from 'platform/components';

import {useSelector} from 'react-redux';

import {
  GetAuditDataApiResponse,
  useGetAutoFillTemplatesQuery,
  useLazyGetAutoFillTemplateQuery,
} from '@dms/api';
import {testIds} from '@dms/routes';
import {handleApiError, usePermissions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {updateAudit} from '../../../store/carAudit/actions';
import {getAuditDataFromAutoFillTemplate} from '../../../store/carAudit/reducerUtils';
import {selectAudit} from '../../../store/carAudit/selectors';
import {InspectionIdForAuditId} from '../../../types/InspectionIdForAuditId';
import {getUpdateRequestBodyFromFormData} from '../../../utils/getUpdateRequestBodyFromFormData';
import {useConditionContext} from '../hooks/useConditionContext';
import {AutoFillTemplatesActions} from './AutoFillTemplatesActions';
import {ScrollAreaWrapper} from './NewInspectionButton';

interface AutoFillTemplatesProps extends TestIdProps, Omit<InspectionIdForAuditId, 'auditId'> {
  inspectionId?: string | null;
  vehicleId?: string | null;
}

export function AutoFillTemplates(props: AutoFillTemplatesProps) {
  const dispatch = useThunkDispatch();

  const audit = useSelector(selectAudit);

  const {data: autoFillTemplates} = useGetAutoFillTemplatesQuery();
  const [getAutoFillTemplateData] = useLazyGetAutoFillTemplateQuery();
  const [hasUpdateInspectionPermission] = usePermissions({
    permissionKeys: ['updateInspection'],
    scopes: {
      updateInspection: {inspectionType: audit?.inspectionType},
    },
  });
  const {loadActualData} = useConditionContext();

  const handleChooseAutoFillTemplate = async (templateId: string) => {
    if (!audit) {
      return;
    }

    await getAutoFillTemplateData({
      inspectionId: audit.id,
      autoFillTemplateId: templateId,
    })
      .unwrap()
      .then((templateData) => loadAutoFillTemplateData(templateData))
      .catch(handleApiError);
  };

  const loadAutoFillTemplateData = async (templateData: GetAuditDataApiResponse) => {
    if (!audit) {
      return;
    }

    const mappedAuditFields = getAuditDataFromAutoFillTemplate(audit, templateData);

    if (mappedAuditFields) {
      await dispatch(
        updateAudit.action({
          auditId: audit.id,
          requestBody: getUpdateRequestBodyFromFormData(mappedAuditFields.fields),
        })
      );
    }

    await loadActualData(audit.id).catch((error) => handleApiError(error.response));
  };

  if (!hasUpdateInspectionPermission) {
    return null;
  }

  return (
    <Dropdown
      data-testid={testIds.vehicles.inspectionDetail('newAutoFillTemplate')}
      placement="bottom-end"
      dropdownControl={
        <Button
          variant="outlined"
          rightIcon="hardware/keyboard_arrow_down"
          data-testid={suffixTestId('templates', props)}
          title={i18n.t('general.actions.autofillTemplates.dropDown.label')}
        />
      }
    >
      <ScrollAreaWrapper data-testid={testIds.vehicles.inspectionDetail('scrollAreaWrapper')}>
        {autoFillTemplates && autoFillTemplates.length > 0 && (
          <>
            <DropdownGroup label={i18n.t('entity.vehicle.labels.fillFromTemplate')}>
              {autoFillTemplates?.map((template, index) => (
                <DropdownItem
                  key={`$template-${template.id}`}
                  data-testid={`autoFillTemplate-dropdownItem-[${index}]`}
                  label={template.inspectionName || ''}
                  onClick={() => handleChooseAutoFillTemplate(template.id)}
                />
              ))}
            </DropdownGroup>
            <DropdownDivider />
          </>
        )}
        <AutoFillTemplatesActions
          shouldUseAuditIdForUpdateTemplate={props.shouldUseAuditIdForUpdateTemplate ?? false}
          inspectionId={props.inspectionId}
          vehicleId={props.vehicleId}
          auditId={audit?.id}
        />
      </ScrollAreaWrapper>
    </Dropdown>
  );
}
