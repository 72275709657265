import {match} from 'ts-pattern';
import {array, object, string} from 'yup';

import {always, isNil} from 'ramda';

import {GetParticipationApiResponse, ScopeValues} from '@dms/api';

export const evaluateParticipation = (
  scopes: string[],
  evaluatorParams: ScopeValues | undefined,
  currentUserId: string | undefined
) => {
  if (isNil(currentUserId) || isNil(evaluatorParams) || !('participation' in evaluatorParams)) {
    return false;
  }

  const {assignees, author, owner} = validateParticipationEvaluatorParams(
    evaluatorParams.participation
  );

  return scopes.some((scope) =>
    match(scope)
      .with('AUTHOR', always(author.userId === currentUserId))
      .with('ASSIGNEE', always(assignees?.some(({userId}) => userId === currentUserId)))
      .with('OWNER', always(owner.userId === currentUserId))
      .otherwise(always(false))
  );
};

export const validateParticipationEvaluatorParams = (
  participationScope: GetParticipationApiResponse | undefined
) => participationParamsSchema.validateSync(participationScope);

const participationParamsSchema = object({
  assignees: array().of(
    object({
      type: string(),
      userId: string().nullable(),
      organizationalUnitId: string().nullable(),
    })
  ),
  owner: object({
    type: string(),
    userId: string().nullable(),
    organizationalUnitId: string().nullable(),
  }),
  author: object({
    type: string(),
    userId: string().nullable(),
  }),
});
