import {createContext, useContext} from 'react';

import {AuditData} from '../../../store/carAudit/reducerUtils';
import {TConditionFormRenderProps} from '../../../types/ConditionTypes';
import {RequiredParams} from '../utils/getRequiredFieldsFromCategories';

export type TConditionContext = {
  formRenderProps: TConditionFormRenderProps;
  requiredFormFields?: RequiredParams;
  isCreateVehicle: boolean;
  loadActualData: (auditId: string) => Promise<AuditData>;
  loadActualDataAndValidate: () => Promise<void>;
  handleChangeCategory: () => Promise<void>;
  hasPermissionsToEditInspectionData: () => boolean;
  isDisabledAuditEdit: (includeState?: boolean) => boolean;
  isInNotEditableState: boolean;
  isDisabledForUser: boolean;
};

export const ConditionContext = createContext<TConditionContext>({} as TConditionContext);

export const useConditionContext = (): TConditionContext => useContext(ConditionContext);
