import i18n from '@dms/i18n';

export const getPositionOptions = (position: 'top' | 'center' | 'bottom') => [
  {value: `${position}_left`, label: ''},
  {value: `${position}_center`, label: ''},
  {value: `${position}_right`, label: ''},
];

export const addToOptions = [
  {value: 'true', label: i18n.t('entity.watermark.labels.addToFirstPhoto')},
  {value: 'false', label: i18n.t('entity.watermark.labels.addToAllPhotos')},
];
