import {Button, ButtonGroup, Form, FormButton, FormSubmitHandler} from 'platform/components';
import {Box, Space, Text, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useGrantPermissionMutation, useUpdateProtectedUnitPermissionMutation} from '@dms/api';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId, yupString} from 'shared';

import {getFixedTranslation} from '../../../utils/getFixedTranslation';
import {ProtectedUnitWithPermission, TableDataType} from '../hooks/useGetACLData';
import {EMPTY_SCOPE} from '../utils/consts';
import {FormValues, ScopeList} from './ScopeList';

interface ScopeFormProps extends TestIdProps {
  protectedUnit: ProtectedUnitWithPermission;
  roleId: string;
  resource: TableDataType;
  closeScopeForm: () => void;
}

export function ScopeForm(props: ScopeFormProps) {
  const isUpdating = isNotNil(props.protectedUnit.permission);

  const [updateScope] = useUpdateProtectedUnitPermissionMutation();
  const [grantPermissionWithScope] = useGrantPermissionMutation();

  const grantWithScope: FormSubmitHandler<FormValues> = async (data) => {
    const scopes = data.scopes.map((scope) => ({id: scope.scopeId, values: scope.optionIds}));

    const submitAction = isUpdating
      ? updateScope({
          permissionId: props.protectedUnit.permission!.id,
          updateActionPermissionRequestBody: {
            scopes,
          },
        })
      : grantPermissionWithScope({
          grantPermissionRequestBody: {
            protectedUnitId: props.protectedUnit.id,
            resourceId: props.resource.id,
            scopes,
            roleId: props.roleId,
          },
        });

    await submitAction.unwrap().then(props.closeScopeForm).catch(handleApiError);
  };

  const roleAndActionTitle = ` ${props.resource.name} / ${props.protectedUnit.name}`;
  const scopes = props.protectedUnit.permission?.scopes;
  const defaultValues = isNotNilOrEmpty(scopes)
    ? {
        scopes: scopes?.map((scope) => ({
          scopeId: scope.id,
          optionIds: scope.values.map((item) => item.id),
        })),
      }
    : {scopes: [EMPTY_SCOPE]};

  return (
    <Box width="100%">
      <Form<FormValues> schema={schema} onSubmit={grantWithScope} defaultValues={defaultValues}>
        {(control, formApi) => (
          <>
            <VStack spacing={4}>
              <Box>
                <Text size="small" inline>
                  {getFixedTranslation('acl.dialog.grantAccessFor')}
                </Text>
                <Text size="small" inline alternative>
                  {roleAndActionTitle}
                </Text>
              </Box>

              <ScopeList
                control={control}
                formApi={formApi}
                protectedUnit={props.protectedUnit}
                resourceId={props.resource.id}
              />
            </VStack>
            <Space vertical={4} />
            <ButtonGroup align="right">
              <Button
                title={getFixedTranslation('general.actions.cancel')}
                variant="secondary"
                onClick={props.closeScopeForm}
                data-testid={suffixTestId('cancel', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={getFixedTranslation('acl.dialog.button.submit')}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </Box>
  );
}

const schema = object({
  scopes: array()
    .of(
      object({
        scopeId: yupString.required(),
        optionIds: array()
          .of(yupString)
          .min(1, getFixedTranslation('general.validations.fieldIsRequired'))
          .required(),
      })
    )
    .default([]),
});
