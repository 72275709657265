import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {CorrectiveTaxDocumentForm} from './components/CorrectiveTaxDocumentForm';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.correctiveTaxDocumentSetting.labels.list'),
    href: settingsRoutes.correctiveTaxDocuments,
  },
];

export const CorrectiveTaxDocumentNew = () => {
  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.correctiveTaxDocumentSetting.labels.new'), breadcrumbs}}
      data-testid="settings-corrective-tax-document-edit-page"
    >
      <CorrectiveTaxDocumentForm />
    </SettingsTemplate>
  );
};
