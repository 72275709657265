// eslint-disable-next-line no-restricted-imports -- We need to use path here
import {path} from 'ramda';
import {isNotObject} from 'ramda-adjunct';

import {ApiError, isFetchBaseQueryError} from 'shared';

type ValidationError = {
  field: string;
  message: string;
};

/**
 * @about Parses caught error from RTK Query
 * @returns Validation errors for RHF form
 */
export function getApiValidationErrors(error: ApiError) {
  if (!isFetchBaseQueryError(error) || isNotObject(error?.data)) {
    return null;
  }

  const errors = path<ValidationError[]>(['data', 'validationErrors'])(error);

  if (!errors?.length) {
    return null;
  }

  return errors.map((error: ValidationError) => ({
    name: error.field,
    message: error.message,
  }));
}
