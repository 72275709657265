import {Form, FormField, FormSubmitHandler, Label, showNotification} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {usePostProformaGenerationMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useBranches} from '@dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  unify: boolean;
};

export function ProformaInvoiceGenerationNew() {
  const navigate = useNavigate();
  const [createProformaGeneration] = usePostProformaGenerationMutation();

  const {branchOptions, isLoading, isError} = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) =>
    await createProformaGeneration(data)
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.proformaGeneration))
      .catch(handleApiError);

  const defaultValues = {unify: false};

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accounting.labels.newDefinition'),
      }}
      data-testid={testIds.settings.proformaGeneration('page')}
      isLoading={isLoading}
      isError={isError}
      isCreating
    >
      <Form<FormValues> onSubmit={handleSubmit} defaultValues={defaultValues}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  data-testid={testIds.settings.proformaGenerationNew('branch')}
                  isRequired
                />
              </Grid>
            </SettingsSection>
            <VStack spacing={1}>
              <Label>{i18n.t('page.accountingSettings.labels.generationType')}</Label>
              <FormField
                control={control}
                type="checkbox"
                data-testid={testIds.settings.proformaGenerationNew('unify')}
                name="unify"
                label={i18n.t('page.accountingSettings.labels.unify')}
              />
            </VStack>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  onClick: () => navigate(settingsRoutes.proformaGeneration),
                  variant: 'secondary',
                  'data-testid': testIds.settings.proformaGenerationNew('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.create'),
                  'data-testid': testIds.settings.proformaGenerationNew('create'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}
