import {i18n} from '@dms/i18n';

export const contextOptions = [
  {
    value: 'customer',
    label: i18n.t('page.messageTemplates.labels.contextOptions.customer'),
  },
  {
    value: 'service-order',
    label: i18n.t('page.messageTemplates.labels.contextOptions.serviceOrder'),
  },
];
