import {FormControl, Separator} from 'platform/components';
import {Grid, Heading, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {Fragment} from 'react';
import {useWatch} from 'react-hook-form';

import {defaultTo, filter, indexBy, isNil, map, pipe} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {MoneyResponseBody} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {LIST_ITEM_FIELD_NAME} from '../constants';
import {useInvoiceVatRatesContext} from '../context/useInvoiceVatRatesContext';
import {AccountingDocumentFormValues, VatTypeSummary} from '../types';
import {typedToPairs} from './utils';

interface AdditionalVatCalculationProps extends TestIdProps {
  vatTypeSummary: VatTypeSummary | Nullish;
  control: FormControl<AccountingDocumentFormValues>;
}

export function VatSummary(props: AdditionalVatCalculationProps) {
  const formatCurrency = useFormatCurrency();
  const {rateOptions} = useInvoiceVatRatesContext();
  const invoiceItems = useWatch({control: props.control, name: LIST_ITEM_FIELD_NAME});

  const formatPrice = (money: MoneyResponseBody | Nullish) =>
    formatCurrency(parseFloat(money?.amount ?? ''), money?.currency ?? '', 2);

  if (isNil(props.vatTypeSummary)) {
    return null;
  }

  const vatRatesByValue = indexBy((item) => item.value, rateOptions);

  const vatSummaryCalculation = pipe(
    defaultTo({}),
    typedToPairs,
    filter(([_, item]) => isNotNil(item.groupSummary)),
    map(([key, item]) => ({
      ...item,
      vatType: key,
      vatLabel: vatRatesByValue?.[key]?.label,
    }))
  )(props.vatTypeSummary);

  const isVatRecapitulationShown =
    isNil(invoiceItems) ||
    invoiceItems?.filter(
      (item) => isNotNilOrEmpty(item.quantity) && isNotNilOrEmpty(item.pricePerUnit)
    )?.length >= 1;

  return (
    <Show when={isNotNilOrEmpty(vatSummaryCalculation) && isVatRecapitulationShown}>
      <Heading size={4}>{i18n.t('page.accounting.labels.vatRecapitulation')}</Heading>
      <VStack spacing={2}>
        <Grid columns={4} key="labels-calculation" align="flex-end">
          <Text size="xSmall" color="tertiary">
            {i18n.t('general.labels.vatRate')}
          </Text>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.lineItems.labels.totalPriceWithoutVat')}
          </Text>
          <Text size="xSmall" color="tertiary">
            {i18n.t('general.labels.vat')}
          </Text>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.lineItems.labels.totalPriceWithVat')}
          </Text>
        </Grid>
        {vatSummaryCalculation.map((calc) => (
          <Fragment key={`${calc.vatType}-calculation`}>
            <Separator spacing={0} />
            <Grid columns={4}>
              <Text size="xSmall" color="secondary" key={`${calc}-calculation`}>
                {calc.vatLabel}
              </Text>
              <Text
                size="xSmall"
                color="secondary"
                data-testid={suffixTestId('priceWithoutVat', props)}
              >
                {formatPrice(calc.groupSummary?.priceWithoutVat)}
              </Text>
              <Text size="xSmall" color="secondary" data-testid={suffixTestId('vat', props)}>
                {formatPrice(calc.groupSummary?.vat)}
              </Text>
              <Text
                size="xSmall"
                color="secondary"
                data-testid={suffixTestId('priceWithVat', props)}
              >
                {formatPrice(calc.groupSummary?.priceWithVat)}
              </Text>
            </Grid>
          </Fragment>
        ))}
      </VStack>
    </Show>
  );
}
