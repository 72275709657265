import {Parameter} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {useGetUserQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish, buildArray, parseDate} from 'shared';

import {getUserName} from '../../../utils/getUserName';
import {PostponeJobType} from '../types/PostponeJobType';

export function useSummaryParams(postponeJobData: PostponeJobType | Nullish) {
  const formatDateTime = useDateTimeFormatter();

  const userQuery = useGetUserQuery(
    {id: postponeJobData?.postponedBy || ''},
    {skip: !postponeJobData?.postponedBy}
  );

  return buildArray<Parameter>()
    .when(postponeJobData?.postponedAt, () =>
      i18n.t('entity.postponedJobs.label.postponedAt', {
        date: formatDateTime('dateShort', parseDate(postponeJobData?.postponedAt)),
      })
    )
    .when(postponeJobData?.postponedUntil, () =>
      i18n.t('entity.postponedJobs.label.postponedUntil', {
        date: formatDateTime('dateShort', parseDate(postponeJobData?.postponedUntil)),
      })
    )
    .when(
      postponeJobData?.postponeReason,
      i18n.t('entity.postponedJobs.label.postponeReason', {
        note: postponeJobData?.postponeReason,
      })
    )
    .when(postponeJobData?.postponedBy && userQuery.data, () =>
      i18n.t('entity.postponedJobs.label.postponedBy', {
        name: getUserName(userQuery.data),
      })
    );
}
