import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CardTagSelection, CardTagSelectionTagType, VehicleCatalogueFeature} from '@dms/teas';

interface HighlightedFeaturesCardProps {
  isLoading?: boolean;
  featureItems: Array<VehicleCatalogueFeature & {isPremium?: boolean}>;
  highlightedFeatures: string[];
  onHighlightOn: (keys: string[]) => void;
  onHighlightOff: (key: string) => void;
}

export function HighlightedFeaturesCard({
  featureItems = [],
  highlightedFeatures,
  onHighlightOn,
  onHighlightOff,
}: HighlightedFeaturesCardProps) {
  const removeFeatureHighlight = (key: string) => {
    onHighlightOff(key);
  };

  const addFeatureHighlight = (tags: CardTagSelectionTagType[]) => {
    onHighlightOn(tags.map((item) => item.id));
  };

  const highlightedItems = featureItems
    .filter((item) => (item.key ? highlightedFeatures.includes(item.key) : false))
    .map(
      (item) =>
        ({
          id: item.key,
          label: item.isPremium ? `★ ${item.name}` : item.name,
        }) as CardTagSelectionTagType
    )
    .sort(
      (a, b) =>
        highlightedFeatures.indexOf(a.id as string) - highlightedFeatures.indexOf(b.id as string)
    );

  return (
    <CardTagSelection
      tags={highlightedItems}
      predefinedTags={featureItems.map(
        (i) =>
          ({
            id: i.key,
            label: i.name,
          }) as CardTagSelectionTagType
      )}
      onDelete={(tag) => removeFeatureHighlight(tag.id)}
      onCreate={addFeatureHighlight}
      data-testid={testIds.vehicles.detail('topHighlightedFeatures')}
      title={i18n.t('entity.vehicle.labels.highlightedFeatures')}
      editDialogTitle={i18n.t('entity.vehicle.labels.highlightedFeatures')}
      emptyMessage={i18n.t('entity.vehicle.labels.highlightedFeaturesPlaceholder')}
      tagsColorScheme="blue"
      isCreationDisabled
    />
  );
}
