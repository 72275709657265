import {
  Button,
  ButtonGroup,
  closeDialog,
  Form,
  Label,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';

import {usePostStorageLocationGenerateLocationApiMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {LocationRow, MultipleLocationsForm, VectorType} from '../types/MultipleLocationsForm';
import {locationRowFactory} from '../utils/locationRowFactory';
import {multipleLocationsSchema} from '../utils/multipleLocationsSchema';
import {LocationExample} from './LocationExample';
import {VectorsArray} from './VectorsArray';

export interface CreateMultipleLocationsFormProps {
  tireWarehouseId: string;
  rowDefinition?: LocationRow[] | Nullish;
}

export function CreateMultipleLocationsForm(props: CreateMultipleLocationsFormProps) {
  const [generateMultipleLocations, queryStatus] =
    usePostStorageLocationGenerateLocationApiMutation();

  const handleCloseDialog = () => {
    closeDialog('create-multiple-locations-dialog');
  };

  const handleSubmit = async (formValues: MultipleLocationsForm) => {
    const saneDefinition = formValues.definition.map((item) =>
      item.beginAt ? item : {...item, beginAt: ''}
    );

    await generateMultipleLocations({
      contextTarget: 'TIRE-WAREHOUSE',
      contextId: props.tireWarehouseId,
      body: saneDefinition,
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('storage-location-tire-warehouse');
        handleCloseDialog();
      })
      .catch(handleApiError);
  };

  const getDefaultValue = (): MultipleLocationsForm => {
    if (props.rowDefinition?.length) {
      return {
        definition: props.rowDefinition,
      };
    }

    return {
      definition: [
        locationRowFactory({vectorType: VectorType.Aisle, vectorPosition: 1}),
        locationRowFactory({vectorType: VectorType.Rack, vectorPosition: 2}),
        locationRowFactory({vectorType: VectorType.Level, vectorPosition: 3}),
        locationRowFactory({vectorType: VectorType.Position, vectorPosition: 4}),
        locationRowFactory({vectorType: VectorType.Other, vectorPosition: 5}),
      ],
    };
  };

  return (
    <Form defaultValues={getDefaultValue()} schema={multipleLocationsSchema} mode="onChange">
      {(control, formApi) => (
        <VStack spacing={4}>
          <Text color="tertiary" size="small">
            {i18n.t('entity.tireWarehouses.labels.createMultipleLocation.description')}
          </Text>
          <LocationExample tireWarehouseId={props.tireWarehouseId} control={control} />
          <Separator spacing={0} />
          <VStack spacing={1}>
            <HStack spacing={4}>
              <Box width={5} />
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.vector.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.vector')}
                </Label>
              </Box>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.number.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.number')}
                </Label>
              </Box>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.symbolType.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.symbolType')}
                </Label>
              </Box>
              <Box flex={1}>
                <Label tooltip={i18n.t('entity.tireWarehouses.labels.beginAt.tooltip')}>
                  {i18n.t('entity.tireWarehouses.labels.beginAt')}
                </Label>
              </Box>
            </HStack>
            <VectorsArray control={control} formApi={formApi} />
          </VStack>
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.labels.discard')}
              variant="secondary"
              isDisabled={queryStatus.isLoading}
              onClick={handleCloseDialog}
            />
            <Button
              title={i18n.t('general.actions.create')}
              onClick={() => formApi.handleSubmit(handleSubmit)()}
              isLoading={queryStatus.isLoading}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
