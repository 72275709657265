import {Chips, Separator} from 'platform/components';
import {Hide, VStack} from 'platform/foundation';

import {head, isNil, map, reject} from 'ramda';
import {isNilOrEmpty, isNotNil, isTrue} from 'ramda-adjunct';

import {CheckoutCustomers} from '@dms/api';

import {Nullish, suffixTestId, RequiredTestIdProps} from 'shared';

import {CheckoutBillingInformationList} from './CheckoutBillingInformationList';

interface CheckoutBillingInformationProps extends RequiredTestIdProps {
  customers: CheckoutCustomers | Nullish;
  customerTyp: string | Nullish;
  customerId: string | Nullish;
  customerContractId: string | Nullish;
  hasInvoice?: boolean | Nullish;
  getChipLabelByType: (type: string | Nullish) => string | null;
  onCustomerTypChange: (type: string) => void;
  onOtherCustomerSelect: (otherCustomerId: string) => void;
  onOtherCustomerRemove: () => void;
  onCustomerContractChange: (customerContractId: string) => void;
  hasVerification?: boolean;
  resourceId?: string;
  recordId?: string;
}

export function CheckoutBillingInformation(props: CheckoutBillingInformationProps) {
  const customers = reject(isNil, props.customers ?? []);

  const chipsOptions = map(
    (e) => ({label: props.getChipLabelByType(e?.type) ?? '', value: e?.type ?? ''}),
    customers
  );

  const handleCustomerTypeChange = (value: string[] | Nullish) => {
    const type = head(value ?? []);

    if (isNotNil(type)) {
      props.onCustomerTypChange(type);
    }
  };

  return (
    <Hide when={isNilOrEmpty(customers)}>
      <VStack>
        <Chips
          isDisabled={isTrue(props.hasInvoice)}
          options={chipsOptions}
          value={props.customerTyp ? [props.customerTyp] : undefined}
          onChange={handleCustomerTypeChange}
          data-testid={suffixTestId('customers', props)}
        />
        <Separator />
        <CheckoutBillingInformationList
          customerId={props.customerId}
          contractId={props.customerContractId}
          isOtherCustomer={props.customerTyp === 'OTHER'}
          onCustomerSelect={props.onOtherCustomerSelect}
          onCustomerRemove={props.onOtherCustomerRemove}
          onCustomerContractSelect={props.onCustomerContractChange}
          isReadOnly={isTrue(props.hasInvoice)}
          hasVerification={props.hasVerification}
          resourceId={props.resourceId}
          recordId={props.recordId}
          data-testid={suffixTestId('billing', props)}
        />
      </VStack>
    </Hide>
  );
}
