import {Card, openConfirmDialog, openDialog, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {equals} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {UpdateInterestApiArg, UpdateInterestRequestBody, useUpdateInterestMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {interestsRoutes, testIds} from '@dms/routes';
import {Section, handleApiError, useCustomerUrl} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {InterestCloseForm} from './components/InterestCloseForm';

export function Interests() {
  const navigate = useNavigate();

  const {customerId} = useCustomerUrl();

  const [updateInterest, {isLoading}] = useUpdateInterestMutation();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      customer: [customerId],
    }),
    [customerId]
  );

  const handleUpdateInterest = async (interestId: string, values: UpdateInterestRequestBody) => {
    const args: UpdateInterestApiArg = {
      id: interestId,
      updateInterestRequestBody: {
        customerId,
        state: values?.state ?? null,
        reasonCloseNote: values?.reasonCloseNote ?? null,
        reasonCloseCodeId: values?.reasonCloseCodeId ?? null,
      },
    };

    return await updateInterest(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.success'));
      })
      .catch(handleApiError);
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .when(equals('detail'), () =>
        navigate(composePath(interestsRoutes.wildcard, {params: {id, '*': ''}}))
      )
      .when(equals('close'), () =>
        openDialog(
          <InterestCloseForm
            data-testid={testIds.customer.detail('interests')}
            isLoading={isLoading}
            onSubmit={(values) => handleUpdateInterest(id, values)}
          />,
          {}
        )
      )
      .when(equals('end'), () =>
        openConfirmDialog({
          onConfirm: () => handleUpdateInterest(id, {state: 'CLOSED'}).then(refreshData),
        })
      )
      .otherwise(() =>
        openConfirmDialog({
          onConfirm: () => handleUpdateInterest(id, {state: 'OPEN'}).then(refreshData),
        })
      );
  };

  return (
    <Section>
      <Card title={i18n.t('page.interests.title')}>
        <Box height={100}>
          <DataGrid
            data-testid={testIds.customer.detail('interests')}
            gridCode="customer-interests-list"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
          />
        </Box>
      </Card>
    </Section>
  );
}
