import {Card, DataStatus, Form, FormButton, FormField, showNotification} from 'platform/components';
import {Grid, Right, Space, Text} from 'platform/foundation';
import {object} from 'yup';

import {
  UpdateClientDataRequestBody,
  useGetTipCarsClientDataQuery,
  useUpdateTipCarsClientDataMutation,
} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {AdvertisingPlatformCodeEnum} from '@dms/teas';

import {yupString} from 'shared';

import {useBranchId} from '../../../../hooks/useBranchId';

export function PlatformTipCarsInfo() {
  const branchId = useBranchId();
  const params = {branchId};
  const {data, isLoading, isError} = useGetTipCarsClientDataQuery({params});
  const [updateData] = useUpdateTipCarsClientDataMutation();

  const onSubmit = (body: UpdateClientDataRequestBody) =>
    updateData({body, params})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Card
      data-testid={testIds.settings.advertisingPlatforms(
        `card-${AdvertisingPlatformCodeEnum.TIPCARS}-tenantInfo`
      )}
      title={i18n.t('page.settings.labels.tenantInfo')}
    >
      <Text size="small" color="secondary">
        {i18n.t('page.settings.labels.tenantInfoDescription')}
      </Text>
      <Space vertical={4} />

      <DataStatus isLoading={isLoading} isError={isError}>
        <Form defaultValues={data} schema={schema} onSubmit={onSubmit}>
          {(control) => (
            <>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('entity.customer.labels.companyName')}
                  name="companyName"
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('entity.address.labels.street')}
                  name="address"
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('entity.address.labels.postalCode')}
                  name="postalCode"
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('entity.address.labels.city')}
                  name="city"
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('general.labels.phone')}
                  name="phone"
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('general.labels.fax')}
                  name="fax"
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('general.labels.email')}
                  name="email"
                  isRequired
                />
                <FormField
                  control={control}
                  type="text"
                  label={i18n.t('general.labels.website')}
                  name="website"
                />
              </Grid>

              <Space vertical={4} />
              <FormField
                control={control}
                type="textarea"
                label={i18n.t('general.labels.note')}
                name="note"
                rows={11}
              />

              <Space vertical={4} />
              <Right>
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                />
              </Right>
            </>
          )}
        </Form>
      </DataStatus>
    </Card>
  );
}

const schema = object().shape({
  email: yupString
    .required(i18n.t('general.notifications.errorFieldRequired'))
    .email(i18n.t('general.notifications.errorEmailInvalid'))
    .max(80),
  companyName: yupString.max(50),
  address: yupString.max(32),
  postalCode: yupString.max(5),
  city: yupString.max(32),
  phone: yupString.max(32),
  fax: yupString.max(24),
  website: yupString.max(100),
  note: yupString.max(480),
});
