import {Grid, Heading} from 'platform/foundation';
import {css} from 'styled-components';

import {ReactElement} from 'react';

import i18n from '@dms/i18n';

import {CreateVehicleRequestBody} from '../../../types/CreateVehicleRequestBody';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';

export const Warranty = (): ReactElement => {
  const {Field} = useFormRenderer<CreateVehicleRequestBody>();

  return (
    <Grid columns={1}>
      <Heading size={4}>{i18n.t('entity.vehicle.labels.warrantyAndValidity')}</Heading>
      <div
        css={css`
          margin-bottom: -16px;
        `}
      >
        <Grid columns={4}>
          <Field
            as="date"
            name="modelSpecification.warrantyDate"
            label={i18n.t('entity.vehicle.labels.warrantyUntil')}
          />
          <Field
            as="integer"
            name="modelSpecification.warrantyMileage"
            label={i18n.t('entity.vehicle.labels.warrantyMileageLimit')}
            suffix={i18n.t('general.metric.km')}
          />
          <Field
            as="date"
            name="modelSpecification.serviceContractUntil"
            label={i18n.t('entity.vehicle.labels.serviceContractUntil')}
          />
          <Field
            as="integer"
            name="modelSpecification.serviceContractMileageLimit"
            label={i18n.t('entity.vehicle.labels.serviceContractMileageLimit')}
            suffix={i18n.t('general.metric.km')}
          />
        </Grid>
        <Field
          name="modelSpecification.serviceContractNote"
          label={i18n.t('entity.vehicle.labels.serviceContractNote')}
          multiline
        />
      </div>
    </Grid>
  );
};
