import {
  Card,
  DataStatus,
  Flag,
  Form,
  FormField,
  FormSubmitHandler,
  Label,
  showNotification,
} from 'platform/components';
import {GridItem, Box, Grid, Text} from 'platform/foundation';
import {useFormatCurrency, useDateTimeFormatter} from 'platform/locale';
import {object} from 'yup';

import {useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';

import {useGetBranchQuery, useGetCashRegisterQuery, usePatchCashRegisterMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError} from '@dms/shared';

import {parseDate, Nullish, yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {useSeriesOptions} from '../../../hooks/useSeriesOptions';

type FormValues = {
  name: string;
  expenseSeriesId: string;
  incomeSeriesId: string;
  cashRegisterAccount: string | null;
  eligibleForCardPayments: boolean | null;
};

interface InUseCashRegisterEditProps {
  cashRegisterId: string | Nullish;
}

export function InUseCashRegisterEdit(props: InUseCashRegisterEditProps) {
  const navigate = useNavigate();
  const {groupedSeries, isSeriesError, isSeriesLoading} = useSeriesOptions([
    'accounting/cash_register_document',
  ]);
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const [patchCashRegister] = usePatchCashRegisterMutation();

  const {
    data: cashRegister,
    isLoading: isCashRegisterLoading,
    isError: isCashRegisterError,
  } = useGetCashRegisterQuery(
    {cashRegisterId: props.cashRegisterId ?? ''},
    {skip: isNil(props.cashRegisterId)}
  );
  const {data: branch, isLoading: isBranchLoading} = useGetBranchQuery(
    {branchId: cashRegister?.branchId ?? ''},
    {skip: isNil(cashRegister?.branchId)}
  );

  const isLoading = isCashRegisterLoading || isBranchLoading || isSeriesLoading;
  const isError = isCashRegisterError || isSeriesError;

  const goToCashRegisterList = () => navigate(settingsRoutes.cashRegisters);

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    if (isNil(props.cashRegisterId)) {
      showNotification.error();
      return;
    }

    await patchCashRegister({
      cashRegisterId: props.cashRegisterId,
      patchCashRegisterRequestBody: data,
    })
      .unwrap()
      .then(goToCashRegisterList)
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const defaultValues: Partial<FormValues> = {
    name: cashRegister?.name,
    expenseSeriesId: cashRegister?.expenseSeriesId,
    incomeSeriesId: cashRegister?.incomeSeriesId,
    eligibleForCardPayments: cashRegister?.eligibleForCardPayments,
    cashRegisterAccount: cashRegister?.cashRegisterAccount,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormValues> defaultValues={defaultValues} schema={schema} onSubmit={handleSubmit}>
        {(control, formApi) => {
          formApi.register('eligibleForCardPayments');
          const onChange = (val: boolean) => formApi.setValue('eligibleForCardPayments', val);
          const value = formApi.watch('eligibleForCardPayments') ?? false;

          return (
            <SettingsSection>
              <Grid columns={2}>
                <GridItem span={2}>
                  <FormField
                    control={control}
                    type="text"
                    name="name"
                    label={i18n.t('entity.cashRegister.parameters.description')}
                    isRequired
                  />
                </GridItem>
                <GridItem span={2}>
                  <FormField
                    control={control}
                    type="text"
                    name="cashRegisterAccount"
                    label={i18n.t('entity.bankAccount.labels.number')}
                    maxLength={9}
                    isRequired
                  />
                </GridItem>
                <FormField
                  control={control}
                  type="choice"
                  name="expenseSeriesId"
                  isRequired
                  isNotClearable
                  options={groupedSeries['accounting/cash_register_document']}
                  label={i18n.t('entity.cashRegister.parameters.expenseSeries')}
                />

                <FormField
                  control={control}
                  type="choice"
                  name="incomeSeriesId"
                  isRequired
                  isNotClearable
                  options={groupedSeries['accounting/cash_register_document']}
                  label={i18n.t('entity.cashRegister.parameters.incomeSeries')}
                />

                <GridItem span={2}>
                  <Card variant="inlineGrey">
                    <Grid columns={5} align="center">
                      <Box>
                        <Label>{i18n.t('entity.cashRegister.parameters.code')}</Label>
                      </Box>
                      <Box>
                        <Label>{i18n.t('entity.cashRegister.parameters.currency')}</Label>
                      </Box>
                      <Box>
                        <Label>{i18n.t('entity.cashRegister.parameters.currentState')}</Label>
                      </Box>
                      <Box>
                        <Label>{i18n.t('entity.cashRegister.parameters.asOf')}</Label>
                      </Box>
                      <Box>
                        <Label>{i18n.t('entity.cashRegister.parameters.branch')}</Label>
                      </Box>

                      {cashRegister && (
                        <>
                          <Text size="small">{cashRegister.code}</Text>
                          <Flag label={cashRegister.currency} colorScheme="blue" isSubtle />
                          <Text size="small">
                            {formatCurrency(
                              parseFloat(cashRegister.currentBalance.amount),
                              cashRegister.currentBalance.currency
                            )}
                          </Text>
                          <Text size="small">
                            {formatDateTime('dateShort', parseDate(cashRegister.activeFrom))}
                          </Text>
                          <Text size="small">{branch?.name ?? EMPTY_PLACEHOLDER}</Text>
                        </>
                      )}
                    </Grid>
                  </Card>
                </GridItem>

                <GridItem span={2}>
                  <Card
                    title={i18n.t('entity.cashRegister.parameters.eligibleForCardPayments')}
                    control={{type: 'switch', value, onChange}}
                    isExpanded={false}
                  />
                </GridItem>
                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      title: i18n.t('general.actions.close'),
                      variant: 'secondary',
                      onClick: goToCashRegisterList,
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      title: i18n.t('general.actions.edit'),
                    },
                  ]}
                />
              </Grid>
            </SettingsSection>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const schema = object({
  name: yupString.required(),
  cashRegisterAccount: yupString
    .matches(/^\d+$/, i18n.t('general.validations.mustBeNumber'))
    .min(6, i18n.t('general.validations.minimumLength', {codeMinLength: 6}))
    .max(9, i18n.t('general.validations.maximumLength', {codeMaxLength: 9})),
  incomeSeriesId: yupString.required(),
  expenseSeriesId: yupString.required(),
});
