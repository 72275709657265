import {parseISO} from 'date-fns';
import {Attributes, Button, Card, DataStatus, FormControl, openDialog} from 'platform/components';
import {HStack, Heading, Hide, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {UseFormReturn, useFieldArray} from 'react-hook-form';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {PostBillingInformationV2ApiArg} from '@dms/api';
import i18n from '@dms/i18n';
import {getUserName} from '@dms/shared';

import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {useGetPersonalIdTypes} from '../hooks/useGetPersonalIdTypes';
import {DeputyPersonForm} from './DeputyPersonForm';

interface DeputyPersonListProps {
  control: FormControl<PostBillingInformationV2ApiArg>;
  formApi: UseFormReturn<PostBillingInformationV2ApiArg>;
}

export function DeputyPersonList(props: DeputyPersonListProps) {
  const formatDateTime = useDateTimeFormatter();
  const {getTypeByCode} = useGetPersonalIdTypes();

  const {fields, prepend, remove, update} = useFieldArray({
    name: 'deputyPersons',
    control: props.control,
  });

  const areDeputyPersonsEmpty = isNilOrEmpty(fields);
  const handleAddDeputyPerson = () =>
    openDialog(<DeputyPersonForm handleSubmit={prepend} />, {
      scrollBehavior: 'outside',
      title: i18n.t('general.customer.addDeputyPerson'),
    });

  const handleUpdateDeputyPerson = (index: number) =>
    openDialog(
      <DeputyPersonForm handleSubmit={(val) => update(index, val)} defaultValues={fields[index]} />,
      {
        scrollBehavior: 'outside',
        title: i18n.t('general.customer.addDeputyPerson'),
      }
    );

  return (
    <>
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.person.labels.deputyPerson')}</Heading>
        <Hide when={areDeputyPersonsEmpty}>
          <Button
            title={i18n.t('general.customer.addDeputyPerson')}
            leftIcon="content/add_circle"
            variant="link"
            onClick={handleAddDeputyPerson}
          />
        </Hide>
      </HStack>
      <Space vertical={5} />
      <SettingsSection>
        <DataStatus
          isEmpty={areDeputyPersonsEmpty}
          spacing={5}
          action={{
            title: i18n.t('general.customer.addDeputyPerson'),
            onClick: handleAddDeputyPerson,
            leftIcon: 'content/add_circle',
          }}
        >
          <VStack spacing={2}>
            {fields.map((field, index) => (
              <Card
                title={getUserName(field) ?? i18n.t('entity.customer.labels.noDeputyPersonName')}
                key={field.id}
                isClosedByDefault
                isExpandable
                variant="inlineGrey"
                actions={[
                  {
                    type: 'dropdown-iconButton',
                    icon: 'navigation/more_vert',
                    menuItems: [
                      {
                        label: i18n.t('general.actions.edit'),
                        onClick: () => handleUpdateDeputyPerson(index),
                        leftIcon: 'image/edit',
                      },
                      {
                        label: i18n.t('general.actions.delete'),
                        onClick: () => remove(index),
                        leftIcon: 'action/delete',
                        severity: 'danger',
                      },
                    ],
                  },
                ]}
              >
                <Attributes
                  size="quarter"
                  rows={[
                    {
                      label: i18n.t('entity.person.labels.birthdate'),
                      value: isNotNil(field.dateOfBirth)
                        ? formatDateTime('dateMedium', parseISO(field.dateOfBirth))
                        : null,
                    },
                    {
                      label: i18n.t('entity.invoice.labels.registrationNumber'),
                      value: field.identificationNumber,
                    },
                    {
                      label: i18n.t('entity.person.labels.personalIdType'),
                      value: getTypeByCode(field.personalIdType)?.label,
                    },
                    {
                      label: i18n.t('entity.person.labels.idNumber'),
                      value: field.personalIdNumber,
                    },
                    {
                      label: i18n.t('general.labels.validUntil'),
                      value: isNotNil(field.validUntil)
                        ? formatDateTime('dateMedium', parseISO(field.validUntil))
                        : null,
                    },
                    {
                      label: i18n.t('general.labels.issuedBy'),
                      value: field.issuedBy,
                    },
                  ]}
                />
              </Card>
            ))}
          </VStack>
        </DataStatus>
      </SettingsSection>
    </>
  );
}
