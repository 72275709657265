import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';

import {find, head} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  FileResponseBody,
  TemplateListItemResponseBody,
  useGetTemplatesQuery,
  useLazyGetVehicleWarehouseMovementQuery,
  useListAvailableVehicleWarehousesForVehicleMovementQuery,
  useReceiveVehicleWarehouseMovementMutation,
} from '@dms/api';
import i18n from '@dms/i18n';

import {getApiDateString, suffixTestId, TestIdProps, yupString} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

interface VehicleWarehouseStockInProps extends TestIdProps {
  vehicleId: string;
  onDocumentCreation: (document: FileResponseBody) => void;
  onMovementComplete?: () => void;
}

interface VehicleWarehouseStockInFormFields {
  vehicleWarehouseId: string;
  movementAt: string;
  note: string | null;
}

const STOCK_IN_DOCUMENT_TEMPLATES_CODE = 'receive-vehicle-warehouse-movement';

export function VehicleWarehouseStockIn(props: VehicleWarehouseStockInProps) {
  const [isDocumentSelected, setIsDocumentSelected] = useState(true);
  const [documentTemplate, setDocumentTemplate] = useState<TemplateListItemResponseBody>();

  const {
    data: warehouses = [],
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useListAvailableVehicleWarehousesForVehicleMovementQuery({vehicleId: props.vehicleId});

  const {
    data: stockInTemplates,
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
  } = useGetTemplatesQuery({
    documentKindCode: STOCK_IN_DOCUMENT_TEMPLATES_CODE,
  });

  const [stockInVehicle] = useReceiveVehicleWarehouseMovementMutation();
  const [getMovement] = useLazyGetVehicleWarehouseMovementQuery();

  if (!documentTemplate && isNotNilOrEmpty(stockInTemplates)) {
    setDocumentTemplate(find((template) => template.primary, stockInTemplates ?? []));
  }

  const warehouseOptions = warehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.id,
  }));

  const onSubmit: FormSubmitHandler<VehicleWarehouseStockInFormFields> = (values) =>
    stockInVehicle({
      vehicleWarehouseId: values.vehicleWarehouseId,
      body: {
        vehicleId: props.vehicleId,
        movementAt: values.movementAt,
        receiptTemplateId: isDocumentSelected ? documentTemplate?.id : undefined,
        note: values.note,
      },
    })
      .unwrap()
      .then((response) => {
        if (!isDocumentSelected) {
          showNotification.success(i18n.t('entity.vehicle.labels.vehicleStockedIn'));
          closeCurrentDialog();

          return;
        }

        return response;
      })
      .then((response) => {
        if (!response) {
          return;
        }

        return getMovement({
          vehicleWarehouseMovementId: response?.id,
        })
          .unwrap()
          .then((response) => {
            response.document && props.onDocumentCreation(response.document);
          });
      })
      .then(props.onMovementComplete)
      .catch(handleApiError);

  const defaultValues: Partial<VehicleWarehouseStockInFormFields> = {
    vehicleWarehouseId: head(warehouseOptions)?.value,
    movementAt: getApiDateString(new Date()),
  };

  return (
    <VStack spacing={4}>
      <Text size="small" color="secondary">
        {i18n.t('entity.vehicleWarehouse.modal.stockInDescription')}
      </Text>
      <DataStatus
        isLoading={isWarehousesLoading || isTemplatesLoading}
        isError={isWarehousesError || isTemplatesError}
      >
        <Form<VehicleWarehouseStockInFormFields>
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          schema={schema}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Grid columns={2}>
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.warehouse')}
                  name="vehicleWarehouseId"
                  type="choice"
                  options={warehouseOptions}
                  control={control}
                  isNotClearable
                  isRequired
                  menuInPortal
                />
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.dateOfStockIn')}
                  name="movementAt"
                  type="apiDate"
                  control={control}
                  data-testid="movement-at-datepicker"
                  isRequired
                />
              </Grid>
              <DocumentTemplateBox
                data-testid={suffixTestId('documentTemplateBox', props)}
                title={i18n.t('entity.document.labels.vehicleWarehouseStockInDocument')}
                templates={stockInTemplates}
                selectedTemplate={documentTemplate}
                isSelected={isDocumentSelected}
                onSelect={setIsDocumentSelected}
                onTemplateChange={setDocumentTemplate}
                onNoteChange={(value) => formApi.setValue('note', value)}
                noteLabel={i18n.t('entity.document.labels.vehicleWarehouseStockInNote')}
                isWithNote
                isDisabled
              />
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.cancel')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                />
                <FormButton
                  title={i18n.t('general.actions.confirm')}
                  type="submit"
                  control={control}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const schema = object({
  vehicleWarehouseId: yupString.required(),
  movementAt: yupString.required(),
  note: yupString,
});
