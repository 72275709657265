import {Text} from 'platform/foundation';

import {ReactElement} from 'react';

import i18n from '@dms/i18n';

import {MultiDefaultWrapper, MultiSelectionDefaultComponent} from './styles';

export const DefaultMultiSelectComponent = (count: number): ReactElement => (
  <MultiDefaultWrapper>
    <MultiSelectionDefaultComponent>
      <Text align="center" size="small" color="white">
        {count} {i18n.t('entity.photo.labels.photos')}
      </Text>
    </MultiSelectionDefaultComponent>
  </MultiDefaultWrapper>
);
