import {has, not, pluck, uniq} from 'ramda';
import {isPlainObj} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {ApiError, isFetchBaseQueryError} from 'shared';

import {ApiException} from '../types/api';

export function isApiException(errorData: unknown): errorData is ApiException {
  return !!(isPlainObj(errorData) && has('errors', errorData));
}

const NO_CODE = '???';

/**
 * @about Parses catched error from RTK Query
 */
export function getApiErrorMessages(error: ApiError, defaultErrorMessage?: string) {
  const defaultMessage = defaultErrorMessage ?? i18n.t('general.notifications.defaultErrorMessage');
  if (!isFetchBaseQueryError(error) || !isApiException(error?.data)) {
    return defaultMessage;
  }

  /**
   * Filter out messages without proper code
   * TODO: Translate messages by code
   */
  const errors = error?.data?.errors.filter((error) =>
    not(!error?.message || !error?.code || NO_CODE === error?.code)
  );

  /**
   * Get unique error messsages
   */
  const messages = uniq(pluck('message', errors));

  return !!messages.length ? messages : defaultMessage;
}
