import {Actions, Alert, Radio} from 'platform/components';
import {Box, Heading, HStack, Image, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import i18n from '@dms/i18n';

import {useBoolean} from 'shared';

import {InsuranceData} from '../../../hooks/useInsuranceData';
import {RecommendCustomerForm} from '../../RecommendCustomerForm';

interface RecommendReminderFormProps {
  insuranceData: InsuranceData;
  businessCaseId: string;
  onDone: VoidFunction;
  onCancel: VoidFunction;
}

export function RecommendReminderForm(props: RecommendReminderFormProps) {
  const [selected, setSelected] = useState<string | null>(INTERESTED_OPTION);
  const [isRecommending, showRecommendation, hideRecommendation] = useBoolean();

  const onSubmit = () => {
    if (selected !== INTERESTED_OPTION) {
      return props.onDone();
    }
    showRecommendation();
  };

  if (isRecommending) {
    return (
      <RecommendCustomerForm
        insuranceData={props.insuranceData}
        businessCaseId={props.businessCaseId}
        onCancel={hideRecommendation}
        onDone={props.onDone}
      />
    );
  }

  return (
    <HStack spacing={6} justify="stretch" align="stretch">
      <Box padding={6} backgroundColor="palettes.neutral.20.100">
        <Image width={58} src="assets/images/insurance_notebook.png" />
      </Box>
      <VStack spacing={6}>
        <Heading size={3}>{i18n.t('entity.insurance.labels.doesCustomerHaveInsurance')}</Heading>
        <Text size="small">{i18n.t('entity.insurance.labels.tipperReminderDescription')}</Text>

        <Alert
          type="banner"
          variant="warning"
          title={i18n.t('entity.insurance.labels.recommendCustomerReminder')}
        />

        <Radio direction="column" value={selected} options={options} onChange={setSelected} />
        <Actions
          align="right"
          actions={[
            {
              type: 'button',
              title: i18n.t('general.actions.cancel'),
              onClick: props.onCancel,
              variant: 'secondary',
            },
            {
              type: 'button',
              title: i18n.t('general.actions.confirm'),
              isDisabled: isNil(selected),
              onClick: onSubmit,
            },
          ]}
        />
      </VStack>
    </HStack>
  );
}

const INTERESTED_OPTION = 'interested';

const options = [
  {label: i18n.t('entity.insurance.labels.customerIsInterested'), value: INTERESTED_OPTION},
  {label: i18n.t('entity.insurance.labels.alreadyInsured'), value: 'insured'},
  {label: i18n.t('entity.insurance.labels.notInterested'), value: 'notInterested'},
];
