import {Card, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, HStack, Link, Right, Space, Text, VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {isNotNil} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {TestIdProps} from 'shared';

export type PlatformAuthenticationFormData = {
  username: string;
  password: string;
};

export interface PlatformAuthenticationFormUrlExportProps extends TestIdProps {
  platformName: string;
  defaultValues: PlatformAuthenticationFormData | undefined | null;
  onSubmit: FormSubmitHandler<PlatformAuthenticationFormData>;
  extraLink: string | undefined;
}

export function PlatformAuthenticationFormUrlExport(
  props: PlatformAuthenticationFormUrlExportProps
) {
  return (
    <Card title={`${i18n.t('general.labels.authentication')} – ${props.platformName}`}>
      <Text size="small" color="secondary">
        {i18n.t('page.settings.labels.authenticationDescription')}
      </Text>
      <Space vertical={4} />
      <Form<PlatformAuthenticationFormData>
        defaultValues={props.defaultValues}
        schema={schema}
        onSubmit={props.onSubmit}
      >
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={2}>
              <FormField
                control={control}
                name="username"
                type="text"
                label={i18n.t(`page.advertisement.labels.username`)}
                data-testid={testIds.settings.advertisingPlatforms(`credentialsUsername-urlExport`)}
              />
              <FormField
                control={control}
                name="password"
                type="password"
                label={i18n.t(`page.advertisement.labels.password`)}
                data-testid={testIds.settings.advertisingPlatforms(`credentialsPassword-urlExport`)}
              />
            </Grid>
            {isNotNil(props.extraLink) && (
              <>
                <HStack spacing={1} align="center">
                  <Text size="xSmall">{i18n.t('general.labels.URL')}</Text>
                  <Link
                    size="small"
                    data-testid={testIds.settings.advertisingPlatforms('externalLink-urlExport')}
                    href={props.extraLink}
                    title={i18n.t('page.advertisement.labels.extraLinkText')}
                    target="_blank"
                  />
                </HStack>
              </>
            )}
            <Right>
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.save')}
                data-testid={testIds.settings.advertisingPlatforms(`credentialsSave-urlExport`)}
              />
            </Right>
          </VStack>
        )}
      </Form>
    </Card>
  );
}

const schema = object({
  username: string().nullable().required(),
  password: string().nullable().required(),
});
