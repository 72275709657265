import {formatPhoneNumber} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {CustomerResponseBodyV2} from '@dms/api';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {isCustomerNaturalPerson} from './isCustomerNaturalPerson';

export function getCustomerPhoneNumber(customer: CustomerResponseBodyV2 | Nullish) {
  if (!customer) {
    return null;
  }

  if (isCustomerNaturalPerson(customer)) {
    const phoneNumber = customer.foundingPerson?.phoneNumbers[0];

    const formattedNumber =
      isNotNilOrEmpty(phoneNumber?.prefix) && isNotNilOrEmpty(phoneNumber?.number)
        ? formatPhoneNumber(`${phoneNumber!.prefix}${phoneNumber!.number}`)
        : null;

    return formattedNumber ?? i18n.t('entity.customer.labels.noPhoneNumber');
  }

  const contact = customer.contacts?.[0];
  const contactPhoneNumber = contact?.phoneNumbers[0];

  const formattedContactNumber =
    isNotNilOrEmpty(contactPhoneNumber?.prefix) && isNotNilOrEmpty(contactPhoneNumber?.number)
      ? formatPhoneNumber(`${contactPhoneNumber!.prefix}${contactPhoneNumber!.number}`)
      : null;

  return formattedContactNumber ?? i18n.t('entity.customer.labels.noPhoneNumber');
}
