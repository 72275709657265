import {Tooltip} from 'platform/components';
import {HStack, Link, Text} from 'platform/foundation';

import {GetParticipationApiResponse} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

interface AssigneesButtonProps extends TestIdProps {
  assigneesNames: string[];
  handleEdit: (type: keyof GetParticipationApiResponse) => void;
  isDisabled?: boolean;
}

export function AssigneeButton(props: AssigneesButtonProps) {
  const areMultipleAssignees = props.assigneesNames.length >= 2;
  const otherAssigneesCount = props?.assigneesNames.length - 1;
  const otherAssigneesCountTest = areMultipleAssignees ? `+${otherAssigneesCount}` : ``;

  const otherAssigneesNames = props.assigneesNames.join(', ');
  const firstAssigneeName = props.assigneesNames[0] ?? i18n.t('general.labels.addPerson');

  const tooltipText = props.isDisabled
    ? i18n.t('general.labels.noPermission')
    : otherAssigneesNames;

  return (
    <HStack align="center" spacing={1}>
      <DotSeparator />
      <Tooltip
        label={tooltipText}
        isDisabled={!areMultipleAssignees && !props.isDisabled}
        data-testid={suffixTestId('assignees', props)}
      >
        <HStack align="center" spacing={1}>
          <Link
            size="small"
            leftIcon="social/person_add"
            onClick={() => props.handleEdit('assignees')}
            title={`${firstAssigneeName} ${otherAssigneesCountTest}`}
            isDisabled={props.isDisabled}
            data-testid={suffixTestId('assignees', props)}
          />
        </HStack>
      </Tooltip>
    </HStack>
  );
}

export const DotSeparator = () => (
  <Text color="tertiary" size="small">
    {' • '}
  </Text>
);
