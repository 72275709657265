import {getWorkspaceFromUri, useGetAvailableLanguagesListQuery} from '@dms/api';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';

const {workspace} = getWorkspaceFromUri();

const CERTIFICATE_ALLOWED_LANGUAGES = ['en', 'de', 'fr', 'it', 'es', 'pl', 'cs', 'sk'];
const CERTIFICATE_FALLBACK_LANGUAGE = 'en';

export function useWorkspaceLanguage() {
  const {data: availableLanguages} = useGetAvailableLanguagesListQuery(
    {
      workspace: workspace!, // `workspace` is checked in second argument of `useGetAvailableLanguagesListQuery`
    },
    {skip: !workspace}
  );

  const defaultLanguage =
    i18n?.resolvedLanguage ||
    localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE] ||
    availableLanguages?.default;

  const language = CERTIFICATE_ALLOWED_LANGUAGES.includes(defaultLanguage)
    ? defaultLanguage
    : CERTIFICATE_FALLBACK_LANGUAGE;

  return language;
}
