import {DataStatus, Form, FormField} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useGetBranchListQuery, useGetWarehousesQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {getOptionsFromBranches, getOptionsFromWarehouses} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

type ArticleListExternalFiltersForm = Partial<{
  branchId: string;
  warehouseId: string;
  isAvailable: boolean;
  isInStock: boolean;
}>;

type ArticleListExternalFiltersFormProps = FormToDataGridConnectorProps & TestIdProps;

export function ArticleListExternalFilters(props: ArticleListExternalFiltersFormProps) {
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: branches,
    isLoading: branchesLoading,
    isError: isBranchesError,
  } = useGetBranchListQuery();

  const isLoading = isWarehousesLoading || branchesLoading;
  const isError = isWarehousesError || isBranchesError;

  const values = {
    ...props.values,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ArticleListExternalFiltersForm>
        values={values}
        onChange={props.onChange}
        defaultValues={{}}
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="branchId"
              type="choice"
              label={i18n.t('entity.branch.labels.branch')}
              options={getOptionsFromBranches(branches)}
              data-testid={suffixTestId('branch', props)}
            />
            <FormField
              control={control}
              name="warehouseId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(warehouses)}
              data-testid={suffixTestId('warehouse', props)}
            />
            <FormField
              control={control}
              name="isAvailable"
              label={i18n.t('entity.warehouse.labels.availableOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isInStock"
              label={i18n.t('entity.warehouse.labels.inStock')}
              type="checkbox"
              data-testid={suffixTestId('inStock', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
