import {Card} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {Section, SectionTab} from '@dms/shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';

export function ActivityLogs() {
  const {serviceCaseId} = useWorkshopUrl();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceCaseId}]),
    [serviceCaseId]
  );

  const tabs: SectionTab[] = [
    {
      content: (
        <Card title={i18n.t('general.labels.activityLogs')}>
          <Box minHeight={40}>
            <DataGrid
              gridCode="activity-log-service-case"
              queryModifier={queryModifier}
              autoHeight
              data-testid={testIds.workshop.serviceCaseDetail('activityLogs')}
            />
          </Box>
        </Card>
      ),
      queryId: 'activityLogs',
      title: i18n.t('general.labels.activityLogs'),
    },
    {
      content: (
        <Card title={`${i18n.t('general.labels.email')}/${i18n.t('general.labels.sms')}`}>
          <Box minHeight={40}>TODO - https://carvago.atlassian.net/browse/T20-66337</Box>
        </Card>
      ),
      queryId: 'email_sms',
      title: `${i18n.t('general.labels.email')}/${i18n.t('general.labels.sms')}`,
    },
  ];

  return <Section tabs={tabs} />;
}
