import styled from 'styled-components';

import {FormLabel, TypographyCard} from '@dms/teas';

export const StyledCard = styled(TypographyCard)``;

export const Wrapper = styled.div`
  width: 888px;
  margin: 0 auto;
  margin-top: ${({theme}) => theme.getSize(3)};

  ${StyledCard} {
    margin-bottom: ${({theme}) => theme.getSize(3)};
  }
`;

const BaseGreyBox = styled.div`
  width: 100%;
  padding: ${({theme}) => theme.getSize(4)};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
`;

export const ItemGrayBox = styled(BaseGreyBox)`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.getSize(4)};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-items: flex-start;
  margin-top: ${({theme}) => theme.getSize(2)};
`;

export const TooltipWrapper = styled.div``;

export const CheckboxWrapper = styled.div`
  position: relative;
  top: 25px;

  ${FormLabel} {
    color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
  }
`;
