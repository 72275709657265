import {Dialog, useDialog} from 'platform/components';
import {Icon} from 'platform/foundation';

import {MenuItemProps} from '@dms/api';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {SidebarButton} from '../SidebarButton';
import {SidebarEdit} from './SidebarEdit';

interface SidebarEditProps extends TestIdProps {
  menuItemsById: Record<string, MenuItemProps>;
  pinnedMenuItems: string[];
}

export function SidebarEditDialog(props: SidebarEditProps) {
  const [isOpen, onOpen, onClose] = useDialog(false);
  const [canClose, canCloseOn, canCloseFalse] = useBoolean(true);

  const handleOnSettled = (error?: unknown) => {
    canCloseOn();
    if (!error) {
      onClose();
    }
  };

  return (
    <>
      <SidebarButton onClick={onOpen} isActive={isOpen}>
        <Icon color="text.white" size={5} value="sidebar/edit" />
      </SidebarButton>
      <Dialog
        scrollBehavior="outside"
        title={i18n.t('entity.user.labels.sidebarSettings')}
        size="small"
        isOpen={isOpen}
        onClose={canClose ? onClose : undefined}
        data-testid={suffixTestId('sidebarEditDialog', props)}
      >
        <SidebarEdit
          menuItemsById={props.menuItemsById}
          pinnedMenuItems={props.pinnedMenuItems}
          onSave={canCloseFalse}
          onCancel={onClose}
          onSettled={handleOnSettled}
          data-testid={props['data-testid']}
        />
      </Dialog>
    </>
  );
}
