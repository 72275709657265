import {showNotification} from 'platform/components';
import {RcFile} from 'rc-upload/es/interface';
import {match} from 'ts-pattern';

import {always, isNotNil} from 'ramda';
import {isString} from 'ramda-adjunct';

import {UploadFileResponse, useUploadFileMutation} from '@dms/api';
import i18n from '@dms/i18n';

import {blobToFile, isBlob, stringToFile} from 'shared';

export interface UploadFileError extends Error {
  status?: number;
  method?: 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';
  url?: string;
}

type CustomUploadParams = {
  file: string | File | Blob | RcFile;
  onSuccess?: (response: UploadFileResponse) => void;
  onProgress?: (percentage: number, status?: string) => void;
  onError?: (error: UploadFileError | ProgressEvent) => void;
};

export function useCustomUpload() {
  const [uploadFile] = useUploadFileMutation();

  const onCustomUpload = (params: CustomUploadParams) => {
    const file = getFileToUpload(params.file);

    uploadFile({file, onProgress: params.onProgress})
      .unwrap()
      .then((data) => {
        if (isNotNil(data)) {
          params.onSuccess?.(data);
        }
      })
      .catch((error) => {
        showNotification.error(
          i18n.t('general.validation.imageWasNotUploaded', {
            fileName: file.name,
          })
        );
        params.onError?.(error);
      });
  };

  return [onCustomUpload];
}

export const getFileToUpload = (file: string | File | Blob | RcFile) =>
  match([isString(file), isBlob(file)])
    .with([true, false], always(stringToFile(file as string)))
    .with([false, true], always(blobToFile(file as Blob)))
    .otherwise(always(file as File));
