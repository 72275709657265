import {Button} from 'platform/components';
import {Grid, Right} from 'platform/foundation';

import {ReactElement} from 'react';

import i18n from '@dms/i18n';
import {Form, KonzultaCredentials, $AuthCredentials} from '@dms/teas';

export interface ServiceAuthenticationFormProps {
  initialValues?: KonzultaCredentials;
  onSubmit: (requestBody: KonzultaCredentials) => void;
}

export function ServiceAuthenticationForm(
  props: ServiceAuthenticationFormProps
): ReactElement | null {
  const {initialValues, onSubmit} = props;

  return (
    <Form<KonzultaCredentials>
      onSubmit={onSubmit}
      schema={$AuthCredentials}
      initialValues={initialValues}
      render={({Field, handleSubmit}) => (
        <form onSubmit={handleSubmit} data-testid="integrations-smsGateway">
          <Grid columns={2}>
            <Field name="username" required label={i18n.t('entity.user.labels.userName')} />
            <Field
              as="password"
              required
              name="password"
              label={i18n.t('general.labels.password')}
            />
          </Grid>

          <Right>
            <Button
              data-testid="button.integrations-smsGateway"
              type="submit"
              title={i18n.t('general.actions.save')}
            />
          </Right>
        </form>
      )}
    />
  );
}
