import {yupResolver} from '@hookform/resolvers/yup';
import {Button, ButtonGroup, Card, Separator} from 'platform/components';
import {Heading, Space} from 'platform/foundation';

import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';

import {DocumentLimitDefinitionResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {useFields} from '@dms/teas';

import {getApiDateString} from 'shared';

import {BranchSection} from './BranchSection';
import {RestrictionSection} from './RestrictionSection';
import {DefinitionFormType} from './types';
import {cashRegisterLimitValidations} from './validations';

interface CashRegisterLimitFormProps {
  defaultValues?: DefinitionFormType;
  isSystem: boolean;
  edit: boolean;
  onSubmit: (values: Omit<DocumentLimitDefinitionResponseBody, 'id'>) => void;
  onCancel: () => void;
}

export function CashRegisterLimitForm(props: CashRegisterLimitFormProps) {
  const form = useForm<DefinitionFormType>({
    resolver: yupResolver(cashRegisterLimitValidations(props.defaultValues)),
    defaultValues: props.defaultValues,
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: true,
  });

  const {formState} = form;
  const getField = useFields<DefinitionFormType>(form);

  const handleSubmit: SubmitHandler<DefinitionFormType> = async (values) => {
    const changedValues: Omit<DocumentLimitDefinitionResponseBody, 'id'> = {
      restrictions: values.restrictions.map((item) => ({
        ...item,
        amount: {
          amount: item.amount.amount,
          currency: values.currency,
        },
        validFrom: item.validFrom ? getApiDateString(item.validFrom) : '',
      })),
      system: false,
      branchId: values.branchId,
      currency: values.system ? null : values.currency,
    };

    await props.onSubmit?.(changedValues);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        {/* This input is for a usage of non-changeable system flag down the hierarchy */}
        {getField.hidden({
          name: 'system',
        })}
        <Card>
          <Heading size={4}>
            {props.edit
              ? i18n.t('page.accounting.labels.editDefinition')
              : i18n.t('page.accounting.labels.newDefinition')}
          </Heading>
          <Separator />
          <BranchSection edit={props.edit} isSystem={props.isSystem} />
          <Space vertical={4} />
          <RestrictionSection />
          <Separator />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={props.onCancel}
              isDisabled={formState.isSubmitting}
              title={i18n.t('general.actions.cancel')}
              data-testid={testIds.settings.cashLimitsAdd('cancel')}
            />
            <Button
              variant="primary"
              type="submit"
              isLoading={formState.isSubmitting}
              title={props.edit ? i18n.t('general.actions.save') : i18n.t('general.actions.create')}
              data-testid={testIds.settings.cashLimitsAdd('submit')}
            />
          </ButtonGroup>
        </Card>
      </form>
    </FormProvider>
  );
}
