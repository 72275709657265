import {Card, DataStatus} from 'platform/components';
import {Box, Heading, VStack} from 'platform/foundation';

import {useCallback, useMemo} from 'react';

import {isString} from 'ramda-adjunct';

import {useGetActiveBusinessCaseByCustomerQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds} from '@dms/routes';
import {BusinessCaseSummary, Section, useCustomerUrl} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {
  ActionCallback,
  DataGrid,
  DataGridBehaviorOverrides,
  QueryFilterObject,
} from 'features/datagrid';

export function BusinessCases() {
  const navigate = useNavigate();

  const {customerId} = useCustomerUrl();

  const {data, isLoading, isError} = useGetActiveBusinessCaseByCustomerQuery({customerId});

  const actionCallback: ActionCallback = ({rowId}) => {
    if (isString(rowId)) {
      navigate(
        composePath(businessCaseRoutes.overview, {
          params: {id: rowId},
          isSearchParamsPreserved: false,
        })
      );
    }
  };

  const settingsOverrides = useMemo<DataGridBehaviorOverrides>(
    () => ({
      rowSelectMode: 'NONE',
    }),
    []
  );

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      customerName: [customerId],
    }),
    [customerId]
  );

  return (
    <Section>
      <VStack spacing={4}>
        <DataStatus
          isEmpty={!data?.length}
          isLoading={isLoading}
          isError={isError}
          minHeight={50}
          emptyMessage={i18n.t('page.customer.labels.noActiveBusinessCases')}
          data-testid={testIds.customer.detail('businessCases')}
        >
          <>
            <Heading size={3} alternative>
              {i18n.t('page.customer.activeBusinessCases.title')}
            </Heading>
            {data?.map((businessCase, index) => (
              <BusinessCaseSummary
                key={businessCase.id}
                activeBusinessCase={businessCase}
                variant="vehicle"
                data-testid={testIds.customer.detail(`businessCaseSummary-${index}`)}
              />
            ))}
          </>
        </DataStatus>
        <Heading size={3} alternative>
          {i18n.t('page.customer.previousBusinessCases.title')}
        </Heading>
        <Card title={i18n.t('general.labels.history')}>
          <Box height={100}>
            <DataGrid
              data-testid={testIds.customer.detail('businessCases')}
              gridCode="customer-business-case-history"
              actionCallback={actionCallback}
              _useAsLastResort_definitionBehaviorOverrides={settingsOverrides}
              queryModifier={queryModifier}
            />
          </Box>
        </Card>
      </VStack>
    </Section>
  );
}
