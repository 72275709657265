import {Hide} from 'platform/foundation';

import {forwardRef, Ref, useImperativeHandle, useState} from 'react';

import {Box, useMultiStyleConfig} from '@chakra-ui/react';

import {useRowSelection} from '../hooks/useRowSelections';
import {ICellRenderer, ICellRendererParams} from '../types/AgGridTypes';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultRowSelectCheckboxRenderer} from './DefaultRowSelectCheckboxRenderer';

export interface RowSelectCellRendererProps extends ICellRendererParams {
  gridProps: DataGridProps;
}

function RowSelectCellRendererComponent(
  {node, api, context, gridProps}: RowSelectCellRendererProps,
  ref: Ref<ICellRenderer>
) {
  const {rowSelectRenderer: theme} = useMultiStyleConfig('DataGrid', gridProps);
  const [selected, setSelected] = useState<boolean | undefined>(node.isSelected.bind(node));

  const selectRow = useRowSelection(api, context.behavior.rowSelectMode);
  const selectRowCb = () => {
    selectRow(node, !selected);
    setSelected(!selected);
  };

  useImperativeHandle(ref, () => ({
    refresh: ({node: newNode}) => {
      const newlySelected = newNode.isSelected();
      if (newlySelected !== selected) {
        setSelected(newlySelected);
      }
      return true;
    },
  }));

  const isRowPinned = node.isRowPinned();

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    selectRowCb();
  };

  return (
    <Box className="preventClickPropagation" onClick={handleRowClick} __css={theme}>
      <Hide when={isRowPinned}>
        <DefaultRowSelectCheckboxRenderer
          selected={selected}
          data-testid={`row-${node.rowIndex}`}
        />
      </Hide>
    </Box>
  );
}

export const RowSelectCellRenderer = forwardRef<ICellRenderer, RowSelectCellRendererProps>(
  RowSelectCellRendererComponent
);
