import {object, SchemaOf, mixed} from 'yup';

import i18n from '@dms/i18n';

import {TConditionForm, TConditionFormValue} from '../../../types/ConditionTypes';

export const isValidValue = (value: TConditionFormValue) =>
  value !== '' && value !== null && value !== undefined;

export const getFormSchema = (requiredFormFieldNames: string[]): SchemaOf<TConditionForm> =>
  // eslint-disable-next-line no-restricted-syntax
  object(
    requiredFormFieldNames.reduce(
      (o, key) => ({
        ...o,
        [key]: mixed().test(
          'fieldIsRequired',
          i18n.t('general.validations.fieldIsRequired'),
          isValidValue
        ),
      }),
      {}
    )
  ) as unknown as SchemaOf<TConditionForm>;
