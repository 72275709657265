import {createSelector} from '@reduxjs/toolkit';

import i18n from '@dms/i18n';

export const defaultRestrictionTypes = () => [
  {
    id: 'notice',
    label: i18n.t('entity.cashRegisterLimits.enums.restrictionTypesNotice') as string,
  },
  {
    id: 'disallow',
    label: i18n.t('entity.cashRegisterLimits.enums.restrictionTypesDisallow') as string,
  },
  {
    id: 'off',
    label: i18n.t('entity.cashRegisterLimits.enums.restrictionTypesOff') as string,
  },
];

export const defaultDocumentTypes = () => [
  {
    id: 'income',
    label: i18n.t('entity.cashRegisterLimits.enums.documentTypesIncome') as string,
  },
  {
    id: 'expense',
    label: i18n.t('entity.cashRegisterLimits.enums.documentTypesExpense') as string,
  },
];

export const selectCashRegisterLimitsTypes = createSelector(
  () => i18n?.resolvedLanguage, // Recalculate this selector when lang is changed
  () => defaultRestrictionTypes()
);
export const selectCashRegisterLimitsDocumentTypes = createSelector(
  () => i18n?.resolvedLanguage, // Recalculate this selector when lang is changed
  () => defaultDocumentTypes()
);
