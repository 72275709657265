import {Module} from '@dms/api';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {customerRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {CreateCustomer} from './pages/CreateCustomer/CreateCustomer';
import {CustomerDetail} from './pages/CustomerDetail/CustomerDetail';
import {CustomerOverview} from './pages/CustomerOverview/CustomerOverview';

export const CustomerModule: Module = {
  id: moduleKeys.customers,
  requiredFeatureFlag: featureFlags.ACL_CORE,
  requiredPermission: permissions.vehicleRead,
  layoutProps: {
    title: 'page.customers.title',
    icon: 'social/person',
    iconV2: 'sidebar/person',
  },
  routerProps: {
    path: customerRoutes.overview,
    element: <CustomerOverview />,
  },
  content: [
    {
      id: moduleKeys.customers,
      routerProps: {
        path: customerRoutes.overview,
        element: <CustomerOverview />,
      },
      layoutProps: {
        title: 'page.customers.title',
        icon: 'sidebar/person',
      },
    },
    {
      id: 'customers-detail',
      routerProps: {
        path: customerRoutes.detail,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customers-create',
      routerProps: {
        path: customerRoutes.create,
        element: <CreateCustomer />,
      },
      layoutProps: {
        isPartOfCreateMenuItem: true,
        title: 'page.customerCreate.title',
      },
    },

    {
      id: 'customer-customerInformation',
      routerProps: {
        path: customerRoutes.customerInformation,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-GDPRConsents',
      routerProps: {
        path: customerRoutes.GDPRConsents,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-businessCases',
      routerProps: {
        path: customerRoutes.businessCases,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-serviceCases',
      routerProps: {
        path: customerRoutes.serviceCases,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-interests',
      routerProps: {
        path: customerRoutes.interests,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-vehicles',
      routerProps: {
        path: customerRoutes.vehicles,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-documents',
      routerProps: {
        path: customerRoutes.documents,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-customerContracts',
      routerProps: {
        path: customerRoutes.customerContracts,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-activityLogs',
      routerProps: {
        path: customerRoutes.activityLogs,
        element: <CustomerDetail />,
      },
    },
    {
      id: 'customer-tires',
      routerProps: {
        path: customerRoutes.tires,
        element: <CustomerDetail />,
      },
    },
  ],
};
