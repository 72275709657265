import {useEffect} from 'react';

import i18n from '@dms/i18n';
import {getEmailConfig, useThunkDispatch} from '@dms/teas';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {EmailServices} from './components/EmailServices';

export function EmailServicesPage() {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getEmailConfig.action({}));
  }, [dispatch]);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.emailServices')}}
      data-testid="integrations-emailServices-settings"
    >
      <EmailServices />
    </SettingsTemplate>
  );
}
