import {isNil} from 'ramda';

import {EntityResourceIds, useGetParticipationQuery} from '@dms/api';

import {Nullish} from 'shared';

import {usePermissions} from './usePermissions/usePermissions';

interface PricePermissionsProps {
  businessCaseRecordId: string | Nullish;
  vehicleRecordId: string | Nullish;
}

export const usePricePermissions = (props: PricePermissionsProps) => {
  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      recordId: props?.businessCaseRecordId ?? '',
      resourceId: EntityResourceIds.businessCase,
    },
    {
      skip: isNil(props?.businessCaseRecordId),
    }
  );

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      recordId: props?.vehicleRecordId ?? '',
      resourceId: EntityResourceIds.vehicle,
    },
    {
      skip: isNil(props?.vehicleRecordId),
    }
  );

  const [
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehicleTotalGrossMargin,
    canReadVehiclePurchasePrice,
    canEditVehicleSalePrice,
    canEditVehiclePurchasePrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canEditBusinessCasePurchaseBrokerageFees,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
    canReadBusinessCaseBrokerageFees,
    canEditBusinessCaseMaxPurchasePrice,
  ] = usePermissions({
    permissionKeys: [
      // vehicle
      'vehicleProfit',
      'vehicleSellingPrice',
      'vehicleRealSellingPrice',
      'vehicleReadCosts',
      'vehicleReadEarnings',
      'vehicleReadTotalGrossMargin',
      'vehiclePurchasePrice',
      'vehicleEditSalePrice',
      'vehicleEditPurchasePrice',
      // businessCaseSale
      'businessCaseSaleVehiclePurchasePrice',
      'businessCaseSaleVehicleSalePrice',
      'businessCaseSaleProfit',
      'businessCaseSaleTotalPrice',
      'editBusinessCasePurchaseBrokerageFees',
      // businessCasePurchase
      'businessCasePurchaseVehiclePurchasePrice',
      'businessCasePurchaseVehicleSellingPrice',
      'businessCasePurchaseExpectedProfit',
      'businessCasePurchaseTotalPrice',
      'businessCaseViewBrokerageFees',
      'businessCaseEditMaxPurchasePrice',
    ],
    scopes: {
      businessCaseEditMaxPurchasePrice: {participation: businessCaseParticipation},
      businessCaseViewBrokerageFees: {participation: businessCaseParticipation},
      businessCaseSaleVehiclePurchasePrice: {participation: businessCaseParticipation},
      businessCaseSaleVehicleSalePrice: {participation: businessCaseParticipation},
      businessCaseSaleProfit: {participation: businessCaseParticipation},
      businessCaseSaleTotalPrice: {participation: businessCaseParticipation},
      editBusinessCasePurchaseBrokerageFees: {participation: businessCaseParticipation},
      businessCasePurchaseVehiclePurchasePrice: {participation: businessCaseParticipation},
      businessCasePurchaseVehicleSellingPrice: {participation: businessCaseParticipation},
      businessCasePurchaseExpectedProfit: {participation: businessCaseParticipation},
      businessCasePurchaseTotalPrice: {participation: businessCaseParticipation},
      vehicleReadCosts: {participation: vehicleParticipation},
      vehicleReadEarnings: {participation: vehicleParticipation},
      vehicleReadTotalGrossMargin: {participation: vehicleParticipation},
      vehicleProfit: {participation: vehicleParticipation},
      vehicleSellingPrice: {participation: vehicleParticipation},
      vehicleRealSellingPrice: {participation: vehicleParticipation},
      vehiclePurchasePrice: {participation: vehicleParticipation},
      vehicleEditSalePrice: {participation: vehicleParticipation},
      vehicleEditPurchasePrice: {participation: vehicleParticipation},
    },
  });

  const fieldAccessMap = {
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehicleTotalGrossMargin,
    canReadVehiclePurchasePrice,
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
    canReadBusinessCaseBrokerageFees,
  } as const;

  const vehiclePriceFields = [
    canReadVehicleProfit,
    canReadVehicleSellingPrice,
    canReadVehicleRealSellingPrice,
    canReadVehicleCosts,
    canReadVehicleEarnings,
    canReadVehicleTotalGrossMargin,
    canReadVehiclePurchasePrice,
  ];

  const businessCaseSalePriceFields = [
    canReadBusinessCaseSaleVehiclePurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice,
    canReadBusinessCaseSaleProfit,
    canReadBusinessCaseSaleTotalPrice,
  ];

  const businessCasePurchasePriceFields = [
    canReadBusinessCasePurchaseVehiclePurchasePrice,
    canReadBusinessCasePurchaseVehicleSellingPrice,
    canReadBusinessCasePurchaseExpectedProfit,
    canReadBusinessCasePurchaseTotalPrice,
  ];

  return {
    canViewAnyOfVehiclePriceFields: vehiclePriceFields.some(Boolean),
    canViewAnyOfSalePriceFields:
      vehiclePriceFields.some(Boolean) || businessCaseSalePriceFields.some(Boolean),
    canViewAnyOfPurchasePriceFields:
      vehiclePriceFields.some(Boolean) || businessCasePurchasePriceFields.some(Boolean),
    ...fieldAccessMap,
    canEditVehicleSalePrice,
    canEditVehiclePurchasePrice,
    canEditBusinessCasePurchaseBrokerageFees,
    canEditBusinessCaseMaxPurchasePrice,
  };
};
