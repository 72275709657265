import {FlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getSparePartStateFlag = (state: string | Nullish) =>
  match<string | Nullish, FlagProps | undefined>(state)
    .with(
      'NOTAVAILABLE',
      always({
        label: i18n.t('entity.workshop.labels.notAvailable'),
        colorScheme: 'red',
        isSubtle: true,
      })
    )
    .with(
      'PARTLYAVAILABLE',
      always({
        label: i18n.t('entity.workshop.labels.partlyAvailable'),
        colorScheme: 'red',
        isSubtle: true,
      })
    )
    .with(
      'AVAILABLE',
      always({
        label: i18n.t('general.labels.available'),
        colorScheme: 'blue',
        isSubtle: true,
      })
    )
    .with(
      'ORDER_REQ',
      always({
        label: i18n.t('entity.workshop.labels.orderRequest'),
        colorScheme: 'orange',
        isSubtle: true,
      })
    )
    .with(
      'ORDERED',
      always({
        label: i18n.t('entity.workshop.labels.ordered'),
        colorScheme: 'blue',
        isSubtle: true,
      })
    )
    .with(
      'RECEIVED',
      always({
        label: i18n.t('general.labels.received'),
        colorScheme: 'blue',
        isSubtle: true,
      })
    )
    .with(
      'RESERVED',
      always({
        label: i18n.t('entity.vehicle.labels.reserved'),
        colorScheme: 'blue',
        isSubtle: true,
      })
    )
    .with(
      'DISPATCH_REQ',
      always({
        label: i18n.t('entity.workshop.labels.dispatchRequest'),
        colorScheme: 'orange',
        isSubtle: true,
      })
    )
    .with(
      'READY',
      always({
        label: i18n.t('general.labels.ready'),
        colorScheme: 'green',
        isSubtle: true,
      })
    )
    .with(
      'DISPATCHED',
      always({
        label: i18n.t('entity.workshop.labels.issued'),
        colorScheme: 'green',
        isSubtle: true,
      })
    )
    .otherwise(always(undefined));
