import i18n from 'i18next';
import {
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Space} from 'platform/foundation';
import * as Yup from 'yup';

import {useSelector} from 'react-redux';

import {useDeleteAutoFillTemplateMutation, selectAutoFillTemplates} from '@dms/api';
import {handleApiError} from '@dms/shared';

type DeleteTemplateFormType = {
  templateIdToDelete: string;
};

export function DeleteAutoFillTemplateForm() {
  const {data: autoFillTemplates = []} = useSelector(selectAutoFillTemplates);
  const [deleteAutoFillTemplate, {isLoading: isLoadingDeleteAutoFillTemplate}] =
    useDeleteAutoFillTemplateMutation();

  const handleSubmit: FormSubmitHandler<DeleteTemplateFormType> = async (values) => {
    const autoFillTemplateIdToDelete = values.templateIdToDelete;

    await deleteAutoFillTemplate({
      inspectionId: autoFillTemplateIdToDelete,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  return (
    <>
      <Form<DeleteTemplateFormType>
        schema={Yup.object({
          templateIdToDelete: Yup.string().required(i18n.t('general.validations.fieldIsRequired')),
        })}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <>
            <FormField
              control={control}
              type="choice"
              label={i18n.t('entity.autofillTemplates.deleteDialog.templateSelect.label')}
              name="templateIdToDelete"
              options={autoFillTemplates?.map((template) => ({
                label: template.inspectionName,
                value: template.id,
              }))}
            />

            <Space vertical={4} />

            <ButtonGroup align="right">
              <FormButton
                control={control}
                variant="secondary"
                onClick={closeCurrentDialog}
                title={i18n.t('general.actions.cancel')}
              />
              <FormButton
                variant="danger"
                type="submit"
                control={control}
                isLoading={isLoadingDeleteAutoFillTemplate}
                title={i18n.t('general.actions.delete')}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </>
  );
}
