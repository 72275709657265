import * as Yup from 'yup';

import {CashRegisterDocumentTypeEnum, DocumentRestrictionTypeEnum} from '@dms/api';
import i18n from '@dms/i18n';

import {parseDate} from 'shared';

import {DefinitionFormType} from './types';

const cashRegisterLimitAmountObject = (): Yup.SchemaOf<
  DefinitionFormType['restrictions'][0]['amount']
> =>
  Yup.object({
    amount: Yup.string()
      .required(i18n.t('general.validations.fieldIsRequired'))
      // eslint-disable-next-line unicorn/no-unsafe-regex
      .matches(/^[0-9]+(\.[0-9]+)?$/, i18n.t('general.validations.mustBeNumber'))
      .matches(
        // eslint-disable-next-line unicorn/no-unsafe-regex
        /^[0-9]+(\.[0-9]{1,2})?$/,
        i18n.t('general.validations.maximumDecimalPlaces', {maximum: 2})
      )
      .nullable(),
    currency: Yup.string().nullable(),
  });

const cashRegisterLimitRestrictionItemValidations = (
  values?: DefinitionFormType
): Yup.SchemaOf<DefinitionFormType['restrictions']> =>
  Yup.array().of(
    Yup.object({
      amount: cashRegisterLimitAmountObject(),
      documentTypes: Yup.array()
        .of(
          Yup.string()
            .oneOf(['income', 'expense'])
            .required() as Yup.SchemaOf<CashRegisterDocumentTypeEnum>
        )
        .required(i18n.t('general.validations.fieldIsRequired'))
        .transform((val) => (val === null ? [] : val))
        .min(1, i18n.t('general.validations.minimumItems', {minItems: 1})),
      validFrom: Yup.date().required(i18n.t('general.validations.fieldIsRequired')),
      type: Yup.string()
        .oneOf(['off', 'disallow', 'notice'])
        .required(
          i18n.t('general.validations.fieldIsRequired')
        ) as Yup.SchemaOf<DocumentRestrictionTypeEnum>,
    })
      .test('unique', i18n.t('entity.accounting.validations.validFromUnique'), function (value) {
        const parent = this.parent as DefinitionFormType['restrictions'];

        // Checks if `validFrom` are identical in whole array
        if (
          parent
            .filter((v) => v !== value)
            .some((v) => {
              if (!v.validFrom || !value.validFrom) {
                return false;
              }

              const validFrom = v.validFrom.toDateString();
              const thisValidFrom = value.validFrom.toDateString();

              if (validFrom === thisValidFrom) {
                return true;
              }
              return false;
            })
        ) {
          throw this.createError({
            path: `${this.path}.validFrom`,
          });
        }

        return true;
      })
      .test('noPast', i18n.t('entity.accounting.validations.noPast'), function (value) {
        const nowDate = parseDate(new Date().toDateString()).getTime();
        const validFromDate = value.validFrom
          ? parseDate(value.validFrom.toDateString()).getTime()
          : 0;

        // check if date already exists in values (values are passed only while editing)
        const existingItem = values?.restrictions?.find(
          (item) => item.validFrom?.toDateString() === value.validFrom?.toDateString()
        )?.validFrom;

        if (existingItem) {
          const defaultValueItem = parseDate(existingItem.toDateString()).getTime();

          // Checking if defaultValue is equal to field value - to allow old definitions (edit) passing through this validation
          if (defaultValueItem === validFromDate) {
            return true;
          }
        }

        // Checking if field value is from the past
        if (validFromDate < nowDate) {
          return this.createError({
            path: `${this.path}.validFrom`,
          });
        }

        return true;
      })
      .test(
        'noBeforeOtherDates',
        i18n.t('entity.cashRegisterLimits.validations.noBeforeOtherDates'),
        function (value) {
          const parent = this.parent as DefinitionFormType['restrictions'];
          const foundIndex = parent.findIndex((item) => item === value) ?? -1;

          if (foundIndex === -1) {
            return true;
          }

          const currentValidFrom = parent?.[foundIndex]?.validFrom;
          const prevValidFrom = parent?.[foundIndex - 1]?.validFrom;

          // Checking if field value is after every other date
          const parsedCurrentDateFrom = currentValidFrom
            ? parseDate(currentValidFrom.toDateString()).getTime()
            : 0;

          const parsedPrevDateFrom = prevValidFrom
            ? parseDate(prevValidFrom.toDateString()).getTime()
            : 0;

          if (parsedPrevDateFrom >= parsedCurrentDateFrom) {
            return this.createError({
              path: `${this.path}.validFrom`,
            });
          }

          return true;
        }
      )
  );
export const cashRegisterLimitValidations = (
  values?: DefinitionFormType
): Yup.SchemaOf<DefinitionFormType> =>
  Yup.object({
    currency: Yup.string().ensure(),
    branchId: Yup.string()
      .nullable()
      .when('system', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    restrictions: cashRegisterLimitRestrictionItemValidations(values),
    system: Yup.bool().notRequired(),
  }) as Yup.SchemaOf<DefinitionFormType>;
