import {DocumentSeriesFormValues} from '../types/DocumentSeriesFormValues';

type Pattern = DocumentSeriesFormValues['patterns'][number];

export const getEmptyItem = (from?: Date): Pattern =>
  ({
    prefix: '',
    startingNumber: '00000001',
    length: 8,
    from: from ?? null,
    state: 'not_used',
  }) as Pattern;
