import {isFeatureEnabled} from 'feature-flags';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BillingInformationOverview} from '../BillingInformationOverview/BillingInformationOverview';
import {BillingInformationList} from './components/BillingInformationList';

export function BillingInfo() {
  if (isFeatureEnabled(featureFlags.TENANT_V2)) {
    return <BillingInformationOverview />;
  }

  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.invoice.labels.billingInformationManagement')}}
      data-testid="settings-billingInformation"
      description={i18n.t('page.settings.labels.billingInformationDescription')}
    >
      <BillingInformationList />
    </SettingsTemplate>
  );
}
