import i18n from '@dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {GdprConsentTypesList} from './components/GdprConsentTypesList';

export function GdprConsentTypes() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.gdpr.labels.gdprConsentTypes')}}
      description={i18n.t('entity.gdpr.labels.consentTypesDescription')}
      data-testid="gdpr-consent-types-page"
    >
      <GdprConsentTypesList />
    </SettingsTemplate>
  );
}
