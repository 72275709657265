import {Pattern, match} from 'ts-pattern';

import {always} from 'ramda';

import {OrderPaymentRequestBody, PaymentDiscriminator, PaymentMethod} from '@dms/api';
import {useGetCurrentBranch} from '@dms/shared';

import {FormValuesType} from '../types/FormValuesType';

export const useGetSubmitBody = (paymentDiscriminator: PaymentDiscriminator) => {
  const {data: branchData} = useGetCurrentBranch();

  const getPaymentBody = (data: FormValuesType) => {
    const comment = data.note ?? '';
    const exchangeRate = data.exchangeRate?.toString() || null;
    const rawTenantBankAccount = branchData?.billingInformation?.bankAccounts.find(
      (item) => item.accountNumber === data.tenantBankAccount
    );
    const tenantBankAccount = rawTenantBankAccount
      ? {
          accountName: rawTenantBankAccount.accountName ?? '',
          accountNumber: rawTenantBankAccount.accountNumber ?? '',
          bankName: rawTenantBankAccount.bankName ?? '',
          currencyCode: rawTenantBankAccount.currency,
          iban: rawTenantBankAccount.iban,
          swiftBic: rawTenantBankAccount.swift,
          isPrimary: rawTenantBankAccount.isPrimary ?? false,
        }
      : null;

    return match<
      [PaymentMethod, OrderPaymentRequestBody['paymentDiscriminator']],
      OrderPaymentRequestBody
    >([data.paymentMethod, paymentDiscriminator])
      .with(
        ['BANK_TRANSFER', 'DEPOSIT'],
        ['BANK_TRANSFER', 'BALANCE'],
        ([_, paymentDiscriminator]) => ({
          comment,
          dueDate: data.dueDate,
          amount: data.amount?.toString(),
          exchangeRate,
          paymentDiscriminator,
          tenantBankAccount,
          paymentMethod: 'BANK_TRANSFER',
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .with(
        ['BANK_TRANSFER', 'PURCHASE'],
        always({
          comment,
          dueDate: data.dueDate,
          amount: data.amount?.toString(),
          exchangeRate,
          paymentDiscriminator: 'PURCHASE',
          paymentMethod: 'BANK_TRANSFER',
          customerBankAccountId: data.customerBankAccountId,
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .with(
        ['CASH', Pattern.string],
        always({
          comment,
          dueDate: data.dueDate,
          amount: data.amount?.toString(),
          cashRegisterId: data.cashRegisterId ?? null,
          paymentDiscriminator,
          exchangeRate,
          paymentMethod: 'CASH',
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .with(
        ['PAYMENT_CARD', Pattern.string],
        always({
          comment,
          dueDate: data.dueDate,
          amount: data.amount?.toString(),
          cashRegisterId: data.cashRegisterId ?? null,
          createCashRegisterDocument: data.createCashRegisterDocument ?? false,
          paymentDiscriminator,
          exchangeRate,
          tenantBankAccount,
          paymentMethod: 'PAYMENT_CARD',
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .with(
        ['OFFSET', 'BALANCE'],
        always({
          comment,
          dueDate: data.dueDate,
          exchangeRate,
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          tenantBankAccount,
          paymentMethod: 'OFFSET',
          paymentDiscriminator: 'BALANCE',
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .with(
        ['OFFSET', 'DEPOSIT'],
        always({
          comment,
          dueDate: data.dueDate,
          amount: data.amount?.toString(),
          exchangeRate,
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          tenantBankAccount,
          paymentMethod: 'OFFSET',
          paymentDiscriminator: 'DEPOSIT',
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .with(
        ['OFFSET', 'PURCHASE'],
        always({
          dueDate: data.dueDate,
          exchangeRate,
          supplierInvoiceNumber: data.supplierInvoiceNumber || null,
          customerBankAccountId: data.customerBankAccountId,
          paymentMethod: 'OFFSET',
          paymentDiscriminator: 'PURCHASE',
          dateOfTaxableSupply: data.dateOfTaxableSupply,
        })
      )
      .exhaustive();
  };

  return [getPaymentBody] as const;
};
