import {BreadcrumbType} from 'platform/components';

import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {CashRegisterForm} from '../../components/CashRegisterForm/CashRegisterForm';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accounting.labels.cashRegisters'),
    href: settingsRoutes.cashRegisters,
  },
];

export function NewCashRegister() {
  return (
    <SettingsTemplate
      header={{breadcrumbs, title: i18n.t('page.accountingSettings.labels.newCashRegister')}}
      data-testid={testIds.settings.newCashRegister('template')}
      isCreating
    >
      <CashRegisterForm data-testid={testIds.settings.newCashRegister('form')} />
    </SettingsTemplate>
  );
}
