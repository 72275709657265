import {openDeleteDialog, openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import {path} from 'ramda';

import {useDeletePostponeJobsMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {Main, PostponeDialog} from '@dms/shared';

import {composePath} from 'shared';

import {ActionCallbackParams, DataGrid, useRefreshDataGrid} from 'features/datagrid';

export function PostponedRequests() {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [deletePostponeJobs] = useDeletePostponeJobsMutation();

  const handleDataGridAction = (action: ActionCallbackParams) => {
    match(action.actionKey)
      .with('redirectDetail', () => {
        const vehicleId = path<string>(['vehicleId', 'value'], action.rowData);

        if (!vehicleId) {
          return;
        }

        navigate(
          composePath(vehiclesRoutes.detail, {
            params: {
              id: vehicleId,
            },
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('postponeUntil', () => {
        const reason = path<string>(['postponeReason', 'value'], action.rowData);
        const postponedUntil = path<string>(['jobPostponedUntil', 'value'], action.rowData);

        openDialog(
          <PostponeDialog
            refreshDataGrid={() => {
              action.deselectAll();
              refreshDataGrid();
            }}
            dialogId="postpone-dialog"
            jobsIds={action.rowId}
            reason={reason}
            postponeUntil={postponedUntil}
            data-testid={testIds.workshop.postponedRequests('postpone-dialog')}
          />,
          {
            id: 'postpone-dialog',
            title: i18n.t('entity.serviceCase.labels.postpone'),
            size: 'small',
            withAdditionalFooter: true,
          }
        );
      })
      .with('delete', () => {
        openDeleteDialog({
          onConfirm: () => {
            deletePostponeJobs({
              body: !Array.isArray(action.rowId)
                ? [{postponeJobId: action.rowId}]
                : action.rowId.map((id) => ({postponeJobId: id})),
            })
              .unwrap()
              .then(() => {
                action.deselectAll();
                refreshDataGrid();
              })
              .catch(() => showNotification.error());
          },
        });
      })
      .otherwise(() => {
        showNotification.error(`Action ${action.actionKey} not implemented`);
      });
  };

  return (
    <>
      <Helmet title={i18n.t('page.workshop.labels.postponedRequests')} />
      <Main
        heading={i18n.t('page.workshop.labels.postponedRequests')}
        data-testid={testIds.workshop.postponedRequests('page')}
        isFullHeight
      >
        <DataGrid
          gridCode="postpone-job"
          actionCallback={handleDataGridAction}
          ref={dataGridRef}
          data-testid={testIds.workshop.postponedRequests('page')}
        />
      </Main>
    </>
  );
}
