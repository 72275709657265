import {Alert} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {Money, PaymentMethod, useGetDocumentRestrictionQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {useGetCurrentBranch} from '@dms/shared';

import {Nullish} from 'shared';

interface PaymentAlertsProps {
  price: Money | Nullish;
  paymentMethod: PaymentMethod | Nullish;
  documentType: 'expense' | 'income';
}

export function PaymentLimitAlert(props: PaymentAlertsProps) {
  const {activeBranchId} = useGetCurrentBranch();
  const {data: limitDefinition} = useGetDocumentRestrictionQuery({
    branchId: activeBranchId,
    documentType: props.documentType,
    currency: props.price?.currency,
  });

  const isLimitNotificationExceed =
    parseFloat(props.price?.amount ?? '0') > parseFloat(limitDefinition?.amount.amount ?? '0');

  if (!isLimitNotificationExceed || props.paymentMethod === 'BANK_TRANSFER') {
    return null;
  }

  return match(limitDefinition?.type ?? 'off')
    .with(
      'notice',
      always(
        <Alert
          title={i18n.t('entity.cashRegister.notifications.limitExceededWarningTitle')}
          variant="warning"
        />
      )
    )
    .with(
      'disallow',
      always(
        <Alert
          title={i18n.t('entity.cashRegister.notifications.limitExceededErrorTitle')}
          message={i18n.t('entity.cashRegister.notifications.limitExceededErrorSubtitle')}
          variant="error"
        />
      )
    )
    .with('off', always(null))
    .exhaustive();
}
