import {FC, PropsWithChildren} from 'react';

import {DeprecatedProfileLayout} from '@dms/my-profile';
import {testIds} from '@dms/routes';

import {Profile} from './components/Profile';

export const PersonalInformation: FC<PropsWithChildren<any>> = () => (
  <DeprecatedProfileLayout data-testid={testIds.myProfile.personalInformation('wrapper')}>
    <Profile />
  </DeprecatedProfileLayout>
);
