import {addYears} from 'date-fns';
import {Chips, Separator} from 'platform/components';
import {GridItem, Box, Grid, HStack} from 'platform/foundation';

import {FC, ReactElement, useContext} from 'react';
import {FieldMetaState} from 'react-final-form';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';

import {parseDate, suffixTestId, Nullish, TestIdProps, getApiDateString} from 'shared';

import {selectUserSelectedLanguage} from '../../../store/user/selectors';
import {AuditParamDefinition} from '../../../types/AuditParamDefinition';
import {AuditParamDefinitions} from '../../../types/AuditParamDefinitions';
import {AuditParamValue} from '../../../types/AuditParamValue';
import {TConditionForm, TConditionFormValue} from '../../../types/ConditionTypes';
import {ParamType} from '../../../types/ParamType';
import {getNameAccordingToLocale} from '../../../utils/getNameAccordingToLocale';
import {FormContext} from '../../FinalForm/FormContext';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {useConditionContext} from '../hooks/useConditionContext';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findByParamType} from '../utils/findByParamType';
import {getBaseFormFieldProps} from '../utils/getBaseFormFieldProps';
import {getFormFieldName} from '../utils/getFormFieldName';
import {getParsedValue} from '../utils/getParsedValue';
import {getTranslation} from '../utils/getTranslation';
import {parseNumber} from '../utils/parseNumber';
import {AdditionalInformation} from './AdditionalInformation';

interface AuditFormFieldProps {
  paramDefinition: AuditParamDefinition;
  categoryId: string;
  isRelated?: boolean;
  label?: string;
  asType?: string;
  onChange?: (value: TConditionFormValue) => void;
  noMargin?: boolean;
  uniqueKey: string;
}

export const AuditFormField: FC<AuditFormFieldProps & TestIdProps> = ({
  paramDefinition,
  categoryId,
  isRelated,
  label,
  onChange,
  asType,
  uniqueKey,
  ...rest
}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const {isDisabledForUser} = useConditionContext();
  const {Field: FormField, Condition} = useFormRenderer<TConditionForm>();

  const fieldProps = {
    ...getBaseFormFieldProps(categoryId, paramDefinition, locale),
    ...(label ? {label} : {}),
    ...(onChange ? {onChange} : {}),
    disabled: isDisabledForUser,
    cyName: uniqueKey,
  };

  const renderValueRelatedActions = (
    paramDefinitions: AuditParamDefinitions,
    categoryId: string,
    columns: number
  ): ReactElement | null => {
    const {relatedActions, additionalInformation} = paramDefinitions;

    return (
      <>
        <Grid columns={columns} verticalSpacing={0} data-testid={rest['data-testid']}>
          {relatedActions &&
            relatedActions.length > 0 &&
            relatedActions.map((paramDefinition, index) => (
              <AuditFormField
                key={`relatedActions-${paramDefinition.id}`}
                paramDefinition={paramDefinition}
                categoryId={categoryId}
                isRelated
                uniqueKey={uniqueKey}
                data-testid={suffixTestId(`relatedActions-[${index}]`, rest)}
              />
            ))}
        </Grid>
        {additionalInformation && (
          <AdditionalInformation
            categoryId={categoryId}
            additionalComment={findByParamType(
              additionalInformation,
              AuditParamType.ADDITIONAL_COMMENT
            )}
            additionalPhotos={findByParamType(
              additionalInformation,
              AuditParamType.ADDITIONAL_PHOTOS
            )}
            uniqueKey={uniqueKey}
            data-testid={rest['data-testid']}
          />
        )}
      </>
    );
  };

  const getFormConditions = (columns: number): ReactElement => (
    <>
      {paramDefinition.values.map(({content, value}) => {
        if (!content) {
          return null;
        }

        const isValue = getParsedValue(value) ?? value;

        return (
          <Condition
            key={`formConditions-${value}`}
            when={fieldProps.name}
            is={isValue}
            data-testid={suffixTestId((fieldProps.name ?? paramDefinition.id)?.toString(), rest)}
          >
            <>{renderValueRelatedActions(content, categoryId, columns)}</>
          </Condition>
        );
      })}
    </>
  );

  const paramType: string = asType ?? paramDefinition.type;
  const selectOptions = [
    {
      value: null,
      label: null,
      openCategoryWithKey: null,
      content: null,
      featureKey: null,
    },
    ...paramDefinition.values,
  ];

  switch (paramType) {
    case AuditParamType.TEXT: {
      return (
        <FormField {...fieldProps} as="text" data-testid={suffixTestId('auditFormField', rest)} />
      );
    }

    case AuditParamType.NUMBER:
    case AuditParamType.COUNTER:
      return (
        <FormField
          {...fieldProps}
          as="integer"
          data-testid={suffixTestId('auditFormField', rest)}
          parse={(v) => parseNumber(v)}
          format={(v) => v}
          numberProps={{
            decimalPlaces: 0,
            minStepperValue: 0,
            isStepperVisible: paramType === AuditParamType.COUNTER && !isDisabledForUser,
          }}
        />
      );

    case AuditParamType.DATE:
      return (
        <FormField
          {...fieldProps}
          as="date"
          data-testid={suffixTestId('auditFormField', rest)}
          parse={(value: string | Nullish) => value && getApiDateString(parseDate(value))}
          disabled={isDisabledForUser}
        />
      );

    case AuditParamType.YEAR:
    case AuditParamType.DATE_YEAR: {
      const isLimitedForPresent = uniqueKey === AuditCategoryUniqueKey.YEAR_OF_PRODUCTION;

      return (
        <FormField
          {...fieldProps}
          as="year"
          data-testid={suffixTestId('auditFormField', rest)}
          disabled={isDisabledForUser}
          dateProps={{
            maxDate: isLimitedForPresent ? new Date() : addYears(new Date(), 50),
          }}
        />
      );
    }

    case AuditParamType.DROPDOWN:
      return (
        <GridItem>
          <FormField
            {...fieldProps}
            as="select"
            data-testid={suffixTestId('auditFormField', rest)}
            options={selectOptions}
            getOptionValue={({value}) => value}
            parse={(value) => value}
            placeholder={i18n.t('entity.condition.actions.defaultAction')}
            getOptionLabel={(option) =>
              option.value === null
                ? i18n.t('entity.condition.actions.defaultAction')
                : option?.label
                  ? getNameAccordingToLocale(locale, option.label)
                  : ''
            }
          />

          {getFormConditions(1)}
        </GridItem>
      );

    case AuditParamType.SELECTABLE_BUTTON:
    case AuditParamType.PILL_CHECKBOX:
    case AuditParamType.CHECKBOX_BUTTON:
    case AuditParamType.PILL_RADIO:
    case AuditParamType.RADIO_BUTTONS: {
      const isMultiple = [AuditParamType.CHECKBOX_BUTTON, AuditParamType.PILL_CHECKBOX].includes(
        paramType
      );

      return (
        <GridItem span={2}>
          {isRelated && <Separator />}

          <ChipsField
            data-testid={suffixTestId('auditFormField', rest)}
            name={getFormFieldName(categoryId, paramDefinition.id)}
            disabled={isDisabledForUser}
            isMultiple={isMultiple}
            options={paramDefinition.values}
            label={fieldProps?.label}
            isMandatory={paramDefinition?.mandatory}
          />

          {getFormConditions(2)}
        </GridItem>
      );
    }

    case ParamType.TOGGLE:
      return (
        <HStack spacing={3} wrap>
          <FormField
            {...fieldProps}
            as="checkbox"
            data-testid={suffixTestId('auditFormField', rest)}
          />
          {getFormConditions(1)}
        </HStack>
      );

    default:
      return null;
  }
};

interface ChipsFieldProps {
  name: string;
  disabled: boolean;
  isMultiple: boolean;
  options: AuditParamValue[];
  label?: string | Nullish;
  isMandatory?: boolean;
}

const ChipsField: FC<ChipsFieldProps & TestIdProps> = ({
  name,
  disabled,
  options,
  isMultiple,
  label,
  isMandatory,
  ...rest
}) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const {Field: FormField} = useFormRenderer();
  const {submitCount, validateAfterSubmit} = useContext(FormContext);

  return (
    <FormField
      name={name}
      data-testid={suffixTestId('ChipsField', rest)}
      parse={(value) => value}
      component={({input, meta}) => {
        const hasShowError = (meta: FieldMetaState<unknown>) =>
          (meta.error || meta.submitError) &&
          (!validateAfterSubmit || submitCount > 0) &&
          (meta.touched || submitCount > 0);

        const isValid = hasShowError(meta) ? false : true;

        return (
          <Box width="100%" paddingBottom={3} position="relative">
            <Chips
              isInvalid={!isValid}
              label={label}
              isRequired={isMandatory}
              isMultiple={isMultiple}
              options={options.map(({value, label}) => ({
                value,
                label: getTranslation(locale, label ?? {[locale]: ''}) as string,
                isDisabled: disabled,
              }))}
              value={isMultiple ? input.value : [input.value]}
              onChange={(value) => input.onChange(isMultiple ? value : (value?.[0] ?? ''))}
              isDeselectable
              data-testid={rest['data-testid']}
              errorMessage={hasShowError(meta) ? meta.error || meta.submitError : undefined}
            />
          </Box>
        );
      }}
    />
  );
};
