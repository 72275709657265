import {Option, Separator} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import {
  BaseSupplier,
  BaseSupplierOrder,
  GetSupplierOrderVariantsResponse,
  GetWarehousesResponse,
} from '@dms/api';
import i18n from '@dms/i18n';
import {ReadOnlyField} from '@dms/shared';

import {EMPTY_PLACEHOLDER, Nullish, parseDate, TestIdProps, Unpacked} from 'shared';

interface BasicInformationReadOnlyProps extends TestIdProps {
  supplierOrder: BaseSupplierOrder | Nullish;
  warehouse: Unpacked<GetWarehousesResponse> | Nullish;
  supplier: BaseSupplier | Nullish;
  supplierOrderType: Option | Nullish;
  selectedSupplierOrderVariant: GetSupplierOrderVariantsResponse | Nullish;
}

export function BasicInformationReadOnly(props: BasicInformationReadOnlyProps) {
  const dateTimeFormatter = useDateTimeFormatter();

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  return (
    <>
      <VStack spacing={4}>
        <Grid columns={4}>
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.warehouse')}
            value={props.warehouse?.name}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.supplierOrderVariant')}
            value={props.selectedSupplierOrderVariant?.name}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.supplier')}
            value={props.supplier?.name}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.supplierOrderType')}
            value={props.supplierOrderType?.label}
          />
        </Grid>
        <Grid columns={4}>
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.currency')}
            value={props.supplierOrder?.currency}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.exchangeRate')}
            value={props.supplierOrder?.exchangeRate}
          />
          <ReadOnlyField
            label={i18n.t('entity.warehouse.labels.ncConversion')}
            value={props.supplierOrder?.ncConversion}
          />
        </Grid>
      </VStack>
      <Separator />
      <Grid columns={4}>
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.sentDate')}
          value={formatToShortDate(props.supplierOrder?.orderedAt)}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.createdDate')}
          value={formatToShortDate(props.supplierOrder?.created)}
        />
        <ReadOnlyField
          label={i18n.t('entity.warehouse.labels.updatedDate')}
          value={formatToShortDate(props.supplierOrder?.updated)}
        />
      </Grid>
    </>
  );
}
