import {Avatar, Dropdown} from 'platform/components';
import {Box, Center, Heading, Hide, Scroll, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';
import {omneticReleaseNotes} from '@dms/releaseNotes';

import {useBoolean} from 'shared';

import {HeaderButtonWrapper} from '../../HeaderButtonWrapper/HeaderButtonWrapper';
import {Release} from './Release';

export function ReleasePopup() {
  const [isOpen, setOpen, setClose] = useBoolean();

  return (
    <Dropdown
      onOpen={setOpen}
      onClose={setClose}
      closeOnBlur
      dropdownControl={<ReleasePopupDropdownControl isOpen={isOpen} />}
      placement="bottom-end"
      isLazy
      lazyBehavior="unmount"
    >
      <Box data-testid="layout-header-releasePopup">
        <Scroll width={120} maxWidth={200} maxHeight="85vh" auto>
          <VStack data-testid="layout-header-releasePopupInnerBox">
            <Box padding={2}>
              <Center justify="flex-start" minHeight={8}>
                <Heading size={4}>{i18n.t('general.labels.releaseNotesTitle')}</Heading>
              </Center>
            </Box>
            <VStack spacing={1}>
              {omneticReleaseNotes.map((release, index) => (
                <Hide whenFeatureDisabled={release.featureName} key={`release-[${release.url}]`}>
                  <Release
                    data-testid={`release-[${index}]`}
                    type={release.type}
                    title={release.title}
                    description={release.description}
                    dateTime={release.dateTime}
                    url={release.url}
                  />
                </Hide>
              ))}
            </VStack>
          </VStack>
        </Scroll>
      </Box>
    </Dropdown>
  );
}

const ReleasePopupDropdownControl = ({isOpen}: {isOpen: boolean}) => (
  <HeaderButtonWrapper $isOpen={isOpen} data-testid="layout-header-releasePopup-button">
    <Center width="100%" height="100%">
      <Avatar variant="circle" size="small" icon="action/grade" />
    </Center>
  </HeaderButtonWrapper>
);
