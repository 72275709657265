import {addMonths} from 'date-fns';
import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, getApiDateString, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';

interface ServiceFormProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function ServiceForm(props: ServiceFormProps) {
  const {setValue} = props.formApi;

  const lastServiceInspectionDate = props.formApi.watch(
    'serviceIntervals.serviceInspection.lastServiceInspectionDate'
  );

  const replacementAfterMonths = props.formApi.watch(
    'serviceIntervals.serviceInspection.replacementAfterMonths'
  );

  const lastServiceInspectionMileage = props.formApi.watch(
    'serviceIntervals.serviceInspection.lastServiceInspectionMileage'
  );

  const replacementAfterMileage = props.formApi.watch(
    'serviceIntervals.serviceInspection.replacementAfterMileage'
  );

  useEffect(() => {
    if (!lastServiceInspectionDate || !replacementAfterMonths) {
      return;
    }

    setValue(
      'serviceIntervals.serviceInspection.nextServiceInspectionDate',
      getApiDateString(addMonths(lastServiceInspectionDate, replacementAfterMonths))
    );
  }, [setValue, lastServiceInspectionDate, replacementAfterMonths]);

  useEffect(() => {
    if (!lastServiceInspectionMileage || !replacementAfterMileage) {
      return;
    }

    setValue(
      'serviceIntervals.serviceInspection.nextServiceInspectionMileage',
      lastServiceInspectionMileage + replacementAfterMileage
    );
  }, [setValue, lastServiceInspectionMileage, replacementAfterMileage]);

  return (
    <>
      <Grid columns={4}>
        <GridItem>
          <FormField
            type="apiDate"
            label={i18n.t('entity.vehicle.labels.dateOfLastInspection')}
            control={props.control}
            name="serviceIntervals.serviceInspection.lastServiceInspectionDate"
            data-testid={suffixTestId('lastServiceInspectionDate', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.lastServiceInspection')}
            control={props.control}
            name="serviceIntervals.serviceInspection.lastServiceInspectionMileage"
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('lastServiceInspectionMileage', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="apiDate"
            label={i18n.t('entity.vehicle.labels.dateOfNextServiceInspection')}
            control={props.control}
            name="serviceIntervals.serviceInspection.nextServiceInspectionDate"
            data-testid={suffixTestId('nextServiceInspectionDate', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.nextServiceInspection')}
            control={props.control}
            name="serviceIntervals.serviceInspection.nextServiceInspectionMileage"
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('nextServiceInspectionMileage', props)}
          />
        </GridItem>
      </Grid>
      <Grid columns={4}>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.replacementAfterMonths')}
            control={props.control}
            name="serviceIntervals.serviceInspection.replacementAfterMonths"
            suffix={i18n.t('general.metric.months')}
            data-testid={suffixTestId('replacementAfterMonths', props)}
          />
        </GridItem>
        <GridItem>
          <FormField
            type="number"
            label={i18n.t('entity.vehicle.labels.replacementAfterMileage')}
            control={props.control}
            name="serviceIntervals.serviceInspection.replacementAfterMileage"
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('replacementAfterMileage', props)}
          />
        </GridItem>
      </Grid>
    </>
  );
}
