import {Attributes, AttributesRow, Button, Card, DataStatus} from 'platform/components';
import {Heading, HStack, Space} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {defaultTo, mergeAll} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {GetCostCorrectionResponse, useGetCustomerV2Query, useGetWarehouseQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {DEFAULT_CURRENCY, getCustomerName, Section} from '@dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

interface OverviewProps extends RequiredTestIdProps {
  costCorrection: GetCostCorrectionResponse | Nullish;
  isCostCorrectionLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const formatDateTime = useDateTimeFormatter();

  const formatCurrency = useFormatCurrency();

  const {data: costCorrectionWarehouse, isLoading: isCostCorrectionWarehouseLoading} =
    useGetWarehouseQuery(
      {warehouseId: props.costCorrection?.costCorrectionInfo.warehouseId as string},
      {skip: isNilOrEmpty(props.costCorrection?.costCorrectionInfo.warehouseId)}
    );

  const {data: costCorrectionCreatedBy, isLoading: isCostCorrectionCreatedByLoading} =
    useGetCustomerV2Query(
      {customerId: props.costCorrection?.costCorrectionInfo.createdBy as string},
      {skip: isNilOrEmpty(props.costCorrection?.costCorrectionInfo.createdBy)}
    );

  const {data: receiveNoteCorrectionCreatedBy, isLoading: isReceiveNoteCorrectionCreatedByLoading} =
    useGetCustomerV2Query(
      {customerId: props.costCorrection?.receiveNoteCorrectionInfo.createdBy as string},
      {skip: isNilOrEmpty(props.costCorrection?.receiveNoteCorrectionInfo.createdBy)}
    );

  const isLoading =
    props.isCostCorrectionLoading ||
    isCostCorrectionWarehouseLoading ||
    isCostCorrectionCreatedByLoading ||
    isReceiveNoteCorrectionCreatedByLoading;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {issueNoteValueCorrectionId: props.costCorrection?.costCorrectionInfo.costCorrectionId},
      ]),
    [props.costCorrection?.costCorrectionInfo.costCorrectionId]
  );

  const formatToDateTimeShort = (date: string | Nullish) => {
    if (isNilOrEmpty(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const costCorrectionCreatedByName = getCustomerName(costCorrectionCreatedBy);

  const receiveNoteCorrectionCreatedByName = getCustomerName(receiveNoteCorrectionCreatedBy);

  const costCorrectionAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: costCorrectionWarehouse?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.createdBy'),
      value: costCorrectionCreatedByName,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToDateTimeShort(
        props.costCorrection?.costCorrectionInfo.dateAndTimeOfTheCreation
      ),
    },
  ];

  const receiveNoteCorrectionAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.costCorrection?.receiveNoteCorrectionInfo.number}
          rightIcon="action/launch"
          onClick={() =>
            window.open(
              composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
                params: {
                  id: props.costCorrection?.receiveNoteCorrectionInfo.receiveNoteCorrectionId,
                },
              }),
              '_blank'
            )
          }
          data-testid={suffixTestId('linkToReceiveNoteCorrection', props)}
        />
      ),
    },
    {
      label: i18n.t('entity.warehouse.labels.createdBy'),
      value: receiveNoteCorrectionCreatedByName,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToDateTimeShort(props.costCorrection?.receiveNoteCorrectionInfo.createdDate),
    },
  ];

  const totalValue = formatCurrency(
    defaultTo(0, props.costCorrection?.totalValueItems.amount),
    defaultTo(DEFAULT_CURRENCY, props.costCorrection?.totalValueItems.currency),
    2
  );

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={isLoading} minHeight={60}>
        <Card title={i18n.t('entity.warehouse.labels.costCorrectionInformation')}>
          <Attributes
            rows={costCorrectionAttributes}
            size="quarter"
            data-testid={suffixTestId('costCorrectionAttributes', props)}
          />
          <Space vertical={4} />
          <Card
            title={i18n.t('entity.warehouse.labels.receiveNoteCorrection')}
            variant="inlineGrey"
          >
            <Attributes
              rows={receiveNoteCorrectionAttributes}
              size="quarter"
              data-testid={suffixTestId('receiveNoteCorrectionAttributes', props)}
            />
          </Card>
        </Card>

        <Space vertical={4} />

        <Card title={i18n.t('entity.warehouse.labels.items')}>
          <DataGrid
            gridCode="warehouse-issue-note-cost-correction-items"
            queryModifier={queryModifier}
            autoHeight
            data-testid={suffixTestId('items', props)}
          />
          <HStack minHeight={15} align="center" justify="space-between">
            <Heading size={5}>{i18n.t('general.labels.totalValue')}</Heading>
            <Heading size={5}>{totalValue}</Heading>
          </HStack>
        </Card>
      </DataStatus>
    </Section>
  );
}
