import styled from 'styled-components';

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {SourcingVehicleDetailResponseBody} from '@dms/api';
import {
  convertVehicleTagsToVehicleSnippetHighlights,
  loadPresetRequest,
  selectPresetItems,
  VehicleSnippetHighlightsWithMore,
} from '@dms/teas';

import {noop, suffixTestId, TestIdProps} from 'shared';

import {ActionButtonsVehicle} from '../../ActionButtonsVehicle/ActionButtonsVehicle';
import {SourcingVehicleActions} from '../../SourcingVehicleActions/SourcingVehicleActions';
import {VehiclePriceForm} from '../../VehiclePriceForm/VehiclePriceForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .form-field-wrapper {
    &:nth-child(4) {
      min-width: 250px;
    }
  }
`;

const TopRow = styled.div`
  display: flex;
  margin-bottom: 14px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: 48px;
`;

interface TableDetailRowProps extends TestIdProps {
  row: {index: number; original: SourcingVehicleDetailResponseBody};
  recomputeRowHeight?: (index?: number) => void;
  setLastVisitedVehicle?: (adId: string, index: number) => void;
}

export function TableDetailRow({
  row: {
    index,
    original,
    original: {vehicleSummary, features},
  },
  recomputeRowHeight = noop,
  setLastVisitedVehicle,
  ...props
}: TableDetailRowProps) {
  const dispatch = useDispatch();
  const vehicleData = original.sourcingVehicle;
  const costs = useSelector(selectPresetItems(vehicleData?.adId))?.filter(
    (i) => i.type === 'fixed'
  );

  const isDisabled = !original.sourcingVehicle?.isAvailable;

  useEffect(() => {
    if (!costs.length) {
      dispatch(loadPresetRequest({vehicleId: vehicleData?.adId}));
    }
  }, [dispatch, vehicleData?.adId, costs.length]);

  return (
    <Container>
      <TopRow>
        <VehicleSnippetHighlightsWithMore
          data={convertVehicleTagsToVehicleSnippetHighlights(
            [],
            [],
            Object.values(features).filter((i) => i.isHighlight)
          )}
          onRecalculate={() => recomputeRowHeight()}
        />
        <ButtonsWrapper>
          <ActionButtonsVehicle
            vehicle={original}
            index={index}
            isDisabled={isDisabled}
            actionButton={<SourcingVehicleActions vehicle={vehicleData} />}
            adId={vehicleData?.adId}
            setLastVisitedVehicle={setLastVisitedVehicle}
          />
        </ButtonsWrapper>
      </TopRow>
      <VehiclePriceForm
        isDisabled={isDisabled}
        vehicleId={vehicleData?.adId}
        initialValues={{
          comment: vehicleSummary?.comment as string,
          purchasePrice: `${vehicleSummary?.purchasePrice}`,
          sellingPrice: `${vehicleSummary?.sellingPrice}`,
        }}
        data-testid={suffixTestId('vehiclePriceForm', props)}
      />
    </Container>
  );
}
