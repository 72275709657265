import {match} from 'ts-pattern';

import {always} from 'ramda';

import {ContractInformationResponseBodyV2} from '@dms/api';
import i18n from '@dms/i18n';

export const getContractSubTitle = (contract: ContractInformationResponseBodyV2) =>
  match(contract.legalForm)
    .with('NATURAL_PERSON', always(i18n.t('entity.person.labels.physicalPerson')))
    .with('SELF_EMPLOYED', always(i18n.t('entity.customer.labels.isSelfEmployed')))
    .with('LEGAL_ENTITY', always(i18n.t('general.labels.company')))
    .otherwise(always(null));
