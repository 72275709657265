import {BreadcrumbType} from 'platform/components';
import {Hide, Show} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetCashRegisterQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {CashRegisterForm} from '../../components/CashRegisterForm/CashRegisterForm';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {InUseCashRegisterEdit} from './components/InUseCashRegisterEdit';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accounting.labels.cashRegisters'),
    href: settingsRoutes.cashRegisters,
  },
];

export function EditCashRegister() {
  const {id} = useParams();
  const {data: cashRegister} = useGetCashRegisterQuery(
    {cashRegisterId: id ?? ''},
    {skip: isNil(id)}
  );

  const isInUse = (cashRegister?.documentCount ?? 0) > 0;
  const headerTitle = isNotNil(cashRegister)
    ? `${cashRegister?.name} ${cashRegister?.currency}`
    : '';

  return (
    <SettingsTemplate
      header={{breadcrumbs, title: headerTitle}}
      data-testid={testIds.settings.cashRegistersDetail('template')}
    >
      <Show when={isInUse}>
        <InUseCashRegisterEdit cashRegisterId={id} />
      </Show>
      <Hide when={isInUse}>
        <CashRegisterForm
          cashRegisterId={id}
          data-testid={testIds.settings.cashRegistersDetail('form')}
        />
      </Hide>
    </SettingsTemplate>
  );
}
