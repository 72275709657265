import {DataStatus, DynamicUi, Separator} from 'platform/components';
import {Heading, Hide} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {path} from 'ramda';

import {NotificationSettingsResponseBody, Obligation, useGetSchemaQuery} from '@dms/api';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, useRequiredParams} from 'shared';

import type {FormValues} from '../types/FormValues';

interface CustomFieldsNotificationSettingsProps extends RequiredTestIdProps {
  obligation: Obligation | undefined;
  notificationSettings: NotificationSettingsResponseBody | undefined;
  formApi: UseFormReturn<FormValues>;
}
const SCHEMA_ID = 'notification';

export function CustomFieldsNotificationSettings(props: CustomFieldsNotificationSettingsProps) {
  const {id} = useRequiredParams();
  const notificationSchemaId = `${SCHEMA_ID}-${id}`;

  const {
    data: schema,
    isLoading: isLoadingSchema,
    isError: isSchemaError,
    error,
  } = useGetSchemaQuery({id: notificationSchemaId});

  const defaultValues =
    props.notificationSettings?.tenantSettings ?? props.notificationSettings?.systemSettings;

  const isNotFoundError = path(['status'], error) === 404;

  return (
    <Hide when={isNotFoundError || isLoadingSchema}>
      <Separator spacing={0} />

      <Heading size={4}>{i18n.t('page.settings.notifications.anotherSettings')}</Heading>

      <DataStatus minHeight={20} isError={isSchemaError}>
        {schema?.root && (
          <DynamicUi schema={schema.root} isReadOnly defaultValues={defaultValues} />
        )}
      </DataStatus>
    </Hide>
  );
}
