import {SyntheticEvent, useMemo} from 'react';

import {thumbnailUrl} from '@dms/shared';

import {Nullish} from 'shared';

import {ImgWrapper} from '../AdvanceVehicleView/styles';

export interface PlaceholderVehicleImageProps {
  src?: string | Nullish;
  alt?: string;
  isAutoHeight?: boolean;
  zoomThumbnail?: boolean;
}

export function PlaceholderVehicleImage({
  src,
  alt,
  zoomThumbnail,
  isAutoHeight = false,
}: PlaceholderVehicleImageProps) {
  const thumbImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const el = e.target as HTMLImageElement;
    el.onerror = null;

    el.onerror = null;
    el.src = thumbnailUrl;
  };

  const image = useMemo(
    () => <img alt={alt} src={src ?? thumbnailUrl} onError={thumbImageError} />,
    [src, alt]
  );

  return (
    <ImgWrapper $isAutoHeight={isAutoHeight} $zoomThumbnail={zoomThumbnail}>
      {image}
    </ImgWrapper>
  );
}
