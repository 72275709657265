import {Alert, ProgressBar} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {isNotEmpty} from 'ramda';

import {saleVehiclePhotoApi, useFileOperationAcknowledgeMutation} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useToggle} from 'shared';

interface BackgroundRemovalStatusProps {
  isInProgress: boolean;
  failedOperationsIds: string[];
  onAcknowledgeOperation: () => void;
}

export const BackgroundRemovalStatus = (props: BackgroundRemovalStatusProps) => {
  const [acknowledgeFileOperation, {isLoading: isAcknowledgeLoading}] =
    useFileOperationAcknowledgeMutation();
  const dispatch = useDispatch();

  const [isAcknowledgeAlertAllowed, toggleAcknowledgeAlertAllowed] = useToggle(true);

  const handleAcknowledgeOperation = () => {
    if (isNotEmpty(props.failedOperationsIds)) {
      acknowledgeFileOperation({
        body: {
          fileOperationIds: props.failedOperationsIds,
        },
      })
        .unwrap()
        .then(toggleAcknowledgeAlertAllowed)
        .then(() => {
          dispatch(saleVehiclePhotoApi.util.invalidateTags([{type: 'SaleVehicleAlbumPhotos'}]));
        })
        .catch(handleApiError);
    }
  };

  return (
    <>
      <Show when={props.isInProgress}>
        <VStack>
          <Alert
            variant="info"
            title={i18n.t('entity.photo.notifications.backgroundRemovalInProgress')}
            message={i18n.t('entity.photo.notifications.backgroundRemovalCouldLast')}
            data-testid={testIds.vehicles.photos(`bgRemovalInProgress-alert`)}
          />
          <ProgressBar indeterminate />
        </VStack>
      </Show>
      <Show when={isAcknowledgeAlertAllowed && isNotEmpty(props.failedOperationsIds)}>
        <Alert
          variant="error"
          title={i18n.t('entity.photo.notifications.backgroundRemovalFailed')}
          data-testid={testIds.vehicles.photos(`bgRemovalFailed-alert`)}
          hyperlinks={[
            {
              title: isAcknowledgeLoading
                ? i18n.t('general.labels.loading')
                : i18n.t('general.actions.acknowledge'),
              onClick: handleAcknowledgeOperation,
              size: 'small',
              isDisabled: isAcknowledgeLoading,
            },
          ]}
        />
      </Show>
    </>
  );
};
