import {Attributes} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {Nullish} from 'utility-types';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, parseDate, suffixTestId} from 'shared';

interface VehicleServiceIntervalReplacementAttributesProps extends RequiredTestIdProps {
  part:
    | {
        lastReplacementDate: string | Nullish;
        replacementAfterMonths: number | Nullish;
        nextReplacementDate: string | Nullish;
        lastReplacementMileage: number | Nullish;
        replacementAfterMileage: number | Nullish;
        nextReplacementMileage: number | Nullish;
      }
    | Nullish;
}

export function VehicleServiceIntervalReplacementAttributes(
  props: VehicleServiceIntervalReplacementAttributesProps
) {
  const formatDateTime = useDateTimeFormatter();

  return (
    <Attributes
      rows={[
        {
          label: i18n.t('entity.vehicle.labels.dateOfLastReplacement'),
          value: props.part?.lastReplacementDate
            ? formatDateTime('dateShort', parseDate(props.part?.lastReplacementDate))
            : null,
        },
        {
          label: i18n.t('entity.vehicle.labels.replacementAfterMonths'),
          value: props.part?.replacementAfterMonths,
        },
        {
          label: i18n.t('entity.vehicle.labels.nextReplacementDate'),
          value: props.part?.nextReplacementDate
            ? formatDateTime('dateShort', parseDate(props.part?.nextReplacementDate))
            : null,
        },
        {
          label: `${i18n.t('entity.vehicle.labels.mileageAtLastReplacement')} (${i18n.t('general.metric.km')})`,
          value: props.part?.lastReplacementMileage,
        },
        {
          label: `${i18n.t('entity.vehicle.labels.replacementAfterMileage')} (${i18n.t('general.metric.km')})`,
          value: props.part?.replacementAfterMileage,
        },
        {
          label: `${i18n.t('entity.vehicle.labels.nextReplacementMileage')} (${i18n.t('general.metric.km')})`,
          value: props.part?.nextReplacementMileage,
        },
      ]}
      data-testid={suffixTestId('part', props)}
    />
  );
}
