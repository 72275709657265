import {DeepPath, DeepPathArray} from '@hookform/strictly-typed';

import {ReactNode, ReactElement, ChangeEvent} from 'react';
import {UseFormReturn, FormState, Controller, Control, FieldValues} from 'react-hook-form';

import {path} from 'ramda';
import {isString} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {TextField} from '../../TextField/TextField';
import {TextFieldProps as TxtFieldProps} from '../../TextField/types';
import {FieldProps} from '../FieldProps';
import {FieldLabel} from '../styles';

/**
 * @deprecated - use platform instead
 */
export interface TextFieldProps<
  TValues extends FieldValues,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
> extends FieldProps<TValues, TPath> {
  onChange?: (values: any, formState?: FormState<TValues>) => void;
  onBlur?: (values: any, formState?: FormState<TValues>) => void;
  value?: string;
  defaultValue?: string extends keyof TValues ? TValues[keyof TValues & string] : unknown;
  inputProps?: Omit<TxtFieldProps, 'onBlur' | 'value' | 'defaultValue'>;
}
/**
 * @deprecated - use platform instead
 */
export function useTextFields<
  TValues extends Record<string, string | undefined>,
  TPath = DeepPath<TValues, DeepPathArray<TValues, keyof TValues>>,
>({
  control,
  formState: {errors},
  getValues,
  formState,
}: UseFormReturn<TValues>): (fieldProps: TextFieldProps<TValues, TPath>) => ReactElement {
  return ({
    label,
    name,
    onChange,
    onBlur,
    value,
    defaultValue,
    inputProps,
  }: TextFieldProps<TValues, TPath>) => {
    const stringifiedName = Array.isArray(name) ? name.join('.') : String(name);

    const isError = !!path(
      // eslint-disable-next-line no-restricted-syntax
      isString(name) ? [name] : Array.from(name as unknown as string[]),
      errors
    );
    const helperText: ReactNode =
      (isError &&
        path(
          isString(name)
            ? [name, 'message']
            : // eslint-disable-next-line no-restricted-syntax
              [...Array.from(name as unknown as string[]), 'message'],
          errors
        )) ||
      inputProps?.helperText;

    return (
      <>
        {label && <FieldLabel className="label">{i18n.t(label)}</FieldLabel>}
        <Controller
          name={stringifiedName}
          defaultValue={defaultValue as any}
          control={control as Control<Record<string, any>>}
          render={(props) => {
            const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
              inputProps?.onChange && inputProps?.onChange(event);
              props.field.onChange(event.target.value);

              if (onChange) {
                const values = getValues();
                onChange(values, formState);
              }
            };
            const handleBlur = () => {
              props.field.onBlur();

              if (onBlur) {
                const values = getValues();
                onBlur(values, formState);
              }
            };

            return (
              <TextField
                {...props.field}
                error={isError}
                name={stringifiedName}
                helperText={helperText}
                onBlur={handleBlur}
                value={value || props.field.value}
                {...inputProps}
                onChange={handleChange}
              />
            );
          }}
        />
      </>
    );
  };
}
