import {LocationRow, SymbolType, VectorType} from '../types/MultipleLocationsForm';

export function locationRowFactory(defaultValues: Partial<LocationRow>): LocationRow {
  const defaultLocationRow: LocationRow = {
    isSelected: true,
    vectorType: VectorType.Aisle,
    number: 1,
    symbolType: SymbolType.Alphabet,
    beginAt: '',
    vectorPosition: 1,
  };

  return {
    ...defaultLocationRow,
    ...defaultValues,
  };
}
