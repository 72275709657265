import {Flag, FlagProps} from 'platform/components';
import {Heading, HStack, Show, Text, ThemeColorTextPath, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {defaultTo} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';

const DEFAULT_PRICE = 0;

interface PriceSummaryItemProps extends TestIdProps {
  title: string;
  price?: number | null;
  priceWithoutVat?: number | null;
  currency?: string | null;
  color?: ThemeColorTextPath;
  flag?: FlagProps;
}

export function PriceSummaryItem(props: PriceSummaryItemProps) {
  const formatCurrency = useFormatCurrency();

  return (
    <VStack spacing={1}>
      <Text size="xxSmall" color="secondary" data-testid={suffixTestId('title', props)}>
        {props.title}
      </Text>
      <HStack spacing={2} align="center">
        <Heading size={4} color={props.color} data-testid={suffixTestId('withVat', props)}>
          {formatCurrency(
            defaultTo(DEFAULT_PRICE, props.price),
            props.currency ?? DEFAULT_CURRENCY,
            2
          )}
        </Heading>
        {isNotNil(props.flag) && <Flag {...props.flag} size="small" />}
      </HStack>
      <Show when={isNotNil(props.priceWithoutVat)}>
        <Text size="xxSmall" color="secondary" data-testid={suffixTestId('withoutVat', props)}>
          {`${formatCurrency(
            defaultTo(DEFAULT_PRICE, props.priceWithoutVat),
            props.currency ?? DEFAULT_CURRENCY,
            2
          )} ${i18n.t('general.labels.withoutVat')}`}
        </Text>
      </Show>
    </VStack>
  );
}
