import {Attributes, AttributesRow} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {GetCustomerRequestedPriceApiRes, useGetBusinessCaseQuery, useGetUserQuery} from '@dms/api';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {buildArray, parseDate, useRequiredParams} from 'shared';

import {FormatParameterPrice} from '../RequestedPrice/RequestedPriceDetail';

export interface PricingDetailProps {
  requestedPrice: GetCustomerRequestedPriceApiRes | undefined;
}

export function PricingDetail({requestedPrice}: PricingDetailProps) {
  const formatDateTime = useDateTimeFormatter();
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? (businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0] ?? null)
      : (businessCase?.offers?.[0]?.purchaseVehicles?.[0] ?? null);

  const purchaseVehiclePricing = purchaseVehicle?.pricing;
  const vehicleSellingPrice = purchaseVehiclePricing?.sellingPrice;
  const vehicleMaxBuyingPrice = purchaseVehiclePricing?.maxBuyingPrice;
  const {data: user} = useGetUserQuery(
    {id: purchaseVehiclePricing?.pricer ?? ''},
    {skip: !purchaseVehiclePricing?.pricer}
  );

  const rows = buildArray<AttributesRow>([
    {
      label: i18n.t('general.labels.vatDeductible'),
      value:
        purchaseVehiclePricing?.vatDeductible && requestedPrice?.vatRate?.vatRate
          ? `${requestedPrice?.vatRate.vatRate} %`
          : i18n.t('general.labels.no'),
      testId: testIds.businessCase.buying('pricing-vatDeductible'),
    },
  ])
    .when(vehicleMaxBuyingPrice?.priceWithVat || vehicleMaxBuyingPrice?.priceNotDeductible, {
      label: i18n.t('page.businessCase.labels.maxBuyingPrice'),
      content: (
        <FormatParameterPrice
          priceWithVat={
            vehicleMaxBuyingPrice?.priceWithVat ?? vehicleMaxBuyingPrice?.priceNotDeductible
          }
          priceWoVat={vehicleMaxBuyingPrice?.priceWithoutVat}
          vatDeductible={requestedPrice?.isVatDeductible ?? false}
        />
      ),
      testId: testIds.businessCase.buying('pricing-maxBuyingPrice'),
    })
    .add({
      label: i18n.t('general.labels.sellingPrice'),
      content: (
        <FormatParameterPrice
          priceWithVat={
            vehicleSellingPrice?.priceWithVat ?? vehicleSellingPrice?.priceNotDeductible
          }
          priceWoVat={vehicleSellingPrice?.priceWithoutVat}
          vatDeductible={requestedPrice?.isVatDeductible ?? false}
        />
      ),
      testId: testIds.businessCase.buying('pricing-maxBuyingPrice'),
    })
    .add({
      label: i18n.t('general.labels.reason'),
      value: purchaseVehiclePricing?.reason,
      testId: testIds.businessCase.buying('pricing-reason'),
    })
    .add({
      label: i18n.t('entity.interest.labels.pricer'),
      value: user ? `${user?.firstName ?? ''} ${user?.lastName}` : null,
      testId: testIds.businessCase.buying('pricing-pricer'),
    })
    .add({
      label: i18n.t('general.labels.pricedOn'),
      value: purchaseVehiclePricing?.pricedOn
        ? formatDateTime('dateShort', parseDate(purchaseVehiclePricing?.pricedOn ?? ''))
        : null,
      testId: testIds.businessCase.buying('pricing-pricedOn'),
    });

  return (
    <Attributes data-testid={testIds.businessCase.buying('pricing')} rows={rows} size="third" />
  );
}
