import {Card, EmptyStatus, Action} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {FC, useState} from 'react';

import {isNil, reject} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {useRouter} from '../../../hooks/useRouter';
import {MainCategoryProps} from '../../../types/ConditionTypes';
import {useConditionContext} from '../hooks/useConditionContext';
import {useVehicleFeatures} from '../hooks/useVehicleFeatures';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {AddNewFeature} from './AddNewFeature';
import {CategoryCard} from './CategoryCard';

interface FeaturesProps extends MainCategoryProps {
  vehicleFeatures?: string[];
}

export const Features: FC<FeaturesProps & TestIdProps> = ({data: categoryStructure, ...props}) => {
  const {isDisabledForUser} = useConditionContext();
  const [isOpenAddNewFeature, setIsOpenAddNewFeature] = useState<boolean>(false);
  const [newAddedFeatures, setNewAddedFeatures] = useState<string[]>([]);
  const router = useRouter();
  const {inspectionId} = router.params;
  const isAddNewFeatureAllowed = Boolean(inspectionId);
  const {
    vehicleFeatures: getVehicleFeatures,
    addedFeatures,
    selectedFeatures,
    hasFeature,
    featuresCategories,
    moreFeaturesCategories,
  } = useVehicleFeatures(categoryStructure, props?.vehicleFeatures);

  const vehicleFeatures = reject(
    isNil,
    selectedFeatures?.map((feature) => feature.featureKey) ?? props?.vehicleFeatures ?? []
  );

  if (!featuresCategories) {
    return null;
  }

  return (
    <>
      <Card
        title={i18n.t('entity.vehicle.labels.vehicleEquipment')}
        variant="inlineWhite"
        data-testid={suffixTestId('features', props)}
        actions={buildArray<Action>().when(isAddNewFeatureAllowed, {
          leftIcon: 'content/add',
          variant: 'ghostLink',
          type: 'button',
          onClick: () => setIsOpenAddNewFeature(true),
          isDisabled: isDisabledForUser,
          title: i18n.t('general.labels.addNewFeatures'),
          'data-testid': suffixTestId('features-vehicleEquipment-addNewFeatures', props),
        })}
      >
        <Show when={isNotNilOrEmpty(selectedFeatures)}>
          <VStack spacing={3}>
            {selectedFeatures
              ?.filter(
                (category) =>
                  category.uniqueKey !== AuditCategoryUniqueKey.MORE_EQUIPMENT &&
                  hasFeature(category.featureKey)
              )
              ?.map((category, index) => (
                <CategoryCard
                  key={`technicalCondition-feature-${category.id}-${category.name}`}
                  data={category}
                  cardVariant="inlineGrey"
                  additionalInformationBeside
                  isNewAdded={
                    !!(category?.featureKey && newAddedFeatures.includes(category?.featureKey))
                  }
                  data-testid={suffixTestId(`technicalCondition-[${index}]`, props)}
                />
              ))}
          </VStack>
        </Show>
        <Hide when={isNotNilOrEmpty(selectedFeatures)}>
          <EmptyStatus
            data-testid={suffixTestId('features-noData', props)}
            headline={i18n.t('general.labels.noData')}
          />
        </Hide>
      </Card>
      <Show when={isAddNewFeatureAllowed}>
        {isOpenAddNewFeature && (
          <AddNewFeature
            data-testid={suffixTestId('features', props)}
            isOpen={isOpenAddNewFeature}
            onClose={() => setIsOpenAddNewFeature(false)}
            categories={moreFeaturesCategories ?? undefined}
            vehicleFeatures={vehicleFeatures}
            addedFeatures={addedFeatures}
            onAddFeatures={(newAddedFeatures) => {
              setNewAddedFeatures(newAddedFeatures);
            }}
          />
        )}
      </Show>
    </>
  );
};
