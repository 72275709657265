import {yupResolver} from '@hookform/resolvers/yup';
import {Chips} from 'platform/components';
import styled from 'styled-components';

import {FC, useEffect, useState} from 'react';
import {
  SubmitHandler,
  Controller,
  SubmitErrorHandler,
  Resolver,
  FieldErrors,
  useForm,
} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {
  useInlineEditing,
  selectCountries,
  AddressRequestBody,
  PutTenantRequestBody,
  replaceValues,
} from '@dms/teas';

import {TestIdProps, suffixTestId, useOnMount} from 'shared';

import {ContactInfoFormType, TenantCache} from '../types';
import {ContactInfoFields} from './ContactInfoFields';
import {FormWithSubmitProvider} from './FormContextWithSubmit';
import {FormWatcher} from './FormWatcher';
import {SelfEmployedFields} from './SelfEmployedFields';
import {contactInformationValidations} from './validations';

export interface ContactInfoWithType extends ContactInfoFormType {
  type: string;
}

const checkPrefix = (values: ContactInfoWithType): ContactInfoWithType => {
  const {countryCode, number} = values?.selfEmployedInformation?.phoneNumber ?? {};
  if (isNil(countryCode) && isNil(number)) {
    return {
      ...values,
      selfEmployedInformation: values.selfEmployedInformation
        ? {...values.selfEmployedInformation, phoneNumber: null}
        : null,
    };
  }
  return values;
};

const Wrapper = styled.div`
  padding-bottom: ${({theme}) => theme.getSize(3)};
`;

export interface ContactInfoFormProps extends TestIdProps {
  onSubmit?: (values: ContactInfoWithType) => void;
  onSubmitError?: (errors: FieldErrors<ContactInfoWithType>) => void;
}

export const ContactInfoForm: FC<ContactInfoFormProps> = ({onSubmit, onSubmitError, ...props}) => {
  const [type, setType] = useState<string | null>(null); // TODO: Use enums from BE ?

  const countries = useSelector(selectCountries) ?? [];
  const inlineEdit = useInlineEditing<Partial<PutTenantRequestBody>, TenantCache>();
  const infoForm = useForm<ContactInfoWithType, Record<string, any>>({
    resolver: yupResolver(contactInformationValidations()) as Resolver<
      ContactInfoWithType,
      Record<string, any>
    >,
    mode: 'onBlur',
    defaultValues: inlineEdit.editedValue?.data?.contactInformation ?? undefined,
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    shouldUnregister: false,
  });

  const handleSubmit: SubmitHandler<ContactInfoWithType> = (values) => {
    const {type, ...convertedObj} = checkPrefix(replaceValues(values, '', null));

    onSubmit?.(values);

    const submitObj = {
      contactInformation: convertedObj,
      type,
    }; // BE schemas are not matching

    inlineEdit.updateMethod(
      submitObj as AddressRequestBody & Partial<PutTenantRequestBody>,
      submitObj as AddressRequestBody & Partial<PutTenantRequestBody>
    );
  };

  const handleSubmitError: SubmitErrorHandler<ContactInfoWithType> = (values) => {
    onSubmitError?.(values);
  };

  useOnMount(() => {
    setType(inlineEdit.editedValue?.data?.type ?? 'selfEmployed');
  });

  useEffect(() => {
    infoForm.setValue('type', type as string);
  }, [type]);

  return (
    <Wrapper>
      <FormWithSubmitProvider
        formProps={infoForm}
        onSubmit={infoForm.handleSubmit(handleSubmit, handleSubmitError)}
      >
        <FormWatcher name="type" />

        <Wrapper>
          <Controller
            name="type"
            defaultValue={null}
            render={() => (
              <Chips
                value={type ? [type] : []}
                options={[
                  {value: 'selfEmployed', label: 'Self-employed'},
                  {value: 'company', label: 'Company'},
                ]}
                onChange={(opt) => {
                  setType(opt?.[0] || null);
                  infoForm.reset();
                }}
                data-testid={suffixTestId('typeChips', props)}
              />
            )}
          />
        </Wrapper>

        <form onSubmit={infoForm.handleSubmit(handleSubmit)}>
          {type === 'selfEmployed' && <SelfEmployedFields />}
          <ContactInfoFields
            countries={countries}
            required={[
              'registrationNumber',
              'companyName',
              'address.street',
              'address.city',
              'address.zipCode',
              'address.country',
            ]}
          />
        </form>
      </FormWithSubmitProvider>
    </Wrapper>
  );
};
