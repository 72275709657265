import {Tooltip, Trend} from 'platform/components';
import {
  Heading,
  HStack,
  Icon,
  Show,
  Stack,
  Text,
  Box,
  ThemeColorTextPath,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {css} from 'styled-components';
import {Pattern, match} from 'ts-pattern';

import {FC, ReactNode} from 'react';

import {always} from 'ramda';
import {isNegative, isNotNil, isNotNilOrEmpty, isPositive, isTrue} from 'ramda-adjunct';

import {SaleVehiclePriceInForeignCurrencyResponseBody} from '@dms/api';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY, useCurrencies, useTenant} from '@dms/shared';

import {EMPTY_PLACEHOLDER, suffixTestId, Nullish, TestIdProps} from 'shared';

type Money = {
  amount?: string | null;
  currency?: string | null;
};

export type PriceBoxProps = {
  title?: string | Nullish;
  price?: Money | null;
  hasAmountSign?: boolean;
  percentTag?: number;
  priceSub?: Money | null;
  isVatDeductible?: boolean | null;
  align?: 'left' | 'right';
  size?: 'normal' | 'small';
  isHorizontal?: boolean;
  colored?: boolean;
  isDiscount?: boolean;
  highlighted?: boolean;
  showDecimals?: boolean;
  tooltipText?: string;
  foreignPrice?: SaleVehiclePriceInForeignCurrencyResponseBody | null;
} & TestIdProps;

/**
 * @deprecated - use @dms/shared PriceBox component instead
 */
export const PriceBox: FC<PriceBoxProps> = (props) => {
  const {
    title,
    price,
    percentTag,
    priceSub,
    isVatDeductible = true,
    align = 'left',
    size = 'normal',
    isHorizontal,
    colored,
    isDiscount,
    showDecimals = false,
  } = props;

  const formatCurrency = useFormatCurrency();
  const {getCurrencySymbol} = useCurrencies();

  const priceAmount = isDiscount ? Number(price?.amount) * -1 : Number(price?.amount);
  const priceSubAmount = isDiscount ? Number(priceSub?.amount) * -1 : Number(priceSub?.amount);

  const isGreen = colored && priceAmount > 0;
  const isRed = colored && priceAmount < 0;

  const {tenantCurrency} = useTenant();

  const _price =
    !isNaN(priceAmount) &&
    price?.currency &&
    formatCurrency(priceAmount, price?.currency, showDecimals ? 2 : 0);

  const _priceSub =
    !isNaN(priceSubAmount) &&
    priceSub?.currency &&
    formatCurrency(priceSubAmount, priceSub?.currency, showDecimals ? 2 : 0);

  const getPercentTag = (percent: number): ReactNode => {
    const isPositive = match<[number, boolean | undefined], boolean | undefined>([
      percent,
      isDiscount,
    ])
      .when(([percent, isDiscount]) => (isDiscount ? percent < 0 : percent > 0), always(true))
      .otherwise(always(undefined));

    const isNegative = match<[number, boolean | undefined], boolean | undefined>([
      percent,
      isDiscount,
    ])
      .when(([percent, isDiscount]) => (isDiscount ? percent > 0 : percent < 0), always(true))
      .otherwise(always(undefined));

    return (
      <Trend
        label={`${Math.abs(percent)}%`}
        isPositive={isPositive}
        isNegative={isNegative}
        isSubtle
        data-testid={suffixTestId('trend', props)}
      />
    );
  };
  const getAlignItems = (): 'baseline' | 'flex-start' | 'flex-end' => {
    if (isHorizontal) {
      return 'baseline';
    } else {
      return align === 'left' ? 'flex-start' : 'flex-end';
    }
  };

  return (
    <div
      css={css`
        text-align: ${align};
      `}
      data-testid={props['data-testid']}
    >
      <div>
        {title && (
          <div>
            <HStack align="center" spacing={1}>
              <Text data-testid={suffixTestId('label', props)} size="xSmall" color="secondary">
                {title}
              </Text>
              <Show when={isNotNilOrEmpty(props.tooltipText)}>
                <Tooltip label={isNotNilOrEmpty(props.tooltipText) ? props.tooltipText : undefined}>
                  <Icon value="action/help" size={3} color="palettes.black.900.60" />
                </Tooltip>
              </Show>
            </HStack>
          </div>
        )}
      </div>
      <Box paddingTop={isHorizontal ? undefined : 2}>
        <Stack align={getAlignItems()} direction={isHorizontal ? 'row-reverse' : 'column'}>
          <HStack align="center">
            <HStack spacing={2} align="center">
              {isNotNil(percentTag) && !isNaN(percentTag) ? getPercentTag(percentTag) : null}
              <Heading
                data-testid={suffixTestId('withVat', props)}
                headingLevel={2}
                size={size === 'small' ? 4 : 3}
                color={match([isTrue(isGreen), isTrue(isRed)])
                  .with([true, Pattern.boolean], always('success' as ThemeColorTextPath))
                  .with([Pattern.boolean, true], always('danger' as ThemeColorTextPath))
                  .otherwise(always(undefined))}
              >
                {_price
                  ? `${getSign(props.hasAmountSign, priceAmount)}${_price}`
                  : `${EMPTY_PLACEHOLDER} ${getCurrencySymbol(tenantCurrency)}`}
              </Heading>
              <Show when={isNotNilOrEmpty(props.foreignPrice?.priceWithVat)}>
                <Heading
                  data-testid={suffixTestId('withVatForeign', props)}
                  headingLevel={2}
                  size={4}
                  color={match([isTrue(isGreen), isTrue(isRed)])
                    .with([true, Pattern.boolean], always('success' as ThemeColorTextPath))
                    .with([Pattern.boolean, true], always('danger' as ThemeColorTextPath))
                    .otherwise(always(undefined))}
                >
                  {`/ ${
                    formatCurrency(
                      parseFloat(props.foreignPrice?.priceWithVat ?? '0'),
                      props.foreignPrice?.foreignCurrencyCode ?? DEFAULT_CURRENCY
                    ) ?? ''
                  }`}
                </Heading>
              </Show>
            </HStack>
          </HStack>

          {isVatDeductible && priceSub !== undefined && (
            <Box
              paddingTop={isHorizontal ? undefined : 1}
              paddingRight={isHorizontal ? 1 : undefined}
            >
              <HStack spacing={1} align="center">
                <Text
                  data-testid={suffixTestId('withoutVat', props)}
                  size="xSmall"
                  inline
                  color="secondary"
                >
                  {`${
                    _priceSub
                      ? `${getSign(props.hasAmountSign, priceSubAmount)}${_priceSub}`
                      : EMPTY_PLACEHOLDER
                  }`}
                </Text>
                <Show when={isNotNilOrEmpty(props.foreignPrice?.priceWithoutVat)}>
                  <Text
                    data-testid={suffixTestId('withoutVatForeign', props)}
                    size="xSmall"
                    inline
                    color="secondary"
                  >
                    /{' '}
                    {formatCurrency(
                      parseFloat(props.foreignPrice?.priceWithoutVat ?? '0'),
                      props.foreignPrice?.foreignCurrencyCode ?? DEFAULT_CURRENCY
                    )}
                  </Text>
                </Show>
                <Text
                  data-testid={suffixTestId('withoutVatLabel', props)}
                  size="xSmall"
                  inline
                  color="secondary"
                >
                  {isNotNilOrEmpty(_priceSub)
                    ? i18n.t('general.labels.withoutVat')
                    : EMPTY_PLACEHOLDER}
                </Text>
              </HStack>
            </Box>
          )}
        </Stack>
      </Box>
    </div>
  );
};

const getSign = (hasAmountSign: boolean | Nullish, value: number): string => {
  if (!hasAmountSign) {
    return '';
  }
  if (isPositive(value)) {
    return '+';
  }
  if (isNegative(value)) {
    return '';
  }
  return '';
};
