import {Button} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

interface AvailabilityTabProps extends TestIdProps {
  warehouseArticleId: string | Nullish;
  manufacturerNumber: string | Nullish;
  manufacturerId: string | Nullish;
}

export function AvailabilityTab(props: AvailabilityTabProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          articleId: props.warehouseArticleId,
          manufacturerNumber: props.manufacturerNumber,
          manufacturer: props.manufacturerId,
        },
      ]),
    [props.warehouseArticleId, props.manufacturerNumber, props.manufacturerId]
  );

  return (
    <VStack spacing={0}>
      <DataGrid
        gridCode="warehouse-availability"
        ref={dataGridRef}
        autoHeight={true}
        queryModifier={queryModifier}
        data-testid={suffixTestId('availability-dg', props)}
      />
      <HStack justify="flex-start">
        <Button
          variant="link"
          title={i18n.t('general.actions.refresh')}
          leftIcon="navigation/refresh"
          onClick={refreshDataGrid}
          data-testid={suffixTestId('requestsAction.refresh', props)}
        />
      </HStack>
    </VStack>
  );
}
