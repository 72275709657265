import {FormControl} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import {MultipleLocationsForm} from '../types/MultipleLocationsForm';
import {VectorRow} from './VectorRow';

export interface VectorsArrayProps {
  control: FormControl<MultipleLocationsForm>;
  formApi: UseFormReturn<MultipleLocationsForm>;
}

export function VectorsArray(props: VectorsArrayProps) {
  const {fields} = useFieldArray<MultipleLocationsForm>({
    name: 'definition',
  });

  return (
    <VStack spacing={4}>
      {fields.map((item, index) => (
        <VectorRow key={item.id} index={index} control={props.control} formApi={props.formApi} />
      ))}
    </VStack>
  );
}
